import React, { FC, useCallback, useContext, useState } from 'react';

import { Box, Card, Container, Divider, Grid, Typography, makeStyles } from '@material-ui/core';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useTranslation } from 'react-i18next';

import { AppGlobalUiContext } from '../../../../../context/AppGlobalUiContext';
import usePrivilege from '../../../../../hooks/usePrivilege';
import i18n from '../../../../../i18n';
import { OrderStateWorkflow, OrderSubscriptionOpenData } from '../../../../../models/order-order.model';
import { cancelOrderSubscriptionByHashId } from '../../../../../services/v3/order-once';
import useAppContainerStyles from '../../../../../theme/container.style';
import AppButton from '../../../../ui/AppButton';
import AppDialog from '../../../../ui/AppDialog';
import OrderStatusBadge from '../../orderOnce/OrderStatusBadge';

dayjs.extend(customParseFormat);

export type OrderSubscriptionOpenProps = {
  orderSubscription?: OrderSubscriptionOpenData;
  isLoadingData: boolean;
  onFetch: () => void;
};

const useStyles = makeStyles((theme) => ({
  orderIcon: {
    marginBottom: theme.spacing(1),
    background: theme.palette.grey[300],
    width: 'auto',
    display: 'inline-block',
    padding: '16px 16px 10px 16px',
    borderRadius: '50%',
    '& svg': {
      fontSize: theme.typography.pxToRem(40),
      color: theme.palette.grey[500],
    },
  },
  productName: {
    width: '200px',
  },
  confirmDialogBox: {
    width: '100%',
    maxWidth: '1000px',
    [theme.breakpoints.up('md')]: {
      minWidth: '300px',
    },
  },
  boxSpace: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
    },
  },
}));

const OrderSubscriptionOpen: FC<OrderSubscriptionOpenProps> = (props) => {
  const { t } = useTranslation();
  const { isLoadingData, orderSubscription, onFetch } = props;
  const containerClasses = useAppContainerStyles();
  const classes = useStyles();
  const iconBronze = '../../static/images/icons/icon-bronze-ribbon.png';
  const iconSilver = '../../static/images/icons/icon-silver-ribbon.png';
  const iconGold = '../../static/images/icons/icon-gold-ribbon.png';
  const { showSnackbar, setAppLoading } = useContext(AppGlobalUiContext);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);

  const { canPerform } = usePrivilege();
  const allowEdit = canPerform('product', 'update');

  const dateDisplayFormat = i18n.language === 'th' ? 'D MMM BBBB' : 'D MMM YYYY';

  const onCancelSubscriptionOpenSubmit = useCallback(async () => {
    if (orderSubscription && orderSubscription?.orderSubscriptionHashId) {
      setAppLoading(true);
      const result = await cancelOrderSubscriptionByHashId(orderSubscription?.orderSubscriptionHashId);
      setAppLoading(false);

      if (result.data) {
        showSnackbar(t('order:orderList.message.cancelOrderSuccess').toString(), 'success');
        setIsConfirmDialogOpen(false);
        onFetch();
      } else {
        showSnackbar(t('order:orderList.message.cancelOrderFailed').toString(), 'error');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setAppLoading, showSnackbar, t]);

  const onConfirmationCancelClick = () => {
    setIsConfirmDialogOpen(false);
  };

  return (
    <>
      <Container className={containerClasses.container}>
        <Box my={3}>
          {orderSubscription && !!isLoadingData ? (
            <Box>
              <Card>
                <Box className={classes.boxSpace}>
                  <Grid container spacing={3}>
                    <Grid item sm={12} md={12}>
                      <Box display="flex" flexDirection="row" justifyContent="space-between" mt={2}>
                        <Box display="flex" flexDirection="row">
                          <Typography style={{ fontWeight: 'bold' }}>
                            {t('order:orderList.details.orderSubscriptionHashId')} :
                          </Typography>
                          <Box ml={1} mr={3}>
                            <Typography>{orderSubscription.orderSubscriptionHashId}</Typography>
                          </Box>
                        </Box>
                        <Box display="flex" justifyContent="flex-end">
                          <AppButton
                            variant="contained"
                            color="error"
                            disabled={!allowEdit}
                            onClick={() => {
                              setIsConfirmDialogOpen(true);
                            }}>
                            {t('order:orderList.button.cancelOrderSubscription')}
                          </AppButton>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      {orderSubscription.userOrderOnce && (
                        <Box>
                          <Box mb={2}>
                            <Typography style={{ fontWeight: 'bold' }}>
                              {t('order:orderList.details.shoppingCartCurrent')}
                            </Typography>
                          </Box>
                          {orderSubscription.shoppingCart.products.map((item) => {
                            const itemKey = item.productHashId;
                            return (
                              <Box key={itemKey}>
                                <Box display="flex" flexDirection="row" mb={1}>
                                  <Typography style={{ fontWeight: 'bold' }}>
                                    {t('order:orderList.details.productHashId')} :
                                  </Typography>
                                  <Box ml={1}>
                                    <Typography>{item.productHashId}</Typography>
                                  </Box>
                                </Box>

                                <Box display="flex" flexDirection="row">
                                  <Typography style={{ fontWeight: 'bold' }}>
                                    {t('order:orderList.details.productName')} :
                                  </Typography>
                                  <Box ml={1} display="flex" flexDirection="row">
                                    <Box mr={1}>
                                      {item.membershipType === 'BRONZE' && (
                                        <img src={iconBronze} width={24} height={30} alt="BRONZE" />
                                      )}
                                      {item.membershipType === 'SILVER' && (
                                        <img src={iconSilver} width={24} height={30} alt="SILVER" />
                                      )}
                                      {item.membershipType === 'GOLD' && (
                                        <img src={iconGold} width={24} height={30} alt="GOLD" />
                                      )}
                                    </Box>
                                    <Typography>{item.productName}</Typography>
                                  </Box>
                                </Box>

                                <Box display="flex" flexDirection="row" mb={1}>
                                  <Typography style={{ fontWeight: 'bold' }}>
                                    {t('order:orderList.details.productAmount')} :
                                  </Typography>
                                  <Box ml={1} display="flex" flexDirection="row">
                                    <Typography>{item.amount}</Typography>
                                    <Typography style={{ marginLeft: '4px' }}>
                                      {item.productUnit === 'MONTH' ? 'เดือน' : ''}
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box display="flex" flexDirection="row" mb={1}>
                                  <Typography style={{ fontWeight: 'bold' }}>
                                    {t('order:orderList.details.freeCoin')} :
                                  </Typography>
                                  <Box ml={1}>
                                    <Typography>
                                      {item.coinFree ? item.coinFree.toLocaleString() : '0'} Coins
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box display="flex" flexDirection="row" mb={1}>
                                  <Typography style={{ fontWeight: 'bold' }}>
                                    {t('order:orderList.details.productTotalPrice')} :
                                  </Typography>
                                  <Box ml={1}>
                                    <Typography>
                                      {item.totalPrice.toLocaleString()} {t('shopping-cart:unit')}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                            );
                          })}
                        </Box>
                      )}
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      {orderSubscription.omiseCreditCardDetail?.brand && (
                        <Box>
                          <Box display="flex" flexDirection="row" mb={1}>
                            <Typography style={{ fontWeight: 'bold' }}>
                              {t('order:orderList.columns.paymentBy')} :
                            </Typography>
                            <Box ml={1}>
                              <Typography>{t('order:orderList.list.creditCard')}</Typography>
                            </Box>
                          </Box>
                          <Box display="flex" flexDirection="row" mt={1}>
                            <Typography style={{ fontWeight: 'bold' }}>
                              {t('order:orderList.details.bank')} :
                            </Typography>
                            <Box ml={1}>
                              <Typography>{orderSubscription.omiseCreditCardDetail.bank}</Typography>
                            </Box>
                          </Box>
                          <Box display="flex" flexDirection="row" mt={1}>
                            <Typography style={{ fontWeight: 'bold' }}>
                              {t('order:orderList.details.brand')} :
                            </Typography>
                            <Box ml={1} mr={3}>
                              <Typography>
                                {orderSubscription.omiseCreditCardDetail.brand.toLocaleUpperCase()}
                              </Typography>
                            </Box>
                            <Typography style={{ fontWeight: 'bold' }}>
                              {t('order:orderList.details.lastDigit')} :
                            </Typography>
                            <Box ml={1}>
                              <Typography>{orderSubscription.omiseCreditCardDetail.lastDigits}</Typography>
                            </Box>
                          </Box>
                          <Box display="flex" flexDirection="row" mt={1}>
                            <Typography style={{ fontWeight: 'bold' }}>
                              {t('order:orderList.details.expire')} :
                            </Typography>
                            <Box ml={1}>
                              <Typography>
                                {orderSubscription.omiseCreditCardDetail.expirationMonth}/
                                {orderSubscription.omiseCreditCardDetail.expirationYear}
                              </Typography>
                            </Box>
                          </Box>
                          <Box display="flex" flexDirection="row" mt={1}>
                            <Typography style={{ fontWeight: 'bold' }}>
                              {t('order:orderList.details.nameCreditCard')} :
                            </Typography>
                            <Box ml={1}>
                              <Typography>{orderSubscription.omiseCreditCardDetail.name}</Typography>
                            </Box>
                          </Box>
                          <Box display="flex" flexDirection="row" mt={1}>
                            <Typography style={{ fontWeight: 'bold' }}>
                              {t('order:orderList.details.cardBindingAt')} :
                            </Typography>
                            <Box ml={1} display="flex" flexDirection="row">
                              <Typography>
                                {dayjs(orderSubscription.createdAt).format(`${dateDisplayFormat}`)} |
                              </Typography>
                              <Typography style={{ marginLeft: '4px' }}>
                                {dayjs(orderSubscription.createdAt).format(`HH:mm:ss`)}
                              </Typography>
                            </Box>
                          </Box>
                          {orderSubscription.nextOccurrencesOn && (
                            <Box display="flex" flexDirection="row" mt={1}>
                              <Typography style={{ fontWeight: 'bold' }}>
                                {t('order:orderList.details.nextOccurrencesOn')} :
                              </Typography>
                              <Box ml={1} display="flex" flexDirection="row">
                                <Typography>
                                  {dayjs(orderSubscription.nextOccurrencesOn[0]).format(`${dateDisplayFormat}`)} |
                                </Typography>
                                <Typography style={{ marginLeft: '4px' }}>
                                  {dayjs(orderSubscription.nextOccurrencesOn[0]).format(`HH:mm:ss`)}
                                </Typography>
                              </Box>
                            </Box>
                          )}
                        </Box>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Divider />
                    </Grid>

                    <Box px={2} mt={2}>
                      <Typography style={{ fontWeight: 'bold' }}>
                        {t('order:orderList.details.titleOrderSubscriptionOpen')}
                      </Typography>
                    </Box>

                    {orderSubscription.userOrderOnce && (
                      <Grid item xs={12} sm={12}>
                        {orderSubscription.userOrderOnce.reverse().map((orderOnce) => (
                          <Box key={orderOnce.orderOnceHashId}>
                            <Box>
                              {orderOnce.shoppingCart.products.map((product) => (
                                <Box my={2} key={product.productHashId}>
                                  <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                      <Box display="flex" flexDirection="row" mb={1}>
                                        <Typography style={{ fontWeight: 'bold' }}>
                                          {t('order:orderList.columns.orderOnceHashId')} :
                                        </Typography>
                                        <Box ml={1} mr={2}>
                                          <Typography>{orderOnce.orderOnceHashId}</Typography>
                                        </Box>
                                        <OrderStatusBadge
                                          status={
                                            orderSubscription.userOrderOnce
                                              ? (orderOnce.orderStateWorkflow?.toString() as OrderStateWorkflow)
                                              : OrderStateWorkflow.open
                                          }
                                        />
                                      </Box>
                                      <Box display="flex" flexDirection="row" mb={1}>
                                        <Typography style={{ fontWeight: 'bold' }}>
                                          {t('order:orderList.details.omiseChargeId')} :
                                        </Typography>
                                        <Box ml={1}>
                                          <Typography>{orderOnce.omiseChargeId}</Typography>
                                        </Box>
                                      </Box>
                                      <Box display="flex" flexDirection="row" mb={1}>
                                        <Typography style={{ fontWeight: 'bold' }}>
                                          {t('order:orderList.details.createdAt')} :
                                        </Typography>
                                        <Box ml={1} display="flex" flexDirection="row">
                                          <Typography>
                                            {dayjs(orderOnce.createdAt).format(`${dateDisplayFormat}`)} |
                                          </Typography>
                                          <Typography style={{ marginLeft: '4px' }}>
                                            {dayjs(orderOnce.createdAt).format(`HH:mm:ss`)}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                      <Box display="flex" flexDirection="row">
                                        <Typography style={{ fontWeight: 'bold' }}>
                                          {t('order:orderList.details.productName')} :
                                        </Typography>
                                        <Box ml={1} display="flex" flexDirection="row">
                                          <Box mr={1}>
                                            {product.membershipType === 'BRONZE' && (
                                              <img src={iconBronze} width={24} height={30} alt="BRONZE" />
                                            )}
                                            {product.membershipType === 'SILVER' && (
                                              <img src={iconSilver} width={24} height={30} alt="SILVER" />
                                            )}
                                            {product.membershipType === 'GOLD' && (
                                              <img src={iconGold} width={24} height={30} alt="GOLD" />
                                            )}
                                          </Box>
                                          <Typography>{product.productName}</Typography>
                                        </Box>
                                      </Box>
                                      <Box display="flex" flexDirection="row" mb={1}>
                                        <Box display="flex" flexDirection="row">
                                          <Typography style={{ fontWeight: 'bold' }}>
                                            {t('order:orderList.details.productType')} :
                                          </Typography>
                                          <Box ml={1} display="flex" flexDirection="row">
                                            <Typography>{product.amount}</Typography>
                                            <Typography style={{ marginLeft: '4px' }}>
                                              {product.productUnit === 'MONTH' ? 'เดือน' : ''}
                                            </Typography>
                                          </Box>
                                        </Box>
                                        <Box display="flex" flexDirection="row" ml={3}>
                                          <Typography style={{ fontWeight: 'bold' }}>
                                            {t('order:orderList.details.productAmount')} :
                                          </Typography>
                                          <Box ml={1} display="flex" flexDirection="row">
                                            <Typography>{product.productAmount} รายการ</Typography>
                                          </Box>
                                        </Box>
                                      </Box>

                                      <Box display="flex" flexDirection="row" mb={1}>
                                        <Box display="flex" flexDirection="row">
                                          <Typography style={{ fontWeight: 'bold' }}>
                                            {t('order:orderList.details.freeCoin')} :
                                          </Typography>
                                          <Box ml={1}>
                                            <Typography>
                                              {product.coinFree ? product.coinFree.toLocaleString() : '0'} Coins
                                            </Typography>
                                          </Box>
                                        </Box>
                                        <Box display="flex" flexDirection="row" ml={3}>
                                          <Typography style={{ fontWeight: 'bold' }}>
                                            {t('order:orderList.details.productTotalPrice')} :
                                          </Typography>
                                          <Box ml={1}>
                                            <Typography>
                                              {product.totalPrice.toLocaleString()} {t('shopping-cart:unit')}
                                            </Typography>
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                  <Box my={2}>
                                    <Divider />
                                  </Box>
                                </Box>
                              ))}
                            </Box>
                          </Box>
                        ))}
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </Card>
            </Box>
          ) : (
            <Box style={{ textAlign: 'center', paddingTop: '200px' }}>
              <Box className={classes.orderIcon}>
                <ListAltOutlinedIcon />
              </Box>
              <Typography>{t('order:orderList.details.noOrderSubscriptionOpen')}</Typography>
            </Box>
          )}
        </Box>
      </Container>

      <AppDialog
        title={t('common:dialog.title.confirm').toString()}
        open={isConfirmDialogOpen}
        onOkClick={onCancelSubscriptionOpenSubmit}
        onCancelClick={onConfirmationCancelClick}
        cancelButtonText={t('common:button.cancel').toString()}
        okButtonText={t('common:button.save').toString()}
        okButtonColor="primary"
        dialogProps={{
          maxWidth: 'md',
        }}>
        <Box className={classes.confirmDialogBox}>
          <Typography>{t('order:orderList.message.confirmCancelOrderOnce')}</Typography>
        </Box>
      </AppDialog>
    </>
  );
};

export default OrderSubscriptionOpen;
