import React, { FC, useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import SellerApplicationsListSearchForm from '../../../components/partials/v3/seller-applications/SellerApplicationListSearchForm';
import SellerApplicationListTable from '../../../components/partials/v3/seller-applications/SellerApplicationListTable';
import { AppGlobalUiContext } from '../../../context/AppGlobalUiContext';
import { SearchFilterContext } from '../../../context/SearchFilterContext';
import { defaultRowsPerPage } from '../../../helpers/constants';
import {
  AppTableConditions,
  SellerAppListSearchFormDataTC,
  SellerListSearchParamsTC,
  SellerApplicationTC,
  SellerApplicationWorkflow,
} from '../../../models';
import { getSellerApplicationList } from '../../../services/v3/seller-applications';

type SellerApplicationListPageProps = unknown;

const searchFilterContextKey = 'seller-applications';

const SellerApplicationList: FC<SellerApplicationListPageProps> = () => {
  const { t } = useTranslation();
  const { showSnackbar } = useContext(AppGlobalUiContext);
  const [sellerApplicationList, setSellerApplicationList] = useState<SellerApplicationTC[]>([]);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [totalLists, setTotalLists] = useState<number>(0);

  // start filling default search filters (either from the one we saved in context or fresh conditions)
  const { currentFilters: ctxFilters, setFilters } = useContext(SearchFilterContext);
  const currentFilters: SellerListSearchParamsTC = ctxFilters ? ctxFilters[searchFilterContextKey] : undefined;
  const [searchFilters, setSearchFilters] = useState<SellerAppListSearchFormDataTC>({
    searchField: currentFilters?.searchField,
    searchKeyword: currentFilters?.searchKeyword || '',
    filteringStatuses: [SellerApplicationWorkflow.WaitingForSellerApproval],
  });
  const [tableConditions, setTableConditions] = useState<AppTableConditions>({
    page: currentFilters?.page || 0,
    rowsPerPage: currentFilters?.rowsPerPage || defaultRowsPerPage,
    sortColumn: currentFilters?.sortColumn || 'updatedAt',
    sortDirection: currentFilters?.sortDirection || 'desc',
  });

  const getCurrentSearchParam = (): SellerListSearchParamsTC => ({
    page: tableConditions.page || 0,
    rowsPerPage: tableConditions.rowsPerPage || defaultRowsPerPage,
    sortColumn: tableConditions.sortColumn || 'updatedAt',
    sortDirection: tableConditions.sortDirection || 'desc',
    searchField: searchFilters.searchField,
    searchKeyword: searchFilters.searchKeyword,
    filteringStatuses: searchFilters.filteringStatuses || [SellerApplicationWorkflow.WaitingForSellerApproval],
  });

  const requestSellerApplicationList = async (isPageSubscribed = true): Promise<boolean> => {
    if (isPageSubscribed) {
      setIsLoadingData(true);

      const searchConditions = getCurrentSearchParam();
      setFilters(searchFilterContextKey, searchConditions);
      const result = await getSellerApplicationList(searchConditions);
      setIsLoadingData(false);

      if (result.data) {
        setSellerApplicationList(result.data.contents);
        setTotalLists(result.data?.page?.totalElements || 0);
        return Promise.resolve(true);
      }

      if (result.error) {
        setSellerApplicationList([]);
        setTotalLists(0);
        showSnackbar(t('seller-applications:error.list.requestFailed').toString(), 'error');
        return Promise.reject(result.error);
      }

      showSnackbar(t(result.error as string).toString(), 'error');
      return Promise.reject(result.error);
    }

    return false;
  };

  useEffect(() => {
    let isSubscribed = true;
    requestSellerApplicationList(isSubscribed);

    return () => {
      isSubscribed = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableConditions, searchFilters]);

  const tableConditionsChangedHandler = (values: AppTableConditions) => {
    setTableConditions(values);
  };

  const onSearchFormSubmit = (filters: SellerAppListSearchFormDataTC) => {
    if (isLoadingData) {
      showSnackbar(t('common.message.pleaseWaitForDataToFinish').toString(), 'warning');
      return;
    }

    if (tableConditions.page !== 0) {
      tableConditions.page = 0;
    }

    setSearchFilters(filters);
  };

  return (
    <>
      <SellerApplicationsListSearchForm
        isLoadingData={isLoadingData}
        currentFilters={searchFilters}
        onSearchFormSubmit={onSearchFormSubmit}
      />
      <SellerApplicationListTable
        currentConditions={tableConditions}
        sellerAppList={sellerApplicationList}
        totalSeller={totalLists}
        isLoadingData={isLoadingData}
        onTableConditionsChanged={tableConditionsChangedHandler}
      />
    </>
  );
};

export default SellerApplicationList;
