import React, { FC, useContext, useEffect, useState } from 'react';

import { Box, Grid, Typography } from '@material-ui/core';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Area, AreaChart, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts';

import { AppGlobalUiContext } from '../../../../context/AppGlobalUiContext';
import { SearchKeyword } from '../../../../models/s3-td.model';
import { getS3TdFile } from '../../../../services/s3-td';
import useTypographyStyles from '../../../../theme/typography.style';

export type DashboardCallClickProps = {
  selectPeriod: string;
  period: string;
};

const DashboardCallClick: FC<DashboardCallClickProps> = (props) => {
  const typoClasses = useTypographyStyles();
  const { t } = useTranslation();
  const { selectPeriod, period } = props;
  const { setAppLoading, showSnackbar } = useContext(AppGlobalUiContext);

  const [callClickData, setCallClickData] = useState<SearchKeyword[]>([]);

  const requestGetData = async () => {
    const result = await getS3TdFile(encodeURIComponent('graph_call_click.csv'));
    setAppLoading(true);
    if (result.data?.data) {
      const dataFilter = result.data?.data.filter((item) => item.filter === selectPeriod);
      const sortedData = [...dataFilter].sort((a, b) => parseInt(b.name, 10) - parseInt(a.name, 10));
      const currentTimestamp = Math.floor(Date.now() / 1000);
      const newData = sortedData.map((item) => ({
        ...item,
        name: dayjs.unix(currentTimestamp - parseInt(item.name || '0', 10) * 86400).format('D MMM'),
      }));
      setCallClickData(newData);
    } else {
      showSnackbar(
        t('dashboard:error.list.requestFailed', {
          fieldName: t('dashboard:v3.title.callClick').toString(),
        }),
        'error',
      );
    }
    setAppLoading(false);
  };

  useEffect(() => {
    requestGetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectPeriod]);

  return (
    <>
      {callClickData.length > 0 && (
        <Box mb={2} p={2}>
          <Grid container direction="row" spacing={2} alignItems="center">
            <Grid item xs="auto" sm={8}>
              <Box display="flex" flexDirection="row">
                <Typography style={{ fontWeight: 'bold' }}>{t('dashboard:v3.title.callClick')}</Typography>
                <Box mx={2}>
                  <Typography className={typoClasses.infoText}>
                    Total : {parseInt(callClickData[0].totalcount, 10).toLocaleString()}
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="row" textAlign="right">
                  {callClickData[0].growth !== '0%' ? (
                    <Box display="flex" flexDirection="row">
                      {callClickData[0].isgrowing === 'true' ? (
                        <TrendingUpIcon className={typoClasses.successText} style={{ marginRight: '4px' }} />
                      ) : (
                        <TrendingDownIcon className={typoClasses.errorText} style={{ marginRight: '4px' }} />
                      )}

                      <Typography
                        style={{ fontWeight: 'bold' }}
                        className={
                          callClickData[0].isgrowing === 'true' ? typoClasses.successText : typoClasses.errorText
                        }>
                        {parseInt(
                          callClickData[0].growth.substring(0, callClickData[0].growth.length - 1),
                          10,
                        ).toLocaleString()}{' '}
                        %
                      </Typography>
                    </Box>
                  ) : (
                    <Typography style={{ fontWeight: 'bold' }} className={typoClasses.greyText}>
                      0 %
                    </Typography>
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item xs="auto" sm={4}>
              <Typography className={typoClasses.greyText600} align="right">
                From last {period} day
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}

      {callClickData.length > 0 && (
        <Box>
          <Box style={{ overflow: 'auto' }}>
            <AreaChart width={840} height={300} data={callClickData} style={{ margin: '0 auto' }}>
              <defs>
                <linearGradient id="colorFill" x1="0" y1="0" x2="2" y2="0">
                  <stop offset="5%" stopColor="#5EC3FF" stopOpacity={0.2} />
                  <stop offset="95%" stopColor="#FD5DEF" stopOpacity={0.2} />
                </linearGradient>
                <linearGradient id="colorstroke" x1="0" y1="0" x2="2" y2="0">
                  <stop offset="5%" stopColor="#5BC4FF" stopOpacity={1} />
                  <stop offset="95%" stopColor="#FF5BEF" stopOpacity={1} />
                </linearGradient>
              </defs>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Area
                type="monotone"
                dataKey="item"
                stroke="url(#colorstroke)"
                strokeWidth={4}
                fillOpacity={1}
                fill="url(#colorFill)"
              />
            </AreaChart>
          </Box>
        </Box>
      )}
    </>
  );
};

export default DashboardCallClick;
