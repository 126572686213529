import React, { FC } from 'react';

import { Typography, TypographyProps } from '@material-ui/core';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import useTypographyStyles from '../../../theme/typography.style';

export type AppDatetimeTextProps = TypographyProps & {
  value: dayjs.Dayjs | null;
  withTime?: boolean;
  withTimeNewLine?: boolean;
};

const AppDatetimeText: FC<AppDatetimeTextProps> = (props) => {
  const { value, withTime, withTimeNewLine, ...rest } = props;
  const { i18n } = useTranslation();
  const typoClasses = useTypographyStyles();
  let dateFormat = i18n.language === 'th' ? 'D MMM BB' : 'MM/dd/yyyy';

  if (!value) {
    return null;
  }

  if (withTime && !withTimeNewLine) {
    dateFormat += ' - HH:mm:ss';
  }

  return (
    <>
      <Typography {...rest}>{value.format(dateFormat)}</Typography>
      {withTimeNewLine && (
        <Typography variant="caption" className={typoClasses.greyText}>
          {value.format('HH:mm:ss')}
        </Typography>
      )}
    </>
  );
};

export default AppDatetimeText;
