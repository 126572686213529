import React, { FC, ReactNode, useEffect } from 'react';

import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { Block, ErrorOutline } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import Layout from './Layout';

export interface ErrorPageTemplateProps {
  type: 'not-found' | 'unauthorized';
  onComponentRendered?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageContainer: { height: `calc(100vh - ${theme.spacing(3) * 2 + 48}px )` },
    icon: {
      fontSize: 140,
      transform: 'translateX(-5px)',
    },
    message: {
      maxWidth: '70%',
      textAlign: 'center',
      marginTop: 20,
      fontSize: theme.typography.h5.fontSize,
      color: theme.palette.grey[600],
    },
    code: {
      fontSize: theme.typography.body1.fontSize,
      color: theme.palette.grey[400],
      marginTop: '10px',
    },
  }),
);

const assetList: { [x: string]: { icon: ReactNode; message: string; code: number } } = {
  'not-found': {
    icon: <ErrorOutline style={{ fontSize: 140 }} color="disabled" />,
    message: 'error:access.notFound',
    code: 404,
  },
  unauthorized: {
    icon: <Block style={{ fontSize: 140 }} color="error" />,
    message: 'error:access.unauthorized',
    code: 403,
  },
};

const ErrorPageTemplate: FC<ErrorPageTemplateProps> = (props) => {
  const { type = 'not-found', onComponentRendered } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const asset = assetList[type];

  useEffect(() => {
    if (typeof onComponentRendered === 'function') {
      onComponentRendered();
    }
  }, [onComponentRendered]);

  return (
    <Layout>
      <Box
        component="div"
        className={classes.pageContainer}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center">
        <Box component="div">{asset.icon}</Box>
        <Typography component="div" className={classes.message}>
          {t(asset.message)}
        </Typography>
        <Typography component="div" className={classes.code}>
          ERROR_CODE: &nbsp;
          {asset.code}
        </Typography>
      </Box>
    </Layout>
  );
};

export default ErrorPageTemplate;
