import React, { FC } from 'react';

import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import YoutubeSearchedForIcon from '@material-ui/icons/YoutubeSearchedFor';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CoinUsageListSearchFormData } from '../../../../../../../models/user.model';
import useTypographyStyles from '../../../../../../../theme/typography.style';
import AppButton from '../../../../../../ui/AppButton';
import AppChipList from '../../../../../../ui/AppChipList';

export type CoinUsageListSearchFormProps = {
  isLoadingData?: boolean;
  currentFilters: CoinUsageListSearchFormData | undefined;
  onSearchFormSubmit: (values: CoinUsageListSearchFormData) => void;
};

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  searchBoxContainer: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 600,
    },
  },
}));

const searchUsageType = [
  {
    label: 'user:details.label.PURCHASED_COIN',
    value: 'PURCHASED_COIN',
  },
  {
    label: 'user:details.label.PURCHASED_MEMBER',
    value: 'PURCHASED_MEMBER',
  },
  {
    label: 'user:details.label.PROMO_CODE',
    value: 'PROMO_CODE',
  },
  {
    label: 'user:details.label.PURCHASED_BOOST',
    value: 'PURCHASED_BOOST',
  },
  {
    label: 'user:details.label.MIGRATED',
    value: 'MIGRATED',
  },
  {
    label: 'user:details.label.CR_TOP_UP',
    value: 'CR_TOP_UP',
  },

  {
    label: 'user:details.label.CR_DEDUCT_COIN',
    value: 'CR_DEDUCT_COIN',
  },
  {
    label: 'user:details.label.NEW_SELLER_FREE_COIN',
    value: 'NEW_SELLER_FREE_COIN',
  },
  {
    label: 'user:details.label.DAILY_CHALLENGE',
    value: 'DAILY_CHALLENGE',
  },
];

const CoinUsageListSearchForm: FC<CoinUsageListSearchFormProps> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const typoClasses = useTypographyStyles();

  const { currentFilters, onSearchFormSubmit, isLoadingData = false } = props;
  const { handleSubmit, setValue, control, getValues } = useForm<CoinUsageListSearchFormData>({
    mode: 'onSubmit',
    defaultValues: {
      searchKeyword: currentFilters?.searchKeyword || '',
      filteringUsageType: currentFilters?.filteringUsageType || [],
    },
  });

  const submitSearchForm = () => {
    if (!isLoadingData) {
      setValue('searchKeyword', getValues('searchKeyword').trim());
      handleSubmit(onSearchFormSubmit)();
    }
  };

  const clearSearchForm = () => {
    if (!isLoadingData) {
      setValue('searchKeyword', '');
      setValue('filteringUsageType', []);
      handleSubmit(onSearchFormSubmit)();
    }
  };

  return (
    <Box className={classes.filterContainer}>
      <form onSubmit={handleSubmit(onSearchFormSubmit)}>
        <Grid container direction="row">
          <Grid item xs={12} md>
            <Grid container direction="row" spacing={0}>
              <Grid item xs={12} md="auto">
                <Box>
                  <Typography variant="body2" className={typoClasses.textWeightBold}>
                    {t('user:coin.filterUsageType')}
                  </Typography>
                  <Box display="flex" flexDirection="row" flexWrap="wrap" mt={1}>
                    <Controller
                      control={control}
                      name="filteringUsageType"
                      defaultValue={currentFilters?.filteringUsageType || []}
                      render={({ field }) => (
                        <>
                          <AppChipList
                            items={searchUsageType}
                            selectedValues={field.value}
                            onChange={(values: string[]) => {
                              field.onChange(values);
                              submitSearchForm();
                            }}
                            isDisabled={isLoadingData}
                          />
                        </>
                      )}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md="auto">
            <Box mt={2}>
              <AppButton variant="outlined" color="primary" onClick={clearSearchForm} disabled={isLoadingData}>
                <YoutubeSearchedForIcon />
              </AppButton>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default CoinUsageListSearchForm;
