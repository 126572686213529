import React, { FC, useState } from 'react';

import { Box, Typography, makeStyles, Divider, Grid } from '@material-ui/core';
import { Controller, UseControllerProps, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { UserImage, UserTCImage } from '../../../../../models';
import { GetShoppingCartTCResponse, OrderCheckoutRequest, SummarizeResponse } from '../../../../../models/shopping';
import AppButton from '../../../../ui/AppButton';
import AppDialog from '../../../../ui/AppDialog';
import AppFormControl from '../../../../ui/AppFormControl';
import AppFormErrorMessage from '../../../../ui/AppFormErrorMessage';
import AppTCFileSelector from '../../../../ui/AppTCFileSelector';
import ImagePreview from '../../ImagePreview';

export type OrderDetailDialogProps = {
  title: string;
  open: boolean;
  onClose: () => void;
  onCloseClick?: () => void;
  orderItem: GetShoppingCartTCResponse;
  onFormSubmit: (data: OrderCheckoutRequest) => void;
  onCancelOrder: () => void;
  onCheckPromoCode: (data: string) => void;
  userHashId: string;
  rules?: { [x: string]: UseControllerProps['rules'] };
  summarize?: SummarizeResponse;
};

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  numberInput: {
    MozAppearance: 'none',
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
  confirmDialogBox: {
    width: '100%',
    maxWidth: '1000px',
    [theme.breakpoints.up('md')]: {
      minWidth: '400px',
    },
  },
  buttonGroups: {
    '& > *': {
      margin: `0 ${theme.spacing(1)}px`,
    },
  },
  promoCodeInput: {
    padding: theme.spacing(1),
    width: '250px',
  },
  textPromoCode: {
    color: theme.palette.success.main,
  },
}));

const OrderDetailDialog: FC<OrderDetailDialogProps> = (props) => {
  const classes = useStyles();
  const iconCoin = '../../static/images/icons/icon-coin.png';
  const iconBronze = '../../static/images/icons/icon-bronze-ribbon.png';
  const iconSilver = '../../static/images/icons/icon-silver-ribbon.png';
  const iconGold = '../../static/images/icons/icon-gold-ribbon.png';
  const {
    title,
    open,
    onClose,
    onCloseClick,
    orderItem,
    onFormSubmit,
    onCancelOrder,
    onCheckPromoCode,
    userHashId,
    rules,
    summarize,
  } = props;
  const { t } = useTranslation();

  const [selectedPreviewImage, setSelectedPreviewImage] = useState<string | undefined>(undefined);
  const [currentPromoCode, setCurrentPromoCode] = useState<string>('');
  const getRules = (fieldName: string): UseControllerProps['rules'] | undefined =>
    rules ? rules[fieldName] : undefined;

  const totalFreeCoin = orderItem.products.reduce((sum, product) => sum + (product?.freeCoin || 0), 0);
  const totalPrice = orderItem.products.reduce((sum, price) => sum + price.price, 0);
  const totalFreeCoinSummarize = summarize?.products.reduce((sum, product) => sum + product.coinFree || 0, 0);
  const [isCheckPromoCode, setIsCheckPromoCode] = useState<boolean>(false);

  const {
    handleSubmit,
    getValues,
    control,
    formState: { errors },
    setValue,
  } = useForm<OrderCheckoutRequest>({
    mode: 'onChange',
    defaultValues: {
      userHashId,
      uploadSlip: undefined,
      fileUrl: '',
      fileHashId: '',
    },
  });

  const onPaymentClick = () => {
    if (typeof onFormSubmit === 'function') {
      onFormSubmit({ ...getValues(), promoCode: currentPromoCode });
    }
  };

  const onThumbnailClicked = (imageUrl: string) => {
    if (imageUrl) {
      setSelectedPreviewImage(imageUrl);
    }
  };

  return (
    <>
      <AppDialog
        open={open}
        onClose={onClose}
        title={title}
        okButtonText="common:button.close"
        okButtonColor="default"
        okButtonVariant="outlined"
        closeOnOkClicked
        noActionBar
        dialogProps={{
          fullWidth: true,
          maxWidth: 'sm',
          disableBackdropClick: true,
        }}>
        {!summarize && orderItem.products.length > 0 ? (
          <>
            {orderItem.products.map((item) => {
              const itemKey = item.id;
              return (
                <Box key={itemKey}>
                  <Box display="flex" flexDirection="row" justifyContent="space-between" my={2}>
                    <Box display="flex" flexDirection="row">
                      {!item.membershipType && <img src={iconCoin} width={24} height={24} alt="coin" />}
                      {item.membershipType === 'BRONZE' && <img src={iconBronze} width={24} height={30} alt="BRONZE" />}
                      {item.membershipType === 'SILVER' && <img src={iconSilver} width={24} height={30} alt="SILVER" />}
                      {item.membershipType === 'GOLD' && <img src={iconGold} width={24} height={30} alt="GOLD" />}
                      <Box>
                        <Typography style={{ marginLeft: 6, fontWeight: 'bold' }}>{item.title}</Typography>
                        {!!item.freeCoin && item.freeCoin > 0 && (
                          <Typography style={{ marginLeft: 6, marginTop: 2 }} variant="subtitle2">
                            {t('shopping-cart:freeCoin', {
                              fieldName: item.freeCoin ? t(item.freeCoin.toLocaleString()) : '-',
                            })}
                          </Typography>
                        )}
                      </Box>
                    </Box>

                    <Box display="flex" flexDirection="row">
                      <Box>
                        <Typography style={{ fontWeight: 'bold' }}>
                          {item.price.toLocaleString()} {t('shopping-cart:unit')}
                        </Typography>
                        <Typography variant="subtitle2" style={{ textAlign: 'right' }}>
                          x1
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Divider />
                </Box>
              );
            })}

            <Typography align="right" style={{ marginTop: '12px', fontWeight: 'bold' }}>
              {t('shopping-cart:detail.orderTotalModal', {
                fieldName: t(orderItem.productsCount.toLocaleString()),
              })}
            </Typography>

            <Box my={1} justifyContent="space-between" display="flex" flexDirection="row">
              <Box>
                <Typography style={{ fontWeight: 'bold' }}>{t('shopping-cart:detail.freeCoinTotal')}</Typography>
              </Box>
              <Box>
                <Typography style={{ fontWeight: 'bold' }}>{totalFreeCoin?.toLocaleString() || '-'} Coins</Typography>
              </Box>
            </Box>

            <Box my={1} justifyContent="space-between" display="flex" flexDirection="row">
              <Box>
                <Typography style={{ fontWeight: 'bold' }}>{t('shopping-cart:detail.totalPrice')}</Typography>
              </Box>
              <Box>
                <Typography style={{ fontWeight: 'bold' }}>
                  {totalPrice.toLocaleString()} {t('shopping-cart:unit')}
                </Typography>
              </Box>
            </Box>

            <Box display="flex" flexDirection="row" justifyContent="space-between" my={2}>
              <Typography style={{ fontWeight: 'bold' }}>{t('shopping-cart:promoCode')}</Typography>

              <Box style={{ marginLeft: '12px' }} display="flex" flexDirection="row">
                <Box>
                  <input
                    type="text"
                    className={classes.promoCodeInput}
                    onChange={(e) => setCurrentPromoCode(e.target.value)}
                  />
                </Box>

                <AppButton
                  style={{ marginLeft: 4 }}
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    onCheckPromoCode(currentPromoCode);
                    setIsCheckPromoCode(true);
                  }}>
                  <Typography style={{ fontWeight: 'bold' }}>{t('shopping-cart:button.checkPromo')}</Typography>
                </AppButton>
              </Box>
            </Box>
          </>
        ) : (
          <>
            {summarize &&
              summarize?.products.map((item) => {
                const itemKey = item.productId;
                return (
                  <Box mb={2} key={itemKey}>
                    <Box display="flex" flexDirection="row" justifyContent="space-between" my={2}>
                      <Box display="flex" flexDirection="row">
                        <img src={iconCoin} width={24} height={24} alt="coin" />
                        <Box>
                          <Typography style={{ marginLeft: 6, fontWeight: 'bold' }}>{item.productName}</Typography>
                          {!!item.coinFree && item.coinFree > 0 && (
                            <Typography style={{ marginLeft: 6, marginTop: 2 }} variant="subtitle2">
                              {t('shopping-cart:freeCoin', {
                                fieldName: item.coinFree ? t(item.coinFree.toLocaleString()) : '-',
                              })}
                            </Typography>
                          )}
                        </Box>
                      </Box>

                      <Box display="flex" flexDirection="row">
                        <Box>
                          <Typography style={{ fontWeight: 'bold' }}>
                            {item.perPrice.toLocaleString()} {t('shopping-cart:unit')}
                          </Typography>
                          <Typography variant="subtitle2" style={{ textAlign: 'right' }}>
                            x{item.productAmount}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Divider />
                  </Box>
                );
              })}
            <Typography align="right" style={{ marginTop: '12px', fontWeight: 'bold' }}>
              {t('shopping-cart:detail.orderTotalModal', {
                fieldName: t(orderItem.productsCount.toLocaleString()),
              })}
            </Typography>
            <Box my={1} justifyContent="space-between" display="flex" flexDirection="row">
              <Box>
                <Typography style={{ fontWeight: 'bold' }}>{t('shopping-cart:detail.freeCoinTotal')}</Typography>
              </Box>
              <Box>
                <Typography style={{ fontWeight: 'bold' }}>{totalFreeCoinSummarize?.toLocaleString()} Coins</Typography>
              </Box>
            </Box>

            <Box my={1} justifyContent="space-between" display="flex" flexDirection="row">
              <Box>
                <Typography style={{ fontWeight: 'bold' }}>{t('shopping-cart:promoCode')}</Typography>
              </Box>
              <Box>
                <Typography style={{ fontWeight: 'bold' }} className={classes.textPromoCode}>
                  {currentPromoCode && currentPromoCode}
                </Typography>
              </Box>
            </Box>
            <Box my={1} justifyContent="space-between" display="flex" flexDirection="row">
              <Box>
                <Typography style={{ fontWeight: 'bold' }}>{t('shopping-cart:detail.discount')}</Typography>
              </Box>
              <Box>
                <Typography style={{ fontWeight: 'bold' }} color="error">
                  - {summarize && summarize.totalDiscount?.toLocaleString()}
                </Typography>
              </Box>
            </Box>
            <Box my={1} justifyContent="space-between" display="flex" flexDirection="row">
              <Box>
                <Typography style={{ fontWeight: 'bold' }}>{t('shopping-cart:detail.totalPrice')}</Typography>
              </Box>
              <Box>
                <Typography style={{ fontWeight: 'bold' }}>
                  {summarize && summarize.totalPrice?.toLocaleString()} บาท
                </Typography>
              </Box>
            </Box>
          </>
        )}
        {!summarize?.totalDiscount && currentPromoCode && summarize === undefined && isCheckPromoCode && (
          <Box textAlign="right" mt={2}>
            <Typography color="error">{t('shopping-cart:message.summarizePromoCodeNotError')}</Typography>
          </Box>
        )}

        <Box mt={3}>
          <form onSubmit={handleSubmit(onPaymentClick)}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={7} md={7}>
                <Box mt={2} mb={3}>
                  <Controller
                    name="uploadSlip"
                    control={control}
                    rules={getRules('uploadSlip') || {}}
                    render={({ field }) => (
                      <AppFormControl error={!!errors.uploadSlip}>
                        <AppTCFileSelector
                          defaultFile={field.value as UserImage}
                          fileMode="image"
                          inputProps={{
                            label: t('shopping-cart:detail.uploadSlipPayment').toString(),
                            error: !!errors.uploadSlip,
                          }}
                          onFilesSelected={(file: UserImage) => {
                            if (file) {
                              field.onChange(file.fullUrl);
                              setValue('uploadSlip', file);
                            }
                          }}
                          onValueCleared={() => field.onChange('')}
                          imageType={UserTCImage.other}
                          onImageClick={onThumbnailClicked}
                        />
                        {!!errors.uploadSlip && <AppFormErrorMessage errors={errors} name="uploadSlip" />}
                      </AppFormControl>
                    )}
                  />
                </Box>
              </Grid>
            </Grid>

            <Box display="flex" my={2} justifyContent="flex-end" className={classes.buttonGroups}>
              <AppButton type="submit" color="primary">
                {t('shopping-cart:detail.sendToApprove')}
              </AppButton>
              <AppButton
                type="submit"
                color="error"
                onClick={() => {
                  if (typeof onCancelOrder === 'function') {
                    onCancelOrder();
                    setCurrentPromoCode('');
                    setIsCheckPromoCode(false);
                  }
                }}>
                {t('shopping-cart:detail.cancelOrder')}
              </AppButton>
              <AppButton
                variant="outlined"
                color="default"
                onClick={() => {
                  if (typeof onCloseClick === 'function') {
                    onCloseClick();
                    setCurrentPromoCode('');
                    setIsCheckPromoCode(false);
                  }
                }}>
                {t('common:button.close')}
              </AppButton>
            </Box>
          </form>
        </Box>
      </AppDialog>

      <ImagePreview imageUrl={selectedPreviewImage} onCloseClick={() => setSelectedPreviewImage(undefined)} asModal />
    </>
  );
};

export default OrderDetailDialog;
