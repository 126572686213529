import React, { forwardRef } from 'react';

import { Checkbox, makeStyles, CheckboxProps } from '@material-ui/core';
import clsx from 'clsx';

import { colors } from '../../../theme/colors';

type MaterialButtonType = Omit<CheckboxProps, 'color'>;
type CheckboxColor = 'inherit' | 'default' | 'primary' | 'secondary' | 'success' | 'error' | 'warning' | 'info';

export type StyledCheckboxProps = MaterialButtonType & {
  color?: CheckboxColor;
};

const disabledStyled = {
  '&.Mui-disabled': {
    color: colors.disabled.dark,
  },
};

const useButtonStyles = makeStyles((theme) => ({
  disabled: {
    ...disabledStyled,
  },
  success: {
    '&.Mui-checked .MuiIconButton-label': {
      color: theme.palette.success.main,
    },
  },
  error: {
    '&.Mui-checked .MuiIconButton-label': {
      color: theme.palette.error.main,
    },
  },
  warning: {
    '&.Mui-checked .MuiIconButton-label': {
      color: theme.palette.warning.main,
    },
  },
  info: {
    '&.Mui-checked .MuiIconButton-label': {
      color: theme.palette.info.main,
    },
  },
}));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AppCheckbox = forwardRef<any, StyledCheckboxProps>((props, ref) => {
  const classes = useButtonStyles();
  const { color = 'default', classes: elemClasses, ...rest } = props;
  const isAltColor = !['default', 'primary', 'secondary'].includes(color);

  let componentClasses = null;
  const altColorList: { [x: string]: string } = {
    success: classes.success,
    error: classes.error,
    warning: classes.warning,
    info: classes.info,
  };

  const rootClass = clsx(elemClasses?.root || null, classes.disabled, {
    [altColorList[color]]: isAltColor,
  });

  // Combine with another input 'classes' property
  componentClasses = { ...(elemClasses || {}), root: rootClass };

  return isAltColor ? (
    <Checkbox classes={componentClasses} {...rest} ref={ref} />
  ) : (
    <Checkbox classes={componentClasses} color={color as 'primary' | 'secondary' | 'default'} {...rest} ref={ref} />
  );
});

export default AppCheckbox;
