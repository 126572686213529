import React, { FC, useState } from 'react';

import { Avatar, Box, Container, makeStyles, TableRow, Typography } from '@material-ui/core';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useRouteMatch, useHistory } from 'react-router-dom';

import { AppTableConditions, TableColumn, SellerApplication } from '../../../../models';
import useAppContainerStyles from '../../../../theme/container.style';
import useAppTableStyles from '../../../../theme/table.style';
import AppTableCell from '../../../ui/AppTableCell';
import AppTableList from '../../../ui/AppTableList';
import SellerApplicationStatusBadge from '../SellerApplicationStatusBadge';

export type UserListTableProps = {
  currentConditions: AppTableConditions;
  totalApplications: number;
  isLoadingData: boolean;
  sellerApplicationList: SellerApplication[];
  onTableConditionsChanged: (values: AppTableConditions) => void;
};

const userTableColumns: TableColumn[] = [
  { name: 'status', label: 'seller-applications:list.columns.status', width: 120 },
  { name: 'username', label: 'user:list.columns.displayName', width: 250, unsortable: true },
  { name: 'email', label: 'user:list.columns.email', width: 220, unsortable: true },
  { name: 'type', label: 'seller-applications:list.columns.applyType', width: 140 },
  { name: 'registeredAt', label: 'seller-applications:list.columns.createdAt', width: 170, unsortable: true },
  { name: 'createdAt', label: 'seller-applications:list.columns.createdSellerAt', width: 170 },
  { name: 'verifiedAt', label: 'seller-applications:list.columns.verifiedAt', width: 170, unsortable: true },
];

const useStyles = makeStyles((theme) => ({
  dateDisplayText: {
    lineHeight: 1.3,
  },
  userNameText: {
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
  },
  userAvatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    border: `solid 1px ${theme.palette.grey[300]}`,
    boxSizing: 'border-box',
  },
}));

const SellerApplicationListTable: FC<UserListTableProps> = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { url } = useRouteMatch();
  const { totalApplications, isLoadingData, currentConditions, sellerApplicationList, onTableConditionsChanged } =
    props;
  const classes = useStyles();
  const containerClasses = useAppContainerStyles();
  const tableClasses = useAppTableStyles();
  const [tableConditions, setTableConditions] = useState<AppTableConditions>(currentConditions);
  const dateDisplayFormat = i18n.language === 'th' ? 'D MMM BBBB' : 'D MMM YYYY';

  const onTableConditionChangedHandler = (values: AppTableConditions) => {
    if (JSON.stringify(values) !== JSON.stringify(tableConditions)) {
      setTableConditions(values);
      onTableConditionsChanged(values);
    }
  };

  const openDetailPage = (sellerApplication: SellerApplication) => {
    // TODO: uncomment when seller application detail page has been implemented
    history.push(`${url}/${sellerApplication.hashId}`);
  };

  return (
    <>
      <Container className={containerClasses.container}>
        <Box className={tableClasses.tableContainer}>
          <AppTableList
            {...tableConditions}
            aria-label="user-list"
            columns={userTableColumns}
            totalDataCount={totalApplications}
            hasPagination
            onTableConditionChanged={onTableConditionChangedHandler}
            isLoading={isLoadingData}
            tableBodyContent={
              sellerApplicationList.length > 0 && (
                <>
                  {sellerApplicationList.map((app: SellerApplication) => (
                    <TableRow
                      hover
                      key={app.hashId}
                      classes={{ root: clsx(tableClasses.tableRow, tableClasses.clickableTableRow) }}
                      onClick={() => openDetailPage(app)}>
                      <AppTableCell>
                        <SellerApplicationStatusBadge status={app.status} />
                      </AppTableCell>
                      <AppTableCell>
                        <Box display="flex" flexDirection="row" flexWrap="nowrap" alignItems="center">
                          <Avatar
                            alt={app.user?.displayName}
                            src={app.user?.profileImageUrl}
                            className={classes.userAvatar}
                          />
                          <Box
                            component="span"
                            pl={1}
                            flexGrow={1}
                            minWidth={0}
                            whiteSpace="normal"
                            className={classes.userNameText}>
                            {app.user?.displayName ? `${app.user?.displayName}` : '-'}
                          </Box>
                        </Box>
                      </AppTableCell>
                      <AppTableCell>{app.user?.email}</AppTableCell>
                      <AppTableCell>{t(`seller-applications:common.applicationType.${app.type}`)}</AppTableCell>
                      <AppTableCell>
                        <Typography component="div" variant="subtitle2" className={classes.dateDisplayText}>
                          {dayjs(app.user?.createdAt).format(`${dateDisplayFormat}`)}
                          <br />
                          {dayjs(app.user?.createdAt).format(`HH:mm:ss`)}
                        </Typography>
                      </AppTableCell>
                      <AppTableCell>
                        <Typography component="div" variant="subtitle2" className={classes.dateDisplayText}>
                          {dayjs(app.createdAt).format(`${dateDisplayFormat}`)}
                          <br />
                          {dayjs(app.createdAt).format(`HH:mm:ss`)}
                        </Typography>
                      </AppTableCell>
                      <AppTableCell>
                        {app.user?.verifiedAt ? (
                          <Typography component="div" variant="subtitle2" className={classes.dateDisplayText}>
                            {dayjs(app.user?.verifiedAt).format(`${dateDisplayFormat}`)}
                            <br />
                            {dayjs(app.user?.verifiedAt).format(`HH:mm:ss`)}
                          </Typography>
                        ) : (
                          '-'
                        )}
                      </AppTableCell>
                    </TableRow>
                  ))}
                </>
              )
            }
          />
        </Box>
      </Container>
    </>
  );
};

export default SellerApplicationListTable;
