import React, { FC } from 'react';

import { Chip, makeStyles } from '@material-ui/core'
import DoneIcon from '@material-ui/icons/Done';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { ListingCRStatus } from '../../../../models/listing.model';


export type ListingCrStatusBadgeProps = {
  crStatus: ListingCRStatus;
};

const useStyles = makeStyles((theme) => ({
  crStatus: {
    height: '26px',
    lineHeight: '26px',
  },
  crStatusApproved: {
    backgroundColor: theme.customPalette.appCrStatus.approved.main,
    color: theme.customPalette.appCrStatus.approved.contrastText,
  },
  crStatusPending: {
    backgroundColor: theme.customPalette.appCrStatus.pending.main,
    color: theme.customPalette.appCrStatus.pending.contrastText,
  },
}));

const ListingCrStatusBadge: FC<ListingCrStatusBadgeProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { crStatus } = props;

  if (!crStatus || crStatus === ListingCRStatus.NotApproved) {
    return null;
  }

  return (
    <Chip
      classes={{ root: classes.crStatus }}
      className={clsx({
        [classes.crStatusPending]: crStatus === ListingCRStatus.Pending,
        [classes.crStatusApproved]: crStatus === ListingCRStatus.Approved,
      })}
      icon={[ListingCRStatus.Approved].includes(crStatus) ? <DoneIcon fontSize="small" color="inherit" /> : undefined}
      label={t(`listing:common.crStatus.${crStatus}`).toString()}
    />
  );
};

export default ListingCrStatusBadge;
