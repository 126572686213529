import React, { FC, useEffect, useState } from 'react';

import { Box, TableRow } from '@material-ui/core';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { defaultRowsPerPage } from '../../../../helpers/constants';
import { AppTableConditions, TableColumn } from '../../../../models';
import { NotificationData, NotificationSearchParams } from '../../../../models/notification.model';
import { getNotificationsByUserHashId } from '../../../../services/notification';
import AppDialog from '../../../ui/AppDialog';
import AppTableCell from '../../../ui/AppTableCell';
import AppTableList from '../../../ui/AppTableList';

export type UserNotificationDialogProps = {
  title: string;
  open: boolean;
  userHashId: string;
  isLoadingData: boolean;
  onClose: () => void;
  onDialogExited?: () => void;
};

const UserNotificationDialog: FC<UserNotificationDialogProps> = (props) => {
  const { open, title, userHashId, isLoadingData, onClose, onDialogExited } = props;
  const [data, setData] = useState<NotificationData[]>([]);
  const [totalItems, setTotalItems] = useState<number>(0);
  const { t } = useTranslation();

  const notificationTableColumns: TableColumn[] = [
    { name: 'timestamp', label: 'user:list.columns.createdAt', width: 200 },
    { name: 'content', label: 'user:list.columns.content', width: 400 },
    { name: 'link', label: 'user:list.columns.link', width: 400 },
    { name: 'type', label: 'user:list.columns.type', width: 200 },
  ];

  const [tableConditions, setTableConditions] = useState<Partial<AppTableConditions>>({
    page: 0,
    rowsPerPage: defaultRowsPerPage,
  });

  const getCurrentSearchParam = (): Partial<NotificationSearchParams> => ({
    page: tableConditions.page || 0,
    rowsPerPage: tableConditions.rowsPerPage || defaultRowsPerPage,
  });

  const requestNotificationList = async () => {
    const searchConditions = getCurrentSearchParam();
    const res = await getNotificationsByUserHashId(userHashId, searchConditions);
    if (res.data) {
      setData(res.data.data);
      setTotalItems(res.data.total);
    }
  };

  useEffect(() => {
    requestNotificationList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userHashId]);

  const onTableConditionChangedHandler = (values: AppTableConditions) => {
    if (JSON.stringify(values) !== JSON.stringify(tableConditions)) {
      setTableConditions(values);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useDeepCompareEffect((): any => {
    requestNotificationList();
  }, [tableConditions]);

  return (
    <AppDialog
      open={open}
      title={title}
      onClose={onClose}
      okButtonText="common:button.close"
      okButtonColor="default"
      okButtonVariant="outlined"
      closeOnOkClicked
      dialogProps={{
        fullWidth: true,
        maxWidth: 'lg',
        TransitionProps: {
          onExited: () => {
            if (typeof onDialogExited === 'function') {
              onDialogExited();
            }
          },
        },
      }}>
      <Box px={3}>
        <AppTableList
          {...tableConditions}
          aria-label="payment-history-list"
          columns={notificationTableColumns}
          totalDataCount={totalItems}
          hasPagination
          onTableConditionChanged={onTableConditionChangedHandler}
          isLoading={isLoadingData}
          tableBodyContent={
            data.length > 0 && (
              <>
                {data.map((item: NotificationData) => (
                  <TableRow key={item.hashId}>
                    <AppTableCell>{dayjs(item.timestamp).format('YYYY-MM-DD')}</AppTableCell>
                    <AppTableCell>{item.content}</AppTableCell>
                    <AppTableCell>{item.link}</AppTableCell>
                    <AppTableCell>{t(`user:details.label.${item.type}`)}</AppTableCell>
                  </TableRow>
                ))}
              </>
            )
          }
        />
      </Box>
    </AppDialog>
  );
};

export default UserNotificationDialog;
