import dayjs from 'dayjs';
import { DocumentData, DocumentReference, DocumentSnapshot, QueryDocumentSnapshot } from 'firebase/firestore';

import { AuthUser } from './auth-user.model';
import { AppTableConditions } from './common';

export type FsQueryDocSnapshot = QueryDocumentSnapshot<DocumentData>;
export type FsDocSnapshot = DocumentSnapshot<DocumentData>;
export type FsDocRef = DocumentReference<DocumentData>;

export enum ReportItemType {
  Post = 'post',
  Comment = 'comment',
}

export enum ReportType {
  Fraud = 'FRAUD',
  Spam = 'SPAM',
  Inappropriate = 'INAPPROPRIATE',
  Other = 'OTHER',
}

export enum ReportStatus {
  Submitted = 'submitted',
  Approved = 'approved',
  Rejected = 'rejected',
  MarkAsDuplicated = 'markAsDuplicated',
}

export interface PostListRequest {
  replyToType?: 'post' | 'comment';
  itemPerRequest?: number;
  lastVisibleDocId?: string;
  firstVisibleDocId?: string;
  sortingColumn?: string;
  sortingDirection?: 'asc' | 'desc';
}

export type CommentListRequest = PostListRequest;

export interface SharedItem {
  description: string;
  icon: string;
  image: string;
  siteName: string;
  title: string;
  url: string;
}

export type PostSharedItem = {
  title?: string;
  description?: string;
  url?: string;
  siteName?: string;
  image?: string;
  icon?: string;
  keywords?: string;
};

export type PostAuthor = AuthUser;

export enum PostType {
  Buy = 'buy',
  Sell = 'sell',
  Common = 'common',
}

export type NewPostFormData = {
  postType?: PostType;
  content: string;
  tags?: string[];
  media?: File;
  sharedItem?: PostSharedItem;
};

// eslint-disable-next-line import/prefer-default-export
export class Post {
  id = '';

  author: PostAuthor | undefined = undefined;

  content: string | undefined = undefined;

  media: string | undefined = undefined;

  postType: string | undefined = undefined;

  createdAt: dayjs.Dayjs | undefined = undefined;

  updatedAt: dayjs.Dayjs | undefined = undefined;

  sharedItem: SharedItem | undefined = undefined;

  tags: string[] = [];

  /* eslint-disable @typescript-eslint/no-explicit-any */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  constructor(data: any) {
    Object.assign(this, data);
  }

  get asDataObject(): any {
    /* eslint-enable @typescript-eslint/no-explicit-any */
    return {
      id: this.id || null,
      author: this.author?.asDataObject || null,
      content: this.content || null,
      media: this.media || null,
      postType: this.postType || null,
      tags: this.tags || [],
      createdAt: this.createdAt?.valueOf() || null,
      updatedAt: this.updatedAt?.valueOf() || null,
      sharedItem: this.sharedItem || null,
    };
  }
}

export type ReportedItemListSearchFormData = {
  startDate: dayjs.Dayjs | null;
  endDate: dayjs.Dayjs | null;
  filteringItemTypes: string[];
  filteringTypes: string[];
  filteringStatuses: string[];
};

export type ReportedItemListSearchParams = ReportedItemListSearchFormData &
  AppTableConditions & {
    firstVisibleDocId?: string;
    lastVisibleDocId?: string;
  };

export type ReportedItem = {
  id: string;
  itemId: string;
  itemType: 'post' | 'common';
  reportReason: string;
  reportStatus: string;
  reportType: ReportType;
  reportedBy: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reportItem: any;
  remark?: string;
  createdAt: dayjs.Dayjs;
  updatedAt: dayjs.Dayjs;
};

export interface GetReportedItemListResponse {
  reportedItems: ReportedItem[];
  totalCount: number;
}

export interface SnsTag {
  id: string;
  tagName: string;
  popular: boolean;
  recommend: boolean;
  isDisable: boolean;
}
