import React, { FC, useState, useEffect } from 'react';

import {
  Box,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  makeStyles,
  Divider,
  TextField,
  FormHelperText,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import clsx from 'clsx';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import CommonWebConfigFields from './CommonWebConfigFields';
import usePrivilege from '../../../../hooks/usePrivilege';
import { AdsDetail, AdsIds, FileItem, WebConfig, WebConfigEditFormProps } from '../../../../models';
import useTypographyStyles from '../../../../theme/typography.style';
import AppFormControl from '../../../ui/AppFormControl';
import AppMediaSelector from '../../../ui/AppMediaSelector';

const useStyles = makeStyles((theme) => ({
  paper: {
    border: `solid 1px ${theme.palette.grey[400]}`,
    marginBottom: theme.spacing(2),
    '&:hover': {
      borderColor: theme.palette.grey[700],
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    flexBasis: 'auto',
    flexShrink: 1,
    marginLeft: theme.spacing(1),
  },
  activeIcon: {
    color: theme.palette.success.main,
    transform: 'translateY(5px)',
  },
  inactiveIcon: {
    color: theme.palette.grey[400],
    transform: 'translateY(5px)',
  },
  expandedPanel: {
    boxShadow: theme.shadows[3],
    borderBottomLeftRadius: '3px',
    borderBottomRightRadius: '3px',
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: `${theme.spacing(2)}px 0`,
    },
  },
  accordionPanel: {
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  remarkText: {
    fontSize: '12px',
    color: theme.palette.grey[700],
    marginLeft: '6px',
  },
}));

const WebConfigAdsDetail: FC<WebConfigEditFormProps> = (props) => {
  const classes = useStyles();
  const typoClasses = useTypographyStyles();
  const { configItem, onSave } = props;
  const [config, setConfig] = useState(configItem);
  const [configObject, setConfigObject] = useState<AdsDetail | undefined>(undefined);
  const { t } = useTranslation();
  const [expandedPanel, setExpandedPanel] = useState<string>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { control, getValues, setValue, watch } = useForm<any>();
  const { canPerform } = usePrivilege();
  const canUpdateWebConfig = canPerform('webConfig', 'update');

  const sizeData = {
    A: {
      mobile: {
        width: 300,
        height: 250,
      },
      desktop: {
        width: 970,
        height: 250,
      },
    },
    B: {
      mobile: {
        width: 320,
        height: 100,
      },
      desktop: {
        width: 970,
        height: 90,
      },
    },
    C: {
      mobile: {
        width: 320,
        height: 100,
      },
      desktop: {
        width: 970,
        height: 90,
      },
    },
    D: {
      mobile: {
        width: 320,
        height: 100,
      },
      desktop: {
        width: 970,
        height: 90,
      },
    },
    E: {
      mobile: {
        width: 300,
        height: 250,
      },
      desktop: {
        width: 970,
        height: 90,
      },
    },
    F: {
      mobile: {
        width: 300,
        height: 250,
      },
      desktop: {
        width: 970,
        height: 90,
      },
    },
    L1: {
      mobile: {
        width: 320,
        height: 100,
      },
      desktop: {
        width: 728,
        height: 90,
      },
    },
    L2: {
      mobile: {
        width: 320,
        height: 100,
      },
      desktop: {
        width: 300,
        height: 600,
      },
    },
    L3: {
      mobile: {
        width: 300,
        height: 250,
      },
      desktop: {
        width: 970,
        height: 90,
      },
    },
    L4: {
      mobile: {
        width: 300,
        height: 250,
      },
      desktop: {
        width: 970,
        height: 90,
      },
    },
    DEF: {
      mobile: {
        width: 0,
        height: 0,
      },
      desktop: {
        width: 0,
        height: 0,
      },
    },
    S1: {
      mobile: {
        width: 300,
        height: 250,
      },
      desktop: {
        width: 728,
        height: 90,
      },
    },
    S2: {
      mobile: {
        width: 300,
        height: 250,
      },
      desktop: {
        width: 300,
        height: 250,
      },
    },
    PC1: {
      mobile: {
        width: 0,
        height: 0,
      },
      desktop: {
        width: 0,
        height: 0,
      },
    },
    PC2: {
      mobile: {
        width: 0,
        height: 0,
      },
      desktop: {
        width: 0,
        height: 0,
      },
    },
  };

  useEffect(() => {
    try {
      const configJsonObject = JSON.parse(configItem.description) as AdsDetail;
      setConfigObject(configJsonObject);

      // Set default form values after getting config converted to object
      Object.keys(configJsonObject).forEach((key) => {
        const cfg = configJsonObject[key as AdsIds];
        setValue(`${key}_active`, cfg.active);
        setValue(`${key}_type`, cfg.type);
        setValue(`${key}_desktop_link`, cfg.desktop.link);
        setValue(`${key}_desktop_image`, cfg.desktop.image);
        setValue(`${key}_desktop_googleId`, cfg.desktop.googleId);
        setValue(`${key}_desktop_src`, cfg.desktop.src);
        setValue(`${key}_mobile_link`, cfg.mobile.link);
        setValue(`${key}_mobile_image`, cfg.mobile.image);
        setValue(`${key}_mobile_googleId`, cfg.mobile.googleId);
        setValue(`${key}_mobile_src`, cfg.mobile.src);
      });
    } catch (e) {
      setConfigObject(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onExpandedPanelChanged = (key: string) => {
    setExpandedPanel(expandedPanel === key ? '' : key);
  };

  const onCommonFieldsChanged = (data: Partial<WebConfig>) => {
    setConfig({
      ...config,
      ...data,
    });
  };

  const saveConfigData = () => {
    const formData = getValues();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const saveData: any = {};

    Object.keys(formData).forEach((key) => {
      const [propKey, field, subField] = key.split(/_/gi);

      if (!saveData[propKey]) {
        saveData[propKey] = {};
      }

      if (!subField) {
        saveData[propKey][field] = formData[key];
      } else {
        if (!saveData[propKey][field]) {
          saveData[propKey][field] = {};
        }

        saveData[propKey][field][subField] = formData[key];
      }
    });

    // TODO: Fix image url gateway ของ prod ถูกเก็บไว้ไม่ถูกที่เลยทำการเปลี่ยน path ใหม่เฉพาะส่วนของ PROD เท่านั้น
    const newSaveData = JSON.stringify(saveData).replaceAll(
      `${process.env.REACT_APP_S3_ASSET_PATH}`,
      'https://www.truck2hand.com',
    );

    if (typeof onSave === 'function') {
      onSave({
        ...config,
        description:
          process.env.REACT_APP_DOMAIN_URL === 'https://crm.truck2hand.com' ? newSaveData : JSON.stringify(saveData),
      });
    }
  };

  return (
    <>
      {!!configObject && (
        <>
          <CommonWebConfigFields config={config} onDataChange={onCommonFieldsChanged} onSaveClick={saveConfigData} />
          <Box mt={3}>
            <form>
              {Object.keys(configObject).map((key) => {
                const item = configObject[key as AdsIds];

                return (
                  <Accordion
                    key={key}
                    expanded={expandedPanel === key}
                    onChange={() => onExpandedPanelChanged(key)}
                    className={clsx({
                      [classes.accordionPanel]: expandedPanel !== key,
                    })}
                    classes={{ expanded: classes.expandedPanel }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`${key}-panel-content`}
                      id={`${key}-panel-header`}>
                      {watch(`${key}_active`) ? (
                        <CheckCircleIcon className={classes.activeIcon} fontSize="small" />
                      ) : (
                        <RemoveCircleIcon className={classes.inactiveIcon} fontSize="small" />
                      )}
                      <Typography variant="h6" className={clsx(classes.heading, typoClasses.textWeightBold)}>
                        {key}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <Controller
                            defaultValue={item.active}
                            control={control}
                            name={`${key}_active`}
                            render={({ field }) => (
                              <>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      {...field}
                                      checked={field.value}
                                      color="primary"
                                      disabled={!canUpdateWebConfig}
                                    />
                                  }
                                  label={t('settings:dialog.config.adsDetail.active')}
                                />

                                <FormHelperText>
                                  {field.value && t('settings:message.config.enableManualBannerDesc')}
                                  {!field.value && t('settings:message.config.disableManualBannerDesc')}
                                </FormHelperText>
                              </>
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={8} md={6}>
                          <Controller
                            defaultValue={item.type}
                            control={control}
                            name={`${key}_type`}
                            render={({ field }) => (
                              <AppFormControl>
                                <TextField
                                  {...field}
                                  variant="outlined"
                                  label={t('settings:dialog.config.adsDetail.type').toString()}
                                  disabled={!canUpdateWebConfig}
                                />
                              </AppFormControl>
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                          <Box my={2} display="flex" alignItems="center">
                            <Typography variant="subtitle2" className={typoClasses.textWeightBold}>
                              {t('settings:dialog.config.adsDetail.desktop')}
                            </Typography>
                            {sizeData[key as AdsIds].desktop.width > 0 && (
                              <Typography className={classes.remarkText}>
                                {t('settings:dialog.config.adsDetail.imageSize', {
                                  width: sizeData[key as AdsIds].desktop.width,
                                  height: sizeData[key as AdsIds].desktop.height,
                                })}
                              </Typography>
                            )}
                          </Box>
                          <Controller
                            defaultValue={item.desktop.image}
                            control={control}
                            name={`${key}_desktop_image`}
                            render={({ field }) => (
                              <AppFormControl boxProps={{ mb: 2 }}>
                                <AppMediaSelector
                                  defaultValue={field.value}
                                  mode="input"
                                  inputProps={{
                                    label: t('settings:dialog.config.adsDetail.image').toString(),
                                  }}
                                  onFilesSelected={(files: FileItem[]) => {
                                    if (files.length) {
                                      field.onChange(files[0].fullUrl);
                                    }
                                  }}
                                  onValueCleared={() => field.onChange('')}
                                />
                              </AppFormControl>
                            )}
                          />
                          <Controller
                            defaultValue={item.desktop.link}
                            control={control}
                            name={`${key}_desktop_link`}
                            render={({ field }) => (
                              <AppFormControl boxProps={{ mb: 2 }}>
                                <TextField
                                  {...field}
                                  variant="outlined"
                                  label={t('settings:dialog.config.adsDetail.link').toString()}
                                  disabled={!canUpdateWebConfig}
                                />
                              </AppFormControl>
                            )}
                          />
                          <Controller
                            defaultValue={item.desktop.googleId}
                            control={control}
                            name={`${key}_desktop_googleId`}
                            render={({ field }) => (
                              <AppFormControl boxProps={{ mb: 2 }}>
                                <TextField
                                  {...field}
                                  variant="outlined"
                                  label={t('settings:dialog.config.adsDetail.googleId').toString()}
                                  disabled={!canUpdateWebConfig}
                                />
                              </AppFormControl>
                            )}
                          />
                          <Controller
                            defaultValue={item.desktop.src}
                            control={control}
                            name={`${key}_desktop_src`}
                            render={({ field }) => (
                              <AppFormControl boxProps={{ mb: 2 }}>
                                <TextField
                                  {...field}
                                  variant="outlined"
                                  label={t('settings:dialog.config.adsDetail.src').toString()}
                                  disabled={!canUpdateWebConfig}
                                />
                              </AppFormControl>
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                          <Box my={2} display="flex" alignItems="center">
                            <Typography variant="subtitle2" className={typoClasses.textWeightBold}>
                              {t('settings:dialog.config.adsDetail.mobile')}
                            </Typography>
                            {sizeData[key as AdsIds].mobile.width > 0 && (
                              <Typography className={classes.remarkText}>
                                {t('settings:dialog.config.adsDetail.imageSize', {
                                  width: sizeData[key as AdsIds].mobile.width,
                                  height: sizeData[key as AdsIds].mobile.height,
                                })}
                              </Typography>
                            )}
                          </Box>
                          <Controller
                            defaultValue={item.mobile.image}
                            control={control}
                            name={`${key}_mobile_image`}
                            render={({ field }) => (
                              <AppFormControl boxProps={{ mb: 2 }}>
                                <AppMediaSelector
                                  defaultValue={field.value}
                                  mode="input"
                                  inputProps={{
                                    label: t('settings:dialog.config.adsDetail.image').toString(),
                                  }}
                                  onFilesSelected={(files: FileItem[]) => {
                                    if (files.length) {
                                      field.onChange(files[0].fullUrl);
                                    }
                                  }}
                                  onValueCleared={() => field.onChange('')}
                                />
                              </AppFormControl>
                            )}
                          />
                          <Controller
                            defaultValue={item.mobile.link}
                            control={control}
                            name={`${key}_mobile_link`}
                            render={({ field }) => (
                              <AppFormControl boxProps={{ mb: 2 }}>
                                <TextField
                                  {...field}
                                  variant="outlined"
                                  label={t('settings:dialog.config.adsDetail.link').toString()}
                                  disabled={!canUpdateWebConfig}
                                />
                              </AppFormControl>
                            )}
                          />
                          <Controller
                            defaultValue={item.mobile.googleId}
                            control={control}
                            name={`${key}_mobile_googleId`}
                            render={({ field }) => (
                              <AppFormControl boxProps={{ mb: 2 }}>
                                <TextField
                                  {...field}
                                  variant="outlined"
                                  label={t('settings:dialog.config.adsDetail.googleId').toString()}
                                  disabled={!canUpdateWebConfig}
                                />
                              </AppFormControl>
                            )}
                          />
                          <Controller
                            defaultValue={item.mobile.src}
                            control={control}
                            name={`${key}_mobile_src`}
                            render={({ field }) => (
                              <AppFormControl boxProps={{ mb: 2 }}>
                                <TextField
                                  {...field}
                                  variant="outlined"
                                  label={t('settings:dialog.config.adsDetail.src').toString()}
                                  disabled={!canUpdateWebConfig}
                                />
                              </AppFormControl>
                            )}
                          />
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </form>
          </Box>
        </>
      )}
    </>
  );
};

export default WebConfigAdsDetail;
