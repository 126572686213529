import React, { FC, useMemo } from 'react';

import { Box, Grid, Typography } from '@material-ui/core';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import i18n from '../../../../../i18n';
import { UserDataTC } from '../../../../../models';
import DataBlock from '../../../common/DataBlock';
import YoutubeThumbnail from '../../../common/YoutubeThumbnail';

export interface AdditionalInfoProps {
  currentUser: UserDataTC;
}

const AdditionalInfo: FC<AdditionalInfoProps> = (props) => {
  const { t } = useTranslation();
  const { currentUser } = props;

  const dateDisplayFormat = i18n.language === 'th' ? 'D MMM BBBB' : 'D/MM/YY';

  const productTypeItem = useMemo(
    () => (currentUser.sellerProductType ? currentUser.sellerProductType.join(', ') : '-'),
    [currentUser.sellerProductType],
  );

  return (
    <Grid container>
      <Grid item xs={12} sm={12} lg={6}>
        {currentUser.sellerProductType && (
          <DataBlock label="user:common.fields.shopProductType" data={productTypeItem || '-'} noWrapText={false} />
        )}

        <DataBlock label="user:common.fields.shopType" data={currentUser.sellerType} />

        <DataBlock label="user:common.fields.ratingScore" data={currentUser.sellerRatingScore} />

        {currentUser.badges && currentUser.badges !== null ? (
          <DataBlock
            label="user:common.fields.badges"
            data={currentUser.badges.map((item, index: number) => {
              const rowKey = `thumbnail-${index}`;
              return (
                <Box key={rowKey} mb={1}>
                  <Box display="flex" flexDirection="row">
                    <Typography style={{ marginRight: '4px' }}>{index + 1} : </Typography>
                    <Typography>{item.name}</Typography>
                  </Box>

                  <Typography variant="subtitle2">
                    {item.crName} | {dayjs(item.createdAt).format(`${dateDisplayFormat}`)}
                  </Typography>
                </Box>
              );
            })}
          />
        ) : (
          <DataBlock label="user:common.fields.badges" data="-" />
        )}
      </Grid>
      <Grid item xs={12} sm={12} lg={6}>
        <Typography style={{ fontWeight: 'bold' }}>{t('user:common.fields.shopYoutubeVdos')}</Typography>

        <Box py={2} pr={3}>
          <Grid container spacing={2}>
            {(currentUser.sellerYoutubeVdos || []).map((socialMedia, index: number) => {
              const rowKey = `social-media-${index}`;
              return (
                <Grid key={rowKey} item xs={12} sm={6} md={6}>
                  <YoutubeThumbnail
                    createdAt={socialMedia.createdAt}
                    youtubeUrl={socialMedia.url}
                    thumbnailLabel={socialMedia.title}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default AdditionalInfo;
