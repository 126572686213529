/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';

import { AnyLiteralObject, SearchFilterContextProps } from '../models';

export default (): SearchFilterContextProps => {
  const [currentFilters, setCurrentFilters] = useState<AnyLiteralObject | undefined>(undefined);

  const setFilters = (key: string | null, filters?: AnyLiteralObject): void => {
    if (!key) {
      setCurrentFilters(undefined);
      return;
    }

    setCurrentFilters({ ...currentFilters, [key]: filters || undefined });
  };

  return {
    currentFilters,
    setFilters,
  };
};
