import React, { FC } from 'react';

import { Chip, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { ReportListingStatus } from '../../../../models/report-listing.model';
import { colors } from '../../../../theme/colors';

export type ReportedStatusBadgeProps = {
  status: string;
};

const useStyles = makeStyles(() => ({
  waitingChip: {
    backgroundColor: colors.appListingReportStatus.waiting.main,
    color: colors.appListingReportStatus.waiting.contrastText,
  },
  doneChip: {
    backgroundColor: colors.appListingReportStatus.done.main,
    color: colors.appListingReportStatus.done.contrastText,
  },
  markAsDuplicatedChip: {
    backgroundColor: colors.appListingReportStatus.markAsDuplicated.main,
    color: colors.appListingReportStatus.markAsDuplicated.contrastText,
  },
  ignoreChip: {
    backgroundColor: colors.appListingReportStatus.ignore.main,
    color: colors.appListingReportStatus.ignore.contrastText,
  },
}));

const ReportedStatusBadge: FC<ReportedStatusBadgeProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { status } = props;

  return (
    <Chip
      label={t(`report-listing:common.reportStatus.${status}`).toString()}
      className={clsx({
        [classes.waitingChip]: status === ReportListingStatus.Waiting,
        [classes.doneChip]: status === ReportListingStatus.Done,
        [classes.markAsDuplicatedChip]: status === ReportListingStatus.DuplicateReport,
        [classes.ignoreChip]: status === ReportListingStatus.Ignore,
      })}
    />
  );
};

export default ReportedStatusBadge;
