import { CustomerContact, CustomerContactFormData } from './customer-contact.model';

export enum FinanceType {
  Trade = 'trade',
  ReFinance = 'refinance',
  Pawn = 'pawn',
}

export type ItemDetail = {
  urlLink?: string;
  type?: string;
  rootCategory?: string;
  subCategory?: string;
  brand?: string;
  model?: string;
  productionYear?: string;
  licensePlate?: string;
  mileage?: string;
  province?: string;
  remark?: string;
};

export type ParsedJsonDetail = {
  itemCategoryType?: string;
  financeType?: string;
  financeCompany?: string;
  remark?: string;
  item?: ItemDetail;
};

export type UserContact = {
  id: number;
} & CustomerContactFormData;

export type KanbanCardFormData = {
  userHashId?: string;
  uid?: string;
  laneId?: number;
  title?: string;
  detail?: string;
  dueDate?: string;
  itemHashId?: string;
  imageUrls?: [string];
  modelUid?: string;
  brandUid?: string;
  assignees?: string;
  reporters?: string;
  price?: number;
  marginPrice?: number;
  isDone?: boolean;
  userContact?: Partial<CustomerContact>;
  comments?: string;
  contactUserId?: number;
  jsonDetail?: string;
  parsedJsonDetail?: ParsedJsonDetail;
};

export type KanbanCardData = {
  board?: string;
  boardSlug?: string;
  boardId?: number;
  status?: string;
} & KanbanCardFormData;

export type KanbanCard = {
  id?: number;
  uid?: string;
  createdAt: string;
  updatedAt: string;
} & KanbanCardData;
