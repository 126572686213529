import { doc, getDoc, getDocFromCache } from 'firebase/firestore';

import { firestoreDb, FS_DB_COLLECTION } from '../../../helpers/firebase';
import { MessageUser } from '../../../models/message-user';
import { messageUserConverter } from '../../messenger/user/create';

const getFirestoreUserByHashId = async (hashId: string): Promise<MessageUser | undefined> =>
  new Promise((resolve) => {
    const userDoc = doc(firestoreDb, FS_DB_COLLECTION.users, hashId);
    getDocFromCache(userDoc.withConverter(messageUserConverter))
      .then((userDocResult) => {
        resolve(userDocResult.data());
      })
      .catch(() => {
        getDoc(userDoc.withConverter(messageUserConverter))
          .then(async (userDocResult) => {
            resolve(userDocResult.data());
          })
          .catch(() => {
            resolve(undefined);
          });
      });
  });

export default getFirestoreUserByHashId;
