import React, { FC, useContext, useRef, useState } from 'react';

import { Box, Container, TableRow, TextField, Typography } from '@material-ui/core';
import clsx from 'clsx';
import useDeepCompareEffect from 'use-deep-compare-effect';

import ItemRowBlogSlug from '../../components/partials/blog-slug/ItemRowBlogSlug';
import AppButton from '../../components/ui/AppButton';
import AppTableCell from '../../components/ui/AppTableCell';
import AppTableList from '../../components/ui/AppTableList';
import { AppGlobalUiContext } from '../../context/AppGlobalUiContext';
import { AppTableConditions } from '../../models';
import {
  deleteBlogSlugById,
  getAllBlogSlug,
  ItemBlogSlugResponse,
  addBlogSlugs,
  UpdateBlogSlugRequestData,
  editBlogSlugById,
} from '../../services/blog-slug';
import useAppContainerStyles from '../../theme/container.style';
import useAppTableStyles from '../../theme/table.style';

const BlogSlugListPage: FC = () => {
  const { showSnackbar, setAppLoading } = useContext(AppGlobalUiContext);

  const [blogSlugList, setBlogSlugList] = useState<ItemBlogSlugResponse[]>([]);
  const [totalElements, setTotalElements] = useState<number>(0);

  const [currentUidInEditMode, setCurrentUidInEditMode] = useState<number | null>(null);

  const defaultTableConditions: AppTableConditions = {
    page: 0,
    rowsPerPage: 20,
    sortColumn: 'updatedAt',
    sortDirection: 'desc',
  };

  const [tableConditions, setTableConditions] = useState<AppTableConditions>(defaultTableConditions);

  const containerClasses = useAppContainerStyles();
  const tableClasses = useAppTableStyles();

  const isInEditMode = currentUidInEditMode !== null;

  const inputCategorySlugRef = useRef<HTMLInputElement | null>(null);
  const inputBrandSlugRef = useRef<HTMLInputElement | null>(null);
  const inputModelSlugRef = useRef<HTMLInputElement | null>(null);
  const inputBlogSlugRef = useRef<HTMLInputElement | null>(null);

  const requestBlogSlugList = async (isPageSubscribed = true): Promise<boolean> => {
    if (isPageSubscribed) {
      setAppLoading(true);

      const result = await getAllBlogSlug({
        page: tableConditions.page,
        per_page: tableConditions.rowsPerPage,
        sort: tableConditions?.sortDirection
          ? `${tableConditions.sortColumn}|${(tableConditions.sortDirection || 'desc').toUpperCase()}`
          : '',
      });

      setAppLoading(false);

      if (result.data) {
        setBlogSlugList(result.data.brandModelBlogSlugs);
        setTotalElements(result.data.page.totalElements);
        return Promise.resolve(true);
      }

      showSnackbar(result.error, 'error');
      return Promise.reject(result.error);
    }

    return false;
  };

  const performUpdateBlogSlugSingleItem = async (
    mode: 'add' | 'edit',
    data: UpdateBlogSlugRequestData,
    id?: number,
  ) => {
    if (!data.categorySlug || !data.brandSlug || !data.slug) {
      showSnackbar('กรุณาระบุ Category slug, Brand slug, Blog slug', 'error');
    } else {
      setAppLoading(true);
      try {
        let result;
        if (mode === 'add') {
          result = await addBlogSlugs([data]);
        } else if (id) {
          result = await editBlogSlugById(id, data);
        } else {
          result = null;
        }
        if (result?.data) {
          requestBlogSlugList(true);
          if (inputCategorySlugRef.current && typeof inputCategorySlugRef.current.value !== 'undefined') {
            inputCategorySlugRef.current.value = '';
          }
          if (inputBrandSlugRef.current && typeof inputBrandSlugRef.current.value !== 'undefined') {
            inputBrandSlugRef.current.value = '';
          }
          if (inputModelSlugRef.current && typeof inputModelSlugRef.current.value !== 'undefined') {
            inputModelSlugRef.current.value = '';
          }
          if (inputBlogSlugRef.current && typeof inputBlogSlugRef.current.value !== 'undefined') {
            inputBlogSlugRef.current.value = '';
          }
          showSnackbar(`${mode} BlogSlug สำเร็จ`, 'success');
        } else if (result?.error) {
          showSnackbar(result?.error, 'error');
        }
      } finally {
        setCurrentUidInEditMode(null);
        setAppLoading(false);
      }
    }
  };

  const performDeleteBlogSlugByUid = async (uid: number) => {
    setAppLoading(true);
    try {
      const result = await deleteBlogSlugById(uid);
      if (result.data === true) {
        requestBlogSlugList(true);
        showSnackbar('DeleteBlogSlug สำเร็จ', 'success');
      } else if (result.error) {
        showSnackbar(result.error, 'error');
      }
    } finally {
      setAppLoading(false);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useDeepCompareEffect((): any => {
    let isSubscribed = true;

    requestBlogSlugList(isSubscribed);

    return () => (isSubscribed = false);
  }, [tableConditions]);

  const tableConditionsChangedHandler = (values: AppTableConditions) => {
    setTableConditions(values);
  };

  return (
    <Container className={containerClasses.container}>
      <Box className={tableClasses.tableContainer}>
        <AppTableList
          {...tableConditions}
          columns={[
            {
              label: 'Root Category slug',
              name: 'root-category-slug',
            },
            {
              label: 'Brand slug',
              name: 'brand-slug',
            },
            {
              label: 'Model slug',
              name: 'model-slug',
            },
            {
              label: 'Blog slug',
              name: 'blog-slug',
            },
            {
              label: '',
              name: 'button-edit',
            },
          ]}
          totalDataCount={totalElements}
          hasPagination
          onTableConditionChanged={tableConditionsChangedHandler}
          isLoading={false}
          isFixedHeightAuto
          tableBodyContent={
            <>
              <TableRow
                key="create-item"
                classes={{ root: clsx(tableClasses.tableRow) }}
                style={{ opacity: isInEditMode ? 0.4 : 1, pointerEvents: isInEditMode ? 'none' : 'auto' }}>
                <AppTableCell>
                  <Typography variant="subtitle2">ระบุ Category slug</Typography>
                  <TextField
                    inputRef={inputCategorySlugRef}
                    variant="outlined"
                    placeholder="เช่น truck"
                    style={{ marginTop: '4px', backgroundColor: '#fff' }}
                  />
                </AppTableCell>
                <AppTableCell>
                  <Typography variant="subtitle2">ระบุ Brand slug</Typography>
                  <TextField
                    inputRef={inputBrandSlugRef}
                    variant="outlined"
                    placeholder="เช่น brand-1-isuzu"
                    style={{ marginTop: '4px', backgroundColor: '#fff' }}
                  />
                </AppTableCell>
                <AppTableCell>
                  <Typography variant="subtitle2">ระบุ Model slug</Typography>
                  <TextField
                    inputRef={inputModelSlugRef}
                    variant="outlined"
                    placeholder="เช่น D-Max"
                    style={{ marginTop: '4px', backgroundColor: '#fff' }}
                  />
                </AppTableCell>
                <AppTableCell>
                  <Typography variant="subtitle2">ระบุ Blog slug จาก WordPress</Typography>
                  <TextField
                    inputRef={inputBlogSlugRef}
                    variant="outlined"
                    placeholder="เช่น truck-isuzu"
                    style={{ marginTop: '4px', backgroundColor: '#fff' }}
                  />
                </AppTableCell>
                <AppTableCell align="right" style={{ height: '69.1px' }}>
                  <AppButton
                    color="primary"
                    onClick={() =>
                      performUpdateBlogSlugSingleItem('add', {
                        slug: inputBlogSlugRef.current?.value?.toString()?.trim() || null,
                        categorySlug: inputCategorySlugRef.current?.value?.toString()?.trim() || null,
                        brandSlug: inputBrandSlugRef.current?.value?.toString()?.trim() || null,
                        modelSlug: inputModelSlugRef.current?.value?.toString()?.trim() || null,
                      })
                    }
                    disabled={isInEditMode}
                    style={{ marginLeft: '6px' }}>
                    Add New
                  </AppButton>
                </AppTableCell>
              </TableRow>
              {blogSlugList.map((itemRow) => {
                const isCurrentItemInEditMode = currentUidInEditMode === itemRow.id;
                return (
                  <ItemRowBlogSlug
                    key={itemRow.id}
                    classes={{ root: clsx(tableClasses.tableRow) }}
                    itemRow={itemRow}
                    isCurrentItemInEditMode={isCurrentItemInEditMode}
                    isInEditMode={isInEditMode}
                    onClickEdit={(uid) => setCurrentUidInEditMode(uid)}
                    onClickCancel={() => setCurrentUidInEditMode(null)}
                    onClickSave={(data) => {
                      performUpdateBlogSlugSingleItem('edit', data, itemRow.id);
                    }}
                    onClickDelete={(uid) => performDeleteBlogSlugByUid(uid)}
                  />
                );
              })}
            </>
          }
        />
      </Box>
    </Container>
  );
};

export default BlogSlugListPage;
