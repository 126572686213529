import React, { PropsWithChildren, ReactElement, useEffect, useState } from 'react';

import { Box, Card, makeStyles, Typography } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';
import clsx from 'clsx';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import { reorderDragDropItems } from '../../../../../helpers/utils';
import { CategoryBreadcrumb, RefMasterIndex } from '../../../../../models';
import useTypographyStyles from '../../../../../theme/typography.style';
import AppButton from '../../../../ui/AppButton';
import AppDialog from '../../../../ui/AppDialog';
import RefMasterIndexSelector, { RefMasterIndexSelectorProps } from '../RefMasterIndexSelector';

export type BreadcrumbEditorProps = {
  data: CategoryBreadcrumb[];
  onChange?: (listData: CategoryBreadcrumb[]) => void;
};

const useStyles = makeStyles((theme) => ({
  droppableArea: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexFlow: 'row nowrap',
    borderRadius: 4,
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(1),
    overflow: 'hidden',
    overflowX: 'auto',
  },
  draggableItemContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexFlow: 'row nowrap',
    flexBasis: 200,
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up('lg')]: {
      flexBasis: '25%',
    },
    '&:focus-visible': {
      outline: 'none',
    },
  },
  draggableCard: {
    marginRight: theme.spacing(1),
    width: '100%',
    minWidth: 200,
    padding: theme.spacing(1, 2),
    '& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]':
      {
        paddingRight: theme.spacing(1),
      },
  },
  newItemDraggableCard: {
    boxShadow: 'none',
    cursor: 'pointer',
    padding: 0,
    marginRight: 0,
    marginBottom: 0,
    maxWidth: 200,
    backgroundColor: theme.palette.grey[100],
    '& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]':
      {
        backgroundColor: theme.palette.background.paper,
      },
  },
  draggingPlaceholder: {
    borderWidth: 2,
    borderStyle: 'dashed',
    borderColor: theme.palette.primary.main,
  },
}));

const MasterIndexSelector = (
  props: RefMasterIndexSelectorProps & {
    isNewItem?: boolean;
  },
) => {
  const { isNewItem, ...rest } = props;
  const classes = useStyles();
  return (
    <>
      <Card
        className={clsx(classes.draggableCard, {
          [classes.newItemDraggableCard]: isNewItem,
        })}>
        <RefMasterIndexSelector {...rest} />
      </Card>
    </>
  );
};

const BreadcrumbEditor = (props: PropsWithChildren<BreadcrumbEditorProps>): ReactElement => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { data, onChange } = props;
  const [listData, setListData] = useState<CategoryBreadcrumb[]>(data);
  const typoClasses = useTypographyStyles();
  const [isHelpDialogOpen, setIsHelpDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange(listData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listData]);

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorderDragDropItems<CategoryBreadcrumb>(listData, result.source.index, result.destination.index);

    setListData(items);
  };

  const onItemSelectionChanged = (item: RefMasterIndex | undefined, index: number) => {
    // New item
    if (index === -1 && item) {
      setListData([
        ...listData,
        {
          masterIndex: item,
        },
      ]);
    }

    if (index > -1) {
      if (!item) {
        listData.splice(index, 1);
      } else {
        listData[index] = {
          masterIndex: item,
        };
      }
      setListData([...listData]);
    }
  };

  return (
    <>
      <Box display="flex" justifyContent="flex-end" mb={1}>
        <AppButton
          color="info"
          variant="text"
          startIcon={<EmojiObjectsOutlinedIcon fontSize="small" />}
          size="small"
          onClick={() => setIsHelpDialogOpen(true)}>
          {t('common:dialog.title.help')}
        </AppButton>
      </Box>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className={clsx(classes.droppableArea, {
                [classes.draggingPlaceholder]: snapshot.isDraggingOver,
              })}>
              {listData.length > 0 &&
                listData.map((item: CategoryBreadcrumb, index: number) => {
                  const itemKey = `item-${index}`;
                  return (
                    <Draggable key={itemKey} draggableId={index.toString()} index={index}>
                      {(providedItem) => (
                        <div
                          ref={providedItem.innerRef}
                          {...providedItem.draggableProps}
                          {...providedItem.dragHandleProps}
                          className={classes.draggableItemContainer}>
                          <MasterIndexSelector
                            selectedMasterIndex={item.masterIndex}
                            onItemChange={(value: RefMasterIndex | undefined) => onItemSelectionChanged(value, index)}
                          />
                          <ArrowForwardIosIcon fontSize="small" className={typoClasses.greyText} />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
              {!snapshot.isDraggingOver && (
                <div className={clsx(classes.draggableItemContainer)}>
                  <MasterIndexSelector
                    isNewItem
                    selectedMasterIndex={undefined}
                    onItemChange={(value: RefMasterIndex | undefined) => onItemSelectionChanged(value, -1)}
                  />
                </div>
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <AppDialog
        open={isHelpDialogOpen}
        title="common:dialog.title.help"
        okButtonText="common:button.close"
        okButtonColor="default"
        okButtonVariant="outlined"
        onOkClick={() => setIsHelpDialogOpen(false)}>
        <Typography>{t('master-data:form.category.breadcrumbHelpText')}</Typography>
      </AppDialog>
    </>
  );
};

export default BreadcrumbEditor;
