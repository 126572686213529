import React, { FC, useState } from 'react';

import { Box, Grid, InputLabel, MenuItem, OutlinedInput, Select, Typography, makeStyles } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { formatDate } from '../../../../../helpers/date';
import usePrivilege from '../../../../../hooks/usePrivilege';
import { LoginBonus, LoginBonusFormDataRequest } from '../../../../../models/login-bonus.model';
import AppButton from '../../../../ui/AppButton';
import AppDialog from '../../../../ui/AppDialog';
import AppFormControl from '../../../../ui/AppFormControl';

export type LoginBonusItemDialogProps = {
  title: string;
  loginBonusItem: LoginBonus;
  open: boolean;
  onClose: () => void;
  onDialogExited?: () => void;
  onCloseClick?: () => void;
  onFormSubmit: (data: LoginBonusFormDataRequest) => void;
  isExpire: boolean;
};

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  numberInput: {
    MozAppearance: 'none',
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
  confirmDialogBox: {
    width: '100%',
    maxWidth: '1000px',
    [theme.breakpoints.up('md')]: {
      minWidth: '400px',
    },
  },
  buttonGroups: {
    '& > *': {
      margin: `0 ${theme.spacing(1)}px`,
    },
  },
  widthGrid: {
    width: '20%',
    [theme.breakpoints.up('md')]: {
      width: '10%',
    },
  },
}));

const LoginBonusItemDialog: FC<LoginBonusItemDialogProps> = (props) => {
  const classes = useStyles();
  const { title, loginBonusItem, isExpire, open, onClose, onDialogExited, onCloseClick, onFormSubmit } = props;
  const { t } = useTranslation();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);
  const [dayValue, setDayValue] = useState<number>(loginBonusItem.dayRewards.length || 3);
  const { canPerform } = usePrivilege();
  const allowEdit = canPerform('loginBonus', 'update');

  const {
    handleSubmit,
    getValues,
    setValue,
    control,
    formState: { errors },
  } = useForm<LoginBonusFormDataRequest>({
    mode: 'onChange',
    defaultValues: {
      title: loginBonusItem.title || '',
      startDate: loginBonusItem.startDate || null,
      endDate: loginBonusItem.endDate || null,
      period: loginBonusItem.dayRewards.length || 0,
      day1: loginBonusItem.dayRewards[0] || 0,
      day2: loginBonusItem.dayRewards[1] || 0,
      day3: loginBonusItem.dayRewards[2] || 0,
      day4: loginBonusItem.dayRewards[3] || 0,
      day5: loginBonusItem.dayRewards[4] || 0,
      day6: loginBonusItem.dayRewards[5] || 0,
      day7: loginBonusItem.dayRewards[6] || 0,
      day8: loginBonusItem.dayRewards[7] || 0,
      day9: loginBonusItem.dayRewards[8] || 0,
      day10: loginBonusItem.dayRewards[9] || 0,
    },
  });

  const onSaveClick = () => {
    setIsConfirmDialogOpen(true);
  };

  const onConfirmationSaveClick = () => {
    setIsConfirmDialogOpen(false);

    if (typeof onFormSubmit === 'function') {
      onFormSubmit(getValues());
    }
  };

  const onConfirmationCancelClick = () => {
    setIsConfirmDialogOpen(false);
  };

  return (
    <>
      <AppDialog
        open={open}
        title={title}
        onClose={onClose}
        okButtonText="common:button.close"
        okButtonColor="default"
        okButtonVariant="outlined"
        closeOnOkClicked
        noActionBar
        dialogProps={{
          disableBackdropClick: true,
          fullWidth: true,
          maxWidth: 'md',
          TransitionProps: {
            onExited: () => {
              if (typeof onDialogExited === 'function') {
                onDialogExited();
              }
            },
          },
        }}>
        <form onSubmit={handleSubmit(onSaveClick)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="title"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <AppFormControl>
                    <InputLabel htmlFor="login-bonus-title">
                      {t('daily-challenge:loginBonus.details.label.campaignName')}
                    </InputLabel>
                    <OutlinedInput
                      {...field}
                      id="login-bonus-title"
                      fullWidth
                      error={!!errors.title}
                      disabled={!allowEdit}
                    />
                  </AppFormControl>
                )}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <Typography variant="subtitle2" style={{ marginBottom: '6px' }}>
                {t('daily-challenge:loginBonus.details.label.startDateAt')}
              </Typography>
              <Controller
                name="startDate"
                control={control}
                defaultValue={loginBonusItem.startDate}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <AppFormControl error={!!errors.startDate}>
                    <OutlinedInput
                      id="login-bonus-start-date-at"
                      fullWidth
                      type="datetime-local"
                      defaultValue={field.value ? formatDate(new Date(field.value), 'YYYY-MM-dd hh:mm') : null}
                      onChange={field.onChange}
                      error={!!errors.startDate}
                      disabled={!allowEdit}
                    />
                  </AppFormControl>
                )}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <Typography variant="subtitle2" style={{ marginBottom: '6px' }}>
                {t('daily-challenge:loginBonus.details.label.endDateAt')}
              </Typography>
              <Controller
                name="endDate"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <AppFormControl error={!!errors.endDate}>
                    <OutlinedInput
                      id="login-bonus-end-date-at"
                      fullWidth
                      type="datetime-local"
                      defaultValue={field.value ? formatDate(new Date(field.value), 'YYYY-MM-dd hh:mm') : null}
                      onChange={field.onChange}
                      error={!!errors.endDate}
                      disabled={!allowEdit}
                    />
                  </AppFormControl>
                )}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={2} style={{ marginTop: '28px' }}>
              <Controller
                name="period"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <AppFormControl>
                    <InputLabel htmlFor="login-bonus-days">
                      {t('daily-challenge:loginBonus.details.label.days')}
                    </InputLabel>
                    <Select
                      id="login-bonus-days"
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setDayValue(Number(e.target.value) || 0);
                        setValue('day1', 0);
                        setValue('day2', 0);
                        setValue('day3', 0);
                        setValue('day4', 0);
                        setValue('day5', 0);
                        setValue('day6', 0);
                        setValue('day7', 0);
                        setValue('day8', 0);
                        setValue('day9', 0);
                        setValue('day10', 0);
                      }}
                      error={!!errors.period}
                      disabled={!allowEdit}
                      fullWidth>
                      <MenuItem value="3">3 วัน</MenuItem>
                      <MenuItem value="5">5 วัน</MenuItem>
                      <MenuItem value="7">7 วัน</MenuItem>
                      <MenuItem value="10">10 วัน</MenuItem>
                    </Select>
                  </AppFormControl>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle2" style={{ marginBottom: '6px' }}>
                {t('daily-challenge:loginBonus.details.label.coins')}
              </Typography>
              <Grid container spacing={1}>
                {Array(dayValue)
                  .fill(null)
                  .map((_item, index: number) => {
                    const itemKey = `day-${index}`;
                    return (
                      <Grid item sm="auto" className={classes.widthGrid} key={itemKey}>
                        <Controller
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          name={`day${index + 1}` as any}
                          control={control}
                          rules={{
                            required: true,
                          }}
                          render={({ field }) => (
                            <AppFormControl>
                              <OutlinedInput
                                {...field}
                                inputProps={{ min: '1', step: 'any' }}
                                name={`day${index + 1}`}
                                margin="dense"
                                type="number"
                                disabled={!allowEdit}
                              />
                              <Typography variant="subtitle2" style={{ textAlign: 'center', marginTop: '6px' }}>
                                {`วันที่ ${index + 1}`}
                              </Typography>
                            </AppFormControl>
                          )}
                        />
                      </Grid>
                    );
                  })}
              </Grid>
            </Grid>
          </Grid>

          <Box display="flex" my={2} justifyContent="flex-end" className={classes.buttonGroups}>
            <AppButton
              type="submit"
              color="primary"
              disabled={!allowEdit || loginBonusItem.deletedAt !== undefined || isExpire}>
              {t('common:button.save')}
            </AppButton>
            <AppButton
              variant="outlined"
              color="default"
              onClick={() => {
                if (typeof onCloseClick === 'function') {
                  onCloseClick();
                }
              }}>
              {t('common:button.close')}
            </AppButton>
          </Box>
        </form>

        <AppDialog
          title={t('common:dialog.title.confirm').toString()}
          open={isConfirmDialogOpen}
          onOkClick={onConfirmationSaveClick}
          onCancelClick={onConfirmationCancelClick}
          cancelButtonText={t('common:button.cancel').toString()}
          okButtonText={t('common:button.save').toString()}
          okButtonColor="primary"
          dialogProps={{
            maxWidth: 'md',
          }}>
          <Box className={classes.confirmDialogBox}>
            <Typography>{t('daily-challenge:loginBonus.dialogEditLoginBonus.title')}</Typography>
          </Box>
        </AppDialog>
      </AppDialog>
    </>
  );
};

export default LoginBonusItemDialog;
