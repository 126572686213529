import React, { FC, useCallback, useContext } from 'react';

import { Box, DialogActions, Typography } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AppGlobalUiContext } from '../../../../context/AppGlobalUiContext';
import { uploadWeeklySellerReportFile } from '../../../../services/uploadFileWeeklySellerReport';
import AppButton from '../../../ui/AppButton';
import { useStyles } from '../../../ui/AppDialog';
import AppFormControl from '../../../ui/AppFormControl';

type ModelUploadFileWeeklySellerReportProps = {
  onClose: () => void;
};

const ModelUploadFileWeeklySellerReport: FC<ModelUploadFileWeeklySellerReportProps> = (props) => {
  const { onClose } = props;

  const { t } = useTranslation();
  const classes = useStyles();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{ sellerReportFile: File }>({
    mode: 'onSubmit',
  });

  const { showSnackbar, setAppLoading } = useContext(AppGlobalUiContext);

  const onOkClickHandle = useCallback(async (form) => {
    setAppLoading(true);

    const result = await uploadWeeklySellerReportFile(form.sellerReportFile[0]);
    if (result) {
      if (result.data) {
        showSnackbar(t('master-data:error.uploadFileWeeklySellerReport.dataUploadSuccess').toString(), 'success');
        onClose();
      } else {
        showSnackbar(t('master-data:error.uploadFileWeeklySellerReport.dataUploadFailed').toString(), 'error');
      }
    }
    setAppLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={handleSubmit(onOkClickHandle)}>
      <AppFormControl>
        <Controller
          name="sellerReportFile"
          rules={{
            required: `${t('validation:invalidFileUpload')}`,
          }}
          control={control}
          render={({ field }) => (
            <Box>
              <input
                type="file"
                ref={field.ref}
                accept=".csv"
                onChange={(e) => {
                  field.onChange(e.target.files);
                }}
              />
              {!!errors.sellerReportFile?.message && (
                <Typography color="error">{errors.sellerReportFile?.message}</Typography>
              )}
            </Box>
          )}
        />
      </AppFormControl>
      <hr />

      <DialogActions className={classes.dialogAction}>
        <AppButton onClick={onClose} variant="text" color="default">
          {t('common:button.cancel')}
        </AppButton>
        <AppButton type="submit" variant="contained" color="primary">
          {t('common:button.ok')}
        </AppButton>
      </DialogActions>
    </form>
  );
};

export default ModelUploadFileWeeklySellerReport;
