import React, { FC, useCallback, useState } from 'react';

import {
  Box,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  Typography,
  makeStyles,
} from '@material-ui/core';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';
import { Controller, UseControllerProps, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { formatDate } from '../../../../../helpers/date';
import usePrivilege from '../../../../../hooks/usePrivilege';
import { UserTCImage } from '../../../../../models';
import { Product, ProductFormDataRequest, ProductImage, PurchaseTypeTC } from '../../../../../models/product.model';
import AppButton from '../../../../ui/AppButton';
import AppDialog from '../../../../ui/AppDialog';
import AppFormControl from '../../../../ui/AppFormControl';
import AppFormErrorMessage from '../../../../ui/AppFormErrorMessage';
import AppTCFileSelector from '../../../../ui/AppTCFileSelector';

export type ProductItemDialogProps = {
  productItem: Product;
  title: string;
  open: boolean;
  typeCoin: boolean;
  typeProduct: string;
  onClose: () => void;
  onDialogExited?: () => void;
  onCloseClick?: () => void;
  onFormSubmit: (data: ProductFormDataRequest) => void;
  rules?: { [x: string]: UseControllerProps['rules'] };
};

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  numberInput: {
    MozAppearance: 'none',
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
  confirmDialogBox: {
    width: '100%',
    maxWidth: '1000px',
    [theme.breakpoints.up('md')]: {
      minWidth: '400px',
    },
  },
  buttonGroups: {
    '& > *': {
      margin: `0 ${theme.spacing(1)}px`,
    },
  },
}));

const ProductItemDialog: FC<ProductItemDialogProps> = (props) => {
  const classes = useStyles();
  const {
    productItem,
    open,
    title,
    typeCoin,
    typeProduct,
    rules,
    onClose,
    onDialogExited,
    onCloseClick,
    onFormSubmit,
  } = props;
  const { t } = useTranslation();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);

  const { canPerform } = usePrivilege();
  const allowEdit = canPerform('product', 'update');

  const [isDescriptionExampleDialogOpen, setIsDescriptionExampleDialogOpen] = useState(false);

  const getRules = (fieldName: string): UseControllerProps['rules'] | undefined =>
    rules ? rules[fieldName] : undefined;

  const productDescription: string = productItem.description ? JSON.parse(productItem.description).badges || '' : '';

  const {
    handleSubmit,
    getValues,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm<ProductFormDataRequest>({
    mode: 'onChange',
    defaultValues: {
      name: productItem.name || '',
      amount: productItem.amount || 0,
      title: productItem.title || '',
      description:
        typeof productDescription === 'object'
          ? JSON.parse(productItem.description).badges.join('|')
          : productDescription,

      subTitle: productItem.description ? JSON.parse(productItem.description).subtitle : '',
      isActive: productItem.isActive || false,
      availableFrom: productItem.availableFrom || null,
      availableTo: productItem.availableTo || null,
      sku: productItem.sku || '',
      price: productItem.price || 0,
      image: productItem.image || undefined,
      imageFileHashId: productItem.image?.fileHashId || '',
      imageFullUrl: productItem.image?.fullUrl || '',
      currency: productItem.currency || 'THB',
      productUnit: productItem.productUnit || '',
      membershipType: productItem.membershipType || '',
      freeCoin: productItem.freeCoin || 0,
    },
  });

  const onSaveClick = () => {
    setIsConfirmDialogOpen(true);
  };

  const onConfirmationSaveClick = () => {
    setIsConfirmDialogOpen(false);

    if (typeof onFormSubmit === 'function') {
      onFormSubmit(getValues());
    }
  };

  const onConfirmationCancelClick = () => {
    setIsConfirmDialogOpen(false);
  };

  const skuFieldInput = useCallback(() => {
    const type = watch('membershipType');
    const amount = watch('amount');

    if (typeProduct === PurchaseTypeTC.MemberOnce) {
      const productUnit = watch('productUnit');
      if (type && amount && productUnit) {
        const skuValue = `${'member-once'}-${type.toLowerCase()}-${amount}${productUnit[0].toLowerCase()}`;
        setValue('sku', skuValue);
      }
    } else if (typeProduct === PurchaseTypeTC.MemberSubscription) {
      if (type) {
        const skuValue = `${'member-subscr'}-${type.toLowerCase()}`;
        setValue('sku', skuValue);
      }
    } else {
      const skuValue = `${'coin'}-${amount}`;
      setValue('sku', skuValue);
    }
  }, [setValue, typeProduct, watch]);

  return (
    <>
      <AppDialog
        open={open}
        title={title}
        onClose={onClose}
        okButtonText="common:button.close"
        okButtonColor="default"
        okButtonVariant="outlined"
        closeOnOkClicked
        noActionBar
        dialogProps={{
          disableBackdropClick: true,
          fullWidth: true,
          maxWidth: 'md',
          TransitionProps: {
            onExited: () => {
              if (typeof onDialogExited === 'function') {
                onDialogExited();
              }
            },
          },
        }}>
        <form onSubmit={handleSubmit(onSaveClick)}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Box mb={3}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="title"
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field }) => (
                        <AppFormControl>
                          <InputLabel htmlFor="product-title">{t('product:details.label.title')}</InputLabel>
                          <OutlinedInput
                            {...field}
                            id="product-title"
                            fullWidth
                            error={!!errors.title}
                            disabled={!allowEdit}
                          />
                        </AppFormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="name"
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={(data) => (
                        <AppFormControl error={!!errors.name}>
                          <InputLabel htmlFor="product-name">{t('product:details.label.name')}</InputLabel>
                          <Box style={{ position: 'relative' }}>
                            <OutlinedInput
                              {...data.field}
                              id="product-name"
                              fullWidth
                              error={!!errors.name}
                              disabled={!allowEdit}
                            />
                          </Box>
                        </AppFormControl>
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box mb={3}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} style={{ position: 'relative' }}>
                    <Box style={{ position: 'absolute', right: '4px', top: '8px', zIndex: '2' }}>
                      <AppButton
                        color="primary"
                        variant="text"
                        style={{ marginLeft: 8, minWidth: 'auto' }}
                        startIcon={<EmojiObjectsOutlinedIcon />}
                        onClick={() => setIsDescriptionExampleDialogOpen(true)}
                      />
                    </Box>
                    <Controller
                      name="description"
                      control={control}
                      rules={{
                        required: false,
                      }}
                      render={({ field }) => (
                        <AppFormControl>
                          <InputLabel htmlFor="product-description">
                            {t('product:details.label.description')}
                          </InputLabel>
                          <OutlinedInput
                            {...field}
                            multiline
                            rows={1}
                            id="product-description"
                            fullWidth
                            error={!!errors.description}
                            disabled={!allowEdit}
                          />
                        </AppFormControl>
                      )}
                    />
                    <Typography variant="subtitle2" style={{ marginTop: '4px' }}>
                      {t('product:details.label.descriptionRemark1')}
                    </Typography>
                    <Typography variant="subtitle2">{t('product:details.label.descriptionRemark2')}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="subTitle"
                      control={control}
                      rules={{
                        required: false,
                      }}
                      render={({ field }) => (
                        <AppFormControl>
                          <InputLabel htmlFor="product-subTitle">{t('product:details.label.subTitle')}</InputLabel>
                          <OutlinedInput
                            {...field}
                            id="product-subTitle"
                            fullWidth
                            error={!!errors.subTitle}
                            disabled={!allowEdit}
                          />
                        </AppFormControl>
                      )}
                    />
                    <Typography variant="subtitle2" style={{ marginTop: '4px' }}>
                      {t('product:details.label.subTitle1')}
                    </Typography>
                    <Typography variant="subtitle2">{t('product:details.label.subTitle2')}</Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box mb={3}>
                <Grid container spacing={2}>
                  {!typeCoin && (
                    <Grid item xs={12} sm={4}>
                      <Controller
                        name="membershipType"
                        control={control}
                        rules={{
                          required: false,
                        }}
                        render={({ field }) => (
                          <AppFormControl error={!!errors.membershipType}>
                            <InputLabel htmlFor="product-membershipType">
                              {t('product:details.label.membershipType')}
                            </InputLabel>
                            <Select
                              id="product-membershipType"
                              value={field.value}
                              onChange={field.onChange}
                              error={!!errors.productUnit}
                              fullWidth>
                              <MenuItem value="BRONZE">เหรียญทองแดง</MenuItem>
                              <MenuItem value="SILVER">เหรียญเงิน</MenuItem>
                              <MenuItem value="GOLD">เหรียญทอง</MenuItem>
                            </Select>
                          </AppFormControl>
                        )}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="productUnit"
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field }) => (
                        <AppFormControl error={!!errors.productUnit}>
                          <InputLabel htmlFor="product-productUnit">
                            {t('product:details.label.productUnit')}
                          </InputLabel>
                          <Select
                            id="product-productUnit"
                            value={field.value}
                            onChange={field.onChange}
                            error={!!errors.productUnit}
                            fullWidth>
                            <MenuItem
                              value="UNIT"
                              disabled={!typeCoin || typeProduct === PurchaseTypeTC.MemberSubscription}>
                              Coin
                            </MenuItem>
                            <MenuItem
                              value="DAY"
                              disabled={typeCoin || typeProduct === PurchaseTypeTC.MemberSubscription}>
                              รายวัน
                            </MenuItem>
                            <MenuItem value="MONTH" disabled={typeCoin}>
                              รายเดือน
                            </MenuItem>
                            <MenuItem
                              value="YEAR"
                              disabled={typeCoin || typeProduct === PurchaseTypeTC.MemberSubscription}>
                              รายปี
                            </MenuItem>
                          </Select>
                        </AppFormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="amount"
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field }) => (
                        <AppFormControl>
                          <InputLabel htmlFor="product-amount">
                            {typeProduct === PurchaseTypeTC.Coin
                              ? t('product:details.label.amountCoin')
                              : t('product:details.label.amount')}
                          </InputLabel>
                          <OutlinedInput
                            type="number"
                            {...field}
                            inputProps={{ min: '0', step: 'any' }}
                            id="product-amount"
                            fullWidth
                            error={!!errors.amount}
                            onChange={(event) => field.onChange(+event.target.value)}
                            disabled={!allowEdit}
                          />
                        </AppFormControl>
                      )}
                    />
                  </Grid>

                  {typeCoin && (
                    <Grid item xs={12} sm={4}>
                      <Controller
                        name="price"
                        control={control}
                        rules={{
                          required: true,
                        }}
                        render={(data) => (
                          <AppFormControl error={!!errors.price}>
                            <InputLabel htmlFor="product-price">{t('product:details.label.price')}</InputLabel>
                            <Box style={{ position: 'relative' }}>
                              <OutlinedInput
                                type="number"
                                inputProps={{ min: '0', step: 'any' }}
                                {...data.field}
                                id="product-price"
                                fullWidth
                                error={!!errors.price}
                                onChange={(event) => data.field.onChange(+event.target.value)}
                                disabled={!allowEdit}
                              />
                            </Box>
                          </AppFormControl>
                        )}
                      />
                    </Grid>
                  )}
                </Grid>
              </Box>
              <Box mb={3}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={8}>
                    <Grid container spacing={2}>
                      {!typeCoin && (
                        <Grid item xs={12} sm={4}>
                          <Controller
                            name="price"
                            control={control}
                            rules={{
                              required: true,
                            }}
                            render={(data) => (
                              <AppFormControl error={!!errors.price}>
                                <InputLabel htmlFor="product-price">{t('product:details.label.price')}</InputLabel>
                                <Box style={{ position: 'relative' }}>
                                  <OutlinedInput
                                    type="number"
                                    inputProps={{ min: '0', step: 'any' }}
                                    {...data.field}
                                    id="product-price"
                                    fullWidth
                                    error={!!errors.price}
                                    onChange={(event) => data.field.onChange(+event.target.value)}
                                    disabled={!allowEdit}
                                  />
                                </Box>
                              </AppFormControl>
                            )}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12} sm={4}>
                        <Controller
                          name="freeCoin"
                          control={control}
                          rules={{
                            required: true,
                          }}
                          render={(data) => (
                            <AppFormControl error={!!errors.freeCoin}>
                              <InputLabel htmlFor="product-free-coin">{t('product:details.label.freeCoin')}</InputLabel>
                              <Box style={{ position: 'relative' }}>
                                <OutlinedInput
                                  type="number"
                                  {...data.field}
                                  inputProps={{ min: '0', step: 'any' }}
                                  id="product-free-coin"
                                  fullWidth
                                  error={!!errors.freeCoin}
                                  onChange={(event) => data.field.onChange(+event.target.value)}
                                  disabled={!allowEdit}
                                />
                              </Box>
                            </AppFormControl>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Controller
                          name="sku"
                          control={control}
                          rules={{
                            required: true,
                          }}
                          render={(data) => (
                            <AppFormControl error={!!errors.sku}>
                              <InputLabel htmlFor="product-sku">{t('product:details.label.sku')}</InputLabel>
                              <Box style={{ position: 'relative' }}>
                                <OutlinedInput
                                  {...data.field}
                                  id="product-sku"
                                  fullWidth
                                  error={!!errors.sku}
                                  disabled={!allowEdit}
                                  placeholder={
                                    typeCoin
                                      ? t('product:details.label.skuCoinFormat')
                                      : t('product:details.label.skuMemberShipFormat')
                                  }
                                  onFocus={skuFieldInput}
                                />
                              </Box>
                            </AppFormControl>
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Box mt={1}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                          <Controller
                            name="availableFrom"
                            control={control}
                            defaultValue={productItem.availableFrom}
                            rules={{
                              required: false,
                            }}
                            render={({ field }) => (
                              <AppFormControl boxProps={{ mb: 2 }} error={!!errors.availableFrom}>
                                <Box>
                                  <Typography>{t('product:details.label.availableFrom')}</Typography>
                                  <OutlinedInput
                                    id="product-availableFrom"
                                    fullWidth
                                    type="date"
                                    defaultValue={field.value ? formatDate(new Date(field.value), 'YYYY-MM-dd') : null}
                                    onChange={field.onChange}
                                    error={!!errors.availableFrom}
                                    disabled={!allowEdit}
                                  />
                                </Box>
                              </AppFormControl>
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Controller
                            name="availableTo"
                            control={control}
                            defaultValue={productItem.availableTo}
                            rules={{
                              required: false,
                            }}
                            render={({ field }) => (
                              <AppFormControl boxProps={{ mb: 2 }} error={!!errors.availableTo}>
                                <Box>
                                  <Typography>{t('product:details.label.availableTo')}</Typography>
                                  <OutlinedInput
                                    id="product-availableTo"
                                    fullWidth
                                    type="date"
                                    defaultValue={field.value ? formatDate(new Date(field.value), 'YYYY-MM-dd') : null}
                                    onChange={field.onChange}
                                    error={!!errors.availableTo}
                                    disabled={!allowEdit}
                                  />
                                </Box>
                              </AppFormControl>
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Controller
                            control={control}
                            name="isActive"
                            defaultValue={productItem.isActive}
                            render={({ field }) => (
                              <AppFormControl boxProps={{ mb: 3 }}>
                                <Typography>{t('product:details.label.isActive')}</Typography>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={field.value}
                                      onChange={field.onChange}
                                      color="primary"
                                      name="clientSideRedirect"
                                      inputProps={{ 'aria-label': 'client side redirection?' }}
                                      disabled={!allowEdit}
                                    />
                                  }
                                  label={t('common:button.apply').toString()}
                                />
                              </AppFormControl>
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="image"
                      control={control}
                      rules={getRules('image') || {}}
                      render={({ field }) => (
                        <AppFormControl error={!!errors.image}>
                          <AppTCFileSelector
                            defaultFile={field.value as ProductImage}
                            fileMode="image"
                            inputProps={{
                              label: t('product:details.label.image').toString(),
                              error: !!errors.image,
                            }}
                            onFilesSelected={(file: ProductImage) => {
                              if (file) {
                                field.onChange(file.fullUrl);
                                setValue('image', file);
                              }
                            }}
                            onValueCleared={() => field.onChange('')}
                            imageType={UserTCImage.product}
                          />
                          {!!errors.image && <AppFormErrorMessage errors={errors} name="image" />}
                        </AppFormControl>
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <Box display="flex" my={2} justifyContent="flex-end" className={classes.buttonGroups}>
            <AppButton type="submit" color="primary" disabled={!allowEdit}>
              {t('common:button.save')}
            </AppButton>
            <AppButton
              variant="outlined"
              color="default"
              onClick={() => {
                if (typeof onCloseClick === 'function') {
                  onCloseClick();
                }
              }}>
              {t('common:button.close')}
            </AppButton>
          </Box>
        </form>

        <AppDialog
          title={t('common:dialog.title.confirm').toString()}
          open={isConfirmDialogOpen}
          onOkClick={onConfirmationSaveClick}
          onCancelClick={onConfirmationCancelClick}
          cancelButtonText={t('common:button.cancel').toString()}
          okButtonText={t('common:button.save').toString()}
          okButtonColor="primary"
          dialogProps={{
            maxWidth: 'md',
          }}>
          <Box className={classes.confirmDialogBox}>
            <Typography>{t('product:dialogEdit.title')}</Typography>
          </Box>
        </AppDialog>

        <AppDialog
          type="tips"
          open={isDescriptionExampleDialogOpen}
          title="product:details.label.exampleDescription"
          okButtonText="common:button.close"
          okButtonColor="default"
          okButtonVariant="outlined"
          onOkClick={() => setIsDescriptionExampleDialogOpen(false)}>
          <Box textAlign="center">
            {typeCoin ? (
              <img
                src="../../static/images/image/example-description-coin.png"
                alt="example-description-coin"
                width="450px"
              />
            ) : (
              <img
                src="../../static/images/image/example-description-membership.png"
                alt="example-description-membership"
                width="550px"
              />
            )}
          </Box>
        </AppDialog>
      </AppDialog>
    </>
  );
};

export default ProductItemDialog;
