import React, { FC, useCallback, useContext, useState } from 'react';

import { Box, Container, Grid, TableRow, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import clsx from 'clsx';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import { AppGlobalUiContext } from '../../../../../context/AppGlobalUiContext';
import { getAppUrl } from '../../../../../helpers/utils';
import usePrivilege from '../../../../../hooks/usePrivilege';
import i18n from '../../../../../i18n';
import { AppTableConditions, TableColumn } from '../../../../../models';
import { AbTestingData, AbTestingFormDataRequest } from '../../../../../models/ab-testing.model';
import { updateAbTesting } from '../../../../../services/ab-testing';
import useAppContainerStyles from '../../../../../theme/container.style';
import useAppTableStyles from '../../../../../theme/table.style';
import AppButton from '../../../../ui/AppButton';
import AppTableCell from '../../../../ui/AppTableCell';
import AppTableList from '../../../../ui/AppTableList';
import AddFeatureTestingItemDialog from '../AddFeatureTestingItemDialog';

dayjs.extend(customParseFormat);

export type AbTestingListTableProps = {
  currentConditions: AppTableConditions;
  totalAbTestings: number;
  isLoadingData: boolean;
  abTestingList: AbTestingData[];
  onFetch: () => void;
  onTableConditionsChanged: (values: AppTableConditions) => void;
};

const abTestingTableColumns: TableColumn[] = [
  { name: 'feature', label: 'ab-testing:list.columns.feature', width: 200, unsortable: true },
  { name: 'totalUser', label: 'ab-testing:list.columns.totalUser', width: 200, unsortable: true },
  { name: 'createdAt', label: 'ab-testing:list.columns.createdAt', width: 200 },
  { name: 'detail', label: 'ab-testing:list.columns.manage', width: 100, unsortable: true },
];

const AbTestingListTable: FC<AbTestingListTableProps> = (props) => {
  const { t } = useTranslation();
  const { totalAbTestings, isLoadingData, currentConditions, abTestingList, onFetch, onTableConditionsChanged } = props;
  const containerClasses = useAppContainerStyles();
  const tableClasses = useAppTableStyles();
  const { url } = useRouteMatch();

  const dateDisplayFormat = i18n.language === 'th' ? 'D MMM BBBB / HH:mm:ss' : 'D MMM YYYY';

  const [tableConditions, setTableConditions] = useState<AppTableConditions>(currentConditions);
  const { showSnackbar, setAppLoading } = useContext(AppGlobalUiContext);

  const [isAddFeatureDialogOpen, setIsAddFeatureDialogOpen] = useState<boolean>(false);

  const { canPerform } = usePrivilege();
  const allowCreate = canPerform('abTesting', 'create');

  const onTableConditionChangedHandler = useCallback(
    (values: AppTableConditions) => {
      if (JSON.stringify(values) !== JSON.stringify(tableConditions)) {
        setTableConditions(values);
        onTableConditionsChanged(values);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableConditions],
  );

  const handleAddFeature = useCallback(async () => {
    setIsAddFeatureDialogOpen(true);
  }, []);

  const onSubmitAddNewFeature = useCallback(
    async (formData?: AbTestingFormDataRequest) => {
      if (formData) {
        setAppLoading(true);

        const newData = {
          features: formData.features.toString().split(','),
          userHashIds: formData.userHashIds.toString().split(','),
        };

        const result = await updateAbTesting(newData);
        if (result.data) {
          showSnackbar(t('ab-testing:message.addNewFeatureSuccess').toString(), 'success');
          setIsAddFeatureDialogOpen(false);
          onFetch();
        } else {
          showSnackbar(t('ab-testing:message.addNewFeatureFailed').toString(), 'error');
        }
        setAppLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setAppLoading, showSnackbar, t],
  );

  const openFeatureDetailPage = useCallback(
    (feature: AbTestingData) => {
      window.open(getAppUrl(`${url}/${feature.feature}`), '_blank');
    },
    [url],
  );

  return (
    <>
      <Container className={containerClasses.container}>
        <Box className={tableClasses.tableContainer}>
          <Box mb={3}>
            <Grid container spacing={2}>
              <Grid item sm={12} md={8}>
                <Box display="inline-block">
                  <AppButton
                    style={{ display: 'inline-block' }}
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={handleAddFeature}
                    disabled={!allowCreate}>
                    {t('ab-testing:button.addNewFeature')}
                  </AppButton>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                {!isLoadingData && (
                  <Box style={{ marginBottom: '8px', marginRight: '8px', textAlign: 'right' }}>
                    <Typography variant="subtitle2" color="textSecondary">
                      {t('common:list.resultTotal', { fieldName: t(totalAbTestings.toLocaleString() || '0') })}
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>

          <AppTableList
            {...tableConditions}
            aria-label="ab-testing-list"
            columns={abTestingTableColumns}
            totalDataCount={totalAbTestings}
            hasPagination
            onTableConditionChanged={onTableConditionChangedHandler}
            isLoading={isLoadingData}
            tableBodyContent={
              abTestingList.length > 0 && (
                <>
                  {abTestingList.map((item: AbTestingData) => (
                    <TableRow
                      hover
                      key={item.feature}
                      classes={{ root: clsx(tableClasses.tableRow, tableClasses.clickableTableRow) }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        openFeatureDetailPage(item);
                      }}>
                      <AppTableCell>{item.feature ? item.feature.toLocaleUpperCase() : '-'} </AppTableCell>
                      <AppTableCell>{item.totalUser ? item.totalUser.toLocaleString() : '-'}</AppTableCell>
                      <AppTableCell>{dayjs(item.createdAt).format(`${dateDisplayFormat}`)}</AppTableCell>
                      <AppTableCell>
                        <Typography variant="subtitle2">{t('ab-testing:list.columns.detail')}</Typography>
                      </AppTableCell>
                    </TableRow>
                  ))}
                </>
              )
            }
          />
        </Box>
      </Container>

      {isAddFeatureDialogOpen && (
        <AddFeatureTestingItemDialog
          title="เพิ่มฟีเจอร์ใหม่"
          open={isAddFeatureDialogOpen}
          onClose={() => {
            setIsAddFeatureDialogOpen(false);
          }}
          onCloseClick={() => {
            setIsAddFeatureDialogOpen(false);
          }}
          onFormSubmit={onSubmitAddNewFeature}
        />
      )}
    </>
  );
};

export default AbTestingListTable;
