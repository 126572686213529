import { apiTCRequest, apiTCRequestV2 } from '../../helpers/request';
import { queryParamGenerator } from '../../helpers/utils';
import {
  ApiResponse,
  GetInterviewLogTCResponse,
  GetUserListTCResponse,
  InterviewLogData,
  InterviewLogDataRequest,
  UserDataTC,
  UserEditApplicationAdditionalInfoRequest,
  UserEditApplicationBasicInfoRequest,
  UserEditApplicationBillingInfoRequest,
  UserEditApplicationPrivateInfoRequest,
  UserEditApplicationShopInfoRequest,
  UserSearchFiltersTC,
} from '../../models';

const getUserListQueryParam = (param?: UserSearchFiltersTC): string => {
  const reqParam: { [x: string]: string | number } = {
    page: param?.page || 0, // plus 1 for index shift between FE component and backend query
    per_page: param?.rowsPerPage || 10,
    sort: param?.sortColumn ? `id%7C${(param?.sortDirection || 'desc').toUpperCase()}` : '',
  };

  if (param?.searchKeyword && param?.searchField) {
    reqParam[param.searchField] = param.searchKeyword.trim();
  }

  if (param?.filteringStatus?.length) {
    reqParam.buyerSellerVerificationWorkflow = param.filteringStatus.join(',');
  }

  if (param?.filteringGeneralWorkflow?.length) {
    reqParam.userGeneralWorkflow = param.filteringGeneralWorkflow.join(',');
  }

  if (param?.filteringRoleStateWorkflow?.length) {
    reqParam.userRoleStateWorkflow = param.filteringRoleStateWorkflow.join(',');
  }

  if (param?.filteringNationality?.length) {
    reqParam.userNationality = param.filteringNationality.join(',');
  }

  if (param?.q) {
    reqParam.q = param.q;
  }

  return queryParamGenerator(reqParam);
};

export const getUserList = async (param?: UserSearchFiltersTC): Promise<ApiResponse<GetUserListTCResponse>> => {
  try {
    const queryParamString = getUserListQueryParam(param);
    const result = await apiTCRequest<GetUserListTCResponse>(`/admin/user/?${queryParamString}`);

    return {
      data: result.data ?? null,
    };
  } catch (e) {
    return {
      data: null,
      error: 'user:error.list.requestFailed',
    };
  }
};

export const getUserByHashIdTC = async (hashId: string): Promise<ApiResponse<UserDataTC>> => {
  try {
    const result = await apiTCRequest<UserDataTC>(`/admin/user/${hashId}`);

    return {
      data: result.data,
    };
  } catch (e) {
    return {
      data: null,
      error: 'seller-applications:error.detail.requestFailed',
    };
  }
};

export default async function userEditApplicationBasicInfo(
  userEditData: UserEditApplicationBasicInfoRequest,
): Promise<ApiResponse<boolean>> {
  const result = await apiTCRequestV2(`/admin/user-edit-application/basic-info`, {
    method: 'POST',
    data: { ...userEditData },
  });

  if (result.status) {
    return {
      data: [200, 201].includes(result.status),
    };
  }

  return {
    data: false,
  };
}

export const userEditApplicationPrivateInfo = async (
  userEditData: UserEditApplicationPrivateInfoRequest,
): Promise<ApiResponse<boolean>> => {
  const result = await apiTCRequestV2(`/admin/user-edit-application/private-info`, {
    method: 'POST',
    data: { ...userEditData },
  });

  if (result.status) {
    return {
      data: [200, 201].includes(result.status),
    };
  }

  return {
    data: false,
  };
};

export const userEditApplicationShopInfo = async (
  userEditData: UserEditApplicationShopInfoRequest,
): Promise<ApiResponse<boolean>> => {
  const result = await apiTCRequestV2(`/admin/user-edit-application/shop-info`, {
    method: 'POST',
    data: { ...userEditData },
  });

  if (result.status) {
    return {
      data: [200, 201].includes(result.status),
    };
  }

  return {
    data: false,
  };
};

export const userEditApplicationBillingInfo = async (
  userEditData: UserEditApplicationBillingInfoRequest,
): Promise<ApiResponse<boolean>> => {
  try {
    const result = await apiTCRequestV2(`/admin/user-edit-application/billing-info`, {
      method: 'POST',
      data: { ...userEditData },
    });

    return {
      data: [200, 201].includes(result.status),
    };
  } catch (e) {
    return {
      data: false,
    };
  }
};

export const userEditApplicationAdditionalInfo = async (
  userEditData: UserEditApplicationAdditionalInfoRequest,
): Promise<ApiResponse<boolean>> => {
  const result = await apiTCRequest(`/admin/user-edit-application/additional-info`, {
    method: 'POST',
    data: { ...userEditData },
  });

  if (result.status) {
    return {
      data: [200, 201].includes(result.status),
    };
  }

  return {
    data: false,
  };
};

export const inactivateUser = async (userHashId: string, comment: string): Promise<string> => {
  try {
    const result = await apiTCRequest<string>(`/admin/user/inactive/${userHashId}`, {
      method: 'PATCH',
      data: { comment },
    });

    return result.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const bannedUser = async (userHashId: string, comment: string): Promise<string> => {
  try {
    const result = await apiTCRequest<string>(`/admin/user/ban/${userHashId}`, {
      method: 'PATCH',
      data: { comment },
    });
    return result.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const blacklistUser = async (userHashId: string, comment: string): Promise<string> => {
  try {
    const result = await apiTCRequest<string>(`/admin/user/blacklist/${userHashId}`, {
      method: 'PATCH',
      data: { comment },
    });
    return result.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const activationUser = async (userHashId: string, comment: string): Promise<string> => {
  try {
    const result = await apiTCRequest<string>(`/admin/user/active/${userHashId}`, {
      method: 'PATCH',
      data: { comment },
    });

    return result.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const unBannedUser = async (userHashId: string, comment: string): Promise<string> => {
  try {
    const result = await apiTCRequest<string>(`/admin/user/unban/${userHashId}`, {
      method: 'PATCH',
      data: { comment },
    });

    return result.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const unBlacklistUser = async (userHashId: string, comment: string): Promise<string> => {
  try {
    const result = await apiTCRequest<string>(`/admin/user/un-blacklist/${userHashId}`, {
      method: 'PATCH',
      data: { comment },
    });

    return result.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getInterviewLogByHashId = async (
  userHashId: string,
  param?: UserSearchFiltersTC,
): Promise<ApiResponse<GetInterviewLogTCResponse>> => {
  try {
    const queryParamString = getUserListQueryParam(param);
    const result = await apiTCRequestV2<GetInterviewLogTCResponse>(
      `/admin/interview-log/?${queryParamString}&userHashId=${userHashId}`,
    );

    return {
      data: result.data ?? null,
    };
  } catch (e) {
    return {
      data: null,
      error: 'Error GET Interview log By hashId',
    };
  }
};

export const addNewInterviewLog = async (formData: InterviewLogDataRequest): Promise<ApiResponse<InterviewLogData>> => {
  const result = await apiTCRequestV2<InterviewLogData>(`/admin/interview-log/`, {
    method: 'POST',
    data: { ...formData },
  });

  if (result.data) {
    return {
      ...result,
      data: result.data,
    };
  }

  return {
    ...result,
    data: null,
  };
};

export const updateInterviewLog = async (
  hashId: string,
  formData: InterviewLogDataRequest,
): Promise<ApiResponse<InterviewLogData | null>> => {
  try {
    const result = await apiTCRequestV2<InterviewLogData>(`/admin/interview-log/${hashId}`, {
      method: 'PATCH',
      data: { ...formData },
    });

    return {
      ...result,
      data: result.data,
    };
  } catch (e) {
    return Promise.reject(e);
  }
};

export const deleteInterviewLogByHashId = async (hashId: string): Promise<ApiResponse<boolean>> => {
  try {
    await apiTCRequestV2<boolean>(`/admin/interview-log/${hashId}`, {
      method: 'DELETE',
      data: hashId,
    });

    return {
      data: true,
    };
  } catch (error) {
    return {
      data: null,
      error: 'Error delete Interview Log By hashId',
    };
  }
};
