import React, { FC, useContext, useState } from 'react';

import { useTranslation } from 'react-i18next';
import useDeepCompareEffect from 'use-deep-compare-effect';

import SellerApplicationListTable from '../../components/partials/seller-applications/SellerApplicationListTable';
import SellerApplicationsListSearchForm from '../../components/partials/seller-applications/SellerApplicationsListSearchForm';
import { AppGlobalUiContext } from '../../context/AppGlobalUiContext';
import { SearchFilterContext } from '../../context/SearchFilterContext';
import { defaultRowsPerPage } from '../../helpers/constants';
import {
  AppTableConditions,
  SellerAppListSearchParams,
  SellerAppListSearchFormData,
  SellerApplication,
} from '../../models';
import * as sellerApplicationService from '../../services/seller-applications';

const searchFilterContextKey = 'seller-applications';

const SellerApplicationList: FC = () => {
  const { t } = useTranslation();
  const { showSnackbar } = useContext(AppGlobalUiContext);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [sellerApplicationList, setSellerApplicationList] = useState<SellerApplication[]>([]);
  const [totalSellerApplications, setTotalSellerApplications] = useState<number>(0);
  // start filling default search filters (either from the one we saved in context or fresh conditions)
  const { currentFilters: ctxFilters, setFilters } = useContext(SearchFilterContext);
  const currentFilters: SellerAppListSearchParams = ctxFilters ? ctxFilters[searchFilterContextKey] : undefined;
  const [searchFilters, setSearchFilters] = useState<SellerAppListSearchFormData>({
    searchField: currentFilters?.searchField,
    searchKeyword: currentFilters?.searchKeyword || '',
    filteringStatuses: currentFilters?.filteringStatuses || [],
    filteringTypes: currentFilters?.filteringTypes || [],
  });
  const [tableConditions, setTableConditions] = useState<AppTableConditions>({
    page: currentFilters?.page || 0,
    rowsPerPage: currentFilters?.rowsPerPage || defaultRowsPerPage,
    sortColumn: currentFilters?.sortColumn || 'createdAt',
    sortDirection: currentFilters?.sortDirection || 'desc',
  });

  const getCurrentSearchParam = (): SellerAppListSearchParams => ({
    page: tableConditions.page || 0,
    rowsPerPage: tableConditions.rowsPerPage || defaultRowsPerPage,
    sortColumn: tableConditions.sortColumn || 'createdAt',
    sortDirection: tableConditions.sortDirection || 'desc',
    searchField: searchFilters.searchField,
    searchKeyword: searchFilters.searchKeyword,
    filteringStatuses: searchFilters.filteringStatuses,
    filteringTypes: searchFilters.filteringTypes,
  });

  const requestSellerApplicationList = async (isPageSubscribed = true): Promise<boolean> => {
    if (isPageSubscribed) {
      setIsLoadingData(true);

      const searchConditions = getCurrentSearchParam();
      setFilters(searchFilterContextKey, searchConditions);
      const result = await sellerApplicationService.getSellerApplicationList(searchConditions);

      setIsLoadingData(false);

      if (result.data) {
        setSellerApplicationList(result.data.sellerApplications);
        setTotalSellerApplications(result.data.totalCount);
        return Promise.resolve(true);
      }

      showSnackbar(t(result.error as string).toString(), 'error');
      return Promise.reject(result.error);
    }

    return false;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useDeepCompareEffect((): any => {
    let isSubscribed = true;

    requestSellerApplicationList(isSubscribed);

    return () => (isSubscribed = false);
  }, [tableConditions, searchFilters]);

  const tableConditionsChangedHandler = (values: AppTableConditions) => {
    setTableConditions(values);
  };

  const onSearchFormSubmit = (filters: SellerAppListSearchFormData) => {
    if (isLoadingData) {
      showSnackbar(t('common.message.pleaseWaitForDataToFinish').toString(), 'warning');
      return;
    }

    if (tableConditions.page !== 0) {
      tableConditions.page = 0;
    }

    setSearchFilters(filters);
  };

  return (
    <>
      <SellerApplicationsListSearchForm
        isLoadingData={isLoadingData}
        currentFilters={searchFilters}
        onSearchFormSubmit={onSearchFormSubmit}
      />
      <SellerApplicationListTable
        currentConditions={tableConditions}
        totalApplications={totalSellerApplications}
        isLoadingData={isLoadingData}
        sellerApplicationList={sellerApplicationList}
        onTableConditionsChanged={tableConditionsChangedHandler}
      />
    </>
  );
};

export default SellerApplicationList;
