import { makeStyles } from '@material-ui/core';

const useAppTableStyles = makeStyles((theme) => ({
  tableContainer: {
    padding: theme.spacing(3),
  },
  headerCell: {
    fontWeight: 700,
    paddingTop: '10px',
    paddingBottom: '10px',
    backgroundColor: theme.customPalette.table.header.main,
    color: theme.customPalette.table.header.contrastText,
  },
  bodyCell: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  dimRow: {
    backgroundColor: theme.palette.action.hover,
  },
  clickableTableRow: {
    '&:not(.disabled):hover, &:not(.readonly):hover': {
      cursor: 'pointer',
    },
  },
  dividerRow: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tablePagination: {
    backgroundColor: theme.palette.background.paper,
  },
  tableExternalLink: {
    position: 'relative',
    display: 'inline-flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: theme.customPalette.global.link.main,
    '& .MuiSvgIcon-root': {
      fontSize: '16px',
      marginLeft: '3px',
    },
  },
}));

export default useAppTableStyles;
