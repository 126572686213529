import React, { FC, useEffect, useState, useContext } from 'react';

import { Box, Container, Paper, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import WebConfigAdsDetail from '../../../components/partials/settings/WebConfigEditForm/WebConfigAdsDetail';
import WebConfigHeroBanner from '../../../components/partials/settings/WebConfigEditForm/WebConfigHeroBanner';
import WebConfigHotItems from '../../../components/partials/settings/WebConfigEditForm/WebConfigHotItems';
import WebConfigRecentCategories from '../../../components/partials/settings/WebConfigEditForm/WebConfigRecentCategories';
import { AppGlobalUiContext } from '../../../context/AppGlobalUiContext';
import usePrivilege from '../../../hooks/usePrivilege';
import { WebConfig } from '../../../models';
import * as webConfigService from '../../../services/web-config';
import useAppContainerStyles from '../../../theme/container.style';
import useTypographyStyles from '../../../theme/typography.style';

const WebConfigEditPage: FC = () => {
  const { t } = useTranslation();
  const { title } = useParams<{ title: string }>();
  const [config, setConfig] = useState<WebConfig>();
  const { showSnackbar, setAppLoading, setAppBarBackButtonUrl, setAppBarTitle } = useContext(AppGlobalUiContext);
  const containerClasses = useAppContainerStyles();
  const typoClasses = useTypographyStyles();
  const { canPerform } = usePrivilege();
  const canUpdateWebConfig = canPerform('webConfig', 'update');

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useEffect((): any => {
    let isSubscribed = true;

    webConfigService.getWebConfigByTitle(title).then((result) => {
      if (isSubscribed) {
        setAppBarBackButtonUrl('/settings/web-config');
        setAppBarTitle(t('settings:detail.config.edit', { configName: title }).toString());

        if (result.data) {
          setConfig(result.data);
        } else {
          showSnackbar(t(result.error), 'error');
        }
      }
    });

    return () => (isSubscribed = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSaveDataHandler = async (data: WebConfig) => {
    if (data && canUpdateWebConfig) {
      setAppLoading(true);

      const result = await webConfigService.saveWebConfig(data as WebConfig);

      setAppLoading(false);
      if (result.data) {
        showSnackbar(t('settings:message.config.updateConfigSuccess').toString(), 'success');
        setConfig(result.data);
        window.scrollTo(0, 0);
      } else {
        showSnackbar(t(result.error), 'error');
      }
    }
  };

  return (
    <>
      <Box mt={3} mb={10}>
        <Container className={containerClasses.container}>
          <Paper>
            {!!config && (
              <Box p={3}>
                <Typography variant="h5" component="h5" className={typoClasses.textWeightBold}>
                  {t('settings:detail.config.edit', { configName: config.title })}
                </Typography>
                <Box mt={3}>
                  {['ads_detail', 'ads_index'].includes(config.title) && (
                    <WebConfigAdsDetail configItem={config} onSave={onSaveDataHandler} />
                  )}
                  {config.title === 'hot_items' && <WebConfigHotItems configItem={config} onSave={onSaveDataHandler} />}
                  {['recent_categories', 'recent_index_categories'].includes(config.title) && (
                    <WebConfigRecentCategories configItem={config} onSave={onSaveDataHandler} />
                  )}
                  {config.title === 'hero_banner' && (
                    <WebConfigHeroBanner configItem={config} onSave={onSaveDataHandler} />
                  )}
                </Box>
              </Box>
            )}
          </Paper>
        </Container>
      </Box>
    </>
  );
};

export default WebConfigEditPage;
