import { AppTableConditions, FileResponse, ProvinceData } from './common';
import { ShopType, UserData, UserDataTC, UserEditApplicationPrivateInfoRequest, UserImage } from './user.model';

export type SellerAppListSearchFormData = {
  searchField: string;
  searchKeyword: string;
  filteringStatuses: string[];
  filteringTypes: string[];
};

export type SellerAppListSearchParams = SellerAppListSearchFormData & AppTableConditions;

export type SellerAppListSearchFilters = Partial<SellerAppListSearchParams>;

export type SellerApplication = {
  address: string;
  bankAccNo: string | null;
  bankBookFile: Partial<FileResponse> | null;
  bankHashId: string;
  comment: string | null;
  companyName: string;
  companyTaxId: string;
  createdAt: string;
  dbdFile?: Partial<FileResponse> | null;
  facebookUri: string;
  hashId: string;
  lineId: string;
  membershipType: 'basic' | 'bronze' | 'silver' | 'gold';
  mobile: string;
  nationalId: string;
  nationalIdCardFile: Partial<FileResponse> | null;
  passportNo: string;
  province: ProvinceData;
  provinceHashId: string;
  selfieFile: Partial<FileResponse> | null;
  sellerType: 'enduser' | 'agent';
  status: 'pending' | 'submitted' | 'approved' | 'draft' | 'rejected';
  type: 'individual' | 'corporation';
  updatedAt: string;
  user: UserData;
  userHashId: string;
  email: string;
  adminImage?: Partial<FileResponse[]> | null;
};

export enum SellerApplicationWorkflow {
  StartSellerApp = 'W4_START_STATE',
  FirstCreate = 'W4_FIRST_CREATED_STATE',
  WaitingForSellerApproval = 'W4_WAITING_CR_APPROVAL_STATE',
  SellerApproval = 'W4_SELLER_APPLICATION_APPROVED_STATE',
  WaitingForEditingApplication = 'W4_WAITING_FOR_EDITING_STATE',
  RejectedApplication = 'W4_REJECTED_STATE',
  BlacklistApplication = 'W4_BLACKLIST_REJECTED_STATE',
  DeletedApplication = 'W4_DELETED_STATE',
}

export type CommentData = {
  createdAt?: number;
  sellerApplicationWorkflow?: string;
  comment?: string;
};

export type SellerApplicationTC = {
  sellerApplication: {
    bookBankImage: UserImage | null;
    facebookUrl: string;
    hashId: string;
    instagramUrl: string;
    lineUrl: string;
    nidImage: UserImage | null;
    selfieImage: UserImage | null;
    sellerApplicationWorkflow: SellerApplicationWorkflow;
    shopAddress: string;
    shopDescription: string;
    shopDistrict: string;
    shopLocationLatitude: number;
    shopLocationLongitude: number;
    shopName: string;
    shopProductType: [];
    shopProvince: string;
    shopType: ShopType;
    shopZipcode: string;
    tiktokUrl: string;
    userId: number;
    websiteUrl: string;
    youtubeUrl: string;
    createdAt: string;
    updatedAt: string;
    isNewSellerCampaign: boolean;
    comment: string | null;
  };
  user: UserDataTC;
  isNewSellerFreeCoinCampaign: boolean;
};

export interface GetSellerApplicationTCResponse {
  contents: SellerApplicationTC[];
  page?: {
    size?: number;
    totalElements?: number;
    totalPages?: number;
    number?: number;
  };
}

export interface GetSellerAppListResponse {
  sellerApplications: SellerApplication[];
  totalCount: number;
}

export type SellerApplicationFormData = {
  nationalId: string;
  passportNo: string;
  verifiedFirstName: string;
  verifiedLastName: string;
  verifiedAddress: string;
  bankHashId: string;
  bankAccNo: string;
  status: SellerApplication['status'] | null;
  rejectReason: string;
} & ReverifyFormData;

export type ReverifyFormData = {
  adminImageHashIds?: string[];
  reVerifyIdentityFile1?: FileResponse;
  reVerifyIdentityFile2?: FileResponse;
  reVerifyIdentityFile3?: FileResponse;
  reVerifyIdentityFile4?: FileResponse;
  reVerifyIdentityFile5?: FileResponse;
};

export type SellerAppListSearchFormDataTC = {
  searchField: string;
  searchKeyword: string;
  filteringStatuses: string[];
};

export type SellerListSearchParamsTC = SellerAppListSearchFormDataTC & AppTableConditions;

export type SellerSearchFiltersTC = Partial<SellerListSearchParamsTC> & {
  q?: string;
};

export type SellerApplicationFormDataTC = Omit<
  UserEditApplicationPrivateInfoRequest,
  'userId' | 'selfieImage' | 'bookBankImage' | 'nidImage'
> & {
  status: string | null;
  comment: string | null;
  interviewLogComment: string | null;
};
