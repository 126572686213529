import React, { FC, useState } from 'react';

import { Avatar, Box, Grid, Typography, makeStyles } from '@material-ui/core';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { getProvinceName } from '../../../../../helpers/utils';
import { ShopType, UserDataTC } from '../../../../../models';
import DataBlock from '../../../common/DataBlock';
import ImagePreview from '../../../common/ImagePreview';
import ImageThumbnail from '../../../common/ImageThumbnail';

const useStyles = makeStyles(() => ({
  numberInput: {
    MozAppearance: 'none',
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
  previewImage: {
    maxWidth: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  bankLogo: {
    width: '28px',
    height: '28px',
    marginRight: '8px',
  },
  bankSelectorRoot: {
    display: 'flex',
    alignItems: 'center',
  },
  bankItemName: {
    flexGrow: 1,
    maxWidth: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

export interface PrivateInfoProps {
  currentUser: UserDataTC;
}

const PrivateInfo: FC<PrivateInfoProps> = (props) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const dateDisplayFormat = i18n.language === 'th' ? 'D MMM BBBB' : 'D/MM/YY';
  const { currentUser } = props;
  const classes = useStyles();
  const [selectedPreviewImage, setSelectedPreviewImage] = useState<string | undefined>(undefined);

  const isDbdFileType = currentUser.shopType === ShopType.TentCompany;

  const imageSlideData = [
    currentUser.nidImage?.fullUrl,
    currentUser.selfieImage?.fullUrl,
    currentUser.bookBankImage?.fullUrl,
  ];

  const onThumbnailClicked = (imageUrl: string) => {
    if (imageUrl) {
      setSelectedPreviewImage(imageUrl);
    }
  };

  const getBankLogoUrl = (url: string): string =>
    `${(process.env.REACT_APP_T2H_FRONTEND_URL || process.env.REACT_APP_DOMAIN_URL) as string}/${url.replace(
      /\//,
      '',
    )}`;

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <DataBlock label="user:common.fields.verifiedFullName" data={currentUser.verifiedFullName} />
          <DataBlock label="user:common.fields.verifiedCompanyName" data={currentUser.verifiedCompanyName} />
          <DataBlock
            label="user:common.fields.birthday"
            data={currentUser.birthday ? dayjs(currentUser.birthday).format(`${dateDisplayFormat}`) : null}
          />
          <DataBlock
            label="user:common.fields.nationality"
            data={
              <Box>
                {currentUser.nationality ? (
                  <Typography>
                    {t(
                      `user:common.fields.${
                        currentUser.nationality === 'Thai' ? 'nationalityTh' : 'nationalityForeign'
                      }`,
                    )}
                  </Typography>
                ) : (
                  <Typography>-</Typography>
                )}
              </Box>
            }
          />
          <DataBlock
            label="user:common.fields.gender"
            data={
              <Box>
                {currentUser.gender ? (
                  <Typography>{t(`user:common.fields.${currentUser.gender === 'F' ? 'female' : 'male'}`)}</Typography>
                ) : (
                  <Typography>-</Typography>
                )}
              </Box>
            }
          />
          <DataBlock label="user:common.fields.nationalId" data={currentUser.nationalId} />
          <DataBlock label="user:common.fields.passportNumber" data={currentUser.passportNumber} />
          <DataBlock label="user:common.fields.companyTaxId" data={currentUser.companyTaxId} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DataBlock label="user:common.fields.bookBankNumber" data={currentUser.bookBankNumber} />

          <DataBlock
            label="user:common.fields.bookBankCompanyHashId"
            data={
              currentUser.bookBankCompany ? (
                <Box display="flex" flexDirection="row">
                  <Avatar
                    alt={currentUser.bookBankCompany.thaiName}
                    src={getBankLogoUrl(currentUser.bookBankCompany.logo)}
                    className={classes.bankLogo}
                  />
                  <Typography>{currentUser.bookBankCompany.thaiName}</Typography>
                </Box>
              ) : (
                '-'
              )
            }
          />

          <DataBlock label="user:common.fields.address" data={currentUser.address} />
          <DataBlock label="user:common.fields.district" data={currentUser.district} />
          <DataBlock label="user:common.fields.province" data={getProvinceName(currentUser.province)} />
          <DataBlock label="user:common.fields.zipcode" data={currentUser.zipcode} />
        </Grid>
      </Grid>
      <Box my={3}>
        <Grid container spacing={2}>
          {currentUser.nidImage && (
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <ImageThumbnail
                listingHashId={currentUser.nidImage.fileHashId}
                imageUrl={currentUser.nidImage?.fullUrl}
                altText="National ID file"
                thumbnailLabel={t('seller-applications:details.label.nationalIdOrPassport')}
                imageClick={onThumbnailClicked}
              />
            </Grid>
          )}
          {currentUser.selfieImage && (
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <ImageThumbnail
                listingHashId={currentUser.selfieImage.fileHashId}
                imageUrl={currentUser.selfieImage?.fullUrl}
                altText={isDbdFileType ? 'DBD file' : 'Selfie file'}
                thumbnailLabel={t(
                  isDbdFileType
                    ? 'seller-applications:details.label.dbdFile'
                    : 'seller-applications:details.label.idSelfiePic',
                )}
                imageClick={onThumbnailClicked}
              />
            </Grid>
          )}
          {currentUser.bookBankImage && (
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <ImageThumbnail
                listingHashId={currentUser.bookBankImage.fileHashId}
                imageUrl={currentUser.bookBankImage?.fullUrl}
                altText="Book Bank file"
                thumbnailLabel={t('seller-applications:details.label.bookBankPic')}
                imageClick={onThumbnailClicked}
              />
            </Grid>
          )}
        </Grid>
      </Box>

      <ImagePreview
        imageUrl={selectedPreviewImage}
        onCloseClick={() => setSelectedPreviewImage(undefined)}
        asModal
        isPreviewSlide
        imageSlideData={imageSlideData}
      />
    </>
  );
};

export default PrivateInfo;
