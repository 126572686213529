import React, { FC, useState, useContext, DragEvent, useRef, ChangeEvent, MouseEvent } from 'react';

import {
  Box,
  ButtonGroup,
  Card,
  CircularProgress,
  Divider,
  Fade,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ListIcon from '@material-ui/icons/List';
import PublishIcon from '@material-ui/icons/Publish';
import SearchIcon from '@material-ui/icons/Search';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { AppGlobalUiContext } from '../../../context/AppGlobalUiContext';
import { defaultRowsPerPage } from '../../../helpers/constants';
import { getFileType, getRgbaFromHex, getT2HAssetUrl, lightenDarkenColor } from '../../../helpers/utils';
import {
  ApiResponse,
  AppTableConditions,
  FileItem,
  FileListSearchParam,
  FileListSearchParamFormData,
  SelectItem,
} from '../../../models';
import * as fileService from '../../../services/file';
import useControlStyles from '../../../theme/controls.style';
import useTypographyStyles from '../../../theme/typography.style';
import FileListTable from '../../partials/common/FileListTable';
import FileListThumbnail from '../../partials/common/FileListThumbnail';
import ImagePreview from '../../partials/common/ImagePreview';
import AppButton from '../AppButton';
import AppDatetimeText from '../AppDatetimeText';
import AppDialog, { AppDialogProps } from '../AppDialog';
import AppFormControl from '../AppFormControl';
import AppFormControlGroup from '../AppFormControlGroup';

export type AppMediaLibraryProps = Omit<AppDialogProps, 'onOkClick'> & {
  onOkClick?: (files: FileItem[]) => void;
  multiple?: boolean;
};

const useStyles = makeStyles((theme) => ({
  mediaLibContainer: {
    position: 'relative',
    width: 'calc(100vw - 140px)',
    [theme.breakpoints.up('sm')]: {
      width: 'calc(100vw - 140px)',
    },
  },
  mediaToolbarSearch: {
    flexBasis: '100%',
    padding: theme.spacing(1, 0),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 1),
      flexGrow: 1,
      flexBasis: 'auto',
    },
    [theme.breakpoints.up('lg')]: {
      flexGrow: 0,
      flexBasis: '400px',
    },
  },
  fileListContainer: {
    height: 'calc(100vh - 230px)',
    marginTop: theme.spacing(2),
    position: 'relative',
    border: 'solid 2px transparent',
  },
  fileListContainerDropStyle: {
    borderColor: lightenDarkenColor(theme.palette.primary.main, 20),
    '&::before': {
      content: '""',
      display: 'flex',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 5,
      backgroundColor: getRgbaFromHex(theme.palette.primary.main, 10),
    },
  },
  fileListContainerGrid: {
    padding: theme.spacing(2),
  },
  hiddenFileInput: {
    position: 'absolute',
    opacity: 0,
    width: 0,
    top: -9999,
    left: -9999,
    visibility: 'hidden',
  },
  fileListWrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
  },
  fileListWrapperActive: {
    pointerEvents: 'none',
  },
  fileListWrapperDropPanel: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '100%',
    height: '100%',
    border: 'solid 2px transparent',
    borderRadius: 4,
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 4,
    borderColor: lightenDarkenColor(theme.palette.primary.main, 20),
    backgroundColor: getRgbaFromHex(theme.palette.primary.main, 10),
  },
  fileListWrapperDropPanelIconCircle: {
    pointerEvents: 'none',
    position: 'absolute',
    zIndex: 6,
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    top: '50%',
    left: '50%',
    width: 200,
    height: 200,
    marginLeft: -100,
    marginTop: -100,
    borderRadius: '50%',
    backgroundColor: getRgbaFromHex(theme.palette.primary.main, 80),
    transformOrigin: 'center',
    transform: 'scale(0.6)',
    animation: `$uploadIconCircleAnimate 120ms ${theme.transitions.easing.easeOut} 0s forwards`,
  },
  '@keyframes uploadIconCircleAnimate': {
    '0%': {
      transform: 'scale(0.6)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  },
  fileListWrapperDropPanelIcon: {
    fontSize: 80,
    marginBottom: theme.spacing(2),
  },
  fileUploaderContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    border: 'solid 2px transparent',
    borderRadius: 4,
    zIndex: 1,
  },
  loadingPanel: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 5,
    backgroundColor: theme.customPalette.global.lightOverlay.backgroundColor,
  },
  searchBoxContainer: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 500,
    },
  },
  uploadListItem: {
    borderBottom: `solid 1px ${theme.palette.divider}`,
  },
  uploadListFileNameText: {
    width: 200,
    [theme.breakpoints.up('md')]: {
      width: 300,
    },
    [theme.breakpoints.up('lg')]: {
      width: 400,
    },
  },
  contextMenuRoot: {
    zIndex: 1250,
  },
}));

const searchFieldList: SelectItem<string>[] = [
  {
    label: 'common:mediaLibrary.fields.fileName',
    value: 'original_name',
  },
  {
    label: 'common:mediaLibrary.fields.fileUrl',
    value: 'q',
  },
];

const FileListRenderer = (props: {
  currentDisplayMode: 'list' | 'grid';
  currentConditions: AppTableConditions;
  selectedFiles: FileItem[];
  contextedFile: FileItem | null;
  fileList: FileItem[];
  totalFiles: number;
  isLoadingData: boolean;
  onTableConditionsChanged: (values: AppTableConditions) => void;
  onFileClick?: (file: FileItem) => void;
  onFileDoubleClick?: (file: FileItem) => void;
  onContextMenuClick?: (e: MouseEvent<HTMLElement>, file: FileItem) => void;
}) => {
  const classes = useStyles();
  const { currentDisplayMode, ...rest } = props;

  return (
    <>
      <Box className={classes.fileListWrapper}>
        {currentDisplayMode === 'list' && <FileListTable {...rest} />}
        {currentDisplayMode === 'grid' && <FileListThumbnail {...rest} />}
      </Box>
    </>
  );
};

const UploadingFileList = (props: {
  fileList: File[];
  isUploading?: boolean;
  onDelete: (index: number) => void;
  uploadResult?: (string | null)[];
}) => {
  const { fileList, onDelete, isUploading, uploadResult = [] } = props;
  const classes = useStyles();
  const typoClasses = useTypographyStyles();

  return (
    <List dense>
      {fileList.map((file: File, index: number) => {
        const rowKey = `upload-file-${index}`;
        const uploadProcessResult = uploadResult[index] || null;

        return (
          <ListItem key={rowKey} className={classes.uploadListItem}>
            <ListItemText primary={file.name} className={classes.uploadListFileNameText} />
            <ListItemSecondaryAction>
              {!isUploading && (
                <IconButton edge="end" aria-label="delete" onClick={() => onDelete(index)} size="small">
                  <DeleteIcon className={typoClasses.errorText} />
                </IconButton>
              )}
              {isUploading && <CircularProgress size={36} />}
              {uploadProcessResult === 'success' && <DoneIcon fontSize="small" className={typoClasses.successText} />}
              {uploadProcessResult === 'error' && (
                <ErrorOutlineIcon fontSize="small" className={typoClasses.errorText} />
              )}
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
};

const UploaderModal = (props: {
  fileList: File[];
  onUploadFileDelete: (index: number) => void;
  onUploadCancel: () => void;
  onUploadComplete: (uploadedFileCount: number) => void;
}) => {
  const { fileList, onUploadFileDelete, onUploadCancel, onUploadComplete } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const typoClasses = useTypographyStyles();
  const controlClasses = useControlStyles();
  const [isUploading, setIsUploading] = useState(false);
  const [uploadResult, setUploadResult] = useState<(string | null)[]>(fileList.map(() => null));

  const onDeleteHandler = (index: number) => {
    if (!isUploading) {
      onUploadFileDelete(index);
    }
  };

  const onUploadClick = () => {
    if (!isUploading) {
      setIsUploading(true);

      (async () => {
        const requestList = fileList.map((file: File) => fileService.uploadFile(file));
        const result = await Promise.all(requestList);

        const fileUploadResult = result.map((res: ApiResponse<FileItem>) => (res.data ? 'success' : 'error'));
        setUploadResult(fileUploadResult);

        setIsUploading(false);
        if (typeof onUploadComplete === 'function') {
          onUploadComplete(fileUploadResult.filter((res) => res === 'success').length);
        }
      })();
    }
  };

  return (
    <Box className={classes.loadingPanel}>
      <Fade in>
        <Card>
          <Box p={2}>
            <Typography variant="subtitle1" className={typoClasses.textWeightBold}>
              {t('common:mediaLibrary.uploadTitle')}
            </Typography>
            <Box mt={2}>
              <UploadingFileList
                fileList={fileList}
                onDelete={onDeleteHandler}
                isUploading={isUploading}
                uploadResult={uploadResult}
              />
            </Box>
            {isUploading && (
              <Box mt={2}>
                <LinearProgress />
                <Typography variant="caption">{t('common:mediaLibrary.message.uploadInProgress')}</Typography>
              </Box>
            )}
            <Box
              mt={2}
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              className={controlClasses.buttonsGroup}>
              <AppButton variant="text" color="default" onClick={() => onUploadCancel()} disabled={isUploading}>
                {t('common:button.cancel')}
              </AppButton>
              <AppButton
                variant="contained"
                color="primary"
                startIcon={<PublishIcon fontSize="small" />}
                disabled={isUploading}
                onClick={() => onUploadClick()}>
                {t('common:mediaLibrary.button.uploadFile')}
              </AppButton>
            </Box>
          </Box>
        </Card>
      </Fade>
    </Box>
  );
};

const FileInfo = ({ file }: { file: FileItem }) => {
  const typoClasses = useTypographyStyles();
  const { t } = useTranslation();

  return (
    <Box>
      <Box>
        <Typography variant="caption" className={typoClasses.textWeightBold}>
          {t('common:mediaLibrary.fields.fileName')}
        </Typography>
        <Typography>{file.fileOriginalName}</Typography>
      </Box>
      <Box mt={2}>
        <Typography variant="caption" className={typoClasses.textWeightBold}>
          {t('common:mediaLibrary.fields.fileUrl')}
        </Typography>
        <Typography>{file.fullUrl}</Typography>
      </Box>
      <Box mt={2}>
        <Typography variant="caption" className={typoClasses.textWeightBold}>
          {t('common:mediaLibrary.fields.fileSize')}
        </Typography>
        <Typography>{file.size / 1024}</Typography>
      </Box>
      <Box mt={2}>
        <Typography variant="caption" className={typoClasses.textWeightBold}>
          {t('common:mediaLibrary.fields.createdAt')}
        </Typography>
        <Typography>
          <AppDatetimeText value={file.createdAt ? dayjs(file.createdAt) : null} withTime />
        </Typography>
      </Box>
    </Box>
  );
};

const AppMediaLibrary: FC<AppMediaLibraryProps> = (props) => {
  const initialContextValue = {
    mouseX: null,
    mouseY: null,
  };
  const { t } = useTranslation();
  const { multiple = false, ...rest } = props;
  const classes = useStyles();
  const hiddenFileInputRef = useRef<HTMLInputElement>(null);
  const { showSnackbar, setAppLoading } = useContext(AppGlobalUiContext);
  const typoClasses = useTypographyStyles();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = useState<FileItem[]>([]);
  const [selectedPreviewImage, setSelectedPreviewImage] = useState<string>('');
  const [totalFileCount, setTotalFileCount] = useState<number>(0);
  const [currentFiles, setCurrentFiles] = useState<FileItem[]>([]);
  const [currentDisplayMode, setCurrentDisplayMode] = useState<'list' | 'grid'>('list');
  const [isUploaderDragging, setIsUploaderDragging] = useState<boolean>(false);
  const [uploadFileList, setUploadFileList] = useState<File[]>([]);
  const [searchFilters, setSearchFilters] = useState<FileListSearchParamFormData>({
    searchField: searchFieldList[0].value,
    searchKeyword: '',
  });
  const [tableConditions, setTableConditions] = useState<AppTableConditions>({
    page: 0,
    rowsPerPage: defaultRowsPerPage,
    sortColumn: 'createdAt',
    sortDirection: 'desc',
  });
  const [selectedContextFile, setSelectedContextFile] = useState<FileItem | null>(null);
  const [contextPosition, setContextPosition] = useState<{
    mouseX: null | number;
    mouseY: null | number;
  }>(initialContextValue);
  const isImageFileContexted = !!selectedContextFile && getFileType(selectedContextFile.fullUrl) === 'image';
  const [actionDialogProps, setActionDialogProps] = useState<Omit<AppDialogProps, 'open'> | null>(null);
  const [actionDialogContentType, setActionDialogContentType] = useState<'info' | 'delete'>('delete');

  const { control, handleSubmit } = useForm<FileListSearchParamFormData>({
    mode: 'onSubmit',
    defaultValues: {
      searchField: 'q',
      searchKeyword: '',
    },
  });
  const hasFilesToUpload = uploadFileList.length > 0;

  const clearUploadContent = () => {
    setCurrentDisplayMode('list');
    setSearchFilters({
      searchField: searchFieldList[0].value,
      searchKeyword: '',
    });

    setTableConditions({
      page: 0,
      rowsPerPage: defaultRowsPerPage,
      sortColumn: 'createdAt',
      sortDirection: 'desc',
    });

    if (isUploaderDragging) {
      setIsUploaderDragging(false);
    }

    setUploadFileList([]);
    setSelectedFiles([]);
    if (hiddenFileInputRef?.current) {
      hiddenFileInputRef.current.files = null;
      hiddenFileInputRef.current.value = '';
    }
  };

  const uploadFileSelectionHandler = (fileList: FileList) => {
    const files = [];

    for (let i = 0; i < fileList.length; i += 1) {
      const file = fileList.item(i);
      if (file) {
        files.push(file);
      }
    }
    setUploadFileList(files);
  };

  const onInputFileChanged = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      uploadFileSelectionHandler(e.target.files);
    }
  };

  const onFileDropReceived = (e: DragEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    if (isUploaderDragging && e.dataTransfer?.files) {
      if (hiddenFileInputRef?.current) {
        hiddenFileInputRef.current.files = e.dataTransfer.files;
      }

      uploadFileSelectionHandler(e.dataTransfer.files);
      setIsUploaderDragging(false);
    }
  };

  const getCurrentSearchParam = (): FileListSearchParam => ({
    searchField: searchFilters.searchField,
    searchKeyword: searchFilters.searchKeyword,
    page: tableConditions.page || 0,
    rowsPerPage: tableConditions.rowsPerPage || defaultRowsPerPage,
    sortColumn: tableConditions.sortColumn || 'createdAt',
    sortDirection: tableConditions.sortDirection || 'desc',
  });

  const loadFileList = async (isActive: boolean) => {
    if (isActive && rest.open) {
      const searchConditions = getCurrentSearchParam();
      setIsLoading(true);
      const fileList = await fileService.getFileList(searchConditions);
      setIsLoading(false);
      if (fileList.error) {
        showSnackbar(t(fileList.error), 'error');
      } else {
        setCurrentFiles(fileList.data?.list || []);
        setTotalFileCount(fileList.data?.totalCount || 0);
      }
    }
  };

  useDeepCompareEffect(() => {
    let isSubscribed = true;

    loadFileList(isSubscribed);

    return () => {
      isSubscribed = false;
    };
  }, [tableConditions, searchFilters]);

  const tableConditionsChangedHandler = (values: AppTableConditions) => {
    setTableConditions(values);
  };

  const onSearchFormSubmit = (filters: FileListSearchParamFormData) => {
    if (tableConditions.page !== 0) {
      tableConditions.page = 0;
    }

    setSearchFilters(filters);
  };

  const onOkClickHandler = () => {
    if (typeof rest.onOkClick === 'function') {
      rest.onOkClick(selectedFiles);
    }
  };

  const getOkButtonText = (): string =>
    selectedFiles.length > 0
      ? t('common:mediaLibrary.button.selectedFiles', { fileCount: selectedFiles.length })
      : 'common:button.cancel';

  const getCancelButtonText = (): string => (selectedFiles.length > 0 ? 'common:button.cancel' : '');

  const onFileClick = (file: FileItem) => {
    const existingFile = selectedFiles.find((item) => item.id === file.id);

    if (!existingFile) {
      setSelectedFiles(multiple ? [...selectedFiles, file] : [file]);
    } else {
      setSelectedFiles(selectedFiles.filter((item) => item.id !== file.id));
    }
  };

  const onFileDoubleClick = (file: FileItem) => {
    if (file) {
      const fileType = getFileType(file.fullUrl);
      const fileUrl = !/^(http)\.+$/gi.test(file.fullUrl) ? getT2HAssetUrl(file.fullUrl) : file.fullUrl;
      if (fileUrl && fileType === 'image') {
        setSelectedPreviewImage(fileUrl);
      }
    }
  };

  const onFileListDragOver = (e: DragEvent<HTMLElement>) => {
    e.preventDefault();

    if (!isUploaderDragging) {
      setIsUploaderDragging(true);
    }
  };

  const onFileListDragEnd = (e: DragEvent<HTMLElement>) => {
    e.preventDefault();
    if (isUploaderDragging) {
      setIsUploaderDragging(false);
    }
  };

  const openFileUploadClickHandler = () => {
    if (hiddenFileInputRef.current) {
      hiddenFileInputRef.current.click();
    }
  };

  const onUploadFileDelete = (index: number) => {
    const newList = [...uploadFileList];
    newList.splice(index, 1);
    setUploadFileList(newList);
  };

  const onUploadCancel = () => {
    setUploadFileList([]);
    if (hiddenFileInputRef?.current) {
      hiddenFileInputRef.current.files = null;
    }
  };

  const onUploadComplete = (uploadedFileCount: number) => {
    if (uploadedFileCount > 0) {
      if (uploadedFileCount >= uploadFileList.length) {
        showSnackbar(t('common:mediaLibrary.message.uploadSuccess'), 'success');
      } else if (uploadedFileCount < uploadFileList.length) {
        showSnackbar(t('common:mediaLibrary.message.uploadSuccess'), 'warning');
      }

      if (isUploaderDragging) {
        setIsUploaderDragging(false);
      }

      setUploadFileList([]);
      if (hiddenFileInputRef?.current) {
        hiddenFileInputRef.current.files = null;
        hiddenFileInputRef.current.value = '';
      }

      // Refresh file list
      const defaultCondition = {
        page: 0,
        rowsPerPage: defaultRowsPerPage,
        sortColumn: 'createdAt',
        sortDirection: 'desc',
      };

      // If table condition is not default, change it to trigger file list refresh.
      // Otherwise, refresh it directly
      if (JSON.stringify(tableConditions) !== JSON.stringify(defaultCondition)) {
        setTableConditions({
          page: 0,
          rowsPerPage: defaultRowsPerPage,
          sortColumn: 'createdAt',
          sortDirection: 'desc',
        });
      } else {
        loadFileList(true);
      }
    } else {
      showSnackbar(t('common:mediaLibrary.error.uploadFailed'), 'error');
    }
  };

  const handleContextClick = (event: MouseEvent<HTMLElement>, file: FileItem) => {
    event.preventDefault();
    setSelectedContextFile(file);
    setContextPosition({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  };

  const handleContextClose = () => {
    setSelectedContextFile(null);
    setContextPosition(initialContextValue);
  };

  const onFilePreviewClick = () => {
    if (selectedContextFile) {
      onFileDoubleClick(selectedContextFile);
      handleContextClose();
    }
  };

  const deleteFile = () => {
    if (selectedContextFile) {
      setContextPosition(initialContextValue);
      setAppLoading(true);

      (async () => {
        const result = await fileService.deleteFile(selectedContextFile.id);
        if (result.data) {
          showSnackbar(t('common:mediaLibrary.message.deleteSuccess').toString(), 'success');
          setActionDialogProps(null);
          setSelectedContextFile(null);
          setSelectedFiles([]);
          loadFileList(true);
        } else {
          showSnackbar(t(result.error).toString(), 'success');
        }
        setAppLoading(false);
      })();
    }
  };

  const onFileDeleteClick = () => {
    setContextPosition(initialContextValue);
    setActionDialogContentType('delete');
    setActionDialogProps({
      title: 'common:dialog.title.warning',
      okButtonColor: 'error',
      okButtonText: 'common:button.delete',
      cancelButtonText: 'common:button.cancel',
      onOkClick: () => deleteFile(),
      onClose: () => {
        setActionDialogProps(null);
        setSelectedContextFile(null);
      },
    });
  };

  const onFileInfoClick = () => {
    setContextPosition(initialContextValue);
    setActionDialogContentType('info');
    setActionDialogProps({
      title: 'common:mediaLibrary.button.fileInfo',
      okButtonColor: 'default',
      okButtonVariant: 'outlined',
      okButtonText: 'common:button.ok',
      closeOnOkClicked: true,
      onClose: () => {
        setActionDialogProps(null);
        setSelectedContextFile(null);
      },
    });
  };

  return (
    <>
      <AppDialog
        {...rest}
        title="common:mediaLibrary.dialogTitle"
        dialogProps={{
          ...(rest.dialogProps || {}),
          disableBackdropClick: true,
          maxWidth: 'xl',
          TransitionProps: {
            onEntered: () => {
              loadFileList(true);
            },
            onExited: () => {
              clearUploadContent();
            },
          },
        }}
        onOkClick={() => onOkClickHandler()}
        okButtonText={getOkButtonText()}
        okButtonVariant={selectedFiles.length > 0 ? 'contained' : 'outlined'}
        okButtonColor={selectedFiles.length > 0 ? 'primary' : 'default'}
        cancelButtonText={getCancelButtonText()}
        okButtonProps={{
          disabled: hasFilesToUpload,
        }}
        cancelButtonProps={{
          disabled: hasFilesToUpload,
        }}>
        <Box className={classes.mediaLibContainer}>
          {hasFilesToUpload && (
            <UploaderModal
              fileList={uploadFileList}
              onUploadFileDelete={onUploadFileDelete}
              onUploadCancel={onUploadCancel}
              onUploadComplete={onUploadComplete}
            />
          )}

          <Box display="flex" flexDirection="row" flexWrap="wrap">
            <Box>
              <AppButton
                onClick={() => openFileUploadClickHandler()}
                variant="outlined"
                startIcon={<AddIcon fontSize="small" />}
                disabled={isLoading}>
                {t('common:button.upload')}
              </AppButton>
            </Box>
            <Box className={classes.mediaToolbarSearch}>
              <form onSubmit={handleSubmit(onSearchFormSubmit)}>
                <Box display="flex" flexDirection="row" flexWrap="nowrap" className={classes.searchBoxContainer}>
                  <AppFormControlGroup flexGrow={1} minWidth={0} pr={1}>
                    <AppFormControl margin="dense">
                      <Controller
                        name="searchField"
                        control={control}
                        defaultValue={searchFieldList[0].value}
                        render={({ field }) => (
                          <Select
                            labelId="file-list-search-field-label"
                            id="file-list-search-field"
                            value={field.value}
                            variant="outlined"
                            onChange={field.onChange}
                            fullWidth
                            disabled={isLoading}>
                            {searchFieldList.map((item) => (
                              <MenuItem value={item.value} key={item.value}>
                                {t(item.label)}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </AppFormControl>
                    <AppFormControl margin="dense" boxProps={{ flexGrow: 1 }}>
                      <Controller
                        name="searchKeyword"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <OutlinedInput
                            type="search"
                            endAdornment={<SearchIcon />}
                            id="file-list-search-keyword"
                            value={field.value}
                            onChange={field.onChange}
                            fullWidth
                            disabled={isLoading}
                            placeholder={t('common:message.search').toString()}
                          />
                        )}
                      />
                    </AppFormControl>
                  </AppFormControlGroup>
                  <AppButton type="submit" color="primary" style={{ alignSelf: 'flex-start' }} disabled={isLoading}>
                    {t('common:message.search')}
                  </AppButton>
                </Box>
              </form>
            </Box>
            <Box>
              <ButtonGroup>
                <AppButton
                  onClick={() => setCurrentDisplayMode('list')}
                  color={currentDisplayMode === 'list' ? 'success' : 'default'}
                  variant={currentDisplayMode === 'list' ? 'contained' : 'outlined'}
                  disabled={isLoading}>
                  <ListIcon />
                </AppButton>
                <AppButton
                  onClick={() => setCurrentDisplayMode('grid')}
                  color={currentDisplayMode === 'grid' ? 'success' : 'default'}
                  variant={currentDisplayMode === 'grid' ? 'contained' : 'outlined'}
                  disabled={isLoading}>
                  <ViewModuleIcon />
                </AppButton>
              </ButtonGroup>
            </Box>
          </Box>

          <Box
            className={clsx(classes.fileListContainer, {
              [classes.fileListContainerGrid]: currentDisplayMode === 'grid',
              [classes.fileListContainerDropStyle]: isUploaderDragging,
            })}
            onDrop={onFileDropReceived}
            onDragOver={onFileListDragOver}
            onDragEnd={onFileListDragEnd}>
            {isLoading && (
              <Box className={classes.loadingPanel}>
                <CircularProgress />
              </Box>
            )}
            {isUploaderDragging && (
              <Box className={classes.fileListWrapperDropPanelIconCircle}>
                <InsertDriveFileIcon className={clsx(typoClasses.whiteText, classes.fileListWrapperDropPanelIcon)} />
                <Typography
                  component="label"
                  variant="body1"
                  className={clsx(typoClasses.textWeightBold, typoClasses.whiteText)}>
                  {t('common:mediaLibrary.message.dropFileHere')}
                </Typography>
              </Box>
            )}

            <input
              type="file"
              className={classes.hiddenFileInput}
              ref={hiddenFileInputRef}
              onChange={onInputFileChanged}
              multiple
            />
            {!isLoading && (
              <FileListRenderer
                currentDisplayMode={currentDisplayMode}
                currentConditions={tableConditions}
                fileList={currentFiles}
                totalFiles={totalFileCount}
                isLoadingData={isLoading}
                onTableConditionsChanged={tableConditionsChangedHandler}
                selectedFiles={selectedFiles}
                contextedFile={selectedContextFile}
                onFileClick={onFileClick}
                onFileDoubleClick={onFileDoubleClick}
                onContextMenuClick={handleContextClick}
              />
            )}
            <Box />
          </Box>
        </Box>
      </AppDialog>

      <ImagePreview imageUrl={selectedPreviewImage} onCloseClick={() => setSelectedPreviewImage('')} asModal />

      <Menu
        keepMounted={false}
        open={contextPosition.mouseY !== null}
        onClose={handleContextClose}
        anchorReference="anchorPosition"
        PopoverClasses={{
          root: classes.contextMenuRoot,
        }}
        anchorPosition={
          contextPosition.mouseY !== null && contextPosition.mouseX !== null
            ? { top: contextPosition.mouseY, left: contextPosition.mouseX }
            : undefined
        }>
        <MenuItem onClick={onFileInfoClick}>{t('common:mediaLibrary.button.fileInfo')}</MenuItem>
        {isImageFileContexted && (
          <MenuItem onClick={onFilePreviewClick}>{t('common:mediaLibrary.button.previewFile')}</MenuItem>
        )}
        <Divider />
        <MenuItem onClick={onFileDeleteClick} className={typoClasses.errorText}>
          {t('common:mediaLibrary.button.deleteFile')}
        </MenuItem>
      </Menu>

      <AppDialog open={!!actionDialogProps} {...actionDialogProps}>
        {actionDialogContentType === 'delete' && (
          <Typography className={typoClasses.errorText}>{t('common:mediaLibrary.message.deleteWarning')}</Typography>
        )}
        {actionDialogContentType === 'info' && selectedContextFile && <FileInfo file={selectedContextFile} />}
      </AppDialog>
    </>
  );
};

export default AppMediaLibrary;
