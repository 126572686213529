/* eslint-disable max-len */
export const DISPLAY_NAME_REGEX = /^[^๐-๙!@#$%^&*+={}<>\\[\]\\/\\?"':][A-Za-z\d-_.,();ก-๏\s]*$/gi;
export const PASSWORD_REGEX = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d-_"':;?,!@#$^&*%()<>=+{}\\[\]\\/\\]{8,32}$/g;
export const URL_REGEX =
  /((https?:\/\/)|www\.)([a-zA-Z0-9]+([a-zA-Z0-9-_]*[a-zA-Z0-9])*)(\.([a-zA-Z0-9]+([a-zA-Z0-9-_]*[a-zA-Z0-9])*))*(:[1-9][0-9]*)*(\/([a-zA-Z0-9]+([a-zA-Z0-9-_]*[a-zA-Z0-9])*))*\/?(\?([a-zA-Z0-9]+([a-zA-Z0-9-_]*[a-zA-Z0-9])*)=([a-zA-Z0-9%]+([a-zA-Z0-9-_]*[a-zA-Z0-9])*)(&([a-zA-Z0-9]+([a-zA-Z0-9-_]*[a-zA-Z0-9])*)=([a-zA-Z0-9%]+([a-zA-Z0-9-_]*[a-zA-Z0-9])*))*)*(#[^\s#]+)*/gi;
export const TEL_REGEX = /^0[0-9]{8,9}$/gi;
export const MATCH_TEL_REGEX =
  /(0|\+66)[-. ]*?((([2][-. ]*?){1})([0-9][-. ]*?){7}|([3-9][-. ]*?){1}([0-9][-. ]*?){8})\s*/gm;
export const ZIPCODE_REGEX = /^[0-9]{5}$/gi;
export const COMPANY_TAX_ID_REGEX = /^[0-9]{13}$/gi;
export const OTP_REGEX = /^[0-9]{6}$/gi;
export const EMAIL_REGEX = /^(?=.{5,512}$)[\w-!+@-_.#%^&*=/\\?\\.]+@([\w-]+\.)+[\w-]{2,3}$/gi;
export const HTML_ESCAPED_REGEX = /(&([^;]{2,4});)/gi;
export const NOT_SPECIAL_CHAR_REGEX = /^[^๐-๙!@#$%^&*()+={}<>\\[\]\\/\\?"':;,][A-Za-z\d-_ก-ฮ]*$/gi;
export const EMOJI_REGEX =
  /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
export const NUMBER_THOUSANDS_REGEX = /\B(?=(\d{3})+(?!\d))/gi;
export const THAI_NUMBER_CHARACTERS = /([๐-๙])/g;
export const MODEL_NAME_REGEX = /^[^!@#$%^&*+=/?:;'"{}<>\\[\]\\]*$/gi;
export const SOCIAL_REGEX = /^[A-Za-z0-9\u0020-\u007E]+$/g;
export const LISTING_NAME_REGEX = /^[^๐-๙!@#$%^&฿*+={}<>\\[\]\\/\\?"';][A-Za-z\d-_.,:()ก-๏\s]*$/gi;
export const ADDRESS_REGEX = /^[^!#฿$%^*+={}<>|?][A-Za-z\d-_:;."'@&()\\/\\,๐-๙ก-๏\s]*$/gi;
export const FIELD_REQUIRED_REGEX = /(?!^$)([^\s])/;
export const FACEBOOK_REGEX = /(?:https?:\/\/)?(?:www\.)?(m\.facebook|facebook|fb)\.(com)/g;
