import { BaseEntity, BaseMaster, ESBaseEntity, MasterCommon } from './base-entity.model';
import { Brand } from './brand.model';
import { AppTableConditions } from './common';
import { MasterIndex, RefMasterIndex } from './master-index.model';
import { Model } from './models.model';

/* eslint-disable @typescript-eslint/naming-convention */
export enum KTMasterIndexAttributeDataType {
  ATTRIBUTE_BOOLEAN = 'ATTRIBUTE_BOOLEAN',
  ATTRIBUTE_CHOICE = 'ATTRIBUTE_CHOICE',
  ATTRIBUTE_CHOICE_AS_PROVINCE = 'ATTRIBUTE_CHOICE_AS_PROVINCE',
  ATTRIBUTE_CHOICE_AS_YEARS = 'ATTRIBUTE_CHOICE_AS_YEARS',
  ATTRIBUTE_CHOICE_AS_COLOR = 'ATTRIBUTE_CHOICE_AS_COLOR',
  ATTRIBUTE_NUMBER = 'ATTRIBUTE_NUMBER',
  ATTRIBUTE_TEXT = 'ATTRIBUTE_TEXT',
  CATEGORY = 'CATEGORY',
  BRAND = 'BRAND',
  BRAND_TEXT = 'BRAND_TEXT',
  MODEL = 'MODEL',
  MODEL_TEXT = 'MODEL_TEXT',
  MODEL_NAME = 'MODEL_NAME',
}
/* eslint-enable */

export type CategoryMasterRoot = {
  thaiName: string;
  englishName: string;
  pageTitle: string;
  pageDescription: string;
  searchUrl: string;
  pageImageUrl: string;
  pageIconUrl: string;
  itemCount: 0;
  subCategories: CategoryMasterRoot[];
  spacialCategories: CategoryMasterRoot[];
  originalCategories: string[];
  hashId: string;
};

export type CategoryMasterIndex = {
  masterIndex: MasterIndex;
  optional: boolean;
};

export type CategoryFormData = BaseMaster &
  Partial<MasterCommon> & {
    path?: string;
    breadcrumbPath?: string;
    canonicalUrlMeta?: string;
    searchSynonym?: string;
    masterIndexUid?: number;
    blogSlug?: string;
    banner?: string;
  };

export type Category = BaseEntity &
  CategoryFormData & {
    rootCategory?: Category;
    masterIndex?: MasterIndex;
    numberOfItems?: number;
    childrenCategories?: Category[];
    brands?: Brand[];
    models?: Model[];
  };

export interface GetEsCategoryChildrenParams {
  page?: number;
  /* eslint-disable camelcase */
  per_page?: number;
  sort?: string;
  q?: string;
}

export interface ESCategory extends ESBaseEntity {
  hashUid: string;
  uid: number;
  name: string;
  thaiName: string;
  slug: string;
  imageUrl: string;
  description: string;
  placeholder: string;
  path: KTCategoryPathItem[];
  breadcrumbPath: ItemBreadcrumbCategory[];
  titleMeta: string;
  descriptionMeta: string;
  imageUrlMeta: string;
  searchSynonym: string;
  numberOfItems: number;
  hashMasterIndexId: string;
  masterIndexId: number;
  hashMasterIndexUid: string;
  masterIndexUid: number;
  masterIndexName: string;
  masterIndexThaiName: string;
  masterIndexSlug: string;
  hashRootCategoryId: string;
  rootCategoryId: number;
  hashRootCategoryUid: string;
  rootCategoryUid: number;
  rootCategoryName: string;
  rootCategoryThaiName: string;
  rootCategorySlug: string;
}

export type CategoryData = Omit<ESCategory, 'breadcrumbPath'>;

export type ItemBreadcrumbCategory = {
  masterIndex: RefMasterIndex;
  optional: boolean;
};

export type RefCategory = Pick<
  Category,
  | 'id'
  | 'description'
  | 'titleMeta'
  | 'descriptionMeta'
  | 'canonicalUrlMeta'
  | 'searchSynonym'
  | 'uid'
  | 'name'
  | 'thaiName'
  | 'slug'
> & {
  masterIndex?: RefMasterIndex;
};

export type CategoryBreadcrumb = {
  masterIndex: RefMasterIndex;
};

export type CategoryPath = {
  masterIndex: RefMasterIndex;
  disabledFilter?: boolean;
  isUseCountItems?: boolean;
  optional?: boolean;
  dependOnCategories?: RefCategory[];
  placeholder?: string;
  description?: string;
  exampleUrl?: string;
};

export type CategorySearchFormData = {
  searchField: string;
  searchKeyword: string;
  masterIndexUid: string;
};

export interface KTCategory {
  hashId: string;
  id: number;
  hashUid: string;
  uid: number;
  name: string;
  thaiName: string;
  slug: string;
  imageUrl: string;
  description: string;
  placeholder: string;
  path: string;
  breadcrumbPath: string;
  titleMeta: string;
  descriptionMeta: string;
  searchSynonym: string;
  hashMasterIndexId: string;
  masterIndexId: number;
  hashMasterIndexUid: string;
  masterIndexUid: number;
  masterIndexName: string;
  masterIndexThaiName: string;
  masterIndexSlug: string;
  masterIndexAttributeDataType: KTMasterIndexAttributeDataType;
}

export interface KTMasterIndex {
  uid: number;
  name: string;
  thaiName: string;
  slug: string;
  attributeDataType: KTMasterIndexAttributeDataType;
  id: number;
  description: string;
  placeholder: string;
}

export interface KTCategoryPathItem {
  masterIndex: KTMasterIndex;
  disabledFilter?: boolean;
  optional?: boolean;
  placeholder?: string;
  exampleUrl?: string;
  dependOnCategories?: KTdependOnCategoryItem[];
}

export interface KTdependOnCategoryItem {
  canonicalUrlMeta: string;
  description: string;
  descriptionMeta: string;
  id: number;
  masterIndex: KTMasterIndex;
  name: string;
  searchSynonym: string;
  slug: string;
  thaiName: string;
  titleMeta: string;
  uid: number;
}
export interface KTRootCategory {
  esCategories: ESCategory[];
}

export type CategoryListSearchParams = CategorySearchFormData & AppTableConditions;

export type CategorySearchFilters = Partial<CategoryListSearchParams>;

export type BannerCategoryFormData = {
  bannerMobileImageUrl?: string;
  bannerMobileTitle?: string;
  bannerMobileLink?: string;
  bannerDesktopImageUrl?: string;
  bannerDesktopTitle?: string;
  bannerDesktopLink?: string;
};

export type BannerCategoryObjectType = {
  mobile?: {
    imageUrl?: string;
    title?: string;
    link?: string;
  };
  desktop?: {
    imageUrl?: string;
    title?: string;
    link?: string;
  };
};
