import React, { FC, useState, useCallback, useContext, useEffect } from 'react';

import { Box, Container, TableRow, Typography } from '@material-ui/core';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { AppGlobalUiContext } from '../../../../context/AppGlobalUiContext';
import { AppTableConditions, ReportedItem, ReportStatus, TableColumn } from '../../../../models';
import { approveReport, markAsDuplicatedReport, rejectReport } from '../../../../services/sns';
import useAppContainerStyles from '../../../../theme/container.style';
import useAppTableStyles from '../../../../theme/table.style';
import AppDatetimeText from '../../../ui/AppDatetimeText';
import AppDialog from '../../../ui/AppDialog';
import AppTableCell from '../../../ui/AppTableCell';
import AppTableList from '../../../ui/AppTableList';
import SnsItemPreviewDialog from '../../common/SnsItemPreviewDialog';
import ReportedStatusBadge from '../ReportedStatusBadge';

export type ReportedItemTableProps = {
  currentConditions: AppTableConditions;
  totalReportedItems: number;
  isLoadingData: boolean;
  reportedItemList: ReportedItem[];
  onTableConditionsChanged: (
    values: AppTableConditions & {
      firstVisibleDocId: string;
      lastVisibleDocId: string;
    },
  ) => void;
};

const reportedItemTableColumns: TableColumn[] = [
  { name: 'status', label: 'sns:list.reportedItem.columns.status', width: 150, unsortable: true },
  { name: 'type', label: 'sns:list.reportedItem.columns.type', width: 100, unsortable: true },
  { name: 'reportType', label: 'sns:list.reportedItem.columns.reportType', width: 140, unsortable: true },
  { name: 'content', label: 'sns:list.reportedItem.columns.content', width: 240, unsortable: true },
  { name: 'hashId', label: 'sns:list.reportedItem.columns.hashId', width: 140, unsortable: true },
  { name: 'reportedBy', label: 'sns:list.reportedItem.columns.reportedBy', width: 140, unsortable: true },
  { name: 'createdAt', label: 'sns:list.reportedItem.columns.reportedAt', width: 140, unsortable: true },
  { name: 'updatedAt', label: 'sns:list.reportedItem.columns.updatedAt', width: 170 },
];

const ReportedItemTable: FC<ReportedItemTableProps> = (props) => {
  const { t } = useTranslation();
  const { totalReportedItems, isLoadingData, currentConditions, reportedItemList, onTableConditionsChanged } = props;
  const containerClasses = useAppContainerStyles();
  const tableClasses = useAppTableStyles();
  const [tableData, setTableData] = useState<ReportedItem[]>([...reportedItemList]);
  const { setAppLoading, showSnackbar } = useContext(AppGlobalUiContext);
  const [tableConditions, setTableConditions] = useState<AppTableConditions>(currentConditions);
  const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState<boolean>(false);
  const [selectedPreviewItem, setSelectedPreviewItem] = useState<ReportedItem | undefined>(undefined);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [currentOperation, setCurrentOperation] = useState<'approve' | 'reject' | 'markAsDuplicated' | undefined>(
    undefined,
  );
  const [currentWorkingItem, setCurrentWorkingItem] = useState<ReportedItem | undefined>(undefined);
  const [currentWorkingRemark, setCurrentWorkingRemark] = useState<string>('');

  const onTableConditionChangedHandler = useCallback(
    (values: AppTableConditions) => {
      if (JSON.stringify(values) !== JSON.stringify(tableConditions)) {
        setTableConditions(values);

        let firstVisibleDocId = '';
        let lastVisibleDocId = '';

        if (values.page < tableConditions.page) {
          firstVisibleDocId = tableData[0].id;
        } else if (values.page > tableConditions.page) {
          lastVisibleDocId = tableData[tableData.length - 1].id;
        }

        if (typeof onTableConditionsChanged === 'function') {
          onTableConditionsChanged({
            ...values,
            firstVisibleDocId,
            lastVisibleDocId,
          });
        }
      }
    },
    [onTableConditionsChanged, tableConditions, tableData],
  );

  const openItemPreview = useCallback(async (item: ReportedItem) => {
    setSelectedPreviewItem(item);
    setIsPreviewDialogOpen(true);
  }, []);

  const openConfirmActionDialog = useCallback(() => {
    setIsConfirmDialogOpen(true);
  }, []);

  const approveClickHandler = useCallback((previewItem: ReportedItem, remark = '') => {
    setCurrentOperation('approve');
    setCurrentWorkingItem(previewItem);
    setCurrentWorkingRemark(remark);
    openConfirmActionDialog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rejectClickHandler = useCallback((previewItem: ReportedItem, remark = '') => {
    //
    setCurrentOperation('reject');
    setCurrentWorkingItem(previewItem);
    setCurrentWorkingRemark(remark);
    openConfirmActionDialog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const markDuplicationHandler = useCallback((previewItem: ReportedItem, remark = '') => {
    //
    setCurrentOperation('markAsDuplicated');
    setCurrentWorkingItem(previewItem);
    setCurrentWorkingRemark(remark);
    openConfirmActionDialog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const confirmOperation = useCallback(async () => {
    if (currentWorkingItem) {
      const {
        id: reportId,
        reportItem: { id: refId, replyToId, postId },
      } = currentWorkingItem;

      if (currentOperation === 'approve') {
        setAppLoading(true);

        const result = await approveReport(
          reportId,
          refId,
          replyToId ? 'comment' : 'post',
          currentWorkingRemark,
          postId,
        );

        setAppLoading(false);
        setIsConfirmDialogOpen(false);
        setCurrentOperation(undefined);
        setCurrentWorkingItem(undefined);
        setCurrentWorkingRemark('');
        setIsPreviewDialogOpen(false);

        if (result.data) {
          let updatedData = [...tableData];
          updatedData = updatedData.map((item) => {
            if (item.id === reportId) {
              return {
                ...item,
                remark: result.data as string,
                reportStatus: ReportStatus.Approved,
              };
            }

            return item;
          });
          setTableData(updatedData);
          showSnackbar(t('sns:message.report.approveSuccess').toString(), 'success');
        } else {
          showSnackbar(t('sns:message.report.approveFailed').toString(), 'error');
        }
      }

      if (currentOperation === 'reject') {
        setAppLoading(true);
        const result = await rejectReport(reportId, currentWorkingRemark);
        setAppLoading(false);
        setIsConfirmDialogOpen(false);
        setCurrentOperation(undefined);
        setCurrentWorkingItem(undefined);
        setCurrentWorkingRemark('');
        setIsPreviewDialogOpen(false);
        if (result.data) {
          let updatedData = [...tableData];
          updatedData = updatedData.map((item) => {
            if (item.id === reportId) {
              return {
                ...item,
                remark: result.data as string,
                reportStatus: ReportStatus.Rejected,
              };
            }

            return item;
          });
          setTableData(updatedData);
          showSnackbar(t('sns:message.report.rejectionSuccess').toString(), 'success');
        } else {
          showSnackbar(t('sns:message.report.rejectionFailed').toString(), 'error');
        }
      }

      if (currentOperation === 'markAsDuplicated') {
        setAppLoading(true);
        const result = await markAsDuplicatedReport(reportId, currentWorkingRemark);
        setAppLoading(false);
        setIsConfirmDialogOpen(false);
        setCurrentOperation(undefined);
        setCurrentWorkingItem(undefined);
        setCurrentWorkingRemark('');
        setIsPreviewDialogOpen(false);
        if (result.data) {
          let updatedData = [...tableData];
          updatedData = updatedData.map((item) => {
            if (item.id === reportId) {
              return {
                ...item,
                remark: result.data as string,
                reportStatus: ReportStatus.MarkAsDuplicated,
              };
            }

            return item;
          });
          setTableData(updatedData);
          showSnackbar(t('sns:message.report.markingDuplicationSuccess').toString(), 'success');
        } else {
          showSnackbar(t('sns:message.report.markingDuplicationFailed').toString(), 'error');
        }
      }
    }
  }, [currentOperation, currentWorkingItem, currentWorkingRemark, setAppLoading, showSnackbar, t, tableData]);

  useEffect(() => {
    setTableData([...reportedItemList]);
  }, [reportedItemList]);

  return (
    <>
      <Container className={containerClasses.container}>
        <Box className={tableClasses.tableContainer}>
          {!isLoadingData && (
            <Typography
              variant="subtitle2"
              color="textSecondary"
              style={{ marginBottom: '8px', marginRight: '8px', textAlign: 'right' }}>
              {t('common:list.resultTotal', { fieldName: t(reportedItemList.length.toLocaleString() || '0') })}
            </Typography>
          )}
          <AppTableList
            {...tableConditions}
            aria-label="reported-item-list"
            columns={reportedItemTableColumns}
            totalDataCount={totalReportedItems}
            hasPagination
            onTableConditionChanged={onTableConditionChangedHandler}
            isLoading={isLoadingData}
            tableBodyContent={
              tableData.length > 0 && (
                <>
                  {tableData.map((reportedItem: ReportedItem) => (
                    <TableRow
                      hover
                      key={reportedItem.id}
                      classes={{ root: clsx(tableClasses.tableRow, tableClasses.clickableTableRow) }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        openItemPreview(reportedItem);
                      }}>
                      <AppTableCell>
                        <ReportedStatusBadge status={reportedItem.reportStatus} />
                      </AppTableCell>
                      <AppTableCell>
                        {t(
                          `sns:common.fields.${
                            reportedItem.itemType === 'post' ? 'reportedItemTypePost' : 'reportedItemTypeComment'
                          }`,
                        )}
                      </AppTableCell>
                      <AppTableCell>{t(`sns:common.reportType.${reportedItem.reportType.toLowerCase()}`)}</AppTableCell>
                      <AppTableCell>
                        {reportedItem.reportItem.content ? reportedItem.reportItem.content : '-'}
                      </AppTableCell>

                      <AppTableCell>
                        {reportedItem.reportItem.author.displayName !== null ? (
                          <>
                            {reportedItem.reportItem.author.userHashId ? (
                              <a
                                href={`/v3/users/${reportedItem.reportItem.author.userHashId}`}
                                target="_blank"
                                rel="noreferrer"
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}>
                                {reportedItem.reportItem.author.displayName}
                              </a>
                            ) : (
                              <>{reportedItem.reportItem.author.displayName}</>
                            )}
                          </>
                        ) : (
                          '-'
                        )}
                      </AppTableCell>

                      <AppTableCell>
                        <a
                          href={`/v3/users/${reportedItem.reportedBy}`}
                          target="_blank"
                          rel="noreferrer"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}>
                          {reportedItem.reportedBy}
                        </a>
                      </AppTableCell>
                      <AppTableCell>
                        <AppDatetimeText
                          variant="subtitle2"
                          value={reportedItem.createdAt ? dayjs(reportedItem.createdAt) : null}
                          withTimeNewLine
                        />
                      </AppTableCell>
                      <AppTableCell>
                        <AppDatetimeText
                          variant="subtitle2"
                          value={reportedItem.updatedAt ? dayjs(reportedItem.updatedAt) : null}
                          withTimeNewLine
                        />
                      </AppTableCell>
                    </TableRow>
                  ))}
                </>
              )
            }
          />
        </Box>
      </Container>

      {selectedPreviewItem && (
        <SnsItemPreviewDialog
          open={isPreviewDialogOpen}
          onClose={() => {
            setIsPreviewDialogOpen(false);
          }}
          onCloseClick={() => {
            setIsPreviewDialogOpen(false);
          }}
          onDialogExited={() => {
            setSelectedPreviewItem(undefined);
          }}
          title={t('sns:title.itemPreview')}
          previewItem={selectedPreviewItem}
          onApproveClick={approveClickHandler}
          onRejectClick={rejectClickHandler}
          onMarkAsDuplicatedClick={markDuplicationHandler}
        />
      )}

      <AppDialog
        open={isConfirmDialogOpen}
        title="common:dialog.title.confirm"
        cancelButtonText="common:button.cancel"
        okButtonText="common:button.confirm"
        okButtonColor="warning"
        onClose={() => setIsConfirmDialogOpen(false)}
        onOkClick={confirmOperation}>
        {currentOperation === 'approve' && t('sns:message.report.approveConfirmation')}
        {currentOperation === 'reject' && t('sns:message.report.rejectConfirmation')}
        {currentOperation === 'markAsDuplicated' && t('sns:message.report.markDuplicationConfirmation')}
      </AppDialog>
    </>
  );
};

export default ReportedItemTable;
