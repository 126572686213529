/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable max-len */
import React, { FC, useEffect, useState, useCallback, useContext } from 'react';

import {
  Paper,
  Box,
  Grid,
  Divider,
  makeStyles,
  Avatar,
  Typography,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  RadioGroup,
  Radio,
  FormHelperText,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import dayjs from 'dayjs';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';

import { AppGlobalUiContext } from '../../../../context/AppGlobalUiContext';
import { TITLE_LISTING } from '../../../../helpers/constants';
import { getT2HSiteUrl, pickNotEmptyV2 } from '../../../../helpers/utils';
import {
  KTCategory,
  KTCategoryPathItem,
  KTMasterIndexAttributeDataType,
  ROOT_CATEGORY_MASTERINDEX_DATA,
  SelectItem,
} from '../../../../models';
import {
  LatestBoostResponse,
  ListingCleansingStatus,
  ListingCRStatus,
  ListingData,
  ListingFormData,
  ListingPossession,
  ListingStatus,
  PossessionInfo,
  SoldInfo,
} from '../../../../models/listing.model';
import { getEsCategoryBySlug } from '../../../../services';
import { getLatestBoostByHashId, getViewCountByHashId } from '../../../../services/listing';
import theme from '../../../../theme';
import useTypographyStyles from '../../../../theme/typography.style';
import AppButton from '../../../ui/AppButton';
import AppCheckbox from '../../../ui/AppCheckbox';
import AppFormControl from '../../../ui/AppFormControl';
import ImagePreview from '../../common/ImagePreview';
import ImageThumbnail from '../../common/ImageThumbnail';
import VideoThumbnail from '../../common/VideoThumbnail';
import FieldBrand from '../FieldBrand';
import FieldByAttributeDataType from '../FieldByAttributeDataType';
import FieldModel from '../FieldModel';
import FieldProvince from '../FieldProvince';
import ListingStatusBadge from '../ListingStatusBadge';
import ProductInfoFieldGroup from '../ProductInfoFieldGroup';

export type ListingDetailProps = {
  data: ListingData;
  rootCategory: KTCategory | null;
  isUserDeleted: boolean;
  onFormSubmit: (
    data: ListingFormData,
    isUpdateStatus: boolean,
    isUpdatePossession: boolean,
    rejectReason: string,
    soldInfo: SoldInfo,
    possessionInfo: PossessionInfo,
    isSendNotifyUserUnlisted: boolean,
  ) => void;
  onRootCategoryChange?: (data: { name: string; value: string; uid: number }) => void;
};

const MAX_TITLE_LENGTH = 300;
const MAX_DETAIL_LENGTH = 10000;
const MIN_SELLING_PRICE = 10;
const MIN_SELLING_PRICE_MAIN_CATEGORY = 100;
const MAX_SELLING_PRICE = 99999999;

const useStyles = makeStyles((themeStyle) => ({
  boxGrey: {
    padding: `${themeStyle.spacing(1)}px ${themeStyle.spacing(2)}px`,
    backgroundColor: themeStyle.palette.grey['200'],
  },
  priceLineThrough: {
    marginTop: -2,
    marginBottom: -8,
    textDecoration: 'line-through',
  },
  rightColumn: {
    borderTop: `solid 1px ${theme.palette.divider}`,
    borderLeft: 'none',
    [theme.breakpoints.up('md')]: {
      flexBasis: '460px',
      borderLeft: `solid 1px ${theme.palette.divider}`,
      borderTop: 'none',
      paddingLeft: 0,
      paddingTop: theme.spacing(2),
      maxWidth: '460px',
    },
  },
}));

// TODO: This component has been implemented as reusable component in another ticket.
// TODO: Need to refactor this in the future.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const NumberFormatCustom = (numberInputProps: any) => {
  const { inputRef, onChange, ...other } = numberInputProps;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            // eslint-disable-next-line react/destructuring-assignment
            name: numberInputProps.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      allowNegative={false}
    />
  );
};

const statusSelectList: SelectItem<string>[] = [
  {
    label: 'listing:common.status.published',
    value: ListingStatus.PUBLISHED,
  },
  {
    label: 'listing:common.status.rejected',
    value: ListingStatus.REJECTED,
  },
  {
    label: 'listing:common.status.draft',
    value: ListingStatus.DRAFT,
  },
  {
    label: 'listing:common.status.sold',
    value: ListingStatus.SOLD,
  },
  {
    label: 'listing:common.status.unlisted',
    value: ListingStatus.UNLISTED,
  },
  {
    label: 'listing:common.status.booked',
    value: ListingStatus.BOOKED,
  },
];

const possessionSelectList: SelectItem<string>[] = [
  {
    label: 'listing:common.possession.jkke_reset',
    value: ListingPossession.JkkeReset,
  },
  {
    label: 'listing:common.possession.jkke',
    value: ListingPossession.Jkke,
  },
  {
    label: 'listing:common.possession.jkke_rejected',
    value: ListingPossession.JkkeRejected,
  },
  {
    label: 'listing:common.possession.t2h_official',
    value: ListingPossession.T2hOfficial,
  },
  {
    label: 'listing:common.possession.t2h_official_consignment',
    value: ListingPossession.T2hOfficialConsignment,
  },
];

const crStatusSelectList: SelectItem<string>[] = [
  {
    label: 'listing:common.crStatus.notApproved',
    value: ListingCRStatus.NotApproved,
  },
  {
    label: 'listing:common.crStatus.pending',
    value: ListingCRStatus.Pending,
  },
  {
    label: 'listing:common.crStatus.approved',
    value: ListingCRStatus.Approved,
  },
];

const cleansingStatusSelectList: SelectItem<string>[] = [
  {
    label: 'listing:common.cleansingStatus.null',
    value: '',
  },
  {
    label: 'listing:common.cleansingStatus.checked',
    value: ListingCleansingStatus.Checked,
  },
  {
    label: 'listing:common.cleansingStatus.pending',
    value: ListingCleansingStatus.Pending,
  },
  {
    label: 'listing:common.cleansingStatus.trash',
    value: ListingCleansingStatus.Trash,
  },
];

const ListingDetail: FC<ListingDetailProps> = (props) => {
  const { data, rootCategory, isUserDeleted, onFormSubmit, onRootCategoryChange } = props;
  const { t, i18n } = useTranslation();

  const classes = useStyles();
  const typoClasses = useTypographyStyles();

  const [isEditingForm, setIsEditingForm] = useState<boolean>(!isUserDeleted);
  const [isSavingData] = useState<boolean>(false);
  const [isUpdateStatus, setIsUpdateStatus] = useState(true);
  const [isUpdatePossession, setIsUpdatePossession] = useState(true);

  const isEditingFormSubmit = isEditingForm && !isSavingData;

  const [selectedPreviewImage, setSelectedPreviewImage] = useState<string | undefined>(undefined);

  const dateDisplayFormat = i18n.language === 'th' ? 'D MMM BB / HH:mm:ss' : 'D MMM YY / HH:mm:ss';

  const createdAt = dayjs(data.createdAt).format(dateDisplayFormat);
  const updatedAt = data.updatedAt ? dayjs(data.updatedAt).format(dateDisplayFormat) : null;

  const [itemPathProvince, setItemPathProvince] = useState<KTCategoryPathItem>();
  const [itemPathBrand, setItemPathBrand] = useState<KTCategoryPathItem>();
  const [itemPathBrandText, setItemPathBrandText] = useState<KTCategoryPathItem>();
  const [itemPathModel, setItemPathModel] = useState<KTCategoryPathItem>();
  const [itemPathModelText, setItemPathModelText] = useState<KTCategoryPathItem>();
  const [itemPathModelName, setItemPathModelName] = useState<KTCategoryPathItem>();
  const [path, setPath] = useState<KTCategoryPathItem[]>([]);

  const [rejectReason, setRejectReason] = useState<string>(data.comment || 'ไม่ระบุเหตุผล');
  const [soldReason, setSoldReason] = useState<string>(data.soldReason || '');
  const [soldPrice, setSoldPrice] = useState<number>(data.sellingPrice || 0);

  const [possessionComment, setPossessionComment] = useState<string>(data.possessionComment || 'ไม่ระบุเหตุผล');

  const [mileageNA, setMileageNA] = useState<boolean>(!data.mileage);
  const [isCategoryMigrated, setIsCategoryMigrated] = useState<boolean>(true);
  const [latestBoostData, setLatestBoostData] = useState<LatestBoostResponse[]>([]);
  const [isSendNotifyUserUnlisted, setIsSendNotifyUserUnlisted] = useState<boolean>(true);
  const { setAppLoading } = useContext(AppGlobalUiContext);
  const [viewCount, setViewCount] = useState<number>(0);

  const defaultCategoryPath = data?.categoryPath
    ? Object.fromEntries((data.categoryPath || []).map((item: any) => [item.slug, item.value || item.valueNumber]))
    : {};

  const formHolder = useForm<ListingFormData & { [key: string]: any }>({
    mode: 'onSubmit',
    defaultValues: {
      hashId: data.hashId,
      categoryHashId: data.category?.hashId || '',
      rootCategoryHashId: rootCategory?.hashId || '',
      subCategoryHashId: data.subCategory?.hashId || '',
      spaCategoryHashId: data.spacialCategory?.hashId || '',
      brandHashId: data.brand?.hashId || '',
      modelHashId: data.model?.hashId || '',
      modelName: '',
      provinceHashId: data.province?.hashId || '',
      firstRegistrationYear: data.firstRegistrationYear || '',
      operatingHours: data.operatingHours || '',
      mileage: data.mileage || '',
      purchaseYear: data.purchaseYear || '',
      licencePlate: data.licencePlate || '',
      vin: data.vin || '',
      vehicleRegistrationBookFileHashIds:
        data.vehicleRegistrationBookImages?.length > 0
          ? data.vehicleRegistrationBookImages.map((item) => item.hashId)
          : [],
      title: data.title || '',
      detail: data.detail || '',
      imageHashIds: data.images?.length > 0 ? data.images.map((item) => item.hashId) : [],
      videoHashId: data.video?.hashId || '',
      markdownPrice: data.markdownPrice ?? '',
      status: data.status || '',
      documentNote: data.documentNote || '',
      crStatus: data.crStatus || ListingCRStatus.NotApproved,
      ...pickNotEmptyV2(defaultCategoryPath),
    },
  });

  const {
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors },
  } = formHolder;

  React.useEffect(() => {
    /* eslint-disable prefer-destructuring */
    const rootCategorySlug = rootCategory?.slug;
    if (rootCategorySlug) {
      (async () => {
        if (rootCategory) {
          setValue('category', rootCategory);
        }

        const categoryPath = (await getEsCategoryBySlug(rootCategorySlug))?.path;
        setValue('categoryPath', categoryPath);
        /* eslint-disable @typescript-eslint/no-explicit-any */
        const itemPathProvince =
          categoryPath?.find(
            (item: any) =>
              item.masterIndex?.attributeDataType === KTMasterIndexAttributeDataType.ATTRIBUTE_CHOICE_AS_PROVINCE,
          ) || undefined;
        if (itemPathProvince) {
          setItemPathProvince(itemPathProvince);
        }
        const itemPathBrand =
          categoryPath?.find(
            (item: any) => item.masterIndex?.attributeDataType === KTMasterIndexAttributeDataType.BRAND,
          ) || undefined;
        if (itemPathBrand) {
          setItemPathBrand(itemPathBrand);
        }
        const itemPathBrandText =
          categoryPath?.find(
            (item: any) => item.masterIndex?.attributeDataType === KTMasterIndexAttributeDataType.BRAND_TEXT,
          ) || undefined;
        if (itemPathBrandText) {
          setItemPathBrandText(itemPathBrandText);
        }
        const itemPathModel =
          categoryPath?.find(
            (item: any) => item.masterIndex?.attributeDataType === KTMasterIndexAttributeDataType.MODEL,
          ) || undefined;
        if (itemPathModel) {
          setItemPathModel(itemPathModel);
        }
        const itemPathModelText =
          categoryPath?.find(
            (item: any) => item.masterIndex?.attributeDataType === KTMasterIndexAttributeDataType.MODEL_TEXT,
          ) || undefined;
        if (itemPathModelText) {
          setItemPathModelText(itemPathModelText);
        }
        const itemPathModelName =
          categoryPath?.find(
            (item: any) => item.masterIndex?.attributeDataType === KTMasterIndexAttributeDataType.MODEL_NAME,
          ) || undefined;
        if (itemPathModelName) {
          setItemPathModelName(itemPathModelName);
        }

        const path = categoryPath
          ? categoryPath.filter(
              (item: any) =>
                item.masterIndex?.slug !== 'root-category' && // root category
                ![
                  KTMasterIndexAttributeDataType.ATTRIBUTE_CHOICE_AS_PROVINCE,
                  KTMasterIndexAttributeDataType.BRAND,
                  KTMasterIndexAttributeDataType.BRAND_TEXT,
                  KTMasterIndexAttributeDataType.MODEL,
                  KTMasterIndexAttributeDataType.MODEL_TEXT,
                  KTMasterIndexAttributeDataType.MODEL_NAME,
                ].includes(item.masterIndex?.attributeDataType),
            )
          : [];
        if (path) {
          setPath(path);
        }
      })();
    } else {
      setIsCategoryMigrated(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.categoryPath, rootCategory]);
  /* eslint-disable @typescript-eslint/no-explicit-any */

  const clearListingForm = () => {
    setValue('hashId', data.hashId);
    setValue('categoryHashId', data.category?.hashId || '');
    setValue('rootCategoryHashId', rootCategory?.hashId || '');
    setValue('subCategoryHashId', data.subCategory?.hashId || '');
    setValue('spaCategoryHashId', data.spacialCategory?.hashId || '');
    setValue('brandHashId', data.brand?.hashId || '');
    setValue('modelHashId', data.model?.hashId || '');
    setValue('provinceHashId', data.province?.hashId || '');
    setValue('firstRegistrationYear', data.firstRegistrationYear || '');
    setValue('operatingHours', data.operatingHours || '');
    setValue('mileage', data.mileage || '');
    setValue('purchaseYear', data.purchaseYear || '');
    setValue('licencePlate', data.licencePlate || '');
    setValue('vin', data.vin || '');
    setValue(
      'vehicleRegistrationBookFileHashIds',
      data.vehicleRegistrationBookImages?.length > 0
        ? data.vehicleRegistrationBookImages.map((item) => item.hashId)
        : [],
    );
    setValue('title', data.title || '');
    setValue('detail', data.detail || '');
    setValue('imageHashIds', data.images?.length > 0 ? data.images.map((item) => item.hashId) : []);
    setValue('videoHashId', data.video?.hashId || '');
    setValue('markdownPrice', data.markdownPrice || '');
    setIsEditingForm(false);
  };

  const onListingFormSubmit = (formData: ListingFormData) => {
    onFormSubmit(
      formData,
      isUpdateStatus,
      isUpdatePossession,
      rejectReason,
      {
        soldReason,
        soldPrice,
      },
      { possessionComment },
      isSendNotifyUserUnlisted,
    );

    setIsEditingForm(false);
  };

  const onListingFormError = (error: any) => {
    if (error) {
      const fieldFormData = Object.keys(error);
      const el = document.getElementById(fieldFormData[0]);

      if (el) {
        const headerOffset = 300;
        const topOffset = el.getBoundingClientRect().top;
        const offsetPosition = topOffset + window.scrollY - headerOffset;
        window.scrollTo({
          top: offsetPosition,
          left: 0,
          behavior: 'smooth',
        });
      }
    }
  };

  useEffect(() => {
    if (data.status !== watch('status')) {
      setIsUpdateStatus(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.status, watch('status')]);

  useEffect(() => {
    const dirtyPossession = watch('possession');

    if (data.possession !== dirtyPossession) {
      setIsUpdatePossession(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.possession, watch('possession')]);

  useEffect(() => {
    if (mileageNA) {
      setValue('mileage', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mileageNA]);

  useEffect(() => {
    const mileage = watch('mileage');
    if (mileage && mileageNA) {
      setMileageNA(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('mileage')]);

  const getLatestBoost = useCallback(async () => {
    setAppLoading(true);
    const result = await getLatestBoostByHashId(data.hashId);
    if (result.data) {
      setAppLoading(false);
      setLatestBoostData(result.data);
    } else {
      setAppLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.hashId]);

  const getViewCount = useCallback(async () => {
    const result = await getViewCountByHashId(data.hashId);

    if (result.data) {
      setViewCount(result.data[0].viewCount);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.hashId]);

  useEffect(() => {
    getLatestBoost();
    getViewCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatLatestBoostedAt =
    latestBoostData.length > 0 && dayjs(latestBoostData[0].latestBoostedAt).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

  const isMainCategory = [
    'truck',
    'truck-body-trailer',
    'heavy-machinery',
    'agricultural-machinery',
    'pickup',
    'passenger-car',
    'van',
    'motorbike',
  ].includes(rootCategory?.slug || '')
    ? MIN_SELLING_PRICE_MAIN_CATEGORY
    : MIN_SELLING_PRICE;

  const modelTextData = (data.categoryPath || []).filter((item) => item.slug === 'model-text');
  const isModelTypeText = modelTextData.length > 0;

  const isBrandTypeText = data.brandName === null || data.brandName === '';

  return (
    <>
      <Paper elevation={0} square>
        <Grid container direction="row">
          <Grid item xs={12} md zeroMinWidth>
            <Box
              p={3}
              position="relative"
              style={selectedPreviewImage ? { maxHeight: '100vh', overflow: 'hidden' } : {}}>
              <Box display="flex" justifyContent="flex-end">
                <ListingStatusBadge status={data.status} />
              </Box>
              {isUserDeleted && (
                <Box pl={1} flex={1}>
                  <Typography color="error" className={typoClasses.textWeightBold} variant="caption">
                    {t('listing:common.message.deletedUser')}
                  </Typography>
                </Box>
              )}
              <Box display="flex" alignItems="center">
                <Avatar alt={data.user.displayName} src={data.user.profileImageUrl} />
                <Box pl={1} flex={1}>
                  <Typography className={typoClasses.textWeightBold} variant="h6">
                    {data.user.displayName}
                  </Typography>
                </Box>
              </Box>
              <Box mt={3}>
                <Grid container direction="row">
                  <Grid item xs={12} md>
                    <Box px={2} pb={1} display="grid" gridGap={theme.spacing(1)}>
                      <Grid container direction="row">
                        <Grid item xs="auto">
                          {t('listing:common.fields.createdAt')} :
                        </Grid>
                        <Grid item xs style={{ textAlign: 'right' }}>
                          {createdAt}
                        </Grid>
                      </Grid>
                      <Grid container direction="row">
                        <Grid item xs="auto">
                          {t('listing:common.fields.updatedAt')} :
                        </Grid>
                        <Grid item xs style={{ textAlign: 'right' }}>
                          {updatedAt}
                        </Grid>
                      </Grid>
                      <Grid container direction="row">
                        <Grid item xs="auto">
                          {t('listing:common.fields.favoriteCount')} :
                        </Grid>
                        <Grid item xs style={{ textAlign: 'right' }}>
                          {data.favoriteCount ? data.favoriteCount?.toLocaleString() : 0}
                        </Grid>
                      </Grid>
                      <Grid container direction="row">
                        <Grid item xs="auto">
                          {t('listing:common.fields.viewCount')} :
                        </Grid>
                        <Grid item xs style={{ textAlign: 'right' }}>
                          {viewCount ? viewCount?.toLocaleString() : 0}
                        </Grid>
                      </Grid>
                      <Grid container direction="row">
                        <Grid item xs="auto">
                          {t('listing:common.fields.boosts')} :
                        </Grid>
                        <Grid item xs style={{ textAlign: 'right' }}>
                          {latestBoostData.length > 0 ? latestBoostData[0].boostedCount : 0}
                        </Grid>
                      </Grid>
                      <Grid container direction="row">
                        <Grid item xs="auto">
                          {t('listing:common.fields.latestBoostedAt')} :
                        </Grid>
                        <Grid item xs style={{ textAlign: 'right' }}>
                          {latestBoostData.length > 0 && latestBoostData[0].latestBoostedAt && formatLatestBoostedAt
                            ? dayjs(formatLatestBoostedAt).format(`${dateDisplayFormat}`)
                            : '-'}
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Divider orientation="vertical" flexItem />
                  <Grid item xs={12} md>
                    <Box px={2} pb={1} display="grid" gridGap={theme.spacing(1)}>
                      <Grid container direction="row">
                        <Grid item xs="auto">
                          {t('listing:common.fields.userId')} :
                        </Grid>
                        <Grid item xs style={{ textAlign: 'right' }}>
                          <a href={`/v3/users/${data.user?.hashId}`} target="_blank" rel="noreferrer">
                            {data.user?.hashId}
                          </a>
                        </Grid>
                      </Grid>
                      <Grid container direction="row">
                        <Grid item xs="auto">
                          {t('listing:common.fields.itemId')} :
                        </Grid>
                        <Grid item xs style={{ textAlign: 'right' }}>
                          {data.hashId}
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box
                mt={3}
                className={classes.boxGrey}
                borderRadius="borderRadius"
                maxWidth="100%"
                style={{ wordWrap: 'break-word' }}>
                {t('listing:common.postUrl')} :{' '}
                <a
                  href={getT2HSiteUrl(`/listing/${data.hashId}`)}
                  target="_blank"
                  rel="noreferrer"
                  style={{ marginLeft: theme.spacing(1), wordBreak: 'break-word' }}>
                  {getT2HSiteUrl(`/listing/${data.hashId}`)}
                </a>
              </Box>
              <Box
                mt={1}
                className={classes.boxGrey}
                borderRadius="borderRadius"
                maxWidth="100%"
                style={{ wordWrap: 'break-word' }}>
                <Typography className={typoClasses.textWeightBold} variant="h6">
                  {data.title}
                </Typography>
                {data.detail && (
                  <Box mt={1}>
                    {data.detail?.split('\n').map((itemDetail, indexDetail) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Typography key={indexDetail} variant="body1" gutterBottom style={{ wordBreak: 'break-word' }}>
                        {itemDetail}
                      </Typography>
                    ))}
                  </Box>
                )}
              </Box>
              <Box
                mt={1}
                className={classes.boxGrey}
                display="flex"
                alignItems="flex-end"
                justifyContent="space-between"
                borderRadius="borderRadius">
                <Typography variant="h6">{t('listing:common.fields.sellingPrice')} :</Typography>
                {data.markdownPrice !== data.sellingPrice ? (
                  <Box>
                    <Typography className={classes.priceLineThrough} variant="subtitle2" style={{ textAlign: 'right' }}>
                      {data.sellingPrice ? data.sellingPrice?.toLocaleString() : ''}
                    </Typography>
                    <Typography
                      className={typoClasses.textWeightBold}
                      variant="h6"
                      color="secondary"
                      style={{ textAlign: 'right' }}>
                      {data.markdownPrice ? data.markdownPrice?.toLocaleString() : 0}
                    </Typography>
                  </Box>
                ) : (
                  <Typography
                    className={typoClasses.textWeightBold}
                    variant="h6"
                    color="secondary"
                    style={{ textAlign: 'right' }}>
                    {data.sellingPrice ? data.sellingPrice?.toLocaleString() : ''}
                  </Typography>
                )}
              </Box>
              <Box mt={2}>
                <Grid container spacing={1}>
                  {data.imageUrl && (
                    <Grid item xs={6}>
                      <Box onClick={() => setSelectedPreviewImage(data.imageUrl)}>
                        <ImageThumbnail
                          imageUrl={data.imageUrl}
                          altText={t('listing:common.fields.imageCover')}
                          listingHashId={data.hashId}
                          thumbnailLabel={t('listing:common.fields.imageCover')}
                          typeListing
                        />
                      </Box>
                    </Grid>
                  )}
                  {data.video && (
                    <Grid item xs={6}>
                      <VideoThumbnail
                        videoUrl={data.video.url}
                        listingHashId={data.hashId}
                        thumbnailLabel={t('listing:common.fields.video')}
                      />
                    </Grid>
                  )}
                </Grid>
              </Box>
              {data.images?.length > 1 && (
                <Box mt={2}>
                  <Grid container spacing={1}>
                    {data.images.map((item, index) => (
                      <Grid key={item.hashId} item xs={6} sm={4}>
                        <Box onClick={() => setSelectedPreviewImage(item.url)}>
                          <ImageThumbnail
                            imageUrl={item.url}
                            listingHashId={item.hashId}
                            thumbnailLabel={`${index + 1}. ${t('listing:common.fields.imageProduct')}`}
                            typeListing
                          />
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              )}
              {data.vehicleRegistrationBookImages?.length > 0 && (
                <Box mt={2}>
                  <Grid container spacing={1}>
                    {data.vehicleRegistrationBookImages.map((item, index) => (
                      <Grid key={item.hashId} item xs={6} sm={4}>
                        <Box onClick={() => setSelectedPreviewImage(item.url)}>
                          <ImageThumbnail
                            imageUrl={item.url}
                            listingHashId={item.hashId}
                            thumbnailLabel={`${index + 1}. ${t('listing:common.fields.imageVehicleRegistrationBook')}`}
                          />
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              )}

              {!!selectedPreviewImage && (
                <ImagePreview imageUrl={selectedPreviewImage} onCloseClick={() => setSelectedPreviewImage(undefined)} />
              )}
            </Box>
          </Grid>

          <Grid item xs={12} className={classes.rightColumn}>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="listing-v2-content"
                id="listing-v2-header">
                <Box>
                  <Typography className={typoClasses.textWeightBold}>
                    {t('listing:common.productDetail')} v.2
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <form onSubmit={handleSubmit(onListingFormSubmit, onListingFormError)}>
                  {!isEditingForm && isCategoryMigrated && (
                    <Box mb={2} display="flex" justifyContent="flex-end">
                      <AppButton
                        type="button"
                        variant="outlined"
                        color="primary"
                        onClick={() => setIsEditingForm(true)}>
                        {t('common:button.edit')}
                      </AppButton>
                    </Box>
                  )}
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        name="title"
                        defaultValue={data.title}
                        rules={{
                          required: true,
                          maxLength: MAX_TITLE_LENGTH,
                          // eslint-disable-next-line consistent-return
                          validate: async (value) => {
                            if (TITLE_LISTING.test(value || '')) {
                              return `ไม่สามารถใช้ อักขระพิเศษ ${TITLE_LISTING.source}`;
                            }
                          },
                        }}
                        render={({ field }) => (
                          <AppFormControl error={!!errors.title}>
                            <TextField
                              {...field}
                              id="title"
                              label={t('listing:common.fields.title')}
                              variant="outlined"
                              fullWidth
                              disabled={!isEditingForm}
                              error={!!errors.title}
                            />
                            {!!errors.title && (
                              <FormHelperText error>
                                {errors.title.type === 'required' &&
                                  t('validation:requiredField', {
                                    fieldName: t('listing:common.fields.title').toString(),
                                  })}
                                {errors.title.type === 'validate' &&
                                  t('validation:invalidFieldFormat', {
                                    fieldName: t('listing:common.fields.title').toString(),
                                    suggestion: t('listing:error.detail.titleListingFormat').toString(),
                                  })}
                                {errors.title.type === 'maxLength' &&
                                  t('listing:error:detail.detailListingFormat', {
                                    max: MAX_TITLE_LENGTH.toString(),
                                  })}
                              </FormHelperText>
                            )}
                          </AppFormControl>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        name="detail"
                        defaultValue={data.detail || ''}
                        rules={{
                          required: true,
                          maxLength: MAX_DETAIL_LENGTH,
                        }}
                        render={({ field }) => (
                          <AppFormControl error={!!errors.detail}>
                            <TextField
                              {...field}
                              id="detail"
                              multiline
                              rows={6}
                              label={t('listing:common.fields.detail')}
                              variant="outlined"
                              disabled={!isEditingForm}
                              error={!!errors.detail}
                            />
                            {!!errors.detail && (
                              <FormHelperText error>
                                {errors.detail.type === 'required' &&
                                  t('validation:requiredField', {
                                    fieldName: t('listing:common.fields.detail').toString(),
                                  })}
                                {errors.detail.type === 'maxLength' &&
                                  t('listing:error:detail.detailListingFormat', {
                                    max: MAX_DETAIL_LENGTH.toString(),
                                  })}
                              </FormHelperText>
                            )}
                          </AppFormControl>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        name="sellingPrice"
                        defaultValue={data.sellingPrice || ''}
                        rules={{
                          required: true,
                          validate: async (value) => {
                            if (value && Number(value) < isMainCategory) {
                              return `ต้องอยู่ระหว่าง ${MIN_SELLING_PRICE_MAIN_CATEGORY} ถึง ${MAX_SELLING_PRICE.toLocaleString()}`;
                            }
                            if (value && Number(value) > MAX_SELLING_PRICE) {
                              return `ต้องอยู่ระหว่าง ${MIN_SELLING_PRICE_MAIN_CATEGORY} ถึง ${MAX_SELLING_PRICE.toLocaleString()}`;
                            }
                            return true;
                          },
                        }}
                        render={({ field }) => (
                          <AppFormControl error={!!errors.sellingPrice}>
                            <TextField
                              {...field}
                              margin="dense"
                              onWheel={(event) => {
                                event.currentTarget.querySelector('input')?.blur();
                              }}
                              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                              id="sellingPrice"
                              type="number"
                              label={t('listing:common.fields.sellingPrice')}
                              variant="outlined"
                              fullWidth
                              disabled={!isEditingForm}
                              error={!!errors.sellingPrice}
                            />
                            {!!errors.sellingPrice && (
                              <FormHelperText error>
                                {errors.sellingPrice.type === 'required' &&
                                  t('validation:requiredField', {
                                    fieldName: t('listing:common.fields.sellingPrice').toString(),
                                  })}
                                {(errors.sellingPrice.type === 'validate' ||
                                  errors.sellingPrice.type === 'maxLength') &&
                                  t('listing:error.detail.sellingPrice.between', {
                                    min: isMainCategory,
                                    max: MAX_SELLING_PRICE.toString(),
                                  })}
                              </FormHelperText>
                            )}
                          </AppFormControl>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        name="markdownPrice"
                        defaultValue={data.markdownPrice || ''}
                        render={({ field }) => (
                          <AppFormControl>
                            <TextField
                              {...field}
                              margin="dense"
                              onWheel={(event) => {
                                event.currentTarget.querySelector('input')?.blur();
                              }}
                              id="price"
                              type="number"
                              InputProps={{ inputProps: { min: 0 } }}
                              label={t('listing:common.fields.markdownPrice')}
                              variant="outlined"
                              fullWidth
                              disabled={!isEditingForm}
                            />
                          </AppFormControl>
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Box mt={3}>
                    <Grid container>
                      <Typography className={typoClasses.textWeightBold}>
                        ข้อมูล Category Path จาก MasterData:
                      </Typography>
                      {itemPathProvince && (
                        <FieldProvince
                          itemPathProvince={itemPathProvince}
                          defaultProvinceUid={watch(itemPathProvince.masterIndex.slug)}
                          formHolder={formHolder}
                          isEditingForm={isEditingForm}
                        />
                      )}
                      <Grid item xs={12} style={{ marginBottom: '16px' }}>
                        {/* Root Category */}
                        <FieldByAttributeDataType
                          key={`category_${rootCategory?.id}`}
                          value={rootCategory?.thaiName || ''}
                          itemPath={
                            {
                              masterIndex: rootCategory
                                ? {
                                    uid: rootCategory?.masterIndexUid,
                                    name: rootCategory?.masterIndexName,
                                    thaiName: rootCategory?.masterIndexThaiName,
                                    slug: rootCategory?.masterIndexSlug,
                                    attributeDataType: rootCategory?.masterIndexAttributeDataType,
                                    id: rootCategory?.masterIndexId,
                                    description: '',
                                    placeholder: '',
                                  }
                                : ROOT_CATEGORY_MASTERINDEX_DATA,
                              dependOnCategories: [],
                            } as KTCategoryPathItem
                          }
                          path={path}
                          formHolder={formHolder}
                          defaultValue={rootCategory?.thaiName || ''}
                          isEditingForm={isEditingForm}
                          onSetValue={(data: any) =>
                            typeof onRootCategoryChange === 'function' && onRootCategoryChange(data)
                          }
                        />
                        {/* End Root Category */}
                      </Grid>
                      {rootCategory?.uid && (
                        <Grid item xs={12}>
                          <FieldBrand
                            key={`brand_${rootCategory?.id}`}
                            rootCategoryUid={rootCategory?.uid}
                            itemPathBrand={itemPathBrand}
                            itemPathBrandText={itemPathBrandText}
                            currentValueBrand={
                              itemPathBrand?.masterIndex?.slug ? watch(itemPathBrand?.masterIndex?.slug) : ''
                            }
                            formHolder={formHolder}
                            isEditingForm={isEditingForm}
                            isBrandText={isBrandTypeText}
                          />
                        </Grid>
                      )}
                      {rootCategory?.id && (
                        <Grid item xs={12}>
                          <FieldModel
                            key={`model_${rootCategory?.id}`}
                            rootCategoryId={rootCategory?.id}
                            brandSlug={watch('brand')}
                            itemPathModel={itemPathModel}
                            itemPathModelText={itemPathModelText}
                            itemPathModelName={itemPathModelName}
                            currentValueModelGroup={
                              itemPathModel?.masterIndex?.slug ? watch(itemPathModel?.masterIndex?.slug) : ''
                            }
                            currentValueModel={
                              itemPathModelName?.masterIndex?.slug ? watch(itemPathModelName?.masterIndex?.slug) : ''
                            }
                            formHolder={formHolder}
                            isEditingForm={isEditingForm}
                            isModelTypeText={isModelTypeText}
                          />
                        </Grid>
                      )}
                      {path && (
                        <Grid item xs={12}>
                          <ProductInfoFieldGroup path={path} formHolder={formHolder} isEditingForm={isEditingForm} />
                        </Grid>
                      )}
                    </Grid>
                  </Box>

                  <Box mt={3}>
                    <Typography className={typoClasses.textWeightBold}>{t('listing:common.fields.status')}</Typography>
                    <Box mt={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Controller
                            control={control}
                            name="status"
                            defaultValue={data.status}
                            render={({ field }) => (
                              <AppFormControl>
                                <InputLabel id="status-label">{t('listing:common.fields.status')}</InputLabel>
                                <Select
                                  disabled={!isEditingForm}
                                  labelId="status-label"
                                  id="status"
                                  value={field.value}
                                  variant="outlined"
                                  onChange={field.onChange}
                                  fullWidth>
                                  {statusSelectList?.length > 0 &&
                                    statusSelectList.map((item) => (
                                      <MenuItem value={item.value} key={item.value}>
                                        {t(item.label)}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </AppFormControl>
                            )}
                          />
                        </Grid>
                        {watch('status') === ListingStatus.UNLISTED && (
                          <Grid item xs={12}>
                            <TextField
                              defaultValue={t('listing:common.fields.unlistedReasonValue')}
                              label={t('listing:common.fields.unlistedReason')}
                              variant="outlined"
                              fullWidth
                              disabled
                            />
                            <Controller
                              name="notifyUserUnlistedItem"
                              control={control}
                              rules={{
                                required: false,
                              }}
                              render={({ field }) => (
                                <AppFormControl>
                                  <FormControlLabel
                                    control={
                                      <AppCheckbox
                                        name={t('listing:common.fields.notifyUserUnlisted')}
                                        checked={isSendNotifyUserUnlisted}
                                        onChange={(e) => {
                                          field.onChange(e.currentTarget.checked);
                                          setIsSendNotifyUserUnlisted(e.currentTarget.checked);
                                        }}
                                        color="default"
                                      />
                                    }
                                    label={t('listing:common.fields.notifyUserUnlisted')}
                                  />
                                </AppFormControl>
                              )}
                            />
                          </Grid>
                        )}
                        {watch('status') === ListingStatus.REJECTED && (
                          <Grid item xs={12}>
                            <TextField
                              defaultValue={rejectReason}
                              id="rejectReason"
                              label={t('listing:common.fields.rejectReason')}
                              variant="outlined"
                              fullWidth
                              disabled={!isEditingForm}
                              onChange={(e) => {
                                setRejectReason(e.target.value);
                              }}
                            />
                          </Grid>
                        )}
                        {watch('status') === ListingStatus.SOLD && (
                          <Grid item xs={12}>
                            <TextField
                              defaultValue={soldReason}
                              margin="dense"
                              id="soldReason"
                              label={t('listing:common.fields.soldReason')}
                              variant="outlined"
                              fullWidth
                              disabled
                              onChange={(e) => {
                                setSoldReason(e.target.value);
                              }}
                            />
                            <Box mt={2}>
                              <TextField
                                defaultValue={soldPrice}
                                margin="dense"
                                id="soldPrice"
                                label={t('listing:common.fields.soldPrice')}
                                variant="outlined"
                                fullWidth
                                disabled={!isEditingForm}
                                InputProps={{ inputComponent: NumberFormatCustom }}
                                onChange={(e) => {
                                  setSoldPrice(Number(e.target.value || '0'));
                                }}
                              />
                            </Box>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  </Box>
                  <Box mt={3}>
                    <Typography className={typoClasses.textWeightBold}>
                      {t('listing:common.fields.possession')}
                    </Typography>
                    <Box mt={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Controller
                            control={control}
                            name="possession"
                            defaultValue={data.possession || ListingPossession.JkkeReset}
                            render={({ field }) => (
                              <AppFormControl>
                                <InputLabel id="status-label">{t('listing:common.fields.possession')}</InputLabel>
                                <Select
                                  disabled={!isEditingForm}
                                  labelId="status-label"
                                  id="possession"
                                  value={field.value}
                                  variant="outlined"
                                  onChange={field.onChange}
                                  fullWidth>
                                  {possessionSelectList?.length > 0 &&
                                    possessionSelectList.map((item) => (
                                      <MenuItem value={item.value} key={item.value}>
                                        {t(item.label)}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </AppFormControl>
                            )}
                          />
                        </Grid>
                        {[ListingPossession.JkkeRejected, ListingPossession.T2hOfficialRejected].includes(
                          watch('possession'),
                        ) && (
                          <Grid item xs={12}>
                            <TextField
                              defaultValue={possessionComment}
                              id="possessionComment"
                              label={t('listing:common.fields.rejectReason')}
                              variant="outlined"
                              fullWidth
                              disabled={!isEditingForm}
                              onChange={(e) => {
                                setPossessionComment(e.target.value);
                              }}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  </Box>
                  <Box mt={3}>
                    <Typography className={typoClasses.textWeightBold}>
                      {t('listing:common.fields.crStatus')}
                    </Typography>
                    <Box mt={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Controller
                            control={control}
                            name="crStatus"
                            defaultValue={data.crStatus || ListingCRStatus.NotApproved}
                            render={({ field }) => (
                              <RadioGroup row value={field.value}>
                                {crStatusSelectList?.length > 0 &&
                                  crStatusSelectList.map((item) => (
                                    <FormControlLabel
                                      key={item.value}
                                      value={item.value}
                                      control={
                                        <Radio
                                          name={item.value}
                                          onChange={field.onChange}
                                          color="primary"
                                          disabled={!isEditingForm}
                                        />
                                      }
                                      label={t(item.label)}
                                      labelPlacement="end"
                                    />
                                  ))}
                              </RadioGroup>
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Typography className={typoClasses.textWeightBold}>
                      {t('listing:common.fields.cleansingStatus')}
                    </Typography>
                    <Box mt={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Controller
                            control={control}
                            name="cleansingStatus"
                            defaultValue={data.cleansingStatus}
                            render={({ field }) => (
                              <RadioGroup row value={field.value}>
                                {cleansingStatusSelectList?.length > 0 &&
                                  cleansingStatusSelectList.map((item) => (
                                    <FormControlLabel
                                      value={item.value}
                                      key={item.value}
                                      control={
                                        <Radio
                                          name={item.value}
                                          onChange={field.onChange}
                                          color="primary"
                                          disabled={!isEditingForm}
                                        />
                                      }
                                      label={t(item.label)}
                                      labelPlacement="end"
                                    />
                                  ))}
                              </RadioGroup>
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                  <Box mt={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Controller
                          control={control}
                          name="documentNote"
                          defaultValue={data.documentNote || ''}
                          render={({ field }) => (
                            <AppFormControl>
                              <TextField
                                {...field}
                                id="documentNote"
                                value={field.value || ''}
                                multiline
                                rows={6}
                                label={t('listing:common.fields.documentNote')}
                                variant="outlined"
                                disabled={!isEditingForm}
                              />
                            </AppFormControl>
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  {isEditingForm && (
                    <Box mt={3} display="flex" justifyContent="flex-end">
                      <AppButton type="button" variant="outlined" color="default" onClick={clearListingForm}>
                        {t('common:button.cancel')}
                      </AppButton>
                      <Box ml={2}>
                        <AppButton type="submit" color="primary" disabled={!isEditingFormSubmit}>
                          {t('common:button.save')}
                        </AppButton>
                      </Box>
                    </Box>
                  )}
                </form>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default ListingDetail;
