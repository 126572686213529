import React, { FC, ReactNode } from 'react';

import { FormHelperText, TextField } from '@material-ui/core';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Controller, UnpackNestedValue, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import WebConfigListItemEditor from './WebConfigListItemEditor';
import usePrivilege from '../../../../hooks/usePrivilege';
import { FileItem, HotItem, TableColumn, WebConfigEditFormProps } from '../../../../models';
import AppDatePicker from '../../../ui/AppDatePicker';
import AppFormControl from '../../../ui/AppFormControl';
import AppMediaSelector from '../../../ui/AppMediaSelector';
import AppTableCell from '../../../ui/AppTableCell';

dayjs.extend(customParseFormat);

const tableColumns: TableColumn[] = [
  { name: 'title', label: 'settings:list.hotItems.title', width: 150, unsortable: true },
  { name: 'url', label: 'settings:list.hotItems.url', unsortable: true },
  { name: 'imageUrl', label: 'settings:list.hotItems.imageUrl', unsortable: true },
  { name: 'createDateTime', label: 'settings:list.hotItems.createDateTime', width: 150, unsortable: true },
];

type HotItemFormData = Omit<HotItem, 'createDateTime'> & {
  createDateTime: dayjs.Dayjs | null;
};

const WebConfigHotItems: FC<WebConfigEditFormProps> = (props) => {
  const { configItem, onSave } = props;
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    setValue,
    trigger,
    clearErrors,
    formState: { errors },
  } = useForm<HotItemFormData>({
    mode: 'onSubmit',
    defaultValues: {
      title: '',
      url: '',
      imageUrl: '',
      createDateTime: null,
    },
  });
  const { canPerform } = usePrivilege();
  const canUpdateWebConfig = canPerform('webConfig', 'update');

  const tableCellRenderer = (item: HotItem): ReactNode => (
    <>
      <AppTableCell>{item.title}</AppTableCell>
      <AppTableCell>{item.url}</AppTableCell>
      <AppTableCell>{item.imageUrl}</AppTableCell>
      <AppTableCell>{item.createDateTime}</AppTableCell>
    </>
  );

  const editFormContent = (item: HotItem | undefined) => (
    <form>
      <Controller
        defaultValue={item?.title}
        control={control}
        name="title"
        rules={{ required: true }}
        render={({ field }) => (
          <AppFormControl boxProps={{ mb: 3 }} error={!!errors.title}>
            <TextField
              {...field}
              variant="outlined"
              label={t('settings:list.hotItems.title').toString()}
              error={!!errors.title}
              disabled={!canUpdateWebConfig}
            />
            {!!errors.title && (
              <FormHelperText error>
                {t('validation:requiredFieldAlt', {
                  fieldName: t('settings:list.hotItems.title').toString(),
                })}
              </FormHelperText>
            )}
          </AppFormControl>
        )}
      />
      <Controller
        defaultValue={item?.url}
        control={control}
        name="url"
        rules={{ required: true }}
        render={({ field }) => (
          <AppFormControl boxProps={{ mb: 3 }} error={!!errors.url}>
            <TextField
              {...field}
              variant="outlined"
              label={t('settings:list.hotItems.url').toString()}
              error={!!errors.url}
              disabled={!canUpdateWebConfig}
            />
            {!!errors.url && (
              <FormHelperText error>
                {t('validation:requiredFieldAlt', {
                  fieldName: t('settings:list.hotItems.url').toString(),
                })}
              </FormHelperText>
            )}
          </AppFormControl>
        )}
      />
      <Controller
        defaultValue={item?.imageUrl}
        control={control}
        name="imageUrl"
        rules={{ required: true }}
        render={({ field }) => (
          <AppFormControl boxProps={{ mb: 3 }} error={!!errors.imageUrl}>
            <AppMediaSelector
              defaultValue={field.value}
              mode="input"
              inputProps={{
                label: t('settings:list.hotItems.imageUrl').toString(),
              }}
              onFilesSelected={(files: FileItem[]) => {
                if (files.length) {
                  field.onChange(files[0].fullUrl);
                }
              }}
              onValueCleared={() => field.onChange('')}
            />
            {!!errors.imageUrl && (
              <FormHelperText error>
                {t('validation:requiredFieldAlt', {
                  fieldName: t('settings:list.hotItems.imageUrl').toString(),
                })}
              </FormHelperText>
            )}
          </AppFormControl>
        )}
      />
      <Controller
        control={control}
        name="createDateTime"
        rules={{ required: true }}
        render={({ field }) => (
          <>
            <AppDatePicker
              value={field.value?.format('YYYY-MM-DD')}
              onChange={field.onChange}
              placeholder={t('settings:list.hotItems.createDateTime')}
              error={!!errors.createDateTime}
              disabled={!canUpdateWebConfig}
            />
            {!!errors.createDateTime && (
              <FormHelperText error>
                {t('validation:requiredFieldAlt', {
                  fieldName: t('settings:list.hotItems.createDateTime').toString(),
                })}
              </FormHelperText>
            )}
          </>
        )}
      />
    </form>
  );

  const itemTransformer = (data: UnpackNestedValue<HotItemFormData>): HotItem => ({
    ...data,
    createDateTime: data.createDateTime ? data.createDateTime?.format('D MMM BB') : '',
  });

  const setFormValues = (item: HotItem | undefined) => {
    clearErrors();
    setValue('title', item?.title || '');
    setValue('url', item?.url || '');
    setValue('imageUrl', item?.imageUrl || '');
    setValue('createDateTime', item?.createDateTime ? dayjs(item.createDateTime, 'D MMM BB') : null);
    if (item) {
      trigger();
    }
  };

  return (
    <WebConfigListItemEditor<HotItem, HotItemFormData>
      configItem={configItem}
      onSave={onSave}
      listTableRowKeyPrefix="hot-item-"
      listColumns={tableColumns}
      listTableCellsContent={tableCellRenderer}
      dialogFormContent={editFormContent}
      handleSubmit={handleSubmit}
      dialogSaveItemTransformer={itemTransformer}
      setEditFormValues={setFormValues}
    />
  );
};

export default WebConfigHotItems;
