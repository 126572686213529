import React, { FC } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Grid,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import YoutubeSearchedForIcon from '@material-ui/icons/YoutubeSearchedFor';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import useAuthContext from '../../../../../hooks/useAuthContext';
import {
  UserRoleTC,
  SelectItem,
  UserGeneralWorkflow,
  UserListSearchFormDataTC,
  UserStateWorkflow,
} from '../../../../../models';
import useAppContainerStyles from '../../../../../theme/container.style';
import AppButton from '../../../../ui/AppButton';
import AppChipList from '../../../../ui/AppChipList';
import AppFormControl from '../../../../ui/AppFormControl';
import AppFormControlGroup from '../../../../ui/AppFormControlGroup';
import UserReceiveTpgEmailData from '../UserReceiveTpgEmail';

export type UserListSearchFormProps = {
  isLoadingData?: boolean;
  currentFilters: UserListSearchFormDataTC | undefined;
  onSearchFormSubmit: (values: UserListSearchFormDataTC) => void;
};

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
  },
  searchBoxContainer: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 500,
    },
  },
  sellerStatusBoxContainer: {
    '& div': {
      width: 200,
    },
  },
  coinBoxContainer: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 300,
    },
  },
  coinInput: {
    maxWidth: '100px',
  },
  filterHeader: {
    fontWeight: 700,
  },
  selectedChipIcon: {
    marginRight: '4px',
    marginBottom: '4px',
    '& .MuiChip-icon': {
      order: 3,
      marginRight: '8px',
      marginLeft: '-6px',
    },
  },

  accordionBar: {
    boxShadow: 'none',
    '& svg': {
      color: theme.palette.primary.main,
      fontSize: '30px',
    },
    margin: '0 !important',
    background: 'none',
    '&::before': {
      background: 'none',
    },
  },
  accordionSummary: {
    display: 'inline-flex',
    margin: '0 !important',
    minHeight: '48px',
    padding: '0',
    color: theme.palette.primary.main,
    '>div': {
      marginTop: '0',
    },
  },
  accordionDetail: {
    display: 'block',
    padding: '0',
  },
}));

const searchFieldList: SelectItem<string>[] = [
  {
    label: 'user:common.fields.displayName',
    value: 'displayName',
  },
  {
    label: 'user:common.fields.email',
    value: 'email',
  },
  {
    label: 'user:common.fields.mobile',
    value: 'mobilePhone',
  },
  {
    label: 'user:common.fields.hashId',
    value: 'userHashId',
  },
  {
    label: 'user:common.fields.nationalId',
    value: 'nationalId',
  },
];

const searchStatus = [
  {
    label: 'user:v3.list.search.workflow.W2_BUYER_STATE',
    value: UserStateWorkflow.Buyer,
  },
  {
    label: 'user:v3.list.search.workflow.W2_SELLER_STATE',
    value: UserStateWorkflow.Seller,
  },
  {
    label: 'user:v3.list.search.workflow.W2_UNVERIFIED_SELLER_STATE',
    value: UserStateWorkflow.UnverifiedSeller,
  },
];

const searchGeneralWorkflow = [
  {
    label: 'user:v3.list.search.workflow.W10_FIRST_INIT_STATE',
    value: UserGeneralWorkflow.FirstInit,
  },
  {
    label: 'user:v3.list.search.workflow.W10_ACTIVE_STATE',
    value: UserGeneralWorkflow.Active,
  },
  {
    label: 'user:v3.list.search.workflow.W10_BANNED_STATE',
    value: UserGeneralWorkflow.Banned,
  },
  {
    label: 'user:v3.list.search.workflow.W10_BLACKLISTED_STATE',
    value: UserGeneralWorkflow.BlackListed,
  },
  {
    label: 'user:v3.list.search.workflow.W10_INACTIVE_STATE',
    value: UserGeneralWorkflow.InActive,
  },
];

const searchRolesStateWorkflow = [
  {
    label: 'user:v3.list.search.workflow.W3_BUYER_STATE',
    value: UserRoleTC.Buyer,
  },
  {
    label: 'user:v3.list.search.workflow.W3_SELLER_STATE',
    value: UserRoleTC.Seller,
  },
  {
    label: 'user:v3.list.search.workflow.W3_SELLER_GOLD_STATE',
    value: UserRoleTC.SellerGold,
  },
  {
    label: 'user:v3.list.search.workflow.W3_SELLER_SILVER_STATE',
    value: UserRoleTC.SellerSilver,
  },
  {
    label: 'user:v3.list.search.workflow.W3_SELLER_BRONZE_STATE',
    value: UserRoleTC.SellerBronze,
  },
];

const searchNationalityType = [
  {
    label: 'user:v3.list.search.nationality.thai',
    value: 'THAI',
  },
  {
    label: 'user:v3.list.search.nationality.foreign',
    value: 'FOREIGN',
  },
];

const UserListSearchForm: FC<UserListSearchFormProps> = (props) => {
  const { onSearchFormSubmit, currentFilters, isLoadingData = false } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const containerClasses = useAppContainerStyles();
  const { authUser } = useAuthContext();

  const { handleSubmit, control, getValues, setValue } = useForm<UserListSearchFormDataTC>({
    mode: 'onSubmit',
    defaultValues: {
      searchField: currentFilters?.searchField || searchFieldList[0].value,
      searchKeyword: currentFilters?.searchKeyword || '',
      filteringStatus: currentFilters?.filteringStatus || [],
      filteringGeneralWorkflow: currentFilters?.filteringGeneralWorkflow || [],
      filteringRoleStateWorkflow: currentFilters?.filteringRoleStateWorkflow || [],
      filteringNationality: currentFilters?.filteringNationality || [],
    },
  });

  const submitSearchForm = () => {
    if (!isLoadingData) {
      setValue('searchKeyword', getValues('searchKeyword').trim());
      handleSubmit(onSearchFormSubmit)();
    }
  };

  const clearSearchForm = () => {
    if (!isLoadingData) {
      setValue('searchField', searchFieldList[0].value);
      setValue('searchKeyword', '');
      setValue('filteringStatus', []);
      setValue('filteringGeneralWorkflow', []);
      setValue('filteringRoleStateWorkflow', []);
      setValue('filteringNationality', []);
      handleSubmit(onSearchFormSubmit)();
    }
  };

  return (
    <Box className={classes.filterContainer}>
      <Container className={containerClasses.container}>
        <form onSubmit={handleSubmit(onSearchFormSubmit)}>
          <Grid container direction="row" spacing={1}>
            <Grid item xs={12} md container spacing={2}>
              <Grid item xs={12} md="auto">
                <Box display="flex" flexDirection="row" flexWrap="nowrap" className={classes.searchBoxContainer}>
                  <AppFormControlGroup flexGrow={1} minWidth={0} pr={1}>
                    <AppFormControl margin="dense">
                      <Controller
                        name="searchField"
                        control={control}
                        defaultValue={searchFieldList[0].value}
                        render={({ field }) => (
                          <Select
                            labelId="user-list-search-field-label"
                            id="user-list-search-field"
                            value={field.value}
                            variant="outlined"
                            onChange={field.onChange}
                            disabled={isLoadingData}
                            fullWidth>
                            {searchFieldList.map((item) => (
                              <MenuItem value={item.value} key={item.value}>
                                {t(item.label)}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </AppFormControl>
                    <AppFormControl margin="dense" boxProps={{ flexGrow: 1 }}>
                      <Controller
                        name="searchKeyword"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <OutlinedInput
                            type="search"
                            endAdornment={<SearchIcon />}
                            id="user-list-search-keyword"
                            value={field.value}
                            onChange={field.onChange}
                            fullWidth
                            disabled={isLoadingData}
                          />
                        )}
                      />
                    </AppFormControl>
                  </AppFormControlGroup>
                  <AppButton
                    size="large"
                    type="submit"
                    color="primary"
                    style={{ alignSelf: 'flex-start' }}
                    disabled={isLoadingData}>
                    {t('common:message.search')}
                  </AppButton>
                </Box>
              </Grid>

              <Grid container direction="row" spacing={1} item xs={12}>
                <Grid item md={3}>
                  <Box>
                    <Typography variant="body2" className={classes.filterHeader}>
                      {t('user:v3.roleShort.title')}
                    </Typography>
                    <Box display="flex" flexDirection="row" flexWrap="wrap" mt={1}>
                      <Controller
                        control={control}
                        name="filteringRoleStateWorkflow"
                        defaultValue={currentFilters?.filteringRoleStateWorkflow || []}
                        render={({ field }) => (
                          <>
                            <AppChipList
                              items={searchRolesStateWorkflow}
                              selectedValues={field.value}
                              onChange={(values: string[]) => {
                                field.onChange(values);
                                submitSearchForm();
                              }}
                              isDisabled={isLoadingData}
                            />
                          </>
                        )}
                      />
                    </Box>
                  </Box>
                </Grid>

                <Grid item md={3}>
                  <Typography variant="body2" className={classes.filterHeader}>
                    {t('user:v3.list.search.status.title')}
                  </Typography>
                  <Box display="flex" flexDirection="row" flexWrap="wrap" mt={1}>
                    <Controller
                      control={control}
                      name="filteringStatus"
                      defaultValue={currentFilters?.filteringStatus || []}
                      render={({ field }) => (
                        <>
                          <AppChipList
                            items={searchStatus}
                            selectedValues={field.value}
                            onChange={(values: string[]) => {
                              field.onChange(values);
                              submitSearchForm();
                            }}
                            isDisabled={isLoadingData}
                          />
                        </>
                      )}
                    />
                  </Box>
                </Grid>

                <Grid item md={4}>
                  <Typography variant="body2" className={classes.filterHeader}>
                    {t('user:v3.list.search.workflow.title')}
                  </Typography>
                  <Box display="flex" flexDirection="row" flexWrap="wrap" mt={1}>
                    <Controller
                      control={control}
                      name="filteringGeneralWorkflow"
                      defaultValue={currentFilters?.filteringGeneralWorkflow || []}
                      render={({ field }) => (
                        <>
                          <AppChipList
                            items={searchGeneralWorkflow}
                            selectedValues={field.value}
                            onChange={(values: string[]) => {
                              field.onChange(values);
                              submitSearchForm();
                            }}
                            isDisabled={isLoadingData}
                          />
                        </>
                      )}
                    />
                  </Box>
                </Grid>

                <Grid item md={2}>
                  <Typography variant="body2" className={classes.filterHeader}>
                    {t('user:v3.list.search.nationality.title')}
                  </Typography>
                  <Box display="flex" flexDirection="row" flexWrap="wrap" mt={1}>
                    <Controller
                      control={control}
                      name="filteringNationality"
                      defaultValue={currentFilters?.filteringNationality || []}
                      render={({ field }) => (
                        <>
                          <AppChipList
                            items={searchNationalityType}
                            selectedValues={field.value}
                            onChange={(values: string[]) => {
                              field.onChange(values);
                              submitSearchForm();
                            }}
                            isDisabled={isLoadingData}
                          />
                        </>
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>

              {authUser?.role === 'ADMIN_GM' && (
                <Grid
                  container
                  direction="row"
                  item
                  xs={12}
                  style={{ borderTop: '1px solid', borderColor: '#e0e0e0', marginTop: '14px' }}>
                  <Grid item>
                    <Accordion classes={{ root: classes.accordionBar }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="filer-user-list"
                        id="filer-user-header"
                        classes={{ root: classes.accordionSummary }}>
                        <Box>
                          <Typography style={{ fontWeight: 'bold' }}>ข้อมูลผู้ใช้งานที่รับ Email TPG</Typography>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails classes={{ root: classes.accordionDetail }}>
                        <UserReceiveTpgEmailData isLoadingData={isLoadingData} />
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                </Grid>
              )}
            </Grid>

            <Grid item xs={12} md="auto">
              <AppButton variant="outlined" color="primary" onClick={clearSearchForm} disabled={isLoadingData}>
                <YoutubeSearchedForIcon />
              </AppButton>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Box>
  );
};

export default UserListSearchForm;
