import { apiTCRequest, apiTCRequestV2 } from '../../helpers/request';
import { queryParamGenerator } from '../../helpers/utils';
import { ApiResponse } from '../../models';
import { FakhaData, FakhaDataRequest, FakhaSearchFiltersTC, GetFakhaResponse } from '../../models/fakha.model';

const getFakhaListQueryParam = (param?: FakhaSearchFiltersTC): string => {
  const reqParam: { [x: string]: string | number } = {
    page: param?.page || 0, // plus 1 for index shift between FE component and backend query
    per_page: param?.rowsPerPage || 10,
    sort: param?.sortColumn ? `id%7C${(param?.sortDirection || 'desc').toUpperCase()}` : '',
  };

  if (param?.searchKeyword && param?.searchField) {
    reqParam[param.searchField] = param.searchKeyword.trim();
  }

  if (param?.filteringType?.length) {
    reqParam.types = param.filteringType.join(',');
  }

  if (param?.filteringRoleStateWorkflow?.length) {
    reqParam.roles = param.filteringRoleStateWorkflow.join(',');
  }

  if (param?.q) {
    reqParam.q = param.q;
  }

  return queryParamGenerator(reqParam);
};

export const getFakha = async (param?: FakhaSearchFiltersTC): Promise<ApiResponse<GetFakhaResponse>> => {
  try {
    const queryParamString = getFakhaListQueryParam(param);
    const result = await apiTCRequest<GetFakhaResponse>(`/admin/fakha-item/?${queryParamString}`);

    return {
      data: result.data ?? null,
    };
  } catch (e) {
    return {
      data: null,
      error: 'fakha:error.list.requestFailed',
    };
  }
};

export const updateNoteFakhaItem = async (
  hashId: string,
  formData: FakhaDataRequest,
): Promise<ApiResponse<FakhaData | null>> => {
  try {
    const result = await apiTCRequestV2<FakhaData>(`/admin/fakha-item/add-note/${hashId}`, {
      method: 'POST',
      data: { ...formData },
    });

    return {
      ...result,
      data: result.data,
    };
  } catch (e) {
    return Promise.reject(e);
  }
};
