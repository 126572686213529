import { apiTCRequest } from '../helpers/request';
import { ApiResponse } from '../models';

// eslint-disable-next-line import/prefer-default-export
export const uploadWeeklySellerReportFile = async (file: File): Promise<ApiResponse<boolean>> => {
  try {
    const formData = new FormData();
    formData.append('file', file);

    await apiTCRequest<boolean>('/admin/user/upload/private/seller-report-file', {
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
      },

      data: formData,
    });
    return {
      data: true,
    };
  } catch (e) {
    return {
      data: null,
      error: 'master-data:error.uploadFileWeeklySellerReport.dataUploadFailed',
    };
  }
};
