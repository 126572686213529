import React, { FC, useCallback, useContext, useEffect, useState } from 'react';

import { Box, CircularProgress, Typography, makeStyles } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import axios from 'axios';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import InterviewLogListSearchForm from '../../../components/partials/v3/interviewLog/InterviewLogListSearchForm';
import InterviewLogListTable from '../../../components/partials/v3/interviewLog/InterviewLogListTable';
import NoneUserInterviewLogListTable from '../../../components/partials/v3/interviewLog/NoneUserInterviewLogListTable';
import AppButton from '../../../components/ui/AppButton';
import { AppGlobalUiContext } from '../../../context/AppGlobalUiContext';
import { SearchFilterContext } from '../../../context/SearchFilterContext';
import { defaultRowsPerPage } from '../../../helpers/constants';
import { queryParamGenerator } from '../../../helpers/utils';
import {
  AppTableConditions,
  InterviewLogData,
  InterviewLogListSearchFormDataTC,
  InterviewLogListSearchParamsTC,
  InterviewLogSearchFiltersTC,
  NoneUserInterviewLogData,
} from '../../../models';
import { getInterviewLog, getNoneUserInterviewLog } from '../../../services/v3/interview-log';
import theme from '../../../theme';

type InterviewLogListPageProps = unknown;

const searchFilterContextKey = 'interviewLogsTc';

const useStyles = makeStyles(() => ({
  menuTab: {
    paddingLeft: theme.spacing(4),
    width: '100%',
    backgroundColor: 'white',
  },
  buttonTab: {
    padding: '10px 20px',
    borderRadius: 'unset',
  },
  linkTab: {
    color: 'black',
    textDecoration: 'unset',
    padding: '20px 0',
  },
  buttonExportDesktop: {
    margin: '0 44px 12px 44px',
    width: '240px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  buttonExportMobile: {
    backgroundColor: 'white',
    width: '100%',
    padding: '0 38px 38px 38px',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

const InterviewLogList: FC<InterviewLogListPageProps> = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { showSnackbar } = useContext(AppGlobalUiContext);
  const [logList, setLogList] = useState<InterviewLogData[]>([]);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [isLoadingExportData, setIsLoadingExportData] = useState<boolean>(false);
  const [totalLogs, setTotalLogs] = useState<number>(0);

  const [activeTab, setActiveTab] = useState<string>('user-t2h');
  const [noneUserLogList, setNoneUserLogList] = useState<NoneUserInterviewLogData[]>([]);
  const [noneUserTotalLogs, setNoneUserTotalLogs] = useState<number>(0);

  // start filling default search filters (either from the one we saved in context or fresh conditions)
  const { currentFilters: ctxFilters, setFilters } = useContext(SearchFilterContext);
  const currentFilters: InterviewLogListSearchParamsTC = ctxFilters ? ctxFilters[searchFilterContextKey] : undefined;

  const [searchFilters, setSearchFilters] = useState<InterviewLogListSearchFormDataTC>({
    searchField: currentFilters?.searchField || '',
    searchKeyword: currentFilters?.searchKeyword || '',
    filteringStatus: currentFilters?.filteringStatus || '',
    filteringContactBy: currentFilters?.filteringContactBy || '',
    filteringContactType: currentFilters?.filteringContactType || '',
    filteringStartCreatedDate: currentFilters?.filteringStartCreatedDate || 0,
    filteringEndCreatedDate: currentFilters?.filteringEndCreatedDate || 0,
    filteringStartUpdatedDate: currentFilters?.filteringStartUpdatedDate || 0,
    filteringEndUpdatedDate: currentFilters?.filteringEndUpdatedDate || 0,
  });

  const [tableConditions, setTableConditions] = useState<AppTableConditions>({
    page: currentFilters?.page || 0,
    rowsPerPage: currentFilters?.rowsPerPage || defaultRowsPerPage,
    sortColumn: currentFilters?.sortColumn || 'createdAt',
    sortDirection: currentFilters?.sortDirection || 'desc',
  });

  const getCurrentSearchParam = (): InterviewLogListSearchParamsTC => ({
    page: tableConditions.page || 0,
    rowsPerPage: tableConditions.rowsPerPage || defaultRowsPerPage,
    sortColumn: tableConditions.sortColumn || 'createdAt',
    sortDirection: tableConditions.sortDirection || 'desc',
    searchField: searchFilters.searchField,
    searchKeyword: searchFilters.searchKeyword,
    filteringStatus: searchFilters.filteringStatus,
    filteringContactBy: searchFilters.filteringContactBy,
    filteringContactType: searchFilters.filteringContactType,
    filteringStartCreatedDate: searchFilters.filteringStartCreatedDate,
    filteringEndCreatedDate: searchFilters.filteringEndCreatedDate,
    filteringStartUpdatedDate: searchFilters.filteringStartUpdatedDate,
    filteringEndUpdatedDate: searchFilters.filteringEndUpdatedDate,
  });

  const handleTabClick = (productType: string) => {
    setActiveTab(productType);
  };

  const requestInterviewLogList = async (isPageSubscribed = true): Promise<boolean> => {
    if (isPageSubscribed) {
      setIsLoadingData(true);

      const searchConditions = getCurrentSearchParam();
      setFilters(searchFilterContextKey, searchConditions);

      const filteringStartCreatedDate = dayjs(searchConditions.filteringStartCreatedDate).valueOf();
      const filteringEndCreatedDate = dayjs(searchConditions.filteringEndCreatedDate).valueOf();
      const filteringStartUpdatedDate = dayjs(searchConditions.filteringStartUpdatedDate).valueOf();
      const filteringEndUpdatedDate = dayjs(searchConditions.filteringEndUpdatedDate).valueOf();

      const resultInterviewLog = await getInterviewLog({
        ...searchConditions,
        filteringStartCreatedDate,
        filteringEndCreatedDate,
        filteringStartUpdatedDate,
        filteringEndUpdatedDate,
      });
      const resultNoneUserInterviewLog = await getNoneUserInterviewLog({
        ...searchConditions,
        filteringStartCreatedDate,
        filteringEndCreatedDate,
        filteringStartUpdatedDate,
        filteringEndUpdatedDate,
      });

      if (resultInterviewLog.data && resultNoneUserInterviewLog.data) {
        setIsLoadingData(false);
        setLogList(resultInterviewLog.data.contents);
        setTotalLogs(resultInterviewLog.data?.page?.totalElements || 0);
        setNoneUserLogList(resultNoneUserInterviewLog.data.contents);
        setNoneUserTotalLogs(resultNoneUserInterviewLog.data?.page?.totalElements || 0);
        return Promise.resolve(true);
      }

      if (resultInterviewLog.error && resultNoneUserInterviewLog.error) {
        setLogList([]);
        setTotalLogs(0);
        setNoneUserLogList([]);
        setNoneUserTotalLogs(0);
        showSnackbar(t('seller-applications:error.list.requestFailed').toString(), 'error');
        return Promise.reject(resultInterviewLog.error);
      }

      showSnackbar(t(resultInterviewLog.error as string).toString(), 'error');
      return Promise.reject(resultInterviewLog.error);
    }

    return false;
  };

  useEffect(() => {
    let isSubscribed = true;
    requestInterviewLogList(isSubscribed);
    return () => {
      isSubscribed = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableConditions, searchFilters]);

  const tableConditionsChangedHandler = (values: AppTableConditions) => {
    setTableConditions(values);
  };

  const onSearchFormSubmit = (filters: InterviewLogListSearchFormDataTC) => {
    if (isLoadingData) {
      showSnackbar(t('common.message.pleaseWaitForDataToFinish').toString(), 'warning');
      return;
    }
    if (tableConditions.page !== 0) {
      tableConditions.page = 0;
    }
    setSearchFilters(filters);
  };

  const getInterviewLogListQueryParam = (param?: InterviewLogSearchFiltersTC): string => {
    const reqParam: { [x: string]: string | number } = {
      sort: param?.sortColumn ? `id%7C${(param?.sortDirection || 'desc').toUpperCase()}` : '',
    };

    if (param?.searchKeyword && param?.searchField) {
      reqParam[param.searchField] = param.searchKeyword.trim();
    }

    if (param?.filteringStatus) {
      reqParam.status = param.filteringStatus;
    }

    if (param?.filteringContactBy) {
      reqParam.contactBy = param.filteringContactBy;
    }

    if (param?.filteringContactType) {
      reqParam.contactType = param.filteringContactType;
    }

    if (param?.filteringStartCreatedDate) {
      reqParam.startCreatedDate = param.filteringStartCreatedDate;
    }
    if (param?.filteringEndCreatedDate) {
      reqParam.endCreatedDate = param.filteringEndCreatedDate;
    }

    if (param?.filteringStartUpdatedDate) {
      reqParam.startUpdatedDate = param.filteringStartUpdatedDate;
    }
    if (param?.filteringEndUpdatedDate) {
      reqParam.endUpdatedDate = param.filteringEndUpdatedDate;
    }

    if (param?.q) {
      reqParam.q = param.q;
    }

    return queryParamGenerator(reqParam);
  };

  const onExportData = useCallback(async () => {
    if (currentFilters) {
      setIsLoadingExportData(true);
      const filteringStartCreatedDate = dayjs(currentFilters.filteringStartCreatedDate).valueOf();
      const filteringEndCreatedDate = dayjs(currentFilters.filteringEndCreatedDate).valueOf();
      const filteringStartUpdatedDate = dayjs(currentFilters.filteringStartUpdatedDate).valueOf();
      const filteringEndUpdatedDate = dayjs(currentFilters.filteringEndUpdatedDate).valueOf();

      const queryParamString = getInterviewLogListQueryParam({
        ...currentFilters,
        filteringStartCreatedDate,
        filteringEndCreatedDate,
        filteringStartUpdatedDate,
        filteringEndUpdatedDate,
      });

      await axios
        .request({
          url:
            activeTab === 'user-t2h'
              ? `${process.env.REACT_APP_T2H_TC_BACKEND_DOMAIN}/admin/interview-log/excel?${queryParamString}`
              : `${process.env.REACT_APP_T2H_TC_BACKEND_DOMAIN}/admin/anonymous-user-interview-log/excel?${queryParamString}`,
          responseType: 'blob',
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          const now = new Date();
          const currentTime = dayjs(now.getTime()).format('YYYY-MM-DD_HH_mm_ss');
          link.href = url;
          link.setAttribute('download', `interviewLog_${activeTab}_${currentTime}.xlsx`);
          document.body.appendChild(link);
          link.click();
          link.remove();
        });
      setIsLoadingExportData(false);
    }
  }, [activeTab, currentFilters]);

  return (
    <>
      <InterviewLogListSearchForm
        isLoadingData={isLoadingData}
        currentFilters={searchFilters}
        onSearchFormSubmit={onSearchFormSubmit}
        type={activeTab}
      />

      <Box className={classes.buttonExportMobile}>
        <AppButton
          variant="contained"
          color="primary"
          startIcon={
            isLoadingExportData ? <CircularProgress style={{ width: '20px', height: '20px' }} /> : <GetAppIcon />
          }
          onClick={onExportData}
          style={{ width: '100%' }}
          disabled={
            !!isLoadingExportData || (activeTab === 'user-t2h' ? logList.length <= 0 : noneUserLogList.length <= 0)
          }>
          Export Data
        </AppButton>
      </Box>

      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="end"
        className={classes.menuTab}>
        <Box>
          <AppButton
            className={classes.buttonTab}
            variant="text"
            style={{
              backgroundColor: activeTab === 'user-t2h' ? '#F5F5F5' : 'transparent',
            }}
            onClick={() => handleTabClick('user-t2h')}>
            <Typography style={{ fontWeight: 'bold' }} variant="subtitle2">
              ผู้ใช้งาน T2H
            </Typography>
          </AppButton>
          <AppButton
            className={classes.buttonTab}
            variant="text"
            style={{
              backgroundColor: activeTab !== 'user-t2h' ? '#F5F5F5' : 'transparent',
            }}
            onClick={() => handleTabClick('anonymous-user')}>
            <Typography style={{ fontWeight: 'bold' }} variant="subtitle2">
              ผู้ใช้งานที่ไม่มีในระบบ
            </Typography>
          </AppButton>
        </Box>

        <AppButton
          variant="contained"
          color="primary"
          startIcon={
            isLoadingExportData ? <CircularProgress style={{ width: '20px', height: '20px' }} /> : <GetAppIcon />
          }
          onClick={onExportData}
          className={classes.buttonExportDesktop}
          disabled={
            !!isLoadingExportData || (activeTab === 'user-t2h' ? logList.length <= 0 : noneUserLogList.length <= 0)
          }>
          Export Data
        </AppButton>
      </Box>

      {activeTab === 'user-t2h' ? (
        <Box>
          <InterviewLogListTable
            currentConditions={tableConditions}
            interviewLogList={logList}
            totalLogs={totalLogs}
            isLoadingData={isLoadingData}
            onTableConditionsChanged={tableConditionsChangedHandler}
            onFetch={() => requestInterviewLogList()}
          />
        </Box>
      ) : (
        <NoneUserInterviewLogListTable
          currentConditions={tableConditions}
          interviewLogList={noneUserLogList}
          totalLogs={noneUserTotalLogs}
          isLoadingData={isLoadingData}
          onTableConditionsChanged={tableConditionsChangedHandler}
          type={activeTab}
          onFetch={() => requestInterviewLogList()}
        />
      )}
    </>
  );
};

export default InterviewLogList;
