import { ReactNode, useState } from 'react';

import { AlertProps, Color } from '@material-ui/lab/Alert';

import { AppGlobalUiHookProps, AppSnackbarProps, BreadcrumbItem } from '../models';

export default (): AppGlobalUiHookProps => {
  const [snackbar, setSnackbar] = useState<AppSnackbarProps | null>(null);
  const [isAppLoading, setIsAppLoading] = useState(false);
  const [breadcrumb, setBreadcrumb] = useState<BreadcrumbItem[]>([]);
  const [appBarTitle, setAppBarTitle] = useState<string>('T2H CRM');
  const [appBarBackButtonUrl, setAppBarBackButtonUrl] = useState<string>('');

  const showSnackbar = (content: ReactNode, type: Color, config?: AlertProps) => {
    setSnackbar({
      content,
      type,
      config: config || null,
    });
  };

  const closeSnackbar = () => {
    setSnackbar(null);
  };

  const setAppLoading = (value: boolean) => {
    setIsAppLoading(value);
  };

  return {
    snackbar,
    showSnackbar,
    closeSnackbar,
    isAppLoading,
    setAppLoading,
    breadcrumb,
    setBreadcrumb,
    appBarTitle,
    setAppBarTitle,
    appBarBackButtonUrl,
    setAppBarBackButtonUrl,
  };
};
