import React, { FC, useState, useEffect, useRef } from 'react';

import { Box, Typography, makeStyles, debounce, Tooltip } from '@material-ui/core';
import CachedIcon from '@material-ui/icons/Cached';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import Panzoom, { PanzoomObject } from '@panzoom/panzoom';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { getRgbaFromHex } from '../../../../helpers/utils';
import useTypographyStyles from '../../../../theme/typography.style';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

type ImageObject = {
  width: number;
  height: number;
  orientation: 'square' | 'portrait' | 'landscape';
};

type PreviewContentProps = {
  currentImageUrl: string;
  imageObject?: ImageObject;
  toolbarClassName?: string;
  imageBoxClassName?: string;
};

const useStyles = makeStyles((theme) => ({
  imagePanZoomParent: {
    flexGrow: 1,
    position: 'relative',
    userSelect: 'none',
    touchAction: 'none',
    overflow: 'hidden',
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imagePanZoomElement: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
  },
  imagePreviewBlockReady: {
    animation: `$imagePreviewReady 150ms ${theme.transitions.easing.easeOut} 0.1s forwards`,
  },
  '@keyframes imagePreviewReady': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  imagePreviewRotate: {
    position: 'absolute',
    bottom: '70px',
    right: '12px',
    color: theme.palette.common.white,
    cursor: 'pointer',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    userSelect: 'none',
  },
  imagePreviewToolbarGroup: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    zIndex: 10,
    marginBottom: '20px',
  },
  imagePreviewToolbar: {
    alignSelf: 'center',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: getRgbaFromHex('#000000', 70),
    padding: theme.spacing(0, 3),
    borderRadius: 5,
  },
  toolbarButton: {
    cursor: 'pointer',
    userSelect: 'none',
    padding: theme.spacing(1),
    '&:hover': {
      backgroundColor: getRgbaFromHex('#000000', 60),
    },
  },
  imageRotationNone: {
    transform: 'rotateZ(0)',
  },
  imageRotation90: {
    transform: 'rotateZ(90deg)',
  },
  imageRotation180: {
    transform: 'rotateZ(180deg)',
  },
  imageRotation270: {
    transform: 'rotateZ(270deg)',
  },
}));

const PreviewContent: FC<PreviewContentProps> = (props) => {
  const { currentImageUrl, imageObject, imageBoxClassName, toolbarClassName } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const typoClasses = useTypographyStyles();
  const imageParentRef = useRef<HTMLDivElement | null>(null);
  const imageContainerRef = useRef<HTMLDivElement | null>(null);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const [rotation, setRotation] = useState(0);
  const [panzoomInstance, setPanzoomInstance] = useState<PanzoomObject | null>(null);
  const [imageContainerStyles, setImageContainerStyles] = useState<{ [x: string]: string | number }>({
    width: 0,
    height: 0,
  });
  const [imageStyles, setImageStyles] = useState<{ [x: string]: string | number }>({
    transform: 'none',
  });

  const initialPanzoom = (minScale = 1) => {
    const optimizedMinScale = minScale / 2.5;
    const panzoom = Panzoom(imageContainerRef.current as HTMLElement, {
      minScale: optimizedMinScale,
      maxScale: 8,
      startScale: minScale / 2.5,
      step: 0.2,
      startX: 0,
      startY: 0,
      canvas: true,
    });
    setPanzoomInstance(panzoom);
  };

  const getFitScale = (rotationDegree?: number): number => {
    // default fit scale
    if (!imageParentRef.current || !imageObject?.width) {
      return 1;
    }

    const { offsetHeight: parentHeight, offsetWidth: parentWidth } = imageParentRef.current;
    const { height: imageHeight, width: imageWidth, orientation } = imageObject;
    const rotationValue = typeof rotationDegree === 'number' ? rotationDegree : rotation;

    if (orientation === 'landscape') {
      if ([90, 270].includes(rotationValue)) {
        return Number((parentHeight / imageWidth).toFixed(2));
      }

      return Number((parentWidth / imageWidth).toFixed(2));
    }
    if (orientation === 'portrait') {
      if ([90, 270].includes(rotationValue)) {
        return Number((parentWidth / imageHeight).toFixed(2));
      }

      return Number((parentHeight / imageHeight).toFixed(2));
    }
    if (orientation === 'square') {
      return Number((parentWidth / imageWidth).toFixed(2));
    }
    return 1;
  };

  const imageSizeHandler = (rotationDegree: number) => {
    if (imageObject?.width && imageObject?.height) {
      if (panzoomInstance) {
        panzoomInstance.reset();
        panzoomInstance.resetStyle();
        panzoomInstance.destroy();

        if ([90, 270].includes(rotationDegree)) {
          setImageContainerStyles({
            width: imageObject.height,
            height: imageObject.width,
            transform: 'none',
          });
          // To make 90 and 270 deg. rotation work, image transform styles need to be re-calculated for default position
          const difX = (imageObject.width - imageObject.height) / 2;
          const difY = (imageObject.height - imageObject.width) / 2;
          setImageStyles({
            transform: `translate3d(${-difX}px, ${-difY}px, 0) rotateZ(${rotationDegree}deg)`,
          });
        } else {
          setImageContainerStyles({
            width: imageObject.width,
            height: imageObject.height,
            transform: 'none',
          });
          setImageStyles({
            transform: `translate3d(0, 0, 0) rotateZ(${rotationDegree}deg)`,
          });
        }

        const imageFitScale = getFitScale(rotationDegree);
        setTimeout(() => {
          initialPanzoom(imageFitScale);
        }, 1);
      }
    }
  };

  useEffect(() => {
    const windowResizeHandler = debounce(() => {
      imageSizeHandler(rotation);
    }, 100);

    window.addEventListener('resize', windowResizeHandler, false);

    return () => {
      window.removeEventListener('resize', windowResizeHandler, false);
      if (panzoomInstance) {
        panzoomInstance.destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (imageObject?.width && imageObject?.height) {
      setImageContainerStyles({
        width: imageObject.width,
        height: imageObject.height,
      });
      setImageStyles({
        transform: 'none',
      });
      const imageFitScale = getFitScale(0);
      initialPanzoom(imageFitScale);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageObject]);

  const onRotateClick = () => {
    let nextRotation = 0;
    switch (rotation) {
      case 0:
        nextRotation = 90;
        break;
      case 90:
        nextRotation = 180;
        break;
      case 180:
        nextRotation = 270;
        break;
      case 270:
        nextRotation = 0;
        break;
      default:
        break;
    }

    setRotation(nextRotation);
    imageSizeHandler(nextRotation);
  };

  return (
    <>
      <Box className={clsx(classes.imagePreviewToolbarGroup, toolbarClassName)}>
        <Box className={classes.imagePreviewToolbar}>
          <Box display="flex" flexDirection="row">
            <Tooltip title={t('common:imagePreview.zoomOut').toString()} placement="top" aria-label="zoom out">
              <Box
                display="flex"
                mr={1}
                className={classes.toolbarButton}
                onClick={() => {
                  panzoomInstance?.zoomOut();
                }}>
                <ZoomOutIcon className={typoClasses.whiteText} />
              </Box>
            </Tooltip>
            <Tooltip title={t('common:imagePreview.zoomIn').toString()} placement="top" aria-label="zoom in">
              <Box
                display="flex"
                mr={2}
                className={classes.toolbarButton}
                onClick={() => {
                  panzoomInstance?.zoomIn();
                }}>
                <ZoomInIcon className={typoClasses.whiteText} />
              </Box>
            </Tooltip>
            <Tooltip title={t('common:imagePreview.rotate').toString()} placement="top" aria-label="rotate image">
              <Box display="flex" mr={2} onClick={() => onRotateClick()} className={classes.toolbarButton}>
                <CachedIcon className={typoClasses.whiteText} />
              </Box>
            </Tooltip>
          </Box>
          <Tooltip title={t('common:imagePreview.reset').toString()} placement="top" aria-label="reset preview">
            <Box
              display="flex"
              onClick={() => {
                setRotation(0);
                imageSizeHandler(0);
              }}
              className={classes.toolbarButton}>
              <Typography component="span" className={clsx(typoClasses.whiteText, typoClasses.textWeightBold)}>
                {t('common:button.reset')}
              </Typography>
            </Box>
          </Tooltip>
        </Box>
      </Box>
      <div className={clsx(classes.imagePanZoomParent, imageBoxClassName)} ref={imageParentRef}>
        <div className={classes.imagePanZoomElement} ref={imageContainerRef} style={imageContainerStyles}>
          <img src={currentImageUrl} alt="Preview" ref={imageRef} style={imageStyles} />
        </div>
      </div>
    </>
  );
};

export default PreviewContent;
