import React, { FC } from 'react';

import { Box, Container, Grid, makeStyles, MenuItem, OutlinedInput, Select, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import YoutubeSearchedForIcon from '@material-ui/icons/YoutubeSearchedFor';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SelectItem } from '../../../../../models';
import { LoginBonusListSearchFormData } from '../../../../../models/login-bonus.model';
import useAppContainerStyles from '../../../../../theme/container.style';
import AppButton from '../../../../ui/AppButton';
import AppFormControl from '../../../../ui/AppFormControl';
import AppFormControlGroup from '../../../../ui/AppFormControlGroup';

export type LoginBonusListSearchFormProps = {
  isLoadingData?: boolean;
  currentFilters: LoginBonusListSearchFormData | undefined;
  onSearchFormSubmit: (values: LoginBonusListSearchFormData) => void;
};

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
  },
  searchBoxContainer: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 600,
    },
  },
}));

const searchFieldList: SelectItem<string>[] = [
  {
    label: 'daily-challenge:loginBonus.list.search.campaignName',
    value: 'title',
  },
];

const LoginBonusListSearchForm: FC<LoginBonusListSearchFormProps> = (props) => {
  const classes = useStyles();
  const containerClasses = useAppContainerStyles();

  const { t } = useTranslation();
  const { currentFilters, onSearchFormSubmit, isLoadingData = false } = props;
  const { handleSubmit, control, setValue } = useForm<LoginBonusListSearchFormData>({
    mode: 'onSubmit',
    defaultValues: {
      searchField: currentFilters?.searchField || searchFieldList[0].value,
      searchKeyword: currentFilters?.searchKeyword || '',
      filteringStartDate: currentFilters?.filteringStartDate || 0,
      filteringEndDate: currentFilters?.filteringEndDate || 0,
    },
  });

  const clearSearchForm = () => {
    if (!isLoadingData) {
      setValue('searchField', searchFieldList[0].value);
      setValue('searchKeyword', '');
      setValue('filteringStartDate', 0);
      setValue('filteringEndDate', 0);
      handleSubmit(onSearchFormSubmit)();
    }
  };

  return (
    <Box className={classes.filterContainer}>
      <Container className={containerClasses.container}>
        <form onSubmit={handleSubmit(onSearchFormSubmit)}>
          <Grid container direction="row" spacing={2} style={{ alignItems: 'end' }}>
            <Grid item xs={12} lg={4}>
              <Box>
                <AppFormControlGroup flexGrow={1} minWidth={0}>
                  <AppFormControl margin="dense">
                    <Controller
                      name="searchField"
                      control={control}
                      defaultValue={searchFieldList[0].value}
                      render={({ field }) => (
                        <Select
                          labelId="user-list-search-field-label"
                          id="user-list-search-field"
                          value={field.value}
                          variant="outlined"
                          onChange={field.onChange}
                          disabled={isLoadingData}
                          fullWidth>
                          {searchFieldList.map((item) => (
                            <MenuItem value={item.value} key={item.value}>
                              {t(item.label)}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </AppFormControl>
                  <AppFormControl margin="dense" boxProps={{ flexGrow: 1 }}>
                    <Controller
                      name="searchKeyword"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <OutlinedInput
                          type="search"
                          endAdornment={<SearchIcon />}
                          id="user-list-search-keyword"
                          value={field.value}
                          onChange={field.onChange}
                          fullWidth
                          disabled={isLoadingData}
                        />
                      )}
                    />
                  </AppFormControl>
                </AppFormControlGroup>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} lg={3}>
              <Typography variant="body2">{t('daily-challenge:loginBonus.list.search.startDate')}</Typography>
              <Box mt={1}>
                <Controller
                  name="filteringStartDate"
                  control={control}
                  render={({ field }) => (
                    <AppFormControl>
                      <OutlinedInput
                        id="login-bonus-start-date"
                        value={field.value}
                        fullWidth
                        margin="dense"
                        type="datetime-local"
                        onChange={field.onChange}
                      />
                    </AppFormControl>
                  )}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} lg={3}>
              <Typography variant="body2">{t('daily-challenge:loginBonus.list.search.endDate')}</Typography>
              <Box mt={1}>
                <Controller
                  name="filteringEndDate"
                  control={control}
                  render={({ field }) => (
                    <AppFormControl>
                      <OutlinedInput
                        id="login-bonus-end-date"
                        value={field.value}
                        fullWidth
                        margin="dense"
                        type="datetime-local"
                        onChange={field.onChange}
                      />
                    </AppFormControl>
                  )}
                />
              </Box>
            </Grid>
            <Grid item xs="auto" lg={1}>
              <AppButton
                size="large"
                type="submit"
                color="primary"
                style={{ alignSelf: 'flex-start' }}
                disabled={isLoadingData}>
                {t('common:message.search')}
              </AppButton>
            </Grid>
            <Grid item xs="auto">
              <AppButton
                size="large"
                variant="outlined"
                color="primary"
                onClick={clearSearchForm}
                disabled={isLoadingData}>
                <YoutubeSearchedForIcon />
              </AppButton>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Box>
  );
};

export default LoginBonusListSearchForm;
