import React, { FC, useCallback, useContext, useState } from 'react';

import { CircularProgress, makeStyles, OutlinedInput, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import GetAppIcon from '@material-ui/icons/GetApp';
import axios from 'axios';
import dayjs from 'dayjs';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AppGlobalUiContext } from '../../../../../context/AppGlobalUiContext';
import AppButton from '../../../../ui/AppButton';
import AppFormControl from '../../../../ui/AppFormControl';

export type UserReceiveTpgEmailDataProps = {
  isLoadingData: boolean;
};

const useStyles = makeStyles(() => ({
  filterContainer: {
    '@media screen and (max-width: 768px)': {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      justifyItems: 'flex-start',
      alignContent: 'center',
      alignItems: 'flex-start',
      textAlign: 'left',
    },
  },
  inputEndDate: {
    margin: '0 14px',
    '@media screen and (max-width: 768px)': {
      margin: '4px 0',
    },
  },
  btnExport: {
    '@media screen and (max-width: 768px)': {
      marginTop: '8px',
      button: {
        width: '100% !important',
      },
    },
  },
}));

type UserTpgSearchFormData = {
  filteringStartCreatedDate: number;
  filteringEndCreatedDate: number;
};

const UserReceiveTpgEmailData: FC<UserReceiveTpgEmailDataProps> = (props) => {
  const classes = useStyles();
  const { isLoadingData } = props;
  const { t } = useTranslation();
  const { showSnackbar } = useContext(AppGlobalUiContext);
  const [isLoadingExportData, setIsLoadingExportData] = useState<boolean>(false);

  const { control, watch } = useForm<UserTpgSearchFormData>({
    mode: 'onSubmit',
    defaultValues: {
      filteringStartCreatedDate: 0,
      filteringEndCreatedDate: 0,
    },
  });

  const onExportData = useCallback(async () => {
    if (!isLoadingData) {
      setIsLoadingExportData(true);
      const startCreatedDate = dayjs(watch('filteringStartCreatedDate')).valueOf();
      const endCreatedDate = dayjs(watch('filteringEndCreatedDate')).valueOf();

      const fetchData = async () => {
        try {
          await axios
            .request({
              url: `${process.env.REACT_APP_T2H_TC_BACKEND_DOMAIN}/admin/user/tpg-notification/excel?userGeneralWorkflows=W10_ACTIVE_STATE&startCreatedDate=${startCreatedDate}&endCreatedDate=${endCreatedDate}`,
              responseType: 'blob',
            })
            .then((response) => {
              const linkUrl = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              const now = new Date();
              const currentTime = dayjs(now.getTime()).format('YYYY-MM-DD_HH_mm_ss');
              link.href = linkUrl;
              link.setAttribute('download', `userTPGExcelReport_${currentTime}.xlsx`);
              document.body.appendChild(link);
              link.click();
              link.remove();
            });
          setIsLoadingExportData(false);
        } catch (error) {
          showSnackbar(t('user:error.list.userTpgNotFound').toString(), 'error');
          setIsLoadingExportData(false);
        }
      };
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingData]);

  return (
    <>
      <Box mb={1}>
        <Typography variant="subtitle2">* โปรดทำการเลือกช่วงเวลาก่อน (แนะนำว่าไม่ควรเกิน 3 เดือน)</Typography>
      </Box>
      <Box display="flex" alignItems="flex-end" mt={1} className={classes.filterContainer}>
        <Box display="flex" alignItems="center">
          <Typography variant="body2">วันที่เริ่มต้น</Typography>
          <Box mt={1} ml={1}>
            <Controller
              name="filteringStartCreatedDate"
              control={control}
              render={({ field }) => (
                <AppFormControl>
                  <OutlinedInput
                    id="interview-log-start-created-date"
                    value={field.value}
                    fullWidth
                    margin="dense"
                    type="datetime-local"
                    onChange={field.onChange}
                  />
                </AppFormControl>
              )}
            />
          </Box>
        </Box>

        <Box display="flex" alignItems="center" className={classes.inputEndDate}>
          <Typography variant="body2">วันที่สิ้นสุด</Typography>
          <Box mt={1} ml={1}>
            <Controller
              name="filteringEndCreatedDate"
              control={control}
              render={({ field }) => (
                <AppFormControl>
                  <OutlinedInput
                    id="interview-log-end-created-date"
                    value={field.value}
                    fullWidth
                    margin="dense"
                    type="datetime-local"
                    onChange={field.onChange}
                  />
                </AppFormControl>
              )}
            />
          </Box>
        </Box>

        <Box>
          <AppButton
            variant="contained"
            color="primary"
            size="large"
            startIcon={
              isLoadingExportData ? (
                <CircularProgress style={{ width: '20px', height: '20px', color: 'white' }} />
              ) : (
                <GetAppIcon style={{ color: 'white' }} />
              )
            }
            onClick={onExportData}
            disabled={
              isLoadingData || watch('filteringStartCreatedDate') === 0 || watch('filteringEndCreatedDate') === 0
            }
            className={classes.btnExport}>
            Export
          </AppButton>
        </Box>
      </Box>
    </>
  );
};

export default UserReceiveTpgEmailData;
