import { apiTCRequest, apiTCRequestV2 } from '../helpers/request';
import { queryParamGenerator } from '../helpers/utils';
import { ApiResponse } from '../models';
import {
  GetProductListTCResponse,
  Product,
  ProductFormDataRequest,
  ProductSearchFiltersTC,
} from '../models/product.model';

const getProductListQueryParam = (param?: ProductSearchFiltersTC): string => {
  const reqParam: { [x: string]: string | number } = {
    page: param?.page || 0, // plus 1 for index shift between FE component and backend query
    per_page: param?.rowsPerPage || 10,
    sort: param?.sortColumn ? `id%7C${(param?.sortDirection || 'asc').toUpperCase()}` : '',
  };

  return queryParamGenerator(reqParam);
};

export const getProductList = async (
  type?: string,
  param?: ProductSearchFiltersTC,
): Promise<ApiResponse<GetProductListTCResponse>> => {
  try {
    const queryParamString = getProductListQueryParam(param);
    const result = await apiTCRequest<GetProductListTCResponse>(`/admin/product/${type || 'COIN'}?${queryParamString}`);

    return {
      data: result.data ?? null,
    };
  } catch (e) {
    return {
      data: null,
      error: 'product:error.list.requestFailed',
    };
  }
};

export const getProductListByHashId = async (
  userHashId?: string,
  type?: string,
  param?: ProductSearchFiltersTC,
): Promise<ApiResponse<GetProductListTCResponse>> => {
  try {
    const queryParamString = getProductListQueryParam(param);
    const result = await apiTCRequest<GetProductListTCResponse>(
      `/admin/product/${type}?${queryParamString}&userHashId=${userHashId}`,
    );

    return {
      data: result.data ?? null,
    };
  } catch (e) {
    return {
      data: null,
      error: 'product:error.list.requestFailed',
    };
  }
};

export const updateProduct = async (
  productHashId: string,
  type: string,
  formData: ProductFormDataRequest,
): Promise<ApiResponse<Product | null>> => {
  try {
    const result = await apiTCRequestV2<Product>(`/admin/product/${type}/${productHashId}`, {
      method: 'PATCH',
      data: { ...formData },
    });

    return {
      ...result,
      data: result.data,
    };
  } catch (e) {
    return Promise.reject(e);
  }
};

export const addNewProduct = async (type: string, formData: ProductFormDataRequest): Promise<ApiResponse<Product>> => {
  const result = await apiTCRequestV2<Product>(`/admin/product/${type}`, {
    method: 'POST',
    data: { ...formData },
  });

  if (result.data) {
    return {
      ...result,
      data: result.data,
    };
  }

  return {
    ...result,
    data: null,
  };
};
