import React, { FC, useContext, useState } from 'react';

import { useTranslation } from 'react-i18next';
import useDeepCompareEffect from 'use-deep-compare-effect';

import ProductListSearchForm from '../../components/partials/v3/products/ProductListSearchForm';
import ProductListTable from '../../components/partials/v3/products/ProductListTable';
import { AppGlobalUiContext } from '../../context/AppGlobalUiContext';
import { SearchFilterContext } from '../../context/SearchFilterContext';
import { defaultRowsPerPage } from '../../helpers/constants';
import { AppTableConditions } from '../../models';
import { Product, ProductListSearchFormData, ProductListSearchParamsTC } from '../../models/product.model';
import { getProductList } from '../../services/product';

const searchFilterContextKey = 'users';

const ProductList: FC = () => {
  const { t } = useTranslation();
  const { showSnackbar } = useContext(AppGlobalUiContext);
  const [productList, setProductList] = useState<Product[]>([]);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [totalProducts, setTotalProducts] = useState<number>(0);

  const { currentFilters: ctxFilters, setFilters } = useContext(SearchFilterContext);
  const currentFilters: ProductListSearchParamsTC = ctxFilters ? ctxFilters[searchFilterContextKey] : undefined;
  const [searchFilters, setSearchFilters] = useState<ProductListSearchFormData>({
    filteringType: currentFilters?.filteringType || 'COIN',
  });

  const [tableConditions, setTableConditions] = useState<AppTableConditions>({
    page: currentFilters?.page || 0,
    rowsPerPage: currentFilters?.rowsPerPage || defaultRowsPerPage,
    sortColumn: currentFilters?.sortColumn || 'createdAt',
    sortDirection: currentFilters?.sortDirection || 'asc',
  });

  const getCurrentSearchParam = (): ProductListSearchParamsTC => ({
    page: tableConditions.page || 0,
    rowsPerPage: tableConditions.rowsPerPage || defaultRowsPerPage,
    sortColumn: tableConditions.sortColumn || 'createdAt',
    sortDirection: tableConditions.sortDirection || 'asc',
    filteringType: searchFilters.filteringType,
  });

  const requestProductList = async (isPageSubscribed = true): Promise<boolean> => {
    if (isPageSubscribed) {
      setIsLoadingData(true);

      const searchConditions = getCurrentSearchParam();
      setFilters(searchFilterContextKey, searchConditions);
      const type = searchFilters.filteringType;
      const result = await getProductList(type as string, searchConditions);

      setIsLoadingData(false);

      if (result.data) {
        setProductList(result.data.contents);
        setTotalProducts(result.data?.page?.totalElements || 0);
        return Promise.resolve(true);
      }

      showSnackbar(t(result.error as string).toString(), 'error');
      return Promise.reject(result.error);
    }

    return false;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useDeepCompareEffect((): any => {
    let isSubscribed = true;

    requestProductList(isSubscribed);

    return () => (isSubscribed = false);
  }, [tableConditions, searchFilters]);

  const tableConditionsChangedHandler = (values: AppTableConditions) => {
    setTableConditions(values);
  };

  const onSearchFormSubmit = (filters: ProductListSearchFormData) => {
    if (isLoadingData) {
      showSnackbar(t('common.message.pleaseWaitForDataToFinish').toString(), 'warning');
      return;
    }
    if (tableConditions.page !== 0) {
      tableConditions.page = 0;
    }
    setSearchFilters(filters);
  };

  return (
    <>
      <ProductListSearchForm
        isLoadingData={isLoadingData}
        currentFilters={searchFilters}
        onSearchFormSubmit={onSearchFormSubmit}
      />
      <ProductListTable
        currentConditions={tableConditions}
        productList={productList}
        totalProducts={totalProducts}
        isLoadingData={isLoadingData}
        typeProduct={searchFilters.filteringType as string}
        onTableConditionsChanged={tableConditionsChangedHandler}
        onFetch={() => requestProductList()}
      />
    </>
  );
};

export default ProductList;
