import React, { FC, useCallback, useContext, useState } from 'react';

import { Box, Container, TableRow, Typography } from '@material-ui/core';
import PostAddOutlinedIcon from '@material-ui/icons/PostAddOutlined';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { AppGlobalUiContext } from '../../../../../context/AppGlobalUiContext';
import { AuthContext } from '../../../../../context/AuthContext';
import {
  AppTableConditions,
  InterviewLogDataRequest,
  NoneUserInterviewLogData,
  TableColumn,
} from '../../../../../models';
import { addNewNoneUserInterviewLogType, updateNoneUserInterviewLog } from '../../../../../services/v3/interview-log';
import useAppContainerStyles from '../../../../../theme/container.style';
import useAppTableStyles from '../../../../../theme/table.style';
import AppButton from '../../../../ui/AppButton';
import AppTableCell from '../../../../ui/AppTableCell';
import AppTableList from '../../../../ui/AppTableList';
import AddInterviewLogItemDialog from '../../users/UserDetail/InterviewLog/AddInterviewLogItemDialog';
import InterviewLogItemDialog from '../../users/UserDetail/InterviewLog/InterviewLogItemDialog';

export type NoneUserInterviewLogListTableProps = {
  currentConditions: AppTableConditions;
  totalLogs: number;
  isLoadingData: boolean;
  type: string;
  interviewLogList: NoneUserInterviewLogData[];
  onTableConditionsChanged: (values: AppTableConditions) => void;
  onFetch: () => void;
};

const NoneUserInterviewLogListTable: FC<NoneUserInterviewLogListTableProps> = (props) => {
  const { t, i18n } = useTranslation();
  const { totalLogs, isLoadingData, type, currentConditions, interviewLogList, onTableConditionsChanged, onFetch } =
    props;
  const { authUser } = useContext(AuthContext);
  const containerClasses = useAppContainerStyles();
  const tableClasses = useAppTableStyles();

  const [tableConditions, setTableConditions] = useState<AppTableConditions>(currentConditions);
  const dateDisplayFormat = i18n.language === 'th' ? 'D MMM BBBB' : 'D MMM YYYY';

  const { showSnackbar, setAppLoading } = useContext(AppGlobalUiContext);
  const [isAddInterviewLogDialogOpen, setIsAddInterviewLogDialogOpen] = useState<boolean>(false);
  const [selectedLogItem, setSelectedLogItem] = useState<NoneUserInterviewLogData | undefined>(undefined);
  const [isInterviewLogDialogOpen, setIsInterviewLogDialogOpen] = useState<boolean>(false);

  const interviewLogTableColumns: TableColumn[] = [
    { name: 'name', label: 'interview-log:list.columns.name', width: 200, unsortable: true },
    {
      name: 'email',
      label: 'interview-log:list.columns.email',
      width: 300,
      unsortable: true,
    },
    { name: 'mobile', label: 'interview-log:list.columns.mobile', width: 140, unsortable: true },
    { name: 'contactBy', label: 'interview-log:list.columns.contactBy', width: 100, unsortable: true },
    {
      name: 'contactType',
      label: 'interview-log:list.columns.contactType',
      width: 220,
      unsortable: true,
    },
    { name: 'comment', label: 'interview-log:list.columns.comment', width: 400, unsortable: true },
    { name: 'fileImage', label: 'interview-log:list.columns.fileImage', width: 120, unsortable: true },
    { name: 'status', label: 'interview-log:list.columns.status', width: 200, unsortable: true },
    { name: 'createdAt', label: 'interview-log:list.columns.createdAt', width: 120 },
    { name: 'updatedAt', label: 'interview-log:list.columns.updatedAt', width: 120, unsortable: true },
    { name: 'createdBy', label: 'interview-log:list.columns.createdBy', width: 160, unsortable: true },
  ];

  const onTableConditionChangedHandler = useCallback(
    (values: AppTableConditions) => {
      if (JSON.stringify(values) !== JSON.stringify(tableConditions)) {
        setTableConditions(values);
        onTableConditionsChanged(values);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableConditions],
  );

  const openInterviewDetailPage = useCallback(async (item: NoneUserInterviewLogData) => {
    setSelectedLogItem(item);
    setIsInterviewLogDialogOpen(true);
  }, []);

  const onEditFormSubmit = useCallback(
    async (data?: InterviewLogDataRequest) => {
      if (selectedLogItem && data) {
        setAppLoading(true);

        if (authUser?.role) {
          const result = await updateNoneUserInterviewLog(selectedLogItem?.hashId, data, authUser?.role);
          if (result.data) {
            setAppLoading(false);
            setSelectedLogItem(result.data);
            showSnackbar(t('interview-log:message.updateInterviewLogSuccess').toString(), 'success');
            setIsInterviewLogDialogOpen(false);
            onFetch();
          } else {
            setAppLoading(false);
            showSnackbar(t('interview-log:message.saveFailed').toString(), 'error');
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedLogItem, setAppLoading, showSnackbar, t],
  );

  const handleAddInterviewLog = useCallback(async () => {
    setIsAddInterviewLogDialogOpen(true);
  }, []);

  const onSubmitAddNewLog = useCallback(
    async (formData?: InterviewLogDataRequest) => {
      if (formData) {
        setAppLoading(true);

        const result = await addNewNoneUserInterviewLogType(formData);

        if (result.data) {
          setSelectedLogItem(result.data);
          showSnackbar(t('interview-log:message.addNewInterviewLogSuccess').toString(), 'success');
          setIsAddInterviewLogDialogOpen(false);
          onFetch();
        } else {
          showSnackbar(t('interview-log:message.addNewInterviewLogFailed').toString(), 'error');
        }
        setAppLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setAppLoading, showSnackbar, t],
  );

  return (
    <>
      <Container className={containerClasses.container}>
        <Box className={tableClasses.tableContainer}>
          <Box display="flex" justifyContent="space-between" alignItems="end" mb={2}>
            <AppButton
              variant="contained"
              color="primary"
              startIcon={<PostAddOutlinedIcon />}
              onClick={handleAddInterviewLog}>
              {t('interview-log:button.addInterviewLog')}
            </AppButton>

            {!isLoadingData && (
              <Typography variant="subtitle2" color="textSecondary" style={{ marginRight: '8px', textAlign: 'right' }}>
                {t('common:list.resultTotal', { fieldName: t(totalLogs.toLocaleString() || '0') })}
              </Typography>
            )}
          </Box>

          <AppTableList
            {...tableConditions}
            aria-label="interview-log-list"
            columns={interviewLogTableColumns}
            totalDataCount={totalLogs}
            hasPagination
            onTableConditionChanged={onTableConditionChangedHandler}
            isLoading={isLoadingData}
            tableBodyContent={
              interviewLogList.length > 0 && (
                <>
                  {interviewLogList.map((item: NoneUserInterviewLogData) => (
                    <TableRow
                      key={item.hashId}
                      classes={{ root: clsx(tableClasses.tableRow, tableClasses.clickableTableRow) }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        openInterviewDetailPage(item);
                      }}>
                      <AppTableCell>{item.name ? item.name : '-'}</AppTableCell>
                      <AppTableCell>{item.email ? item.email : '-'}</AppTableCell>
                      <AppTableCell>
                        <Typography variant="subtitle2">{item.mobilePhone ? item.mobilePhone : '-'}</Typography>
                      </AppTableCell>
                      <AppTableCell>
                        <Typography variant="subtitle2">{item.contactBy}</Typography>
                      </AppTableCell>
                      <AppTableCell>
                        <Typography variant="subtitle2">
                          {item.contactType ? t(`interview-log:contactType.${item.contactType}`) : '-'}
                        </Typography>
                      </AppTableCell>

                      <AppTableCell style={{ whiteSpace: 'normal' }}>
                        <Typography variant="subtitle2">{item.comment}</Typography>
                      </AppTableCell>
                      <AppTableCell>
                        {item.otherFiles?.length && item.otherFiles?.length > 0 ? (
                          <Typography variant="subtitle2" color="textSecondary">
                            {t('interview-log:list.columns.totalImages', {
                              fieldName: t(item.otherFiles?.length.toLocaleString()),
                            })}
                          </Typography>
                        ) : (
                          '-'
                        )}
                      </AppTableCell>
                      <AppTableCell>
                        <Typography variant="subtitle2">
                          {item.status ? t(`interview-log:status.${item.status}`) : '-'}
                        </Typography>
                      </AppTableCell>
                      <AppTableCell>
                        {item.createdAt ? (
                          <Typography variant="subtitle2">
                            {dayjs(item.createdAt).format(`${dateDisplayFormat}`)}
                            <br />
                            {dayjs(item.createdAt).format(`HH:mm:ss`)}
                          </Typography>
                        ) : (
                          <Typography>-</Typography>
                        )}
                      </AppTableCell>
                      <AppTableCell>
                        {item.updatedAt ? (
                          <Typography variant="subtitle2">
                            {dayjs(item.updatedAt).format(`${dateDisplayFormat}`)}
                            <br />
                            {dayjs(item.updatedAt).format(`HH:mm:ss`)}
                          </Typography>
                        ) : (
                          <Typography>-</Typography>
                        )}
                      </AppTableCell>
                      <AppTableCell style={{ whiteSpace: 'normal' }}>{item.crDisplayName}</AppTableCell>
                    </TableRow>
                  ))}
                </>
              )
            }
          />

          {selectedLogItem && (
            <InterviewLogItemDialog
              key={selectedLogItem.hashId}
              title={t('interview-log:list.columns.comment')}
              open={isInterviewLogDialogOpen}
              onClose={() => {
                setIsInterviewLogDialogOpen(false);
              }}
              onCloseClick={() => {
                setIsInterviewLogDialogOpen(false);
                setSelectedLogItem(undefined);
              }}
              interviewLogItem={selectedLogItem}
              onFormSubmit={onEditFormSubmit}
              type={type}
            />
          )}

          {isAddInterviewLogDialogOpen && (
            <AddInterviewLogItemDialog
              title={t('interview-log:dialogAdd.titleNoneUser')}
              open={isAddInterviewLogDialogOpen}
              onClose={() => {
                setIsAddInterviewLogDialogOpen(false);
              }}
              onCloseClick={() => {
                setIsAddInterviewLogDialogOpen(false);
              }}
              onFormSubmit={onSubmitAddNewLog}
              type={type}
            />
          )}
        </Box>
      </Container>
    </>
  );
};

export default NoneUserInterviewLogListTable;
