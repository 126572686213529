import React, { FC } from 'react';

import { Box, Container, Grid, makeStyles, Typography } from '@material-ui/core';
import YoutubeSearchedForIcon from '@material-ui/icons/YoutubeSearchedFor';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  ReportListingListSearchFormData,
  ReportListingStatus,
  ReportListingType,
} from '../../../../models/report-listing.model';
import useAppContainerStyles from '../../../../theme/container.style';
import AppButton from '../../../ui/AppButton';
import AppChipList from '../../../ui/AppChipList';

export type ReportedSearchFormProps = {
  isLoadingData: boolean;
  currentFilters: ReportListingListSearchFormData | undefined;
  onSearchFormSubmit: (data: ReportListingListSearchFormData) => void;
};

const reportStatusList = [
  {
    label: 'report-listing:common.reportStatus.waiting',
    value: ReportListingStatus.Waiting,
  },
  {
    label: 'report-listing:common.reportStatus.done',
    value: ReportListingStatus.Done,
  },
  {
    label: 'report-listing:common.reportStatus.duplicate_report',
    value: ReportListingStatus.DuplicateReport,
  },
  {
    label: 'report-listing:common.reportStatus.ignore',
    value: ReportListingStatus.Ignore,
  },
];

const reportTypeList = [
  {
    label: 'report-listing:common.reportType.not_reasonable_price',
    value: ReportListingType.NotReasonablePrice,
  },
  {
    label: 'report-listing:common.reportType.duplicated_item',
    value: ReportListingType.DuplicatedItem,
  },
  {
    label: 'report-listing:common.reportType.forbidden_item',
    value: ReportListingType.ForbiddenItem,
  },
  {
    label: 'report-listing:common.reportType.fraud_item',
    value: ReportListingType.FraudItem,
  },
  {
    label: 'report-listing:common.reportType.unauthorized_item',
    value: ReportListingType.UnauthorizedItem,
  },
  {
    label: 'report-listing:common.reportType.other',
    value: ReportListingType.Other,
  },
];

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
  },
  searchBoxContainer: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 500,
    },
  },
  filterHeader: {
    fontWeight: 700,
  },
}));

const ReportedSearchForm: FC<ReportedSearchFormProps> = (props) => {
  const { currentFilters, isLoadingData, onSearchFormSubmit } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const containerClasses = useAppContainerStyles();
  const { handleSubmit, control, setValue } = useForm<ReportListingListSearchFormData>({
    mode: 'onSubmit',
    defaultValues: {
      filteringStatuses: [],
      filteringTypes: [],
    },
  });

  const submitSearchForm = () => {
    if (!isLoadingData) {
      handleSubmit(onSearchFormSubmit)();
    }
  };

  const clearSearchForm = () => {
    if (!isLoadingData) {
      setValue('filteringStatuses', []);
      setValue('filteringTypes', []);
      handleSubmit(onSearchFormSubmit)();
    }
  };

  return (
    <Box className={classes.filterContainer}>
      <Container className={containerClasses.container}>
        <Box>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} md container spacing={2}>
              <Grid item xs={12} md={7}>
                <Typography variant="body2" className={classes.filterHeader}>
                  {t('report-listing:common.label.reportType')}
                </Typography>
                <Box display="flex" flexDirection="row" flexWrap="wrap" mt={1}>
                  <Controller
                    control={control}
                    name="filteringTypes"
                    defaultValue={currentFilters?.filteringStatuses || []}
                    render={({ field }) => (
                      <>
                        <AppChipList
                          items={reportTypeList}
                          selectedValues={field.value}
                          onChange={(values: string[]) => {
                            field.onChange(values);
                            submitSearchForm();
                          }}
                        />
                      </>
                    )}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} md={5}>
                <Typography variant="body2" className={classes.filterHeader}>
                  {t('report-listing:common.label.reportStatus')}
                </Typography>
                <Box display="flex" flexDirection="row" flexWrap="wrap" mt={1}>
                  <Controller
                    control={control}
                    name="filteringStatuses"
                    defaultValue={currentFilters?.filteringStatuses || []}
                    render={({ field }) => (
                      <>
                        <AppChipList
                          items={reportStatusList}
                          selectedValues={field.value}
                          onChange={(values: string[]) => {
                            field.onChange(values);
                            submitSearchForm();
                          }}
                        />
                      </>
                    )}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12} md="auto">
              <AppButton variant="outlined" color="primary" onClick={clearSearchForm} disabled={isLoadingData}>
                <YoutubeSearchedForIcon />
              </AppButton>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default ReportedSearchForm;
