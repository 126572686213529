import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  page: {
    minHeight: 'calc(100vh - 112px)',
  },
  container: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  flexBreak: {
    flexBasis: '100%',
  },
  rightColumn: {
    borderTop: `solid 1px ${theme.palette.divider}`,
    borderLeft: 'none',
    [theme.breakpoints.up('md')]: {
      flexBasis: '340px',
      borderLeft: `solid 1px ${theme.palette.divider}`,
      borderTop: 'none',
      paddingLeft: theme.spacing(4),
      maxWidth: '340px',
    },
  },
  coinAmountBox: {
    backgroundColor: theme.palette.grey['200'],
    height: '46px',
  },
  paymentOptionBox: {
    backgroundColor: theme.palette.grey['200'],
    height: '46px',
  },
  remarkBox: {
    backgroundColor: theme.palette.grey['200'],
    height: '46px',
  },
  progressionOverlay: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    zIndex: 10,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: theme.customPalette.global.lightOverlay.backgroundColor,
  },
  extLinkIcon: {
    fontSize: 18,
    verticalAlign: 'middle',
    marginLeft: 3,
  },
}));

export const generalInfoStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'unset',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '180px 1fr',
      gridTemplateRows: 'unset',
      placeContent: 'center',
    },
  },
  avatarContainer: {
    [theme.breakpoints.up('md')]: {
      paddingTop: '16px',
    },
  },
  avatar: {
    width: '120px',
    height: '120px',
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
    border: 'solid 2px',
    borderColor: theme.palette.grey[300],
    [theme.breakpoints.up('md')]: {
      width: '160px',
      height: '160px',
    },
  },
  commonInfoContainer: {
    marginBottom: '20px',
    [theme.breakpoints.up('md')]: {
      paddingLeft: '24px',
    },
  },
  personalInfoContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    [theme.breakpoints.up('lg')]: {
      flexFlow: 'row nowrap',
      '& > div:first-of-type': {
        flex: '1 0 auto',
      },
    },
  },
  socialLinkContainer: {
    marginTop: '20px',
    display: 'flex',
    flexFlow: 'column wrap',
    [theme.breakpoints.up('md')]: {
      flexFlow: 'row wrap',
      '& > *': {
        flex: '1 0 50%',
      },
    },
    [theme.breakpoints.up('lg')]: {
      '& > *': {
        flex: '1 0 50%',
      },
    },
  },
  displayName: {
    fontWeight: 700,
    fontSize: '24px',
    marginBottom: '6px',
    marginTop: '20px',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      marginTop: 'unset',
      textAlign: 'unset',
    },
    '&:before': {
      content: 'attr(data-title)',
      display: 'block',
      fontSize: '16px',
      color: theme.palette.grey[700],
      fontWeight: 400,
    },
  },
  statusBadgeContainer: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '10px',
    '& > *': {
      marginRight: '6px',
      marginBottom: '6px',
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'unset',
    },
  },
  basicInfoContainer: {
    display: 'inline-flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'unset',
    },
  },
  shortInfoContainer: {
    display: 'inline-flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: '16px',
    '&.clickable:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.main,
      '&:before': {
        color: theme.palette.grey[700],
      },
    },
    '&.full-block': {
      display: 'flex',
    },
  },
  commonInfo: {
    fontWeight: 600,
    '&:before': {
      content: 'attr(data-title)',
      fontWeight: 400,
      fontSize: '14px',
      color: theme.palette.grey[700],
      display: 'block',
    },
    '&.clickable:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.main,
      '&:before': {
        color: theme.palette.grey[700],
      },
    },
    '& ~ .open-link': {
      display: 'flex',
      marginLeft: '6px',
      alignSelf: 'flex-end',
    },
    '&.text-lg': {
      fontSize: '18px',
    },
    '& .leading-icon': {
      display: 'inline-flex',
      marginRight: '8px',
    },
  },
  usernameInfo: {
    fontWeight: 600,
    '&:before': {
      content: 'attr(data-title)',
      fontWeight: 400,
    },
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.main,
      '&:before': {
        color: 'initial',
      },
    },
    '& ~ .open-link': {
      display: 'flex',
      marginLeft: '4px',
    },
  },
  subTabContainer: {
    borderBottom: 'solid 1px',
    borderBottomColor: theme.palette.grey[300],
    '& .Mui-selected': {
      fontWeight: 700,
    },
    '& > .MuiTabs-scroller': {
      overflow: 'auto !important',
    },
  },
  coinBalanceBox: {
    position: 'relative',
    display: 'inline-flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    width: 'min(100%, 240px)',
    borderRadius: '8px',
    backgroundColor: theme.palette.grey[100],
    padding: '10px',
    fontSize: '20px',
    fontWeight: 600,
    marginTop: '20px',
    '& > .icon': {
      display: 'block',
      marginRight: '8px',
    },
    '&:before': {
      content: 'attr(data-title)',
      position: 'absolute',
      top: 0,
      left: 0,
      transform: 'translateY(-100%)',
      fontSize: '14px',
      fontWeight: 600,
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '10px',
    },
  },
  userMenu: {
    backgroundColor: theme.palette.grey[200],
  },
  userMenuItem: {
    backgroundColor: '#ffffff',
    '&.danger': {
      color: theme.palette.error.main,
      '& svg': {
        fill: theme.palette.error.main,
      },
    },
    '&.link': {
      color: theme.palette.primary.main,
      '& svg': {
        fill: theme.palette.primary.main,
      },
    },
    '&.restore': {
      color: theme.palette.success.main,
      '& svg': {
        fill: theme.palette.success.main,
      },
    },
    '&.disabled': {
      color: theme.palette.grey[600],
      '& svg': {
        fill: theme.palette.grey[600],
      },
    },
  },
  userMenuContainer: {
    position: 'relative',
    top: 0,
    right: 0,
    '& .button-text': {
      display: 'none',
    },
    '& .MuiButton-startIcon': {
      marginRight: 0,
    },
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      transform: 'translate3d(16px, -16px, 0)',
    },
    [theme.breakpoints.up('lg')]: {
      '& .button-text': {
        display: 'inline-block',
      },
      '& .MuiButton-startIcon': {
        marginRight: '8px',
      },
    },
  },
  commonUserDataContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    '& > .menu-container': {
      order: 1,
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'flex-end',
      marginBottom: '24px',
    },
    '& > .common-data': {
      order: 2,
    },
    [theme.breakpoints.up('md')]: {
      '& > .menu-container': {
        order: 2,
        paddingTop: 0,
        paddingBottom: 0,
        marginBottom: 0,
      },
      '& > .common-data': {
        order: 1,
        marginTop: '20px',
      },
    },
  },
}));

export const usernameTooltipStyles = makeStyles(() => ({
  tooltip: {
    transform: 'translateY(28px) !important',
  },
}));
