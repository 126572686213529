import React, { FC, useEffect, useState } from 'react';

import { Box, TableRow, Typography } from '@material-ui/core';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { defaultRowsPerPage } from '../../../../helpers/constants';
import { AppTableConditions, PaymentHistory, PaymentHistorySearchParams, TableColumn } from '../../../../models';
import { getPaymentHistoryByUserHashId } from '../../../../services';
import AppDialog from '../../../ui/AppDialog';
import AppTableCell from '../../../ui/AppTableCell';
import AppTableList from '../../../ui/AppTableList';

export type UserPaymentHistoryDialogProps = {
  title: string;
  open: boolean;
  userHashId: string;
  isLoadingData: boolean;
  onClose: () => void;
  onDialogExited?: () => void;
};

const UserPaymentHistoryDialog: FC<UserPaymentHistoryDialogProps> = (props) => {
  const { open, title, userHashId, onClose, onDialogExited } = props;
  const [purchaseData, setPurchaseData] = useState<PaymentHistory[]>([]);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const { i18n } = useTranslation();
  const dateDisplayFormat = i18n.language === 'th' ? 'D MMM BBBB' : 'D MMM YYYY';

  const paymentHistoryTableColumns: TableColumn[] = [
    { name: 'createdAt', label: 'user:list.columns.createdAt', width: 200 },
    { name: 'productThaiName', label: 'user:list.columns.productThaiName', width: 250 },
    { name: 'productPrice', label: 'user:list.columns.productPrice', width: 200 },
    { name: 'paymentMethod', label: 'user:list.columns.paymentMethod', width: 200 },
  ];

  const [tableConditions, setTableConditions] = useState<Partial<AppTableConditions>>({
    page: 0,
    rowsPerPage: defaultRowsPerPage,
  });

  const getCurrentSearchParam = (): Partial<PaymentHistorySearchParams> => ({
    page: tableConditions.page || 0,
    rowsPerPage: tableConditions.rowsPerPage || defaultRowsPerPage,
  });

  const requestPaymentHistoryList = async () => {
    const searchConditions = getCurrentSearchParam();
    setIsLoadingData(true);
    const res = await getPaymentHistoryByUserHashId(userHashId, searchConditions);
    setIsLoadingData(false);
    if (res.data) {
      setPurchaseData(res.data.data);
      setTotalItems(res.data.totalCount);
    }
  };

  useEffect(() => {
    requestPaymentHistoryList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userHashId]);

  const onTableConditionChangedHandler = (values: AppTableConditions) => {
    if (JSON.stringify(values) !== JSON.stringify(tableConditions)) {
      setTableConditions(values);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useDeepCompareEffect((): any => {
    requestPaymentHistoryList();
  }, [tableConditions]);

  return (
    <AppDialog
      open={open}
      title={title}
      onClose={onClose}
      okButtonText="common:button.close"
      okButtonColor="default"
      okButtonVariant="outlined"
      closeOnOkClicked
      dialogProps={{
        fullWidth: true,
        maxWidth: 'lg',
        TransitionProps: {
          onExited: () => {
            if (typeof onDialogExited === 'function') {
              onDialogExited();
            }
          },
        },
      }}>
      <Box px={3}>
        {!isLoadingData && (
          <AppTableList
            {...tableConditions}
            aria-label="payment-history-list"
            columns={paymentHistoryTableColumns}
            totalDataCount={totalItems}
            hasPagination
            onTableConditionChanged={onTableConditionChangedHandler}
            isLoading={isLoadingData}
            tableBodyContent={
              purchaseData.length > 0 && (
                <>
                  {purchaseData.map((item: PaymentHistory) => (
                    <TableRow key={item.hashId}>
                      <AppTableCell>
                        <Typography component="div" variant="subtitle2">
                          {dayjs(item.createdAt).format(`${dateDisplayFormat} HH:mm:ss`)}
                        </Typography>
                      </AppTableCell>
                      <AppTableCell>{item.productThaiName}</AppTableCell>
                      <AppTableCell>{item.productPrice ? item.productPrice / 100 : 'ไม่ระบุ'}</AppTableCell>
                      <AppTableCell>{item.paymentMethod}</AppTableCell>
                    </TableRow>
                  ))}
                </>
              )
            }
          />
        )}
      </Box>
    </AppDialog>
  );
};

export default UserPaymentHistoryDialog;
