import { apiKTRequest } from '../helpers/request';
import { ApiResponse } from '../models';

// eslint-disable-next-line import/prefer-default-export
export const uploadSearchSuggestionFile = async (formUploadData: {
  dictionaryFile: FileList | null;
  directIntentFile: FileList | null;
}): Promise<ApiResponse<boolean>> => {
  try {
    const formData = new FormData();
    formData.append('dictionaryFile', formUploadData.dictionaryFile?.item(0) as File);
    formData.append('directIntentFile', formUploadData.directIntentFile?.item(0) as File);
    const result = await apiKTRequest('/admin_gm/upload/file', {
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    });

    return {
      data: [200, 201].includes(result.status),
    };
  } catch (e) {
    // Error message will be handled at UI
    return {
      data: false,
    };
  }
};
