import RecommendedTagList from './RecommendedTagList';
import ReportedItemList from './ReportedItemList';
import SnsMenuListPage from './SnsMenuList';
import { RouteConfig } from '../../models/route.model';

const listingRoutes = (path: string): RouteConfig[] => [
  {
    key: 'SNS_MENU_LIST',
    path,
    component: SnsMenuListPage,
    exact: true,
    title: 'sns:title.snsManager',
  },
  {
    key: 'SNS_ITEM_REPORT',
    path: `${path}/report`,
    component: ReportedItemList,
    exact: true,
    title: 'sns:title.itemReportList',
    backUrl: path,
  },
  {
    key: 'SNS_RECOMMENDED_TAGS',
    path: `${path}/recommended-tags`,
    component: RecommendedTagList,
    exact: true,
    title: 'sns:title.recommendedTags',
    backUrl: path,
  },
];

export default listingRoutes;
