import { UserImage, UserRole } from './user.model';

export interface AuthUserObject {
  id: string | null;
  firestoreUid: string | null;
  hashId: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  role: UserRole | null;
  displayName: string | null;
  isUserVerified: boolean | null;
  profileImage: UserImage | null;
}

export class AuthUser {
  id: string | null = null;

  firestoreUid: string | null = null;

  hashId: string | undefined = undefined;

  email: string | undefined = undefined;

  firstName: string | undefined = undefined;

  lastName: string | undefined = undefined;

  fullName: string | undefined = undefined;

  role: UserRole | undefined = undefined;

  displayName: string | undefined = undefined;

  isUserVerified: boolean | null = null;

  profileImage: UserImage | undefined = undefined;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(data: { [x: string]: any }) {
    Object.assign(this, data);

    if (Array.isArray(data.roles)) {
      this.role = data.roles[0] as UserRole;
    }
    if (typeof data.roles === 'string') {
      this.role = data.roles as UserRole;
    }
  }

  get roleName(): string | undefined {
    if (!this.role) {
      return undefined;
    }

    if ([UserRole.Seller, UserRole.SellerBronze, UserRole.SellerSilver, UserRole.SellerGold].includes(this.role)) {
      return 'buyer';
    }

    if ([UserRole.Staff, UserRole.Manager, UserRole.GM].includes(this.role)) {
      return 'admin';
    }

    return 'buyer';
  }

  get isBuyer(): boolean {
    return (this.role as UserRole) === UserRole.Buyer;
  }

  get isSeller(): boolean {
    return [UserRole.Seller, UserRole.SellerBronze, UserRole.SellerSilver, UserRole.SellerGold].includes(
      this.role as UserRole,
    );
  }

  get isAdmin(): boolean {
    return [UserRole.Staff, UserRole.Manager, UserRole.GM].includes(this.role as UserRole);
  }

  get asDataObject(): AuthUserObject {
    return {
      id: this.id || null,
      firestoreUid: this.firestoreUid || null,
      hashId: this.hashId || null,
      email: this.email || null,
      firstName: this.firstName || null,
      lastName: this.lastName || null,
      role: this.role || null,
      displayName: this.displayName || null,
      isUserVerified: this.isUserVerified || null,
      profileImage: this.profileImage || null,
    };
  }
}
