import React, { FC, useCallback, useContext, useEffect, useState } from 'react';

import { Box, Button, Card, CardContent, Divider, Typography, makeStyles } from '@material-ui/core';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { AppGlobalUiContext } from '../../../../../context/AppGlobalUiContext';
import { UserDataTC } from '../../../../../models';
import { Product } from '../../../../../models/product.model';
import {
  GetShoppingCartTCResponse,
  OrderCheckoutRequest,
  ShoppingCartRequest,
  SummarizeResponse,
} from '../../../../../models/shopping';
import {
  addShoppingCart,
  checkOutOrderOnce,
  getShoppingCartByHashId,
  summarizePromoCode,
} from '../../../../../services/shopping';
import theme from '../../../../../theme';
import useTypographyStyles from '../../../../../theme/typography.style';
import AppButton from '../../../../ui/AppButton';
import OrderDetailDialog from '../../../common/v3/OrderDetailDialog';

export interface ShoppingCartCheckoutProps {
  currentUser: UserDataTC;
  selectedItem: Product[];
  onAddedShoppingCart: () => void;
  onClickDelete: (index: number) => void;
  onSubmitPayment: () => void;
}
const useStyles = makeStyles((themeStyle) => ({
  container: {
    padding: theme.spacing(2),
  },
  card: {
    marginBottom: theme.spacing(1),
  },
  cardContent: {
    padding: theme.spacing(2),
  },
  promoCodeInput: {
    padding: theme.spacing(1),
    width: '250px',
  },
  boxGrey: {
    padding: `${themeStyle.spacing(1)}px ${themeStyle.spacing(2)}px`,
    backgroundColor: themeStyle.palette.grey['200'],
  },
}));

const ShoppingCartCheckout: FC<ShoppingCartCheckoutProps> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const typoClasses = useTypographyStyles();
  const classes = useStyles();
  const iconCoin = '../../static/images/icons/icon-coin.png';
  const iconBronze = '../../static/images/icons/icon-bronze-ribbon.png';
  const iconSilver = '../../static/images/icons/icon-silver-ribbon.png';
  const iconGold = '../../static/images/icons/icon-gold-ribbon.png';
  const { currentUser, selectedItem, onClickDelete, onAddedShoppingCart, onSubmitPayment } = props;

  const { showSnackbar, setAppLoading } = useContext(AppGlobalUiContext);

  const [orderList, setOrderList] = useState<GetShoppingCartTCResponse>();
  const [isOrderDetailDialogOpen, setIsOrderDetailDialogOpen] = useState<boolean>(false);

  const [summarizeData, setSummarizeData] = useState<SummarizeResponse | null>();

  const shoppingCartData: ShoppingCartRequest = {
    userHashId: currentUser.userHashId,
    productDetails: selectedItem?.map((item) => ({
      productHashId: item.hashId,
      productType: item.purchaseType === 'ONCE' ? 'MEMBERSHIP' : 'COIN',
      productAmount: 1,
    })),
  };

  const cancelShoppingCartData: ShoppingCartRequest = {
    userHashId: currentUser.userHashId,
    productDetails: [],
  };

  const priceLists = selectedItem?.map((item) => item.price);
  const totalPrice = priceLists.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  const shoppingCartByHashId = () => {
    (async () => {
      setAppLoading(true);
      const result = await getShoppingCartByHashId(currentUser.userHashId);

      if (result.data) {
        setAppLoading(false);
        setOrderList(result.data);
      } else {
        setAppLoading(false);
        showSnackbar(t(result.error as string).toString(), 'error');
      }
    })();
  };

  const checkPromoCode = useCallback(
    async (data: string) => {
      const result = await summarizePromoCode(currentUser.userHashId, data);

      setSummarizeData(result.data);
    },
    [currentUser.userHashId],
  );

  const onSubmitAddShoppingCart = useCallback(
    async (formData: ShoppingCartRequest) => {
      setAppLoading(true);

      const result = await addShoppingCart(formData);
      if (result.data) {
        setAppLoading(false);
        setTimeout(() => {
          showSnackbar(t('shopping-cart:message.updateShoppingCartSuccess').toString(), 'success');
        }, 300);

        if (typeof onAddedShoppingCart === 'function') {
          onAddedShoppingCart();
        }
        shoppingCartByHashId();
      } else {
        setAppLoading(false);
        showSnackbar(t(result.error as string).toString(), 'error');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser.id, setAppLoading, showSnackbar, t],
  );

  useEffect(() => {
    shoppingCartByHashId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmitPaymentOrder = useCallback(
    async (paymentData?: OrderCheckoutRequest) => {
      if (paymentData) {
        setAppLoading(true);
        const fileUrl = paymentData.uploadSlip?.fullUrl;
        const fileHashId = paymentData.uploadSlip?.fileHashId;
        const promoCode = paymentData?.promoCode;
        delete paymentData.uploadSlip;

        if (!promoCode && promoCode === '') {
          delete paymentData.promoCode;
        }

        if (fileUrl && fileHashId) {
          const result = await checkOutOrderOnce(
            promoCode && promoCode !== ''
              ? {
                  ...paymentData,
                  fileUrl,
                  fileHashId,
                  promoCode,
                }
              : {
                  ...paymentData,
                  fileUrl,
                  fileHashId,
                },
          );

          if (result.data) {
            setAppLoading(false);
            showSnackbar(t('shopping-cart:message.checkoutOrderOnceSuccess').toString(), 'success');
            setIsOrderDetailDialogOpen(false);
            if (typeof onSubmitPayment === 'function') {
              onSubmitPayment();
            }
            setSummarizeData(null);
            setOrderList(undefined);
            setTimeout(() => {
              history.push(`/v3/order-approve?id=${result.data?.orderOnceHashId}`);
            }, 1000);
          } else if (result.error.response.data.details === 'Order for this user already exist') {
            showSnackbar(t('shopping-cart:message.OrderAlreadyExist').toString(), 'error');
            setTimeout(() => {
              setIsOrderDetailDialogOpen(false);
              window.location.href = `${currentUser.userHashId}?tab=order`;
            }, 2000);
          } else {
            showSnackbar(t('shopping-cart:message.checkoutOrderOnceFailed').toString(), 'error');
          }
        }
        setAppLoading(false);
      }
    },
    [currentUser.userHashId, history, onSubmitPayment, setAppLoading, showSnackbar, t],
  );

  const onDeleteProductItemClick = (index: number) => {
    if (typeof onClickDelete === 'function') {
      onClickDelete(index);
    }
  };

  return (
    <Box>
      <Card variant="outlined" className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Box mb={3}>
            <Typography className={typoClasses.textWeightBold}>{t('shopping-cart:title.orderList')}</Typography>
            {selectedItem.length > 0 ? (
              <>
                {selectedItem.map((item, index: number) => {
                  const itemKey = `item_${index}`;
                  return (
                    <Box key={itemKey}>
                      <Box display="flex" flexDirection="row" justifyContent="space-between" my={2}>
                        <Box display="flex" flexDirection="row">
                          {!item.membershipType && <img src={iconCoin} width={24} height={24} alt="coin" />}
                          {item.membershipType === 'BRONZE' && (
                            <img src={iconBronze} width={24} height={30} alt="BRONZE" />
                          )}
                          {item.membershipType === 'SILVER' && (
                            <img src={iconSilver} width={24} height={30} alt="SILVER" />
                          )}
                          {item.membershipType === 'GOLD' && <img src={iconGold} width={24} height={30} alt="GOLD" />}
                          <Box>
                            <Typography style={{ marginLeft: 6, fontWeight: 'bold' }}>{item.title}</Typography>
                            {!!item.freeCoin && item.freeCoin > 0 && (
                              <Box display="flex" flexDirection="row">
                                <Typography
                                  variant="subtitle2"
                                  className={typoClasses.greyText}
                                  style={{ marginLeft: 8, paddingTop: 2 }}>
                                  {t('shopping-cart:detail.freeCoin')} :
                                </Typography>
                                <Typography
                                  variant="subtitle2"
                                  className={typoClasses.greyText}
                                  style={{ marginLeft: 8, paddingTop: 2 }}>
                                  {item.freeCoin?.toLocaleString() || ''} Coins
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </Box>

                        <Box display="flex" flexDirection="row">
                          <Box>
                            <Typography style={{ fontWeight: 'bold' }}>
                              {item.price.toLocaleString()} {t('shopping-cart:unit')}
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              className={typoClasses.greyText}
                              style={{ textAlign: 'right' }}>
                              x1
                            </Typography>
                          </Box>
                          <Button
                            size="small"
                            style={{ marginLeft: 8, width: '20px', height: '40px' }}
                            onClick={() => onDeleteProductItemClick(index)}>
                            <CloseIcon color="error" />
                          </Button>
                        </Box>
                      </Box>
                      <Divider />
                    </Box>
                  );
                })}
              </>
            ) : (
              <Box textAlign="center" margin={4}>
                <Typography>{t('shopping-cart:noOrderList')}</Typography>
              </Box>
            )}
          </Box>

          <Box>
            {selectedItem.length > 0 && (
              <Box
                my={2}
                className={classes.boxGrey}
                alignItems="flex-end"
                justifyContent="space-between"
                display="flex"
                flexDirection="row">
                <Box>
                  <Typography style={{ fontWeight: 'bold' }}>{t('shopping-cart:totalPrice')}</Typography>
                </Box>
                <Box>
                  {summarizeData ? (
                    <Typography style={{ fontWeight: 'bold' }}>
                      {summarizeData?.totalPrice.toLocaleString()} {t('shopping-cart:unit')}
                    </Typography>
                  ) : (
                    <Typography style={{ fontWeight: 'bold' }}>
                      {totalPrice.toLocaleString()} {t('shopping-cart:unit')}
                    </Typography>
                  )}
                </Box>
              </Box>
            )}

            {selectedItem.length > 0 && (
              <Box style={{ textAlign: 'center' }}>
                <AppButton
                  type="submit"
                  color="primary"
                  startIcon={<AddShoppingCartIcon />}
                  onClick={() => {
                    onSubmitAddShoppingCart(shoppingCartData);
                    setIsOrderDetailDialogOpen(true);
                  }}>
                  <Typography style={{ fontWeight: 'bold' }}>{t('shopping-cart:button.confirmAddToCart')}</Typography>
                </AppButton>
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>

      {orderList && orderList?.products?.length > 0 && (
        <Box mt={3}>
          <Card variant="outlined" className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Typography style={{ fontWeight: 'bold' }}>{t('shopping-cart:title.shoppingCart')}</Typography>
                <Typography style={{ marginLeft: 6 }}>{t('shopping-cart:detail.orderTotal')}</Typography>
                <AppButton type="submit" color="primary" onClick={() => setIsOrderDetailDialogOpen(true)}>
                  <Typography style={{ fontWeight: 'bold' }}>{t('shopping-cart:button.cartList')}</Typography>
                </AppButton>
              </Box>
            </CardContent>
          </Card>
        </Box>
      )}

      {isOrderDetailDialogOpen && orderList && orderList?.products?.length > 0 && (
        <OrderDetailDialog
          title={t('shopping-cart:detail.title')}
          open={isOrderDetailDialogOpen}
          onClose={() => {
            setIsOrderDetailDialogOpen(false);
          }}
          onCloseClick={() => {
            setIsOrderDetailDialogOpen(false);
            setSummarizeData(null);
          }}
          orderItem={orderList}
          userHashId={currentUser.userHashId}
          onFormSubmit={onSubmitPaymentOrder}
          onCheckPromoCode={checkPromoCode}
          onCancelOrder={() => {
            onSubmitAddShoppingCart(cancelShoppingCartData);
            setIsOrderDetailDialogOpen(false);
            setSummarizeData(null);
          }}
          summarize={summarizeData || undefined}
        />
      )}
    </Box>
  );
};

export default ShoppingCartCheckout;
