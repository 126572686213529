import React, { FC, useState } from 'react';

import { Box, Grid, InputLabel, MenuItem, OutlinedInput, Select, Typography, makeStyles } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { formatDate } from '../../../../../helpers/date';
import usePrivilege from '../../../../../hooks/usePrivilege';
import { LoginBonusFormDataRequest } from '../../../../../models/login-bonus.model';
import AppButton from '../../../../ui/AppButton';
import AppDialog from '../../../../ui/AppDialog';
import AppFormControl from '../../../../ui/AppFormControl';

export type AddNewLoginBonusItemDialogProps = {
  title: string;
  open: boolean;
  onClose: () => void;
  onCloseClick?: () => void;
  onFormSubmit: (data: LoginBonusFormDataRequest) => void;
};

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  numberInput: {
    MozAppearance: 'none',
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
  confirmDialogBox: {
    width: '100%',
    maxWidth: '1000px',
    [theme.breakpoints.up('md')]: {
      minWidth: '400px',
    },
  },
  buttonGroups: {
    '& > *': {
      margin: `0 ${theme.spacing(1)}px`,
    },
  },
  widthGrid: {
    width: '20%',
    [theme.breakpoints.up('md')]: {
      width: '10%',
    },
  },
}));

const AddNewLoginBonusItemDialog: FC<AddNewLoginBonusItemDialogProps> = (props) => {
  const classes = useStyles();
  const { open, title, onClose, onCloseClick, onFormSubmit } = props;
  const { t } = useTranslation();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);
  const { canPerform } = usePrivilege();
  const allowCreate = canPerform('loginBonus', 'create');
  const [dayValue, setDayValue] = useState<number>(3);

  const {
    handleSubmit,
    getValues,
    control,
    formState: { errors },
  } = useForm<LoginBonusFormDataRequest>({
    mode: 'onChange',
    defaultValues: {
      title: '',
      startDate: 0 || null,
      endDate: 0 || null,
      period: 3 || null,
      day1: 0,
      day2: 0,
      day3: 0,
      day4: 0,
      day5: 0,
      day6: 0,
      day7: 0,
      day8: 0,
      day9: 0,
      day10: 0,
    },
  });

  const onSaveClick = () => {
    if (typeof onFormSubmit === 'function') {
      onFormSubmit(getValues());
    }
  };

  const onConfirmationSaveClick = () => {
    setIsConfirmDialogOpen(false);
    if (typeof onFormSubmit === 'function') {
      onFormSubmit(getValues());
    }
  };

  const onConfirmationCancelClick = () => {
    setIsConfirmDialogOpen(false);
  };

  return (
    <>
      <AppDialog
        open={open}
        onClose={onClose}
        title={title}
        okButtonText="common:button.close"
        okButtonColor="default"
        okButtonVariant="outlined"
        closeOnOkClicked
        noActionBar
        dialogProps={{
          disableBackdropClick: true,
          fullWidth: true,
          maxWidth: 'md',
        }}>
        <form onSubmit={handleSubmit(onSaveClick)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="title"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <AppFormControl>
                    <InputLabel htmlFor="login-bonus-title">
                      {t('daily-challenge:loginBonus.details.label.campaignName')}
                    </InputLabel>
                    <OutlinedInput
                      {...field}
                      id="login-bonus-title"
                      fullWidth
                      disabled={!allowCreate}
                      error={!!errors.title}
                    />
                  </AppFormControl>
                )}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <Typography variant="subtitle2" style={{ marginBottom: '6px' }}>
                {t('daily-challenge:loginBonus.details.label.startDateAt')}
              </Typography>
              <Controller
                name="startDate"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <AppFormControl error={!!errors.startDate}>
                    <OutlinedInput
                      id="login-bonus-start-date-at"
                      fullWidth
                      type="datetime-local"
                      defaultValue={field.value ? formatDate(new Date(field.value), 'YYYY-MM-dd') : null}
                      onChange={field.onChange}
                      error={!!errors.startDate}
                      disabled={!allowCreate}
                    />
                  </AppFormControl>
                )}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <Typography variant="subtitle2" style={{ marginBottom: '6px' }}>
                {t('daily-challenge:loginBonus.details.label.endDateAt')}
              </Typography>
              <Controller
                name="endDate"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <AppFormControl error={!!errors.endDate}>
                    <OutlinedInput
                      id="login-bonus-end-date-at"
                      fullWidth
                      type="datetime-local"
                      defaultValue={field.value ? formatDate(new Date(field.value), 'YYYY-MM-dd') : null}
                      onChange={field.onChange}
                      error={!!errors.endDate}
                      disabled={!allowCreate}
                    />
                  </AppFormControl>
                )}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={2} style={{ marginTop: '28px' }}>
              <Controller
                name="period"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <AppFormControl>
                    <InputLabel htmlFor="login-bonus-days">
                      {t('daily-challenge:loginBonus.details.label.days')}
                    </InputLabel>
                    <Select
                      id="login-bonus-days"
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setDayValue(Number(e.target.value) || 0);
                      }}
                      error={!!errors.period}
                      fullWidth>
                      <MenuItem value="3">3 วัน</MenuItem>
                      <MenuItem value="5">5 วัน</MenuItem>
                      <MenuItem value="7">7 วัน</MenuItem>
                      <MenuItem value="10">10 วัน</MenuItem>
                    </Select>
                  </AppFormControl>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle2" style={{ marginBottom: '6px' }}>
                {t('daily-challenge:loginBonus.details.label.coins')}
              </Typography>
              <Grid container spacing={1}>
                {Array(dayValue)
                  .fill(null)
                  .map((_item, index: number) => {
                    const itemKey = `day-${index}`;
                    return (
                      <Grid item sm="auto" className={classes.widthGrid} key={itemKey}>
                        <Controller
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          name={`day${index + 1}` as any}
                          control={control}
                          rules={{
                            required: true,
                          }}
                          render={({ field }) => (
                            <AppFormControl>
                              <OutlinedInput
                                {...field}
                                inputProps={{ min: '1', step: 'any' }}
                                name={`day${index + 1}`}
                                margin="dense"
                                type="number"
                              />
                              <Typography variant="subtitle2" style={{ textAlign: 'center', marginTop: '6px' }}>
                                {`วันที่ ${index + 1}`}
                              </Typography>
                            </AppFormControl>
                          )}
                        />
                      </Grid>
                    );
                  })}
              </Grid>
            </Grid>
          </Grid>
          <Box display="flex" mt={4} mb={2} justifyContent="flex-end" className={classes.buttonGroups}>
            <AppButton type="submit" color="primary" disabled={!allowCreate}>
              {t('daily-challenge:loginBonus.details.buttonAddNew')}
            </AppButton>
            <AppButton
              variant="outlined"
              color="default"
              onClick={() => {
                if (typeof onCloseClick === 'function') {
                  onCloseClick();
                }
              }}>
              {t('common:button.close')}
            </AppButton>
          </Box>
        </form>

        <AppDialog
          title={t('common:dialog.title.confirm').toString()}
          open={isConfirmDialogOpen}
          onOkClick={onConfirmationSaveClick}
          onCancelClick={onConfirmationCancelClick}
          cancelButtonText={t('common:button.cancel').toString()}
          okButtonText={t('common:button.save').toString()}
          okButtonColor="primary">
          <Box className={classes.confirmDialogBox}>
            <Box mb={3}>
              <Typography>{t('daily-challenge:loginBonus.dialogAddNewLoginBonus.title')}</Typography>
            </Box>
          </Box>
        </AppDialog>
      </AppDialog>
    </>
  );
};

export default AddNewLoginBonusItemDialog;
