import React, { FC } from 'react';

import { Box, Container, Grid, makeStyles, MenuItem, OutlinedInput, Select, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import YoutubeSearchedForIcon from '@material-ui/icons/YoutubeSearchedFor';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SelectItem } from '../../../../../models';
import { UserAppListSearchFormData } from '../../../../../models/user-application.model';
import useAppContainerStyles from '../../../../../theme/container.style';
import useTypographyStyles from '../../../../../theme/typography.style';
import AppButton from '../../../../ui/AppButton';
import AppChipList from '../../../../ui/AppChipList';
import AppFormControl from '../../../../ui/AppFormControl';
import AppFormControlGroup from '../../../../ui/AppFormControlGroup';

export type UserAppListSearchFormProps = {
  isLoadingData?: boolean;
  currentFilters: UserAppListSearchFormData | undefined;
  onSearchFormSubmit: (values: UserAppListSearchFormData) => void;
};

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
  },
  searchBoxContainer: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 600,
    },
  },
}));

const searchFieldList: SelectItem<string>[] = [
  {
    label: 'user:common.fields.email',
    value: 'email',
  },
];

const searchStatuses = [
  {
    label: 'user-applications:common.statuses.approved',
    value: 'true',
  },
  {
    label: 'user-applications:common.statuses.pending',
    value: 'false',
  },
];

const UserApplicationsListSearchForm: FC<UserAppListSearchFormProps> = (props) => {
  const classes = useStyles();
  const containerClasses = useAppContainerStyles();
  const typoClasses = useTypographyStyles();
  const { t } = useTranslation();
  const { currentFilters, onSearchFormSubmit, isLoadingData = false } = props;
  const { handleSubmit, setValue, control, getValues } = useForm<UserAppListSearchFormData>({
    mode: 'onSubmit',
    defaultValues: {
      searchField: currentFilters?.searchField || searchFieldList[0].value,
      searchKeyword: currentFilters?.searchKeyword || '',
      filteringStatuses: currentFilters?.filteringStatuses || [],
    },
  });

  const submitSearchForm = () => {
    if (!isLoadingData) {
      setValue('searchKeyword', getValues('searchKeyword').trim());
      handleSubmit(onSearchFormSubmit)();
    }
  };

  const clearSearchForm = () => {
    if (!isLoadingData) {
      setValue('searchField', searchFieldList[0].value);
      setValue('searchKeyword', '');
      setValue('filteringStatuses', []);
      handleSubmit(onSearchFormSubmit)();
    }
  };

  return (
    <Box className={classes.filterContainer}>
      <Container className={containerClasses.container}>
        <form onSubmit={handleSubmit(onSearchFormSubmit)}>
          <Grid container direction="row" spacing={3}>
            <Grid item xs={12} md>
              <Box display="flex" flexDirection="row" flexWrap="nowrap" className={classes.searchBoxContainer}>
                <AppFormControlGroup flexGrow={1} minWidth={0} pr={1}>
                  <AppFormControl margin="dense">
                    <Controller
                      name="searchField"
                      control={control}
                      defaultValue={searchFieldList[0].value}
                      render={({ field }) => (
                        <Select
                          labelId="seller-app-list-search-field-label"
                          id="seller-app-list-search-field"
                          value={field.value}
                          variant="outlined"
                          onChange={field.onChange}
                          fullWidth
                          disabled={isLoadingData}>
                          {searchFieldList.map((item) => (
                            <MenuItem value={item.value} key={item.value}>
                              {t(item.label)}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </AppFormControl>
                  <AppFormControl margin="dense" boxProps={{ flexGrow: 1 }}>
                    <Controller
                      name="searchKeyword"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <OutlinedInput
                          type="search"
                          endAdornment={<SearchIcon />}
                          id="user-list-search-keyword"
                          value={field.value}
                          onChange={field.onChange}
                          fullWidth
                          disabled={isLoadingData}
                        />
                      )}
                    />
                  </AppFormControl>
                </AppFormControlGroup>
                <AppButton type="submit" color="primary" style={{ alignSelf: 'flex-start' }} disabled={isLoadingData}>
                  {t('common:message.search')}
                </AppButton>
              </Box>

              <Grid container direction="row" spacing={0}>
                <Grid item xs={12} md="auto">
                  <Box minWidth="300px" maxWidth="600px" mt={2}>
                    <Typography variant="body2" className={typoClasses.textWeightBold}>
                      {t('user-applications:list.search.statusTitle')}
                    </Typography>
                    <Box display="flex" flexDirection="row" flexWrap="wrap" mt={1}>
                      <Controller
                        control={control}
                        name="filteringStatuses"
                        defaultValue={currentFilters?.filteringStatuses || []}
                        render={({ field }) => (
                          <>
                            <AppChipList
                              items={searchStatuses}
                              selectedValues={field.value}
                              onChange={(values: string[]) => {
                                field.onChange(values);
                                submitSearchForm();
                              }}
                              isDisabled={isLoadingData}
                            />
                          </>
                        )}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md="auto">
              <AppButton variant="outlined" color="primary" onClick={clearSearchForm} disabled={isLoadingData}>
                <YoutubeSearchedForIcon />
              </AppButton>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Box>
  );
};

export default UserApplicationsListSearchForm;
