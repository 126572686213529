import { apiKTRequest } from '../helpers/request';
import { apiResponseTransformer } from '../helpers/response';
import { getPagingParameter, queryParamGenerator } from '../helpers/utils';
import {
  ApiResponse,
  Brand,
  BrandFormData,
  BrandSearchFilters,
  BrandSyncRootCategorySlugs,
  ESBrand,
  KTBrand,
  KTPageResult,
  ListResponse,
} from '../models';

type BrandListAxiosResponse = {
  brands: Brand[];
  page: KTPageResult;
};

type KTBrandListAxiosResponse = {
  brands: KTBrand[];
  page: KTPageResult;
};

/**
 * Build URL query parameter for brand list
 *
 * @param param Brand list search filter
 * @returns URL query string
 */
const getBrandListQueryParam = (param?: BrandSearchFilters): string => {
  const reqParam = getPagingParameter(param, true);

  if (param?.searchKeyword) {
    reqParam.q = param.searchKeyword;
  }

  return queryParamGenerator(reqParam, true);
};

/**
 * Get brand list
 *
 * @param param Brand list search filter
 * @returns Brand list request result as promise function
 */
export const getBrandMasterList = async (param?: BrandSearchFilters): Promise<ApiResponse<ListResponse<Brand>>> => {
  const qString = getBrandListQueryParam(param);
  const result = await apiKTRequest<BrandListAxiosResponse>(`/api/brand?${qString}`);

  return apiResponseTransformer<BrandListAxiosResponse, ListResponse<Brand>>(
    result,
    'master-index:error.list.requestBrandFailed',
    (data: BrandListAxiosResponse) => ({
      list: data?.brands || [],
      totalCount: data?.page.totalElements,
    }),
  );
};

/**
 * Get brand by UID
 *
 * @param uid Brand UID
 * @returns Brand item request result as promise function
 */
export const getBrandByUid = async (uid: string): Promise<ApiResponse<Brand>> => {
  const result = await apiKTRequest<Brand>(`/api/brand/${uid}`);

  return apiResponseTransformer(result, 'master-index:error.list.requestBrandFailed');
};

/**
 * Create new brand
 *
 * @param data Brand form data
 * @returns Brand create result as promise function
 */
export const createBrand = async (data: BrandFormData): Promise<ApiResponse<Brand>> => {
  const result = await apiKTRequest<Brand>(`/admin_manager/brand`, {
    method: 'POST',
    data,
  });

  return apiResponseTransformer(result, 'master-data:error.brand.createFailed');
};

/**
 * Update brand data
 *
 * @param id Brand ID (NOT UID)
 * @param data Brand form data
 * @returns Brand update result as promise function
 */
export const updateBrand = async (id: number, data: BrandFormData): Promise<ApiResponse<Brand>> => {
  const result = await apiKTRequest<Brand>(`/admin_manager/brand`, {
    method: 'PATCH',
    data: {
      ...data,
      id,
    },
  });

  return apiResponseTransformer(result, 'master-data:error.brand.updateFailed');
};

export const syncBrandWithRootCategory = async (
  brandSlug: string,
  data: BrandSyncRootCategorySlugs,
): Promise<ApiResponse<Brand>> => {
  const result = await apiKTRequest<Brand>(`/admin_manager/sync/brand/${brandSlug}/root-category`, {
    method: 'PATCH',
    data: {
      ...data,
    },
  });

  return apiResponseTransformer(result, 'master-data:error.brand.updateFailed');
};

/**
 * Sync elasticsearch brands
 *
 * @returns Update result as promise function
 */
export const syncElasticsearchBrandsData = async (): Promise<ApiResponse<boolean>> => {
  const result = await apiKTRequest<boolean>('/admin_manager/es/brand/sync', {
    method: 'GET',
  });

  return apiResponseTransformer(result, 'master-data:error.operations.dataSyncFailed');
};

/**
 * Sync elasticsearch brand
 *
 * @param id Brand ID (NOT UID)
 * @returns Brand update result as promise function
 */
export const syncElasticsearchBrandData = async (id: number): Promise<ApiResponse<ESBrand>> => {
  const result = await apiKTRequest<ESBrand>(`/admin_manager/es/brand/sync/${id}`, {
    method: 'GET',
  });

  return apiResponseTransformer(result, 'master-data:error.brand.updateFailed');
};

/**
 * Get KTBrand
 *
 * @param uid string
 * @returns KTBrand
 */
export const getKTBrandsByCategoryUID = async (uid: string): Promise<ApiResponse<ListResponse<KTBrand>>> => {
  const result = await apiKTRequest<KTBrandListAxiosResponse>(`/api/category/${uid}/brands?per_page=5000`);
  return apiResponseTransformer<KTBrandListAxiosResponse, ListResponse<KTBrand>>(
    result,
    'master-index:error.list.requestBrandFailed',
    (data: KTBrandListAxiosResponse) => ({
      list: data?.brands || [],
      totalCount: data?.page.totalElements,
    }),
  );
};

/**
 * Get KTBrand
 *
 * @param uid string
 * @returns KTBrand
 */
export const getKTBrandsByCategorySlug = async (slug: string): Promise<ApiResponse<ListResponse<KTBrand>>> => {
  const result = await apiKTRequest<KTBrandListAxiosResponse>(`/api/category/slug/${slug}/brands?per_page=5000`);
  return apiResponseTransformer<KTBrandListAxiosResponse, ListResponse<KTBrand>>(
    result,
    'master-index:error.list.requestBrandFailed',
    (data: KTBrandListAxiosResponse) => ({
      list: data?.brands || [],
      totalCount: data?.page.totalElements,
    }),
  );
};
