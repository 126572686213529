import { AppTableConditions } from './common';
import { UserRole } from './user.model';

export type FakhaListSearchFormDataTC = {
  searchField: string;
  searchKeyword: string;
  filteringType: string[];
  filteringRoleStateWorkflow: string[];
};

export type FakhaListSearchParamsTC = FakhaListSearchFormDataTC & AppTableConditions;

export type FakhaSearchFiltersTC = Partial<FakhaListSearchParamsTC> & {
  q?: string;
};

export enum FakhaType {
  truck = 'TRUCK',
  heavyMachinery = 'HEAVY_MACHINERY',
  agriculturalMachinery = 'AGRICULTURAL_MACHINERY',
  other = 'OTHER',
}

export interface FakhaData {
  hashId: string;
  email: string;
  mobilePhone: string;
  type: string;
  role: UserRole;
  url: string;
  remark: string;
  mode: string;
  userHashId: string;
  displayName: string;
  createdAt: number;
  updatedAt: number;
  crNote: string;
  updateNoteBy: string;
}

export interface GetFakhaResponse {
  contents: FakhaData[];
  page?: {
    size?: number;
    totalElements?: number;
    totalPages?: number;
    number?: number;
  };
}

export type FakhaDataRequest = {
  crNote: string;
};
