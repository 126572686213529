import React, { ChangeEvent, PropsWithChildren, ReactElement, useEffect, useState } from 'react';

import { Box, Card, Divider, IconButton, makeStyles, Switch, Typography, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import clsx from 'clsx';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import { Category, CategoryPath, FileItem, RefCategory, RefMasterIndex } from '../../../../../models';
import * as categoryService from '../../../../../services/category';
import useTypographyStyles from '../../../../../theme/typography.style';
import AppAutoCompleteMultiple from '../../../../ui/AppAutoCompleteMultiple';
import AppFormControl from '../../../../ui/AppFormControl';
import AppMediaSelector from '../../../../ui/AppMediaSelector';
import RefMasterIndexSelector from '../RefMasterIndexSelector';

export type PathEditorProps = {
  data: CategoryPath[];
  onChange?: (listData: CategoryPath[]) => void;
};

const useStyles = makeStyles((theme) => ({
  selectedTextBlock: {
    height: 44,
  },
  droppableArea: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexFlow: 'row wrap',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.grey[100],
    borderRadius: 4,
  },
  draggableItemContainer: {
    flexBasis: '100%',
    '&:focus-visible': {
      outline: 'none',
    },
    '&:not(:first-child)': {
      marginTop: theme.spacing(1),
    },
  },
  draggableCard: {
    width: '100%',
    height: 'auto',
    minHeight: 60,
    padding: theme.spacing(1, 2),
    '& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]':
      {
        paddingRight: theme.spacing(1),
      },
  },
  noStyleCard: {
    backgroundColor: theme.palette.grey[200],
    boxShadow: 'none',
    cursor: 'pointer',
  },
  editingCard: {
    paddingTop: 4,
    paddingBottom: 4,
  },
  draggingPlaceholder: {
    borderWidth: 2,
    borderStyle: 'dashed',
    borderColor: theme.palette.primary.main,
  },
  newItem: {
    paddingLeft: 3,
  },
  hideNewItem: {
    display: 'none',
  },
  autocompletePopper: {
    width: 230,
  },
  selectedItemText: {
    marginTop: 3,
  },
  itemSelectorAction: {
    width: 30,
    transform: 'translateY(4px)',
  },
}));

const reorder = (list: CategoryPath[], startIndex: number, endIndex: number) => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const RefCategorySelector = (selectorProps: {
  selectedCategories: RefCategory[] | undefined;
  onItemChange: (value: RefCategory[] | undefined) => void;
}) => {
  const classes = useStyles();
  const typoClasses = useTypographyStyles();
  const { t } = useTranslation();
  const { selectedCategories, onItemChange } = selectorProps;
  const [currentValues, setCurrentValues] = useState<RefCategory[]>(selectedCategories || []);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  useEffect(() => {
    setCurrentValues(selectedCategories || []);
  }, [selectedCategories]);

  const getCategory = async (value: string): Promise<Category[]> => {
    const result = await categoryService.getCategoryByName(value);
    return (result.data?.list || []).map((category) => {
      const { ...restCategoryFields } = category;

      return {
        ...restCategoryFields,
      };
    });
    /* eslint-enabled */
  };

  const onClose = () => {
    setIsEditing(false);
  };

  const itemChangeHandler = (value: RefCategory[] | undefined) => {
    const newValues = value || [];
    setCurrentValues(newValues);
    if (typeof onItemChange === 'function') {
      onItemChange(newValues);
    }
  };

  return (
    <>
      {isEditing && (
        <AppAutoCompleteMultiple<RefCategory>
          getData={getCategory}
          onValueChange={itemChangeHandler}
          initialValue={currentValues}
          onClose={onClose}
          autoCompleteProps={{
            ChipProps: {
              variant: 'outlined',
            },
            disableCloseOnSelect: true,
            popupIcon: null,
            getOptionLabel: (option) => (typeof option === 'string' ? option : option.thaiName),
            filterOptions: (option) =>
              option.filter((opt: RefCategory) => !currentValues.find((item: RefCategory) => item.id === opt.id)),
            renderOption: ({ thaiName }: RefCategory) => (
              <div>
                <Typography variant="body1">{thaiName}</Typography>
              </div>
            ),
            loadingText: t('common:message.searching').toString(),
            noOptionsText: t('common:message.dataNotFound').toString(),
          }}
          textfieldProps={{
            margin: 'dense',
            autoFocus: true,
          }}
        />
      )}
      {!isEditing && (
        <Box
          onClick={() => setIsEditing(true)}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-start"
          className={classes.selectedTextBlock}>
          {!!currentValues && currentValues.length > 0 && (
            <>
              <Typography component="div" noWrap className={classes.selectedItemText}>
                {t('master-data:common.label.selectedCategories', {
                  number: currentValues.length,
                })}
              </Typography>
            </>
          )}
          {(!currentValues || currentValues.length === 0) && (
            <Typography className={typoClasses.greyText} component="div" noWrap>
              {t('master-data:form.category.selectDependOnCategories')}
            </Typography>
          )}
        </Box>
      )}
    </>
  );
};

const RefTextEditor = (selectorProps: {
  selectedItem: string | undefined;
  placeholder: string | undefined;
  onItemChange: (value: string | undefined) => void;
}) => {
  const classes = useStyles();
  const typoClasses = useTypographyStyles();

  const { selectedItem, placeholder, onItemChange } = selectorProps;
  const [currentValues, setCurrentValues] = useState<string>(selectedItem || '');
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const onClose = () => {
    setIsEditing(false);
  };

  const itemChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const newValues = e.target.value;
    setCurrentValues(newValues);
    if (typeof onItemChange === 'function') {
      onItemChange(newValues);
    }
  };

  return (
    <>
      {isEditing && (
        <TextField
          id="outlined-basic"
          variant="outlined"
          onBlur={onClose}
          onChange={itemChangeHandler}
          autoFocus
          value={currentValues}
        />
      )}
      {!isEditing && (
        <Box
          onClick={() => setIsEditing(true)}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-start"
          className={classes.selectedTextBlock}>
          {!!currentValues && currentValues.length > 0 && (
            <>
              <Typography component="div" noWrap className={classes.selectedItemText}>
                {currentValues}
              </Typography>
            </>
          )}
          {(!currentValues || currentValues.length === 0) && (
            <Typography className={typoClasses.greyText} component="div" noWrap>
              {placeholder}
            </Typography>
          )}
        </Box>
      )}
    </>
  );
};

const RefImageSelector = (selectorProps: {
  selectedItem: string | undefined;
  onItemChange: (value: string | undefined) => void;
}) => {
  const { selectedItem, onItemChange } = selectorProps;
  const [currentValues, setCurrentValues] = useState<string>(selectedItem || '');

  const itemChangeHandler = (files: FileItem[]) => {
    if (files.length) {
      const fileURL = files[0].fullUrl;
      setCurrentValues(fileURL);
      if (typeof onItemChange === 'function') {
        onItemChange(fileURL);
      }
    }
  };

  const itemClearHandler = () => {
    setCurrentValues('');
    if (typeof onItemChange === 'function') {
      onItemChange('');
    }
  };

  return (
    <>
      <AppFormControl>
        <AppMediaSelector
          defaultValue={currentValues}
          mode="input"
          onFilesSelected={itemChangeHandler}
          onValueCleared={itemClearHandler}
        />
      </AppFormControl>
    </>
  );
};

const ItemSelector = (props: {
  defaultValue: Partial<CategoryPath> | undefined;
  onChange?: (categoryPath: CategoryPath | undefined) => void;
  newItem?: boolean;
  onAddClick?: (categoryPath: CategoryPath | undefined) => void;
  onDeleteClick?: () => void;
  withHeader?: boolean;
}) => {
  const { t } = useTranslation();
  const { defaultValue, onChange, newItem, onAddClick, onDeleteClick, withHeader } = props;
  const classes = useStyles();
  const typoClasses = useTypographyStyles();
  const [currentValue, setCurrentValue] = useState<Partial<CategoryPath> | undefined>(defaultValue);

  useEffect(() => {
    setCurrentValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange(currentValue as CategoryPath | undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue]);

  const addClickHandler = () => {
    if (typeof onAddClick === 'function' && currentValue?.masterIndex) {
      onAddClick(currentValue as CategoryPath | undefined);

      setCurrentValue({
        optional: false,
        masterIndex: undefined,
        placeholder: undefined,
        description: undefined,
        exampleUrl: undefined,
        dependOnCategories: undefined,
      });
    }
  };

  const deleteClickHandler = () => {
    if (typeof onDeleteClick === 'function') {
      onDeleteClick();
    }
  };

  return (
    <Box display="flex" flexDirection="row" flexWrap="nowrap" alignItems="flex-start">
      <Box flexGrow={1} flexBasis="17%" px={2}>
        {!!withHeader && (
          <Typography variant="caption" className={typoClasses.textWeightBold}>
            {t('master-data:form.category.pathMasterIndex')}
          </Typography>
        )}
        <RefMasterIndexSelector
          selectedMasterIndex={currentValue?.masterIndex || undefined}
          onItemChange={(value: RefMasterIndex | undefined) => {
            setCurrentValue({
              ...currentValue,
              masterIndex: value,
            });
          }}
        />
      </Box>
      <Box flexGrow={1} flexBasis="15%" px={2}>
        {!!withHeader && (
          <Typography variant="caption" className={typoClasses.textWeightBold}>
            {t('master-data:form.category.pathPlaceholder')}
          </Typography>
        )}
        <RefTextEditor
          selectedItem={currentValue?.placeholder || undefined}
          placeholder={t('master-data:form.category.fillPlaceholder')}
          onItemChange={(value: string | undefined) => {
            setCurrentValue({
              ...currentValue,
              placeholder: value,
            });
          }}
        />
      </Box>
      <Box flexGrow={1} flexBasis="18%" px={2}>
        {!!withHeader && (
          <Typography variant="caption" className={typoClasses.textWeightBold}>
            {t('master-data:form.category.pathDescription')}
          </Typography>
        )}
        <RefTextEditor
          selectedItem={currentValue?.description || undefined}
          placeholder={t('master-data:form.category.fillDescription')}
          onItemChange={(value: string | undefined) => {
            setCurrentValue({
              ...currentValue,
              description: value,
            });
          }}
        />
      </Box>
      <Box flexGrow={1} flexBasis="18%" px={2}>
        {!!withHeader && (
          <Typography variant="caption" className={typoClasses.textWeightBold}>
            {t('master-data:form.category.pathExampleUrl')}
          </Typography>
        )}
        <RefImageSelector
          selectedItem={currentValue?.exampleUrl || undefined}
          onItemChange={(value: string | undefined) => {
            setCurrentValue({
              ...currentValue,
              exampleUrl: value,
            });
          }}
        />
      </Box>

      <Box flexGrow={1} flexBasis="22%" px={2}>
        {!!withHeader && (
          <Typography variant="caption" className={typoClasses.textWeightBold}>
            {t('master-data:form.category.pathDependOnCategory')}
          </Typography>
        )}
        <RefCategorySelector
          selectedCategories={currentValue?.dependOnCategories || undefined}
          onItemChange={(value: RefCategory[] | undefined) => {
            setCurrentValue({
              ...currentValue,
              dependOnCategories: value,
            });
          }}
        />
      </Box>
      <Box flexBasis="10%">
        {!!withHeader && (
          <Typography variant="caption" className={typoClasses.textWeightBold}>
            {t('master-data:form.category.pathOptional')}
          </Typography>
        )}
        <Switch
          checked={Boolean(currentValue?.optional || false)}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setCurrentValue({
              ...currentValue,
              optional: e.target.checked,
            });
          }}
          color="primary"
          name="optional"
          inputProps={{ 'aria-label': 'item optional' }}
        />
      </Box>
      <Box flexBasis="10%">
        {!!withHeader && (
          <Typography variant="caption" className={typoClasses.textWeightBold}>
            {t('master-data:form.category.disabledFilter')}
          </Typography>
        )}
        <Switch
          checked={Boolean(currentValue?.disabledFilter || false)}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setCurrentValue({
              ...currentValue,
              disabledFilter: e.target.checked,
            });
          }}
          color="primary"
          name="disabledFilter"
          inputProps={{ 'aria-label': 'item disabledFilter' }}
        />
      </Box>
      <Box flexBasis="10%">
        {!!withHeader && (
          <Typography variant="caption" className={typoClasses.textWeightBold}>
            {t('master-data:form.category.isUseCountItems')}
          </Typography>
        )}
        <Switch
          checked={Boolean(currentValue?.isUseCountItems || false)}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setCurrentValue({
              ...currentValue,
              isUseCountItems: e.target.checked,
            });
          }}
          color="primary"
          name="isUseCountItems"
          inputProps={{ 'aria-label': 'item isUseCountItems' }}
        />
      </Box>
      <Box flexBasis="auto" className={classes.itemSelectorAction}>
        {!newItem && currentValue?.masterIndex?.id !== 1 && (
          <IconButton size="small" onClick={() => deleteClickHandler()}>
            <DeleteIcon color="error" />
          </IconButton>
        )}
        {newItem && (
          <IconButton size="small" onClick={() => addClickHandler()}>
            <AddIcon color="primary" />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

const PathEditor = (props: PropsWithChildren<PathEditorProps>): ReactElement => {
  const classes = useStyles();
  const { data, onChange } = props;
  const [listData, setListData] = useState<CategoryPath[]>(data);

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange(listData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listData]);

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(listData, result.source.index, result.destination.index);

    setListData(items);
  };

  const onItemSelectionChange = (item: CategoryPath, index: number) => {
    if (item.dependOnCategories) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      item.dependOnCategories.forEach((dependOnItem: any) => {
        /* eslint-disable no-underscore-dangle, no-underscore-dangle */
        if (dependOnItem._link) {
          dependOnItem._link = undefined;
        }
        /* eslint-disable no-underscore-dangle, no-underscore-dangle */
      });
    }
    listData[index] = item;
    setListData([...listData]);
  };

  const addNewItem = (item: CategoryPath | undefined) => {
    if (item) {
      listData.push(item);
      setListData([...listData]);
    }
  };

  const deleteItem = (index: number) => {
    listData.splice(index, 1);
    setListData([...listData]);
  };

  return (
    <>
      <Box>
        <ItemSelector defaultValue={undefined} newItem onAddClick={addNewItem} withHeader />
      </Box>
      <Box mt={1} mb={2}>
        <Divider />
      </Box>
      {listData.length > 0 && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className={clsx(classes.droppableArea, {
                  [classes.draggingPlaceholder]: snapshot.isDraggingOver,
                })}>
                {listData.map((item: CategoryPath, index: number) => {
                  const itemKey = `item-${index}`;
                  return (
                    <Draggable key={itemKey} draggableId={index.toString()} index={index}>
                      {(providedItem) => (
                        <div
                          ref={providedItem.innerRef}
                          {...providedItem.draggableProps}
                          {...providedItem.dragHandleProps}
                          className={classes.draggableItemContainer}>
                          <Card className={classes.draggableCard}>
                            <ItemSelector
                              defaultValue={item}
                              onChange={(categoryPath: CategoryPath | undefined) => {
                                onItemSelectionChange(categoryPath || item, index);
                              }}
                              onDeleteClick={() => {
                                deleteItem(index);
                              }}
                            />
                          </Card>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </>
  );
};

export default PathEditor;
