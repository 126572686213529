import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';

import UserGeneralWorkflowDialog from '../UserGeneralWorkflow/UserGeneralWorkflowDialog';

type AccountActionsProps = {
  mode: 'ban' | 'unban' | 'blacklist' | 'remove-blacklist' | 'deactivate' | 'activate' | null;
  onConfirm: (
    mode: 'ban' | 'unban' | 'blacklist' | 'remove-blacklist' | 'deactivate' | 'activate',
    comment: string,
  ) => void;
  onCancel: () => void;
};

const AccountActions: FC<AccountActionsProps> = ({ mode, onConfirm, onCancel }: AccountActionsProps) => {
  const { t } = useTranslation();

  if (!mode) {
    return null;
  }

  return (
    <>
      {mode === 'ban' && (
        <UserGeneralWorkflowDialog
          title={t('user:details.label.accBanDialogTitle')}
          description={t('user:details.label.accBanDialogDesc')}
          type="isBan"
          isBlockMode
          onOkClicked={(comment) => onConfirm(mode, comment)}
          onClose={onCancel}
        />
      )}

      {mode === 'blacklist' && (
        <UserGeneralWorkflowDialog
          title={t('user:details.label.accBlacklistDialogTitle')}
          description={t('user:details.label.accBlacklistDialogDesc')}
          type="isBlacklist"
          isBlockMode
          onOkClicked={(comment) => onConfirm(mode, comment)}
          onClose={onCancel}
        />
      )}

      {mode === 'deactivate' && (
        <UserGeneralWorkflowDialog
          title={t('user:details.label.accDeactivationDialogTitle')}
          description={t('user:details.label.accDeactivationDialogDesc')}
          type="isInactive"
          onOkClicked={(comment) => onConfirm(mode, comment)}
          onClose={onCancel}
        />
      )}

      {mode === 'unban' && (
        <UserGeneralWorkflowDialog
          title={t('user:details.label.accUnBanDialogTitle')}
          description={t('user:details.label.accUnBanDialogDesc')}
          type="isUnBan"
          onOkClicked={(comment) => onConfirm(mode, comment)}
          onClose={onCancel}
        />
      )}

      {mode === 'remove-blacklist' && (
        <UserGeneralWorkflowDialog
          title={t('user:details.label.accUnBlacklistDialogTitle')}
          description={t('user:details.label.accUnBlacklistDialogDesc')}
          type="isUnBlacklist"
          onOkClicked={(comment) => onConfirm(mode, comment)}
          onClose={onCancel}
        />
      )}

      {mode === 'activate' && (
        <UserGeneralWorkflowDialog
          title={t('user:details.label.accActivationDialogTitle')}
          description={t('user:details.label.accActiveDialogDesc')}
          type="isActive"
          onOkClicked={(comment) => onConfirm(mode, comment)}
          onClose={onCancel}
        />
      )}
    </>
  );
};

export default AccountActions;
