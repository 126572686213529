import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';

import CoinTransactionDialog from './CoinTransactionDialog';
import MembershipTransactionDialog from './MembershipTransactionDialog';
import { UserDataTC } from '../../../../../models';
import UserPaymentHistoryDialog from '../../../common/UserPaymentHistoryDialog';
import UserNotificationDialog from '../../../common/v3/UserNotificationDialog';

export interface UserDialogListProps {
  currentUser: UserDataTC;
  mode: 'notification' | 'coin-usage' | 'membership' | 'membership-v2' | null;
  onClose: () => void;
}

const UserDialogList: FC<UserDialogListProps> = (props) => {
  const { t } = useTranslation();
  const { currentUser, mode, onClose } = props;

  return (
    <>
      <UserNotificationDialog
        title={t('user:details.label.userNotification')}
        open={mode === 'notification'}
        userHashId={currentUser.userHashId}
        isLoadingData
        onClose={onClose}
      />
      <CoinTransactionDialog
        title={t('user:details.label.coinUsedTransaction')}
        open={mode === 'coin-usage'}
        userHashId={currentUser.userHashId}
        isLoadingData
        onClose={onClose}
      />
      <MembershipTransactionDialog
        title={t('user:details.label.membershipTransaction')}
        open={mode === 'membership'}
        userHashId={currentUser.userHashId}
        isLoadingData
        onClose={onClose}
      />
      <UserPaymentHistoryDialog
        title={t('user:details.label.paymentHistory')}
        open={mode === 'membership-v2'}
        userHashId={currentUser.userHashId}
        isLoadingData
        onClose={onClose}
      />
    </>
  );
};

export default UserDialogList;
