import { makeStyles } from '@material-ui/core';

const useAppContainerStyles = makeStyles(() => ({
  container: {
    maxWidth: '1500px',
  },
  smallContainer: {
    maxWidth: 1000,
  },
}));

export default useAppContainerStyles;
