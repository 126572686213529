import React, { ChangeEvent, ReactElement, useEffect, useMemo, useState } from 'react';

import { CircularProgress, makeStyles, TextField, TextFieldProps } from '@material-ui/core';
import Autocomplete, { AutocompleteProps, AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete';
import clsx from 'clsx';
import { debounce } from 'lodash';

export type AppAutoCompleteProps<T> = {
  getData: (value: string) => Promise<T[]>;
  changeDebounceDuration?: number;
  autoCompleteProps?: Omit<AutocompleteProps<T, false, false, true>, 'options' | 'renderInput'>;
  initialValue?: T | undefined;
  initialInputValue?: string;
  onValueChange?: (option: T | undefined) => void;
  onClose?: () => void;
  textfieldProps?: TextFieldProps;
  minLength?: number;
  isDisabled?: boolean;
};

const useStyles = makeStyles((theme) => ({
  autoCompleteInputRoot: {
    '&[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"]': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  autoCompleteInputRootMarginNormal: {
    '&[class*="MuiOutlinedInput-root"]': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  loadingText: {
    fontSize: theme.typography.subtitle2.fontSize,
  },
  noOptionsText: {
    fontSize: theme.typography.subtitle2.fontSize,
  },
}));

const AppAutoComplete = <T,>(props: AppAutoCompleteProps<T>): ReactElement => {
  const classes = useStyles();
  const {
    getData,
    changeDebounceDuration = 500,
    autoCompleteProps,
    initialValue,
    initialInputValue,
    onValueChange: onSelectedValueChange,
    onClose,
    textfieldProps,
    minLength = 3,
    isDisabled = false,
  } = props;
  const [currentOptions, setCurrentOptions] = useState<T[]>([]);
  const [selectedValue, setSelectedValue] = useState<T | undefined>(initialValue);
  const [inputValue, setInputValue] = useState<string>(initialInputValue || '');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = useMemo(
    () =>
      debounce((input: string, callback: (results: T[]) => void) => {
        (async () => {
          setIsLoading(true);
          const result = await getData(input);

          setIsLoading(false);
          callback(result);
        })();
      }, changeDebounceDuration),
    [changeDebounceDuration, getData],
  );

  const setValue = (value: T | string | null | undefined) => {
    setCurrentOptions(value ? [value as T] : currentOptions);
    setSelectedValue(value as T | undefined);
  };

  const refreshOptions = (): void => {
    if (inputValue === '' || inputValue.length < minLength) {
      setCurrentOptions(selectedValue ? [selectedValue] : []);
      return;
    }

    fetchData(inputValue, (results: T[]) => {
      setCurrentOptions([...results]);
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useEffect((): any => {
    let isActive = true;

    if (isActive) {
      refreshOptions();
    }

    return () => {
      isActive = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  useEffect(() => {
    setValue(initialValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onAutocompleteInputChange = (_e: ChangeEvent<any>, value: string) => {
    setInputValue(value);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onValueChange = (e: ChangeEvent<any>, value: T | string | null) => {
    setValue(value);
    setCurrentOptions(value ? [value as T] : currentOptions);
    if (typeof onSelectedValueChange === 'function') {
      onSelectedValueChange(value as T | undefined);
    }
  };

  const onAutocompleteBlur = () => {
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  const onAutocompleteFocus = () => {
    refreshOptions();
  };

  return (
    <>
      <Autocomplete
        {...autoCompleteProps}
        classes={{
          inputRoot: clsx(classes.autoCompleteInputRoot, {
            [classes.autoCompleteInputRootMarginNormal]: !textfieldProps?.margin || textfieldProps?.margin === 'normal',
          }),
          noOptions: classes.noOptionsText,
          loading: classes.loadingText,
        }}
        options={currentOptions}
        autoComplete
        includeInputInList
        value={selectedValue}
        onChange={onValueChange}
        onInputChange={onAutocompleteInputChange}
        onFocus={onAutocompleteFocus}
        onBlur={onAutocompleteBlur}
        disabled={isDisabled}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            {...params}
            variant="outlined"
            fullWidth
            InputProps={{
              ...params.InputProps,
              endAdornment: <>{isLoading ? <CircularProgress color="inherit" size={20} /> : null}</>,
            }}
            {...textfieldProps}
            disabled={isDisabled}
          />
        )}
      />
    </>
  );
};

export default AppAutoComplete;
