import { AppTableConditions } from './common';

export enum CustomerType {
  CustType1 = 'individual',
  CustType2 = 'company',
  CustType3 = 'dealer',
  CustType4 = 'owner',
  CustType5 = 'finance',
}

export type CustomerContactExclusiveProps = {
  searchSynonym?: string;
};

export type CustomerContactListSearchFormData = {
  searchKeyword: string;
  searchField: string;
};

export type CustomerContactFormData = {
  fullName: string;
  mobileNo: string;
  email: string;
  lineId: string;
  address: string;
  province: string;
  customerType: string;
  userHashId: string;
  remark: string;
  profileImageUrl?: string;
};

export type CustomerContact = {
  id?: number;
  createdAt: string;
  updatedAt: string;
} & CustomerContactFormData;

export type CustomerContactListSearchParams = CustomerContactListSearchFormData & AppTableConditions;

export type CustomerContactListSearchFilters = Partial<CustomerContactListSearchParams>;
