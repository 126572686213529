import React, { ChangeEvent, FC, useContext, useEffect, useState } from 'react';

import { makeStyles, Paper, Tab, Tabs } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import AppCircularProgress from '../../components/partials/AppCircularProgress';
import UserDetail from '../../components/partials/users/UserDetail';
import AppTabPanel from '../../components/ui/AppTabPanel';
import { AppGlobalUiContext } from '../../context/AppGlobalUiContext';
import { SellerApplication, TabItem, User } from '../../models';
import { getUserByHashId } from '../../services';
import * as sellerAppService from '../../services/seller-applications';

type UserEditPageProps = unknown;

const tabIdPrefix = 'user-tabs-';
const tabPanelPrefix = 'user-tabpanel-';
const tabList: TabItem[] = [
  {
    id: 'info',
    label: 'user:details.tabs.info',
    value: 'info',
  },
];

const useStyles = makeStyles(() => ({
  tabBar: {
    position: 'relative',
    zIndex: 2,
  },
  tabPanel: {
    position: 'relative',
    zIndex: 1,
  },
}));

const UserEdit: FC<UserEditPageProps> = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { setAppBarTitle } = useContext(AppGlobalUiContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [user, setUser] = useState<User | undefined>(undefined);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [latestSellerApp, setLatestSellerApp] = useState<SellerApplication | undefined>(undefined);
  const { hashId } = useParams<{ hashId: string }>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useEffect((): any => {
    let isSubscribed = true;

    if (hashId) {
      getUserByHashId(hashId)
        .then((result) => {
          if (!isSubscribed) {
            return null;
          }

          if (result) {
            setUser(result);
            setAppBarTitle(result.username);

            (async () => {
              const latestSellerAppList = await sellerAppService.getSellerApplicationList({
                searchField: 'userHashId',
                searchKeyword: hashId,
                page: 0,
                rowsPerPage: 1,
                sortColumn: 'createdAt',
                sortDirection: 'desc',
              });

              if (latestSellerAppList.data?.sellerApplications.length === 1) {
                setLatestSellerApp(latestSellerAppList.data?.sellerApplications[0]);
              }
            })();
          }

          return true;
        })
        .finally(() => (isSubscribed ? setIsLoading(false) : null));
    }

    return () => (isSubscribed = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const tabChangeHandler = (e: ChangeEvent<any>, value: any) => {
    setSelectedTab(value);
  };

  return (
    <>
      {!!isLoading && <AppCircularProgress />}
      {!isLoading && !!user && (
        <>
          <Paper square className={classes.tabBar}>
            <Tabs
              value={selectedTab}
              onChange={tabChangeHandler}
              aria-label="User detail tabs"
              textColor="primary"
              indicatorColor="primary">
              {tabList.map((tab: TabItem) => (
                <Tab
                  key={tab.id}
                  label={t(tab.label).toString()}
                  id={`${tabIdPrefix}${tab.id}`}
                  aria-controls={`${tabPanelPrefix}${tab.id}`}
                  disabled={tab.disabled || false}
                />
              ))}
            </Tabs>
          </Paper>
          <AppTabPanel
            value={selectedTab}
            index={0}
            tabItem={tabList[0]}
            tabIdPrefix={tabIdPrefix}
            tabPanelPrefix={tabPanelPrefix}
            className={classes.tabPanel}>
            <UserDetail user={user} latestSellerApplication={latestSellerApp} />
          </AppTabPanel>
          {/* TODO: Will bring them back when the implementation is ready */}
          {/* <AppTabPanel
            value={selectedTab}
            index={1}
            tabItem={tabList[1]}
            tabIdPrefix={tabIdPrefix}
            tabPanelPrefix={tabPanelPrefix}
            className={classes.tabPanel}>
          </AppTabPanel>
          <AppTabPanel
            value={selectedTab}
            index={2}
            tabItem={tabList[2]}
            tabIdPrefix={tabIdPrefix}
            tabPanelPrefix={tabPanelPrefix}
            className={classes.tabPanel}>
          </AppTabPanel>
          <AppTabPanel
            value={selectedTab}
            index={3}
            tabItem={tabList[3]}
            tabIdPrefix={tabIdPrefix}
            tabPanelPrefix={tabPanelPrefix}
            className={classes.tabPanel}>
          </AppTabPanel> */}
        </>
      )}
    </>
  );
};

export default UserEdit;
