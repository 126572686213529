import SettingsList from './List';
import WebConfigSettings from './WebConfigSettings';
import WebConfigEditPage from './WebConfigSettings/Edit';
import { RouteConfig } from '../../models/route.model';

const settingsRoutes = (path: string): RouteConfig[] => [
  {
    key: 'SETTINGS_LIST',
    path,
    component: SettingsList,
    exact: true,
    title: 'common:title.settings',
  },
  {
    key: 'SETTINGS_WEB_CONFIG_LIST',
    path: `${path}/web-config`,
    exact: true,
    component: WebConfigSettings,
  },
  {
    key: 'SETTINGS_WEB_CONFIG_EDIT',
    path: `${path}/web-config/:title`,
    exact: true,
    component: WebConfigEditPage,
  },
];

export default settingsRoutes;
