import React, { FC, ReactNode } from 'react';

import { Box, Divider, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { Control, Controller, DeepMap, FieldError, UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { validateNonZeroLeadNumber, validateSlug } from '../../../helpers/validations';
import { FileItem, KTMasterIndexAttributeDataType } from '../../../models';
import useTypographyStyles from '../../../theme/typography.style';
import AppFormControl from '../../ui/AppFormControl';
import AppFormErrorMessage from '../../ui/AppFormErrorMessage';
import AppMediaSelector from '../../ui/AppMediaSelector';

export type MasterDataCommonFieldsProps = {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  control: Control<any>;
  errors: DeepMap<any, FieldError>;
  /* eslint-enable @typescript-eslint/no-explicit-any */
  hasSearchSynonym?: boolean;
  hasCanonicalUrlMeta?: boolean;
  hasBlogSlug?: boolean;
  hasBanner?: boolean;
  hasMetaInfo?: boolean;
  rules?: { [x: string]: UseControllerProps['rules'] };
  additionalGeneralContent?: ReactNode;
};

const MasterDataCommonFields: FC<MasterDataCommonFieldsProps> = (props) => {
  const {
    control,
    errors,
    hasSearchSynonym,
    hasCanonicalUrlMeta,
    hasBlogSlug,
    hasBanner,
    rules,
    additionalGeneralContent = null,
    hasMetaInfo = true,
  } = props;
  const { t } = useTranslation();
  const typoClasses = useTypographyStyles();
  const getRules = (fieldName: string): UseControllerProps['rules'] | undefined =>
    rules ? rules[fieldName] : undefined;

  const AttributeDataTypeList: { index: number; value: KTMasterIndexAttributeDataType }[] = [];
  let i = 0;
  /* eslint-disable-next-line no-restricted-syntax */
  for (const item of Object.values(KTMasterIndexAttributeDataType)) {
    AttributeDataTypeList.push({ index: i, value: item });
    i += 1;
  }

  return (
    <>
      <Box mb={2}>
        <Typography className={typoClasses.textWeightBold} variant="h6">
          {t('master-data:common.form.generalSectionTitle')}
        </Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={4}>
          <Controller
            name="uid"
            control={control}
            rules={{ required: true, validate: validateNonZeroLeadNumber }}
            render={({ field }) => (
              <AppFormControl error={!!errors.uid}>
                <TextField
                  {...field}
                  variant="outlined"
                  label={t('master-data:common.field.uid').toString()}
                  error={!!errors.uid}
                  autoFocus
                />
                {!!errors.uid && <AppFormErrorMessage errors={errors} name="uid" />}
              </AppFormControl>
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <AppFormControl error={!!errors.name}>
                <TextField
                  {...field}
                  variant="outlined"
                  label={t('master-data:common.field.name').toString()}
                  error={!!errors.name}
                />
                {!!errors.name && <AppFormErrorMessage errors={errors} name="name" />}
              </AppFormControl>
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Controller
            name="thaiName"
            control={control}
            rules={getRules('thaiName')}
            render={({ field }) => (
              <AppFormControl error={!!errors.thaiName}>
                <TextField
                  {...field}
                  variant="outlined"
                  label={t('master-data:common.field.thaiName').toString()}
                  error={!!errors.thaiName}
                />
                {!!errors.thaiName && <AppFormErrorMessage errors={errors} name="thaiName" />}
              </AppFormControl>
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Controller
            name="slug"
            control={control}
            rules={{
              ...(getRules('slug') || {}),
              validate: validateSlug,
            }}
            render={({ field }) => (
              <AppFormControl error={!!errors.slug}>
                <TextField
                  {...field}
                  variant="outlined"
                  label={t('master-data:common.field.slug').toString()}
                  error={!!errors.slug}
                />
                {!!errors.slug && <AppFormErrorMessage errors={errors} name="slug" />}
              </AppFormControl>
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Controller
            name="imageUrl"
            control={control}
            rules={{
              ...(getRules('imageUrl') || {}),
            }}
            render={({ field }) => (
              <AppFormControl error={!!errors.imageUrl}>
                <AppMediaSelector
                  defaultValue={field.value}
                  mode="input"
                  inputProps={{
                    label: t('master-data:common.field.imageUrl').toString(),
                    error: !!errors.imageUrl,
                  }}
                  onFilesSelected={(files: FileItem[]) => {
                    if (files.length) {
                      field.onChange(files[0].fullUrl);
                    }
                  }}
                  onValueCleared={() => field.onChange('')}
                />
                {!!errors.imageUrl && <AppFormErrorMessage errors={errors} name="imageUrl" />}
              </AppFormControl>
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Controller
            name="placeholder"
            control={control}
            rules={getRules('placeholder')}
            render={({ field }) => (
              <AppFormControl error={!!errors.placeholder}>
                <TextField
                  {...field}
                  variant="outlined"
                  label={t('master-data:common.field.placeholder').toString()}
                  error={!!errors.placeholder}
                />
                {!!errors.placeholder && <AppFormErrorMessage errors={errors} name="placeholder" />}
              </AppFormControl>
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="description"
            control={control}
            rules={getRules('description')}
            render={({ field }) => (
              <AppFormControl error={!!errors.description}>
                <TextField
                  {...field}
                  variant="outlined"
                  label={t('master-data:common.field.description').toString()}
                  multiline
                  rowsMax={3}
                  rows={3}
                  error={!!errors.description}
                />
                {!!errors.description && <AppFormErrorMessage errors={errors} name="description" />}
              </AppFormControl>
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="attributeDataType"
            control={control}
            rules={getRules('attributeDataType')}
            render={({ field }) => (
              <AppFormControl error={!!errors.description}>
                <InputLabel htmlFor="age-simple">{t('master-data:common.field.attributeDataType')}</InputLabel>
                <Select {...field} variant="outlined" error={!!errors.description}>
                  {AttributeDataTypeList.map((item) => (
                    <MenuItem value={item.value} key={item.index}>
                      {t(item.value)}
                    </MenuItem>
                  ))}
                </Select>
                {!!errors.attributeDataType && <AppFormErrorMessage errors={errors} name="attributeDataType" />}
              </AppFormControl>
            )}
          />
          {hasBlogSlug && (
            <Controller
              name="blogSlug"
              control={control}
              rules={getRules('blogSlug')}
              render={({ field }) => (
                <AppFormControl error={!!errors.blogSlug} boxProps={{ mt: 3, mb: 3 }}>
                  <TextField
                    {...field}
                    variant="outlined"
                    label={t('master-data:common.field.blogSlug').toString()}
                    error={!!errors.blogSlug}
                  />
                  {!!errors.blogSlug && <AppFormErrorMessage errors={errors} name="blogSlug" />}
                </AppFormControl>
              )}
            />
          )}
        </Grid>
        {additionalGeneralContent}
      </Grid>
      {hasBanner && (
        <>
          <Box pt={3}>
            <Divider />
          </Box>
          <Box my={2}>
            <Typography className={typoClasses.textWeightBold} variant="h6">
              Banner Mobile
            </Typography>
            <Typography>686 x 240 px</Typography>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={6} md={4}>
              <Controller
                name="bannerMobileImageUrl"
                control={control}
                render={({ field }) => (
                  <AppFormControl>
                    <AppMediaSelector
                      defaultValue={field.value}
                      mode="input"
                      inputProps={{
                        label: 'Image Url',
                      }}
                      onFilesSelected={(files: FileItem[]) => {
                        if (files.length) {
                          field.onChange(files[0].fullUrl);
                        }
                      }}
                      onValueCleared={() => field.onChange('')}
                    />
                  </AppFormControl>
                )}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Controller
                name="bannerMobileTitle"
                control={control}
                render={({ field }) => (
                  <AppFormControl boxProps={{ mb: 3 }}>
                    <TextField {...field} variant="outlined" label="Title" />
                  </AppFormControl>
                )}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Controller
                name="bannerMobileLink"
                control={control}
                render={({ field }) => (
                  <AppFormControl boxProps={{ mb: 3 }}>
                    <TextField {...field} variant="outlined" label="Link" />
                  </AppFormControl>
                )}
              />
            </Grid>
          </Grid>
          <Box my={2}>
            <Typography className={typoClasses.textWeightBold} variant="h6">
              Banner Desktop
            </Typography>
            <Typography>2220 x 320 px</Typography>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={6} md={4}>
              <Controller
                name="bannerDesktopImageUrl"
                control={control}
                render={({ field }) => (
                  <AppFormControl>
                    <AppMediaSelector
                      defaultValue={field.value}
                      mode="input"
                      inputProps={{
                        label: 'Image Url',
                      }}
                      onFilesSelected={(files: FileItem[]) => {
                        if (files.length) {
                          field.onChange(files[0].fullUrl);
                        }
                      }}
                      onValueCleared={() => field.onChange('')}
                    />
                  </AppFormControl>
                )}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Controller
                name="bannerDesktopTitle"
                control={control}
                render={({ field }) => (
                  <AppFormControl boxProps={{ mb: 3 }}>
                    <TextField {...field} variant="outlined" label="Title" />
                  </AppFormControl>
                )}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Controller
                name="bannerDesktopLink"
                control={control}
                render={({ field }) => (
                  <AppFormControl boxProps={{ mb: 3 }}>
                    <TextField {...field} variant="outlined" label="Link" />
                  </AppFormControl>
                )}
              />
            </Grid>
          </Grid>
        </>
      )}
      {(hasMetaInfo || hasSearchSynonym || hasCanonicalUrlMeta) && (
        <>
          <Box pt={3}>
            <Divider />
          </Box>
          <Box my={2}>
            <Typography className={typoClasses.textWeightBold} variant="h6">
              {t('master-data:common.form.metaSectionTitle')}
            </Typography>
          </Box>
        </>
      )}
      <Grid container spacing={3}>
        {hasMetaInfo && (
          <>
            <Grid item xs={12} md={6}>
              <Controller
                name="titleMeta"
                control={control}
                rules={getRules('titleMeta')}
                render={({ field }) => (
                  <AppFormControl error={!!errors.titleMeta} boxProps={{ mb: 3 }}>
                    <TextField
                      {...field}
                      variant="outlined"
                      label={t('master-data:common.field.titleMeta').toString()}
                      error={!!errors.titleMeta}
                    />
                    {!!errors.titleMeta && <AppFormErrorMessage errors={errors} name="titleMeta" />}
                  </AppFormControl>
                )}
              />
              <Controller
                name="imageUrlMeta"
                control={control}
                rules={getRules('imageUrlMeta')}
                render={({ field }) => (
                  <AppFormControl error={!!errors.imageUrlMeta} boxProps={{ mb: 3 }}>
                    <AppMediaSelector
                      defaultValue={field.value}
                      mode="input"
                      inputProps={{
                        label: t('master-data:common.field.imageUrlMeta').toString(),
                        error: !!errors.imageUrl,
                      }}
                      onFilesSelected={(files: FileItem[]) => {
                        if (files.length) {
                          field.onChange(files[0].fullUrl);
                        }
                      }}
                      onValueCleared={() => field.onChange('')}
                    />
                    {!!errors.imageUrlMeta && <AppFormErrorMessage errors={errors} name="imageUrlMeta" />}
                  </AppFormControl>
                )}
              />
              <Controller
                name="descriptionMeta"
                control={control}
                rules={getRules('descriptionMeta')}
                render={({ field }) => (
                  <AppFormControl error={!!errors.descriptionMeta}>
                    <TextField
                      {...field}
                      variant="outlined"
                      label={t('master-data:common.field.descriptionMeta').toString()}
                      multiline
                      rowsMax={3}
                      rows={3}
                      error={!!errors.descriptionMeta}
                    />
                    {!!errors.descriptionMeta && <AppFormErrorMessage errors={errors} name="descriptionMeta" />}
                  </AppFormControl>
                )}
              />
            </Grid>
          </>
        )}
        {(hasSearchSynonym || hasCanonicalUrlMeta) && (
          <Grid item xs={12} md={6}>
            {hasSearchSynonym && (
              <Controller
                name="searchSynonym"
                control={control}
                rules={getRules('searchSynonym')}
                render={({ field }) => (
                  <AppFormControl error={!!errors.searchSynonym} boxProps={{ mb: 3 }}>
                    <TextField
                      {...field}
                      variant="outlined"
                      label={t('master-data:common.field.searchSynonym').toString()}
                      error={!!errors.searchSynonym}
                    />
                    {!!errors.searchSynonym && <AppFormErrorMessage errors={errors} name="searchSynonym" />}
                  </AppFormControl>
                )}
              />
            )}
            {hasCanonicalUrlMeta && (
              <Controller
                name="canonicalUrlMeta"
                control={control}
                rules={getRules('canonicalUrlMeta')}
                render={({ field }) => (
                  <AppFormControl error={!!errors.canonicalUrlMeta} boxProps={{ mb: 3 }}>
                    <TextField
                      {...field}
                      variant="outlined"
                      label={t('master-data:common.field.canonicalUrlMeta').toString()}
                      error={!!errors.canonicalUrlMeta}
                    />
                    {!!errors.canonicalUrlMeta && <AppFormErrorMessage errors={errors} name="canonicalUrlMeta" />}
                  </AppFormControl>
                )}
              />
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default MasterDataCommonFields;
