import { apiKTRequest } from '../helpers/request';
import { apiResponseTransformer } from '../helpers/response';
import { getPagingParameter, queryParamGenerator } from '../helpers/utils';
import { ApiResponse, KTPageResult, ListResponse, MenuListSearchFilters, T2HMenu, T2HMenuFormData } from '../models';

type MenuListAxiosResponse = {
  menus: T2HMenu[];
  page: KTPageResult;
};

/**
 * Get search menu query parameter from given filters
 *
 * @param param Menu search filter
 * @returns URL query parameter string
 */
const getMenuListQueryParam = (param?: MenuListSearchFilters): string => {
  const reqParam = getPagingParameter(param, true);

  // Only search with keyword when it comes with the selected column to be searched
  if (param?.searchKeyword) {
    reqParam.q = param.searchKeyword;
  }

  return queryParamGenerator(reqParam, true);
};

/**
 * Get category list from search filter
 *
 * @param param Category search filter
 * @returns Search request result as promise function
 */
// eslint-disable-next-line import/prefer-default-export
export const getMenuList = async (param?: MenuListSearchFilters): Promise<ApiResponse<ListResponse<T2HMenu>>> => {
  const qString = getMenuListQueryParam(param);
  const result = await apiKTRequest<MenuListAxiosResponse>(`/api/menu?${qString}`);

  return apiResponseTransformer<MenuListAxiosResponse, ListResponse<T2HMenu>>(
    result,
    'master-data:error.category.requestCategoryFailed',
    (data: MenuListAxiosResponse) => ({
      list: data.menus,
      totalCount: data.page.totalElements,
    }),
  );
};

/**
 * Get menu from given UID
 *
 * @param uid Menu UID
 * @returns Menu item search result from given uid
 */
export const getMenuByUid = async (uid: number | string): Promise<ApiResponse<T2HMenu>> => {
  const result = await apiKTRequest<T2HMenu>(`/api/menu/${uid}`);

  return apiResponseTransformer(result, 'master-data:error.category.requestCategoryFailed');
};

/**
 * Create new menu setting
 *
 * @param data Menu settings form data
 * @returns Menu settings create result as promise function
 */
export const createMenu = async (data: T2HMenuFormData): Promise<ApiResponse<T2HMenu>> => {
  const result = await apiKTRequest<T2HMenu>(`/admin_manager/menu`, {
    method: 'POST',
    data,
  });

  return apiResponseTransformer(result, 'menu:error.createFailed');
};

/**
 * Update current menu settings
 *
 * @param id Menu settings ID
 * @param data Menu settings form data
 * @returns Menu settings update result as promise function
 */
export const updateMenu = async (id: number, data: T2HMenuFormData): Promise<ApiResponse<T2HMenu>> => {
  const result = await apiKTRequest<T2HMenu>(`/admin_manager/menu`, {
    method: 'PATCH',
    data: {
      ...data,
      id,
    },
  });

  return apiResponseTransformer(result, 'menu:error.updateFailed');
};
