import React, { FC, ReactNode, forwardRef, memo, useContext } from 'react';

import { Box, BoxProps, Tooltip, Typography, makeStyles } from '@material-ui/core';
import { grey, indigo } from '@material-ui/core/colors';
import LaunchRoundedIcon from '@material-ui/icons/LaunchRounded';
import clsx from 'clsx';
import copy from 'copy-to-clipboard';
import { TFunction } from 'react-i18next';

import { AppGlobalUiContext } from '../../../../../../context/AppGlobalUiContext';

export type DataDisplayBlockProps = BoxProps & {
  allowCopy?: boolean;
  copySuccessText?: string;
  newTabLink?: string;
  newTabTooltipText?: string;
  label?: string | ReturnType<TFunction>;
  trailingContent?: ReactNode;
};

type BlockContentProps = {
  children: ReactNode;
  label?: string | ReturnType<TFunction>;
  clickable?: boolean;
  onClick?: () => void;
};

const useStyles = makeStyles(() => ({
  shortInfoContainer: {
    display: 'inline-flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: '16px',
    wordBreak: 'break-all',
    '&.clickable:hover': {
      cursor: 'pointer',
      color: indigo[500],
      '&:before': {
        color: grey[700],
      },
    },
    '&.full-block': {
      display: 'flex',
    },
  },
  commonInfo: {
    fontWeight: 600,
    '&:before': {
      content: 'attr(data-title)',
      fontWeight: 400,
      fontSize: '14px',
      color: grey[700],
      display: 'block',
    },
    '&.clickable:hover': {
      cursor: 'pointer',
      color: indigo[500],
      '&:before': {
        color: grey[700],
      },
    },
    '& ~ .open-link': {
      display: 'flex',
      marginLeft: '6px',
      alignSelf: 'flex-end',
    },
    '&.text-lg': {
      fontSize: '18px',
    },
    '& .leading-icon': {
      display: 'inline-flex',
      marginRight: '8px',
    },
  },
}));

const tooltipStyles = makeStyles(() => ({
  tooltip: {
    transform: 'translateY(28px) !important',
  },
}));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const BlockContent = forwardRef<any, BlockContentProps>((props, ref) => {
  const { children, label, clickable, onClick, ...rest } = props;
  const classes = useStyles();
  const additionalClassName = [];
  if (clickable) {
    additionalClassName.push('clickable');
  }

  return (
    <Typography
      {...rest}
      ref={ref}
      component="div"
      className={clsx(classes.commonInfo, ...additionalClassName)}
      data-title={label}
      onClick={onClick}>
      {children}
    </Typography>
  );
});

const OpenNewTab = memo(({ newTabLink, newTabTooltipText }: { newTabTooltipText?: string; newTabLink: string }) => (
  <Tooltip
    title={newTabTooltipText || 'เปิดลิงก์ในแท็บใหม่'}
    aria-label={newTabTooltipText || 'เปิดลิงก์ในแท็บใหม่'}
    placement="top"
    arrow>
    <a href={newTabLink} target="_blank" rel="noreferrer" className="open-link">
      <LaunchRoundedIcon fontSize="small" style={{ color: indigo[500] }} />
    </a>
  </Tooltip>
));

const DataDisplayBlock: FC<DataDisplayBlockProps> = memo((props) => {
  const { allowCopy, label, copySuccessText, newTabLink, newTabTooltipText, children, trailingContent, ...rest } =
    props;
  const classes = useStyles();
  const tooltipClasses = tooltipStyles();
  const { showSnackbar, closeSnackbar } = useContext(AppGlobalUiContext);
  const additionalClasses = [];
  if (allowCopy) {
    additionalClasses.push('clickable');
  }
  if (rest.className) {
    additionalClasses.push(rest.className);
  }

  if (allowCopy) {
    return (
      <Box {...rest} className={clsx(classes.shortInfoContainer, ...additionalClasses)}>
        <Tooltip title="คัดลอก" aria-label="คัดลอก" placement="top" arrow classes={tooltipClasses}>
          <BlockContent
            label={label}
            clickable
            onClick={() => {
              closeSnackbar();
              setTimeout(() => {
                copy(children as string);
                showSnackbar(copySuccessText || 'คัดลอกแล้ว', 'success');
              }, 300);
            }}>
            {children}
          </BlockContent>
        </Tooltip>
        {trailingContent}
        {!!newTabLink && <OpenNewTab newTabLink={newTabLink} newTabTooltipText={newTabTooltipText} />}
      </Box>
    );
  }

  return (
    <Box {...rest} className={clsx(classes.shortInfoContainer, ...additionalClasses)}>
      <BlockContent label={label}>{children}</BlockContent>
      {trailingContent}
      {!!newTabLink && <OpenNewTab newTabLink={newTabLink} newTabTooltipText={newTabTooltipText} />}
    </Box>
  );
});

export default DataDisplayBlock;
