/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import dayjs from 'dayjs';

import { MessageContent } from './message';
import { MessageUser } from './message-user';

export enum MessageGroupStatus {
  New = 'new',
  Active = 'active',
}
export type GroupRecentMessage = {
  id: string;
  content: MessageContent | null;
  readBy: string[];
  sender: string;
  readInfo: { id: string; readAt: string }[];
  createdAt: dayjs.Dayjs;
  deletedAt?: dayjs.Dayjs;
};

export class MessageGroup {
  id: string | null = null;

  createdAt: dayjs.Dayjs | null = null;

  createdBy: string | null = null;

  members: MessageUser[] | undefined = undefined;

  isPrivate: boolean | null = null;

  hasUnreadMessage: boolean | null = null;

  status: MessageGroupStatus = MessageGroupStatus.New;

  blockingUsers?: string[];

  recentMessage?: GroupRecentMessage;

  allMemberHashIdList: string[];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(data: any) {
    const {
      id,
      createdAt,
      createdBy,
      members,
      isPrivate,
      hasUnreadMessage = false,
      status = MessageGroupStatus.New,
      recentMessage,
      blockingUsers,
      allMembers,
    } = data;
    this.id = id;
    this.createdAt = createdAt || null;
    this.createdBy = createdBy || null;
    this.members = members || [];
    this.isPrivate = !!isPrivate;
    this.hasUnreadMessage = hasUnreadMessage;
    this.status = status;
    this.blockingUsers = blockingUsers || [];
    this.recentMessage = recentMessage || undefined;
    this.allMemberHashIdList = (!!allMembers && allMembers.split(',')) || null;
  }
}
