import React, { FC, useCallback, useContext } from 'react';

import { DialogActions, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AppGlobalUiContext } from '../../../../../context/AppGlobalUiContext';
import { uploadSearchSuggestionFile } from '../../../../../services/suggestion';
import AppButton from '../../../../ui/AppButton';
import { useStyles } from '../../../../ui/AppDialog';
import AppFormControl from '../../../../ui/AppFormControl/index';

type ModelUploadFileSSDictionaryProps = {
  onClose: () => void;
};

const ModelUploadFileSSDictionary: FC<ModelUploadFileSSDictionaryProps> = (props) => {
  const { onClose } = props;

  const { t } = useTranslation();
  const classes = useStyles();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{ dictionaryFile: FileList; directIntentFile: FileList }>({
    mode: 'onSubmit',
  });

  const { showSnackbar, setAppLoading } = useContext(AppGlobalUiContext);

  const onOkClickHandle = useCallback(async (form) => {
    setAppLoading(true);
    const result = await uploadSearchSuggestionFile(form);
    if (result) {
      if (result.data) {
        showSnackbar(t('master-data:error.uploadFileSearchSuggestion.dataUploadSuccess').toString(), 'success');
        onClose();
      } else {
        showSnackbar(t('master-data:error.uploadFileSearchSuggestion.dataUploadFailed').toString(), 'error');
      }
    }
    setAppLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={handleSubmit(onOkClickHandle)}>
      <Box mb={1}>
        <Typography variant="subtitle2">
          {t('master-data:form.uploadFileSearchSuggestion.fileDictionaryFile')}
        </Typography>
      </Box>
      <AppFormControl>
        <Controller
          name="dictionaryFile"
          rules={{
            required: `${t('validation:invalidFileUpload')}`,
          }}
          control={control}
          render={({ field }) => (
            <Box>
              <input
                type="file"
                ref={field.ref}
                accept=".csv"
                onChange={(e) => {
                  field.onChange(e.target.files);
                }}
              />
              {!!errors.dictionaryFile?.message && (
                <Typography color="error">{errors.dictionaryFile?.message}</Typography>
              )}
            </Box>
          )}
        />
      </AppFormControl>
      <hr />
      <Box mt={1}>
        <Typography variant="subtitle2">
          {t('master-data:form.uploadFileSearchSuggestion.fileDirectIntentFile')}
        </Typography>
      </Box>
      <Box mt={1}>
        <AppFormControl>
          <Controller
            name="directIntentFile"
            rules={{ required: `${t('validation:invalidFileUpload')}` }}
            control={control}
            render={({ field }) => (
              <Box>
                <input
                  type="file"
                  ref={field.ref}
                  accept=".csv"
                  onChange={(e) => {
                    field.onChange(e.target.files);
                  }}
                />
                {!!errors.directIntentFile?.message && (
                  <Typography color="error">{errors.directIntentFile?.message}</Typography>
                )}
              </Box>
            )}
          />
        </AppFormControl>
      </Box>

      <DialogActions className={classes.dialogAction}>
        <AppButton onClick={onClose} variant="text" color="default">
          {t('common:button.cancel')}
        </AppButton>
        <AppButton type="submit" variant="contained" color="primary">
          {t('common:button.ok')}
        </AppButton>
      </DialogActions>
    </form>
  );
};

export default ModelUploadFileSSDictionary;
