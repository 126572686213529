import React, { FC, Suspense, useContext, useEffect, useState } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import { MainModuleContext } from '../../../context/MainModuleContext';

export type AppRouteRendererProps = {
  translations?: string | string[];
  onTranslationLoaded?: () => void;
};

const TranslationLoader: FC<AppRouteRendererProps> = (props) => {
  const { translations, onTranslationLoaded } = props;
  const { i18n } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useEffect((): any => {
    let isSubscribed = true;

    if (translations) {
      i18n.loadNamespaces(translations).then(() => {
        if (isSubscribed && typeof onTranslationLoaded === 'function') {
          onTranslationLoaded();
        }
      });
    } else if (isSubscribed && typeof onTranslationLoaded === 'function') {
      onTranslationLoaded();
    }

    return () => (isSubscribed = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

const AppRouteRenderer: FC<AppRouteRendererProps> = (props) => {
  const { path } = useRouteMatch();
  const { setActiveRoute } = useContext(MainModuleContext);
  const { translations, children } = props;
  const [isTranslationLoaded, setIsTranslationLoaded] = useState(false);

  useEffect(() => {
    setActiveRoute(path);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Suspense fallback={<CircularProgress size={30} />}>
      <TranslationLoader translations={translations} onTranslationLoaded={() => setIsTranslationLoaded(true)} />
      {isTranslationLoaded && children}
    </Suspense>
  );
};

export default AppRouteRenderer;
