import thLocale from 'date-fns/locale/th';
import { TFunction } from 'i18next';
import { DraggableLocation } from 'react-beautiful-dnd';
import { Path, UseFormGetValues, UseFormSetError } from 'react-hook-form';

import PROVINCE_LIST from '../data/province';
import { KTErrorItem } from '../models';
import { hookFormKotlinErrorTypeMapping } from './constants';
import { formatDate } from './date';

/**
 * Generate random string
 * @param count - The length of generated string
 */
export const generateRandom = (count: number): string => {
  const sym = 'abcdefghijklmnopqrstuvwxyz1234567890';
  let str = '';

  for (let i = 0; i < count; i += 1) {
    const idx = Math.random() * sym.length;

    str += sym.charAt(idx);
  }

  return str;
};

/**
 * Get application URL
 *
 * @param slug URL slug element (optional)
 * @returns Full application URL
 */
export const getAppUrl = (slug?: string): string => {
  let urlSlug = (slug || '').trim();
  if (!!slug && !/^\/.+/gi.test(slug)) {
    urlSlug = `/${urlSlug}`;
  }

  const { protocol, host } = window.location;
  const appUrl = process.env.REACT_APP_DOMAIN_URL || `${protocol}//${host}`;

  return `${appUrl}${urlSlug}`;
};

/**
 * Get backend URL
 *
 * @param apiSlug API url slug (if any)
 * @returns Full backend URL
 */
export const getBackendUrl = (apiSlug?: string): string | null => {
  const backendUrl = process.env.REACT_APP_BACKEND_DOMAIN;
  if (!backendUrl) {
    // eslint-disable-next-line no-console
    console.error('No backend URL provided!');

    return null;
  }

  let urlSlug = (apiSlug || '').trim();
  if (!!apiSlug && !/^\/.+/gi.test(apiSlug)) {
    urlSlug = `/${apiSlug}`;
  }

  return `${backendUrl}${urlSlug}`;
};

/**
 * Get backend URL
 *
 * @param apiSlug API url slug (if any)
 * @returns Full backend URL
 */
export const getTCBackendUrl = (apiSlug?: string): string | null => {
  const backendUrl = process.env.REACT_APP_T2H_TC_BACKEND_DOMAIN;
  if (!backendUrl) {
    // eslint-disable-next-line no-console
    console.error('No backend URL provided!');

    return null;
  }

  let urlSlug = (apiSlug || '').trim();
  if (!!apiSlug && !/^\/.+/gi.test(apiSlug)) {
    urlSlug = `/${apiSlug}`;
  }

  return `${backendUrl}${urlSlug}`;
};

/**
 * Get Kotlin backend URL
 *
 * @param apiSlug API url slug (if any)
 * @returns Full backend URL
 */
export const getKTBackendUrl = (apiSlug?: string): string | null => {
  const backendUrl = process.env.REACT_APP_T2H_KT_BACKEND_DOMAIN;
  if (!backendUrl) {
    // eslint-disable-next-line no-console
    console.error('No backend URL provided!');

    return null;
  }

  let urlSlug = (apiSlug || '').trim();
  if (!!apiSlug && !/^\/.+/gi.test(apiSlug)) {
    urlSlug = `/${apiSlug}`;
  }

  return `${backendUrl}${urlSlug}`;
};

/**
 * Build the Truck2Hand website URL
 *
 * @param slug Website slug (optional)
 * @returns Truck2Hand website URL
 */
export const getT2HSiteUrl = (slug?: string): string => {
  const t2hSiteUrl = process.env.REACT_APP_T2H_FRONTEND_URL || 'https://www.truck2hand.com';

  let urlSlug = (slug || '').trim();
  if (!!slug && !/^\/.+/gi.test(slug)) {
    urlSlug = `/${slug}`;
  }

  return `${t2hSiteUrl}${urlSlug}`;
};

/**
 * Build the Truck2Hand website asset URL
 *
 * @param slug Website slug (optional)
 * @returns Truck2Hand website asset URL
 */
export const getT2HAssetUrl = (slug?: string): string => {
  const t2hSiteUrl = process.env.REACT_APP_S3_ASSET_PATH || 'https://static.truck2hand.com';

  if (!!slug && slug.includes(t2hSiteUrl)) {
    return slug;
  }

  let urlSlug = (slug || '').trim();
  if (!!slug && !/^\/.+/gi.test(slug)) {
    urlSlug = `/${slug}`;
  }

  return `${t2hSiteUrl}${urlSlug}`;
};

/**
 * Get T2H SNS URL
 *
 * @param slug URL slug element (optional)
 * @returns Full application URL
 */
export const getSnsAppUrl = (slug?: string): string => {
  let urlSlug = (slug || '').trim();
  if (!!slug && !/^\/.+/gi.test(slug)) {
    urlSlug = `/${urlSlug}`;
  }

  const { protocol, host } = window.location;
  const appUrl = process.env.REACT_APP_T2H_SNS_URL || `${protocol}//${host}`;

  return `${appUrl}${urlSlug}`;
};

/**
 * Generate lighten/darken color from HEX color
 *
 * @param colorHex Color in HEX format
 * @param percent lighten/darken percentage
 * @returns
 */
export const lightenDarkenColor = (colorHex: string, percent: number): string => {
  if (!colorHex) {
    return 'transparent';
  }

  const num = parseInt(colorHex.replace('#', ''), 16);
  const amt = Math.round(2.55 * percent);
  const R = (num >> 16) + amt;
  const B = ((num >> 8) & 0x00ff) + amt;
  const G = (num & 0x0000ff) + amt;

  /* eslint-disable no-nested-ternary */
  return `#${(
    0x1000000 +
    (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
    (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
    (G < 255 ? (G < 1 ? 0 : G) : 255)
  )
    .toString(16)
    .slice(1)}`;
  /* eslint-enable no-nested-ternary */
};

/**
 * Generate URL query parameters from given object
 *
 * @param param Parameters object to assemble
 * @returns query string with URI encoded format
 */
export const queryParamGenerator = (param: { [x: string]: string | number }, sortEncoding = false): string => {
  if (!param || Object.keys(param).length === 0) {
    return '';
  }

  return Object.keys(param)
    .map((key) => {
      if (key === 'sort' && !sortEncoding) {
        return `${key}=${param[key]}`;
      }

      return `${key}=${encodeURIComponent(param[key])}`;
    })
    .join('&');
};

/**
 * Generate URL query parameters from given object with not-encoded sort field
 *
 * @param param Parameters object to assemble
 * @returns query string with URI encoded format
 */
export const nonEncodedQueryParamGenerator = (param: { [x: string]: string | number }): string => {
  if (!param || Object.keys(param).length === 0) {
    return '';
  }

  return Object.keys(param)
    .map((key) => `${key}=${key !== 'sort' ? encodeURIComponent(param[key]) : param[key]}`)
    .join('&');
};

export const getFacebookLink = (facebookUri: string, isFull = false): string | undefined =>
  facebookUri ? `${facebookUri.replace(/^(http|https):\/\//gi, !isFull ? '//' : 'https://')}` : undefined;

export const getLineLink = (lineId: string): string | undefined =>
  lineId ? `${process.env.REACT_APP_LINE_URL_PREFIX || '//line.me/R/ti/p/~'}${lineId}` : undefined;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export const removeNullProperties = (obj: any) => {
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    const hasProperties = value && Object.keys(value).length > 0;
    if (value === null) {
      delete obj[key];
    } else if (typeof value !== 'string' && hasProperties) {
      removeNullProperties(value);
    }
  });
  return obj;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const getPagingParameter = (param: any, pageStartAtZero = false): { [x: string]: string | number } => {
  const { page, rowsPerPage, sortColumn, sortDirection } = param as Pick<
    typeof param,
    'page' | 'rowsPerPage' | 'sortColumn' | 'sortDirection'
  >;
  const reqParam: { [x: string]: string | number } = {
    page: (page || 0) + (!pageStartAtZero ? 1 : 0), // plus 1 for index shift between FE component and Nest.js backend query
    per_page: rowsPerPage || 10,
    sort: sortColumn ? `${sortColumn}|${(sortDirection || 'desc').toUpperCase()}` : '',
  };

  return reqParam;
};
/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
/* eslint-enable @typescript-eslint/no-explicit-any */

export const applyKTErrorToForm = <T>(
  t: TFunction,
  setError: UseFormSetError<T>,
  getValues: UseFormGetValues<T>,
  errorItems: KTErrorItem[],
): void => {
  const formFields = Object.keys(getValues());

  errorItems
    .filter((error) => formFields.includes(error.field))
    .forEach((error) => {
      setError(error.field as Path<T>, {
        type: hookFormKotlinErrorTypeMapping[error.code],
        message: t(error.message, error.param).toString(),
      });
    });
};

export const getFileName = (filePath: string): string => {
  if (filePath) {
    return filePath.split(/\//gi).pop() || '';
  }

  return '';
};

export const getFileType = (filePath: string): null | 'image' | 'media' | 'misc' => {
  if (!filePath) {
    return null;
  }

  const fileName = getFileName(filePath);
  if (!fileName) {
    return null;
  }

  const fileExtension = fileName.split(/\./).pop() || '';
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'bmp'];
  const mediaExtensions = ['mp4', 'mp3', 'mov', 'avi'];
  const isImageFile = imageExtensions.includes(fileExtension);
  const isMediaFile = mediaExtensions.includes(fileExtension);

  if (isImageFile) {
    return 'image';
  }

  if (isMediaFile) {
    return 'media';
  }

  return 'misc';
};

export const getRgbaFromHex = (hex: string, opacity: number): string => {
  const tempHex = hex.replace('#', '');
  const r = parseInt(tempHex.substring(0, 2), 16);
  const g = parseInt(tempHex.substring(2, 4), 16);
  const b = parseInt(tempHex.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${opacity / 100})`;
};

export const reorderDragDropItems = <T>(list: T[], startIndex: number, endIndex: number): T[] => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const moveDragDropItems = <T>(
  source: T[],
  destination: T[],
  droppableSource: DraggableLocation,
  droppableDestination: DraggableLocation,
): { [x: string]: T[] } => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result: { [x: string]: T[] } = {};
  result[droppableSource.droppableId as string] = sourceClone;
  result[droppableDestination.droppableId as string] = destClone;

  return result;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getJsonIndexByVal = (data: any[], keyName: string, keyVal: string): number => {
  const index = data.findIndex((item) => {
    const testIndex = item[keyName] === keyVal;
    return testIndex;
  });

  return index;
};

export const YEAR_LIST = Array.from(Array(99), (_i, index) => {
  const now = new Date();
  const ceYear = +formatDate(now, 'yyyy') - index;
  const beYear =
    +formatDate(now, 'yyyy', {
      locale: thLocale,
    }) - index;

  return {
    namePrefix: `ค.ศ. ${ceYear} / พ.ศ. ${beYear}`,
    name: `${ceYear} / ${beYear}`,
    value: ceYear,
    hashId: ceYear.toString(),
  };
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const pickNotEmptyV2 = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  obj: any,
  options?: { notNull?: boolean; notEmptyString?: boolean; notUndefined?: boolean },
) => {
  const isOptionsNotNull = options?.notNull || true;
  const isOptionsNotEmptyString = options?.notEmptyString || true;
  const isOptionsNotUndefined = options?.notUndefined || true;

  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    const hasProperties = value && Object.keys(value).length > 0;

    if (
      (isOptionsNotNull && value === null) ||
      (isOptionsNotEmptyString && value === '') ||
      (isOptionsNotUndefined && value === undefined)
    ) {
      delete obj[key];
    } else if (typeof value !== 'string' && hasProperties) {
      pickNotEmptyV2(value);
    }
  });

  return obj;
};

export const getProvinceName = (provinceSlug: string): string => {
  if (provinceSlug) {
    const provinceData = PROVINCE_LIST.find((item) => item.slug === provinceSlug);
    return provinceData?.thaiName || '';
  }
  return '';
};
