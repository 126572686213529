import React, { FC } from 'react';

import { Paper, Box, Typography, makeStyles } from '@material-ui/core';
import DeleteForever from '@material-ui/icons/DeleteForever';
import dayjs from 'dayjs';

import AppDatetimeText from '../../../ui/AppDatetimeText';

interface YoutubeThumbnailProps {
  youtubeUrl: string | undefined;
  thumbnailLabel?: string;
  createdAt?: string | number;
  onDeleteButtonClick?: () => void;
}

const useStyles = makeStyles(() => ({
  YoutubeThumbnailContainer: {
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
  },
  deleteButton: {
    cursor: 'pointer',
    opacity: 0.6,
    '&:hover': {
      opacity: 1,
    },
  },
  YoutubeThumbnailBlock: {
    height: '175px',
    overflow: 'hidden',
    backgroundColor: '#000',
  },
  thumbnail: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
  },
}));

export const getYoutubeIdFromUrl = (url: string): string | null => {
  if (!url) {
    return null;
  }

  let ID = '';
  const rawYoutubeId = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);

  if (!rawYoutubeId || !rawYoutubeId[2]) {
    return null;
  }

  [ID] = rawYoutubeId[2].split(/[^0-9a-z_-]/i);

  return ID;
};

const YoutubeThumbnail: FC<YoutubeThumbnailProps> = (props) => {
  const { onDeleteButtonClick, youtubeUrl, thumbnailLabel, createdAt } = props;

  const classes = useStyles();
  const youtubeId = getYoutubeIdFromUrl(youtubeUrl || '');

  if (youtubeId) {
    return (
      <Paper classes={{ root: classes.YoutubeThumbnailContainer }}>
        <Box className={classes.YoutubeThumbnailBlock}>
          <iframe
            width="100%"
            src={`${process.env.REACT_APP_YOUTUBE_EMBED_URL}/${youtubeId}`}
            frameBorder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </Box>
        <Box p={1} display="flex">
          <Box flexGrow={1} minWidth={0} pr={1}>
            <Typography variant="subtitle2">{thumbnailLabel}</Typography>
          </Box>
          {typeof onDeleteButtonClick === 'function' && (
            <Box display="flex" className={classes.deleteButton} onClick={onDeleteButtonClick}>
              <DeleteForever fontSize="small" />
            </Box>
          )}
        </Box>
        {createdAt && (
          <Box p={1} display="flex">
            <AppDatetimeText variant="subtitle2" value={dayjs(createdAt)} />
          </Box>
        )}
      </Paper>
    );
  }
  return null;
};

export default YoutubeThumbnail;
