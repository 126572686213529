import React, { createContext, FC } from 'react';

import useSearchFilter from '../hooks/useSearchFilter';
import { SearchFilterContextProps } from '../models';

const defaultValue: SearchFilterContextProps = {
  currentFilters: undefined,
  setFilters: () => null,
};

const SearchFilterContext = createContext<SearchFilterContextProps>(defaultValue);

const SearchFilterProvider: FC = (props) => {
  const { children } = props;
  const { Provider } = SearchFilterContext;
  const { currentFilters, setFilters } = useSearchFilter();

  return <Provider value={{ currentFilters, setFilters }}>{children}</Provider>;
};

export { SearchFilterContext, SearchFilterProvider };
