import React, { FC, MouseEvent, useCallback, useState } from 'react';

import { Avatar, Box, Container, makeStyles, TableRow, Typography } from '@material-ui/core';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useRouteMatch, useHistory } from 'react-router-dom';

import { getT2HSiteUrl } from '../../../../helpers/utils';
import { AppTableConditions, TableColumn, UserData } from '../../../../models';
import useAppContainerStyles from '../../../../theme/container.style';
import useAppTableStyles from '../../../../theme/table.style';
import AppTableCell from '../../../ui/AppTableCell';
import AppTableList from '../../../ui/AppTableList';

export type UserListTableProps = {
  currentConditions: AppTableConditions;
  totalUsers: number;
  isLoadingData: boolean;
  userList: UserData[];
  onTableConditionsChanged: (values: AppTableConditions) => void;
};

const userTableColumns: TableColumn[] = [
  { name: 'createdAt', label: 'user:list.columns.registerAt', width: 120 },
  { name: 'email', label: 'user:list.columns.email', width: 250 },
  { name: 'mobile', label: 'user:list.columns.mobile', width: 120 },
  { name: 'displayName', label: 'user:list.columns.displayName', width: 240 },
  { name: 'hashId', label: 'user:list.columns.hashId', width: 120, unsortable: true },
  { name: 'nationalId', label: 'user:list.columns.nationalId', width: 140 },
  { name: 'balance', label: 'user:list.columns.balance', width: 100 },
  { name: 'role', label: 'user:list.columns.role', width: 80 },
  { name: 'status', label: 'user:list.columns.status', width: 100 },
  { name: 'updatedAt', label: 'user:list.columns.updatedAt', width: 120 },
];

const useStyles = makeStyles((theme) => ({
  dateDisplayText: {
    lineHeight: 1.3,
  },
  userAvatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    border: `solid 1px ${theme.palette.grey[300]}`,
    boxSizing: 'border-box',
  },
  userNameText: {
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
  },
  verifiedIcon: {
    fontSize: '16px',
    color: theme.palette.success.main,
    display: 'inline-flex',
    transform: 'translateY(-1px)',
    verticalAlign: 'middle',
    marginLeft: '3px',
  },
}));

const UserListTable: FC<UserListTableProps> = (props) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { url } = useRouteMatch();
  const { totalUsers, isLoadingData, currentConditions, userList, onTableConditionsChanged } = props;
  const containerClasses = useAppContainerStyles();
  const tableClasses = useAppTableStyles();
  const classes = useStyles();
  const [tableConditions, setTableConditions] = useState<AppTableConditions>(currentConditions);
  const dateDisplayFormat = i18n.language === 'th' ? 'D MMM BBBB' : 'D MMM YYYY';

  const onTableConditionChangedHandler = useCallback(
    (values: AppTableConditions) => {
      if (JSON.stringify(values) !== JSON.stringify(tableConditions)) {
        setTableConditions(values);
        onTableConditionsChanged(values);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableConditions],
  );

  const openUserDetailPage = useCallback(
    (user: UserData) => {
      history.push(`${url}/${user.hashId}`);
    },
    [history, url],
  );

  // To prevent event bubbling for row click
  const openUserPageClickHandler = useCallback((e: MouseEvent): void => {
    e.stopPropagation();
  }, []);

  return (
    <>
      <Container className={containerClasses.container}>
        <Box className={tableClasses.tableContainer}>
          <AppTableList
            {...tableConditions}
            aria-label="user-list"
            columns={userTableColumns}
            totalDataCount={totalUsers}
            hasPagination
            onTableConditionChanged={onTableConditionChangedHandler}
            isLoading={isLoadingData}
            tableBodyContent={
              userList.length > 0 && (
                <>
                  {userList.map((user: UserData) => (
                    <TableRow
                      hover
                      key={user.hashId}
                      classes={{ root: clsx(tableClasses.tableRow, tableClasses.clickableTableRow) }}
                      onClick={() => openUserDetailPage(user)}>
                      <AppTableCell>
                        <Typography variant="body2" className={classes.dateDisplayText}>
                          {dayjs(user.createdAt).format(`${dateDisplayFormat}`)}
                          <br />
                          {dayjs(user.createdAt).format(`HH:mm:ss`)}
                        </Typography>
                      </AppTableCell>
                      <AppTableCell>{user.email}</AppTableCell>
                      <AppTableCell>{user.mobile !== null ? user.mobile : '-'}</AppTableCell>
                      <AppTableCell>
                        <Box display="flex" flexDirection="row" flexWrap="nowrap" alignItems="center">
                          <Avatar alt={user.displayName} src={user.profileImageUrl} className={classes.userAvatar} />
                          <Box
                            component="span"
                            pl={1}
                            flexGrow={1}
                            minWidth={0}
                            whiteSpace="normal"
                            className={classes.userNameText}>
                            {user.displayName ? `${user.displayName}` : '-'}
                          </Box>
                        </Box>
                      </AppTableCell>
                      <AppTableCell>
                        {!!user.hashId && (
                          <a
                            href={getT2HSiteUrl(`/u/${user.hashId}`)}
                            target="_blank"
                            rel="noreferrer"
                            className={tableClasses.tableExternalLink}
                            onClick={openUserPageClickHandler}>
                            {user.hashId !== null ? user.hashId : '-'}
                          </a>
                        )}
                      </AppTableCell>
                      <AppTableCell>{user.nationalId !== null ? user.nationalId : '-'}</AppTableCell>
                      <AppTableCell>
                        {user.balance ? user.balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}
                      </AppTableCell>
                      <AppTableCell>{t(`user:common.roleShort.${user.role}`)}</AppTableCell>
                      <AppTableCell>{user.status}</AppTableCell>
                      <AppTableCell>
                        <Typography variant="body2" className={classes.dateDisplayText}>
                          {dayjs(user.updatedAt).format(`${dateDisplayFormat}`)}
                          <br />
                          {dayjs(user.updatedAt).format(`HH:mm:ss`)}
                        </Typography>
                      </AppTableCell>
                    </TableRow>
                  ))}
                </>
              )
            }
          />
        </Box>
      </Container>
    </>
  );
};

export default UserListTable;
