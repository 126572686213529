/* eslint-disable */
import { addDays, format, isSameDay, parseISO } from 'date-fns';
import enUsLocal from 'date-fns/locale/en-US';
import thLocal from 'date-fns/locale/th';

export enum Locale {
  Th = 'th',
  En = 'en',
}

export interface DateFormatOptions {
  locale?: globalThis.Locale;
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  firstWeekContainsDate?: number;
  useAdditionalWeekYearTokens?: boolean;
  useAdditionalDayOfYearTokens?: boolean;
}

/**
 * ref:
 * - https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl
 * - https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleDateString#using_locales
 */
const INTL_DATE = {
  [Locale.Th]: 'th-TH',
  [Locale.En]: 'en-US',
};

export const isToday = (date: Date): boolean => {
  const today = new Date();

  return isSameDay(date, today);
};

export const isYesterday = (date: Date): boolean => {
  const yesterday = addDays(new Date(), -1);

  return isSameDay(date, yesterday);
};

export const cloneDate = (date: Date): Date => {
  return new Date(+date);
};

export const isAnotherMonth = (date: Date, compare: Date): boolean => {
  return date && date.getMonth() !== compare.getMonth();
};

export const isWeekend = (date: Date): boolean => {
  return date.getDay() === 0 || date.getDay() === 6;
};

/**
 * keep this code for rollback when new formatDate function has side effects
 */
// export const formatDate = (dirtyDate: Date | string, template: string, dirtyOptions?: DateFormatOptions): string => {
//   const date = typeof dirtyDate === 'string' ? parseISO(dirtyDate) : dirtyDate;
//   const defaultLocale = Locale.Th;
//   const options: DateFormatOptions = {
//     ...(dirtyOptions ? dirtyOptions : {}),
//     locale: dirtyOptions && dirtyOptions.locale ? dirtyOptions.locale : defaultLocale,
//   };

//   // eslint-disable-next-line @typescript-eslint/no-unused-vars
//   const z = {
//     M: date.getMonth() + 1,
//     d: date.getDate(),
//     D: date.getDate(),
//     h: date.getHours(),
//     H: date.getHours(),
//     m: date.getMinutes(),
//     s: date.getSeconds(),
//   };

//   template = template.replace(/(M+|d+|D+|h+|H+|m+|s+)/g, (v) => {
//     return ((template.length > 1 ? '0' : '') + eval('z.' + v.slice(-1))).slice(-2);
//   });

//   return template.replace(/(y+|Y+)/g, (v) => {
//     const locale = (options && options.locale) || defaultLocale;
//     const yearStr = date.toLocaleDateString(locale, {
//       year: 'numeric',
//     });
//     const isNeedToRemoveBePrefix = locale === Locale.Th;
//     const year = isNeedToRemoveBePrefix === true ? yearStr.split('').reverse().slice(0, 4).reverse().join('') : yearStr;

//     return year.slice(-v.length);
//   });
// };

/**
 *
 * issues: https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md
 * ref: https://stackoverflow.com/questions/60728212/how-do-you-format-a-datetime-to-am-pm-without-periods-when-using-date-fns-vers
 * some characters not support latin tokens
 * please aware of
 * - YYYY|YY should be yyyy|yy
 * - A (momentjs stand for "AM|PM") should be 'a'
 * - a (date-fns use 'a' which is not correct) should be 'aaa'
 *
 */
export const formatDate = (
  dirtyDate: Date | string,
  dirtyTemplate: string,
  dirtyOptions?: DateFormatOptions,
): string => {
  const defaultLocale = enUsLocal;
  const date = typeof dirtyDate === 'string' ? parseISO(dirtyDate) : dirtyDate;
  const options: DateFormatOptions = {
    ...(dirtyOptions ? dirtyOptions : {}),
    locale: dirtyOptions && dirtyOptions.locale ? dirtyOptions.locale : defaultLocale,
  };

  /**
   * date-fns not support timezone
   * ref: https://github.com/bbc/simorgh/issues/637
   */
  const template = dirtyTemplate.replace(/(y+|Y+)/g, (v) => {
    const dirtyLocale = (options && options.locale) || defaultLocale;
    const locale = Object.is(dirtyLocale, thLocal) ? Locale.Th : Locale.En;
    const yearStr = date.toLocaleDateString(locale, {
      year: 'numeric',
    });
    const isNeedToRemoveBePrefix = Object.is(dirtyLocale, thLocal);
    const year = isNeedToRemoveBePrefix === true ? yearStr.split('').reverse().slice(0, 4).reverse().join('') : yearStr;

    return year.slice(-v.length);
  });

  return format(date, template, options);
};

export const fromNow = (date: Date): string => {
  const diffSec = Math.floor((Date.now() - date.getTime()) / 1000);
  const MIN = 60;
  const HOUR = MIN * 60;
  const DAY = HOUR * 24;

  if (diffSec < MIN) {
    const sec = Math.abs(diffSec);

    return `${sec} วินาที`;
  } else if (diffSec < HOUR) {
    const min = Math.floor(diffSec / MIN);

    return `${min} นาที`;
  } else if (diffSec < DAY) {
    const hour = Math.floor(diffSec / HOUR);

    return `${hour} ชม.`;
  } else if (diffSec < 2 * DAY) {
    return `1 วัน`;
  } else {
    return toDateShortMonthFormat(date);
  }
};

//DD MMM YY
export const toDateShortMonthFormat = (date: Date): string => {
  const intlDateFormat = INTL_DATE[Locale.Th];
  const dateFormat = date.toLocaleDateString(intlDateFormat, { day: 'numeric', month: 'short', year: '2-digit' });

  return dateFormat;
};

// hh:mm
export const toTimeFormat = (date: Date): string => {
  const h = date.getHours();
  const m = date.getMinutes().toString();

  return `${h.toString().padStart(2, '0')}:${m.padStart(2, '0')} น.`;
};

// timestamp format
export const dateTimeFormat = (date: Date): string => {
  if (isToday(date)) {
    return `วันนี้ - ${toTimeFormat(date)}`;
  } else if (isYesterday(date)) {
    return `เมื่อวานนี้ - ${toTimeFormat(date)}`;
  } else {
    return `${toDateShortMonthFormat(date)} - ${toTimeFormat(date)}`;
  }
};

export const CAMPAIGN_FREE_COIN_NEW_SELLER_END_DATE_TIMESTAMP = 1714496399000;
