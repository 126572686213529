/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { Box } from '@material-ui/core';
import { UseFormReturn } from 'react-hook-form';

import { KTCategoryPathItem } from '../../../../models';
import FieldByAttributeDataType from '../FieldByAttributeDataType';

interface ProductInfoFieldGroupProps {
  path: KTCategoryPathItem[];
  formHolder: UseFormReturn<any>;
  isEditingForm: boolean;
  onSetValue?: (data: { name?: string; value?: any; uid?: number } & any) => void;
}

const checkActiveDependOnByFormDataAndCategoryPathItem = (formData: any, itemCategoryPath: KTCategoryPathItem) => {
  if (formData && itemCategoryPath && itemCategoryPath.dependOnCategories && itemCategoryPath.dependOnCategories.length > 0) {
    const dependOnCategories = itemCategoryPath.dependOnCategories.map((item) => item.masterIndex).filter(Boolean);
    const masterIndexDataList =
      dependOnCategories.length > 0 ? [...new Map(dependOnCategories.map((item) => [item.uid, item])).values()] : [];

    const masterIndexSlugList = masterIndexDataList.map((item) => item.slug);
    const dependOnCategorySlugList = itemCategoryPath.dependOnCategories.map((item) => item.slug);

    let matchList: string[] = [];

    /* eslint-disable no-plusplus */
    for (let index = 0; index < masterIndexSlugList.length; index++) {
      const item = formData[masterIndexSlugList[index]];

      if (item) {
        for (let indexDepend = 0; indexDepend < dependOnCategorySlugList.length; indexDepend++) {
          const dependItem = dependOnCategorySlugList[indexDepend];

          // eslint-disable-next-line max-depth
          if (item === dependItem) {
            matchList = [...matchList, dependItem];
          }
        }
      }
    }
    /* eslint-enable */

    return matchList.length > 0 ? itemCategoryPath : null;
  }

  // return itemCategoryPath when this item has not dependOnCategories
  return itemCategoryPath;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ProductInfoFieldGroup = (props: ProductInfoFieldGroupProps) => {
  const { path, formHolder, isEditingForm } = props;
  const { watch, setValue } = formHolder;

  return (
    <>
      {path.map((item, index) => {
        const isActive = checkActiveDependOnByFormDataAndCategoryPathItem(watch(), item);

        if (!isActive) {
          return null;
        }

        return (
          // eslint-disable-next-line react/no-array-index-key
          <Box key={index} mt={index !== 0 ? 1 : 2}>
            {item?.masterIndex && (
              <FieldByAttributeDataType
                key={`${item.masterIndex.slug}`}
                value={item.masterIndex.slug ? watch(item.masterIndex.slug) : ''}
                itemPath={item}
                path={path}
                formHolder={formHolder}
                defaultValue=""
                isEditingForm={isEditingForm}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onSetValue={(data: any) => {
                  if (data?.name?.includes('brand')) {
                    setValue('model', '');
                  }
                }}
              />
            )}
          </Box>
        )
      })}
    </>
  );
};

export default ProductInfoFieldGroup;
