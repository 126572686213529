import { AdminActionField, AdminUserActions, UserRole } from '../models';

const rolesActionMapping: { [key in AdminActionField]: AdminUserActions } = {
  userAccount: {
    activate: [UserRole.GM, UserRole.Manager, UserRole.Staff],
    deactivate: [UserRole.GM, UserRole.Manager, UserRole.Staff],
    delete: [UserRole.GM, UserRole.Manager, UserRole.Staff],
  },
  userRole: {
    update: [UserRole.GM],
  },
  userInterviewLog: {
    update: [UserRole.GM, UserRole.Manager, UserRole.Staff],
  },
  userBalance: {
    update: [UserRole.GM],
  },
  userMembership: {
    update: [UserRole.GM],
  },
  userEmailSubscription: {
    update: [UserRole.GM, UserRole.Manager, UserRole.Staff],
  },
  userEmailVerification: {
    update: [UserRole.GM, UserRole.Manager, UserRole.Staff],
  },
  userSocialUrls: {
    update: [UserRole.GM, UserRole.Manager, UserRole.Staff],
  },
  sellerApplication: {
    update: [UserRole.GM, UserRole.Manager, UserRole.Staff],
  },
  webConfig: {
    create: [UserRole.GM, UserRole.Manager],
    update: [UserRole.GM, UserRole.Manager],
    delete: [UserRole.GM, UserRole.Manager],
  },
  masterBrand: {
    create: [UserRole.GM, UserRole.Manager],
    update: [UserRole.GM, UserRole.Manager],
    delete: [UserRole.GM, UserRole.Manager],
  },
  adminUser: {
    view: [UserRole.GM],
  },
  masterDataOps: {
    update: [UserRole.GM],
  },
  product: {
    create: [UserRole.GM, UserRole.Manager],
    update: [UserRole.GM, UserRole.Manager],
  },
  promoCode: {
    create: [UserRole.GM, UserRole.Manager, UserRole.Staff],
  },
  coinBalance: {
    update: [UserRole.GM],
  },
  abTesting: {
    create: [UserRole.GM, UserRole.Manager],
    update: [UserRole.GM, UserRole.Manager],
  },
  loginBonus: {
    create: [UserRole.GM],
    update: [UserRole.GM],
  },
  interviewLog: {
    update: [UserRole.GM],
  },
};

export default rolesActionMapping;
