import UserApplicationList from './List';
import { RouteConfig } from '../../../models/route.model';

const userApplicationsRoutes = (path: string): RouteConfig[] => [
  {
    key: 'USER_APP_LIST',
    path,
    component: UserApplicationList,
    exact: true,
    title: 'common:title.v3.userApplications',
  },
];

export default userApplicationsRoutes;
