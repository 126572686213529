/* eslint-disable import/prefer-default-export */

import { apiTCRequestV2 } from '../../helpers/request';
import { OmiseCreditCardTCResponse } from '../../models/user.model';

export const getCreditCardByUserHashId = async (userHashId: string): Promise<OmiseCreditCardTCResponse[]> => {
  try {
    const result = await apiTCRequestV2<OmiseCreditCardTCResponse[]>(`/admin/user/${userHashId}/credit-card`);

    return result.data || [];
  } catch (e) {
    return [];
  }
};

export const deleteCreditCardById = async (
  userHashId: string,
  omiseCreditCardId: string,
): Promise<OmiseCreditCardTCResponse[]> => {
  try {
    const result = await apiTCRequestV2<OmiseCreditCardTCResponse[]>(
      `/admin/user/${userHashId}/credit-card/${omiseCreditCardId}`,
      {
        method: 'DELETE',
        data: { omiseCreditCardId, userHashId },
      },
    );

    return result.data || [];
  } catch (error) {
    return [];
  }
};
