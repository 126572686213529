import { apiKTRequest } from '../helpers/request';
import { ApiResponse } from '../models';

/**
 * Sync elasticsearch dictionary
 *
 * @returns Update result as promise function
 */
// eslint-disable-next-line import/prefer-default-export
export const syncElasticsearchTagData = async (): Promise<ApiResponse<boolean>> => {
  const result = await apiKTRequest<boolean>(`/admin_gm/es/sns_tag/sync`, {
    method: 'GET',
  });

  return result;
};
