import dayjs from 'dayjs';
import {
  DocumentData,
  DocumentSnapshot,
  collection,
  FirestoreDataConverter,
  getDocs,
  QueryDocumentSnapshot,
  SnapshotOptions,
  Timestamp,
  QueryConstraint,
  orderBy,
  query,
  startAfter,
  limit,
} from 'firebase/firestore';

import { firestoreDb, FS_DB_COLLECTION } from '../../../helpers/firebase';
import { sortMessage } from '../../../helpers/sortMessage';
import { ApiResponse } from '../../../models';
import { Message, MessageQueryConditions } from '../../../models/message';

const convertDataToMessage = (id: string, data: DocumentData) => {
  const { content, readBy = [], readInfo = [], sender, createdAt, deletedAt } = data;

  return new Message({
    id,
    content: content || null,
    sender,
    readBy,
    readInfo: [...readInfo].map((info) => ({
      id: info.id,
      readAt: typeof info.readAt === 'string' ? dayjs(info.readAt, 'YYYY-MM-DD HH:mm:ss') : dayjs(),
    })),
    createdAt: createdAt ? dayjs((createdAt as Timestamp).toDate()) : dayjs(),
    deletedAt: deletedAt ? dayjs((deletedAt as Timestamp).toDate()) : null,
  });
};

export const messageConverter: FirestoreDataConverter<Message> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toFirestore: (form: any) => ({
    ...form,
  }),
  fromFirestore: (snapshot: QueryDocumentSnapshot, options: SnapshotOptions) => {
    const data = snapshot.data(options);
    return convertDataToMessage(snapshot.id, data);
  },
};

export const getMessages = async (
  conditions: MessageQueryConditions,
): Promise<ApiResponse<{ message: Message[]; lastDocSnapshot: DocumentSnapshot<DocumentData> | null }>> => {
  try {
    const { groupId, messagePerRequest = 9999, lastVisibleDoc } = conditions;

    const ref = collection(firestoreDb, `${FS_DB_COLLECTION.messageGroups}/${groupId}/messages`);

    const constraints: QueryConstraint[] = [orderBy('createdAt', 'desc')];

    if (lastVisibleDoc) {
      constraints.push(startAfter(lastVisibleDoc));
    }
    constraints.push(limit(messagePerRequest));

    const queryRef = query(ref, ...constraints).withConverter(messageConverter);

    const messageDocs = await getDocs(queryRef);

    const requests = messageDocs.docs.map(async (msgDoc) => {
      const result = await msgDoc.data();
      return result;
    });

    const messages = await Promise.all(requests);
    const sortedMessages = messages.sort(sortMessage);
    return {
      data: {
        message: sortedMessages,
        lastDocSnapshot: !messageDocs.empty ? messageDocs.docs[messageDocs.docs.length - 1] : null,
      },
    };
  } catch (ex) {
    return {
      data: null,
    };
  }
};
