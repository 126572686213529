import BrandEdit from './brand/Edit';
import BrandList from './brand/List';
import CategoryEdit from './category/Edit';
import CategoryList from './category/List';
import MasterDataList from './List';
import MasterIndexEdit from './master-index/Edit';
import MasterIndexList from './master-index/List';
import ModelEdit from './model/Edit';
import ModelList from './model/List';
import { RouteConfig } from '../../models/route.model';

const masterDataRoutes = (path: string): RouteConfig[] => [
  {
    key: 'MASTER_DATA_LIST',
    path,
    component: MasterDataList,
    exact: true,
    title: 'common:title.masterData',
  },
  {
    key: 'MASTER_DATA_BRANDS',
    path: `${path}/brands`,
    exact: true,
    component: BrandList,
    title: 'master-data:menu.brand',
    backUrl: path,
  },
  {
    key: 'MASTER_DATA_BRAND_DETAIL',
    path: `${path}/brands/:uid`,
    exact: true,
    component: BrandEdit,
    backUrl: `${path}/brands`,
  },
  {
    key: 'MASTER_DATA_MODELS',
    path: `${path}/brands/:uid/models`,
    exact: true,
    component: ModelList,
    title: 'master-data:menu.model',
    backUrl: `${path}/brands/:uid`,
  },
  {
    key: 'MASTER_DATA_MODEL_DETAIL',
    path: `${path}/brands/:uid/models/:modelUid`,
    exact: true,
    component: ModelEdit,
    backUrl: `${path}/brands/:uid/models`,
  },
  {
    key: 'MASTER_DATA_MASTER_INDEX',
    path: `${path}/master-index`,
    exact: true,
    component: MasterIndexList,
    title: 'master-data:menu.masterIndex',
    backUrl: path,
  },
  {
    key: 'MASTER_DATA_MASTER_INDEX_DETAIL',
    path: `${path}/master-index/:uid`,
    exact: true,
    component: MasterIndexEdit,
    backUrl: `${path}/master-index`,
  },
  {
    key: 'MASTER_DATA_CATEGORIES',
    path: `${path}/master-index/:uid/categories`,
    exact: true,
    component: CategoryList,
    title: 'master-data:menu.category',
    backUrl: `${path}/master-index/:uid`,
  },
  {
    key: 'MASTER_DATA_CATEGORY_DETAIL',
    path: `${path}/master-index/:uid/categories/:catUid`,
    exact: true,
    component: CategoryEdit,
    backUrl: `${path}/master-index/:uid/categories`,
  },
];

export default masterDataRoutes;
