import React, { FC, useContext, useState } from 'react';

import { useTranslation } from 'react-i18next';
import useDeepCompareEffect from 'use-deep-compare-effect';

import AbTestingListSearchForm from '../../components/partials/v3/abTesting/AbTestingListSearchForm';
import AbTestingListTable from '../../components/partials/v3/abTesting/AbTestingListTable';
import { AppGlobalUiContext } from '../../context/AppGlobalUiContext';
import { SearchFilterContext } from '../../context/SearchFilterContext';
import { defaultRowsPerPage } from '../../helpers/constants';
import { AppTableConditions } from '../../models';
import { AbTestingData, AbTestingListSearchFormData, AbTestingListSearchParamsTC } from '../../models/ab-testing.model';
import { getAbTestingListGroupByFeatures } from '../../services/ab-testing';

const searchFilterContextKey = 'ab-testing';

const AbTestingList: FC = () => {
  const { t } = useTranslation();
  const { showSnackbar } = useContext(AppGlobalUiContext);
  const [abTestingList, setAbTestingList] = useState<AbTestingData[]>([]);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [totalAbTestings, setTotalAbTestings] = useState<number>(0);

  const { currentFilters: ctxFilters, setFilters } = useContext(SearchFilterContext);
  const currentFilters: AbTestingListSearchParamsTC = ctxFilters ? ctxFilters[searchFilterContextKey] : undefined;
  const [searchFilters, setSearchFilters] = useState<AbTestingListSearchFormData>({
    searchField: currentFilters?.searchField || '',
    searchKeyword: currentFilters?.searchKeyword || '',
    filteringType: currentFilters?.filteringType || [],
  });

  const [tableConditions, setTableConditions] = useState<AppTableConditions>({
    page: currentFilters?.page || 0,
    rowsPerPage: currentFilters?.rowsPerPage || defaultRowsPerPage,
    sortColumn: currentFilters?.sortColumn || 'createdAt',
    sortDirection: currentFilters?.sortDirection || 'desc',
  });

  const getCurrentSearchParam = (): AbTestingListSearchParamsTC => ({
    page: tableConditions.page || 0,
    rowsPerPage: tableConditions.rowsPerPage || defaultRowsPerPage,
    sortColumn: tableConditions.sortColumn || 'createdAt',
    sortDirection: tableConditions.sortDirection || 'desc',
    searchField: searchFilters.searchField,
    searchKeyword: searchFilters.searchKeyword,
    filteringType: searchFilters.filteringType,
  });

  const requestAbTestingList = async (isPageSubscribed = true): Promise<boolean> => {
    if (isPageSubscribed) {
      setIsLoadingData(true);

      const searchConditions = getCurrentSearchParam();
      setFilters(searchFilterContextKey, searchConditions);
      const result = await getAbTestingListGroupByFeatures(searchConditions);

      setIsLoadingData(false);

      if (result.data?.contents) {
        setAbTestingList(result.data.contents);
        setTotalAbTestings(result.data?.page?.totalElements || 0);
        return Promise.resolve(true);
      }
      setAbTestingList([]);
      setTotalAbTestings(0);
      showSnackbar(t('ab-testing:error.list.requestFailed').toString(), 'error');
      return Promise.reject(result.error);
    }

    return false;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useDeepCompareEffect((): any => {
    let isSubscribed = true;

    requestAbTestingList(isSubscribed);

    return () => (isSubscribed = false);
  }, [tableConditions, searchFilters]);

  const tableConditionsChangedHandler = (values: AppTableConditions) => {
    setTableConditions(values);
  };

  const onSearchFormSubmit = (filters: AbTestingListSearchFormData) => {
    if (isLoadingData) {
      showSnackbar(t('common:message.pleaseWaitForDataToFinish').toString(), 'warning');
      return;
    }
    if (tableConditions.page !== 0) {
      tableConditions.page = 0;
    }
    setSearchFilters(filters);
  };

  return (
    <>
      <AbTestingListSearchForm
        isLoadingData={isLoadingData}
        currentFilters={searchFilters}
        onSearchFormSubmit={onSearchFormSubmit}
        isFilterByFeatureName
      />

      <AbTestingListTable
        currentConditions={tableConditions}
        abTestingList={abTestingList}
        totalAbTestings={totalAbTestings}
        isLoadingData={isLoadingData}
        onTableConditionsChanged={tableConditionsChangedHandler}
        onFetch={() => requestAbTestingList()}
      />
    </>
  );
};

export default AbTestingList;
