import { AppTableConditions } from './common';

export enum PromoCodeStateWorkflow {
  Start = 'W12_START_STATE',
  Available = 'W12_AVAILABLE_STATE',
  Used = 'W12_USED_STATE',
  Expired = 'W12_EXPIRED_STATE',
  Inactive = 'W12_INACTIVE_STATE',
}
export interface PromoCodeData {
  hashId: string;
  name: string;
  description: string;
  promoCode: string;
  availableFrom: number;
  availableTo: number;
  applyToProductHashId: string;
  userOwnerHashId: string;
  promoPriceAmount: number;
  promoPriceUnit: string;
  promoCoinProductAmount: number;
  promoCoinProductUnit: string;
  maxDiscountPrice: number;
  maxDiscountPriceType: string;
  productPromoCodeStateWorkflow: PromoCodeStateWorkflow;
  promoType?: string | null;
  createdAt: number;
  updatedAt: number;
}
export interface GetPromoCodeListTCResponse {
  contents: PromoCodeData[];
  page?: {
    size?: number;
    totalElements?: number;
    totalPages?: number;
    number?: number;
  };
}

export type PromoCodeListSearchFormData = {
  searchField: string;
  searchKeyword: string;
  filterStateWorkflow: string[];
};

export type PromoCodeListSearchParamsTC = PromoCodeListSearchFormData & AppTableConditions;

export type PromoCodeSearchFiltersTC = Partial<PromoCodeListSearchParamsTC> & {
  q?: string;
};

export type PromoCodeFormDataRequest = {
  promoType?: string | null;
  name: string;
  description?: string | null;
  promoCode: string;
  availableFrom?: number | null;
  availableTo?: number | null;
  applyToProductHashId?: string | null;
  userOwnerHashId?: string | null;
  promoPriceAmount?: number | null;
  promoPriceUnit?: string | null;
  promoCoinProductAmount?: number | null;
  promoCoinProductUnit?: string | null;
  maxDiscountPrice?: number | null;
  maxDiscountPriceType?: string | null;
};
