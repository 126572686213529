import { AppTableConditions } from './common';
import { OrderOnceData, OrderOnceProductData } from './order-order.model';

export enum UserRole {
  GM = 'ADMIN_GM',
  Manager = 'ADMIN_MANAGER',
  Staff = 'ADMIN_STAFF',
  Seller = 'SELLER',
  SellerBronze = 'SELLER_BRONZE',
  SellerSilver = 'SELLER_SILVER',
  SellerGold = 'SELLER_GOLD',
  Buyer = 'BUYER',
}

export type UserAuthProvider = {
  providerId: string;
  provider: 'facebook' | 'line' | 'google';
};

export enum SellerMembership {
  Basic = 'basic',
  Bronze = 'bronze',
  Silver = 'silver',
  Gold = 'gold',
}

export enum UserStatusRemark {
  Normal = 'normal',
  Suspicious = 'suspicious',
  Fraud = 'fraud',
}

export type UserStatus = 'active' | 'inactive' | 'draft';

export interface SocialLink {
  title: string;
  url: string;
  createdDate?: string;
}

export type UserVideo = {
  url: string;
  title: string;
  createdAt: number;
};

export interface UserData {
  address: string;
  authenticateProviders: UserAuthProvider[];
  balance: number;
  companyName: string;
  companyTaxId: string;
  contactEmail: string;
  contactNumber: string;
  createdAt: string;
  description: string;
  displayName: string;
  district: string;
  email: string;
  facebookUri: string;
  fax: string;
  firstName: string;
  fullName: string;
  hasPassword: boolean;
  hashId: string;
  homePhone: string;
  isBankAccountVerified: boolean;
  isEmailPublic: boolean;
  isEmailVerified: boolean;
  isHomeVerified: boolean;
  isIdentityVerified: boolean;
  isMobilePublic: boolean;
  isMobileVerified: boolean;
  isVerified: boolean;
  lastName: string;
  lineId: string;
  membership: string;
  membershipEndAt: string | null;
  membershipProductHashId: string | null;
  membershipProductId: number | null;
  membershipStartAt: string | null;
  mobile: string;
  nationalId: string;
  passportNo: string;
  personalWebHomepage: string;
  profileImageUrl: string;
  province: string;
  role: UserRole;
  roles: UserRole[];
  sellerIdentityType: string;
  sellerType: string;
  shopLocationImageUrl: string;
  shopLocationLatitude: number;
  shopLocationLongitude: number;
  status: UserStatus;
  updatedAt: string;
  username: string;
  verifiedAddress: string;
  verifiedFullName: string;
  verifiedAt: string;
  zipcode: string;
  socialMedias: SocialLink[];
}

export type UserListSearchFormData = {
  searchField: string;
  searchKeyword: string;
  minCoin: string;
  maxCoin: string;
  filteringRoles: string[];
  filteringStatuses: string[];
  filteringTags: string[];
};

export interface AdminUserProfile extends Pick<UserData, 'hashId' | 'email' | 'fullName' | 'role' | 'displayName'> {
  imageUrl: string;
}

export type UserEmailSubscriptionRequest = {
  // eslint-disable-next-line camelcase
  email_id: string;
  subscribe: boolean;
};

export type EmailSubscription = {
  type: string;
  thaiName: string;
  englishName: string;
  hashId: string;
};

export type UserEmailSubscription = {
  hashId?: string;
  userHashId: string;
  isSubscribed: boolean;
  emailSubscription: EmailSubscription;
};

export type UserListSearchParams = UserListSearchFormData & AppTableConditions;

export type UserSearchFilters = Partial<UserListSearchParams> & {
  q?: string;
};

export interface GetUserListResponse {
  users: UserData[];
  totalCount: number;
}

export class User {
  readonly address!: string;

  readonly authenticateProviders!: UserAuthProvider[];

  readonly balance!: number;

  readonly companyName!: string;

  readonly companyTaxId!: string;

  readonly contactEmail!: string;

  readonly contactNumber!: string;

  readonly createdAt!: string;

  readonly description!: string;

  readonly displayName!: string;

  readonly district!: string;

  readonly email!: string;

  readonly facebookUri!: string;

  readonly fax!: string;

  readonly firstName!: string;

  readonly fullName!: string;

  readonly hasPassword!: boolean;

  readonly hashId!: string;

  readonly homePhone!: string;

  readonly isBankAccountVerified!: boolean;

  readonly isEmailPublic!: boolean;

  readonly isEmailVerified!: boolean;

  readonly isHomeVerified!: boolean;

  readonly isIdentityVerified!: boolean;

  readonly isMobilePublic!: boolean;

  readonly isMobileVerified!: boolean;

  readonly isVerified!: boolean;

  readonly lastName!: string;

  readonly lineId!: string;

  readonly membership!: string;

  readonly membershipEndAt!: string;

  readonly membershipProductHashId!: string;

  readonly membershipProductId!: number;

  readonly membershipStartAt!: string;

  readonly mobile!: string;

  readonly nationalId!: string;

  readonly passportNo!: string;

  readonly personalWebHomepage!: string;

  readonly profileImageUrl!: string;

  readonly province!: string;

  readonly role!: UserRole;

  readonly roles!: UserRole[];

  readonly sellerIdentityType!: string;

  readonly sellerType!: string;

  readonly interviewLog!: string;

  readonly shopLocationImageUrl!: string;

  readonly shopLocationLatitude!: number;

  readonly shopLocationLongitude!: number;

  readonly status!: UserStatus;

  readonly updatedAt!: string;

  readonly username!: string;

  readonly verifiedAddress!: string;

  readonly verifiedFullName!: string;

  readonly zipcode!: string;

  readonly socialMedias!: SocialLink[];

  constructor(userData: Partial<UserData>) {
    Object.assign(this, userData);
  }

  get dataObject(): Partial<UserData> {
    return { ...this };
  }

  get isBuyer(): boolean {
    return this.role === UserRole.Buyer;
  }

  get isSeller(): boolean {
    return [UserRole.Seller, UserRole.SellerBronze, UserRole.SellerSilver, UserRole.SellerGold].includes(
      this.role as UserRole,
    );
  }

  get isDeactivated(): boolean {
    return this.status === 'inactive';
  }
}

export type UserNationalIdAvailableResponse = {
  users: UserDataTC[];
  status: number;
};

export type UserBankAccountAvailableResponse = {
  users: UserDataTC[];
  status: number;
};

export enum UserStateWorkflow {
  Buyer = 'W2_BUYER_STATE',
  Seller = 'W2_SELLER_STATE',
  UnverifiedSeller = 'W2_UNVERIFIED_SELLER_STATE',
}

export enum UserGeneralWorkflow {
  FirstInit = 'W10_FIRST_INIT_STATE',
  Active = 'W10_ACTIVE_STATE',
  Banned = 'W10_BANNED_STATE',
  BlackListed = 'W10_BLACKLISTED_STATE',
  InActive = 'W10_INACTIVE_STATE',
}

export interface UserDataTC {
  id: number;
  userHashId: string;
  latestSellerApplicationHashId: string;
  esSyncedAt: null;
  nestSyncedAt: null;
  isEmailVerified: true;
  isMobileVerified: false;
  sellerVerifiedAt: null;
  username: string;
  password: null;
  email: string;
  mobilePhone: null;
  displayName: string;
  facebookUrl: string;
  lineUrl: string;
  websiteUrl: string;
  instagramUrl: string;
  youtubeUrl: string;
  tiktokUrl: string;
  profileImage: UserImage | null;
  isNotificationEmailSubScripted: boolean;
  isNotificationEmailTpg: boolean;
  verifiedFullName: string;
  verifiedCompanyName: string;
  passportNumber: string;
  companyTaxId: string;
  nationalId: string;
  province: string;
  district: string;
  address: string;
  zipcode: string;
  birthday: string;
  nationality: string;
  gender: string | null;
  bookBankNumber: string;
  bookBankCompany: {
    englishName: string;
    hashId: string;
    logo: string;
    thaiName: string;
  };
  bookBankCompanyHashId: string;
  selfieImage: UserImage | null;
  bookBankImage: UserImage | null;
  nidImage: UserImage | null;
  shopLocationImage: UserImage | null;
  shopLocationLatitude: number;
  shopLocationLongitude: number;
  shopName: string;
  shopOtherTelephoneNumbers?: {
    tel?: string;
    name?: string;
  }[];
  shopProductType?: string[];
  shopHeroImage: UserImage | null;
  shopDescription: string;
  shopProvince: string;
  shopDistrict: string;
  shopAddress: string;
  shopZipcode: string;
  shopType: ShopType;
  billingCompanyName: string;
  billingAddress: string;
  billingTel: string;
  billingTaxId: string;
  sellerType: ShopType;
  sellerProductType: string[];
  badges: UserBadges[];
  sellerYoutubeVdos: UserVideo[];
  sellerRatingScore: number;
  membershipStartedAt: string;
  membershipEndAt: string;
  role: UserRole;
  userGeneralWorkflow: string;
  buyerSellerVerificationWorkflow: UserStateWorkflow;
  userRoleStateWorkflow: UserRoleTC | null;
  sellerMembershipWorkflow: null;
  hasPassword: true;
  interviewLog: Omit<InterviewLogDataRequest, 'userHashId'>[];
  balance: number;
  createdAt: string;
}

export enum UserRoleTC {
  GM = 'ADMIN_GM',
  Manager = 'ADMIN_MANAGER',
  Staff = 'ADMIN_STAFF',
  Seller = 'W3_SELLER_STATE',
  SellerBronze = 'W3_SELLER_BRONZE_STATE',
  SellerSilver = 'W3_SELLER_SILVER_STATE',
  SellerGold = 'W3_SELLER_GOLD_STATE',
  Buyer = 'W3_BUYER_STATE',
}
export type UserImage = {
  fileHashId?: string;
  fullUrl?: string;
  style?: string;
};

export type UserBadges = {
  name?: string;
  crName?: string;
  createdAt?: number;
};

export enum ShopType {
  TentIndividual = 'TENT_INDIVIDUAL',
  TentCompany = 'TENT_COMPANY',
  BrokerIndividual = 'BROKER_INDIVIDUAL',
  UserIndividual = 'END_USER_INDIVIDUAL',
  isuzuOemDlr = 'ISUZU OEM DLR',
  other = 'OTHER',
}

export type UserListSearchFormDataTC = {
  searchField: string;
  searchKeyword: string;
  filteringStatus: string[];
  filteringGeneralWorkflow: string[];
  filteringRoleStateWorkflow: string[];
  filteringNationality: string[];
};

export type UserListSearchParamsTC = UserListSearchFormDataTC & AppTableConditions;

export type UserSearchFiltersTC = Partial<UserListSearchParamsTC> & {
  q?: string;
};

export interface UserTCResponse {
  contents: UserDataTC[];
}

export interface GetUserListTCResponse {
  contents: UserDataTC[];
  page?: {
    size?: number;
    totalElements?: number;
    totalPages?: number;
    number?: number;
  };
}

export type UserEditApplicationBasicInfoRequest = {
  displayName?: string;
  facebookUrl?: string;
  lineUrl?: string;
  websiteUrl?: string;
  instagramUrl?: string;
  youtubeUrl?: string;
  tiktokUrl?: string;
  isNotificationEmailSubScripted?: boolean;
  isNotificationEmailTpg?: boolean;
  userHashId: string;
};

export type UserEditApplicationPrivateInfoRequest = {
  userHashId: string;
  verifiedFullName?: string;
  verifiedCompanyName?: string;
  passportNumber?: string;
  companyTaxId?: string;
  nationalId?: string;
  province?: string;
  district?: string;
  address?: string;
  zipcode?: string;
  birthday?: string;
  nationality?: string;
  gender?: string | null;
  bookBankNumber: string;
  bookBankCompanyHashId?: string;
  selfieImage?: UserImage | null;
  bookBankImage?: UserImage | null;
  nidImage?: UserImage | null;
  isNewSellerCampaign: boolean;
};

export type UserEditApplicationShopInfoRequest = {
  shopLocationLatitude?: number;
  shopLocationLongitude?: number;
  shopName?: string;
  shopOtherTelephoneNumbers?: {
    tel?: string;
    name?: string;
  }[];
  shopProductType?: string[];
  shopDescription?: string;
  shopProvince?: string;
  shopDistrict?: string;
  shopAddress?: string;
  shopZipcode?: string;
  shopType?: ShopType;
  userHashId: string;
  shopLocationImage?: UserImage | null;
  shopHeroImage?: UserImage | null;
};

export type UserEditApplicationBillingInfoRequest = {
  userHashId: string;
  billingCompanyName?: string;
  billingAddress?: string;
  billingTel?: string;
  billingTaxId?: string;
};

export type UserEditApplicationAdditionalInfoRequest = {
  userHashId: string;
  sellerProductType?: string[];
  sellerType?: ShopType;
  badges?: UserBadges[];
  sellerYoutubeVdos?: UserVideo[];
  sellerRatingScore?: number;
};

export enum UserTCImage {
  profile = 'PROFILE_IMAGE',
  shopHeroImage = 'SHOP_HERO_IMAGE',
  shopLocationImage = 'SHOP_LOCATION_IMAGE',
  nid = 'NID',
  selfie = 'SELFIE',
  bookBank = 'BOOK_BANK',
  receipt = 'RECEIPT',
  other = 'OTHER',
  product = 'PRODUCT',
}

export type UserGeneralWorkflowFormData = {
  comment: string;
  selfieImage?: UserImage | null;
  bookBankImage?: UserImage | null;
  nidImage?: UserImage | null;
};

export enum InterviewLogContactBy {
  Email = 'EMAIL',
  Line = 'LINE',
  Facebook = 'FB',
  Chat = 'CHAT',
  Calling = 'CALLING',
  System = 'SYSTEM',
  LineQA = 'LINE_OA',
}

export enum InterviewLogContactType {
  Website = 'WEBSITE',
  Complain = 'COMPLAIN',
  PurchaseMembership = 'PURCHASE_MEMBERSHIP',
  PurchaseMemberCoin = 'PURCHASE_COIN',
  ProblemWithCoin = 'PROBLEM_WITH_COIN',
  NotificationEmailUnSubscription = 'NOTIFICATION_EMAIL_UN_SUBSCRIPTION',
  RemoveAccount = 'REMOVE_ACCOUNT',
  TentVisit = 'TENT_VISIT',
  SpecialOrder = 'SPECIAL_ORDER',
  RemarkV2 = 'REMARK_V2',
  UserStatus = 'USER_STATUS',
  RegisterSeller = 'REGISTER_SELLER',
  NationalIdDuplicated = 'DUPLICATE_NATIONAL_ID',
  EstimatePrice = 'ESTIMATE_PRICE',
  UnderstandT2hAreSeller = 'UNDERSTAND_T2H_ARE_SELLER',
  FinanceCheck = 'FINANCE_CHECK',
  Consignment = 'CONSIGNMENT',
  WantToBuy = 'WANT_TO_BUY',
  WantToSell = 'WANT_TO_SELL',
  WantToPawn = 'WANT_TO_PAWN',
  Other = 'OTHER',
}

export enum InterviewLogStatus {
  NotifyDeveloper = 'NOTIFY_DEVELOPER',
  NotifySale = 'NOTIFY_SALE',
  Done = 'DONE',
}

export type InterviewLogListSearchFormDataTC = {
  searchField: string;
  searchKeyword: string;
  filteringStatus: string;
  filteringContactBy: string;
  filteringContactType: string;
  filteringStartCreatedDate: number;
  filteringEndCreatedDate: number;
  filteringStartUpdatedDate: number;
  filteringEndUpdatedDate: number;
};

export type InterviewLogListSearchParamsTC = InterviewLogListSearchFormDataTC & AppTableConditions;

export type InterviewLogSearchFiltersTC = Partial<InterviewLogListSearchParamsTC> & {
  q?: string;
};
export interface InterviewLogData {
  hashId: string;
  comment: string;
  userHashId: string;
  userDisplayName: string;
  crHashId: string;
  crDisplayName: string;
  otherFiles?: UserImage[];
  createdAt: number;
  updatedAt: number;
  contactBy: string;
  contactType: string;
  status: string;
  userEmail: string;
  userMobilePhone: string;
  name?: string;
  email?: string;
  mobilePhone?: string;
}

export interface GetInterviewLogTCResponse {
  contents: InterviewLogData[];
  page?: {
    size?: number;
    totalElements?: number;
    totalPages?: number;
    number?: number;
  };
}

export type InterviewLogDataRequest = {
  comment: string;
  otherFiles?: UserImage[];
  userHashId: string;
  contactBy: string;
  contactType: string;
  status: string;
  name?: string;
  email?: string;
  mobilePhone?: string;
};

export interface OmiseCreditCardTCResponse {
  id: string;
  brand: string;
  lastDigits: number;
  bank: string;
  name: string;
  expirationYear: number;
  expirationMonth: number;
  isRemovable: boolean;
}

export type CoinUsageListSearchFormData = {
  searchKeyword: string;
  filteringUsageType: string[];
};

export type CoinUsageTransactionSearchParamsTC = CoinUsageListSearchFormData & AppTableConditions;
export type CoinUsageTransactionSearchFiltersTC = Partial<CoinUsageTransactionSearchParamsTC> & {
  q?: string;
};

export interface CoinUsageTransaction {
  hashId: string;
  user: UserDataTC;
  coinAmount: number;
  createdBy: UserDataTC;
  createdAt: string;
  usageType: string;
  boostDetail: {
    itemHashId: string;
    times: number;
    coinAmount: number;
  };
  totalBalance: number;
}

export interface GetCoinUsageTransactionTCResponse {
  contents: CoinUsageTransaction[];
  page?: {
    size?: number;
    totalElements?: number;
    totalPages?: number;
    number?: number;
  };
}

export type MembershipTransactionSearchParamsTC = AppTableConditions;
export type MembershipTransactionSearchFiltersTC = Partial<MembershipTransactionSearchParamsTC> & {
  q?: string;
};

export interface MembershipTransaction {
  hashId: string;
  user: UserDataTC;
  dateFrom: number;
  dateTo: number;
  roleTo: string;
  userOrderOnce: OrderOnceData;
  createdBy: UserDataTC;
  usageType: string;
  product: OrderOnceProductData;
}

export interface GetMembershipTransactionTCResponse {
  contents: MembershipTransaction[];
  page?: {
    size?: number;
    totalElements?: number;
    totalPages?: number;
    number?: number;
  };
}

export interface NoneUserInterviewLogData {
  hashId: string;
  comment: string;
  name: string;
  email: string;
  mobilePhone: string;
  crHashId: string;
  crDisplayName: string;
  otherFiles?: UserImage[];
  createdAt: number;
  updatedAt: number;
  contactBy: string;
  contactType: string;
  status: string;
}

export interface GetNoneUserInterviewLogTCResponse {
  contents: NoneUserInterviewLogData[];
  page?: {
    size?: number;
    totalElements?: number;
    totalPages?: number;
    number?: number;
  };
}

export enum EventType {
  SEEN = 'SEEN',
  CLICKED = 'CLICKED',
  CLOSED = 'CLOSED',
  DOWNLOADED = 'DOWNLOADED',
  SUBMIT = 'SUBMIT',
}

export type AccessLogPrivateInfoDataRequest = {
  remark: string;
  url: string;
  eventType: EventType;
};
