import LoginBonusList from './List';
import { RouteConfig } from '../../../../models/route.model';

const loginBonusRoutes = (path: string): RouteConfig[] => [
  {
    key: 'LOGIN_BONUS_LIST',
    path,
    component: LoginBonusList,
    exact: true,
    title: 'common:title.dailyLoginBonus',
  },
];

export default loginBonusRoutes;
