import React, { FC, useContext, useState } from 'react';

import { useTranslation } from 'react-i18next';
import useDeepCompareEffect from 'use-deep-compare-effect';

import CoinUsageListSearchForm from './CoinUsageListSearchForm';
import CoinUsageListTable from './CoinUsageListTable';
import { AppGlobalUiContext } from '../../../../../../context/AppGlobalUiContext';
import { SearchFilterContext } from '../../../../../../context/SearchFilterContext';
import { defaultRowsPerPage } from '../../../../../../helpers/constants';
import { AppTableConditions, CoinUsageTransaction, CoinUsageTransactionSearchParamsTC } from '../../../../../../models';
import { CoinUsageListSearchFormData } from '../../../../../../models/user.model';
import { getCoinUsageTransaction } from '../../../../../../services/v3/user-balance';
import AppDialog from '../../../../../ui/AppDialog';

const searchFilterContextKey = 'coin';

export type CoinTransactionDialogProps = {
  title: string;
  open: boolean;
  userHashId: string;
  isLoadingData: boolean;
  onClose: () => void;
  onDialogExited?: () => void;
};

const CoinTransactionDialog: FC<CoinTransactionDialogProps> = (props) => {
  const { open, title, userHashId, onClose, onDialogExited } = props;
  const { t } = useTranslation();

  const { showSnackbar } = useContext(AppGlobalUiContext);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [coinUsageData, setCoinUsageData] = useState<CoinUsageTransaction[]>([]);
  const [totalItems, setTotalItems] = useState<number>(0);

  const { currentFilters: ctxFilters, setFilters } = useContext(SearchFilterContext);
  const currentFilters: CoinUsageTransactionSearchParamsTC = ctxFilters
    ? ctxFilters[searchFilterContextKey]
    : undefined;

  const [searchFilters, setSearchFilters] = useState<CoinUsageListSearchFormData>({
    searchKeyword: currentFilters?.searchKeyword || '',
    filteringUsageType: currentFilters?.filteringUsageType || [],
  });

  const [tableConditions, setTableConditions] = useState<AppTableConditions>({
    page: currentFilters?.page || 0,
    rowsPerPage: currentFilters?.rowsPerPage || defaultRowsPerPage,
    sortColumn: currentFilters?.sortColumn || 'createdAt',
    sortDirection: currentFilters?.sortDirection || 'desc',
  });

  const getCurrentSearchParam = (): CoinUsageTransactionSearchParamsTC => ({
    page: tableConditions.page || 0,
    rowsPerPage: tableConditions.rowsPerPage || defaultRowsPerPage,
    sortColumn: tableConditions.sortColumn || 'createdAt',
    sortDirection: tableConditions.sortDirection || 'desc',
    searchKeyword: searchFilters.searchKeyword,
    filteringUsageType: searchFilters.filteringUsageType,
  });

  const getCoinUsage = async (isPageSubscribed = true): Promise<boolean> => {
    if (isPageSubscribed) {
      setIsLoadingData(true);

      const searchConditions = getCurrentSearchParam();
      setFilters(searchFilterContextKey, searchConditions);

      const result = await getCoinUsageTransaction(userHashId, searchConditions);

      setIsLoadingData(false);

      if (result.data) {
        setCoinUsageData(result.data.contents);
        setTotalItems(result.data?.page?.totalElements || 0);
        return Promise.resolve(true);
      }

      showSnackbar(t('user:coin.message.requestListFailed').toString(), 'error');
      return Promise.reject(result.error);
    }

    return false;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useDeepCompareEffect((): any => {
    let isSubscribed = true;

    getCoinUsage(isSubscribed);

    return () => (isSubscribed = false);
  }, [tableConditions, searchFilters]);

  const tableConditionsChangedHandler = (values: AppTableConditions) => {
    setTableConditions(values);
  };

  const onSearchFormSubmit = (filters: CoinUsageListSearchFormData) => {
    if (isLoadingData) {
      showSnackbar(t('common.message.pleaseWaitForDataToFinish').toString(), 'warning');
      return;
    }

    if (tableConditions.page !== 0) {
      tableConditions.page = 0;
    }

    setSearchFilters(filters);
  };

  return (
    <AppDialog
      open={open}
      title={title}
      onClose={onClose}
      okButtonText="common:button.close"
      okButtonColor="default"
      okButtonVariant="outlined"
      closeOnOkClicked
      dialogProps={{
        fullWidth: true,
        maxWidth: 'lg',
        TransitionProps: {
          onExited: () => {
            if (typeof onDialogExited === 'function') {
              onDialogExited();
            }
          },
        },
      }}>
      <CoinUsageListSearchForm
        isLoadingData={isLoadingData}
        currentFilters={searchFilters}
        onSearchFormSubmit={onSearchFormSubmit}
      />

      <CoinUsageListTable
        currentConditions={tableConditions}
        totalItems={totalItems}
        isLoadingData={isLoadingData}
        coinUsageList={coinUsageData}
        onTableConditionsChanged={tableConditionsChangedHandler}
      />
    </AppDialog>
  );
};

export default CoinTransactionDialog;
