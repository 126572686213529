import OrderApproveList from './List';
import { RouteConfig } from '../../../models/route.model';

const orderApproveRoutes = (path: string): RouteConfig[] => [
  {
    key: 'ORDER_APPROVE_LIST',
    path,
    component: OrderApproveList,
    exact: true,
    title: 'common:title.orderApprove',
  },
];

export default orderApproveRoutes;
