import React, { memo, useContext } from 'react';

import { Box, Tooltip, Typography } from '@material-ui/core';
import { indigo } from '@material-ui/core/colors';
import LaunchRoundedIcon from '@material-ui/icons/LaunchRounded';
import clsx from 'clsx';
import copy from 'copy-to-clipboard';
import { TFunction } from 'react-i18next';

import { AppGlobalUiContext } from '../../../../../context/AppGlobalUiContext';
import { generalInfoStyles, usernameTooltipStyles } from '../../../../../helpers/constants/user-detail-styles';

const SocialLinkBlock = memo(
  ({
    url,
    label,
    fullUrl,
    additionalClasses,
  }: {
    url?: string;
    label: string | ReturnType<TFunction>;
    fullUrl?: string;
    additionalClasses?: string[];
  }) => {
    const genInfoStyles = generalInfoStyles();
    const uTooltipStyles = usernameTooltipStyles();
    const { showSnackbar, closeSnackbar } = useContext(AppGlobalUiContext);

    return (
      <Box className={clsx(genInfoStyles.shortInfoContainer, ...(additionalClasses || []))}>
        {!url && (
          <Typography className={genInfoStyles.commonInfo} component="div" data-title={label}>
            -
          </Typography>
        )}

        {!!url && (
          <>
            <Tooltip title="คัดลอก" aria-label="คัดลอก" placement="top" arrow classes={uTooltipStyles}>
              <Typography
                className={clsx(genInfoStyles.commonInfo, 'clickable')}
                component="div"
                data-title={label}
                onClick={() => {
                  closeSnackbar();
                  setTimeout(() => {
                    copy(url);
                    showSnackbar('คัดลอกลิงก์แล้ว', 'success');
                  }, 300);
                }}>
                {url ?? '-'}
              </Typography>
            </Tooltip>
            <Tooltip title="เปิดลิงก์ในแท็บใหม่" aria-label="เปิดลิงก์ในแท็บใหม่" placement="top" arrow>
              <a href={fullUrl} target="_blank" rel="noreferrer" className="open-link">
                <LaunchRoundedIcon fontSize="small" style={{ color: indigo[500] }} />
              </a>
            </Tooltip>
          </>
        )}
      </Box>
    );
  },
);

export default SocialLinkBlock;
