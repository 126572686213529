import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';

import SocialLinkBlock from './SocialLinkBlock';
import { generalInfoStyles } from '../../../../../helpers/constants/user-detail-styles';
import { getFacebookLink, getLineLink } from '../../../../../helpers/utils';
import { UserDataTC } from '../../../../../models/user.model';

type SocialMediaInfoProps = {
  currentUser: UserDataTC;
};

const SocialMediaInfo: FC<SocialMediaInfoProps> = (props: SocialMediaInfoProps) => {
  const { currentUser } = props;
  const genInfoStyles = generalInfoStyles();
  const { t } = useTranslation();

  return (
    <div className={genInfoStyles.socialLinkContainer}>
      <SocialLinkBlock
        url={currentUser.facebookUrl}
        fullUrl={getFacebookLink(currentUser.facebookUrl)}
        label={t('user:common.fields.facebookUrl')}
        additionalClasses={['full-block']}
      />
      <SocialLinkBlock
        url={currentUser.lineUrl}
        fullUrl={getLineLink(currentUser.lineUrl)}
        label={t('user:common.fields.lineUrl')}
        additionalClasses={['full-block']}
      />
      <SocialLinkBlock
        url={currentUser.websiteUrl}
        fullUrl={currentUser.websiteUrl}
        label={t('user:common.fields.websiteUrl')}
        additionalClasses={['full-block']}
      />
      <SocialLinkBlock
        url={currentUser.instagramUrl}
        fullUrl={currentUser.instagramUrl}
        label={t('user:common.fields.instagramUrl')}
        additionalClasses={['full-block']}
      />
      <SocialLinkBlock
        url={currentUser.youtubeUrl}
        fullUrl={currentUser.youtubeUrl}
        label={t('user:common.fields.youtubeUrl')}
        additionalClasses={['full-block']}
      />
      <SocialLinkBlock
        url={currentUser.tiktokUrl}
        fullUrl={currentUser.tiktokUrl}
        label={t('user:common.fields.tiktokUrl')}
        additionalClasses={['full-block']}
      />
    </div>
  );
};

export default SocialMediaInfo;
