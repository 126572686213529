/* eslint-disable max-len */
import React, { FC, useState } from 'react';

import {
  OutlinedInput,
  Grid,
  Box,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  InputLabel,
  Typography,
  Avatar,
  makeStyles,
  Chip,
  InputAdornment,
} from '@material-ui/core';
import clsx from 'clsx';
import { Controller, UseControllerProps, useForm, ValidateResult } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import usePrivilege from '../../../../hooks/usePrivilege';
import { BankMaster, ReverifyFormData, SellerApplication, SellerApplicationFormData } from '../../../../models';
import { checkBookBankNumberAvailable, checkNationalIdAvailable } from '../../../../services/user';
import useTypographyStyles from '../../../../theme/typography.style';
import AppButton from '../../../ui/AppButton';
import AppDialog from '../../../ui/AppDialog';
import AppFormControl from '../../../ui/AppFormControl';
import MasterDataDropdown from '../../common/MasterDataDropdown';

export type SellerApplicationDetailFormProps = {
  sellerApplication: SellerApplication;
  rules?: { [x: string]: UseControllerProps['rules'] };
  onFormSubmit: (data: SellerApplicationFormData) => void;
  onReverifySubmit?: (data: ReverifyFormData) => void;
};

const useStyles = makeStyles((theme) => ({
  bankLogo: {
    width: '28px',
    height: '28px',
    marginRight: '8px',
  },
  bankSelectorRoot: {
    display: 'flex',
    alignItems: 'center',
  },
  bankItemName: {
    flexGrow: 1,
    maxWidth: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  confirmDialogBox: {
    width: '100%',
    maxWidth: '1000px',
    [theme.breakpoints.up('md')]: {
      minWidth: '400px',
    },
  },
  rejectSelectionColor: {
    color: theme.palette.error.main,
  },
  pendingSelectionColor: {
    color: theme.palette.warning.main,
  },
  rejectReasonChip: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
      cursor: 'pointer',
    },
  },
  numberInput: {
    MozAppearance: 'none',
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
}));

const idNumberValidator = (value: string): ValidateResult => {
  if (!/\d{13}/g.test(value)) {
    return 'validation:invalidFieldFormat';
  }
  return true;
};

const idNumberAlphabetValidator = (value: string): ValidateResult => {
  if (!/^[a-zA-Z0-9]*$/g.test(value)) {
    return 'validation:invalidFieldFormat';
  }

  return true;
};

const SellerApplicationDetailForm: FC<SellerApplicationDetailFormProps> = (props) => {
  const { sellerApplication, onFormSubmit } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const typoClasses = useTypographyStyles();
  const userVerifiedNameArray = sellerApplication.user?.verifiedFullName?.split(/\s/g) || [];
  const {
    handleSubmit,
    getValues,
    setValue,
    control,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm<SellerApplicationFormData>({
    mode: 'onChange',
    defaultValues: {
      nationalId: sellerApplication.user.nationalId || '',
      passportNo: sellerApplication.user.passportNo || '',
      verifiedFirstName: userVerifiedNameArray.length ? userVerifiedNameArray[0] : '',
      verifiedLastName: userVerifiedNameArray.length ? userVerifiedNameArray[1] : '',
      verifiedAddress: sellerApplication.user.verifiedAddress || '',
      bankHashId: sellerApplication.bankHashId || '',
      bankAccNo: sellerApplication.bankAccNo || '',
      status: sellerApplication.status || '',
      rejectReason: '',
    },
  });
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);
  const [isCheckNationalIdDialogOpen, setIsCheckNationalIdDialogOpen] = useState<boolean>(false);
  const [handleNationalId, setHandleNationalId] = useState<string | undefined>(undefined);
  const [nationalIdAvailable, setNationalIdAvailable] = useState<boolean>(false);

  const [handleBankAccNo, setHandleBankAccNo] = useState<string | undefined>(undefined);
  const [isCheckBankAccNoDialogOpen, setIsCheckBankAccNoDialogOpen] = useState<boolean>(false);
  const [bankAccNoAvailable, setBankAccNoAvailable] = useState<boolean>(false);

  const isDataRequired = watch('status') === 'approved';
  const isRejectReasonRequired = watch('status') === 'rejected';
  const { canPerform } = usePrivilege();
  const allowEdit = sellerApplication.status === 'submitted' || canPerform('sellerApplication', 'updateAfterSubmitted');
  const allowUpdate =
    sellerApplication.status === 'approved' && canPerform('sellerApplication', 'updateAfterSubmitted');
  const [identityType, setIdentityType] = useState<'nationalId' | 'passportNo'>(
    sellerApplication.user.passportNo ? 'passportNo' : 'nationalId',
  );
  const allowSave = ['approved', 'rejected', 'pending'].includes(watch('status') as string);

  const onSaveClick = () => {
    const removedSpaceFirstName = (getValues('verifiedFirstName') || '').trim();
    const removedSpaceLastName = (getValues('verifiedLastName') || '').trim();

    setValue('verifiedFirstName', removedSpaceFirstName);
    setValue('verifiedLastName', removedSpaceLastName);

    setIsConfirmDialogOpen(true);
  };

  const onConfirmationSaveClick = () => {
    setIsConfirmDialogOpen(false);
    if (typeof onFormSubmit === 'function') {
      onFormSubmit(getValues());
    }
  };

  const onConfirmationCancelClick = () => {
    setIsConfirmDialogOpen(false);
  };

  const ConfirmationDataBlock = ({
    fieldName,
    label,
  }: {
    fieldName: keyof SellerApplicationFormData;
    label: string;
  }) => (
    <>
      <Typography variant="subtitle2" className={typoClasses.textWeightBold}>
        {t(label)}
      </Typography>
      <Typography>{getValues(fieldName)}</Typography>
    </>
  );

  const setRejectReason = (reason: string) => {
    setValue('rejectReason', reason, { shouldValidate: true });
  };

  const getBankLogoUrl = (url: string): string =>
    `${(process.env.REACT_APP_T2H_FRONTEND_URL || process.env.REACT_APP_DOMAIN_URL) as string}/${url.replace(
      /\//,
      '',
    )}`;

  const checkNationalIdNotUsed = async (nationalId: string) => {
    const result = await checkNationalIdAvailable(nationalId);
    setNationalIdAvailable(!!result);
    setHandleNationalId(nationalId);
    setIsCheckNationalIdDialogOpen(true);
  };

  const checkBankAccNoDuplicate = async (bankAccNo: string) => {
    const result = await checkBookBankNumberAvailable(bankAccNo);
    setBankAccNoAvailable(!!result);
    setHandleBankAccNo(bankAccNo);
    setIsCheckBankAccNoDialogOpen(true);
  };

  return (
    <>
      <Box p={3}>
        <form onSubmit={handleSubmit(onSaveClick)}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Box mb={1}>
                <Typography component="h6" variant="body1" className={typoClasses.textWeightBold}>
                  {t('seller-applications:details.label.applicationStatus')}
                </Typography>
              </Box>
              <Controller
                control={control}
                name="status"
                rules={{
                  required: true,
                }}
                render={({ field }) => {
                  const onStatusChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                    field.onChange(event, checked);

                    if (field.value !== 'pending') {
                      setValue('rejectReason', '', { shouldValidate: true });
                    }
                    clearErrors();
                  };

                  return (
                    <RadioGroup row value={field.value}>
                      <FormControlLabel
                        value="approved"
                        control={
                          <Radio name={field.name} onChange={onStatusChange} color="primary" disabled={!allowEdit} />
                        }
                        label={t('seller-applications:common.statuses.approved').toString()}
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="rejected"
                        control={
                          <Radio name={field.name} onChange={onStatusChange} color="primary" disabled={!allowEdit} />
                        }
                        label={t('seller-applications:common.statuses.rejected').toString()}
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="pending"
                        control={
                          <Radio name={field.name} onChange={onStatusChange} color="primary" disabled={!allowEdit} />
                        }
                        label={t('seller-applications:common.statuses.pending').toString()}
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  );
                }}
              />
              {!!errors.status && (
                <FormHelperText error>
                  {t('validation:requiredFieldAlt', {
                    fieldName: t('seller-applications:details.label.applicationStatus').toString(),
                  })}
                </FormHelperText>
              )}
            </Grid>
            {isRejectReasonRequired && allowEdit && (
              <Grid item xs={12}>
                <Box mb={2}>
                  <Controller
                    control={control}
                    name="rejectReason"
                    rules={{
                      required: isRejectReasonRequired,
                    }}
                    render={({ field }) => (
                      <AppFormControl error={!!errors.rejectReason}>
                        <TextField
                          {...field}
                          id="seller-app-reject-reason"
                          label={t('seller-applications:details.label.rejectReason').toString()}
                          variant="outlined"
                          fullWidth
                          error={!!errors.rejectReason}
                        />
                        {!!errors.rejectReason && (
                          <FormHelperText error>
                            {t('validation:requiredFieldAlt', {
                              fieldName: t('seller-applications:details.label.rejectReason').toString(),
                            })}
                          </FormHelperText>
                        )}
                      </AppFormControl>
                    )}
                  />
                  <Chip
                    label={t('seller-applications:common.rejectReason.invalidDocument').toString()}
                    variant="outlined"
                    className={classes.rejectReasonChip}
                    clickable
                    onClick={() =>
                      setRejectReason(t('seller-applications:common.rejectReason.invalidDocument').toString())
                    }
                  />
                  <Chip
                    label={t('seller-applications:common.rejectReason.invalidInformation').toString()}
                    variant="outlined"
                    className={classes.rejectReasonChip}
                    onClick={() =>
                      setRejectReason(t('seller-applications:common.rejectReason.invalidInformation').toString())
                    }
                  />
                </Box>
              </Grid>
            )}
            {isDataRequired && (
              <>
                <Box flexBasis="100%" />
                <Grid item xs={12} md={6}>
                  <Controller
                    control={control}
                    name="verifiedFirstName"
                    rules={{
                      required: isDataRequired,
                    }}
                    defaultValue=""
                    render={({ field }) => (
                      <AppFormControl error={!!errors.verifiedFirstName}>
                        <TextField
                          {...field}
                          id="seller-app-first-name"
                          label={t('seller-applications:details.label.verifiedFirstName').toString()}
                          variant="outlined"
                          fullWidth
                          error={!!errors.verifiedFirstName}
                          disabled={!allowUpdate && !allowUpdate && (!allowEdit || !isDataRequired)}
                          autoComplete="off"
                        />
                        {!!errors.verifiedFirstName && (
                          <FormHelperText error>
                            {t('validation:requiredField', {
                              fieldName: t('seller-applications:details.label.verifiedFirstName').toString(),
                            })}
                          </FormHelperText>
                        )}
                      </AppFormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    control={control}
                    name="verifiedLastName"
                    rules={{
                      required: isDataRequired,
                    }}
                    defaultValue=""
                    render={({ field }) => (
                      <AppFormControl error={!!errors.verifiedLastName}>
                        <TextField
                          {...field}
                          id="seller-app-last-name"
                          label={t('seller-applications:details.label.verifiedLastName').toString()}
                          variant="outlined"
                          fullWidth
                          error={!!errors.verifiedLastName}
                          disabled={!allowUpdate && (!allowEdit || !isDataRequired)}
                        />
                        {!!errors.verifiedLastName && (
                          <FormHelperText error>
                            {t('validation:requiredField', {
                              fieldName: t('seller-applications:details.label.verifiedLastName').toString(),
                            })}
                          </FormHelperText>
                        )}
                      </AppFormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RadioGroup row defaultValue="nationalId" value={identityType}>
                    <FormControlLabel
                      value="nationalId"
                      control={
                        <Radio
                          onChange={() => setIdentityType('nationalId')}
                          color="primary"
                          disabled={!allowUpdate && (!allowEdit || !isDataRequired)}
                        />
                      }
                      label={t('seller-applications:details.label.nationalIdCard').toString()}
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="passportNo"
                      control={
                        <Radio
                          onChange={() => setIdentityType('passportNo')}
                          color="primary"
                          disabled={!allowUpdate && (!allowEdit || !isDataRequired)}
                        />
                      }
                      label={t('seller-applications:details.label.passport').toString()}
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </Grid>
                <Grid item xs={12}>
                  {identityType === 'nationalId' && (
                    <Controller
                      control={control}
                      name="nationalId"
                      rules={{
                        required: true,
                        maxLength: 13,
                        validate: idNumberValidator,
                      }}
                      render={({ field }) => (
                        <AppFormControl boxProps={{ mb: 2 }} error={!!errors.nationalId}>
                          <InputLabel htmlFor="seller-app-national-id">
                            {t('seller-applications:details.label.nationalId')}
                          </InputLabel>
                          <OutlinedInput
                            {...field}
                            id="seller-app-national-id"
                            type="number"
                            fullWidth
                            error={!!errors.nationalId}
                            inputProps={{
                              className: classes.numberInput,
                              onKeyDown: (e) => {
                                const isNumberKey = /[0-9]/g.test(e.key);
                                if (field.value.length < 13) {
                                  return true;
                                }

                                if (!isNumberKey) {
                                  return true;
                                }

                                e.preventDefault();
                                return false;
                              },
                            }}
                            endAdornment={
                              <InputAdornment position="end">
                                <AppButton
                                  onClick={() => checkNationalIdNotUsed(field.value)}
                                  size="small"
                                  disabled={!allowUpdate && (!allowEdit || !isDataRequired)}
                                  variant="outlined">
                                  {t('seller-applications:details.button.check')}
                                </AppButton>
                              </InputAdornment>
                            }
                            disabled={!allowUpdate && (!allowEdit || !isDataRequired)}
                          />
                          {!!errors.nationalId && (
                            <FormHelperText error>
                              {errors.nationalId.type === 'required' &&
                                t('validation:requiredField', {
                                  fieldName: t('seller-applications:details.label.nationalIdCard').toString(),
                                })}
                              {(errors.nationalId.type === 'validate' || errors.nationalId.type === 'maxLength') &&
                                t('validation:invalidFieldFormat', {
                                  fieldName: t('seller-applications:details.label.nationalId').toString(),
                                  suggestion: t('seller-applications:error.detail.nationalIdFormat').toString(),
                                })}
                            </FormHelperText>
                          )}
                        </AppFormControl>
                      )}
                    />
                  )}
                  {identityType === 'passportNo' && (
                    <Controller
                      control={control}
                      name="passportNo"
                      rules={{
                        validate: idNumberAlphabetValidator,
                      }}
                      render={({ field }) => (
                        <AppFormControl boxProps={{ mb: 2 }} error={!!errors.passportNo}>
                          <InputLabel htmlFor="seller-app-passport-no">
                            {t('common:message.pleaseSpecifyWithParam', {
                              fieldName: t('seller-applications:details.label.passportNo'),
                            })}
                          </InputLabel>
                          <OutlinedInput
                            {...field}
                            id="seller-app-passport-no"
                            fullWidth
                            error={!!errors.passportNo}
                            disabled={!allowUpdate && (!allowEdit || !isDataRequired)}
                          />
                          {!!errors.passportNo && (
                            <FormHelperText error>{!!errors.passportNo && errors.passportNo.message}</FormHelperText>
                          )}
                        </AppFormControl>
                      )}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="verifiedAddress"
                    rules={{
                      required: isDataRequired,
                    }}
                    render={({ field }) => (
                      <AppFormControl error={!!errors.verifiedAddress}>
                        <InputLabel htmlFor="seller-app-verified-address">
                          {t('seller-applications:details.label.verifiedAddress')}
                        </InputLabel>
                        <OutlinedInput
                          id="seller-app-verified-address"
                          {...field}
                          multiline
                          rows={4}
                          error={!!errors.verifiedAddress}
                          disabled={!allowUpdate && (!allowEdit || !isDataRequired)}
                        />
                        {!!errors.verifiedAddress && (
                          <FormHelperText error>
                            {t('validation:requiredField', {
                              fieldName: t('seller-applications:details.label.verifiedAddress').toString(),
                            })}
                          </FormHelperText>
                        )}
                      </AppFormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <Controller
                    control={control}
                    name="bankHashId"
                    rules={{
                      required: isDataRequired,
                    }}
                    render={({ field }) => (
                      <AppFormControl error={!!errors.bankHashId}>
                        <MasterDataDropdown
                          dataKey="bankMaster"
                          itemValuePropName="hashId"
                          itemLabelPropName="thaiName"
                          inputLabel={t('seller-applications:details.label.bankHashId').toString()}
                          value={field.value}
                          onChange={field.onChange}
                          formControlProps={{ boxProps: { mt: 2 } }}
                          classes={{ root: classes.bankSelectorRoot }}
                          error={!!errors.bankHashId}
                          disabled={!allowUpdate && (!allowEdit || !isDataRequired)}
                          menuItemComponent={(item: BankMaster) => (
                            <>
                              <Avatar
                                alt={item.thaiName}
                                src={getBankLogoUrl(item.logo)}
                                className={classes.bankLogo}
                              />
                              <span className={classes.bankItemName}>{item.thaiName}</span>
                            </>
                          )}
                        />
                        {!!errors.bankHashId && (
                          <FormHelperText error>
                            {t('validation:requiredFieldAlt', {
                              fieldName: t('seller-applications:details.label.bankHashId').toString(),
                            })}
                          </FormHelperText>
                        )}
                      </AppFormControl>
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={12} lg={6}>
                  <Controller
                    control={control}
                    name="bankAccNo"
                    rules={{
                      required: isDataRequired,
                      minLength: 10,
                      maxLength: 20,
                    }}
                    defaultValue=""
                    render={({ field }) => (
                      <AppFormControl boxProps={{ mt: 2 }} error={!!errors.bankAccNo}>
                        <InputLabel htmlFor="seller-app-bank-acc-no">
                          {t('seller-applications:details.label.bankAccNo').toString()}
                        </InputLabel>
                        <OutlinedInput
                          {...field}
                          id="seller-app-bank-acc-no"
                          type="number"
                          fullWidth
                          error={!!errors.bankAccNo}
                          inputProps={{
                            className: classes.numberInput,
                            onKeyDown: (e) => {
                              const isNumberKey = /[0-9]/g.test(e.key);
                              if (field.value.length < 20) {
                                return true;
                              }

                              if (!isNumberKey) {
                                return true;
                              }

                              e.preventDefault();
                              return false;
                            },
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              <AppButton
                                onClick={() => checkBankAccNoDuplicate(field.value)}
                                size="small"
                                disabled={!allowUpdate && (!allowEdit || !isDataRequired)}
                                variant="outlined">
                                {t('seller-applications:details.button.check')}
                              </AppButton>
                            </InputAdornment>
                          }
                          disabled={!allowUpdate && (!allowEdit || !isDataRequired)}
                        />
                        {!!errors.bankAccNo && (
                          <FormHelperText error>
                            {errors.bankAccNo.type === 'required' &&
                              t('validation:requiredField', {
                                fieldName: t('seller-applications:details.label.bankAccNo').toString(),
                              })}
                            {errors.bankAccNo.type === 'minLength' &&
                              t('validation:invalidMinLengthNumberField', {
                                fieldName: t('seller-applications:details.label.bankAccNo').toString(),
                                minLength: 10,
                              })}
                          </FormHelperText>
                        )}
                      </AppFormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box my={2}>
                    <AppFormControl>
                      <TextField
                        id="seller-app-shop-address"
                        value={sellerApplication.address}
                        label={t('seller-applications:details.label.shopAddress').toString()}
                        variant="outlined"
                        fullWidth
                        disabled
                        multiline
                        rows={4}
                      />
                    </AppFormControl>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box mb={2}>
                    <AppFormControl>
                      <TextField
                        id="seller-app-shop-province"
                        value={sellerApplication.province?.thaiName || '-'}
                        label={t('seller-applications:details.label.province').toString()}
                        variant="outlined"
                        fullWidth
                        disabled
                      />
                    </AppFormControl>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box mb={2}>
                    <AppFormControl>
                      <TextField
                        id="seller-app-shop-address"
                        value={sellerApplication.user.zipcode}
                        label={t('seller-applications:details.label.zipcode').toString()}
                        variant="outlined"
                        fullWidth
                        disabled
                      />
                    </AppFormControl>
                  </Box>
                </Grid>
              </>
            )}

            {(allowEdit || allowUpdate) && (
              <>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="flex-end">
                    <AppButton type="submit" color="primary" disabled={!allowSave}>
                      {t('common:button.save')}
                    </AppButton>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
        </form>
      </Box>
      <AppDialog
        open={isCheckNationalIdDialogOpen}
        title="common:dialog.title.info"
        okButtonText="common:button.close"
        okButtonColor="primary"
        okButtonVariant="outlined"
        onOkClick={() => setIsCheckNationalIdDialogOpen(false)}>
        <Typography>
          {t(
            `seller-applications:common.message.${
              nationalIdAvailable ? 'nationalIdCardUsed' : 'nationalIdCardNotUsed'
            }`,
            {
              fieldName: handleNationalId,
            },
          )}
        </Typography>
      </AppDialog>

      <AppDialog
        title={t('common:dialog.title.confirm').toString()}
        open={isConfirmDialogOpen}
        onOkClick={onConfirmationSaveClick}
        onCancelClick={onConfirmationCancelClick}
        cancelButtonText={t('common:button.cancel').toString()}
        okButtonText={t('common:button.save').toString()}
        okButtonColor="primary">
        <Box className={classes.confirmDialogBox}>
          <Box mb={3}>
            <Typography>{t('seller-applications:common.message.savingDataConfirmation')}</Typography>
          </Box>
          <Grid container spacing={2}>
            {getValues('status') === 'approved' && (
              <>
                <Grid item xs={12} md={6}>
                  <ConfirmationDataBlock
                    fieldName="verifiedFirstName"
                    label="seller-applications:details.label.verifiedFirstName"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ConfirmationDataBlock
                    fieldName="verifiedLastName"
                    label="seller-applications:details.label.verifiedLastName"
                  />
                </Grid>
                <Grid item xs={12}>
                  <ConfirmationDataBlock
                    fieldName="verifiedAddress"
                    label="seller-applications:details.label.verifiedAddress"
                  />
                </Grid>
                <Grid item xs={12}>
                  {identityType === 'nationalId' && (
                    <ConfirmationDataBlock
                      fieldName="nationalId"
                      label="seller-applications:details.label.nationalId"
                    />
                  )}
                  {identityType === 'passportNo' && (
                    <ConfirmationDataBlock
                      fieldName="passportNo"
                      label="seller-applications:details.label.passportNo"
                    />
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle2" className={typoClasses.textWeightBold}>
                    {t('seller-applications:details.label.bankHashId')}
                  </Typography>
                  <MasterDataDropdown
                    dataKey="bankMaster"
                    itemValuePropName="hashId"
                    itemLabelPropName="thaiName"
                    value={getValues('bankHashId')}
                    formControlProps={{ boxProps: { mt: 2 } }}
                    classes={{ root: classes.bankSelectorRoot }}
                    renderAsText
                    menuItemComponent={(item: BankMaster) => (
                      <>
                        <Avatar alt={item.thaiName} src={item.logo} className={classes.bankLogo} />
                        <span className={classes.bankItemName}>{item.thaiName}</span>
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ConfirmationDataBlock fieldName="bankAccNo" label="seller-applications:details.label.bankAccNo" />
                </Grid>
              </>
            )}
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2" className={typoClasses.textWeightBold}>
                {t('seller-applications:details.label.applicationStatusConfirm')}
              </Typography>
              <Typography
                className={clsx({
                  [classes.pendingSelectionColor]: getValues('status') === 'pending',
                  [classes.rejectSelectionColor]: getValues('status') === 'rejected',
                })}>
                {t(`seller-applications:common.statuses.${getValues('status')}`)}
              </Typography>
            </Grid>
            {getValues('status') === 'rejected' && (
              <Grid item xs={12}>
                <ConfirmationDataBlock
                  fieldName="rejectReason"
                  label="seller-applications:details.label.rejectReason"
                />
              </Grid>
            )}
          </Grid>
        </Box>
      </AppDialog>

      <AppDialog
        open={isCheckBankAccNoDialogOpen}
        title="common:dialog.title.info"
        okButtonText="common:button.close"
        okButtonColor="primary"
        okButtonVariant="outlined"
        onOkClick={() => setIsCheckBankAccNoDialogOpen(false)}>
        <Typography>
          {t(`seller-applications:common.message.${bankAccNoAvailable ? 'bankAccNoUsed' : 'bankAccNoNotUsed'}`, {
            fieldName: handleBankAccNo,
          })}
        </Typography>
      </AppDialog>
    </>
  );
};

export default SellerApplicationDetailForm;
