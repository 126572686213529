import React, { FC, useCallback, useState } from 'react';

import { Box, Divider, Grid, makeStyles, TextField, Tooltip, Typography } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { getSnsAppUrl, getT2HSiteUrl } from '../../../../helpers/utils';
import { ReportedItem, ReportStatus } from '../../../../models';
import AppButton from '../../../ui/AppButton';
import AppDatetimeText from '../../../ui/AppDatetimeText';
import AppDialog from '../../../ui/AppDialog';
import AppFormControl from '../../../ui/AppFormControl';
import ReportedStatusBadge from '../../sns/ReportedStatusBadge';
import DataDisplayBlock from '../../v3/users/UserDetail/DataDisplayBlock';
import SnsSharedItemThumbnail from '../SnsSharedItemThumbnail';

export type SnsItemPreviewDialogProps = {
  previewItem: ReportedItem;
  title: string;
  open: boolean;
  onClose: () => void;
  onDialogExited?: () => void;
  onCloseClick?: () => void;
  onApproveClick?: (previewItem: ReportedItem, remark?: string) => void;
  onRejectClick?: (previewItem: ReportedItem, remark?: string) => void;
  onMarkAsDuplicatedClick?: (previewItem: ReportedItem, remark?: string) => void;
};

const useStyles = makeStyles((theme) => ({
  previewInfoHeadingText: {
    fontWeight: 700,
    fontSize: '14px',
  },
  previewImageContainer: {
    border: `solid 1px ${theme.palette.grey[400]}`,
    borderRadius: 6,
    overflow: 'hidden',
  },
  previewImage: {
    maxWidth: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  buttonGroups: {
    '& > *': {
      margin: `0 ${theme.spacing(1)}px`,
    },
  },
}));

const getMediaType = (url: string): 'image' | 'video' | 'other' => {
  const fileExtension = url.split(/\./).pop() || '';
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'bmp'];
  const mediaExtensions = ['mp4', 'mp3', 'mov', 'avi'];
  const isImageFile = imageExtensions.includes(fileExtension);
  const isMediaFile = mediaExtensions.includes(fileExtension);

  if (isImageFile) {
    return 'image';
  }

  if (isMediaFile) {
    return 'video';
  }

  return 'other';
};

const SnsItemPreviewDialog: FC<SnsItemPreviewDialogProps> = (props) => {
  const classes = useStyles();
  const {
    previewItem,
    open,
    title,
    onClose,
    onDialogExited,
    onCloseClick,
    onApproveClick,
    onRejectClick,
    onMarkAsDuplicatedClick,
  } = props;
  const { t } = useTranslation();
  const item = previewItem.reportItem;
  const [remark, setRemark] = useState<string>(previewItem.remark || '');

  const openUserEditPage = useCallback(() => {
    window.open(getSnsAppUrl(`/post/${item.replyToId || item.id}`), '_blank');
  }, [item.id, item.replyToId]);

  const approveReport = useCallback(() => {
    if (typeof onApproveClick === 'function') {
      onApproveClick(previewItem, remark);
    }
  }, [onApproveClick, previewItem, remark]);

  const rejectReport = useCallback(() => {
    if (typeof onRejectClick === 'function') {
      onRejectClick(previewItem, remark);
    }
  }, [onRejectClick, previewItem, remark]);

  const markReportAsDuplicated = useCallback(() => {
    if (typeof onMarkAsDuplicatedClick === 'function') {
      onMarkAsDuplicatedClick(previewItem, remark);
    }
  }, [onMarkAsDuplicatedClick, previewItem, remark]);

  return (
    <>
      <AppDialog
        open={open}
        title={title}
        onClose={onClose}
        okButtonText="common:button.close"
        okButtonColor="default"
        okButtonVariant="outlined"
        closeOnOkClicked
        noActionBar
        dialogProps={{
          fullWidth: true,
          maxWidth: 'md',
          TransitionProps: {
            onExited: () => {
              if (typeof onDialogExited === 'function') {
                onDialogExited();
              }
            },
          },
        }}>
        <Box>
          <Box display="flex" justifyContent="space-between">
            <ReportedStatusBadge status={previewItem.reportStatus} />

            <AppButton
              color="primary"
              variant="outlined"
              startIcon={<OpenInNewIcon fontSize="small" />}
              onClick={() => openUserEditPage()}>
              {t('sns:common.buttons.openSnsPage')}
            </AppButton>
          </Box>
          <Box my={1}>
            <Divider />
          </Box>

          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={4}>
              <DataDisplayBlock label={t('sns:list.reportedItem.columns.reportType')}>
                {t(`sns:common.reportType.${previewItem.reportType.toLowerCase()}`)}
              </DataDisplayBlock>
            </Grid>
            <Grid item xs={12} md={6} lg={8}>
              <DataDisplayBlock label={t('sns:preview.reportMessage')}>
                {previewItem.reportReason || '-'}
              </DataDisplayBlock>
            </Grid>
          </Grid>
          <Box mb={1}>
            <Divider />
          </Box>

          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={4}>
              <DataDisplayBlock label={t('sns:preview.refPostId')} allowCopy>
                {item.replyToId || item.id}
              </DataDisplayBlock>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <DataDisplayBlock label={t('sns:preview.refCommentId')} allowCopy>
                {item.postType ? '-' : item.id}
              </DataDisplayBlock>
            </Grid>
            <Grid item sm={12} md={4}>
              <DataDisplayBlock label={t('sns:preview.createdAt')}>
                <AppDatetimeText value={dayjs(item.createdAt)} withTime style={{ fontWeight: 'bold' }} />
              </DataDisplayBlock>
            </Grid>
            {item.author.userHashId && (
              <Grid item xs={12} sm={4}>
                <DataDisplayBlock
                  label={t('sns:preview.authorUserHashId')}
                  allowCopy
                  copySuccessText="คัดลอก Hash ID ผู้ใช้แล้ว"
                  newTabLink={getT2HSiteUrl(`/u/${item.author.userHashId}`)}
                  newTabTooltipText="เปิดหน้าผู้ใช้บนเว็บไซต์ในแท็บใหม่">
                  {item.author.userHashId}
                </DataDisplayBlock>
              </Grid>
            )}

            <Grid item xs={12} sm={8}>
              <DataDisplayBlock
                label={t('sns:preview.authorDisplayName')}
                trailingContent={
                  item.author.isVerified || item.author.isMobileVerified ? (
                    <Tooltip title="ยืนยันแล้ว" aria-label="ยืนยันแล้ว" placement="top" arrow>
                      <CheckCircleRoundedIcon
                        fontSize="small"
                        style={{ color: green[500], marginLeft: '4px', transform: 'translateY(12px)' }}
                      />
                    </Tooltip>
                  ) : null
                }>
                {item.author.displayName}
              </DataDisplayBlock>
            </Grid>
            <Grid item xs={12}>
              <Box my={1}>
                <Typography component="span" className={classes.previewInfoHeadingText}>
                  {t('sns:preview.content')}
                </Typography>
                <Box mt={1} style={{ wordBreak: 'break-all' }}>
                  {item.content}
                </Box>
                {item.media && (
                  <>
                    {getMediaType(item.media) === 'image' && (
                      <Box width={{ _: '100%', md: '300px', lg: '400px' }} className={classes.previewImageContainer}>
                        <img src={item.media} alt="Media item" className={classes.previewImage} />
                      </Box>
                    )}
                    {getMediaType(item.media) === 'video' && (
                      <Box width={{ _: '100%', md: '300px', lg: '400px' }} className={classes.previewImageContainer}>
                        <video
                          preload="none"
                          controls
                          controlsList="nodownload"
                          disablePictureInPicture
                          disableRemotePlayback
                          muted
                          playsInline>
                          <source src={item.media} type="`video/mp4" />
                        </video>
                      </Box>
                    )}
                  </>
                )}

                {item.sharedItem && (
                  <Box my={2}>
                    <SnsSharedItemThumbnail data={item.sharedItem} />
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box my={2}>
          <Divider />
        </Box>
        <AppFormControl boxProps={{ mt: 3 }}>
          <TextField
            margin="dense"
            id="title"
            label={t('sns:message.report.remarkLabel')}
            variant="outlined"
            fullWidth
            defaultValue={previewItem.remark || ''}
            onChange={(e) => setRemark(e.target.value)}
            disabled={previewItem.reportStatus !== ReportStatus.Submitted}
          />
        </AppFormControl>
        <Box my={3} display="flex" justifyContent="flex-end" className={classes.buttonGroups}>
          {previewItem.reportStatus === ReportStatus.Submitted && (
            <>
              <AppButton variant="contained" color="error" onClick={() => approveReport()}>
                {t('sns:common.buttons.approveReport')}
              </AppButton>
              <AppButton variant="contained" color="warning" onClick={() => rejectReport()}>
                {t('sns:common.buttons.rejectReport')}
              </AppButton>
              <AppButton variant="contained" color="info" onClick={() => markReportAsDuplicated()}>
                {t('sns:common.buttons.markDuplicatedReport')}
              </AppButton>
            </>
          )}
          <AppButton
            variant="outlined"
            color="default"
            onClick={() => {
              if (typeof onCloseClick === 'function') {
                onCloseClick();
              }
            }}>
            {t('common:button.close')}
          </AppButton>
        </Box>
      </AppDialog>
    </>
  );
};

export default SnsItemPreviewDialog;
