import FakhaList from './List';
import { RouteConfig } from '../../../models/route.model';

const fakhaRoutes = (path: string): RouteConfig[] => [
  {
    key: 'FAKHA_LIST',
    path,
    component: FakhaList,
    exact: true,
    title: 'common:title.fakha',
  },
];

export default fakhaRoutes;
