import UserChat from './Chat';
import UserEdit from './Edit';
import UserList from './List';
import { RouteConfig } from '../../models/route.model';

const userRoutes = (path: string): RouteConfig[] => [
  {
    key: 'USERS_LIST',
    path,
    component: UserList,
    exact: true,
    title: 'common:title.users',
  },
  {
    key: 'USER_DETAIL',
    path: `${path}/:hashId`,
    component: UserEdit,
    exact: true,
    backUrl: path,
  },
  {
    key: 'USER_CHAT',
    path: `${path}/:hashId/chat`,
    component: UserChat,
    exact: true,
    title: 'common:title.users',
    backUrl: path,
  },
  {
    key: 'USER_CHAT_ROOM',
    path: `${path}/:hashId/chat/:roomId`,
    component: UserChat,
    exact: true,
    title: 'common:title.users',
    backUrl: path,
  },
];

export default userRoutes;
