import { AppControls } from './theme-types';

const controls: AppControls = {
  control: {
    normal: {
      height: '60px',
      borderWidth: '1px',
      lineHeight: '60px',
      fontSize: '16px',
      padding: '4px 14px',
      boxSizing: 'border-box',
    },
    small: {
      height: '42px',
      borderWidth: '1px',
      lineHeight: '42px',
      fontSize: '14px',
      paddingTop: '8px',
      paddingRight: '14px',
      paddingBottom: '8px',
      paddingLeft: '14px',
    },
  },
  inputLabel: {
    shrink: {
      fontSize: '14px',
      transform: 'translate(10px, -10px) scale(0.8)',
      padding: '4px',
      fontWeight: 700,
    },
  },
};

export default controls;
