import React, { FC } from 'react';

import { Box, Container, Grid, makeStyles, Typography } from '@material-ui/core';
import YoutubeSearchedForIcon from '@material-ui/icons/YoutubeSearchedFor';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { PromoCodeListSearchFormData, PromoCodeStateWorkflow } from '../../../../../models/promo-code';
import useAppContainerStyles from '../../../../../theme/container.style';
import useTypographyStyles from '../../../../../theme/typography.style';
import AppButton from '../../../../ui/AppButton';
import AppChipList from '../../../../ui/AppChipList';

export type PromoCodeListSearchFormProps = {
  isLoadingData?: boolean;
  currentFilters: PromoCodeListSearchFormData | undefined;
  onSearchFormSubmit: (values: PromoCodeListSearchFormData) => void;
};

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
  },
  searchBoxContainer: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 600,
    },
  },
}));

const searchStatusWorkflow = [
  {
    label: 'promo-code:list.search.workflow.W12_START_STATE',
    value: PromoCodeStateWorkflow.Start,
  },
  {
    label: 'promo-code:list.search.workflow.W12_AVAILABLE_STATE',
    value: PromoCodeStateWorkflow.Available,
  },
  {
    label: 'promo-code:list.search.workflow.W12_USED_STATE',
    value: PromoCodeStateWorkflow.Used,
  },
  {
    label: 'promo-code:list.search.workflow.W12_EXPIRED_STATE',
    value: PromoCodeStateWorkflow.Expired,
  },
  {
    label: 'promo-code:list.search.workflow.W12_INACTIVE_STATE',
    value: PromoCodeStateWorkflow.Inactive,
  },
];

const PromoCodeListSearchForm: FC<PromoCodeListSearchFormProps> = (props) => {
  const classes = useStyles();
  const containerClasses = useAppContainerStyles();
  const typoClasses = useTypographyStyles();
  const { t } = useTranslation();
  const { currentFilters, onSearchFormSubmit, isLoadingData = false } = props;
  const { handleSubmit, setValue, control, getValues } = useForm<PromoCodeListSearchFormData>({
    mode: 'onSubmit',
    defaultValues: {
      searchKeyword: currentFilters?.searchKeyword || '',
      filterStateWorkflow: currentFilters?.filterStateWorkflow || [],
    },
  });

  const submitSearchForm = () => {
    if (!isLoadingData) {
      setValue('searchKeyword', getValues('searchKeyword').trim());
      handleSubmit(onSearchFormSubmit)();
    }
  };

  const clearSearchForm = () => {
    if (!isLoadingData) {
      setValue('searchKeyword', '');
      setValue('filterStateWorkflow', []);
      handleSubmit(onSearchFormSubmit)();
    }
  };

  return (
    <Box className={classes.filterContainer}>
      <Container className={containerClasses.container}>
        <form onSubmit={handleSubmit(onSearchFormSubmit)}>
          <Grid container direction="row" spacing={3}>
            <Grid item xs={12} md>
              <Box>
                <Typography variant="body2" style={{ marginBottom: 12 }} className={typoClasses.textWeightBold}>
                  {t('promo-code:list.search.statusTitle')}
                </Typography>
                <Box display="flex" flexDirection="row" flexWrap="wrap">
                  <Controller
                    control={control}
                    name="filterStateWorkflow"
                    defaultValue={currentFilters?.filterStateWorkflow || []}
                    render={({ field }) => (
                      <>
                        <AppChipList
                          items={searchStatusWorkflow}
                          selectedValues={field.value}
                          onChange={(values: string[]) => {
                            field.onChange(values);
                            submitSearchForm();
                          }}
                        />
                      </>
                    )}
                  />
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} md="auto">
              <AppButton variant="outlined" color="primary" onClick={clearSearchForm} disabled={isLoadingData}>
                <YoutubeSearchedForIcon />
              </AppButton>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Box>
  );
};

export default PromoCodeListSearchForm;
