import React, { FC } from 'react';

import { Box, BoxProps, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    '& > .MuiFormControl-root, & > .MuiBox-root': {
      '&:hover': {
        zIndex: 2,
      },
      '&:not(:only-child):first-child .MuiOutlinedInput-root ': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
      '&:last-child:not(:only-child) .MuiOutlinedInput-root ': {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },
      '&:not(:first-child):not(:last-child):not(:only-child) .MuiOutlinedInput-root ': {
        borderRadius: 0,
      },
      '&:not(:only-child)': {
        marginRight: -1,
      },
      '& .Mui-focused': {
        zIndex: 2,
      },
    },
  },
}));

const AppFormControlGroup: FC<BoxProps> = (props) => {
  const { children, ...rest } = props;
  const classes = useStyles();
  return (
    <Box component="div" display="flex" flexDirection="row" flexWrap="nowrap" className={classes.container} {...rest}>
      {children}
    </Box>
  );
};

export default AppFormControlGroup;
