import ListingEdit from './Edit';
import List from './List';
import { RouteConfig } from '../../models/route.model';

const listingRoutes = (path: string): RouteConfig[] => [
  {
    key: 'LISTING',
    path,
    component: List,
    exact: true,
    title: 'common:title.listing',
  },
  {
    key: 'LISTING_DETAIL',
    path: `${path}/:hashId`,
    component: ListingEdit,
    exact: true,
  },
];

export default listingRoutes;
