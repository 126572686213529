/* eslint-disable import/prefer-default-export */
import { apiRequest, apiTCRequest } from '../helpers/request';
import { queryParamGenerator } from '../helpers/utils';
import { ApiResponse } from '../models';
import {
  GetNotificationResponse,
  GetNotificationAdminResponse,
  GetNotificationResponseTC,
  MarkNotificationResponse,
  MarkNotifications,
  NotificationData,
  NotificationSearchFilters,
} from '../models/notification.model';

const getNotificationQueryParam = (param?: Partial<NotificationSearchFilters>): string => {
  const reqParam: { [x: string]: string | number } = {
    page: (param?.page || 0) + 1, // plus 1 for index shift between FE component and backend query
    per_page: param?.rowsPerPage || 10,
    sort: param?.sortColumn ? `${param.sortColumn}|${(param?.sortDirection || 'desc').toUpperCase()}` : '',
  };

  // Only search with keyword when it comes with the selected column to be searched
  if (param?.searchKeyword && param?.searchField) {
    reqParam[param.searchField] = param.searchKeyword.trim();
  }
  return queryParamGenerator(reqParam);
};

const getNotificationQueryParamTc = (param?: Partial<NotificationSearchFilters>): string => {
  const reqParam: { [x: string]: string | number } = {
    page: param?.page || 0, // plus 1 for index shift between FE component and backend query
    per_page: param?.rowsPerPage || 10,
    sort: param?.sortDirection ? `id%7C${(param?.sortDirection || 'desc').toUpperCase()}` : '',
    notification_type: param?.notificationType ? param?.notificationType : '',
  };

  // Only search with keyword when it comes with the selected column to be searched
  if (param?.searchKeyword && param?.searchField) {
    reqParam[param.searchField] = param.searchKeyword.trim();
  }

  return queryParamGenerator(reqParam);
};

export const getNotifications = async (
  page: number,
  rowPerpage: number,
): Promise<ApiResponse<GetNotificationResponse>> => {
  try {
    const notificationFilter = `page=${page}&per_page=${rowPerpage}`;
    const result = await apiRequest<NotificationData[]>(`/users/me/notifications?${notificationFilter}`);

    return {
      data: {
        data: result.data,
        total: Number(result.headers['x-total-count'] || '0') || result.data.length,
      },
    };
  } catch (e) {
    return {
      data: null,
      error: 'error:request.getNotification',
    };
  }
};

export const markNotificationAs = async (data: MarkNotifications): Promise<ApiResponse<MarkNotificationResponse>> => {
  try {
    const body = data;
    const result = await apiRequest<NotificationData>(`/users/me/notifications/mark-as`, {
      method: 'POST',
      data: body,
    });

    return {
      data: {
        data: result.data,
        status: result.status,
        statusText: result.statusText,
      },
    };
  } catch (e) {
    return {
      data: null,
      error: 'error:request.markNotification',
    };
  }
};

export const getNotificationsByUserHashId = async (
  hashId: string,
  param?: Partial<NotificationSearchFilters>,
): Promise<ApiResponse<GetNotificationResponse>> => {
  try {
    const queryParamString = getNotificationQueryParam(param);
    const result = await apiRequest<NotificationData[]>(`admin/users/${hashId}/notifications?${queryParamString}`, {
      method: 'GET',
    });

    return {
      data: {
        data: result.data,
        total: Number(result.headers['x-total-count'] || '0') || result.data.length,
      },
    };
  } catch (e) {
    return {
      data: null,
      error: 'payment-history:snackbar.error',
    };
  }
};

export const getNotificationsByUserHashIdTC = async (
  hashId: string,
  param?: Partial<NotificationSearchFilters>,
): Promise<ApiResponse<GetNotificationResponseTC>> => {
  try {
    const queryParamString = getNotificationQueryParamTc(param);

    const result = await apiTCRequest<GetNotificationResponseTC>(
      `admin/notification/user/${hashId}?${queryParamString}`,
    );

    return {
      data: result.data ?? null,
    };
  } catch (e) {
    return {
      data: null,
      error: 'error:request.getNotification',
    };
  }
};

export const getAdminNotifications = async (
  param?: Partial<NotificationSearchFilters>,
): Promise<ApiResponse<GetNotificationAdminResponse>> => {
  try {
    const queryParamString = getNotificationQueryParamTc(param);
    const result = await apiTCRequest<GetNotificationAdminResponse>(`admin/notification/?${queryParamString}`);

    return {
      data: result.data ?? null,
    };
  } catch (e) {
    return {
      data: null,
      error: 'error:request.getNotification',
    };
  }
};

export const markAdminNotificationAs = async (hashId: string): Promise<ApiResponse<MarkNotificationResponse>> => {
  try {
    const result = await apiTCRequest<NotificationData>(`/auth/user-notification/read/${hashId}`, {
      method: 'POST',
    });
    return {
      data: {
        data: result.data,
        status: result.status,
        statusText: result.statusText,
      },
    };
  } catch (e) {
    return {
      data: null,
      error: 'error:request.markNotification',
    };
  }
};

export const markAllNotification = async (): Promise<boolean> => {
  try {
    await apiTCRequest<NotificationData>(`auth/user-notification/read/all`, {
      method: 'POST',
    });

    return true;
  } catch (e) {
    return Promise.reject(e);
  }
};
