import ReportListingList from './List';
import { RouteConfig } from '../../models/route.model';

const reportListingRoutes = (path: string): RouteConfig[] => [
  {
    key: 'REPORT_LISTING_LIST',
    path,
    component: ReportListingList,
    exact: true,
    title: 'common:title.reportListing',
  },
];

export default reportListingRoutes;
