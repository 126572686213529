import ProductList from './List';
import { RouteConfig } from '../../models/route.model';

const productRoutes = (path: string): RouteConfig[] => [
  {
    key: 'PRODUCT_LIST',
    path,
    component: ProductList,
    exact: true,
    title: 'common:title.product',
  },
];

export default productRoutes;
