import React, { FC, useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import FakhaListSearchForm from '../../../components/partials/v3/fakha/FakhaListSearchForm';
import FakhaListTable from '../../../components/partials/v3/fakha/FakhaListTable';
import { AppGlobalUiContext } from '../../../context/AppGlobalUiContext';
import { SearchFilterContext } from '../../../context/SearchFilterContext';
import { defaultRowsPerPage } from '../../../helpers/constants';
import { AppTableConditions } from '../../../models';
import { FakhaData, FakhaListSearchFormDataTC, FakhaListSearchParamsTC } from '../../../models/fakha.model';
import { getFakha } from '../../../services/v3/fakha';

type FakhaListPageProps = unknown;

const searchFilterContextKey = 'fakha';

const FakhaList: FC<FakhaListPageProps> = () => {
  const { t } = useTranslation();
  const { showSnackbar } = useContext(AppGlobalUiContext);
  const [fakhaList, setFakhaList] = useState<FakhaData[]>([]);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [totalItems, setTotalItems] = useState<number>(0);

  const { currentFilters: ctxFilters, setFilters } = useContext(SearchFilterContext);
  const currentFilters: FakhaListSearchParamsTC = ctxFilters ? ctxFilters[searchFilterContextKey] : undefined;

  const [searchFilters, setSearchFilters] = useState<FakhaListSearchFormDataTC>({
    searchField: currentFilters?.searchField || '',
    searchKeyword: currentFilters?.searchKeyword || '',
    filteringType: currentFilters?.filteringType || [],
    filteringRoleStateWorkflow: currentFilters?.filteringRoleStateWorkflow || [],
  });

  const [tableConditions, setTableConditions] = useState<AppTableConditions>({
    page: currentFilters?.page || 0,
    rowsPerPage: currentFilters?.rowsPerPage || defaultRowsPerPage,
    sortColumn: currentFilters?.sortColumn || 'createdAt',
    sortDirection: currentFilters?.sortDirection || 'desc',
  });

  const getCurrentSearchParam = (): FakhaListSearchParamsTC => ({
    page: tableConditions.page || 0,
    rowsPerPage: tableConditions.rowsPerPage || defaultRowsPerPage,
    sortColumn: tableConditions.sortColumn || 'createdAt',
    sortDirection: tableConditions.sortDirection || 'desc',
    searchField: searchFilters.searchField,
    searchKeyword: searchFilters.searchKeyword,
    filteringType: searchFilters.filteringType,
    filteringRoleStateWorkflow: searchFilters.filteringRoleStateWorkflow,
  });

  const requestFakhaList = async (isPageSubscribed = true): Promise<boolean> => {
    if (isPageSubscribed) {
      setIsLoadingData(true);

      const searchConditions = getCurrentSearchParam();
      setFilters(searchFilterContextKey, searchConditions);
      const result = await getFakha(searchConditions);
      setIsLoadingData(false);

      if (result.data) {
        setFakhaList(result.data.contents);
        setTotalItems(result.data?.page?.totalElements || 0);
        return Promise.resolve(true);
      }

      if (result.error) {
        setFakhaList([]);
        setTotalItems(0);
        showSnackbar(t('fakha:error.list.requestFailed').toString(), 'error');
        return Promise.reject(result.error);
      }

      showSnackbar(t(result.error as string).toString(), 'error');
      return Promise.reject(result.error);
    }

    return false;
  };

  useEffect(() => {
    let isSubscribed = true;
    requestFakhaList(isSubscribed);

    return () => {
      isSubscribed = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableConditions, searchFilters]);

  const tableConditionsChangedHandler = (values: AppTableConditions) => {
    setTableConditions(values);
  };

  const onSearchFormSubmit = (filters: FakhaListSearchFormDataTC) => {
    if (isLoadingData) {
      showSnackbar(t('common.message.pleaseWaitForDataToFinish').toString(), 'warning');
      return;
    }
    if (tableConditions.page !== 0) {
      tableConditions.page = 0;
    }
    setSearchFilters(filters);
  };

  return (
    <>
      <FakhaListSearchForm
        isLoadingData={isLoadingData}
        currentFilters={searchFilters}
        onSearchFormSubmit={onSearchFormSubmit}
      />
      <FakhaListTable
        currentConditions={tableConditions}
        fakhaList={fakhaList}
        totalItems={totalItems}
        isLoadingData={isLoadingData}
        onTableConditionsChanged={tableConditionsChangedHandler}
        onFetch={() => requestFakhaList()}
      />
    </>
  );
};

export default FakhaList;
