/* eslint-disable import/prefer-default-export */
import { apiTCRequestV2 } from '../helpers/request';
import { ApiResponse } from '../models';
import { GetS3TdResponse } from '../models/s3-td.model';

export const getS3TdFile = async (filePath: string): Promise<ApiResponse<GetS3TdResponse>> => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const result = await apiTCRequestV2<any>(`/admin/s3-td/${filePath}`);

    return {
      data: {
        data: result.data,
      },
    };
  } catch (e) {
    return {
      data: null,
      error: 'dashboard:snackbar.error',
    };
  }
};
