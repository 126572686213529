import React, { ReactElement } from 'react';

import { createSvgIcon } from '@material-ui/core';

const SnsIcon = createSvgIcon(
  <>
    <path
      d="M11,0h0A11,11,0,0,1,22,11h0A11,11,0,0,1,11,22h0A11,11,0,0,1,0,11H0A11,11,0,0,1,11,0Z"
      style={{ fill: '#fff', opacity: 0.2 }}
    />
    <path
      d="M21,10h0A11,11,0,0,1,32,21h0A11,11,0,0,1,21,32h0A11,11,0,0,1,10,21h0A11,11,0,0,1,21,10Z"
      style={{ fill: '#fff', opacity: 0.4 }}
    />
    <path
      d="M22,10l0,1A11,11,0,0,1,11,22c-.32,0-.64,0-1,0,0-.32,0-.64,0-1A11,11,0,0,1,21,10C21.32,10,21.64,10,22,10Z"
      style={{ fillRule: 'evenodd', opacity: 0.4 }}
    />
  </>,
  'Sns',
);

const AppSnsSvgIcon = (): ReactElement => <SnsIcon viewBox="0 0 32 32" />;

export default AppSnsSvgIcon;
