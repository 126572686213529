import React, { FC, useEffect, useState } from 'react';

import { AppBar, Box, makeStyles, Toolbar } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import useControlStyles from '../../../../theme/controls.style';
import AppButton from '../../../ui/AppButton';

const useStyles = makeStyles((theme) => ({
  stickyActionContainer: {
    position: 'fixed',
    top: 'auto',
    left: '0',
    bottom: 0,
    right: 0,
    zIndex: theme.zIndex.drawer - 1,
    backgroundColor: theme.palette.background.paper,
  },
  stickyActionToolbar: {
    justifyContent: 'flex-end',
  },
}));

const StickyActionBar: FC = (props) => {
  const { children } = props;
  const classes = useStyles();
  const controlsClasses = useControlStyles();
  const { t } = useTranslation();
  const [isActionBarDisplayed, setIsActionBarDisplayed] = useState(false);

  useEffect(() => {
    let isSubscribed = true;

    const scrollEventHandler = () => {
      if (isSubscribed) {
        const scrollTop = window.scrollY || document.body.scrollTop || document.documentElement.scrollTop;
        setIsActionBarDisplayed((scrollTop || 0) > 140);
      }
    };

    window.addEventListener('scroll', scrollEventHandler, false);

    return () => {
      window.removeEventListener('scroll', scrollEventHandler, false);
      isSubscribed = false;
    };
  }, []);

  const goBackToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      {children}
      {isActionBarDisplayed && (
        <AppBar className={classes.stickyActionContainer}>
          <Toolbar className={classes.stickyActionToolbar}>
            <Box pt={1} className={controlsClasses.buttonsGroup}>
              <AppButton color="default" variant="outlined" onClick={goBackToTop}>
                {t('common:button.backToTop')}
              </AppButton>
              {children}
            </Box>
          </Toolbar>
        </AppBar>
      )}
    </>
  );
};

export default StickyActionBar;
