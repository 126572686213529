import { apiTCRequestV2 } from '../helpers/request';
import { queryParamGenerator } from '../helpers/utils';
import { ApiResponse } from '../models';
import {
  GetAbTestingListTCResponse,
  AbTestingData,
  AbTestingFormDataRequest,
  AbTestingSearchFiltersTC,
} from '../models/ab-testing.model';

const getAbTestingListQueryParam = (param?: AbTestingSearchFiltersTC): string => {
  const reqParam: { [x: string]: string | number } = {
    page: param?.page || 0, // plus 1 for index shift between FE component and backend query
    per_page: param?.rowsPerPage || 10,
    sort: param?.sortColumn ? `${'id' || param?.sortColumn}%7C${(param?.sortDirection || 'DESC').toUpperCase()}` : '',
  };

  if (param?.searchKeyword && param?.searchField) {
    reqParam[param.searchField] = param.searchKeyword.trim();
  }

  if (param?.filteringType) {
    reqParam.features = param.filteringType.join(',');
  }

  if (param?.q) {
    reqParam.q = param.q;
  }

  return queryParamGenerator(reqParam);
};

export const getAbTestingListGroupByFeatures = async (
  param?: AbTestingSearchFiltersTC,
): Promise<ApiResponse<GetAbTestingListTCResponse>> => {
  try {
    const queryParamString = getAbTestingListQueryParam(param);
    const result = await apiTCRequestV2<GetAbTestingListTCResponse>(
      `/admin/user-ab-feature/group-by-features?${queryParamString}`,
    );

    return {
      data: result.data ?? null,
    };
  } catch (e) {
    return {
      data: null,
      error: 'ab-testing:error.list.requestFailed',
    };
  }
};

export const getAbTestingListGroupByUsers = async (
  param?: AbTestingSearchFiltersTC,
  feature?: string,
): Promise<ApiResponse<GetAbTestingListTCResponse>> => {
  try {
    const queryParamString = getAbTestingListQueryParam(param);

    const result = await apiTCRequestV2<GetAbTestingListTCResponse>(
      `/admin/user-ab-feature/group-by-users?${queryParamString}${feature}`,
    );

    return {
      data: result.data ?? null,
    };
  } catch (e) {
    return {
      data: null,
      error: 'ab-testing:error.list.requestFailed',
    };
  }
};

export const deleteUserAbTestingByFeature = async (features: string[]): Promise<ApiResponse<boolean>> => {
  try {
    await apiTCRequestV2<boolean>(`/admin/user-ab-feature/feature`, {
      method: 'DELETE',
      data: { features },
    });

    return {
      data: true,
    };
  } catch (error) {
    return {
      data: null,
      error: 'ab-testing:error.list.requestDeleteFailed',
    };
  }
};

export const updateAbTesting = async (
  formData: AbTestingFormDataRequest,
): Promise<ApiResponse<AbTestingData | null>> => {
  try {
    const result = await apiTCRequestV2<AbTestingData>(`/admin/user-ab-feature/feature`, {
      method: 'PATCH',
      data: { ...formData },
    });

    return {
      ...result,
      data: result.data,
    };
  } catch (e) {
    return Promise.reject(e);
  }
};

export const deleteUsersByFeature = async (
  formData: AbTestingFormDataRequest,
): Promise<ApiResponse<AbTestingData | null>> => {
  try {
    const result = await apiTCRequestV2<AbTestingData>(`/admin/user-ab-feature/features-users`, {
      method: 'DELETE',
      data: { ...formData },
    });

    return {
      ...result,
      data: result.data,
    };
  } catch (e) {
    return Promise.reject(e);
  }
};
