import React, { FC, useCallback, useContext, useEffect, useState } from 'react';

import {
  Box,
  Divider,
  Fade,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Popover,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import { grey, indigo } from '@material-ui/core/colors';
import BlockRoundedIcon from '@material-ui/icons/BlockRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import HistoryRoundedIcon from '@material-ui/icons/HistoryRounded';
import LaunchRoundedIcon from '@material-ui/icons/LaunchRounded';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import SettingsBackupRestoreRoundedIcon from '@material-ui/icons/SettingsBackupRestoreRounded';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import AccountActions from './AccountActions';
import AdditionalInfo from './AdditionalInfo';
import BasicInfo from './BasicInfo';
import BillingInfo from './BillingInfo';
import EditFormDialogList from './EditFormDialogList';
import PrivateInfo from './PrivateInfo';
import ShopInfo from './ShopInfo';
import SocialMediaInfo from './SocialMediaInfo';
import UserDialogList from './UserDialogList';
import { AppGlobalUiContext } from '../../../../../context/AppGlobalUiContext';
import { generalInfoStyles, useStyles } from '../../../../../helpers/constants/user-detail-styles';
import { getAppUrl } from '../../../../../helpers/utils';
import {
  UserRoleTC,
  UserDataTC,
  TabItem,
  UserGeneralWorkflow,
  AccessLogPrivateInfoDataRequest,
  EventType,
} from '../../../../../models';
import { addAccessLogPrivateInfo } from '../../../../../services/v3/interview-log';
import {
  activationUser,
  bannedUser,
  blacklistUser,
  inactivateUser,
  unBannedUser,
  unBlacklistUser,
} from '../../../../../services/v3/user';
import AppButton from '../../../../ui/AppButton';
import AppTabPanel from '../../../../ui/AppTabPanel';

export type UserDetailsProps = {
  user: UserDataTC;
  currentCoinBalance: number;
  onUpdated?: (newData: UserDataTC) => void;
};

const tabIdPrefix = 'user-detail-tab-';
const tabPanelPrefix = 'user-detail-tabpanel-';
const tabList: TabItem[] = [
  {
    id: 'personal',
    label: 'ข้อมูลส่วนตัว',
    value: 'personal',
  },
  {
    id: 'shop',
    label: 'ข้อมูลร้านค้า',
    value: 'shop',
  },
  {
    id: 'payment',
    label: 'ข้อมูลการชำระเงิน',
    value: 'payment',
  },
  {
    id: 'additional',
    label: 'ข้อมูลอื่นๆ',
    value: 'additional',
  },
  {
    id: 'social',
    label: 'โซเชียลมีเดีย',
    value: 'social',
  },
];

const UserDetails: FC<UserDetailsProps> = (props) => {
  const classes = useStyles();
  const genInfoStyles = generalInfoStyles();
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const { user: propsUser, currentCoinBalance, onUpdated } = props;
  const [currentUser, setCurrentUser] = useState<UserDataTC>(propsUser);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [historyMenuAnchorElem, setHistoryMenuAnchorElem] = useState<HTMLElement | null>(null);
  const [userMenuAnchorElem, setUserMenuAnchorElem] = useState<HTMLElement | null>(null);
  const [isShowPrivateInfoData, setIsShowPrivateInfoData] = useState<boolean>(false);

  const [infoDialogMode, setInfoDialogMode] = useState<
    'notification' | 'coin-usage' | 'membership' | 'membership-v2' | null
  >(null);
  const [accountActionMode, setAccountActionMode] = useState<
    'ban' | 'unban' | 'blacklist' | 'remove-blacklist' | 'deactivate' | 'activate' | null
  >(null);

  // Edit form states
  const [editingForm, setEditingForm] = useState<'user-data' | 'private-info' | 'shop' | 'billing' | 'misc' | null>(
    null,
  );

  const { showSnackbar, setAppLoading } = useContext(AppGlobalUiContext);

  const actionableMenuColor = currentUser.userGeneralWorkflow === UserGeneralWorkflow.Active ? 'danger' : 'restore';
  const isBanActionable = [UserGeneralWorkflow.Active, UserGeneralWorkflow.Banned].includes(
    currentUser.userGeneralWorkflow as UserGeneralWorkflow,
  );
  const isBlacklistActionable = [UserGeneralWorkflow.Active, UserGeneralWorkflow.BlackListed].includes(
    currentUser.userGeneralWorkflow as UserGeneralWorkflow,
  );
  const isActiveActionable = [UserGeneralWorkflow.Active, UserGeneralWorkflow.InActive].includes(
    currentUser.userGeneralWorkflow as UserGeneralWorkflow,
  );
  const isSeller = currentUser.userRoleStateWorkflow !== UserRoleTC.Buyer;
  const isMembership =
    currentUser.userRoleStateWorkflow === UserRoleTC.SellerBronze || UserRoleTC.SellerGold || UserRoleTC.SellerSilver;

  const handleSelectedTab = useCallback((e, newValue) => {
    setSelectedTab(newValue);
  }, []);

  const handleHistoryMenuClick = useCallback((e) => {
    setHistoryMenuAnchorElem(e.currentTarget);
  }, []);

  const handleCloseHistoryMenu = useCallback(() => {
    setHistoryMenuAnchorElem(null);
  }, []);

  const handleUserMenuClick = useCallback((e) => {
    setUserMenuAnchorElem(e.currentTarget);
  }, []);

  const handleCloseUserMenu = useCallback(() => {
    setUserMenuAnchorElem(null);
  }, []);

  const handleConfirmAccountAction = useCallback(
    async (
      actionMode: 'ban' | 'unban' | 'blacklist' | 'remove-blacklist' | 'deactivate' | 'activate',
      comment: string,
    ) => {
      let result;
      let resultWorkflowStatus = currentUser.userGeneralWorkflow;
      let failMessage;

      try {
        setAppLoading(true);

        switch (actionMode) {
          case 'ban':
            result = await bannedUser(currentUser.userHashId, comment);
            resultWorkflowStatus = UserGeneralWorkflow.Banned;
            failMessage = 'user:error.edit.banAccountFailed';
            break;
          case 'blacklist':
            result = await blacklistUser(currentUser.userHashId, comment);
            resultWorkflowStatus = UserGeneralWorkflow.BlackListed;
            failMessage = 'user:error.edit.blacklistAccountFailed';
            break;
          case 'deactivate':
            result = await inactivateUser(currentUser.userHashId, comment);
            resultWorkflowStatus = UserGeneralWorkflow.InActive;
            failMessage = 'user:error.edit.deactivateAccountFailed';
            break;
          case 'unban':
            result = await unBannedUser(currentUser.userHashId, comment);
            resultWorkflowStatus = UserGeneralWorkflow.Active;
            failMessage = 'user:error.edit.unBanAccountFailed';
            break;
          case 'remove-blacklist':
            result = await unBlacklistUser(currentUser.userHashId, comment);
            resultWorkflowStatus = UserGeneralWorkflow.Active;
            failMessage = 'user:error.edit.unBlacklistAccountFailed';
            break;
          case 'activate':
            result = await activationUser(currentUser.userHashId, comment);
            resultWorkflowStatus = UserGeneralWorkflow.Active;
            failMessage = 'user:error.edit.activateAccountFailed';
            break;
          default:
            break;
        }

        if (result && resultWorkflowStatus) {
          setCurrentUser((current) => ({ ...current, userGeneralWorkflow: resultWorkflowStatus }));
          showSnackbar(t('user:common.message.updateAccountSuccess').toString(), 'success');
        }
      } catch (e) {
        showSnackbar(failMessage ? t(failMessage).toString() : 'Failed to update account', 'error');
      } finally {
        setAppLoading(false);
        setAccountActionMode(null);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser.userGeneralWorkflow, currentUser.userHashId],
  );

  const handleCancelAccountAction = useCallback(() => {
    setAccountActionMode(null);
  }, []);

  const handleAddEventAccessLog = useCallback(
    async (logData?: AccessLogPrivateInfoDataRequest) => {
      if (currentUser.userHashId) {
        const remark = `View User hash id ${currentUser.userHashId} - Profile private info in User detail page`;
        const urlPath = getAppUrl(url);
        const eventType = EventType.SEEN;

        await addAccessLogPrivateInfo({
          ...logData,
          remark,
          url: urlPath,
          eventType,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser.userHashId],
  );

  useEffect(() => {
    if (onUpdated) {
      onUpdated(currentUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  return (
    <>
      <Paper elevation={1} classes={{ root: classes.page }}>
        <Box className={classes.container} py="30px">
          <Box position="relative" className={genInfoStyles.commonUserDataContainer}>
            <Box className={clsx(genInfoStyles.container, 'common-data')}>
              <BasicInfo
                currentUser={currentUser}
                currentCoinBalance={currentCoinBalance}
                isSeller={isSeller}
                isMembership={!!isMembership}
              />
            </Box>
            <Box className={clsx(genInfoStyles.userMenuContainer, 'menu-container')}>
              <AppButton
                variant="outlined"
                color="primary"
                startIcon={<EditRoundedIcon />}
                style={{ marginRight: '8px' }}
                onClick={() => {
                  setEditingForm('user-data');
                }}>
                <span className="button-text">{t('user:v3.label.editUser')}</span>
              </AppButton>

              <AppButton
                variant="outlined"
                color="primary"
                startIcon={<HistoryRoundedIcon />}
                onClick={handleHistoryMenuClick}
                style={{ marginRight: '8px' }}>
                <span className="button-text">{t('user:common.buttons.history')}</span>
              </AppButton>
              <Popover
                id="history-menu"
                anchorEl={historyMenuAnchorElem}
                keepMounted
                open={!!historyMenuAnchorElem}
                onClose={handleCloseHistoryMenu}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                TransitionComponent={Fade}>
                <MenuItem
                  onClick={() => {
                    handleCloseHistoryMenu();
                    setInfoDialogMode('notification');
                  }}
                  classes={{ root: genInfoStyles.userMenuItem }}>
                  <ListItemIcon>
                    <NotificationsOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('user:common.buttons.userNotification')} />
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleCloseHistoryMenu();
                    setInfoDialogMode('coin-usage');
                  }}
                  classes={{ root: genInfoStyles.userMenuItem }}>
                  <ListItemIcon>
                    <MonetizationOnOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('user:common.buttons.coinUsedTransaction')} />
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleCloseHistoryMenu();
                    setInfoDialogMode('membership');
                  }}
                  classes={{ root: genInfoStyles.userMenuItem }}>
                  <ListItemIcon>
                    <ShoppingCartOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('user:common.buttons.membershipTransaction')} />
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleCloseHistoryMenu();
                    setInfoDialogMode('membership-v2');
                  }}
                  classes={{ root: genInfoStyles.userMenuItem }}>
                  <ListItemIcon>
                    <ShoppingCartOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('user:common.buttons.paymentHistoryV2')} />
                </MenuItem>
              </Popover>

              <AppButton
                variant="outlined"
                color="primary"
                startIcon={<MenuRoundedIcon />}
                onClick={handleUserMenuClick}>
                <span className="button-text">{t('user:common.buttons.userMenu')}</span>
              </AppButton>
              <Popover
                id="user-menu"
                anchorEl={userMenuAnchorElem}
                keepMounted
                open={!!userMenuAnchorElem}
                onClose={handleCloseUserMenu}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                TransitionComponent={Fade}>
                {!!currentUser.latestSellerApplicationHashId && (
                  <a
                    href={`/v3/seller-applications/${currentUser.latestSellerApplicationHashId}`}
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: 'none' }}>
                    <MenuItem
                      onClick={handleCloseUserMenu}
                      classes={{ root: clsx(genInfoStyles.userMenuItem, 'link') }}>
                      <ListItemIcon>
                        <LaunchRoundedIcon style={{ color: indigo[500] }} />
                      </ListItemIcon>
                      <ListItemText primary="ดูใบสมัครเป็นผู้ขาย" />
                    </MenuItem>
                  </a>
                )}
                {!currentUser.latestSellerApplicationHashId && (
                  <MenuItem classes={{ root: clsx(genInfoStyles.userMenuItem, 'disabled') }} disabled>
                    <ListItemIcon>
                      <LaunchRoundedIcon style={{ color: grey[600] }} />
                    </ListItemIcon>
                    <ListItemText primary="ดูใบสมัครเป็นผู้ขาย" />
                  </MenuItem>
                )}
                <a
                  href={`/users/${currentUser.userHashId}`}
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: 'none' }}>
                  <MenuItem onClick={handleCloseUserMenu} classes={{ root: clsx(genInfoStyles.userMenuItem, 'link') }}>
                    <ListItemIcon>
                      <LaunchRoundedIcon style={{ color: indigo[500] }} />
                    </ListItemIcon>
                    <ListItemText primary="ดูข้อมูลผู้ใช้ในเวอร์ชั่นเดิม" />
                  </MenuItem>
                </a>
                <li style={{ listStyle: 'none' }}>
                  <Divider />
                </li>
                <MenuItem
                  onClick={() => {
                    if (isBanActionable) {
                      handleCloseUserMenu();
                      setAccountActionMode(
                        currentUser.userGeneralWorkflow !== UserGeneralWorkflow.Banned ? 'ban' : 'unban',
                      );
                    }
                  }}
                  classes={{
                    root: clsx(genInfoStyles.userMenuItem, isBanActionable ? actionableMenuColor : 'disabled'),
                  }}
                  disabled={!isBanActionable}>
                  <ListItemIcon>
                    {currentUser.userGeneralWorkflow !== UserGeneralWorkflow.Banned && <BlockRoundedIcon />}
                    {currentUser.userGeneralWorkflow === UserGeneralWorkflow.Banned && (
                      <SettingsBackupRestoreRoundedIcon />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      currentUser.userGeneralWorkflow !== UserGeneralWorkflow.Banned
                        ? t('user:common.buttons.bannedAccount')
                        : t('user:common.buttons.unBannedAccount')
                    }
                  />
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    if (isBlacklistActionable) {
                      handleCloseUserMenu();
                      setAccountActionMode(
                        currentUser.userGeneralWorkflow !== UserGeneralWorkflow.BlackListed
                          ? 'blacklist'
                          : 'remove-blacklist',
                      );
                    }
                  }}
                  classes={{
                    root: clsx(genInfoStyles.userMenuItem, isBlacklistActionable ? actionableMenuColor : 'disabled'),
                  }}
                  disabled={!isBlacklistActionable}>
                  <ListItemIcon>
                    {currentUser.userGeneralWorkflow !== UserGeneralWorkflow.BlackListed && <BlockRoundedIcon />}
                    {currentUser.userGeneralWorkflow === UserGeneralWorkflow.BlackListed && (
                      <SettingsBackupRestoreRoundedIcon />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      currentUser.userGeneralWorkflow !== UserGeneralWorkflow.BlackListed
                        ? t('user:common.buttons.blacklistAccount')
                        : t('user:common.buttons.unBlacklistAccount')
                    }
                  />
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    if (isActiveActionable) {
                      handleCloseUserMenu();
                      setAccountActionMode(
                        currentUser.userGeneralWorkflow !== UserGeneralWorkflow.InActive ? 'deactivate' : 'activate',
                      );
                    }
                  }}
                  classes={{
                    root: clsx(genInfoStyles.userMenuItem, isActiveActionable ? actionableMenuColor : 'disabled'),
                  }}
                  disabled={!isActiveActionable}>
                  <ListItemIcon>
                    {currentUser.userGeneralWorkflow !== UserGeneralWorkflow.InActive && <BlockRoundedIcon />}
                    {currentUser.userGeneralWorkflow === UserGeneralWorkflow.InActive && (
                      <SettingsBackupRestoreRoundedIcon />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      currentUser.userGeneralWorkflow !== UserGeneralWorkflow.InActive
                        ? t('user:common.buttons.deactivateAccount')
                        : t('user:common.buttons.activateAccount')
                    }
                  />
                </MenuItem>
              </Popover>
            </Box>
          </Box>

          <Divider />

          <Tabs
            value={selectedTab}
            onChange={handleSelectedTab}
            aria-label="user detail page"
            indicatorColor="primary"
            className={genInfoStyles.subTabContainer}>
            <Tab label="ข้อมูลส่วนตัว" />
            <Tab label="ข้อมูลร้านค้า" />
            <Tab label="ข้อมูลการชำระเงิน" />
            <Tab label="ข้อมูลเพิ่มเติมอื่นๆ" />
            <Tab label="ข้อมูลโซเชียล" />
          </Tabs>

          {/* Private Info */}
          <AppTabPanel
            value={selectedTab}
            index={0}
            tabItem={tabList[0]}
            tabIdPrefix={tabIdPrefix}
            tabPanelPrefix={tabPanelPrefix}>
            {isShowPrivateInfoData ? (
              <>
                <Box display="flex" flexDirection="row" justifyContent="flex-end" my="16px">
                  <AppButton
                    variant="outlined"
                    color="primary"
                    startIcon={<EditRoundedIcon />}
                    onClick={() => {
                      setEditingForm('private-info');
                    }}>
                    {t('common:button.edit')}
                  </AppButton>
                </Box>
                <PrivateInfo currentUser={currentUser} />
              </>
            ) : (
              <Box textAlign="center" mt={6}>
                <Typography variant="subtitle2">
                  รายละเอียดในส่วนนี้เป็นข้อมูลที่เป็นส่วนตัวของผู้ใช้งาน
                  <br />
                  เพื่อความปลอดภัยและเป็นส่วนตัวให้แก่เจ้าของข้อมูล
                  <br />
                  ระบบจะทำการบันทึกข้อมูลของผู้ที่เข้าถึงการใช้งานในส่วนนี้
                </Typography>

                <AppButton
                  variant="contained"
                  color="primary"
                  style={{ marginTop: '18px' }}
                  onClick={() => {
                    handleAddEventAccessLog();
                    setIsShowPrivateInfoData(true);
                  }}>
                  ยืนยันการเข้าถึงข้อมูล
                </AppButton>
              </Box>
            )}
          </AppTabPanel>

          {/* Shop Info */}
          <AppTabPanel
            value={selectedTab}
            index={1}
            tabItem={tabList[1]}
            tabIdPrefix={tabIdPrefix}
            tabPanelPrefix={tabPanelPrefix}>
            <Box display="flex" flexDirection="row" justifyContent="flex-end" py="16px">
              <AppButton
                variant="outlined"
                color="primary"
                startIcon={<EditRoundedIcon />}
                onClick={() => {
                  setEditingForm('shop');
                }}>
                {t('common:button.edit')}
              </AppButton>
            </Box>
            <ShopInfo currentUser={currentUser} />
          </AppTabPanel>

          {/* Billing Info */}
          <AppTabPanel
            value={selectedTab}
            index={2}
            tabItem={tabList[2]}
            tabIdPrefix={tabIdPrefix}
            tabPanelPrefix={tabPanelPrefix}>
            <Box display="flex" flexDirection="row" justifyContent="flex-end" py="16px">
              <AppButton
                variant="outlined"
                color="primary"
                startIcon={<EditRoundedIcon />}
                onClick={() => {
                  setEditingForm('billing');
                }}>
                {t('common:button.edit')}
              </AppButton>
            </Box>
            <BillingInfo currentUser={currentUser} />
          </AppTabPanel>

          {/* Additional Info */}
          <AppTabPanel
            value={selectedTab}
            index={3}
            tabItem={tabList[3]}
            tabIdPrefix={tabIdPrefix}
            tabPanelPrefix={tabPanelPrefix}>
            <Box display="flex" flexDirection="row" justifyContent="flex-end" py="16px">
              <AppButton
                variant="outlined"
                color="primary"
                startIcon={<EditRoundedIcon />}
                onClick={() => {
                  setEditingForm('misc');
                }}>
                {t('common:button.edit')}
              </AppButton>
            </Box>
            <AdditionalInfo currentUser={currentUser} />
          </AppTabPanel>

          {/* Social Media Info */}
          <AppTabPanel
            value={selectedTab}
            index={4}
            tabItem={tabList[4]}
            tabIdPrefix={tabIdPrefix}
            tabPanelPrefix={tabPanelPrefix}>
            <Box display="flex" flexDirection="row" justifyContent="flex-end" py="16px">
              <AppButton
                variant="outlined"
                color="primary"
                startIcon={<EditRoundedIcon />}
                onClick={() => setEditingForm('user-data')}>
                {t('common:button.edit')}
              </AppButton>
            </Box>
            <SocialMediaInfo currentUser={currentUser} />
          </AppTabPanel>
        </Box>
      </Paper>

      <UserDialogList currentUser={currentUser} mode={infoDialogMode} onClose={() => setInfoDialogMode(null)} />

      <EditFormDialogList
        currentUser={currentUser}
        mode={editingForm}
        onClose={() => setEditingForm(null)}
        onSubmitted={(updatedUser) => {
          setCurrentUser(updatedUser);
        }}
      />

      <AccountActions
        mode={accountActionMode}
        onConfirm={handleConfirmAccountAction}
        onCancel={handleCancelAccountAction}
      />
    </>
  );
};

export default UserDetails;
