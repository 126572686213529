import { PaletteOptions, TypeBackground } from '@material-ui/core/styles/createPalette';

import {
  AppHeaderColors,
  AppSidebarColors,
  AppControlColors,
  AppTableColors,
  AppGlobalColors,
  AppStatusColors,
  AppWorkflowColors,
  AppPossessionColors,
  AppCrStatusColors,
  AppSnsReportColors,
  AppCleansingStatusColors,
  AppListingReportColors,
} from './theme-types';

export type AppColorOptions = {
  main: string;
  light?: string;
  dark?: string;
  contrastText?: string;
};

export type AppColors = {
  global: AppGlobalColors;
  primary: AppColorOptions;
  secondary: AppColorOptions;
  error: AppColorOptions;
  warning: AppColorOptions;
  info: AppColorOptions;
  success: AppColorOptions;
  disabled: AppColorOptions;
  appHeader: AppHeaderColors;
  appSidebar: AppSidebarColors;
  appStatus: AppStatusColors;
  appWorkflow: AppWorkflowColors;
  appPossession: AppPossessionColors;
  appCrStatus: AppCrStatusColors;
  appCleansingStatus: AppCleansingStatusColors;
  control: AppControlColors;
  table: AppTableColors;
  background: Partial<TypeBackground>;
  appSnsReportStatus: AppSnsReportColors<AppColorOptions>;
  appListingReportStatus: AppListingReportColors;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const colors: AppColors = {
  global: {
    document: {
      backgroundColor: '#f5f5f5',
      bodyPrimaryTextColor: '#000000',
      bodySecondaryTextColor: 'rgba(0,0,0,.8)',
    },
    lightOverlay: {
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
    },
    darkOverlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.9)',
    },
    link: {
      main: '#1976D2',
    },
  },
  primary: {
    main: '#3949ab',
    light: '#5c6bc0',
    dark: '#283593',
    contrastText: '#ffffff',
  },
  secondary: {
    main: '#ff5722',
    light: '#ff8a65',
    dark: '#d84315',
    contrastText: '#ffffff',
  },
  error: {
    main: '#f44366',
    light: '#e57373',
    dark: '#c62828',
    contrastText: '#ffffff',
  },
  warning: {
    main: '#ff9800',
    light: '#ffb74d',
    dark: '#f57c00',
    contrastText: 'rgba(0,0,0,.87)',
  },
  info: {
    main: '#2196f3',
    light: '#64b5f6',
    dark: '#1976d2',
    contrastText: '#ffffff',
  },
  success: {
    main: '#4caf50',
    light: '#81c784',
    dark: '#388e3c',
    contrastText: '#ffffff',
  },
  disabled: {
    main: '#eeeeee',
    contrastText: '#ffffff',
    dark: '#9e9e9e',
  },
  appHeader: {
    backgroundColor: '#1a237e',
    contrastBgColor: '#ffffff',
  },
  appSidebar: {
    backgroundColor: '#3949ab',
    contrastBgColor: '#ffffff',
    menuItem: {
      activeBackgroundColor: '#5c6bc0',
      hoverBackgroundColor: '#5c6bc0',
    },
  },
  appStatus: {
    submitted: {
      main: '#2196f3',
      contrastText: '#ffffff',
    },
    approved: {
      main: '#4caf50',
      contrastText: '#ffffff',
    },
    published: {
      main: '#4caf50',
      contrastText: '#ffffff',
    },
    rejected: {
      main: '#f44336',
      contrastText: '#ffffff',
    },
    sold: {
      main: '#424242',
      contrastText: '#ffffff',
    },
    pending: {
      main: '#ff9800',
      contrastText: '#ffffff',
    },
    fraud: {
      main: '#424242',
      contrastText: '#ffffff',
    },
    unlisted: {
      main: '#9e9e9e',
      contrastText: '#ffffff',
    },
  },

  appWorkflow: {
    statusFirstInit: {
      main: '#2196f3',
      contrastText: '#ffffff',
    },
    statusActive: {
      main: '#4caf50',
      contrastText: '#ffffff',
    },
    statusBanned: {
      main: '#f44336',
      contrastText: '#ffffff',
    },
    statusBlackListed: {
      main: '#f44336',
      contrastText: '#ffffff',
    },
    statusInActive: {
      main: '#FAC711',
      contrastText: '#000000',
    },

    stateBuyer: {
      main: '#2196f3',
      contrastText: '#ffffff',
    },
    stateSeller: {
      main: '#4caf50',
      contrastText: '#ffffff',
    },
    unverifiedSeller: {
      main: '#f44336',
      contrastText: '#ffffff',
    },

    buyer: {
      main: '#2196f3',
      contrastText: '#ffffff',
    },
    seller: {
      main: '#4caf50',
      contrastText: '#ffffff',
    },
    sellerGold: {
      main: '#FAC711',
      contrastText: '#ffffff',
    },
    sellerSilver: {
      main: '#652CB3',
      contrastText: '#ffffff',
    },
    sellerBronze: {
      main: '#14CDD4',
      contrastText: '#ffffff',
    },

    stateStartSellerApp: {
      main: '#808080',
      contrastText: '#ffffff',
    },
    stateStartFirstCreate: {
      main: '#2196f3',
      contrastText: '#ffffff',
    },
    stateWaitingForSellerApproval: {
      main: '#FAC711',
      contrastText: '#000000',
    },
    stateSellerApproval: {
      main: '#4caf50',
      contrastText: '#ffffff',
    },
    stateWaitingForEditingApplication: {
      main: '#808080',
      contrastText: '#ffffff',
    },
    stateRejectedApplication: {
      main: '#f44336',
      contrastText: '#ffffff',
    },
    stateBlacklistApplication: {
      main: '#f44336',
      contrastText: '#ffffff',
    },
    stateDeletedApplication: {
      main: '#f44336',
      contrastText: '#ffffff',
    },

    orderStateCanceled: {
      main: '#9e9e9e',
      contrastText: '#ffffff',
    },
    orderStateExpired: {
      main: '#f44336',
      contrastText: '#ffffff',
    },
    orderStateFailed: {
      main: '#f44336',
      contrastText: '#ffffff',
    },
    orderStateOpen: {
      main: '#2196f3',
      contrastText: '#ffffff',
    },
    orderStatePaid: {
      main: '#4caf50',
      contrastText: '#ffffff',
    },
    orderStateRefunded: {
      main: '#ff9800',
      contrastText: '#000000',
    },

    statePromoCodeStart: {
      main: '#2196f3',
      contrastText: '#ffffff',
    },
    statePromoCodeAvailable: {
      main: '#4caf50',
      contrastText: '#ffffff',
    },
    statePromoCodeUsed: {
      main: '#9e9e9e',
      contrastText: '#ffffff',
    },
    statePromoCodeExpired: {
      main: '#ff9800',
      contrastText: '#ffffff',
    },
    statePromoCodeInactive: {
      main: '#f44336',
      contrastText: '#ffffff',
    },
  },
  appPossession: {
    jkke: {
      main: '#5880CF',
      contrastText: '#ffffff',
    },
    rejected: {
      main: '#f44336',
      contrastText: '#ffffff',
    },
    t2hOfficial: {
      main: '#264787',
      contrastText: '#ffffff',
    },
    t2hOfficialConsignment: {
      main: '#C30700',
      contrastText: '#ffffff',
    },
  },
  appCrStatus: {
    pending: {
      main: '#e0e0e0',
      contrastText: '#000000',
    },
    notApproved: {
      main: '#C30700',
      contrastText: '#ffffff',
    },
    approved: {
      main: '#4caf50',
      contrastText: '#ffffff',
    },
  },
  appCleansingStatus: {
    notChecked: {
      main: '#e0e0e0',
      contrastText: '#000000',
    },
    checked: {
      main: '#4caf50',
      contrastText: '#ffffff',
    },
    trash: {
      main: '#C30700',
      contrastText: '#ffffff',
    },
    pending: {
      main: '#FFEB3B',
      contrastText: '#424242',
    },
  },
  appSnsReportStatus: {
    submitted: {
      main: '#2196f3',
      contrastText: '#ffffff',
    },
    approved: {
      main: '#4caf50',
      contrastText: '#ffffff',
    },
    rejected: {
      main: '#f44336',
      contrastText: '#ffffff',
    },
    markAsDuplicated: {
      main: '#ff9800',
      contrastText: '#ffffff',
    },
  },
  appListingReportStatus: {
    waiting: {
      main: '#2196f3',
      contrastText: '#ffffff',
    },
    done: {
      main: '#4caf50',
      contrastText: '#ffffff',
    },
    markAsDuplicated: {
      main: '#808080',
      contrastText: '#ffffff',
    },
    ignore: {
      main: '#f44336',
      contrastText: '#ffffff',
    },
  },
  control: {
    border: {
      main: '#9e9e9e',
      hover: '#000000',
      focus: '#000000',
    },
  },
  table: {
    header: {
      main: '#ffffff',
      contrastText: '#000000',
    },
    body: {
      main: '#ffffff',
      contrastText: '#000000',
    },
  },
  background: {
    default: '#ffffff',
    paper: '#ffffff',
  },
};

export const materialPalette: PaletteOptions = {
  primary: colors.primary,
  secondary: colors.secondary,
  error: colors.error,
  warning: colors.warning,
  info: colors.info,
  success: colors.success,
  background: colors.background,
  action: {
    disabled: colors.disabled.dark,
    disabledBackground: colors.disabled.main,
    disabledOpacity: 1,
  },
};
