import React, { FC } from 'react';

import { Backdrop, CircularProgress, createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.modal + 1,
      color: '#fff',
    },
  }),
);

type AppBackdropProps = {
  open: boolean;
};

const AppBackdrop: FC<AppBackdropProps> = ({ open }: AppBackdropProps) => {
  const classes = useStyles();

  return open ? (
    <Backdrop className={classes.backdrop} open>
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : null;
};

export default AppBackdrop;
