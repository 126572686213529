import { makeStyles } from '@material-ui/core';

const useTypographyStyles = makeStyles((theme) => ({
  textWeightBold: {
    fontWeight: 700,
  },
  successText: {
    color: theme.palette.success.main,
  },
  warningText: {
    color: theme.palette.warning.main,
  },
  infoText: {
    color: theme.palette.info.main,
  },
  errorText: {
    color: theme.palette.error.main,
  },
  greyText: {
    color: theme.palette.grey[500],
  },
  greyText600: {
    color: theme.palette.grey[600],
  },
  breakwordText: {
    wordWrap: 'break-word',
  },
  noDecorationText: {
    textDecoration: 'none',
  },
  whiteText: {
    color: theme.palette.common.white,
  },
  blackText: {
    color: theme.palette.common.black,
  },
  noUserSelectText: {
    userSelect: 'none',
  },
}));

export default useTypographyStyles;
