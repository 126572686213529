import React, { FC, useCallback } from 'react';

import { Snackbar } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

import AppBackdrop from '../components/ui/AppBackdrop';
import useGlobalUi from '../hooks/useGlobalUi';
import { AppGlobalUiHookProps } from '../models';

const defaultValue: AppGlobalUiHookProps = {
  snackbar: null,
  showSnackbar: () => null,
  closeSnackbar: () => null,
  isAppLoading: false,
  setAppLoading: () => null,
  breadcrumb: [],
  setBreadcrumb: () => null,
  appBarTitle: 'T2H CRM',
  setAppBarTitle: () => null,
  appBarBackButtonUrl: '',
  setAppBarBackButtonUrl: () => null,
};

const AppGlobalUiContext = React.createContext<AppGlobalUiHookProps>(defaultValue);
const { Provider } = AppGlobalUiContext;

const Alert = (props: AlertProps) => <MuiAlert elevation={6} variant="filled" {...props} />;

const AppGlobalUiProvider: FC = (props) => {
  const { children } = props;
  const values = useGlobalUi();
  const { snackbar, closeSnackbar, isAppLoading } = values;

  const handleClose = useCallback((event: React.SyntheticEvent) => {
    if (typeof snackbar?.config?.onClose === 'function') {
      snackbar.config.onClose(event);
    }

    closeSnackbar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Provider value={values}>
      {children}
      <Snackbar open={!!snackbar} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={snackbar?.type} {...snackbar?.config}>
          {snackbar?.content}
        </Alert>
      </Snackbar>
      <AppBackdrop open={isAppLoading} />
    </Provider>
  );
};

export { AppGlobalUiProvider, AppGlobalUiContext };
