import React, { FC, useCallback, useContext, useEffect, useState } from 'react';

import { Box, Grid, Typography } from '@material-ui/core';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from 'recharts';

import { AppGlobalUiContext } from '../../../../context/AppGlobalUiContext';
import { SearchKeyword } from '../../../../models/s3-td.model';
import { getS3TdFile } from '../../../../services/s3-td';
import useTypographyStyles from '../../../../theme/typography.style';

export type DashboardCreateListingByCategoryProps = {
  selectPeriod: string;
  period: string;
};

const inputLabels = [
  { key: 'accessory', color: '#BAEDBD' },
  { key: 'agriculturalmachinery', color: '#89B4F8' },
  { key: 'battery', color: '#F4872E' },
  { key: 'boat', color: '#64D6D6' },
  { key: 'bus', color: '#2DAAE1' },
  { key: 'clothessafetyaccessory', color: '#EE6868' },
  { key: 'equipment', color: '#B6C944' },
  { key: 'heavymachinery', color: '#346BF1' },
  { key: 'lubricantchemical', color: '#AEC4D8' },
  { key: 'motorbike', color: '#9350E5' },
  { key: 'other', color: '#D374DE' },
  { key: 'partengine', color: '#F8D46E' },
  { key: 'pickup', color: '#98A3F6' },
  { key: 'picpickupkup', color: '#907361' },
  { key: 'service', color: '#67AD5B' },
  { key: 'truck', color: '#1C2379' },
  { key: 'truckbodytrailer', color: '#5F6BBA' },
  { key: 'van', color: '#3C49A5' },
  { key: 'wheeltire', color: '#EFBD44' },
];

const DashboardCreateListingByCategory: FC<DashboardCreateListingByCategoryProps> = (props) => {
  const { selectPeriod, period } = props;
  const typoClasses = useTypographyStyles();
  const { t } = useTranslation();
  const { setAppLoading, showSnackbar } = useContext(AppGlobalUiContext);
  const [createListingData, setCreateListingData] = useState<SearchKeyword[]>([]);

  const requestGetData = async () => {
    const result = await getS3TdFile(encodeURIComponent('graph_create_listing_by_each_category.csv'));
    setAppLoading(true);
    if (result.data?.data) {
      const dataFilter = result.data?.data.filter((item) => item.filter === selectPeriod);
      const sortedData = [...dataFilter].sort((a, b) => parseInt(b.name, 10) - parseInt(a.name, 10));
      const currentTimestamp = Math.floor(Date.now() / 1000);

      const newData = sortedData.map((item) => ({
        ...item,
        name: dayjs.unix(currentTimestamp - parseInt(item.name || '0', 10) * 86400).format('D MMM'),
      }));

      setCreateListingData(newData);
    } else {
      showSnackbar(
        t('dashboard:error.list.requestFailed', {
          fieldName: t('dashboard:v3.title.createListingByCategory').toString(),
        }),
        'error',
      );
    }
    setAppLoading(false);
  };

  useEffect(() => {
    requestGetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectPeriod]);

  const [barProps, setBarProps] = useState(
    inputLabels.reduce(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (a: any, { key }) => {
        a[key] = false;
        return a;
      },
      { hover: null },
    ),
  );

  const selectBar = useCallback(
    (e) => {
      setBarProps({
        ...barProps,
        [e.dataKey]: !barProps[e.dataKey],
        hover: null,
      });
    },
    [barProps],
  );

  return (
    <>
      {createListingData.length > 0 && (
        <Box mb={2} p={2}>
          <Grid container direction="row" spacing={2} alignItems="center">
            <Grid item xs="auto" sm={8}>
              <Box display="flex" flexDirection="row">
                <Typography style={{ fontWeight: 'bold' }}>
                  {t('dashboard:v3.title.createListingByCategory')}
                </Typography>
                <Box mx={2}>
                  <Typography className={typoClasses.infoText}>
                    Total : {parseInt(createListingData[0].totalcount, 10).toLocaleString()}
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="row" textAlign="right">
                  {createListingData[0].growth !== '0%' ? (
                    <Box display="flex" flexDirection="row">
                      {createListingData[0].isgrowing === 'true' ? (
                        <TrendingUpIcon className={typoClasses.successText} style={{ marginRight: '4px' }} />
                      ) : (
                        <TrendingDownIcon className={typoClasses.errorText} style={{ marginRight: '4px' }} />
                      )}

                      <Typography
                        style={{ fontWeight: 'bold' }}
                        className={
                          createListingData[0].isgrowing === 'true' ? typoClasses.successText : typoClasses.errorText
                        }>
                        {parseInt(
                          createListingData[0].growth.substring(0, createListingData[0].growth.length - 1),
                          10,
                        ).toLocaleString()}
                        %
                      </Typography>
                    </Box>
                  ) : (
                    <Typography style={{ fontWeight: 'bold' }} className={typoClasses.greyText}>
                      0 %
                    </Typography>
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item xs="auto" sm={4}>
              <Typography className={typoClasses.greyText600} align="right">
                From last {period} day
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}

      {createListingData.length > 0 && (
        <Box overflow="hidden">
          <BarChart
            width={860}
            height={650}
            data={createListingData}
            barCategoryGap={period === '7' ? 40 : 6}
            style={{ margin: '0 auto' }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend iconType="square" onClick={selectBar} />
            {inputLabels.map((label) => (
              <Bar
                key={label.key}
                dataKey={label.key}
                fill={label.color}
                stackId="a"
                hide={barProps[label.key] === true}
                fillOpacity={Number(barProps.hover === label.key || !barProps.hover ? 1 : 0.6)}
              />
            ))}
          </BarChart>
        </Box>
      )}
    </>
  );
};

export default DashboardCreateListingByCategory;
