import { RouteConfig, UserRole } from './models';
import AbTestingMain from './pages/ab-testing/Main';
import BlogSlugMain from './pages/blog-slug/Main';
import DashboardPage from './pages/Dashboard';
import ListingMain from './pages/listing/Main';
import ListingMainV1 from './pages/listing-v1/Main';
import Login from './pages/Login';
import MainPage from './pages/MainPage';
import MasterDataMain from './pages/master-data/Main';
import MenuMain from './pages/menu/Main';
import ProductMain from './pages/product/Main';
import PromoCodeMain from './pages/promo-code/Main';
import ReportListingMain from './pages/report-listing/Main';
import SellerApplicationMain from './pages/seller-applications/Main';
import SettingsMain from './pages/settings/Main';
import SNSMain from './pages/sns/Main';
import Unauthorized from './pages/Unauthorized';
import UserMain from './pages/users/Main';
import LoginBonusMain from './pages/v3/daily-challenge/login-bonus/Main';
import FakhaMain from './pages/v3/fakha/Main';
import InterviewLogMain from './pages/v3/interview-log/Main';
import OrderApproveMain from './pages/v3/order-approve/Main';
import SellerApplicationTcMain from './pages/v3/seller-applications/Main';
import UserApplicationMain from './pages/v3/user-applications/Main';
import UsersMain from './pages/v3/users/Main';

const routes: RouteConfig[] = [
  {
    key: 'HOME',
    path: '/',
    component: MainPage,
  },
  {
    key: 'DASHBOARD',
    path: '/dashboard',
    component: DashboardPage,
    protected: true,
    translations: ['dashboard'],
  },
  {
    key: 'LOGIN',
    path: '/login',
    component: Login,
  },
  {
    key: 'USERS',
    path: '/users',
    exact: false,
    component: UserMain,
    protected: true,
    translations: ['user', 'seller-applications'],
  },
  {
    key: 'LISTINGV1',
    path: '/listing-v1',
    exact: false,
    component: ListingMainV1,
    protected: true,
    forRoles: [UserRole.GM],
    translations: ['user', 'listing'],
  },
  {
    key: 'LISTING',
    path: '/listing',
    exact: false,
    component: ListingMain,
    protected: true,
    translations: ['user', 'listing', 'seller-applications'],
  },
  {
    key: 'SELLER_APPLICATIONS',
    path: '/seller-applications',
    exact: false,
    component: SellerApplicationMain,
    protected: true,
    translations: ['user', 'seller-applications'],
  },
  {
    key: 'MENU',
    path: '/menus',
    exact: false,
    component: MenuMain,
    protected: true,
    forRoles: [UserRole.GM, UserRole.Manager],
    translations: ['menu', 'master-data'],
  },
  {
    key: 'MASTER_DATA',
    path: '/master-data',
    exact: false,
    component: MasterDataMain,
    protected: true,
    forRoles: [UserRole.GM, UserRole.Manager],
    translations: ['master-data'],
  },
  // TODO: ### **return this route back after board configuration spec have been done correctly.**
  // - [ ] specs confirm on board-setting's configuration
  // - [ ] board-setting's configuration CRU implementation
  // {
  //   key: 'KANBAN-SETTINGS',
  //   path: '/board-setting',
  //   exact: false,
  //   component: BoardSettingMain,
  //   protected: true,
  //   translations: ['user', 'board', 'listing'],
  // },

  {
    key: 'SETTINGS',
    path: '/settings',
    exact: false,
    component: SettingsMain,
    protected: true,
    forRoles: [UserRole.GM, UserRole.Manager],
    translations: ['settings'],
  },
  {
    key: 'UNAUTHORIZED',
    path: '/unauthorized',
    component: Unauthorized,
    protected: true,
  },
  {
    key: 'SNS',
    path: '/sns',
    exact: false,
    component: SNSMain,
    protected: true,
    translations: ['user', 'sns', 'listing', 'seller-applications', 'dashboard'],
  },
  {
    key: 'BLOG_SLUG',
    path: '/blog-slug',
    exact: false,
    component: BlogSlugMain,
    forRoles: [UserRole.GM, UserRole.Manager],
    protected: true,
  },
  {
    key: 'USER_APPLICATIONS',
    path: '/v3/user-applications',
    exact: false,
    component: UserApplicationMain,
    protected: true,
    translations: ['user', 'user-applications'],
  },
  {
    key: 'USERS',
    path: '/v3/users',
    exact: false,
    component: UsersMain,
    protected: true,
    translations: ['user', 'seller-applications', 'shopping-cart', 'order', 'interview-log'],
  },
  {
    key: 'SELLER_APPLICATIONS',
    path: '/v3/seller-applications',
    exact: false,
    component: SellerApplicationTcMain,
    protected: true,
    translations: ['user', 'seller-applications'],
  },
  {
    key: 'PRODUCT',
    path: '/product',
    exact: false,
    component: ProductMain,
    protected: true,
    translations: ['product'],
  },
  {
    key: 'PROMO_CODE',
    path: '/promo-code',
    exact: false,
    component: PromoCodeMain,
    protected: true,
    translations: ['promo-code'],
  },
  {
    key: 'INTERVIEW_LOG',
    path: '/v3/interview-log',
    exact: false,
    component: InterviewLogMain,
    protected: true,
    translations: ['user', 'interview-log'],
  },
  {
    key: 'FAKHA',
    path: '/v3/fakha',
    exact: false,
    component: FakhaMain,
    protected: true,
    translations: ['user', 'fakha'],
  },
  {
    key: 'ORDER_APPROVE',
    path: '/v3/order-approve',
    exact: false,
    component: OrderApproveMain,
    protected: true,
    translations: ['user', 'order', 'shopping-cart'],
  },
  {
    key: 'AB_TESTING',
    path: '/ab-testing',
    exact: false,
    component: AbTestingMain,
    protected: true,
    forRoles: [UserRole.GM, UserRole.Manager],
    translations: ['ab-testing'],
  },
  {
    key: 'DAILY_CHALLENGE_LOGIN_BONUS',
    path: '/daily-challenge-login-bonus',
    exact: false,
    component: LoginBonusMain,
    protected: true,
    translations: ['daily-challenge'],
  },
  {
    key: 'REPORT_LISTING',
    path: '/report-listing',
    exact: false,
    component: ReportListingMain,
    protected: true,
    translations: ['report-listing'],
  },
];

export default routes;
