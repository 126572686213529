import React, { FC } from 'react';

import { Box, OutlinedInput, Typography, makeStyles } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import dayjs from 'dayjs';
import { Controller, UseControllerProps, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import i18n from '../../../../../i18n';
import { FakhaData, FakhaDataRequest } from '../../../../../models/fakha.model';
import AppButton from '../../../../ui/AppButton';
import AppDialog from '../../../../ui/AppDialog';
import AppFormControl from '../../../../ui/AppFormControl';
import DataBlock from '../../../common/DataBlock';

export type FakhaItemDialogProps = {
  fakhaItem: FakhaData;
  title: string;
  open: boolean;
  onCloseClick?: () => void;
  onFormSubmit: (data: FakhaDataRequest) => void;
  rules?: { [x: string]: UseControllerProps['rules'] };
};

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  numberInput: {
    MozAppearance: 'none',
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
  confirmDialogBox: {
    width: '100%',
    maxWidth: '1000px',
    [theme.breakpoints.up('md')]: {
      minWidth: '400px',
    },
  },
  buttonGroups: {
    '& > *': {
      margin: `0 ${theme.spacing(1)}px`,
    },
  },
  extLinkIcon: {
    fontSize: 18,
    verticalAlign: 'middle',
    marginLeft: 3,
  },
}));

const FakhaItemDialog: FC<FakhaItemDialogProps> = (props) => {
  const classes = useStyles();
  const { fakhaItem, open, title, onCloseClick, onFormSubmit } = props;
  const { t } = useTranslation();
  const dateDisplayFormat = i18n.language === 'th' ? 'D MMM BBBB - HH:mm:ss' : 'D MMM YYYY - HH:mm:ss';

  const {
    handleSubmit,
    getValues,
    control,
    formState: { errors },
  } = useForm<FakhaDataRequest>({
    mode: 'onChange',
    defaultValues: {
      crNote: fakhaItem.crNote || '',
    },
  });

  const onSaveClick = () => {
    if (typeof onFormSubmit === 'function') {
      onFormSubmit(getValues());
    }
  };

  return (
    <>
      <AppDialog
        open={open}
        title={title}
        okButtonText="common:button.close"
        okButtonColor="default"
        okButtonVariant="outlined"
        closeOnOkClicked
        noActionBar
        dialogProps={{
          fullWidth: true,
          maxWidth: 'sm',
          disableBackdropClick: true,
        }}>
        <form onSubmit={handleSubmit(onSaveClick)}>
          <Box>
            <Box mb={1}>
              <DataBlock
                label="fakha:dialog.role"
                data={t(`user:common.roleShort.${fakhaItem.role.toLocaleLowerCase()}`).toString()}
                noNewLine
              />

              <DataBlock
                label="fakha:dialog.userHashID"
                data={
                  fakhaItem?.userHashId ? (
                    <a href={`/v3/users/${fakhaItem?.userHashId}`} target="_blank" rel="noreferrer">
                      {fakhaItem?.userHashId}
                      <OpenInNewIcon className={classes.extLinkIcon} />
                    </a>
                  ) : null
                }
                noNewLine
              />
              <DataBlock
                noNewLine
                label="fakha:dialog.displayName"
                data={fakhaItem?.displayName ? fakhaItem?.displayName : '-'}
              />
              {fakhaItem?.email && (
                <DataBlock label="fakha:dialog.email" data={fakhaItem.email} noNewLine noWrapText={false} />
              )}
              {fakhaItem?.mobilePhone && (
                <DataBlock label="fakha:dialog.mobile" data={fakhaItem.mobilePhone} noNewLine />
              )}

              {fakhaItem?.type && (
                <DataBlock label="fakha:dialog.type" data={t(`fakha:list.list.type.${fakhaItem.type}`)} noNewLine />
              )}

              {fakhaItem?.remark && (
                <DataBlock label="fakha:dialog.remark" data={fakhaItem.remark} noNewLine noWrapText={false} />
              )}

              <DataBlock
                label="fakha:dialog.createdAt"
                data={dayjs(fakhaItem?.createdAt).format(`${dateDisplayFormat}`)}
                noNewLine
              />

              <DataBlock
                label="fakha:dialog.updatedAt"
                data={dayjs(fakhaItem?.updatedAt).format(`${dateDisplayFormat}`)}
                noNewLine
              />
            </Box>
            <Box>
              <Typography style={{ fontWeight: 'bold', marginBottom: '12px' }}>Note :</Typography>
              <Controller
                name="crNote"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <AppFormControl error={!!errors.crNote}>
                    <OutlinedInput {...field} multiline rows={2} id="fakha-crNote" fullWidth error={!!errors.crNote} />
                  </AppFormControl>
                )}
              />
            </Box>
          </Box>

          <Box display="flex" my={2} justifyContent="flex-end" className={classes.buttonGroups}>
            <AppButton type="submit" color="primary">
              {t('common:button.save')}
            </AppButton>

            <AppButton
              variant="outlined"
              color="default"
              onClick={() => {
                if (typeof onCloseClick === 'function') {
                  onCloseClick();
                }
              }}>
              {t('common:button.close')}
            </AppButton>
          </Box>
        </form>
      </AppDialog>
    </>
  );
};

export default FakhaItemDialog;
