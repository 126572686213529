import thaiAddressData from '../../data/thaiAddressData';

type ItemAddress = {
  district: string;
  province: string;
  zipcode: number;
};

export default function getThaiAddressData(): ItemAddress[] | null {
  try {
    return thaiAddressData;
  } catch (error) {
    return null;
  }
}
