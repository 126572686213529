import React, { FC, useEffect, useState } from 'react';

import { AppBar, Box, Grid, makeStyles, TextField, Toolbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import usePrivilege from '../../../../hooks/usePrivilege';
import { WebConfig } from '../../../../models';
import AppButton from '../../../ui/AppButton';
import AppFormControl from '../../../ui/AppFormControl';

export type CommonWebConfigFieldsProps = {
  config: WebConfig;
  onSaveClick: () => void;
  onDataChange: (configData: Partial<WebConfig>) => void;
  showModifiedDataWarning?: boolean;
};

type CommonWebConfigFormData = {
  configType: string;
};

const useStyles = makeStyles((theme) => ({
  stickyActionContainer: {
    position: 'fixed',
    top: 'auto',
    left: '0',
    bottom: 0,
    right: 0,
    zIndex: theme.zIndex.drawer - 1,
    backgroundColor: theme.palette.background.paper,
  },
  stickyActionToolbar: {
    justifyContent: 'flex-end',
  },
}));

const CommonWebConfigFields: FC<CommonWebConfigFieldsProps> = (props) => {
  const { config, onDataChange, onSaveClick, showModifiedDataWarning } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const [isBackToTopDisplayed, setIsBackToTopDisplayed] = useState<boolean>(false);
  const { control, getValues } = useForm<CommonWebConfigFormData>({
    mode: 'onChange',
    defaultValues: {
      configType: config.configType,
    },
  });
  const { canPerform } = usePrivilege();
  const canUpdateWebConfig = canPerform('webConfig', 'update');

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useEffect((): any => {
    let isSubscribed = true;

    const scrollEventHandler = () => {
      if (isSubscribed) {
        const scrollTop = window.scrollY || document.body.scrollTop || document.documentElement.scrollTop;
        setIsBackToTopDisplayed((scrollTop || 0) > 140);
      }
    };

    window.addEventListener('scroll', scrollEventHandler, false);

    return () => {
      window.removeEventListener('scroll', scrollEventHandler, false);
      isSubscribed = false;
    };
  }, []);

  const goBackToTop = () => {
    window.scrollTo(0, 0);
  };

  const onFormDataChanged = () => {
    onDataChange({
      configType: getValues('configType'),
    });
  };

  return (
    <>
      {canUpdateWebConfig && (
        <Box display="flex" justifyContent="flex-end" mb={1}>
          <AppButton color="primary" onClick={onSaveClick}>
            {t('common:button.save')}
          </AppButton>
        </Box>
      )}
      {showModifiedDataWarning && (
        <Box mb={1}>
          <Alert severity="warning">{t('settings:message.config.modifiedDataWarning')}</Alert>
        </Box>
      )}
      {!!config && isBackToTopDisplayed && (
        <AppBar className={classes.stickyActionContainer}>
          <Toolbar className={classes.stickyActionToolbar}>
            <AppButton color="default" variant="outlined" onClick={goBackToTop}>
              {t('common:button.backToTop')}
            </AppButton>
            {canUpdateWebConfig && (
              <Box ml={1}>
                <AppButton color="primary" onClick={onSaveClick}>
                  {t('common:button.save')}
                </AppButton>
              </Box>
            )}
          </Toolbar>
        </AppBar>
      )}
      <form onChange={onFormDataChanged}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              defaultValue={config.configType}
              control={control}
              name="configType"
              render={({ field }) => (
                <AppFormControl boxProps={{ mt: 3 }}>
                  <TextField
                    {...field}
                    variant="outlined"
                    label={t('settings:dialog.config.configType').toString()}
                    disabled={!canUpdateWebConfig}
                  />
                </AppFormControl>
              )}
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default CommonWebConfigFields;
