import React, { FC, useState } from 'react';

import { makeStyles, SortDirection, TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { TableColumn } from '../../../models';
import useAppTableStyles from '../../../theme/table.style';

export type AppSortableTableHeadProps = {
  columns: TableColumn[];
  orderBy: string;
  orderDirection: SortDirection;
  onSortChanged?: (columnName: string, direction: SortDirection) => void;
};

const useStyles = makeStyles((theme) => ({
  headerText: {
    order: 2,
  },
  sortableLabel: {
    '&:hover': {
      color: theme.palette.primary.light,
    },
  },
  headerSortableIcon: {
    order: 1,
  },
  hiddenSortableIcon: {
    display: 'none',
  },
}));

const AppSortableTableHead: FC<AppSortableTableHeadProps> = (props) => {
  const classes = useStyles();
  const { columns, orderBy, orderDirection, onSortChanged } = props;
  const { t } = useTranslation();
  const tableClasses = useAppTableStyles();
  const [sortDirection, setSortDirection] = useState<SortDirection>(orderDirection);
  const [sortColumn, setSortColumn] = useState<string>(orderBy);

  const onSortingClicked = (column: TableColumn) => {
    let colName = sortColumn;
    let direction = sortDirection;
    if (column.name !== sortColumn) {
      colName = column.name;
      direction = 'asc';
    } else {
      direction = sortDirection === 'desc' ? 'asc' : 'desc';
    }

    setSortColumn(colName);
    setSortDirection(direction);

    if (typeof onSortChanged === 'function') {
      onSortChanged(colName, direction);
    }
  };

  return (
    <>
      <colgroup>
        {columns.map((column) => (
          <col key={column.name} width={column.width || 'auto'} />
        ))}
      </colgroup>
      <TableHead>
        <TableRow>
          {columns.map((column) => (
            <TableCell
              key={column.name}
              className={tableClasses.headerCell}
              sortDirection={!column.unsortable && sortColumn === column.name ? sortDirection : false}>
              {!column.unsortable && (
                <TableSortLabel
                  active={sortColumn === column.name}
                  direction={sortColumn === column.name ? (sortDirection as 'asc' | 'desc') : 'asc'}
                  onClick={() => onSortingClicked(column)}
                  classes={{
                    root: classes.sortableLabel,
                    icon: clsx(classes.headerSortableIcon, {
                      [classes.hiddenSortableIcon]: sortColumn !== column.name,
                    }),
                  }}>
                  <span className={classes.headerText}>{t(column.label)}</span>
                  {sortColumn === column.name ? (
                    <span className={tableClasses.visuallyHidden}>
                      {sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              )}
              {column.unsortable && <span className={classes.headerText}>{t(column.label)}</span>}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
};

export default AppSortableTableHead;
