import React, { FC, useState, MouseEvent } from 'react';

import { makeStyles, TableRow } from '@material-ui/core';
import clsx from 'clsx';
import dayjs from 'dayjs';

import { getFileName, getRgbaFromHex } from '../../../../helpers/utils';
import { AppTableConditions, FileItem, TableColumn } from '../../../../models';
import useAppTableStyles from '../../../../theme/table.style';
import useTypographyStyles from '../../../../theme/typography.style';
import AppDatetimeText from '../../../ui/AppDatetimeText';
import AppFileTypeIcon from '../../../ui/AppFileTypeIcon';
import AppTableCell from '../../../ui/AppTableCell';
import AppTableList from '../../../ui/AppTableList';

export type FileListTableProps = {
  currentConditions: AppTableConditions;
  totalFiles: number;
  isLoadingData: boolean;
  fileList: FileItem[];
  onTableConditionsChanged: (values: AppTableConditions) => void;
  onFileClick?: (file: FileItem) => void;
  onFileDoubleClick?: (file: FileItem) => void;
  selectedFiles?: FileItem[];
  contextedFile: FileItem | null;
  onContextMenuClick?: (e: MouseEvent<HTMLElement>, file: FileItem) => void;
};

const useStyles = makeStyles((theme) => ({
  fixedTableContainer: {
    maxHeight: 'calc(100vh - 320px)',
  },
  selectedItem: {
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: theme.palette.info.main,
      '& .MuiTableCell-body': {
        color: theme.palette.info.contrastText,
      },
      '& [class*="greyText"]': {
        color: getRgbaFromHex(theme.palette.info.contrastText, 60),
      },
    },
  },
  contextedItem: {
    '& .MuiTableCell-body': {
      color: theme.palette.primary.main,
    },
  },
}));

const fileListTableColumns: TableColumn[] = [
  { name: 'type', label: 'common:mediaLibrary.fields.type', width: 90, unsortable: true },
  { name: 'fileName', label: 'common:mediaLibrary.fields.fileName' },
  { name: 'fileUrl', label: 'common:mediaLibrary.fields.fileUrl' },
  { name: 'fileSize', label: 'common:mediaLibrary.fields.fileSize', width: 180 },
  { name: 'createdAt', label: 'common:message.createdAt', width: 180 },
];

const FileListTable: FC<FileListTableProps> = (props) => {
  const {
    totalFiles,
    isLoadingData,
    currentConditions,
    fileList,
    onFileClick,
    onFileDoubleClick,
    onTableConditionsChanged,
    selectedFiles = [],
    contextedFile,
    onContextMenuClick,
  } = props;
  const tableClasses = useAppTableStyles();
  const typoClasses = useTypographyStyles();
  const classes = useStyles();
  const [tableConditions, setTableConditions] = useState<AppTableConditions>(currentConditions);

  const onTableConditionChangedHandler = (values: AppTableConditions) => {
    if (JSON.stringify(values) !== JSON.stringify(tableConditions)) {
      setTableConditions(values);
      onTableConditionsChanged(values);
    }
  };

  const onFileItemClick = (file: FileItem) => {
    if (typeof onFileClick === 'function') {
      onFileClick(file);
    }
  };

  const onFileItemDoubleClick = (file: FileItem) => {
    if (typeof onFileDoubleClick === 'function') {
      onFileDoubleClick(file);
    }
  };

  const onFileContextHandler = (e: MouseEvent<HTMLElement>, file: FileItem) => {
    if (typeof onContextMenuClick === 'function') {
      onContextMenuClick(e, file);
    }
  };

  const isSelected = (file: FileItem): boolean => !!selectedFiles.find((item: FileItem) => item.id === file.id);
  const isContexted = (file: FileItem): boolean => !!contextedFile && contextedFile.id === file.id;

  return (
    <AppTableList
      {...tableConditions}
      aria-label="user-list"
      columns={fileListTableColumns}
      totalDataCount={totalFiles}
      hasPagination
      onTableConditionChanged={onTableConditionChangedHandler}
      isLoading={isLoadingData}
      tableContainerProps={{
        className: classes.fixedTableContainer,
      }}
      tableBodyContent={
        fileList.length > 0 && (
          <>
            {fileList.map((file: FileItem, index: number) => {
              const rowKey = `row-file-${index}`;
              const isSelectedFile = isSelected(file);
              const isContextedFile = isContexted(file);

              return (
                <TableRow
                  hover
                  key={rowKey}
                  classes={{
                    root: clsx(tableClasses.tableRow, tableClasses.clickableTableRow, classes.selectedItem, {
                      [classes.contextedItem]: isContextedFile && !isSelectedFile,
                    }),
                  }}
                  selected={isSelectedFile}
                  onClick={() => onFileItemClick(file)}
                  onDoubleClick={() => onFileItemDoubleClick(file)}
                  onContextMenu={(e) => onFileContextHandler(e, file)}>
                  <AppTableCell className={typoClasses.greyText}>
                    <AppFileTypeIcon filePath={file.fullUrl} />
                  </AppTableCell>
                  <AppTableCell className={typoClasses.noUserSelectText}>{getFileName(file.fullUrl)}</AppTableCell>
                  <AppTableCell className={typoClasses.noUserSelectText}>{file.fullUrl}</AppTableCell>
                  <AppTableCell className={typoClasses.noUserSelectText}>{(file.size / 1024).toFixed(2)}</AppTableCell>
                  <AppTableCell>
                    <AppDatetimeText
                      variant="subtitle2"
                      value={file.createdAt ? dayjs(file.createdAt) : null}
                      withTimeNewLine
                    />
                  </AppTableCell>
                </TableRow>
              );
            })}
          </>
        )
      }
    />
  );
};

export default FileListTable;
