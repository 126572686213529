import React, { FC, useState } from 'react';

import { TableRow } from '@material-ui/core';
import clsx from 'clsx';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { AppTableConditions, MasterIndex, TableColumn } from '../../../../../models';
import useAppTableStyles from '../../../../../theme/table.style';
import AppTableCell from '../../../../ui/AppTableCell';
import AppTableList from '../../../../ui/AppTableList';

const masterIndexTableColumns: TableColumn[] = [
  { name: 'name', label: 'master-data:common.field.name' },
  { name: 'thaiName', label: 'master-data:common.field.thaiName' },
  { name: 'slug', label: 'master-data:common.field.slug' },
  { name: 'attributeDataType', label: 'master-data:list.masterIndex.columns.attributeDataType' },
];

export type MasterIndexListTableProps = {
  currentConditions: AppTableConditions;
  totalMasterIndexes: number;
  isLoadingData: boolean;
  masterIndexList: MasterIndex[];
  onTableConditionsChanged: (values: AppTableConditions) => void;
};

const MasterIndexListTable: FC<MasterIndexListTableProps> = (props) => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { totalMasterIndexes, isLoadingData, currentConditions, masterIndexList, onTableConditionsChanged } = props;
  const tableClasses = useAppTableStyles();
  const [tableConditions, setTableConditions] = useState<AppTableConditions>(currentConditions);

  const onTableConditionChangedHandler = (values: AppTableConditions) => {
    if (JSON.stringify(values) !== JSON.stringify(tableConditions)) {
      setTableConditions(values);
      onTableConditionsChanged(values);
    }
  };

  const openMasterIndexDetailPage = (mIndex: MasterIndex) => {
    history.push(`${url}/${mIndex.uid}`);
  };

  return (
    <AppTableList
      {...tableConditions}
      aria-label="brand-list"
      columns={masterIndexTableColumns}
      totalDataCount={totalMasterIndexes}
      hasPagination
      onTableConditionChanged={onTableConditionChangedHandler}
      isLoading={isLoadingData}
      tableBodyContent={
        masterIndexList.length > 0 && (
          <>
            {masterIndexList.map((mIndex: MasterIndex) => (
              <TableRow
                hover
                key={mIndex.id}
                classes={{ root: clsx(tableClasses.tableRow, tableClasses.clickableTableRow) }}
                onClick={() => openMasterIndexDetailPage(mIndex)}>
                <AppTableCell>{mIndex.name}</AppTableCell>
                <AppTableCell>{mIndex.thaiName}</AppTableCell>
                <AppTableCell>{mIndex.slug}</AppTableCell>
                <AppTableCell>{mIndex.attributeDataType}</AppTableCell>
              </TableRow>
            ))}
          </>
        )
      }
    />
  );
};

export default MasterIndexListTable;
