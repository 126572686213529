import React, { FC } from 'react';

import { Box, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';

import Layout from '../components/templates/Layout';
import { APP_VERSION } from '../helpers/constants';
import useTypographyStyles from '../theme/typography.style';

type MainPageProps = unknown;

const useStyles = makeStyles((theme) => ({ pageContainer: { height: `calc(100vh - ${theme.spacing(3) * 2}px )` } }));

const cmsLogo = 'static/images/t2h-logo.svg';

const MainPage: FC<MainPageProps> = () => {
  const classes = useStyles();
  const typoClasses = useTypographyStyles();

  return (
    <Layout pageTitle="common:title.home">
      <Box
        component="div"
        className={classes.pageContainer}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center">
        <Box component="div" maxWidth="243px" mb="10px">
          <img src={cmsLogo} alt="Truck2Hand CMS" width="100%" />
        </Box>
        <Box>
          <Typography variant="h6" className={clsx(typoClasses.textWeightBold, typoClasses.greyText)}>{APP_VERSION}</Typography>
        </Box>
      </Box>
    </Layout>
  );
};

export default MainPage;
