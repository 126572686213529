import React, { FC, useCallback, useEffect, useState } from 'react';

import { getAuthToken } from '../../../../services/auth';
import PreviewContent from '../PreviewContent';

type LoadMediaProps = {
  imageUrl: string | undefined;
  onCloseClick: () => void;
};

type ImageObject = {
  width: number;
  height: number;
  orientation: 'square' | 'portrait' | 'landscape';
};

const LoadMedia: FC<LoadMediaProps> = (props) => {
  const { imageUrl } = props;
  const [currentImageUrl, setCurrentImageUrl] = useState<string | undefined>(imageUrl);
  const [imageObject, setImageObject] = useState<ImageObject>({
    width: 400,
    height: 400,
    orientation: 'square',
  });
  const isImageObjectValid = imageObject?.width > 0 && imageObject?.height > 0;

  const loadMedia = useCallback(async () => {
    if (!imageUrl) {
      return;
    }
    if (!imageUrl.includes('private')) {
      const newImageUrl = imageUrl;
      setCurrentImageUrl(newImageUrl);

      if (newImageUrl) {
        const img = new Image();
        img.onload = (e: Event) => {
          const imgObj = e.currentTarget as HTMLImageElement;
          let orientation: ImageObject['orientation'] = 'square';
          if (imgObj.width > imgObj.height) {
            orientation = 'landscape';
          } else if (imgObj.width < imgObj.height) {
            orientation = 'portrait';
          }
          setImageObject({
            width: imgObj.width,
            height: imgObj.height,
            orientation,
          });
        };
        img.src = newImageUrl;
      }
    } else {
      const tokenInfo = getAuthToken();
      const idToken = tokenInfo?.firebaseIdToken;

      if (idToken) {
        const result = await fetch(imageUrl, {
          headers: {
            'x-admin-token': idToken || '',
          },
        })
          .then(async (response) => {
            const blob = await response.blob();
            return URL.createObjectURL(blob);
          })
          .catch(() => null);

        if (result) {
          const newImageUrl = result;
          setCurrentImageUrl(newImageUrl);

          if (newImageUrl) {
            const img = new Image();
            img.onload = (e: Event) => {
              const imgObj = e.currentTarget as HTMLImageElement;
              let orientation: ImageObject['orientation'] = 'square';

              if (imgObj.width > imgObj.height) {
                orientation = 'landscape';
              } else if (imgObj.width < imgObj.height) {
                orientation = 'portrait';
              }
              setImageObject({
                width: imgObj.width,
                height: imgObj.height,
                orientation,
              });
            };
            img.src = newImageUrl;
          }
        }
      }
    }
  }, [imageUrl]);

  useEffect(() => {
    loadMedia();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageUrl]);

  if (!currentImageUrl) {
    return null;
  }

  return (
    <>
      {isImageObjectValid && currentImageUrl && (
        <PreviewContent currentImageUrl={currentImageUrl} imageObject={imageObject} />
      )}
    </>
  );
};

export default LoadMedia;
