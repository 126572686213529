import React, { FC } from 'react';

import { Breadcrumbs, makeStyles, Typography } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { BreadcrumbItem } from '../../../models';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export type AppBreadcrumbProps = {
  items: BreadcrumbItem[];
};

const AppBreadcrumb: FC<AppBreadcrumbProps> = (props) => {
  const { items } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          {items.map((item: BreadcrumbItem, index) => {
            const rowKey = `breadcrumb-${index}`;

            if (item.url) {
              return (
                <Link key={rowKey} to={item.url}>
                  {t(item.label, item.labelParam || {})}
                </Link>
              );
            }

            return (
              <Typography key={rowKey} color="textPrimary">
                {t(item.label, item.labelParam || {})}
              </Typography>
            );
          })}
        </Breadcrumbs>
      </div>
    </>
  );
};

export default AppBreadcrumb;
