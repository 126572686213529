import React, { FC } from 'react';

import { Box, Grid, InputLabel, OutlinedInput, Typography, makeStyles } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import usePrivilege from '../../../../../hooks/usePrivilege';
import { AbTestingFormDataRequest } from '../../../../../models/ab-testing.model';
import AppButton from '../../../../ui/AppButton';
import AppDialog from '../../../../ui/AppDialog';
import AppFormControl from '../../../../ui/AppFormControl';

export type AddFeatureTestingItemDialogProps = {
  open: boolean;
  title: string;
  onClose: () => void;
  onCloseClick?: () => void;
  onFormSubmit: (data: AbTestingFormDataRequest) => void;
};

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  numberInput: {
    MozAppearance: 'none',
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
  confirmDialogBox: {
    width: '100%',
    maxWidth: '1000px',
    [theme.breakpoints.up('md')]: {
      minWidth: '400px',
    },
  },
  buttonGroups: {
    '& > *': {
      margin: `0 ${theme.spacing(1)}px`,
    },
  },
}));

const AddFeatureTestingItemDialog: FC<AddFeatureTestingItemDialogProps> = (props) => {
  const classes = useStyles();
  const { open, title, onClose, onCloseClick, onFormSubmit } = props;
  const { t } = useTranslation();

  const { canPerform } = usePrivilege();
  const allowCreate = canPerform('abTesting', 'create');

  const {
    handleSubmit,
    getValues,
    control,
    formState: { errors },
  } = useForm<AbTestingFormDataRequest>({
    mode: 'onChange',
    defaultValues: {
      features: [] || '',
      userHashIds: [] || '',
    },
  });

  const onSaveClick = () => {
    if (typeof onFormSubmit === 'function') {
      onFormSubmit(getValues());
    }
  };

  return (
    <>
      <AppDialog
        open={open}
        onClose={onClose}
        title={title}
        okButtonText="common:button.close"
        okButtonColor="default"
        okButtonVariant="outlined"
        closeOnOkClicked
        noActionBar
        dialogProps={{
          disableBackdropClick: true,
          fullWidth: true,
          maxWidth: 'md',
        }}>
        <form onSubmit={handleSubmit(onSaveClick)}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Box mb={3}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="features"
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field }) => (
                        <AppFormControl>
                          <InputLabel htmlFor="feature-name">{t('ab-testing:details.label.featureName')}</InputLabel>
                          <OutlinedInput
                            {...field}
                            id="feature-name"
                            fullWidth
                            disabled={!allowCreate}
                            error={!!errors.features}
                          />
                        </AppFormControl>
                      )}
                    />
                    <Typography variant="subtitle2" style={{ marginTop: '4px' }}>
                      {t('ab-testing:details.label.subTitle1')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="userHashIds"
                      control={control}
                      rules={{
                        required: false,
                      }}
                      render={(data) => (
                        <AppFormControl error={!!errors.userHashIds}>
                          <InputLabel htmlFor="user-hash-id-lists">
                            {t('ab-testing:details.label.userHashIds')}
                          </InputLabel>
                          <Box style={{ position: 'relative' }}>
                            <OutlinedInput
                              {...data.field}
                              id="user-hash-id-lists"
                              fullWidth
                              disabled={!allowCreate}
                              error={!!errors.userHashIds}
                              rows={3}
                              multiline
                              onChange={(e) => data.field.onChange(e.target.value.replace(/[\n"' ']/g, ''))}
                            />
                          </Box>
                        </AppFormControl>
                      )}
                    />
                    <Typography variant="subtitle2" style={{ marginTop: '4px' }}>
                      {t('ab-testing:details.label.subTitle2')}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <Box display="flex" my={2} justifyContent="flex-end" className={classes.buttonGroups}>
            <AppButton type="submit" color="primary" disabled={!allowCreate}>
              {t('ab-testing:button.addNewFeature')}
            </AppButton>
            <AppButton
              variant="outlined"
              color="default"
              onClick={() => {
                if (typeof onCloseClick === 'function') {
                  onCloseClick();
                }
              }}>
              {t('common:button.close')}
            </AppButton>
          </Box>
        </form>
      </AppDialog>
    </>
  );
};

export default AddFeatureTestingItemDialog;
