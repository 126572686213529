import React, { ReactNode, FC, ReactElement } from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
  DialogProps,
  IconButton,
  DialogContentProps,
  Box,
} from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import WarningIcon from '@material-ui/icons/Warning';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import AppButton, { AppButtonProps } from './AppButton';
import { AppDialogType } from '../../models';
import useTypographyStyles from '../../theme/typography.style';

export interface AppDialogProps {
  open?: boolean;
  type?: AppDialogType;
  title?: string;
  titleIcon?: ReactNode | ReactElement;
  noTitle?: boolean;
  noActionBar?: boolean;
  showCloseButton?: boolean;
  okButtonText?: string;
  okButtonColor?: AppButtonProps['color'];
  okButtonVariant?: 'text' | 'outlined' | 'contained';
  cancelButtonText?: string;
  onOkClick?: () => void;
  onCancelClick?: () => void;
  onClose?: () => void;
  okButtonProps?: AppButtonProps;
  cancelButtonProps?: AppButtonProps;
  dialogProps?: Omit<DialogProps, 'open' | 'onClose'>;
  dialogContentProps?: DialogContentProps;
  closeOnOkClicked?: boolean;
  titleClasses?: string;
}

const defaultDialogTitle: { [x: string]: string } = {
  normal: 'common:dialog.title.normal',
  success: 'common:dialog.title.success',
  warning: 'common:dialog.title.warning',
  error: 'common:dialog.title.error',
  info: 'common:dialog.title.info',
};

export const useStyles = makeStyles((theme) => ({
  dialogAction: {
    marginTop: '20px',
  },
  closeButton: {
    position: 'absolute',
    top: '4px',
    right: '4px',
  },
  contentSpaceTop: {
    marginTop: theme.spacing(5),
  },
  dialogTitle: {
    '& .MuiTypography-root': {
      display: 'flex',
      flexFlow: 'row nowrap',
    },
  },
  dialogTitleText: {
    flexGrow: 1,
    lineHeight: 1.8,
  },
}));

const AppDialogIcon = ({ icon, type }: { icon: ReactNode | ReactElement; type?: AppDialogType }): ReactElement => {
  const typoClasses = useTypographyStyles();

  if (type === 'success') {
    return <CheckCircleOutlineIcon className={typoClasses.successText} />;
  }

  if (type === 'error') {
    return <HighlightOffIcon className={typoClasses.errorText} />;
  }

  if (type === 'info') {
    return <HelpOutlineIcon className={typoClasses.infoText} />;
  }

  if (type === 'warning') {
    return <WarningIcon className={typoClasses.warningText} />;
  }

  if (type === 'question') {
    return <HelpOutlineIcon />;
  }

  if (type === 'tips') {
    return <EmojiObjectsOutlinedIcon className={typoClasses.infoText} />;
  }

  return <>{icon}</>;
};

const AppDialog: FC<AppDialogProps> = (props) => {
  const classes = useStyles();
  const typoClasses = useTypographyStyles();
  const { t } = useTranslation();
  const {
    open = false,
    type = 'normal',
    title,
    titleIcon = undefined,
    okButtonText,
    cancelButtonText,
    onOkClick,
    onCancelClick,
    onClose,
    children,
    okButtonColor = 'primary',
    okButtonVariant = 'contained',
    okButtonProps,
    cancelButtonProps,
    dialogProps = undefined,
    noTitle = false,
    showCloseButton = false,
    noActionBar = false,
    dialogContentProps = undefined,
    closeOnOkClicked = false,
    titleClasses = '',
  } = props;

  const onOkClickHandler = () => {
    if (typeof onOkClick === 'function') {
      onOkClick();
    }
    if (closeOnOkClicked && typeof onClose === 'function') {
      onClose();
    }
  };

  const onCloseHandler = () => {
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  const onCancelClickHandler = () => {
    if (typeof onCancelClick === 'function') {
      onCancelClick();
    }

    onCloseHandler();
  };

  return (
    <Dialog
      {...dialogProps}
      open={open}
      onClose={onCloseHandler}
      aria-labelledby="app-dialog-title"
      aria-describedby="app-dialog-message">
      {!noTitle && (
        <DialogTitle
          id="app-dialog-title"
          classes={{
            root: classes.dialogTitle,
          }}>
          {(!!titleIcon || !!type) && (
            <Box component="span" pr={1} display="inline-flex">
              <AppDialogIcon icon={titleIcon} type={type} />
            </Box>
          )}
          <Typography
            component="span"
            className={clsx(typoClasses.textWeightBold, classes.dialogTitleText, titleClasses)}
            noWrap>
            {title ? t(title) : t(defaultDialogTitle[type])}
          </Typography>
        </DialogTitle>
      )}
      {showCloseButton && (
        <IconButton aria-label="close" onClick={onCloseHandler} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
      )}
      <DialogContent
        {...dialogContentProps}
        className={clsx({
          [classes.contentSpaceTop]: noTitle && showCloseButton,
        })}>
        {children}
      </DialogContent>
      {!noActionBar && (
        <DialogActions className={classes.dialogAction}>
          {!!cancelButtonText && (
            <AppButton onClick={onCancelClickHandler} variant="text" color="default" {...cancelButtonProps}>
              {cancelButtonText && t(cancelButtonText)}
            </AppButton>
          )}
          <AppButton variant={okButtonVariant} color={okButtonColor} onClick={onOkClickHandler} {...okButtonProps}>
            {okButtonText ? t(okButtonText) : t('common:button.ok')}
          </AppButton>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default AppDialog;
