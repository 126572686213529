import React, { FC, useContext, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import ReportedItemSearchForm from '../../components/partials/sns/ReportedItemSearchForm';
import ReportedItemTable from '../../components/partials/sns/ReportedItemTable';
import { AppGlobalUiContext } from '../../context/AppGlobalUiContext';
import { SearchFilterContext } from '../../context/SearchFilterContext';
import { defaultRowsPerPage } from '../../helpers/constants';
import {
  AppTableConditions,
  ReportedItem,
  ReportedItemListSearchFormData,
  ReportedItemListSearchParams,
} from '../../models';
import { getReportedItemList } from '../../services/sns';

const searchFilterContextKey = 'reported-sns-item';

const ReportedItemList: FC = () => {
  const { t } = useTranslation();
  const { showSnackbar } = useContext(AppGlobalUiContext);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [firstVisibleDocId, setFirstVisibleDocId] = useState<string>('');
  const [lastVisibleDocId, setLastVisibleDocId] = useState<string>('');
  const [reportedItemList, setReportedItemList] = useState<ReportedItem[]>([]);
  const [totalReportedItems, setTotalReportedItems] = useState<number>(0);

  // start filling default search filters (either from the one we saved in context or fresh conditions)
  const { currentFilters: ctxFilters, setFilters } = useContext(SearchFilterContext);
  const currentFilters: ReportedItemListSearchParams = ctxFilters ? ctxFilters[searchFilterContextKey] : undefined;
  const [searchFilters, setSearchFilters] = useState<ReportedItemListSearchFormData>({
    startDate: currentFilters?.startDate || null,
    endDate: currentFilters?.endDate || null,
    filteringItemTypes: currentFilters?.filteringItemTypes || '',
    filteringTypes: currentFilters?.filteringTypes || '',
    filteringStatuses: currentFilters?.filteringStatuses || '',
  });
  const [tableConditions, setTableConditions] = useState<AppTableConditions>({
    page: currentFilters?.page || 0,
    rowsPerPage: currentFilters?.rowsPerPage || defaultRowsPerPage,
    sortColumn: currentFilters?.sortColumn || 'updatedAt',
    sortDirection: currentFilters?.sortDirection || 'desc',
  });

  const getCurrentSearchParam = (): ReportedItemListSearchParams => ({
    page: tableConditions.page || 0,
    rowsPerPage: tableConditions.rowsPerPage || defaultRowsPerPage,
    sortColumn: tableConditions.sortColumn || 'updatedAt',
    sortDirection: tableConditions.sortDirection || 'desc',
    startDate: searchFilters.startDate,
    endDate: searchFilters.endDate,
    filteringItemTypes: searchFilters.filteringItemTypes,
    filteringTypes: searchFilters.filteringTypes,
    filteringStatuses: searchFilters.filteringStatuses,
    firstVisibleDocId,
    lastVisibleDocId,
  });

  const requestReportedItemList = async (isPageSubscribed = true): Promise<boolean> => {
    if (isPageSubscribed) {
      setIsLoadingData(true);

      const searchConditions = getCurrentSearchParam();

      // Save search filter to search filter context
      setFilters(searchFilterContextKey, searchConditions);

      const result = await getReportedItemList(searchConditions);
      setIsLoadingData(false);

      if (result.data) {
        setReportedItemList(result.data.reportedItems);
        setTotalReportedItems(result.data.totalCount);
        return Promise.resolve(true);
      }

      showSnackbar(t(result.error as string).toString(), 'error');
      return Promise.reject(result.error);
    }

    return false;
  };

  const tableConditionString = useMemo(() => {
    const { page, rowsPerPage, sortColumn, sortDirection } = tableConditions;
    return `page=${page},rowsPerPage=${rowsPerPage},sortColumn=${sortColumn},sortDirection=${sortDirection}`;
  }, [tableConditions]);

  useEffect(() => {
    let isSubscribed = true;
    requestReportedItemList(isSubscribed);
    return () => {
      isSubscribed = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableConditionString, searchFilters]);

  const tableConditionsChangedHandler = (
    values: AppTableConditions & {
      firstVisibleDocId: string;
      lastVisibleDocId: string;
    },
  ) => {
    const { firstVisibleDocId: firstDocId, lastVisibleDocId: lastDocId, ...rest } = values;
    setFirstVisibleDocId(firstDocId || '');
    setLastVisibleDocId(lastDocId || '');
    setTableConditions(rest);
  };

  const onSearchFormSubmit = (filters: ReportedItemListSearchFormData) => {
    if (isLoadingData) {
      showSnackbar(t('common.message.pleaseWaitForDataToFinish').toString(), 'warning');
      return;
    }

    if (tableConditions.page !== 0) {
      tableConditions.page = 0;
    }

    setSearchFilters(filters);
  };

  return (
    <>
      <ReportedItemSearchForm
        isLoadingData={isLoadingData}
        currentFilters={searchFilters}
        onSearchFormSubmit={onSearchFormSubmit}
      />
      <ReportedItemTable
        currentConditions={tableConditions}
        reportedItemList={reportedItemList}
        totalReportedItems={totalReportedItems}
        isLoadingData={isLoadingData}
        onTableConditionsChanged={tableConditionsChangedHandler}
      />
    </>
  );
};

export default ReportedItemList;
