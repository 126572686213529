import React, { FC, useCallback, useContext, useEffect, useState } from 'react';

import { Box, Container, TableRow, Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { AppGlobalUiContext } from '../../../../../context/AppGlobalUiContext';
import useQuery from '../../../../../hooks/useQuery';
import { AppTableConditions, TableColumn } from '../../../../../models';
import { FakhaData, FakhaDataRequest } from '../../../../../models/fakha.model';
import { updateNoteFakhaItem } from '../../../../../services/v3/fakha';
import useAppContainerStyles from '../../../../../theme/container.style';
import useAppTableStyles from '../../../../../theme/table.style';
import AppTableCell from '../../../../ui/AppTableCell';
import AppTableList from '../../../../ui/AppTableList';
import FakhaItemDialog from '../FakhaItemDialog';

export type FakhaListTableProps = {
  currentConditions: AppTableConditions;
  totalItems: number;
  isLoadingData: boolean;
  fakhaList: FakhaData[];
  onTableConditionsChanged: (values: AppTableConditions) => void;
  onFetch: () => void;
};

const useStyles = makeStyles((theme) => ({
  selectedRow: {
    backgroundColor: theme.palette.primary.main,
    '&:nth-of-type(2n+1)': {
      backgroundColor: theme.palette.primary.main,
    },
    '& td, & a': {
      color: theme.palette.primary.contrastText,
    },
    '&:not(.readonly):hover': {
      backgroundColor: theme.palette.primary.main,
      '& td, & a': {
        color: theme.palette.primary.contrastText,
      },
    },
  },
  remarkText: {
    whiteSpace: 'nowrap',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const FakhaListTable: FC<FakhaListTableProps> = (props) => {
  const { t, i18n } = useTranslation();
  const query = useQuery();
  const history = useHistory();
  const classes = useStyles();
  const { totalItems, isLoadingData, currentConditions, fakhaList, onFetch, onTableConditionsChanged } = props;
  const containerClasses = useAppContainerStyles();
  const tableClasses = useAppTableStyles();
  const { showSnackbar, setAppLoading } = useContext(AppGlobalUiContext);

  const [tableConditions, setTableConditions] = useState<AppTableConditions>(currentConditions);
  const dateDisplayFormat = i18n.language === 'th' ? 'D MMM BBBB' : 'D MMM YYYY';

  const [isFakhaDialogOpen, setIsFakhaDialogOpen] = useState(false);
  const [selectedFakhaItem, setSelectedFakhaItem] = useState<FakhaData | undefined>(undefined);
  const [isCrNoted, setIsCrNoted] = useState(false);

  const fakhaTableColumns: TableColumn[] = [
    { name: 'role', label: 'fakha:list.columns.role', width: 140, unsortable: true },
    { name: 'userHashId', label: 'fakha:list.columns.userHashID', width: 140, unsortable: true },
    { name: 'displayName', label: 'fakha:list.columns.displayName', width: 200, unsortable: true },
    { name: 'email', label: 'fakha:list.columns.email', width: 220, unsortable: true },
    { name: 'mobile', label: 'fakha:list.columns.mobile', width: 140, unsortable: true },
    { name: 'type', label: 'fakha:list.columns.type', width: 140, unsortable: true },
    { name: 'remark', label: 'fakha:list.columns.remark', width: 400, unsortable: true },
    { name: 'createdAt', label: 'fakha:list.columns.createdAt', width: 120 },
    { name: 'updatedAt', label: 'fakha:list.columns.updatedAt', width: 120, unsortable: true },
  ];

  const onTableConditionChangedHandler = useCallback(
    (values: AppTableConditions) => {
      if (JSON.stringify(values) !== JSON.stringify(tableConditions)) {
        setTableConditions(values);
        onTableConditionsChanged(values);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableConditions],
  );

  const onEditFormSubmit = useCallback(
    async (data?: FakhaDataRequest) => {
      if (selectedFakhaItem && data) {
        setAppLoading(true);

        const result = await updateNoteFakhaItem(selectedFakhaItem.hashId, data);

        if (result.data) {
          setAppLoading(false);
          setSelectedFakhaItem(result.data);
          showSnackbar(t('fakha:message.updateFakhaSuccess').toString(), 'success');
          setIsFakhaDialogOpen(false);
          onFetch();
          setIsCrNoted(true);
        } else {
          setAppLoading(false);
          showSnackbar(t('fakha:message.saveFailed').toString(), 'error');
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedFakhaItem, setAppLoading, showSnackbar, t],
  );

  useEffect(() => {
    const selectedId = query.get('hashId');
    if (selectedId) {
      if (isCrNoted) {
        setIsFakhaDialogOpen(false);
        setSelectedFakhaItem(undefined);
        history.push('/v3/fakha');
      } else {
        setIsFakhaDialogOpen(true);
        const selectedData = fakhaList.filter((item) => item.hashId === selectedId);
        setSelectedFakhaItem(selectedData[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fakhaList, isCrNoted, query]);

  return (
    <>
      <Container className={containerClasses.container}>
        <Box className={tableClasses.tableContainer}>
          {!isLoadingData && (
            <Typography
              variant="subtitle2"
              color="textSecondary"
              style={{ marginBottom: '8px', marginRight: '8px', textAlign: 'right' }}>
              {t('common:list.resultTotal', { fieldName: t(totalItems.toLocaleString() || '0') })}
            </Typography>
          )}

          <AppTableList
            {...tableConditions}
            aria-label="fakha-list"
            columns={fakhaTableColumns}
            totalDataCount={totalItems}
            hasPagination
            onTableConditionChanged={onTableConditionChangedHandler}
            isLoading={isLoadingData}
            tableBodyContent={
              fakhaList.length > 0 && (
                <>
                  {fakhaList.map((item: FakhaData) => (
                    <TableRow
                      hover
                      key={item.hashId}
                      classes={{
                        root: clsx({
                          [tableClasses.tableRow]: true,
                          [tableClasses.clickableTableRow]: true,
                          [classes.selectedRow]: selectedFakhaItem === item,
                        }),
                      }}
                      onClick={() => {
                        setIsFakhaDialogOpen(true);
                        setSelectedFakhaItem(item);
                      }}>
                      <AppTableCell>
                        {t(`user:common.roleShort.${item.role.toLocaleLowerCase()}`).toString()}
                      </AppTableCell>
                      <AppTableCell>
                        <a
                          href={`/v3/users/${item?.userHashId}`}
                          target="_blank"
                          rel="noreferrer"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}>
                          {item?.userHashId}
                        </a>
                      </AppTableCell>
                      <AppTableCell>{item.displayName ? item.displayName : '-'}</AppTableCell>
                      <AppTableCell>{item.email ? item.email : '-'}</AppTableCell>
                      <AppTableCell>
                        <Typography variant="subtitle2">{item.mobilePhone ? item.mobilePhone : '-'}</Typography>
                      </AppTableCell>
                      <AppTableCell>{t(`fakha:list.list.type.${item.type}`)}</AppTableCell>
                      <AppTableCell>
                        <Typography className={classes.remarkText} variant="subtitle2">
                          {item.remark ? item.remark : '-'}
                        </Typography>
                      </AppTableCell>
                      <AppTableCell>
                        {item.createdAt ? (
                          <Typography variant="subtitle2">
                            {dayjs(item.createdAt).format(`${dateDisplayFormat}`)}
                            <br />
                            {dayjs(item.createdAt).format(`HH:mm:ss`)}
                          </Typography>
                        ) : (
                          <Typography>-</Typography>
                        )}
                      </AppTableCell>
                      <AppTableCell>
                        {item.updatedAt ? (
                          <Typography variant="subtitle2">
                            {dayjs(item.updatedAt).format(`${dateDisplayFormat}`)}
                            <br />
                            {dayjs(item.updatedAt).format(`HH:mm:ss`)}
                          </Typography>
                        ) : (
                          <Typography>-</Typography>
                        )}
                      </AppTableCell>
                    </TableRow>
                  ))}
                </>
              )
            }
          />
        </Box>
      </Container>

      {selectedFakhaItem && (
        <FakhaItemDialog
          key={selectedFakhaItem.hashId}
          title={t('fakha:dialog.title')}
          open={isFakhaDialogOpen}
          onCloseClick={() => {
            setIsFakhaDialogOpen(false);
            setSelectedFakhaItem(undefined);
            if (selectedFakhaItem) {
              history.push('/v3/fakha');
            }
          }}
          fakhaItem={selectedFakhaItem}
          onFormSubmit={onEditFormSubmit}
        />
      )}
    </>
  );
};

export default FakhaListTable;
