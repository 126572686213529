import React, { FC, useState } from 'react';

import { Badge, Box, Button, Card, makeStyles, Tooltip, Typography } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DescriptionIcon from '@material-ui/icons/Description';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { getT2HSiteUrl } from '../../../../helpers/utils';
import { ListingItemData } from '../../../../models/listing.model';
import theme from '../../../../theme';
import useAppTableStyles from '../../../../theme/table.style';
import ImageThumbnail from '../../common/ImageThumbnail';
import ListingCleansingStatusBadge from '../ListingCleansingStatusBadge';
import ListingCrStatusBadge from '../ListingCrStatusBadge';
import ListingPossessionBadge from '../ListingPossessionBadge';
import ListingStatusBadge from '../ListingStatusBadge';

interface ListingItemProps {
  data: ListingItemData;
}

const useStyles = makeStyles((themeStyle) => ({
  wrapperCard: {
    gridGap: themeStyle.spacing(1),
    [themeStyle.breakpoints.up('sm')]: {
      gridTemplateColumns: '22% 1fr 210px',
      gridGap: themeStyle.spacing(2),
    },
    [themeStyle.breakpoints.up('lg')]: {
      gridGap: themeStyle.spacing(3),
    },
  },
  card: {
    width: '100%',
    height: '89%',
    position: 'relative',
  },
  media: {
    height: 0,
    paddingTop: '75%',
  },
  title: {
    fontWeight: 700,
    color: themeStyle.palette.primary.dark,
  },
  price: {
    fontWeight: 700,
  },
  linkCover: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 0,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  button: {
    position: 'absolute',
    zIndex: 1,
    width: '10%',
    height: '10%',
    right: 0,
    bottom: '25px',
  },
  icon: {
    color: 'White',
    backgroundColor: 'rgba(0,0,0,.7)',
    right: 0,
    width: '100%',
    height: '100%',
    borderRadius: '3px',
    top: 0,
  },
}));

const ListingItem: FC<ListingItemProps> = (props) => {
  const { data } = props;
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const tableClasses = useAppTableStyles();
  const dateDisplayFormat = i18n.language === 'th' ? 'D MMM BB / HH:mm:ss' : 'D MMM YY / HH:mm:ss';

  const sellerUsername =
    data.user?.username.length > 12 ? `${data.user?.username.slice(0, 12)}...` : data.user?.username;

  const createdAt = dayjs(data.createdAt).format(dateDisplayFormat);
  const updatedAt = data.updatedAt ? dayjs(data.updatedAt).format(dateDisplayFormat) : null;

  const openListingDetailPage = (hashId: string) => {
    window.open(`/listing/${hashId}`, '_blank');
  };
  const [hovering, setHovering] = useState(false);

  return (
    <Box p={1} className={classes.wrapperCard} display="grid" position="relative">
      <Box className={classes.linkCover} onClick={() => openListingDetailPage(data.hashId)} />

      <Card
        className={classes.card}
        onMouseEnter={() => {
          setHovering(true);
        }}
        onMouseLeave={() => {
          setHovering(false);
        }}
        onClick={() => openListingDetailPage(data.hashId)}>
        {hovering && (
          <Tooltip title="Open New Tab">
            <Button
              onClick={() => {
                openListingDetailPage(data.hashId);
              }}
              color="default"
              className={classes.button}>
              <OpenInNewIcon className={classes.icon} />
            </Button>
          </Tooltip>
        )}

        <ImageThumbnail
          imageUrl={data.imageUrl}
          altText={data.title}
          listingHashId={data.hashId}
          noLabel
          noDownload
          typeListing
        />
      </Card>
      <Box>
        <Typography
          className={classes.title}
          variant="subtitle1"
          style={{
            wordBreak: 'break-word',
          }}>
          {data.title}
        </Typography>
        <Box mt={1} display="flex" alignItems="center" flexWrap="wrap">
          <Typography variant="body2">{data.rootCategory?.thaiName}</Typography>
          {data.subCategory && (
            <>
              <ChevronRightIcon fontSize="small" />
              <Typography variant="body2">{data.subCategory?.thaiName}</Typography>
            </>
          )}
          {data.spacialCategory && (
            <>
              <ChevronRightIcon fontSize="small" />
              <Typography variant="body2">{data.spacialCategory?.thaiName}</Typography>
            </>
          )}
        </Box>
        <Box mt={1}>
          <Typography variant="body2">
            {data.brand && `${data.brand?.name} - `}
            {data.firstRegistrationYear && `${data.firstRegistrationYear} - `}
            {data.province?.thaiName}
          </Typography>
        </Box>
        <Box mt={1}>
          <Typography color="secondary" className={classes.price}>
            {data.sellingPrice?.toLocaleString()}
          </Typography>
        </Box>
        <Box mt={1} display="flex">
          <Badge
            badgeContent={data.imageCount}
            color="primary"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}>
            <PhotoLibraryIcon />
          </Badge>
          {data.video && (
            <Badge
              badgeContent={1}
              color="primary"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              style={{ marginLeft: theme.spacing(2) }}>
              <VideoLibraryIcon />
            </Badge>
          )}
          {data.vehicleRegistrationBookImages?.length > 0 && (
            <Badge
              badgeContent={data.vehicleRegistrationBookImages?.length}
              color="primary"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              style={{ marginLeft: theme.spacing(2) }}>
              <DescriptionIcon />
            </Badge>
          )}
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="flex-end">
        <Box display="flex" mt={2} maxWidth="200px" flexWrap="wrap" gridRowGap="10px">
          <ListingStatusBadge status={data.status} />
          {data && data.possession && <Box m={0.25} />}
          <ListingPossessionBadge possession={data.possession} />
          <Box m={0.25} />
          <ListingCleansingStatusBadge cleansingStatus={data.cleansingStatus} />
          {data && data.crStatus && <Box m={0.25} />}
          {data && data.crStatus && <ListingCrStatusBadge crStatus={data.crStatus} />}
        </Box>
        <Box mt={2}>
          <Typography style={{ textAlign: 'right' }}>
            {t('listing:common.fields.itemId')} :{' '}
            <a
              href={getT2HSiteUrl(`/listing/${data.hashId}`)}
              target="_blank"
              rel="noreferrer"
              className={tableClasses.tableExternalLink}>
              {data.hashId}
            </a>
          </Typography>
        </Box>
        <Box mt={1}>
          <Typography style={{ textAlign: 'right' }}>
            {t('listing:common.fields.username')} :{' '}
            <a
              href={getT2HSiteUrl(`/u/${data.user?.hashId}`)}
              target="_blank"
              rel="noreferrer"
              className={tableClasses.tableExternalLink}
              style={{ textDecoration: 'none' }}>
              {sellerUsername}
            </a>
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography variant="body2" style={{ textAlign: 'right' }}>
            {t('listing:common.fields.createdAt')}: {createdAt}
          </Typography>
          {updatedAt && (
            <Typography variant="body2" style={{ textAlign: 'right' }}>
              {t('listing:common.fields.updatedAt')} : {updatedAt}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ListingItem;
