import React, { FC } from 'react';

import { Paper, Box, Typography, makeStyles } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';

interface VideoThumbnailProps {
  videoUrl: string | undefined;
  listingHashId: string;
  thumbnailLabel?: string;
}

const useStyles = makeStyles(() => ({
  VideoThumbnailContainer: {
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
  },
  imageDownloadButton: {
    cursor: 'pointer',
    opacity: 0.6,
    '&:hover': {
      opacity: 1,
    },
  },
  VideoThumbnailBlock: {
    height: '175px',
    overflow: 'hidden',
    backgroundColor: '#000',
  },
  thumbnail: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
  },
}));

const VideoThumbnail: FC<VideoThumbnailProps> = (props) => {
  const { listingHashId, videoUrl, thumbnailLabel } = props;

  const classes = useStyles();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const downloadItem = async (e: any) => {
    e.stopPropagation();

    if (videoUrl) {
      const link = document.createElement('a');
      link.href = videoUrl;
      link.download = `${listingHashId && `${listingHashId}_`}video`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  if (videoUrl) {
    return (
      <Paper classes={{ root: classes.VideoThumbnailContainer }}>
        <Box className={classes.VideoThumbnailBlock}>
          {/*  eslint-disable-next-line jsx-a11y/media-has-caption */}
          <video className={classes.thumbnail} controls>
            <source src={videoUrl} type="video/mp4" />
          </video>
        </Box>
        <Box p={1} display="flex">
          <Box flexGrow={1} minWidth={0} pr={1}>
            <Typography variant="subtitle2">{thumbnailLabel}</Typography>
          </Box>
          <Box display="flex" className={classes.imageDownloadButton} onClick={downloadItem}>
            <GetAppIcon fontSize="small" />
          </Box>
        </Box>
      </Paper>
    );
  }
  return null;
};

export default VideoThumbnail;
