import React, { FC, useContext, useMemo, useState } from 'react';

import { Box, Divider, Grid, Typography, makeStyles } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import copy from 'copy-to-clipboard';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { AppGlobalUiContext } from '../../../../../context/AppGlobalUiContext';
import { AuthContext } from '../../../../../context/AuthContext';
import { getAppUrl } from '../../../../../helpers/utils';
import usePrivilege from '../../../../../hooks/usePrivilege';
import i18n from '../../../../../i18n';
import { OrderOnceData, OrderStateWorkflow } from '../../../../../models/order-order.model';
import AppButton from '../../../../ui/AppButton';
import AppDialog from '../../../../ui/AppDialog';
import OrderStatusBadge from '../../../v3/orderOnce/OrderStatusBadge';
import ImagePreview from '../../ImagePreview';
import ImageThumbnail from '../../ImageThumbnail';

export type OrderOnceProductItemDialogProps = {
  productItem: OrderOnceData;
  title: string;
  open: boolean;
  onClose: () => void;
  onCloseClick?: () => void;
  onCancelOrder: () => void;
  onApproveOrder: () => void;
};

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  numberInput: {
    MozAppearance: 'none',
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
  confirmDialogBox: {
    width: '100%',
    maxWidth: '1000px',
    [theme.breakpoints.up('md')]: {
      minWidth: '400px',
    },
  },
  buttonGroups: {
    '& > *': {
      margin: `0 ${theme.spacing(1)}px`,
    },
  },
  textPromoCode: {
    color: theme.palette.success.main,
  },
  textURLApprove: {
    padding: '8px',
    marginRight: '8px',
    width: '400px',
    color: theme.palette.primary.main,
  },
  copyURLIcon: {
    color: theme.palette.primary.main,
    marginTop: '6px',
    cursor: 'pointer',
  },
  buttonClose: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

const OrderOnceProductItemDialog: FC<OrderOnceProductItemDialogProps> = (props) => {
  const classes = useStyles();
  const iconCoin = '../../static/images/icons/icon-coin.png';
  const iconBronze = '../../static/images/icons/icon-bronze-ribbon.png';
  const iconSilver = '../../static/images/icons/icon-silver-ribbon.png';
  const iconGold = '../../static/images/icons/icon-gold-ribbon.png';
  const { productItem, open, title, onClose, onCloseClick, onCancelOrder, onApproveOrder } = props;
  const { t } = useTranslation();
  const dateDisplayFormat = i18n.language === 'th' ? 'D MMM BBBB' : 'D MMM YYYY';
  const { authUser } = useContext(AuthContext);
  const [selectedPreviewImage, setSelectedPreviewImage] = useState<string | undefined>(undefined);

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);
  const [isConfirmApproveDialogOpen, setIsConfirmApproveDialogOpen] = useState<boolean>(false);

  const { canPerform } = usePrivilege();
  const allowEdit = canPerform('product', 'update');
  const { showSnackbar } = useContext(AppGlobalUiContext);
  const url = getAppUrl(`/v3/order-approve?id=${productItem.orderOnceHashId}`);

  const onConfirmationSaveClick = () => {
    setIsConfirmDialogOpen(false);
    if (typeof onCancelOrder === 'function') {
      onCancelOrder();
    }
  };

  const onConfirmationCancelClick = () => {
    setIsConfirmDialogOpen(false);
  };

  const onThumbnailClicked = (imageUrl: string) => {
    if (imageUrl) {
      setSelectedPreviewImage(imageUrl);
    }
  };

  const totalFreeCoinSummarize = productItem.shoppingCart.products.reduce(
    (sum, product) => sum + (product?.coinFree || 0),
    0,
  );

  const userEmailApproval = (process.env.REACT_APP_USER_EMAIL_APPROVE_ORDER || '').split(',');
  const isAllowApprovalOrder = useMemo(
    () => authUser?.role === 'ADMIN_GM' && userEmailApproval.includes(authUser?.email || ''),
    [authUser?.email, authUser?.role, userEmailApproval],
  );

  return (
    <>
      <AppDialog
        showCloseButton
        open={open}
        title={title}
        onClose={onClose}
        okButtonText="common:button.close"
        okButtonColor="default"
        okButtonVariant="outlined"
        closeOnOkClicked
        noActionBar
        dialogProps={{
          fullWidth: true,
          maxWidth: 'md',
          disableBackdropClick: true,
        }}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Box>
              <Box display="flex" flexDirection="row" mt={2} mb={1}>
                <Typography style={{ fontWeight: 'bold' }}>{t('order:orderList.columns.orderOnceHashId')} :</Typography>
                <Box mx={1}>
                  <Typography>{productItem.orderOnceHashId}</Typography>
                </Box>
                <OrderStatusBadge
                  status={productItem.orderStateWorkflow}
                  isOrderByCr={productItem.cr && productItem.orderStateWorkflow === OrderStateWorkflow.open}
                />
              </Box>

              <Box display="flex" flexDirection="row" mb={1}>
                <Typography style={{ fontWeight: 'bold' }}>{t('order:orderList.columns.userHashId')} : </Typography>
                <Box ml={1}>
                  <a href={`/v3/users/${productItem.user.userHashId}`} target="_blank" rel="noreferrer">
                    {productItem.user.userHashId}
                  </a>
                </Box>
              </Box>

              <Box display="flex" flexDirection="row" mb={1}>
                <Typography style={{ fontWeight: 'bold' }}>{t('order:orderList.columns.createAt')} :</Typography>

                <Box ml={1} display="flex" flexDirection="row">
                  <Typography>{dayjs(productItem.createdAt).format(`${dateDisplayFormat}`)}</Typography>
                  <Box mx={1}>|</Box>
                  <Typography>{dayjs(productItem.createdAt).format(`HH:mm:ss`)}</Typography>
                </Box>
              </Box>

              {productItem.omisePromptPayDetail?.qrCodeUri && (
                <Box display="flex" flexDirection="row" mb={1}>
                  <Typography style={{ fontWeight: 'bold' }}>{t('order:orderList.columns.paymentBy')} : </Typography>
                  <Box ml={1}>
                    <Typography>{t('order:orderList.list.promptPay')}</Typography>
                  </Box>
                </Box>
              )}

              {productItem.omiseCreditCardDetail?.brand && (
                <Box display="flex" flexDirection="row" mb={1}>
                  <Typography style={{ fontWeight: 'bold' }}>{t('order:orderList.columns.paymentBy')} : </Typography>
                  <Box ml={1}>
                    <Typography>{t('order:orderList.list.creditCard')}</Typography>
                  </Box>
                </Box>
              )}

              {!productItem.omiseCreditCardDetail?.brand &&
                !productItem.omisePromptPayDetail?.qrCodeUri &&
                productItem.cr && (
                  <Box display="flex" flexDirection="row" mb={1}>
                    <Typography style={{ fontWeight: 'bold' }}>ทำรายการโดย : </Typography>
                    <Box ml={1}>
                      <Typography>{productItem.cr.displayName}</Typography>
                    </Box>
                  </Box>
                )}

              {productItem.confirmedByCr && productItem.orderStateWorkflow !== OrderStateWorkflow.open && (
                <Box display="flex" flexDirection="row" mb={1}>
                  <Typography style={{ fontWeight: 'bold' }}>อนุมัติโดย : </Typography>
                  <Box ml={1}>
                    <Typography>{productItem.confirmedByCr.displayName}</Typography>
                  </Box>
                </Box>
              )}

              {productItem.orderStateWorkflow !== OrderStateWorkflow.open && (
                <Box display="flex" flexDirection="row" mb={1}>
                  <Typography style={{ fontWeight: 'bold' }}>วันที่อนุมัติ/ยกเลิก : </Typography>
                  <Box ml={1} display="flex" flexDirection="row">
                    <Typography>{dayjs(productItem.updatedAt).format(`${dateDisplayFormat}`)}</Typography>
                    <Box mx={1}>|</Box>
                    <Typography>{dayjs(productItem.updatedAt).format(`HH:mm:ss`)}</Typography>
                  </Box>
                </Box>
              )}

              {productItem.userOrderSubscriptionHashId && (
                <Box display="flex" flexDirection="row" mb={1}>
                  <Typography style={{ fontWeight: 'bold' }}>{t('order:orderList.details.purchaseType')} :</Typography>
                  <Box ml={1}>
                    <Typography>{t('order:orderList.list.orderSubscriptionOpen')}</Typography>
                  </Box>
                </Box>
              )}
              {productItem.omiseChargeId && (
                <Box mb={3}>
                  <Typography style={{ fontWeight: 'bold' }}>{t('order:orderList.details.omiseChargeId')} :</Typography>
                  <Box>
                    <Typography>{productItem.omiseChargeId}</Typography>
                  </Box>
                </Box>
              )}

              {!isAllowApprovalOrder && productItem.cr && productItem.orderStateWorkflow === OrderStateWorkflow.open && (
                <Box
                  my={3}
                  display="flex"
                  flexDirection="row"
                  onClick={() => {
                    setTimeout(() => {
                      copy(url);
                      showSnackbar('คัดลอกลิงก์แล้ว', 'success');
                    }, 300);
                  }}>
                  <input type="text" value={url} disabled className={classes.textURLApprove} />
                  <FileCopyIcon className={classes.copyURLIcon} />
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box mb={3}>
              {productItem.paymentEvidentFile && (
                <ImageThumbnail
                  listingHashId={productItem.paymentEvidentFile.fileHashId}
                  imageUrl={productItem.paymentEvidentFile.fullUrl}
                  altText="Payment Evident file"
                  thumbnailLabel={t('order:orderList.details.paymentEvidentFile')}
                  imageClick={onThumbnailClicked}
                />
              )}
              {productItem.omisePromptPayDetail?.qrCodeUri && (
                <Box>
                  <ImageThumbnail
                    listingHashId={productItem.orderOnceHashId}
                    imageUrl={productItem.omisePromptPayDetail?.qrCodeUri}
                    altText="QR Code file"
                    thumbnailLabel={t('order:orderList.details.omisePromptPayFile')}
                    imageClick={onThumbnailClicked}
                  />
                  <Box mt={1}>
                    <Typography style={{ fontWeight: 'bold' }}>
                      {t('order:orderList.details.qrCodeCreatedAt')} :
                    </Typography>
                    <Box display="flex" flexDirection="row">
                      <Typography>
                        {dayjs(productItem.omisePromptPayDetail.qrCodeCreatedAt).format(`${dateDisplayFormat}`)}
                      </Typography>
                      <Box mx={1}>|</Box>
                      <Typography>
                        {dayjs(productItem.omisePromptPayDetail.qrCodeCreatedAt).format(`HH:mm:ss`)}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}
              {productItem.omiseCreditCardDetail?.brand && (
                <Box>
                  <Typography style={{ fontWeight: 'bold' }}>
                    {t('order:orderList.details.creditCardDetail')}
                  </Typography>
                  <Box display="flex" flexDirection="row" mt={2} mb={1}>
                    <Typography style={{ fontWeight: 'bold' }}>{t('order:orderList.details.bank')} :</Typography>
                    <Box ml={1}>
                      <Typography>{productItem.omiseCreditCardDetail.bank}</Typography>
                    </Box>
                  </Box>
                  <Box display="flex" flexDirection="row" mt={1}>
                    <Typography style={{ fontWeight: 'bold' }}>{t('order:orderList.details.brand')} :</Typography>
                    <Box ml={1} mr={3}>
                      <Typography>{productItem.omiseCreditCardDetail.brand.toLocaleUpperCase()}</Typography>
                    </Box>
                    <Typography style={{ fontWeight: 'bold' }}>{t('order:orderList.details.lastDigit')} :</Typography>
                    <Box ml={1}>
                      <Typography>{productItem.omiseCreditCardDetail.lastDigits}</Typography>
                    </Box>
                  </Box>
                  <Box display="flex" flexDirection="row" mt={1}>
                    <Typography style={{ fontWeight: 'bold' }}>{t('order:orderList.details.expire')} :</Typography>
                    <Box ml={1}>
                      <Typography>
                        {productItem.omiseCreditCardDetail.expirationMonth}/
                        {productItem.omiseCreditCardDetail.expirationYear}
                      </Typography>
                    </Box>
                  </Box>
                  <Box display="flex" flexDirection="row" mt={1}>
                    <Typography style={{ fontWeight: 'bold' }}>
                      {t('order:orderList.details.nameCreditCard')} :
                    </Typography>
                    <Box ml={1}>
                      <Typography>{productItem.omiseCreditCardDetail.name}</Typography>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Divider />
          </Grid>
        </Grid>

        {productItem &&
          productItem.shoppingCart.products.map((item) => {
            const itemKey = item.productHashId;
            return (
              <Box my={2} key={itemKey}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box display="flex" flexDirection="row">
                      {item.productType === 'COIN' && <img src={iconCoin} width={24} height={24} alt="COIN" />}
                      {item.membershipType === 'BRONZE' && <img src={iconBronze} width={24} height={30} alt="BRONZE" />}
                      {item.membershipType === 'SILVER' && <img src={iconSilver} width={24} height={30} alt="SILVER" />}
                      {item.membershipType === 'GOLD' && <img src={iconGold} width={24} height={30} alt="GOLD" />}
                      <Box>
                        <Typography style={{ marginLeft: 6 }}>{item.productName}</Typography>
                        {item.coinFree > 0 && (
                          <Typography variant="subtitle2" style={{ marginLeft: 8, paddingTop: 2 }}>
                            {t('shopping-cart:freeCoin', {
                              fieldName: item.coinFree ? t(item.coinFree.toLocaleString()) : '-',
                            })}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box display="flex" flexDirection="row" justifyContent="space-between">
                      <Typography>x{item.productAmount}</Typography>
                      <Typography style={{ fontWeight: 'bold' }}>
                        {item.totalPrice.toLocaleString()} {t('shopping-cart:unit')}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              </Box>
            );
          })}

        <Grid container spacing={2}>
          <Grid item xs={12} sm={5} md={5}>
            <Typography style={{ fontWeight: 'bold' }}>
              {t('order:orderList.details.subTotal', {
                fieldName: t(productItem.shoppingCart.subTotal.toLocaleString() || '0'),
              })}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={7} md={7}>
            <Box mb={2}>
              <Box my={1} justifyContent="space-between" display="flex" flexDirection="row">
                <Box>
                  <Typography style={{ fontWeight: 'bold' }}>
                    {t('order:orderList.details.freeCoinTotal')} :{' '}
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="row">
                  <Typography style={{ fontWeight: 'bold' }}>
                    {totalFreeCoinSummarize?.toLocaleString()} Coins
                  </Typography>
                </Box>
              </Box>

              {productItem.shoppingCart.promoCode && (
                <Box my={1} justifyContent="space-between" display="flex" flexDirection="row">
                  <Box>
                    <Typography style={{ fontWeight: 'bold' }}>{t('order:orderList.details.promoCode')} : </Typography>
                  </Box>
                  <Box>
                    <Typography style={{ fontWeight: 'bold' }} className={classes.textPromoCode}>
                      {productItem.shoppingCart.promoCode}
                    </Typography>
                  </Box>
                </Box>
              )}

              {productItem.shoppingCart.freeCoinFromPromoCode && (
                <Box my={1} justifyContent="space-between" display="flex" flexDirection="row">
                  <Box>
                    <Typography style={{ fontWeight: 'bold' }}>
                      {t('order:orderList.details.freeCoinFromPromoCode')} :{' '}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography style={{ fontWeight: 'bold' }}>
                      {productItem.shoppingCart.freeCoinFromPromoCode
                        ? productItem.shoppingCart.freeCoinFromPromoCode.toLocaleString()
                        : '-'}
                    </Typography>
                  </Box>
                </Box>
              )}

              <Box my={1} justifyContent="space-between" display="flex" flexDirection="row">
                <Box>
                  <Typography style={{ fontWeight: 'bold' }}>
                    {t('order:orderList.details.totalDiscount')} :{' '}
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="row">
                  <Typography style={{ fontWeight: 'bold', marginRight: '4px' }}>
                    {productItem.shoppingCart.totalDiscount
                      ? productItem.shoppingCart.totalDiscount.toLocaleString()
                      : '0'}
                  </Typography>
                  <Typography style={{ fontWeight: 'bold' }}>{t('shopping-cart:unit')}</Typography>
                </Box>
              </Box>

              <Box my={1} justifyContent="space-between" display="flex" flexDirection="row">
                <Box>
                  <Typography style={{ fontWeight: 'bold' }}>{t('order:orderList.details.totalPrice')} : </Typography>
                </Box>
                <Box display="flex" flexDirection="row">
                  <Typography style={{ fontWeight: 'bold', marginRight: '4px' }}>
                    {productItem.shoppingCart.totalPrice ? productItem.shoppingCart.totalPrice.toLocaleString() : '0'}
                  </Typography>
                  <Typography style={{ fontWeight: 'bold' }}>{t('shopping-cart:unit')}</Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Divider />

        <Box textAlign="right" my={2}>
          <Box display="inline-block" className={classes.buttonGroups}>
            {productItem.orderStateWorkflow === OrderStateWorkflow.open && !!isAllowApprovalOrder && (
              <AppButton
                variant="outlined"
                color="primary"
                disabled={!allowEdit}
                onClick={() => {
                  setIsConfirmApproveDialogOpen(true);
                }}>
                {t('order:orderList.button.approveOrder')}
              </AppButton>
            )}
            {productItem.orderStateWorkflow === OrderStateWorkflow.open && (
              <AppButton
                variant="outlined"
                color="error"
                onClick={() => {
                  setIsConfirmDialogOpen(true);
                }}>
                {t('order:orderList.button.cancelOrder')}
              </AppButton>
            )}

            <AppButton
              variant="outlined"
              color="default"
              onClick={() => {
                if (typeof onCloseClick === 'function') {
                  onCloseClick();
                }
              }}
              className={classes.buttonClose}>
              {t('common:button.close')}
            </AppButton>
          </Box>
        </Box>
        <AppDialog
          title={t('common:dialog.title.confirm').toString()}
          open={isConfirmDialogOpen}
          onOkClick={onConfirmationSaveClick}
          onCancelClick={onConfirmationCancelClick}
          cancelButtonText={t('common:button.cancel').toString()}
          okButtonText={t('common:button.ok').toString()}
          okButtonColor="primary"
          dialogProps={{
            maxWidth: 'md',
          }}>
          <Box className={classes.confirmDialogBox}>
            <Typography>{t('order:orderList.message.confirmCancelOrderOnce')}</Typography>
          </Box>
        </AppDialog>

        <AppDialog
          title={t('common:dialog.title.confirm').toString()}
          open={isConfirmApproveDialogOpen}
          onOkClick={() => {
            setIsConfirmApproveDialogOpen(true);
            if (typeof onApproveOrder === 'function') {
              onApproveOrder();
            }
          }}
          onCancelClick={() => {
            setIsConfirmApproveDialogOpen(false);
          }}
          cancelButtonText={t('common:button.cancel').toString()}
          okButtonText={t('common:button.ok').toString()}
          okButtonColor="primary"
          dialogProps={{
            maxWidth: 'md',
          }}>
          <Box className={classes.confirmDialogBox}>
            <Typography>{t('order:orderList.message.confirmApproveOrderOnce')}</Typography>
          </Box>
        </AppDialog>
      </AppDialog>

      <ImagePreview imageUrl={selectedPreviewImage} onCloseClick={() => setSelectedPreviewImage(undefined)} asModal />
    </>
  );
};

export default OrderOnceProductItemDialog;
