import React, { FC, useCallback, useContext, useMemo, useState } from 'react';

import { Box, Container, Grid, TableRow, Typography, makeStyles } from '@material-ui/core';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { AppGlobalUiContext } from '../../../../context/AppGlobalUiContext';
import { AuthContext } from '../../../../context/AuthContext';
import { tableRowPerPageList } from '../../../../helpers/constants';
import { AppTableConditions, UserRole } from '../../../../models';
import {
  ListingCRStatus,
  ListingItemData,
  UpdateStatusMultiListingFormDataRequest,
} from '../../../../models/listing.model';
import { updateStatusListingLists } from '../../../../services/listing';
import useAppContainerStyles from '../../../../theme/container.style';
import useAppTableStyles from '../../../../theme/table.style';
import AppButton from '../../../ui/AppButton';
import AppCheckbox from '../../../ui/AppCheckbox';
import AppTableCell from '../../../ui/AppTableCell';
import AppTableList from '../../../ui/AppTableList';
import EditListingStatusDialog from '../EditListingStatusDialog';
import ListingItem from '../ListingItem';

export type ListingTableProps = {
  currentConditions: AppTableConditions;
  totalItems: number;
  isLoadingData: boolean;
  items: ListingItemData[];
  onTableConditionsChanged: (values: AppTableConditions) => void;
  defaultRowsPerPage?: number;
  rowsPerPageOptions?: number[];
  onFetch: () => void;
};

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    display: 'flex',
    [theme.breakpoints.down('lg')]: {
      display: 'block',
    },
  },
  checkboxItem: {
    width: '30px',
    height: '30px',
    margin: 'auto',
  },
}));

const ListingTable: FC<ListingTableProps> = (props) => {
  const { t } = useTranslation();
  const {
    totalItems,
    isLoadingData,
    currentConditions,
    items,
    onTableConditionsChanged,
    defaultRowsPerPage = tableRowPerPageList[0],
    rowsPerPageOptions = [tableRowPerPageList[0], tableRowPerPageList[1]],
    onFetch,
  } = props;
  const containerClasses = useAppContainerStyles();
  const tableClasses = useAppTableStyles();
  const classes = useStyles();
  const { authUser } = useContext(AuthContext);

  const { showSnackbar, setAppLoading } = useContext(AppGlobalUiContext);
  const [tableConditions, setTableConditions] = useState<AppTableConditions>(currentConditions);

  const [isUpdateListingStatusDialogOpen, setIsUpdateListingStatusDialogOpen] = useState<boolean>(false);
  const [isSelectListingItem, setIsSelectListingItem] = useState<boolean>(false);
  const [hashIdItems, setHashIdItems] = useState<string[]>([]);

  const onTableConditionChangedHandler = (values: AppTableConditions) => {
    if (JSON.stringify(values) !== JSON.stringify(tableConditions)) {
      setTableConditions(values);
      onTableConditionsChanged(values);
    }
  };

  const selectedAllData = useCallback(() => {
    if (!isSelectListingItem) {
      const dataHashIds = items.map((item) => item.hashId);
      setHashIdItems(dataHashIds);
    }
  }, [isSelectListingItem, items]);

  const onSubmitUpdateListingStatus = useCallback(
    async (formData?: UpdateStatusMultiListingFormDataRequest) => {
      if (formData) {
        setAppLoading(true);

        const updateStatusUnlistedData = {
          hashIds: formData.hashIds,
          rejectReason: formData.rejectReason,
          notifyUserUnlistedItem: formData.notifyUserUnlistedItem,
        };

        const updateStatusSoldData = {
          hashIds: formData.hashIds,
          soldPrice: Number(formData.soldPrice),
          soldReason: formData.soldReason,
        };

        const result = await updateStatusListingLists(
          formData.itemStatus === 'sold' ? updateStatusSoldData : updateStatusUnlistedData,
          formData.itemStatus as string,
        );
        setAppLoading(false);

        if (result.data) {
          showSnackbar(t('listing:common.message.updatedStatusListingsSuccess').toString(), 'success');
          setIsUpdateListingStatusDialogOpen(false);
          setHashIdItems([]);
          setIsSelectListingItem(false);
          setTimeout(() => {
            onFetch();
          }, 3000);
        } else {
          showSnackbar(t('listing:common.message.updatedStatusListingsFailed').toString(), 'error');
        }
      }
    },
    [onFetch, setAppLoading, showSnackbar, t],
  );

  const crUserEmail = (process.env.REACT_APP_USER_ROLE_CR || '').split(',');
  const isAllowsMultipleUpdateListing = useMemo(
    () =>
      authUser?.role === UserRole.GM ||
      authUser?.role === UserRole.Manager ||
      crUserEmail.includes(authUser?.email || ''),
    [authUser?.email, authUser?.role, crUserEmail],
  );

  return (
    <>
      <Container className={containerClasses.container}>
        <Box className={tableClasses.tableContainer}>
          {!isLoadingData && (
            <Grid container spacing={1}>
              <Grid item sm={12} md={9}>
                {!!isAllowsMultipleUpdateListing && (
                  <Box>
                    {totalItems > 0 && (
                      <Box>
                        {!isSelectListingItem ? (
                          <Box className={classes.buttonGroup}>
                            <AppButton
                              variant="contained"
                              color="primary"
                              startIcon={<CheckBoxOutlinedIcon />}
                              onClick={() => setIsSelectListingItem(true)}
                              disabled={totalItems <= 0}
                              style={{ marginRight: '6px', marginBottom: '6px' }}>
                              {t('listing:button.selectListing')}
                            </AppButton>
                            <AppButton
                              variant="contained"
                              color="primary"
                              startIcon={<CheckBoxOutlinedIcon />}
                              onClick={() => {
                                setIsUpdateListingStatusDialogOpen(true);
                                setIsSelectListingItem(false);
                                selectedAllData();
                              }}
                              disabled={totalItems <= 0}
                              style={{ marginBottom: '6px' }}>
                              {t('listing:button.selectAllListing')}
                            </AppButton>
                          </Box>
                        ) : (
                          <Box className={classes.buttonGroup}>
                            <AppButton
                              variant="contained"
                              color="primary"
                              startIcon={<SyncAltIcon />}
                              onClick={() => setIsUpdateListingStatusDialogOpen(true)}
                              disabled={hashIdItems.length === 0}
                              style={{ marginRight: '6px' }}>
                              {t('listing:button.updateAllListing')}
                            </AppButton>
                            <AppButton
                              variant="outlined"
                              color="primary"
                              onClick={() => {
                                setHashIdItems([]);
                                setIsSelectListingItem(false);
                              }}>
                              {t('common:button.cancel').toString()}
                            </AppButton>
                          </Box>
                        )}
                      </Box>
                    )}
                  </Box>
                )}
              </Grid>
              <Grid item sm={12} md={3}>
                <Typography variant="subtitle2" color="textSecondary" style={{ marginTop: '12px', textAlign: 'right' }}>
                  {t('common:list.resultTotal', { fieldName: t(totalItems.toLocaleString() || '0') })}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Box mt={2}>
            <AppTableList
              {...tableConditions}
              aria-label="listing"
              totalDataCount={totalItems}
              hasPagination
              onTableConditionChanged={onTableConditionChangedHandler}
              isLoading={isLoadingData}
              style={{ borderCollapse: 'collapse' }}
              disableRowsLabel
              rowsPerPage={defaultRowsPerPage}
              rowsPerPageOptions={rowsPerPageOptions}
              isFixedHeightAuto
              tableBodyContent={
                items.length > 0 && (
                  <>
                    {items.map((item) => (
                      <TableRow
                        key={item.hashId}
                        classes={{
                          root: clsx(
                            tableClasses.dividerRow,
                            [ListingCRStatus.Approved].includes(item.crStatus as ListingCRStatus) &&
                              tableClasses.dimRow,
                          ),
                        }}>
                        <AppTableCell style={{ whiteSpace: 'unset' }}>
                          <Box style={{ display: 'flex', position: 'relative' }}>
                            {isSelectListingItem === true && (
                              <AppCheckbox
                                name={item.hashId}
                                value={item.hashId}
                                onClick={() => {
                                  if (item.hashId) {
                                    let newValue = [];
                                    if (!hashIdItems.includes(item.hashId)) {
                                      newValue = [...hashIdItems, item.hashId];
                                    } else {
                                      newValue = [...hashIdItems].filter((val) => val !== item.hashId);
                                    }
                                    setHashIdItems(newValue);
                                  }
                                }}
                                size="medium"
                                color="primary"
                                className={classes.checkboxItem}
                              />
                            )}
                            <Box style={{ width: '100%' }}>
                              <ListingItem data={item} />
                            </Box>
                          </Box>
                        </AppTableCell>
                      </TableRow>
                    ))}
                  </>
                )
              }
            />
          </Box>
        </Box>
      </Container>

      {isUpdateListingStatusDialogOpen && (
        <EditListingStatusDialog
          title={t('listing:dialog.updateStatusListing.title')}
          open={isUpdateListingStatusDialogOpen}
          onClose={() => {
            setIsUpdateListingStatusDialogOpen(false);
          }}
          onCloseClick={() => {
            setIsUpdateListingStatusDialogOpen(false);
            if (!isSelectListingItem) {
              setHashIdItems([]);
            }
          }}
          onFormSubmit={onSubmitUpdateListingStatus}
          selectedItems={hashIdItems}
        />
      )}
    </>
  );
};

export default ListingTable;
