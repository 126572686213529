import React, { FC } from 'react';

import { Chip, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { SellerApplication } from '../../../../models';

export type SellerApplicationStatusBadgeProps = {
  status: SellerApplication['status'];
};

const useStyles = makeStyles((theme) => ({
  statusChip: {
    height: '26px',
    lineHeight: '26px',
  },
  statusSubmitted: {
    backgroundColor: theme.customPalette.appStatus.submitted.main,
    color: theme.customPalette.appStatus.submitted.contrastText,
  },
  statusApproved: {
    backgroundColor: theme.customPalette.appStatus.approved.main,
    color: theme.customPalette.appStatus.approved.contrastText,
  },
  statusRejected: {
    backgroundColor: theme.customPalette.appStatus.rejected.main,
    color: theme.customPalette.appStatus.rejected.contrastText,
  },
  statusPending: {
    backgroundColor: theme.customPalette.appStatus.pending.main,
    color: theme.customPalette.appStatus.pending.contrastText,
  },
}));

const SellerApplicationStatusBadge: FC<SellerApplicationStatusBadgeProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { status } = props;

  return (
    <Chip
      classes={{ root: classes.statusChip }}
      className={clsx({
        [classes.statusApproved]: status === 'approved',
        [classes.statusPending]: status === 'pending',
        [classes.statusSubmitted]: status === 'submitted',
        [classes.statusRejected]: status === 'rejected',
      })}
      label={t(`seller-applications:common.statuses.${status}`).toString()}
    />
  );
};

export default SellerApplicationStatusBadge;
