import { apiTCRequestV2 } from '../../helpers/request';
import { queryParamGenerator } from '../../helpers/utils';
import { ApiResponse } from '../../models';
import {
  GetOrderOnceListTCResponse,
  OrderOnceFiltersTC,
  OrderSubscriptionOpenData,
} from '../../models/order-order.model';

const getOrderOnceListQueryParam = (param?: OrderOnceFiltersTC): string => {
  const reqParam: { [x: string]: string | number } = {
    page: param?.page || 0, // plus 1 for index shift between FE component and backend query
    per_page: param?.rowsPerPage || 10,
    sort: param?.sortColumn ? `id%7C${(param?.sortDirection || 'desc').toUpperCase()}` : '',
  };

  return queryParamGenerator(reqParam);
};

export const getOrderOnceByHashId = async (
  userHashId: string,
  param?: OrderOnceFiltersTC,
): Promise<ApiResponse<GetOrderOnceListTCResponse>> => {
  try {
    const queryParamString = getOrderOnceListQueryParam(param);
    const result = await apiTCRequestV2<GetOrderOnceListTCResponse>(
      `/admin/order/once?${queryParamString}&userHashId=${userHashId}`,
    );

    return {
      data: result.data ?? null,
    };
  } catch (e) {
    return {
      data: null,
      error: 'Error GET Order Once By hashId',
    };
  }
};

export const cancelOrderOnceByHashId = async (hashId: string): Promise<ApiResponse<boolean>> => {
  try {
    const result = await apiTCRequestV2<boolean>(`/admin/order/once/${hashId}/cancel`, {
      method: 'PATCH',
    });

    return {
      data: result.data,
    };
  } catch (e) {
    return Promise.reject(e);
  }
};

export const approveOrderOnceByHashId = async (hashId: string): Promise<ApiResponse<boolean>> => {
  try {
    const result = await apiTCRequestV2<boolean>(`/admin_gm/order/once/${hashId}/approve`, {
      method: 'PATCH',
    });

    return {
      data: result.data,
    };
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getOrderSubscriptionOpen = async (userHashId: string): Promise<ApiResponse<OrderSubscriptionOpenData>> => {
  try {
    const result = await apiTCRequestV2<OrderSubscriptionOpenData>(
      `/admin/order/subscription/open?userHashId=${userHashId}`,
    );

    return {
      data: result.data ?? null,
    };
  } catch (e) {
    return {
      data: null,
      error: 'Error GET Order Subscription Open',
    };
  }
};

export const cancelOrderSubscriptionByHashId = async (hashId: string): Promise<ApiResponse<boolean>> => {
  try {
    const result = await apiTCRequestV2<boolean>(`/admin/order/subscription/${hashId}/cancel`, {
      method: 'PATCH',
    });

    return {
      data: result.data,
    };
  } catch (e) {
    return Promise.reject(e);
  }
};
