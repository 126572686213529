import React, { FC, useCallback, useEffect } from 'react';

import {
  Box,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  makeStyles,
  OutlinedInput,
  Typography,
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DISPLAY_NAME_REGEX } from '../../../../../../helpers/constants/regex-pattern';
import usePrivilege from '../../../../../../hooks/usePrivilege';
import { UserDataTC, UserEditApplicationBasicInfoRequest } from '../../../../../../models';
import useControlStyles from '../../../../../../theme/controls.style';
import AppButton from '../../../../../ui/AppButton';
import AppCheckbox from '../../../../../ui/AppCheckbox';
import AppDialog from '../../../../../ui/AppDialog';
import AppFormControl from '../../../../../ui/AppFormControl';

export type EditUserDataModalProps = {
  open: boolean;
  currentUser: UserDataTC;
  onSubmit: (data?: UserEditApplicationBasicInfoRequest) => void;
  onClose: () => void;
};

const useStyles = makeStyles((theme) => ({
  sectionSubscription: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    '& span': {
      padding: '4px 0 0 4px',
    },
  },
}));

const EditUserDataModal: FC<EditUserDataModalProps> = (props) => {
  const { open, currentUser, onSubmit, onClose } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const controlClasses = useControlStyles();
  const { canPerform } = usePrivilege();
  const canUpdateEmailSubscription = canPerform('userEmailSubscription', 'update');

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<UserEditApplicationBasicInfoRequest>({
    mode: 'onChange',
    defaultValues: {
      displayName: currentUser?.displayName,
      facebookUrl: currentUser?.facebookUrl || '',
      lineUrl: currentUser?.lineUrl || '',
      websiteUrl: currentUser?.websiteUrl || '',
      instagramUrl: currentUser?.instagramUrl || '',
      youtubeUrl: currentUser?.youtubeUrl || '',
      tiktokUrl: currentUser?.tiktokUrl || '',
      userHashId: currentUser?.userHashId || '',
      isNotificationEmailSubScripted: currentUser?.isNotificationEmailSubScripted,
      isNotificationEmailTpg: currentUser?.isNotificationEmailTpg,
    },
  });

  const handleSave = useCallback(
    (data: UserEditApplicationBasicInfoRequest) => {
      if (data?.userHashId) {
        const displayName = data.displayName && data.displayName.trim();
        const facebookUrl = data.facebookUrl && data.facebookUrl.trim();
        const lineUrl = data.lineUrl && data.lineUrl.trim();
        const websiteUrl = data.websiteUrl && data.websiteUrl.trim();
        const instagramUrl = data.instagramUrl && data.instagramUrl.trim();
        const youtubeUrl = data.youtubeUrl && data.youtubeUrl.trim();
        const tiktokUrl = data.tiktokUrl && data.tiktokUrl.trim();
        onSubmit({ ...data, displayName, facebookUrl, lineUrl, websiteUrl, instagramUrl, youtubeUrl, tiktokUrl });
      }
    },
    [onSubmit],
  );

  useEffect(() => {
    reset({
      displayName: currentUser?.displayName,
      facebookUrl: currentUser?.facebookUrl || '',
      lineUrl: currentUser?.lineUrl || '',
      websiteUrl: currentUser?.websiteUrl || '',
      instagramUrl: currentUser?.instagramUrl || '',
      youtubeUrl: currentUser?.youtubeUrl || '',
      tiktokUrl: currentUser?.tiktokUrl || '',
      userHashId: currentUser?.userHashId || '',
      isNotificationEmailSubScripted: currentUser?.isNotificationEmailSubScripted,
      isNotificationEmailTpg: currentUser?.isNotificationEmailTpg,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  return (
    <AppDialog
      open={open}
      title={t('user:v3.label.editUser')}
      noActionBar
      dialogProps={{
        fullWidth: true,
        maxWidth: 'md',
        TransitionProps: {
          onExited: () => {
            reset();
          },
        },
      }}>
      <form onSubmit={handleSubmit(handleSave)}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Box mb={3}>
              <Controller
                name="displayName"
                control={control}
                rules={{
                  required: true,
                  maxLength: {
                    value: 100,
                    message: t('error:validation.invalidFormat', {
                      fieldName: t('user:common.fields.displayName'),
                    }),
                  },
                  pattern: {
                    value: DISPLAY_NAME_REGEX,
                    message: t('error:validation.invalidFormat', {
                      fieldName: t('user:common.fields.displayName'),
                    }),
                  },
                }}
                render={({ field }) => (
                  <AppFormControl error={!!errors.displayName}>
                    <InputLabel htmlFor="user-app-displayName">{`${t('user:common.fields.displayName')} *`}</InputLabel>
                    <OutlinedInput {...field} id="user-app-displayName" fullWidth error={!!errors.displayName} />
                    {!!errors.displayName && (
                      <FormHelperText error>
                        {(errors.displayName.type === 'validate' || errors.displayName.type === 'maxLength') &&
                          t('validation:invalidMaxLengthField', {
                            fieldName: t('user:common.fields.displayName').toString(),
                            maxLength: 100,
                          })}
                      </FormHelperText>
                    )}
                  </AppFormControl>
                )}
              />
            </Box>
            <Box mb={3}>
              <Controller
                name="facebookUrl"
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field }) => (
                  <AppFormControl error={!!errors.facebookUrl}>
                    <InputLabel htmlFor="user-app-facebookUrl">{t('user:common.fields.facebookUrl')}</InputLabel>
                    <OutlinedInput
                      {...field}
                      id="user-app-facebookUrl"
                      fullWidth
                      error={!!errors.facebookUrl}
                      placeholder={t('user:details.placeHolder.facebookUrl')}
                    />
                  </AppFormControl>
                )}
              />
            </Box>
            <Box mb={3}>
              <Controller
                name="lineUrl"
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field }) => (
                  <AppFormControl error={!!errors.lineUrl}>
                    <InputLabel htmlFor="user-app-lineId">{t('user:common.fields.lineUrl')}</InputLabel>
                    <OutlinedInput
                      {...field}
                      id="user-app-lineId"
                      fullWidth
                      error={!!errors.lineUrl}
                      placeholder={t('user:details.placeHolder.lineUrl')}
                    />
                  </AppFormControl>
                )}
              />
            </Box>
            <Box>
              <Controller
                name="websiteUrl"
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field }) => (
                  <AppFormControl error={!!errors.websiteUrl}>
                    <InputLabel htmlFor="user-app-websiteUrl">{t('user:common.fields.websiteUrl')}</InputLabel>
                    <OutlinedInput
                      {...field}
                      id="user-app-websiteUrl"
                      fullWidth
                      error={!!errors.websiteUrl}
                      placeholder={t('user:details.placeHolder.websiteUrl')}
                    />
                  </AppFormControl>
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box mb={3}>
              <Controller
                name="instagramUrl"
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field }) => (
                  <AppFormControl error={!!errors.instagramUrl}>
                    <InputLabel htmlFor="user-app-instagramUrl">{t('user:common.fields.instagramUrl')}</InputLabel>
                    <OutlinedInput
                      {...field}
                      id="user-app-instagramUrl"
                      fullWidth
                      error={!!errors.instagramUrl}
                      placeholder={t('user:details.placeHolder.instagramUrl')}
                    />
                  </AppFormControl>
                )}
              />
            </Box>
            <Box mb={3}>
              <Controller
                name="youtubeUrl"
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field }) => (
                  <AppFormControl error={!!errors.youtubeUrl}>
                    <InputLabel htmlFor="user-app-youtubeUrl">{t('user:common.fields.youtubeUrl')}</InputLabel>
                    <OutlinedInput
                      {...field}
                      id="user-app-youtubeUrl"
                      fullWidth
                      error={!!errors.youtubeUrl}
                      placeholder={t('user:details.placeHolder.youtubeUrl')}
                    />
                  </AppFormControl>
                )}
              />
            </Box>
            <Box mb={3}>
              <Controller
                name="tiktokUrl"
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field }) => (
                  <AppFormControl error={!!errors.tiktokUrl}>
                    <InputLabel htmlFor="user-app-tiktokUrl">{t('user:common.fields.tiktokUrl')}</InputLabel>
                    <OutlinedInput
                      {...field}
                      id="user-app-tiktokUrl"
                      fullWidth
                      error={!!errors.tiktokUrl}
                      placeholder={t('user:details.placeHolder.tiktokUrl')}
                    />
                  </AppFormControl>
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography component="label" htmlFor="user-email-subscription" style={{ fontWeight: 700 }}>
              {t('user:details.label.newsletterSubscription')}
            </Typography>
            <Box className={classes.sectionSubscription}>
              <Controller
                name="isNotificationEmailSubScripted"
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field }) => (
                  <AppFormControl>
                    <FormControlLabel
                      control={
                        <AppCheckbox
                          id="user-email-subscription"
                          name={t('user:common.fields.featuredProductsOfWeek')}
                          checked={field.value}
                          onChange={(e) => {
                            field.onChange(e.currentTarget.checked);
                          }}
                          color="default"
                          disabled={!canUpdateEmailSubscription}
                        />
                      }
                      label={t('user:common.fields.featuredProductsOfWeek')}
                    />
                  </AppFormControl>
                )}
              />
              <Controller
                name="isNotificationEmailTpg"
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field }) => (
                  <AppFormControl>
                    <FormControlLabel
                      control={
                        <AppCheckbox
                          id="user-email-tpg"
                          name={t('user:common.fields.emailTpgDesc')}
                          checked={field.value}
                          onChange={(e) => {
                            field.onChange(e.currentTarget.checked);
                          }}
                          color="default"
                          disabled={!canUpdateEmailSubscription}
                        />
                      }
                      label={t('user:common.fields.emailTpgDesc')}
                    />
                  </AppFormControl>
                )}
              />
            </Box>
          </Grid>
        </Grid>

        <Box display="flex" justifyContent="flex-end" className={controlClasses.buttonsGroup} mt="16px">
          <AppButton color="default" variant="outlined" onClick={() => onClose()}>
            {t('common:button.cancel')}
          </AppButton>
          <AppButton type="submit" color="primary" disabled={!isValid}>
            {t('common:button.save')}
          </AppButton>
        </Box>
      </form>
    </AppDialog>
  );
};

export default EditUserDataModal;
