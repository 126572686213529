import React, { FC } from 'react';

import { Box, Container, Grid } from '@material-ui/core';
import ListIcon from '@material-ui/icons/List';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import { Link, useRouteMatch } from 'react-router-dom';

import IconCard from '../../components/partials/common/IconCard';
import useAppContainerStyles from '../../theme/container.style';
import useTypographyStyles from '../../theme/typography.style';

const SnsMenuListPage: FC = () => {
  const { path } = useRouteMatch();
  const containerClasses = useAppContainerStyles();
  const typoClasses = useTypographyStyles();

  return (
    <Container className={containerClasses.container}>
      <Box py={3}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Link to={`${path}/report`} className={typoClasses.noDecorationText}>
              <IconCard title="sns:menu.reportManager" icon={<ListIcon />} style={{ minHeight: '100%' }} />
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Link to={`${path}/recommended-tags`} className={typoClasses.noDecorationText}>
              <IconCard title="sns:menu.recommendedTags" icon={<LocalOfferIcon />} style={{ minHeight: '100%' }} />
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default SnsMenuListPage;
