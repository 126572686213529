import React, { FC, forwardRef, ReactNode, useContext, useEffect, useState } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  createStyles,
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Theme,
  Toolbar,
  Typography,
} from '@material-ui/core';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import DescriptionIcon from '@material-ui/icons/Description';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import HomeIcon from '@material-ui/icons/Home';
import ListIcon from '@material-ui/icons/List';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import PeopleIcon from '@material-ui/icons/People';
import PostAddOutlinedIcon from '@material-ui/icons/PostAddOutlined';
import Settings from '@material-ui/icons/Settings';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import StorageIcon from '@material-ui/icons/Storage';
import ViewList from '@material-ui/icons/ViewList';
import WidgetsIcon from '@material-ui/icons/Widgets';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { AuthContext } from '../../context/AuthContext';
import AppSnsSvgIcon from '../ui/AppSnsSvgIcon';

interface SidebarItem {
  icon?: ReactNode;
  text: string;
  url: string;
  onClick?: () => void;
}

export interface AppSidebarProps {
  isOpen: boolean;
  onSidebarClose?: () => void;
}

export const drawerWidth = 230;
export const minimizedSidebarWidth = 56;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      [theme.breakpoints.down('sm')]: {
        zIndex: theme.zIndex.appBar + 1,
      },
    },
    // necessary for content to be below app bar
    toolbar: { backgroundColor: theme.customPalette.appHeader.backgroundColor },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: theme.customPalette.appSidebar.backgroundColor,
      borderRight: 'none',
      color: theme.customPalette.appSidebar.contrastBgColor,
    },
    activeLink: { backgroundColor: theme.customPalette.appSidebar.menuItem.activeBackgroundColor },
    menuItem: {
      '&:hover': {
        backgroundColor: theme.customPalette.appSidebar.menuItem.hoverBackgroundColor,
      },
    },
    menuIcon: {
      color: theme.customPalette.appSidebar.contrastBgColor,
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      [theme.breakpoints.up('md')]: {
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: minimizedSidebarWidth,
      },
    },
    versionLabel: {
      position: 'absolute',
      bottom: 0,
      right: 0,
      left: 0,
      padding: theme.spacing(2),
      color: theme.customPalette.appSidebar.contrastBgColor,
      opacity: 0.5,
    },
    accordionBar: {
      background: 'transparent',
      boxShadow: theme.shadows[2],
      color: 'white',
      '& svg': {
        color: theme.customPalette.appSidebar.contrastBgColor,
      },
      margin: '0 !important',
    },
    accordionSummary: {
      margin: '0 !important',
      minHeight: '60px',
      '>div': {
        marginTop: '0',
      },
    },
    accordionDetail: {
      display: 'block',
      padding: '0px 0px 16px 0',
      margin: '0px',
    },
  }),
);

const menuList: SidebarItem[] = [
  {
    icon: <HomeIcon />,
    text: 'common:sidebar.menu.dashboard',
    url: '/dashboard',
  },
];

const menuListV3: SidebarItem[] = [
  {
    icon: <AssignmentIndIcon />,
    text: 'common:sidebar.menuV3.sellerApplication',
    url: '/v3/seller-applications',
  },
  {
    icon: <PeopleIcon />,
    text: 'common:sidebar.menuV3.t2hUser',
    url: '/v3/users',
  },
  {
    icon: <PostAddOutlinedIcon />,
    text: 'common:sidebar.menuV3.interviewLog',
    url: '/v3/interview-log',
  },
  {
    icon: <AssignmentIndIcon />,
    text: 'common:sidebar.menuV3.userApplication',
    url: '/v3/user-applications',
  },
  {
    icon: <FindInPageIcon />,
    text: 'common:sidebar.menuV3.fakha',
    url: '/v3/fakha',
  },
  {
    icon: <ListAltOutlinedIcon />,
    text: 'common:sidebar.menuV3.orderApprove',
    url: '/v3/order-approve',
  },
];
const menuListingList: SidebarItem[] = [
  {
    icon: <ListIcon />,
    text: 'common:sidebar.manageListing.listingV1',
    url: '/listing-v1',
  },
  {
    icon: <ViewList />,
    text: 'common:sidebar.manageListing.listing',
    url: '/listing',
  },
  {
    icon: <AnnouncementIcon />,
    text: 'common:sidebar.manageListing.reportListing',
    url: '/report-listing',
  },
];
const dailyChallengeList: SidebarItem[] = [
  {
    icon: <MonetizationOnIcon />,
    text: 'common:sidebar.dailyChallenge.loginBonus',
    url: '/daily-challenge-login-bonus',
  },
];
const menuSettingList: SidebarItem[] = [
  {
    icon: <MenuIcon />,
    text: 'common:sidebar.menu.menu',
    url: '/menus',
  },
  {
    icon: <AppSnsSvgIcon />,
    text: 'common:sidebar.menu.sns',
    url: '/sns',
  },
  {
    icon: <DescriptionIcon />,
    text: 'common:sidebar.menu.blogSlug',
    url: '/blog-slug',
  },
  {
    icon: <ShoppingCartIcon />,
    text: 'common:sidebar.menuV3.product',
    url: '/product',
  },
  {
    icon: <ConfirmationNumberIcon />,
    text: 'common:sidebar.menuV3.promoCode',
    url: '/promo-code',
  },
  {
    icon: <WidgetsIcon />,
    text: 'common:sidebar.menuV3.abTesting',
    url: '/ab-testing',
  },
  {
    icon: <StorageIcon />,
    text: 'common:sidebar.menu.masterData',
    url: '/master-data',
  },
  {
    icon: <Settings />,
    text: 'common:sidebar.menu.settings',
    url: '/settings',
  },
];

const AppMenuItem = (props: SidebarItem) => {
  const { onClick } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const { url, icon, text } = props;

  const menuClickHandler = () => {
    if (typeof onClick === 'function') {
      onClick();
    }
  };

  const CustomLink = React.useMemo(
    () =>
      forwardRef<HTMLAnchorElement>((linkProps, ref) => (
        <NavLink to={url} ref={ref} activeClassName={classes.activeLink} exact={url === '/'} {...linkProps} />
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [url],
  );

  return (
    <Box component="div" onClick={menuClickHandler}>
      <ListItem button component={CustomLink} classes={{ root: classes.menuItem }}>
        {!!icon && <ListItemIcon classes={{ root: classes.menuIcon }}>{icon}</ListItemIcon>}
        <ListItemText primary={t(text)} />
      </ListItem>
    </Box>
  );
};

const AppSidebar: FC<AppSidebarProps> = (props) => {
  const { isOpen, onSidebarClose } = props;
  const classes = useStyles();
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(isOpen);
  const { t } = useTranslation();
  const { authUser } = useContext(AuthContext);

  const menuListingListData = menuListingList.filter((item) => !item.text.includes('listingV1'));

  const closeSidebar = () => {
    if (typeof onSidebarClose === 'function') {
      onSidebarClose();
    }
  };

  useEffect(() => {
    setIsSidebarOpen(isOpen);
  }, [isOpen]);

  const drawer = (
    <div>
      <Toolbar variant="dense" className={classes.toolbar} />
      <List component="nav">
        {menuList.map((menu: SidebarItem) => (
          <AppMenuItem key={menu.text} {...menu} />
        ))}

        <Accordion classes={{ root: classes.accordionBar }} defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="menu-v3-content"
            id="menu-v3-header"
            classes={{ root: classes.accordionSummary }}>
            <Box>
              <Typography style={{ fontWeight: 'bold' }}>{t('common:sidebar.menuV3.title')}</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails classes={{ root: classes.accordionDetail }}>
            {menuListV3.map((menu: SidebarItem) => (
              <AppMenuItem key={menu.text} {...menu} />
            ))}
          </AccordionDetails>
        </Accordion>

        <Accordion classes={{ root: classes.accordionBar }} defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="menu-v3-content"
            id="menu-v3-header"
            classes={{ root: classes.accordionSummary }}>
            <Box>
              <Typography style={{ fontWeight: 'bold' }}>{t('common:sidebar.manageListing.title')}</Typography>
            </Box>
          </AccordionSummary>
          {authUser?.role === 'ADMIN_GM' ? (
            <AccordionDetails classes={{ root: classes.accordionDetail }} style={{ marginBottom: '-16px' }}>
              {menuListingList.map((menu: SidebarItem) => (
                <AppMenuItem key={menu.text} {...menu} />
              ))}
            </AccordionDetails>
          ) : (
            <AccordionDetails classes={{ root: classes.accordionDetail }}>
              {menuListingListData.map((menu: SidebarItem) => (
                <AppMenuItem key={menu.text} {...menu} />
              ))}
            </AccordionDetails>
          )}
        </Accordion>

        <Accordion classes={{ root: classes.accordionBar }} defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="menu-v3-content"
            id="menu-v3-header"
            classes={{ root: classes.accordionSummary }}>
            <Box>
              <Typography style={{ fontWeight: 'bold' }}>{t('common:sidebar.dailyChallenge.title')}</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails classes={{ root: classes.accordionDetail }}>
            {dailyChallengeList.map((menu: SidebarItem) => (
              <AppMenuItem key={menu.text} {...menu} />
            ))}
          </AccordionDetails>
        </Accordion>

        {menuSettingList.map((menu: SidebarItem) => (
          <AppMenuItem key={menu.text} {...menu} />
        ))}
      </List>
    </div>
  );

  return (
    <>
      <Hidden smDown implementation="js">
        <Drawer
          container={document.body}
          variant="permanent"
          open={isSidebarOpen}
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: isSidebarOpen,
            [classes.drawerClose]: !isSidebarOpen,
          })}
          classes={{
            paper: clsx(classes.drawerPaper, {
              [classes.drawerOpen]: isSidebarOpen,
              [classes.drawerClose]: !isSidebarOpen,
            }),
          }}>
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden mdUp implementation="js">
        <Drawer
          container={document.body}
          variant="temporary"
          open={isSidebarOpen}
          onClose={closeSidebar}
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: isSidebarOpen,
            [classes.drawerClose]: !isSidebarOpen,
          })}
          classes={{
            paper: clsx(classes.drawerPaper, {
              [classes.drawerOpen]: isSidebarOpen,
              [classes.drawerClose]: !isSidebarOpen,
            }),
          }}>
          {drawer}
        </Drawer>
      </Hidden>
    </>
  );
};

export default AppSidebar;
