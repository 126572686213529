/* eslint-disable import/prefer-default-export */

import {
  QueryConstraint,
  collection,
  doc,
  getDocs,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from 'firebase/firestore';
import Cookies from 'js-cookie';

import { FS_DB_COLLECTION, firestoreDb } from '../helpers/firebase';
import { apiRequest, apiTCRequest } from '../helpers/request';
import { queryParamGenerator } from '../helpers/utils';
import {
  User,
  UserData,
  ApiResponse,
  EmailSubscription,
  UserEmailSubscription,
  UserEmailSubscriptionRequest,
  UserSearchFilters,
  GetUserListResponse,
  UserRole,
  UserNationalIdAvailableResponse,
  UserBankAccountAvailableResponse,
} from '../models';

export const getMe = async (): Promise<UserData | null> => {
  let data;
  try {
    if (process.env.REACT_APP_V3_ENABLED === 'true') {
      const { data: rawData } = await apiTCRequest<UserData>('auth/user/me', {
        headers: {
          Cookies: Cookies.get('admin_token'),
        },
      });

      data = rawData;
    } else {
      const { data: rawData } = await apiRequest<UserData>('/users/me', {
        headers: {
          Cookies: Cookies.get('admin_token'),
        },
      });

      data = rawData;
    }

    return data;
  } catch (e) {
    return null;
  }
};

const getUserListQueryParam = (param?: UserSearchFilters): string => {
  const reqParam: { [x: string]: string | number } = {
    page: (param?.page || 0) + 1, // plus 1 for index shift between FE component and backend query
    per_page: param?.rowsPerPage || 10,
    sort: param?.sortColumn ? `${param.sortColumn}|${(param?.sortDirection || 'desc').toUpperCase()}` : '',
  };

  // Only search with keyword when it comes with the selected column to be searched
  if (param?.searchKeyword && param?.searchField) {
    reqParam[param.searchField] = param.searchKeyword.trim();
  }
  if (param?.filteringStatuses?.length) {
    reqParam.status = param.filteringStatuses.join(',');
  }
  if (param?.filteringRoles?.length) {
    reqParam.role = param.filteringRoles.join(',');
  }
  if (param?.minCoin) {
    reqParam.minCoin = param.minCoin;
  }
  if (param?.maxCoin) {
    reqParam.maxCoin = param.maxCoin;
  }
  if (param?.q) {
    reqParam.q = param.q;
  }

  return queryParamGenerator(reqParam);
};

export const getUserList = async (param?: UserSearchFilters): Promise<ApiResponse<GetUserListResponse>> => {
  try {
    const queryParamString = getUserListQueryParam(param);
    const result = await apiRequest<UserData[]>(`/admin/users?${queryParamString}`);

    return {
      data: {
        users: result.data,
        totalCount: Number(result.headers['x-total-count'] || '0') || result.data.length,
      },
    };
  } catch (e) {
    return {
      data: null,
      error: 'user:error.list.requestFailed',
    };
  }
};

export const getAdminUserList = async (searchKeyword?: string): Promise<ApiResponse<GetUserListResponse>> => {
  try {
    const queryParamString = getUserListQueryParam({
      q: searchKeyword,
      page: 0,
      rowsPerPage: 10000,
      filteringRoles: [UserRole.Staff, UserRole.Manager, UserRole.GM],
    });
    const result = await apiRequest<UserData[]>(`/admin/users?${queryParamString}`);

    return {
      data: {
        users: result.data,
        totalCount: Number(result.headers['x-total-count'] || '0') || result.data.length,
      },
    };
  } catch (e) {
    return {
      data: null,
      error: 'user:error.list.requestFailed',
    };
  }
};

export const getUserByHashId = async (hashId: string): Promise<User | undefined> => {
  try {
    const result = await apiRequest<UserData>(`/admin/users/${hashId}`);

    return result.data ? new User(result.data) : undefined;
  } catch (e) {
    // TODO: remove this log later when there are no problem from this endpoint calling
    console.log(`[DEBUG] error from /admin/users/${hashId}`, e);
    return undefined;
  }
};

export const getUserByMobileNumber = async (mobileNumber: string): Promise<User | undefined> => {
  try {
    const result = await apiRequest<UserData[]>(`/admin/users?mobile=${mobileNumber}`);

    return result.data && result.data.length ? new User(result.data[0]) : undefined;
  } catch (e) {
    return undefined;
  }
};

export const updateUserSocialUrls = async (hashId: string, user: Partial<UserData>): Promise<User | undefined> => {
  try {
    const result = await apiRequest<UserData>(`/admin/users/${hashId}/social-urls`, {
      method: 'POST',
      data: { ...user },
    });

    return result.data ? new User(result.data) : undefined;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateUserMembership = async (hashId: string, user: Partial<UserData>): Promise<User | undefined> => {
  try {
    const result = await apiRequest<UserData>(`/admin/users/${hashId}/membership`, {
      method: 'POST',
      data: { ...user },
    });

    return result.data ? new User(result.data) : undefined;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateUserBalance = async (hashId: string, balance: number): Promise<User | undefined> => {
  try {
    const result = await apiRequest<UserData>(`/admin/users/${hashId}/balance`, {
      method: 'POST',
      data: {
        balance,
      },
    });

    return result.data ? new User(result.data) : undefined;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateUserRole = async (hashId: string, role: string): Promise<User | undefined> => {
  try {
    const result = await apiRequest<UserData>(`/admin/users/${hashId}/roles`, {
      method: 'POST',
      data: {
        role,
      },
    });

    return result.data ? new User(result.data) : undefined;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateUserInterviewLog = async (hashId: string, interviewLog?: string): Promise<User | undefined> => {
  try {
    const result = await apiRequest<UserData>(`/admin/users/${hashId}/interview-log`, {
      method: 'POST',
      data: {
        interviewLog,
      },
    });

    return result.data ? new User(result.data) : undefined;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getEmailSubscriptions = async (): Promise<EmailSubscription[] | undefined> => {
  try {
    const result = await apiRequest<EmailSubscription[]>('/admin/email-subscriptions');

    return result.data;
  } catch (e) {
    return undefined;
  }
};

export const getUserEmailSubscriptions = async (userHashId: string): Promise<UserEmailSubscription[] | undefined> => {
  try {
    const result = await apiRequest<UserEmailSubscription[]>(`/admin/users/${userHashId}/subscriptions`);

    return result.data;
  } catch (e) {
    return undefined;
  }
};

export const updateUserEmailSubscription = async (
  userHashId: string,
  data: UserEmailSubscriptionRequest,
): Promise<UserEmailSubscription[] | undefined> => {
  try {
    const result = await apiRequest<UserEmailSubscription[]>(`/admin/users/${userHashId}/email-subscriptions`, {
      method: 'POST',
      data: { ...data },
    });

    return result.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const activateUser = async (userHashId: string): Promise<User> => {
  try {
    const result = await apiRequest<User>(`/admin/users/${userHashId}/activate`, {
      method: 'POST',
    });

    return result.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const deactivateUser = async (userHashId: string, reason: string): Promise<User> => {
  try {
    const result = await apiRequest<User>(`/admin/users/${userHashId}/deactivate`, {
      method: 'POST',
      data: { reason },
    });

    return result.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const deleteUser = async (userHashId: string): Promise<boolean> => {
  try {
    const result = await apiRequest<User>(`/admin/users/${userHashId}/delete`, {
      method: 'DELETE',
    });

    // When deleting user from T2H, also remove all of their posts
    if (result.data) {
      const deletePostRequestRef = collection(firestoreDb, FS_DB_COLLECTION.posts);
      const constrainst: QueryConstraint[] = [
        where('author', 'in', [doc(firestoreDb, FS_DB_COLLECTION.users, userHashId), userHashId]),
      ];
      const postToDeleteRequest = await getDocs(query(deletePostRequestRef, ...constrainst));
      if (postToDeleteRequest.docs) {
        const postIdsToBeDeleted = postToDeleteRequest.docs.map((snapshot) => snapshot.id);
        await Promise.all(
          postIdsToBeDeleted.map(async (docId) => {
            const req = await updateDoc(doc(firestoreDb, FS_DB_COLLECTION.posts, docId), {
              deletedAt: serverTimestamp(),
            });

            return req;
          }),
        );
      }
    }
    return !!result.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const verifyUserEmail = async (userHashId: string): Promise<User> => {
  try {
    const result = await apiRequest<User>(`/admin/users/${userHashId}/verify-email`, {
      method: 'POST',
    });

    return result.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const checkNationalIdAvailable = async (
  nationalId: string,
): Promise<UserNationalIdAvailableResponse[] | undefined> => {
  try {
    const result = await apiTCRequest<UserNationalIdAvailableResponse[]>(
      `/admin/user-application/check-availability/national-id/${nationalId}`,
    );
    return result.data;
  } catch (e) {
    return undefined;
  }
};

export const checkBookBankNumberAvailable = async (
  bankAccount: string,
): Promise<UserBankAccountAvailableResponse[] | undefined> => {
  try {
    const result = await apiTCRequest<UserBankAccountAvailableResponse[]>(
      `/admin/user-application/check-availability/book-bank-number/${bankAccount}`,
    );
    return result.data;
  } catch (e) {
    return undefined;
  }
};
