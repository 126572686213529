import React, { FC, useContext, useState } from 'react';

import { Box, Paper, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useDeepCompareEffect from 'use-deep-compare-effect';

import InterviewLogListTable from './InterviewLog/InterviewLogListTable';
import { AppGlobalUiContext } from '../../../../../context/AppGlobalUiContext';
import { SearchFilterContext } from '../../../../../context/SearchFilterContext';
import { defaultRowsPerPage } from '../../../../../helpers/constants';
import {
  AppTableConditions,
  InterviewLogData,
  UserDataTC,
  UserListSearchParamsTC,
  UserSearchFiltersTC,
} from '../../../../../models';
import { getInterviewLogByHashId } from '../../../../../services/v3/user';

const searchFilterContextKey = 'interview';
export interface InterviewLogProps {
  currentUser: UserDataTC;
}
const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(5),
    minHeight: '100vh',
  },
}));

const InterviewLog: FC<InterviewLogProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { currentUser } = props;
  const { showSnackbar } = useContext(AppGlobalUiContext);
  const [interviewLogList, setInterviewLogList] = useState<InterviewLogData[]>([]);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [totalInterviewLog, setTotalInterviewLog] = useState<number>(0);

  const { currentFilters: ctxFilters, setFilters } = useContext(SearchFilterContext);
  const currentFilters: UserListSearchParamsTC = ctxFilters ? ctxFilters[searchFilterContextKey] : undefined;

  const [tableConditions, setTableConditions] = useState<AppTableConditions>({
    page: currentFilters?.page || 0,
    rowsPerPage: currentFilters?.rowsPerPage || defaultRowsPerPage,
    sortColumn: currentFilters?.sortColumn || 'createdAt',
    sortDirection: currentFilters?.sortDirection || 'desc',
  });

  const getCurrentSearchParam = (): UserSearchFiltersTC => ({
    page: tableConditions.page || 0,
    rowsPerPage: tableConditions.rowsPerPage || defaultRowsPerPage,
    sortColumn: tableConditions.sortColumn || 'createdAt',
    sortDirection: tableConditions.sortDirection || 'desc',
  });

  const getInterviewLog = async (isPageSubscribed = true): Promise<boolean> => {
    if (isPageSubscribed) {
      setIsLoadingData(true);

      const searchConditions = getCurrentSearchParam();
      setFilters(searchFilterContextKey, searchConditions);

      const result = await getInterviewLogByHashId(currentUser.userHashId, searchConditions);
      setIsLoadingData(false);

      if (result.data) {
        setInterviewLogList(result.data.contents);
        setTotalInterviewLog(result.data?.page?.totalElements || 0);
        return Promise.resolve(true);
      }

      showSnackbar(t('interview-log:message.requestListFailed').toString(), 'error');
      return Promise.reject(result.error);
    }

    return false;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useDeepCompareEffect((): any => {
    let isSubscribed = true;
    getInterviewLog(isSubscribed);

    return () => (isSubscribed = false);
  }, [tableConditions]);

  const tableConditionsChangedHandler = (values: AppTableConditions) => {
    setTableConditions(values);
  };

  return (
    <>
      <Paper elevation={1}>
        <Box className={classes.container}>
          <Box>
            <InterviewLogListTable
              currentConditions={tableConditions}
              interviewLog={interviewLogList}
              totalData={totalInterviewLog}
              isLoadingData={isLoadingData}
              onTableConditionsChanged={tableConditionsChangedHandler}
              onFetch={() => getInterviewLog()}
            />
          </Box>
        </Box>
      </Paper>
    </>
  );
};

export default InterviewLog;
