import React, { FC, useState, useContext, useCallback } from 'react';

import { Box, Container, TableRow, Typography } from '@material-ui/core';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { AppGlobalUiContext } from '../../../../context/AppGlobalUiContext';
import { AppTableConditions, TableColumn } from '../../../../models';
import { ReportListingData, ReportListingFormDataRequest } from '../../../../models/report-listing.model';
import { updateReportListing } from '../../../../services/report-listing';
import useAppContainerStyles from '../../../../theme/container.style';
import useAppTableStyles from '../../../../theme/table.style';
import AppDatetimeText from '../../../ui/AppDatetimeText';
import AppTableCell from '../../../ui/AppTableCell';
import AppTableList from '../../../ui/AppTableList';
import ReportedStatusBadge from '../ReportedStatusBadge';
import ReportItemDialog from '../ReportItemDialog';

export type ReportedItemTableProps = {
  currentConditions: AppTableConditions;
  totalReportedItems: number;
  isLoadingData: boolean;
  reportList: ReportListingData[];
  onTableConditionsChanged: (values: AppTableConditions) => void;
  onFetch: () => void;
};

const reportedItemTableColumns: TableColumn[] = [
  { name: 'adminStatus', label: 'report-listing:list.reportedItem.columns.adminStatus', width: 150, unsortable: true },
  { name: 'listingId', label: 'report-listing:list.reportedItem.columns.listingId', width: 140, unsortable: true },
  { name: 'sellerName', label: 'report-listing:list.reportedItem.columns.sellerName', width: 140, unsortable: true },
  { name: 'reportType', label: 'report-listing:list.reportedItem.columns.reportType', width: 140, unsortable: true },
  {
    name: 'reportedComment',
    label: 'report-listing:list.reportedItem.columns.reportedComment',
    width: 300,
    unsortable: true,
  },
  { name: 'reportedBy', label: 'report-listing:list.reportedItem.columns.reportedBy', width: 140, unsortable: true },
  { name: 'reportedAt', label: 'report-listing:list.reportedItem.columns.reportedAt', width: 140, unsortable: true },
  { name: 'reason', label: 'report-listing:list.reportedItem.columns.reason', width: 240, unsortable: true },
  { name: 'updatedAt', label: 'report-listing:list.reportedItem.columns.updatedAt', width: 140, unsortable: true },
];

const ReportedItemTable: FC<ReportedItemTableProps> = (props) => {
  const { t } = useTranslation();
  const { totalReportedItems, isLoadingData, currentConditions, reportList, onFetch, onTableConditionsChanged } = props;
  const containerClasses = useAppContainerStyles();
  const tableClasses = useAppTableStyles();
  const { setAppLoading, showSnackbar } = useContext(AppGlobalUiContext);
  const [tableConditions, setTableConditions] = useState<AppTableConditions>(currentConditions);
  const [isReportDialogOpen, setIsReportDialogOpen] = useState<boolean>(false);
  const [selectedReportItem, setSelectedReportItem] = useState<ReportListingData | undefined>(undefined);

  const onTableConditionChangedHandler = useCallback(
    (values: AppTableConditions) => {
      if (JSON.stringify(values) !== JSON.stringify(tableConditions)) {
        setTableConditions(values);
        onTableConditionsChanged(values);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableConditions],
  );

  const onEditFormSubmit = useCallback(
    async (data?: ReportListingFormDataRequest) => {
      if (selectedReportItem && data) {
        setAppLoading(true);

        const result = await updateReportListing(selectedReportItem.hashId, data);

        if (result.data) {
          setAppLoading(false);
          showSnackbar(t('report-listing:message.updateReportListingSuccess').toString(), 'success');
          setIsReportDialogOpen(false);
          onFetch();
        } else {
          setAppLoading(false);
          showSnackbar(t('report-listing:message.error.detail.saveFailed').toString(), 'error');
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedReportItem, setAppLoading, showSnackbar, t],
  );

  return (
    <>
      <Container className={containerClasses.container}>
        <Box className={tableClasses.tableContainer}>
          {!isLoadingData && (
            <Typography
              variant="subtitle2"
              color="textSecondary"
              style={{ marginBottom: '8px', marginRight: '8px', textAlign: 'right' }}>
              {t('common:list.resultTotal', { fieldName: t(reportList.length.toLocaleString() || '0') })}
            </Typography>
          )}
          <AppTableList
            {...tableConditions}
            aria-label="reported-item-list"
            columns={reportedItemTableColumns}
            totalDataCount={totalReportedItems}
            hasPagination
            onTableConditionChanged={onTableConditionChangedHandler}
            isLoading={isLoadingData}
            tableBodyContent={
              reportList.length > 0 && (
                <>
                  {reportList.map((reportedItem: ReportListingData, idx: number) => {
                    const itemKey = `${reportedItem}${idx}`;
                    return (
                      <TableRow
                        hover
                        key={itemKey}
                        classes={{ root: clsx(tableClasses.tableRow, tableClasses.clickableTableRow) }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setIsReportDialogOpen(true);
                          setSelectedReportItem(reportedItem);
                        }}>
                        <AppTableCell>
                          <ReportedStatusBadge status={reportedItem.adminStatus} />
                        </AppTableCell>
                        <AppTableCell>
                          <a
                            href={`/listing/${reportedItem.item.hashId}`}
                            target="_blank"
                            rel="noreferrer"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}>
                            {reportedItem.item.hashId}
                          </a>
                        </AppTableCell>

                        <AppTableCell>
                          <a
                            href={`/v3/users/${reportedItem.item.userHashId}`}
                            target="_blank"
                            rel="noreferrer"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}>
                            {reportedItem.item.userHashId}
                          </a>
                        </AppTableCell>
                        <AppTableCell>{t(`report-listing:common.reportType.${reportedItem.type}`)}</AppTableCell>
                        <AppTableCell>{reportedItem.detail ? reportedItem.detail : '-'}</AppTableCell>
                        <AppTableCell>
                          {reportedItem.reporter.displayName !== null ? (
                            <>
                              {reportedItem.reporter.hashId ? (
                                <a
                                  href={`/v3/users/${reportedItem.reporter.hashId}`}
                                  target="_blank"
                                  rel="noreferrer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}>
                                  {reportedItem.reporter.hashId}
                                </a>
                              ) : (
                                <>{reportedItem.reporter.hashId}</>
                              )}
                            </>
                          ) : (
                            '-'
                          )}
                        </AppTableCell>
                        <AppTableCell>
                          <AppDatetimeText
                            variant="subtitle2"
                            value={reportedItem.createdAt ? dayjs(reportedItem.createdAt) : null}
                            withTimeNewLine
                          />
                        </AppTableCell>
                        <AppTableCell>{reportedItem.crDetail ? reportedItem.crDetail : '-'}</AppTableCell>
                        <AppTableCell>
                          <AppDatetimeText
                            variant="subtitle2"
                            value={reportedItem.updatedAt ? dayjs(reportedItem.updatedAt) : null}
                            withTimeNewLine
                          />
                        </AppTableCell>
                      </TableRow>
                    );
                  })}
                </>
              )
            }
          />
        </Box>
      </Container>

      {selectedReportItem && (
        <ReportItemDialog
          key={selectedReportItem.hashId}
          title={t('report-listing:dialog.title')}
          open={isReportDialogOpen}
          onCloseClick={() => {
            setIsReportDialogOpen(false);
            setSelectedReportItem(undefined);
          }}
          reportItem={selectedReportItem}
          onFormSubmit={onEditFormSubmit}
        />
      )}
    </>
  );
};

export default ReportedItemTable;
