import React, { FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import { Paper, Box, Typography, makeStyles, PaperProps, CircularProgress } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import RefreshIcon from '@material-ui/icons/Refresh';
import clsx from 'clsx';

import { setAdminTokenCookie } from '../../../../helpers/cookies';
import { getAuthToken } from '../../../../services/auth';

interface ImageThumbnailProps {
  imageUrl: string | undefined;
  altText?: string;
  thumbnailLabel?: ReactNode;
  listingHashId?: string;
  imageClick?: (imageUrl: string) => void;
  noLabel?: boolean;
  noDownload?: boolean;
  paperProps?: PaperProps;
  titleImage?: string;
  typeListing?: boolean;
}

const useStyles = makeStyles((theme) => ({
  imageThumbnailContainer: {
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
    minHeight: '200px',
  },
  clickableImage: {
    cursor: 'pointer',
  },
  imageDownloadButton: {
    cursor: 'pointer',
    opacity: 0.6,
    '&:hover': {
      opacity: 1,
    },
  },
  imageThumbnailBlock: {
    cursor: 'pointer',
    height: '175px',
    overflow: 'hidden',
    backgroundColor: theme.palette.grey[200],
  },
  thumbnailImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  imageRefreshButton: {
    position: 'absolute',
    right: '8px',
    top: '8px',
    cursor: 'pointer',
    borderRadius: '50%',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    width: '24px',
    height: '24px',
    textAlign: 'center',
  },
  iconCircularProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

const ImageThumbnail: FC<ImageThumbnailProps> = (props) => {
  const {
    imageUrl,
    altText,
    thumbnailLabel,
    listingHashId,
    imageClick,
    noDownload = false,
    noLabel = false,
    paperProps,
    titleImage,
    typeListing = false,
  } = props;

  const classes = useStyles();

  const [mediaPath, setMediaPath] = useState<string>('');
  const mediaPathRef = useRef<string>('');
  const imageDefault = '../../static/images/image/listing-image-default.jpeg';
  const [isLoadingImage, setIsLoadingImage] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const downloadItem = async (e: any) => {
    e.stopPropagation();

    if (imageUrl) {
      const image = await fetch(imageUrl);
      const imageBlog = await image.blob();
      const imageURL = URL.createObjectURL(imageBlog);
      const fileName = imageUrl.split('/').pop()?.replace(/\s/gi, '_');

      const link = document.createElement('a');
      link.href = imageURL;
      link.download = `${listingHashId || ''}_${altText?.toLowerCase().replace(/\s/gi, '_') || fileName}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const onImageClick = useCallback(() => {
    if (imageUrl && typeof imageClick === 'function') {
      imageClick(imageUrl);
    }
  }, [imageUrl, imageClick]);

  const loadMedia = useCallback(async () => {
    setIsLoadingImage(true);
    if (!imageUrl || !imageUrl.includes('private')) {
      setIsLoadingImage(false);
      return;
    }

    if (mediaPathRef.current.trim()) {
      URL.revokeObjectURL(mediaPathRef.current.trim());
    }

    const tokenInfo = getAuthToken();
    const idToken = tokenInfo?.firebaseIdToken;

    if (idToken) {
      const result = await fetch(imageUrl, {
        headers: {
          'x-admin-token': idToken || '',
        },
      })
        .then(async (response) => {
          const blob = await response.blob();
          return URL.createObjectURL(blob);
        })
        .catch(() => null);
      if (result) {
        setMediaPath(result);
        mediaPathRef.current = result;
        setIsLoadingImage(false);
      } else {
        setIsLoadingImage(false);
      }
    }
  }, [imageUrl]);

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT === 'production' && imageUrl) {
      const tokenInfo = getAuthToken();
      const idToken = tokenInfo?.firebaseIdToken;
      if (idToken) {
        setAdminTokenCookie(idToken);
      }
      setMediaPath(imageUrl);
      loadMedia();
    } else {
      loadMedia();
    }

    return () => {
      if (mediaPathRef.current.trim()) {
        URL.revokeObjectURL(mediaPathRef.current.trim());
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageUrl]);

  if (imageUrl || mediaPath) {
    return (
      <Paper classes={{ root: classes.imageThumbnailContainer }} {...paperProps}>
        {isLoadingImage ? (
          <CircularProgress className={classes.iconCircularProgress} size={20} color="inherit" />
        ) : (
          <>
            <Box
              className={clsx(classes.imageThumbnailBlock, {
                [classes.clickableImage]: typeof imageClick === 'function',
              })}
              onClick={() => onImageClick()}>
              <img
                title={titleImage}
                src={mediaPath || imageUrl || imageDefault}
                alt={altText}
                className={classes.thumbnailImage}
                onError={(e) => {
                  (e.target as HTMLImageElement).src = typeListing ? imageDefault : '';
                }}
              />
            </Box>
            {!noLabel && (
              <Box p={1} display="flex">
                <Box flexGrow={1} minWidth={0} pr={1}>
                  <Typography variant="subtitle2">{thumbnailLabel}</Typography>
                </Box>
                {!noDownload && (
                  <Box display="flex" className={classes.imageDownloadButton} onClick={downloadItem}>
                    <GetAppIcon fontSize="small" />
                  </Box>
                )}
              </Box>
            )}
            {!mediaPath && imageUrl && imageUrl.includes('private') && (
              <Box className={classes.imageRefreshButton}>
                {isLoadingImage ? (
                  <CircularProgress style={{ width: '20px', height: '20px' }} color="primary" />
                ) : (
                  <RefreshIcon color="primary" onClick={loadMedia} />
                )}
              </Box>
            )}
          </>
        )}
      </Paper>
    );
  }
  return null;
};

export default ImageThumbnail;
