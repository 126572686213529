import React, { FC, useEffect } from 'react';

import {
  Box,
  Container,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import YoutubeSearchedForIcon from '@material-ui/icons/YoutubeSearchedFor';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  SelectItem,
  InterviewLogListSearchFormDataTC,
  InterviewLogStatus,
  InterviewLogContactBy,
  InterviewLogContactType,
} from '../../../../../models';
import useAppContainerStyles from '../../../../../theme/container.style';
import AppButton from '../../../../ui/AppButton';
import AppFormControl from '../../../../ui/AppFormControl';
import AppFormControlGroup from '../../../../ui/AppFormControlGroup';

export type InterviewLogListSearchFormProps = {
  isLoadingData?: boolean;
  currentFilters: InterviewLogListSearchFormDataTC | undefined;
  onSearchFormSubmit: (values: InterviewLogListSearchFormDataTC) => void;
  type: string;
};

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
  },
  searchBoxContainer: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 500,
    },
  },
}));

const searchFieldList: SelectItem<string>[] = [
  {
    label: 'user:common.fields.hashId',
    value: 'userHashId',
  },
  {
    label: 'user:common.fields.email',
    value: 'userEmail',
  },
  {
    label: 'user:common.fields.mobile',
    value: 'userMobilePhone',
  },
];

const noneUserSearchFieldList: SelectItem<string>[] = [
  {
    label: 'interview-log:fields.name',
    value: 'name',
  },
  {
    label: 'interview-log:fields.email',
    value: 'email',
  },
  {
    label: 'interview-log:fields.mobile',
    value: 'mobilePhone',
  },
];

const contactByList = [
  {
    label: 'Email',
    value: InterviewLogContactBy.Email,
  },
  {
    label: 'Line',
    value: InterviewLogContactBy.Line,
  },
  {
    label: 'Facebook',
    value: InterviewLogContactBy.Facebook,
  },
  {
    label: 'Chat',
    value: InterviewLogContactBy.Chat,
  },
  {
    label: 'Calling',
    value: InterviewLogContactBy.Calling,
  },
  {
    label: 'System',
    value: InterviewLogContactBy.System,
  },
  {
    label: 'LineQA',
    value: InterviewLogContactBy.LineQA,
  },
];
const contactTypeList = [
  {
    label: 'interview-log:contactType.WEBSITE',
    value: InterviewLogContactType.Website,
  },
  {
    label: 'interview-log:contactType.COMPLAIN',
    value: InterviewLogContactType.Complain,
  },
  {
    label: 'interview-log:contactType.PURCHASE_MEMBERSHIP',
    value: InterviewLogContactType.PurchaseMembership,
  },
  {
    label: 'interview-log:contactType.PURCHASE_COIN',
    value: InterviewLogContactType.PurchaseMemberCoin,
  },
  {
    label: 'interview-log:contactType.PROBLEM_WITH_COIN',
    value: InterviewLogContactType.ProblemWithCoin,
  },
  {
    label: 'interview-log:contactType.NOTIFICATION_EMAIL_UN_SUBSCRIPTION',
    value: InterviewLogContactType.NotificationEmailUnSubscription,
  },
  {
    label: 'interview-log:contactType.REMOVE_ACCOUNT',
    value: InterviewLogContactType.RemoveAccount,
  },
  {
    label: 'interview-log:contactType.TENT_VISIT',
    value: InterviewLogContactType.TentVisit,
  },
  {
    label: 'interview-log:contactType.SPECIAL_ORDER',
    value: InterviewLogContactType.SpecialOrder,
  },
  {
    label: 'interview-log:contactType.REMARK_V2',
    value: InterviewLogContactType.RemarkV2,
  },
  {
    label: 'interview-log:contactType.USER_STATUS',
    value: InterviewLogContactType.UserStatus,
  },
  {
    label: 'interview-log:contactType.REGISTER_SELLER',
    value: InterviewLogContactType.RegisterSeller,
  },
  {
    label: 'interview-log:contactType.DUPLICATE_NATIONAL_ID',
    value: InterviewLogContactType.NationalIdDuplicated,
  },
  {
    label: 'interview-log:contactType.ESTIMATE_PRICE',
    value: InterviewLogContactType.EstimatePrice,
  },
  {
    label: 'interview-log:contactType.UNDERSTAND_T2H_ARE_SELLER',
    value: InterviewLogContactType.UnderstandT2hAreSeller,
  },
  {
    label: 'interview-log:contactType.FINANCE_CHECK',
    value: InterviewLogContactType.FinanceCheck,
  },
  {
    label: 'interview-log:contactType.CONSIGNMENT',
    value: InterviewLogContactType.Consignment,
  },
  {
    label: 'interview-log:contactType.WANT_TO_BUY',
    value: InterviewLogContactType.WantToBuy,
  },
  {
    label: 'interview-log:contactType.WANT_TO_SELL',
    value: InterviewLogContactType.WantToSell,
  },
  {
    label: 'interview-log:contactType.WANT_TO_PAWN',
    value: InterviewLogContactType.WantToPawn,
  },
  {
    label: 'interview-log:contactType.OTHER',
    value: InterviewLogContactType.Other,
  },
];
const searchStatus = [
  {
    label: 'interview-log:status.NOTIFY_DEVELOPER',
    value: InterviewLogStatus.NotifyDeveloper,
  },
  {
    label: 'interview-log:status.NOTIFY_SALE',
    value: InterviewLogStatus.NotifySale,
  },
  {
    label: 'interview-log:status.DONE',
    value: InterviewLogStatus.Done,
  },
];

const InterviewLogListSearchForm: FC<InterviewLogListSearchFormProps> = (props) => {
  const { onSearchFormSubmit, currentFilters, isLoadingData = false, type } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const containerClasses = useAppContainerStyles();

  const { handleSubmit, control, setValue } = useForm<InterviewLogListSearchFormDataTC>({
    mode: 'onSubmit',
    defaultValues: {
      searchField:
        currentFilters?.searchField || type === 'user-t2h'
          ? searchFieldList[0].value
          : noneUserSearchFieldList[0].value,
      searchKeyword: currentFilters?.searchKeyword || '',
      filteringStatus: currentFilters?.filteringStatus || '',
      filteringContactBy: currentFilters?.filteringContactBy || '',
      filteringContactType: currentFilters?.filteringContactType || '',
      filteringStartCreatedDate: currentFilters?.filteringStartCreatedDate || 0,
      filteringEndCreatedDate: currentFilters?.filteringEndCreatedDate || 0,
      filteringStartUpdatedDate: currentFilters?.filteringStartUpdatedDate || 0,
      filteringEndUpdatedDate: currentFilters?.filteringEndUpdatedDate || 0,
    },
  });

  const clearSearchForm = () => {
    if (!isLoadingData) {
      setValue('searchField', type === 'user-t2h' ? searchFieldList[0].value : noneUserSearchFieldList[0].value);
      setValue('searchKeyword', '');
      setValue('filteringStatus', '');
      setValue('filteringContactBy', '');
      setValue('filteringContactType', '');
      setValue('filteringStartCreatedDate', 0);
      setValue('filteringEndCreatedDate', 0);
      setValue('filteringStartUpdatedDate', 0);
      setValue('filteringEndUpdatedDate', 0);
      handleSubmit(onSearchFormSubmit)();
    }
  };

  useEffect(() => {
    setValue('searchField', type === 'user-t2h' ? searchFieldList[0].value : noneUserSearchFieldList[0].value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  return (
    <Box className={classes.filterContainer}>
      <Container className={containerClasses.container}>
        <form onSubmit={handleSubmit(onSearchFormSubmit)}>
          <Grid container direction="row" spacing={2} style={{ alignItems: 'end' }}>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <AppFormControlGroup flexGrow={1} minWidth={0} pr={1}>
                <AppFormControl margin="dense">
                  {type === 'user-t2h' ? (
                    <Controller
                      name="searchField"
                      control={control}
                      defaultValue={searchFieldList[0].value}
                      render={({ field }) => (
                        <Select
                          labelId="interview-log-list-search-field-label"
                          id="interview-log-list-search-field"
                          value={field.value}
                          variant="outlined"
                          onChange={field.onChange}
                          disabled={isLoadingData}
                          fullWidth>
                          {searchFieldList.map((item) => (
                            <MenuItem value={item.value} key={item.value}>
                              <Typography variant="subtitle2">{t(item.label)}</Typography>
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  ) : (
                    <Controller
                      name="searchField"
                      control={control}
                      defaultValue={noneUserSearchFieldList[0].value}
                      render={({ field }) => (
                        <Select
                          labelId="interview-log-list-none-user-search-field-label"
                          id="interview-log-list-none-user-search-field"
                          value={field.value}
                          variant="outlined"
                          onChange={field.onChange}
                          disabled={isLoadingData}
                          fullWidth>
                          {noneUserSearchFieldList.map((item) => (
                            <MenuItem value={item.value} key={item.value}>
                              <Typography variant="subtitle2">{t(item.label)}</Typography>
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  )}
                </AppFormControl>

                <AppFormControl margin="dense" boxProps={{ flexGrow: 1 }}>
                  <Controller
                    name="searchKeyword"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <OutlinedInput
                        type="search"
                        endAdornment={<SearchIcon />}
                        id="interview-log-list-search-keyword"
                        value={field.value}
                        onChange={field.onChange}
                        fullWidth
                        disabled={isLoadingData}
                      />
                    )}
                  />
                </AppFormControl>
              </AppFormControlGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={2}>
              <Controller
                name="filteringContactBy"
                control={control}
                render={({ field }) => (
                  <AppFormControl margin="dense">
                    <InputLabel id="contact-by-label" style={{ fontSize: '14px' }}>
                      {t('interview-log:list.search.contactBy')}
                    </InputLabel>
                    <Select
                      labelId="contact-by-label"
                      id="contact-by"
                      value={field.value}
                      variant="outlined"
                      onChange={field.onChange}
                      fullWidth
                      disabled={isLoadingData}>
                      <MenuItem value="">
                        <Typography variant="subtitle2" component="em">
                          {t('common:message.unspecified')}
                        </Typography>
                      </MenuItem>
                      {contactByList.map((item) => (
                        <MenuItem value={item.value} key={item.value}>
                          {t(item.label)}
                        </MenuItem>
                      ))}
                    </Select>
                  </AppFormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <Controller
                name="filteringContactType"
                control={control}
                render={({ field }) => (
                  <AppFormControl margin="dense">
                    <InputLabel id="contact-type-label" style={{ fontSize: '14px' }}>
                      {t('interview-log:list.search.contactType')}
                    </InputLabel>
                    <Select
                      labelId="contact-type-label"
                      id="contact-type"
                      value={field.value}
                      variant="outlined"
                      onChange={field.onChange}
                      fullWidth
                      disabled={isLoadingData}>
                      <MenuItem value="">
                        <Typography component="em">{t('common:message.unspecified')}</Typography>
                      </MenuItem>
                      {contactTypeList.map((item) => (
                        <MenuItem value={item.value} key={item.value}>
                          {t(item.label)}
                        </MenuItem>
                      ))}
                    </Select>
                  </AppFormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <Controller
                name="filteringStatus"
                control={control}
                render={({ field }) => (
                  <AppFormControl margin="dense">
                    <InputLabel id="contact-status-label" style={{ fontSize: '14px' }}>
                      {t('interview-log:list.search.status')}
                    </InputLabel>
                    <Select
                      labelId="contact-status-label"
                      id="contact-status"
                      value={field.value}
                      variant="outlined"
                      onChange={field.onChange}
                      fullWidth
                      disabled={isLoadingData}>
                      <MenuItem value="">
                        <Typography component="em">{t('common:message.unspecified')}</Typography>
                      </MenuItem>
                      {searchStatus.map((item) => (
                        <MenuItem value={item.value} key={item.value}>
                          {t(item.label)}
                        </MenuItem>
                      ))}
                    </Select>
                  </AppFormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} lg={5}>
              <Grid container direction="row" spacing={1} alignItems="center">
                <Grid item xs={12} lg={6}>
                  <Typography variant="body2">{t('interview-log:fields.startCreatedDate')}</Typography>
                  <Box mt={1}>
                    <Controller
                      name="filteringStartCreatedDate"
                      control={control}
                      render={({ field }) => (
                        <AppFormControl>
                          <OutlinedInput
                            id="interview-log-start-created-date"
                            value={field.value}
                            fullWidth
                            margin="dense"
                            type="datetime-local"
                            onChange={field.onChange}
                          />
                        </AppFormControl>
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Typography variant="body2">{t('interview-log:fields.endCreatedDate')}</Typography>
                  <Box mt={1}>
                    <Controller
                      name="filteringEndCreatedDate"
                      control={control}
                      render={({ field }) => (
                        <AppFormControl>
                          <OutlinedInput
                            id="interview-log-end-created-date"
                            value={field.value}
                            fullWidth
                            margin="dense"
                            type="datetime-local"
                            onChange={field.onChange}
                          />
                        </AppFormControl>
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={5}>
              <Grid container direction="row" spacing={1} alignItems="center">
                <Grid item xs={12} lg={6}>
                  <Typography variant="body2">{t('interview-log:fields.startUpdatedDate')}</Typography>
                  <Box mt={1}>
                    <Controller
                      name="filteringStartUpdatedDate"
                      control={control}
                      render={({ field }) => (
                        <AppFormControl>
                          <OutlinedInput
                            id="interview-log-start-updated-date"
                            value={field.value}
                            fullWidth
                            margin="dense"
                            type="datetime-local"
                            onChange={field.onChange}
                          />
                        </AppFormControl>
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Typography variant="body2">{t('interview-log:fields.endCreatedDate')}</Typography>
                  <Box mt={1}>
                    <Controller
                      name="filteringEndUpdatedDate"
                      control={control}
                      render={({ field }) => (
                        <AppFormControl>
                          <OutlinedInput
                            id="interview-log-end-updated-date"
                            value={field.value}
                            fullWidth
                            margin="dense"
                            type="datetime-local"
                            onChange={field.onChange}
                          />
                        </AppFormControl>
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={2}>
              <Box display="flex">
                <AppButton
                  size="large"
                  type="submit"
                  color="primary"
                  style={{ alignSelf: 'flex-start' }}
                  disabled={isLoadingData}>
                  {t('common:message.search')}
                </AppButton>
                <AppButton
                  size="large"
                  variant="outlined"
                  color="primary"
                  onClick={clearSearchForm}
                  disabled={isLoadingData}
                  style={{ marginLeft: '10px' }}>
                  <YoutubeSearchedForIcon />
                </AppButton>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Box>
  );
};

export default InterviewLogListSearchForm;
