import React, { FC, useContext, useState } from 'react';

import { Avatar, Box, Typography } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import NotificationsIcon from '@material-ui/icons/Notifications';
import RefreshIcon from '@material-ui/icons/Refresh';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { AppGlobalUiContext } from '../../../context/AppGlobalUiContext';
import { defaultRowsPerPage } from '../../../helpers/constants';
import { getT2HSiteUrl } from '../../../helpers/utils';
import useInterval from '../../../hooks/useInterval';
import i18n from '../../../i18n';
import { AppTableConditions } from '../../../models';
import { NotificationAdminData } from '../../../models/notification.model';
import { getAdminNotifications, markAdminNotificationAs, markAllNotification } from '../../../services/notification';

type NotificationItemProps = {
  key: string;
  record: NotificationAdminData;
};

const useStyles = makeStyles((themeStyle) => ({
  wrapperCard: {
    gridGap: themeStyle.spacing(1),
    [themeStyle.breakpoints.up('sm')]: {
      gridTemplateColumns: '22% 1fr 210px',
      gridGap: themeStyle.spacing(2),
    },
    [themeStyle.breakpoints.up('lg')]: {
      gridGap: themeStyle.spacing(3),
    },
  },
  menu: {
    height: 600,
    '& li': {
      padding: '10px 10px 10px 20px',
      whiteSpace: 'break-spaces',
      borderBottom: '1px solid #eee',
      '& .MuiListItemIcon-root': {
        minWidth: '38px',
      },
    },
    '& li:last-child': {
      borderBottom: 'none',
    },
  },
  unreadIcon: {
    position: 'absolute',
    left: 6,
    top: 'calc(100% / 2 - 3px)',
    width: 8,
    height: 8,
    backgroundColor: '#2196F3',
    borderRadius: 4,
  },
  content: {
    fontSize: '.9em',
    color: 'rgba(0, 0, 0, 0.54)',
    width: '100%',
    cursor: 'pointer',
    overflow: 'hidden',
    wordBreak: 'break-word',
  },
  title: {
    fontWeight: 700,
  },
  subtitle: {
    fontWeight: 500,
  },
  date: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '.8em',
    textAlign: 'right',
  },
  media: {
    height: 0,
    paddingTop: '75%',
  },
  image: {
    width: 200,
    height: 200,
    borderRadius: 4,
  },
  externalLinkContainer: {
    display: 'inline-flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  externalLink: {
    color: '#2196F3',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: '0.00938em',
    textDecoration: 'none',
    marginLeft: '10px',
  },
  notificationNav: {
    zIndex: 1,
    '& a': {
      fontSize: '.9em',
    },
  },
  small: {
    width: themeStyle.spacing(4),
    height: themeStyle.spacing(4),
  },
  buttonNowrap: {
    whiteSpace: 'nowrap',
  },
  unread: {
    backgroundColor: '#e2edf3',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    padding: '10px 14px',
  },
  read: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    padding: '10px 14px',
  },
}));

const Notification: FC = () => {
  const [data, setData] = useState<NotificationAdminData[]>([]);
  const [unread, setUnread] = useState<NotificationAdminData[]>([]);
  const { t } = useTranslation();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const { showSnackbar } = useContext(AppGlobalUiContext);
  const dateDisplayFormat = i18n.language === 'th' ? 'D MMM BB / HH:mm:ss' : 'D MMM YY / HH:mm:ss';

  const getConditionsParam = (): Partial<AppTableConditions> => ({
    page: 0,
    rowsPerPage: defaultRowsPerPage,
    sortDirection: 'desc',
    notificationType: 'T2H_CR',
  });

  const requestNotifications = () => {
    (async () => {
      const conditions = getConditionsParam();
      const result = await getAdminNotifications(conditions);

      if (result.data) {
        const notificationData = result.data.contents;
        setData(notificationData);

        const unreadData = notificationData.filter((record) => !record.readAt);
        setUnread(unreadData);
      }
    })();
  };

  const markNotificationAsRead = async (notiHashId: string, readAt: string) => {
    if (!notiHashId || readAt !== null) {
      return;
    }
    await markAdminNotificationAs(notiHashId);
    requestNotifications();
  };

  const updateMarkAllNotificationAs = async () => {
    if (unread.length <= 0) {
      return;
    }
    const result = await markAllNotification();
    if (result) {
      requestNotifications();
      setAnchorEl(null);
      showSnackbar(t('common:notification.message.markAsReadAllSuccess').toString(), 'success');
    } else {
      showSnackbar(t('common:notification.message.markAsReadAllFailed').toString(), 'error');
    }
  };

  const handleNotificationClose = () => {
    setAnchorEl(null);
  };

  const handleMarkAllRead = () => {
    updateMarkAllNotificationAs();
  };

  const handleNotificationOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (data.length <= 0) {
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const handleRefreshButton = () => {
    requestNotifications();
  };

  const NotificationItem = (props: NotificationItemProps) => {
    const {
      record: { hashId, imageUrl, messageTh, readAt, createdAt },
    } = props;

    const getUnreadIcon = () => {
      if (readAt) {
        return null;
      }
      return <span className={classes.unreadIcon} />;
    };

    return (
      <Box className={readAt ? classes.read : classes.unread} onClick={handleNotificationClose}>
        {getUnreadIcon()}
        <Box display="flex">
          <ListItemIcon>
            <Avatar
              alt={imageUrl}
              src={getT2HSiteUrl(`${imageUrl}`)}
              imgProps={{ referrerPolicy: 'no-referrer' }}
              className={classes.small}
            />
          </ListItemIcon>
          <Typography className={classes.content} onClick={() => markNotificationAsRead(hashId, readAt)}>
            {messageTh}
          </Typography>
        </Box>
        <Typography className={classes.date}>{dayjs(createdAt).format(`${dateDisplayFormat}`)}</Typography>
      </Box>
    );
  };

  const notificationListId = 'notification-list';
  // eslint-disable-next-line react/display-name
  const notificationList = (
    <Menu
      anchorEl={anchorEl}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      id={notificationListId}
      keepMounted
      open={isMenuOpen}
      onClose={handleNotificationClose}
      className={classes.menu}
      PaperProps={{
        style: {
          marginTop: 20,
          width: 380,
        },
      }}>
      {unread.length > 0 && (
        <MenuItem className={classes.notificationNav} onClick={handleMarkAllRead}>
          <Typography>{t('common:notification.markAsRead')}</Typography>
        </MenuItem>
      )}

      {data.map((record) => (
        <NotificationItem key={record.hashId} record={record} />
      ))}
    </Menu>
  );

  useInterval(() => {
    requestNotifications();
  }, 300000);

  return (
    <>
      <Box mr={3}>
        <IconButton
          edge="end"
          aria-controls={notificationListId}
          aria-haspopup="true"
          onClick={handleNotificationOpen}
          color="inherit">
          <Badge badgeContent={unread.length} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        {notificationList}
        <IconButton
          edge="end"
          aria-controls={notificationListId}
          aria-haspopup="true"
          onClick={handleRefreshButton}
          color="inherit">
          <RefreshIcon />
        </IconButton>
      </Box>
    </>
  );
};

export default Notification;
