import React, { ChangeEvent, FC, useEffect, useState } from 'react';

import {
  Box,
  Card,
  CardContent,
  Grid,
  MenuItem,
  Paper,
  Select,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import useDeepCompareEffect from 'use-deep-compare-effect';

import DashboardBoostAmount from '../components/partials/dashboard/BoostAmount';
import DashboardCallClick from '../components/partials/dashboard/CallClick';
import DashboardCreateListingByCategory from '../components/partials/dashboard/CreateListingByCategory';
import DashboardPieChart from '../components/partials/dashboard/DashboardPieChart';
import DashboardSnsTag from '../components/partials/dashboard/DashboardSnsTag';
import DashboardTopWebStatistic from '../components/partials/dashboard/DashboardTopWebStatistic';
import Layout from '../components/templates/Layout';
import AppTableCell from '../components/ui/AppTableCell';
import AppTableList from '../components/ui/AppTableList';
import { AppTableConditions, TableColumn } from '../models';
import { getS3TdFile } from '../services/s3-td';
import useAppTableStyles from '../theme/table.style';
import useTypographyStyles from '../theme/typography.style';

const useStyles = makeStyles((theme) => ({
  colItem: {
    width: 'calc(100% / 5) !important',
  },
  card: {
    borderRadius: '16px',
    boxShadow: `0 0 15px 0px ${theme.palette.grey[300]}`,
    minHeight: '120px',
  },
  cardContent: {
    padding: theme.spacing(2, 1),
  },
}));

const searchDashboard = [
  {
    label: '7 วันย้อนหลัง',
    value: '7d',
  },
  {
    label: '30 วันย้อนหลัง',
    value: '30d',
  },
];

const dashboardRowsPerPage = 10;
const popularList = {
  count: '',
  keyword: '',
  lastexported: '',
  time: '',
  type: '',
};
const weeklyPopularSearchKeywordList = { ...popularList };
const weeklyPopularSearchSuggestionKeywordList = { ...popularList };

const popularKeywordTableColumns: TableColumn[] = [
  { name: 'keyword', label: 'dashboard:list.columns.keyword', width: 230 },
  { name: 'count', label: 'dashboard:list.columns.count', width: 140 },
];

const DashboardPage: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const typoClasses = useTypographyStyles();

  const [currentSelectData, setCurrentSelectData] = useState<string>('7d');
  const onSortColumnChanged = (
    e: ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => {
    setCurrentSelectData(e.target.value as string);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [searchList, setSearchList] = useState<any>(weeklyPopularSearchKeywordList);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [searchSuggestionList, setSearchSuggestionList] = useState<any>(weeklyPopularSearchSuggestionKeywordList);

  const [searchConditions, setSearchConditions] = useState<Partial<AppTableConditions>>({
    page: 0,
    rowsPerPage: dashboardRowsPerPage,
  });
  const [searchSuggestionConditions, setSearchSuggestionConditions] = useState<Partial<AppTableConditions>>({
    page: 0,
    rowsPerPage: dashboardRowsPerPage,
  });

  const [totalSearchCount, setTotalSearchCount] = useState<number>(0);
  const [totalSearchSuggestionCount, setTotalSearchSuggestionCount] = useState<number>(0);

  const tableClasses = useAppTableStyles();

  const period = currentSelectData.substring(0, currentSelectData.length - 1);

  const requestTopSearchList = async () => {
    try {
      const topSearch = await getS3TdFile(encodeURIComponent('top-search.csv'));

      if (topSearch.data) {
        const sliceStart = (searchConditions?.page || 0) * dashboardRowsPerPage;
        setTotalSearchCount(topSearch.data.data.length);
        topSearch.data.data = topSearch.data.data.slice(sliceStart, sliceStart + dashboardRowsPerPage);
        const resultTopSearchData =
          topSearch.data.data.length > 0
            ? topSearch.data.data.map((item) => ({
                ...item,
                tag: item.keyword
                  .replace(/[\n\t]/gi, '')
                  .replace(/""/gi, '"')
                  .replace(/""/gi, '"'),
              }))
            : [];

        setSearchList(resultTopSearchData);
      }
    } catch (error) {
      setTotalSearchCount(0);
      setSearchList([]);
    }
  };

  const requestTopSearchSuggestionList = async () => {
    try {
      const topSearchSuggestion = await getS3TdFile(encodeURIComponent('top-search-suggestion.csv'));

      if (topSearchSuggestion.data) {
        const sliceStart = (searchSuggestionConditions?.page || 0) * dashboardRowsPerPage;
        setTotalSearchSuggestionCount(topSearchSuggestion.data.data.length);
        topSearchSuggestion.data.data = topSearchSuggestion.data.data.slice(
          sliceStart,
          sliceStart + dashboardRowsPerPage,
        );

        const resultTopSearchSuggestionData =
          topSearchSuggestion.data.data.length > 0
            ? topSearchSuggestion.data.data.map((item) => ({
                ...item,
                tag: item.keyword
                  .replace(/[\n\t]/gi, '')
                  .replace(/""/gi, '"')
                  .replace(/""/gi, '"'),
              }))
            : [];
        setSearchSuggestionList(resultTopSearchSuggestionData);
      }
    } catch (error) {
      setSearchSuggestionList([]);
      setTotalSearchSuggestionCount(0);
    }
  };

  useEffect(() => {
    requestTopSearchList();
    requestTopSearchSuggestionList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearchConditionChangedHandler = (values: AppTableConditions) => {
    if (JSON.stringify(values) !== JSON.stringify(searchConditions)) {
      setSearchConditions(values);
    }
  };

  const onSearchSuggestionConditionChangedHandler = (values: AppTableConditions) => {
    if (JSON.stringify(values) !== JSON.stringify(searchSuggestionConditions)) {
      setSearchSuggestionConditions(values);
    }
  };

  useDeepCompareEffect(() => {
    requestTopSearchList();
  }, [searchConditions]);

  useDeepCompareEffect(() => {
    requestTopSearchSuggestionList();
  }, [searchSuggestionConditions]);

  return (
    <Layout pageTitle="common:title.home">
      <Box my={3} px={3}>
        <Box mb={2}>
          <Typography style={{ fontWeight: 'bold' }}>{t('dashboard:v3.search')}</Typography>
          <Select
            value={currentSelectData}
            onChange={onSortColumnChanged}
            variant="outlined"
            margin="dense"
            style={{ width: '200px', marginTop: '8px' }}>
            {searchDashboard.map((item) => (
              <MenuItem value={item.value} key={item.value}>
                {t(item.label)}
              </MenuItem>
            ))}
          </Select>
        </Box>

        <Box mb={1}>
          <DashboardTopWebStatistic selectPeriod={currentSelectData} />
        </Box>

        <Grid container direction="row" spacing={2}>
          <Grid item xs={12} lg={9} xl={10}>
            <Card variant="outlined" className={classes.card}>
              <CardContent className={classes.cardContent}>
                <DashboardCallClick selectPeriod={currentSelectData} period={period} />
              </CardContent>
            </Card>
            <Box my={2}>
              <Card variant="outlined" className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <DashboardCreateListingByCategory selectPeriod={currentSelectData} period={period} />
                </CardContent>
              </Card>
            </Box>
            <Card variant="outlined" className={classes.card}>
              <CardContent className={classes.cardContent}>
                <DashboardBoostAmount selectPeriod={currentSelectData} period={period} />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} lg={3} xl={2}>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} sm={12}>
                <DashboardTopWebStatistic selectPeriod={currentSelectData} isPageView />
              </Grid>
              <Grid item xs={12} sm={6} lg={12}>
                <Card variant="outlined" className={classes.card}>
                  <CardContent className={classes.cardContent}>
                    <Box px={2}>
                      <Typography style={{ fontWeight: 'bold' }}>{t('dashboard:v3.title.device')}</Typography>
                    </Box>
                    <DashboardPieChart selectPeriod={currentSelectData} isDevice />
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} sm={6} lg={12}>
                <Card variant="outlined" className={classes.card}>
                  <CardContent className={classes.cardContent}>
                    <Box px={2}>
                      <Typography style={{ fontWeight: 'bold' }}>
                        {t('dashboard:v3.title.proportionNewUserAndReturnUser')}
                      </Typography>
                    </Box>

                    <DashboardPieChart selectPeriod={currentSelectData} />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs="auto" md={6}>
            <Paper>
              <Box mx={3} pt={2} mt={3}>
                <Typography variant="h6" className={typoClasses.textWeightBold}>
                  {t('dashboard:title.popularSearchKeyword')}
                </Typography>
                {searchList.length > 0 && (
                  <Typography variant="subtitle2">
                    {t('dashboard:label.lastExport')}
                    {dayjs.unix(searchList[0].time).format('DD MMM YY HH:mm')}
                  </Typography>
                )}
              </Box>

              <AppTableList
                {...searchConditions}
                onTableConditionChanged={onSearchConditionChangedHandler}
                aria-label="popular-search-keyword-list"
                totalDataCount={totalSearchCount}
                rowsPerPageOptions={[dashboardRowsPerPage]}
                columns={popularKeywordTableColumns}
                tableBodyContent={
                  searchList.length > 0 && (
                    <>
                      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                      {searchList.map((item: any) => (
                        <TableRow
                          key={item.keyword}
                          classes={{
                            root: clsx(tableClasses.tableRow, tableClasses.clickableTableRow),
                          }}>
                          <AppTableCell>{item.keyword}</AppTableCell>
                          <AppTableCell>{item.count}</AppTableCell>
                        </TableRow>
                      ))}
                    </>
                  )
                }
              />
            </Paper>
          </Grid>

          <Grid item xs="auto" md={6}>
            <Paper>
              <Box mx={3} pt={2} mt={3}>
                <Typography variant="h6" className={typoClasses.textWeightBold}>
                  {t('dashboard:title.popularSearchSuggestionKeyword')}
                </Typography>
                {searchSuggestionList.length > 0 && (
                  <Typography variant="subtitle2">
                    {t('dashboard:label.lastExport')}

                    {dayjs.unix(searchSuggestionList[0].time).format('DD MMM YY HH:mm')}
                  </Typography>
                )}
              </Box>

              <AppTableList
                {...searchSuggestionConditions}
                onTableConditionChanged={onSearchSuggestionConditionChangedHandler}
                aria-label="popular-search-suggestion-keyword-list"
                totalDataCount={totalSearchSuggestionCount}
                rowsPerPageOptions={[dashboardRowsPerPage]}
                columns={popularKeywordTableColumns}
                tableBodyContent={
                  searchSuggestionList.length > 0 && (
                    <>
                      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                      {searchSuggestionList.map((item: any) => (
                        <TableRow
                          key={item.keyword}
                          classes={{
                            root: clsx(tableClasses.tableRow, tableClasses.clickableTableRow),
                          }}>
                          <AppTableCell>{item.keyword}</AppTableCell>
                          <AppTableCell>{item.count}</AppTableCell>
                        </TableRow>
                      ))}
                    </>
                  )
                }
              />
            </Paper>
          </Grid>
        </Grid>

        <DashboardSnsTag />
      </Box>
    </Layout>
  );
};

export default DashboardPage;
