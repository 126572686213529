import { useEffect, useState } from 'react';

import { browserLocalPersistence, Unsubscribe } from 'firebase/auth';

import { firebaseAuth } from '../helpers/firebase';
import { AuthContextProps } from '../models';
import { AuthUser } from '../models/auth-user.model';
import { appSignOut, setAuthToken } from '../services/auth';
import { getMe } from '../services/user';

const useAuthContext = (): AuthContextProps => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [authUser, setAuthUser] = useState<AuthUser | null>(null);
  const [isAuthInitialized, setIsAuthInitialized] = useState(false);
  useEffect(() => {
    let unsubscribe: Unsubscribe;

    firebaseAuth.setPersistence(browserLocalPersistence).then(async () => {
      const user = firebaseAuth.currentUser;

      if (user) {
        const idToken = await user.getIdToken();

        // Set idToken in firebase

        setAuthToken({
          authScheme: 'Bearer',
          firebaseIdToken: idToken,
        });

        const userInfo = await getMe();

        if (userInfo) {
          setAuthUser(new AuthUser(userInfo));
        } else {
          console.log('[DEBUG] log out due to user info is invalid');
          // If fails to get user, we force them to logout
          await appSignOut();
          window.location.href = '/login';
        }
      }
      setIsAuthInitialized(true);
    });

    const onFocusHandler = async () => {
      if (firebaseAuth.currentUser) {
        const idToken = await firebaseAuth.currentUser.getIdToken();
        // Set idToken in firebase
        setAuthToken({
          authScheme: 'Bearer',
          firebaseIdToken: idToken,
        });
      }
    };

    window.addEventListener('focus', onFocusHandler);

    return () => {
      if (typeof unsubscribe === 'function') {
        unsubscribe();
      }
      window.removeEventListener('focus', onFocusHandler);
    };
  }, []);

  useEffect(() => {
    setIsAuthenticated(!!authUser);
  }, [authUser]);

  return {
    isAuthenticated,
    setAuthUser,
    authUser,
    isAuthInitialized,
  };
};

export default useAuthContext;
