import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

export const appDefaultLocale = 'th';

i18n
  .use(initReactI18next)
  .use(Backend)
  .init({
    backend: { loadPath: '/locales/{{lng}}/{{ns}}.json' },
    ns: ['common', 'error', 'validation'],
    fallbackLng: appDefaultLocale,
    lng: appDefaultLocale,
    keySeparator: '.',
    // react already safes from xss
    interpolation: { escapeValue: false },
  });

export default i18n;
