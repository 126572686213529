import dayjs from 'dayjs';
import { DocumentData, DocumentSnapshot } from 'firebase/firestore';

export type MessageContent = {
  type: 'text' | 'sticker' | 'listing-info' | 'image' | 'video';
  content: string;
};

export class Message {
  id = '';

  content: MessageContent | null = null;

  createdAt: dayjs.Dayjs = dayjs();

  deletedAt: dayjs.Dayjs | null = null;

  readBy: string[] = [];

  readInfo: { id: string; readAt: dayjs.Dayjs }[] = [];

  sender = '';

  /* eslint-disable @typescript-eslint/no-explicit-any */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  constructor(data: any) {
    Object.assign(this, data);
  }
}

export type MessageQueryConditions = {
  groupId: string;
  lastVisibleDoc?: DocumentSnapshot<DocumentData>;
  messagePerRequest?: number;
};

export type MessageListingInfoData = {
  hashId?: string;
  title?: string;
  detail?: string;
  price?: string;
  coverImageUrl?: string;
};
