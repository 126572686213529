import React, { FC, ReactNode } from 'react';

import { Box, Card, CardProps, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import useTypographyStyles from '../../../../theme/typography.style';

export type IconCardProps = CardProps & {
  icon: ReactNode;
  title: string;
};

const useStyles = makeStyles((theme) => ({
  menuCard: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  menuIcon: {
    marginBottom: theme.spacing(1),
    '& svg': {
      fontSize: theme.typography.pxToRem(70),
      color: theme.palette.grey[500],
    },
  },
  cardText: {
    fontSize: theme.typography.pxToRem(18),
  },
}));

const IconCard: FC<IconCardProps> = (props) => {
  const { icon, title, ...cardProps } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const typoClasses = useTypographyStyles();

  return (
    <Card {...cardProps} className={classes.menuCard}>
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" p={3}>
        <Box className={classes.menuIcon}>{icon}</Box>
        <Typography component="label" align="center" className={clsx(typoClasses.textWeightBold, classes.cardText)}>
          {t(title)}
        </Typography>
      </Box>
    </Card>
  );
};

export default IconCard;
