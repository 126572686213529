import React, { FC, useCallback, useContext, useEffect, useState } from 'react';

import { Box, TableRow, Typography } from '@material-ui/core';
import MoreHorizOutlinedIcon from '@material-ui/icons/MoreHorizOutlined';
import PostAddOutlinedIcon from '@material-ui/icons/PostAddOutlined';
import clsx from 'clsx';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { AppGlobalUiContext } from '../../../../../../../context/AppGlobalUiContext';
import useQuery from '../../../../../../../hooks/useQuery';
import i18n from '../../../../../../../i18n';
import {
  AppTableConditions,
  InterviewLogData,
  InterviewLogDataRequest,
  TableColumn,
} from '../../../../../../../models';
import { addNewInterviewLog, updateInterviewLog } from '../../../../../../../services/v3/user';
import useAppTableStyles from '../../../../../../../theme/table.style';
import AppButton from '../../../../../../ui/AppButton';
import AppTableCell from '../../../../../../ui/AppTableCell';
import AppTableList from '../../../../../../ui/AppTableList';
import AddInterviewLogItemDialog from '../AddInterviewLogItemDialog';
import InterviewLogItemDialog from '../InterviewLogItemDialog';

dayjs.extend(customParseFormat);

export type InterviewLogListTableProps = {
  currentConditions: AppTableConditions;
  interviewLog: InterviewLogData[];
  totalData: number;
  isLoadingData: boolean;
  onTableConditionsChanged: (values: AppTableConditions) => void;
  onFetch: () => void;
};

const interviewLogTableColumns: TableColumn[] = [
  {
    name: 'hashId',
    label: 'interview-log:list.columns.hashId',
    width: 120,
    unsortable: true,
  },
  { name: 'contactBy', label: 'interview-log:list.columns.contactBy', width: 100, unsortable: true },
  { name: 'contactType', label: 'interview-log:list.columns.contactType', width: 200, unsortable: true },
  {
    name: 'comment',
    label: 'interview-log:list.columns.comment',
    width: 400,
    unsortable: true,
  },
  { name: 'fileImage', label: 'interview-log:list.columns.fileImage', width: 120, unsortable: true },
  { name: 'status', label: 'interview-log:list.columns.status', width: 160, unsortable: true },
  { name: 'createdAt', label: 'interview-log:list.columns.createdAt', width: 120, unsortable: true },
  { name: 'updatedAt', label: 'interview-log:list.columns.updatedAt', width: 120, unsortable: true },
  { name: 'crDisplayName', label: 'interview-log:list.columns.createdBy', width: 160, unsortable: true },
  { name: 'manege', label: 'interview-log:list.columns.manege', width: 120, unsortable: true },
];

const InterviewLogListTable: FC<InterviewLogListTableProps> = (props) => {
  const { t } = useTranslation();
  const query = useQuery();
  const history = useHistory();
  const { totalData, isLoadingData, currentConditions, interviewLog, onFetch, onTableConditionsChanged } = props;
  const tableClasses = useAppTableStyles();
  const [tableConditions, setTableConditions] = useState<AppTableConditions>(currentConditions);
  const { showSnackbar, setAppLoading } = useContext(AppGlobalUiContext);
  const dateDisplayFormat = i18n.language === 'th' ? 'D MMM BBBB' : 'D MMM YYYY';

  const [selectedLogItem, setSelectedLogItem] = useState<InterviewLogData | undefined>(undefined);
  const [isInterviewLogDialogOpen, setIsInterviewLogDialogOpen] = useState<boolean>(false);

  const [isAddInterviewLogDialogOpen, setIsAddInterviewLogDialogOpen] = useState<boolean>(false);

  const selectedId = query.get('hashId');

  const onTableConditionChangedHandler = useCallback(
    (values: AppTableConditions) => {
      if (JSON.stringify(values) !== JSON.stringify(tableConditions)) {
        setTableConditions(values);
        onTableConditionsChanged(values);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableConditions],
  );

  const openInterviewDetailPage = useCallback(async (item: InterviewLogData) => {
    setSelectedLogItem(item);
    setIsInterviewLogDialogOpen(true);
  }, []);

  const onEditFormSubmit = useCallback(
    async (data?: InterviewLogDataRequest) => {
      if (selectedLogItem && data) {
        setAppLoading(true);

        const result = await updateInterviewLog(selectedLogItem?.hashId, data);

        if (result.data) {
          setAppLoading(false);
          setSelectedLogItem(result.data);
          showSnackbar(t('interview-log:message.updateInterviewLogSuccess').toString(), 'success');
          onFetch();
          history.push(`/v3/users/${selectedLogItem.userHashId}?tab=interview`);
          setIsInterviewLogDialogOpen(false);
        } else {
          setAppLoading(false);
          showSnackbar(t('interview-log:message.saveFailed').toString(), 'error');
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedLogItem, setAppLoading, showSnackbar, t],
  );

  const handleAddInterviewLog = useCallback(async () => {
    setIsAddInterviewLogDialogOpen(true);
  }, []);

  const onSubmitAddNewLog = useCallback(
    async (formData?: InterviewLogDataRequest) => {
      if (formData) {
        setAppLoading(true);

        const result = await addNewInterviewLog(formData);

        if (result.data) {
          setSelectedLogItem(result.data);
          showSnackbar(t('interview-log:message.addNewInterviewLogSuccess').toString(), 'success');
          setIsAddInterviewLogDialogOpen(false);
          onFetch();
        } else {
          showSnackbar(t('interview-log:message.addNewInterviewLogFailed').toString(), 'error');
        }
        setAppLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setAppLoading, showSnackbar, t],
  );

  useEffect(() => {
    if (selectedId) {
      setIsInterviewLogDialogOpen(true);
      const selectedData = interviewLog.filter((item) => item.hashId === selectedId);
      setSelectedLogItem(selectedData[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId, interviewLog]);

  return (
    <>
      <Box>
        <AppButton
          variant="contained"
          color="primary"
          startIcon={<PostAddOutlinedIcon />}
          onClick={handleAddInterviewLog}>
          {t('interview-log:button.addInterviewLog')}
        </AppButton>
        <Box textAlign="right" mb={2}>
          {!isLoadingData && (
            <Typography
              variant="subtitle2"
              color="textSecondary"
              style={{ marginBottom: '8px', marginRight: '8px', textAlign: 'right' }}>
              {t('common:list.resultTotal', { fieldName: t(totalData.toLocaleString() || '0') })}
            </Typography>
          )}
        </Box>

        <AppTableList
          {...tableConditions}
          aria-label="product-list"
          columns={interviewLogTableColumns}
          totalDataCount={totalData}
          hasPagination
          onTableConditionChanged={onTableConditionChangedHandler}
          isLoading={isLoadingData}
          tableBodyContent={
            interviewLog.length > 0 && (
              <>
                {interviewLog.map((item: InterviewLogData) => (
                  <TableRow
                    hover
                    key={item.hashId}
                    classes={{ root: clsx(tableClasses.tableRow, tableClasses.clickableTableRow) }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      openInterviewDetailPage(item);
                    }}>
                    <AppTableCell>{item.hashId}</AppTableCell>
                    <AppTableCell>
                      <Typography variant="subtitle2">{item.contactBy}</Typography>
                    </AppTableCell>
                    <AppTableCell>
                      <Typography variant="subtitle2">
                        {item.contactType ? t(`interview-log:contactType.${item.contactType}`) : '-'}
                      </Typography>
                    </AppTableCell>
                    <AppTableCell style={{ whiteSpace: 'normal' }}>
                      <Typography variant="subtitle2">{item.comment}</Typography>
                    </AppTableCell>
                    <AppTableCell>
                      {item.otherFiles?.length && item.otherFiles?.length > 0 ? (
                        <Typography variant="subtitle2" color="textSecondary">
                          {t('interview-log:list.columns.totalImages', {
                            fieldName: t(item.otherFiles?.length.toLocaleString()),
                          })}
                        </Typography>
                      ) : (
                        '-'
                      )}
                    </AppTableCell>
                    <AppTableCell>
                      <Typography variant="subtitle2">
                        {item.status ? t(`interview-log:status.${item.status}`) : '-'}
                      </Typography>
                    </AppTableCell>
                    <AppTableCell>
                      {item.createdAt ? (
                        <Typography variant="subtitle2">
                          {dayjs(item.createdAt).format(`${dateDisplayFormat}`)}
                          <br />
                          {dayjs(item.createdAt).format(`HH:mm:ss`)}
                        </Typography>
                      ) : (
                        <Typography>-</Typography>
                      )}
                    </AppTableCell>
                    <AppTableCell>
                      {item.updatedAt ? (
                        <Typography variant="subtitle2">
                          {dayjs(item.updatedAt).format(`${dateDisplayFormat}`)}
                          <br />
                          {dayjs(item.updatedAt).format(`HH:mm:ss`)}
                        </Typography>
                      ) : (
                        <Typography>-</Typography>
                      )}
                    </AppTableCell>
                    <AppTableCell style={{ whiteSpace: 'normal' }}>{item.crDisplayName}</AppTableCell>
                    <AppTableCell>
                      <MoreHorizOutlinedIcon />
                    </AppTableCell>
                  </TableRow>
                ))}
              </>
            )
          }
        />
      </Box>

      {selectedLogItem && (
        <InterviewLogItemDialog
          key={selectedLogItem.hashId}
          title={t('interview-log:list.columns.comment')}
          open={isInterviewLogDialogOpen}
          onClose={() => {
            setIsInterviewLogDialogOpen(false);
          }}
          onCloseClick={() => {
            setIsInterviewLogDialogOpen(false);
            setSelectedLogItem(undefined);
            if (selectedLogItem) {
              history.push(`/v3/users/${selectedLogItem.userHashId}?tab=interview`);
            }
          }}
          interviewLogItem={selectedLogItem}
          onFormSubmit={onEditFormSubmit}
        />
      )}

      {isAddInterviewLogDialogOpen && (
        <AddInterviewLogItemDialog
          title={t('interview-log:dialogAdd.title')}
          open={isAddInterviewLogDialogOpen}
          onClose={() => {
            setIsAddInterviewLogDialogOpen(false);
          }}
          onCloseClick={() => {
            setIsAddInterviewLogDialogOpen(false);
          }}
          onFormSubmit={onSubmitAddNewLog}
        />
      )}
    </>
  );
};

export default InterviewLogListTable;
