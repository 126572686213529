import React, { FC, useCallback, useContext, useEffect, useState } from 'react';

import {
  Box,
  Container,
  Divider,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import StickyActionBar from '../../../components/partials/common/StickyActionBar';
import MasterDataCommonFields from '../../../components/partials/master-data/MasterDataCommonFields';
import AppBreadcrumb from '../../../components/ui/AppBreadcrumb';
import AppButton from '../../../components/ui/AppButton';
import AppFormControl from '../../../components/ui/AppFormControl';
import AppFormErrorMessage from '../../../components/ui/AppFormErrorMessage';
import { AppGlobalUiContext } from '../../../context/AppGlobalUiContext';
import { defaultRowsPerPage } from '../../../helpers/constants';
import { applyKTErrorToForm } from '../../../helpers/utils';
import { ApiResponse, BreadcrumbItem, Category, CategoryListSearchParams, Model, ModelFormData } from '../../../models';
import * as categoryService from '../../../services/category';
import * as modelService from '../../../services/model';
import useAppContainerStyles from '../../../theme/container.style';
import useControlStyles from '../../../theme/controls.style';
import useTypographyStyles from '../../../theme/typography.style';

const ModelEdit: FC = () => {
  const { t } = useTranslation();
  const { modelUid, uid: brandUid } = useParams<{ modelUid: string; uid: string }>();
  const history = useHistory();
  const typoClasses = useTypographyStyles();
  const { showSnackbar, setAppLoading, setAppBarTitle } = useContext(AppGlobalUiContext);
  const [currentData, setCurrentData] = useState<Model | null>(null);
  const [categoryData, setCategoryData] = useState<Category[]>([]);
  const containerClass = useAppContainerStyles();
  const controlsClasses = useControlStyles();
  const defaultFormValues: ModelFormData = {
    uid: 0,
    name: '',
    thaiName: '',
    slug: '',
    imageUrl: '',
    placeholder: '',
    description: '',
    canonicalUrlMeta: '',
    searchSynonym: '',
    titleMeta: '',
    imageUrlMeta: '',
    descriptionMeta: '',
    modelName: '',
    brandUid: brandUid ? Number(brandUid) : undefined,
    rootCategoryUid: currentData?.rootCategoryUid || currentData?.rootCategoryUid || 1,
  };
  const {
    control,
    handleSubmit,
    clearErrors,
    reset,
    setValue,
    getValues,
    setError,
    formState: { errors },
  } = useForm<ModelFormData>({
    mode: 'onChange',
    defaultValues: defaultFormValues,
  });

  const breadcrumbItems: BreadcrumbItem[] = [
    { label: 'common:sidebar.menu.masterData', url: '/master-data' },
    { label: 'master-data:menu.brand', url: '/master-data/brands' },
    {
      label: 'master-data:menu.brandUid',
      url: `/master-data/brands/${brandUid}`,
      labelParam: {
        brandUid,
      },
    },
    {
      label: 'master-data:menu.model',
      url: `/master-data/brands/${brandUid}/models`,
      labelParam: {
        brandUid,
      },
    },
    {
      label: `master-data:menu.${modelUid === 'create' ? 'newModel' : 'modelUid'}`,
      labelParam: {
        modelUid,
      },
    },
  ];

  const setFormValues = (data: Model) => {
    reset();
    clearErrors();

    if (data) {
      const {
        uid,
        name,
        thaiName,
        slug,
        modelName,
        canonicalUrlMeta,
        searchSynonym,
        imageUrl,
        placeholder,
        description,
        titleMeta,
        imageUrlMeta,
        descriptionMeta,
        rootCategoryUid,
      } = data;
      setValue('uid', uid || 0, { shouldValidate: true });
      setValue('name', name || '', { shouldValidate: true });
      setValue('thaiName', thaiName || '', { shouldValidate: true });
      setValue('slug', slug || '', { shouldValidate: true });
      setValue('modelName', modelName || '', { shouldValidate: true });
      setValue('imageUrl', imageUrl || '', { shouldValidate: true });
      setValue('placeholder', placeholder || '', { shouldValidate: true });
      setValue('description', description || '', { shouldValidate: true });
      setValue('titleMeta', titleMeta || '', { shouldValidate: true });
      setValue('imageUrlMeta', imageUrlMeta || '', { shouldValidate: true });
      setValue('descriptionMeta', descriptionMeta || '', { shouldValidate: true });
      setValue('canonicalUrlMeta', canonicalUrlMeta || '', { shouldValidate: true });
      setValue('searchSynonym', searchSynonym || '', { shouldValidate: true });
      setValue('rootCategoryUid', rootCategoryUid || undefined, { shouldValidate: true });
    }
  };

  useEffect(() => {
    let isActive = true;
    setAppBarTitle(`master-data:title.model.${modelUid === 'create' ? 'create' : 'edit'}`);

    (async () => {
      if (modelUid && modelUid !== 'create' && isActive) {
        const result = await modelService.getModelByUid(modelUid);
        if (result.data && !result.error) {
          setFormValues(result.data);
          setCurrentData(result.data);
        }
        if (result.error) {
          showSnackbar(t(result.error), 'error');
        }
      }
    })();

    return () => {
      isActive = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSaveDataHandler = (data: ModelFormData) => {
    (async () => {
      setAppLoading(true);

      let saveResult: ApiResponse<Model>;

      if (modelUid !== 'create' && currentData) {
        saveResult = await modelService.updateModel(currentData.id, data);
      } else {
        saveResult = await modelService.createModel(data);
      }

      if (saveResult && saveResult.data && saveResult.data.id) {
        await modelService.syncElasticsearchModelData(saveResult.data.id);
      }

      if (saveResult) {
        if (saveResult.data) {
          clearErrors();
          showSnackbar(
            t(`master-data:message.model.${modelUid === 'create' ? 'create' : 'update'}Success`).toString(),
            'success',
          );

          // If uid has changed, refresh page to get fresh data
          // Don't have to clear app loading since it's refreshing
          if (modelUid === 'create' || (saveResult.data?.uid && saveResult.data.uid !== Number(modelUid))) {
            setTimeout(() => {
              window.location.href = `/master-data/brands/${brandUid}/models/${saveResult.data?.uid}`;
            }, 2000);
          } else {
            setAppLoading(false);
            setCurrentData(saveResult.data);
          }
        } else {
          setAppLoading(false);
          showSnackbar(t(saveResult.error).toString(), 'error');

          if (saveResult.validationErrors) {
            applyKTErrorToForm<ModelFormData>(t, setError, getValues, saveResult.validationErrors);
          }
        }
      }
    })();
  };

  const onSaveClick = () => {
    handleSubmit(onSaveDataHandler)();
  };

  const onBackClick = () => {
    history.push(`/master-data/brands/${brandUid}/models`);
  };

  const getCategorySearchParam = (): CategoryListSearchParams => ({
    page: 0,
    rowsPerPage: 100 || defaultRowsPerPage,
    sortColumn: 'name',
    sortDirection: 'desc',
    searchField: '',
    searchKeyword: '',
    // TODO : 1 is ID of Root Category
    masterIndexUid: '1',
  });

  const categoryList = useCallback(async () => {
    const searchConditions = getCategorySearchParam();
    const result = await categoryService.getCategoryList(searchConditions);
    if (result.data?.list) {
      setCategoryData(result.data.list);
    }
  }, []);

  useEffect(() => {
    categoryList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box px={6} py={3}>
        <AppBreadcrumb items={breadcrumbItems} />
      </Box>
      <Container className={containerClass.container} style={{ marginBottom: 60 }}>
        <Box py={3}>
          <Box py={2} display="flex" justifyContent="flex-end" className={controlsClasses.buttonsGroup}>
            <StickyActionBar>
              <AppButton color="default" variant="outlined" onClick={() => onBackClick()}>
                {t('common:button.cancel')}
              </AppButton>
              <AppButton color="primary" onClick={() => onSaveClick()}>
                {t('common:button.save')}
              </AppButton>
            </StickyActionBar>
          </Box>
          <Paper>
            <Box p={3}>
              <MasterDataCommonFields control={control} errors={errors} hasSearchSynonym hasCanonicalUrlMeta />
              <Box mt={1} mb={2}>
                <Divider />
              </Box>

              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={4}>
                  <Box mb={3}>
                    <Typography className={typoClasses.textWeightBold} variant="h6">
                      {t('master-data:form.model.modelInfoTitle')}
                    </Typography>
                  </Box>
                  <Controller
                    name="modelName"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <AppFormControl error={!!errors.modelName}>
                        <TextField
                          {...field}
                          variant="outlined"
                          label={t('master-data:list.model.columns.modelName')}
                          error={!!errors.modelName}
                        />
                        {!!errors.modelName && (
                          <AppFormErrorMessage
                            errors={errors}
                            name="modelName"
                            translationFieldName="master-data:list.model.columns.modelName"
                          />
                        )}
                      </AppFormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Box mb={3}>
                    <Typography className={typoClasses.textWeightBold} variant="h6">
                      {t('common:label.rootCategoryType')}
                    </Typography>
                  </Box>
                  <Controller
                    name="rootCategoryUid"
                    control={control}
                    defaultValue={currentData?.rootCategoryUid}
                    render={({ field }) => (
                      <AppFormControl>
                        <InputLabel id="rootCategory-label">{t('common:label.rootCategoryType')}</InputLabel>
                        <Select
                          {...field}
                          labelId="rootCategory-label"
                          id="rootCategoryUid"
                          value={field.value}
                          variant="outlined">
                          {categoryData.map((item) => (
                            <MenuItem value={item.uid} key={item.id}>
                              {t(item.thaiName)}
                            </MenuItem>
                          ))}
                        </Select>
                      </AppFormControl>
                    )}
                  />
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
      </Container>
    </>
  );
};

export default ModelEdit;
