import React, { FC } from 'react';

import { Box, Container, Grid, makeStyles, MenuItem, OutlinedInput, Select, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import YoutubeSearchedForIcon from '@material-ui/icons/YoutubeSearchedFor';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SelectItem } from '../../../../../models';
import { AbTestingListSearchFormData, FeatureType } from '../../../../../models/ab-testing.model';
import useAppContainerStyles from '../../../../../theme/container.style';
import AppButton from '../../../../ui/AppButton';
import AppChipList from '../../../../ui/AppChipList';
import AppFormControl from '../../../../ui/AppFormControl';
import AppFormControlGroup from '../../../../ui/AppFormControlGroup';

export type AbTestingSearchFormProps = {
  isLoadingData?: boolean;
  currentFilters: AbTestingListSearchFormData | undefined;
  onSearchFormSubmit: (values: AbTestingListSearchFormData) => void;
  isFilterByFeatureName?: boolean;
};

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
  },
  searchBoxContainer: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 600,
    },
  },
  filterHeader: {
    fontWeight: 700,
  },
}));

const searchFieldList: SelectItem<string>[] = [
  {
    label: 'ab-testing:list.search.userHashId',
    value: 'userHashIds',
  },
];

const searchType = [
  {
    label: 'PDPA',
    value: FeatureType.pdpa,
  },
  {
    label: 'CSAT Survey',
    value: FeatureType.csat,
  },
  {
    label: 'OnBoard Tooltip Price Offer',
    value: FeatureType.onBoardTooltipPriceOffer,
  },
  {
    label: 'OnBoard Tooltip Seller Profile',
    value: FeatureType.onBoardTooltipSellerProfile,
  },
];

const AbTestingListSearchForm: FC<AbTestingSearchFormProps> = (props) => {
  const classes = useStyles();
  const containerClasses = useAppContainerStyles();
  const { t } = useTranslation();
  const { currentFilters, onSearchFormSubmit, isLoadingData = false, isFilterByFeatureName = false } = props;
  const { handleSubmit, control, getValues, setValue } = useForm<AbTestingListSearchFormData>({
    mode: 'onSubmit',
    defaultValues: {
      searchField: currentFilters?.searchField || searchFieldList[0].value,
      searchKeyword: currentFilters?.searchKeyword || '',
      filteringType: currentFilters?.filteringType || [],
    },
  });

  const submitSearchForm = () => {
    if (!isLoadingData) {
      setValue('searchKeyword', getValues('searchKeyword').trim());
      handleSubmit(onSearchFormSubmit)();
    }
  };

  const clearSearchForm = () => {
    if (!isLoadingData) {
      setValue('searchField', searchFieldList[0].value);
      setValue('searchKeyword', '');
      setValue('filteringType', []);
      handleSubmit(onSearchFormSubmit)();
    }
  };

  return (
    <Box className={classes.filterContainer}>
      <Container className={containerClasses.container}>
        <form onSubmit={handleSubmit(onSearchFormSubmit)}>
          <Grid container direction="row" spacing={3}>
            <Grid item xs={12} md container spacing={3}>
              <Grid item xs={12} md="auto">
                <Box display="flex" flexDirection="row" flexWrap="nowrap" className={classes.searchBoxContainer}>
                  <AppFormControlGroup flexGrow={1} minWidth={0} pr={1}>
                    <AppFormControl margin="dense">
                      <Controller
                        name="searchField"
                        control={control}
                        defaultValue={searchFieldList[0].value}
                        render={({ field }) => (
                          <Select
                            labelId="user-list-search-field-label"
                            id="user-list-search-field"
                            value={field.value}
                            variant="outlined"
                            onChange={field.onChange}
                            disabled={isLoadingData}
                            fullWidth>
                            {searchFieldList.map((item) => (
                              <MenuItem value={item.value} key={item.value}>
                                {t(item.label)}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </AppFormControl>
                    <AppFormControl margin="dense" boxProps={{ flexGrow: 1 }}>
                      <Controller
                        name="searchKeyword"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <OutlinedInput
                            type="search"
                            endAdornment={<SearchIcon />}
                            id="user-list-search-keyword"
                            value={field.value}
                            onChange={field.onChange}
                            fullWidth
                            disabled={isLoadingData}
                          />
                        )}
                      />
                    </AppFormControl>
                  </AppFormControlGroup>
                  <AppButton type="submit" color="primary" style={{ alignSelf: 'flex-start' }} disabled={isLoadingData}>
                    {t('common:message.search')}
                  </AppButton>
                </Box>
              </Grid>

              {!!isFilterByFeatureName && (
                <Grid container direction="row" spacing={2} item xs={12}>
                  <Grid item sm={12} md={12}>
                    <Typography variant="body2" className={classes.filterHeader}>
                      {t('ab-testing:list.search.featureName')}
                    </Typography>
                    <Box display="flex" flexDirection="row" flexWrap="wrap" mt={1}>
                      <Controller
                        control={control}
                        name="filteringType"
                        defaultValue={currentFilters?.filteringType || []}
                        render={({ field }) => (
                          <>
                            <AppChipList
                              items={searchType}
                              selectedValues={field.value}
                              onChange={(values: string[]) => {
                                field.onChange(values);
                                submitSearchForm();
                              }}
                              isDisabled={isLoadingData}
                            />
                          </>
                        )}
                      />
                    </Box>
                  </Grid>
                </Grid>
              )}
            </Grid>

            <Grid item xs={12} md="auto">
              <AppButton variant="outlined" color="primary" onClick={clearSearchForm} disabled={isLoadingData}>
                <YoutubeSearchedForIcon />
              </AppButton>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Box>
  );
};

export default AbTestingListSearchForm;
