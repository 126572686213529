import React, { FC, useEffect, useState } from 'react';

import {
  Avatar,
  Box,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { Controller, ValidateResult, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CAMPAIGN_FREE_COIN_NEW_SELLER_END_DATE_TIMESTAMP, formatDate } from '../../../../../helpers/date';
import usePrivilege from '../../../../../hooks/usePrivilege';
import i18n from '../../../../../i18n';
import {
  SellerApplicationFormDataTC,
  SellerApplicationTC,
  SellerApplicationWorkflow,
  UserGeneralWorkflow,
  ShopType,
  UserDataTC,
  CommentData,
  BankMaster,
} from '../../../../../models';
import { checkBookBankNumberAvailable, checkNationalIdAvailable } from '../../../../../services/user';
import useControlStyles from '../../../../../theme/controls.style';
import useTypographyStyles from '../../../../../theme/typography.style';
import AppButton from '../../../../ui/AppButton';
import AppCheckbox from '../../../../ui/AppCheckbox';
import AppDialog from '../../../../ui/AppDialog';
import AppFormControl from '../../../../ui/AppFormControl';
import MasterDataDropdown from '../../../common/MasterDataDropdown';
import SelectAddress from '../../../common/SelectAddress';
import SellerApplicationStatusBadge from '../SellerApplicationStatusBadge';

export type SellerApplicationFormProps = {
  user: UserDataTC;
  sellerApp: SellerApplicationTC;
  onFormSubmit: (data: SellerApplicationFormDataTC) => void;
};

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  numberInput: {
    MozAppearance: 'none',
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
  bankLogo: {
    width: '28px',
    height: '28px',
    marginRight: '8px',
  },
  bankSelectorRoot: {
    display: 'flex',
    alignItems: 'center',
  },
  bankItemName: {
    flexGrow: 1,
    maxWidth: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  confirmDialogBox: {
    width: '100%',
    maxWidth: '1000px',
    [theme.breakpoints.up('md')]: {
      minWidth: '400px',
    },
  },
  statusSelectionColor: {
    color: theme.palette.error.main,
    fontWeight: 'bold',
    marginLeft: '8px',
  },
  approveSelectionColor: {
    color: theme.palette.success.main,
    fontWeight: 'bold',
    marginLeft: '8px',
  },
  editingSelectionColor: {
    color: theme.palette.warning.main,
    fontWeight: 'bold',
    marginLeft: '8px',
  },
}));

const SellerApplicationForm: FC<SellerApplicationFormProps> = (props) => {
  const { t } = useTranslation();
  const controlClasses = useControlStyles();
  const classes = useStyles();
  const typoClasses = useTypographyStyles();
  const { user, sellerApp, onFormSubmit } = props;
  const dateDisplayFormat = i18n.language === 'th' ? 'D MMM BBBB - HH:mm:ss' : 'D MMM YYYY - HH:mm:ss';
  const now = new Date();
  const currentTime = now.getTime();

  const {
    handleSubmit,
    getValues,
    setValue,
    control,
    watch,
    register,
    unregister,
    trigger,
    clearErrors,
    formState: { errors },
  } = useForm<SellerApplicationFormDataTC>({
    mode: 'onChange',
    defaultValues: {
      status: sellerApp.sellerApplication.sellerApplicationWorkflow || '',
      verifiedFullName: user.verifiedFullName || '',
      verifiedCompanyName: user.verifiedCompanyName || '',
      passportNumber: user.passportNumber || '',
      companyTaxId: user.companyTaxId || '',
      nationalId: user.nationalId || '',
      province: user.province || '',
      district: user.district || '',
      address: user.address || '',
      zipcode: user.zipcode || '',
      birthday: user.birthday || '',
      nationality: user.nationality && user.passportNumber ? 'Foreign' : 'Thai',
      gender: user.gender || '',
      bookBankNumber: user.bookBankNumber || '',
      bookBankCompanyHashId: user.bookBankCompany?.hashId || '',
      isNewSellerCampaign: sellerApp.sellerApplication.isNewSellerCampaign || sellerApp.isNewSellerFreeCoinCampaign,
      comment: sellerApp.sellerApplication.comment
        ? JSON.parse(sellerApp.sellerApplication.comment).reverse()[0].comment || ''
        : '',
    },
  });

  const isStatusApproved = watch('status') === SellerApplicationWorkflow.SellerApproval;
  const isStatusData = getValues('status');
  const isWaitingForEditStatus =
    sellerApp.sellerApplication.sellerApplicationWorkflow === SellerApplicationWorkflow.WaitingForEditingApplication;

  const { canPerform } = usePrivilege();
  const allowUpdate =
    sellerApp.user.userGeneralWorkflow === UserGeneralWorkflow.Active && canPerform('sellerApplication', 'update');

  const allowEdit =
    sellerApp.sellerApplication.sellerApplicationWorkflow === SellerApplicationWorkflow.WaitingForSellerApproval ||
    (sellerApp.sellerApplication.sellerApplicationWorkflow === SellerApplicationWorkflow.WaitingForEditingApplication &&
      canPerform('sellerApplication', 'updateAfterSubmitted'));

  const isCampaignExpired =
    Number(sellerApp.sellerApplication.createdAt) > CAMPAIGN_FREE_COIN_NEW_SELLER_END_DATE_TIMESTAMP ||
    currentTime > CAMPAIGN_FREE_COIN_NEW_SELLER_END_DATE_TIMESTAMP;

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);
  const [handleNationalId, setHandleNationalId] = useState<string | undefined>(undefined);
  const [isCheckNationalIdDialogOpen, setIsCheckNationalIdDialogOpen] = useState<boolean>(false);
  const [nationalIdAvailable, setNationalIdAvailable] = useState<boolean>(false);

  const [handleBookBankNumber, setHandleBookBankNumber] = useState<string | undefined>(undefined);
  const [isCheckBookBankNumberDialogOpen, setIsCheckBookBankNumberDialogOpen] = useState<boolean>(false);
  const [bookBankNumberAvailable, setBookBankNumberAvailable] = useState<boolean>(false);

  const [identityType, setIdentityType] = useState<'nationalId' | 'passportNumber'>(
    user.passportNumber || user.nationality === 'Foreign' ? 'passportNumber' : 'nationalId',
  );
  const [commentHistoryList, setCommentHistoryList] = useState<CommentData[]>([]);

  const isEndUserIndividualType = sellerApp.sellerApplication.shopType === ShopType.UserIndividual;
  const [isNewSellerCampaignDialogOpen, setIsNewSellerCampaignDialogOpen] = useState(false);

  const onSaveClick = () => {
    setIsConfirmDialogOpen(true);
  };

  const onConfirmationSaveClick = () => {
    setIsConfirmDialogOpen(false);

    if (typeof onFormSubmit === 'function') {
      onFormSubmit(getValues());
    }
  };

  const onConfirmationCancelClick = () => {
    setIsConfirmDialogOpen(false);
  };

  const ConfirmationDataBlock = ({
    fieldName,
    label,
  }: {
    fieldName: keyof SellerApplicationFormDataTC;
    label: string;
  }) => (
    <>
      <Typography variant="subtitle2" className={typoClasses.textWeightBold}>
        {t(label)} :
      </Typography>
      <Typography>{getValues(fieldName)}</Typography>
    </>
  );

  const checkNationalIdNotUsed = async (nationalId: string) => {
    const result = await checkNationalIdAvailable(nationalId);
    setNationalIdAvailable(!result);
    setHandleNationalId(nationalId);
    setIsCheckNationalIdDialogOpen(true);
  };

  const checkBookBankNumberDuplicate = async (bookBankNumber: string) => {
    const result = await checkBookBankNumberAvailable(bookBankNumber);
    setBookBankNumberAvailable(!result);
    setHandleBookBankNumber(bookBankNumber);
    setIsCheckBookBankNumberDialogOpen(true);
  };

  const idNumberValidator = (value?: string): ValidateResult => {
    if (value && !/\d{13}/g.test(value)) {
      return 'validation:invalidFieldFormat';
    }
    return true;
  };

  const idNumberAlphabetValidator = (value?: string): ValidateResult => {
    if (value && !/^[a-zA-Z0-9]*$/g.test(value)) {
      return 'validation:invalidFieldFormat';
    }

    return true;
  };

  const bankNumberValidator = (value: string): ValidateResult => {
    if (!/^[0-9]{10,20}/g.test(value)) {
      return 'validation:invalidFieldFormat';
    }
    return true;
  };

  const getBankLogoUrl = (url: string): string =>
    `${(process.env.REACT_APP_T2H_FRONTEND_URL || process.env.REACT_APP_DOMAIN_URL) as string}/${url.replace(
      /\//,
      '',
    )}`;

  useEffect(() => {
    if (sellerApp.sellerApplication.comment) {
      const commentStr = sellerApp.sellerApplication.comment || '';
      const commentHistory = JSON.parse(commentStr);
      setCommentHistoryList(commentHistory.reverse());
    }
  }, [sellerApp.sellerApplication.comment]);

  useEffect(() => {
    if (isStatusApproved) {
      register('province', {
        required: `${t('user:error.detail.validateProvince')}`,
      });
      register('district', {
        required: `${t('user:error.detail.validateDistrict')}`,
      });
      register('zipcode', {
        required: `${t('user:error.detail.validateZipCode')}`,
      });
    } else {
      unregister('province');
      unregister('district');
      unregister('zipcode');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStatusApproved]);

  return (
    <>
      <Typography
        variant="body1"
        component="h4"
        className={typoClasses.textWeightBold}
        style={{ marginBottom: '16px' }}>
        {t('seller-applications:details.label.applicationStatus')}
      </Typography>

      <form onSubmit={handleSubmit(onSaveClick)}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="status"
              rules={{
                required: true,
              }}
              defaultValue={sellerApp.sellerApplication.sellerApplicationWorkflow}
              render={({ field }) => {
                const onStatusChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                  field.onChange(event, checked);
                  if (!!checked && event.target.value === SellerApplicationWorkflow.WaitingForEditingApplication) {
                    setTimeout(() => {
                      setValue('comment', '');
                    }, 300);
                  } else {
                    setValue('comment', '', { shouldValidate: true });
                  }
                  clearErrors();
                };

                return (
                  <RadioGroup row value={field.value}>
                    <FormControlLabel
                      value={SellerApplicationWorkflow.SellerApproval}
                      control={
                        <Radio
                          name={field.name}
                          onChange={onStatusChange}
                          color="primary"
                          disabled={isWaitingForEditStatus || !allowEdit || !allowUpdate}
                        />
                      }
                      label={t(`seller-applications:v3.statuses.${SellerApplicationWorkflow.SellerApproval}`)}
                      labelPlacement="end"
                    />

                    <FormControlLabel
                      value={SellerApplicationWorkflow.WaitingForEditingApplication}
                      control={
                        <Radio
                          name={field.name}
                          onChange={onStatusChange}
                          color="primary"
                          disabled={isWaitingForEditStatus || !allowEdit || !allowUpdate}
                        />
                      }
                      label={t(
                        `seller-applications:v3.statuses.${SellerApplicationWorkflow.WaitingForEditingApplication}`,
                      )}
                      labelPlacement="end"
                    />

                    <FormControlLabel
                      value={SellerApplicationWorkflow.DeletedApplication}
                      control={
                        <Radio
                          name={field.name}
                          onChange={onStatusChange}
                          color="primary"
                          disabled={isWaitingForEditStatus || !allowEdit || !allowUpdate}
                        />
                      }
                      label={t(`seller-applications:v3.statuses.${SellerApplicationWorkflow.DeletedApplication}`)}
                      labelPlacement="end"
                    />

                    <Box>
                      <FormControlLabel
                        value={SellerApplicationWorkflow.BlacklistApplication}
                        control={
                          <Radio
                            name={field.name}
                            onChange={onStatusChange}
                            color="primary"
                            disabled={!allowEdit || !allowUpdate}
                          />
                        }
                        label={t(`seller-applications:v3.statuses.${SellerApplicationWorkflow.BlacklistApplication}`)}
                        labelPlacement="end"
                      />

                      <FormControlLabel
                        value={SellerApplicationWorkflow.RejectedApplication}
                        control={
                          <Radio
                            name={field.name}
                            onChange={onStatusChange}
                            color="primary"
                            disabled={!allowEdit || !allowUpdate}
                          />
                        }
                        label={t(`seller-applications:v3.statuses.${SellerApplicationWorkflow.RejectedApplication}`)}
                        labelPlacement="end"
                      />
                    </Box>
                  </RadioGroup>
                );
              }}
            />
            {!!errors.status && (
              <FormHelperText error>
                {t('validation:requiredFieldAlt', {
                  fieldName: t('seller-applications:details.label.applicationStatus').toString(),
                })}
              </FormHelperText>
            )}
          </Grid>

          {isStatusApproved && (
            <Grid item xs={12}>
              <Box mb={2}>
                <Controller
                  name="verifiedFullName"
                  control={control}
                  rules={{
                    required: true,
                    maxLength: {
                      value: 100,
                      message: t('error:validation.invalidFormat', {
                        fieldName: t('user:common.fields.verifiedFullName'),
                      }),
                    },
                  }}
                  render={({ field }) => (
                    <AppFormControl error={!!errors.verifiedFullName}>
                      <InputLabel htmlFor="seller-app-verified-fullName">
                        {t('user:common.fields.verifiedFullName')}
                      </InputLabel>
                      <Box style={{ position: 'relative' }}>
                        <OutlinedInput
                          id="seller-app-verified-fullName"
                          fullWidth
                          error={!!errors.verifiedFullName}
                          disabled={!allowEdit}
                          inputProps={field}
                        />
                        {!!errors.verifiedFullName && (
                          <FormHelperText error>
                            {errors.verifiedFullName.type === 'required' &&
                              t('validation:requiredField', {
                                fieldName: t('user:common.fields.verifiedFullName').toString(),
                              })}
                          </FormHelperText>
                        )}
                      </Box>
                    </AppFormControl>
                  )}
                />
              </Box>
              {!isEndUserIndividualType && (
                <>
                  <Box mb={2}>
                    <Controller
                      name="verifiedCompanyName"
                      control={control}
                      rules={{
                        required: !isEndUserIndividualType,
                        maxLength: {
                          value: 100,
                          message: t('error:validation.invalidFormat', {
                            fieldName: t('user:common.fields.verifiedCompanyName'),
                          }),
                        },
                      }}
                      render={({ field }) => (
                        <AppFormControl error={!!errors.verifiedCompanyName}>
                          <InputLabel htmlFor="seller-app-verified-company-name">
                            {t('user:common.fields.verifiedCompanyName')}
                          </InputLabel>
                          <Box style={{ position: 'relative' }}>
                            <OutlinedInput
                              id="seller-app-verified-company-name"
                              fullWidth
                              error={!!errors.verifiedCompanyName}
                              disabled={!allowEdit}
                              inputProps={field}
                            />
                            {!!errors.verifiedCompanyName && (
                              <FormHelperText error>
                                {errors.verifiedCompanyName.type === 'required' &&
                                  t('validation:requiredField', {
                                    fieldName: t('user:common.fields.verifiedCompanyName').toString(),
                                  })}
                              </FormHelperText>
                            )}
                          </Box>
                        </AppFormControl>
                      )}
                    />
                  </Box>

                  <Box mb={2}>
                    <Controller
                      name="companyTaxId"
                      control={control}
                      rules={{
                        required: !isEndUserIndividualType,
                        maxLength: 13,
                        validate: idNumberValidator,
                      }}
                      render={({ field }) => (
                        <AppFormControl boxProps={{ mb: 2 }} error={!!errors.companyTaxId}>
                          <InputLabel htmlFor="seller-app-tax-id">{t('user:common.fields.companyTaxId')}</InputLabel>
                          <OutlinedInput
                            id="seller-app-tax-id"
                            className={classes.numberInput}
                            fullWidth
                            error={!!errors.companyTaxId}
                            disabled={!allowEdit}
                            inputProps={field}
                          />
                          {!!errors.companyTaxId && (
                            <FormHelperText error>
                              {errors.companyTaxId.type === 'required' &&
                                t('validation:requiredField', {
                                  fieldName: t('user:common.fields.companyTaxId').toString(),
                                })}
                              {(errors.companyTaxId.type === 'validate' || errors.companyTaxId.type === 'maxLength') &&
                                t('validation:invalidFieldFormat', {
                                  fieldName: t('user:common.fields.companyTaxId').toString(),
                                  suggestion: t('user:error.detail.nationalIdFormat').toString(),
                                })}
                            </FormHelperText>
                          )}
                        </AppFormControl>
                      )}
                    />
                  </Box>
                </>
              )}

              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="birthday"
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field }) => (
                        <AppFormControl boxProps={{ mb: 2 }} error={!!errors.birthday}>
                          <InputLabel htmlFor="seller-app-birthday">{t('user:common.fields.birthday')}</InputLabel>
                          <Box>
                            <OutlinedInput
                              id="seller-app-birthday"
                              fullWidth
                              type="date"
                              defaultValue={field.value ? formatDate(new Date(field.value), 'YYYY-MM-dd') : null}
                              onChange={field.onChange}
                              error={!!errors.birthday}
                              disabled={!allowEdit}
                              inputProps={isStatusApproved ? undefined : field}
                            />
                            {!!errors.birthday && (
                              <FormHelperText error>
                                {errors.birthday.type === 'required' &&
                                  t('validation:requiredField', {
                                    fieldName: t('user:common.fields.birthday').toString(),
                                  })}
                              </FormHelperText>
                            )}
                          </Box>
                        </AppFormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="nationality"
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field }) => (
                        <AppFormControl error={!!errors.nationality}>
                          <InputLabel htmlFor="seller-app-nationality">
                            {t('user:common.fields.nationality')}
                          </InputLabel>
                          <Select
                            id="seller-app-nationality"
                            value={field.value}
                            onChange={(e) => {
                              field.onChange(e.target.value);
                              if (watch('nationality') === 'Thai') {
                                setIdentityType('nationalId');
                                setValue('passportNumber', '');
                              } else {
                                setIdentityType('passportNumber');
                                setValue('nationalId', '');
                              }
                            }}
                            error={!!errors.nationality}
                            fullWidth
                            disabled={!allowEdit}>
                            <MenuItem value="Thai">{t('user:common.fields.nationalityTh')}</MenuItem>
                            <MenuItem value="Foreign">{t('user:common.fields.nationalityForeign')}</MenuItem>
                          </Select>
                        </AppFormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="gender"
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field }) => (
                        <AppFormControl error={!!errors.gender}>
                          <InputLabel htmlFor="seller-app-gender">{t('user:common.fields.gender')}</InputLabel>
                          <Select
                            id="seller-app-gender"
                            value={field.value}
                            onChange={field.onChange}
                            error={!!errors.gender}
                            fullWidth
                            disabled={!allowEdit}>
                            <MenuItem value="M">{t('user:common.fields.male')}</MenuItem>
                            <MenuItem value="F">{t('user:common.fields.female')}</MenuItem>
                          </Select>
                        </AppFormControl>
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box mb={2}>
                <RadioGroup row defaultValue="nationalId" value={identityType}>
                  <Grid container>
                    <Grid item xs={12} sm="auto">
                      <FormControlLabel
                        value="nationalId"
                        control={
                          <Radio
                            onChange={() => {
                              setIdentityType('nationalId');
                              setValue('nationality', 'Thai');
                              setValue('passportNumber', '');
                            }}
                            color="primary"
                            disabled={!allowEdit}
                          />
                        }
                        label={t('seller-applications:details.label.nationalIdCard').toString()}
                        labelPlacement="end"
                      />
                    </Grid>
                    <Grid item xs={12} sm="auto">
                      <FormControlLabel
                        value="passportNumber"
                        control={
                          <Radio
                            onChange={() => {
                              setIdentityType('passportNumber');
                              setValue('nationality', 'Foreign');
                              setValue('nationalId', '');
                            }}
                            color="primary"
                            disabled={!allowEdit}
                          />
                        }
                        label={t('seller-applications:details.label.passport').toString()}
                        labelPlacement="end"
                      />
                    </Grid>
                  </Grid>
                </RadioGroup>
              </Box>

              {identityType === 'nationalId' && (
                <Box mb={2}>
                  <Controller
                    name="nationalId"
                    control={control}
                    rules={{
                      required: identityType === 'nationalId',
                      maxLength: 13,
                      validate: idNumberValidator,
                    }}
                    render={({ field }) => (
                      <AppFormControl boxProps={{ mb: 2 }} error={!!errors.nationalId}>
                        <InputLabel htmlFor="seller-app-national-id">{t('user:common.fields.nationalId')}</InputLabel>
                        <OutlinedInput
                          {...field}
                          id="seller-app-national-id"
                          type="number"
                          fullWidth
                          error={!!errors.nationalId}
                          inputProps={{
                            className: classes.numberInput,
                            onKeyDown: (e) => {
                              const isNumberKey = /[0-9]/g.test(e.key);
                              if ((field.value || '').length < 13) {
                                return true;
                              }

                              if (!isNumberKey) {
                                return true;
                              }

                              e.preventDefault();
                              return false;
                            },
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              <AppButton
                                onClick={() => checkNationalIdNotUsed(field.value ? field.value : '')}
                                size="small"
                                disabled={!allowEdit}
                                variant="outlined">
                                {t('common:button.check')}
                              </AppButton>
                            </InputAdornment>
                          }
                          disabled={!allowEdit}
                        />
                        {!!errors.nationalId && (
                          <FormHelperText error>
                            {errors.nationalId.type === 'required' &&
                              t('validation:requiredField', {
                                fieldName: t('user:common.fields.nationalId').toString(),
                              })}
                            {(errors.nationalId.type === 'validate' || errors.nationalId.type === 'maxLength') &&
                              t('validation:invalidFieldFormat', {
                                fieldName: t('user:common.fields.nationalId').toString(),
                                suggestion: t('user:error.detail.nationalIdFormat').toString(),
                              })}
                          </FormHelperText>
                        )}
                      </AppFormControl>
                    )}
                  />
                </Box>
              )}
              {identityType === 'passportNumber' && (
                <Box mb={2}>
                  <Controller
                    name="passportNumber"
                    control={control}
                    rules={{
                      required: identityType === 'passportNumber',
                      validate: idNumberAlphabetValidator,
                    }}
                    render={({ field }) => (
                      <AppFormControl error={!!errors.passportNumber}>
                        <InputLabel htmlFor="seller-app-passport-number">
                          {t('user:common.fields.passportNumber')}
                        </InputLabel>
                        <OutlinedInput
                          {...field}
                          id="seller-app-passport-number"
                          fullWidth
                          error={!!errors.passportNumber}
                          disabled={!allowEdit}
                          inputProps={{
                            maxLength: 10,
                          }}
                        />
                        {!!errors.passportNumber && (
                          <FormHelperText error>
                            {errors.passportNumber.type === 'required' &&
                              t('validation:requiredField', {
                                fieldName: t('user:common.fields.passportNumber').toString(),
                              })}
                            {(errors.passportNumber.type === 'validate' ||
                              errors.passportNumber.type === 'maxLength') &&
                              t('validation:invalidFieldFormat', {
                                fieldName: t('user:common.fields.passportNumber').toString(),
                              })}
                          </FormHelperText>
                        )}
                      </AppFormControl>
                    )}
                  />
                </Box>
              )}

              <Box mb={2}>
                <Controller
                  name="bookBankNumber"
                  control={control}
                  rules={{
                    required: true,
                    minLength: 10,
                    maxLength: 20,
                    validate: bankNumberValidator,
                  }}
                  render={({ field }) => (
                    <AppFormControl boxProps={{ mb: 2 }} error={!!errors.bookBankNumber}>
                      <InputLabel htmlFor="seller-app-book-bank-number">
                        {t('user:common.fields.bookBankNumber')}
                      </InputLabel>
                      <OutlinedInput
                        id="seller-app-book-bank-number"
                        fullWidth
                        error={!!errors.bookBankNumber}
                        inputProps={{
                          className: classes.numberInput,
                          onKeyDown: (e) => {
                            const isNumberKey = /^[0-9]/g.test(e.key);
                            if (field.value.length < 20) {
                              return true;
                            }

                            if (!isNumberKey) {
                              return true;
                            }

                            e.preventDefault();
                            return false;
                          },
                          ...field,
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <AppButton
                              onClick={() => checkBookBankNumberDuplicate(field.value)}
                              size="small"
                              disabled={!allowEdit}
                              variant="outlined">
                              {t('common:button.check')}
                            </AppButton>
                          </InputAdornment>
                        }
                        disabled={!allowEdit}
                      />
                      {!!errors.bookBankNumber && (
                        <FormHelperText error>
                          {errors.bookBankNumber.type === 'required' &&
                            t('validation:requiredField', {
                              fieldName: t('user:common.fields.bookBankNumber').toString(),
                            })}
                          {(errors.bookBankNumber.type === 'validate' ||
                            errors.bookBankNumber.type === 'maxLength' ||
                            errors.bookBankNumber.type === 'minLength') &&
                            t('validation:invalidFieldFormat', {
                              fieldName: t('user:common.fields.bookBankNumber').toString(),
                              suggestion: t('user:error.detail.bookBankNumberIdFormat').toString(),
                            })}
                        </FormHelperText>
                      )}
                    </AppFormControl>
                  )}
                />
              </Box>
              <Box mb={2}>
                <Controller
                  name="bookBankCompanyHashId"
                  control={control}
                  rules={{
                    required: true,
                    maxLength: {
                      value: 100,
                      message: t('error:validation.invalidFormat', {
                        fieldName: t('user:common.fields.bookBankCompanyHashId'),
                      }),
                    },
                  }}
                  render={({ field }) => (
                    <AppFormControl error={!!errors.bookBankCompanyHashId}>
                      <MasterDataDropdown
                        dataKey="bankMaster"
                        itemValuePropName="hashId"
                        itemLabelPropName="thaiName"
                        inputLabel={t('user:common.fields.bookBankCompanyHashId').toString()}
                        value={field.value}
                        onChange={field.onChange}
                        classes={{ root: classes.bankSelectorRoot }}
                        error={!!errors.bookBankCompanyHashId}
                        disabled={!allowEdit}
                        menuItemComponent={(item: BankMaster) => (
                          <>
                            <Avatar alt={item.thaiName} src={getBankLogoUrl(item.logo)} className={classes.bankLogo} />
                            <Typography className={classes.bankItemName}>{item.thaiName}</Typography>
                          </>
                        )}
                      />

                      {!!errors.bookBankCompanyHashId && (
                        <FormHelperText error>
                          {t('validation:requiredFieldAlt', {
                            fieldName: t('seller-applications:details.label.bankHashId').toString(),
                          })}
                        </FormHelperText>
                      )}
                    </AppFormControl>
                  )}
                />
              </Box>

              <Box mb={2}>
                <Typography style={{ marginBottom: '16px' }}>ที่อยู่ตามบัตรประชาชน</Typography>
                <Controller
                  name="address"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <AppFormControl error={!!errors.address}>
                      <InputLabel htmlFor="seller-app-address">{t('user:common.fields.address')}</InputLabel>
                      <OutlinedInput
                        id="seller-app-address"
                        fullWidth
                        rows={2}
                        multiline
                        error={!!errors.address}
                        disabled={!allowEdit}
                        inputProps={field}
                      />
                      {!!errors.address && (
                        <FormHelperText error>
                          {errors.address.type === 'required' &&
                            t('validation:requiredField', {
                              fieldName: t('user:common.fields.address').toString(),
                            })}
                        </FormHelperText>
                      )}
                    </AppFormControl>
                  )}
                />
              </Box>
              <Box mb={2}>
                <SelectAddress
                  currentValue={{
                    province: watch('province'),
                    district: watch('district'),
                    zipcode: watch('zipcode'),
                  }}
                  errorMessage={{
                    province: errors.province?.message,
                    district: errors.district?.message,
                    zipcode: errors.zipcode?.message,
                  }}
                  onChange={{
                    province: (value) => {
                      setValue('province', value);
                      setValue('district', '');
                      setValue('zipcode', '');
                      if (errors.province?.message != null) {
                        trigger('province');
                      }
                    },
                    district: (value) => {
                      setValue('district', value);
                      setValue('zipcode', '');
                      if (errors.district?.message != null) {
                        trigger('district');
                      }
                    },
                    zipcode: (value) => {
                      setValue('zipcode', value);
                      if (errors.zipcode?.message != null) {
                        trigger('zipcode');
                      }
                    },
                  }}
                  isEdit={!allowEdit}
                />
              </Box>

              {!isCampaignExpired && allowEdit && (
                <Box mb={2}>
                  <Grid item xs="auto">
                    <Box display="flex" flexDirection="row" textAlign="center">
                      <Typography
                        component="label"
                        htmlFor="seller-app-new-seller-campaign"
                        style={{ fontWeight: 700 }}>
                        {t('user:details.label.joinCampaign')}
                      </Typography>
                      <AppButton
                        color="primary"
                        variant="text"
                        style={{ marginLeft: 4, minWidth: 'auto' }}
                        startIcon={<EmojiObjectsOutlinedIcon style={{ marginLeft: '6px' }} />}
                        onClick={() => setIsNewSellerCampaignDialogOpen(true)}
                      />
                    </Box>

                    <Controller
                      name="isNewSellerCampaign"
                      control={control}
                      rules={{
                        required: false,
                      }}
                      render={({ field }) => (
                        <AppFormControl>
                          <FormControlLabel
                            control={
                              <AppCheckbox
                                id="seller-app-new-seller-campaign"
                                name={t('user:common.fields.isNewSellerCampaign')}
                                checked={field.value}
                                onChange={(e) => {
                                  field.onChange(e.currentTarget.checked);
                                }}
                                color="default"
                                disabled={!allowEdit}
                              />
                            }
                            label={t('user:common.fields.isNewSellerCampaign')}
                          />
                        </AppFormControl>
                      )}
                    />
                  </Grid>
                </Box>
              )}
              {sellerApp.isNewSellerFreeCoinCampaign && isStatusApproved && (
                <Box mb={2}>
                  <Grid item xs="auto">
                    <Box display="flex" flexDirection="row" textAlign="center">
                      <Typography
                        component="label"
                        htmlFor="seller-app-new-seller-campaign"
                        style={{ fontWeight: 700 }}>
                        {t('user:details.label.joinCampaign')}
                      </Typography>
                      <AppButton
                        color="primary"
                        variant="text"
                        style={{ marginLeft: 4, minWidth: 'auto' }}
                        startIcon={<EmojiObjectsOutlinedIcon style={{ marginLeft: '6px' }} />}
                        onClick={() => setIsNewSellerCampaignDialogOpen(true)}
                      />
                    </Box>

                    <Controller
                      name="isNewSellerCampaign"
                      control={control}
                      rules={{
                        required: false,
                      }}
                      render={({ field }) => (
                        <AppFormControl>
                          <FormControlLabel
                            control={
                              <AppCheckbox
                                id="seller-app-new-seller-campaign"
                                name={t('user:common.fields.isNewSellerCampaign')}
                                checked={field.value}
                                onChange={(e) => {
                                  field.onChange(e.currentTarget.checked);
                                }}
                                color="default"
                                disabled
                              />
                            }
                            label={t('user:common.fields.isNewSellerCampaign')}
                          />
                        </AppFormControl>
                      )}
                    />
                  </Grid>
                </Box>
              )}
            </Grid>
          )}

          {sellerApp.sellerApplication.sellerApplicationWorkflow && (
            <Grid item xs={12}>
              <Box mb={2}>
                <Controller
                  control={control}
                  name="comment"
                  rules={{
                    required: !isStatusApproved,
                  }}
                  render={({ field }) => (
                    <AppFormControl>
                      <TextField
                        {...field}
                        id="seller-app-reject-reason"
                        label={t(
                          isStatusApproved
                            ? 'seller-applications:v3.details.comment'
                            : 'seller-applications:v3.details.rejectReason',
                        ).toString()}
                        variant="outlined"
                        fullWidth
                        rows={3}
                        multiline
                        error={!isStatusApproved && !!errors.comment}
                        disabled={!allowEdit || !allowUpdate}
                      />
                      {!!errors.comment && !isStatusApproved && (
                        <FormHelperText error>
                          {t('validation:requiredFieldAlt', {
                            fieldName: t('seller-applications:v3.details.rejectReason').toString(),
                          })}
                        </FormHelperText>
                      )}
                    </AppFormControl>
                  )}
                />
              </Box>
            </Grid>
          )}

          {allowUpdate && allowEdit && (
            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end" className={controlClasses.buttonsGroup}>
                <AppButton type="submit" color="primary">
                  {t('common:button.save')}
                </AppButton>
              </Box>
            </Grid>
          )}
        </Grid>
      </form>

      {!isStatusApproved && commentHistoryList.length > 0 && (
        <Box mt={2}>
          <Box mb={3}>
            <Divider />
          </Box>
          <Typography variant="body1" className={typoClasses.textWeightBold} style={{ marginBottom: '16px' }}>
            {t('seller-applications:details.label.historyReject')}
          </Typography>
          {commentHistoryList.map((item, index) => {
            const itemKey = `item-${index}`;
            return (
              <Box key={itemKey} mb={3}>
                <Box display="flex" flexDirection="row" justifyContent="space-between" mb={1}>
                  <SellerApplicationStatusBadge status={item.sellerApplicationWorkflow as SellerApplicationWorkflow} />
                  <Typography variant="subtitle2">{dayjs(item.createdAt).format(`${dateDisplayFormat}`)}</Typography>
                </Box>
                <Typography>{item.comment}</Typography>
              </Box>
            );
          })}
        </Box>
      )}

      <AppDialog
        open={isCheckNationalIdDialogOpen}
        title="common:dialog.title.info"
        okButtonText="common:button.close"
        okButtonColor="primary"
        okButtonVariant="outlined"
        onOkClick={() => setIsCheckNationalIdDialogOpen(false)}>
        <Typography>
          {t(
            `seller-applications:common.message.${
              nationalIdAvailable ? 'nationalIdCardUsed' : 'nationalIdCardNotUsed'
            }`,
            {
              fieldName: handleNationalId,
            },
          )}
        </Typography>
      </AppDialog>

      <AppDialog
        open={isCheckBookBankNumberDialogOpen}
        title="common:dialog.title.info"
        okButtonText="common:button.close"
        okButtonColor="primary"
        okButtonVariant="outlined"
        onOkClick={() => setIsCheckBookBankNumberDialogOpen(false)}>
        <Typography>
          {t(`seller-applications:common.message.${bookBankNumberAvailable ? 'bankAccNoUsed' : 'bankAccNoNotUsed'}`, {
            fieldName: handleBookBankNumber,
          })}
        </Typography>
      </AppDialog>

      <AppDialog
        title={t('common:dialog.title.confirm').toString()}
        open={isConfirmDialogOpen}
        onOkClick={onConfirmationSaveClick}
        onCancelClick={onConfirmationCancelClick}
        cancelButtonText={t('common:button.cancel').toString()}
        okButtonText={t('common:button.confirm').toString()}
        okButtonColor="primary">
        <Box className={classes.confirmDialogBox}>
          <Box mb={2}>
            <Typography>{t('seller-applications:common.message.savingDataConfirmation')}</Typography>
          </Box>
          <Box display="flex" flexDirection="row" mb={2}>
            <Typography variant="subtitle2" className={typoClasses.textWeightBold}>
              {t('seller-applications:details.label.applicationStatusConfirm')} :
            </Typography>
            <Typography
              className={clsx(
                {
                  [classes.approveSelectionColor]: isStatusApproved,
                  [classes.editingSelectionColor]:
                    isStatusData === SellerApplicationWorkflow.WaitingForEditingApplication,
                },
                classes.statusSelectionColor,
              )}>
              {t(`seller-applications:v3.statuses.${isStatusData}`)}
            </Typography>
          </Box>

          {isStatusData === SellerApplicationWorkflow.SellerApproval && (
            <>
              {isEndUserIndividualType ? (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <ConfirmationDataBlock fieldName="verifiedFullName" label="user:common.fields.verifiedFullName" />
                  </Grid>

                  {getValues('passportNumber') ? (
                    <Grid item xs={12} md={6}>
                      <ConfirmationDataBlock fieldName="passportNumber" label="user:common.fields.passportNumber" />
                    </Grid>
                  ) : (
                    <Grid item xs={12} md={6}>
                      <ConfirmationDataBlock fieldName="nationalId" label="user:common.fields.nationalId" />
                    </Grid>
                  )}
                </Grid>
              ) : (
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <ConfirmationDataBlock
                      fieldName="verifiedCompanyName"
                      label="user:common.fields.verifiedCompanyName"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ConfirmationDataBlock fieldName="companyTaxId" label="user:common.fields.companyTaxId" />
                  </Grid>
                </Grid>
              )}

              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <ConfirmationDataBlock fieldName="bookBankNumber" label="user:common.fields.bookBankNumber" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle2" className={typoClasses.textWeightBold}>
                    {t('user:common.fields.bookBankCompanyHashId')}
                  </Typography>
                  <MasterDataDropdown
                    dataKey="bankMaster"
                    itemValuePropName="hashId"
                    itemLabelPropName="thaiName"
                    value={getValues('bookBankCompanyHashId')}
                    formControlProps={{ boxProps: { mt: 2 } }}
                    classes={{ root: classes.bankSelectorRoot }}
                    renderAsText
                    menuItemComponent={(item: BankMaster) => (
                      <>
                        <Avatar alt={item.thaiName} src={item.logo} className={classes.bankLogo} />
                        <span className={classes.bankItemName}>{item.thaiName}</span>
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <ConfirmationDataBlock fieldName="address" label="user:common.fields.address" />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <ConfirmationDataBlock fieldName="district" label="user:common.fields.district" />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <ConfirmationDataBlock fieldName="province" label="user:common.fields.province" />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <ConfirmationDataBlock fieldName="zipcode" label="user:common.fields.zipcode" />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="subtitle2" className={typoClasses.textWeightBold}>
                    {t('user:common.fields.birthday')} :
                  </Typography>
                  <Typography>{dayjs(getValues('birthday')).format('D MMM BBBB')}</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="subtitle2" className={typoClasses.textWeightBold}>
                    {t('user:common.fields.nationality')} :
                  </Typography>

                  <Typography>
                    {t(
                      `user:common.fields.${
                        getValues('nationality') === 'Thai' ? 'nationalityTh' : 'nationalityForeign'
                      }`,
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="subtitle2" className={typoClasses.textWeightBold}>
                    {t('user:common.fields.gender')} :
                  </Typography>
                  <Typography>{getValues('gender') === 'F' ? 'หญิง' : 'ชาย'}</Typography>
                </Grid>
                {getValues('isNewSellerCampaign') && (
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" className={typoClasses.textWeightBold}>
                      {t('user:details.label.joinCampaign')} :
                    </Typography>
                    <Typography>{t('user:common.fields.isNewSellerCampaign')}</Typography>
                  </Grid>
                )}
              </Grid>
            </>
          )}

          <Grid container spacing={2}>
            {getValues('comment') && (
              <Grid item xs={12}>
                <ConfirmationDataBlock fieldName="comment" label="seller-applications:v3.details.rejectReason" />
              </Grid>
            )}

            {isStatusData === SellerApplicationWorkflow.BlacklistApplication && (
              <Grid item xs={12}>
                <Typography color="error" variant="subtitle2">
                  {t('seller-applications:details.message.remarkBlacklistSellerApplication')}
                </Typography>
              </Grid>
            )}
            {isStatusData === SellerApplicationWorkflow.DeletedApplication && (
              <Grid item xs={12}>
                <Typography color="error" variant="subtitle2">
                  {t('seller-applications:details.message.remarkDeleteSellerApplication')}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Box>
      </AppDialog>

      <AppDialog
        type="tips"
        open={isNewSellerCampaignDialogOpen}
        title="seller-applications:v3.dialog.titleNewSellerCampaignDialogOpen"
        okButtonText="common:button.close"
        okButtonColor="default"
        okButtonVariant="outlined"
        onOkClick={() => setIsNewSellerCampaignDialogOpen(false)}
        dialogProps={{
          fullWidth: true,
          maxWidth: 'md',
        }}>
        <Box>
          <ol>
            <li>
              ใบสมัครที่ส่งมาก่อนเอา Feature ขึ้น หรือใบสมัครที่ส่งมาในช่วงก่อน 30 เม.ย. 66 เวลา 23.59
              จะได้รับสิทธิ์ทั้งหมด
            </li>
            <li>
              ใบสมัครที่เคยส่งมาก่อนหน้า Campaign แล้วโดน Reject และถ้าอนุมัติในช่วงของ Campaign จะได้รับสิทธิ์ทั้งหมด
            </li>
            <li>ใบสมัครที่ส่งมาในช่วง Campaign แต่โดน Reject จนเลยช่วง Campaign ไปแล้ว จะได้รับสิทธิ์ทั้งหมด</li>
          </ol>
        </Box>
        <Box ml={3}>
          <Typography color="error">ใบสมัครที่ User Reverify มาจะถือว่าไม่ได้รับสิทธิ์เข้าร่วม</Typography>
        </Box>
      </AppDialog>
    </>
  );
};

export default SellerApplicationForm;
