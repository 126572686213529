const THAI_ADDRESS_LIST = [
  {
    district: 'คลองท่อม',
    province: 'กระบี่',
    zipcode: 81120,
  },
  {
    district: 'คลองท่อม',
    province: 'กระบี่',
    zipcode: 81170,
  },
  {
    district: 'ปลายพระยา',
    province: 'กระบี่',
    zipcode: 81160,
  },
  {
    district: 'ลำทับ',
    province: 'กระบี่',
    zipcode: 81190,
  },
  {
    district: 'อ่าวลึก',
    province: 'กระบี่',
    zipcode: 81110,
  },
  {
    district: 'เกาะลันตา',
    province: 'กระบี่',
    zipcode: 81120,
  },
  {
    district: 'เกาะลันตา',
    province: 'กระบี่',
    zipcode: 81150,
  },
  {
    district: 'เขาพนม',
    province: 'กระบี่',
    zipcode: 81140,
  },
  {
    district: 'เขาพนม',
    province: 'กระบี่',
    zipcode: 80240,
  },
  {
    district: 'เมืองกระบี่',
    province: 'กระบี่',
    zipcode: 81000,
  },
  {
    district: 'เมืองกระบี่',
    province: 'กระบี่',
    zipcode: 81180,
  },
  {
    district: 'เหนือคลอง',
    province: 'กระบี่',
    zipcode: 81130,
  },
  {
    district: 'คลองสาน',
    province: 'กรุงเทพมหานคร',
    zipcode: 10600,
  },
  {
    district: 'คลองสามวา',
    province: 'กรุงเทพมหานคร',
    zipcode: 10510,
  },
  {
    district: 'คลองเตย',
    province: 'กรุงเทพมหานคร',
    zipcode: 10110,
  },
  {
    district: 'คลองเตย',
    province: 'กรุงเทพมหานคร',
    zipcode: 10260,
  },
  {
    district: 'คันนายาว',
    province: 'กรุงเทพมหานคร',
    zipcode: 10230,
  },
  {
    district: 'จตุจักร',
    province: 'กรุงเทพมหานคร',
    zipcode: 10900,
  },
  {
    district: 'จอมทอง',
    province: 'กรุงเทพมหานคร',
    zipcode: 10150,
  },
  {
    district: 'ดอนเมือง',
    province: 'กรุงเทพมหานคร',
    zipcode: 10210,
  },
  {
    district: 'ดินแดง',
    province: 'กรุงเทพมหานคร',
    zipcode: 10400,
  },
  {
    district: 'ดุสิต',
    province: 'กรุงเทพมหานคร',
    zipcode: 10300,
  },
  {
    district: 'ดุสิต',
    province: 'กรุงเทพมหานคร',
    zipcode: 10303,
  },
  {
    district: 'ตลิ่งชัน',
    province: 'กรุงเทพมหานคร',
    zipcode: 10170,
  },
  {
    district: 'ทวีวัฒนา',
    province: 'กรุงเทพมหานคร',
    zipcode: 10170,
  },
  {
    district: 'ทุ่งครุ',
    province: 'กรุงเทพมหานคร',
    zipcode: 10140,
  },
  {
    district: 'ธนบุรี',
    province: 'กรุงเทพมหานคร',
    zipcode: 10600,
  },
  {
    district: 'บางกอกน้อย',
    province: 'กรุงเทพมหานคร',
    zipcode: 10700,
  },
  {
    district: 'บางกอกใหญ่',
    province: 'กรุงเทพมหานคร',
    zipcode: 10600,
  },
  {
    district: 'บางกะปิ',
    province: 'กรุงเทพมหานคร',
    zipcode: 10240,
  },
  {
    district: 'บางกะปิ',
    province: 'กรุงเทพมหานคร',
    zipcode: 10250,
  },
  {
    district: 'บางขุนเทียน',
    province: 'กรุงเทพมหานคร',
    zipcode: 10150,
  },
  {
    district: 'บางคอแหลม',
    province: 'กรุงเทพมหานคร',
    zipcode: 10120,
  },
  {
    district: 'บางซื่อ',
    province: 'กรุงเทพมหานคร',
    zipcode: 10800,
  },
  {
    district: 'บางนา',
    province: 'กรุงเทพมหานคร',
    zipcode: 10260,
  },
  {
    district: 'บางบอน',
    province: 'กรุงเทพมหานคร',
    zipcode: 10150,
  },
  {
    district: 'บางพลัด',
    province: 'กรุงเทพมหานคร',
    zipcode: 10700,
  },
  {
    district: 'บางรัก',
    province: 'กรุงเทพมหานคร',
    zipcode: 10500,
  },
  {
    district: 'บางเขน',
    province: 'กรุงเทพมหานคร',
    zipcode: 10220,
  },
  {
    district: 'บางเขน',
    province: 'กรุงเทพมหานคร',
    zipcode: 10230,
  },
  {
    district: 'บางแค',
    province: 'กรุงเทพมหานคร',
    zipcode: 10160,
  },
  {
    district: 'บึงกุ่ม',
    province: 'กรุงเทพมหานคร',
    zipcode: 10230,
  },
  {
    district: 'บึงกุ่ม',
    province: 'กรุงเทพมหานคร',
    zipcode: 10240,
  },
  {
    district: 'ปทุมวัน',
    province: 'กรุงเทพมหานคร',
    zipcode: 10330,
  },
  {
    district: 'ปทุมวัน',
    province: 'กรุงเทพมหานคร',
    zipcode: 10110,
  },
  {
    district: 'ปทุมวัน',
    province: 'กรุงเทพมหานคร',
    zipcode: 10120,
  },
  {
    district: 'ปทุมวัน',
    province: 'กรุงเทพมหานคร',
    zipcode: 10400,
  },
  {
    district: 'ปทุมวัน',
    province: 'กรุงเทพมหานคร',
    zipcode: 10500,
  },
  {
    district: 'ประเวศ',
    province: 'กรุงเทพมหานคร',
    zipcode: 10250,
  },
  {
    district: 'ป้อมปราบศัตรูพ่าย',
    province: 'กรุงเทพมหานคร',
    zipcode: 10100,
  },
  {
    district: 'พญาไท',
    province: 'กรุงเทพมหานคร',
    zipcode: 10400,
  },
  {
    district: 'พระนคร',
    province: 'กรุงเทพมหานคร',
    zipcode: 10200,
  },
  {
    district: 'พระโขนง',
    province: 'กรุงเทพมหานคร',
    zipcode: 10260,
  },
  {
    district: 'ภาษีเจริญ',
    province: 'กรุงเทพมหานคร',
    zipcode: 10160,
  },
  {
    district: 'มีนบุรี',
    province: 'กรุงเทพมหานคร',
    zipcode: 10510,
  },
  {
    district: 'ยานนาวา',
    province: 'กรุงเทพมหานคร',
    zipcode: 10120,
  },
  {
    district: 'ราชเทวี',
    province: 'กรุงเทพมหานคร',
    zipcode: 10400,
  },
  {
    district: 'ราษฎร์บูรณะ',
    province: 'กรุงเทพมหานคร',
    zipcode: 10140,
  },
  {
    district: 'ลาดกระบัง',
    province: 'กรุงเทพมหานคร',
    zipcode: 10520,
  },
  {
    district: 'ลาดพร้าว',
    province: 'กรุงเทพมหานคร',
    zipcode: 10230,
  },
  {
    district: 'วังทองหลาง',
    province: 'กรุงเทพมหานคร',
    zipcode: 10310,
  },
  {
    district: 'วัฒนา',
    province: 'กรุงเทพมหานคร',
    zipcode: 10110,
  },
  {
    district: 'วัฒนา',
    province: 'กรุงเทพมหานคร',
    zipcode: 10260,
  },
  {
    district: 'สวนหลวง',
    province: 'กรุงเทพมหานคร',
    zipcode: 10250,
  },
  {
    district: 'สะพานสูง',
    province: 'กรุงเทพมหานคร',
    zipcode: 10240,
  },
  {
    district: 'สะพานสูง',
    province: 'กรุงเทพมหานคร',
    zipcode: 10250,
  },
  {
    district: 'สัมพันธวงศ์',
    province: 'กรุงเทพมหานคร',
    zipcode: 10100,
  },
  {
    district: 'สาทร',
    province: 'กรุงเทพมหานคร',
    zipcode: 10120,
  },
  {
    district: 'สายไหม',
    province: 'กรุงเทพมหานคร',
    zipcode: 10220,
  },
  {
    district: 'หนองจอก',
    province: 'กรุงเทพมหานคร',
    zipcode: 10530,
  },
  {
    district: 'หนองแขม',
    province: 'กรุงเทพมหานคร',
    zipcode: 10160,
  },
  {
    district: 'หลักสี่',
    province: 'กรุงเทพมหานคร',
    zipcode: 10210,
  },
  {
    district: 'ห้วยขวาง',
    province: 'กรุงเทพมหานคร',
    zipcode: 10310,
  },
  {
    district: 'ด่านมะขามเตี้ย',
    province: 'กาญจนบุรี',
    zipcode: 71260,
  },
  {
    district: 'ทองผาภูมิ',
    province: 'กาญจนบุรี',
    zipcode: 71180,
  },
  {
    district: 'ท่ามะกา',
    province: 'กาญจนบุรี',
    zipcode: 71120,
  },
  {
    district: 'ท่ามะกา',
    province: 'กาญจนบุรี',
    zipcode: 71130,
  },
  {
    district: 'ท่ามะกา',
    province: 'กาญจนบุรี',
    zipcode: 70190,
  },
  {
    district: 'ท่าม่วง',
    province: 'กาญจนบุรี',
    zipcode: 71110,
  },
  {
    district: 'ท่าม่วง',
    province: 'กาญจนบุรี',
    zipcode: 71130,
  },
  {
    district: 'ท่าม่วง',
    province: 'กาญจนบุรี',
    zipcode: 71000,
  },
  {
    district: 'บ่อพลอย',
    province: 'กาญจนบุรี',
    zipcode: 71160,
  },
  {
    district: 'บ่อพลอย',
    province: 'กาญจนบุรี',
    zipcode: 71220,
  },
  {
    district: 'พนมทวน',
    province: 'กาญจนบุรี',
    zipcode: 71140,
  },
  {
    district: 'พนมทวน',
    province: 'กาญจนบุรี',
    zipcode: 71170,
  },
  {
    district: 'ศรีสวัสดิ์',
    province: 'กาญจนบุรี',
    zipcode: 71250,
  },
  {
    district: 'ศรีสวัสดิ์',
    province: 'กาญจนบุรี',
    zipcode: 71220,
  },
  {
    district: 'สังขละบุรี',
    province: 'กาญจนบุรี',
    zipcode: 71240,
  },
  {
    district: 'หนองปรือ',
    province: 'กาญจนบุรี',
    zipcode: 71220,
  },
  {
    district: 'ห้วยกระเจา',
    province: 'กาญจนบุรี',
    zipcode: 71170,
  },
  {
    district: 'เมืองกาญจนบุรี',
    province: 'กาญจนบุรี',
    zipcode: 71190,
  },
  {
    district: 'เมืองกาญจนบุรี',
    province: 'กาญจนบุรี',
    zipcode: 71000,
  },
  {
    district: 'เลาขวัญ',
    province: 'กาญจนบุรี',
    zipcode: 71210,
  },
  {
    district: 'ไทรโยค',
    province: 'กาญจนบุรี',
    zipcode: 71150,
  },
  {
    district: 'กมลาไสย',
    province: 'กาฬสินธุ์',
    zipcode: 46130,
  },
  {
    district: 'กุฉินารายณ์',
    province: 'กาฬสินธุ์',
    zipcode: 46110,
  },
  {
    district: 'คำม่วง',
    province: 'กาฬสินธุ์',
    zipcode: 46180,
  },
  {
    district: 'ฆ้องชัย',
    province: 'กาฬสินธุ์',
    zipcode: 46130,
  },
  {
    district: 'ดอนจาน',
    province: 'กาฬสินธุ์',
    zipcode: 46000,
  },
  {
    district: 'ท่าคันโท',
    province: 'กาฬสินธุ์',
    zipcode: 46190,
  },
  {
    district: 'นาคู',
    province: 'กาฬสินธุ์',
    zipcode: 46160,
  },
  {
    district: 'นามน',
    province: 'กาฬสินธุ์',
    zipcode: 46230,
  },
  {
    district: 'ยางตลาด',
    province: 'กาฬสินธุ์',
    zipcode: 46120,
  },
  {
    district: 'ร่องคำ',
    province: 'กาฬสินธุ์',
    zipcode: 46210,
  },
  {
    district: 'สมเด็จ',
    province: 'กาฬสินธุ์',
    zipcode: 46150,
  },
  {
    district: 'สหัสขันธ์',
    province: 'กาฬสินธุ์',
    zipcode: 46140,
  },
  {
    district: 'สามชัย',
    province: 'กาฬสินธุ์',
    zipcode: 46180,
  },
  {
    district: 'หนองกุงศรี',
    province: 'กาฬสินธุ์',
    zipcode: 46220,
  },
  {
    district: 'ห้วยผึ้ง',
    province: 'กาฬสินธุ์',
    zipcode: 46240,
  },
  {
    district: 'ห้วยเม็ก',
    province: 'กาฬสินธุ์',
    zipcode: 46170,
  },
  {
    district: 'เขาวง',
    province: 'กาฬสินธุ์',
    zipcode: 46160,
  },
  {
    district: 'เมืองกาฬสินธุ์',
    province: 'กาฬสินธุ์',
    zipcode: 46000,
  },
  {
    district: 'ขาณุวรลักษบุรี',
    province: 'กำแพงเพชร',
    zipcode: 62140,
  },
  {
    district: 'ขาณุวรลักษบุรี',
    province: 'กำแพงเพชร',
    zipcode: 62130,
  },
  {
    district: 'คลองขลุง',
    province: 'กำแพงเพชร',
    zipcode: 62120,
  },
  {
    district: 'คลองลาน',
    province: 'กำแพงเพชร',
    zipcode: 62180,
  },
  {
    district: 'ทรายทองวัฒนา',
    province: 'กำแพงเพชร',
    zipcode: 62190,
  },
  {
    district: 'บึงสามัคคี',
    province: 'กำแพงเพชร',
    zipcode: 62210,
  },
  {
    district: 'ปางศิลาทอง',
    province: 'กำแพงเพชร',
    zipcode: 62120,
  },
  {
    district: 'พรานกระต่าย',
    province: 'กำแพงเพชร',
    zipcode: 62110,
  },
  {
    district: 'ลานกระบือ',
    province: 'กำแพงเพชร',
    zipcode: 62170,
  },
  {
    district: 'เมืองกำแพงเพชร',
    province: 'กำแพงเพชร',
    zipcode: 62000,
  },
  {
    district: 'เมืองกำแพงเพชร',
    province: 'กำแพงเพชร',
    zipcode: 62160,
  },
  {
    district: 'โกสัมพีนคร',
    province: 'กำแพงเพชร',
    zipcode: 62000,
  },
  {
    district: 'ไทรงาม',
    province: 'กำแพงเพชร',
    zipcode: 62150,
  },
  {
    district: 'กระนวน',
    province: 'ขอนแก่น',
    zipcode: 40170,
  },
  {
    district: 'ชนบท',
    province: 'ขอนแก่น',
    zipcode: 40180,
  },
  {
    district: 'ชุมแพ',
    province: 'ขอนแก่น',
    zipcode: 40130,
  },
  {
    district: 'ชุมแพ',
    province: 'ขอนแก่น',
    zipcode: 40290,
  },
  {
    district: 'ซำสูง',
    province: 'ขอนแก่น',
    zipcode: 40170,
  },
  {
    district: 'น้ำพอง',
    province: 'ขอนแก่น',
    zipcode: 40310,
  },
  {
    district: 'น้ำพอง',
    province: 'ขอนแก่น',
    zipcode: 40140,
  },
  {
    district: 'บ้านฝาง',
    province: 'ขอนแก่น',
    zipcode: 40270,
  },
  {
    district: 'บ้านแฮด',
    province: 'ขอนแก่น',
    zipcode: 40110,
  },
  {
    district: 'บ้านไผ่',
    province: 'ขอนแก่น',
    zipcode: 40110,
  },
  {
    district: 'พระยืน',
    province: 'ขอนแก่น',
    zipcode: 40320,
  },
  {
    district: 'พล',
    province: 'ขอนแก่น',
    zipcode: 40120,
  },
  {
    district: 'ภูผาม่าน',
    province: 'ขอนแก่น',
    zipcode: 40350,
  },
  {
    district: 'ภูเวียง',
    province: 'ขอนแก่น',
    zipcode: 40150,
  },
  {
    district: 'เวียงเก่า',
    province: 'ขอนแก่น',
    zipcode: 40150,
  },
  {
    district: 'มัญจาคีรี',
    province: 'ขอนแก่น',
    zipcode: 40160,
  },
  {
    district: 'สีชมพู',
    province: 'ขอนแก่น',
    zipcode: 40220,
  },
  {
    district: 'หนองนาคำ',
    province: 'ขอนแก่น',
    zipcode: 40150,
  },
  {
    district: 'หนองสองห้อง',
    province: 'ขอนแก่น',
    zipcode: 40190,
  },
  {
    district: 'หนองเรือ',
    province: 'ขอนแก่น',
    zipcode: 40210,
  },
  {
    district: 'หนองเรือ',
    province: 'ขอนแก่น',
    zipcode: 40240,
  },
  {
    district: 'อุบลรัตน์',
    province: 'ขอนแก่น',
    zipcode: 40250,
  },
  {
    district: 'เขาสวนกวาง',
    province: 'ขอนแก่น',
    zipcode: 40280,
  },
  {
    district: 'เปือยน้อย',
    province: 'ขอนแก่น',
    zipcode: 40340,
  },
  {
    district: 'เมืองขอนแก่น',
    province: 'ขอนแก่น',
    zipcode: 40000,
  },
  {
    district: 'เมืองขอนแก่น',
    province: 'ขอนแก่น',
    zipcode: 40260,
  },
  {
    district: 'เมืองขอนแก่น',
    province: 'ขอนแก่น',
    zipcode: 40002,
  },
  {
    district: 'แวงน้อย',
    province: 'ขอนแก่น',
    zipcode: 40230,
  },
  {
    district: 'แวงใหญ่',
    province: 'ขอนแก่น',
    zipcode: 40330,
  },
  {
    district: 'โคกโพธิ์ไชย',
    province: 'ขอนแก่น',
    zipcode: 40160,
  },
  {
    district: 'โนนศิลา',
    province: 'ขอนแก่น',
    zipcode: 40110,
  },
  {
    district: 'ขลุง',
    province: 'จันทบุรี',
    zipcode: 22110,
  },
  {
    district: 'ขลุง',
    province: 'จันทบุรี',
    zipcode: 22150,
  },
  {
    district: 'ท่าใหม่',
    province: 'จันทบุรี',
    zipcode: 22120,
  },
  {
    district: 'ท่าใหม่',
    province: 'จันทบุรี',
    zipcode: 22170,
  },
  {
    district: 'นายายอาม',
    province: 'จันทบุรี',
    zipcode: 22170,
  },
  {
    district: 'นายายอาม',
    province: 'จันทบุรี',
    zipcode: 22160,
  },
  {
    district: 'มะขาม',
    province: 'จันทบุรี',
    zipcode: 22150,
  },
  {
    district: 'สอยดาว',
    province: 'จันทบุรี',
    zipcode: 22180,
  },
  {
    district: 'เขาคิชฌกูฏ',
    province: 'จันทบุรี',
    zipcode: 22210,
  },
  {
    district: 'เมืองจันทบุรี',
    province: 'จันทบุรี',
    zipcode: 22000,
  },
  {
    district: 'แก่งหางแมว',
    province: 'จันทบุรี',
    zipcode: 22160,
  },
  {
    district: 'แหลมสิงห์',
    province: 'จันทบุรี',
    zipcode: 22190,
  },
  {
    district: 'แหลมสิงห์',
    province: 'จันทบุรี',
    zipcode: 22120,
  },
  {
    district: 'แหลมสิงห์',
    province: 'จันทบุรี',
    zipcode: 22130,
  },
  {
    district: 'โป่งน้ำร้อน',
    province: 'จันทบุรี',
    zipcode: 22140,
  },
  {
    district: 'คลองเขื่อน',
    province: 'ฉะเชิงเทรา',
    zipcode: 24000,
  },
  {
    district: 'คลองเขื่อน',
    province: 'ฉะเชิงเทรา',
    zipcode: 24110,
  },
  {
    district: 'ท่าตะเกียบ',
    province: 'ฉะเชิงเทรา',
    zipcode: 24160,
  },
  {
    district: 'บางคล้า',
    province: 'ฉะเชิงเทรา',
    zipcode: 24110,
  },
  {
    district: 'บางน้ำเปรี้ยว',
    province: 'ฉะเชิงเทรา',
    zipcode: 24170,
  },
  {
    district: 'บางน้ำเปรี้ยว',
    province: 'ฉะเชิงเทรา',
    zipcode: 24150,
  },
  {
    district: 'บางน้ำเปรี้ยว',
    province: 'ฉะเชิงเทรา',
    zipcode: 24000,
  },
  {
    district: 'บางปะกง',
    province: 'ฉะเชิงเทรา',
    zipcode: 24130,
  },
  {
    district: 'บางปะกง',
    province: 'ฉะเชิงเทรา',
    zipcode: 24180,
  },
  {
    district: 'บ้านโพธิ์',
    province: 'ฉะเชิงเทรา',
    zipcode: 24140,
  },
  {
    district: 'พนมสารคาม',
    province: 'ฉะเชิงเทรา',
    zipcode: 24120,
  },
  {
    district: 'ราชสาส์น',
    province: 'ฉะเชิงเทรา',
    zipcode: 24120,
  },
  {
    district: 'สนามชัยเขต',
    province: 'ฉะเชิงเทรา',
    zipcode: 24160,
  },
  {
    district: 'เมืองฉะเชิงเทรา',
    province: 'ฉะเชิงเทรา',
    zipcode: 24000,
  },
  {
    district: 'แปลงยาว',
    province: 'ฉะเชิงเทรา',
    zipcode: 24190,
  },
  {
    district: 'บางละมุง',
    province: 'ชลบุรี',
    zipcode: 20150,
  },
  {
    district: 'บ่อทอง',
    province: 'ชลบุรี',
    zipcode: 20270,
  },
  {
    district: 'บ้านบึง',
    province: 'ชลบุรี',
    zipcode: 20220,
  },
  {
    district: 'บ้านบึง',
    province: 'ชลบุรี',
    zipcode: 20170,
  },
  {
    district: 'พนัสนิคม',
    province: 'ชลบุรี',
    zipcode: 20140,
  },
  {
    district: 'พานทอง',
    province: 'ชลบุรี',
    zipcode: 20160,
  },
  {
    district: 'ศรีราชา',
    province: 'ชลบุรี',
    zipcode: 20230,
  },
  {
    district: 'ศรีราชา',
    province: 'ชลบุรี',
    zipcode: 20110,
  },
  {
    district: 'สัตหีบ',
    province: 'ชลบุรี',
    zipcode: 20250,
  },
  {
    district: 'สัตหีบ',
    province: 'ชลบุรี',
    zipcode: 20180,
  },
  {
    district: 'หนองใหญ่',
    province: 'ชลบุรี',
    zipcode: 20190,
  },
  {
    district: 'เกาะจันทร์',
    province: 'ชลบุรี',
    zipcode: 20240,
  },
  {
    district: 'เกาะสีชัง',
    province: 'ชลบุรี',
    zipcode: 20120,
  },
  {
    district: 'เมืองชลบุรี',
    province: 'ชลบุรี',
    zipcode: 20000,
  },
  {
    district: 'เมืองชลบุรี',
    province: 'ชลบุรี',
    zipcode: 20130,
  },
  {
    district: 'มโนรมย์',
    province: 'ชัยนาท',
    zipcode: 17110,
  },
  {
    district: 'มโนรมย์',
    province: 'ชัยนาท',
    zipcode: 17170,
  },
  {
    district: 'วัดสิงห์',
    province: 'ชัยนาท',
    zipcode: 17120,
  },
  {
    district: 'สรรคบุรี',
    province: 'ชัยนาท',
    zipcode: 17140,
  },
  {
    district: 'สรรพยา',
    province: 'ชัยนาท',
    zipcode: 17150,
  },
  {
    district: 'หนองมะโมง',
    province: 'ชัยนาท',
    zipcode: 17120,
  },
  {
    district: 'หันคา',
    province: 'ชัยนาท',
    zipcode: 17130,
  },
  {
    district: 'หันคา',
    province: 'ชัยนาท',
    zipcode: 17160,
  },
  {
    district: 'เนินขาม',
    province: 'ชัยนาท',
    zipcode: 17130,
  },
  {
    district: 'เมืองชัยนาท',
    province: 'ชัยนาท',
    zipcode: 17000,
  },
  {
    district: 'เมืองชัยนาท',
    province: 'ชัยนาท',
    zipcode: 17120,
  },
  {
    district: 'คอนสวรรค์',
    province: 'ชัยภูมิ',
    zipcode: 36140,
  },
  {
    district: 'คอนสาร',
    province: 'ชัยภูมิ',
    zipcode: 36180,
  },
  {
    district: 'จัตุรัส',
    province: 'ชัยภูมิ',
    zipcode: 36130,
  },
  {
    district: 'จัตุรัส',
    province: 'ชัยภูมิ',
    zipcode: 36220,
  },
  {
    district: 'ซับใหญ่',
    province: 'ชัยภูมิ',
    zipcode: 36130,
  },
  {
    district: 'บำเหน็จณรงค์',
    province: 'ชัยภูมิ',
    zipcode: 36160,
  },
  {
    district: 'บำเหน็จณรงค์',
    province: 'ชัยภูมิ',
    zipcode: 36220,
  },
  {
    district: 'บ้านเขว้า',
    province: 'ชัยภูมิ',
    zipcode: 36170,
  },
  {
    district: 'บ้านแท่น',
    province: 'ชัยภูมิ',
    zipcode: 36190,
  },
  {
    district: 'ภักดีชุมพล',
    province: 'ชัยภูมิ',
    zipcode: 36260,
  },
  {
    district: 'ภูเขียว',
    province: 'ชัยภูมิ',
    zipcode: 36110,
  },
  {
    district: 'หนองบัวระเหว',
    province: 'ชัยภูมิ',
    zipcode: 36250,
  },
  {
    district: 'หนองบัวแดง',
    province: 'ชัยภูมิ',
    zipcode: 36210,
  },
  {
    district: 'เกษตรสมบูรณ์',
    province: 'ชัยภูมิ',
    zipcode: 36120,
  },
  {
    district: 'เทพสถิต',
    province: 'ชัยภูมิ',
    zipcode: 36230,
  },
  {
    district: 'เนินสง่า',
    province: 'ชัยภูมิ',
    zipcode: 36130,
  },
  {
    district: 'เมืองชัยภูมิ',
    province: 'ชัยภูมิ',
    zipcode: 36000,
  },
  {
    district: 'เมืองชัยภูมิ',
    province: 'ชัยภูมิ',
    zipcode: 36240,
  },
  {
    district: 'แก้งคร้อ',
    province: 'ชัยภูมิ',
    zipcode: 36150,
  },
  {
    district: 'ทุ่งตะโก',
    province: 'ชุมพร',
    zipcode: 86220,
  },
  {
    district: 'ท่าแซะ',
    province: 'ชุมพร',
    zipcode: 86140,
  },
  {
    district: 'ท่าแซะ',
    province: 'ชุมพร',
    zipcode: 86190,
  },
  {
    district: 'ปะทิว',
    province: 'ชุมพร',
    zipcode: 86160,
  },
  {
    district: 'ปะทิว',
    province: 'ชุมพร',
    zipcode: 86210,
  },
  {
    district: 'ปะทิว',
    province: 'ชุมพร',
    zipcode: 86230,
  },
  {
    district: 'พะโต๊ะ',
    province: 'ชุมพร',
    zipcode: 86180,
  },
  {
    district: 'ละแม',
    province: 'ชุมพร',
    zipcode: 86170,
  },
  {
    district: 'สวี',
    province: 'ชุมพร',
    zipcode: 86130,
  },
  {
    district: 'หลังสวน',
    province: 'ชุมพร',
    zipcode: 86110,
  },
  {
    district: 'หลังสวน',
    province: 'ชุมพร',
    zipcode: 86150,
  },
  {
    district: 'เมืองชุมพร',
    province: 'ชุมพร',
    zipcode: 86190,
  },
  {
    district: 'เมืองชุมพร',
    province: 'ชุมพร',
    zipcode: 86000,
  },
  {
    district: 'เมืองชุมพร',
    province: 'ชุมพร',
    zipcode: 86100,
  },
  {
    district: 'เมืองชุมพร',
    province: 'ชุมพร',
    zipcode: 86120,
  },
  {
    district: 'กันตัง',
    province: 'ตรัง',
    zipcode: 92110,
  },
  {
    district: 'นาโยง',
    province: 'ตรัง',
    zipcode: 92170,
  },
  {
    district: 'ปะเหลียน',
    province: 'ตรัง',
    zipcode: 92180,
  },
  {
    district: 'ปะเหลียน',
    province: 'ตรัง',
    zipcode: 92120,
  },
  {
    district: 'ปะเหลียน',
    province: 'ตรัง',
    zipcode: 92140,
  },
  {
    district: 'ย่านตาขาว',
    province: 'ตรัง',
    zipcode: 92140,
  },
  {
    district: 'รัษฎา',
    province: 'ตรัง',
    zipcode: 92160,
  },
  {
    district: 'รัษฎา',
    province: 'ตรัง',
    zipcode: 92130,
  },
  {
    district: 'วังวิเศษ',
    province: 'ตรัง',
    zipcode: 92000,
  },
  {
    district: 'วังวิเศษ',
    province: 'ตรัง',
    zipcode: 92220,
  },
  {
    district: 'สิเกา',
    province: 'ตรัง',
    zipcode: 92150,
  },
  {
    district: 'สิเกา',
    province: 'ตรัง',
    zipcode: 92000,
  },
  {
    district: 'หาดสำราญ',
    province: 'ตรัง',
    zipcode: 92120,
  },
  {
    district: 'ห้วยยอด',
    province: 'ตรัง',
    zipcode: 92130,
  },
  {
    district: 'ห้วยยอด',
    province: 'ตรัง',
    zipcode: 92210,
  },
  {
    district: 'ห้วยยอด',
    province: 'ตรัง',
    zipcode: 92190,
  },
  {
    district: 'เมืองตรัง',
    province: 'ตรัง',
    zipcode: 92000,
  },
  {
    district: 'เมืองตรัง',
    province: 'ตรัง',
    zipcode: 92190,
  },
  {
    district: 'เมืองตรัง',
    province: 'ตรัง',
    zipcode: 92170,
  },
  {
    district: 'คลองใหญ่',
    province: 'ตราด',
    zipcode: 23110,
  },
  {
    district: 'บ่อไร่',
    province: 'ตราด',
    zipcode: 23140,
  },
  {
    district: 'เกาะกูด',
    province: 'ตราด',
    zipcode: 23000,
  },
  {
    district: 'เกาะช้าง',
    province: 'ตราด',
    zipcode: 23170,
  },
  {
    district: 'เขาสมิง',
    province: 'ตราด',
    zipcode: 23130,
  },
  {
    district: 'เขาสมิง',
    province: 'ตราด',
    zipcode: 23150,
  },
  {
    district: 'เมืองตราด',
    province: 'ตราด',
    zipcode: 23000,
  },
  {
    district: 'แหลมงอบ',
    province: 'ตราด',
    zipcode: 23120,
  },
  {
    district: 'ท่าสองยาง',
    province: 'ตาก',
    zipcode: 63150,
  },
  {
    district: 'บ้านตาก',
    province: 'ตาก',
    zipcode: 63120,
  },
  {
    district: 'พบพระ',
    province: 'ตาก',
    zipcode: 63160,
  },
  {
    district: 'วังเจ้า',
    province: 'ตาก',
    zipcode: 63180,
  },
  {
    district: 'สามเงา',
    province: 'ตาก',
    zipcode: 63130,
  },
  {
    district: 'อุ้มผาง',
    province: 'ตาก',
    zipcode: 63170,
  },
  {
    district: 'เมืองตาก',
    province: 'ตาก',
    zipcode: 63000,
  },
  {
    district: 'แม่ระมาด',
    province: 'ตาก',
    zipcode: 63140,
  },
  {
    district: 'แม่สอด',
    province: 'ตาก',
    zipcode: 63110,
  },
  {
    district: 'บ้านนา',
    province: 'นครนายก',
    zipcode: 26110,
  },
  {
    district: 'ปากพลี',
    province: 'นครนายก',
    zipcode: 26130,
  },
  {
    district: 'องครักษ์',
    province: 'นครนายก',
    zipcode: 26120,
  },
  {
    district: 'เมืองนครนายก',
    province: 'นครนายก',
    zipcode: 26000,
  },
  {
    district: 'กำแพงแสน',
    province: 'นครปฐม',
    zipcode: 73180,
  },
  {
    district: 'กำแพงแสน',
    province: 'นครปฐม',
    zipcode: 73140,
  },
  {
    district: 'ดอนตูม',
    province: 'นครปฐม',
    zipcode: 73150,
  },
  {
    district: 'นครชัยศรี',
    province: 'นครปฐม',
    zipcode: 73120,
  },
  {
    district: 'บางเลน',
    province: 'นครปฐม',
    zipcode: 73130,
  },
  {
    district: 'บางเลน',
    province: 'นครปฐม',
    zipcode: 73190,
  },
  {
    district: 'พุทธมณฑล',
    province: 'นครปฐม',
    zipcode: 73170,
  },
  {
    district: 'สามพราน',
    province: 'นครปฐม',
    zipcode: 73220,
  },
  {
    district: 'สามพราน',
    province: 'นครปฐม',
    zipcode: 73110,
  },
  {
    district: 'สามพราน',
    province: 'นครปฐม',
    zipcode: 73210,
  },
  {
    district: 'สามพราน',
    province: 'นครปฐม',
    zipcode: 73160,
  },
  {
    district: 'เมืองนครปฐม',
    province: 'นครปฐม',
    zipcode: 73000,
  },
  {
    district: 'ท่าอุเทน',
    province: 'นครพนม',
    zipcode: 48120,
  },
  {
    district: 'ธาตุพนม',
    province: 'นครพนม',
    zipcode: 48110,
  },
  {
    district: 'นาทม',
    province: 'นครพนม',
    zipcode: 48140,
  },
  {
    district: 'นาหว้า',
    province: 'นครพนม',
    zipcode: 48180,
  },
  {
    district: 'นาแก',
    province: 'นครพนม',
    zipcode: 48130,
  },
  {
    district: 'บ้านแพง',
    province: 'นครพนม',
    zipcode: 48140,
  },
  {
    district: 'ปลาปาก',
    province: 'นครพนม',
    zipcode: 48160,
  },
  {
    district: 'วังยาง',
    province: 'นครพนม',
    zipcode: 48130,
  },
  {
    district: 'ศรีสงคราม',
    province: 'นครพนม',
    zipcode: 48150,
  },
  {
    district: 'เมืองนครพนม',
    province: 'นครพนม',
    zipcode: 48000,
  },
  {
    district: 'เรณูนคร',
    province: 'นครพนม',
    zipcode: 48170,
  },
  {
    district: 'โพนสวรรค์',
    province: 'นครพนม',
    zipcode: 48190,
  },
  {
    district: 'ขามทะเลสอ',
    province: 'นครราชสีมา',
    zipcode: 30280,
  },
  {
    district: 'ขามสะแกแสง',
    province: 'นครราชสีมา',
    zipcode: 30290,
  },
  {
    district: 'คง',
    province: 'นครราชสีมา',
    zipcode: 30260,
  },
  {
    district: 'ครบุรี',
    province: 'นครราชสีมา',
    zipcode: 30250,
  },
  {
    district: 'จักราช',
    province: 'นครราชสีมา',
    zipcode: 30230,
  },
  {
    district: 'ชุมพวง',
    province: 'นครราชสีมา',
    zipcode: 30270,
  },
  {
    district: 'ด่านขุนทด',
    province: 'นครราชสีมา',
    zipcode: 30210,
  },
  {
    district: 'ด่านขุนทด',
    province: 'นครราชสีมา',
    zipcode: 36220,
  },
  {
    district: 'บัวลาย',
    province: 'นครราชสีมา',
    zipcode: 30120,
  },
  {
    district: 'บัวใหญ่',
    province: 'นครราชสีมา',
    zipcode: 30120,
  },
  {
    district: 'บ้านเหลื่อม',
    province: 'นครราชสีมา',
    zipcode: 30350,
  },
  {
    district: 'ประทาย',
    province: 'นครราชสีมา',
    zipcode: 30180,
  },
  {
    district: 'ปักธงชัย',
    province: 'นครราชสีมา',
    zipcode: 30150,
  },
  {
    district: 'ปากช่อง',
    province: 'นครราชสีมา',
    zipcode: 30320,
  },
  {
    district: 'ปากช่อง',
    province: 'นครราชสีมา',
    zipcode: 30130,
  },
  {
    district: 'พระทองคำ',
    province: 'นครราชสีมา',
    zipcode: 30220,
  },
  {
    district: 'พิมาย',
    province: 'นครราชสีมา',
    zipcode: 30110,
  },
  {
    district: 'ลำทะเมนชัย',
    province: 'นครราชสีมา',
    zipcode: 30270,
  },
  {
    district: 'วังน้ำเขียว',
    province: 'นครราชสีมา',
    zipcode: 30150,
  },
  {
    district: 'วังน้ำเขียว',
    province: 'นครราชสีมา',
    zipcode: 30370,
  },
  {
    district: 'สีคิ้ว',
    province: 'นครราชสีมา',
    zipcode: 30140,
  },
  {
    district: 'สีคิ้ว',
    province: 'นครราชสีมา',
    zipcode: 30340,
  },
  {
    district: 'สีดา',
    province: 'นครราชสีมา',
    zipcode: 30430,
  },
  {
    district: 'สูงเนิน',
    province: 'นครราชสีมา',
    zipcode: 30380,
  },
  {
    district: 'สูงเนิน',
    province: 'นครราชสีมา',
    zipcode: 30170,
  },
  {
    district: 'หนองบุญมาก',
    province: 'นครราชสีมา',
    zipcode: 30410,
  },
  {
    district: 'ห้วยแถลง',
    province: 'นครราชสีมา',
    zipcode: 30240,
  },
  {
    district: 'เฉลิมพระเกียรติ',
    province: 'นครราชสีมา',
    zipcode: 30230,
  },
  {
    district: 'เฉลิมพระเกียรติ',
    province: 'นครราชสีมา',
    zipcode: 30000,
  },
  {
    district: 'เทพารักษ์',
    province: 'นครราชสีมา',
    zipcode: 30210,
  },
  {
    district: 'เมืองนครราชสีมา',
    province: 'นครราชสีมา',
    zipcode: 30310,
  },
  {
    district: 'เมืองนครราชสีมา',
    province: 'นครราชสีมา',
    zipcode: 30000,
  },
  {
    district: 'เมืองนครราชสีมา',
    province: 'นครราชสีมา',
    zipcode: 30280,
  },
  {
    district: 'เมืองยาง',
    province: 'นครราชสีมา',
    zipcode: 30270,
  },
  {
    district: 'เสิงสาง',
    province: 'นครราชสีมา',
    zipcode: 30330,
  },
  {
    district: 'แก้งสนามนาง',
    province: 'นครราชสีมา',
    zipcode: 30440,
  },
  {
    district: 'โชคชัย',
    province: 'นครราชสีมา',
    zipcode: 30190,
  },
  {
    district: 'โนนสูง',
    province: 'นครราชสีมา',
    zipcode: 30160,
  },
  {
    district: 'โนนสูง',
    province: 'นครราชสีมา',
    zipcode: 30420,
  },
  {
    district: 'โนนแดง',
    province: 'นครราชสีมา',
    zipcode: 30360,
  },
  {
    district: 'โนนไทย',
    province: 'นครราชสีมา',
    zipcode: 30220,
  },
  {
    district: 'ขนอม',
    province: 'นครศรีธรรมราช',
    zipcode: 80210,
  },
  {
    district: 'จุฬาภรณ์',
    province: 'นครศรีธรรมราช',
    zipcode: 80130,
  },
  {
    district: 'จุฬาภรณ์',
    province: 'นครศรีธรรมราช',
    zipcode: 80180,
  },
  {
    district: 'ฉวาง',
    province: 'นครศรีธรรมราช',
    zipcode: 80260,
  },
  {
    district: 'ฉวาง',
    province: 'นครศรีธรรมราช',
    zipcode: 80250,
  },
  {
    district: 'ฉวาง',
    province: 'นครศรีธรรมราช',
    zipcode: 80150,
  },
  {
    district: 'ชะอวด',
    province: 'นครศรีธรรมราช',
    zipcode: 80180,
  },
  {
    district: 'ช้างกลาง',
    province: 'นครศรีธรรมราช',
    zipcode: 80250,
  },
  {
    district: 'ถ้ำพรรณรา',
    province: 'นครศรีธรรมราช',
    zipcode: 80260,
  },
  {
    district: 'ทุ่งสง',
    province: 'นครศรีธรรมราช',
    zipcode: 80310,
  },
  {
    district: 'ทุ่งสง',
    province: 'นครศรีธรรมราช',
    zipcode: 80110,
  },
  {
    district: 'ทุ่งใหญ่',
    province: 'นครศรีธรรมราช',
    zipcode: 80240,
  },
  {
    district: 'ท่าศาลา',
    province: 'นครศรีธรรมราช',
    zipcode: 80160,
  },
  {
    district: 'นบพิตำ',
    province: 'นครศรีธรรมราช',
    zipcode: 80160,
  },
  {
    district: 'นาบอน',
    province: 'นครศรีธรรมราช',
    zipcode: 80220,
  },
  {
    district: 'บางขัน',
    province: 'นครศรีธรรมราช',
    zipcode: 80360,
  },
  {
    district: 'ปากพนัง',
    province: 'นครศรีธรรมราช',
    zipcode: 80140,
  },
  {
    district: 'ปากพนัง',
    province: 'นครศรีธรรมราช',
    zipcode: 80370,
  },
  {
    district: 'ปากพนัง',
    province: 'นครศรีธรรมราช',
    zipcode: 80330,
  },
  {
    district: 'พรหมคีรี',
    province: 'นครศรีธรรมราช',
    zipcode: 80320,
  },
  {
    district: 'พระพรหม',
    province: 'นครศรีธรรมราช',
    zipcode: 80000,
  },
  {
    district: 'พิปูน',
    province: 'นครศรีธรรมราช',
    zipcode: 80270,
  },
  {
    district: 'ร่อนพิบูลย์',
    province: 'นครศรีธรรมราช',
    zipcode: 80130,
  },
  {
    district: 'ร่อนพิบูลย์',
    province: 'นครศรีธรรมราช',
    zipcode: 80350,
  },
  {
    district: 'ลานสกา',
    province: 'นครศรีธรรมราช',
    zipcode: 80230,
  },
  {
    district: 'สิชล',
    province: 'นครศรีธรรมราช',
    zipcode: 80120,
  },
  {
    district: 'สิชล',
    province: 'นครศรีธรรมราช',
    zipcode: 80340,
  },
  {
    district: 'หัวไทร',
    province: 'นครศรีธรรมราช',
    zipcode: 80170,
  },
  {
    district: 'เฉลิมพระเกียรติ',
    province: 'นครศรีธรรมราช',
    zipcode: 80290,
  },
  {
    district: 'เฉลิมพระเกียรติ',
    province: 'นครศรีธรรมราช',
    zipcode: 80190,
  },
  {
    district: 'เชียรใหญ่',
    province: 'นครศรีธรรมราช',
    zipcode: 80190,
  },
  {
    district: 'เมืองนครศรีธรรมราช',
    province: 'นครศรีธรรมราช',
    zipcode: 80280,
  },
  {
    district: 'เมืองนครศรีธรรมราช',
    province: 'นครศรีธรรมราช',
    zipcode: 80000,
  },
  {
    district: 'เมืองนครศรีธรรมราช',
    province: 'นครศรีธรรมราช',
    zipcode: 80330,
  },
  {
    district: 'ชุมตาบง',
    province: 'นครสวรรค์',
    zipcode: 60150,
  },
  {
    district: 'ชุมแสง',
    province: 'นครสวรรค์',
    zipcode: 60120,
  },
  {
    district: 'ชุมแสง',
    province: 'นครสวรรค์',
    zipcode: 60250,
  },
  {
    district: 'ตากฟ้า',
    province: 'นครสวรรค์',
    zipcode: 60190,
  },
  {
    district: 'ตาคลี',
    province: 'นครสวรรค์',
    zipcode: 60260,
  },
  {
    district: 'ตาคลี',
    province: 'นครสวรรค์',
    zipcode: 60210,
  },
  {
    district: 'ตาคลี',
    province: 'นครสวรรค์',
    zipcode: 60140,
  },
  {
    district: 'ท่าตะโก',
    province: 'นครสวรรค์',
    zipcode: 60160,
  },
  {
    district: 'บรรพตพิสัย',
    province: 'นครสวรรค์',
    zipcode: 60180,
  },
  {
    district: 'พยุหะคีรี',
    province: 'นครสวรรค์',
    zipcode: 60130,
  },
  {
    district: 'ลาดยาว',
    province: 'นครสวรรค์',
    zipcode: 60150,
  },
  {
    district: 'หนองบัว',
    province: 'นครสวรรค์',
    zipcode: 60110,
  },
  {
    district: 'เก้าเลี้ยว',
    province: 'นครสวรรค์',
    zipcode: 60230,
  },
  {
    district: 'เมืองนครสวรรค์',
    province: 'นครสวรรค์',
    zipcode: 60000,
  },
  {
    district: 'เมืองนครสวรรค์',
    province: 'นครสวรรค์',
    zipcode: 60240,
  },
  {
    district: 'แม่วงก์',
    province: 'นครสวรรค์',
    zipcode: 60150,
  },
  {
    district: 'แม่เปิน',
    province: 'นครสวรรค์',
    zipcode: 60150,
  },
  {
    district: 'โกรกพระ',
    province: 'นครสวรรค์',
    zipcode: 60170,
  },
  {
    district: 'ไพศาลี',
    province: 'นครสวรรค์',
    zipcode: 60220,
  },
  {
    district: 'บางกรวย',
    province: 'นนทบุรี',
    zipcode: 11130,
  },
  {
    district: 'บางบัวทอง',
    province: 'นนทบุรี',
    zipcode: 11110,
  },
  {
    district: 'บางใหญ่',
    province: 'นนทบุรี',
    zipcode: 11140,
  },
  {
    district: 'ปากเกร็ด',
    province: 'นนทบุรี',
    zipcode: 11120,
  },
  {
    district: 'เมืองนนทบุรี',
    province: 'นนทบุรี',
    zipcode: 11000,
  },
  {
    district: 'ไทรน้อย',
    province: 'นนทบุรี',
    zipcode: 11150,
  },
  {
    district: 'จะแนะ',
    province: 'นราธิวาส',
    zipcode: 96220,
  },
  {
    district: 'ตากใบ',
    province: 'นราธิวาส',
    zipcode: 96110,
  },
  {
    district: 'บาเจาะ',
    province: 'นราธิวาส',
    zipcode: 96170,
  },
  {
    district: 'ยี่งอ',
    province: 'นราธิวาส',
    zipcode: 96180,
  },
  {
    district: 'ระแงะ',
    province: 'นราธิวาส',
    zipcode: 96130,
  },
  {
    district: 'ระแงะ',
    province: 'นราธิวาส',
    zipcode: 96220,
  },
  {
    district: 'รือเสาะ',
    province: 'นราธิวาส',
    zipcode: 96150,
  },
  {
    district: 'ศรีสาคร',
    province: 'นราธิวาส',
    zipcode: 96210,
  },
  {
    district: 'สุคิริน',
    province: 'นราธิวาส',
    zipcode: 96190,
  },
  {
    district: 'สุไหงปาดี',
    province: 'นราธิวาส',
    zipcode: 96140,
  },
  {
    district: 'สุไหงโก-ลก',
    province: 'นราธิวาส',
    zipcode: 96120,
  },
  {
    district: 'เจาะไอร้อง',
    province: 'นราธิวาส',
    zipcode: 96130,
  },
  {
    district: 'เมืองนราธิวาส',
    province: 'นราธิวาส',
    zipcode: 96000,
  },
  {
    district: 'แว้ง',
    province: 'นราธิวาส',
    zipcode: 96160,
  },
  {
    district: 'ทุ่งช้าง',
    province: 'น่าน',
    zipcode: 55130,
  },
  {
    district: 'ท่าวังผา',
    province: 'น่าน',
    zipcode: 55140,
  },
  {
    district: 'นาน้อย',
    province: 'น่าน',
    zipcode: 55150,
  },
  {
    district: 'นาหมื่น',
    province: 'น่าน',
    zipcode: 55180,
  },
  {
    district: 'บ่อเกลือ',
    province: 'น่าน',
    zipcode: 55220,
  },
  {
    district: 'บ้านหลวง',
    province: 'น่าน',
    zipcode: 55190,
  },
  {
    district: 'ปัว',
    province: 'น่าน',
    zipcode: 55120,
  },
  {
    district: 'ภูเพียง',
    province: 'น่าน',
    zipcode: 55000,
  },
  {
    district: 'สองแคว',
    province: 'น่าน',
    zipcode: 55160,
  },
  {
    district: 'สันติสุข',
    province: 'น่าน',
    zipcode: 55210,
  },
  {
    district: 'เฉลิมพระเกียรติ',
    province: 'น่าน',
    zipcode: 55130,
  },
  {
    district: 'เชียงกลาง',
    province: 'น่าน',
    zipcode: 55160,
  },
  {
    district: 'เมืองน่าน',
    province: 'น่าน',
    zipcode: 55000,
  },
  {
    district: 'เวียงสา',
    province: 'น่าน',
    zipcode: 55110,
  },
  {
    district: 'แม่จริม',
    province: 'น่าน',
    zipcode: 55170,
  },
  {
    district: 'บึงโขงหลง',
    province: 'บึงกาฬ',
    zipcode: 38220,
  },
  {
    district: 'บุ่งคล้า',
    province: 'บึงกาฬ',
    zipcode: 38000,
  },
  {
    district: 'ปากคาด',
    province: 'บึงกาฬ',
    zipcode: 38190,
  },
  {
    district: 'พรเจริญ',
    province: 'บึงกาฬ',
    zipcode: 38180,
  },
  {
    district: 'ศรีวิไล',
    province: 'บึงกาฬ',
    zipcode: 38210,
  },
  {
    district: 'เซกา',
    province: 'บึงกาฬ',
    zipcode: 38150,
  },
  {
    district: 'เมืองบึงกาฬ',
    province: 'บึงกาฬ',
    zipcode: 38000,
  },
  {
    district: 'โซ่พิสัย',
    province: 'บึงกาฬ',
    zipcode: 38170,
  },
  {
    district: 'กระสัง',
    province: 'บุรีรัมย์',
    zipcode: 31160,
  },
  {
    district: 'คูเมือง',
    province: 'บุรีรัมย์',
    zipcode: 31190,
  },
  {
    district: 'ชำนิ',
    province: 'บุรีรัมย์',
    zipcode: 31110,
  },
  {
    district: 'นางรอง',
    province: 'บุรีรัมย์',
    zipcode: 31110,
  },
  {
    district: 'นาโพธิ์',
    province: 'บุรีรัมย์',
    zipcode: 31230,
  },
  {
    district: 'บ้านกรวด',
    province: 'บุรีรัมย์',
    zipcode: 31180,
  },
  {
    district: 'บ้านด่าน',
    province: 'บุรีรัมย์',
    zipcode: 31000,
  },
  {
    district: 'บ้านใหม่ไชยพจน์',
    province: 'บุรีรัมย์',
    zipcode: 31120,
  },
  {
    district: 'ประโคนชัย',
    province: 'บุรีรัมย์',
    zipcode: 31140,
  },
  {
    district: 'ปะคำ',
    province: 'บุรีรัมย์',
    zipcode: 31220,
  },
  {
    district: 'พลับพลาชัย',
    province: 'บุรีรัมย์',
    zipcode: 31250,
  },
  {
    district: 'พุทไธสง',
    province: 'บุรีรัมย์',
    zipcode: 31120,
  },
  {
    district: 'ละหานทราย',
    province: 'บุรีรัมย์',
    zipcode: 31170,
  },
  {
    district: 'ลำปลายมาศ',
    province: 'บุรีรัมย์',
    zipcode: 31130,
  },
  {
    district: 'สตึก',
    province: 'บุรีรัมย์',
    zipcode: 31150,
  },
  {
    district: 'หนองกี่',
    province: 'บุรีรัมย์',
    zipcode: 31210,
  },
  {
    district: 'หนองหงส์',
    province: 'บุรีรัมย์',
    zipcode: 31240,
  },
  {
    district: 'ห้วยราช',
    province: 'บุรีรัมย์',
    zipcode: 31000,
  },
  {
    district: 'เฉลิมพระเกียรติ',
    province: 'บุรีรัมย์',
    zipcode: 31110,
  },
  {
    district: 'เฉลิมพระเกียรติ',
    province: 'บุรีรัมย์',
    zipcode: 31170,
  },
  {
    district: 'เมืองบุรีรัมย์',
    province: 'บุรีรัมย์',
    zipcode: 31000,
  },
  {
    district: 'แคนดง',
    province: 'บุรีรัมย์',
    zipcode: 31150,
  },
  {
    district: 'โนนดินแดง',
    province: 'บุรีรัมย์',
    zipcode: 31260,
  },
  {
    district: 'โนนสุวรรณ',
    province: 'บุรีรัมย์',
    zipcode: 31110,
  },
  {
    district: 'คลองหลวง',
    province: 'ปทุมธานี',
    zipcode: 12120,
  },
  {
    district: 'ธัญบุรี',
    province: 'ปทุมธานี',
    zipcode: 12110,
  },
  {
    district: 'ธัญบุรี',
    province: 'ปทุมธานี',
    zipcode: 12130,
  },
  {
    district: 'ลาดหลุมแก้ว',
    province: 'ปทุมธานี',
    zipcode: 12140,
  },
  {
    district: 'ลำลูกกา',
    province: 'ปทุมธานี',
    zipcode: 12130,
  },
  {
    district: 'ลำลูกกา',
    province: 'ปทุมธานี',
    zipcode: 12150,
  },
  {
    district: 'สามโคก',
    province: 'ปทุมธานี',
    zipcode: 12160,
  },
  {
    district: 'หนองเสือ',
    province: 'ปทุมธานี',
    zipcode: 12170,
  },
  {
    district: 'เมืองปทุมธานี',
    province: 'ปทุมธานี',
    zipcode: 12000,
  },
  {
    district: 'กุยบุรี',
    province: 'ประจวบคีรีขันธ์',
    zipcode: 77150,
  },
  {
    district: 'ทับสะแก',
    province: 'ประจวบคีรีขันธ์',
    zipcode: 77130,
  },
  {
    district: 'บางสะพาน',
    province: 'ประจวบคีรีขันธ์',
    zipcode: 77140,
  },
  {
    district: 'บางสะพาน',
    province: 'ประจวบคีรีขันธ์',
    zipcode: 77190,
  },
  {
    district: 'บางสะพาน',
    province: 'ประจวบคีรีขันธ์',
    zipcode: 77230,
  },
  {
    district: 'บางสะพานน้อย',
    province: 'ประจวบคีรีขันธ์',
    zipcode: 77170,
  },
  {
    district: 'ปราณบุรี',
    province: 'ประจวบคีรีขันธ์',
    zipcode: 77120,
  },
  {
    district: 'ปราณบุรี',
    province: 'ประจวบคีรีขันธ์',
    zipcode: 77160,
  },
  {
    district: 'ปราณบุรี',
    province: 'ประจวบคีรีขันธ์',
    zipcode: 77220,
  },
  {
    district: 'สามร้อยยอด',
    province: 'ประจวบคีรีขันธ์',
    zipcode: 77180,
  },
  {
    district: 'สามร้อยยอด',
    province: 'ประจวบคีรีขันธ์',
    zipcode: 77120,
  },
  {
    district: 'สามร้อยยอด',
    province: 'ประจวบคีรีขันธ์',
    zipcode: 77160,
  },
  {
    district: 'หัวหิน',
    province: 'ประจวบคีรีขันธ์',
    zipcode: 77110,
  },
  {
    district: 'เมืองประจวบคีรีขันธ์',
    province: 'ประจวบคีรีขันธ์',
    zipcode: 77000,
  },
  {
    district: 'เมืองประจวบคีรีขันธ์',
    province: 'ประจวบคีรีขันธ์',
    zipcode: 77210,
  },
  {
    district: 'กบินทร์บุรี',
    province: 'ปราจีนบุรี',
    zipcode: 25110,
  },
  {
    district: 'กบินทร์บุรี',
    province: 'ปราจีนบุรี',
    zipcode: 25240,
  },
  {
    district: 'นาดี',
    province: 'ปราจีนบุรี',
    zipcode: 25220,
  },
  {
    district: 'บ้านสร้าง',
    province: 'ปราจีนบุรี',
    zipcode: 25150,
  },
  {
    district: 'ประจันตคาม',
    province: 'ปราจีนบุรี',
    zipcode: 25130,
  },
  {
    district: 'ศรีมหาโพธิ',
    province: 'ปราจีนบุรี',
    zipcode: 25140,
  },
  {
    district: 'ศรีมโหสถ',
    province: 'ปราจีนบุรี',
    zipcode: 25190,
  },
  {
    district: 'เมืองปราจีนบุรี',
    province: 'ปราจีนบุรี',
    zipcode: 25000,
  },
  {
    district: 'เมืองปราจีนบุรี',
    province: 'ปราจีนบุรี',
    zipcode: 25230,
  },
  {
    district: 'กะพ้อ',
    province: 'ปัตตานี',
    zipcode: 94230,
  },
  {
    district: 'ทุ่งยางแดง',
    province: 'ปัตตานี',
    zipcode: 94140,
  },
  {
    district: 'ปะนาเระ',
    province: 'ปัตตานี',
    zipcode: 94190,
  },
  {
    district: 'ปะนาเระ',
    province: 'ปัตตานี',
    zipcode: 94130,
  },
  {
    district: 'มายอ',
    province: 'ปัตตานี',
    zipcode: 94190,
  },
  {
    district: 'มายอ',
    province: 'ปัตตานี',
    zipcode: 94140,
  },
  {
    district: 'ยะรัง',
    province: 'ปัตตานี',
    zipcode: 94160,
  },
  {
    district: 'ยะหริ่ง',
    province: 'ปัตตานี',
    zipcode: 94150,
  },
  {
    district: 'ยะหริ่ง',
    province: 'ปัตตานี',
    zipcode: 94190,
  },
  {
    district: 'สายบุรี',
    province: 'ปัตตานี',
    zipcode: 94110,
  },
  {
    district: 'สายบุรี',
    province: 'ปัตตานี',
    zipcode: 94190,
  },
  {
    district: 'หนองจิก',
    province: 'ปัตตานี',
    zipcode: 94170,
  },
  {
    district: 'เมืองปัตตานี',
    province: 'ปัตตานี',
    zipcode: 94000,
  },
  {
    district: 'แม่ลาน',
    province: 'ปัตตานี',
    zipcode: 94180,
  },
  {
    district: 'โคกโพธิ์',
    province: 'ปัตตานี',
    zipcode: 94180,
  },
  {
    district: 'โคกโพธิ์',
    province: 'ปัตตานี',
    zipcode: 94120,
  },
  {
    district: 'ไม้แก่น',
    province: 'ปัตตานี',
    zipcode: 94220,
  },
  {
    district: 'ท่าเรือ',
    province: 'พระนครศรีอยุธยา',
    zipcode: 13130,
  },
  {
    district: 'ท่าเรือ',
    province: 'พระนครศรีอยุธยา',
    zipcode: 18270,
  },
  {
    district: 'นครหลวง',
    province: 'พระนครศรีอยุธยา',
    zipcode: 13260,
  },
  {
    district: 'บางซ้าย',
    province: 'พระนครศรีอยุธยา',
    zipcode: 13270,
  },
  {
    district: 'บางบาล',
    province: 'พระนครศรีอยุธยา',
    zipcode: 13250,
  },
  {
    district: 'บางปะหัน',
    province: 'พระนครศรีอยุธยา',
    zipcode: 13220,
  },
  {
    district: 'บางปะอิน',
    province: 'พระนครศรีอยุธยา',
    zipcode: 13160,
  },
  {
    district: 'บางปะอิน',
    province: 'พระนครศรีอยุธยา',
    zipcode: 13170,
  },
  {
    district: 'บางปะอิน',
    province: 'พระนครศรีอยุธยา',
    zipcode: 13180,
  },
  {
    district: 'บางไทร',
    province: 'พระนครศรีอยุธยา',
    zipcode: 13190,
  },
  {
    district: 'บางไทร',
    province: 'พระนครศรีอยุธยา',
    zipcode: 13290,
  },
  {
    district: 'บ้านแพรก',
    province: 'พระนครศรีอยุธยา',
    zipcode: 13240,
  },
  {
    district: 'ผักไห่',
    province: 'พระนครศรีอยุธยา',
    zipcode: 13120,
  },
  {
    district: 'ผักไห่',
    province: 'พระนครศรีอยุธยา',
    zipcode: 13280,
  },
  {
    district: 'พระนครศรีอยุธยา',
    province: 'พระนครศรีอยุธยา',
    zipcode: 13000,
  },
  {
    district: 'ภาชี',
    province: 'พระนครศรีอยุธยา',
    zipcode: 13140,
  },
  {
    district: 'มหาราช',
    province: 'พระนครศรีอยุธยา',
    zipcode: 13150,
  },
  {
    district: 'ลาดบัวหลวง',
    province: 'พระนครศรีอยุธยา',
    zipcode: 13230,
  },
  {
    district: 'วังน้อย',
    province: 'พระนครศรีอยุธยา',
    zipcode: 13170,
  },
  {
    district: 'อุทัย',
    province: 'พระนครศรีอยุธยา',
    zipcode: 13210,
  },
  {
    district: 'เสนา',
    province: 'พระนครศรีอยุธยา',
    zipcode: 13110,
  },
  {
    district: 'จุน',
    province: 'พะเยา',
    zipcode: 56150,
  },
  {
    district: 'ดอกคำใต้',
    province: 'พะเยา',
    zipcode: 56120,
  },
  {
    district: 'ปง',
    province: 'พะเยา',
    zipcode: 56140,
  },
  {
    district: 'ภูกามยาว',
    province: 'พะเยา',
    zipcode: 56000,
  },
  {
    district: 'ภูซาง',
    province: 'พะเยา',
    zipcode: 56110,
  },
  {
    district: 'เชียงคำ',
    province: 'พะเยา',
    zipcode: 56110,
  },
  {
    district: 'เชียงม่วน',
    province: 'พะเยา',
    zipcode: 56160,
  },
  {
    district: 'เมืองพะเยา',
    province: 'พะเยา',
    zipcode: 56000,
  },
  {
    district: 'แม่ใจ',
    province: 'พะเยา',
    zipcode: 56130,
  },
  {
    district: 'กะปง',
    province: 'พังงา',
    zipcode: 82170,
  },
  {
    district: 'คุระบุรี',
    province: 'พังงา',
    zipcode: 82150,
  },
  {
    district: 'ตะกั่วทุ่ง',
    province: 'พังงา',
    zipcode: 82130,
  },
  {
    district: 'ตะกั่วทุ่ง',
    province: 'พังงา',
    zipcode: 82140,
  },
  {
    district: 'ตะกั่วป่า',
    province: 'พังงา',
    zipcode: 82220,
  },
  {
    district: 'ตะกั่วป่า',
    province: 'พังงา',
    zipcode: 82110,
  },
  {
    district: 'ตะกั่วป่า',
    province: 'พังงา',
    zipcode: 82190,
  },
  {
    district: 'ทับปุด',
    province: 'พังงา',
    zipcode: 82180,
  },
  {
    district: 'ท้ายเหมือง',
    province: 'พังงา',
    zipcode: 82120,
  },
  {
    district: 'ท้ายเหมือง',
    province: 'พังงา',
    zipcode: 82210,
  },
  {
    district: 'เกาะยาว',
    province: 'พังงา',
    zipcode: 83000,
  },
  {
    district: 'เกาะยาว',
    province: 'พังงา',
    zipcode: 82160,
  },
  {
    district: 'เมืองพังงา',
    province: 'พังงา',
    zipcode: 82000,
  },
  {
    district: 'กงหรา',
    province: 'พัทลุง',
    zipcode: 93180,
  },
  {
    district: 'กงหรา',
    province: 'พัทลุง',
    zipcode: 93000,
  },
  {
    district: 'ควนขนุน',
    province: 'พัทลุง',
    zipcode: 93110,
  },
  {
    district: 'ควนขนุน',
    province: 'พัทลุง',
    zipcode: 93150,
  },
  {
    district: 'ตะโหมด',
    province: 'พัทลุง',
    zipcode: 93160,
  },
  {
    district: 'บางแก้ว',
    province: 'พัทลุง',
    zipcode: 93140,
  },
  {
    district: 'ปากพะยูน',
    province: 'พัทลุง',
    zipcode: 93120,
  },
  {
    district: 'ป่าบอน',
    province: 'พัทลุง',
    zipcode: 93170,
  },
  {
    district: 'ป่าพะยอม',
    province: 'พัทลุง',
    zipcode: 93210,
  },
  {
    district: 'ศรีนครินทร์',
    province: 'พัทลุง',
    zipcode: 93000,
  },
  {
    district: 'ศรีบรรพต',
    province: 'พัทลุง',
    zipcode: 93190,
  },
  {
    district: 'เขาชัยสน',
    province: 'พัทลุง',
    zipcode: 93130,
  },
  {
    district: 'เมืองพัทลุง',
    province: 'พัทลุง',
    zipcode: 93000,
  },
  {
    district: 'ดงเจริญ',
    province: 'พิจิตร',
    zipcode: 66210,
  },
  {
    district: 'ตะพานหิน',
    province: 'พิจิตร',
    zipcode: 66110,
  },
  {
    district: 'ตะพานหิน',
    province: 'พิจิตร',
    zipcode: 66150,
  },
  {
    district: 'ทับคล้อ',
    province: 'พิจิตร',
    zipcode: 66150,
  },
  {
    district: 'ทับคล้อ',
    province: 'พิจิตร',
    zipcode: 66230,
  },
  {
    district: 'บางมูลนาก',
    province: 'พิจิตร',
    zipcode: 66120,
  },
  {
    district: 'บางมูลนาก',
    province: 'พิจิตร',
    zipcode: 66210,
  },
  {
    district: 'บึงนาราง',
    province: 'พิจิตร',
    zipcode: 66130,
  },
  {
    district: 'วชิรบารมี',
    province: 'พิจิตร',
    zipcode: 66140,
  },
  {
    district: 'วชิรบารมี',
    province: 'พิจิตร',
    zipcode: 66220,
  },
  {
    district: 'วังทรายพูน',
    province: 'พิจิตร',
    zipcode: 66180,
  },
  {
    district: 'สากเหล็ก',
    province: 'พิจิตร',
    zipcode: 66160,
  },
  {
    district: 'สามง่าม',
    province: 'พิจิตร',
    zipcode: 66220,
  },
  {
    district: 'สามง่าม',
    province: 'พิจิตร',
    zipcode: 66140,
  },
  {
    district: 'เมืองพิจิตร',
    province: 'พิจิตร',
    zipcode: 66000,
  },
  {
    district: 'เมืองพิจิตร',
    province: 'พิจิตร',
    zipcode: 66170,
  },
  {
    district: 'โพทะเล',
    province: 'พิจิตร',
    zipcode: 66130,
  },
  {
    district: 'โพธิ์ประทับช้าง',
    province: 'พิจิตร',
    zipcode: 66190,
  },
  {
    district: 'ชาติตระการ',
    province: 'พิษณุโลก',
    zipcode: 65170,
  },
  {
    district: 'นครไทย',
    province: 'พิษณุโลก',
    zipcode: 65120,
  },
  {
    district: 'บางกระทุ่ม',
    province: 'พิษณุโลก',
    zipcode: 65110,
  },
  {
    district: 'บางกระทุ่ม',
    province: 'พิษณุโลก',
    zipcode: 65210,
  },
  {
    district: 'บางระกำ',
    province: 'พิษณุโลก',
    zipcode: 65240,
  },
  {
    district: 'บางระกำ',
    province: 'พิษณุโลก',
    zipcode: 65140,
  },
  {
    district: 'พรหมพิราม',
    province: 'พิษณุโลก',
    zipcode: 65180,
  },
  {
    district: 'พรหมพิราม',
    province: 'พิษณุโลก',
    zipcode: 65150,
  },
  {
    district: 'วังทอง',
    province: 'พิษณุโลก',
    zipcode: 65130,
  },
  {
    district: 'วังทอง',
    province: 'พิษณุโลก',
    zipcode: 65220,
  },
  {
    district: 'วัดโบสถ์',
    province: 'พิษณุโลก',
    zipcode: 65160,
  },
  {
    district: 'เนินมะปราง',
    province: 'พิษณุโลก',
    zipcode: 65190,
  },
  {
    district: 'เมืองพิษณุโลก',
    province: 'พิษณุโลก',
    zipcode: 65230,
  },
  {
    district: 'เมืองพิษณุโลก',
    province: 'พิษณุโลก',
    zipcode: 65000,
  },
  {
    district: 'กะทู้',
    province: 'ภูเก็ต',
    zipcode: 83120,
  },
  {
    district: 'กะทู้',
    province: 'ภูเก็ต',
    zipcode: 83150,
  },
  {
    district: 'ถลาง',
    province: 'ภูเก็ต',
    zipcode: 83110,
  },
  {
    district: 'เมืองภูเก็ต',
    province: 'ภูเก็ต',
    zipcode: 83100,
  },
  {
    district: 'เมืองภูเก็ต',
    province: 'ภูเก็ต',
    zipcode: 83000,
  },
  {
    district: 'เมืองภูเก็ต',
    province: 'ภูเก็ต',
    zipcode: 83130,
  },
  {
    district: 'กันทรวิชัย',
    province: 'มหาสารคาม',
    zipcode: 44150,
  },
  {
    district: 'กุดรัง',
    province: 'มหาสารคาม',
    zipcode: 44130,
  },
  {
    district: 'ชื่นชม',
    province: 'มหาสารคาม',
    zipcode: 44160,
  },
  {
    district: 'นาดูน',
    province: 'มหาสารคาม',
    zipcode: 44180,
  },
  {
    district: 'นาเชือก',
    province: 'มหาสารคาม',
    zipcode: 44170,
  },
  {
    district: 'บรบือ',
    province: 'มหาสารคาม',
    zipcode: 44130,
  },
  {
    district: 'พยัคฆภูมิพิสัย',
    province: 'มหาสารคาม',
    zipcode: 44110,
  },
  {
    district: 'ยางสีสุราช',
    province: 'มหาสารคาม',
    zipcode: 44210,
  },
  {
    district: 'วาปีปทุม',
    province: 'มหาสารคาม',
    zipcode: 44120,
  },
  {
    district: 'เชียงยืน',
    province: 'มหาสารคาม',
    zipcode: 44160,
  },
  {
    district: 'เมืองมหาสารคาม',
    province: 'มหาสารคาม',
    zipcode: 44000,
  },
  {
    district: 'แกดำ',
    province: 'มหาสารคาม',
    zipcode: 44190,
  },
  {
    district: 'โกสุมพิสัย',
    province: 'มหาสารคาม',
    zipcode: 44140,
  },
  {
    district: 'คำชะอี',
    province: 'มุกดาหาร',
    zipcode: 49110,
  },
  {
    district: 'ดงหลวง',
    province: 'มุกดาหาร',
    zipcode: 49140,
  },
  {
    district: 'ดอนตาล',
    province: 'มุกดาหาร',
    zipcode: 49120,
  },
  {
    district: 'นิคมคำสร้อย',
    province: 'มุกดาหาร',
    zipcode: 49130,
  },
  {
    district: 'หนองสูง',
    province: 'มุกดาหาร',
    zipcode: 49160,
  },
  {
    district: 'หว้านใหญ่',
    province: 'มุกดาหาร',
    zipcode: 49150,
  },
  {
    district: 'เมืองมุกดาหาร',
    province: 'มุกดาหาร',
    zipcode: 49000,
  },
  {
    district: 'กรงปินัง',
    province: 'ยะลา',
    zipcode: 95000,
  },
  {
    district: 'กาบัง',
    province: 'ยะลา',
    zipcode: 95120,
  },
  {
    district: 'ธารโต',
    province: 'ยะลา',
    zipcode: 95150,
  },
  {
    district: 'ธารโต',
    province: 'ยะลา',
    zipcode: 95170,
  },
  {
    district: 'บันนังสตา',
    province: 'ยะลา',
    zipcode: 95130,
  },
  {
    district: 'ยะหา',
    province: 'ยะลา',
    zipcode: 95120,
  },
  {
    district: 'รามัน',
    province: 'ยะลา',
    zipcode: 95140,
  },
  {
    district: 'เบตง',
    province: 'ยะลา',
    zipcode: 95110,
  },
  {
    district: 'เมืองยะลา',
    province: 'ยะลา',
    zipcode: 95000,
  },
  {
    district: 'เมืองยะลา',
    province: 'ยะลา',
    zipcode: 95160,
  },
  {
    district: 'กุดชุม',
    province: 'ยโสธร',
    zipcode: 35140,
  },
  {
    district: 'คำเขื่อนแก้ว',
    province: 'ยโสธร',
    zipcode: 35110,
  },
  {
    district: 'คำเขื่อนแก้ว',
    province: 'ยโสธร',
    zipcode: 35180,
  },
  {
    district: 'ค้อวัง',
    province: 'ยโสธร',
    zipcode: 35160,
  },
  {
    district: 'ทรายมูล',
    province: 'ยโสธร',
    zipcode: 35170,
  },
  {
    district: 'ป่าติ้ว',
    province: 'ยโสธร',
    zipcode: 35150,
  },
  {
    district: 'มหาชนะชัย',
    province: 'ยโสธร',
    zipcode: 35130,
  },
  {
    district: 'เมืองยโสธร',
    province: 'ยโสธร',
    zipcode: 35000,
  },
  {
    district: 'เลิงนกทา',
    province: 'ยโสธร',
    zipcode: 35120,
  },
  {
    district: 'ไทยเจริญ',
    province: 'ยโสธร',
    zipcode: 35120,
  },
  {
    district: 'กระบุรี',
    province: 'ระนอง',
    zipcode: 85110,
  },
  {
    district: 'กะเปอร์',
    province: 'ระนอง',
    zipcode: 85120,
  },
  {
    district: 'ละอุ่น',
    province: 'ระนอง',
    zipcode: 85130,
  },
  {
    district: 'สุขสำราญ',
    province: 'ระนอง',
    zipcode: 85120,
  },
  {
    district: 'เมืองระนอง',
    province: 'ระนอง',
    zipcode: 85130,
  },
  {
    district: 'เมืองระนอง',
    province: 'ระนอง',
    zipcode: 85000,
  },
  {
    district: 'นิคมพัฒนา',
    province: 'ระยอง',
    zipcode: 21180,
  },
  {
    district: 'บ้านค่าย',
    province: 'ระยอง',
    zipcode: 21120,
  },
  {
    district: 'บ้านฉาง',
    province: 'ระยอง',
    zipcode: 21130,
  },
  {
    district: 'ปลวกแดง',
    province: 'ระยอง',
    zipcode: 21140,
  },
  {
    district: 'วังจันทร์',
    province: 'ระยอง',
    zipcode: 21210,
  },
  {
    district: 'เขาชะเมา',
    province: 'ระยอง',
    zipcode: 21110,
  },
  {
    district: 'เมืองระยอง',
    province: 'ระยอง',
    zipcode: 21100,
  },
  {
    district: 'เมืองระยอง',
    province: 'ระยอง',
    zipcode: 21000,
  },
  {
    district: 'เมืองระยอง',
    province: 'ระยอง',
    zipcode: 21150,
  },
  {
    district: 'เมืองระยอง',
    province: 'ระยอง',
    zipcode: 21160,
  },
  {
    district: 'แกลง',
    province: 'ระยอง',
    zipcode: 21110,
  },
  {
    district: 'แกลง',
    province: 'ระยอง',
    zipcode: 21190,
  },
  {
    district: 'แกลง',
    province: 'ระยอง',
    zipcode: 22160,
  },
  {
    district: 'แกลง',
    province: 'ระยอง',
    zipcode: 21170,
  },
  {
    district: 'จอมบึง',
    province: 'ราชบุรี',
    zipcode: 70150,
  },
  {
    district: 'ดำเนินสะดวก',
    province: 'ราชบุรี',
    zipcode: 70130,
  },
  {
    district: 'ดำเนินสะดวก',
    province: 'ราชบุรี',
    zipcode: 70210,
  },
  {
    district: 'บางแพ',
    province: 'ราชบุรี',
    zipcode: 70160,
  },
  {
    district: 'บ้านคา',
    province: 'ราชบุรี',
    zipcode: 70180,
  },
  {
    district: 'บ้านโป่ง',
    province: 'ราชบุรี',
    zipcode: 70190,
  },
  {
    district: 'บ้านโป่ง',
    province: 'ราชบุรี',
    zipcode: 70110,
  },
  {
    district: 'ปากท่อ',
    province: 'ราชบุรี',
    zipcode: 70140,
  },
  {
    district: 'วัดเพลง',
    province: 'ราชบุรี',
    zipcode: 70170,
  },
  {
    district: 'สวนผึ้ง',
    province: 'ราชบุรี',
    zipcode: 70180,
  },
  {
    district: 'เมืองราชบุรี',
    province: 'ราชบุรี',
    zipcode: 70000,
  },
  {
    district: 'โพธาราม',
    province: 'ราชบุรี',
    zipcode: 70120,
  },
  {
    district: 'จตุรพักตรพิมาน',
    province: 'ร้อยเอ็ด',
    zipcode: 45180,
  },
  {
    district: 'จังหาร',
    province: 'ร้อยเอ็ด',
    zipcode: 45000,
  },
  {
    district: 'ทุ่งเขาหลวง',
    province: 'ร้อยเอ็ด',
    zipcode: 45170,
  },
  {
    district: 'ธวัชบุรี',
    province: 'ร้อยเอ็ด',
    zipcode: 45170,
  },
  {
    district: 'ปทุมรัตต์',
    province: 'ร้อยเอ็ด',
    zipcode: 45190,
  },
  {
    district: 'พนมไพร',
    province: 'ร้อยเอ็ด',
    zipcode: 45140,
  },
  {
    district: 'ศรีสมเด็จ',
    province: 'ร้อยเอ็ด',
    zipcode: 45000,
  },
  {
    district: 'ศรีสมเด็จ',
    province: 'ร้อยเอ็ด',
    zipcode: 45280,
  },
  {
    district: 'สุวรรณภูมิ',
    province: 'ร้อยเอ็ด',
    zipcode: 45130,
  },
  {
    district: 'หนองพอก',
    province: 'ร้อยเอ็ด',
    zipcode: 45210,
  },
  {
    district: 'หนองฮี',
    province: 'ร้อยเอ็ด',
    zipcode: 45140,
  },
  {
    district: 'อาจสามารถ',
    province: 'ร้อยเอ็ด',
    zipcode: 45160,
  },
  {
    district: 'เกษตรวิสัย',
    province: 'ร้อยเอ็ด',
    zipcode: 45150,
  },
  {
    district: 'เชียงขวัญ',
    province: 'ร้อยเอ็ด',
    zipcode: 45000,
  },
  {
    district: 'เชียงขวัญ',
    province: 'ร้อยเอ็ด',
    zipcode: 45170,
  },
  {
    district: 'เมยวดี',
    province: 'ร้อยเอ็ด',
    zipcode: 45250,
  },
  {
    district: 'เมืองร้อยเอ็ด',
    province: 'ร้อยเอ็ด',
    zipcode: 45000,
  },
  {
    district: 'เมืองสรวง',
    province: 'ร้อยเอ็ด',
    zipcode: 45220,
  },
  {
    district: 'เสลภูมิ',
    province: 'ร้อยเอ็ด',
    zipcode: 45120,
  },
  {
    district: 'โพธิ์ชัย',
    province: 'ร้อยเอ็ด',
    zipcode: 45230,
  },
  {
    district: 'โพนทราย',
    province: 'ร้อยเอ็ด',
    zipcode: 45240,
  },
  {
    district: 'โพนทอง',
    province: 'ร้อยเอ็ด',
    zipcode: 45110,
  },
  {
    district: 'ชัยบาดาล',
    province: 'ลพบุรี',
    zipcode: 15130,
  },
  {
    district: 'ชัยบาดาล',
    province: 'ลพบุรี',
    zipcode: 15230,
  },
  {
    district: 'ชัยบาดาล',
    province: 'ลพบุรี',
    zipcode: 15190,
  },
  {
    district: 'ท่าวุ้ง',
    province: 'ลพบุรี',
    zipcode: 15150,
  },
  {
    district: 'ท่าวุ้ง',
    province: 'ลพบุรี',
    zipcode: 15180,
  },
  {
    district: 'ท่าหลวง',
    province: 'ลพบุรี',
    zipcode: 15230,
  },
  {
    district: 'บ้านหมี่',
    province: 'ลพบุรี',
    zipcode: 15110,
  },
  {
    district: 'บ้านหมี่',
    province: 'ลพบุรี',
    zipcode: 15180,
  },
  {
    district: 'พัฒนานิคม',
    province: 'ลพบุรี',
    zipcode: 15140,
  },
  {
    district: 'พัฒนานิคม',
    province: 'ลพบุรี',
    zipcode: 15220,
  },
  {
    district: 'พัฒนานิคม',
    province: 'ลพบุรี',
    zipcode: 18220,
  },
  {
    district: 'ลำสนธิ',
    province: 'ลพบุรี',
    zipcode: 15190,
  },
  {
    district: 'ลำสนธิ',
    province: 'ลพบุรี',
    zipcode: 15130,
  },
  {
    district: 'สระโบสถ์',
    province: 'ลพบุรี',
    zipcode: 15240,
  },
  {
    district: 'หนองม่วง',
    province: 'ลพบุรี',
    zipcode: 15170,
  },
  {
    district: 'เมืองลพบุรี',
    province: 'ลพบุรี',
    zipcode: 15000,
  },
  {
    district: 'เมืองลพบุรี',
    province: 'ลพบุรี',
    zipcode: 15160,
  },
  {
    district: 'เมืองลพบุรี',
    province: 'ลพบุรี',
    zipcode: 13240,
  },
  {
    district: 'เมืองลพบุรี',
    province: 'ลพบุรี',
    zipcode: 15210,
  },
  {
    district: 'โคกสำโรง',
    province: 'ลพบุรี',
    zipcode: 15120,
  },
  {
    district: 'โคกเจริญ',
    province: 'ลพบุรี',
    zipcode: 15250,
  },
  {
    district: 'งาว',
    province: 'ลำปาง',
    zipcode: 52110,
  },
  {
    district: 'วังเหนือ',
    province: 'ลำปาง',
    zipcode: 52140,
  },
  {
    district: 'สบปราบ',
    province: 'ลำปาง',
    zipcode: 52170,
  },
  {
    district: 'ห้างฉัตร',
    province: 'ลำปาง',
    zipcode: 52190,
  },
  {
    district: 'เกาะคา',
    province: 'ลำปาง',
    zipcode: 52130,
  },
  {
    district: 'เถิน',
    province: 'ลำปาง',
    zipcode: 52160,
  },
  {
    district: 'เถิน',
    province: 'ลำปาง',
    zipcode: 52230,
  },
  {
    district: 'เมืองปาน',
    province: 'ลำปาง',
    zipcode: 52240,
  },
  {
    district: 'เมืองลำปาง',
    province: 'ลำปาง',
    zipcode: 52000,
  },
  {
    district: 'เมืองลำปาง',
    province: 'ลำปาง',
    zipcode: 52100,
  },
  {
    district: 'เสริมงาม',
    province: 'ลำปาง',
    zipcode: 52210,
  },
  {
    district: 'แจ้ห่ม',
    province: 'ลำปาง',
    zipcode: 52120,
  },
  {
    district: 'แม่ทะ',
    province: 'ลำปาง',
    zipcode: 52150,
  },
  {
    district: 'แม่พริก',
    province: 'ลำปาง',
    zipcode: 52180,
  },
  {
    district: 'แม่พริก',
    province: 'ลำปาง',
    zipcode: 52230,
  },
  {
    district: 'แม่เมาะ',
    province: 'ลำปาง',
    zipcode: 52220,
  },
  {
    district: 'ทุ่งหัวช้าง',
    province: 'ลำพูน',
    zipcode: 51160,
  },
  {
    district: 'บ้านธิ',
    province: 'ลำพูน',
    zipcode: 51180,
  },
  {
    district: 'บ้านโฮ่ง',
    province: 'ลำพูน',
    zipcode: 51130,
  },
  {
    district: 'ป่าซาง',
    province: 'ลำพูน',
    zipcode: 51120,
  },
  {
    district: 'ลี้',
    province: 'ลำพูน',
    zipcode: 51110,
  },
  {
    district: 'เมืองลำพูน',
    province: 'ลำพูน',
    zipcode: 51000,
  },
  {
    district: 'เมืองลำพูน',
    province: 'ลำพูน',
    zipcode: 51150,
  },
  {
    district: 'เวียงหนองล่อง',
    province: 'ลำพูน',
    zipcode: 51120,
  },
  {
    district: 'แม่ทา',
    province: 'ลำพูน',
    zipcode: 51170,
  },
  {
    district: 'แม่ทา',
    province: 'ลำพูน',
    zipcode: 51140,
  },
  {
    district: 'กันทรลักษ์',
    province: 'ศรีสะเกษ',
    zipcode: 33110,
  },
  {
    district: 'กันทรารมย์',
    province: 'ศรีสะเกษ',
    zipcode: 33130,
  },
  {
    district: 'ขุขันธ์',
    province: 'ศรีสะเกษ',
    zipcode: 33140,
  },
  {
    district: 'ขุนหาญ',
    province: 'ศรีสะเกษ',
    zipcode: 33150,
  },
  {
    district: 'น้ำเกลี้ยง',
    province: 'ศรีสะเกษ',
    zipcode: 33130,
  },
  {
    district: 'บึงบูรพ์',
    province: 'ศรีสะเกษ',
    zipcode: 33220,
  },
  {
    district: 'ปรางค์กู่',
    province: 'ศรีสะเกษ',
    zipcode: 33170,
  },
  {
    district: 'พยุห์',
    province: 'ศรีสะเกษ',
    zipcode: 33230,
  },
  {
    district: 'ภูสิงห์',
    province: 'ศรีสะเกษ',
    zipcode: 33140,
  },
  {
    district: 'ยางชุมน้อย',
    province: 'ศรีสะเกษ',
    zipcode: 33190,
  },
  {
    district: 'ราษีไศล',
    province: 'ศรีสะเกษ',
    zipcode: 33160,
  },
  {
    district: 'วังหิน',
    province: 'ศรีสะเกษ',
    zipcode: 33270,
  },
  {
    district: 'ศรีรัตนะ',
    province: 'ศรีสะเกษ',
    zipcode: 33240,
  },
  {
    district: 'ศิลาลาด',
    province: 'ศรีสะเกษ',
    zipcode: 33160,
  },
  {
    district: 'ห้วยทับทัน',
    province: 'ศรีสะเกษ',
    zipcode: 33210,
  },
  {
    district: 'อุทุมพรพิสัย',
    province: 'ศรีสะเกษ',
    zipcode: 33120,
  },
  {
    district: 'เบญจลักษ์',
    province: 'ศรีสะเกษ',
    zipcode: 33110,
  },
  {
    district: 'เมืองจันทร์',
    province: 'ศรีสะเกษ',
    zipcode: 33120,
  },
  {
    district: 'เมืองศรีสะเกษ',
    province: 'ศรีสะเกษ',
    zipcode: 33000,
  },
  {
    district: 'โนนคูณ',
    province: 'ศรีสะเกษ',
    zipcode: 33250,
  },
  {
    district: 'โพธิ์ศรีสุวรรณ',
    province: 'ศรีสะเกษ',
    zipcode: 33120,
  },
  {
    district: 'ไพรบึง',
    province: 'ศรีสะเกษ',
    zipcode: 33180,
  },
  {
    district: 'กุดบาก',
    province: 'สกลนคร',
    zipcode: 47180,
  },
  {
    district: 'กุสุมาลย์',
    province: 'สกลนคร',
    zipcode: 47210,
  },
  {
    district: 'กุสุมาลย์',
    province: 'สกลนคร',
    zipcode: 47230,
  },
  {
    district: 'คำตากล้า',
    province: 'สกลนคร',
    zipcode: 47250,
  },
  {
    district: 'นิคมน้ำอูน',
    province: 'สกลนคร',
    zipcode: 47270,
  },
  {
    district: 'บ้านม่วง',
    province: 'สกลนคร',
    zipcode: 47140,
  },
  {
    district: 'พรรณานิคม',
    province: 'สกลนคร',
    zipcode: 47130,
  },
  {
    district: 'พรรณานิคม',
    province: 'สกลนคร',
    zipcode: 47220,
  },
  {
    district: 'พังโคน',
    province: 'สกลนคร',
    zipcode: 47160,
  },
  {
    district: 'ภูพาน',
    province: 'สกลนคร',
    zipcode: 47180,
  },
  {
    district: 'วานรนิวาส',
    province: 'สกลนคร',
    zipcode: 47120,
  },
  {
    district: 'วาริชภูมิ',
    province: 'สกลนคร',
    zipcode: 47150,
  },
  {
    district: 'สว่างแดนดิน',
    province: 'สกลนคร',
    zipcode: 47110,
  },
  {
    district: 'สว่างแดนดิน',
    province: 'สกลนคร',
    zipcode: 47240,
  },
  {
    district: 'ส่องดาว',
    province: 'สกลนคร',
    zipcode: 47190,
  },
  {
    district: 'อากาศอำนวย',
    province: 'สกลนคร',
    zipcode: 47170,
  },
  {
    district: 'เจริญศิลป์',
    province: 'สกลนคร',
    zipcode: 47290,
  },
  {
    district: 'เต่างอย',
    province: 'สกลนคร',
    zipcode: 47260,
  },
  {
    district: 'เมืองสกลนคร',
    province: 'สกลนคร',
    zipcode: 47220,
  },
  {
    district: 'เมืองสกลนคร',
    province: 'สกลนคร',
    zipcode: 47000,
  },
  {
    district: 'เมืองสกลนคร',
    province: 'สกลนคร',
    zipcode: 47230,
  },
  {
    district: 'โคกศรีสุพรรณ',
    province: 'สกลนคร',
    zipcode: 47280,
  },
  {
    district: 'โพนนาแก้ว',
    province: 'สกลนคร',
    zipcode: 47230,
  },
  {
    district: 'กระแสสินธุ์',
    province: 'สงขลา',
    zipcode: 90270,
  },
  {
    district: 'คลองหอยโข่ง',
    province: 'สงขลา',
    zipcode: 90115,
  },
  {
    district: 'คลองหอยโข่ง',
    province: 'สงขลา',
    zipcode: 90230,
  },
  {
    district: 'ควนเนียง',
    province: 'สงขลา',
    zipcode: 90220,
  },
  {
    district: 'จะนะ',
    province: 'สงขลา',
    zipcode: 90130,
  },
  {
    district: 'นาทวี',
    province: 'สงขลา',
    zipcode: 90160,
  },
  {
    district: 'นาหม่อม',
    province: 'สงขลา',
    zipcode: 90310,
  },
  {
    district: 'บางกล่ำ',
    province: 'สงขลา',
    zipcode: 90110,
  },
  {
    district: 'ระโนด',
    province: 'สงขลา',
    zipcode: 90140,
  },
  {
    district: 'รัตภูมิ',
    province: 'สงขลา',
    zipcode: 90180,
  },
  {
    district: 'รัตภูมิ',
    province: 'สงขลา',
    zipcode: 90220,
  },
  {
    district: 'สทิงพระ',
    province: 'สงขลา',
    zipcode: 90190,
  },
  {
    district: 'สะบ้าย้อย',
    province: 'สงขลา',
    zipcode: 90210,
  },
  {
    district: 'สะเดา',
    province: 'สงขลา',
    zipcode: 90240,
  },
  {
    district: 'สะเดา',
    province: 'สงขลา',
    zipcode: 90170,
  },
  {
    district: 'สะเดา',
    province: 'สงขลา',
    zipcode: 90120,
  },
  {
    district: 'สะเดา',
    province: 'สงขลา',
    zipcode: 90320,
  },
  {
    district: 'สิงหนคร',
    province: 'สงขลา',
    zipcode: 90330,
  },
  {
    district: 'สิงหนคร',
    province: 'สงขลา',
    zipcode: 90280,
  },
  {
    district: 'หาดใหญ่',
    province: 'สงขลา',
    zipcode: 90110,
  },
  {
    district: 'หาดใหญ่',
    province: 'สงขลา',
    zipcode: 90250,
  },
  {
    district: 'หาดใหญ่',
    province: 'สงขลา',
    zipcode: 90230,
  },
  {
    district: 'เทพา',
    province: 'สงขลา',
    zipcode: 90150,
  },
  {
    district: 'เทพา',
    province: 'สงขลา',
    zipcode: 90260,
  },
  {
    district: 'เมืองสงขลา',
    province: 'สงขลา',
    zipcode: 90000,
  },
  {
    district: 'เมืองสงขลา',
    province: 'สงขลา',
    zipcode: 90100,
  },
  {
    district: 'ควนกาหลง',
    province: 'สตูล',
    zipcode: 91130,
  },
  {
    district: 'ควนโดน',
    province: 'สตูล',
    zipcode: 91160,
  },
  {
    district: 'ทุ่งหว้า',
    province: 'สตูล',
    zipcode: 91120,
  },
  {
    district: 'ท่าแพ',
    province: 'สตูล',
    zipcode: 91150,
  },
  {
    district: 'มะนัง',
    province: 'สตูล',
    zipcode: 91130,
  },
  {
    district: 'ละงู',
    province: 'สตูล',
    zipcode: 91110,
  },
  {
    district: 'เมืองสตูล',
    province: 'สตูล',
    zipcode: 91000,
  },
  {
    district: 'เมืองสตูล',
    province: 'สตูล',
    zipcode: 91140,
  },
  {
    district: 'บางบ่อ',
    province: 'สมุทรปราการ',
    zipcode: 10550,
  },
  {
    district: 'บางบ่อ',
    province: 'สมุทรปราการ',
    zipcode: 10560,
  },
  {
    district: 'บางพลี',
    province: 'สมุทรปราการ',
    zipcode: 10540,
  },
  {
    district: 'บางเสาธง',
    province: 'สมุทรปราการ',
    zipcode: 10570,
  },
  {
    district: 'พระประแดง',
    province: 'สมุทรปราการ',
    zipcode: 10130,
  },
  {
    district: 'พระสมุทรเจดีย์',
    province: 'สมุทรปราการ',
    zipcode: 10290,
  },
  {
    district: 'เมืองสมุทรปราการ',
    province: 'สมุทรปราการ',
    zipcode: 10280,
  },
  {
    district: 'เมืองสมุทรปราการ',
    province: 'สมุทรปราการ',
    zipcode: 10270,
  },
  {
    district: 'บางคนที',
    province: 'สมุทรสงคราม',
    zipcode: 75120,
  },
  {
    district: 'อัมพวา',
    province: 'สมุทรสงคราม',
    zipcode: 75110,
  },
  {
    district: 'เมืองสมุทรสงคราม',
    province: 'สมุทรสงคราม',
    zipcode: 75000,
  },
  {
    district: 'กระทุ่มแบน',
    province: 'สมุทรสาคร',
    zipcode: 74110,
  },
  {
    district: 'กระทุ่มแบน',
    province: 'สมุทรสาคร',
    zipcode: 74130,
  },
  {
    district: 'บ้านแพ้ว',
    province: 'สมุทรสาคร',
    zipcode: 74120,
  },
  {
    district: 'เมืองสมุทรสาคร',
    province: 'สมุทรสาคร',
    zipcode: 74000,
  },
  {
    district: 'ดอนพุด',
    province: 'สระบุรี',
    zipcode: 18210,
  },
  {
    district: 'บ้านหมอ',
    province: 'สระบุรี',
    zipcode: 18130,
  },
  {
    district: 'บ้านหมอ',
    province: 'สระบุรี',
    zipcode: 18270,
  },
  {
    district: 'พระพุทธบาท',
    province: 'สระบุรี',
    zipcode: 18120,
  },
  {
    district: 'มวกเหล็ก',
    province: 'สระบุรี',
    zipcode: 18220,
  },
  {
    district: 'มวกเหล็ก',
    province: 'สระบุรี',
    zipcode: 18180,
  },
  {
    district: 'มวกเหล็ก',
    province: 'สระบุรี',
    zipcode: 30130,
  },
  {
    district: 'วังม่วง',
    province: 'สระบุรี',
    zipcode: 18220,
  },
  {
    district: 'วิหารแดง',
    province: 'สระบุรี',
    zipcode: 18150,
  },
  {
    district: 'หนองแค',
    province: 'สระบุรี',
    zipcode: 18140,
  },
  {
    district: 'หนองแค',
    province: 'สระบุรี',
    zipcode: 18250,
  },
  {
    district: 'หนองแค',
    province: 'สระบุรี',
    zipcode: 18230,
  },
  {
    district: 'หนองแซง',
    province: 'สระบุรี',
    zipcode: 18170,
  },
  {
    district: 'หนองโดน',
    province: 'สระบุรี',
    zipcode: 18190,
  },
  {
    district: 'เฉลิมพระเกียรติ',
    province: 'สระบุรี',
    zipcode: 18000,
  },
  {
    district: 'เฉลิมพระเกียรติ',
    province: 'สระบุรี',
    zipcode: 18240,
  },
  {
    district: 'เมืองสระบุรี',
    province: 'สระบุรี',
    zipcode: 18000,
  },
  {
    district: 'เสาไห้',
    province: 'สระบุรี',
    zipcode: 18160,
  },
  {
    district: 'แก่งคอย',
    province: 'สระบุรี',
    zipcode: 18110,
  },
  {
    district: 'แก่งคอย',
    province: 'สระบุรี',
    zipcode: 18260,
  },
  {
    district: 'คลองหาด',
    province: 'สระแก้ว',
    zipcode: 27260,
  },
  {
    district: 'ตาพระยา',
    province: 'สระแก้ว',
    zipcode: 27180,
  },
  {
    district: 'วังน้ำเย็น',
    province: 'สระแก้ว',
    zipcode: 27210,
  },
  {
    district: 'วังสมบูรณ์',
    province: 'สระแก้ว',
    zipcode: 27250,
  },
  {
    district: 'วัฒนานคร',
    province: 'สระแก้ว',
    zipcode: 27160,
  },
  {
    district: 'อรัญประเทศ',
    province: 'สระแก้ว',
    zipcode: 27120,
  },
  {
    district: 'เขาฉกรรจ์',
    province: 'สระแก้ว',
    zipcode: 27000,
  },
  {
    district: 'เมืองสระแก้ว',
    province: 'สระแก้ว',
    zipcode: 27000,
  },
  {
    district: 'โคกสูง',
    province: 'สระแก้ว',
    zipcode: 27180,
  },
  {
    district: 'โคกสูง',
    province: 'สระแก้ว',
    zipcode: 27120,
  },
  {
    district: 'ค่ายบางระจัน',
    province: 'สิงห์บุรี',
    zipcode: 16150,
  },
  {
    district: 'ท่าช้าง',
    province: 'สิงห์บุรี',
    zipcode: 16140,
  },
  {
    district: 'บางระจัน',
    province: 'สิงห์บุรี',
    zipcode: 16130,
  },
  {
    district: 'พรหมบุรี',
    province: 'สิงห์บุรี',
    zipcode: 16120,
  },
  {
    district: 'พรหมบุรี',
    province: 'สิงห์บุรี',
    zipcode: 16160,
  },
  {
    district: 'อินทร์บุรี',
    province: 'สิงห์บุรี',
    zipcode: 16110,
  },
  {
    district: 'เมืองสิงห์บุรี',
    province: 'สิงห์บุรี',
    zipcode: 16000,
  },
  {
    district: 'ดอนเจดีย์',
    province: 'สุพรรณบุรี',
    zipcode: 72170,
  },
  {
    district: 'ดอนเจดีย์',
    province: 'สุพรรณบุรี',
    zipcode: 72250,
  },
  {
    district: 'ด่านช้าง',
    province: 'สุพรรณบุรี',
    zipcode: 72180,
  },
  {
    district: 'บางปลาม้า',
    province: 'สุพรรณบุรี',
    zipcode: 72150,
  },
  {
    district: 'ศรีประจันต์',
    province: 'สุพรรณบุรี',
    zipcode: 72140,
  },
  {
    district: 'สองพี่น้อง',
    province: 'สุพรรณบุรี',
    zipcode: 72110,
  },
  {
    district: 'สองพี่น้อง',
    province: 'สุพรรณบุรี',
    zipcode: 72190,
  },
  {
    district: 'สามชุก',
    province: 'สุพรรณบุรี',
    zipcode: 72130,
  },
  {
    district: 'หนองหญ้าไซ',
    province: 'สุพรรณบุรี',
    zipcode: 72240,
  },
  {
    district: 'อู่ทอง',
    province: 'สุพรรณบุรี',
    zipcode: 72160,
  },
  {
    district: 'อู่ทอง',
    province: 'สุพรรณบุรี',
    zipcode: 71170,
  },
  {
    district: 'อู่ทอง',
    province: 'สุพรรณบุรี',
    zipcode: 72220,
  },
  {
    district: 'เดิมบางนางบวช',
    province: 'สุพรรณบุรี',
    zipcode: 72120,
  },
  {
    district: 'เมืองสุพรรณบุรี',
    province: 'สุพรรณบุรี',
    zipcode: 72000,
  },
  {
    district: 'เมืองสุพรรณบุรี',
    province: 'สุพรรณบุรี',
    zipcode: 72230,
  },
  {
    district: 'เมืองสุพรรณบุรี',
    province: 'สุพรรณบุรี',
    zipcode: 72210,
  },
  {
    district: 'กาญจนดิษฐ์',
    province: 'สุราษฎร์ธานี',
    zipcode: 84160,
  },
  {
    district: 'กาญจนดิษฐ์',
    province: 'สุราษฎร์ธานี',
    zipcode: 84290,
  },
  {
    district: 'คีรีรัฐนิคม',
    province: 'สุราษฎร์ธานี',
    zipcode: 84180,
  },
  {
    district: 'ชัยบุรี',
    province: 'สุราษฎร์ธานี',
    zipcode: 84350,
  },
  {
    district: 'ดอนสัก',
    province: 'สุราษฎร์ธานี',
    zipcode: 84160,
  },
  {
    district: 'ดอนสัก',
    province: 'สุราษฎร์ธานี',
    zipcode: 84220,
  },
  {
    district: 'ดอนสัก',
    province: 'สุราษฎร์ธานี',
    zipcode: 84340,
  },
  {
    district: 'ท่าฉาง',
    province: 'สุราษฎร์ธานี',
    zipcode: 84150,
  },
  {
    district: 'ท่าชนะ',
    province: 'สุราษฎร์ธานี',
    zipcode: 84170,
  },
  {
    district: 'บ้านตาขุน',
    province: 'สุราษฎร์ธานี',
    zipcode: 84230,
  },
  {
    district: 'บ้านนาสาร',
    province: 'สุราษฎร์ธานี',
    zipcode: 84120,
  },
  {
    district: 'บ้านนาสาร',
    province: 'สุราษฎร์ธานี',
    zipcode: 84270,
  },
  {
    district: 'บ้านนาเดิม',
    province: 'สุราษฎร์ธานี',
    zipcode: 84240,
  },
  {
    district: 'พนม',
    province: 'สุราษฎร์ธานี',
    zipcode: 84250,
  },
  {
    district: 'พระแสง',
    province: 'สุราษฎร์ธานี',
    zipcode: 84210,
  },
  {
    district: 'พุนพิน',
    province: 'สุราษฎร์ธานี',
    zipcode: 84130,
  },
  {
    district: 'วิภาวดี',
    province: 'สุราษฎร์ธานี',
    zipcode: 84180,
  },
  {
    district: 'เกาะพะงัน',
    province: 'สุราษฎร์ธานี',
    zipcode: 84280,
  },
  {
    district: 'เกาะพะงัน',
    province: 'สุราษฎร์ธานี',
    zipcode: 84360,
  },
  {
    district: 'เกาะสมุย',
    province: 'สุราษฎร์ธานี',
    zipcode: 84140,
  },
  {
    district: 'เกาะสมุย',
    province: 'สุราษฎร์ธานี',
    zipcode: 84320,
  },
  {
    district: 'เกาะสมุย',
    province: 'สุราษฎร์ธานี',
    zipcode: 84310,
  },
  {
    district: 'เกาะสมุย',
    province: 'สุราษฎร์ธานี',
    zipcode: 84330,
  },
  {
    district: 'เคียนซา',
    province: 'สุราษฎร์ธานี',
    zipcode: 84260,
  },
  {
    district: 'เคียนซา',
    province: 'สุราษฎร์ธานี',
    zipcode: 84210,
  },
  {
    district: 'เมืองสุราษฎร์ธานี',
    province: 'สุราษฎร์ธานี',
    zipcode: 84100,
  },
  {
    district: 'เมืองสุราษฎร์ธานี',
    province: 'สุราษฎร์ธานี',
    zipcode: 84000,
  },
  {
    district: 'เวียงสระ',
    province: 'สุราษฎร์ธานี',
    zipcode: 84190,
  },
  {
    district: 'ไชยา',
    province: 'สุราษฎร์ธานี',
    zipcode: 84110,
  },
  {
    district: 'กาบเชิง',
    province: 'สุรินทร์',
    zipcode: 32210,
  },
  {
    district: 'จอมพระ',
    province: 'สุรินทร์',
    zipcode: 32180,
  },
  {
    district: 'ชุมพลบุรี',
    province: 'สุรินทร์',
    zipcode: 32190,
  },
  {
    district: 'ท่าตูม',
    province: 'สุรินทร์',
    zipcode: 32120,
  },
  {
    district: 'บัวเชด',
    province: 'สุรินทร์',
    zipcode: 32230,
  },
  {
    district: 'ปราสาท',
    province: 'สุรินทร์',
    zipcode: 32140,
  },
  {
    district: 'พนมดงรัก',
    province: 'สุรินทร์',
    zipcode: 32140,
  },
  {
    district: 'รัตนบุรี',
    province: 'สุรินทร์',
    zipcode: 32130,
  },
  {
    district: 'ลำดวน',
    province: 'สุรินทร์',
    zipcode: 32220,
  },
  {
    district: 'ศรีณรงค์',
    province: 'สุรินทร์',
    zipcode: 32150,
  },
  {
    district: 'ศีขรภูมิ',
    province: 'สุรินทร์',
    zipcode: 32110,
  },
  {
    district: 'สนม',
    province: 'สุรินทร์',
    zipcode: 32160,
  },
  {
    district: 'สังขะ',
    province: 'สุรินทร์',
    zipcode: 32150,
  },
  {
    district: 'สำโรงทาบ',
    province: 'สุรินทร์',
    zipcode: 32170,
  },
  {
    district: 'เขวาสินรินทร์',
    province: 'สุรินทร์',
    zipcode: 32000,
  },
  {
    district: 'เมืองสุรินทร์',
    province: 'สุรินทร์',
    zipcode: 32000,
  },
  {
    district: 'โนนนารายณ์',
    province: 'สุรินทร์',
    zipcode: 32130,
  },
  {
    district: 'กงไกรลาศ',
    province: 'สุโขทัย',
    zipcode: 64170,
  },
  {
    district: 'คีรีมาศ',
    province: 'สุโขทัย',
    zipcode: 64160,
  },
  {
    district: 'ทุ่งเสลี่ยม',
    province: 'สุโขทัย',
    zipcode: 64150,
  },
  {
    district: 'ทุ่งเสลี่ยม',
    province: 'สุโขทัย',
    zipcode: 64230,
  },
  {
    district: 'บ้านด่านลานหอย',
    province: 'สุโขทัย',
    zipcode: 64140,
  },
  {
    district: 'ศรีนคร',
    province: 'สุโขทัย',
    zipcode: 64180,
  },
  {
    district: 'ศรีสัชนาลัย',
    province: 'สุโขทัย',
    zipcode: 64130,
  },
  {
    district: 'ศรีสัชนาลัย',
    province: 'สุโขทัย',
    zipcode: 64190,
  },
  {
    district: 'ศรีสำโรง',
    province: 'สุโขทัย',
    zipcode: 64120,
  },
  {
    district: 'สวรรคโลก',
    province: 'สุโขทัย',
    zipcode: 64110,
  },
  {
    district: 'เมืองสุโขทัย',
    province: 'สุโขทัย',
    zipcode: 64220,
  },
  {
    district: 'เมืองสุโขทัย',
    province: 'สุโขทัย',
    zipcode: 64000,
  },
  {
    district: 'เมืองสุโขทัย',
    province: 'สุโขทัย',
    zipcode: 64210,
  },
  {
    district: 'ท่าบ่อ',
    province: 'หนองคาย',
    zipcode: 43110,
  },
  {
    district: 'รัตนวาปี',
    province: 'หนองคาย',
    zipcode: 43120,
  },
  {
    district: 'ศรีเชียงใหม่',
    province: 'หนองคาย',
    zipcode: 43130,
  },
  {
    district: 'สระใคร',
    province: 'หนองคาย',
    zipcode: 43100,
  },
  {
    district: 'สังคม',
    province: 'หนองคาย',
    zipcode: 43160,
  },
  {
    district: 'เฝ้าไร่',
    province: 'หนองคาย',
    zipcode: 43120,
  },
  {
    district: 'เมืองหนองคาย',
    province: 'หนองคาย',
    zipcode: 43000,
  },
  {
    district: 'เมืองหนองคาย',
    province: 'หนองคาย',
    zipcode: 43100,
  },
  {
    district: 'โพธิ์ตาก',
    province: 'หนองคาย',
    zipcode: 43130,
  },
  {
    district: 'โพนพิสัย',
    province: 'หนองคาย',
    zipcode: 43120,
  },
  {
    district: 'นากลาง',
    province: 'หนองบัวลำภู',
    zipcode: 39350,
  },
  {
    district: 'นากลาง',
    province: 'หนองบัวลำภู',
    zipcode: 39170,
  },
  {
    district: 'นาวัง',
    province: 'หนองบัวลำภู',
    zipcode: 39170,
  },
  {
    district: 'ศรีบุญเรือง',
    province: 'หนองบัวลำภู',
    zipcode: 39180,
  },
  {
    district: 'สุวรรณคูหา',
    province: 'หนองบัวลำภู',
    zipcode: 39270,
  },
  {
    district: 'เมืองหนองบัวลำภู',
    province: 'หนองบัวลำภู',
    zipcode: 39000,
  },
  {
    district: 'โนนสัง',
    province: 'หนองบัวลำภู',
    zipcode: 39140,
  },
  {
    district: 'ชานุมาน',
    province: 'อำนาจเจริญ',
    zipcode: 37210,
  },
  {
    district: 'ปทุมราชวงศา',
    province: 'อำนาจเจริญ',
    zipcode: 37110,
  },
  {
    district: 'พนา',
    province: 'อำนาจเจริญ',
    zipcode: 37180,
  },
  {
    district: 'ลืออำนาจ',
    province: 'อำนาจเจริญ',
    zipcode: 37120,
  },
  {
    district: 'หัวตะพาน',
    province: 'อำนาจเจริญ',
    zipcode: 37240,
  },
  {
    district: 'เมืองอำนาจเจริญ',
    province: 'อำนาจเจริญ',
    zipcode: 37000,
  },
  {
    district: 'เสนางคนิคม',
    province: 'อำนาจเจริญ',
    zipcode: 37290,
  },
  {
    district: 'กุดจับ',
    province: 'อุดรธานี',
    zipcode: 41250,
  },
  {
    district: 'กุมภวาปี',
    province: 'อุดรธานี',
    zipcode: 41110,
  },
  {
    district: 'กุมภวาปี',
    province: 'อุดรธานี',
    zipcode: 41370,
  },
  {
    district: 'กู่แก้ว',
    province: 'อุดรธานี',
    zipcode: 41130,
  },
  {
    district: 'ทุ่งฝน',
    province: 'อุดรธานี',
    zipcode: 41310,
  },
  {
    district: 'นายูง',
    province: 'อุดรธานี',
    zipcode: 41380,
  },
  {
    district: 'น้ำโสม',
    province: 'อุดรธานี',
    zipcode: 41210,
  },
  {
    district: 'บ้านดุง',
    province: 'อุดรธานี',
    zipcode: 41190,
  },
  {
    district: 'บ้านผือ',
    province: 'อุดรธานี',
    zipcode: 41160,
  },
  {
    district: 'ประจักษ์ศิลปาคม',
    province: 'อุดรธานี',
    zipcode: 41110,
  },
  {
    district: 'พิบูลย์รักษ์',
    province: 'อุดรธานี',
    zipcode: 41130,
  },
  {
    district: 'วังสามหมอ',
    province: 'อุดรธานี',
    zipcode: 41280,
  },
  {
    district: 'ศรีธาตุ',
    province: 'อุดรธานี',
    zipcode: 41230,
  },
  {
    district: 'สร้างคอม',
    province: 'อุดรธานี',
    zipcode: 41260,
  },
  {
    district: 'หนองวัวซอ',
    province: 'อุดรธานี',
    zipcode: 41220,
  },
  {
    district: 'หนองวัวซอ',
    province: 'อุดรธานี',
    zipcode: 41360,
  },
  {
    district: 'หนองหาน',
    province: 'อุดรธานี',
    zipcode: 41130,
  },
  {
    district: 'หนองหาน',
    province: 'อุดรธานี',
    zipcode: 41320,
  },
  {
    district: 'หนองแสง',
    province: 'อุดรธานี',
    zipcode: 41340,
  },
  {
    district: 'เพ็ญ',
    province: 'อุดรธานี',
    zipcode: 41150,
  },
  {
    district: 'เมืองอุดรธานี',
    province: 'อุดรธานี',
    zipcode: 41000,
  },
  {
    district: 'เมืองอุดรธานี',
    province: 'อุดรธานี',
    zipcode: 41330,
  },
  {
    district: 'โนนสะอาด',
    province: 'อุดรธานี',
    zipcode: 41240,
  },
  {
    district: 'ไชยวาน',
    province: 'อุดรธานี',
    zipcode: 41290,
  },
  {
    district: 'ตรอน',
    province: 'อุตรดิตถ์',
    zipcode: 53140,
  },
  {
    district: 'ทองแสนขัน',
    province: 'อุตรดิตถ์',
    zipcode: 53230,
  },
  {
    district: 'ท่าปลา',
    province: 'อุตรดิตถ์',
    zipcode: 53150,
  },
  {
    district: 'น้ำปาด',
    province: 'อุตรดิตถ์',
    zipcode: 53110,
  },
  {
    district: 'ท่าปลา',
    province: 'อุตรดิตถ์',
    zipcode: 53190,
  },
  {
    district: 'น้ำปาด',
    province: 'อุตรดิตถ์',
    zipcode: 53110,
  },
  {
    district: 'บ้านโคก',
    province: 'อุตรดิตถ์',
    zipcode: 53180,
  },
  {
    district: 'พิชัย',
    province: 'อุตรดิตถ์',
    zipcode: 53120,
  },
  {
    district: 'พิชัย',
    province: 'อุตรดิตถ์',
    zipcode: 53220,
  },
  {
    district: 'ฟากท่า',
    province: 'อุตรดิตถ์',
    zipcode: 53160,
  },
  {
    district: 'ลับแล',
    province: 'อุตรดิตถ์',
    zipcode: 53130,
  },
  {
    district: 'ลับแล',
    province: 'อุตรดิตถ์',
    zipcode: 53210,
  },
  {
    district: 'เมืองอุตรดิตถ์',
    province: 'อุตรดิตถ์',
    zipcode: 53000,
  },
  {
    district: 'เมืองอุตรดิตถ์',
    province: 'อุตรดิตถ์',
    zipcode: 53170,
  },
  {
    district: 'ทัพทัน',
    province: 'อุทัยธานี',
    zipcode: 61120,
  },
  {
    district: 'บ้านไร่',
    province: 'อุทัยธานี',
    zipcode: 61140,
  },
  {
    district: 'บ้านไร่',
    province: 'อุทัยธานี',
    zipcode: 61180,
  },
  {
    district: 'บ้านไร่',
    province: 'อุทัยธานี',
    zipcode: 61150,
  },
  {
    district: 'ลานสัก',
    province: 'อุทัยธานี',
    zipcode: 61160,
  },
  {
    district: 'สว่างอารมณ์',
    province: 'อุทัยธานี',
    zipcode: 61150,
  },
  {
    district: 'หนองขาหย่าง',
    province: 'อุทัยธานี',
    zipcode: 61130,
  },
  {
    district: 'หนองฉาง',
    province: 'อุทัยธานี',
    zipcode: 61110,
  },
  {
    district: 'หนองฉาง',
    province: 'อุทัยธานี',
    zipcode: 61170,
  },
  {
    district: 'ห้วยคต',
    province: 'อุทัยธานี',
    zipcode: 61170,
  },
  {
    district: 'เมืองอุทัยธานี',
    province: 'อุทัยธานี',
    zipcode: 61000,
  },
  {
    district: 'กุดข้าวปุ้น',
    province: 'อุบลราชธานี',
    zipcode: 34270,
  },
  {
    district: 'ดอนมดแดง',
    province: 'อุบลราชธานี',
    zipcode: 34000,
  },
  {
    district: 'ตระการพืชผล',
    province: 'อุบลราชธานี',
    zipcode: 34130,
  },
  {
    district: 'ตาลสุม',
    province: 'อุบลราชธานี',
    zipcode: 34330,
  },
  {
    district: 'ทุ่งศรีอุดม',
    province: 'อุบลราชธานี',
    zipcode: 34160,
  },
  {
    district: 'นาจะหลวย',
    province: 'อุบลราชธานี',
    zipcode: 34280,
  },
  {
    district: 'นาตาล',
    province: 'อุบลราชธานี',
    zipcode: 34170,
  },
  {
    district: 'นาเยีย',
    province: 'อุบลราชธานี',
    zipcode: 34160,
  },
  {
    district: 'น้ำขุ่น',
    province: 'อุบลราชธานี',
    zipcode: 34260,
  },
  {
    district: 'น้ำยืน',
    province: 'อุบลราชธานี',
    zipcode: 34260,
  },
  {
    district: 'บุณฑริก',
    province: 'อุบลราชธานี',
    zipcode: 34230,
  },
  {
    district: 'พิบูลมังสาหาร',
    province: 'อุบลราชธานี',
    zipcode: 34110,
  },
  {
    district: 'ม่วงสามสิบ',
    province: 'อุบลราชธานี',
    zipcode: 34140,
  },
  {
    district: 'วารินชำราบ',
    province: 'อุบลราชธานี',
    zipcode: 34190,
  },
  {
    district: 'วารินชำราบ',
    province: 'อุบลราชธานี',
    zipcode: 34310,
  },
  {
    district: 'ศรีเมืองใหม่',
    province: 'อุบลราชธานี',
    zipcode: 34250,
  },
  {
    district: 'สว่างวีระวงศ์',
    province: 'อุบลราชธานี',
    zipcode: 34190,
  },
  {
    district: 'สำโรง',
    province: 'อุบลราชธานี',
    zipcode: 34360,
  },
  {
    district: 'สิรินธร',
    province: 'อุบลราชธานี',
    zipcode: 34350,
  },
  {
    district: 'เขมราฐ',
    province: 'อุบลราชธานี',
    zipcode: 34170,
  },
  {
    district: 'เขื่องใน',
    province: 'อุบลราชธานี',
    zipcode: 34320,
  },
  {
    district: 'เขื่องใน',
    province: 'อุบลราชธานี',
    zipcode: 34150,
  },
  {
    district: 'เดชอุดม',
    province: 'อุบลราชธานี',
    zipcode: 34160,
  },
  {
    district: 'เมืองอุบลราชธานี',
    province: 'อุบลราชธานี',
    zipcode: 34000,
  },
  {
    district: 'เหล่าเสือโก้ก',
    province: 'อุบลราชธานี',
    zipcode: 34000,
  },
  {
    district: 'โขงเจียม',
    province: 'อุบลราชธานี',
    zipcode: 34220,
  },
  {
    district: 'โพธิ์ไทร',
    province: 'อุบลราชธานี',
    zipcode: 34340,
  },
  {
    district: 'ป่าโมก',
    province: 'อ่างทอง',
    zipcode: 14130,
  },
  {
    district: 'วิเศษชัยชาญ',
    province: 'อ่างทอง',
    zipcode: 14110,
  },
  {
    district: 'สามโก้',
    province: 'อ่างทอง',
    zipcode: 14160,
  },
  {
    district: 'เมืองอ่างทอง',
    province: 'อ่างทอง',
    zipcode: 14000,
  },
  {
    district: 'แสวงหา',
    province: 'อ่างทอง',
    zipcode: 14150,
  },
  {
    district: 'โพธิ์ทอง',
    province: 'อ่างทอง',
    zipcode: 14120,
  },
  {
    district: 'ไชโย',
    province: 'อ่างทอง',
    zipcode: 14140,
  },
  {
    district: 'ขุนตาล',
    province: 'เชียงราย',
    zipcode: 57340,
  },
  {
    district: 'ดอยหลวง',
    province: 'เชียงราย',
    zipcode: 57110,
  },
  {
    district: 'ป่าแดด',
    province: 'เชียงราย',
    zipcode: 57190,
  },
  {
    district: 'พญาเม็งราย',
    province: 'เชียงราย',
    zipcode: 57290,
  },
  {
    district: 'พาน',
    province: 'เชียงราย',
    zipcode: 57120,
  },
  {
    district: 'พาน',
    province: 'เชียงราย',
    zipcode: 57280,
  },
  {
    district: 'พาน',
    province: 'เชียงราย',
    zipcode: 57250,
  },
  {
    district: 'เชียงของ',
    province: 'เชียงราย',
    zipcode: 57140,
  },
  {
    district: 'เชียงแสน',
    province: 'เชียงราย',
    zipcode: 57150,
  },
  {
    district: 'เทิง',
    province: 'เชียงราย',
    zipcode: 57160,
  },
  {
    district: 'เทิง',
    province: 'เชียงราย',
    zipcode: 57230,
  },
  {
    district: 'เมืองเชียงราย',
    province: 'เชียงราย',
    zipcode: 57000,
  },
  {
    district: 'เมืองเชียงราย',
    province: 'เชียงราย',
    zipcode: 57100,
  },
  {
    district: 'เวียงชัย',
    province: 'เชียงราย',
    zipcode: 57210,
  },
  {
    district: 'เวียงป่าเป้า',
    province: 'เชียงราย',
    zipcode: 57170,
  },
  {
    district: 'เวียงป่าเป้า',
    province: 'เชียงราย',
    zipcode: 57260,
  },
  {
    district: 'เวียงเชียงรุ้ง',
    province: 'เชียงราย',
    zipcode: 57210,
  },
  {
    district: 'เวียงแก่น',
    province: 'เชียงราย',
    zipcode: 57310,
  },
  {
    district: 'แม่จัน',
    province: 'เชียงราย',
    zipcode: 57110,
  },
  {
    district: 'แม่จัน',
    province: 'เชียงราย',
    zipcode: 57270,
  },
  {
    district: 'แม่จัน',
    province: 'เชียงราย',
    zipcode: 57240,
  },
  {
    district: 'แม่ฟ้าหลวง',
    province: 'เชียงราย',
    zipcode: 57240,
  },
  {
    district: 'แม่ฟ้าหลวง',
    province: 'เชียงราย',
    zipcode: 57110,
  },
  {
    district: 'แม่ลาว',
    province: 'เชียงราย',
    zipcode: 57250,
  },
  {
    district: 'แม่ลาว',
    province: 'เชียงราย',
    zipcode: 57000,
  },
  {
    district: 'แม่สรวย',
    province: 'เชียงราย',
    zipcode: 57180,
  },
  {
    district: 'แม่สาย',
    province: 'เชียงราย',
    zipcode: 57220,
  },
  {
    district: 'แม่สาย',
    province: 'เชียงราย',
    zipcode: 57130,
  },
  {
    district: 'กัลยาณิวัฒนา',
    province: 'เชียงใหม่',
    zipcode: 58130,
  },
  {
    district: 'จอมทอง',
    province: 'เชียงใหม่',
    zipcode: 50160,
  },
  {
    district: 'จอมทอง',
    province: 'เชียงใหม่',
    zipcode: 50240,
  },
  {
    district: 'ดอยสะเก็ด',
    province: 'เชียงใหม่',
    zipcode: 50220,
  },
  {
    district: 'ดอยหล่อ',
    province: 'เชียงใหม่',
    zipcode: 50160,
  },
  {
    district: 'ดอยเต่า',
    province: 'เชียงใหม่',
    zipcode: 50260,
  },
  {
    district: 'ฝาง',
    province: 'เชียงใหม่',
    zipcode: 50110,
  },
  {
    district: 'ฝาง',
    province: 'เชียงใหม่',
    zipcode: 50320,
  },
  {
    district: 'พร้าว',
    province: 'เชียงใหม่',
    zipcode: 50190,
  },
  {
    district: 'สะเมิง',
    province: 'เชียงใหม่',
    zipcode: 50250,
  },
  {
    district: 'สันกำแพง',
    province: 'เชียงใหม่',
    zipcode: 50130,
  },
  {
    district: 'สันทราย',
    province: 'เชียงใหม่',
    zipcode: 50210,
  },
  {
    district: 'สันทราย',
    province: 'เชียงใหม่',
    zipcode: 50290,
  },
  {
    district: 'สันป่าตอง',
    province: 'เชียงใหม่',
    zipcode: 50120,
  },
  {
    district: 'สารภี',
    province: 'เชียงใหม่',
    zipcode: 50140,
  },
  {
    district: 'หางดง',
    province: 'เชียงใหม่',
    zipcode: 50230,
  },
  {
    district: 'หางดง',
    province: 'เชียงใหม่',
    zipcode: 50340,
  },
  {
    district: 'อมก๋อย',
    province: 'เชียงใหม่',
    zipcode: 50310,
  },
  {
    district: 'ฮอด',
    province: 'เชียงใหม่',
    zipcode: 50240,
  },
  {
    district: 'เชียงดาว',
    province: 'เชียงใหม่',
    zipcode: 50170,
  },
  {
    district: 'เมืองเชียงใหม่',
    province: 'เชียงใหม่',
    zipcode: 50100,
  },
  {
    district: 'เมืองเชียงใหม่',
    province: 'เชียงใหม่',
    zipcode: 50300,
  },
  {
    district: 'เมืองเชียงใหม่',
    province: 'เชียงใหม่',
    zipcode: 50000,
  },
  {
    district: 'เมืองเชียงใหม่',
    province: 'เชียงใหม่',
    zipcode: 50200,
  },
  {
    district: 'เวียงแหง',
    province: 'เชียงใหม่',
    zipcode: 50350,
  },
  {
    district: 'แม่ริม',
    province: 'เชียงใหม่',
    zipcode: 50180,
  },
  {
    district: 'แม่ริม',
    province: 'เชียงใหม่',
    zipcode: 50330,
  },
  {
    district: 'แม่วาง',
    province: 'เชียงใหม่',
    zipcode: 50360,
  },
  {
    district: 'แม่ออน',
    province: 'เชียงใหม่',
    zipcode: 50130,
  },
  {
    district: 'แม่อาย',
    province: 'เชียงใหม่',
    zipcode: 50280,
  },
  {
    district: 'แม่แจ่ม',
    province: 'เชียงใหม่',
    zipcode: 50270,
  },
  {
    district: 'แม่แตง',
    province: 'เชียงใหม่',
    zipcode: 50150,
  },
  {
    district: 'แม่แตง',
    province: 'เชียงใหม่',
    zipcode: 50330,
  },
  {
    district: 'ไชยปราการ',
    province: 'เชียงใหม่',
    zipcode: 50320,
  },
  {
    district: 'ชะอำ',
    province: 'เพชรบุรี',
    zipcode: 76120,
  },
  {
    district: 'ท่ายาง',
    province: 'เพชรบุรี',
    zipcode: 76130,
  },
  {
    district: 'บ้านลาด',
    province: 'เพชรบุรี',
    zipcode: 76150,
  },
  {
    district: 'บ้านแหลม',
    province: 'เพชรบุรี',
    zipcode: 76110,
  },
  {
    district: 'บ้านแหลม',
    province: 'เพชรบุรี',
    zipcode: 76100,
  },
  {
    district: 'หนองหญ้าปล้อง',
    province: 'เพชรบุรี',
    zipcode: 76160,
  },
  {
    district: 'เขาย้อย',
    province: 'เพชรบุรี',
    zipcode: 76140,
  },
  {
    district: 'เมืองเพชรบุรี',
    province: 'เพชรบุรี',
    zipcode: 76000,
  },
  {
    district: 'เมืองเพชรบุรี',
    province: 'เพชรบุรี',
    zipcode: 76100,
  },
  {
    district: 'แก่งกระจาน',
    province: 'เพชรบุรี',
    zipcode: 76170,
  },
  {
    district: 'ชนแดน',
    province: 'เพชรบูรณ์',
    zipcode: 67150,
  },
  {
    district: 'ชนแดน',
    province: 'เพชรบูรณ์',
    zipcode: 67190,
  },
  {
    district: 'น้ำหนาว',
    province: 'เพชรบูรณ์',
    zipcode: 67260,
  },
  {
    district: 'บึงสามพัน',
    province: 'เพชรบูรณ์',
    zipcode: 67160,
  },
  {
    district: 'บึงสามพัน',
    province: 'เพชรบูรณ์',
    zipcode: 67230,
  },
  {
    district: 'วังโป่ง',
    province: 'เพชรบูรณ์',
    zipcode: 67240,
  },
  {
    district: 'วิเชียรบุรี',
    province: 'เพชรบูรณ์',
    zipcode: 67180,
  },
  {
    district: 'วิเชียรบุรี',
    province: 'เพชรบูรณ์',
    zipcode: 67130,
  },
  {
    district: 'ศรีเทพ',
    province: 'เพชรบูรณ์',
    zipcode: 67170,
  },
  {
    district: 'หนองไผ่',
    province: 'เพชรบูรณ์',
    zipcode: 67140,
  },
  {
    district: 'หนองไผ่',
    province: 'เพชรบูรณ์',
    zipcode: 67220,
  },
  {
    district: 'หล่มสัก',
    province: 'เพชรบูรณ์',
    zipcode: 67110,
  },
  {
    district: 'หล่มเก่า',
    province: 'เพชรบูรณ์',
    zipcode: 67120,
  },
  {
    district: 'เขาค้อ',
    province: 'เพชรบูรณ์',
    zipcode: 67270,
  },
  {
    district: 'เขาค้อ',
    province: 'เพชรบูรณ์',
    zipcode: 67280,
  },
  {
    district: 'เมืองเพชรบูรณ์',
    province: 'เพชรบูรณ์',
    zipcode: 67000,
  },
  {
    district: 'เมืองเพชรบูรณ์',
    province: 'เพชรบูรณ์',
    zipcode: 67250,
  },
  {
    district: 'เมืองเพชรบูรณ์',
    province: 'เพชรบูรณ์',
    zipcode: 67210,
  },
  {
    district: 'ด่านซ้าย',
    province: 'เลย',
    zipcode: 42120,
  },
  {
    district: 'ท่าลี่',
    province: 'เลย',
    zipcode: 42140,
  },
  {
    district: 'นาด้วง',
    province: 'เลย',
    zipcode: 42210,
  },
  {
    district: 'นาแห้ว',
    province: 'เลย',
    zipcode: 42170,
  },
  {
    district: 'ปากชม',
    province: 'เลย',
    zipcode: 42150,
  },
  {
    district: 'ผาขาว',
    province: 'เลย',
    zipcode: 42240,
  },
  {
    district: 'ภูกระดึง',
    province: 'เลย',
    zipcode: 42180,
  },
  {
    district: 'ภูหลวง',
    province: 'เลย',
    zipcode: 42230,
  },
  {
    district: 'ภูเรือ',
    province: 'เลย',
    zipcode: 42160,
  },
  {
    district: 'วังสะพุง',
    province: 'เลย',
    zipcode: 42130,
  },
  {
    district: 'หนองหิน',
    province: 'เลย',
    zipcode: 42190,
  },
  {
    district: 'เชียงคาน',
    province: 'เลย',
    zipcode: 42110,
  },
  {
    district: 'เมืองเลย',
    province: 'เลย',
    zipcode: 42000,
  },
  {
    district: 'เมืองเลย',
    province: 'เลย',
    zipcode: 42100,
  },
  {
    district: 'เอราวัณ',
    province: 'เลย',
    zipcode: 42220,
  },
  {
    district: 'ร้องกวาง',
    province: 'แพร่',
    zipcode: 54140,
  },
  {
    district: 'ลอง',
    province: 'แพร่',
    zipcode: 54150,
  },
  {
    district: 'วังชิ้น',
    province: 'แพร่',
    zipcode: 54160,
  },
  {
    district: 'สอง',
    province: 'แพร่',
    zipcode: 54120,
  },
  {
    district: 'สูงเม่น',
    province: 'แพร่',
    zipcode: 54130,
  },
  {
    district: 'สูงเม่น',
    province: 'แพร่',
    zipcode: 54000,
  },
  {
    district: 'หนองม่วงไข่',
    province: 'แพร่',
    zipcode: 54170,
  },
  {
    district: 'เด่นชัย',
    province: 'แพร่',
    zipcode: 54110,
  },
  {
    district: 'เมืองแพร่',
    province: 'แพร่',
    zipcode: 54000,
  },
  {
    district: 'ขุนยวม',
    province: 'แม่ฮ่องสอน',
    zipcode: 58140,
  },
  {
    district: 'ปางมะผ้า',
    province: 'แม่ฮ่องสอน',
    zipcode: 58150,
  },
  {
    district: 'ปาย',
    province: 'แม่ฮ่องสอน',
    zipcode: 58130,
  },
  {
    district: 'สบเมย',
    province: 'แม่ฮ่องสอน',
    zipcode: 58110,
  },
  {
    district: 'เมืองแม่ฮ่องสอน',
    province: 'แม่ฮ่องสอน',
    zipcode: 58000,
  },
  {
    district: 'แม่ลาน้อย',
    province: 'แม่ฮ่องสอน',
    zipcode: 58120,
  },
  {
    district: 'แม่สะเรียง',
    province: 'แม่ฮ่องสอน',
    zipcode: 58110,
  },
];

export default THAI_ADDRESS_LIST;
