import React, { FC, useContext, useState } from 'react';

import { Box, Container, Divider, Grid, Paper, Typography } from '@material-ui/core';
import ListIcon from '@material-ui/icons/List';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import PublishIcon from '@material-ui/icons/Publish';
import SyncIcon from '@material-ui/icons/Sync';
import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';

import IconCard from '../../components/partials/common/IconCard';
import ModelUploadFileWeeklySellerReport from '../../components/partials/master-data/ModelUploadFileWeeklySellerReport';
import ModelUploadFileSSDictionary from '../../components/partials/master-data/suggestion/ModalUploadFileSSDictionary';
import AppButton from '../../components/ui/AppButton';
import AppDialog from '../../components/ui/AppDialog';
import { AppGlobalUiContext } from '../../context/AppGlobalUiContext';
import usePrivilege from '../../hooks/usePrivilege';
import { syncElasticsearchBrandsData } from '../../services/brand';
import { syncElasticsearchCategoriesData } from '../../services/category';
import { syncElasticsearchDictsData } from '../../services/dict';
import { syncElasticsearchModelsData } from '../../services/model';
import { syncElasticsearchTagData } from '../../services/tag';
import useAppContainerStyles from '../../theme/container.style';
import useTypographyStyles from '../../theme/typography.style';

enum ELASTICSEARCH_SYNC {
  BRAND = 'brand',
  CATEGORY = 'category',
  MODEL = 'model',
  DICT = 'dict',
  TAG = 'tag',
}

const MasterDataList: FC = () => {
  const { t } = useTranslation();
  const { showSnackbar, setAppLoading } = useContext(AppGlobalUiContext);
  const { path } = useRouteMatch();
  const containerClasses = useAppContainerStyles();
  const typoClasses = useTypographyStyles();
  const { canPerform } = usePrivilege();
  const canPerformOperations = canPerform('masterDataOps', 'update');
  const [typeElasticsearchDataSyncDlgOpen, setTypeElasticsearchDataSyncDlgOpen] = useState<ELASTICSEARCH_SYNC | null>();

  const [isUploadSearchSuggestionDialogOpen, setIsUploadSearchSuggestionDialogOpen] = useState<boolean>(false);
  const [isDialogUploadReportOpen, setIsDialogUploadReportOpen] = useState<boolean>(false);

  const masterDataESSyncClickHandler = () => {
    if (!typeElasticsearchDataSyncDlgOpen) {
      return;
    }
    setAppLoading(true);
    (async () => {
      const result = await {
        [ELASTICSEARCH_SYNC.BRAND]: syncElasticsearchBrandsData,
        [ELASTICSEARCH_SYNC.CATEGORY]: syncElasticsearchCategoriesData,
        [ELASTICSEARCH_SYNC.MODEL]: syncElasticsearchModelsData,
        [ELASTICSEARCH_SYNC.DICT]: syncElasticsearchDictsData,
        [ELASTICSEARCH_SYNC.TAG]: syncElasticsearchTagData,
      }[typeElasticsearchDataSyncDlgOpen]();

      setAppLoading(false);
      setTypeElasticsearchDataSyncDlgOpen(null);
      if (result.error) {
        showSnackbar(t(result.error).toString(), 'error');
      } else {
        showSnackbar(t('master-data:error.operations.dataSyncSuccess').toString(), 'success');
      }
    })();
  };

  return (
    <Container className={containerClasses.container}>
      <Box py={3}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Link to={`${path}/master-index`} className={typoClasses.noDecorationText}>
              <IconCard title="master-data:menu.masterIndex" icon={<ListIcon />} style={{ minHeight: '100%' }} />
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Link to={`${path}/brands`} className={typoClasses.noDecorationText}>
              <IconCard title="master-data:menu.brand" icon={<LocalOfferIcon />} style={{ minHeight: '100%' }} />
            </Link>
          </Grid>
        </Grid>
      </Box>
      {canPerformOperations && (
        <>
          <Divider />
          <Box mb={2} mt={1}>
            <Typography className={typoClasses.textWeightBold} variant="h6">
              {t('master-data:message.operations.title')}
            </Typography>
          </Box>
          <Paper>
            <Box p={3}>
              <Typography className={typoClasses.textWeightBold} variant="subtitle2">
                {t('master-data:message.operations.masterDataEsSyncDesc')}
              </Typography>

              <Box mt={2}>
                <Grid container spacing={1}>
                  <Grid item xs="auto">
                    <AppButton
                      variant="outlined"
                      color="warning"
                      onClick={() => setTypeElasticsearchDataSyncDlgOpen(ELASTICSEARCH_SYNC.BRAND)}
                      startIcon={<SyncIcon />}>
                      {t('master-data:message.operations.brandEsSyncBtn')}
                    </AppButton>
                  </Grid>
                  <Grid item xs="auto">
                    <AppButton
                      variant="outlined"
                      color="warning"
                      onClick={() => setTypeElasticsearchDataSyncDlgOpen(ELASTICSEARCH_SYNC.CATEGORY)}
                      startIcon={<SyncIcon />}>
                      {t('master-data:message.operations.categoryEsSyncBtn')}
                    </AppButton>
                  </Grid>
                  <Grid item xs="auto">
                    <AppButton
                      variant="outlined"
                      color="warning"
                      onClick={() => setTypeElasticsearchDataSyncDlgOpen(ELASTICSEARCH_SYNC.MODEL)}
                      startIcon={<SyncIcon />}>
                      {t('master-data:message.operations.modelEsSyncBtn')}
                    </AppButton>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Paper>

          <Paper>
            <Box p={3} mt={3}>
              <Grid container spacing={3}>
                <Grid item md={7}>
                  <Box>
                    <Typography className={typoClasses.textWeightBold} variant="subtitle2">
                      {t('master-data:message.operations.searchDictEsSyncTitle')}
                    </Typography>
                    <Box mt={2}>
                      <Grid container spacing={1}>
                        <Grid item xs="auto">
                          <AppButton
                            variant="outlined"
                            color="warning"
                            onClick={() => setTypeElasticsearchDataSyncDlgOpen(ELASTICSEARCH_SYNC.DICT)}
                            startIcon={<SyncIcon />}>
                            {t('master-data:message.operations.searchDictEsSyncBtn')}
                          </AppButton>
                        </Grid>
                        <Grid item xs="auto">
                          <AppButton
                            variant="outlined"
                            color="warning"
                            onClick={() => setIsUploadSearchSuggestionDialogOpen(true)}
                            startIcon={<PublishIcon />}>
                            {t('master-data:message.operations.uploadSearchSuggestionEsSyncBtn')}
                          </AppButton>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={5}>
                  <Box>
                    <Typography className={typoClasses.textWeightBold} variant="subtitle2">
                      {t('master-data:message.operations.weeklySellerReportTitle')}
                    </Typography>
                    <Box mt={2}>
                      <AppButton
                        variant="outlined"
                        color="warning"
                        onClick={() => setIsDialogUploadReportOpen(true)}
                        startIcon={<PublishIcon />}>
                        {t('master-data:message.operations.uploadWeeklySellerReportToS3')}
                      </AppButton>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Paper>

          <Paper>
            <Box p={3} mt={3}>
              <Typography className={typoClasses.textWeightBold} variant="subtitle2">
                {t('master-data:message.operations.snsTagsEsSyncTitle')}
              </Typography>
              <Box display="flex" flexDirection="row" mt={2}>
                <AppButton
                  variant="outlined"
                  color="warning"
                  onClick={() => setTypeElasticsearchDataSyncDlgOpen(ELASTICSEARCH_SYNC.TAG)}
                  startIcon={<SyncIcon />}>
                  {t('master-data:message.operations.snsTagsEsSyncBtn')}
                </AppButton>
              </Box>
            </Box>
          </Paper>

          <AppDialog
            open={Boolean(typeElasticsearchDataSyncDlgOpen)}
            type="warning"
            okButtonText="common:button.confirm"
            okButtonColor="warning"
            onOkClick={() => masterDataESSyncClickHandler()}
            cancelButtonText="common:button.cancel"
            onClose={() => setTypeElasticsearchDataSyncDlgOpen(null)}
            dialogProps={{
              disableBackdropClick: true,
            }}>
            <Typography>{t('master-data:message.operations.esSyncConfirm')}</Typography>
          </AppDialog>

          <AppDialog
            type="normal"
            open={isUploadSearchSuggestionDialogOpen}
            title="common:dialog.title.uploadSearchSuggestion-dialog"
            okButtonText="common:button.upload"
            noActionBar>
            <ModelUploadFileSSDictionary onClose={() => setIsUploadSearchSuggestionDialogOpen(false)} />
          </AppDialog>

          <AppDialog
            type="normal"
            open={isDialogUploadReportOpen}
            title="master-data:dialog.uploadWeeklySellerReportToS3"
            okButtonText="common:button.upload"
            noActionBar>
            <ModelUploadFileWeeklySellerReport onClose={() => setIsDialogUploadReportOpen(false)} />
          </AppDialog>
        </>
      )}
    </Container>
  );
};

export default MasterDataList;
