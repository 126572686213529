import React, { forwardRef } from 'react';

import { Button, ButtonProps, makeStyles, PropTypes } from '@material-ui/core';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';

import { colors } from '../../../theme/colors';

type MaterialButtonType = Omit<ButtonProps, 'color'>;
type ButtonColor = 'inherit' | 'default' | 'primary' | 'secondary' | 'success' | 'error' | 'warning' | 'info';

export type AppButtonProps = MaterialButtonType & {
  color?: ButtonColor;
  url?: string;
  normalLink?: boolean;
};

const disabledContainedStyle = {
  '&:disabled': {
    backgroundColor: colors.disabled.dark,
    color: colors.disabled.contrastText,
  },
};
const disabledOutlinedStyle = {
  '&:disabled': {
    borderColor: colors.disabled.dark,
    color: colors.disabled.dark,
  },
};
const disabledTextStyle = {
  '&:disabled': {
    color: colors.disabled.dark,
  },
};

const useButtonStyles = makeStyles((theme) => ({
  containedPrimary: {
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    ...disabledContainedStyle,
  },
  outlinedPrimary: {
    borderColor: theme.palette.primary.main,
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    },
    ...disabledOutlinedStyle,
  },
  textPrimary: {
    borderColor: 'transparent',
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.dark,
    },
    ...disabledTextStyle,
  },
  containedSecondary: {
    borderColor: theme.palette.secondary.main,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
    ...disabledContainedStyle,
  },
  outlinedSecondary: {
    borderColor: theme.palette.secondary.main,
    backgroundColor: 'transparent',
    color: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.secondary.contrastText,
    },
    ...disabledOutlinedStyle,
  },
  textSecondary: {
    borderColor: 'transparent',
    backgroundColor: 'transparent',
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.secondary.dark,
    },
    ...disabledTextStyle,
  },
  containedSuccess: {
    borderColor: theme.palette.success.main,
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
    ...disabledContainedStyle,
  },
  outlinedSuccess: {
    borderColor: theme.palette.success.main,
    backgroundColor: 'transparent',
    color: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
      color: theme.palette.success.contrastText,
    },
    ...disabledOutlinedStyle,
  },
  textSuccess: {
    borderColor: 'transparent',
    backgroundColor: 'transparent',
    color: theme.palette.success.main,
    '&:hover': {
      color: theme.palette.success.dark,
    },
    ...disabledTextStyle,
  },
  containedError: {
    borderColor: theme.palette.error.main,
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
    ...disabledContainedStyle,
  },
  outlinedError: {
    borderColor: theme.palette.error.main,
    backgroundColor: 'transparent',
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: theme.palette.error.contrastText,
    },
    ...disabledOutlinedStyle,
  },
  textError: {
    borderColor: 'transparent',
    backgroundColor: 'transparent',
    color: theme.palette.error.main,
    '&:hover': {
      color: theme.palette.error.dark,
    },
    ...disabledTextStyle,
  },
  containedWarning: {
    borderColor: theme.palette.warning.main,
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.warning.dark,
    },
    ...disabledContainedStyle,
  },
  outlinedWarning: {
    borderColor: theme.palette.warning.main,
    backgroundColor: 'transparent',
    color: theme.palette.warning.main,
    '&:hover': {
      backgroundColor: theme.palette.warning.dark,
      color: theme.palette.warning.contrastText,
    },
    ...disabledOutlinedStyle,
  },
  textWarning: {
    borderColor: 'transparent',
    backgroundColor: 'transparent',
    color: theme.palette.warning.main,
    '&:hover': {
      color: theme.palette.warning.dark,
    },
    ...disabledTextStyle,
  },
  containedInfo: {
    borderColor: theme.palette.info.main,
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.info.dark,
    },
    ...disabledContainedStyle,
  },
  outlinedInfo: {
    borderColor: theme.palette.info.main,
    backgroundColor: 'transparent',
    color: theme.palette.info.main,
    '&:hover': {
      backgroundColor: theme.palette.info.dark,
      color: theme.palette.info.contrastText,
    },
    ...disabledOutlinedStyle,
  },
  textInfo: {
    borderColor: 'transparent',
    backgroundColor: 'transparent',
    color: theme.palette.info.main,
    '&:hover': {
      color: theme.palette.info.dark,
    },
    ...disabledTextStyle,
  },
  // Below are required to override original disabled styling of Material UI Buttons
  disabledContained: {
    ...disabledContainedStyle,
  },
  disabledOutlined: {
    ...disabledOutlinedStyle,
  },
  disabledText: {
    ...disabledTextStyle,
  },
}));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomButtonLink = forwardRef((linkProps: { href: string }, ref: any) => {
  const { href, ...restLinkProps } = linkProps;
  return <NavLink to={href} ref={ref} {...restLinkProps} />;
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AppButton = forwardRef<any, AppButtonProps>((props, ref) => {
  const classes = useButtonStyles();
  const { url, normalLink, children, variant = 'contained', color = 'default', classes: elemClasses, ...rest } = props;
  const colorKey = `${variant}-${color}`;
  const isAltColor = !['inherit', 'default'].includes(color);
  const linkComponent = normalLink ? 'a' : CustomButtonLink;

  // Alternative color list aside from Material UI default list
  const colorList: { [x: string]: string } = {
    'contained-primary': classes.containedPrimary,
    'outlined-primary': classes.outlinedPrimary,
    'text-primary': classes.textPrimary,
    'contained-secondary': classes.containedSecondary,
    'outlined-secondary': classes.outlinedSecondary,
    'text-secondary': classes.textSecondary,
    'contained-success': classes.containedSuccess,
    'outlined-success': classes.outlinedSuccess,
    'text-success': classes.textSuccess,
    'contained-error': classes.containedError,
    'outlined-error': classes.outlinedError,
    'text-error': classes.textError,
    'contained-warning': classes.containedWarning,
    'outlined-warning': classes.outlinedWarning,
    'text-warning': classes.textWarning,
    'contained-info': classes.containedInfo,
    'outlined-info': classes.outlinedInfo,
    'text-info': classes.textInfo,
  };

  // For those buttons with original variant and color, use the override styled declared above via makeStyles.
  const defaultDisabledClass: { [x: string]: string } = {
    contained: classes.disabledContained,
    outlined: classes.disabledOutlined,
    text: classes.disabledText,
  };

  const rootClass = clsx(elemClasses?.root || null, defaultDisabledClass[variant], {
    [colorList[colorKey]]: isAltColor,
  });

  // Combine with another input 'classes' property
  const componentClasses = { ...(elemClasses || {}), root: rootClass };

  return isAltColor ? (
    <Button
      variant={variant}
      classes={componentClasses}
      component={url ? linkComponent : 'button'}
      {...rest}
      href={url}
      ref={ref}>
      {children}
    </Button>
  ) : (
    <Button
      variant={variant}
      classes={componentClasses}
      component={url ? linkComponent : 'button'}
      color={color as PropTypes.Color}
      {...rest}
      href={url}
      ref={ref}>
      {children}
    </Button>
  );
});

export default AppButton;
