import React, { FC, useContext, useState } from 'react';

import { useTranslation } from 'react-i18next';
import useDeepCompareEffect from 'use-deep-compare-effect';

import UserListSearchForm from '../../components/partials/users/UserListSearchForm';
import UserListTable from '../../components/partials/users/UserListTable';
import { AppGlobalUiContext } from '../../context/AppGlobalUiContext';
import { SearchFilterContext } from '../../context/SearchFilterContext';
import { defaultRowsPerPage } from '../../helpers/constants';
import { AppTableConditions, UserData, UserListSearchFormData, UserListSearchParams } from '../../models';
import { getUserList } from '../../services';

type UserListPageProps = unknown;

const searchFilterContextKey = 'users';

const UserList: FC<UserListPageProps> = () => {
  const { t } = useTranslation();
  const { showSnackbar } = useContext(AppGlobalUiContext);
  const [userList, setUserList] = useState<UserData[]>([]);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [totalUsers, setTotalUsers] = useState<number>(0);

  // start filling default search filters (either from the one we saved in context or fresh conditions)
  const { currentFilters: ctxFilters, setFilters } = useContext(SearchFilterContext);
  const currentFilters: UserListSearchParams = ctxFilters ? ctxFilters[searchFilterContextKey] : undefined;
  const [searchFilters, setSearchFilters] = useState<UserListSearchFormData>({
    searchField: currentFilters?.searchField,
    searchKeyword: currentFilters?.searchKeyword || '',
    minCoin: currentFilters?.minCoin || '',
    maxCoin: currentFilters?.maxCoin || '',
    filteringRoles: currentFilters?.filteringRoles || [],
    filteringStatuses: currentFilters?.filteringStatuses || [],
    filteringTags: currentFilters?.filteringTags || [],
  });
  const [tableConditions, setTableConditions] = useState<AppTableConditions>({
    page: currentFilters?.page || 0,
    rowsPerPage: currentFilters?.rowsPerPage || defaultRowsPerPage,
    sortColumn: currentFilters?.sortColumn || 'createdAt',
    sortDirection: currentFilters?.sortDirection || 'desc',
  });

  const getCurrentSearchParam = (): UserListSearchParams => ({
    page: tableConditions.page || 0,
    rowsPerPage: tableConditions.rowsPerPage || defaultRowsPerPage,
    sortColumn: tableConditions.sortColumn || 'createdAt',
    sortDirection: tableConditions.sortDirection || 'desc',
    searchField: searchFilters.searchField,
    searchKeyword: searchFilters.searchKeyword,
    minCoin: searchFilters.minCoin,
    maxCoin: searchFilters.maxCoin,
    filteringRoles: searchFilters.filteringRoles,
    filteringStatuses: searchFilters.filteringStatuses,
    filteringTags: searchFilters.filteringTags,
  });

  const requestUserList = async (isPageSubscribed = true): Promise<boolean> => {
    if (isPageSubscribed) {
      setIsLoadingData(true);

      const searchConditions = getCurrentSearchParam();

      // Save search filter to search filter context
      setFilters(searchFilterContextKey, searchConditions);

      const result = await getUserList(searchConditions);

      setIsLoadingData(false);

      if (result.data) {
        setUserList(result.data.users);
        setTotalUsers(result.data.totalCount);
        return Promise.resolve(true);
      }

      showSnackbar(t(result.error as string).toString(), 'error');
      return Promise.reject(result.error);
    }

    return false;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useDeepCompareEffect((): any => {
    let isSubscribed = true;

    requestUserList(isSubscribed);

    return () => (isSubscribed = false);
  }, [tableConditions, searchFilters]);

  const tableConditionsChangedHandler = (values: AppTableConditions) => {
    setTableConditions(values);
  };

  const onSearchFormSubmit = (filters: UserListSearchFormData) => {
    if (isLoadingData) {
      showSnackbar(t('common.message.pleaseWaitForDataToFinish').toString(), 'warning');
      return;
    }

    if (tableConditions.page !== 0) {
      tableConditions.page = 0;
    }

    setSearchFilters(filters);
  };

  return (
    <>
      <UserListSearchForm
        isLoadingData={isLoadingData}
        currentFilters={searchFilters}
        onSearchFormSubmit={onSearchFormSubmit}
      />
      <UserListTable
        currentConditions={tableConditions}
        userList={userList}
        totalUsers={totalUsers}
        isLoadingData={isLoadingData}
        onTableConditionsChanged={tableConditionsChangedHandler}
      />
    </>
  );
};

export default UserList;
