import { apiRequest } from '../helpers/request';
import { queryParamGenerator } from '../helpers/utils';
import { ApiResponse } from '../models';
import {
  BrandItemData,
  DeleteListingResponse,
  DraftListingResponse,
  GetBrandsResponse,
  GetListingListResponse,
  GetListingListResponseV2,
  GetModelsResponse,
  GetViewCountResponse,
  LatestBoostResponse,
  ListingData,
  ListingDataV2,
  ListingFormData,
  ListingFormDataV2,
  ListingSearchFilters,
  ListingSearchFiltersV1,
  ModelItemData,
  PossessionInfo,
  PossessionListingResponse,
  PublishListingResponse,
  RejectListingResponse,
  RejectPossessionListingResponse,
  SoldInfo,
  UpdateListingResponse,
  UpdateStatusMultiListingFormDataRequest,
} from '../models/listing.model';

const getListingQueryParam = (param?: ListingSearchFilters): string => {
  const reqParam: { [x: string]: string | number } = {
    page: (param?.page || 0) + 1, // plus 1 for index shift between FE component and backend query
    per_page: param?.rowsPerPage || 10,
    sort: param?.sortColumn ? `${param.sortColumn}|${(param?.sortDirection || 'desc').toUpperCase()}` : '',
  };

  // Only search with keyword when it comes with the selected column to be searched
  if (param?.searchKeyword && param?.searchField) {
    reqParam[param.searchField] = param.searchKeyword.trim();
  }
  if (param?.filteringStatuses?.length) {
    reqParam.status = param.filteringStatuses.join(',');
  }
  if (param?.filteringPossessions?.length) {
    reqParam.possession = param.filteringPossessions.join(',');
  }
  if (param?.filteringCrStatuses?.length) {
    reqParam.crStatus = param.filteringCrStatuses.join(',');
  }
  if (param?.categoryQuery) {
    reqParam.categoryQuery = param.categoryQuery;
  }
  if (param?.brandQuery) {
    reqParam.brandQuery = param.brandQuery;
  }
  if (param?.modelQuery) {
    reqParam.modelQuery = param.modelQuery;
  }
  if (param?.createdDateFrom) {
    reqParam.createdDateFrom = param.createdDateFrom;
  }
  if (param?.createdDateTo) {
    reqParam.createdDateTo = param.createdDateTo;
  }
  if (param?.lowerFirstRegistrationYear) {
    reqParam.lowerFirstRegistrationYear = param.lowerFirstRegistrationYear;
  }
  if (param?.upperFirstRegistrationYear) {
    reqParam.upperFirstRegistrationYear = param.upperFirstRegistrationYear;
  }
  if (param?.lowerBoundPrice) {
    reqParam.lowerBoundPrice = param.lowerBoundPrice;
  }
  if (param?.upperBoundPrice) {
    reqParam.upperBoundPrice = param.upperBoundPrice;
  }

  return queryParamGenerator(reqParam);
};

const getListingQueryParamV1 = (param?: ListingSearchFiltersV1): string => {
  const reqParam: { [x: string]: string | number } = {
    page: (param?.page || 0) + 1, // plus 1 for index shift between FE component and backend query
    per_page: param?.rowsPerPage || 10,
    sort: param?.sortColumn ? `${param.sortColumn}|${(param?.sortDirection || 'desc').toUpperCase()}` : '',
  };

  // Only search with keyword when it comes with the selected column to be searched
  if (param?.searchKeyword && param?.searchField) {
    reqParam[param.searchField] = param.searchKeyword.trim();
  }
  if (param?.filteringStatuses?.length) {
    reqParam.status = param.filteringStatuses.join(',');
  }
  if (param?.filteringPossessions?.length) {
    reqParam.possession = param.filteringPossessions.join(',');
  }
  if (param?.filteringCrStatuses?.length) {
    reqParam.crStatus = param.filteringCrStatuses.join(',');
  }
  if (param?.rootCategory) {
    reqParam.rootCategoryHashId = param.rootCategory;
  }
  if (param?.subCategory) {
    reqParam.subCategoryHashId = param.subCategory;
  }
  if (param?.speCategory) {
    reqParam.spacialCategoryHashId = param.speCategory;
  }

  return queryParamGenerator(reqParam);
};

export const getListingV1 = async (param?: ListingSearchFilters): Promise<ApiResponse<GetListingListResponse>> => {
  try {
    const queryParamString = getListingQueryParamV1(param);
    const result = await apiRequest<ListingData[]>(`/admin/items?${queryParamString}`);

    return {
      data: {
        data: result.data,
        totalCount: Number(result.headers['x-total-count'] || '0') || result.data.length,
      },
    };
  } catch (e) {
    return {
      data: null,
      error: 'listing:error.list',
    };
  }
};

export const getListing = async (param?: ListingSearchFilters): Promise<ApiResponse<GetListingListResponseV2>> => {
  try {
    const queryParamString = getListingQueryParam(param);

    const result = await apiRequest<ListingDataV2>(`/admin/search/items/?${queryParamString}`);

    return {
      data: {
        data: result.data,
        totalCount: Number(result.headers['x-total-count'] || '0') || result.data.items.length,
      },
    };
  } catch (e) {
    return {
      data: null,
      error: 'listing:error.list',
    };
  }
};

export const getListingByHashId = async (itemHashId: string): Promise<ApiResponse<ListingData>> => {
  try {
    const result = await apiRequest<ListingData>(`/admin/items/${itemHashId}`);

    return {
      data: result.data,
    };
  } catch (e) {
    return {
      data: null,
      error: 'listing:common.message.getListingItemFailed',
    };
  }
};

export const getBrandsByRootCategoryHashId = async (hashId: string): Promise<ApiResponse<GetBrandsResponse>> => {
  try {
    const result = await apiRequest<BrandItemData[]>(`/category-master/${hashId}/brands/root`);

    return {
      data: {
        data: result.data,
      },
    };
  } catch (e) {
    return {
      data: null,
      error: 'listing:error.list',
    };
  }
};

export const getModelsByBrandHashId = async (hashId: string): Promise<ApiResponse<GetModelsResponse>> => {
  try {
    const result = await apiRequest<ModelItemData[]>(`/brand-master/${hashId}/models/group`);

    return {
      data: {
        data: result.data,
      },
    };
  } catch (e) {
    return {
      data: null,
      error: 'listing:error.list',
    };
  }
};

export const publishListingByHashId = async (hashId: string): Promise<ApiResponse<PublishListingResponse>> => {
  try {
    const result = await apiRequest<ListingData>(`/admin/items/${hashId}/publish`, {
      method: 'POST',
    });

    return {
      data: {
        data: result.data,
        status: result.status,
        statusText: result.statusText,
      },
    };
  } catch (e) {
    return {
      data: null,
      error: 'listing:error.list',
    };
  }
};

export const rejectListingByHashId = async (
  hashId: string,
  rejectReason: string,
): Promise<ApiResponse<RejectListingResponse>> => {
  try {
    const result = await apiRequest<ListingData>(`/admin/items/${hashId}/reject`, {
      method: 'POST',
      data: {
        rejectReason,
      },
    });

    return {
      data: {
        data: result.data,
        status: result.status,
        statusText: result.statusText,
      },
    };
  } catch (e) {
    return {
      data: null,
      error: 'listing:error.list',
    };
  }
};

export const draftListingByHashId = async (hashId: string): Promise<ApiResponse<DraftListingResponse>> => {
  try {
    const result = await apiRequest<ListingData>(`/admin/items/${hashId}/draft`, {
      method: 'POST',
    });

    return {
      data: {
        data: result.data,
        status: result.status,
        statusText: result.statusText,
      },
    };
  } catch (e) {
    return {
      data: null,
      error: 'listing:error.list',
    };
  }
};

export const updateListing = async (
  hashId: string,
  rawData: ListingFormData,
): Promise<ApiResponse<UpdateListingResponse>> => {
  try {
    const result = await apiRequest<ListingData>(`/admin/items/${hashId}`, {
      method: 'PATCH',
      data: rawData,
    });

    return {
      data: {
        data: result.data,
        status: result.status,
        statusText: result.statusText,
      },
    };
  } catch (e) {
    return {
      data: null,
      error: 'listing:error.list',
    };
  }
};

export const updateListingV2 = async (
  hashId: string,
  rawData: ListingFormDataV2,
): Promise<ApiResponse<UpdateListingResponse>> => {
  const { ...data } = rawData;

  try {
    const result = await apiRequest<ListingData>(`/admin/items/v2/${hashId}`, {
      method: 'PATCH',
      data,
    });

    return {
      data: {
        data: result.data,
        status: result.status,
        statusText: result.statusText,
      },
    };
  } catch (e) {
    return {
      data: null,
      error: 'listing:error.list',
    };
  }
};

export const deleteListingByHashId = async (hashId: string): Promise<ApiResponse<DeleteListingResponse>> => {
  try {
    const result = await apiRequest<ListingData>(`/admin/items/${hashId}`, { method: 'DELETE' });

    return {
      data: {
        data: result.data,
        status: result.status,
        statusText: result.statusText,
      },
    };
  } catch (e) {
    return {
      data: null,
      error: 'listing:error.list',
    };
  }
};

export const markAsUnlistedByHashId = async (
  hashId: string,
  notifyUserUnlistedItem: boolean,
): Promise<ApiResponse<DeleteListingResponse>> => {
  try {
    const result = await apiRequest<ListingData>(`/admin/items/${hashId}/unlist`, {
      method: 'POST',
      data: { notifyUserUnlistedItem },
    });

    return {
      data: result,
    };
  } catch (e) {
    return {
      data: null,
      error: 'listing:error.list',
    };
  }
};

export const markAsSoldListingByHashId = async (
  hashId: string,
  soldInfo: SoldInfo,
): Promise<ApiResponse<RejectListingResponse>> => {
  try {
    const result = await apiRequest<ListingData>(`/admin/items/${hashId}/sold`, {
      method: 'POST',
      data: soldInfo,
    });

    return {
      data: {
        data: result.data,
        status: result.status,
        statusText: result.statusText,
      },
    };
  } catch (e) {
    return {
      data: null,
      error: 'listing:error.list',
    };
  }
};

export const markAsBookedListingByHashId = async (hashId: string): Promise<ApiResponse<RejectListingResponse>> => {
  try {
    const result = await apiRequest<ListingData>(`/admin/items/${hashId}/booked`, {
      method: 'POST',
    });

    return {
      data: {
        data: result.data,
        status: result.status,
        statusText: result.statusText,
      },
    };
  } catch (e) {
    return {
      data: null,
      error: 'listing:error.list',
    };
  }
};

export const markAsPendingListingByHashId = async (hashId: string): Promise<ApiResponse<DeleteListingResponse>> => {
  try {
    const result = await apiRequest<ListingData>(`/admin/items/${hashId}/pending`, {
      method: 'POST',
    });

    return {
      data: result,
    };
  } catch (e) {
    return {
      data: null,
      error: 'listing:error.list',
    };
  }
};

export const approveJkkeListingByHashId = async (hashId: string): Promise<ApiResponse<PossessionListingResponse>> => {
  try {
    const result = await apiRequest<ListingData>(`/admin/items/${hashId}/possession/jkke`, {
      method: 'POST',
    });

    return {
      data: {
        data: result.data,
        status: result.status,
        statusText: result.statusText,
      },
    };
  } catch (e) {
    return {
      data: null,
      error: 'listing:error.list',
    };
  }
};

export const resetJkkeListingByHashId = async (hashId: string): Promise<ApiResponse<PossessionListingResponse>> => {
  try {
    const result = await apiRequest<ListingData>(`/admin/items/${hashId}/possession/jkke_reset`, {
      method: 'POST',
    });

    return {
      data: {
        data: result.data,
        status: result.status,
        statusText: result.statusText,
      },
    };
  } catch (e) {
    return {
      data: null,
      error: 'listing:error.list',
    };
  }
};

export const waitJkkeListingByHashId = async (hashId: string): Promise<ApiResponse<PossessionListingResponse>> => {
  try {
    const result = await apiRequest<ListingData>(`/admin/items/${hashId}/possession/jkke_waiting`, {
      method: 'POST',
    });

    return {
      data: {
        data: result.data,
        status: result.status,
        statusText: result.statusText,
      },
    };
  } catch (e) {
    return {
      data: null,
      error: 'listing:error.list',
    };
  }
};

export const rejectJkkeListingByHashId = async (
  hashId: string,
  possessionInfo: PossessionInfo,
): Promise<ApiResponse<RejectPossessionListingResponse>> => {
  try {
    const result = await apiRequest<ListingData>(`/admin/items/${hashId}/possession/jkke_rejected`, {
      method: 'POST',
      data: possessionInfo,
    });

    return {
      data: {
        data: result.data,
        status: result.status,
        statusText: result.statusText,
      },
    };
  } catch (e) {
    return {
      data: null,
      error: 'listing:error.list',
    };
  }
};

export const approveT2hOfficialListingByHashId = async (
  hashId: string,
): Promise<ApiResponse<PossessionListingResponse>> => {
  try {
    const result = await apiRequest<ListingData>(`/admin/items/${hashId}/possession/t2h_official`, {
      method: 'POST',
    });

    return {
      data: {
        data: result.data,
        status: result.status,
        statusText: result.statusText,
      },
    };
  } catch (e) {
    return {
      data: null,
      error: 'listing:error.list',
    };
  }
};

export const waitT2hOfficialListingByHashId = async (
  hashId: string,
): Promise<ApiResponse<PossessionListingResponse>> => {
  try {
    const result = await apiRequest<ListingData>(`/admin/items/${hashId}/possession/t2h_official_waiting`, {
      method: 'POST',
    });

    return {
      data: {
        data: result.data,
        status: result.status,
        statusText: result.statusText,
      },
    };
  } catch (e) {
    return {
      data: null,
      error: 'listing:error.list',
    };
  }
};

export const consignT2hOfficialListingByHashId = async (
  hashId: string,
): Promise<ApiResponse<PossessionListingResponse>> => {
  try {
    const result = await apiRequest<ListingData>(`/admin/items/${hashId}/possession/t2h_official_consignment`, {
      method: 'POST',
    });

    return {
      data: {
        data: result.data,
        status: result.status,
        statusText: result.statusText,
      },
    };
  } catch (e) {
    return {
      data: null,
      error: 'listing:error.list',
    };
  }
};

export const rejectT2hOfficialListingByHashId = async (
  hashId: string,
  possessionInfo: PossessionInfo,
): Promise<ApiResponse<PossessionListingResponse>> => {
  try {
    const result = await apiRequest<ListingData>(`/admin/items/${hashId}/possession/t2h_official_rejected`, {
      method: 'POST',
      data: possessionInfo,
    });

    return {
      data: {
        data: result.data,
        status: result.status,
        statusText: result.statusText,
      },
    };
  } catch (e) {
    return {
      data: null,
      error: 'listing:error.list',
    };
  }
};

export const getLatestBoostByHashId = async (itemHashId: string): Promise<ApiResponse<LatestBoostResponse[]>> => {
  try {
    const result = await apiRequest<LatestBoostResponse[]>(`/items/latest-boost?itemHashId=${itemHashId}`);
    return {
      data: result.data,
    };
  } catch (e) {
    return {
      data: null,
      error: 'listing:common.message.getLatestBoostFailed',
    };
  }
};

export const updateStatusListingLists = async (
  formData: UpdateStatusMultiListingFormDataRequest,
  status: string,
): Promise<ApiResponse<UpdateListingResponse>> => {
  try {
    const result = await apiRequest<UpdateListingResponse>(`/admin/items/status/${status}`, {
      method: 'PATCH',
      data: formData,
    });

    return {
      data: result.data,
    };
  } catch (e) {
    return {
      data: null,
      error: 'listing:error.list',
    };
  }
};

export const getViewCountByHashId = async (itemHashId: string): Promise<ApiResponse<GetViewCountResponse[]>> => {
  try {
    const result = await apiRequest<GetViewCountResponse[]>(`/items/view-count?itemHashId=${itemHashId}`);
    return {
      data: result.data,
    };
  } catch (e) {
    return {
      data: null,
    };
  }
};
