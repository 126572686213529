import React, { FC, useState } from 'react';

import { Box, TableRow, Typography } from '@material-ui/core';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { getT2HSiteUrl } from '../../../../../../../helpers/utils';
import i18n from '../../../../../../../i18n';
import { AppTableConditions, TableColumn } from '../../../../../../../models/common';
import { CoinUsageTransaction } from '../../../../../../../models/user.model';
import AppTableCell from '../../../../../../ui/AppTableCell';
import AppTableList from '../../../../../../ui/AppTableList';

export type CoinUsageListTableProps = {
  currentConditions: AppTableConditions;
  totalItems: number;
  isLoadingData: boolean;
  coinUsageList: CoinUsageTransaction[];
  onTableConditionsChanged: (values: AppTableConditions) => void;
};

const coinUsageTableColumns: TableColumn[] = [
  { name: 'hashId', label: 'user:coin.columns.hashId', width: 150 },
  { name: 'usageType', label: 'user:coin.columns.usageType', width: 200 },
  { name: 'boost', label: 'user:coin.columns.detail', width: 300 },
  { name: 'coinAmount', label: 'user:coin.columns.coinAmount', width: 150 },
  { name: 'totalBalance', label: 'user:coin.columns.totalBalance', width: 150 },
  { name: 'createdAt', label: 'user:coin.columns.createdAt', width: 200 },
  { name: 'createdBy', label: 'user:coin.columns.createdBy', width: 200 },
];

const iconCoin = '../../../static/images/icons/icon-coin.png';

const CoinUsageListTable: FC<CoinUsageListTableProps> = (props) => {
  const { t } = useTranslation();
  const { totalItems, isLoadingData, currentConditions, coinUsageList, onTableConditionsChanged } = props;
  const [tableConditions, setTableConditions] = useState<AppTableConditions>(currentConditions);
  const dateDisplayFormat = i18n.language === 'th' ? 'D MMM BB / HH:mm:ss' : 'D MMM YY / HH:mm:ss';

  const onTableConditionChangedHandler = (values: AppTableConditions) => {
    if (JSON.stringify(values) !== JSON.stringify(tableConditions)) {
      setTableConditions(values);
      onTableConditionsChanged(values);
    }
  };

  return (
    <>
      <Box textAlign="right" mb={2}>
        {!isLoadingData && (
          <Typography variant="subtitle2" color="textSecondary" style={{ marginRight: '8px', textAlign: 'right' }}>
            {t('common:list.resultTotal', { fieldName: t(totalItems.toLocaleString() || '0') })}
          </Typography>
        )}
      </Box>
      <AppTableList
        {...tableConditions}
        aria-label="user-list"
        columns={coinUsageTableColumns}
        totalDataCount={totalItems}
        hasPagination
        onTableConditionChanged={onTableConditionChangedHandler}
        isLoading={isLoadingData}
        tableBodyContent={
          coinUsageList.length > 0 && (
            <>
              {coinUsageList?.map((item: CoinUsageTransaction) => (
                <TableRow key={item.hashId}>
                  <AppTableCell>{item.hashId}</AppTableCell>
                  <AppTableCell>{t(`user:details.label.${item.usageType}`)}</AppTableCell>
                  <AppTableCell>
                    {item.boostDetail ? (
                      <Box>
                        <Typography variant="subtitle2">
                          {t('user:details.boostItemHashId')}
                          <a
                            href={getT2HSiteUrl(`/listing/${item.boostDetail.itemHashId}`)}
                            target="_blank"
                            rel="noreferrer"
                            style={{ marginLeft: '4px', wordBreak: 'break-word' }}>
                            {item.boostDetail.itemHashId}
                          </a>
                        </Typography>
                        <Box display="flex" flexDirection="row" mt={1}>
                          <Typography variant="subtitle2">
                            {t('user:details.boostItemTimes', { times: item.boostDetail.times })}
                          </Typography>
                          <Box ml={2}>
                            <Typography variant="subtitle2">
                              {t('user:details.boostItemCoinAmount', { coinAmount: item.boostDetail.coinAmount })}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    ) : (
                      <Typography variant="subtitle2">-</Typography>
                    )}
                  </AppTableCell>
                  <AppTableCell>
                    <Box display="flex" flexDirection="row">
                      <img src={iconCoin} width={20} height={20} alt="coin" />
                      <Box ml={1}>
                        {item.usageType === 'PURCHASED_BOOST' ? (
                          <Typography variant="subtitle2" color="error">
                            - {item.coinAmount.toLocaleString()} Coins
                          </Typography>
                        ) : (
                          <Typography variant="subtitle2">{item.coinAmount.toLocaleString()} Coins</Typography>
                        )}
                      </Box>
                    </Box>
                  </AppTableCell>

                  <AppTableCell>
                    {item.totalBalance ? (
                      <Box display="flex" flexDirection="row">
                        <img src={iconCoin} width={20} height={20} alt="coin" />
                        <Box ml={1}>
                          <Typography variant="subtitle2">{item.totalBalance.toLocaleString()} Coins</Typography>
                        </Box>
                      </Box>
                    ) : (
                      <Typography variant="subtitle2">-</Typography>
                    )}
                  </AppTableCell>

                  <AppTableCell>
                    <Box>
                      <Typography variant="subtitle2">
                        {item.createdAt ? dayjs(item.createdAt).format(`${dateDisplayFormat}`) : '-'}
                      </Typography>
                    </Box>
                  </AppTableCell>
                  <AppTableCell>
                    {item.createdBy && (
                      <Box>
                        <Typography variant="subtitle2">
                          {item.createdBy.displayName ? item.createdBy.displayName : '-'}
                        </Typography>
                      </Box>
                    )}
                  </AppTableCell>
                </TableRow>
              ))}
            </>
          )
        }
      />
    </>
  );
};

export default CoinUsageListTable;
