import { useState } from 'react';

import { AppMasterDataContextHookProps, AppMasterDataContextProps, MasterDataKey } from '../models';
import {
  getMembershipProducts,
  getAllCategoryMasterRoot,
  getBankMasterList,
  getKTMasterIndexRootCategory,
} from '../services';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const masterDataUrlList: { [key in keyof AppMasterDataContextProps]: () => Promise<any> } = {
  membershipProducts: getMembershipProducts,
  categoryMasterRoots: getAllCategoryMasterRoot,
  ktCategoryMasterRoots: getKTMasterIndexRootCategory,
  bankMaster: getBankMasterList,
};

export default (): AppMasterDataContextHookProps => {
  const [currentData, setCurrentData] = useState<Partial<AppMasterDataContextProps> | undefined>(undefined);

  const getData = async <T = unknown>(dataKey: MasterDataKey): Promise<T | undefined> => {
    if (!!currentData && currentData[dataKey]) {
      return Promise.resolve(currentData[dataKey] as unknown as T);
    }

    if (typeof masterDataUrlList[dataKey] === 'function') {
      const data = await masterDataUrlList[dataKey]();
      setCurrentData({ ...(currentData || {}), [dataKey]: data });
      return data;
    }

    return Promise.resolve(undefined);
  };

  return {
    getData,
  };
};
