import SellerApplicationEdit from './Edit';
import SellerApplicationList from './List';
import { RouteConfig } from '../../../models/route.model';

const sellerApplicationsRoutes = (path: string): RouteConfig[] => [
  {
    key: 'SELLER_APP_LIST',
    path,
    component: SellerApplicationList,
    exact: true,
    title: 'common:title.sellerApplications',
  },
  {
    key: 'SELLER_APP_DETAIL',
    path: `${path}/:hashId`,
    component: SellerApplicationEdit,
    exact: true,
    backUrl: path,
  },
];

export default sellerApplicationsRoutes;
