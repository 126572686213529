import React, { FC, useState } from 'react';

import { Box, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { MasterIndex, RefMasterIndex } from '../../../../../models';
import * as masterIndexService from '../../../../../services/master-index';
import useTypographyStyles from '../../../../../theme/typography.style';
import AppAutoComplete from '../../../../ui/AppAutoComplete';

export type RefMasterIndexSelectorProps = {
  selectedMasterIndex: RefMasterIndex | undefined;
  onItemChange: (value: RefMasterIndex | undefined) => void;
  onDelete?: () => void;
};

const useStyles = makeStyles(() => ({
  selectedTextBlock: {
    height: 44,
  },
  editingCard: {
    paddingTop: 4,
    paddingBottom: 4,
  },
  selectedItemText: {
    marginTop: 3,
  },
}));

const RefMasterIndexSelector: FC<RefMasterIndexSelectorProps> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const typoClasses = useTypographyStyles();
  const { onItemChange, selectedMasterIndex } = props;
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const getMasterIndex = async (value: string): Promise<MasterIndex[]> => {
    const result = await masterIndexService.getMasterIndexByName(value);
    return result.data?.list || [];
  };

  const onClose = () => {
    setIsEditing(false);
  };

  return (
    <>
      {isEditing && (
        <div className={classes.editingCard}>
          <AppAutoComplete<RefMasterIndex>
            getData={getMasterIndex}
            onValueChange={onItemChange}
            initialValue={selectedMasterIndex}
            onClose={onClose}
            autoCompleteProps={{
              popupIcon: null,
              getOptionLabel: (option) => (typeof option === 'string' ? option : option.thaiName),
              filterOptions: (option) => option,
              renderOption: ({ thaiName, attributeDataType }: RefMasterIndex) => (
                <div>
                  <Typography variant="caption" component="div" noWrap className={typoClasses.greyText}>
                    {attributeDataType}
                  </Typography>
                  <Typography variant="body1" component="div" noWrap>
                    {thaiName}
                  </Typography>
                </div>
              ),
              blurOnSelect: true,
              loadingText: t('common:message.searching').toString(),
              noOptionsText: t('common:message.dataNotFound').toString(),
            }}
            textfieldProps={{
              margin: 'dense',
              autoFocus: true,
            }}
          />
        </div>
      )}
      {!isEditing && (
        <Box
          onClick={() => setIsEditing(true)}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-start"
          className={classes.selectedTextBlock}>
          {!!selectedMasterIndex && (
            <>
              <Typography variant="caption" noWrap className={typoClasses.greyText}>
                {selectedMasterIndex.attributeDataType}
              </Typography>
              <Typography noWrap className={classes.selectedItemText}>
                {selectedMasterIndex.thaiName}
              </Typography>
            </>
          )}
          {!selectedMasterIndex && (
            <Typography className={typoClasses.greyText} component="div" noWrap>
              {t('master-data:form.masterIndex.selectMasterIndex')}
            </Typography>
          )}
        </Box>
      )}
    </>
  );
};

export default RefMasterIndexSelector;
