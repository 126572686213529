import React, { FC, useCallback, useContext, useEffect, useState } from 'react';

import { Avatar, Box, CircularProgress, Grid, makeStyles, Typography } from '@material-ui/core';
import ChatIcon from '@material-ui/icons/Chat';
import { DocumentData, DocumentSnapshot } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import MessageGroupItemList from '../../components/partials/users/MessageGroupItem';
import MessageItemList from '../../components/partials/users/MessageList';
import { AppGlobalUiContext } from '../../context/AppGlobalUiContext';
import { AuthContext } from '../../context/AuthContext';
import { UserRole } from '../../models';
import { Message } from '../../models/message';
import { MessageGroup } from '../../models/message-group';
import { getMessages } from '../../services/messenger/message/get-message';
import getGroupListByUserId from '../../services/messenger/message-group/get-group-list-by-user-id';

export type UserChatPageProps = {
  title: string;
  data: MessageGroup;
};

const useStyles = makeStyles((theme) => ({
  scrollBarStyle: {
    overflowY: 'scroll',
    height: '500px',
  },
  chatSectionRow: {
    border: `solid 1px ${theme.palette.divider}`,
    borderRadius: '8px',
    backgroundColor: theme.palette.background.paper,
  },
  titleGroup: {
    padding: '20px 16px',
    fontWeight: 700,
    borderBottom: `solid 1px ${theme.palette.divider}`,
  },
  titleGroupRight: {
    padding: '12px',
    borderBottom: `solid 1px ${theme.palette.divider}`,
  },
  rightColumn: {
    position: 'relative',
    borderLeft: `solid 1px ${theme.palette.divider}`,
    fontSize: '14px',
    paddingBottom: '80px',
    [theme.breakpoints.up('md')]: {
      borderLeft: `solid 1px ${theme.palette.divider}`,
      borderTop: 'none',
    },
  },
  iconAvatar: {
    border: `solid 1px ${theme.palette.divider}`,
  },
  dataTextWrap: {
    paddingRight: theme.spacing(12),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 700,
  },
  messageIcon: {
    marginBottom: theme.spacing(1),
    background: theme.palette.grey[300],
    width: 'auto',
    display: 'inline-block',
    padding: '16px 16px 10px 16px',
    borderRadius: '50%',
    '& svg': {
      fontSize: theme.typography.pxToRem(40),
      color: theme.palette.grey[500],
    },
  },
  blockUsers: {
    position: 'fixed',
    top: 'auto',
    bottom: '50px',
    right: '8%',
    background: theme.palette.grey[300],
    padding: '10px',
    borderRadius: '8px',
    width: '40%',
  },
  extraSmallText: {
    fontSize: '14px',
  },
}));

const UserChat: FC<UserChatPageProps> = () => {
  const { hashId, roomId } = useParams<{ hashId: string; roomId: string }>();
  const { authUser } = useContext(AuthContext);
  const [lastVisibleDoc, setLastVisibleDoc] = useState<DocumentSnapshot<DocumentData> | undefined>(undefined);
  const { t } = useTranslation();
  const { setAppLoading } = useContext(AppGlobalUiContext);
  const classes = useStyles();
  const [messagesGroupList, setMessagesGroupList] = useState<MessageGroup[]>([]);
  const [messageItemList, setMessageItemList] = useState<Message[]>([]);
  const [isLoadingMessage, setIsLoadingMessage] = useState<boolean>(false);
  const history = useHistory();
  const chatRoomData = messagesGroupList.find((item) => item.id === roomId) || null;
  const membersWithoutCurrentUser = chatRoomData?.members
    ? chatRoomData.members.filter((item) => item.hashId !== hashId)
    : null;
  const interlocutorUserData = membersWithoutCurrentUser ? membersWithoutCurrentUser[0] : null;
  const [isLoadingGroup, setIsLoadingGroup] = useState<boolean>(false);

  const getMessageGroupListByUserId = async () => {
    const getMessageGroupByIdResult = await getGroupListByUserId(hashId);
    return getMessageGroupByIdResult.data || [];
  };

  const getMessageList = useCallback(async () => {
    if (isLoadingMessage) {
      return;
    }
    setAppLoading(true);
    setIsLoadingMessage(true);
    const getMessageListResult = await getMessages({
      groupId: roomId,
      lastVisibleDoc,
    });

    if (getMessageListResult?.data) {
      setLastVisibleDoc(getMessageListResult?.data?.lastDocSnapshot || undefined);
      setMessageItemList(getMessageListResult.data?.message);
    }
    setIsLoadingMessage(false);
    setAppLoading(false);
  }, [isLoadingMessage, lastVisibleDoc, roomId, setAppLoading]);

  const changeMessageGroupHandler = useCallback(
    (messageGroupId: string) => {
      if (isLoadingMessage) {
        return;
      }
      setLastVisibleDoc(undefined);
      history.push(`/users/${hashId}/chat/${messageGroupId}`);
    },
    [hashId, history, isLoadingMessage],
  );

  useEffect(() => {
    (async () => {
      await getMessageList();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomId]);

  useEffect(() => {
    (async () => {
      setIsLoadingGroup(true);
      const getMessageGroupResult = await getMessageGroupListByUserId();
      setMessagesGroupList(getMessageGroupResult);
      setIsLoadingGroup(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hashId]);

  return (
    <>
      {authUser?.role === UserRole.GM && (
        <Box p={3}>
          <Grid container className={classes.chatSectionRow}>
            <Grid item xs={4} md={4}>
              <Box>
                <Typography className={classes.titleGroup}>{t('user:chat.messageGroup')}</Typography>
                <Box className={classes.scrollBarStyle}>
                  {!isLoadingGroup &&
                    messagesGroupList.map((item) => {
                      const isSelected = item.id === roomId;
                      return (
                        <Box key={item.id} onClick={() => changeMessageGroupHandler(item.id || '')}>
                          <MessageGroupItemList isSelected={isSelected} data={item} />
                        </Box>
                      );
                    })}
                  {isLoadingGroup && (
                    <Box p={4} display="flex" flexDirection="column" alignItems="center">
                      <CircularProgress size={24} />
                      <Box mt={1}>
                        <Typography>Loading group...</Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={8} md={8} className={classes.rightColumn}>
              {roomId && (
                <Box display="flex" flexDirection="row" className={classes.titleGroupRight}>
                  <Box>
                    <Avatar
                      src={interlocutorUserData?.profileImageUrl}
                      alt={interlocutorUserData?.displayName}
                      className={classes.iconAvatar}
                    />
                  </Box>
                  <Box pl={1} pt={1}>
                    <Typography className={classes.dataTextWrap}>
                      {interlocutorUserData?.displayName || 'No name'}
                    </Typography>
                  </Box>
                </Box>
              )}
              <Box>
                {roomId ? (
                  <Box>
                    <MessageItemList messageItemList={messageItemList} />
                    <Box>
                      {messagesGroupList.map((item) => (
                        <Box key={item.id}>
                          {item.blockingUsers?.length !== 0 && item.id === roomId && (
                            <Box className={classes.blockUsers} style={{ textAlign: 'center' }}>
                              <Typography className={classes.extraSmallText}>{t('user:chat.blockUser')}</Typography>
                            </Box>
                          )}
                        </Box>
                      ))}
                    </Box>
                  </Box>
                ) : (
                  <Box style={{ textAlign: 'center', paddingTop: '200px' }}>
                    <Box className={classes.messageIcon}>
                      <ChatIcon />
                    </Box>
                    <Typography>{t('user:chat.selectMessageGroup')}</Typography>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default UserChat;
