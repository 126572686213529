import { AppTableConditions } from './common';

export enum FeatureType {
  pdpa = 'pdpa',
  csat = 'csat-survey',
  onBoardTooltipPriceOffer = 'price-offer',
  onBoardTooltipSellerProfile = 'seller-profile',
}

export interface AbTestingData {
  feature: string;
  totalUser: number;
  createdAt: number;
  userHashId: string;
  userHashIds: string[];
  features: string[];
}

export type AbTestingFormDataRequest = {
  userHashIds: string[];
  features: string[];
};

export type DeletedDataRequest = {
  features: string[];
};

export interface GetAbTestingListTCResponse {
  contents: AbTestingData[];
  page?: {
    size?: number;
    totalElements?: number;
    totalPages?: number;
    number?: number;
  };
}

export type AbTestingListSearchFormData = {
  searchField: string;
  searchKeyword: string;
  filteringType: [];
};

export type AbTestingListSearchParamsTC = AbTestingListSearchFormData & AppTableConditions;

export type AbTestingSearchFiltersTC = Partial<AbTestingListSearchParamsTC> & {
  q?: string;
};

export interface UpdateFeatureTestingResponse {
  userHashId: string;
  features: string[];
}
