import { AnyLiteralObject, AppTableConditions } from './common';

export type PaymentHistorySearchParams = PaymentHistorySearchFormData & AppTableConditions;

export type PaymentHistorySearchFilters = Partial<PaymentHistorySearchParams>;
export interface PaymentHistorySearchFormData {
  searchField: string;
  searchKeyword: string;
  filteringPaymentMethod: string[];
}

export enum PaymentOption {
  BankTransfer = 'bank_transfer',
  Promptpay = 'promptpay',
  Cash = 'cash',
  CreditCard = 'credit_card',
}

export enum remark {
  DirectContactToCR = 'Direct Contact to CR',
  SaleVisitCustomer = 'Sale visit customer',
  CantUseOmise = 'Can not use Omise',
  Other = 'Other(If select other, can input as free text)',
}
export interface GetPaymentHistoryListResponse {
  data: PaymentHistory[];
  totalCount: number;
}

export type PaymentHistory = {
  hashId?: string;
  userId: number;
  remark?: string;
  paymentOption?: string;
  paymentMethod?: string;
  productThaiName?: string;
  productPrice?: number;
  createdAt?: string;
};

export type PaymentHistoryRequest = {
  userHashId: string;
  remark?: string;
  paymentOption?: string;
  paymentMethod?: string;
  productThaiName?: string;
  productPrice?: number;
};

export type MembershipProduct = {
  hashId: string;
  type: 'coin' | 'membership';
  name: string;
  thaiName: string;
  price: number;
  paymentType: 'monthly' | 'yearly' | 'one-month' | 'one-year';
  active: boolean;
  metadata: AnyLiteralObject;
};
