import { apiTCRequest, apiTCRequestV2 } from '../helpers/request';
import { ApiResponse } from '../models';
import { OrderOnceData } from '../models/order-order.model';
import { Product } from '../models/product.model';
import {
  GetShoppingCartTCResponse,
  OrderCheckoutRequest,
  ShoppingCartRequest,
  SummarizeResponse,
} from '../models/shopping';

export const addShoppingCart = async (formData: ShoppingCartRequest): Promise<ApiResponse<Product>> => {
  const result = await apiTCRequestV2<Product>(`/admin/shopping-cart/`, {
    method: 'POST',
    data: { ...formData },
  });

  if (result.data) {
    return {
      ...result,
      data: result.data,
    };
  }

  return {
    ...result,
    data: null,
  };
};

export const summarizePromoCode = async (
  userHashId: string,
  promoCode: string,
): Promise<ApiResponse<SummarizeResponse>> => {
  const result = await apiTCRequestV2<SummarizeResponse>(`/admin/shopping-cart/summarize`, {
    method: 'POST',
    data: {
      userHashId,
      promoCode,
    },
  });

  return {
    ...result,
    data: result.status === 200 ? result.data : null,
  };
};

export const getShoppingCartByHashId = async (userHashId: string): Promise<ApiResponse<GetShoppingCartTCResponse>> => {
  const result = await apiTCRequest<GetShoppingCartTCResponse>(`/admin/shopping-cart/${userHashId}`);

  if (result.data) {
    return {
      data: result.data,
    };
  }
  return {
    ...result,
    data: null,
  };
};

export const checkOutOrderOnce = async (paymentData: OrderCheckoutRequest): Promise<ApiResponse<OrderOnceData>> => {
  const result = await apiTCRequestV2<OrderOnceData>(`/admin/shopping-cart/checkout/cr/once`, {
    method: 'POST',
    data: { ...paymentData },
  });

  if (result.data) {
    return {
      ...result,
      data: result.data,
    };
  }

  return {
    ...result,
    data: null,
  };
};
