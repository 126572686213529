import { AppTableConditions } from './common';
import { ListingItemUserData, listingItemResponse } from './listing.model';

export enum ReportListingType {
  NotReasonablePrice = 'not_reasonable_price',
  DuplicatedItem = 'duplicated_item',
  ForbiddenItem = 'forbidden_item',
  FraudItem = 'fraud_item',
  UnauthorizedItem = 'unauthorized_item',
  Other = 'other',
}

export enum ReportListingStatus {
  Waiting = 'waiting',
  Done = 'done',
  DuplicateReport = 'duplicate_report',
  Ignore = 'ignore',
}

export type reporterData = Partial<ListingItemUserData> & {
  interviewLog: string;
  verifiedAt: string;
  socialMedias: string;
};

export interface ReportListingData {
  hashId: string;
  updatedAt: string;
  createdAt: string;
  type: string;
  adminStatus: string;
  detail: string;
  crDetail: string;
  item: listingItemResponse;
  reporter: reporterData;
}

export interface GetReportListingListResponse {
  contents: ReportListingData[];
  totalCount: number;
}

export type ReportListingListSearchFormData = {
  filteringTypes: string[];
  filteringStatuses: string[];
};

export type ReportListingListSearchParams = ReportListingListSearchFormData & AppTableConditions;

export type ReportListingSearchFilters = Partial<ReportListingListSearchParams> & {
  q?: string;
};

export type ReportListingFormDataRequest = {
  hashIds: string;
  adminStatus: string;
  crDetail: string;
};
