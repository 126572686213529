import { useContext } from 'react';

import { AuthContext } from '../context/AuthContext';
import rolesActionMapping from '../helpers/privilege';
import { AdminActionField, AdminUserActions } from '../models';

export default (): { canPerform: (field: AdminActionField, action: keyof AdminUserActions) => boolean } => {
  const { authUser } = useContext(AuthContext);

  const canPerform = (field: AdminActionField, action: keyof AdminUserActions): boolean => {
    const targetField = rolesActionMapping[field];
    const targetAction = targetField[action];

    return !!authUser?.role && !!targetField && !!targetAction && targetAction.includes(authUser.role);
  };

  return {
    canPerform,
  };
};
