import React, { FC } from 'react';

import {
  Box,
  Container,
  FormHelperText,
  Grid,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import YoutubeSearchedForIcon from '@material-ui/icons/YoutubeSearchedFor';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';

import usePrivilege from '../../../../hooks/usePrivilege';
import { SelectItem, UserListSearchFormData } from '../../../../models';
import useAppContainerStyles from '../../../../theme/container.style';
import AppButton from '../../../ui/AppButton';
import AppChipList from '../../../ui/AppChipList';
import AppFormControl from '../../../ui/AppFormControl';
import AppFormControlGroup from '../../../ui/AppFormControlGroup';

export type UserListSearchFormProps = {
  isLoadingData?: boolean;
  currentFilters: UserListSearchFormData | undefined;
  onSearchFormSubmit: (values: UserListSearchFormData) => void;
};

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
  },
  searchBoxContainer: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 500,
    },
  },
  coinBoxContainer: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 300,
    },
  },
  coinInput: {
    maxWidth: '100px',
  },
  filterHeader: {
    fontWeight: 700,
  },
  selectedChipIcon: {
    marginRight: '4px',
    marginBottom: '4px',
    '& .MuiChip-icon': {
      order: 3,
      marginRight: '8px',
      marginLeft: '-6px',
    },
  },
}));

const searchFieldList: SelectItem<string>[] = [
  {
    label: 'user:common.fields.displayName',
    value: 'displayName',
  },
  {
    label: 'user:common.fields.username',
    value: 'username',
  },
  {
    label: 'user:common.fields.email',
    value: 'email',
  },
  {
    label: 'user:common.fields.mobile',
    value: 'mobile',
  },
  {
    label: 'user:common.fields.fullName',
    value: 'fullname',
  },
  {
    label: 'user:common.fields.hashId',
    value: 'hashId',
  },
  {
    label: 'user:common.fields.nationalId',
    value: 'nationalId',
  },
];

const searchRoles = [
  {
    label: 'user:common.roleShort.seller',
    value: 'seller',
  },
  {
    label: 'user:common.roleShort.seller_bronze',
    value: 'seller_bronze',
  },
  {
    label: 'user:common.roleShort.seller_silver',
    value: 'seller_silver',
  },
  {
    label: 'user:common.roleShort.seller_gold',
    value: 'seller_gold',
  },
  {
    label: 'user:common.roleShort.buyer',
    value: 'buyer',
  },
];

const searchStatuses = [
  {
    label: 'user:list.search.inactive',
    value: 'inactive',
  },
];

const UserListSearchForm: FC<UserListSearchFormProps> = (props) => {
  const { onSearchFormSubmit, currentFilters, isLoadingData = false } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const containerClasses = useAppContainerStyles();
  const { canPerform } = usePrivilege();
  const canFilterAdminUsers = canPerform('adminUser', 'view');
  const filteringRolesList = [...searchRoles];
  if (canFilterAdminUsers) {
    filteringRolesList.push({
      label: 'user:common.role.admin_staff',
      value: 'admin_staff',
    });
    filteringRolesList.push({
      label: 'user:common.role.admin_manager',
      value: 'admin_manager',
    });
    filteringRolesList.push({
      label: 'user:common.role.admin_gm',
      value: 'admin_gm',
    });
  }

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<UserListSearchFormData>({
    mode: 'onSubmit',
    defaultValues: {
      searchField: currentFilters?.searchField || searchFieldList[0].value,
      searchKeyword: currentFilters?.searchKeyword || '',
      minCoin: currentFilters?.minCoin || '',
      maxCoin: currentFilters?.maxCoin || '',
      filteringRoles: currentFilters?.filteringRoles || [],
      filteringStatuses: currentFilters?.filteringStatuses || [],
      filteringTags: currentFilters?.filteringTags || [],
    },
  });

  const submitSearchForm = () => {
    if (!isLoadingData) {
      setValue('searchKeyword', getValues('searchKeyword').trim());
      handleSubmit(onSearchFormSubmit)();
    }
  };

  /* TODO: Temporary commented due to more info is needed on this filtering */
  // const toggleFilteringTags = (tagKey: string) => {
  //   const filteringTags = getValues('filteringTags');
  //   if (filteringTags.includes(tagKey)) {
  //     setValue('filteringTags', filteringTags.filter((role) => role !== tagKey));
  //   } else {
  //     setValue('filteringTags', [...filteringTags, tagKey]);
  //   }
  // };

  // const TagFilterChip = ({ tagKey }: { tagKey: string }) => {
  //   const filteringTags = getValues('filteringTags');
  //   const isIncluded = filteringTags.includes(tagKey);

  //   return (
  //     <Chip
  //       label={t(`user:list.search.tags.${tagKey}`)}
  //       variant="outlined"
  //       color={isIncluded ? 'primary' : 'default'}
  //       clickable
  //       icon={isIncluded ? <DoneIcon fontSize="small" /> : undefined}
  //       onClick={() => toggleFilteringTags(tagKey)}
  //       className={classes.selectedChipIcon}
  //     />
  //   );
  // };

  const coinInputValidator = (value: string): string | undefined => {
    if (getValues('maxCoin') && value && Number(value) > Number(getValues('maxCoin'))) {
      return t('user:error.filter.invalidCoinValue').toString();
    }

    return undefined;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const NumberFormatCustom = (numberInputProps: any) => {
    const { inputRef, onChange, ...other } = numberInputProps;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              // eslint-disable-next-line react/destructuring-assignment
              name: numberInputProps.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
      />
    );
  };

  const clearSearchForm = () => {
    if (!isLoadingData) {
      setValue('searchField', searchFieldList[0].value);
      setValue('searchKeyword', '');
      setValue('minCoin', '');
      setValue('maxCoin', '');
      setValue('filteringStatuses', []);
      setValue('filteringRoles', []);
      setValue('filteringTags', []);
      handleSubmit(onSearchFormSubmit)();
    }
  };

  return (
    <Box className={classes.filterContainer}>
      <Container className={containerClasses.container}>
        <form onSubmit={handleSubmit(onSearchFormSubmit)}>
          <Grid container direction="row" spacing={0}>
            <Grid item xs={12} md container spacing={3}>
              <Grid item xs={12} md="auto">
                <Box display="flex" flexDirection="row" flexWrap="nowrap" className={classes.searchBoxContainer}>
                  <AppFormControlGroup flexGrow={1} minWidth={0} pr={1}>
                    <AppFormControl margin="dense">
                      <Controller
                        name="searchField"
                        control={control}
                        defaultValue={searchFieldList[0].value}
                        render={({ field }) => (
                          <Select
                            labelId="user-list-search-field-label"
                            id="user-list-search-field"
                            value={field.value}
                            variant="outlined"
                            onChange={field.onChange}
                            disabled={isLoadingData}
                            fullWidth>
                            {searchFieldList.map((item) => (
                              <MenuItem value={item.value} key={item.value}>
                                {t(item.label)}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </AppFormControl>
                    <AppFormControl margin="dense" boxProps={{ flexGrow: 1 }}>
                      <Controller
                        name="searchKeyword"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <OutlinedInput
                            type="search"
                            endAdornment={<SearchIcon />}
                            id="user-list-search-keyword"
                            value={field.value}
                            onChange={field.onChange}
                            fullWidth
                            disabled={isLoadingData}
                          />
                        )}
                      />
                    </AppFormControl>
                  </AppFormControlGroup>
                  <AppButton type="submit" color="primary" style={{ alignSelf: 'flex-start' }} disabled={isLoadingData}>
                    {t('common:message.search')}
                  </AppButton>
                </Box>
              </Grid>
              <Grid item xs={12} md>
                <Box display="flex" flexDirection="row" flexWrap="nowrap" className={classes.coinBoxContainer}>
                  <Box display="flex" flexDirection="row" flexWrap="wrap" minWidth={0}>
                    <Controller
                      name="minCoin"
                      control={control}
                      defaultValue=""
                      rules={{
                        validate: coinInputValidator,
                      }}
                      render={({ field }) => (
                        <>
                          <AppFormControl boxProps={{ pr: 1, flexBasis: '50%' }}>
                            <OutlinedInput
                              id="user-list-min-coin"
                              value={field.value}
                              onChange={field.onChange}
                              className={classes.coinInput}
                              placeholder={t('user:list.search.minCoin')}
                              inputComponent={NumberFormatCustom}
                              error={!!errors.minCoin}
                              margin="dense"
                              disabled={isLoadingData}
                            />
                          </AppFormControl>
                        </>
                      )}
                    />
                    <Controller
                      name="maxCoin"
                      control={control}
                      defaultValue=""
                      rules={{
                        validate: coinInputValidator,
                      }}
                      render={({ field }) => (
                        <AppFormControl boxProps={{ pr: 1, flexBasis: '50%' }}>
                          <OutlinedInput
                            id="user-list-max-coin"
                            value={field.value}
                            onChange={field.onChange}
                            className={classes.coinInput}
                            placeholder={t('user:list.search.maxCoin')}
                            inputComponent={NumberFormatCustom}
                            error={!!errors.maxCoin}
                            margin="dense"
                            disabled={isLoadingData}
                          />
                        </AppFormControl>
                      )}
                    />
                    {(!!errors.minCoin || !!errors.maxCoin) && (
                      <Box flexBasis="100%">
                        <FormHelperText error>{errors.minCoin?.message || errors.maxCoin?.message}</FormHelperText>
                      </Box>
                    )}
                  </Box>
                  <AppButton type="submit" color="primary" style={{ alignSelf: 'flex-start' }} disabled={isLoadingData}>
                    {t('common:message.search')}
                  </AppButton>
                </Box>
              </Grid>
              <Grid container direction="row" spacing={0} item xs={12}>
                <Grid item xs={12} md="auto">
                  <Box minWidth="300px" maxWidth="600px" mt={2} pr={3}>
                    <Typography variant="body2" className={classes.filterHeader}>
                      {t('user:common.fields.role')}
                    </Typography>
                    <Box display="flex" flexDirection="row" flexWrap="wrap" mt={1}>
                      <Controller
                        control={control}
                        name="filteringRoles"
                        defaultValue={currentFilters?.filteringRoles || []}
                        render={({ field }) => (
                          <>
                            <AppChipList
                              items={filteringRolesList}
                              selectedValues={field.value}
                              onChange={(values: string[]) => {
                                field.onChange(values);
                                submitSearchForm();
                              }}
                            />
                          </>
                        )}
                      />
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12} md="auto">
                  <Box minWidth="200px" maxWidth="300px" mt={2} mb={2}>
                    <Typography variant="body2" className={classes.filterHeader}>
                      {t('user:list.search.accountStatus')}
                    </Typography>
                    <Box display="flex" flexDirection="row" flexWrap="wrap" mt={1}>
                      <Controller
                        control={control}
                        name="filteringStatuses"
                        defaultValue={currentFilters?.filteringStatuses || []}
                        render={({ field }) => (
                          <>
                            <AppChipList
                              items={searchStatuses}
                              selectedValues={field.value}
                              onChange={(values: string[]) => {
                                field.onChange(values);
                                submitSearchForm();
                              }}
                            />
                          </>
                        )}
                      />
                    </Box>
                  </Box>
                </Grid>
                {/* TODO: Temporary commented due to more info is needed on this filtering */}
                {/* <Grid item xs={12} md="auto">
                  <Box minWidth="200px" maxWidth="300px" mt={2}>
                    <Typography variant="body2" className={classes.filterHeader}>
                      {t('user:list.search.tagsTitle')}
                    </Typography>
                    <Box display="flex" flexDirection="row" flexWrap="wrap" mt={1}>
                      <TagFilterChip tagKey="unsafe" />
                    </Box>
                  </Box>
                </Grid> */}
              </Grid>
            </Grid>
            <Grid item xs={12} md="auto">
              <AppButton variant="outlined" color="primary" onClick={clearSearchForm} disabled={isLoadingData}>
                <YoutubeSearchedForIcon />
              </AppButton>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Box>
  );
};

export default UserListSearchForm;
