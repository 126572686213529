import React, { FC, useCallback, useContext, useState } from 'react';

import { Box, Container, Grid, TableRow, Typography, makeStyles } from '@material-ui/core';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import clsx from 'clsx';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useTranslation } from 'react-i18next';

import { AppGlobalUiContext } from '../../../../../context/AppGlobalUiContext';
import usePrivilege from '../../../../../hooks/usePrivilege';
import { AppTableConditions, TableColumn } from '../../../../../models';
import { Product, ProductFormDataRequest, PurchaseTypeTC } from '../../../../../models/product.model';
import { addNewProduct, updateProduct } from '../../../../../services/product';
import useAppContainerStyles from '../../../../../theme/container.style';
import useAppTableStyles from '../../../../../theme/table.style';
import AppButton from '../../../../ui/AppButton';
import AppTableCell from '../../../../ui/AppTableCell';
import AppTableList from '../../../../ui/AppTableList';
import AddProductItemDialog from '../../../common/v3/AddProductDialog';
import ProductItemDialog from '../../../common/v3/ProductItemDialog';

dayjs.extend(customParseFormat);

export type ProductListTableProps = {
  currentConditions: AppTableConditions;
  totalProducts: number;
  isLoadingData: boolean;
  productList: Product[];
  typeProduct: string;
  onFetch: () => void;
  onTableConditionsChanged: (values: AppTableConditions) => void;
};

const productTableColumns: TableColumn[] = [
  { name: 'isActive', label: 'product:list.columns.isActive', width: 110, unsortable: true },
  { name: 'hashId', label: 'product:list.columns.hashId', width: 120, unsortable: true },
  { name: 'title', label: 'product:list.columns.title', width: 200, unsortable: true },
  { name: 'sku', label: 'product:list.columns.sku', width: 180, unsortable: true },
  { name: 'price', label: 'product:list.columns.price', width: 120, unsortable: true },
  { name: 'amount', label: 'product:list.columns.amount', width: 100, unsortable: true },
  { name: 'productUnit', label: 'product:list.columns.productUnit', width: 100, unsortable: true },
];

const useStyles = makeStyles((theme) => ({
  activeStatus: {
    padding: theme.spacing(0, 2),
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
    textAlign: 'center',
    borderRadius: '20px',
    fontSize: '13px',
    lineHeight: '26px',
  },
  inactiveStatus: {
    padding: theme.spacing(0, 2),
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.success.contrastText,
    textAlign: 'center',
    borderRadius: '20px',
    fontSize: '13px',
    lineHeight: '26px',
  },
  priceSty: {
    paddingRight: theme.spacing(1),
  },
}));

const ProductListTable: FC<ProductListTableProps> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    totalProducts,
    isLoadingData,
    currentConditions,
    productList,
    typeProduct,
    onFetch,
    onTableConditionsChanged,
  } = props;
  const containerClasses = useAppContainerStyles();
  const tableClasses = useAppTableStyles();
  const [tableConditions, setTableConditions] = useState<AppTableConditions>(currentConditions);
  const { showSnackbar, setAppLoading } = useContext(AppGlobalUiContext);

  const [selectedProductItem, setSelectedProductItem] = useState<Product | undefined>(undefined);
  const [isProductDialogOpen, setIsProductDialogOpen] = useState<boolean>(false);

  const [isAddProductDialogOpen, setIsAddProductDialogOpen] = useState<boolean>(false);

  const { canPerform } = usePrivilege();
  const allowCreate = canPerform('product', 'create');

  const onTableConditionChangedHandler = useCallback(
    (values: AppTableConditions) => {
      if (JSON.stringify(values) !== JSON.stringify(tableConditions)) {
        setTableConditions(values);
        onTableConditionsChanged(values);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableConditions],
  );

  const openProductDetailPage = useCallback(async (item: Product) => {
    setSelectedProductItem(item);
    setIsProductDialogOpen(true);
  }, []);

  const onEditFormSubmit = useCallback(
    async (data?: ProductFormDataRequest) => {
      if (selectedProductItem && data) {
        setAppLoading(true);

        const availableFrom = data.availableFrom ? dayjs(data.availableFrom).valueOf() : null;
        const availableTo = data.availableTo ? dayjs(data.availableTo).valueOf() : null;
        const description = JSON.stringify({
          badges:
            // eslint-disable-next-line no-nested-ternary
            typeProduct === PurchaseTypeTC.Coin
              ? data.description || ''
              : typeof data.description === 'string'
              ? data.description.split('|')
              : data.description || '',
          subtitle: data.subTitle || '',
        });

        if (typeProduct === PurchaseTypeTC.Coin) {
          delete data.membershipType;
        }
        const imageFileHashId = data.image?.fileHashId;
        const imageFullUrl = data.image?.fullUrl;
        delete data.image;

        const result = await updateProduct(selectedProductItem?.hashId, typeProduct, {
          ...data,
          availableFrom,
          availableTo,
          description,
          imageFileHashId,
          imageFullUrl,
        });

        if (result.data) {
          setAppLoading(false);
          setSelectedProductItem(result.data);
          showSnackbar(t('product:message.updateSuccess').toString(), 'success');
          setIsProductDialogOpen(false);
          onFetch();
        } else {
          setAppLoading(false);
          showSnackbar(t('product:message.updateFailed').toString(), 'error');
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedProductItem, setAppLoading, showSnackbar, t, typeProduct],
  );

  const handleAddProduct = useCallback(async () => {
    setIsAddProductDialogOpen(true);
  }, []);

  const onSubmitAddNewProduct = useCallback(
    async (formData?: ProductFormDataRequest) => {
      if (formData) {
        setAppLoading(true);
        const availableFrom = formData.availableFrom ? dayjs(formData.availableFrom).valueOf() : null;
        const availableTo = formData.availableTo ? dayjs(formData.availableTo).valueOf() : null;
        const description = JSON.stringify({
          badges:
            // eslint-disable-next-line no-nested-ternary
            typeProduct === PurchaseTypeTC.Coin
              ? formData.description || ''
              : typeof formData.description === 'string'
              ? formData.description.split('|')
              : formData.description || '',
          subtitle: formData.subTitle || '',
        });

        if (typeProduct === PurchaseTypeTC.Coin) {
          delete formData.membershipType;
        }
        const imageFileHashId = formData.image?.fileHashId;
        const imageFullUrl = formData.image?.fullUrl;
        delete formData.image;
        const result = await addNewProduct(typeProduct, {
          ...formData,
          availableFrom,
          availableTo,
          description,
          imageFileHashId,
          imageFullUrl,
        });

        if (result.data) {
          setSelectedProductItem(result.data);
          showSnackbar(t('product:message.addNewProductSuccess').toString(), 'success');
          setIsAddProductDialogOpen(false);
          onFetch();
        } else {
          showSnackbar(t('product:message.addNewProductFailed').toString(), 'error');
        }
        setAppLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setAppLoading, showSnackbar, t, typeProduct],
  );

  return (
    <>
      <Container className={containerClasses.container}>
        <Box className={tableClasses.tableContainer}>
          <Box mb={1}>
            <Grid container spacing={2}>
              <Grid item sm={12} md={8}>
                <Box display="inline-block">
                  <AppButton
                    style={{ display: 'inline-block' }}
                    variant="contained"
                    color="primary"
                    startIcon={<AddShoppingCartIcon />}
                    onClick={handleAddProduct}
                    disabled={!allowCreate}>
                    {t('product:button.addProduct')}
                  </AppButton>
                  <Typography
                    style={{ marginLeft: '8px', paddingTop: '6px', display: 'inline-block' }}
                    variant="subtitle2"
                    color="textSecondary">
                    {t('product:list.remarkAddNewProduct')}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                {!isLoadingData && (
                  <Box style={{ marginBottom: '8px', marginRight: '8px', textAlign: 'right' }}>
                    <Typography variant="subtitle2" color="textSecondary">
                      {t('common:list.resultTotal', { fieldName: t(totalProducts.toLocaleString() || '0') })}
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>

          <AppTableList
            {...tableConditions}
            aria-label="product-list"
            columns={productTableColumns}
            totalDataCount={totalProducts}
            hasPagination
            onTableConditionChanged={onTableConditionChangedHandler}
            isLoading={isLoadingData}
            tableBodyContent={
              productList.length > 0 && (
                <>
                  {productList.map((product: Product) => (
                    <TableRow
                      hover
                      key={product.hashId}
                      classes={{ root: clsx(tableClasses.tableRow, tableClasses.clickableTableRow) }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        openProductDetailPage(product);
                      }}>
                      <AppTableCell>
                        {product.isActive ? (
                          <Typography className={classes.activeStatus}>{t('product:list.columns.active')}</Typography>
                        ) : (
                          <Typography className={classes.inactiveStatus}>
                            {t('product:list.columns.inActive')}
                          </Typography>
                        )}
                      </AppTableCell>
                      <AppTableCell>{product.hashId}</AppTableCell>
                      <AppTableCell>{product.title}</AppTableCell>
                      <AppTableCell>{product.sku}</AppTableCell>
                      <AppTableCell>
                        <Box display="flex">
                          <Typography className={classes.priceSty}>
                            {product.price ? product.price?.toLocaleString() : ''}
                          </Typography>
                          <Typography>{product.currency}</Typography>
                        </Box>
                      </AppTableCell>
                      <AppTableCell>{product.amount.toLocaleString()}</AppTableCell>
                      <AppTableCell>{product.productUnit}</AppTableCell>
                    </TableRow>
                  ))}
                </>
              )
            }
          />
        </Box>
      </Container>

      {selectedProductItem && (
        <ProductItemDialog
          open={isProductDialogOpen}
          onClose={() => {
            setIsProductDialogOpen(false);
          }}
          onCloseClick={() => {
            setIsProductDialogOpen(false);
          }}
          onDialogExited={() => {
            setSelectedProductItem(undefined);
          }}
          title={t('product:details.title')}
          productItem={selectedProductItem}
          onFormSubmit={onEditFormSubmit}
          typeCoin={typeProduct === PurchaseTypeTC.Coin}
          typeProduct={typeProduct}
        />
      )}

      {isAddProductDialogOpen && (
        <AddProductItemDialog
          title={
            typeProduct === PurchaseTypeTC.Coin
              ? t('product:list.addNewProductTypeCoin')
              : t('product:list.addNewProductTypeMemberShip')
          }
          open={isAddProductDialogOpen}
          typeCoin={typeProduct === PurchaseTypeTC.Coin}
          onClose={() => {
            setIsAddProductDialogOpen(false);
          }}
          onCloseClick={() => {
            setIsAddProductDialogOpen(false);
          }}
          onFormSubmit={onSubmitAddNewProduct}
          typeProduct={typeProduct}
        />
      )}
    </>
  );
};

export default ProductListTable;
