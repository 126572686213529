/* eslint-disable import/prefer-default-export */
import { apiKTRequest } from '../helpers/request';
import { apiResponseTransformer } from '../helpers/response';
import { getPagingParameter, queryParamGenerator } from '../helpers/utils';
import {
  ApiResponse,
  ESModel,
  GetKTModelNamesParams,
  KTModel,
  KTModelName,
  KTPageResult,
  ListResponse,
  Model,
  ModelFormData,
  ModelListSearchFilters,
} from '../models';

type ModelListAxiosResponse = {
  models: Model[];
  page: KTPageResult;
};

type KTModelNameListAxiosResponse = {
  modelNames: KTModelName[];
  page: KTPageResult;
};

type KTModelListAxiosResponse = {
  models: KTModel[];
  page: KTPageResult;
};

type ESModelListAxiosResponse = {
  esModels: ESModel[];
  page: KTPageResult;
};

interface GroupModelName {
  modelName: string;
  brandId: number;
}

/**
 * Get model list request query parameter
 *
 * @param param Model list search filter
 * @returns Model list request query parameter as string
 */
const getModelListQueryParam = (param?: ModelListSearchFilters): string => {
  const reqParam = getPagingParameter(param, true);

  // Only search with keyword when it comes with the selected column to be searched
  if (param?.searchKeyword && param?.searchField) {
    reqParam[param.searchField] = param.searchKeyword;
  }

  return queryParamGenerator(reqParam, true);
};

/**
 * Request for model list
 *
 * @param param Model list search filter
 * @returns Model list request result as promise function
 */
export const getModelList = async (param?: ModelListSearchFilters): Promise<ApiResponse<ListResponse<Model>>> => {
  const qString = getModelListQueryParam(param);
  const result = await apiKTRequest<ModelListAxiosResponse>(`/api/model?${qString}`);

  return apiResponseTransformer<ModelListAxiosResponse, ListResponse<Model>>(
    result,
    'master-data:error.list.requestModelFailed',
    (data: ModelListAxiosResponse) => ({
      list: data.models || [],
      totalCount: data.page.totalElements,
    }),
  );
};

/**
 * Request for model list by given brand UID
 *
 * @param brandUid Brand UID
 * @param param Model list search filter
 * @returns Model list request result as promise function
 */
export const getModelListByBrandUid = async (
  brandUid: string,
  param?: ModelListSearchFilters,
): Promise<ApiResponse<ListResponse<Model>>> => {
  const qString = getModelListQueryParam(param);
  const result = await apiKTRequest<ModelListAxiosResponse>(`/api/brand/${brandUid}/models?${qString}`);

  return apiResponseTransformer<ModelListAxiosResponse, ListResponse<Model>>(
    result,
    'master-data:error.list.requestModelFailed',
    (data: ModelListAxiosResponse) => ({
      list: data.models || [],
      totalCount: data.page.totalElements,
    }),
  );
};

/**
 * Get model by given UID
 *
 * @param uid Model UID
 * @returns Model request result as promise function
 */
export const getModelByUid = async (uid: number | string): Promise<ApiResponse<Model>> => {
  const result = await apiKTRequest<Model>(`/api/model/${uid}`);

  return apiResponseTransformer(result, 'master-data:error.list.requestModelFailed');
};

/**
 * Create new model
 *
 * @param data Model form data
 * @returns Model create result as promise function
 */
export const createModel = async (data: ModelFormData): Promise<ApiResponse<Model>> => {
  const result = await apiKTRequest<Model>(`/admin_manager/model`, {
    method: 'POST',
    data,
  });

  return apiResponseTransformer(result, 'master-data:error.model.createFailed');
};

/**
 * Update current model
 *
 * @param id Model ID
 * @param data Model form data
 * @returns Model update result as promise function
 */
export const updateModel = async (id: number, data: ModelFormData): Promise<ApiResponse<Model>> => {
  const result = await apiKTRequest<Model>(`/admin_manager/model`, {
    method: 'PATCH',
    data: {
      ...data,
      id,
    },
  });

  return apiResponseTransformer(result, 'master-data:error.model.updateFailed');
};

/**
 * Sync elasticsearch categories
 *
 * @returns Update result as promise function
 */
export const syncElasticsearchModelsData = async (): Promise<ApiResponse<boolean>> => {
  const result = await apiKTRequest<boolean>(`/admin_manager/es/model/sync`, {
    method: 'GET',
  });

  return apiResponseTransformer(result, 'master-data:error.operations.dataSyncFailed');
};

/**
 * Sync elasticsearch model
 *
 * @param id Model ID (NOT UID)
 * @returns Model update result as promise function
 */
export const syncElasticsearchModelData = async (id: number): Promise<ApiResponse<ESModel>> => {
  const result = await apiKTRequest<ESModel>(`/admin_manager/es/model/sync/${id}`, {
    method: 'GET',
  });

  return apiResponseTransformer(result, 'master-data:error.operations.dataSyncFailed');
};

/**
 * Get GroupModelNames
 *
 * @param brandUid brand UID (UID is old model_master's id)
 * @param keyword string?
 * @returns GroupModelName with pagination
 */
export const getGroupModelNames = async (
  brandUid: number,
  keyword?: string,
): Promise<
  ApiResponse<{
    modelNames: GroupModelName[];
    page: KTPageResult;
  }>
> => {
  const qString = queryParamGenerator(
    {
      q: keyword || '',
    },
    true,
  );
  const result = await apiKTRequest<GroupModelName>(`/api/model/group/${brandUid}?${qString}`);

  return apiResponseTransformer(result, 'master-index:error.list.requestModelFailed');
};

/**
 * Get ESModels
 *
 * @param brandUid brand UID (UID is old model_master's id)
 * @param modelName string
 * @returns ESModels with pagination
 */
export const getESModelsByBrandUidAndModelName = async (
  brandUid: number,
  modelName: string,
): Promise<
  ApiResponse<{
    esModels: ESModel[];
    page: KTPageResult;
  }>
> => {
  const qString = queryParamGenerator({
    page: 0,
    per_page: 30,
  });
  const result = await apiKTRequest<GroupModelName>(`/api/es/model/${brandUid}/${modelName}?${qString}`);

  return apiResponseTransformer(result, 'master-index:error.list.requestModelFailed');
};

/**
 * Get ESModel
 *
 * @param hashId string
 * @returns ESModels
 */
export const getEsModelByHashUid = async (hashId: string): Promise<ApiResponse<ESModel>> =>
  apiResponseTransformer(
    await apiKTRequest<GroupModelName>(`/api/es/model/hashUid/${hashId}`),
    'master-index:error.list.requestModelFailed',
  );

/**
 * Get KTModel
 *
 * @param slug string
 * @returns KTModelNames
 */
export const getKTModelGroupNameListByBrandSlug = async (
  brandSlug: string,
  params?: GetKTModelNamesParams,
): Promise<ApiResponse<ListResponse<KTModelName>>> => {
  const { rootCategoryId } = params || {};

  const result = await apiKTRequest<KTModelName>(
    `/api/model/group/slug/${brandSlug}?rootCategoryId=${rootCategoryId}&per_page=1500`,
    {
      method: 'GET',
    },
  );

  return apiResponseTransformer<KTModelNameListAxiosResponse, ListResponse<KTModelName>>(
    result,
    'master-index:error.list.requestBrandFailed',
    (data: KTModelNameListAxiosResponse) => ({
      list: data?.modelNames || [],
      totalCount: data?.page.totalElements,
    }),
  );
};

/**
 * Get KTModel
 *
 * @param slug string
 * @returns KTModelNames
 */
export const getESModelsByBrandSlugAndModelName = async (
  brandSlug: string,
  modelName: string,
): Promise<ApiResponse<ListResponse<ESModel>>> => {
  const result = await apiKTRequest<ESModel>(`/api/es/model/slug/${brandSlug}/${modelName}?per_page=1500`, {
    method: 'GET',
  });

  return apiResponseTransformer<ESModelListAxiosResponse, ListResponse<ESModel>>(
    result,
    'master-index:error.list.requestBrandFailed',
    (data: ESModelListAxiosResponse) => ({
      list: data?.esModels || [],
      totalCount: data?.page.totalElements,
    }),
  );
};

/**
 * Get KTModel
 *
 * @param uid string
 * @returns KTModel
 */
export const getKTModelsByCategoryUID = async (uid: string): Promise<ApiResponse<ListResponse<KTModel>>> => {
  const result = await apiKTRequest<KTModelListAxiosResponse>(`/api/category/${uid}/models?per_page=5000`);
  return apiResponseTransformer<KTModelListAxiosResponse, ListResponse<KTModel>>(
    result,
    'master-index:error.list.requestModelFailed',
    (data: KTModelListAxiosResponse) => ({
      list: data?.models || [],
      totalCount: data?.page.totalElements,
    }),
  );
};
