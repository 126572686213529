import { ReactNode } from 'react';

import { SortDirection } from '@material-ui/core';
import { AxiosResponse } from 'axios';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyLiteralObject<T = any> = { [x: string]: T };

export type SelectItem<T> = {
  label: string;
  value: T;
};

export type TabItem = SelectItem<string> & {
  id: string;
  icon?: ReactNode;
  disabled?: boolean;
};

export type TableColumn = {
  name: string;
  label: string;
  width?: number;
  unsortable?: boolean;
};

export type AxiosErrorResponse = {
  data: null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
};

export type KTErrorItem = {
  field: string;
  code: string;
  message: string;
  param: { [x: string]: string | null };
};

export type AxiosApiResponse<T> = AxiosResponse<T> & {
  validationErrors?: KTErrorItem[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
};

export type ApiResponse<T> = {
  data: T | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
  validationErrors?: KTErrorItem[];
};

export type NotificationType =
  | 'T2H_TEXT'
  | 'T2H_PRODUCT_FOLLOW'
  | 'T2H_USER_FOLLOW'
  | 'T2H_MEMBERSHIP'
  | 'T2H_COIN'
  | 'T2H_PURCHASE'
  | 'T2H_CR'
  | 'CHAT'
  | 'SNS';

export type AppTableConditions = {
  page: number; // component starts from 0 but API starts from 1. Be careful when changing this value.
  rowsPerPage: number;
  sortColumn: string;
  sortDirection: SortDirection;
  notificationType?: NotificationType;
  productType?: 'COIN' | 'MEMBERSHIP_ONCE';
};

export type FileResponse = {
  category: string;
  cropHeight: number | string | null;
  cropWidth: number | string | null;
  cropX: number | string | null;
  cropY: number | string | null;
  driver: string;
  fileName: string;
  hashId: string;
  height: number | string | null;
  path: string;
  permission: string;
  size: number;
  url: string;
  width: number | string | null;
};

export type ProvinceData = {
  englishName: string;
  englishRegion: string;
  hashId: string;
  thaiName: string;
  thaiRegion: string;
};

export type ListResponse<T> = {
  list: T[];
  totalCount: number;
};

export type KTPageResult = {
  number: number;
  size: number;
  totalElements: number;
  totalPages: number;
};

export type BreadcrumbItem = {
  label: string;
  url?: string;
  labelParam?: { [x: string]: string | number };
};
export type FileItem = {
  id: number;
  sortingOrder: number;
  uid: number;
  userUid: number;
  fileOriginalName: string;
  fileExtension?: string;
  category: string;
  driver: string;
  fileType: string;
  path: string;
  realPath: string;
  fullUrl: string;
  size: number;
  resized: boolean;
  public: boolean;
  createdAt?: string;
};

export type FileListSearchParamFormData = {
  searchField?: string;
  searchKeyword?: string;
};

export type FileListSearchParam = FileListSearchParamFormData & AppTableConditions;

export type AppDialogType = 'normal' | 'success' | 'warning' | 'error' | 'info' | 'question' | 'tips';

export enum FileCategory {
  Item = 'item',
  FraudReport = 'fraud_report',
  SellerApp = 'seller_application',
  MembershipApp = 'membership_application',
  Other = 'other',
}

export enum FilePermission {
  Public = 'public',
  Private = 'private',
}

export interface FileUploadRequest {
  category: FileCategory;
  permission: FilePermission;
  width?: number;
  height?: number;
  cropX?: number;
  cropY?: number;
  cropWidth?: number;
  cropHeight?: number;
}
