import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import { CircularProgress, Typography, makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { getT2HSiteUrl } from '../../../../helpers/utils';
import { Message, MessageListingInfoData } from '../../../../models/message';
import { getAuthToken } from '../../../../services/auth';
import useTypographyStyles from '../../../../theme/typography.style';

const useStyles = makeStyles((theme) => ({
  contentImage: {
    maxWidth: '250px',
    '& img': {
      width: '100%',
      borderRadius: '12px',
    },
  },
  thumbnail: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
    borderRadius: '12px',
  },
  trackingClass: {
    boxShadow: theme.shadows[4],
    borderRadius: '8px',
    textAlign: 'left',
    justifyContent: 'left',
    alignItems: 'left',
    margin: '6px 4px',
  },
  dataTextWrap: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '200px',
  },
  trackingClickClass: {
    padding: '8px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: '0 0 8px 8px',
  },
  messageContent: {
    padding: '8px 16px',
    borderRadius: '12px',
    background: '#6992DD',
    width: 'auto',
    display: 'inline-block',
    margin: '4px 0',
    color: 'white',
  },
  contentLeft: {
    float: 'left',
    clear: 'both',
  },
  contentRight: {
    float: 'right',
    clear: 'both',
    textAlign: 'right',
    justifyContent: 'right',
    alignItems: 'right',
  },
  flexRight: {
    textAlign: 'right',
    justifyContent: 'right',
    alignItems: 'right',
  },
  extraSmallText: {
    fontSize: '12px',
    color: theme.palette.grey[500],
  },
  extraSmallTextError: {
    fontSize: '12px',
    color: theme.palette.error.main,
    marginRight: '4px',
  },
  mediaImageContainer: {
    position: 'relative',
    '& > img': {
      width: '200px',
      display: 'block',
      borderRadius: '16px',
      transition: 'fast',
      overflow: 'hidden',
      height: 'auto',
      opacity: 0,
    },
  },
  loadedMediaImageContainer: {
    height: 'auto',
    opacity: 1,
    '& > img': {
      opacity: 1,
    },
  },
  mediaImagePlaceholder: {
    width: '100%',
    height: '100%',
    display: 'flex',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(0,0,0,.15)',
    borderRadius: '16px',
    transition: 'fast',
    opacity: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadedMediaImagePlaceholder: {
    opacity: 0,
  },
  loadingIcon: {
    fill: '#fff',
    color: '#fff',
  },
  imageErrorIcon: {
    color: '#9a9a9a',
    fontSize: '32px',
  },
}));

const MessageItem: FC<{
  message: Message;
  hashId: string;
}> = (props) => {
  const { message, hashId } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const typoClasses = useTypographyStyles();
  const [mediaPath, setMediaPath] = useState<string>('');
  const [isMediaLoaded, setIsMediaLoaded] = useState<boolean>(false);
  const mediaPathRef = useRef<string>('');

  const getContentByType = (type: string | null, content: string | null) => {
    switch (type) {
      case 'sticker':
        return <img src={`/assets/stickers/${content}.png`} width={60} height={60} alt={`${content}`} />;
      case 'image':
        return (
          <>
            <Box
              className={clsx(classes.mediaImageContainer, {
                [classes.loadedMediaImageContainer]: isMediaLoaded,
              })}>
              <img
                src={mediaPath}
                alt="chat-room-media"
                width={200}
                height={200}
                onLoad={() => {
                  setIsMediaLoaded(true);
                }}
                onError={() => {
                  setIsMediaLoaded(true);
                }}
              />
              <Box
                onTransitionEnd={(e) => {
                  if (isMediaLoaded) {
                    e.currentTarget.remove();
                  }
                }}
                className={clsx(classes.mediaImagePlaceholder, {
                  [classes.loadedMediaImagePlaceholder]: isMediaLoaded,
                })}>
                <CircularProgress size={32} className={classes.loadingIcon} />
              </Box>
            </Box>
          </>
        );
      case 'video':
        return (
          <Box className={classes.contentImage}>
            <video
              className={classes.thumbnail}
              controls
              disablePictureInPicture
              disableRemotePlayback
              muted
              playsInline>
              <source src={mediaPath} type="`video/mp4" />
            </video>
          </Box>
        );
      case 'listing-info':
        // eslint-disable-next-line no-case-declarations
        const messageListingInfoData = JSON.parse(content || '') as MessageListingInfoData;
        return (
          <Box className={classes.trackingClass}>
            <a
              href={getT2HSiteUrl(`/listing/${messageListingInfoData.hashId}`)}
              target="_blank"
              rel="noreferrer"
              className={typoClasses.noDecorationText}>
              <Box display="flex" flexDirection="row" justifyContent="space-between" p={1}>
                <Box>
                  <img
                    src={messageListingInfoData.coverImageUrl}
                    width={80}
                    height={80}
                    alt={`${messageListingInfoData.coverImageUrl}`}
                  />
                </Box>
                <Box pl={1}>
                  <Typography className={classes.dataTextWrap}>{messageListingInfoData.detail}</Typography>
                  <Typography className={typoClasses.errorText}>{messageListingInfoData.price}</Typography>
                </Box>
              </Box>
              <Box className={classes.trackingClickClass}>{t('user:chat.touchToOpen')}</Box>
            </a>
          </Box>
        );
      default:
        return (
          <Box>
            <Typography className={classes.messageContent}>{content}</Typography>
          </Box>
        );
    }
  };

  const loadMedia = useCallback(async () => {
    if (!message.content) {
      return;
    }

    if (mediaPathRef.current.trim()) {
      URL.revokeObjectURL(mediaPathRef.current.trim());
    }

    const tokenInfo = getAuthToken();
    const idToken = tokenInfo?.firebaseIdToken;
    if (idToken) {
      const result = await fetch(message.content.content, {
        headers: {
          'x-admin-token': idToken || '',
        },
      })
        .then(async (response) => {
          const blob = await response.blob();
          return URL.createObjectURL(blob);
        })
        .catch(() => null);
      if (result) {
        setMediaPath(result);
        mediaPathRef.current = result;
      }
    }
  }, [message.content]);

  useEffect(() => {
    if (message.content?.type && ['image', 'video'].includes(message.content.type)) {
      if (process.env.REACT_APP_ENVIRONMENT === 'production') {
        setMediaPath(message.content.content);
      } else {
        loadMedia();
      }
    }

    return () => {
      if (mediaPathRef.current.trim()) {
        URL.revokeObjectURL(mediaPathRef.current.trim());
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box overflow="hidden">
      <Box
        pb={2}
        className={clsx({
          [classes.contentRight]: message.sender === hashId,
          [classes.contentLeft]: message.sender !== hashId,
        })}>
        {message.content && getContentByType(message.content?.type, message.content?.content)}
        <Box
          display="flex"
          className={clsx({
            [classes.flexRight]: message.sender === hashId,
          })}>
          {message.deletedAt && (
            <Box mr={1} display="flex">
              <Typography className={classes.extraSmallTextError}>{t('user:chat.deleteAt')}</Typography>
              <Typography className={classes.extraSmallText}>
                {dayjs(message.deletedAt).format('DD MMM YY HH:mm')}
              </Typography>
            </Box>
          )}
          <Typography className={classes.extraSmallText}>
            {t('user:chat.createAt')} {dayjs(message.createdAt).format('DD MMM YY HH:mm')}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default MessageItem;
