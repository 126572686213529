import React, { FC, useContext, useState } from 'react';

import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import useDeepCompareEffect from 'use-deep-compare-effect';

import LoginBonusListSearchForm from '../../../../components/partials/v3/dailyChallenge/LoginBonusListSearchForm';
import LoginBonusListTable from '../../../../components/partials/v3/dailyChallenge/LoginBonusListTable';
import { AppGlobalUiContext } from '../../../../context/AppGlobalUiContext';
import { SearchFilterContext } from '../../../../context/SearchFilterContext';
import { defaultRowsPerPage } from '../../../../helpers/constants';
import { AppTableConditions } from '../../../../models';
import {
  LoginBonus,
  LoginBonusListSearchFormData,
  LoginBonusListSearchParamsTC,
} from '../../../../models/login-bonus.model';
import { getLoginBonusList } from '../../../../services/v3/login-bonus';

const searchFilterContextKey = 'daily-challenge-login-bonus';

const LoginBonusList: FC = () => {
  const { t } = useTranslation();
  const { showSnackbar } = useContext(AppGlobalUiContext);
  const [loginBonusList, setLoginBonusList] = useState<LoginBonus[]>([]);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [totalBonusList, setTotalBonusList] = useState<number>(0);

  const { currentFilters: ctxFilters, setFilters } = useContext(SearchFilterContext);
  const currentFilters: LoginBonusListSearchParamsTC = ctxFilters ? ctxFilters[searchFilterContextKey] : undefined;
  const [searchFilters, setSearchFilters] = useState<LoginBonusListSearchFormData>({
    searchField: currentFilters?.searchField || '',
    searchKeyword: currentFilters?.searchKeyword || '',
    filteringStartDate: currentFilters?.filteringStartDate || 0,
    filteringEndDate: currentFilters?.filteringEndDate || 0,
  });

  const [tableConditions, setTableConditions] = useState<AppTableConditions>({
    page: currentFilters?.page || 0,
    rowsPerPage: currentFilters?.rowsPerPage || defaultRowsPerPage,
    sortColumn: currentFilters?.sortColumn || 'createdAt',
    sortDirection: currentFilters?.sortDirection || 'desc',
  });

  const getCurrentSearchParam = (): LoginBonusListSearchParamsTC => ({
    page: tableConditions.page || 0,
    rowsPerPage: tableConditions.rowsPerPage || defaultRowsPerPage,
    sortColumn: tableConditions.sortColumn || 'createdAt',
    sortDirection: tableConditions.sortDirection || 'desc',
    searchField: searchFilters.searchField,
    searchKeyword: searchFilters.searchKeyword,
    filteringStartDate: searchFilters.filteringStartDate || 0,
    filteringEndDate: searchFilters.filteringEndDate || 0,
  });

  const requestLoginBonusList = async (isPageSubscribed = true): Promise<boolean> => {
    if (isPageSubscribed) {
      setIsLoadingData(true);

      const searchConditions = getCurrentSearchParam();
      setFilters(searchFilterContextKey, searchConditions);

      const filteringStartDate = dayjs(searchConditions.filteringStartDate).valueOf();
      const filteringEndDate = dayjs(searchConditions.filteringEndDate).valueOf();

      const result = await getLoginBonusList({ ...searchConditions, filteringStartDate, filteringEndDate });

      setIsLoadingData(false);

      if (result.data) {
        setLoginBonusList(result.data.contents);
        setTotalBonusList(result.data?.page?.totalElements || 0);
        return Promise.resolve(true);
      }
      setLoginBonusList([]);
      setTotalBonusList(0);
      showSnackbar(t('daily-challenge:loginBonus.error.list.requestFailed').toString(), 'error');
      return Promise.reject(result.error);
    }

    return false;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useDeepCompareEffect((): any => {
    let isSubscribed = true;

    requestLoginBonusList(isSubscribed);

    return () => (isSubscribed = false);
  }, [tableConditions, searchFilters]);

  const tableConditionsChangedHandler = (values: AppTableConditions) => {
    setTableConditions(values);
  };

  const onSearchFormSubmit = (filters: LoginBonusListSearchFormData) => {
    if (isLoadingData) {
      showSnackbar(t('common.message.pleaseWaitForDataToFinish').toString(), 'warning');
      return;
    }
    if (tableConditions.page !== 0) {
      tableConditions.page = 0;
    }
    setSearchFilters(filters);
  };

  return (
    <>
      <LoginBonusListSearchForm
        isLoadingData={isLoadingData}
        currentFilters={searchFilters}
        onSearchFormSubmit={onSearchFormSubmit}
      />
      <LoginBonusListTable
        currentConditions={tableConditions}
        loginBonusList={loginBonusList}
        totalBonusList={totalBonusList}
        isLoadingData={isLoadingData}
        onTableConditionsChanged={tableConditionsChangedHandler}
        onFetch={() => requestLoginBonusList()}
      />
    </>
  );
};

export default LoginBonusList;
