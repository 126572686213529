import React, { FC, useState } from 'react';

import { TableRow } from '@material-ui/core';
import clsx from 'clsx';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { AppTableConditions, Category, CategoryBreadcrumb, CategoryPath, TableColumn } from '../../../../../models';
import useAppTableStyles from '../../../../../theme/table.style';
import AppTableCell from '../../../../ui/AppTableCell';
import AppTableList from '../../../../ui/AppTableList';

const categoryTableColumns: TableColumn[] = [
  { name: 'uid', label: 'master-data:common.field.uid' },
  { name: 'name', label: 'master-data:common.field.name' },
  { name: 'thaiName', label: 'master-data:common.field.thaiName' },
  { name: 'slug', label: 'master-data:common.field.slug' },
  { name: 'breadcrumbPath', label: 'master-data:list.category.columns.breadcrumbPath', width: 140, unsortable: true },
  { name: 'path', label: 'master-data:list.category.columns.path', width: 140, unsortable: true },
];

export type CategoryListTableProps = {
  currentConditions: AppTableConditions;
  totalCategories: number;
  isLoadingData: boolean;
  categoryList: Category[];
  onTableConditionsChanged: (values: AppTableConditions) => void;
};

const CategoryListTable: FC<CategoryListTableProps> = (props) => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { totalCategories, isLoadingData, currentConditions, categoryList, onTableConditionsChanged } = props;
  const tableClasses = useAppTableStyles();
  const [tableConditions, setTableConditions] = useState<AppTableConditions>(currentConditions);

  const onTableConditionChangedHandler = (values: AppTableConditions) => {
    if (JSON.stringify(values) !== JSON.stringify(tableConditions)) {
      setTableConditions(values);
      onTableConditionsChanged(values);
    }
  };

  const openCategoryDetailPage = (category: Category) => {
    history.push(`${url}/${category.uid}`);
  };

  const getJsonItemCount = <T,>(value: string | undefined): string | number => {
    if (!value) {
      return '-';
    }

    try {
      return (JSON.parse(value) as T[]).length;
    } catch (e) {
      return '-';
    }
  };

  return (
    <AppTableList
      {...tableConditions}
      aria-label="brand-list"
      columns={categoryTableColumns}
      totalDataCount={totalCategories}
      hasPagination
      onTableConditionChanged={onTableConditionChangedHandler}
      isLoading={isLoadingData}
      tableBodyContent={
        categoryList.length > 0 && (
          <>
            {categoryList.map((category: Category) => (
              <TableRow
                hover
                key={category.id}
                classes={{ root: clsx(tableClasses.tableRow, tableClasses.clickableTableRow) }}
                onClick={() => openCategoryDetailPage(category)}>
                <AppTableCell>{category.uid}</AppTableCell>
                <AppTableCell>{category.name}</AppTableCell>
                <AppTableCell>{category.thaiName}</AppTableCell>
                <AppTableCell>{category.slug}</AppTableCell>
                <AppTableCell>{getJsonItemCount<CategoryBreadcrumb>(category.breadcrumbPath)}</AppTableCell>
                <AppTableCell>{getJsonItemCount<CategoryPath>(category.path)}</AppTableCell>
              </TableRow>
            ))}
          </>
        )
      }
    />
  );
};

export default CategoryListTable;
