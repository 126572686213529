import React, { FC, useContext, useState } from 'react';

import { Box, TableRow, Typography } from '@material-ui/core';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { AppGlobalUiContext } from '../../../../../../context/AppGlobalUiContext';
import { SearchFilterContext } from '../../../../../../context/SearchFilterContext';
import { defaultRowsPerPage } from '../../../../../../helpers/constants';
import i18n from '../../../../../../i18n';
import {
  AppTableConditions,
  MembershipTransaction,
  MembershipTransactionSearchFiltersTC,
  MembershipTransactionSearchParamsTC,
  TableColumn,
} from '../../../../../../models';
import { getMembershipTransaction } from '../../../../../../services/v3/user-balance';
import AppDialog from '../../../../../ui/AppDialog';
import AppTableCell from '../../../../../ui/AppTableCell';
import AppTableList from '../../../../../ui/AppTableList';

const searchFilterContextKey = 'coin';

export type MembershipTransactionDialogProps = {
  title: string;
  open: boolean;
  userHashId: string;
  isLoadingData: boolean;
  onClose: () => void;
  onDialogExited?: () => void;
};

const coinUsageTableColumns: TableColumn[] = [
  { name: 'hashId', label: 'user:memberShipTransaction.columns.hashId', width: 100 },
  { name: 'orderCreateAt', label: 'user:memberShipTransaction.columns.orderCreateAt', width: 100 },
  { name: 'orderId', label: 'user:memberShipTransaction.columns.orderId', width: 100 },
  { name: 'productName', label: 'user:memberShipTransaction.columns.productName', width: 200 },
  { name: 'payment', label: 'user:memberShipTransaction.columns.payment', width: 150 },
  { name: 'usageType', label: 'user:memberShipTransaction.columns.usageType', width: 150 },
  { name: 'expireDate', label: 'user:memberShipTransaction.columns.expireDate', width: 150 },
];

const MembershipTransactionDialog: FC<MembershipTransactionDialogProps> = (props) => {
  const { open, title, userHashId, onClose, onDialogExited } = props;
  const { t } = useTranslation();
  const [membershipTransactionData, setMembershipTransactionData] = useState<MembershipTransaction[]>([]);
  const { showSnackbar } = useContext(AppGlobalUiContext);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [totalItems, setTotalItems] = useState<number>(0);

  const dateDisplayFormat = i18n.language === 'th' ? 'D MMM BB' : 'D MMM YY';

  const { currentFilters: ctxFilters, setFilters } = useContext(SearchFilterContext);
  const currentFilters: MembershipTransactionSearchParamsTC = ctxFilters
    ? ctxFilters[searchFilterContextKey]
    : undefined;

  const [tableConditions, setTableConditions] = useState<Partial<AppTableConditions>>({
    page: currentFilters?.page || 0,
    rowsPerPage: currentFilters?.rowsPerPage || defaultRowsPerPage,
    sortColumn: currentFilters?.sortColumn || 'createdAt',
    sortDirection: currentFilters?.sortDirection || 'desc',
  });

  const getCurrentSearchParam = (): MembershipTransactionSearchFiltersTC => ({
    page: tableConditions.page || 0,
    rowsPerPage: tableConditions.rowsPerPage || defaultRowsPerPage,
    sortColumn: tableConditions.sortColumn || 'createdAt',
    sortDirection: tableConditions.sortDirection || 'desc',
  });

  const getCoinUsage = async (isPageSubscribed = true): Promise<boolean> => {
    if (isPageSubscribed) {
      setIsLoadingData(true);

      const searchConditions = getCurrentSearchParam();
      setFilters(searchFilterContextKey, searchConditions);

      const result = await getMembershipTransaction(userHashId, searchConditions);
      setIsLoadingData(false);

      if (result.data) {
        setMembershipTransactionData(result.data.contents);
        setTotalItems(result.data?.page?.totalElements || 0);
        return Promise.resolve(true);
      }

      showSnackbar(t('user:memberShipTransaction.message.requestListFailed').toString(), 'error');
      return Promise.reject(result.error);
    }

    return false;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useDeepCompareEffect((): any => {
    let isSubscribed = true;
    getCoinUsage(isSubscribed);

    return () => (isSubscribed = false);
  }, [tableConditions]);

  const onTableConditionChangedHandler = (values: AppTableConditions) => {
    if (JSON.stringify(values) !== JSON.stringify(tableConditions)) {
      setTableConditions({ ...values });
    }
  };

  return (
    <AppDialog
      open={open}
      title={title}
      onClose={onClose}
      okButtonText="common:button.close"
      okButtonColor="default"
      okButtonVariant="outlined"
      closeOnOkClicked
      dialogProps={{
        fullWidth: true,
        maxWidth: 'lg',
        TransitionProps: {
          onExited: () => {
            if (typeof onDialogExited === 'function') {
              onDialogExited();
            }
          },
        },
      }}>
      <Box px={3}>
        {!isLoadingData && (
          <Box>
            <Box textAlign="right" mb={2}>
              <Typography variant="subtitle2" color="textSecondary" style={{ textAlign: 'right' }}>
                {t('common:list.resultTotal', { fieldName: t(totalItems.toLocaleString() || '0') })}
              </Typography>
            </Box>
            {membershipTransactionData.length > 0 ? (
              <AppTableList
                {...tableConditions}
                aria-label="payment-history-list"
                columns={coinUsageTableColumns}
                totalDataCount={totalItems}
                hasPagination
                onTableConditionChanged={onTableConditionChangedHandler}
                tableBodyContent={
                  membershipTransactionData.length > 0 && (
                    <>
                      {membershipTransactionData.map((item: MembershipTransaction) => (
                        <TableRow key={item.hashId}>
                          <AppTableCell>{item.hashId}</AppTableCell>
                          <AppTableCell>
                            {item?.userOrderOnce?.createdAt ? (
                              <Box>
                                <Typography variant="subtitle2">
                                  {dayjs(item.userOrderOnce.createdAt).format(`${dateDisplayFormat}`)}
                                </Typography>
                                <Typography variant="subtitle2">
                                  {dayjs(item.userOrderOnce.createdAt).format(`HH:mm:ss`)}
                                </Typography>
                              </Box>
                            ) : (
                              '-'
                            )}
                          </AppTableCell>
                          <AppTableCell>
                            {item?.userOrderOnce?.orderOnceHashId ? item.userOrderOnce.orderOnceHashId : '-'}
                          </AppTableCell>
                          <AppTableCell>{item?.product?.productName ? item?.product?.productName : '-'}</AppTableCell>
                          <AppTableCell>
                            {item?.userOrderOnce?.omisePromptPayDetail?.qrCodeUri && (
                              <Typography>{t('order:orderList.list.promptPay')}</Typography>
                            )}
                            {item?.userOrderOnce?.omiseCreditCardDetail?.brand && (
                              <Typography>{t('order:orderList.list.creditCard')}</Typography>
                            )}
                            {!item?.userOrderOnce?.omiseCreditCardDetail?.brand &&
                              !item?.userOrderOnce?.omisePromptPayDetail?.qrCodeUri && (
                                <Typography>{item.createdBy.displayName ? item.createdBy.displayName : '-'}</Typography>
                              )}
                            {item?.userOrderOnce?.userOrderSubscriptionHashId && (
                              <Typography variant="subtitle2">
                                {t('order:orderList.list.orderSubscriptionOpen')}
                              </Typography>
                            )}
                          </AppTableCell>
                          <AppTableCell>{t(`user:details.label.${item.usageType}`)}</AppTableCell>
                          <AppTableCell>
                            <Box display="flex" flexDirection="row">
                              <Typography variant="subtitle2">
                                {dayjs(item.dateFrom).format(`${dateDisplayFormat}`)}
                              </Typography>
                              <Typography style={{ margin: '0 4px' }}>-</Typography>
                              <Typography variant="subtitle2">
                                {dayjs(item.dateTo).format(`${dateDisplayFormat}`)}
                              </Typography>
                            </Box>
                          </AppTableCell>
                        </TableRow>
                      ))}
                    </>
                  )
                }
              />
            ) : (
              <Box textAlign="center" my={4}>
                <Typography>{t('user:memberShipTransaction.message.noMembershipTransactionData')}</Typography>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </AppDialog>
  );
};

export default MembershipTransactionDialog;
