import { apiTCRequest } from '../../helpers/request';
import { queryParamGenerator } from '../../helpers/utils';
import { ApiResponse } from '../../models';
import {
  GetUserAppListResponse,
  UserAppListSearchFilters,
  UserApplication,
  UserApplicationResponse,
} from '../../models/user-application.model';

const getUserAppListQueryParam = (param?: UserAppListSearchFilters): string => {
  const reqParam: { [x: string]: string | number } = {
    page: param?.page || 0, // plus 1 for index shift between FE component and backend query
    per_page: param?.rowsPerPage || 10,
    sort: param?.sortColumn ? `id%7C${(param?.sortDirection || 'desc').toUpperCase()}` : '',
  };

  // Only search with keyword when it comes with the selected column to be searched
  if (param?.searchKeyword && param?.searchField) {
    reqParam[param.searchField] = param.searchKeyword.trim();
  }
  if (param?.filteringStatuses?.length) {
    reqParam.is_verified = param.filteringStatuses.join(',');
  }

  return queryParamGenerator(reqParam);
};

export const getUserApplicationList = async (
  param?: UserAppListSearchFilters,
): Promise<ApiResponse<GetUserAppListResponse>> => {
  try {
    const queryParamString = getUserAppListQueryParam(param);
    const result = await apiTCRequest<UserApplicationResponse>(`/admin/user-application/?${queryParamString}`);
    return {
      data: {
        userApplications: result.data.contents,
        totalCount: result.data.contents.length,
      },
    };
  } catch (e) {
    return {
      data: null,
      error: 'user-applications:error.list.requestFailed',
    };
  }
};

export const approveUserApplicationByHashId = async (hashId: string): Promise<ApiResponse<UserApplication>> => {
  try {
    const result = await apiTCRequest<UserApplication>(`/admin/user-application/approve/${hashId}`, {
      method: 'POST',
    });
    return {
      data: result.data,
    };
  } catch (e) {
    return {
      data: null,
      error: 'Error approve User Application By hashId',
    };
  }
};

export const deleteUserApplicationByHashId = async (hashId: string): Promise<ApiResponse<boolean>> => {
  try {
    await apiTCRequest<boolean>(`/admin/user-application/${hashId}`, {
      method: 'DELETE',
    });

    return {
      data: true,
    };
  } catch (error) {
    return {
      data: null,
      error: 'Error delete User Application By hashId',
    };
  }
};
