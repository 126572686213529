import React, { FC, Suspense, useContext } from 'react';

import { Backdrop, CircularProgress, createStyles, makeStyles, Theme, Toolbar } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';

import AppNavigation from './components/partials/AppNavigation';
import Loading from './components/partials/Loading';
import { AuthContext } from './context/AuthContext';
import { RenderRoutes } from './helpers/RenderRoutes';
import routes from './route.config';

declare global {
  interface Window {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    googleLoginHandler: null | ((loginResult: any) => void);
    google: any;
    /* eslint-enable @typescript-eslint/no-explicit-any */
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { display: 'flex' },
    content: {
      flexGrow: 1,
      maxWidth: '100%',
      minWidth: 0,
    },
    toolbar: theme.mixins.toolbar,
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }),
);

const App: FC = () => {
  const classes = useStyles();
  const { isAuthenticated, isAuthInitialized } = useContext(AuthContext);

  return (
    <Suspense fallback={<Loading />}>
      {isAuthInitialized && (
        <div className={classes.root}>
          <CssBaseline />
          {isAuthenticated && <AppNavigation />}
          <main className={classes.content}>
            {isAuthenticated && <Toolbar variant="dense" />}
            <RenderRoutes routes={routes} />
          </main>
        </div>
      )}
      {!isAuthInitialized && (
        <Backdrop className={classes.backdrop} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </Suspense>
  );
};

export default App;
