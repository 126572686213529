import React, { FC, useContext, useEffect, useState } from 'react';

import {
  Box,
  Container,
  Grid,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  InputLabel,
  CircularProgress,
  Chip,
} from '@material-ui/core';
import ArrowDownwardOutlinedIcon from '@material-ui/icons/ArrowDownwardOutlined';
import ArrowUpwardOutlinedIcon from '@material-ui/icons/ArrowUpwardOutlined';
import SearchIcon from '@material-ui/icons/Search';
import YoutubeSearchedForIcon from '@material-ui/icons/YoutubeSearchedFor';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { MasterDataContext } from '../../../../context/MasterDataContext';
// import { ATTRIBUTE_UID_BRAND, ATTRIBUTE_UID_MODEL, KTCategory, SelectItem } from '../../../../models';
import { ATTRIBUTE_UID_MODEL, KTCategory, SelectItem } from '../../../../models';
import { ListingPossession, ListingSearchFormData, ListingStatus } from '../../../../models/listing.model';
// import { getCategoryByMasterIndexUid, getKTBrandsByCategoryUID } from '../../../../services';
import { getCategoryByMasterIndexUid } from '../../../../services';
import { getKTBrandsByCategorySlug } from '../../../../services/brand';
import { getKTModelsByCategoryUID } from '../../../../services/model';
import useAppContainerStyles from '../../../../theme/container.style';
import useTypographyStyles from '../../../../theme/typography.style';
import AppButton from '../../../ui/AppButton';
import AppChipList from '../../../ui/AppChipList';
import AppFormControl from '../../../ui/AppFormControl';
import AppFormControlGroup from '../../../ui/AppFormControlGroup';

export type ListingSearchFormProps = {
  isLoadingData?: boolean;
  currentFilters: ListingSearchFormData | undefined;
  onSearchFormSubmit: (values: ListingSearchFormData) => void;
  onLowerPriceClick: () => void;
  onUpperPriceClick: () => void;
};

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
  },
  searchBoxContainer: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 450,
    },
  },
  filterHeader: {
    fontWeight: 700,
  },
  selectedTag: {
    marginRight: '4px',
    marginBottom: '4px',
    '& .MuiChip-icon': {
      order: 3,
      marginRight: '8px',
      marginLeft: '-6px',
    },
  },
  labelFont: {
    fontSize: '14px',
  },
}));

const searchFieldList: { label: string; value: ListingSearchFormData['searchField'] }[] = [
  {
    label: 'listing:list.search.listingUser',
    value: 'userHashIDs',
  },
  {
    label: 'listing:list.search.listingNumber',
    value: 'hashIDs',
  },
];

// TODO: Status searching in the elastic search not working.
const searchStatuses = [
  {
    label: 'listing:common.status.submitted',
    value: ListingStatus.SUBMITTED,
  },
  {
    label: 'listing:common.status.published',
    value: ListingStatus.PUBLISHED,
  },
  {
    label: 'listing:common.status.booked',
    value: ListingStatus.BOOKED,
  },
  {
    label: 'listing:common.status.sold',
    value: ListingStatus.SOLD,
  },
  {
    label: 'listing:common.status.rejected',
    value: ListingStatus.REJECTED,
  },
  {
    label: 'listing:common.status.unlisted',
    value: ListingStatus.UNLISTED,
  },
  {
    label: 'listing:common.status.pending',
    value: ListingStatus.PENDING,
  },
];

const searchPossessions = [
  {
    label: 'listing:common.possession.jkke',
    value: ListingPossession.Jkke,
  },
  {
    label: 'listing:common.possession.jkke_waiting',
    value: ListingPossession.JkkeWaiting,
  },
  {
    label: 'listing:common.possession.jkke_rejected',
    value: ListingPossession.JkkeRejected,
  },
  {
    label: 'listing:common.possession.t2h_official',
    value: ListingPossession.T2hOfficial,
  },
  {
    label: 'listing:common.possession.t2h_official_waiting',
    value: ListingPossession.T2hOfficialWaiting,
  },
  {
    label: 'listing:common.possession.t2h_official_rejected',
    value: ListingPossession.T2hOfficialRejected,
  },
  {
    label: 'listing:common.possession.t2h_official_consignment',
    value: ListingPossession.T2hOfficialConsignment,
  },
];

const ListingSearchForm: FC<ListingSearchFormProps> = (props) => {
  const { onSearchFormSubmit, currentFilters, isLoadingData = false, onLowerPriceClick, onUpperPriceClick } = props;
  const { t } = useTranslation();
  const { getData } = useContext(MasterDataContext);
  const classes = useStyles();
  const containerClasses = useAppContainerStyles();
  const typoClasses = useTypographyStyles();

  const [breadcrumbType2, setBreadcrumbType2] = useState<string>('categoryQuery');

  const [KTCategoryMasterRoots, setKTCategoryMasterRoots] = useState<KTCategory[]>([]);

  const [rootCategorySelectList, setRootCategorySelectList] = useState<SelectItem<string>[]>([]);
  const [subCategorySelectList, setSubCategorySelectList] = useState<SelectItem<string>[]>([]);
  const [brandSelectList, setBrandSelectList] = useState<SelectItem<string>[]>([]);

  const [isLoadingRootCategory, setIsLoadingRootCategory] = useState(true);

  const [isLoadingPath2, setIsLoadingPath2] = useState(false);

  const { handleSubmit, control, setValue, getValues, watch } = useForm<ListingSearchFormData>({
    mode: 'onSubmit',
    defaultValues: {
      searchField: currentFilters?.searchField || searchFieldList[0].value,
      searchKeyword: currentFilters?.searchKeyword || '',
      rootCategory: '',
      breadcrumbPath2: '',
      modelQuery: currentFilters?.modelQuery || '',
      brandQuery: currentFilters?.brandQuery || '',
      categoryQuery: currentFilters?.categoryQuery || '',
      filteringStatuses: [],
      filteringPossessions: [],
      createdDateFrom: '',
      createdDateTo: '',
      lowerFirstRegistrationYear: '',
      upperFirstRegistrationYear: '',
      lowerBoundPrice: '',
      upperBoundPrice: '',
    },
  });

  const submitSearchForm = () => {
    if (!isLoadingData) {
      setValue('searchKeyword', getValues('searchKeyword').trim());
      handleSubmit(onSearchFormSubmit)();
    }
  };

  const clearSearchPath = () => {
    setValue('modelQuery', '');
    setValue('brandQuery', '');
    setValue('categoryQuery', '');
  };

  useEffect(() => {
    if (watch('rootCategory')) {
      const rootCategoryValue = getValues('rootCategory');
      if (rootCategoryValue) {
        switch (breadcrumbType2) {
          default:
            setValue('categoryQuery', rootCategoryValue);
            break;
        }
      }
    } else {
      clearSearchPath();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('rootCategory')]);

  useEffect(() => {
    if (watch('rootCategory')) {
      const rootCategoryValue = getValues('rootCategory');

      if (rootCategoryValue) {
        (async () => {
          const brandListItem: SelectItem<string>[] = [];
          const brandList = await getKTBrandsByCategorySlug(rootCategoryValue);

          /* eslint-disable @typescript-eslint/no-explicit-any */
          brandList.data?.list.forEach((value: any, key: number) => {
            brandListItem[key] = {
              label: value?.thaiName || value?.modelName,
              value: value?.slug,
            };
          });
          setBrandSelectList(brandListItem);
        })();
      }
    } else {
      clearSearchPath();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('rootCategory')]);

  useEffect(() => {
    if (watch('breadcrumbPath2')) {
      const breadcrumbType2Value = getValues('breadcrumbPath2');
      if (breadcrumbType2Value) {
        switch (breadcrumbType2) {
          case 'modelQuery':
            setValue('modelQuery', breadcrumbType2Value);
            break;
          // TODO: after create brandQuery in condition search this might be no longer use. Please remove
          // Ref: [https://tripetch.atlassian.net/browse/T2HV1-3061](https://tripetch.atlassian.net/browse/T2HV1-3061)
          // case 'brandQuery':
          //   setValue('brandQuery', breadcrumbType2Value);
          //   break;
          case 'categoryQuery':
            setValue('categoryQuery', breadcrumbType2Value);
            break;
          default:
            break;
        }
      }
    } else {
      clearSearchPath();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('breadcrumbPath2')]);

  useEffect(() => {
    if (watch('brandQuery')) {
      const brandQuery = getValues('brandQuery');

      if (brandQuery) {
        setValue('brandQuery', brandQuery);
      }
    } else {
      clearSearchPath();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('brandQuery')]);

  const clearSearchForm = () => {
    if (!isLoadingData) {
      setValue('searchField', searchFieldList[0].value);
      setValue('searchKeyword', '');
      setValue('rootCategory', '');
      setValue('breadcrumbPath2', '');
      setValue('filteringStatuses', []);
      setValue('filteringPossessions', []);
      setValue('createdDateFrom', '');
      setValue('createdDateTo', '');
      setValue('lowerFirstRegistrationYear', '');
      setValue('upperFirstRegistrationYear', '');
      setValue('lowerBoundPrice', '');
      setValue('upperBoundPrice', '');
      handleSubmit(onSearchFormSubmit)();
    }
  };

  const requestKTCategoryMasterRoots = async (isPageSubscribed = true): Promise<boolean> => {
    if (isPageSubscribed) {
      setIsLoadingRootCategory(true);
      try {
        const result: KTCategory[] | undefined = await getData('ktCategoryMasterRoots');

        if (result !== undefined) {
          const mapData = result.map((item) => ({ label: item.thaiName, value: item.slug }));
          setRootCategorySelectList(mapData);
          setKTCategoryMasterRoots(result);
        }

        setIsLoadingRootCategory(false);
      } catch (error) {
        setIsLoadingRootCategory(false);
      }
    }

    return false;
  };

  const handleSetSubAndSpeCategorySelectList = async (
    allRootCategory: KTCategory[],
    selectedRootCategorySlug: KTCategory['slug'],
  ) => {
    setIsLoadingPath2(true);
    const findSelectedRootCategory = allRootCategory?.length
      ? allRootCategory.find((item) => item?.slug === selectedRootCategorySlug)
      : null;
    if (findSelectedRootCategory) {
      const pathUid = JSON.parse(findSelectedRootCategory.breadcrumbPath)[1]?.masterIndex?.uid || undefined;
      if (pathUid) {
        let pathList;
        switch (pathUid) {
          case ATTRIBUTE_UID_MODEL:
            pathList = await getKTModelsByCategoryUID(findSelectedRootCategory.uid.toString());
            setBreadcrumbType2('modelQuery');
            break;
          // case ATTRIBUTE_UID_BRAND:
          //   pathList = await getKTBrandsByCategoryUID(findSelectedRootCategory.uid.toString());
          //   setBreadcrumbType2('brandQuery');
          //   break;
          default:
            pathList = await getCategoryByMasterIndexUid(pathUid);
            setBreadcrumbType2('categoryQuery');
            break;
        }
        const pathListItem: SelectItem<string>[] = [];
        /* eslint-disable @typescript-eslint/no-explicit-any */
        pathList.data?.list.forEach((value: any, key: number) => {
          pathListItem[key] = {
            label: value?.thaiName || value?.modelName,
            value: value?.slug,
          };
        });
        setSubCategorySelectList(pathListItem);
      } else {
        setBreadcrumbType2('categoryQuery');
        setSubCategorySelectList([]);
      }
    }
    setIsLoadingPath2(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useEffect((): any => {
    let isSubscribed = true;
    requestKTCategoryMasterRoots(isSubscribed);
    return () => (isSubscribed = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={classes.filterContainer}>
      <Container className={containerClasses.container}>
        <form onSubmit={handleSubmit(onSearchFormSubmit)}>
          <Grid container direction="row" spacing={3}>
            <Grid item xs={12} md container spacing={2}>
              <Grid item xs={12} md="auto">
                <Box display="flex" flexDirection="row" flexWrap="wrap" className={classes.searchBoxContainer}>
                  <AppFormControlGroup mb={1} flexGrow={1} minWidth={0} pr={1}>
                    <AppFormControl margin="dense">
                      <Controller
                        name="searchField"
                        control={control}
                        defaultValue={searchFieldList[0].value}
                        render={({ field }) => (
                          <Select
                            labelId="listing-search-field-label"
                            id="listing-search-field"
                            value={field.value}
                            variant="outlined"
                            onChange={field.onChange}
                            fullWidth
                            disabled={isLoadingData}>
                            {searchFieldList.map((item) => (
                              <MenuItem value={item.value} key={item.value}>
                                {t(item.label)}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </AppFormControl>
                    <AppFormControl margin="dense" boxProps={{ flexGrow: 1 }}>
                      <Controller
                        name="searchKeyword"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <OutlinedInput
                            type="search"
                            endAdornment={<SearchIcon />}
                            id="listing-search-keyword"
                            value={field.value}
                            onChange={field.onChange}
                            fullWidth
                            disabled={isLoadingData}
                          />
                        )}
                      />
                    </AppFormControl>
                  </AppFormControlGroup>
                  <Box>
                    <AppButton type="submit" color="primary" disabled={isLoadingData}>
                      {t('common:message.search')}
                    </AppButton>
                  </Box>
                </Box>
              </Grid>
              <Grid item container direction="row" md={12} lg={7} xl spacing={1}>
                <Grid item container direction="row" md={10} lg sm spacing={1}>
                  <Grid item xs={12} sm={4}>
                    {isLoadingRootCategory ? (
                      <Box display="flex" alignItems="center" justifyContent="center">
                        <CircularProgress size={22} />
                      </Box>
                    ) : (
                      <Controller
                        name="rootCategory"
                        control={control}
                        render={({ field }) => (
                          <AppFormControl margin="dense">
                            <InputLabel className={classes.labelFont} id="root-category-label">
                              {t('listing:list.search.rootCategory')}
                            </InputLabel>
                            <Select
                              labelId="root-category-label"
                              id="root-category"
                              value={field.value}
                              variant="outlined"
                              onChange={(event) => {
                                field.onChange(event);
                                handleSetSubAndSpeCategorySelectList(
                                  KTCategoryMasterRoots,
                                  event.target?.value as string,
                                );
                              }}
                              fullWidth
                              disabled={isLoadingData}>
                              <MenuItem value="">
                                <Typography component="em" className={typoClasses.greyText}>
                                  {t('common:message.unspecified')}
                                </Typography>
                              </MenuItem>
                              {rootCategorySelectList.map((item) => (
                                <MenuItem value={item.value} key={item.value}>
                                  {t(item.label)}
                                </MenuItem>
                              ))}
                            </Select>
                          </AppFormControl>
                        )}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    {isLoadingPath2 ? (
                      <Box display="flex" alignItems="center" justifyContent="center">
                        <CircularProgress size={22} />
                      </Box>
                    ) : (
                      <Controller
                        name="breadcrumbPath2"
                        control={control}
                        render={({ field }) => (
                          <AppFormControl margin="dense">
                            <InputLabel className={classes.labelFont} id="listing-sub-category-label">
                              {t('listing:list.search.breadcrumbPath2')}
                            </InputLabel>
                            <Select
                              labelId="listing-sub-category-label"
                              id="listing-sub-category"
                              value={field.value}
                              variant="outlined"
                              onChange={field.onChange}
                              fullWidth
                              disabled={isLoadingData}>
                              <MenuItem value="">
                                <Typography component="em" className={typoClasses.greyText}>
                                  {t('common:message.unspecified')}
                                </Typography>
                              </MenuItem>
                              {subCategorySelectList.map((item) => (
                                <MenuItem value={item.value} key={item.value}>
                                  {t(item.label)}
                                </MenuItem>
                              ))}
                            </Select>
                          </AppFormControl>
                        )}
                      />
                    )}
                  </Grid>
                  {/* brand */}
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="brandQuery"
                      control={control}
                      render={({ field }) => (
                        <AppFormControl margin="dense">
                          <InputLabel className={classes.labelFont} id="listing-sub-category-label">
                            {t('listing:list.search.brandQuery')}
                          </InputLabel>
                          <Select
                            labelId="listing-sub-category-label"
                            id="listing-sub-category"
                            value={field.value}
                            variant="outlined"
                            onChange={field.onChange}
                            fullWidth
                            disabled={isLoadingData}>
                            <MenuItem value="">
                              <Typography component="em" className={typoClasses.greyText}>
                                {t('common:message.unspecified')}
                              </Typography>
                            </MenuItem>
                            {brandSelectList.map((item) => (
                              <MenuItem value={item.value} key={item.value}>
                                {t(item.label)}
                              </MenuItem>
                            ))}
                          </Select>
                        </AppFormControl>
                      )}
                    />
                  </Grid>
                  {/* brand */}
                </Grid>
                <Grid item xs={12} sm="auto">
                  <AppButton type="submit" color="primary" disabled={isLoadingData}>
                    {t('common:message.search')}
                  </AppButton>
                </Grid>
              </Grid>
              <Grid item container direction="row" md={12} lg={5} xl>
                <Grid item xs={10} sm="auto" md="auto" xl>
                  <AppFormControlGroup minWidth={0} alignItems="stretch" width="100%" pr={1}>
                    <AppFormControl margin="dense">
                      <InputLabel htmlFor="listing-lower-price">{t('listing:list.search.lowerBoundPrice')}</InputLabel>
                      <Controller
                        name="lowerBoundPrice"
                        control={control}
                        defaultValue={searchFieldList[0].value}
                        render={({ field }) => (
                          <OutlinedInput
                            type="number"
                            placeholder="เริ่มต้น"
                            id="listing-lower-price"
                            fullWidth
                            disabled={isLoadingData}
                            inputProps={{ min: 0, ...field }}
                          />
                        )}
                      />
                    </AppFormControl>
                    <AppFormControl margin="dense">
                      <InputLabel htmlFor="listing-upper-price">{t('listing:list.search.upperBoundPrice')}</InputLabel>
                      <Controller
                        name="upperBoundPrice"
                        control={control}
                        defaultValue={searchFieldList[0].value}
                        render={({ field }) => (
                          <OutlinedInput
                            type="number"
                            placeholder="เริ่มต้น"
                            id="listing-upper-price"
                            fullWidth
                            disabled={isLoadingData}
                            inputProps={{ min: 0, ...field }}
                          />
                        )}
                      />
                    </AppFormControl>
                  </AppFormControlGroup>
                </Grid>
                <Grid item xs={2} sm="auto" md={1} xl>
                  <AppButton type="submit" color="primary" disabled={isLoadingData}>
                    {t('common:message.search')}
                  </AppButton>
                </Grid>
              </Grid>
              <Grid item container direction="row" xs={12} spacing={1}>
                <Grid item>
                  <Box display="inline-flex" flexDirection="row" flexWrap="wrap">
                    <AppFormControlGroup mb={1} minWidth={0} alignItems="stretch" width="100%" pr={1}>
                      <AppFormControl margin="dense">
                        <InputLabel htmlFor="listing-created-date-from">
                          {t('listing:list.search.createdDateFrom')}
                        </InputLabel>
                        <Controller
                          name="createdDateFrom"
                          control={control}
                          defaultValue={searchFieldList[0].value}
                          render={({ field }) => (
                            <OutlinedInput
                              type="date"
                              placeholder="เริ่มต้น"
                              id="listing-created-date-from"
                              value={field.value}
                              onChange={field.onChange}
                              fullWidth
                              disabled={isLoadingData}
                            />
                          )}
                        />
                      </AppFormControl>
                      <AppFormControl margin="dense">
                        <InputLabel htmlFor="listing-created-date-to">
                          {t('listing:list.search.createdDateTo')}
                        </InputLabel>
                        <Controller
                          name="createdDateTo"
                          control={control}
                          defaultValue={searchFieldList[0].value}
                          render={({ field }) => (
                            <OutlinedInput
                              type="date"
                              id="listing-created-date-to"
                              value={field.value}
                              onChange={field.onChange}
                              fullWidth
                              disabled={isLoadingData}
                            />
                          )}
                        />
                      </AppFormControl>
                    </AppFormControlGroup>
                  </Box>
                  <Box
                    display="inline-block"
                    style={{
                      verticalAlign: 'top',
                    }}>
                    <AppButton type="submit" color="primary" disabled={isLoadingData}>
                      {t('common:message.search')}
                    </AppButton>
                  </Box>
                </Grid>
                <Grid item>
                  <Box display="inline-flex" flexDirection="row" flexWrap="wrap">
                    <AppFormControlGroup mb={1} minWidth={0} alignItems="stretch" width="100%" pr={1}>
                      <AppFormControl margin="dense">
                        <InputLabel htmlFor="listing-created-date-from">
                          {t('listing:list.search.lowerFirstRegistrationYear')}
                        </InputLabel>
                        <Controller
                          name="lowerFirstRegistrationYear"
                          control={control}
                          defaultValue={searchFieldList[0].value}
                          render={({ field }) => (
                            <OutlinedInput
                              type="number"
                              placeholder="เริ่มต้น"
                              id="listing-created-date-from"
                              value={field.value}
                              onChange={field.onChange}
                              fullWidth
                              disabled={isLoadingData}
                            />
                          )}
                        />
                      </AppFormControl>
                      <AppFormControl margin="dense">
                        <InputLabel htmlFor="listing-created-date-to">
                          {t('listing:list.search.upperFirstRegistrationYear')}
                        </InputLabel>
                        <Controller
                          name="upperFirstRegistrationYear"
                          control={control}
                          defaultValue={searchFieldList[0].value}
                          render={({ field }) => (
                            <OutlinedInput
                              type="number"
                              id="listing-created-date-to"
                              value={field.value}
                              onChange={field.onChange}
                              fullWidth
                              disabled={isLoadingData}
                            />
                          )}
                        />
                      </AppFormControl>
                    </AppFormControlGroup>
                  </Box>
                  <Box
                    display="inline-block"
                    style={{
                      verticalAlign: 'top',
                    }}>
                    <AppButton type="submit" color="primary" disabled={isLoadingData}>
                      {t('common:message.search')}
                    </AppButton>
                  </Box>
                </Grid>
              </Grid>
              <Grid item container direction="row" xs={12} spacing={1}>
                <Grid item xs={12} sm={9}>
                  <Box pr={3}>
                    <Typography variant="body2" className={classes.filterHeader}>
                      {t('listing:list.search.statuses')}
                    </Typography>
                    <Box display="flex" flexDirection="row" flexWrap="wrap" mt={1}>
                      <Controller
                        control={control}
                        name="filteringStatuses"
                        defaultValue={[]}
                        render={({ field }) => (
                          <>
                            <AppChipList
                              items={searchStatuses}
                              selectedValues={field.value}
                              onChange={(values: string[]) => {
                                field.onChange(values);
                                submitSearchForm();
                              }}
                              isDisabled={isLoadingData}
                            />
                          </>
                        )}
                      />
                      <Controller
                        control={control}
                        name="filteringPossessions"
                        defaultValue={[]}
                        render={({ field }) => (
                          <>
                            <AppChipList
                              items={searchPossessions}
                              selectedValues={field.value}
                              onChange={(values: string[]) => {
                                field.onChange(values);
                                submitSearchForm();
                              }}
                              isDisabled={isLoadingData}
                            />
                          </>
                        )}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Box>
                    <Typography variant="body2" className={classes.filterHeader}>
                      Sorting by Price
                    </Typography>

                    <Box mt={1}>
                      <Box display="flex" flexDirection="row">
                        <Chip
                          label={t('listing:common.price.lowerPrice')}
                          variant="outlined"
                          color="default"
                          clickable
                          icon={<ArrowDownwardOutlinedIcon fontSize="small" />}
                          onClick={() => {
                            if (typeof onLowerPriceClick === 'function') {
                              onLowerPriceClick();
                            }
                          }}
                          disabled={isLoadingData}
                        />

                        <Box ml={1}>
                          <Chip
                            label={t('listing:common.price.upperPrice')}
                            variant="outlined"
                            color="default"
                            clickable
                            icon={<ArrowUpwardOutlinedIcon fontSize="small" />}
                            onClick={() => {
                              if (typeof onLowerPriceClick === 'function') {
                                onUpperPriceClick();
                              }
                            }}
                            disabled={isLoadingData}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md="auto">
              <AppButton variant="outlined" color="primary" onClick={clearSearchForm} disabled={isLoadingData}>
                <YoutubeSearchedForIcon />
              </AppButton>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Box>
  );
};

export default ListingSearchForm;
