/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';

import { Box, BoxProps, FormControl, FormControlProps, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  control: {
    borderColor: theme.customPalette.control.border.main,
    '&:not(.Mui-disabled):hover': {
      borderColor: theme.customPalette.control.border.hover,
    },
    '&:not(.Mui-disabled):focus': {
      borderColor: theme.customPalette.control.border.focus,
    },
  },
}));

export type AppFormControlProps = FormControlProps & {
  boxProps?: BoxProps;
};

const AppFormControl: FC<AppFormControlProps> = (props) => {
  const classes = useStyles();
  const { children, boxProps, ...rest } = props;

  return (
    <Box {...boxProps}>
      <FormControl variant="outlined" fullWidth classes={{ root: classes.control }} {...rest}>
        {children}
      </FormControl>
    </Box>
  );
};

export default AppFormControl;
