import { BaseEntity, BaseMaster } from './base-entity.model';
import { Category } from './category.model';
import { AppTableConditions, SelectItem } from './common';

export const ATTRIBUTE_UID_MODEL = 1001;
export const ATTRIBUTE_UID_BRAND = 1002;
export const ROOT_CATEGORY_MASTERINDEX_DATA = {
  uid: 1,
  name: 'Root Category',
  thaiName: 'หมวดหมู่หลัก',
  slug: 'root-category',
  attributeDataType: 'CATEGORY',
  id: 1,
  description: '',
  placeholder: '',
};

export enum AttributeDataType {
  ATTRIBUTE_TEXT = 'ATTRIBUTE_TEXT',
  ATTRIBUTE_NUMBER = 'ATTRIBUTE_NUMBER',
  ATTRIBUTE_BOOLEAN = 'ATTRIBUTE_BOOLEAN',
  ATTRIBUTE_CHOICE = 'ATTRIBUTE_CHOICE',
  ATTRIBUTE_CHOICE_AS_YEARS = 'ATTRIBUTE_CHOICE_AS_YEARS',
  ATTRIBUTE_CHOICE_AS_PROVINCE = 'ATTRIBUTE_CHOICE_AS_PROVINCE',
  ATTRIBUTE_CHOICE_AND_TEXT = 'ATTRIBUTE_CHOICE_AND_TEXT',
  ATTRIBUTE_CHOICE_AS_COLOR = 'ATTRIBUTE_CHOICE_AS_COLOR',
  BRAND = 'BRAND',
  MODEL = 'MODEL',
  BRAND_TEXT = 'BRAND_TEXT',
  MODEL_NAME = 'MODEL_NAME',
  MODEL_TEXT = 'MODEL_TEXT',
  CATEGORY = 'CATEGORY',
}

export const attributeDataTypeList: SelectItem<string>[] = Object.values(AttributeDataType).map((value: string) => ({
  label: value,
  value,
}));

export type MasterIndexExclusiveProps = {
  dtype?: number;
  version?: number;
  attributeDataType?: AttributeDataType | null;
  imageUrl?: string | null;
  description?: string | null;
  placeholder?: string | null;
  categories?: Category[] | null;
};

export type MasterIndex = BaseEntity & BaseMaster & MasterIndexExclusiveProps;

export type RefMasterIndex = Pick<
  MasterIndex,
  'id' | 'description' | 'uid' | 'name' | 'thaiName' | 'slug' | 'attributeDataType'
>;

export type MasterIndexFormData = BaseMaster & Omit<MasterIndexExclusiveProps, 'categories'>;

export type MasterIndexSearchFormData = {
  searchKeyword?: string;
};

export type MasterIndexListSearchParams = MasterIndexSearchFormData & AppTableConditions;

export type MasterIndexListSearchFilters = Partial<MasterIndexListSearchParams>;
