import UserEdit from './Edit';
import UserList from './List';
import { RouteConfig } from '../../../models/route.model';

const userRoutes = (path: string): RouteConfig[] => [
  {
    key: 'USER_APP_LIST',
    path,
    component: UserList,
    exact: true,
    title: 'common:title.v3.users',
  },
  {
    key: 'USER_DETAIL',
    path: `${path}/:hashId`,
    component: UserEdit,
    exact: true,
    backUrl: path,
  },
];

export default userRoutes;
