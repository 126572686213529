/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useContext, useEffect, useState } from 'react';

import { Box, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Cell, Legend, Pie, PieChart } from 'recharts';

import { AppGlobalUiContext } from '../../../../context/AppGlobalUiContext';
import { getS3TdFile } from '../../../../services/s3-td';

const colors = ['#5B93FF', '#FFD66B', '#FF8F6B'];

export type DashboardPieChartProps = {
  selectPeriod: string;
  isDevice?: boolean;
};

const useStyles = makeStyles(() => ({
  noData: {
    borderWidth: '20px',
    borderStyle: 'solid',
    borderColor: '#f8f9ff',
    borderRadius: '50%',
    width: '160px',
    height: '160px',
    margin: '30px auto',
  },
}));

const DashboardPieChart: FC<DashboardPieChartProps> = (props) => {
  const { selectPeriod, isDevice = false } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const [deviceData, setDeviceData] = useState<any>([]);
  const { setAppLoading, showSnackbar } = useContext(AppGlobalUiContext);

  const requestGetData = async () => {
    const result = await getS3TdFile(encodeURIComponent(isDevice ? 'graph_device.csv' : 'graph_user_segment.csv'));
    setAppLoading(true);
    if (result.data?.data) {
      const dataFilter = result.data?.data.filter((item) => item.filter === selectPeriod);
      setDeviceData(dataFilter);
    } else {
      showSnackbar(
        t('dashboard:error.list.requestFailed', {
          fieldName: t(
            isDevice ? 'dashboard:v3.title.device' : 'dashboard:v3.title.proportionNewUserAndReturnUser',
          ).toString(),
        }),
        'error',
      );
    }
    setAppLoading(false);
  };

  useEffect(() => {
    requestGetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectPeriod]);

  const renderColorfulLegendText = (device: string) => (
    <span style={{ color: '#0D162F', fontSize: '14px', padding: '4px' }}>{device}</span>
  );

  return (
    <Box>
      {deviceData.length > 0 ? (
        <PieChart width={260} height={300} style={{ margin: '20px auto 0 auto' }}>
          <Pie
            data={deviceData.map((item: any) => ({ ...item, value: Number(item.value) }))}
            innerRadius={60}
            outerRadius={80}
            dataKey="value"
            label
            labelLine={false}>
            {deviceData.map((item: any, index: number) => {
              const itemKey = `item-${index}`;
              const value = Number(item.value);
              return <Cell key={`${itemKey}`} fill={value === 0 ? '#f8f9ff' : colors[index % colors.length]} />;
            })}
          </Pie>

          <Legend
            iconType="circle"
            layout="horizontal"
            verticalAlign="bottom"
            align="left"
            formatter={renderColorfulLegendText}
          />
        </PieChart>
      ) : (
        <>
          <Box className={classes.noData} />
        </>
      )}
    </Box>
  );
};

export default DashboardPieChart;
