import React, { FC, useContext, useState } from 'react';

import { Box, Container } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import useDeepCompareEffect from 'use-deep-compare-effect';

import MasterIndexListSearchForm from '../../../components/partials/master-data/master-index/MasterIndexListSearchForm';
import MasterIndexListTable from '../../../components/partials/master-data/master-index/MasterIndexListTable';
import AppBreadcrumb from '../../../components/ui/AppBreadcrumb';
import AppButton from '../../../components/ui/AppButton';
import { AppGlobalUiContext } from '../../../context/AppGlobalUiContext';
import { SearchFilterContext } from '../../../context/SearchFilterContext';
import { defaultRowsPerPage } from '../../../helpers/constants';
import {
  AppTableConditions,
  MasterIndexListSearchParams,
  MasterIndex,
  MasterIndexSearchFormData,
  BreadcrumbItem,
} from '../../../models';
import * as masterIndexService from '../../../services/master-index';
import useAppContainerStyles from '../../../theme/container.style';
import useAppTableStyles from '../../../theme/table.style';

/* TODO: Temporary disable search form until API support search feature */
const searchFilterContextKey = 'master-data_master-index';

const MasterIndexList: FC = () => {
  const { path } = useRouteMatch();
  const { t } = useTranslation();
  const containerClasses = useAppContainerStyles();
  const tableClasses = useAppTableStyles();
  const { showSnackbar } = useContext(AppGlobalUiContext);

  const [isLoading, setIsLoading] = useState(true);
  const [masterIndexData, setMasterIndexData] = useState<MasterIndex[]>([]);
  const [totalModels, setTotalModels] = useState<number>(0);

  const breadcrumbItems: BreadcrumbItem[] = [
    { label: 'common:sidebar.menu.masterData', url: '/master-data' },
    { label: 'master-data:menu.masterIndex' },
  ];

  const { currentFilters: ctxFilters, setFilters } = useContext(SearchFilterContext);
  const currentFilters: MasterIndexListSearchParams = ctxFilters ? ctxFilters[searchFilterContextKey] : undefined;
  const [searchFilters, setSearchFilters] = useState<MasterIndexSearchFormData>({
    searchKeyword: currentFilters?.searchKeyword || '',
  });
  const [tableConditions, setTableConditions] = useState<AppTableConditions>({
    page: 0,
    rowsPerPage: defaultRowsPerPage,
    sortColumn: 'name',
    sortDirection: 'asc',
  });

  const getCurrentSearchParam = (): MasterIndexListSearchParams => ({
    page: tableConditions.page || 0,
    rowsPerPage: tableConditions.rowsPerPage || defaultRowsPerPage,
    sortColumn: tableConditions.sortColumn || 'name',
    sortDirection: tableConditions.sortDirection || 'asc',
    searchKeyword: searchFilters.searchKeyword || '',
  });

  const requestModelList = async (isPageActive = true): Promise<boolean> => {
    if (isPageActive) {
      setIsLoading(true);
      const searchConditions = getCurrentSearchParam();
      const result = await masterIndexService.getMasterIndexList(searchConditions);

      setFilters(searchFilterContextKey, searchConditions);

      setIsLoading(false);

      if (result.data) {
        setMasterIndexData(result.data.list);
        setTotalModels(result.data.totalCount);
        return Promise.resolve(true);
      }

      showSnackbar(t(result.error as string).toString(), 'error');
      return Promise.reject(result.error);
    }

    return false;
  };

  useDeepCompareEffect(() => {
    let isActive = true;

    requestModelList(isActive);

    return () => {
      isActive = false;
    };
  }, [tableConditions, searchFilters]);

  const tableConditionsChangedHandler = (values: AppTableConditions) => {
    setTableConditions(values);
  };

  const onSearchFormSubmit = (filters: MasterIndexSearchFormData) => {
    if (tableConditions.page !== 0) {
      tableConditions.page = 0;
    }

    setSearchFilters(filters);
  };

  return (
    <>
      <Box px={6} py={3}>
        <AppBreadcrumb items={breadcrumbItems} />
      </Box>
      <MasterIndexListSearchForm currentFilters={searchFilters} onSearchFormSubmit={onSearchFormSubmit} />
      <Container className={containerClasses.container}>
        <Box className={tableClasses.tableContainer}>
          <Box mb={2}>
            <AppButton color="primary" variant="outlined" startIcon={<Add />} url={`${path}/create`}>
              {t('common:button.addField', {
                field: t('master-data:common.field.masterIndex').toString(),
              })}
            </AppButton>
          </Box>
          <MasterIndexListTable
            currentConditions={tableConditions}
            totalMasterIndexes={totalModels}
            isLoadingData={isLoading}
            masterIndexList={masterIndexData}
            onTableConditionsChanged={tableConditionsChangedHandler}
          />
        </Box>
      </Container>
    </>
  );
};

export default MasterIndexList;
