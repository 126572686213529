import React, { FC, useCallback, useContext, useState } from 'react';

import { Box, Card, CardContent, Grid, Paper, Typography, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CachedIcon from '@material-ui/icons/Cached';
import EditIcon from '@material-ui/icons/Edit';
import RemoveIcon from '@material-ui/icons/Remove';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { AppGlobalUiContext } from '../../../../../context/AppGlobalUiContext';
import usePrivilege from '../../../../../hooks/usePrivilege';
import i18n from '../../../../../i18n';
import { UserDataTC } from '../../../../../models';
import { updateCoinTopUp } from '../../../../../services/v3/user-balance';
import theme from '../../../../../theme';
import useTypographyStyles from '../../../../../theme/typography.style';
import AppButton from '../../../../ui/AppButton';
import DataBlock from '../../../common/DataBlock';

export interface WalletMembershipProps {
  currentUser: UserDataTC;
  currentCoinBalance: number;
  onSubmitCoinTopUp: () => void;
}

const useStyles = makeStyles((themeStyle) => ({
  container: {
    padding: theme.spacing(5, 2),
    height: '100vh',
  },
  card: {
    marginBottom: theme.spacing(1),
  },
  cardContent: {
    padding: theme.spacing(2),
  },
  boxGrey: {
    padding: `${themeStyle.spacing(2)}px`,
    backgroundColor: themeStyle.palette.grey['200'],
  },
  coinInput: {
    padding: theme.spacing(1),
    width: '160px',
  },
  btnStyle: {
    padding: 0,
    minWidth: '56px',
  },
}));

const iconCoin = '../../static/images/icons/icon-coin.png';

const WalletMembership: FC<WalletMembershipProps> = (props) => {
  const { t } = useTranslation();
  const dateDisplayFormat = i18n.language === 'th' ? 'D MMM BBBB' : 'D/MM/YY';
  const typoClasses = useTypographyStyles();
  const classes = useStyles();
  const { currentUser, currentCoinBalance, onSubmitCoinTopUp } = props;

  const { setAppLoading, showSnackbar } = useContext(AppGlobalUiContext);
  const { canPerform } = usePrivilege();
  const allowEdit = canPerform('coinBalance', 'update');
  const [isEditor, setIsEditor] = useState<boolean>(false);

  const [currentCoinAmount, setCurrentCoinAmount] = useState<number>(0);

  const totalCoin = currentCoinAmount + currentCoinBalance;
  const adjustBalance = (type: 'increase' | 'decrease'): void => {
    const updateBalance = type === 'increase' ? currentCoinAmount + 1 : currentCoinAmount - 1;
    setCurrentCoinAmount(updateBalance);
  };

  const addCoinTopUp = useCallback(async () => {
    setAppLoading(true);

    const result = await updateCoinTopUp(currentUser.userHashId, currentCoinAmount);

    if (result) {
      showSnackbar(t('user:common.message.updateCoinTopUpSuccess').toString(), 'success');
      setCurrentCoinAmount(0);
      setIsEditor(false);
      setAppLoading(false);
      if (typeof onSubmitCoinTopUp === 'function') {
        onSubmitCoinTopUp();
      }
    } else {
      setAppLoading(false);
      showSnackbar(t('user:common.message.updateCoinTopUpFailed').toString(), 'error');
    }
  }, [currentCoinAmount, currentUser.userHashId, onSubmitCoinTopUp, setAppLoading, showSnackbar, t]);

  return (
    <>
      <Paper elevation={1}>
        <Box className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Box display="flex" flexDirection="row" justifyContent="space-between" className={classes.boxGrey}>
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                  <Typography variant="body1" component="h4" className={typoClasses.textWeightBold}>
                    {t('user:coin.coinBalance')} :
                  </Typography>
                  <Box ml={3} mr={1}>
                    <img src={iconCoin} width={24} height={24} alt="coin" style={{ marginBottom: '-6px' }} />
                  </Box>
                  <Box>
                    <Typography style={{ fontSize: 'large' }} className={typoClasses.textWeightBold}>
                      {currentCoinBalance.toLocaleString()}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  {!isEditor && (
                    <Box>
                      <AppButton
                        variant="outlined"
                        color="primary"
                        startIcon={<EditIcon />}
                        disabled={!allowEdit}
                        onClick={() => setIsEditor((prev) => !prev)}>
                        {t('common:button.edit')}
                      </AppButton>
                    </Box>
                  )}
                </Box>
              </Box>
              <Box className={classes.boxGrey} mt={1}>
                <DataBlock
                  label="user:common.fields.role"
                  data={t(`user:v3.list.search.workflow.${currentUser.userRoleStateWorkflow}`).toString()}
                />
                <DataBlock
                  label="user:details.label.membershipStartDate"
                  data={
                    currentUser.membershipStartedAt
                      ? dayjs(currentUser.membershipStartedAt).format(`${dateDisplayFormat}`)
                      : null
                  }
                />
                <DataBlock
                  label="user:details.label.membershipEndDate"
                  data={
                    currentUser.membershipEndAt
                      ? dayjs(currentUser.membershipEndAt).format(`${dateDisplayFormat}`)
                      : null
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              {isEditor && allowEdit && (
                <Box>
                  <Card variant="outlined" className={classes.card}>
                    <CardContent className={classes.cardContent}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                          <Box display="flex" flexDirection="row" alignItems="center">
                            <img src={iconCoin} width={24} height={24} alt="coin" style={{ marginBottom: '-4px' }} />
                            <Typography
                              style={{
                                fontWeight: 'bold',
                                marginLeft: '10px',
                                fontSize: 'large',
                                color: totalCoin < 0 ? theme.palette.error.main : 'unset',
                              }}>
                              {totalCoin.toLocaleString()}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <Box
                            style={{ marginLeft: '12px' }}
                            display="flex"
                            flexDirection="row"
                            justifyContent="flex-end">
                            <AppButton
                              style={{ marginRight: 4 }}
                              color="primary"
                              variant="contained"
                              className={classes.btnStyle}
                              onClick={() => adjustBalance('decrease')}
                              disabled={!allowEdit}>
                              <RemoveIcon />
                            </AppButton>

                            <Box>
                              <input
                                name="inputCoinAmount"
                                type="number"
                                className={classes.coinInput}
                                onChange={(e) => setCurrentCoinAmount(+e.target.value)}
                                value={currentCoinAmount}
                                disabled={!allowEdit}
                                onClick={(e) => {
                                  const target = e.target as HTMLInputElement;
                                  target.select();
                                }}
                              />
                            </Box>

                            <AppButton
                              style={{ marginLeft: 4 }}
                              color="primary"
                              variant="contained"
                              className={classes.btnStyle}
                              onClick={() => adjustBalance('increase')}
                              disabled={!allowEdit}>
                              <AddIcon />
                            </AppButton>
                            <AppButton
                              style={{ marginLeft: 4 }}
                              color="default"
                              variant="outlined"
                              className={classes.btnStyle}
                              onClick={() => setCurrentCoinAmount(0)}
                              disabled={!allowEdit}>
                              <CachedIcon />
                            </AppButton>
                          </Box>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                  <Box display="flex" flexDirection="row" justifyContent="flex-end" mt={2}>
                    <Box>
                      <AppButton
                        variant="contained"
                        color="primary"
                        onClick={() => addCoinTopUp()}
                        disabled={totalCoin < 0}>
                        {t('common:button.save')}
                      </AppButton>
                    </Box>
                    <Box ml={1}>
                      <AppButton
                        variant="outlined"
                        color="error"
                        onClick={() => {
                          setCurrentCoinAmount(0);
                          setIsEditor(false);
                        }}>
                        {t('common:button.cancel')}
                      </AppButton>
                    </Box>
                  </Box>
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </>
  );
};

export default WalletMembership;
