import React, { FC, useCallback, useContext, useEffect, useState } from 'react';

import { Box, Card, CardContent, Divider, Grid, Paper, Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import ShoppingCartCheckout from './ShoppingCartCheckout';
import { AppGlobalUiContext } from '../../../../../context/AppGlobalUiContext';
import { defaultRowsPerPage } from '../../../../../helpers/constants';
import { UserDataTC } from '../../../../../models';
import { OrderOnceData, OrderStateWorkflow } from '../../../../../models/order-order.model';
import { Product, ProductListSearchParamsTC, PurchaseTypeTC } from '../../../../../models/product.model';
import { getProductListByHashId } from '../../../../../services/product';
import { getOrderOnceByHashId } from '../../../../../services/v3/order-once';
import theme from '../../../../../theme';
import useTypographyStyles from '../../../../../theme/typography.style';
import AppButton from '../../../../ui/AppButton';
import AppDialog from '../../../../ui/AppDialog';

export interface ShoppingCartProps {
  currentUser: UserDataTC;
  currentCoinBalance: number;
  onSubmitPayment: () => void;
}
const useStyles = makeStyles((themeStyle) => ({
  container: {
    padding: theme.spacing(2),
  },
  card: {
    marginBottom: theme.spacing(1),
  },
  cardContent: {
    padding: theme.spacing(2),
  },
  boxGrey: {
    padding: `${themeStyle.spacing(2)}px`,
    backgroundColor: themeStyle.palette.grey['200'],
  },
  menuTab: {
    paddingRight: theme.spacing(4),
    width: '100%',
    backgroundColor: 'white',
  },
  buttonTab: {
    padding: '10px 20px',
    borderRadius: 'unset',
  },
  linkTab: {
    color: 'black',
    textDecoration: 'unset',
    padding: '20px 0',
  },
}));

const ShoppingCart: FC<ShoppingCartProps> = (props) => {
  const { t } = useTranslation();
  const typoClasses = useTypographyStyles();
  const classes = useStyles();
  const iconCoin = '../../static/images/icons/icon-coin.png';
  const iconBronze = '../../static/images/icons/icon-bronze-ribbon.png';
  const iconSilver = '../../static/images/icons/icon-silver-ribbon.png';
  const iconGold = '../../static/images/icons/icon-gold-ribbon.png';

  const { showSnackbar, setAppLoading } = useContext(AppGlobalUiContext);
  const [productCoinList, setProductCoinList] = useState<Product[]>([]);
  const [productMemberOnceList, setProductMemberOnceList] = useState<Product[]>([]);

  const [selectProductItem, setSelectProductItem] = useState<Product[]>([]);

  const { currentUser, currentCoinBalance, onSubmitPayment } = props;
  const [activeTab, setActiveTab] = useState<string>('coin');

  const handleTabClick = (productType: string) => {
    setActiveTab(productType);
  };

  const selectedMembership = selectProductItem?.filter((item) => item.purchaseType === 'ONCE');
  const isMembershipAlready = selectedMembership.length >= 1;

  const getConditionsParam = (): ProductListSearchParamsTC => ({
    page: 0,
    rowsPerPage: defaultRowsPerPage,
    sortColumn: 'createdAt',
    sortDirection: 'asc',
    filteringType: ['COIN', 'MEMBERSHIP_ONCE'],
  });

  const requestProductListByHashId = () => {
    (async () => {
      const conditions = getConditionsParam();
      setAppLoading(true);
      const resultCoinData = await getProductListByHashId(currentUser.userHashId, PurchaseTypeTC.Coin, conditions);
      const resultMemberOnceData = await getProductListByHashId(
        currentUser.userHashId,
        PurchaseTypeTC.MemberOnce,
        conditions,
      );

      if (resultCoinData.data && resultMemberOnceData.data) {
        setAppLoading(false);
        const coinData = resultCoinData.data.contents;
        setProductCoinList(coinData);
        const memberOnceData = resultMemberOnceData.data.contents;
        setProductMemberOnceList(memberOnceData);
      } else {
        setAppLoading(false);
        showSnackbar(t(resultCoinData.error as string).toString(), 'error');
      }
    })();
  };

  useEffect(() => {
    requestProductListByHashId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeItemByHashId = (index: number) => {
    setAppLoading(true);
    const currentSelectedProducts = selectProductItem || [];
    currentSelectedProducts.splice(index, 1);
    if (currentSelectedProducts) {
      setSelectProductItem([...currentSelectedProducts]);
      setAppLoading(false);
    }
  };

  const [orderOnceList, setOrderOnceList] = useState<OrderOnceData[]>([]);
  const [isOrderAlreadyDialogOpen, setIsOrderAlreadyDialogOpen] = useState(false);
  const getOrderOnceList = async (isPageSubscribed = true): Promise<boolean> => {
    if (isPageSubscribed) {
      const result = await getOrderOnceByHashId(currentUser.userHashId, {
        page: 0,
        rowsPerPage: defaultRowsPerPage,
        sortColumn: 'createdAt',
        sortDirection: 'desc',
      });
      if (result.data) {
        setOrderOnceList(result.data.contents);
        return Promise.resolve(true);
      }
      return Promise.reject(result.error);
    }
    return false;
  };

  useEffect(() => {
    getOrderOnceList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const orderAlreadyOpen = orderOnceList?.filter((item) => item.orderStateWorkflow === OrderStateWorkflow.open);
  const isOrderAlreadyOpen = orderAlreadyOpen.length > 0;

  const checkOrderAlreadyClickHandler = useCallback(() => {
    setIsOrderAlreadyDialogOpen(false);
    window.location.href = `${currentUser.userHashId}?tab=order`;
  }, [currentUser.userHashId]);

  return (
    <>
      <Paper elevation={1}>
        <Box className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Box className={classes.boxGrey}>
                <Box display="flex" flexDirection="row">
                  <Typography variant="body1" component="h4" className={typoClasses.textWeightBold}>
                    {t('user:coin.coinBalance')} :
                  </Typography>
                  <Box ml={3} mr={1}>
                    <img src={iconCoin} width={24} height={24} alt="coin" />
                  </Box>
                  <Box>
                    <Typography style={{ fontSize: 'large', marginTop: '-2px' }} className={typoClasses.textWeightBold}>
                      {currentCoinBalance.toLocaleString()}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box>
                <Box display="flex" flexDirection="row" className={classes.menuTab}>
                  <AppButton
                    className={classes.buttonTab}
                    variant="text"
                    style={{
                      backgroundColor: activeTab === 'coin' ? '#F5F5F5' : 'transparent',
                    }}
                    onClick={() => handleTabClick('coin')}>
                    <Typography style={{ fontWeight: 'bold' }}>{t('shopping-cart:title.coin')}</Typography>
                  </AppButton>
                  <AppButton
                    className={classes.buttonTab}
                    variant="text"
                    style={{
                      backgroundColor: activeTab !== 'coin' ? '#F5F5F5' : 'transparent',
                    }}
                    onClick={() => handleTabClick('membership')}>
                    <Typography style={{ fontWeight: 'bold' }}>{t('shopping-cart:title.memberShip')}</Typography>
                  </AppButton>
                </Box>

                <Box className="tab-content">
                  {activeTab === 'coin' ? (
                    <Card variant="outlined" className={classes.card}>
                      <CardContent className={classes.cardContent}>
                        <Box mb={3}>
                          <Typography variant="subtitle2" style={{ marginLeft: 6, paddingTop: 2 }}>
                            {t('shopping-cart:description.coin')}
                          </Typography>
                        </Box>
                        {productCoinList.length > 0 && (
                          <>
                            {productCoinList.map((item) => {
                              const itemKey = item.hashId;
                              const descriptionRender = item.description && (JSON.parse(item.description)?.text || '');
                              return (
                                <Box key={itemKey}>
                                  <Box my={2}>
                                    <Grid container>
                                      <Grid item xs={8} md={8}>
                                        <Box display="flex" flexDirection="row" justifyContent="space-between">
                                          <Box display="flex" flexDirection="row">
                                            <img src={iconCoin} width={24} height={24} alt="coin" />
                                            <Box>
                                              <Typography style={{ marginLeft: 8, fontWeight: 'bold' }}>
                                                {item.title}
                                              </Typography>
                                              <Typography
                                                variant="subtitle2"
                                                className={typoClasses.greyText}
                                                style={{ marginLeft: 8, paddingTop: 2 }}>
                                                {t('shopping-cart:productId')} {item.hashId}
                                              </Typography>

                                              {item.freeCoin > 0 && (
                                                <Typography
                                                  variant="subtitle2"
                                                  className={typoClasses.greyText}
                                                  style={{ marginLeft: 8, paddingTop: 2 }}>
                                                  {t('shopping-cart:freeCoin', {
                                                    fieldName: item.freeCoin ? t(item.freeCoin.toLocaleString()) : '-',
                                                  })}
                                                </Typography>
                                              )}

                                              {descriptionRender && (
                                                <Typography
                                                  variant="subtitle2"
                                                  className={typoClasses.greyText}
                                                  style={{ marginLeft: 8, paddingTop: 2 }}>
                                                  {descriptionRender}
                                                </Typography>
                                              )}
                                            </Box>
                                          </Box>
                                        </Box>
                                      </Grid>
                                      <Grid item xs={4} md={4} style={{ textAlign: 'right' }}>
                                        <AppButton
                                          variant="contained"
                                          color="primary"
                                          onClick={() => {
                                            window.scrollTo({
                                              top: 0,
                                              left: 0,
                                              behavior: 'smooth',
                                            });
                                            if (isOrderAlreadyOpen) {
                                              setIsOrderAlreadyDialogOpen(true);
                                            } else {
                                              setSelectProductItem((prevItem) => [...prevItem, item]);
                                            }
                                          }}>
                                          <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
                                            {t('shopping-cart:button.addProduct', {
                                              fieldName: t(item.price.toLocaleString()),
                                            })}
                                          </Typography>
                                        </AppButton>
                                      </Grid>
                                    </Grid>
                                  </Box>

                                  <Divider />
                                </Box>
                              );
                            })}
                          </>
                        )}
                      </CardContent>
                    </Card>
                  ) : (
                    <Card variant="outlined" className={classes.card}>
                      <CardContent className={classes.cardContent}>
                        <Box mb={3}>
                          <Typography variant="subtitle2" style={{ marginLeft: 6, paddingTop: 2 }}>
                            {t('shopping-cart:description.memberShip')}
                          </Typography>
                        </Box>

                        {productMemberOnceList.length > 0 && (
                          <>
                            {productMemberOnceList.map((item: Product) => {
                              const descriptionRender =
                                item.description && (JSON.parse(item.description.replace(/\//g, ' & '))?.text || '');
                              return (
                                <Box key={item.hashId}>
                                  <Box my={2}>
                                    <Grid container>
                                      <Grid item xs={8} md={8}>
                                        <Box display="flex" flexDirection="row" justifyContent="space-between">
                                          <Box display="flex" flexDirection="row">
                                            {item.membershipType === 'BRONZE' && (
                                              <img src={iconBronze} width={24} height={30} alt="BRONZE" />
                                            )}
                                            {item.membershipType === 'SILVER' && (
                                              <img src={iconSilver} width={24} height={30} alt="SILVER" />
                                            )}
                                            {item.membershipType === 'GOLD' && (
                                              <img src={iconGold} width={24} height={30} alt="GOLD" />
                                            )}
                                            <Box>
                                              <Typography style={{ marginLeft: 8, fontWeight: 'bold' }}>
                                                {item.title}
                                              </Typography>
                                              <Typography
                                                variant="subtitle2"
                                                className={typoClasses.greyText}
                                                style={{ marginLeft: 8, paddingTop: 2 }}>
                                                {t('shopping-cart:productId')} {item.hashId}
                                              </Typography>

                                              {item.freeCoin > 0 && (
                                                <Typography
                                                  variant="subtitle2"
                                                  className={typoClasses.greyText}
                                                  style={{ marginLeft: 8, paddingTop: 2 }}>
                                                  {t('shopping-cart:freeCoin', {
                                                    fieldName: item.freeCoin ? t(item.freeCoin.toLocaleString()) : '-',
                                                  })}
                                                </Typography>
                                              )}

                                              {descriptionRender && (
                                                <Typography
                                                  variant="subtitle2"
                                                  className={typoClasses.greyText}
                                                  style={{ marginLeft: 8, paddingTop: 2 }}>
                                                  {descriptionRender}
                                                </Typography>
                                              )}
                                            </Box>
                                          </Box>
                                        </Box>
                                      </Grid>
                                      <Grid item xs={4} md={4} style={{ textAlign: 'right' }}>
                                        <AppButton
                                          variant="contained"
                                          color="primary"
                                          disabled={!item.isPurchasable || isMembershipAlready}
                                          onClick={() => {
                                            if (isOrderAlreadyOpen) {
                                              setIsOrderAlreadyDialogOpen(true);
                                            } else {
                                              setSelectProductItem((prevItem) => [...prevItem, item]);
                                            }
                                          }}>
                                          <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
                                            {t('shopping-cart:button.addProduct', {
                                              fieldName: t(item.price.toLocaleString()),
                                            })}
                                          </Typography>
                                        </AppButton>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                  <Divider />
                                </Box>
                              );
                            })}
                          </>
                        )}
                      </CardContent>
                    </Card>
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box style={{ marginTop: '44px' }}>
                <ShoppingCartCheckout
                  currentUser={currentUser}
                  selectedItem={selectProductItem}
                  onClickDelete={(index) => removeItemByHashId(index)}
                  onAddedShoppingCart={() => {
                    setSelectProductItem([]);
                  }}
                  onSubmitPayment={onSubmitPayment}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <AppDialog
          open={isOrderAlreadyDialogOpen}
          title="shopping-cart:orderAlreadyOpenModal.title"
          cancelButtonText="common:button.cancel"
          onClose={() => setIsOrderAlreadyDialogOpen(false)}
          okButtonText="common:button.ok"
          onOkClick={checkOrderAlreadyClickHandler}>
          {t('shopping-cart:orderAlreadyOpenModal.description')}
        </AppDialog>
      </Paper>
    </>
  );
};

export default ShoppingCart;
