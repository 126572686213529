/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { withRouter } from 'react-router-dom';

class ScrollToTop extends React.Component<any> {
  componentDidUpdate(prev: any) {
    // eslint-disable-next-line react/destructuring-assignment
    if (this.props.location.pathname !== prev.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return null;
  }
}

export default withRouter(ScrollToTop);
