/* eslint-disable import/prefer-default-export */

import { apiKTRequest, apiRequest, apiTCRequest } from '../helpers/request';
import { queryParamGenerator } from '../helpers/utils';
import {
  ApiResponse,
  FileItem,
  FileListSearchParam,
  FileResponse,
  FileUploadRequest,
  ListResponse,
  UserImage,
  UserTCImage,
} from '../models';

const getFileListQueryParam = (param?: FileListSearchParam): string => {
  const reqParam: { [x: string]: string | number } = {
    page: param?.page || 0,
    per_page: param?.rowsPerPage || 10,
    sort: param?.sortColumn ? `${param.sortColumn}|${(param?.sortDirection || 'desc').toUpperCase()}` : '',
  };

  // Only search with keyword when it comes with the selected column to be searched
  if (param?.searchKeyword && param?.searchField) {
    reqParam[param.searchField] = param.searchKeyword;
  }

  return queryParamGenerator(reqParam, true);
};

export const getFileList = async (param?: FileListSearchParam): Promise<ApiResponse<ListResponse<FileItem>>> => {
  try {
    const qString = getFileListQueryParam(param);
    const result = await apiKTRequest<{
      files: FileItem[];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      page: any;
    }>(`/admin_manager/file?${qString}`);

    return {
      data: {
        list: result.data.files,
        totalCount: result.data.page.totalElements,
      },
    };
  } catch (e) {
    return {
      data: null,
      error: 'common:mediaLibrary.error.getListFailed',
    };
  }
};

export const uploadFile = async (file: File): Promise<ApiResponse<FileItem>> => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const result = await apiKTRequest<FileItem>('/admin_manager/upload/static/file', {
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    });

    return {
      data: result.data,
    };
  } catch (e) {
    // Error message will be handled at UI
    return {
      data: null,
    };
  }
};

export const uploadPrivateFile = async (file: File, query: FileUploadRequest): Promise<ApiResponse<FileResponse>> => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const result = await apiRequest<FileResponse>(`/files/upload`, {
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
      params: query,
    });

    return {
      data: result.data,
    };
  } catch (e) {
    // Error message will be handled at UI
    return {
      data: null,
    };
  }
};

export const deleteFile = async (fileId: number): Promise<ApiResponse<boolean>> => {
  try {
    await apiKTRequest<FileItem>(`/admin_manager/file/${fileId}`, {
      method: 'DELETE',
    });

    return {
      data: true,
    };
  } catch (e) {
    return {
      data: null,
      error: 'common:mediaLibrary.error.deleteFailed',
    };
  }
};

export const uploadTCImage = async (file: File, type: UserTCImage): Promise<ApiResponse<UserImage | null>> => {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const result = await apiTCRequest<UserImage>(
      `/admin/user/upload/${type !== 'PRODUCT' ? 'private' : 'public'}/other-file${
        type !== 'PRODUCT' ? `/${type}` : ''
      }`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },

        data: formData,
      },
    );
    return {
      data: result.data || null,
    };
  } catch (e) {
    return {
      data: null,
    };
  }
};

export const uploadImageTcUser = async (
  file: File,
  type: UserTCImage,
  userHashId: string,
): Promise<ApiResponse<UserImage | null>> => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const result = await apiTCRequest<UserImage>(`/admin/user/${userHashId}/upload-image/${type}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
      },

      data: formData,
    });

    return {
      data: result.data || null,
    };
  } catch (e) {
    return {
      data: null,
    };
  }
};
