import React, { FC, ReactNode } from 'react';

import { makeStyles, TableContainer, Paper, Table, TableProps, TableContainerProps } from '@material-ui/core';
import clsx from 'clsx';

export type AppTableProps = TableProps & {
  tableContent: ReactNode;
  pagination?: ReactNode;
  layoutFixed?: boolean;
  tableContainerProps?: TableContainerProps;
  isFixedHeightAuto?: boolean;
};

const useStyles = makeStyles(() => ({
  tablePaper: {
    width: '100%',
    minHeight: 100,
  },
  tableLayoutFixed: {
    tableLayout: 'fixed',
  },
}));

const AppTable: FC<AppTableProps> = (props) => {
  const {
    tableContent,
    pagination = undefined,
    layoutFixed,
    classes: tableClasses,
    tableContainerProps,
    isFixedHeightAuto = false,
    ...rest
  } = props;
  const classes = useStyles();
  const combinedTableRootClasses = clsx(tableClasses?.root, { [classes.tableLayoutFixed]: layoutFixed });
  const combinedTableClasses = { ...tableClasses, root: combinedTableRootClasses };

  return (
    <Paper className={classes.tablePaper}>
      <TableContainer {...tableContainerProps} style={{ height: isFixedHeightAuto ? 'auto' : 'calc(100vh - 420px)' }}>
        <Table classes={combinedTableClasses} {...rest}>
          {tableContent}
        </Table>
      </TableContainer>
      {pagination}
    </Paper>
  );
};

export default AppTable;
