import { MessageUser } from '../../../models/message-user';
import getFirestoreUserByHashId from '../../firestore/user/getById';
import { getUserByHashId } from '../../user';
import { createFireStoreUser } from '../user/create';

const getMessageGroupMemberData = async (memberIds: string[]): Promise<MessageUser[]> => {
  const memberRequests = memberIds.map(async (memberHashId) => {
    const memberDataResult = await getUserByHashId(memberHashId);

    if (memberDataResult) {
      const { displayName, firstName, lastName, role, profileImageUrl, hashId, contactNumber, isVerified } =
        memberDataResult;
      return {
        displayName,
        firstName,
        lastName,
        role,
        profileImageUrl,
        hashId,
        contactNumber,
        isVerified,
        isAccountActive: true,
      };
    }

    const firestoreUserData = await getFirestoreUserByHashId(memberHashId);

    if (firestoreUserData) {
      return {
        ...firestoreUserData,
        isAccountActive: false,
      };
    }

    return {
      hashId: memberHashId,
      displayName: '',
      firstName: '',
      lastName: '',
      role: '',
      profileImageUrl: '',
      contactNumber: '',
      isVerified: false,
      blockedUsers: [],
      isAccountActive: false,
    };
  });

  const memberData = (await Promise.all(memberRequests)).filter((member) => !!member) as MessageUser[];
  const availableUsers = memberData.filter((member) => member.isAccountActive);

  if (availableUsers.length > 0) {
    const createMemberRequest = availableUsers
      .filter((u) => memberData.find((user) => user.hashId === u.hashId))
      .map(async (u) => {
        const result = await createFireStoreUser(u);
        return result;
      });

    await Promise.all(createMemberRequest);
  }

  return memberData;
};

export default getMessageGroupMemberData;
