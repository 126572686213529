import React, { FC, useCallback, useContext, useState } from 'react';

import { Box, Grid, InputLabel, OutlinedInput, Paper, Typography } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useDeepCompareEffect from 'use-deep-compare-effect';

import AbTestingListSearchForm from '../../components/partials/v3/abTesting/AbTestingListSearchForm';
import FeatureGroupByUserListTable from '../../components/partials/v3/abTesting/FeatureGroupByUserListTable';
import AppButton from '../../components/ui/AppButton';
import AppDialog from '../../components/ui/AppDialog';
import AppFormControl from '../../components/ui/AppFormControl';
import { AppGlobalUiContext } from '../../context/AppGlobalUiContext';
import { SearchFilterContext } from '../../context/SearchFilterContext';
import { defaultRowsPerPage } from '../../helpers/constants';
import usePrivilege from '../../hooks/usePrivilege';
import { AppTableConditions } from '../../models';
import {
  AbTestingData,
  AbTestingFormDataRequest,
  AbTestingListSearchFormData,
  AbTestingListSearchParamsTC,
} from '../../models/ab-testing.model';
import {
  deleteUserAbTestingByFeature,
  deleteUsersByFeature,
  getAbTestingListGroupByUsers,
  updateAbTesting,
} from '../../services/ab-testing';

const searchFilterContextKey = 'ab-testing';

const FeatureEdit: FC = () => {
  const { featureName } = useParams<{ featureName: string }>();

  const { t } = useTranslation();
  const { showSnackbar, setAppLoading } = useContext(AppGlobalUiContext);
  const [featureGroupByUserList, setFeatureGroupByUserList] = useState<AbTestingData[]>([]);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [totalFeatureGroupByUser, setTotalFeatureGroupByUser] = useState<number>(0);

  const { currentFilters: ctxFilters, setFilters } = useContext(SearchFilterContext);
  const currentFilters: AbTestingListSearchParamsTC = ctxFilters ? ctxFilters[searchFilterContextKey] : undefined;
  const [searchFilters, setSearchFilters] = useState<AbTestingListSearchFormData>({
    searchField: currentFilters?.searchField || '',
    searchKeyword: currentFilters?.searchKeyword || '',
    filteringType: currentFilters?.filteringType || [],
  });

  const [tableConditions, setTableConditions] = useState<AppTableConditions>({
    page: currentFilters?.page || 0,
    rowsPerPage: currentFilters?.rowsPerPage || defaultRowsPerPage,
    sortColumn: currentFilters?.sortColumn || 'user_id',
    sortDirection: currentFilters?.sortDirection || 'asc',
  });

  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

  const { canPerform } = usePrivilege();
  const allowEdit = canPerform('abTesting', 'update');

  const getCurrentSearchParam = (): AbTestingListSearchParamsTC => ({
    page: tableConditions.page || 0,
    rowsPerPage: tableConditions.rowsPerPage || defaultRowsPerPage,
    sortColumn: tableConditions.sortColumn || 'user_id',
    sortDirection: tableConditions.sortDirection || 'desc',
    searchField: searchFilters.searchField,
    searchKeyword: searchFilters.searchKeyword,
    filteringType: searchFilters.filteringType,
  });

  const requestAbTestingList = async (isPageSubscribed = true): Promise<boolean> => {
    if (isPageSubscribed) {
      setIsLoadingData(true);

      const searchConditions = getCurrentSearchParam();
      setFilters(searchFilterContextKey, searchConditions);
      const result = await getAbTestingListGroupByUsers(searchConditions, featureName);

      setIsLoadingData(false);
      if (result.data) {
        setFeatureGroupByUserList(result.data.contents);
        setTotalFeatureGroupByUser(result.data?.page?.totalElements || 0);
        return Promise.resolve(true);
      }

      setFeatureGroupByUserList([]);
      setTotalFeatureGroupByUser(0);
      showSnackbar(t('ab-testing:error.list.requestFailed').toString(), 'error');
      return Promise.reject(result.error);
    }

    return false;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useDeepCompareEffect((): any => {
    let isSubscribed = true;

    requestAbTestingList(isSubscribed);

    return () => (isSubscribed = false);
  }, [tableConditions, searchFilters]);

  const tableConditionsChangedHandler = (values: AppTableConditions) => {
    setTableConditions(values);
  };

  const onSearchFormSubmit = (filters: AbTestingListSearchFormData) => {
    if (isLoadingData) {
      showSnackbar(t('common:message.pleaseWaitForDataToFinish').toString(), 'warning');
      return;
    }
    if (tableConditions.page !== 0) {
      tableConditions.page = 0;
    }
    setSearchFilters(filters);
  };

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<AbTestingFormDataRequest>({
    mode: 'onChange',
    defaultValues: {
      userHashIds: [],
      features: [],
    },
  });

  const onConfirmationDeleteClick = useCallback(async () => {
    if (featureName && featureGroupByUserList.length > 0) {
      setAppLoading(true);

      const features = [];
      features.push(featureName);

      const result = await deleteUserAbTestingByFeature(features);
      setAppLoading(false);

      if (result.data) {
        showSnackbar(t('ab-testing:message.deleteSuccess').toString(), 'success');
        setTimeout(() => {
          setIsDeleteConfirmOpen(false);
          window.location.href = `/ab-testing`;
        }, 300);
      } else {
        showSnackbar(t('ab-testing:message.deleteFailed').toString(), 'error');
      }
    }
  }, [featureGroupByUserList.length, featureName, setAppLoading, showSnackbar, t]);

  const handleSaveData = useCallback(
    async (formData: AbTestingFormDataRequest) => {
      if (formData) {
        setAppLoading(true);

        const newData = {
          features: featureName.toString().split(','),
          userHashIds: formData.userHashIds.toString().split(','),
        };

        const result = await updateAbTesting(newData);
        if (result.data) {
          showSnackbar(t('ab-testing:message.addNewUserSuccess').toString(), 'success');
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          showSnackbar(t('ab-testing:message.addNewUserFailed').toString(), 'error');
        }
        setAppLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setAppLoading, showSnackbar, t],
  );

  const handleRemoveUsersData = useCallback(async () => {
    const userHashIdsList = watch('userHashIds');
    if (userHashIdsList) {
      setAppLoading(true);

      const data = {
        features: featureName.toString().split(','),
        userHashIds: userHashIdsList.toString().split(','),
      };

      const result = await deleteUsersByFeature(data);
      if (result.data) {
        showSnackbar(t('ab-testing:message.deleteUserSuccess').toString(), 'success');
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        showSnackbar(t('ab-testing:message.deleteUserFailed').toString(), 'error');
      }
      setAppLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setAppLoading, showSnackbar, t, watch]);

  return (
    <>
      <AbTestingListSearchForm
        isLoadingData={isLoadingData}
        currentFilters={searchFilters}
        onSearchFormSubmit={onSearchFormSubmit}
      />

      <Paper style={{ margin: '30px', padding: '30px' }}>
        <Grid container spacing={3}>
          <Grid item sm={12} md={8}>
            <Typography style={{ fontWeight: 'bold' }}>
              {t('ab-testing:details.groupByUser.title', {
                fieldName: featureName.toUpperCase(),
                totalUser: totalFeatureGroupByUser,
              })}
            </Typography>
          </Grid>
          <Grid item sm={12} md={4}>
            <Box textAlign="right">
              <AppButton
                variant="outlined"
                color="error"
                startIcon={<DeleteForeverIcon />}
                onClick={() => {
                  setIsDeleteConfirmOpen(true);
                }}>
                {t('ab-testing:button.deleteFeature')}
              </AppButton>
            </Box>
          </Grid>
          <Grid item sm={12} md={6}>
            <FeatureGroupByUserListTable
              currentConditions={tableConditions}
              userFeatureList={featureGroupByUserList}
              totalUserFeatureList={totalFeatureGroupByUser}
              isLoadingData={isLoadingData}
              onTableConditionsChanged={tableConditionsChangedHandler}
              onFetch={() => requestAbTestingList()}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <form onSubmit={handleSubmit(handleSaveData)}>
              <Controller
                name="userHashIds"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <AppFormControl error={!!errors.userHashIds}>
                    <InputLabel htmlFor="user-hash-id-lists">{t('ab-testing:details.label.addUserHashIds')}</InputLabel>
                    <Box>
                      <OutlinedInput
                        {...field}
                        id="user-hash-id-lists"
                        fullWidth
                        error={!!errors.userHashIds}
                        disabled={!allowEdit}
                        rows={8}
                        multiline
                        onChange={(e) => field.onChange(e.target.value.replace(/[\n"' ']/g, ''))}
                      />
                      <Typography variant="subtitle2" style={{ marginTop: '8px' }}>
                        {t('ab-testing:details.label.subTitle2')}
                      </Typography>
                    </Box>
                  </AppFormControl>
                )}
              />
              <Box textAlign="right" mt={3}>
                <AppButton type="submit" color="primary" disabled={!allowEdit}>
                  {t('ab-testing:button.addUser')}
                </AppButton>
                <AppButton
                  color="error"
                  disabled={!allowEdit}
                  style={{ marginLeft: '8px' }}
                  onClick={() => handleRemoveUsersData()}>
                  {t('ab-testing:button.deleteUsers')}
                </AppButton>
              </Box>
            </form>
          </Grid>
        </Grid>
      </Paper>

      <AppDialog
        open={isDeleteConfirmOpen}
        title={t('ab-testing:dialogDeleteFeature.title')}
        okButtonText={t('common:button.confirm')}
        okButtonColor="error"
        cancelButtonText={t('common:button.cancel')}
        onOkClick={onConfirmationDeleteClick}
        onCancelClick={() => {
          setIsDeleteConfirmOpen(false);
        }}
        onClose={() => {
          setIsDeleteConfirmOpen(false);
        }}>
        <Box width="100%" maxWidth="320px">
          <Typography>
            {t('ab-testing:dialogDeleteFeature.detail', {
              fieldName: featureName.toUpperCase(),
            })}
          </Typography>
        </Box>
      </AppDialog>
    </>
  );
};

export default FeatureEdit;
