import React, { ChangeEvent, FC, useContext, useEffect, useState, ReactNode } from 'react';

import { Box, Container, makeStyles, Paper, Tab, Tabs, Typography } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { Alert } from '@material-ui/lab';
import clsx from 'clsx';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import StickyActionBar from '../../../components/partials/common/StickyActionBar';
import BreadcrumbEditor from '../../../components/partials/master-data/category/BreadcrumbEditor';
import PathEditor from '../../../components/partials/master-data/category/PathEditor';
import MasterDataCommonFields from '../../../components/partials/master-data/MasterDataCommonFields';
import AppBreadcrumb from '../../../components/ui/AppBreadcrumb';
import AppButton from '../../../components/ui/AppButton';
import AppDialog from '../../../components/ui/AppDialog';
import AppTabPanel from '../../../components/ui/AppTabPanel';
import { AppGlobalUiContext } from '../../../context/AppGlobalUiContext';
import { applyKTErrorToForm } from '../../../helpers/utils';
import {
  ApiResponse,
  BannerCategoryFormData,
  BannerCategoryObjectType,
  BreadcrumbItem,
  Category,
  CategoryBreadcrumb,
  CategoryFormData,
  CategoryPath,
  TabItem,
} from '../../../models';
import * as categoryService from '../../../services/category';
import useAppContainerStyles from '../../../theme/container.style';
import useControlStyles from '../../../theme/controls.style';
import useTypographyStyles from '../../../theme/typography.style';

const tabIdPrefix = 'category-edit-tab-';
const tabPanelPrefix = 'category-edit-tabpanel-';
const tabList: TabItem[] = [
  {
    id: 'general',
    label: 'master-data:form.category.tabs.general',
    value: 'general',
  },
  {
    id: 'breadcrumb',
    label: 'master-data:form.category.tabs.breadcrumb',
    value: 'breadcrumb',
  },
  {
    id: 'path',
    label: 'master-data:form.category.tabs.path',
    value: 'path',
  },
];

const useStyles = makeStyles((theme) => ({
  tabBar: {
    position: 'relative',
    zIndex: 2,
    borderBottom: `solid 1px ${theme.palette.grey[400]}`,
  },
  tabPanel: {
    position: 'relative',
    minHeight: 'calc(100vh - 230px)',
    marginBottom: 100,
  },
  categoryTab: {
    flexDirection: 'row',
  },
  categoryWarningIcon: {
    marginRight: 3,
  },
}));

const CategoryEdit: FC = () => {
  const { t } = useTranslation();
  const { uid: masterIndexUid, catUid } = useParams<{ uid: string; catUid: string }>();
  const history = useHistory();
  const { showSnackbar, setAppLoading, setAppBarTitle } = useContext(AppGlobalUiContext);
  const classes = useStyles();
  const containerClass = useAppContainerStyles();
  const controlsClasses = useControlStyles();
  const typoClasses = useTypographyStyles();
  const [currentCategory, setCurrentCategory] = useState<Category | undefined>(undefined);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [currentBreadcrumb, setCurrentBreadcrumb] = useState<CategoryBreadcrumb[]>([]);
  const [currentPath, setCurrentPath] = useState<CategoryPath[]>([]);
  const [hasDuplicateFieldError, setHasDuplicateFieldError] = useState<boolean>(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);

  const {
    control,
    setValue,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<CategoryFormData & BannerCategoryFormData>({
    mode: 'onChange',
    defaultValues: {
      uid: 0,
      name: '',
      thaiName: '',
      imageUrl: '',
      slug: '',
      description: '',
      placeholder: '',
      path: '[]',
      breadcrumbPath: '[]',
      titleMeta: '',
      descriptionMeta: '',
      canonicalUrlMeta: '',
      searchSynonym: '',
      masterIndexUid: Number(masterIndexUid || '0'), // master index used only in create mode
      blogSlug: '',
      bannerMobileImageUrl: '',
      bannerMobileTitle: '',
      bannerMobileLink: '',
      bannerDesktopImageUrl: '',
      bannerDesktopTitle: '',
      bannerDesktopLink: '',
    },
  });
  const breadcrumbItems: BreadcrumbItem[] = [
    { label: 'common:sidebar.menu.masterData', url: '/master-data' },
    { label: 'master-data:menu.masterIndex', url: '/master-data/master-index' },
    {
      label: 'master-data:menu.masterIndexUid',
      url: `/master-data/master-index/${masterIndexUid}`,
      labelParam: {
        masterIndexUid,
      },
    },
    {
      label: 'master-data:menu.category',
      url: `/master-data/master-index/${masterIndexUid}/categories`,
      labelParam: {
        masterIndexUid,
      },
    },
    {
      label: `master-data:menu.${catUid === 'create' ? 'newCategory' : 'categoryUid'}`,
      labelParam: {
        categoryUid: catUid,
      },
    },
  ];

  const setFormValues = (data: Category) => {
    setValue('uid', data.uid || 0);
    setValue('name', data.name || '');
    setValue('thaiName', data.thaiName || '');
    setValue('slug', data.slug || '');
    setValue('imageUrl', data.imageUrl || '');
    setValue('description', data.description || '');
    setValue('placeholder', data.placeholder || '');
    setValue('path', data.path || '[]');
    setValue('breadcrumbPath', data.breadcrumbPath || '[]');
    setValue('canonicalUrlMeta', data.canonicalUrlMeta || '');
    setValue('searchSynonym', data.searchSynonym || '');
    setValue('imageUrlMeta', data.imageUrlMeta || '');
    setValue('titleMeta', data.titleMeta || '');
    setValue('descriptionMeta', data.descriptionMeta || '');
    setValue('searchSynonym', data.searchSynonym || '');
    setValue('blogSlug', data.blogSlug || '');

    const bannerData: BannerCategoryObjectType = data?.banner ? JSON.parse(data.banner) : null;

    setValue('bannerMobileImageUrl', bannerData?.mobile?.imageUrl || '');
    setValue('bannerMobileTitle', bannerData?.mobile?.title || '');
    setValue('bannerMobileLink', bannerData?.mobile?.link || '');

    setValue('bannerDesktopImageUrl', bannerData?.desktop?.imageUrl || '');
    setValue('bannerDesktopTitle', bannerData?.desktop?.title || '');
    setValue('bannerDesktopLink', bannerData?.desktop?.link || '');

    try {
      setCurrentBreadcrumb(data.breadcrumbPath ? JSON.parse(data.breadcrumbPath) : []);
      setCurrentPath(data.path ? JSON.parse(data.path) : []);
    } catch (e) {
      setCurrentBreadcrumb([]);
      setCurrentPath([]);
    }
  };

  useEffect(() => {
    let isActive = true;
    setAppBarTitle(`master-data:title.category.${catUid === 'create' ? 'create' : 'edit'}`);

    (async () => {
      if (isActive && catUid !== 'create') {
        const category = await categoryService.getCategoryByUid(catUid);
        if (category.data) {
          setCurrentCategory(category.data);
          setFormValues(category.data);
        } else {
          showSnackbar(t('master-data:error.category.requestCategoryFailed').toString(), 'error');
        }
      }
    })();

    return () => {
      isActive = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const tabChangeHandler = (e: ChangeEvent<any>, value: any) => {
    setSelectedTab(value);
  };

  const saveCategory = (data: CategoryFormData & BannerCategoryFormData) => {
    (async () => {
      setAppLoading(true);
      setHasDuplicateFieldError(false);

      let saveResult: ApiResponse<Category>;

      const payloadBanner = JSON.stringify({
        mobile: {
          imageUrl: data?.bannerMobileImageUrl || '',
          title: data?.bannerMobileTitle || '',
          link: data?.bannerMobileLink || '',
        },
        desktop: {
          imageUrl: data?.bannerDesktopImageUrl || '',
          title: data?.bannerDesktopTitle || '',
          link: data?.bannerDesktopLink || '',
        },
      } as BannerCategoryObjectType);

      delete data.bannerDesktopImageUrl;
      delete data.bannerDesktopTitle;
      delete data.bannerDesktopLink;
      delete data.bannerMobileImageUrl;
      delete data.bannerMobileTitle;
      delete data.bannerMobileLink;
      const payload: CategoryFormData = { ...data, banner: payloadBanner };

      if (catUid !== 'create' && currentCategory) {
        delete payload.masterIndexUid;
        saveResult = await categoryService.updateCategory(currentCategory.id, payload);
      } else {
        saveResult = await categoryService.createCategory(payload);
      }

      if (saveResult && saveResult.data && saveResult.data.id) {
        await categoryService.syncElasticsearchCategoryData(saveResult.data.id);
      }

      if (saveResult.data) {
        clearErrors();
        showSnackbar(
          t(`master-data:message.category.${catUid === 'create' ? 'create' : 'update'}Success`).toString(),
          'success',
        );

        // If uid has changed, refresh page to get fresh data
        // Don't have to clear app loading since it's refreshing
        if (catUid === 'create' || (saveResult.data?.uid && saveResult.data.uid !== Number(catUid))) {
          setTimeout(() => {
            window.location.href = `/master-data/master-index/${masterIndexUid}/categories/${saveResult.data?.uid}`;
          }, 2000);
        } else {
          setAppLoading(false);
          setCurrentCategory(saveResult.data);
        }
      } else {
        setAppLoading(false);
        showSnackbar(t(saveResult.error), 'error');

        if (saveResult.validationErrors) {
          // Skip UID error to display another error format
          const hasUidError = saveResult.validationErrors.find((item) => item.field === 'uid');
          applyKTErrorToForm<CategoryFormData>(
            t,
            setError,
            getValues,
            saveResult.validationErrors.filter((item) => item.field !== 'uid'),
          );

          if (hasUidError) {
            setHasDuplicateFieldError(true);
          }
        }
      }
    })();
  };

  const deleteCategory = () => {
    (async () => {
      setAppLoading(true);
      setHasDuplicateFieldError(false);

      let saveResult: boolean;

      if (currentCategory?.id) {
        try {
          saveResult = await categoryService.deleteCategory(currentCategory?.id.toString());
          if (saveResult) {
            await categoryService.syncElasticsearchCategoryData(currentCategory?.id);
            clearErrors();
            showSnackbar(t(`master-data:message.category.deleteSuccess`).toString(), 'success');
            setTimeout(() => {
              window.location.href = `/master-data/master-index/${masterIndexUid}/categories`;
            }, 2000);
            setAppLoading(false);
          }
        } catch (e) {
          showSnackbar(t(`master-data:message.category.deleteFailed`), 'error');
        } finally {
          setAppLoading(false);
        }
      }
    })();
  };

  const onSaveClick = () => {
    handleSubmit(saveCategory)();
  };

  const onDeleteClick = () => {
    setIsConfirmDialogOpen(true);
  };

  const onConfirmationDeleteClick = () => {
    setIsConfirmDialogOpen(false);
    handleSubmit(deleteCategory)();
  };

  const onConfirmationCancelClick = () => {
    setIsConfirmDialogOpen(false);
  };

  const getTabClassName = (tab: TabItem): string => {
    const hasError = (!!errors.breadcrumbPath && tab.value === 'breadcrumb') || (!!errors.path && tab.value === 'path');
    if (hasError) {
      return typoClasses.errorText;
    }

    return '';
  };

  const getTabLabel = (tab: TabItem): ReactNode => {
    const hasError = (!!errors.breadcrumbPath && tab.value === 'breadcrumb') || (!!errors.path && tab.value === 'path');

    return (
      <>
        {hasError && (
          <ErrorOutlineIcon className={clsx(classes.categoryWarningIcon, typoClasses.errorText)} fontSize="small" />
        )}
        {t(tab.label).toString()}
      </>
    );
  };

  const onBackClick = () => {
    history.push(`/master-data/master-index/${masterIndexUid}/categories`);
  };

  return (
    <>
      <Box px={6} py={3}>
        <AppBreadcrumb items={breadcrumbItems} />
      </Box>
      <Container className={containerClass.container} style={{ marginBottom: 60 }}>
        <Box pt={3} pb={2} display="flex" justifyContent="flex-end" className={controlsClasses.buttonsGroup}>
          <StickyActionBar>
            <AppButton color="default" variant="outlined" onClick={() => onBackClick()}>
              {t('common:button.cancel')}
            </AppButton>
            <AppButton color="primary" onClick={() => onSaveClick()}>
              {t('common:button.save')}
            </AppButton>
            {currentCategory && (
              <AppButton color="error" variant="outlined" onClick={() => onDeleteClick()}>
                {t('common:button.delete')}
              </AppButton>
            )}
          </StickyActionBar>
        </Box>

        <Paper>
          <Tabs
            value={selectedTab}
            onChange={tabChangeHandler}
            aria-label="Finance detail tabs"
            textColor="primary"
            indicatorColor="primary"
            className={classes.tabBar}
            variant="scrollable"
            scrollButtons="auto">
            {tabList.map((tab: TabItem, idx: number) => (
              <Tab
                key={tab.id}
                label={getTabLabel(tab)}
                id={`${tabIdPrefix}${tab.id}`}
                aria-controls={`${tabPanelPrefix}${tab.id}`}
                disabled={tab.disabled || false}
                className={clsx(classes.categoryTab, getTabClassName(tab), {
                  [typoClasses.textWeightBold]: selectedTab === idx,
                })}
              />
            ))}
          </Tabs>
          <AppTabPanel
            value={selectedTab}
            index={0}
            tabItem={tabList[0]}
            tabIdPrefix={tabIdPrefix}
            tabPanelPrefix={tabPanelPrefix}
            className={classes.tabPanel}>
            <Box p={3}>
              {!!errors.breadcrumbPath && (
                <Box mb={2} maxWidth={300}>
                  <Alert variant="outlined" severity="error" className={typoClasses.errorText}>
                    {t('validation:requiredFieldAlt', {
                      fieldName: 'breadcrumb',
                    })}
                  </Alert>
                </Box>
              )}
              {!!errors.breadcrumbPath && (
                <Box mb={2} maxWidth={300}>
                  <Alert variant="outlined" severity="error" className={typoClasses.errorText}>
                    {t('validation:requiredFieldAlt', {
                      fieldName: 'path',
                    })}
                  </Alert>
                </Box>
              )}
              {hasDuplicateFieldError && (
                <Box mb={2} maxWidth={900}>
                  <Alert variant="outlined" severity="error" className={typoClasses.errorText}>
                    {t('master-data:error.category.dupFieldError', {
                      fields: 'uid, name, thaiName, slug',
                    })}
                  </Alert>
                </Box>
              )}

              <MasterDataCommonFields
                control={control}
                errors={errors}
                hasSearchSynonym
                hasCanonicalUrlMeta
                hasBlogSlug
                hasBanner
              />
            </Box>
          </AppTabPanel>
          <AppTabPanel
            value={selectedTab}
            index={1}
            tabItem={tabList[1]}
            tabIdPrefix={tabIdPrefix}
            tabPanelPrefix={tabPanelPrefix}
            className={classes.tabPanel}>
            <Box p={2}>
              <Controller
                control={control}
                name="breadcrumbPath"
                rules={{ required: true }}
                render={({ field }) => (
                  <BreadcrumbEditor
                    data={currentBreadcrumb}
                    onChange={(data: CategoryBreadcrumb[]) => {
                      setCurrentBreadcrumb(data);
                      field.onChange(JSON.stringify(data || []));
                    }}
                  />
                )}
              />
            </Box>
          </AppTabPanel>
          <AppTabPanel
            value={selectedTab}
            index={2}
            tabItem={tabList[2]}
            tabIdPrefix={tabIdPrefix}
            tabPanelPrefix={tabPanelPrefix}
            className={classes.tabPanel}>
            <Box p={2}>
              <Controller
                control={control}
                name="path"
                rules={{ required: true }}
                render={({ field }) => (
                  <PathEditor
                    data={currentPath}
                    onChange={(data: CategoryPath[]) => {
                      setCurrentPath(data);
                      field.onChange(JSON.stringify(data || []));
                    }}
                  />
                )}
              />
            </Box>
          </AppTabPanel>
        </Paper>
      </Container>

      <AppDialog
        title={t('common:dialog.title.confirm').toString()}
        open={isConfirmDialogOpen}
        onOkClick={onConfirmationDeleteClick}
        onCancelClick={onConfirmationCancelClick}
        cancelButtonText={t('common:button.cancel').toString()}
        okButtonText={t('common:button.confirm').toString()}
        okButtonColor="primary">
        <Box mb={3}>
          <Typography>{t('master-data:message.category.deleteDataConfirmation')}</Typography>
        </Box>
      </AppDialog>
    </>
  );
};

export default CategoryEdit;
