import React, { FC } from 'react';

import { Chip, makeStyles } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { ListingCleansingStatus } from '../../../../models/listing.model';

export type ListingCleansingStatusBadgeProps = {
  cleansingStatus?: ListingCleansingStatus;
};

const useStyles = makeStyles((theme) => ({
  cleansingStatusChip: {
    height: '26px',
    lineHeight: '26px',
  },
  cleansingStatusNotChecked: {
    backgroundColor: theme.customPalette.appCleansingStatus.notChecked.main,
    color: theme.customPalette.appCleansingStatus.notChecked.contrastText,
  },
  cleansingStatusChecked: {
    backgroundColor: theme.customPalette.appCleansingStatus.checked.main,
    color: theme.customPalette.appCleansingStatus.checked.contrastText,
  },
  cleansingStatusTrash: {
    backgroundColor: theme.customPalette.appCleansingStatus.trash.main,
    color: theme.customPalette.appCleansingStatus.trash.contrastText,
  },
  cleansingStatusPending: {
    backgroundColor: theme.customPalette.appCleansingStatus.pending.main,
    color: theme.customPalette.appCleansingStatus.pending.contrastText,
  },
}));

const ListingCleansingStatusBadge: FC<ListingCleansingStatusBadgeProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { cleansingStatus } = props;

  return cleansingStatus === ListingCleansingStatus.Checked ? (
    <Chip
      icon={<CheckIcon />}
      classes={{ root: classes.cleansingStatusChip }}
      className={clsx({
        [classes.cleansingStatusChecked]: true,
      })}
      label={t(`listing:common.cleansingStatus.${cleansingStatus}`).toString()}
    />
  ) : (
    <Chip
      classes={{ root: classes.cleansingStatusChip }}
      className={clsx({
        [classes.cleansingStatusNotChecked]: cleansingStatus === null || undefined,
        [classes.cleansingStatusTrash]: cleansingStatus === ListingCleansingStatus.Trash,
        [classes.cleansingStatusPending]: cleansingStatus === ListingCleansingStatus.Pending,
      })}
      label={
        cleansingStatus
          ? t(`listing:common.cleansingStatus.${cleansingStatus}`).toString()
          : t(`listing:common.cleansingStatus.null`).toString()
      }
    />
  );
};

export default ListingCleansingStatusBadge;
