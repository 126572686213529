import React, { FC } from 'react';

import sellerApplicationsRoutes from './route.config';
import ModuleMainPage from '../../../components/templates/ModuleMainPage';

const SellerApplicationTcMain: FC = () => (
  <ModuleMainPage pageTitle="common:title.sellerApplications" routeFunctions={sellerApplicationsRoutes} />
);

export default SellerApplicationTcMain;
