import React, { FC } from 'react';

import { Chip, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { PromoCodeStateWorkflow } from '../../../../../models/promo-code';

export type PromoCodeStatusBadgeProps = {
  status: PromoCodeStateWorkflow;
};

const useStyles = makeStyles((theme) => ({
  statusChip: {
    height: '26px',
    lineHeight: '26px',
  },
  stateStart: {
    backgroundColor: theme.customPalette.appWorkflow.statePromoCodeStart.main,
    color: theme.customPalette.appWorkflow.statePromoCodeStart.contrastText,
  },
  stateAvailable: {
    backgroundColor: theme.customPalette.appWorkflow.statePromoCodeAvailable.main,
    color: theme.customPalette.appWorkflow.statePromoCodeAvailable.contrastText,
  },
  stateUsed: {
    backgroundColor: theme.customPalette.appWorkflow.statePromoCodeUsed.main,
    color: theme.customPalette.appWorkflow.statePromoCodeUsed.contrastText,
  },
  stateExpired: {
    backgroundColor: theme.customPalette.appWorkflow.statePromoCodeExpired.main,
    color: theme.customPalette.appWorkflow.statePromoCodeExpired.contrastText,
  },
  stateInactive: {
    backgroundColor: theme.customPalette.appWorkflow.statePromoCodeInactive.main,
    color: theme.customPalette.appWorkflow.statePromoCodeInactive.contrastText,
  },
}));

const PromoCodeStatusBadge: FC<PromoCodeStatusBadgeProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { status } = props;

  return (
    <Chip
      classes={{ root: classes.statusChip }}
      className={clsx({
        [classes.stateStart]: status === PromoCodeStateWorkflow.Start,
        [classes.stateAvailable]: status === PromoCodeStateWorkflow.Available,
        [classes.stateUsed]: status === PromoCodeStateWorkflow.Used,
        [classes.stateExpired]: status === PromoCodeStateWorkflow.Expired,
        [classes.stateInactive]: status === PromoCodeStateWorkflow.Inactive,
      })}
      label={t(`promo-code:list.search.workflow.${status}`).toString()}
    />
  );
};

export default PromoCodeStatusBadge;
