import React, { FC, useContext, useState, MouseEvent } from 'react';

import { Box, Container, IconButton, TableRow, Tooltip, Typography } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import useDeepCompareEffect from 'use-deep-compare-effect';

import MenuListSearchForm from '../../components/partials/menu/MenuListSearchForm';
import AppButton from '../../components/ui/AppButton';
import AppDialog from '../../components/ui/AppDialog';
import AppTableCell from '../../components/ui/AppTableCell';
import AppTableList from '../../components/ui/AppTableList';
import { AppGlobalUiContext } from '../../context/AppGlobalUiContext';
import { MainModuleContext } from '../../context/MainModuleContext';
import { SearchFilterContext } from '../../context/SearchFilterContext';
import { defaultRowsPerPage } from '../../helpers/constants';
import { AppTableConditions, MenuListSearchFormData, MenuListSearchParams, T2HMenu, TableColumn } from '../../models';
import * as menuService from '../../services/menu';
import useAppContainerStyles from '../../theme/container.style';
import useAppTableStyles from '../../theme/table.style';

const menuTableColumns: TableColumn[] = [
  { name: 'uid', label: 'menu:list.columns.uid', width: 100 },
  { name: 'name', label: 'menu:list.columns.name' },
  { name: '', label: '', width: 150, unsortable: true },
];

const searchFilterContextKey = 't2h-menu';

const MenuList: FC = () => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const history = useHistory();
  const containerClasses = useAppContainerStyles();
  const tableClasses = useAppTableStyles();
  const { setSharedData } = useContext(MainModuleContext);
  const { showSnackbar } = useContext(AppGlobalUiContext);
  const { currentFilters: ctxFilters, setFilters } = useContext(SearchFilterContext);
  const currentFilters: MenuListSearchParams = ctxFilters ? ctxFilters[searchFilterContextKey] : undefined;
  const [menuList, setMenuList] = useState<T2HMenu[]>([]);
  const [totalMenuCount, setTotalMenuCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isHelpDialogOpen, setIsHelpDialogOpen] = useState<boolean>(false);
  const [searchFilters, setSearchFilters] = useState<MenuListSearchFormData>({
    searchKeyword: currentFilters?.searchKeyword || '',
  });
  const [tableConditions, setTableConditions] = useState<AppTableConditions>({
    page: currentFilters?.page || 0,
    rowsPerPage: currentFilters?.rowsPerPage || defaultRowsPerPage,
    sortColumn: currentFilters?.sortColumn || 'name',
    sortDirection: currentFilters?.sortDirection || 'asc',
  });

  const getCurrentSearchParam = (): MenuListSearchParams => ({
    searchKeyword: searchFilters.searchKeyword || '',
    page: tableConditions.page || 0,
    rowsPerPage: tableConditions.rowsPerPage || defaultRowsPerPage,
    sortColumn: tableConditions.sortColumn || 'name',
    sortDirection: tableConditions.sortDirection || 'asc',
  });

  const requestMenuList = async (isPageSubscribed = true): Promise<boolean> => {
    if (isPageSubscribed) {
      setIsLoading(true);

      const searchConditions = getCurrentSearchParam();

      // Save search filter to search filter context
      setFilters(searchFilterContextKey, searchConditions);

      const result = await menuService.getMenuList(searchConditions);

      setIsLoading(false);

      if (result.data) {
        setMenuList(result.data.list);
        setTotalMenuCount(result.data.totalCount);
        return Promise.resolve(true);
      }

      showSnackbar(t(result.error as string).toString(), 'error');
      return Promise.reject(result.error);
    }

    return false;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useDeepCompareEffect((): any => {
    let isSubscribed = true;

    requestMenuList(isSubscribed);

    return () => (isSubscribed = false);
  }, [tableConditions, searchFilters]);

  const onTableConditionChanged = (conditions: AppTableConditions) => {
    setTableConditions(conditions);
  };

  const editConfig = (menu: T2HMenu) => {
    history.push(`${path}/${menu.uid}`);
  };

  const onSearchFormSubmit = (filters: MenuListSearchFormData) => {
    if (isLoading) {
      showSnackbar(t('common.message.pleaseWaitForDataToFinish').toString(), 'warning');
      return;
    }

    if (tableConditions.page !== 0) {
      tableConditions.page = 0;
    }

    setSearchFilters(filters);
  };

  const onCloneClick = (e: MouseEvent, menu: T2HMenu) => {
    e.stopPropagation();
    const cloneMenu = { ...menu, id: null, uid: 0 };
    setSharedData(cloneMenu);
    history.push(`${path}/create`);
  };

  return (
    <>
      <MenuListSearchForm currentFilters={searchFilters} onSearchFormSubmit={onSearchFormSubmit} />
      <Container className={containerClasses.container}>
        <Box className={tableClasses.tableContainer}>
          <Box display="flex" flexDirection="row" justifyContent="space-between" mb={2}>
            <AppButton color="primary" variant="outlined" startIcon={<Add />} url={`${path}/create`}>
              {t('menu:common.addNewMenu')}
            </AppButton>
            <AppButton
              color="primary"
              variant="text"
              startIcon={<EmojiObjectsOutlinedIcon />}
              onClick={() => setIsHelpDialogOpen(true)}>
              {t('common:dialog.title.help')}
            </AppButton>
          </Box>

          <AppTableList
            {...tableConditions}
            columns={menuTableColumns}
            totalDataCount={totalMenuCount}
            isLoading={isLoading}
            onTableConditionChanged={onTableConditionChanged}
            tableBodyContent={
              menuList.length > 0 && (
                <>
                  {menuList.map((menu: T2HMenu) => (
                    <TableRow
                      hover
                      key={menu.id}
                      classes={{ root: clsx(tableClasses.tableRow, tableClasses.clickableTableRow) }}
                      onClick={() => editConfig(menu)}>
                      <AppTableCell>{menu.uid}</AppTableCell>
                      <AppTableCell>{menu.name}</AppTableCell>
                      <AppTableCell>
                        <Tooltip title={t('common:label.clone').toString()} placement="top" aria-label="clone">
                          <IconButton
                            aria-label="edit"
                            disableRipple
                            disableFocusRipple
                            size="small"
                            onClick={(e) => onCloneClick(e, menu)}>
                            <FileCopyOutlinedIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </AppTableCell>
                    </TableRow>
                  ))}
                </>
              )
            }
          />
        </Box>
      </Container>
      <AppDialog
        type="tips"
        open={isHelpDialogOpen}
        title="common:dialog.title.help"
        okButtonText="common:button.close"
        okButtonColor="default"
        okButtonVariant="outlined"
        onOkClick={() => setIsHelpDialogOpen(false)}>
        <Typography>{t('menu:message.cloneItemMessage')}</Typography>
      </AppDialog>
    </>
  );
};

export default MenuList;
