import React, { FC, useContext, useEffect, useState } from 'react';

import { Box, Container, Divider, Grid, List, ListItem, ListItemText, Paper, TextField } from '@material-ui/core';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

import StickyActionBar from '../../components/partials/common/StickyActionBar';
import MenuEditor from '../../components/partials/menu/MenuEditor';
import AppButton from '../../components/ui/AppButton';
import AppDialog from '../../components/ui/AppDialog';
import AppFormControl from '../../components/ui/AppFormControl';
import AppFormErrorMessage from '../../components/ui/AppFormErrorMessage';
import { AppGlobalUiContext } from '../../context/AppGlobalUiContext';
import { MainModuleContext } from '../../context/MainModuleContext';
import { applyKTErrorToForm } from '../../helpers/utils';
import { validateNonZeroLeadNumber } from '../../helpers/validations';
import { ApiResponse } from '../../models';
import { T2HMenuItem, T2HMenu, T2HMenuFormData } from '../../models/menu.model';
import * as menuService from '../../services/menu';
import useAppContainerStyles from '../../theme/container.style';
import useControlStyles from '../../theme/controls.style';

const MenuEdit: FC = () => {
  const { t } = useTranslation();
  const { uid } = useParams<{ uid: string }>();
  const containerClass = useAppContainerStyles();
  const controlsClasses = useControlStyles();
  const history = useHistory();
  const { path } = useRouteMatch();
  const { showSnackbar, setAppLoading } = useContext(AppGlobalUiContext);
  const { currentRoute, sharedData, setSharedData } = useContext(MainModuleContext);
  const [editorMenuData, setEditorMenuData] = useState<T2HMenuItem[] | undefined>(undefined);
  const [isDataFromClone, setIsDataFromClone] = useState(false);
  const [isHelpDialogOpen, setIsHelpDialogOpen] = useState(false);
  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
    clearErrors,
    setError,
    getValues,
  } = useForm<T2HMenuFormData>({
    mode: 'onSubmit',
    defaultValues: {
      uid: 0,
      name: '',
      description: '',
      path: '[]',
    },
  });
  const DISABLED_MENU_UID = ['15', '16'];
  const isUpdateDisabled = DISABLED_MENU_UID.includes(uid);

  const setFormValue = (data: T2HMenu) => {
    const menuEditorData = data?.path || '[]';
    setValue('id', data?.id || 0, { shouldValidate: false });
    setValue('uid', data?.uid || 0, { shouldValidate: true });
    setValue('name', data?.name || '', { shouldValidate: true });
    setValue('description', data?.description || '', { shouldValidate: true });
    setValue('path', menuEditorData, { shouldValidate: true });
    try {
      setEditorMenuData(JSON.parse(menuEditorData));
    } catch (e) {
      setEditorMenuData([]);
    }
  };

  useEffect(() => {
    let isActive = true;

    if (isActive) {
      (async () => {
        if (uid !== 'create') {
          const result = await menuService.getMenuByUid(uid);
          setIsDataFromClone(result.data?.name?.includes('Clone') || false);
          if (result.data) {
            setFormValue(result.data);
          } else {
            showSnackbar(result.error, 'error');
          }
        } else if (sharedData) {
          // This means user click clone object from list page
          setFormValue(sharedData);
          setSharedData(null);
        } else {
          setEditorMenuData([]);
        }
      })();
    }

    return () => {
      isActive = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onMenuEditChangeHandler = (data: T2HMenuItem[]) => {
    setValue('path', JSON.stringify(data || []), { shouldValidate: true });
  };

  const saveData = async (formData: T2HMenuFormData) => {
    setAppLoading(true);

    try {
      let saveResult: ApiResponse<T2HMenu>;
      if (uid === 'create' && !formData.id) {
        saveResult = await menuService.createMenu(formData);
      } else if (formData.id) {
        saveResult = await menuService.updateMenu(Number(formData.id), formData);
      } else {
        throw new Error('Cannot update because there is no id');
      }

      if (saveResult) {
        if (saveResult.data) {
          clearErrors();
          showSnackbar(t(`menu:message.${uid === 'create' ? 'createSuccess' : 'updateSuccess'}`).toString(), 'success');

          // If uid has changed, refresh page to get fresh data
          // Don't have to clear app loading since it's refreshing
          if (uid === 'create' || (saveResult.data?.uid && saveResult.data.uid !== Number(uid))) {
            setTimeout(() => {
              window.location.href = `/menus/${saveResult.data?.uid}`;
            }, 2000);
          } else {
            setAppLoading(false);
            setFormValue(saveResult.data);
          }
        } else {
          setAppLoading(false);
          showSnackbar(t(saveResult.error).toString(), 'error');

          if (saveResult.validationErrors) {
            applyKTErrorToForm<T2HMenuFormData>(t, setError, getValues, saveResult.validationErrors);
          }
        }
      }
    } catch (e) {
      showSnackbar(t(`menu:error.${uid === 'create' ? 'createFailed' : 'updateFailed'}`).toString(), 'error');
    }
  };

  const onSaveClick = () => {
    handleSubmit(saveData)();
  };

  return (
    <>
      <Container className={containerClass.container} style={{ marginBottom: 60 }}>
        <Box py={3}>
          <Box py={2} display="flex" justifyContent="flex-end" className={controlsClasses.buttonsGroup}>
            <StickyActionBar>
              <AppButton color="default" variant="outlined" onClick={() => history.push(currentRoute?.backUrl || path)}>
                {t('common:button.cancel')}
              </AppButton>
              {!isUpdateDisabled && (
                <AppButton color="primary" onClick={() => onSaveClick()}>
                  {t('common:button.save')}
                </AppButton>
              )}
            </StickyActionBar>
          </Box>
          <Paper>
            <Box display="flex" justifyContent="flex-end" px={3} pt={3}>
              <AppButton
                color="primary"
                variant="text"
                startIcon={<EmojiObjectsOutlinedIcon />}
                onClick={() => setIsHelpDialogOpen(true)}>
                {t('common:dialog.title.help')}
              </AppButton>
            </Box>
            <Box p={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={5} md={4} lg={2}>
                  <Controller
                    name="uid"
                    control={control}
                    rules={{ required: true, validate: validateNonZeroLeadNumber }}
                    render={({ field }) => (
                      <AppFormControl error={!!errors.uid}>
                        <TextField
                          {...field}
                          variant="outlined"
                          label={t('master-data:common.field.uid').toString()}
                          error={!!errors.uid}
                          autoFocus
                          disabled={isUpdateDisabled}
                        />
                        {!!errors.uid && <AppFormErrorMessage errors={errors} name="uid" />}
                      </AppFormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={7} md={8} lg={5}>
                  <Controller
                    name="name"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <AppFormControl error={!!errors.name}>
                        <TextField
                          {...field}
                          variant="outlined"
                          label={t('master-data:common.field.name').toString()}
                          error={!!errors.name}
                          disabled={isUpdateDisabled}
                        />
                        {!!errors.name && <AppFormErrorMessage errors={errors} name="name" />}
                      </AppFormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={12} lg={5}>
                  <Controller
                    name="description"
                    control={control}
                    render={({ field }) => (
                      <AppFormControl error={!!errors.description}>
                        <TextField
                          {...field}
                          variant="outlined"
                          label={t('master-data:common.field.description').toString()}
                          multiline
                          rowsMax={3}
                          rows={3}
                          error={!!errors.description}
                          disabled={isUpdateDisabled}
                        />
                        {!!errors.description && <AppFormErrorMessage errors={errors} name="description" />}
                      </AppFormControl>
                    )}
                  />
                </Grid>
              </Grid>
            </Box>
            <Divider />
            {editorMenuData && (
              <MenuEditor
                defaultValue={editorMenuData}
                isDataFromClone={isDataFromClone}
                onChange={onMenuEditChangeHandler}
                disabled={isUpdateDisabled}
              />
            )}
          </Paper>
        </Box>
      </Container>

      <AppDialog
        type="tips"
        open={isHelpDialogOpen}
        title="common:dialog.title.help"
        okButtonText="common:button.close"
        okButtonColor="default"
        okButtonVariant="outlined"
        onOkClick={() => setIsHelpDialogOpen(false)}>
        <List>
          <ListItem>
            <ListItemText primary={t('menu:message.editorHelpText1').toString()} />
          </ListItem>
          <ListItem>
            <ListItemText primary={t('menu:message.editorHelpText2').toString()} />
          </ListItem>
          <ListItem>
            <ListItemText primary={t('menu:message.editorHelpText3').toString()} />
          </ListItem>
        </List>
      </AppDialog>
    </>
  );
};

export default MenuEdit;
