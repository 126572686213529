import dayjs from 'dayjs';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from 'firebase/firestore';

import { firebaseAuth, firestoreDb, FS_DB_COLLECTION } from '../helpers/firebase';
import {
  ApiResponse,
  FsDocRef,
  FsQueryDocSnapshot,
  GetReportedItemListResponse,
  ReportedItem,
  ReportedItemListSearchParams,
  ReportStatus,
  ReportType,
  SnsTag,
} from '../models';

export const reportedItemConverter = {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  /* eslint-disable @typescript-eslint/explicit-module-boundary-types */
  toFirestore: (reportedItem: any) => reportedItem,
  fromFirestore: async (snapshot: FsQueryDocSnapshot, options: any) => {
    const data = snapshot.data(options);
    if (data) {
      return {
        id: snapshot.id,
        ...data,
        createdAt: data.createdAt ? dayjs(data.createdAt.seconds * 1000) : null,
        updatedAt: data.updatedAt ? dayjs(data.updatedAt.seconds * 1000) : null,
      } as ReportedItem;
    }

    return null;
  },
  /* eslint-enable @typescript-eslint/explicit-module-boundary-types */
  /* eslint-enable @typescript-eslint/no-explicit-any */
};

export const tagItemConverter = {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  /* eslint-disable @typescript-eslint/explicit-module-boundary-types */
  toFirestore: (tagItem: any) => tagItem,
  fromFirestore: (snapshot: FsQueryDocSnapshot, options: any) => {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      tagName: data.tagName,
      popular: data.popular,
      recommend: data.recommend,
    } as SnsTag;
  },
  /* eslint-enable @typescript-eslint/explicit-module-boundary-types */
  /* eslint-enable @typescript-eslint/no-explicit-any */
};

// eslint-disable-next-line import/prefer-default-export
export const getReportedItemList = async (
  req: ReportedItemListSearchParams,
): Promise<ApiResponse<GetReportedItemListResponse>> => {
  try {
    const { currentUser } = firebaseAuth;
    if (!currentUser) {
      return {
        data: null,
        error: 'User has not been authenticated to Firebase yet.',
      };
    }

    const { page, rowsPerPage } = req;

    const queryConstraints = [];
    const filteringItemTypes = req.filteringItemTypes || ['post', 'comment'];
    if (filteringItemTypes.length > 0) {
      queryConstraints.push(where('itemType', 'in', filteringItemTypes));
    }

    const filteringTypes =
      req.filteringTypes.length > 0
        ? req.filteringTypes
        : [ReportType.Fraud, ReportType.Spam, ReportType.Inappropriate, ReportType.Other];
    // if (filteringTypes.length > 0) {
    //   queryConstraints.push(where('reportType', 'in', filteringTypes));
    // }

    const filteringStatuses = req.filteringStatuses.length
      ? req.filteringStatuses
      : [ReportStatus.Submitted, ReportStatus.Approved, ReportStatus.Rejected, ReportStatus.MarkAsDuplicated];
    // if (filteringStatuses.length > 0) {
    //   queryConstraints.push(where('reportStatus', 'in', filteringStatuses));
    // }

    const totalDocsQuery = await getDocs(query(collection(firestoreDb, FS_DB_COLLECTION.reports), ...queryConstraints));
    const totalCount = totalDocsQuery.docs.length;

    queryConstraints.push(limit(req.rowsPerPage || 10));
    queryConstraints.push(orderBy(req.sortColumn || 'updatedAt', req.sortDirection || 'desc'));

    // // For querying next page (must not come with first visible doc id)
    // if (req.lastVisibleDocId) {
    //   const lastDoc = await getDoc(doc(firestoreDb, FS_DB_COLLECTION.reports, req.lastVisibleDocId));
    //   queryConstraints.push(startAfter(lastDoc));
    // }

    // // For querying previous page (must not come with last visible doc id)
    // if (req.firstVisibleDocId) {
    //   const firstDoc = await getDoc(doc(firestoreDb, FS_DB_COLLECTION.reports, req.firstVisibleDocId));
    //   queryConstraints.push(endBefore(firstDoc));
    // }

    const reportedItemsQuery = await getDocs(
      query(collection(firestoreDb, FS_DB_COLLECTION.reports), ...queryConstraints).withConverter(
        reportedItemConverter,
      ),
    );

    let reportItemData: ReportedItem[] = [];
    if (!reportedItemsQuery.empty) {
      const reportedItemListBuilder = reportedItemsQuery.docs.map(async (item) => {
        const data = await item.data();
        return data;
      });
      reportItemData = (await Promise.all(reportedItemListBuilder)).filter((item) => !!item) as ReportedItem[];
    }

    // Workaround filtering
    reportItemData = reportItemData.filter((item) => {
      const matchedType = filteringTypes.includes(item.reportType);
      const matchedStatus = filteringStatuses.includes(item.reportStatus);

      return matchedType && matchedStatus;
    });

    const startAt = (page - 1) * rowsPerPage + rowsPerPage;
    const endAt = startAt + rowsPerPage;

    const paginatedList = reportItemData.slice(startAt, endAt);

    return {
      data: {
        reportedItems: paginatedList,
        totalCount,
      },
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const approveReport = async (
  reportId: string,
  refId: string,
  refType: 'post' | 'comment',
  remark?: string,
  postId?: string,
): Promise<ApiResponse<string | null>> => {
  try {
    const remarkText = `Report approved by admin ${remark ? `- ${remark}` : ''}`;
    await updateDoc(doc(firestoreDb, FS_DB_COLLECTION.reports, reportId), {
      remark: remarkText,
      reportStatus: ReportStatus.Approved,
    });

    if (refType === 'post') {
      await updateDoc(doc(firestoreDb, FS_DB_COLLECTION.posts, refId), {
        deletedAt: serverTimestamp(),
        remark: 'Deleted by admin',
      });
    } else {
      await updateDoc(doc(firestoreDb, FS_DB_COLLECTION.comments, refId), {
        deletedAt: serverTimestamp(),
        remark: 'Deleted by admin',
      });
      if (postId) {
        await updateDoc(doc(firestoreDb, FS_DB_COLLECTION.reports, reportId), {
          updatedAt: serverTimestamp(),
        });
        const postData = doc(firestoreDb, FS_DB_COLLECTION.posts, postId);
        const postToDeleteRequest = await getDoc(postData);
        const postToDeleteRequestData = postToDeleteRequest.data();

        if (postToDeleteRequest.exists() && postToDeleteRequestData) {
          let currentComment = [...(postToDeleteRequestData.comments || [])];
          if (currentComment.length > 0) {
            currentComment = currentComment.filter((ref) => {
              if (typeof ref === 'string') {
                return ref !== refId;
              }
              return (ref as FsDocRef).id !== refId;
            });
          }
          await updateDoc(doc(firestoreDb, FS_DB_COLLECTION.posts, postId), {
            comments: currentComment,
          });
        }
      }
    }
    return {
      data: remarkText,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const rejectReport = async (reportId: string, remark?: string): Promise<ApiResponse<string | null>> => {
  try {
    const remarkText = `Report rejected by admin ${remark ? `- ${remark}` : ''}`;
    await updateDoc(doc(firestoreDb, FS_DB_COLLECTION.reports, reportId), {
      remark: remarkText,
      reportStatus: ReportStatus.Rejected,
    });

    return {
      data: remarkText,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const markAsDuplicatedReport = async (
  reportId: string,
  remark?: string,
): Promise<ApiResponse<string | null>> => {
  try {
    const remarkText = `Mark as duplicated case by admin ${remark ? `- ${remark}` : ''}`;
    await updateDoc(doc(firestoreDb, FS_DB_COLLECTION.reports, reportId), {
      remark: `Mark as duplicated case by admin ${remark ? `- ${remark}` : ''}`,
      reportStatus: ReportStatus.MarkAsDuplicated,
    });

    return {
      data: remarkText,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const searchTagList = async (keyword: string): Promise<ApiResponse<SnsTag[] | null>> => {
  try {
    const queryRef = query(
      collection(firestoreDb, FS_DB_COLLECTION.tags),
      limit(20),
      where('tagName', '>=', keyword),
      where('tagName', '<=', `${keyword}\uf8ff`),
    ).withConverter(tagItemConverter);
    const docsRef = await getDocs(queryRef);
    const tagItems = (!docsRef.empty ? docsRef.docs.map((item) => item.data()) : []) as SnsTag[];

    return {
      data: tagItems,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const getCurrentRecommendedTagList = async (): Promise<ApiResponse<SnsTag[] | null>> => {
  try {
    const queryRef = query(
      collection(firestoreDb, FS_DB_COLLECTION.tags),
      where('recommend', '==', true),
    ).withConverter(tagItemConverter);
    const docsRef = await getDocs(queryRef);
    const tagItems = (!docsRef.empty ? docsRef.docs.map((item) => item.data()) : []) as SnsTag[];

    return {
      data: tagItems,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const getCurrentNotRecommendedTagList = async (): Promise<ApiResponse<SnsTag[] | null>> => {
  try {
    const queryRef = query(
      collection(firestoreDb, FS_DB_COLLECTION.tags),
      where('isDisable', '==', true),
    ).withConverter(tagItemConverter);
    const docsRef = await getDocs(queryRef);
    const tagItems = (!docsRef.empty ? docsRef.docs.map((item) => item.data()) : []) as SnsTag[];

    return {
      data: tagItems,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const saveRecommendTagData = async (oldData: SnsTag[], newData: SnsTag[]): Promise<boolean> => {
  try {
    if (oldData.length) {
      oldData.forEach(async (oldTag) => {
        await updateDoc(doc(firestoreDb, FS_DB_COLLECTION.tags, oldTag.id), {
          recommend: false,
        });
      });
    }
    if (newData.length) {
      newData.forEach(async (oldTag) => {
        await updateDoc(doc(firestoreDb, FS_DB_COLLECTION.tags, oldTag.id), {
          recommend: true,
        });
      });
    }
    return true;
  } catch (error) {
    return false;
  }
};

export const saveNotRecommendTagData = async (oldData: SnsTag[], newData: SnsTag[]): Promise<boolean> => {
  try {
    if (oldData.length) {
      oldData.forEach(async (oldTag) => {
        await updateDoc(doc(firestoreDb, FS_DB_COLLECTION.tags, oldTag.id), {
          isDisable: false,
        });
      });
    }
    if (newData.length) {
      newData.forEach(async (oldTag) => {
        await updateDoc(doc(firestoreDb, FS_DB_COLLECTION.tags, oldTag.id), {
          isDisable: true,
        });
      });
    }
    return true;
  } catch (error) {
    return false;
  }
};
