import React, { FC } from 'react';

import userApplicationsRoutes from './route.config';
import ModuleMainPage from '../../../components/templates/ModuleMainPage';

const UserApplicationMain: FC = () => (
  <ModuleMainPage pageTitle="common:title.v3.userApplications" routeFunctions={userApplicationsRoutes} />
);

export default UserApplicationMain;
