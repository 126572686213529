import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Box, Tooltip, Typography, makeStyles } from '@material-ui/core';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import PostAddOutlinedIcon from '@material-ui/icons/PostAddOutlined';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCartOutlined';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useParams } from 'react-router-dom';

import AppCircularProgress from '../../../components/partials/AppCircularProgress';
import UserDetail from '../../../components/partials/v3/users/UserDetail';
import ChatLog from '../../../components/partials/v3/users/UserDetail/ChatLog';
import InterviewLog from '../../../components/partials/v3/users/UserDetail/InterviewLog';
import OrderOnceList from '../../../components/partials/v3/users/UserDetail/OrderOnce';
import ShoppingCart from '../../../components/partials/v3/users/UserDetail/ShoppingCart';
import WalletMembership from '../../../components/partials/v3/users/UserDetail/WalletMembership';
import AppButton from '../../../components/ui/AppButton';
import { AppGlobalUiContext } from '../../../context/AppGlobalUiContext';
import { AuthContext } from '../../../context/AuthContext';
import useQuery from '../../../hooks/useQuery';
import { UserDataTC, UserRole } from '../../../models';
import { getUserByHashIdTC } from '../../../services/v3/user';
import { getCoinBalanceByHashId } from '../../../services/v3/user-balance';

type UserEditPageProps = unknown;

const useStyles = makeStyles(() => ({
  menuTab: {
    overflow: 'auto',
    position: 'fixed',
    width: '100%',
    backgroundColor: '#ffffff',
    zIndex: 3,
  },
  buttonTab: {
    padding: '16px 20px',
    borderBottom: '3px solid',
    textDecoration: 'unset',
    backgroundColor: 'unset',
    borderRadius: 'unset',
    '& > span': {
      whiteSpace: 'nowrap',
    },
  },
  linkTab: {
    color: 'black',
    textDecoration: 'unset',
  },
  tooltip: {
    fontSize: '14px',
  },
}));

const UserEdit: FC<UserEditPageProps> = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [userInfo, setUserInfo] = useState<UserDataTC | null>(null);
  const { hashId } = useParams<{ hashId: string }>();
  const { authUser } = useContext(AuthContext);
  const location = useLocation();
  const query = useQuery();

  const isTabInfo = useMemo(() => !location.search.includes('?tab'), [location.search]);
  const isTabCoin = useMemo(() => location.search === '?tab=coin', [location.search]);
  const isTabChat = useMemo(() => query.get('tab') === 'chat', [query]);
  const isTabInterview = useMemo(() => location.search.includes('?tab=interview'), [location.search]);
  const isTabShopping = useMemo(() => location.search === '?tab=shopping', [location.search]);
  const isTabOrder = useMemo(() => location.search === '?tab=order', [location.search]);

  const { setAppLoading } = useContext(AppGlobalUiContext);
  const [coinBalance, setCoinBalance] = useState<number>(0);

  const getCoinBalance = useCallback(async () => {
    if (userInfo?.userHashId) {
      setAppLoading(true);
      const result = await getCoinBalanceByHashId(userInfo?.userHashId);

      if (result) {
        setAppLoading(false);
        setCoinBalance(result);
      } else {
        setAppLoading(false);
      }
    }
  }, [setAppLoading, userInfo?.userHashId]);

  useEffect(() => {
    getCoinBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo?.userHashId]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      if (hashId) {
        const result = await getUserByHashIdTC(hashId);
        if (result) {
          setUserInfo(result.data);
        }
      }
      setIsLoading(false);
    })();
  }, [hashId]);

  return (
    <>
      {isLoading ? (
        <AppCircularProgress />
      ) : (
        <>
          {!!userInfo && (
            <>
              <Box className={classes.menuTab}>
                <Box style={{ display: 'flex', borderBottom: '1px solid', borderColor: '#ccc', minWidth: '1135px' }}>
                  <Link to={`/v3/users/${userInfo?.userHashId}`} rel="noreferrer" className={classes.linkTab}>
                    <Tooltip
                      title="ข้อมูลทั่วไปของผู้ใช้"
                      aria-label="ข้อมูลทั่วไปของผู้ใช้"
                      placement="bottom"
                      arrow
                      classes={{ tooltip: classes.tooltip }}>
                      <AppButton
                        className={classes.buttonTab}
                        variant="text"
                        startIcon={<AccountCircleOutlinedIcon />}
                        style={{
                          borderColor: isTabInfo ? '#1B247E' : 'transparent',
                        }}>
                        {t('user:details.tabs.info')}
                      </AppButton>
                    </Tooltip>
                  </Link>

                  <Link to={`/v3/users/${userInfo?.userHashId}?tab=coin`} rel="noreferrer" className={classes.linkTab}>
                    <Tooltip
                      title="แก้ไขยอดเหรียญของผู้ใช้"
                      aria-label="แก้ไขยอดเหรียญของผู้ใช้"
                      placement="bottom"
                      arrow
                      classes={{ tooltip: classes.tooltip }}>
                      <AppButton
                        className={classes.buttonTab}
                        variant="text"
                        startIcon={<MonetizationOnOutlinedIcon />}
                        style={{
                          borderColor: isTabCoin ? '#1B247E' : 'transparent',
                        }}>
                        <Typography component="span">{t('user:details.tabs.coin')}</Typography>
                      </AppButton>
                    </Tooltip>
                  </Link>

                  <Link
                    to={`/v3/users/${userInfo?.userHashId}?tab=shopping`}
                    rel="noreferrer"
                    className={classes.linkTab}>
                    <Tooltip
                      title="ซื้อเหรียญหรือสมาชิกให้ผู้ใช้"
                      aria-label="ซื้อเหรียญหรือสมาชิกให้ผู้ใช้"
                      placement="bottom"
                      arrow
                      classes={{ tooltip: classes.tooltip }}>
                      <AppButton
                        className={classes.buttonTab}
                        variant="text"
                        startIcon={<ShoppingCartIcon />}
                        disabled={!authUser?.isAdmin}
                        style={{
                          borderColor: isTabShopping ? '#1B247E' : 'transparent',
                        }}>
                        <Typography component="span">{t('user:details.tabs.shop')}</Typography>
                      </AppButton>
                    </Tooltip>
                  </Link>

                  <Link to={`/v3/users/${userInfo?.userHashId}?tab=order`} rel="noreferrer" className={classes.linkTab}>
                    <Tooltip
                      title="ประวัติการซื้อเหรียญหรือสมาชิกของผู้ใช้"
                      aria-label="ประวัติการซื้อเหรียญหรือสมาชิกของผู้ใช้"
                      placement="bottom"
                      arrow
                      classes={{ tooltip: classes.tooltip }}>
                      <AppButton
                        className={classes.buttonTab}
                        variant="text"
                        startIcon={<ListAltOutlinedIcon />}
                        style={{
                          borderColor: isTabOrder ? '#1B247E' : 'transparent',
                        }}>
                        <Typography component="span">{t('user:details.tabs.order')}</Typography>
                      </AppButton>
                    </Tooltip>
                  </Link>

                  <Link
                    to={`/v3/users/${userInfo?.userHashId}?tab=chat`}
                    rel="noreferrer"
                    className={classes.linkTab}
                    style={{
                      color: UserRole.GM ? '#000000' : '#9e9e9e',
                    }}>
                    <Tooltip
                      title="ดูประวัติการสนทนาในช่องทางส่งข้อความของ Truck2Hand"
                      aria-label="ดูประวัติการสนทนาในช่องทางส่งข้อความของ Truck2Hand"
                      placement="bottom"
                      arrow
                      classes={{ tooltip: classes.tooltip }}>
                      <AppButton
                        className={classes.buttonTab}
                        variant="text"
                        disabled={!UserRole.GM}
                        startIcon={<ChatOutlinedIcon />}
                        style={{
                          borderColor: isTabChat ? '#1B247E' : 'transparent',
                        }}>
                        <Typography component="span">{t('user:details.tabs.chat')}</Typography>
                      </AppButton>
                    </Tooltip>
                  </Link>

                  <Link
                    to={`/v3/users/${userInfo?.userHashId}?tab=interview`}
                    rel="noreferrer"
                    className={classes.linkTab}>
                    <Tooltip
                      title="เพิ่มหรือดูบันทึกหรือหมายเหตุอื่นๆเกี่ยวผู้ใช้"
                      aria-label="เพิ่มหรือดูบันทึกหรือหมายเหตุอื่นๆเกี่ยวผู้ใช้"
                      placement="bottom"
                      arrow
                      classes={{ tooltip: classes.tooltip }}>
                      <AppButton
                        className={classes.buttonTab}
                        variant="text"
                        startIcon={<PostAddOutlinedIcon />}
                        style={{
                          borderColor: isTabInterview ? '#1B247E' : 'transparent',
                        }}>
                        <Typography component="span">{t('user:details.tabs.interviewLog')}</Typography>
                      </AppButton>
                    </Tooltip>
                  </Link>
                </Box>
              </Box>

              <Box pt={8}>
                {isTabInfo && (
                  <Box>
                    {userInfo && (
                      <UserDetail
                        user={userInfo}
                        currentCoinBalance={coinBalance ?? 0}
                        onUpdated={(data) => setUserInfo(data)}
                      />
                    )}
                  </Box>
                )}

                {isTabCoin && (
                  <Box>
                    {userInfo && (
                      <WalletMembership
                        currentUser={userInfo}
                        currentCoinBalance={coinBalance ?? 0}
                        onSubmitCoinTopUp={getCoinBalance}
                      />
                    )}
                  </Box>
                )}

                {isTabShopping && (
                  <Box>
                    {userInfo && (
                      <ShoppingCart
                        currentUser={userInfo}
                        currentCoinBalance={coinBalance ?? 0}
                        onSubmitPayment={getCoinBalance}
                      />
                    )}
                  </Box>
                )}

                {isTabOrder && <Box>{userInfo && <OrderOnceList currentUser={userInfo} />}</Box>}

                {isTabChat && <Box>{userInfo && <ChatLog currentUser={userInfo} />}</Box>}

                {isTabInterview && <Box>{userInfo && <InterviewLog currentUser={userInfo} />}</Box>}
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
};

export default UserEdit;
