import { apiTCRequestV2 } from '../helpers/request';
import { queryParamGenerator } from '../helpers/utils';
import { ApiResponse } from '../models';
import {
  GetPromoCodeListTCResponse,
  PromoCodeData,
  PromoCodeFormDataRequest,
  PromoCodeSearchFiltersTC,
} from '../models/promo-code';

const getPromoCodeListQueryParam = (param?: PromoCodeSearchFiltersTC): string => {
  const reqParam: { [x: string]: string | number } = {
    page: param?.page || 0, // plus 1 for index shift between FE component and backend query
    per_page: param?.rowsPerPage || 10,
    sort: param?.sortColumn ? `id%7C${(param?.sortDirection || 'desc').toUpperCase()}` : '',
  };

  // Only search with keyword when it comes with the selected column to be searched
  if (param?.searchKeyword && param?.searchField) {
    reqParam[param.searchField] = param.searchKeyword.trim();
  }

  if (param?.filterStateWorkflow?.length) {
    reqParam.productPromoCodeStateWorkflow = param.filterStateWorkflow.join(',');
  }

  return queryParamGenerator(reqParam);
};

export const getPromoCodeData = async (
  param?: PromoCodeSearchFiltersTC,
): Promise<ApiResponse<GetPromoCodeListTCResponse>> => {
  try {
    const queryParamString = getPromoCodeListQueryParam(param);

    const result = await apiTCRequestV2<GetPromoCodeListTCResponse>(`/admin/product-promo-code/?${queryParamString}`);

    return {
      data: result.data ?? null,
    };
  } catch (e) {
    return {
      data: null,
      error: 'promo-code:error.list.requestFailed',
    };
  }
};

export const addNewPromoCode = async (formData: PromoCodeFormDataRequest): Promise<ApiResponse<PromoCodeData>> => {
  const result = await apiTCRequestV2<PromoCodeData>(`/admin/product-promo-code/`, {
    method: 'POST',
    data: { ...formData },
  });

  if (result.data) {
    return {
      ...result,
      data: result.data,
    };
  }

  return {
    ...result,
    data: null,
  };
};

export const deletePromoCodeByHashId = async (productHashId: string): Promise<ApiResponse<boolean>> => {
  try {
    await apiTCRequestV2<boolean>(`/admin/product-promo-code/${productHashId}`, {
      method: 'DELETE',
      data: productHashId,
    });

    return {
      data: true,
    };
  } catch (error) {
    return {
      data: null,
      error: 'Error delete Promo Code By hashId',
    };
  }
};
