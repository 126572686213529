import React, { FC, useState } from 'react';

import {
  Box,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  makeStyles,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Controller, FieldErrors, UseControllerProps, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { EMAIL_REGEX } from '../../../../../../../helpers/constants/regex-pattern';
import usePrivilege from '../../../../../../../hooks/usePrivilege';
import {
  InterviewLogContactBy,
  InterviewLogContactType,
  InterviewLogData,
  InterviewLogDataRequest,
  InterviewLogStatus,
  NoneUserInterviewLogData,
  UserTCImage,
} from '../../../../../../../models/user.model';
import AppButton from '../../../../../../ui/AppButton';
import AppDialog from '../../../../../../ui/AppDialog';
import AppFormControl from '../../../../../../ui/AppFormControl';
import AppFormErrorMessage from '../../../../../../ui/AppFormErrorMessage';
import AppTCFileSelector from '../../../../../../ui/AppTCFileSelector';
import ImagePreview from '../../../../../common/ImagePreview';

export type InterviewLogItemDialogProps = {
  interviewLogItem: InterviewLogData | NoneUserInterviewLogData;
  title: string;
  open: boolean;
  onClose: () => void;
  onCloseClick?: () => void;
  onFormSubmit: (data: InterviewLogDataRequest) => void;
  rules?: { [x: string]: UseControllerProps['rules'] };
  type?: string;
};

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  numberInput: {
    MozAppearance: 'none',
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
  confirmDialogBox: {
    width: '100%',
    maxWidth: '1000px',
    [theme.breakpoints.up('md')]: {
      minWidth: '400px',
    },
  },
  buttonGroups: {
    '& > *': {
      margin: `0 ${theme.spacing(1)}px`,
    },
  },
  productName: {
    width: '200px',
  },
}));

const contactByList = [
  {
    label: 'Email',
    value: InterviewLogContactBy.Email,
  },
  {
    label: 'Line',
    value: InterviewLogContactBy.Line,
  },
  {
    label: 'Facebook',
    value: InterviewLogContactBy.Facebook,
  },
  {
    label: 'Chat',
    value: InterviewLogContactBy.Chat,
  },
  {
    label: 'Calling',
    value: InterviewLogContactBy.Calling,
  },
  {
    label: 'System',
    value: InterviewLogContactBy.System,
  },
  {
    label: 'LineQA',
    value: InterviewLogContactBy.LineQA,
  },
];

const contactTypeList = [
  {
    label: 'interview-log:contactType.WEBSITE',
    value: InterviewLogContactType.Website,
  },
  {
    label: 'interview-log:contactType.COMPLAIN',
    value: InterviewLogContactType.Complain,
  },
  {
    label: 'interview-log:contactType.PURCHASE_MEMBERSHIP',
    value: InterviewLogContactType.PurchaseMembership,
  },
  {
    label: 'interview-log:contactType.PURCHASE_COIN',
    value: InterviewLogContactType.PurchaseMemberCoin,
  },
  {
    label: 'interview-log:contactType.PROBLEM_WITH_COIN',
    value: InterviewLogContactType.ProblemWithCoin,
  },
  {
    label: 'interview-log:contactType.NOTIFICATION_EMAIL_UN_SUBSCRIPTION',
    value: InterviewLogContactType.NotificationEmailUnSubscription,
  },
  {
    label: 'interview-log:contactType.REMOVE_ACCOUNT',
    value: InterviewLogContactType.RemoveAccount,
  },
  {
    label: 'interview-log:contactType.TENT_VISIT',
    value: InterviewLogContactType.TentVisit,
  },
  {
    label: 'interview-log:contactType.SPECIAL_ORDER',
    value: InterviewLogContactType.SpecialOrder,
  },
  {
    label: 'interview-log:contactType.REMARK_V2',
    value: InterviewLogContactType.RemarkV2,
  },
  {
    label: 'interview-log:contactType.USER_STATUS',
    value: InterviewLogContactType.UserStatus,
  },
  {
    label: 'interview-log:contactType.REGISTER_SELLER',
    value: InterviewLogContactType.RegisterSeller,
  },
  {
    label: 'interview-log:contactType.DUPLICATE_NATIONAL_ID',
    value: InterviewLogContactType.NationalIdDuplicated,
  },
  {
    label: 'interview-log:contactType.ESTIMATE_PRICE',
    value: InterviewLogContactType.EstimatePrice,
  },
  {
    label: 'interview-log:contactType.UNDERSTAND_T2H_ARE_SELLER',
    value: InterviewLogContactType.UnderstandT2hAreSeller,
  },
  {
    label: 'interview-log:contactType.FINANCE_CHECK',
    value: InterviewLogContactType.FinanceCheck,
  },
  {
    label: 'interview-log:contactType.CONSIGNMENT',
    value: InterviewLogContactType.Consignment,
  },
  {
    label: 'interview-log:contactType.WANT_TO_BUY',
    value: InterviewLogContactType.WantToBuy,
  },
  {
    label: 'interview-log:contactType.WANT_TO_SELL',
    value: InterviewLogContactType.WantToSell,
  },
  {
    label: 'interview-log:contactType.WANT_TO_PAWN',
    value: InterviewLogContactType.WantToPawn,
  },
  {
    label: 'interview-log:contactType.OTHER',
    value: InterviewLogContactType.Other,
  },
];

const statusList = [
  {
    label: 'interview-log:status.NOTIFY_DEVELOPER',
    value: InterviewLogStatus.NotifyDeveloper,
  },
  {
    label: 'interview-log:status.NOTIFY_SALE',
    value: InterviewLogStatus.NotifySale,
  },
  {
    label: 'interview-log:status.DONE',
    value: InterviewLogStatus.Done,
  },
];

const InterviewLogItemDialog: FC<InterviewLogItemDialogProps> = (props) => {
  const classes = useStyles();
  const { interviewLogItem, open, title, rules, onClose, onCloseClick, onFormSubmit, type } = props;
  const { t } = useTranslation();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);
  const getRules = (fieldName: string): UseControllerProps['rules'] | undefined =>
    rules ? rules[fieldName] : undefined;
  const [selectedPreviewImage, setSelectedPreviewImage] = useState<string | undefined>(undefined);

  const { canPerform } = usePrivilege();
  const allowEdit = canPerform('interviewLog', 'update');

  const {
    handleSubmit,
    getValues,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm<InterviewLogDataRequest>({
    mode: 'onChange',
    defaultValues: {
      name: interviewLogItem.name || '',
      email: interviewLogItem.email || '',
      mobilePhone: interviewLogItem.mobilePhone || '',
      contactBy: interviewLogItem.contactBy || '',
      contactType: interviewLogItem.contactType || '',
      status: interviewLogItem.status || '',
      comment: interviewLogItem.comment || '',
      otherFiles: interviewLogItem.otherFiles || [],
    },
  });

  const otherFilesData = watch('otherFiles')?.find((item) => item.fileHashId === '');

  const onSaveClick = () => {
    setIsConfirmDialogOpen(true);
  };

  const onInvalid = (fieldErrors: FieldErrors<InterviewLogDataRequest>) => {
    const fieldErrorsList = (Object.keys(fieldErrors) || []).reverse();

    fieldErrorsList.forEach((item) => {
      const fieldErrorEl = document.getElementById(item);
      fieldErrorEl?.scrollIntoView();
      fieldErrorEl?.focus();
    });
  };

  const onConfirmationSaveClick = () => {
    setIsConfirmDialogOpen(false);

    if (typeof onFormSubmit === 'function') {
      onFormSubmit(getValues());
    }
  };

  const onConfirmationCancelClick = () => {
    setIsConfirmDialogOpen(false);
  };

  const onThumbnailClicked = (imageUrl: string) => {
    if (imageUrl) {
      setSelectedPreviewImage(imageUrl);
    }
  };

  const imageSlideData = getValues('otherFiles')?.map((item) => item.fullUrl);

  return (
    <>
      <AppDialog
        open={open}
        title={title}
        onClose={onClose}
        okButtonText="common:button.close"
        okButtonColor="default"
        okButtonVariant="outlined"
        closeOnOkClicked
        noActionBar
        dialogProps={{
          fullWidth: true,
          maxWidth: 'md',
          disableBackdropClick: true,
        }}>
        <form onSubmit={handleSubmit(onSaveClick, onInvalid)}>
          <Box>
            {type === 'anonymous-user' && (
              <Grid container spacing={2} style={{ marginBottom: '12px' }}>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="name"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={(data) => (
                      <AppFormControl error={!!errors.name}>
                        <InputLabel htmlFor="interview-user-name">{t('interview-log:fields.name')}</InputLabel>
                        <OutlinedInput
                          {...data.field}
                          id="interview-user-name"
                          fullWidth
                          error={!!errors.name}
                          disabled={!allowEdit}
                        />
                      </AppFormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: false,
                      pattern: {
                        value: EMAIL_REGEX,
                        message: t('validation:invalidFieldFormat', {
                          fieldName: t('user:common.fields.email').toString(),
                        }),
                      },
                    }}
                    render={(data) => (
                      <AppFormControl error={!!errors.email}>
                        <InputLabel htmlFor="interview-user-email">{t('interview-log:fields.email')}</InputLabel>
                        <OutlinedInput
                          {...data.field}
                          id="interview-user-email"
                          fullWidth
                          error={!!errors.email}
                          disabled={!allowEdit}
                        />
                        {!!errors.email && (
                          <FormHelperText error>
                            {errors.email.type &&
                              t('validation:invalidFieldFormat', {
                                fieldName: t('user:common.fields.email').toString(),
                              })}
                          </FormHelperText>
                        )}
                      </AppFormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="mobilePhone"
                    control={control}
                    rules={{
                      required: false,
                      maxLength: 10,
                    }}
                    render={(data) => (
                      <AppFormControl error={!!errors.mobilePhone}>
                        <InputLabel htmlFor="interview-user-mobilePhone">{t('interview-log:fields.mobile')}</InputLabel>
                        <OutlinedInput
                          {...data.field}
                          id="interview-user-mobilePhone"
                          fullWidth
                          type="number"
                          error={!!errors.mobilePhone}
                          disabled={!allowEdit}
                        />
                        {!!errors.mobilePhone && (
                          <FormHelperText error>
                            {(errors.mobilePhone.type === 'validate' || errors.mobilePhone.type === 'maxLength') &&
                              t('validation:invalidFieldFormat', {
                                fieldName: t('user:common.fields.mobile').toString(),
                                suggestion: t('user:error.detail.mobileFormat').toString(),
                              })}
                          </FormHelperText>
                        )}
                      </AppFormControl>
                    )}
                  />
                </Grid>
              </Grid>
            )}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="contactBy"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  defaultValue={interviewLogItem.contactBy}
                  render={({ field }) => (
                    <AppFormControl error={!!errors.contactBy}>
                      <InputLabel htmlFor="contactBy">{t('interview-log:fields.contactBy')}</InputLabel>
                      <Select id="contactBy" {...field} variant="outlined" fullWidth>
                        {contactByList.map((item) => (
                          <MenuItem value={item.value} key={item.value}>
                            {t(item.label)}
                          </MenuItem>
                        ))}
                      </Select>
                    </AppFormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="contactType"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  defaultValue={interviewLogItem.contactType}
                  render={({ field }) => (
                    <AppFormControl error={!!errors.contactType}>
                      <InputLabel htmlFor="contactType">{t('interview-log:fields.contactType')}</InputLabel>
                      <Select id="contactType" {...field} variant="outlined" fullWidth>
                        {contactTypeList.map((item) => (
                          <MenuItem value={item.value} key={item.value}>
                            {t(item.label)}
                          </MenuItem>
                        ))}
                      </Select>
                    </AppFormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="status"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  defaultValue={interviewLogItem.status}
                  render={({ field }) => (
                    <AppFormControl error={!!errors.status}>
                      <InputLabel htmlFor="status">{t('interview-log:fields.status')}</InputLabel>
                      <Select id="status" {...field} variant="outlined" fullWidth>
                        {statusList.map((item) => (
                          <MenuItem value={item.value} key={item.value}>
                            {t(item.label)}
                          </MenuItem>
                        ))}
                      </Select>
                    </AppFormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <Controller
                    name="comment"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <AppFormControl error={!!errors.comment}>
                        <InputLabel htmlFor="comment">{t('interview-log:fields.comment')}</InputLabel>
                        <OutlinedInput {...field} multiline rows={2} id="comment" fullWidth />
                      </AppFormControl>
                    )}
                  />
                  <Box mt={1}>
                    <Typography variant="subtitle2">{t('interview-log:message.description')}</Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box mb={3}>
                  <Controller
                    control={control}
                    name="otherFiles"
                    render={(data) => {
                      const fields = data.field.value || [];

                      return (
                        <>
                          {fields.length > 0 && (
                            <Grid container spacing={2}>
                              {fields.map((item, index: number) => {
                                const rowKey = `other-file-item-${item.fileHashId}`;

                                return (
                                  <Grid item xs={12} sm={6} md={4} key={rowKey}>
                                    <Box style={{ display: 'flex', justifyContent: 'space-between' }} mb={2}>
                                      <Typography>{`${t('interview-log:fields.otherFilesInterviewLog')} ${
                                        index + 1
                                      }`}</Typography>
                                      <DeleteForeverIcon
                                        color="error"
                                        onClick={() => {
                                          data.field.onChange(fields.filter((i, idx) => idx !== index));
                                        }}
                                        type="button"
                                      />
                                    </Box>

                                    <Controller
                                      name={`otherFiles.${index}`}
                                      control={control}
                                      rules={getRules('other') || {}}
                                      render={({ field }) => (
                                        <AppFormControl error={!!errors.otherFiles}>
                                          <AppTCFileSelector
                                            defaultFile={field.value}
                                            fileMode="image"
                                            inputProps={{
                                              label: t('interview-log:fields.otherFilesInterviewLog').toString(),
                                              error: !!errors.otherFiles,
                                            }}
                                            onFilesSelected={(file) => {
                                              if (file) {
                                                field.onChange({
                                                  fileHashId: file.fileHashId,
                                                  fullUrl: file.fullUrl,
                                                });
                                              }
                                            }}
                                            onValueCleared={() => field.onChange('')}
                                            imageType={UserTCImage.other}
                                            onImageClick={onThumbnailClicked}
                                          />
                                          {!!errors.otherFiles && (
                                            <AppFormErrorMessage errors={errors} name="otherFiles" />
                                          )}
                                        </AppFormControl>
                                      )}
                                    />
                                  </Grid>
                                );
                              })}
                            </Grid>
                          )}
                        </>
                      );
                    }}
                  />
                </Box>
                <Box>
                  <AppButton
                    variant="outlined"
                    color="primary"
                    startIcon={<AddIcon />}
                    disabled={!!otherFilesData}
                    onClick={() => {
                      const currentOtherFiles = getValues('otherFiles') || [];
                      setValue(
                        'otherFiles',
                        [
                          ...currentOtherFiles,
                          {
                            fileHashId: '' as string,
                            fullUrl: '' as string,
                          },
                        ],
                        { shouldValidate: true },
                      );
                    }}>
                    {t(`interview-log:button.addOtherFile`)}
                  </AppButton>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box display="flex" my={2} justifyContent="flex-end" className={classes.buttonGroups}>
            <AppButton type="submit" color="primary">
              {t('common:button.save')}
            </AppButton>
            <AppButton
              variant="outlined"
              color="default"
              onClick={() => {
                if (typeof onCloseClick === 'function') {
                  onCloseClick();
                }
              }}>
              {t('common:button.close')}
            </AppButton>
          </Box>
        </form>

        <AppDialog
          title={t('common:dialog.title.confirm').toString()}
          open={isConfirmDialogOpen}
          onOkClick={onConfirmationSaveClick}
          onCancelClick={onConfirmationCancelClick}
          cancelButtonText={t('common:button.cancel').toString()}
          okButtonText={t('common:button.save').toString()}
          okButtonColor="primary"
          dialogProps={{
            maxWidth: 'md',
          }}>
          <Box className={classes.confirmDialogBox}>
            <Typography>{t('interview-log:dialogEdit.title')}</Typography>
          </Box>
        </AppDialog>

        <ImagePreview
          imageUrl={selectedPreviewImage}
          onCloseClick={() => setSelectedPreviewImage(undefined)}
          asModal
          isPreviewSlide
          imageSlideData={imageSlideData}
        />
      </AppDialog>
    </>
  );
};

export default InterviewLogItemDialog;
