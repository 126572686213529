import React, { FC, useMemo, useState } from 'react';

import { Box, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { getProvinceName } from '../../../../../helpers/utils';
import { UserDataTC } from '../../../../../models';
import DataBlock from '../../../common/DataBlock';
import ImagePreview from '../../../common/ImagePreview';
import ImageThumbnail from '../../../common/ImageThumbnail';

export interface ShopInfoProps {
  currentUser: UserDataTC;
}

const ShopInfo: FC<ShopInfoProps> = (props) => {
  const { t } = useTranslation();
  const { currentUser } = props;

  const [selectedPreviewImage, setSelectedPreviewImage] = useState<string | undefined>(undefined);

  const onThumbnailClicked = (imageUrl: string) => {
    if (imageUrl) {
      setSelectedPreviewImage(imageUrl);
    }
  };

  const productTypeItem = useMemo(
    () => (currentUser.shopProductType ? currentUser.shopProductType.join(', ') : '-'),
    [currentUser.shopProductType],
  );

  const imageSlideData = [currentUser.shopLocationImage?.fullUrl, currentUser.shopHeroImage?.fullUrl];

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <DataBlock label="user:common.fields.shopLocationLatitude" data={currentUser.shopLocationLatitude} />
          <DataBlock label="user:common.fields.shopLocationLongitude" data={currentUser.shopLocationLongitude} />
          <DataBlock label="user:common.fields.shopName" data={currentUser.shopName} />
          <DataBlock label="user:common.fields.shopProductType" data={productTypeItem} noWrapText={false} />
          <DataBlock
            label="user:common.fields.shopType"
            data={currentUser.shopType ? t(`user:details.shopType.${currentUser.shopType}`) : '-'}
          />
          <DataBlock label="user:common.fields.shopDescription" data={currentUser.shopDescription} noWrapText={false} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DataBlock label="user:common.fields.shopAddress" data={currentUser.shopAddress} noWrapText={false} />
          <DataBlock label="user:common.fields.district" data={currentUser.shopDistrict} />
          <DataBlock label="user:common.fields.province" data={getProvinceName(currentUser.shopProvince)} />
          <DataBlock label="user:common.fields.zipcode" data={currentUser.shopZipcode} />
          {currentUser.shopOtherTelephoneNumbers && currentUser.shopOtherTelephoneNumbers !== null ? (
            <DataBlock
              label="user:common.fields.shopOtherTelephoneNumbers"
              data={currentUser.shopOtherTelephoneNumbers.map((item, index: number) => {
                const rowKey = `thumbnail-${index}`;
                return (
                  <Box key={rowKey}>
                    <Typography>
                      {item.name} : {item.tel}
                    </Typography>
                  </Box>
                );
              })}
            />
          ) : (
            <DataBlock label="user:common.fields.shopOtherTelephoneNumbers" data="-" />
          )}
        </Grid>
      </Grid>
      <Box my={3}>
        <Grid container spacing={2}>
          {currentUser.shopLocationImage && (
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <ImageThumbnail
                listingHashId={currentUser.userHashId}
                imageUrl={currentUser.shopLocationImage?.fullUrl}
                altText="shop location file"
                thumbnailLabel={t('user:common.fields.shopLocationImageUrl')}
                imageClick={onThumbnailClicked}
              />
            </Grid>
          )}
          {currentUser.shopHeroImage && (
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <ImageThumbnail
                listingHashId={currentUser.userHashId}
                imageUrl={currentUser.shopHeroImage.fullUrl}
                altText="Shop Hero file"
                thumbnailLabel={t('user:common.fields.shopHeroImageUrl')}
                imageClick={onThumbnailClicked}
              />
            </Grid>
          )}
        </Grid>
      </Box>

      <ImagePreview
        imageUrl={selectedPreviewImage}
        onCloseClick={() => setSelectedPreviewImage(undefined)}
        asModal
        isPreviewSlide
        imageSlideData={imageSlideData}
      />
    </>
  );
};

export default ShopInfo;
