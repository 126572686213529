import React, { FC, useEffect, useState } from 'react';

import { Box, Grid, Paper, TableRow, Typography } from '@material-ui/core';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { AppTableConditions, TableColumn } from '../../../../models';
import { getS3TdFile } from '../../../../services/s3-td';
import useAppTableStyles from '../../../../theme/table.style';
import useTypographyStyles from '../../../../theme/typography.style';
import AppTableCell from '../../../ui/AppTableCell';
import AppTableList from '../../../ui/AppTableList';

const dashboardRowsPerPage = 10;
const popularList = {
  count: '',
  tag: '',
  lastexported: '',
  time: '',
  type: '',
};

const monthlyPopularTagList = { ...popularList };

const popularTagTableColumns: TableColumn[] = [
  { name: 'tag', label: 'dashboard:list.columns.tag', width: 230 },
  { name: 'tagcount', label: 'dashboard:list.columns.tagcount', width: 140 },
];

const DashboardSnsTag: FC = () => {
  const { t } = useTranslation();
  const tableClasses = useAppTableStyles();
  const typoClasses = useTypographyStyles();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [tagList, setTagList] = useState<any>(monthlyPopularTagList);
  const [totalTagCount, setTotalTagCount] = useState<number>(0);
  const [tagConditions, setTagConditions] = useState<Partial<AppTableConditions>>({
    page: 0,
    rowsPerPage: dashboardRowsPerPage,
  });

  const requestTopSnsTagList = async () => {
    try {
      const result = await getS3TdFile(encodeURIComponent('top-sns-tag.csv'));
      if (result.data) {
        setTotalTagCount(result.data.data.length);
        const sliceStart = (tagConditions?.page || 0) * dashboardRowsPerPage;
        result.data.data = result.data.data.slice(sliceStart, sliceStart + dashboardRowsPerPage);

        const resultMapData =
          result.data.data.length > 0
            ? result.data.data.map((item) => ({
                ...item,
                tag: item.tag
                  .replace(/[\n\t]/gi, '')
                  .replace(/""/gi, '"')
                  .replace(/""/gi, '"'),
              }))
            : [];

        setTagList(resultMapData);
      }
    } catch (error) {
      setTagList([]);
      setTotalTagCount(0);
    }
  };

  useEffect(() => {
    requestTopSnsTagList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onTagConditionChangedHandler = (values: AppTableConditions) => {
    if (JSON.stringify(values) !== JSON.stringify(tagConditions)) {
      setTagConditions(values);
    }
  };

  useDeepCompareEffect(() => {
    requestTopSnsTagList();
  }, [tagConditions]);

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs="auto" md={6}>
        <Paper>
          <Box mx={3} pt={2} mt={3}>
            <Typography variant="h6" className={typoClasses.textWeightBold}>
              {t('dashboard:title.monthlyTag')}
            </Typography>
            {tagList.length > 0 && (
              <Typography variant="subtitle2">
                {t('dashboard:label.lastExport')}
                {dayjs.unix(tagList[0].time).format('DD MMM YY HH:mm')}
              </Typography>
            )}
          </Box>

          <AppTableList
            {...tagConditions}
            onTableConditionChanged={onTagConditionChangedHandler}
            aria-label="popular-tag-list"
            totalDataCount={totalTagCount}
            rowsPerPageOptions={[dashboardRowsPerPage]}
            columns={popularTagTableColumns}
            isFixedHeightAuto
            tableBodyContent={
              tagList.length > 0 && (
                <>
                  {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                  {tagList.map((item: any) => (
                    <TableRow
                      key={item.tag}
                      classes={{ root: clsx(tableClasses.tableRow, tableClasses.clickableTableRow) }}>
                      <AppTableCell>{item.tag}</AppTableCell>
                      <AppTableCell>{item.count}</AppTableCell>
                    </TableRow>
                  ))}
                </>
              )
            }
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default DashboardSnsTag;
