/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/prefer-default-export */

import { apiRequest } from '../helpers/request';
import { queryParamGenerator } from '../helpers/utils';
import {
  PaymentHistoryRequest,
  PaymentHistory,
  ApiResponse,
  PaymentHistorySearchFilters,
  GetPaymentHistoryListResponse,
} from '../models';

const getPaymentHistoryQueryParam = (param?: Partial<PaymentHistorySearchFilters>): string => {
  const reqParam: { [x: string]: string | number } = {
    page: (param?.page || 0) + 1, // plus 1 for index shift between FE component and backend query
    per_page: param?.rowsPerPage || 10,
    sort: param?.sortColumn ? `${param.sortColumn}|${(param?.sortDirection || 'desc').toUpperCase()}` : '',
  };

  // Only search with keyword when it comes with the selected column to be searched
  if (param?.searchKeyword && param?.searchField) {
    reqParam[param.searchField] = param.searchKeyword.trim();
  }
  if (param?.filteringPaymentMethod?.length) {
    reqParam.status = param.filteringPaymentMethod.join(',');
  }
  return queryParamGenerator(reqParam);
};

export const getMembershipProducts = async (): Promise<any[]> => {
  try {
    const result = await apiRequest<any[]>('/payments/products');

    return result.data ? result.data.filter((product) => product.type === 'membership') : [];
  } catch (e) {
    return [];
  }
};

export const createPaymentHistory = async (data: PaymentHistoryRequest): Promise<PaymentHistory[] | undefined> => {
  try {
    const result = await apiRequest<PaymentHistory[]>(`/payments/payment-history`, {
      method: 'POST',
      data: { ...data },
    });

    return result.data;
  } catch (e) {
    console.log('e', e);
    return Promise.reject(e);
  }
};

export const getPaymentHistoryByUserHashId = async (
  hashId: string,
  param?: Partial<PaymentHistorySearchFilters>,
): Promise<ApiResponse<GetPaymentHistoryListResponse>> => {
  try {
    const queryParamString = getPaymentHistoryQueryParam(param);
    const result = await apiRequest<PaymentHistory[]>(`/admin/users/${hashId}/payment-history?${queryParamString}`, {
      method: 'GET',
    });

    return {
      data: {
        data: result.data,
        totalCount: Number(result.headers['x-total-count'] || '0') || result.data.length,
      },
    };
  } catch (e) {
    return {
      data: null,
      error: 'payment-history:snackbar.error',
    };
  }
};
