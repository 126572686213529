import BlogSlugListPage from './BlogSlugList';
import { RouteConfig } from '../../models/route.model';

const routes = (path: string): RouteConfig[] => [
  {
    key: 'BLOG_SLUG',
    path,
    component: BlogSlugListPage,
    exact: true,
    title: 'จัดการ Blog Slug',
  },
];

export default routes;
