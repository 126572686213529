import React, { FC } from 'react';

import masterDataRoutes from './route.config';
import ModuleMainPage from '../../components/templates/ModuleMainPage';

const MasterDataMain: FC = () => (
  <ModuleMainPage pageTitle="common:title.masterData" routeFunctions={masterDataRoutes} />
);

export default MasterDataMain;
