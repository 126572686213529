import React, { FC, ReactNode, useEffect, useState } from 'react';

import {
  Box,
  makeStyles,
  SortDirection,
  TableBody,
  TableCell,
  TableContainerProps,
  TableHead,
  TablePagination,
  TableProps,
  TableRow,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { defaultRowsPerPage, tableRowPerPageList } from '../../../helpers/constants';
import { AppTableConditions, TableColumn } from '../../../models';
import useAppTableStyles from '../../../theme/table.style';
import AppCircularProgress from '../../partials/AppCircularProgress';
import AppNoTableData from '../../partials/AppNoTableData';
import AppSortableTableHead from '../AppSortableTableHead';
import AppTable from '../AppTable';

function getMaxPage(numOfItems: number, perPage: number): number {
  const maxPage = Math.floor(numOfItems / perPage);

  return numOfItems % perPage > 0 ? maxPage + 1 : maxPage;
}

export type AppTableListProps = TableProps &
  Partial<AppTableConditions> & {
    // columns to be rendered as table header. Format: array of TableColumn type
    columns?: TableColumn[];
    // Total data number. This number is regardless to any pagination condition, just the total number of data that came from query
    totalDataCount?: number;
    // Tbody to be rendered in table, developer needs to handle it themselves
    tableBodyContent: ReactNode;
    // Indicate that this table should have pagination or not. When set to true, page and rowsPerPage will be sent upon change
    hasPagination?: boolean;
    // Indicate the table loading status
    isLoading?: boolean;
    // Table conditions change event handler (when page, rowsPerPage, sortColumn or sortDirection changed)
    onTableConditionChanged?: (condition: AppTableConditions) => void;
    tableContainerProps?: TableContainerProps;
    disableRowsLabel?: boolean;
    rowsPerPageOptions?: number[];
    isFixedHeightAuto?: boolean;
  };

const useStyles = makeStyles((theme) => ({
  tableInfoContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2,
    backgroundColor: theme.palette.background.paper,
  },
  loadingTable: {
    visibility: 'hidden',
    opacity: 0,
  },
}));

const AppTableList: FC<AppTableListProps> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const tableClasses = useAppTableStyles();
  const {
    page = 0,
    rowsPerPage = defaultRowsPerPage,
    sortColumn = '',
    sortDirection = 'asc',
    tableBodyContent,
    onTableConditionChanged,
    columns = [],
    totalDataCount = 0,
    hasPagination = true,
    isLoading = false,
    tableContainerProps,
    disableRowsLabel,
    rowsPerPageOptions,
    isFixedHeightAuto = false,
    ...rest
  } = props;

  const [currentPage, setCurrentPage] = useState(page || 0);
  const [currentRowsPerPage, setCurrentRowsPerPage] = useState(rowsPerPage || defaultRowsPerPage);
  const [currentSortColumn, setCurrentSortColumn] = useState<string>(sortColumn || '');
  const [currentSortDirection, setCurrentSortDirection] = useState<SortDirection>(sortDirection || 'asc');
  const [isTableLoading, setIsTableLoading] = useState(isLoading);
  const isSortable = Boolean(sortColumn);

  useEffect(() => {
    if (page !== currentPage) {
      setCurrentPage(page || 0);
    }
    if (rowsPerPage !== currentRowsPerPage) {
      setCurrentRowsPerPage(rowsPerPage || defaultRowsPerPage);
    }
    if (sortColumn !== currentSortColumn) {
      setCurrentSortColumn(sortColumn);
    }
    if (sortDirection !== currentSortDirection) {
      setCurrentSortDirection(sortDirection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, sortColumn, sortDirection]);

  useEffect(() => {
    if (typeof onTableConditionChanged === 'function') {
      onTableConditionChanged({
        page: currentPage,
        rowsPerPage: currentRowsPerPage,
        sortColumn: currentSortColumn,
        sortDirection: currentSortDirection,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, currentRowsPerPage, currentSortColumn, currentSortDirection]);

  useEffect(() => {
    if (isTableLoading && !isLoading) {
      window.scrollTo(0, 0);
    }
    setIsTableLoading(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const onPageChanged = (e: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setCurrentPage(newPage);
  };

  const onRowsPerPageChanged = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setCurrentRowsPerPage(parseInt(e.target.value, 10));
    setCurrentPage(0);
  };

  const onColumnSortingChanged = (columnName: string, direction: SortDirection) => {
    setCurrentSortColumn(columnName);
    setCurrentSortDirection(direction as SortDirection);
  };

  const TableListPagination = () => {
    /**
     * Note that the Pagination page prop starts at 1 to match the requirement of including
     * the value in the URL, while the TablePagination page prop starts at 0 to match the requirement
     * of zero-based JavaScript arrays that comes with rendering a lot of tabular data.
     *
     * Ref: https://mui.com/material-ui/react-pagination/
     */
    const pageForPaginator = page + 1;
    const lastPage = getMaxPage(totalDataCount, currentRowsPerPage);

    return (
      <Box display="flex" flexDirection="column" alignItems="center" py={2}>
        <Pagination
          count={lastPage}
          page={pageForPaginator}
          variant="outlined"
          shape="rounded"
          onChange={(e, newPage) =>
            (onPageChanged as (event: React.ChangeEvent<unknown>, page: number) => void)(e, newPage - 1)
          }
        />
        <Box width="100%">
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions || tableRowPerPageList}
            component="div"
            count={totalDataCount}
            rowsPerPage={currentRowsPerPage}
            page={totalDataCount > 0 ? currentPage : 0}
            onChangePage={onPageChanged}
            onChangeRowsPerPage={onRowsPerPageChanged}
            classes={{ root: tableClasses.tablePagination }}
            className={isLoading ? classes.loadingTable : ''}
            labelDisplayedRows={({ from, to, count }) =>
              disableRowsLabel ? `${from}-${to} of ${count !== -1 ? count : '1'}` : null
            }
          />
        </Box>
      </Box>
    );
  };

  const TableListHeader = () => {
    if (isSortable) {
      return (
        <AppSortableTableHead
          columns={columns}
          orderBy={currentSortColumn}
          orderDirection={currentSortDirection}
          onSortChanged={onColumnSortingChanged}
        />
      );
    }

    return (
      <>
        <colgroup>
          {columns.map((column) => (
            <col key={column.name} width={column.width || 'auto'} />
          ))}
        </colgroup>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.name} className={tableClasses.headerCell}>
                {t(column.label)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      </>
    );
  };

  const TableInfoDisplay: FC = ({ children }) => (
    <Box component="div" className={classes.tableInfoContainer}>
      {children}
    </Box>
  );

  return (
    <>
      <Box component="div" position="relative">
        <AppTable
          isFixedHeightAuto={isFixedHeightAuto}
          stickyHeader
          layoutFixed
          className={clsx(rest.className, {
            [classes.loadingTable]: isTableLoading,
          })}
          tableContainerProps={tableContainerProps}
          tableContent={
            <>
              {!!columns?.length && <TableListHeader />}
              <TableBody>
                {tableBodyContent}
                {!isTableLoading && totalDataCount === 0 && (
                  <tr>
                    <td colSpan={columns?.length}>
                      <AppNoTableData />
                    </td>
                  </tr>
                )}
              </TableBody>
            </>
          }
          pagination={hasPagination && totalDataCount !== 0 ? <TableListPagination /> : null}
          {...rest}
        />
        {isTableLoading && (
          <TableInfoDisplay>
            <AppCircularProgress />
          </TableInfoDisplay>
        )}
      </Box>
    </>
  );
};

export default AppTableList;
