import React, { FC, useContext, useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import useDeepCompareEffect from 'use-deep-compare-effect';

import ListingSearchForm from '../../components/partials/listing/ListingSearchForm';
import ListingTable from '../../components/partials/listing/ListingTable';
import { AppGlobalUiContext } from '../../context/AppGlobalUiContext';
import { SearchFilterContext } from '../../context/SearchFilterContext';
import { tableRowPerPageList } from '../../helpers/constants';
import { AppTableConditions } from '../../models';
import { ListingItemData, ListingSearchFormData, ListingSearchParams } from '../../models/listing.model';
import { getListing } from '../../services/listing';

type ListingPageProps = unknown;

const searchFilterContextKey = 'listing';

const ListingList: FC<ListingPageProps> = () => {
  const { t } = useTranslation();
  const { showSnackbar, setAppBarBackButtonUrl } = useContext(AppGlobalUiContext);
  const [listing, setListing] = useState<ListingItemData[]>([]);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [isSortingPrice, setIsSortingPrice] = useState<boolean>(false);
  const [isTypeDesc, setIsTypeDesc] = useState<boolean>(true);

  const urlParams = new URLSearchParams(window.location.search);
  const initialSearchFilter: Partial<ListingSearchFormData> = {};
  if (urlParams.get('status')) {
    initialSearchFilter.filteringStatuses = (urlParams.get('status') as string)
      .split(',')
      .map((value) => decodeURIComponent(value));
  }
  if (urlParams.get('searchField')) {
    initialSearchFilter.searchField = decodeURIComponent(urlParams.get('searchField') as string);
  }
  if (urlParams.get('searchKeyword')) {
    initialSearchFilter.searchKeyword = decodeURIComponent(urlParams.get('searchKeyword') as string);
  }

  // start filling default search filters (either from the one we saved in context or fresh conditions)
  const { currentFilters: ctxFilters, setFilters } = useContext(SearchFilterContext);
  const currentFilters: ListingSearchParams = ctxFilters ? ctxFilters[searchFilterContextKey] : undefined;
  const [searchFilters, setSearchFilters] = useState<ListingSearchFormData>({
    searchField: initialSearchFilter?.searchField || 'userHashIDs',
    searchKeyword: initialSearchFilter?.searchKeyword || '',
    categoryQuery: initialSearchFilter?.categoryQuery || '',
    brandQuery: initialSearchFilter?.brandQuery || '',
    modelQuery: initialSearchFilter?.modelQuery || '',
    filteringStatuses: initialSearchFilter?.filteringStatuses || [],
    filteringPossessions: initialSearchFilter?.filteringPossessions || [],
    lowerFirstRegistrationYear: initialSearchFilter?.lowerFirstRegistrationYear || '',
    upperFirstRegistrationYear: initialSearchFilter?.upperFirstRegistrationYear || '',
    lowerBoundPrice: initialSearchFilter?.lowerBoundPrice || '',
    upperBoundPrice: initialSearchFilter?.upperBoundPrice || '',
  });
  const defaultRowsPerPage = tableRowPerPageList[0];
  const [tableConditions, setTableConditions] = useState<AppTableConditions>({
    page: currentFilters?.page || 0,
    rowsPerPage: defaultRowsPerPage,
    sortColumn: currentFilters?.sortColumn || 'updatedAt',
    sortDirection: currentFilters?.sortDirection || 'desc',
  });
  const rowsPerPageOptions = [tableRowPerPageList[0], tableRowPerPageList[1]];

  const getCurrentSearchParam = (): ListingSearchParams => ({
    page: tableConditions.page || 0,
    rowsPerPage: tableConditions.rowsPerPage || defaultRowsPerPage,
    sortColumn: isSortingPrice ? 'markdownPrice' : tableConditions.sortColumn || 'updatedAt',
    sortDirection: isTypeDesc ? tableConditions.sortDirection || 'desc' : 'asc',
    searchField: searchFilters.searchField,
    searchKeyword: searchFilters.searchKeyword,
    categoryQuery: searchFilters.categoryQuery,
    brandQuery: searchFilters.brandQuery,
    modelQuery: searchFilters.modelQuery,
    filteringStatuses: searchFilters.filteringStatuses,
    filteringPossessions: searchFilters.filteringPossessions,
    createdDateFrom: searchFilters.createdDateFrom,
    createdDateTo: searchFilters.createdDateTo,
    lowerFirstRegistrationYear: searchFilters.lowerFirstRegistrationYear,
    upperFirstRegistrationYear: searchFilters.upperFirstRegistrationYear,
    lowerBoundPrice: searchFilters.lowerBoundPrice,
    upperBoundPrice: searchFilters.upperBoundPrice,
  });

  const requestListing = async (isPageSubscribed = true): Promise<boolean> => {
    if (isPageSubscribed) {
      setIsLoadingData(true);

      const searchConditions = getCurrentSearchParam();
      setFilters(searchFilterContextKey, searchConditions);
      const result = await getListing(searchConditions);

      setIsLoadingData(false);

      if (result.data) {
        setListing(result.data.data.items);
        setTotalItems(result.data.totalCount);
        return Promise.resolve(true);
      }

      showSnackbar(t(result.error as string).toString(), 'error');
      return Promise.reject(result.error);
    }

    return false;
  };

  useEffect(() => {
    // Remove back button url upon page entering
    setAppBarBackButtonUrl('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useDeepCompareEffect((): any => {
    let isSubscribed = true;

    requestListing(isSubscribed);

    return () => (isSubscribed = false);
  }, [tableConditions, searchFilters, isTypeDesc]);

  const tableConditionsChangedHandler = (values: AppTableConditions) => {
    setTableConditions(values);
  };

  const onSearchFormSubmit = (filters: ListingSearchFormData) => {
    if (isLoadingData) {
      showSnackbar(t('common.message.pleaseWaitForDataToFinish').toString(), 'warning');
      return;
    }

    if (tableConditions.page !== 0) {
      tableConditions.page = 0;
    }
    setSearchFilters(filters);
  };

  return (
    <>
      <ListingSearchForm
        isLoadingData={isLoadingData}
        currentFilters={searchFilters}
        onSearchFormSubmit={onSearchFormSubmit}
        onLowerPriceClick={() => {
          setIsSortingPrice(true);
          setIsTypeDesc(false);
        }}
        onUpperPriceClick={() => {
          setIsSortingPrice(true);
          setIsTypeDesc(true);
        }}
      />

      <ListingTable
        currentConditions={tableConditions}
        totalItems={totalItems}
        isLoadingData={isLoadingData}
        items={listing}
        onTableConditionsChanged={tableConditionsChangedHandler}
        defaultRowsPerPage={tableConditions.rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        onFetch={() => {
          requestListing();
        }}
      />
    </>
  );
};

export default ListingList;
