import React, { FC, useCallback, useContext, useEffect, useState } from 'react';

import { Box, Typography, makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { AppGlobalUiContext } from '../../../../../context/AppGlobalUiContext';
import { OmiseCreditCardTCResponse, UserDataTC } from '../../../../../models';
import { deleteCreditCardById, getCreditCardByUserHashId } from '../../../../../services/v3/user-omise-credit-card';
import theme from '../../../../../theme';
import useTypographyStyles from '../../../../../theme/typography.style';
import AppDialog from '../../../../ui/AppDialog';
import DataBlock from '../../../common/DataBlock';

export interface PaymentCreditCardProps {
  currentUser: UserDataTC;
}
const useStyles = makeStyles((themeStyle) => ({
  container: {
    padding: theme.spacing(2),
    height: '100vh',
  },
  boxGrey: {
    padding: `${themeStyle.spacing(3)}px`,
    backgroundColor: themeStyle.palette.grey['200'],
    borderRadius: '16px',
    position: 'relative',
  },
  styleIcon: {
    marginBottom: theme.spacing(1),
    background: theme.palette.grey[300],
    width: 'auto',
    display: 'inline-block',
    padding: '16px 16px 10px 16px',
    borderRadius: '50%',
    '& svg': {
      fontSize: theme.typography.pxToRem(40),
      color: theme.palette.grey[500],
    },
  },
  styleIconRemove: {
    background: 'white',
    padding: '2px 4px 0px 4px',
    borderRadius: '50%',
    cursor: 'pointer',
    display: 'block',
    width: 'auto',
  },
  styleBadgeExpire: {
    padding: '0 8px',
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    borderRadius: '4px',
    marginLeft: '8px',
    fontSize: '10px',
    '& span': {
      fontWeight: 'bold',
    },
  },
  cardContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr',
    gridGap: '16px',
    paddingBottom: '20px',
    borderBottom: `solid 1px ${grey[300]}`,
    '&.two-cards': {
      gridTemplateRows: 'repeat(2, 1fr)',
    },
    '&.three-cards': {
      gridTemplateRows: 'repeat(3, 1fr)',
    },
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
      gridTemplateRows: '1fr',
      '&.three-cards': {
        gridTemplateRows: 'repeat(2, 1fr)',
      },
    },
    [theme.breakpoints.up('md')]: {
      paddingBottom: 'none',
      borderBottom: 'none',
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'repeat(2, 1fr)',
      '&.three-cards': {
        gridTemplateRows: 'repeat(3, 1fr)',
      },
    },
  },
}));

const PaymentCreditCard: FC<PaymentCreditCardProps> = (props) => {
  const typoClasses = useTypographyStyles();
  const classes = useStyles();
  const { currentUser } = props;
  const { showSnackbar, setAppLoading } = useContext(AppGlobalUiContext);
  const [creditCardData, setCreditCardData] = useState<OmiseCreditCardTCResponse[]>([]);
  const iconVisa = '../../static/images/icons/icon-credit-visa.jpg';
  const iconMasterCard = '../../static/images/icons/icon-credit-mastercard.jpg';
  const iconJcb = '../../static/images/icons/icon-credit-jcb.jpg';

  const { t } = useTranslation();

  const [selectedCreditCardItem, setSelectedCreditCardItem] = useState<OmiseCreditCardTCResponse | undefined>(
    undefined,
  );
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

  const getCreditCard = useCallback(async () => {
    setAppLoading(true);
    const result = await getCreditCardByUserHashId(currentUser.userHashId);

    if (result) {
      setAppLoading(false);
      setCreditCardData(result);
    } else {
      setAppLoading(false);
    }
  }, [currentUser.userHashId, setAppLoading]);

  useEffect(() => {
    getCreditCard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDeleteConfirm = useCallback(async () => {
    if (selectedCreditCardItem?.id) {
      setAppLoading(true);
      const result = await deleteCreditCardById(currentUser.userHashId, selectedCreditCardItem.id);
      setAppLoading(false);

      if (result.length !== 0) {
        showSnackbar(t('user:payment.message.deleteSuccess').toString(), 'success');
        setIsDeleteConfirmOpen(false);
        getCreditCard();
      } else {
        setIsDeleteConfirmOpen(false);
        showSnackbar(t('user:payment.message.deleteFailed').toString(), 'error');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCreditCardItem?.id, setAppLoading, showSnackbar, t]);

  return (
    <>
      {creditCardData.length > 0 ? (
        <Box>
          <Box>
            <Typography variant="subtitle2">{t('user:payment.remark')}</Typography>
          </Box>
          <Box
            className={clsx(
              classes.cardContainer,
              ...[creditCardData.length === 2 ? 'two-cards' : '', creditCardData.length === 3 ? 'three-cards' : ''],
            )}>
            {creditCardData.map((item) => {
              const rowKey = item.id;
              const currentDate = new Date();
              const expirationDate = new Date(item.expirationYear, item.expirationMonth);
              const isExpired = currentDate > expirationDate;
              return (
                <Box key={rowKey} className={clsx(classes.boxGrey, 'card-item')} mt={1}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                      position: 'absolute',
                      top: '8px',
                      left: '8px',
                    }}>
                    <Box className={classes.styleIconRemove}>
                      <DeleteForeverIcon
                        color="error"
                        onClick={() => {
                          setIsDeleteConfirmOpen(true);
                          setSelectedCreditCardItem(item);
                        }}
                        type="button"
                      />
                    </Box>
                    {isExpired && (
                      <Box className={classes.styleBadgeExpire}>
                        <Typography variant="caption">{t('user:payment.creditCardExpire')}</Typography>
                      </Box>
                    )}
                  </Box>
                  <Box textAlign="right">
                    <Typography>{item.bank}</Typography>
                  </Box>
                  <Box textAlign="center" my={3}>
                    <Typography variant="h6" className={typoClasses.textWeightBold}>
                      xxxx xxxx xxxx {item.lastDigits}
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="end">
                    <Box textAlign="right">
                      <Typography variant="subtitle2" color="textSecondary">
                        {t('user:payment.expirationDate')}
                      </Typography>
                      <Typography>
                        {item.expirationMonth} / {item.expirationYear}
                      </Typography>
                      <Box display="flex" flexDirection="row">
                        <Typography color="textSecondary">{t('user:payment.name')} :</Typography>
                        <Box ml={1}>
                          <Typography className={typoClasses.textWeightBold}>{item.name}</Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box>
                      {item.brand === 'Visa' && <img src={iconVisa} width={60} height={20} alt="Visa" />}
                      {item.brand === 'Mastercard' && (
                        <img src={iconMasterCard} width={45} height={27} alt="Mastercard" />
                      )}
                      {item.brand === 'JCB' && <img src={iconJcb} width={40} height={30} alt="JCB" />}
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" minHeight="400px">
          <Box className={classes.styleIcon}>
            <ListAltOutlinedIcon />
          </Box>
          <Typography>{t('user:payment.noDataCreditCard')}</Typography>
        </Box>
      )}

      <AppDialog
        showCloseButton
        open={isDeleteConfirmOpen}
        title={t('user:payment.dialogRemoveCreditCard.title')}
        okButtonText={t('common:button.confirm')}
        okButtonColor="error"
        okButtonVariant="outlined"
        cancelButtonText={t('common:button.cancel')}
        onClose={() => {
          setIsDeleteConfirmOpen(false);
          setSelectedCreditCardItem(undefined);
        }}
        onOkClick={onDeleteConfirm}
        dialogProps={{
          fullWidth: true,
          maxWidth: 'sm',
          disableBackdropClick: true,
        }}>
        <Box>
          <DataBlock label="user:payment.dialogRemoveCreditCard.nameCreditCard" data={selectedCreditCardItem?.name} />
          <DataBlock label="user:payment.dialogRemoveCreditCard.bank" data={selectedCreditCardItem?.bank} />
          <DataBlock label="user:payment.dialogRemoveCreditCard.brand" data={selectedCreditCardItem?.brand} />
          <DataBlock
            label="user:payment.dialogRemoveCreditCard.lastDigit"
            data={`xxxx xxxx xxxx ${selectedCreditCardItem?.lastDigits}`}
          />
          <DataBlock
            label="user:payment.dialogRemoveCreditCard.expire"
            data={`${selectedCreditCardItem?.expirationMonth} / ${selectedCreditCardItem?.expirationYear}`}
          />
        </Box>
      </AppDialog>
    </>
  );
};

export default PaymentCreditCard;
