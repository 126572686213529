import React, { FC, useCallback, useContext, useEffect, useState } from 'react';

import {
  AppBar,
  AppBarProps,
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { ExitToApp } from '@material-ui/icons';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import MenuIcon from '@material-ui/icons/Menu';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Notification from './notification';
import { AppGlobalUiContext } from '../../context/AppGlobalUiContext';
import { AuthContext } from '../../context/AuthContext';
import { APP_VERSION } from '../../helpers/constants';
import { appSignOut, clearAuthToken } from '../../services';
import AppDialog from '../ui/AppDialog';

export interface AppHeaderProps extends AppBarProps {
  onSidebarMenuClicked: () => void;
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
const useStyles = makeStyles((theme: any) => ({
  appBar: {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarRoot: {
    backgroundColor: theme.customPalette.appHeader.backgroundColor,
  },
  pageTitle: {
    paddingRight: theme.spacing(4),
    fontSize: 18,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  offset: theme.mixins.toolbar,
  logoutMenuItem: { color: theme.palette.error.main },
  menuButton: { marginRight: theme.spacing(2) },
  userMenuButton: {
    cursor: 'pointer',
    width: theme.spacing(4),
    height: theme.spacing(4),
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: theme.palette.common.white,
  },
  adminUserName: {
    textTransform: 'capitalize',
    fontWeight: 500,
  },
}));

const AppHeader: FC<AppHeaderProps> = (props) => {
  const { onSidebarMenuClicked, ...rest } = props;
  const { showSnackbar, appBarTitle, appBarBackButtonUrl } = useContext(AppGlobalUiContext);
  const { authUser } = useContext(AuthContext);
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState<null | HTMLElement>(null);
  const userMenuOpen = Boolean(userMenuAnchorEl);
  const [isTranslationLoaded, setTranslationLoaded] = useState(false);
  const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState(false);
  const [isAboutDialogOpen, setIsAboutDialogOpen] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useEffect((): any => {
    let isSubscribed = true;
    i18n
      .loadNamespaces('auth')
      .then(() => (isSubscribed ? setTranslationLoaded(true) : null))
      .catch(() => (isSubscribed ? showSnackbar(t('error:etc.translationLoadFailed').toString(), 'error') : null));

    return () => (isSubscribed = false);
  });

  const onLogoutSuccess = useCallback(() => {
    clearAuthToken();
    window.location.href = '/login';
  }, []);

  const userMenuHandler = (e: React.MouseEvent<HTMLElement>) => {
    setUserMenuAnchorEl(e.currentTarget);
  };

  const closeUserMenu = () => {
    setUserMenuAnchorEl(null);
  };

  const aboutClickHandler = () => {
    setIsAboutDialogOpen(true);
    closeUserMenu();
  };

  const logoutClickHandler = () => {
    setIsLogoutDialogOpen(true);
    closeUserMenu();
  };

  const logoutDialogCloseHandler = () => {
    setIsLogoutDialogOpen(false);
  };

  const confirmLogoutClickHandler = useCallback(async () => {
    const result = await appSignOut();
    if (result) {
      onLogoutSuccess();
    }
  }, [onLogoutSuccess]);

  const menuIconClickHandler = () => {
    if (typeof onSidebarMenuClicked === 'function') {
      onSidebarMenuClicked();
    }
  };

  const backButtonClickHandler = () => {
    if (appBarBackButtonUrl) {
      history.push(appBarBackButtonUrl);
    }
  };

  return (
    <>
      <AppBar position="fixed" className={classes.appBar} classes={{ root: classes.appBarRoot }} {...rest}>
        <Toolbar variant="dense">
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
            onClick={menuIconClickHandler}>
            <MenuIcon />
          </IconButton>
          {!!appBarBackButtonUrl && (
            <IconButton edge="start" color="inherit" aria-label="go back" onClick={backButtonClickHandler}>
              <ArrowBackIcon />
            </IconButton>
          )}
          {!!appBarTitle && (
            <Typography variant="h6" className={classes.pageTitle}>
              {t(appBarTitle)}
            </Typography>
          )}
          <Box component="div" flexGrow={1} />

          <Notification />

          {!!authUser && (
            <Avatar
              alt={authUser.displayName}
              src={authUser.profileImage?.fullUrl}
              className={classes.userMenuButton}
              onClick={userMenuHandler}
              imgProps={{ referrerPolicy: 'no-referrer' }}
            />
          )}
          <Menu
            id="menu-appbar"
            anchorEl={userMenuAnchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={userMenuOpen}
            onClose={closeUserMenu}>
            <Box component="div" px={2} py={1}>
              <Typography variant="subtitle2" color="textSecondary">
                {t('common:header.welcomeTitle')}
              </Typography>
              <Typography className={classes.adminUserName}>{authUser?.displayName}</Typography>
            </Box>
            <Divider />
            <MenuItem onClick={aboutClickHandler}>
              <ListItemIcon>
                <HelpOutlineIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={t('common:header.aboutMenu')} />
            </MenuItem>
            <Divider />
            <MenuItem onClick={logoutClickHandler} className={classes.logoutMenuItem}>
              <ListItemIcon>
                <ExitToApp fontSize="small" color="error" />
              </ListItemIcon>
              <ListItemText primary={t('common:logout')} />
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      {isTranslationLoaded && (
        <AppDialog
          type="question"
          open={isLogoutDialogOpen}
          title="common:dialog.title.confirm"
          okButtonText="common:logout"
          cancelButtonText="common:button.cancel"
          onClose={logoutDialogCloseHandler}
          onOkClick={confirmLogoutClickHandler}
          okButtonColor="error">
          {t('auth:message.logoutConfirmation')}
        </AppDialog>
      )}

      <AppDialog
        type="info"
        open={isAboutDialogOpen}
        title="common:dialog.title.about"
        okButtonText="common:button.close"
        okButtonColor="default"
        okButtonVariant="outlined"
        closeOnOkClicked
        onClose={() => setIsAboutDialogOpen(false)}
        dialogProps={{
          fullWidth: true,
          maxWidth: 'xs',
        }}>
        <Typography variant="caption">{t('common:about.versionLabel')}</Typography>
        <Typography>{`${process.env.REACT_APP_APP_NAME} ${APP_VERSION}`}</Typography>
      </AppDialog>
    </>
  );
};

export default AppHeader;
