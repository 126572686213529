import React, { FC, useCallback, useContext } from 'react';

import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import AdditionalInfoFormModal from './edit-form/AdditionalInfoFormModal';
import BillingInfoFormModal from './edit-form/BillingInfoFormModal';
import PrivateInfoFormModal from './edit-form/PrivateInfoFormModal';
import ShopFormModal from './edit-form/ShopFormModal';
import EditUserDataModal from './EditUserDataModal';
import { AppGlobalUiContext } from '../../../../../context/AppGlobalUiContext';
import { getAppUrl } from '../../../../../helpers/utils';
import {
  AccessLogPrivateInfoDataRequest,
  EventType,
  UserDataTC,
  UserEditApplicationAdditionalInfoRequest,
  UserEditApplicationBasicInfoRequest,
  UserEditApplicationBillingInfoRequest,
  UserEditApplicationPrivateInfoRequest,
  UserEditApplicationShopInfoRequest,
} from '../../../../../models/user.model';
import { addAccessLogPrivateInfo } from '../../../../../services/v3/interview-log';
import userEditApplicationBasicInfo, {
  userEditApplicationAdditionalInfo,
  userEditApplicationBillingInfo,
  userEditApplicationPrivateInfo,
  userEditApplicationShopInfo,
} from '../../../../../services/v3/user';

type EditFormDialogListProps = {
  currentUser: UserDataTC;
  mode: 'user-data' | 'private-info' | 'shop' | 'billing' | 'misc' | null;
  onSubmitted: (newUserData: UserDataTC) => void;
  onClose: () => void;
};

const EditFormDialogList: FC<EditFormDialogListProps> = (props: EditFormDialogListProps) => {
  const { currentUser, mode, onClose, onSubmitted } = props;
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const { showSnackbar, setAppLoading } = useContext(AppGlobalUiContext);

  const handleAddEventLogUpdateBasicInfo = useCallback(
    async (data?: UserEditApplicationBasicInfoRequest, logData?: AccessLogPrivateInfoDataRequest) => {
      const updatedData = `${
        data?.isNotificationEmailSubScripted !== currentUser.isNotificationEmailSubScripted
          ? `T2H Weekly Email: FROM "${currentUser.isNotificationEmailSubScripted}" TO "${data?.isNotificationEmailSubScripted}"`
          : ''
      } ${
        data?.isNotificationEmailTpg !== currentUser.isNotificationEmailTpg
          ? `TIS New Email: FROM "${currentUser.isNotificationEmailTpg}" TO "${data?.isNotificationEmailTpg}"`
          : ''
      } `;

      const remark = `Updated Profile ${currentUser.userHashId} - Field name (${updatedData
        .replace(/\s+/g, ' ')
        .replace(/, *$/, '')
        .replace(/^, */, '')})`;

      const urlPath = getAppUrl(url);
      const eventType = EventType.SUBMIT;

      await addAccessLogPrivateInfo({
        ...logData,
        remark,
        url: urlPath,
        eventType,
      });
    },
    [currentUser, url],
  );

  const handleSubmitBasicInfo = useCallback(
    async (data?: UserEditApplicationBasicInfoRequest) => {
      if (data) {
        setAppLoading(true);
        const result = await userEditApplicationBasicInfo(data);

        if (result.data) {
          showSnackbar(t('user:common.message.updateSuccess').toString(), 'success');
          onSubmitted({ ...currentUser, ...data });
          handleAddEventLogUpdateBasicInfo({ ...data });
          onClose();
        } else {
          showSnackbar(t('user:common.message.saveFailed').toString(), 'error');
        }
        setAppLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser, onClose, onSubmitted, setAppLoading, showSnackbar, t],
  );

  const handleAddEventAccessLog = useCallback(
    async (data?: UserEditApplicationPrivateInfoRequest, logData?: AccessLogPrivateInfoDataRequest) => {
      const updatedData = `${
        data?.verifiedFullName !== currentUser.verifiedFullName
          ? `verifiedFullName:"${currentUser.verifiedFullName}"`
          : ''
      } ${
        data?.verifiedCompanyName !== currentUser.verifiedCompanyName
          ? `verifiedCompanyName:"${currentUser.verifiedCompanyName}"`
          : ''
      } ${data?.nationalId !== currentUser.nationalId ? `nationalId:"${currentUser.nationalId}"` : ''} ${
        data?.passportNumber !== currentUser.passportNumber ? `passportNumber:"${currentUser.passportNumber}"` : ''
      } ${data?.companyTaxId !== currentUser.companyTaxId ? `companyTaxId:"${currentUser.companyTaxId}"` : ''}   ${
        data?.birthday !== currentUser.birthday ? `birthday:"${currentUser.birthday}"` : ''
      } ${data?.nationality !== currentUser.nationality ? `nationality:"${currentUser.nationality}"` : ''} ${
        data?.gender !== currentUser.gender ? `gender:"${currentUser.gender}"` : ''
      } ${data?.address !== currentUser.address ? `address:"${currentUser.address}"` : ''} ${
        data?.province !== currentUser.province ? `province:"${currentUser.province}"` : ''
      } ${data?.district !== currentUser.district ? `district:"${currentUser.district}"` : ''} ${
        data?.zipcode !== currentUser.zipcode ? `zipcode:"${currentUser.zipcode}"` : ''
      } ${
        data?.bookBankCompanyHashId !== currentUser.bookBankCompany.hashId
          ? `bookBankCompanyHashId:"${currentUser.bookBankCompany.thaiName}"`
          : ''
      } ${
        data?.bookBankNumber !== currentUser.bookBankNumber ? `bookBankNumber:"${currentUser.bookBankNumber}"` : ''
      } ${
        data?.nidImage?.fileHashId !== currentUser.nidImage?.fileHashId
          ? `nidImage:"${currentUser.nidImage?.fullUrl}"`
          : ''
      } ${
        data?.selfieImage?.fileHashId !== currentUser.selfieImage?.fileHashId
          ? `selfieImage:"${currentUser.selfieImage?.fullUrl}"`
          : ''
      } ${
        data?.bookBankImage?.fileHashId !== currentUser.bookBankImage?.fileHashId
          ? `bookBankImage:"${currentUser.bookBankImage?.fullUrl}"`
          : ''
      }`;

      const remark = `Updated Profile ${currentUser.userHashId} - Field name (${updatedData
        .replace(/\s+/g, ' ')
        .replace(/, *$/, '')
        .replace(/^, */, '')})`;

      const urlPath = getAppUrl(url);
      const eventType = EventType.SUBMIT;

      await addAccessLogPrivateInfo({
        ...logData,
        remark,
        url: urlPath,
        eventType,
      });
    },
    [currentUser, url],
  );

  const handleSubmitPrivateInfo = useCallback(
    async (data?: UserEditApplicationPrivateInfoRequest) => {
      if (data) {
        setAppLoading(true);

        const gender = data.gender ? data.gender : null;

        const result = await userEditApplicationPrivateInfo({ ...data, gender });

        if (result.data) {
          showSnackbar(t('user:common.message.updateSuccess').toString(), 'success');
          onSubmitted({ ...currentUser, ...data });
          handleAddEventAccessLog({ ...data });
          onClose();
        } else {
          showSnackbar(t('user:common.message.saveFailed').toString(), 'error');
        }
        setAppLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser, onClose, onSubmitted, setAppLoading, showSnackbar, t],
  );

  const handleSubmitShopInfo = useCallback(async (data?: UserEditApplicationShopInfoRequest) => {
    if (data) {
      setAppLoading(true);
      const result = await userEditApplicationShopInfo(data);

      if (result.data) {
        showSnackbar(t('user:common.message.updateSuccess').toString(), 'success');
        onSubmitted({ ...currentUser, ...data });
        onClose();
      } else {
        showSnackbar(t('user:common.message.saveFailed').toString(), 'error');
      }
      setAppLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmitBillingInfo = useCallback(
    async (formData?: UserEditApplicationBillingInfoRequest) => {
      if (formData) {
        setAppLoading(true);
        const result = await userEditApplicationBillingInfo(formData);

        if (result.data) {
          showSnackbar(t('user:common.message.updateSuccess').toString(), 'success');
          onSubmitted({ ...currentUser, ...formData });
          onClose();
        } else {
          showSnackbar(t('user:common.message.saveFailed').toString(), 'error');
        }
        setAppLoading(false);
      }
    },
    [currentUser, onClose, onSubmitted, setAppLoading, showSnackbar, t],
  );

  const handleSubmitAdditionalInfo = useCallback(
    async (data?: UserEditApplicationAdditionalInfoRequest) => {
      if (data) {
        setAppLoading(true);

        const badges = data.badges?.map((item) => ({
          name: item.name,
          crName: item.crName,
          createdAt: dayjs(item.createdAt).valueOf(),
        }));

        const sellerYoutubeVdos = data.sellerYoutubeVdos?.map((item) => ({
          url: item.url,
          title: item.title,
          createdAt: dayjs(item.createdAt).valueOf(),
        }));

        const result = await userEditApplicationAdditionalInfo({ ...data, badges, sellerYoutubeVdos });

        if (result.data) {
          showSnackbar(t('user:common.message.updateSuccess').toString(), 'success');
          onSubmitted({ ...currentUser, ...data });
          onClose();
        } else {
          showSnackbar(t('user:common.message.saveFailed').toString(), 'error');
        }
        setAppLoading(false);
      }
    },
    [currentUser, onClose, onSubmitted, setAppLoading, showSnackbar, t],
  );

  return (
    <>
      <EditUserDataModal
        open={mode === 'user-data'}
        currentUser={currentUser}
        onSubmit={handleSubmitBasicInfo}
        onClose={onClose}
      />

      <PrivateInfoFormModal
        open={mode === 'private-info'}
        currentUser={currentUser}
        onSubmit={handleSubmitPrivateInfo}
        onClose={onClose}
      />

      <ShopFormModal
        open={mode === 'shop'}
        currentUser={currentUser}
        onSubmit={handleSubmitShopInfo}
        onClose={onClose}
      />

      <BillingInfoFormModal
        open={mode === 'billing'}
        currentUser={currentUser}
        onSubmit={handleSubmitBillingInfo}
        onClose={onClose}
      />

      <AdditionalInfoFormModal
        open={mode === 'misc'}
        currentUser={currentUser}
        onSubmit={handleSubmitAdditionalInfo}
        onClose={onClose}
      />
    </>
  );
};

export default EditFormDialogList;
