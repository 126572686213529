import React, { FC, useContext, useEffect, useState } from 'react';

import { Box, TableRow, Container } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import useDeepCompareEffect from 'use-deep-compare-effect';

import AppTableCell from '../../../components/ui/AppTableCell';
import AppTableList from '../../../components/ui/AppTableList';
import { AppGlobalUiContext } from '../../../context/AppGlobalUiContext';
import { defaultRowsPerPage } from '../../../helpers/constants';
import { AppTableConditions, TableColumn, WebConfig, WebConfigListSearchParams } from '../../../models';
import * as webConfigService from '../../../services/web-config';
import useAppContainerStyles from '../../../theme/container.style';
import useAppTableStyles from '../../../theme/table.style';

const configTableColumns: TableColumn[] = [
  { name: 'title', label: 'settings:list.config.columns.configName', width: 180 },
  { name: 'description', label: 'settings:list.config.columns.configValue', unsortable: true },
  { name: 'configType', label: 'settings:list.config.columns.configType', width: 180 },
];

const WebConfigSettings: FC = () => {
  const { path } = useRouteMatch();
  const { t } = useTranslation();
  const containerClasses = useAppContainerStyles();
  const tableClasses = useAppTableStyles();
  const history = useHistory();
  const { showSnackbar, setAppBarTitle, setAppBarBackButtonUrl } = useContext(AppGlobalUiContext);
  const [webConfig, setWebConfig] = useState<WebConfig[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [tableConditions, setTableConditions] = useState<AppTableConditions>({
    page: 0,
    rowsPerPage: defaultRowsPerPage,
    sortColumn: 'title',
    sortDirection: 'asc',
  });

  useEffect(() => {
    setAppBarTitle('settings:title.t2hSettings');
    setAppBarBackButtonUrl('/settings');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCurrentSearchParam = (): WebConfigListSearchParams => ({
    page: tableConditions.page || 0,
    rowsPerPage: tableConditions.rowsPerPage || defaultRowsPerPage,
    sortColumn: tableConditions.sortColumn || 'title',
    sortDirection: tableConditions.sortDirection || 'asc',
  });

  const queryWebConfig = (isComponentSubscribed: boolean) => {
    if (isComponentSubscribed) {
      setIsLoading(true);

      webConfigService
        .getAllWebConfig(getCurrentSearchParam())
        .then((result) => {
          if (result.data) {
            setWebConfig(result.data);
          } else {
            showSnackbar(t(result.error), 'error');
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useDeepCompareEffect((): any => {
    let isSubscribed = true;

    queryWebConfig(isSubscribed);

    return () => (isSubscribed = false);
  }, [tableConditions]);

  const onTableConditionChanged = (conditions: AppTableConditions) => {
    setTableConditions(conditions);
  };

  const editConfig = (config: WebConfig) => {
    history.push(`${path}/${config.title}`);
  };

  return (
    <>
      <Container className={containerClasses.container}>
        <Box className={tableClasses.tableContainer}>
          <AppTableList
            {...tableConditions}
            columns={configTableColumns}
            totalDataCount={webConfig.length}
            isLoading={isLoading}
            onTableConditionChanged={onTableConditionChanged}
            tableBodyContent={
              webConfig.length > 0 && (
                <>
                  {webConfig.map((config: WebConfig) => (
                    <TableRow
                      hover
                      key={config.title}
                      classes={{ root: clsx(tableClasses.tableRow, tableClasses.clickableTableRow) }}
                      onClick={() => editConfig(config)}>
                      <AppTableCell>{config.title}</AppTableCell>
                      <AppTableCell>{config.description}</AppTableCell>
                      <AppTableCell>{config.configType}</AppTableCell>
                    </TableRow>
                  ))}
                </>
              )
            }
          />
        </Box>
      </Container>
    </>
  );
};

export default WebConfigSettings;
