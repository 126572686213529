import React, { FC } from 'react';

import { Box, Container, Grid, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ProductListSearchFormData, PurchaseTypeTC } from '../../../../../models/product.model';
import useAppContainerStyles from '../../../../../theme/container.style';
import AppFormControl from '../../../../ui/AppFormControl';

export type ProductListSearchFormProps = {
  isLoadingData?: boolean;
  currentFilters: ProductListSearchFormData | undefined;
  onSearchFormSubmit: (values: ProductListSearchFormData) => void;
};

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
  },
  searchBoxContainer: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 600,
    },
  },
}));

const searchType = [
  {
    label: 'product:list.search.coin',
    value: PurchaseTypeTC.Coin,
  },
  {
    label: 'product:list.search.memberOnce',
    value: PurchaseTypeTC.MemberOnce,
  },
  {
    label: 'product:list.search.memberSubscription',
    value: PurchaseTypeTC.MemberSubscription,
  },
];

const ProductListSearchForm: FC<ProductListSearchFormProps> = (props) => {
  const classes = useStyles();
  const containerClasses = useAppContainerStyles();
  const { t } = useTranslation();
  const { currentFilters, onSearchFormSubmit, isLoadingData = false } = props;
  const { handleSubmit, control } = useForm<ProductListSearchFormData>({
    mode: 'onSubmit',
    defaultValues: {
      filteringType: currentFilters?.filteringType || '',
    },
  });

  const submitSearchForm = () => {
    if (!isLoadingData) {
      handleSubmit(onSearchFormSubmit)();
    }
  };

  return (
    <Box className={classes.filterContainer}>
      <Container className={containerClasses.container}>
        <form onSubmit={handleSubmit(onSearchFormSubmit)}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} md={3}>
              <Controller
                name="filteringType"
                control={control}
                render={({ field }) => (
                  <AppFormControl margin="dense">
                    <InputLabel id="product-type-label" style={{ fontSize: '14px' }}>
                      {t('product:list.search.type')}
                    </InputLabel>
                    <Select
                      labelId="product-type-label"
                      id="product-type"
                      value={field.value}
                      variant="outlined"
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        submitSearchForm();
                      }}
                      fullWidth
                      disabled={isLoadingData}>
                      {searchType.map((item) => (
                        <MenuItem value={item.value} key={item.value}>
                          {t(item.label)}
                        </MenuItem>
                      ))}
                    </Select>
                  </AppFormControl>
                )}
              />
            </Grid>
          </Grid>
        </form>
      </Container>
    </Box>
  );
};

export default ProductListSearchForm;
