import React, { ChangeEvent, FC, ReactElement, useEffect, useState, MouseEvent } from 'react';

import { Box, Grid, makeStyles, MenuItem, Paper, Select, TablePagination, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { getFileName, getFileType, getT2HAssetUrl } from '../../../../helpers/utils';
import { AppTableConditions, FileItem } from '../../../../models';
import useAppTableStyles from '../../../../theme/table.style';
import useTypographyStyles from '../../../../theme/typography.style';
import AppFileTypeIcon from '../../../ui/AppFileTypeIcon';
import AppFormControl from '../../../ui/AppFormControl';
import ImageThumbnail from '../ImageThumbnail';

export type FileListThumbnailProps = {
  currentConditions: AppTableConditions;
  totalFiles: number;
  isLoadingData: boolean;
  fileList: FileItem[];
  onTableConditionsChanged: (values: AppTableConditions) => void;
  onFileClick?: (file: FileItem) => void;
  onFileDoubleClick?: (file: FileItem) => void;
  selectedFiles?: FileItem[];
  contextedFile: FileItem | null;
  onContextMenuClick?: (e: MouseEvent<HTMLElement>, file: FileItem) => void;
};

const useStyles = makeStyles((theme) => ({
  thumbnailListContainer: {
    overflow: 'hidden',
    overflowY: 'auto',
    height: 'calc(100% - 78px)',
    borderRadius: 5,
    backgroundColor: theme.palette.grey[100],
  },
  hiddenItem: {
    visibility: 'hidden',
    opacity: 0,
  },
  imageThumbnailContainer: {
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
    cursor: 'pointer',
  },
  imageDownloadButton: {
    cursor: 'pointer',
    opacity: 0.6,
    '&:hover': {
      opacity: 1,
    },
  },
  iconThumbnailBlock: {
    height: '175px',
    overflow: 'hidden',
    backgroundColor: theme.palette.grey[200],
  },
  fileTypeIcon: {
    fontSize: 80,
  },
  thumbnailImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  thumbnailPaper: {
    position: 'relative',
  },
  thumbnailPaperSelected: {
    '&::before': {
      position: 'absolute',
      content: '""',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      border: `solid 3px ${theme.palette.info.main}`,
    },
  },
  thumbnailPaperContexted: {
    '&::before': {
      position: 'absolute',
      content: '""',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      border: `dashed 3px ${theme.palette.info.main}`,
    },
  },
}));

const thumbnailItemsPerPageList = [10, 20, 30, 50, 70];

const FileThumbnailCard = (props: {
  file: FileItem;
  isSelected: boolean;
  isContextedFile: boolean;
  onFileClick: (file: FileItem) => void;
  onFileDoubleClick: (file: FileItem) => void;
  onContextMenuClick: (e: MouseEvent<HTMLElement>, file: FileItem) => void;
}): ReactElement => {
  const {
    file,
    isSelected = false,
    isContextedFile = false,
    onFileClick,
    onFileDoubleClick,
    onContextMenuClick,
  } = props;
  const classes = useStyles();
  const typoClasses = useTypographyStyles();
  const fileName = getFileName(file.fullUrl);

  return (
    <Paper
      classes={{
        root: clsx(classes.imageThumbnailContainer, classes.thumbnailPaper, {
          [classes.thumbnailPaperSelected]: isSelected,
          [classes.thumbnailPaperContexted]: isContextedFile && !isSelected,
        }),
      }}
      onClick={() => onFileClick(file)}
      onDoubleClick={() => onFileDoubleClick(file)}
      onContextMenu={(e) => onContextMenuClick(e, file)}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        className={clsx(classes.iconThumbnailBlock, typoClasses.greyText)}>
        <AppFileTypeIcon filePath={file.fullUrl} iconClassName={classes.fileTypeIcon} />
      </Box>
      <Box p={1} display="flex">
        <Box flexGrow={1} minWidth={0} pr={1}>
          <Typography variant="subtitle2">{fileName}</Typography>
        </Box>
      </Box>
    </Paper>
  );
};

const FileListThumbnail: FC<FileListThumbnailProps> = (props) => {
  const { t } = useTranslation();
  const {
    fileList,
    currentConditions,
    totalFiles,
    isLoadingData,
    onTableConditionsChanged,
    onFileClick,
    onFileDoubleClick,
    selectedFiles = [],
    contextedFile,
    onContextMenuClick,
  } = props;
  const classes = useStyles();
  const tableClasses = useAppTableStyles();
  const [currentPage, setCurrentPage] = useState<number>(currentConditions.page || 0);
  const [currentRowsPerPage, setCurrentRowsPerPage] = useState<number>(
    currentConditions.rowsPerPage || thumbnailItemsPerPageList[0],
  );
  const [currentSortColumn, setCurrentSortColumn] = useState<string>(currentConditions.sortColumn || 'createdAt');
  const [currentSortDirection, setcurrentSortDirection] = useState<AppTableConditions['sortDirection']>(
    currentConditions.sortDirection || 'desc',
  );

  useEffect(() => {
    if (typeof onTableConditionsChanged === 'function') {
      onTableConditionsChanged({
        page: currentPage,
        rowsPerPage: currentRowsPerPage,
        sortColumn: currentSortColumn,
        sortDirection: currentSortDirection,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, currentRowsPerPage, currentSortColumn, currentSortDirection]);

  const onPageChanged = (e: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setCurrentPage(newPage);
  };

  const onRowsPerPageChanged = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setCurrentRowsPerPage(parseInt(e.target.value, 10));
    setCurrentPage(0);
  };

  const onSortColumnChanged = (
    e: ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => {
    setCurrentSortColumn(e.target.value as string);
  };

  const onSortDirectionChanged = (
    e: ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => {
    setcurrentSortDirection(e.target.value as AppTableConditions['sortDirection']);
  };

  const onFileItemClick = (file: FileItem) => {
    if (typeof onFileClick === 'function') {
      onFileClick(file);
    }
  };

  const onFileItemDoubleClick = (file: FileItem) => {
    if (typeof onFileDoubleClick === 'function') {
      onFileDoubleClick(file);
    }
  };

  const onFileContextHandler = (e: MouseEvent<HTMLElement>, file: FileItem) => {
    if (typeof onContextMenuClick === 'function') {
      onContextMenuClick(e, file);
    }
  };

  const isSelected = (file: FileItem): boolean => !!selectedFiles.find((item: FileItem) => item.id === file.id);
  const isContexted = (file: FileItem): boolean => !!contextedFile && contextedFile.id === file.id;

  return (
    <>
      <Box p={2} className={classes.thumbnailListContainer} display="flex" flexDirection="column" alignItems="stretch">
        <Box flexGrow={1}>
          <Grid container spacing={2}>
            {fileList.map((file: FileItem, index: number) => {
              const rowKey = `thumbnail-${index}`;
              const isSelectedFile = isSelected(file);
              const isContextedFile = isContexted(file);

              const fileUrl = !/^(http)\.+$/gi.test(file.fullUrl) ? getT2HAssetUrl(file.fullUrl) : file.fullUrl;
              const fileType = getFileType(fileUrl);

              return (
                <Grid key={rowKey} item xs={12} sm={6} md={4} lg={2} xl={2}>
                  {fileType === 'image' && (
                    <ImageThumbnail
                      key={rowKey}
                      imageUrl={fileUrl}
                      altText={file.fullUrl}
                      thumbnailLabel={getFileName(file.fullUrl)}
                      noDownload
                      paperProps={{
                        className: clsx(classes.thumbnailPaper, {
                          [classes.thumbnailPaperSelected]: isSelectedFile,
                          [classes.thumbnailPaperContexted]: isContextedFile && !isSelectedFile,
                        }),
                        onClick: () => onFileItemClick(file),
                        onDoubleClick: () => onFileItemDoubleClick(file),
                        onContextMenu: (e) => onFileContextHandler(e, file),
                      }}
                    />
                  )}
                  {fileType !== 'image' && (
                    <FileThumbnailCard
                      file={file}
                      isSelected={isSelectedFile}
                      isContextedFile={isContextedFile}
                      onFileClick={onFileItemClick}
                      onFileDoubleClick={onFileItemDoubleClick}
                      onContextMenuClick={onFileContextHandler}
                    />
                  )}
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
      <Box pt={2} display="flex" flexDirection="row">
        <Box pr={1} display="flex" alignItems="center">
          <AppFormControl fullWidth>
            <Select value={currentSortColumn} onChange={onSortColumnChanged} margin="dense">
              <MenuItem value="createdAt">{t('common:message.createdAt')}</MenuItem>
              <MenuItem value="updatedAt">{t('common:message.updatedAt')}</MenuItem>
              <MenuItem value="fileOriginalName">{t('common:mediaLibrary.fields.fileName')}</MenuItem>
              <MenuItem value="fullUrl">{t('common:mediaLibrary.fields.fileUrl')}</MenuItem>
            </Select>
          </AppFormControl>
        </Box>
        <Box display="flex" alignItems="center">
          <AppFormControl>
            <Select value={currentSortDirection} onChange={onSortDirectionChanged} margin="dense">
              <MenuItem value="asc">{t('common:message.sortAsc')}</MenuItem>
              <MenuItem value="desc">{t('common:message.sortDesc')}</MenuItem>
            </Select>
          </AppFormControl>
        </Box>
        <Box flexGrow={1} display="flex" flexDirection="row" justifyContent="flex-end">
          <TablePagination
            rowsPerPageOptions={thumbnailItemsPerPageList}
            component="div"
            count={totalFiles}
            rowsPerPage={currentRowsPerPage || thumbnailItemsPerPageList[0]}
            page={totalFiles > 0 ? currentPage : 0}
            onChangePage={onPageChanged}
            onChangeRowsPerPage={onRowsPerPageChanged}
            classes={{ root: tableClasses.tablePagination }}
            className={isLoadingData ? classes.hiddenItem : ''}
          />
        </Box>
      </Box>
    </>
  );
};

export default FileListThumbnail;
