import React, {
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  Box,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  makeStyles,
  Switch,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ErrorIcon from '@material-ui/icons/Error';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import clsx from 'clsx';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FileItem, T2HMenuItemFormData, TabItem } from '../../../../models';
import useTypographyStyles from '../../../../theme/typography.style';
import AppDialog from '../../../ui/AppDialog';
import AppFormControl from '../../../ui/AppFormControl';
import AppFormErrorMessage from '../../../ui/AppFormErrorMessage';
import AppMediaSelector from '../../../ui/AppMediaSelector';
import AppTabPanel from '../../../ui/AppTabPanel';

const tabIdPrefix = 'menu_tab_';
const tabPanelPrefix = 'menu-setting-tab-';
const tabList: TabItem[] = [
  {
    id: 'general',
    label: 'menu:editor.label.tabGeneral',
    value: 'general',
  },
  {
    id: 'attribute',
    label: 'menu:editor.label.tabAttribute',
    value: 'attribute',
  },
];

// eslint-disable-next-line @typescript-eslint/ban-types
export type MenuEditorDialogProps = {
  isDataFromClone: boolean;
  open: boolean;
  editingIndex: number | undefined;
  editingData: T2HMenuItemFormData | undefined;
  onClose: () => void;
  onDialogOkClick: (data: T2HMenuItemFormData) => void;
  onDialogExited: () => void;
};

type AttributeItem = {
  key: string;
  value: string | undefined;
};

const useStyles = makeStyles((theme) => ({
  tabBar: {
    position: 'relative',
    zIndex: 2,
    borderBottom: `solid 1px ${theme.palette.grey[400]}`,
  },
  tabPanel: {
    position: 'relative',
    minHeight: 300,
  },
  categoryTab: {
    flexDirection: 'row',
  },
  categoryWarningIcon: {
    marginRight: 3,
  },
  attributesTable: {
    minWidth: 500,
  },
  attributesTableCell: {
    verticalAlign: 'top',
  },
  rowIconButton: {
    transform: 'translateY(1px)',
  },
  attrTableContainer: {
    overflow: 'hidden',
    overflowX: 'auto',
  },
  attrTable: {
    width: '100%',
    minWidth: 500,
  },
  attrTableDataRow: {
    borderBottom: `solid 1px ${theme.palette.grey[200]}`,
  },
  attrTableDataCol: {
    flexGrow: 1,
    maxWidth: '40%',
    paddingRight: theme.spacing(1),
  },
  errorTooltip: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  helpTextLabel: {
    cursor: 'pointer',
    color: theme.palette.info.main,
  },
  dialogRoot: {
    width: '100%',
  },
  dialogSm: {
    maxWidth: '400px',
  },
  dialogMd: {
    maxWidth: '600px',
  },
  dialogLg: {
    maxWidth: '760px',
  },
}));

const AttributeTableRow = (props: {
  item: AttributeItem;
  onDataChanged: (item: AttributeItem) => void;
  onDataSubmitted?: (item: AttributeItem) => void;
  onEditClick?: (itemData?: AttributeItem) => void;
  onDeleteClick?: () => void;
  onEditCancelClick?: () => void;
  isNewItem?: boolean;
  isKeyDuplicated?: boolean | ((itemKey: string) => boolean);
  isRowEditing?: boolean;
  isCloneData?: MenuEditorDialogProps['isDataFromClone'];
}) => {
  const {
    item,
    onDataSubmitted,
    onDeleteClick,
    onEditClick,
    onEditCancelClick,
    isKeyDuplicated = false,
    isNewItem = false,
    isRowEditing = false,
    isCloneData = false,
  } = props;
  const { t } = useTranslation();
  const keyInputRef = useRef<HTMLInputElement | null>(null);
  const valueInputRef = useRef<HTMLInputElement | null>(null);
  const classes = useStyles();
  const typoClasses = useTypographyStyles();
  const allowEdit = isRowEditing || isNewItem;
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    getValues,
  } = useForm<AttributeItem>({
    mode: 'onChange',
    defaultValues: {
      key: item.key || '',
      value: item.value || '',
    },
  });

  useEffect(() => {
    setValue('key', item.key, { shouldDirty: false, shouldTouch: false, shouldValidate: false });
    setValue('value', item.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const validateKey = (key: string): string | true => {
    if (
      (typeof isKeyDuplicated === 'boolean' && !isKeyDuplicated) ||
      (typeof isKeyDuplicated === 'function' && !isKeyDuplicated(key))
    ) {
      if (/^[a-z](([a-zA-Z0-9])*|([a-zA-Z0-9_-]*[a-zA-Z0-9]))$/gi.test(key)) {
        return true;
      }

      return 'menu:error.invalidAttribute';
    }

    return 'validation:duplicatedKeyInTable';
  };

  const rowDataSubmit = (rowData: AttributeItem) => {
    if (typeof onDataSubmitted === 'function') {
      onDataSubmitted(rowData);
    }
    if (isNewItem) {
      setValue('key', '', { shouldDirty: false, shouldTouch: false, shouldValidate: false });
      setValue('value', '');

      if (keyInputRef.current) {
        keyInputRef.current.focus();
      }
    }
  };

  const onInputKeyUpHandler = (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>, field: 'key' | 'value') => {
    const keyCode = e.code?.toLowerCase();

    if (['enter', 'numpadenter'].includes(keyCode)) {
      if (field === 'value') {
        handleSubmit(rowDataSubmit)();
        return;
      }

      if (valueInputRef.current) {
        valueInputRef.current.focus();
      }
    }
  };

  const onInputBlurHandler = (e: FocusEvent<HTMLInputElement>) => {
    if (!isNewItem) {
      if (!isValid) {
        e.stopPropagation();
        e.preventDefault();

        if (keyInputRef.current) {
          keyInputRef.current.focus();
        }

        return;
      }

      handleSubmit(rowDataSubmit)();
    }
  };

  const onEditClickHandler = () => {
    if (typeof onEditClick === 'function') {
      onEditClick(isRowEditing && isValid ? getValues() : undefined);
    }
  };

  const onDeleteClickHandler = () => {
    if (typeof onDeleteClick === 'function') {
      onDeleteClick();
    }
  };

  const onEditCancelClickHandler = () => {
    if (typeof onEditCancelClick === 'function') {
      onEditCancelClick();
    }
  };

  return (
    <Box display="flex" flexDirection="row" py={1} className={classes.attrTableDataRow}>
      <Box className={classes.attrTableDataCol}>
        {allowEdit && (
          <Controller
            control={control}
            name="key"
            rules={{ required: true, validate: validateKey }}
            render={({ field }) => (
              <>
                <TextField
                  margin="dense"
                  variant="outlined"
                  placeholder={`${t('menu:editor.attributeTable.columns.name').toString()} *`}
                  fullWidth
                  error={!!errors.key}
                  {...field}
                  autoComplete="off"
                  inputRef={keyInputRef}
                  disabled={!isRowEditing || isCloneData}
                  InputProps={{
                    onKeyUp: (e) => onInputKeyUpHandler(e, 'key'),
                    onBlur: onInputBlurHandler,
                    endAdornment: errors.key ? (
                      <InputAdornment position="end">
                        <Tooltip
                          title={
                            errors.key.message
                              ? t(errors.key.message).toString()
                              : t('menu:error.invalidAttribute').toString()
                          }
                          placement="top"
                          aria-label="error"
                          classes={{
                            tooltip: classes.errorTooltip,
                            tooltipArrow: classes.errorTooltip,
                          }}>
                          <IconButton aria-label="attribute error" edge="end">
                            <ErrorIcon color="error" />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ) : null,
                  }}
                />
                {isNewItem && (
                  <Tooltip
                    title={t('menu:editor.attributeTable.nameHelpText').toString()}
                    placement="top"
                    aria-label="help">
                    <FormHelperText component="span" className={classes.helpTextLabel}>
                      {t('menu:editor.attributeTable.nameHelpTextLabel')}
                    </FormHelperText>
                  </Tooltip>
                )}
              </>
            )}
          />
        )}
        {!allowEdit && <Typography component="span">{item.key}</Typography>}
      </Box>
      <Box className={classes.attrTableDataCol}>
        {allowEdit && (
          <Controller
            control={control}
            name="value"
            render={({ field }) => (
              <TextField
                margin="dense"
                variant="outlined"
                placeholder={t('menu:editor.attributeTable.columns.value').toString()}
                fullWidth
                autoComplete="off"
                {...field}
                inputRef={valueInputRef}
                disabled={!isRowEditing || isCloneData}
                InputProps={{
                  onKeyUp: (e) => onInputKeyUpHandler(e, 'value'),
                  onBlur: onInputBlurHandler,
                }}
              />
            )}
          />
        )}
        {!allowEdit && <Typography component="span">{item.value}</Typography>}
      </Box>
      <Box flexBasis={140}>
        {!isNewItem && (
          <>
            <IconButton
              aria-label="edit"
              disabled={isCloneData}
              disableRipple
              disableFocusRipple
              size="small"
              onClick={() => onEditClickHandler()}
              className={classes.rowIconButton}>
              {isRowEditing ? <CheckIcon className={typoClasses.successText} /> : <EditIcon />}
            </IconButton>
            {isRowEditing && (
              <IconButton
                aria-label="cancel"
                disableRipple
                disableFocusRipple
                size="small"
                onClick={() => onEditCancelClickHandler()}
                className={classes.rowIconButton}>
                <CloseIcon />
              </IconButton>
            )}
            <IconButton
              disabled={isCloneData}
              aria-label="delete"
              disableRipple
              disableFocusRipple
              size="small"
              onClick={() => onDeleteClickHandler()}
              className={classes.rowIconButton}>
              <DeleteIcon className={typoClasses.errorText} />
            </IconButton>
          </>
        )}
        {isNewItem && (
          <IconButton
            aria-label="edit"
            disableRipple
            disableFocusRipple
            size="small"
            onClick={() => handleSubmit(rowDataSubmit)()}
            disabled={(!isRowEditing || !isValid) && isCloneData}
            className={classes.rowIconButton}>
            <AddIcon color={isValid ? 'primary' : 'disabled'} />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

const AttributesTableEditor = (props: {
  value: Record<string, string> | undefined;
  onChange: (data: Record<string, string>) => void;
  isCloneData: MenuEditorDialogProps['isDataFromClone'];
}) => {
  const classes = useStyles();
  const { value, onChange, isCloneData } = props;
  const initialAttributes = value || {};
  const [attributeDataArray, setAttributeDataArray] = useState<AttributeItem[]>(
    Object.keys(initialAttributes).map((key: string) => ({
      key,
      value: initialAttributes[key],
    })),
  );
  const [editingRowIndex, setEditingRowIndex] = useState<number>(-1);

  useEffect(() => {
    if (typeof onChange === 'function') {
      const dataObject: Record<string, string> = attributeDataArray.reduce(
        (acc: Record<string, string>, cur: AttributeItem) => {
          const { key, value: itemValue } = cur;
          if (typeof acc[key] === 'undefined') {
            acc[key] = itemValue || '';
          }

          return acc;
        },
        {},
      );

      onChange(dataObject);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributeDataArray]);

  const isKeyDuplicated = (key: string, index: number): boolean =>
    attributeDataArray.filter((attr, idx) => attr.key.toLowerCase() === key.toLowerCase() && index !== idx).length > 0;

  const onRowDataChanged = (item: AttributeItem, index: number) => {
    if (index > -1) {
      const newData = [...attributeDataArray];
      newData[index] = item;
      setAttributeDataArray(newData);
    }
  };

  const onRowDataSubmitted = (item: AttributeItem) => {
    if (!isKeyDuplicated(item.key, -1)) {
      const newData = [...attributeDataArray];
      newData.push(item);
      setAttributeDataArray(newData);
    }
  };

  const onDeleteClickHandler = (item: AttributeItem) => {
    const newData = [...attributeDataArray].filter((attr) => attr.key !== item.key);
    setAttributeDataArray(newData);
  };

  return (
    <>
      <Box className={classes.attrTableContainer}>
        <Box className={classes.attrTable}>
          <AttributeTableRow
            isCloneData={isCloneData}
            isKeyDuplicated={(key) => isKeyDuplicated(key, -1)}
            item={{ key: '', value: '' }}
            onDataChanged={(item) => onRowDataChanged(item, -1)}
            onDataSubmitted={onRowDataSubmitted}
            isRowEditing={editingRowIndex === -1}
            isNewItem
          />
          {attributeDataArray.map((attr, index) => {
            const itemRowKey = `attribute-${index}`;

            return (
              <AttributeTableRow
                key={itemRowKey}
                isKeyDuplicated={(key) => isKeyDuplicated(key, index)}
                isRowEditing={editingRowIndex === index}
                item={attr}
                onDataChanged={(item) => onRowDataChanged(item, index)}
                onEditClick={(data?: AttributeItem) => {
                  if (editingRowIndex === index && data) {
                    onRowDataChanged(data, index);
                    setEditingRowIndex(-1);
                  } else {
                    setEditingRowIndex(index);
                  }
                }}
                onDeleteClick={() => {
                  onDeleteClickHandler(attr);
                  if (editingRowIndex === index) {
                    setEditingRowIndex(-1);
                  }
                }}
                onEditCancelClick={() => setEditingRowIndex(-1)}
                isCloneData={isCloneData}
              />
            );
          })}
        </Box>
      </Box>
    </>
  );
};

const MenuEditorDialog = (props: MenuEditorDialogProps): ReactElement => {
  const { isDataFromClone = false } = props;

  const { t } = useTranslation();
  const { editingData, open, onDialogOkClick, editingIndex, onClose, onDialogExited } = props;
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const classes = useStyles();
  const typoClasses = useTypographyStyles();
  const [currentAttributes, setCurrentAttributes] = useState<Record<string, string>>({});
  const {
    control,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
    handleSubmit,
  } = useForm<T2HMenuItemFormData>({
    mode: 'onSubmit',
    defaultValues: {
      imageUrl: '',
      label: '',
      url: '',
      trackingClass: '',
      clientSideRedirect: false,
      newWindow: false,
      attributes: '{}',
    },
  });

  const setFormData = (data?: T2HMenuItemFormData) => {
    reset();
    clearErrors();
    setValue('imageUrl', data?.imageUrl || '', { shouldValidate: true });
    setValue('label', data?.label || '', { shouldValidate: true });
    setValue('url', data?.url || '', { shouldValidate: true });
    setValue('trackingClass', data?.trackingClass || '', { shouldValidate: true });
    setValue('clientSideRedirect', data?.clientSideRedirect || false, {
      shouldValidate: true,
    });
    setValue('newWindow', data?.newWindow || false, { shouldValidate: true });
    try {
      setCurrentAttributes(data?.attributes ? JSON.parse(data.attributes) : {});
    } catch (e) {
      setCurrentAttributes({});
    }
  };

  useEffect(() => {
    setFormData(editingData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingData]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const tabChangeHandler = (e: ChangeEvent<any>, value: any) => {
    setSelectedTab(value);
  };

  const getTabLabel = (tab: TabItem): ReactNode => {
    const hasError = !!errors.label && tab.value === 'general';

    return (
      <>
        {hasError && (
          <ErrorOutlineIcon className={clsx(classes.categoryWarningIcon, typoClasses.errorText)} fontSize="small" />
        )}
        {t(tab.label).toString()}
      </>
    );
  };

  const menuEditorFormValidator = (formData: T2HMenuItemFormData) => {
    if (typeof onDialogOkClick === 'function') {
      onDialogOkClick(formData);
    }
  };

  const onOkClickHandler = () => {
    handleSubmit(menuEditorFormValidator)();
  };

  return (
    <>
      <AppDialog
        open={open}
        title={`menu:editor.label.${editingIndex === -1 ? 'addDialogTitle' : 'editDialogTitle'}`}
        onClose={onClose}
        okButtonText={`common:button.${editingIndex === -1 ? 'add' : 'save'}`}
        okButtonColor={editingIndex === -1 ? 'primary' : 'success'}
        onOkClick={() => {
          onOkClickHandler();
        }}
        cancelButtonText="common:button.cancel"
        dialogProps={{
          disableBackdropClick: true,
          maxWidth: 'lg',
          classes: {
            paper: classes.dialogRoot,
            paperWidthSm: classes.dialogSm,
            paperWidthMd: classes.dialogMd,
            paperWidthLg: classes.dialogLg,
          },
          TransitionProps: {
            onExited: () => {
              setSelectedTab(0);
              if (typeof onDialogExited === 'function') {
                onDialogExited();
              }
            },
          },
        }}>
        <Tabs
          value={selectedTab}
          onChange={tabChangeHandler}
          aria-label="Finance detail tabs"
          textColor="primary"
          indicatorColor="primary"
          className={classes.tabBar}
          variant="scrollable"
          scrollButtons="auto">
          {tabList.map((tab: TabItem, idx: number) => (
            <Tab
              key={tab.id}
              label={getTabLabel(tab)}
              id={`${tabIdPrefix}${tab.id}`}
              aria-controls={`${tabPanelPrefix}${tab.id}`}
              disabled={tab.disabled || false}
              className={clsx({
                [typoClasses.textWeightBold]: selectedTab === idx,
              })}
              classes={{
                wrapper: classes.categoryTab,
              }}
            />
          ))}
        </Tabs>
        <AppTabPanel
          value={selectedTab}
          index={0}
          tabItem={tabList[0]}
          tabIdPrefix={tabIdPrefix}
          tabPanelPrefix={tabPanelPrefix}
          className={classes.tabPanel}>
          <Box py={3}>
            <Controller
              control={control}
              name="label"
              rules={{ required: true }}
              render={({ field }) => (
                <AppFormControl boxProps={{ mb: 3 }} error={!!errors.label}>
                  <TextField
                    {...field}
                    label={t('menu:common.menuLabel').toString()}
                    variant="outlined"
                    error={!!errors.label}
                    autoFocus
                    autoComplete="off"
                  />
                  {!!errors.label && <AppFormErrorMessage errors={errors} name="label" />}
                </AppFormControl>
              )}
            />

            <Controller
              control={control}
              name="url"
              render={({ field }) => (
                <AppFormControl boxProps={{ mb: 3 }} error={!!errors.url}>
                  <TextField
                    {...field}
                    label={t('menu:common.menuUrl').toString()}
                    variant="outlined"
                    error={!!errors.url}
                    autoComplete="off"
                  />
                  {!!errors.url && <AppFormErrorMessage errors={errors} name="url" />}
                </AppFormControl>
              )}
            />

            <Controller
              control={control}
              name="imageUrl"
              render={({ field }) => (
                <AppFormControl boxProps={{ mb: 3 }} error={!!errors.imageUrl}>
                  <AppMediaSelector
                    defaultValue={field.value}
                    mode="input"
                    inputProps={{
                      label: t('menu:common.menuImageUrl').toString(),
                      error: !!errors.imageUrl,
                    }}
                    onFilesSelected={(files: FileItem[]) => {
                      if (files.length) {
                        field.onChange(files[0].fullUrl);
                      }
                    }}
                    onValueCleared={() => field.onChange('')}
                  />
                  {!!errors.imageUrl && <AppFormErrorMessage errors={errors} name="imageUrl" />}
                </AppFormControl>
              )}
            />

            <Controller
              control={control}
              name="trackingClass"
              render={({ field }) => (
                <AppFormControl boxProps={{ mb: 3 }} error={!!errors.trackingClass}>
                  <TextField
                    {...field}
                    label={t('menu:common.menuTrackingClass').toString()}
                    variant="outlined"
                    error={!!errors.trackingClass}
                    autoComplete="off"
                  />
                  {!!errors.trackingClass && <AppFormErrorMessage errors={errors} name="trackingClass" />}
                </AppFormControl>
              )}
            />

            <Controller
              control={control}
              name="clientSideRedirect"
              render={({ field }) => (
                <AppFormControl boxProps={{ mb: 3 }}>
                  <Typography>{t('menu:common.menuClientSideRedirect')}</Typography>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={field.value}
                        onChange={field.onChange}
                        color="primary"
                        name="clientSideRedirect"
                        inputProps={{ 'aria-label': 'client side redirection?' }}
                      />
                    }
                    label={t('common:button.apply').toString()}
                  />
                </AppFormControl>
              )}
            />
            <Controller
              control={control}
              name="newWindow"
              render={({ field }) => (
                <AppFormControl boxProps={{ mb: 3 }}>
                  <Typography>{t('menu:common.menuNewTab')}</Typography>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={field.value}
                        onChange={field.onChange}
                        color="primary"
                        name="newWindow"
                        inputProps={{ 'aria-label': 'open in new window?' }}
                      />
                    }
                    label={t('common:button.apply').toString()}
                  />
                </AppFormControl>
              )}
            />
          </Box>
        </AppTabPanel>
        <AppTabPanel
          value={selectedTab}
          index={1}
          tabItem={tabList[1]}
          tabIdPrefix={tabIdPrefix}
          tabPanelPrefix={tabPanelPrefix}
          className={classes.tabPanel}>
          <Box py={2}>
            <AttributesTableEditor
              isCloneData={isDataFromClone}
              value={currentAttributes}
              onChange={(data: Record<string, string>) => {
                setCurrentAttributes(data);

                try {
                  setValue('attributes', JSON.stringify(data));
                } catch (e) {
                  setValue('attributes', '{}');
                }
              }}
            />
          </Box>
        </AppTabPanel>
      </AppDialog>
    </>
  );
};

export default MenuEditorDialog;
