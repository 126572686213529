import React, { FC } from 'react';

import { FormHelperText } from '@material-ui/core';
import { DeepMap, FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export type AppFormErrorMessageProps = {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: DeepMap<any, FieldError>;
  translationFieldName?: string;
};

const AppFormErrorMessage: FC<AppFormErrorMessageProps> = (props) => {
  const { t } = useTranslation();
  const { name, errors, translationFieldName } = props;

  return (
    <FormHelperText error>
      {errors[name].type === 'required' &&
        t('validation:requiredFieldAlt', { fieldName: t(translationFieldName || name).toString() })}
      {errors[name].type === 'validate' && t(errors[name].message)}
      {errors[name].type === 'pattern' && t(errors[name].message)}
    </FormHelperText>
  );
};

export default AppFormErrorMessage;
