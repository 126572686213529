import { createMuiTheme } from '@material-ui/core';

import { materialPalette, colors } from './colors';
import controls from './controls';

export * from './controls.style';

const fontFamily = [
  '"Sarabun"',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(',');

const theme = createMuiTheme({
  props: {
    MuiButton: {
      disableElevation: true,
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          height: '100%',
          WebkitFontSmoothing: 'auto',
          fontFamily,
          backgroundColor: colors.global.document.backgroundColor,
        },
        body: { fontSize: '1rem', height: '100%', backgroundColor: colors.global.document.backgroundColor },
        a: {
          color: colors.global.link.main,
        },
      },
    },
    MuiListItemIcon: { root: { minWidth: 40 } },
    MuiButton: {
      label: {
        textTransform: 'initial',
        letterSpacing: '0.3px',
        fontWeight: 700,
      },
      contained: {
        color: colors.disabled.contrastText,
        '&.Mui-disabled, &:disabled': {
          backgroundColor: colors.disabled.dark,
        },
      },
    },
    MuiInputBase: {
      root: {
        '&.Mui-disabled': {
          backgroundColor: colors.disabled.main,
        },
      },
      input: {
        lineHeight: controls.control.normal.lineHeight,
        height: controls.control.normal.height,
        fontSize: controls.control.normal.fontSize,
        boxSizing: 'border-box',
        '&::-webkit-input-placeholder': {
          fontSize: '14px',
        },
        '&:-ms-input-placeholder': {
          fontSize: '14px',
        },
        '&:placeholder': {
          fontSize: controls.control.normal.fontSize,
        },
        '&.Mui-disabled': {
          backgroundColor: colors.disabled.main,
        },
      },
      marginDense: {
        '&:not(.MuiInputBase-multiline)': {
          height: controls.control.small.height,
        },
        lineHeight: controls.control.small.lineHeight,
        height: controls.control.small.height,
      },
      inputMarginDense: {
        minHeight: controls.control.small.minHeight,
      },
      inputMultiline: {
        lineHeight: '28px',
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: controls.control.normal.fontSize,
      },
      outlined: {
        transform: 'translate(14px, 16px) scale(1)',
        '&.MuiInputLabel-marginDense:not(.MuiInputLabel-shrink)': {
          transform: 'translate(14px, 10px) scale(1)',
        },
        '&.MuiInputLabel-shrink': controls.inputLabel.shrink,
        lineHeight: 1.4,
      },
      shrink: {
        ...controls.inputLabel.shrink,
        backgroundColor: colors.background.paper,
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: controls.control.normal.padding,
      },
      root: {
        '&:not(.Mui-disabled):hover .MuiOutlinedInput-notchedOutline': {
          borderColor: colors.control.border.hover,
        },
        '&:not(.Mui-disabled).Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: colors.control.border.focus,
          borderWidth: controls.control.normal.borderWidth,
        },
      },
      notchedOutline: {
        borderColor: colors.control.border.main,
      },
      inputMarginDense: {
        paddingTop: controls.control.small.paddingTop,
        paddingBottom: controls.control.small.paddingBottom,
        ...controls.control.small,
      },
    },
    MuiFormLabel: {
      root: {
        '&.Mui-focused': {
          color: colors.control.border.focus,
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '12px',
        letterSpacing: '0.5',
      },
    },
    MuiSelect: {
      selectMenu: {
        minHeight: controls.control.normal.height,
        lineHeight: controls.control.normal.lineHeight,
        '&[class*="-inputMarginDense"]': {
          minHeight: controls.control.small.height,
          lineHeight: controls.control.small.lineHeight,
          paddingTop: controls.control.small.paddingTop,
          paddingBottom: controls.control.small.paddingBottom,
        },
      },
      outlined: {
        padding: '0 32px 0 14px',
        lineHeight: controls.control.normal.height,
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: controls.control.normal.fontSize,
        '&.Mui-disabled': {
          color: colors.disabled.dark,
        },
      },
    },
    MuiTypography: {
      colorTextSecondary: {
        color: colors.global.document.bodySecondaryTextColor,
      },
    },
    MuiFormControl: {
      marginDense: {
        marginTop: 0,
        marginBottom: 0,
      },
    },
  },
  zIndex: {
    drawer: 1200,
    appBar: 1201,
    modal: 1202,
    snackbar: 2000,
    tooltip: 1204,
  },
  typography: { fontFamily },
  palette: materialPalette,
  customPalette: {
    global: colors.global,
    appHeader: colors.appHeader,
    appSidebar: colors.appSidebar,
    appStatus: colors.appStatus,
    appWorkflow: colors.appWorkflow,
    appPossession: colors.appPossession,
    appCrStatus: colors.appCrStatus,
    appCleansingStatus: colors.appCleansingStatus,
    control: colors.control,
    table: colors.table,
  },
  controls,
});

export default theme;
