import React, { FC, useState, useEffect, useCallback } from 'react';

import { Box, makeStyles, Modal, Fade, Backdrop } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { getAuthToken } from '../../../../services/auth';
import LoadMedia from '../LoadMedia';
import PreviewContent from '../PreviewContent';

type ImagePreviewProps = {
  imageUrl: string | undefined;
  onCloseClick: () => void;
  asModal?: boolean;
  isPreviewSlide?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  imageSlideData?: any;
  isFixWidthModal?: boolean;
};

type ImageObject = {
  width: number;
  height: number;
  orientation: 'square' | 'portrait' | 'landscape';
};

const useStyles = makeStyles((theme) => ({
  imagePreviewClose: {
    width: '44px',
    height: '44px',
    margin: '0 12px 0 auto',
    zIndex: 2,
    cursor: 'pointer',
    color: theme.palette.common.white,
    borderRadius: '50%',
    position: 'relative',
  },
  imagePreviewContainer: {
    position: 'fixed',
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    zIndex: 10,
    top: '48px',
    right: 0,
    bottom: 0,
    left: 0,
    paddingTop: 50,
    backgroundColor: theme.customPalette.global.darkOverlay.backgroundColor,
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      top: 0,
    },
  },

  modalImagePreviewContainer: {
    position: 'absolute',
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    zIndex: 2,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    paddingTop: '50px',
  },
  modalImagePreviewCustom: {
    position: 'absolute',
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    zIndex: 2,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    paddingTop: '50px',
    backgroundColor: theme.customPalette.global.darkOverlay.backgroundColor,
    [theme.breakpoints.up('md')]: {
      width: '41%',
    },
  },
  sliderContainer: {
    overflow: 'hidden',
    clear: 'both',
    height: '100%',
    '& .slick-list': {
      '& img': {
        height: '100%',
      },
      '& .slick-track': {
        '& .slick-slide': {
          display: 'flex',
          flexFlow: 'row nowrap',
          justifyContent: 'center',
          alignItems: 'center',
          '& > div': {
            height: '100%',
            width: '100%',
          },
        },
      },
    },
    '& .slick-next': {
      right: '26px',
      zIndex: 1,
    },
    '& .slick-prev': {
      left: '16px',
      zIndex: 1,
    },
    '& .slick-prev:before, .slick-next:before': {
      fontSize: '30px',
      color: '#b5b5b5',
    },
  },
  sliderWidth: {
    margin: '0 auto',
    [theme.breakpoints.up('md')]: {
      width: '100vh',
      height: '100vh',
    },
  },
  slickSlideContainer: {
    height: '100%',
  },
  slickSlideWrapper: {
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
  },
}));

const ImagePreview: FC<ImagePreviewProps> = (props) => {
  const {
    imageUrl,
    onCloseClick,
    asModal = false,
    isPreviewSlide = false,
    imageSlideData = [],
    isFixWidthModal = false,
  } = props;
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [imageLists, setImageLists] = useState<string[]>([]);

  const imageObject: ImageObject = {
    width: 400,
    height: 400,
    orientation: 'square',
  };

  const selectedIndex = imageSlideData.indexOf(imageUrl);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    initialSlide: selectedIndex,
    adaptiveHeight: true,
    swipe: false,
  };

  useEffect(() => {
    if (imageUrl) {
      setIsModalOpen(true);
    }
  }, [imageUrl]);

  const handleModalClose = () => {
    onCloseClick();
    setIsModalOpen(false);
  };

  const loadMediaSlider = useCallback(
    async (url: string) =>
      new Promise((resolve) => {
        if (!url) {
          resolve(null);
          return;
        }
        if (!url.includes('private')) {
          const newImageUrl = url;

          if (newImageUrl) {
            const img = new Image();
            img.onload = (e: Event) => {
              const imgObj = e.currentTarget as HTMLImageElement;
              let orientation: ImageObject['orientation'] = 'square';

              if (imgObj.width > imgObj.height) {
                orientation = 'landscape';
              } else if (imgObj.width < imgObj.height) {
                orientation = 'portrait';
              }
              resolve({
                url,
                width: imgObj.width,
                height: imgObj.height,
                orientation,
              });
            };

            img.onerror = () => {
              resolve(null);
            };
            img.src = newImageUrl;
          }
        } else {
          (async () => {
            const tokenInfo = getAuthToken();
            const idToken = tokenInfo?.firebaseIdToken;
            if (idToken) {
              const result = await fetch(url, {
                headers: {
                  'x-admin-token': idToken || '',
                },
              })
                .then(async (response) => {
                  const blob = await response.blob();
                  return URL.createObjectURL(blob);
                })
                .catch(() => null);

              resolve(
                result
                  ? {
                      url: result,
                    }
                  : null,
              );
            }
          })();
        }
      }),
    [],
  );

  useEffect(() => {
    (async () => {
      const imageListPromises = imageSlideData.map(async (imgUrl: string) => {
        const result = await loadMediaSlider(imgUrl);
        return result;
      });

      const finalImageList = await Promise.all(imageListPromises);

      if (finalImageList.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setImageLists(finalImageList.filter((img) => !!img).map((img: any) => img.url));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageSlideData]);

  return (
    <>
      {asModal && (
        <Modal
          aria-labelledby="preview-modal-title"
          aria-describedby="preview-modal-description"
          open={isModalOpen}
          onClose={handleModalClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          disableBackdropClick
          BackdropProps={{
            timeout: 500,
          }}>
          <Fade in={isModalOpen}>
            <Box className={classes.modalImagePreviewContainer}>
              {isPreviewSlide ? (
                <Box className={classes.sliderContainer}>
                  <Box className={classes.imagePreviewClose} onClick={handleModalClose}>
                    <CloseIcon fontSize="large" />
                  </Box>
                  <Box className={classes.sliderWidth}>
                    <Slider {...settings}>
                      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                      {imageLists.map((item: any, index: number) => {
                        const rowKey = `image-slide-${index}`;
                        return (
                          <Box key={rowKey}>
                            <PreviewContent currentImageUrl={item} imageObject={imageObject} />
                          </Box>
                        );
                      })}
                    </Slider>
                  </Box>
                </Box>
              ) : (
                <>
                  <Box className={classes.imagePreviewClose} onClick={handleModalClose}>
                    <CloseIcon fontSize="large" />
                  </Box>
                  <LoadMedia imageUrl={imageUrl} onCloseClick={handleModalClose} />
                </>
              )}
            </Box>
          </Fade>
        </Modal>
      )}
      {!asModal && (
        <Box className={isFixWidthModal ? classes.modalImagePreviewCustom : classes.imagePreviewContainer}>
          <>
            {isPreviewSlide ? (
              <Box className={classes.sliderContainer}>
                <Box className={classes.imagePreviewClose} onClick={handleModalClose}>
                  <CloseIcon fontSize="large" />
                </Box>
                <>
                  <Slider {...settings}>
                    {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                    {imageLists.map((item: any, index: number) => {
                      const rowKey = `img-slide-${index}`;
                      return (
                        <Box key={rowKey} className={classes.slickSlideContainer}>
                          <Box className={classes.slickSlideWrapper}>
                            <PreviewContent currentImageUrl={item} imageObject={imageObject} />
                          </Box>
                        </Box>
                      );
                    })}
                  </Slider>
                </>
              </Box>
            ) : (
              <>
                <Box className={classes.imagePreviewClose} onClick={handleModalClose}>
                  <CloseIcon fontSize="large" />
                </Box>
                <LoadMedia imageUrl={imageUrl} onCloseClick={handleModalClose} />
              </>
            )}
          </>
        </Box>
      )}
    </>
  );
};

export default ImagePreview;
