import React, { createContext, FC } from 'react';

import useAuthContext from '../hooks/useAuthContext';
import { AuthContextProps } from '../models';

export const AuthContext = createContext<AuthContextProps>({
  isAuthenticated: false,
  authUser: null,
  setAuthUser: () => null,
  isAuthInitialized: false,
});

export const AuthProvider: FC = (props) => {
  const { children } = props;
  const { Provider } = AuthContext;
  const values = useAuthContext();

  return <Provider value={values}>{children}</Provider>;
};
