import React, { FC, useRef } from 'react';

import { IconButton, TableRow, TableRowProps, TextField } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';

import { ItemBlogSlugResponse, UpdateBlogSlugRequestData } from '../../../../services/blog-slug';
import AppButton from '../../../ui/AppButton';
import AppTableCell from '../../../ui/AppTableCell';

type ItemRowBlogSlugType = {
  classes?: TableRowProps['classes'];
  isCurrentItemInEditMode: boolean;
  isInEditMode: boolean;
  itemRow: ItemBlogSlugResponse;
  onClickSave: (data: UpdateBlogSlugRequestData) => void;
  onClickCancel: () => void;
  onClickEdit: (uid: number) => void;
  onClickDelete: (uid: number) => void;
};

const ItemRowBlogSlug: FC<ItemRowBlogSlugType> = (props) => {
  const {
    classes,
    isCurrentItemInEditMode,
    isInEditMode,
    itemRow,
    onClickSave,
    onClickCancel,
    onClickEdit,
    onClickDelete,
  } = props;

  const inputCategorySlugRef = useRef<HTMLInputElement | null>(null);
  const inputBrandSlugRef = useRef<HTMLInputElement | null>(null);
  const inputModelSlugRef = useRef<HTMLInputElement | null>(null);
  const inputBlogSlugRef = useRef<HTMLInputElement | null>(null);

  return (
    <TableRow classes={classes}>
      <AppTableCell>
        {isCurrentItemInEditMode ? (
          <TextField inputRef={inputCategorySlugRef} defaultValue={itemRow.categorySlug} />
        ) : (
          itemRow.categorySlug
        )}
      </AppTableCell>
      <AppTableCell>
        {isCurrentItemInEditMode ? (
          <TextField inputRef={inputBrandSlugRef} defaultValue={itemRow.brandSlug} />
        ) : (
          itemRow.brandSlug
        )}
      </AppTableCell>
      <AppTableCell>
        {isCurrentItemInEditMode ? (
          <TextField inputRef={inputModelSlugRef} defaultValue={itemRow.modelSlug} />
        ) : (
          itemRow.modelSlug
        )}
      </AppTableCell>
      <AppTableCell>
        {isCurrentItemInEditMode ? <TextField inputRef={inputBlogSlugRef} defaultValue={itemRow.slug} /> : itemRow.slug}
      </AppTableCell>
      <AppTableCell align="right" style={{ height: '69.1px' }}>
        {(!isInEditMode || isCurrentItemInEditMode) && (
          <>
            {isCurrentItemInEditMode ? (
              <>
                <AppButton variant="outlined" onClick={onClickCancel}>
                  Cancel
                </AppButton>
                <AppButton
                  color="primary"
                  onClick={() => {
                    onClickSave({
                      slug: inputBlogSlugRef.current?.value?.toString()?.trim() || '',
                      categorySlug: inputCategorySlugRef.current?.value?.toString()?.trim() || '',
                      brandSlug: inputBrandSlugRef.current?.value?.toString()?.trim() || '',
                      modelSlug: inputModelSlugRef.current?.value?.toString()?.trim() || '',
                    });
                  }}
                  style={{ marginLeft: '6px' }}>
                  Save
                </AppButton>
              </>
            ) : (
              <>
                <IconButton color="primary" onClick={() => onClickEdit(itemRow.id)}>
                  <Edit />
                </IconButton>
                <IconButton onClick={() => onClickDelete(itemRow.id)}>
                  <Delete />
                </IconButton>
              </>
            )}
          </>
        )}
      </AppTableCell>
    </TableRow>
  );
};

export default ItemRowBlogSlug;
