/* eslint-disable import/prefer-default-export */
import { apiRequest } from '../helpers/request';
import { queryParamGenerator } from '../helpers/utils';
import { ApiResponse, WebConfig, WebConfigSearchFilters } from '../models';

const getWebConfigListQueryParam = (param?: WebConfigSearchFilters): string => {
  const reqParam: { [x: string]: string | number } = {
    page: (param?.page || 0) + 1, // plus 1 for index shift between FE component and backend query
    per_page: param?.rowsPerPage || 10,
    sort: param?.sortColumn ? `${param.sortColumn}|${(param?.sortDirection || 'desc').toUpperCase()}` : '',
  };

  // Only search with keyword when it comes with the selected column to be searched
  if (param?.searchKeyword && param?.searchField) {
    reqParam[param.searchField] = param.searchKeyword;
  }

  return queryParamGenerator(reqParam);
};

const getAllWebConfig = async (param?: WebConfigSearchFilters): Promise<ApiResponse<WebConfig[]>> => {
  try {
    const queryParamString = getWebConfigListQueryParam(param);
    const result = await apiRequest<WebConfig[]>(`/admin/web-config?${queryParamString}`);

    return {
      data: result.data,
    };
  } catch (e) {
    return {
      data: null,
      error: 'settings:error.getConfigFailed',
    };
  }
};

const getWebConfigByTitle = async (title: string): Promise<ApiResponse<WebConfig>> => {
  try {
    const result = await apiRequest<WebConfig>(`/admin/web-config/${title}`);

    return {
      data: result.data,
    };
  } catch (e) {
    return {
      data: null,
      error: 'settings:error.getConfigFailed',
    };
  }
};

const saveWebConfig = async (config: WebConfig): Promise<ApiResponse<WebConfig>> => {
  try {
    const result = await apiRequest<WebConfig>(`/admin/web-config`, {
      method: 'POST',
      data: {
        ...config,
      },
    });

    return {
      data: result.data,
    };
  } catch (e) {
    return {
      data: null,
      error: 'settings:error.getConfigFailed',
    };
  }
};

export { getAllWebConfig, getWebConfigByTitle, saveWebConfig };
