/* eslint-disable import/prefer-default-export */
import { apiRequest } from '../helpers/request';
import { queryParamGenerator } from '../helpers/utils';
import { ApiResponse } from '../models';
import {
  GetReportListingListResponse,
  ReportListingData,
  ReportListingFormDataRequest,
  ReportListingSearchFilters,
} from '../models/report-listing.model';

const getReportQueryParam = (param?: Partial<ReportListingSearchFilters>): string => {
  const reqParam: { [x: string]: string | number } = {
    page: (param?.page || 0) + 1,
    per_page: param?.rowsPerPage || 10,
    sort: param?.sortColumn ? `${param.sortColumn}|${(param?.sortDirection || 'desc').toUpperCase()}` : '',
  };

  if (param?.filteringTypes?.length) {
    reqParam.types = param.filteringTypes.join(',');
  }
  if (param?.filteringStatuses?.length) {
    reqParam.adminStatuses = param.filteringStatuses.join(',');
  }
  return queryParamGenerator(reqParam);
};

export const getReportListingList = async (
  param?: ReportListingSearchFilters,
): Promise<ApiResponse<GetReportListingListResponse>> => {
  try {
    const queryParamString = getReportQueryParam(param);
    const result = await apiRequest<ReportListingData[]>(`/admin/item-frauds?${queryParamString}`);

    return {
      data: {
        contents: result.data,
        totalCount: Number(result.headers['x-total-count'] || '0') || result.data.length,
      },
    };
  } catch (e) {
    return {
      data: null,
      error: 'report-listing:message.error.list.requestFailed',
    };
  }
};

export const updateReportListing = async (
  hashId: string,
  formData: ReportListingFormDataRequest,
): Promise<ApiResponse<boolean>> => {
  try {
    const result = await apiRequest<ReportListingData>(`/admin/item-frauds/${hashId}`, {
      method: 'PATCH',
      data: { ...formData },
    });

    return { ...result, data: result.status === 200 };
  } catch (e) {
    return {
      data: null,
      error: 'report-listing:message.error.detail.saveFailed',
    };
  }
};
