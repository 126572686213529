import React, { FC } from 'react';

import { Box, Container, Grid, makeStyles, Typography } from '@material-ui/core';
import YoutubeSearchedForIcon from '@material-ui/icons/YoutubeSearchedFor';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ReportedItemListSearchFormData, ReportItemType, ReportStatus, ReportType } from '../../../../models';
import useAppContainerStyles from '../../../../theme/container.style';
import AppButton from '../../../ui/AppButton';
import AppChipList from '../../../ui/AppChipList';
import AppDatePicker from '../../../ui/AppDatePicker';

export type ReportedItemSearchFormProps = {
  isLoadingData: boolean;
  currentFilters: ReportedItemListSearchFormData | undefined;
  onSearchFormSubmit: (data: ReportedItemListSearchFormData) => void;
};

const reportedItemTypeList = [
  {
    label: 'sns:common.fields.reportedItemTypePost',
    value: ReportItemType.Post,
  },
  {
    label: 'sns:common.fields.reportedItemTypeComment',
    value: ReportItemType.Comment,
  },
];

const reportTypeList = [
  {
    label: 'sns:common.reportType.fraud',
    value: ReportType.Fraud,
  },
  {
    label: 'sns:common.reportType.spam',
    value: ReportType.Spam,
  },
  {
    label: 'sns:common.reportType.inappropriate',
    value: ReportType.Inappropriate,
  },
  {
    label: 'sns:common.reportType.other',
    value: ReportType.Other,
  },
];

const reportStatusList = [
  {
    label: 'sns:common.reportStatus.submitted',
    value: ReportStatus.Submitted,
  },
  {
    label: 'sns:common.reportStatus.approved',
    value: ReportStatus.Approved,
  },
  {
    label: 'sns:common.reportStatus.rejected',
    value: ReportStatus.Rejected,
  },
  {
    label: 'sns:common.reportStatus.markAsDuplicated',
    value: ReportStatus.MarkAsDuplicated,
  },
];

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
  },
  searchBoxContainer: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 500,
    },
  },
  filterHeader: {
    fontWeight: 700,
  },
}));

const ReportedItemSearchForm: FC<ReportedItemSearchFormProps> = (props) => {
  const { currentFilters, isLoadingData, onSearchFormSubmit } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const containerClasses = useAppContainerStyles();
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<ReportedItemListSearchFormData>({
    mode: 'onSubmit',
    defaultValues: {
      startDate: null,
      endDate: null,
      filteringItemTypes: [],
      filteringStatuses: [],
      filteringTypes: [],
    },
  });

  const submitSearchForm = () => {
    if (!isLoadingData) {
      handleSubmit(onSearchFormSubmit)();
    }
  };

  const clearSearchForm = () => {
    if (!isLoadingData) {
      setValue('filteringItemTypes', []);
      setValue('filteringStatuses', []);
      setValue('filteringTypes', []);
      handleSubmit(onSearchFormSubmit)();
    }
  };

  return (
    <Box className={classes.filterContainer}>
      <Container className={containerClasses.container}>
        <Box>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} md container spacing={3}>
              <Grid item xs={12} container spacing={3}>
                <Grid item xs={12} md={4} lg={3}>
                  <Controller
                    name="startDate"
                    control={control}
                    render={({ field }) => (
                      <Box width="100%">
                        <AppDatePicker
                          value={field.value ? field.value.format('YYYY-MM-DD') : null}
                          onChange={field.onChange}
                          disabled={!isLoadingData}
                          placeholder={t('common:label.startDate')}
                          error={!!errors.startDate}
                        />
                      </Box>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Controller
                    name="endDate"
                    control={control}
                    render={({ field }) => (
                      <AppDatePicker
                        value={field.value ? field.value.format('YYYY-MM-DD') : null}
                        onChange={field.onChange}
                        disabled={!isLoadingData}
                        placeholder={t('common:label.endDate')}
                        error={!!errors.endDate}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md="auto">
                <Typography variant="body2" className={classes.filterHeader}>
                  {t('sns:common.label.reportStatus')}
                </Typography>
                <Box display="flex" flexDirection="row" flexWrap="wrap" mt={1}>
                  <Controller
                    control={control}
                    name="filteringStatuses"
                    defaultValue={currentFilters?.filteringStatuses || []}
                    render={({ field }) => (
                      <>
                        <AppChipList
                          items={reportStatusList}
                          selectedValues={field.value}
                          onChange={(values: string[]) => {
                            field.onChange(values);
                            submitSearchForm();
                          }}
                        />
                      </>
                    )}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md="auto">
                <Typography variant="body2" className={classes.filterHeader}>
                  {t('sns:common.label.reportedItemType')}
                </Typography>
                <Box display="flex" flexDirection="row" flexWrap="wrap" mt={1}>
                  <Controller
                    control={control}
                    name="filteringItemTypes"
                    defaultValue={currentFilters?.filteringItemTypes || []}
                    render={({ field }) => (
                      <>
                        <AppChipList
                          items={reportedItemTypeList}
                          selectedValues={field.value}
                          onChange={(values: string[]) => {
                            field.onChange(values);
                            submitSearchForm();
                          }}
                        />
                      </>
                    )}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md="auto">
                <Typography variant="body2" className={classes.filterHeader}>
                  {t('sns:common.label.reportType')}
                </Typography>
                <Box display="flex" flexDirection="row" flexWrap="wrap" mt={1}>
                  <Controller
                    control={control}
                    name="filteringTypes"
                    defaultValue={currentFilters?.filteringStatuses || []}
                    render={({ field }) => (
                      <>
                        <AppChipList
                          items={reportTypeList}
                          selectedValues={field.value}
                          onChange={(values: string[]) => {
                            field.onChange(values);
                            submitSearchForm();
                          }}
                        />
                      </>
                    )}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12} md="auto">
              <AppButton variant="outlined" color="primary" onClick={clearSearchForm} disabled={isLoadingData}>
                <YoutubeSearchedForIcon />
              </AppButton>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default ReportedItemSearchForm;
