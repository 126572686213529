/* eslint-disable import/prefer-default-export */
import { apiKTRequest } from '../helpers/request';
import { apiResponseTransformer } from '../helpers/response';
import { getPagingParameter, queryParamGenerator } from '../helpers/utils';
import {
  ApiResponse,
  KTPageResult,
  ListResponse,
  MasterIndex,
  MasterIndexFormData,
  MasterIndexListSearchFilters,
} from '../models';

type MasterIndexListAxiosResponse = {
  masterIndexes: MasterIndex[];
  page: KTPageResult;
};

/**
 * Get query parameter as string from given search filter
 *
 * @param param Master index list search filter
 * @returns URL query parameter as string
 */
const getMasterIndexListQueryParam = (param?: MasterIndexListSearchFilters): string => {
  const reqParam = getPagingParameter(param, true);

  // Only search with keyword when it comes with the selected column to be searched
  if (param?.searchKeyword?.trim()) {
    reqParam.q = param.searchKeyword;
  }

  return queryParamGenerator(reqParam, true);
};

/**
 * Get master index list from given search filter
 *
 * @param param Master index list search filter
 * @returns Master index list request result as promise function
 */
export const getMasterIndexList = async (
  param?: MasterIndexListSearchFilters,
): Promise<ApiResponse<ListResponse<MasterIndex>>> => {
  const qString = getMasterIndexListQueryParam(param);
  const result = await apiKTRequest<MasterIndexListAxiosResponse>(`/api/master_index?${qString}`);

  return apiResponseTransformer<MasterIndexListAxiosResponse, ListResponse<MasterIndex>>(
    result,
    'master-data:error.masterIndex.requestListFailed',
    (data: MasterIndexListAxiosResponse) => ({
      list: data.masterIndexes,
      totalCount: data.page.totalElements,
    }),
  );
};

/**
 * Get master index list from given name
 *
 * @param keyword Keyword to search by name
 * @returns Master index list request result as promise function
 */
export const getMasterIndexByName = async (keyword: string): Promise<ApiResponse<ListResponse<MasterIndex>>> => {
  const result = await apiKTRequest<MasterIndexListAxiosResponse>(`/api/master_index?q=${keyword}`);

  return apiResponseTransformer<MasterIndexListAxiosResponse, ListResponse<MasterIndex>>(
    result,
    'master-data:error.masterIndex.requestListFailed',
    (data: MasterIndexListAxiosResponse) => ({
      list: data.masterIndexes,
      totalCount: data.page.totalElements,
    }),
  );
};

/**
 * Get master index from its UID
 *
 * @param uid UID to be searched for in master index
 * @returns Master index request result as promise function
 */
export const getMasterIndexByUid = async (uid: string): Promise<ApiResponse<MasterIndex>> => {
  const result = await apiKTRequest<MasterIndex>(`/api/master_index/${uid}`);

  return apiResponseTransformer(result, 'master-index:error.list.requestMasterIndexFailed');
};

/**
 * Create new master index
 *
 * @param data Master index form data to be created
 * @returns Master index create request result as promise function
 */
export const createMasterIndex = async (data: MasterIndexFormData): Promise<ApiResponse<MasterIndex>> => {
  const result = await apiKTRequest<MasterIndex>(`/admin_manager/category_index`, {
    method: 'POST',
    data,
  });

  return apiResponseTransformer(result, 'master-data:error.masterIndex.createFailed');
};

/**
 * Update master index
 *
 * @param id Master index ID
 * @param data Master index form data to be updated
 * @returns Master index update request result as promise function
 */
export const updateMasterIndex = async (id: number, data: MasterIndexFormData): Promise<ApiResponse<MasterIndex>> => {
  const result = await apiKTRequest<MasterIndex>(`/admin_manager/category_index`, {
    method: 'PATCH',
    data: {
      ...data,
      id,
    },
  });

  return apiResponseTransformer(result, 'master-data:error.masterIndex.createFailed');
};
