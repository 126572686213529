/* eslint-disable import/prefer-default-export */

export const validateSlug = (value: string, message = 'validation:invalidSlug'): string | true => {
  if (value && /^[a-z0-9]+(-[a-z0-9]+)*$/gi.test(value)) {
    return true;
  }

  return message;
};

export const validateNonZero = (value: number, message = 'validation:invalidId'): string | true => {
  if (value !== 0) {
    return true;
  }

  return message;
};

export const validateNonZeroLeadNumber = (
  value: string | number,
  message = 'validation:invalidNonZeroLeadNumber',
): string | undefined => {
  const testValue = typeof value === 'number' ? value.toString() : value;
  if (/^[1-9]\d*$/gi.test(testValue)) {
    return undefined;
  }

  return message;
};
