import React, { FC, useContext, useEffect, useState } from 'react';

import { Switch, useLocation, useRouteMatch } from 'react-router-dom';

import Layout from './Layout';
import { AppGlobalUiContext } from '../../context/AppGlobalUiContext';
import { MainModuleProvider } from '../../context/MainModuleContext';
import { RouteWithSubRoutes } from '../../helpers/RenderRoutes';
import { RouteConfig } from '../../models';

export type ModuleMainPageProps = {
  routeFunctions: (path: string) => RouteConfig[];
  pageTitle?: string;
};

const ModuleMainPage: FC<ModuleMainPageProps> = (props) => {
  const { routeFunctions, pageTitle } = props;
  const { path } = useRouteMatch();
  const location = useLocation();
  const { appBarTitle, setAppBarTitle, setAppBarBackButtonUrl } = useContext(AppGlobalUiContext);
  const routeConfig = routeFunctions(path);
  const [currentRoute, setCurrentRoute] = useState<RouteConfig | undefined>(undefined);

  useEffect(() => {
    if (currentRoute) {
      if (currentRoute.title) {
        setAppBarTitle(currentRoute.title);
      }

      if (location.pathname === path) {
        setAppBarBackButtonUrl('');
      } else if (currentRoute.backUrl) {
        let backUrlLink = currentRoute.backUrl;
        const routePathArr = currentRoute.path.split(/\//gi).filter((fragment) => fragment);
        const pathNameArr = location.pathname.split(/\//gi).filter((fragment) => fragment);

        if (routePathArr?.filter((fragment) => /^:(.+)$/gi.test(fragment)).length) {
          routePathArr.forEach((fragment: string, index: number) => {
            if (/^:(.+)$/gi.test(fragment)) {
              const value = pathNameArr[index];
              const valueRegEx = new RegExp(fragment, 'g');
              backUrlLink = backUrlLink.replace(valueRegEx, value);
            }
          });
        }

        setAppBarBackButtonUrl(backUrlLink);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoute]);

  const onRouteChange = (route: RouteConfig | undefined) => {
    if (route?.key !== currentRoute?.key) {
      setCurrentRoute(route);
    }
  };

  return (
    <MainModuleProvider routeConfig={routeConfig} onRouteChange={onRouteChange}>
      <Layout pageTitle={appBarTitle || pageTitle}>
        <Switch>
          {routeFunctions(path).map((route) => {
            const routeExact = typeof route.exact === 'undefined' ? true : route.exact;
            return <RouteWithSubRoutes key={route.key} path={route.path} route={route} exact={routeExact} />;
          })}
        </Switch>
      </Layout>
    </MainModuleProvider>
  );
};

export default ModuleMainPage;
