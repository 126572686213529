import React, { FC, useCallback, useState } from 'react';

import { TableRow } from '@material-ui/core';
import clsx from 'clsx';

import { AppTableConditions, TableColumn } from '../../../../../models';
import { AbTestingData } from '../../../../../models/ab-testing.model';
import useAppTableStyles from '../../../../../theme/table.style';
import AppTableCell from '../../../../ui/AppTableCell';
import AppTableList from '../../../../ui/AppTableList';

export type FeatureGroupByUserListTableProps = {
  currentConditions: AppTableConditions;
  totalUserFeatureList: number;
  isLoadingData: boolean;
  userFeatureList: AbTestingData[];
  onFetch: () => void;
  onTableConditionsChanged: (values: AppTableConditions) => void;
};

const featureTableColumns: TableColumn[] = [
  { name: 'numberList', label: 'ab-testing:list.columns.numberList', width: 60, unsortable: true },
  { name: 'users', label: 'ab-testing:list.columns.users', width: 140, unsortable: true },
  { name: 'feature', label: 'ab-testing:list.columns.feature', width: 140 },
];

const FeatureGroupByUserListTable: FC<FeatureGroupByUserListTableProps> = (props) => {
  const { totalUserFeatureList, isLoadingData, currentConditions, userFeatureList, onTableConditionsChanged } = props;
  const tableClasses = useAppTableStyles();
  const [tableConditions, setTableConditions] = useState<AppTableConditions>(currentConditions);

  const onTableConditionChangedHandler = useCallback(
    (values: AppTableConditions) => {
      if (JSON.stringify(values) !== JSON.stringify(tableConditions)) {
        setTableConditions(values);
        onTableConditionsChanged(values);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableConditions],
  );

  return (
    <>
      <AppTableList
        {...tableConditions}
        aria-label="user-feature-list"
        columns={featureTableColumns}
        totalDataCount={totalUserFeatureList}
        hasPagination
        onTableConditionChanged={onTableConditionChangedHandler}
        isLoading={isLoadingData}
        tableBodyContent={
          userFeatureList.length > 0 && (
            <>
              {userFeatureList.map((item: AbTestingData, index: number) => {
                const itemKey = `item-${index}`;
                return (
                  <TableRow
                    hover
                    key={`${itemKey}`}
                    classes={{ root: clsx(tableClasses.tableRow, tableClasses.clickableTableRow) }}>
                    <AppTableCell>{index + 1} </AppTableCell>
                    <AppTableCell>{item.userHashId}</AppTableCell>
                    <AppTableCell>{item.features}</AppTableCell>
                  </TableRow>
                );
              })}
            </>
          )
        }
      />
    </>
  );
};

export default FeatureGroupByUserListTable;
