/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';

import { MainModuleContextProps, RouteConfig } from '../models';

export default (routeConfig: RouteConfig[]): MainModuleContextProps => {
  const [currentRoute, setCurrentRoute] = useState<RouteConfig | undefined>(undefined);
  const [sharedData, setSharedData] = useState<any>(null);

  const setActiveRoute = (path: string) => {
    const matchedPath = routeConfig.find((route) => route.path === path);
    setCurrentRoute(matchedPath || undefined);
  };

  return {
    routeConfig,
    currentRoute,
    setActiveRoute,
    sharedData,
    setSharedData,
  };
};
