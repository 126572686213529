/* eslint-disable import/prefer-default-export */

import { ApiResponse, AxiosApiResponse, KTErrorItem } from '../models/common';

const getCustomKTErrorCode = (message?: string): string => {
  if (!message) {
    return '';
  }

  if (/(duplicate)\s(key)/gi.test(message)) {
    return 'Duplicate';
  }

  return '';
};

const getCustomKTErrorField = (message?: string): string => {
  if (!message) {
    return '';
  }

  if (/uid/gi.test(message)) {
    return 'uid';
  }

  return '';
};

export const getKTResponseErrorMessage = (code: string, field: string, defaultMessage?: string): KTErrorItem | null => {
  const errCode = code || getCustomKTErrorCode(defaultMessage);
  const errField = field || getCustomKTErrorField(defaultMessage);

  if (!errCode && !errField) {
    return null;
  }

  return {
    field: errField,
    code: errCode,
    message: `validation:ktResponseCode.${errCode}`,
    param: {
      field: errField,
      defaultMessage: defaultMessage || '',
    },
  };
};

export const responseApiError = <T>(errorMessage: string, validationErrors?: KTErrorItem[]): ApiResponse<T> => ({
  data: null,
  error: errorMessage,
  validationErrors,
});

export const apiResponseTransformer = <K, N>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  result: AxiosApiResponse<any>,
  failedMessage: string,
  dataTransformer?: (data: K) => N,
): ApiResponse<N> => {
  if (result.validationErrors) {
    return responseApiError(failedMessage, result.validationErrors);
  }

  if (!result.data) {
    return responseApiError(failedMessage);
  }

  if (typeof dataTransformer === 'function') {
    return {
      data: dataTransformer(result.data),
    };
  }

  return {
    data: result.data,
  };
};
