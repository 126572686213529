import React, { FC } from 'react';

import { Chip, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { OrderStateWorkflow } from '../../../../../models/order-order.model';

export type OrderStatusBadgeProps = {
  status: OrderStateWorkflow;
  isOrderByCr?: boolean;
};

const useStyles = makeStyles((theme) => ({
  statusChip: {
    height: '26px',
    lineHeight: '26px',
  },
  stateCanceled: {
    backgroundColor: theme.customPalette.appWorkflow.orderStateCanceled.main,
    color: theme.customPalette.appWorkflow.orderStateCanceled.contrastText,
  },
  stateExpired: {
    backgroundColor: theme.customPalette.appWorkflow.orderStateExpired.main,
    color: theme.customPalette.appWorkflow.orderStateExpired.contrastText,
  },
  stateFailed: {
    backgroundColor: theme.customPalette.appWorkflow.orderStateFailed.main,
    color: theme.customPalette.appWorkflow.orderStateFailed.contrastText,
  },
  stateOpen: {
    backgroundColor: theme.customPalette.appWorkflow.orderStateOpen.main,
    color: theme.customPalette.appWorkflow.orderStateOpen.contrastText,
  },
  statePaid: {
    backgroundColor: theme.customPalette.appWorkflow.orderStatePaid.main,
    color: theme.customPalette.appWorkflow.orderStatePaid.contrastText,
  },
  stateRefunded: {
    backgroundColor: theme.customPalette.appWorkflow.orderStateRefunded.main,
    color: theme.customPalette.appWorkflow.orderStateRefunded.contrastText,
  },
}));

const SellerApplicationStatusBadge: FC<OrderStatusBadgeProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { status, isOrderByCr } = props;

  return (
    <Chip
      classes={{ root: classes.statusChip }}
      className={clsx({
        [classes.stateCanceled]: status === OrderStateWorkflow.canceled,
        [classes.stateExpired]: status === OrderStateWorkflow.expired,
        [classes.stateFailed]: status === OrderStateWorkflow.failed,
        [classes.stateOpen]: status === OrderStateWorkflow.open,
        [classes.statePaid]: status === OrderStateWorkflow.paid,
        [classes.stateRefunded]: status === OrderStateWorkflow.refunded,
      })}
      label={
        isOrderByCr ? t('order:orderList.filter.orderWaitingForApproveState') : t(`order:status.${status}`).toString()
      }
    />
  );
};

export default SellerApplicationStatusBadge;
