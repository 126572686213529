import React, { FC } from 'react';

import { Box, makeStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import MessageItem from './MessageItem';
import useAuthContext from '../../../../hooks/useAuthContext';
import { Message } from '../../../../models/message';

export type MessageItemListProps = {
  messageItemList: Message[];
};

const useStyles = makeStyles(() => ({
  chatRoom: {
    display: 'block',
    overflowY: 'scroll',
    height: '620px',
  },
}));

const MessageItemList: FC<MessageItemListProps> = (props) => {
  const { hashId } = useParams<{ hashId: string }>();
  const { messageItemList } = props;
  const classes = useStyles();
  const { authUser } = useAuthContext();

  return (
    <>
      {authUser && (
        <Box px={3} py={3} className={classes.chatRoom}>
          {messageItemList.map((item) => (
            <Box key={item.id}>
              <MessageItem message={item} hashId={hashId} />
            </Box>
          ))}
        </Box>
      )}
    </>
  );
};

export default MessageItemList;
