import React, { FC, useState } from 'react';

import { TableRow } from '@material-ui/core';
import clsx from 'clsx';
import { useRouteMatch, useHistory } from 'react-router-dom';

import { AppTableConditions, Brand, TableColumn } from '../../../../../models';
import useAppTableStyles from '../../../../../theme/table.style';
import AppTableCell from '../../../../ui/AppTableCell';
import AppTableList from '../../../../ui/AppTableList';

export type BrandListTableProps = {
  currentConditions: AppTableConditions;
  totalBrands: number;
  isLoadingData: boolean;
  brandList: Brand[];
  onTableConditionsChanged: (values: AppTableConditions) => void;
};

const brandTableColumns: TableColumn[] = [
  { name: 'uid', label: 'master-data:common.field.uid', width: 120 },
  { name: 'name', label: 'master-data:list.brand.columns.name', width: 200 },
  { name: 'thaiName', label: 'master-data:common.field.thaiName', width: 140 },
  { name: 'slug', label: 'master-data:common.field.slug', width: 400 },
];

const BrandListTable: FC<BrandListTableProps> = (props) => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { totalBrands, isLoadingData, currentConditions, brandList, onTableConditionsChanged } = props;
  const tableClasses = useAppTableStyles();
  const [tableConditions, setTableConditions] = useState<AppTableConditions>(currentConditions);

  const onTableConditionChangedHandler = (values: AppTableConditions) => {
    if (JSON.stringify(values) !== JSON.stringify(tableConditions)) {
      setTableConditions(values);
      onTableConditionsChanged(values);
    }
  };

  const openBrandDetailPage = (brand: Brand) => {
    history.push(`${url}/${brand.uid}`);
  };

  return (
    <>
      <AppTableList
        {...tableConditions}
        aria-label="brand-list"
        columns={brandTableColumns}
        totalDataCount={totalBrands}
        hasPagination
        onTableConditionChanged={onTableConditionChangedHandler}
        isLoading={isLoadingData}
        tableBodyContent={
          brandList.length > 0 && (
            <>
              {brandList.map((brand: Brand) => (
                <TableRow
                  hover
                  key={brand.uid}
                  classes={{ root: clsx(tableClasses.tableRow, tableClasses.clickableTableRow) }}
                  onClick={() => openBrandDetailPage(brand)}>
                  <AppTableCell>{brand.uid}</AppTableCell>
                  <AppTableCell>{brand.name}</AppTableCell>
                  <AppTableCell>{brand.thaiName}</AppTableCell>
                  <AppTableCell>{brand.slug}</AppTableCell>
                </TableRow>
              ))}
            </>
          )
        }
      />
    </>
  );
};

export default BrandListTable;
