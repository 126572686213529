import React, { ReactNode, useEffect, useState, ReactElement, MouseEvent } from 'react';

import { Box, IconButton, makeStyles, TableRow } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import clsx from 'clsx';
import { UnpackNestedValue, UseFormHandleSubmit } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import usePrivilege from '../../../../hooks/usePrivilege';
import { AdminActionField, TableColumn } from '../../../../models';
import useAppTableStyles from '../../../../theme/table.style';
import useTypographyStyles from '../../../../theme/typography.style';
import AppButton from '../../../ui/AppButton';
import AppDialog, { AppDialogProps } from '../../../ui/AppDialog';
import AppTableCell from '../../../ui/AppTableCell';
import AppTableList, { AppTableListProps } from '../../../ui/AppTableList';

export type ListItemEditorProps<T, K> = {
  listData: T[];
  listColumns: TableColumn[];
  listTableRowKeyPrefix: string;
  listTableCellsContent: (item: T) => ReactNode;
  dialogFormContent: (item: T | undefined) => ReactNode;
  setEditFormValues: (item: T | undefined) => void;
  handleSubmit: UseFormHandleSubmit<K>;
  dialogSaveItemTransformer?: (item: UnpackNestedValue<K>) => T;
  onSave: (index: number, item: T) => void;
  onDelete: (index: number, item?: T) => void;
  deleteConfirmMessage?: string;
  appTableListProps?: Partial<AppTableListProps>;
  saveAppDialogProps?: Partial<AppDialogProps>;
  adminActionField: AdminActionField;
};

const useStyles = makeStyles(() => ({
  dialogRoot: {
    width: '100%',
  },
  dialogSm: {
    maxWidth: '600px',
  },
  dialogMd: {
    maxWidth: '700px',
  },
  dialogLg: {
    maxWidth: '900px',
  },
  tableDeleteIconButton: {},
}));

const ListItemEditor = <T, K>(props: ListItemEditorProps<T, K>): ReactElement => {
  const {
    listData,
    onSave,
    listColumns,
    listTableRowKeyPrefix,
    listTableCellsContent,
    dialogSaveItemTransformer,
    setEditFormValues,
    dialogFormContent,
    handleSubmit,
    onDelete,
    adminActionField,
    appTableListProps,
    saveAppDialogProps,
    deleteConfirmMessage = 'settings:message.config.itemDeleteWarning',
  } = props;
  const { t } = useTranslation();
  const tableClasses = useAppTableStyles();
  const typoClasses = useTypographyStyles();
  const classes = useStyles();
  const { canPerform } = usePrivilege();
  const [data, setData] = useState<T[]>(listData);
  const tableColumns = [...listColumns, { name: '', label: '', width: 80, unsortable: true }];
  const [selectedItem, setSelectedItem] = useState<T | undefined>(undefined);
  const [selectedItemIndex, setSelectedItemIndex] = useState<number>(-1);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const canCreate = canPerform(adminActionField, 'create');
  const canUpdate = canPerform(adminActionField, 'update');
  const canDelete = canPerform(adminActionField, 'delete');

  useEffect(() => {
    setData(listData);
  }, [listData]);

  const dialogTitle = (): string => {
    if (!canCreate && !canUpdate) {
      return 'common:dialog.title.detail';
    }

    return `common:dialog.title.${selectedItem ? 'edit' : 'add'}`;
  };

  const dialogOkButtonText = (): string => {
    if (!canCreate && !canUpdate) {
      return 'common:button.close';
    }

    return `common:button.${selectedItem ? 'edit' : 'add'}`;
  };

  const closeDialog = () => {
    setSelectedItemIndex(-1);
    setIsDialogOpen(false);
  };

  const closeDeleteConfirmDialog = () => {
    setSelectedItemIndex(-1);
    setIsDeleteDialogOpen(false);
  };

  const addItem = () => {
    setSelectedItemIndex(-1);
    setSelectedItem(undefined);
    setEditFormValues(undefined);
    setIsDialogOpen(true);
  };

  const editListItem = (index: number) => {
    setSelectedItemIndex(index);

    const item = data[index];
    setSelectedItem(item);
    setEditFormValues(item);
    setIsDialogOpen(true);
  };

  const openDeleteConfirmDialog = (e: MouseEvent, index: number, selectedData: T) => {
    e.stopPropagation();
    setSelectedItemIndex(index);
    setSelectedItem(selectedData);
    setIsDeleteDialogOpen(true);
  };

  const saveListItem = (editData: UnpackNestedValue<K>) => {
    const editingItem =
      typeof dialogSaveItemTransformer === 'function' ? dialogSaveItemTransformer(editData) : (editData as T);
    if ((canCreate || canUpdate) && typeof onSave === 'function') {
      onSave(selectedItemIndex, editingItem);
    }
    closeDialog();
  };

  const onSaveListItemClick = () => {
    handleSubmit(saveListItem)();
  };

  const onDeleteConfirm = () => {
    if (canDelete && typeof onDelete === 'function') {
      onDelete(selectedItemIndex, selectedItem);
    }
    closeDeleteConfirmDialog();
  };

  return (
    <>
      <Box mt={3}>
        {canCreate && (
          <Box mb={3}>
            <AppButton color="primary" variant="outlined" onClick={addItem} startIcon={<AddIcon fontSize="small" />}>
              {t('common:button.add')}
            </AppButton>
          </Box>
        )}

        <AppTableList
          {...appTableListProps}
          columns={tableColumns}
          tableBodyContent={data.map((item: T, idx) => {
            const itemKey = `${listTableRowKeyPrefix}${idx}`;

            return (
              <TableRow
                hover
                key={itemKey}
                classes={{ root: clsx(tableClasses.tableRow, tableClasses.clickableTableRow) }}
                onClick={() => editListItem(idx)}>
                {listTableCellsContent(item)}
                <AppTableCell>
                  {canDelete && (
                    <IconButton
                      aria-label="delete"
                      onClick={(e: MouseEvent) => openDeleteConfirmDialog(e, idx, item)}
                      disableRipple
                      disableFocusRipple
                      size="small">
                      <DeleteIcon className={typoClasses.errorText} />
                    </IconButton>
                  )}
                </AppTableCell>
              </TableRow>
            );
          })}
        />
      </Box>
      <AppDialog
        open={isDialogOpen}
        onClose={closeDialog}
        onOkClick={onSaveListItemClick}
        title={dialogTitle()}
        okButtonText={dialogOkButtonText()}
        okButtonColor={canCreate || canUpdate ? 'primary' : 'default'}
        okButtonVariant={canCreate || canUpdate ? 'contained' : 'text'}
        cancelButtonText={canCreate || canUpdate ? 'common:button.cancel' : ''}
        {...saveAppDialogProps}
        dialogProps={{
          disableBackdropClick: true,
          classes: {
            paper: classes.dialogRoot,
            paperWidthSm: classes.dialogSm,
            paperWidthMd: classes.dialogMd,
            paperWidthLg: classes.dialogLg,
          },
        }}>
        <Box>{dialogFormContent}</Box>
      </AppDialog>
      <AppDialog
        open={isDeleteDialogOpen}
        title="common:dialog.title.confirm"
        cancelButtonText="common:button.cancel"
        okButtonText="common:button.delete"
        okButtonColor="error"
        onClose={closeDeleteConfirmDialog}
        onOkClick={onDeleteConfirm}>
        {t(deleteConfirmMessage)}
      </AppDialog>
    </>
  );
};

export default ListItemEditor;
