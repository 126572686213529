/* eslint-disable import/prefer-default-export */
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const removeDuplicatesByKey = (array: any[], key: string) => {
  const uniqueKeys = new Set();
  return array.filter((obj) => {
    if (!uniqueKeys.has(obj[key])) {
      uniqueKeys.add(obj[key]);
      return true;
    }
    return false;
  });
};
