import { initializeApp, getApps } from 'firebase/app';
import { browserLocalPersistence, getAuth, setPersistence } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const FIREBASE_APP_NAME = 't2h-crm';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const FS_DB_COLLECTION = {
  users: 'users',
  posts: 'posts',
  comments: 'comments',
  tags: 'tags',
  likes: 'likes',
  followers: 'followers',
  reports: 'postReports',
  messageGroups: 'messageGroups',
};

const currentApp = getApps();
const existingApp = currentApp.find((app) => app.name === FIREBASE_APP_NAME);
const firebaseApp = existingApp || initializeApp(firebaseConfig, FIREBASE_APP_NAME);
const firestoreDb = getFirestore(firebaseApp);
const firebaseAuth = getAuth(firebaseApp);

if (firebaseAuth) {
  (async () => {
    await setPersistence(firebaseAuth, browserLocalPersistence);
  })();
}

// eslint-disable-next-line import/prefer-default-export
export { firebaseConfig, FS_DB_COLLECTION, FIREBASE_APP_NAME, firebaseApp, firestoreDb, firebaseAuth };
