/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import { Controller, UseFormReturn } from 'react-hook-form';

import { AppGlobalUiContext } from '../../../../context/AppGlobalUiContext';
import { EMOJI_PATTERN, SPECIAL_CHAR } from '../../../../helpers/constants';
import { KTCategoryPathItem } from '../../../../models';
import { getKTBrandsByCategoryUID } from '../../../../services';
import AppFormControl from '../../../ui/AppFormControl';
import ModalSelectValue, { ModalSelectValueProps } from '../ModalSelectValue';

const MAX_TEXT_LENGTH = 300;

interface FieldBrandProps {
  rootCategoryUid: number;
  itemPathBrand?: KTCategoryPathItem;
  itemPathBrandText?: KTCategoryPathItem;
  currentValueBrand: string;
  formHolder: UseFormReturn<any>;
  isEditingForm: boolean;
  isBrandText: boolean;
  onSetValue?: (data: { name?: string; value?: any; uid?: number } & any) => void;
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const FieldBrand = (props: FieldBrandProps) => {
  const {
    rootCategoryUid,
    itemPathBrand,
    itemPathBrandText,
    onSetValue,
    currentValueBrand,
    formHolder,
    isEditingForm,
    isBrandText,
  } = props;

  const { showSnackbar } = useContext(AppGlobalUiContext);
  const [dataList, setDataList] = React.useState<ModalSelectValueProps['dataList']>([]);
  const [currentValuePreview, setCurrentValuePreview] = React.useState<string | number | null>();
  const [isModalSelectDataOpen, setIsModalSelectDataOpen] = React.useState(false);
  const [isLoadingDataList, setIsLoadingDataList] = React.useState(false);
  const [isLoadingDataListError, setIsLoadingDataListError] = React.useState(false);
  const [brandType, setBrandType] = React.useState<'typeSelect' | 'typeText'>(isBrandText ? 'typeText' : 'typeSelect');

  const {
    control,
    setValue,
    formState: { errors },
  } = formHolder;

  const handleSetKTBrandsToDataList = async (categoryUid: string) => {
    if (dataList.length === 0) {
      setIsLoadingDataList(true);
      try {
        const result = await getKTBrandsByCategoryUID(categoryUid);
        if (result.data) {
          const resultMapData = result.data.list.map((item: any) => ({
            ...item,
            name: `${item.name}${item.thaiName ? ` / ${item.thaiName}` : ''}`,
            value: item.slug,
          }));
          setDataList(resultMapData);
        }
        setIsLoadingDataList(false);
        setIsLoadingDataListError(false);
      } catch (error: any) {
        showSnackbar(`error: ${error.message}`, 'error');
        setDataList([]);
        setIsLoadingDataList(false);
        setIsLoadingDataListError(true);
      }
    }
  };

  const handleSetCurrentValuePreview = (value: string | number, currentDataList: ModalSelectValueProps['dataList']) => {
    if (value) {
      if (currentDataList?.length > 0) {
        const currentData = currentDataList?.find((item) => item.value === value);

        setCurrentValuePreview(currentData ? currentData?.name : value);
      } else {
        setCurrentValuePreview(value);
      }
    } else {
      setCurrentValuePreview('');
    }
  };

  const handleSetValue = (data: any) => {
    if (onSetValue) {
      onSetValue(data);
    }
    setValue(data?.name, data?.value);
    handleSetCurrentValuePreview(data?.value, dataList);
  };

  React.useEffect(() => {
    handleSetKTBrandsToDataList(rootCategoryUid.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rootCategoryUid]);

  React.useEffect(() => {
    handleSetCurrentValuePreview(currentValueBrand, dataList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataList]);

  React.useEffect(() => {
    if (brandType === 'typeSelect' && itemPathBrandText?.masterIndex?.slug) {
      setValue(itemPathBrandText?.masterIndex?.slug, '');
    } else if (brandType === 'typeText') {
      handleSetValue({ name: itemPathBrand?.masterIndex.slug, value: null, uid: null });
      setCurrentValuePreview('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandType, setValue]);

  if (itemPathBrand === undefined && itemPathBrandText === undefined) {
    return null;
  }

  const isRequired = Boolean(itemPathBrand?.optional) !== true || Boolean(itemPathBrandText?.optional) !== true;

  return (
    <>
      {itemPathBrand && itemPathBrandText && (
        <RadioGroup row defaultValue="typeSelect" value={brandType}>
          <FormControlLabel
            value="typeSelect"
            control={<Radio onChange={() => setBrandType('typeSelect')} color="primary" />}
            label={`เลือก${itemPathBrand?.masterIndex.thaiName}`}
            labelPlacement="end"
          />
          <FormControlLabel
            value="typeText"
            control={<Radio onChange={() => setBrandType('typeText')} color="primary" />}
            label={itemPathBrandText?.masterIndex.thaiName}
            labelPlacement="end"
          />
        </RadioGroup>
      )}

      {brandType === 'typeSelect' && itemPathBrand && dataList.length > 0 && (
        <Box mb={2}>
          <Typography>
            {`เลือก${itemPathBrand?.masterIndex.thaiName} ${isRequired && dataList.length > 0 ? '*' : ''}`}
          </Typography>
          <>
            {/* eslint-disable-next-line no-nested-ternary */}
            {isLoadingDataListError ? (
              <Button
                style={{ width: '80%' }}
                variant="outlined"
                size="small"
                color="secondary"
                onClick={async () => {
                  if (isEditingForm) {
                    handleSetKTBrandsToDataList(rootCategoryUid.toString());
                  }
                }}
                disabled={isLoadingDataList}>
                <Box display="flex" alignItems="center" justifyContent="center">
                  {isLoadingDataList ? (
                    <CircularProgress size={16} color="secondary" />
                  ) : (
                    <InfoIcon color="secondary" fontSize="default" />
                  )}
                  <Typography style={{ paddingLeft: 2 }} variant="subtitle1">
                    {isLoadingDataList ? 'กำลังโหลด...' : 'โหลดอีกครั้ง'}
                  </Typography>
                </Box>
              </Button>
            ) : isLoadingDataList ? (
              <Box mt={2}>
                <CircularProgress size={20} />
              </Box>
            ) : (
              <Button
                style={{ width: '80%' }}
                onClick={async () => {
                  if (isEditingForm) {
                    setIsModalSelectDataOpen(true);
                  }
                }}>
                <Controller
                  name={itemPathBrand.masterIndex.slug}
                  control={control}
                  defaultValue={itemPathBrand.masterIndex.slug}
                  rules={{
                    required:
                      isRequired && dataList.length > 0 ? `โปรดเลือก${itemPathBrand.masterIndex.thaiName}` : false,
                  }}
                  render={() => (
                    <TextField
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      name={`preview-${itemPathBrand.masterIndex.slug}`}
                      placeholder={
                        itemPathBrand.masterIndex.placeholder || `เลือก${itemPathBrand.masterIndex.thaiName}`
                      }
                      value={currentValuePreview}
                      error={!!errors[itemPathBrand.masterIndex.slug]?.message}
                      style={{ pointerEvents: 'none' }}
                      disabled={!isEditingForm}
                    />
                  )}
                />
                <ArrowDropDownIcon style={{ margin: 'auto', fontSize: '24px' }} color="primary" />
              </Button>
            )}
            {currentValueBrand && isEditingForm && (
              <Button
                variant="outlined"
                onClick={() => {
                  handleSetValue({ name: itemPathBrand.masterIndex.slug, value: null, uid: null });
                  setCurrentValuePreview('');
                }}>
                <CloseIcon color="primary" />
              </Button>
            )}
            {errors[itemPathBrand.masterIndex.slug]?.message && (
              <Typography style={{ marginTop: 2 }} color="error" variant="subtitle1">
                {errors[itemPathBrand.masterIndex.slug]?.message}
              </Typography>
            )}
            <ModalSelectValue
              isOpen={isModalSelectDataOpen}
              selectedValue={currentValueBrand}
              title={itemPathBrand.masterIndex.thaiName}
              dataList={dataList}
              onClickItem={(data: any) => {
                handleSetCurrentValuePreview(data.value, dataList);
                handleSetValue({ name: itemPathBrand.masterIndex.slug, value: data.value, uid: data.uid });
              }}
              handleClose={() => {
                setIsModalSelectDataOpen(false);
              }}
            />
          </>
        </Box>
      )}

      {brandType === 'typeText' && itemPathBrandText && (
        <Box mb={2}>
          <Typography style={{ marginBottom: '6px' }}>{`ยี่ห้ออื่นๆ ระบุเอง ${
            !itemPathBrandText.optional ? '*' : ''
          }`}</Typography>
          {itemPathBrandText && (
            <>
              <Controller
                name={itemPathBrandText?.masterIndex?.slug}
                control={control}
                render={({ field }) => (
                  <AppFormControl>
                    <TextField
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      placeholder={`ระบุ${itemPathBrandText?.masterIndex?.thaiName}`}
                      error={errors[itemPathBrandText?.masterIndex?.slug]?.message}
                      disabled={!isEditingForm}
                      {...field}
                    />
                  </AppFormControl>
                )}
                rules={{
                  required:
                    isRequired || dataList.length <= 0 ? `โปรดเลือก${itemPathBrandText.masterIndex.thaiName}` : false,
                  maxLength: {
                    value: MAX_TEXT_LENGTH,
                    message: `ต้องไม่เกิน ${MAX_TEXT_LENGTH} ตัวอักษร`,
                  },
                  // eslint-disable-next-line consistent-return
                  validate: async (value) => {
                    if (SPECIAL_CHAR.test(value) || EMOJI_PATTERN.test(value)) {
                      return `ไม่สามารถใช้ อักขระพิเศษ ${SPECIAL_CHAR.source} และ Emoji`;
                    }
                  },
                }}
              />
              {errors[itemPathBrandText.masterIndex.slug]?.message && (
                <Typography style={{ marginTop: 2 }} color="error" variant="subtitle1">
                  {errors[itemPathBrandText.masterIndex.slug]?.message}
                </Typography>
              )}
            </>
          )}
        </Box>
      )}
    </>
  );
};

export default FieldBrand;
