import React, { FC, ReactNode, useState } from 'react';

import { makeStyles, OutlinedInputProps } from '@material-ui/core';
import { KeyboardDatePicker, KeyboardDatePickerProps } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

export type AppDatePickerProps = KeyboardDatePickerProps & {
  inputProps?: OutlinedInputProps;
  margin?: OutlinedInputProps['margin'];
};

const useStyles = makeStyles((theme) => ({
  baseRoot: {
    '& > .MuiInputBase-root': {
      '&.Mui-disabled': {
        userSelect: 'none',
        backgroundColor: theme.palette.action.disabledBackground,
        '& > .MuiInputBase-input': {
          userSelect: 'none',
        },
      },
    },
  },
  inputRoot: {
    display: 'flex',
    '& > .MuiInputBase-root': {
      ...theme.controls.control.normal,
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
  smallInputRoot: {
    '& > .MuiInputBase-root': {
      ...theme.controls.control.small,
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
}));

const AppDatePicker: FC<AppDatePickerProps> = (props) => {
  const classes = useStyles();
  const { margin, ...rest } = props;
  const { t, i18n } = useTranslation();
  const labelFormat = i18n.language === 'th' ? 'D MMM BB' : 'MM/dd/yyyy';
  const [hasError, setHasError] = useState(false);
  const invalidDateMessage = t('validation:invalidDateFormat').toString();

  const labelFunc = (date: MaterialUiPickersDate | undefined): string => {
    // Text will only be displayed when there is a value, unavailable or unparsable date will result an empty box
    if (!!rest.value && date) {
      return dayjs(date).format(labelFormat);
    }

    return '';
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onErrorHandler = (error: ReactNode, val: any) => {
    // Only error with value will be considered as a true error.
    // Otherwise, no error message will be displayed
    if (!val) {
      setHasError(false);
    } else {
      setHasError(true);
    }
  };

  return (
    <KeyboardDatePicker
      variant="inline"
      labelFunc={labelFunc}
      format="yyyy-mm-dd"
      invalidDateMessage={hasError ? invalidDateMessage : null}
      {...rest}
      onError={onErrorHandler}
      inputVariant="outlined"
      disableToolbar
      className={clsx(classes.baseRoot, {
        [classes.inputRoot]: margin !== 'dense',
        [classes.smallInputRoot]: margin === 'dense',
      })}
    />
  );
};

export default AppDatePicker;
