import React, { FC, Suspense, useEffect, useState } from 'react';

import { useMediaQuery, useTheme } from '@material-ui/core';

import AppHeader from './AppHeader';
import AppSidebar from './AppSidebar';

const AppNavigation: FC = () => {
  const theme = useTheme();
  // Initial breakpoint check to determine initial open state of sidebar
  const isMdOrLarger = useMediaQuery(theme.breakpoints.up('md'));
  const [isSidebarOpen, setIsSidebarOpen] = useState(isMdOrLarger);

  // This effect will be triggered only when breakpoint changed (screen resizing)
  useEffect(() => {
    setIsSidebarOpen(isMdOrLarger);
  }, [isMdOrLarger]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const sidebarCloseHandler = () => {
    setIsSidebarOpen(false);
  };

  return (
    <>
      <Suspense fallback={null}>
        <AppHeader onSidebarMenuClicked={toggleSidebar} />
        <AppSidebar isOpen={isSidebarOpen} onSidebarClose={sidebarCloseHandler} />
      </Suspense>
    </>
  );
};

export default AppNavigation;
