import React, { FC } from 'react';

import { Chip, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { ListingStatus } from '../../../../models/listing.model';

export type ListingStatusBadgeProps = {
  status: ListingStatus;
};

const useStyles = makeStyles((theme) => ({
  statusChip: {
    height: '26px',
    lineHeight: '26px',
  },
  statusPublished: {
    backgroundColor: theme.customPalette.appStatus.published.main,
    color: theme.customPalette.appStatus.published.contrastText,
  },
  statusRejected: {
    backgroundColor: theme.customPalette.appStatus.rejected.main,
    color: theme.customPalette.appStatus.rejected.contrastText,
  },
  statusSold: {
    backgroundColor: theme.customPalette.appStatus.sold.main,
    color: theme.customPalette.appStatus.sold.contrastText,
  },
  statusSubmitted: {
    backgroundColor: theme.customPalette.appStatus.submitted.main,
    color: theme.customPalette.appStatus.submitted.contrastText,
  },
  statusUnlisted: {
    backgroundColor: theme.customPalette.appStatus.unlisted.main,
    color: theme.customPalette.appStatus.unlisted.contrastText,
  },
  statusDelete: {
    backgroundColor: theme.customPalette.appStatus.unlisted.main,
    color: theme.customPalette.appStatus.unlisted.contrastText,
  },
}));

const ListingStatusBadge: FC<ListingStatusBadgeProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { status } = props;

  return (
    <Chip
      classes={{ root: classes.statusChip }}
      className={clsx({
        [classes.statusPublished]: status === ListingStatus.PUBLISHED,
        [classes.statusRejected]: status === ListingStatus.REJECTED,
        [classes.statusSold]: status === ListingStatus.SOLD,
        [classes.statusSubmitted]: status === ListingStatus.SUBMITTED,
        [classes.statusUnlisted]: status === ListingStatus.UNLISTED,
      })}
      label={t(`listing:common.status.${status}`).toString()}
    />
  );
};

export default ListingStatusBadge;
