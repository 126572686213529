import { AppTableConditions } from './common';

export enum PurchaseTypeTC {
  Coin = 'COIN',
  MemberOnce = 'MEMBERSHIP_ONCE',
  MemberSubscription = 'MEMBERSHIP_SUBSCRIPTION',
}

export type ProductImage = {
  fileHashId?: string;
  fullUrl?: string;
};
export interface Product {
  hashId: string;
  name: string;
  isActive: boolean;
  sku: string;
  price: number;
  currency: string;
  title: string;
  amount: number;
  productUnit: string;
  purchaseType: string;
  membershipType: string;
  freeCoin: number;
  description: string;
  subTitle: string;
  availableFrom: number;
  availableTo: number;
  image: ProductImage | null;
  isPurchasable: boolean;
}

export type ProductFormDataRequest = {
  name: string;
  amount: number;
  title: string;
  description: string | null;
  subTitle: string | null;
  isActive: boolean;
  availableFrom: number | null;
  availableTo: number | null;
  sku: string;
  price: number;
  image?: ProductImage | null;
  imageFileHashId?: string;
  imageFullUrl?: string;
  currency: string;
  productUnit?: string;
  membershipType?: string;
  freeCoin?: number;
};

export interface GetProductListTCResponse {
  contents: Product[];
  page?: {
    size?: number;
    totalElements?: number;
    totalPages?: number;
    number?: number;
  };
}

export type ProductListSearchFormData = {
  filteringType: string[] | string;
};

export type ProductListSearchParamsTC = ProductListSearchFormData & AppTableConditions;

export type ProductSearchFiltersTC = Partial<ProductListSearchParamsTC> & {
  q?: string;
};
