import React, { FC, useCallback, useContext, useEffect, useState } from 'react';

import { Box, Grid, Paper, Typography, makeStyles } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import dayjs from 'dayjs';
import { getAuth } from 'firebase/auth';
import { useTranslation } from 'react-i18next';
import { Link, useParams, useRouteMatch } from 'react-router-dom';

import AppCircularProgress from '../../../components/partials/AppCircularProgress';
import DataBlock from '../../../components/partials/common/DataBlock';
import ImagePreview from '../../../components/partials/common/ImagePreview';
import ImageThumbnail from '../../../components/partials/common/ImageThumbnail';
import SellerApplicationForm from '../../../components/partials/v3/seller-applications/SellerApplicationForm';
import SellerApplicationStatusBadge from '../../../components/partials/v3/seller-applications/SellerApplicationStatusBadge';
import UserStatusBadge from '../../../components/partials/v3/users/UserStatusBadge';
import AppButton from '../../../components/ui/AppButton';
import { AppGlobalUiContext } from '../../../context/AppGlobalUiContext';
import { setAdminTokenCookie } from '../../../helpers/cookies';
import { firebaseApp } from '../../../helpers/firebase';
import { getAppUrl, getProvinceName, getT2HSiteUrl } from '../../../helpers/utils';
import i18n from '../../../i18n';
import {
  AccessLogPrivateInfoDataRequest,
  ApiResponse,
  EventType,
  SellerApplicationFormDataTC,
  SellerApplicationTC,
  SellerApplicationWorkflow,
  ShopType,
  UserDataTC,
  UserStateWorkflow,
} from '../../../models';
import { addAccessLogPrivateInfo } from '../../../services/v3/interview-log';
import * as sellerAppService from '../../../services/v3/seller-applications';
import { getUserByHashIdTC } from '../../../services/v3/user';
import useTypographyStyles from '../../../theme/typography.style';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'stretch',
    height: 'auto',
    minHeight: `calc(100vh - 48px)`,
    padding: '12px',
  },
  container: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  flexBreak: {
    flexBasis: '100%',
  },

  extLinkIcon: {
    fontSize: 18,
    verticalAlign: 'middle',
    marginLeft: 3,
  },
  userDetailContainer: {
    position: 'relative',
  },
  gridContainer: {
    minHeight: '100%',
  },
  sectionPrivateInfo: {
    width: '100%',
    textAlign: 'center',
    marginTop: '20%',
    padding: theme.spacing(5),
  },
}));

const SellerApplicationEdit: FC = () => {
  const classes = useStyles();
  const typoClasses = useTypographyStyles();
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const { showSnackbar } = useContext(AppGlobalUiContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [userData, setUserData] = useState<UserDataTC | null>(null);
  const { hashId } = useParams<{ hashId: string }>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [sellerAppInfo, setSellerAppInfo] = useState<SellerApplicationTC | undefined>(undefined);
  const dateDisplayFormat = i18n.language === 'th' ? 'D MMM BBBB - HH:mm:ss' : 'D MMM YYYY - HH:mm:ss';
  const [selectedPreviewImage, setSelectedPreviewImage] = useState<string | undefined>(undefined);
  const [isShowPrivateInfoData, setIsShowPrivateInfoData] = useState<boolean>(false);

  const isDbdFileType = sellerAppInfo?.sellerApplication.shopType === ShopType.TentCompany;

  const imageSlideData = [
    sellerAppInfo?.sellerApplication.bookBankImage?.fullUrl,
    sellerAppInfo?.sellerApplication.nidImage?.fullUrl,
    sellerAppInfo?.sellerApplication.selfieImage?.fullUrl,
  ];

  useEffect(() => {
    (async () => {
      const firebaseAuth = getAuth(firebaseApp);
      if (firebaseAuth.currentUser) {
        const currentIdToken = await firebaseAuth.currentUser.getIdToken(true);
        setAdminTokenCookie(currentIdToken);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      if (hashId) {
        const result = await sellerAppService.getSellerApplicationByHashId(hashId);
        if (result.data) {
          setSellerAppInfo(result.data);
        }
      }
      setIsLoading(false);
    })();
  }, [hashId]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      if (sellerAppInfo) {
        const result = await getUserByHashIdTC(sellerAppInfo?.user.userHashId);
        if (result) {
          setUserData(result.data);
        }
      }
      setIsLoading(false);
    })();
  }, [sellerAppInfo]);

  const onEditFormSubmit = useCallback(
    async (formData: SellerApplicationFormDataTC) => {
      if (sellerAppInfo && !isSubmitting) {
        setIsSubmitting(true);

        let result: ApiResponse<SellerApplicationTC> | undefined;

        const now = new Date();
        const currentDateTime = dayjs(now).valueOf();

        const newCommentData = {
          createdAt: currentDateTime,
          sellerApplicationWorkflow: formData.status,
          comment: formData.comment,
        };

        const commentStr = sellerAppInfo.sellerApplication.comment || '';
        const commentArr = sellerAppInfo.sellerApplication.comment ? JSON.parse(commentStr) : '';
        const updatedComment = [...commentArr, newCommentData];
        const commentData = JSON.stringify(updatedComment);

        if (sellerAppInfo?.sellerApplication.hashId) {
          if (formData.status === SellerApplicationWorkflow.SellerApproval) {
            result = await sellerAppService.approveApplication(sellerAppInfo?.sellerApplication.hashId, {
              ...formData,
              comment: commentData,
              interviewLogComment: formData.comment || '',
            });
          } else if (formData.status === SellerApplicationWorkflow.WaitingForEditingApplication) {
            result = await sellerAppService.editingSellerApplication(
              sellerAppInfo?.sellerApplication.hashId,
              commentData,
            );
          } else if (formData.status === SellerApplicationWorkflow.BlacklistApplication) {
            result = await sellerAppService.blacklistSellerApplication(
              sellerAppInfo?.sellerApplication.hashId,
              commentData,
            );
          } else if (formData.status === SellerApplicationWorkflow.DeletedApplication) {
            result = await sellerAppService.deletedSellerApplication(
              sellerAppInfo?.sellerApplication.hashId,
              commentData,
            );
          } else if (formData.status === SellerApplicationWorkflow.RejectedApplication) {
            result = await sellerAppService.rejectSellerApplication(
              sellerAppInfo?.sellerApplication.hashId,
              commentData,
            );
          }

          if (result) {
            if (result.data) {
              const newSellerApplicationStatus = {
                ...sellerAppInfo.sellerApplication,
                comment: commentData,
                sellerApplicationWorkflow: formData.status as SellerApplicationWorkflow,
                isNewSellerFreeCoinCampaign: formData.isNewSellerCampaign,
              };
              setSellerAppInfo({
                ...sellerAppInfo,
                sellerApplication: newSellerApplicationStatus,
                isNewSellerFreeCoinCampaign: formData.isNewSellerCampaign,
              });

              showSnackbar(t(`seller-applications:common.message.updatedSuccess`).toString(), 'success');
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
              });
            } else {
              showSnackbar(t(result.error).toString(), 'error');
            }
          }
          setIsSubmitting(false);
        }
      }

      setSelectedPreviewImage(undefined);
    },
    [sellerAppInfo, isSubmitting, showSnackbar, t],
  );

  const onThumbnailClicked = (imageUrl: string) => {
    if (imageUrl) {
      setSelectedPreviewImage(imageUrl);
    }
  };

  const handleAddEventAccessLog = useCallback(
    async (logData?: AccessLogPrivateInfoDataRequest) => {
      if (sellerAppInfo?.user.userHashId) {
        const remark = `View User hash id ${sellerAppInfo?.user.userHashId} - Profile private info in Seller application page`;
        const urlPath = getAppUrl(url);
        const eventType = EventType.SEEN;

        await addAccessLogPrivateInfo({
          ...logData,
          remark,
          url: urlPath,
          eventType,
        });
      }
    },
    [sellerAppInfo?.user.userHashId, url],
  );

  return (
    <>
      {isShowPrivateInfoData ? (
        <Box>
          {!!isLoading && <AppCircularProgress />}
          <Paper classes={{ root: classes.paper }}>
            {!isLoading && !!userData && (
              <Box>
                <Grid container classes={{ root: classes.gridContainer }}>
                  <Grid item xs={12} md={7} classes={{ root: classes.userDetailContainer }}>
                    <Box p={2}>
                      <Box display="flex">
                        <Typography variant="body1" component="h4" className={typoClasses.textWeightBold}>
                          {sellerAppInfo?.user.displayName}
                        </Typography>

                        <Box mx={1}>
                          {sellerAppInfo?.sellerApplication.sellerApplicationWorkflow && (
                            <SellerApplicationStatusBadge
                              status={sellerAppInfo?.sellerApplication.sellerApplicationWorkflow}
                            />
                          )}
                        </Box>
                        {sellerAppInfo?.sellerApplication.sellerApplicationWorkflow &&
                          userData.buyerSellerVerificationWorkflow === UserStateWorkflow.UnverifiedSeller && (
                            <SellerApplicationStatusBadge
                              status={sellerAppInfo?.sellerApplication.sellerApplicationWorkflow}
                              isReverifyingSeller
                            />
                          )}
                      </Box>

                      <Box my={4}>
                        <DataBlock
                          label="seller-applications:details.label.userGeneralWorkflow"
                          data={
                            sellerAppInfo?.user.userGeneralWorkflow && (
                              <UserStatusBadge status={sellerAppInfo?.user.userGeneralWorkflow} />
                            )
                          }
                        />
                        <DataBlock
                          label="seller-applications:v3.columns.firstCreatedAt"
                          data={dayjs(sellerAppInfo?.user.createdAt).format(`${dateDisplayFormat}`)}
                        />
                        <DataBlock
                          label="seller-applications:v3.columns.createdSellerAt"
                          data={dayjs(sellerAppInfo?.sellerApplication.createdAt).format(`${dateDisplayFormat}`)}
                        />
                        <DataBlock
                          label="seller-applications:v3.columns.updatedAt"
                          data={dayjs(sellerAppInfo?.sellerApplication.updatedAt).format(`${dateDisplayFormat}`)}
                        />
                        <DataBlock
                          label="seller-applications:details.label.shopName"
                          data={
                            sellerAppInfo?.sellerApplication.shopName ? sellerAppInfo?.sellerApplication.shopName : '-'
                          }
                          noWrapText={false}
                        />

                        <DataBlock
                          label="seller-applications:details.label.shopAddress"
                          data={[
                            sellerAppInfo?.sellerApplication.shopAddress,
                            sellerAppInfo?.sellerApplication.shopDistrict,
                            sellerAppInfo?.sellerApplication.shopProvince
                              ? getProvinceName(sellerAppInfo?.sellerApplication.shopProvince)
                              : '',
                            sellerAppInfo?.sellerApplication.shopZipcode,
                          ]
                            .filter((txt) => !!txt)
                            .join(' ')}
                          noWrapText={false}
                        />

                        <DataBlock
                          label="seller-applications:details.label.applyType"
                          data={
                            sellerAppInfo?.sellerApplication.shopType
                              ? t(
                                  `seller-applications:common.applicationType.${sellerAppInfo?.sellerApplication.shopType}`,
                                )
                              : '-'
                          }
                        />
                        <DataBlock
                          label="seller-applications:details.label.mobile"
                          data={sellerAppInfo?.user.mobilePhone}
                        />
                        <DataBlock
                          label="seller-applications:details.label.email"
                          data={sellerAppInfo?.user.email}
                          noWrapText={false}
                        />

                        <Box display="flex" flexDirection="row" alignItems="center">
                          <DataBlock
                            label="seller-applications:details.label.hashId"
                            data={
                              sellerAppInfo?.user.userHashId ? (
                                <a
                                  href={getT2HSiteUrl(`/u/${sellerAppInfo?.user.userHashId}`)}
                                  target="_blank"
                                  rel="noreferrer">
                                  {sellerAppInfo?.user.userHashId}
                                  <OpenInNewIcon className={classes.extLinkIcon} />
                                </a>
                              ) : null
                            }
                          />
                          <Link
                            to={`/v3/users/${sellerAppInfo?.user.userHashId}`}
                            target="_blank"
                            className={typoClasses.noDecorationText}>
                            <AppButton variant="outlined" color="primary" style={{ width: 'max-content' }}>
                              {t('seller-applications:details.label.linkToUser')}
                            </AppButton>
                          </Link>
                        </Box>
                      </Box>

                      <Grid container spacing={2}>
                        {sellerAppInfo?.sellerApplication.nidImage && (
                          <Grid item xs={12} sm={4}>
                            <ImageThumbnail
                              listingHashId={sellerAppInfo.user.userHashId}
                              imageUrl={sellerAppInfo?.sellerApplication.nidImage.fullUrl}
                              altText="National ID file"
                              thumbnailLabel={t('seller-applications:details.label.nationalIdOrPassport')}
                              imageClick={onThumbnailClicked}
                            />
                          </Grid>
                        )}
                        {sellerAppInfo?.sellerApplication.selfieImage && (
                          <Grid item xs={12} sm={4}>
                            <ImageThumbnail
                              listingHashId={sellerAppInfo?.sellerApplication.selfieImage.fileHashId}
                              imageUrl={sellerAppInfo?.sellerApplication.selfieImage?.fullUrl}
                              altText={isDbdFileType ? 'DBD file' : 'Selfie file'}
                              thumbnailLabel={t(
                                isDbdFileType
                                  ? 'seller-applications:details.label.dbdFile'
                                  : 'seller-applications:details.label.idSelfiePic',
                              )}
                              imageClick={onThumbnailClicked}
                            />
                          </Grid>
                        )}
                        {sellerAppInfo?.sellerApplication.bookBankImage && (
                          <Grid item xs={12} sm={4}>
                            <ImageThumbnail
                              listingHashId={sellerAppInfo?.sellerApplication.bookBankImage.fileHashId}
                              imageUrl={sellerAppInfo?.sellerApplication.bookBankImage?.fullUrl}
                              altText="Book Bank file"
                              thumbnailLabel={t('seller-applications:details.label.bookBankPic')}
                              imageClick={onThumbnailClicked}
                            />
                          </Grid>
                        )}
                      </Grid>
                      {!!selectedPreviewImage && (
                        <ImagePreview
                          imageUrl={selectedPreviewImage}
                          onCloseClick={() => setSelectedPreviewImage(undefined)}
                          isPreviewSlide
                          imageSlideData={imageSlideData}
                        />
                      )}
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={5}>
                    <Box p={2}>
                      {userData && !!sellerAppInfo && (
                        <SellerApplicationForm
                          user={userData}
                          sellerApp={sellerAppInfo}
                          onFormSubmit={onEditFormSubmit}
                        />
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Paper>
        </Box>
      ) : (
        <Box className={classes.sectionPrivateInfo}>
          <Typography>
            รายละเอียดในหน้านี้มีข้อมูลที่เป็นส่วนตัวของผู้ใช้งาน เพื่อความปลอดภัยและเป็นส่วนตัวให้แก่เจ้าของข้อมูล
            <br />
            ระบบจะทำการบันทึกข้อมูลของผู้ที่เข้าถึงการใช้งานในหน้านี้
          </Typography>

          <AppButton
            variant="contained"
            color="primary"
            style={{ marginTop: '18px' }}
            onClick={() => {
              handleAddEventAccessLog();
              setIsShowPrivateInfoData(true);
            }}>
            ยืนยันการเข้าถึงข้อมูล
          </AppButton>
        </Box>
      )}
    </>
  );
};

export default SellerApplicationEdit;
