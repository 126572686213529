import React, { FC } from 'react';

import { Box, Container, Grid, makeStyles, MenuItem, OutlinedInput, Select } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import YoutubeSearchedForIcon from '@material-ui/icons/YoutubeSearchedFor';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CategorySearchFormData, SelectItem } from '../../../../../models';
import useAppContainerStyles from '../../../../../theme/container.style';
import AppButton from '../../../../ui/AppButton';
import AppFormControl from '../../../../ui/AppFormControl';
import AppFormControlGroup from '../../../../ui/AppFormControlGroup';

export type CategoryListSearchFormProps = {
  currentFilters: CategorySearchFormData | undefined;
  onSearchFormSubmit: (values: CategorySearchFormData) => void;
};

const searchFieldList: SelectItem<string>[] = [
  {
    label: 'master-data:common.field.name',
    value: 'name',
  },
  {
    label: 'master-data:common.field.thaiName',
    value: 'thaiName',
  },
  {
    label: 'master-data:common.field.slug',
    value: 'slug',
  },
  {
    label: 'master-data:common.field.searchSynonym',
    value: 'searchSynonym',
  },
];

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
  },
  searchBoxContainer: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 500,
    },
  },
}));

const CategoryListSearchForm: FC<CategoryListSearchFormProps> = (props) => {
  const { onSearchFormSubmit, currentFilters } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const containerClasses = useAppContainerStyles();

  const { handleSubmit, control, setValue } = useForm<CategorySearchFormData>({
    mode: 'onSubmit',
    defaultValues: {
      searchField: currentFilters?.searchField || searchFieldList[0].value,
      searchKeyword: currentFilters?.searchKeyword || '',
    },
  });

  const clearSearchForm = () => {
    setValue('searchField', searchFieldList[0].value);
    setValue('searchKeyword', '');
    handleSubmit(onSearchFormSubmit)();
  };

  return (
    <Box className={classes.filterContainer}>
      <Container className={containerClasses.container}>
        <form onSubmit={handleSubmit(onSearchFormSubmit)}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} md container spacing={3}>
              <Grid item xs={12} md="auto">
                <Box display="flex" flexDirection="row" flexWrap="nowrap" className={classes.searchBoxContainer}>
                  <AppFormControlGroup flexGrow={1} minWidth={0} pr={1}>
                    <AppFormControl margin="dense">
                      <Controller
                        name="searchField"
                        control={control}
                        defaultValue={searchFieldList[0].value}
                        render={({ field }) => (
                          <Select
                            labelId="user-list-search-field-label"
                            id="user-list-search-field"
                            value={field.value}
                            variant="outlined"
                            onChange={field.onChange}
                            fullWidth>
                            {searchFieldList.map((item) => (
                              <MenuItem value={item.value} key={item.value}>
                                {t(item.label)}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </AppFormControl>
                    <AppFormControl margin="dense" boxProps={{ flexGrow: 1 }}>
                      <Controller
                        name="searchKeyword"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <OutlinedInput
                            type="search"
                            endAdornment={<SearchIcon />}
                            id="user-list-search-keyword"
                            value={field.value}
                            onChange={field.onChange}
                            fullWidth
                          />
                        )}
                      />
                    </AppFormControl>
                  </AppFormControlGroup>
                  <AppButton type="submit" color="primary" style={{ alignSelf: 'flex-start' }}>
                    {t('common:message.search')}
                  </AppButton>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12} md="auto">
              <AppButton variant="outlined" color="primary" onClick={clearSearchForm}>
                <YoutubeSearchedForIcon />
              </AppButton>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Box>
  );
};

export default CategoryListSearchForm;
