import React, { FC, useCallback, useContext, useEffect, useState } from 'react';

import { Box, CircularProgress, Container, Paper, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import DashboardSnsTag from '../../components/partials/dashboard/DashboardSnsTag';
import AppAutoCompleteMultiple from '../../components/ui/AppAutoCompleteMultiple';
import AppButton from '../../components/ui/AppButton';
import { AppGlobalUiContext } from '../../context/AppGlobalUiContext';
import { SnsTag } from '../../models';
import * as snsService from '../../services/sns';
import useAppContainerStyles from '../../theme/container.style';
import useTypographyStyles from '../../theme/typography.style';

const getTagList = async (value: string): Promise<SnsTag[]> => {
  const result = await snsService.searchTagList(value);
  return result.data || [];
};

const RecommendedTagList: FC = () => {
  const typoClasses = useTypographyStyles();
  const { t } = useTranslation();
  const { showSnackbar, setAppLoading } = useContext(AppGlobalUiContext);
  const containerClasses = useAppContainerStyles();
  const [originalData, setOriginalData] = useState<SnsTag[]>([]);
  const [currentValues, setCurrentValues] = useState<SnsTag[]>([]);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [isItemChanged, setIsItemChanged] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [originalNotRecommendData, setOriginalNotRecommendData] = useState<SnsTag[]>([]);
  const [currentNotRecommendValues, setCurrentNotRecommendValues] = useState<SnsTag[]>([]);
  const [isNotRecommendInitialized, setIsNotRecommendInitialized] = useState<boolean>(false);
  const [isNotRecommendItemChanged, setIsNotRecommendItemChanged] = useState<boolean>(false);

  const [isMatchedData, setIsMatchedData] = useState<boolean>(false);

  const itemChangeHandler = (value: SnsTag[] | undefined) => {
    const newValues = value || [];
    if (newValues.length > 10) {
      showSnackbar(t('sns:message.recommendTag.isTagMaximum').toString(), 'error');
    }
    setCurrentValues(newValues);

    const listTagsNotDisplay = currentNotRecommendValues?.map((item) => item.tagName);
    const currentNewValues = newValues?.map((item) => item.tagName);

    const matchData = listTagsNotDisplay.some((i) => currentNewValues.includes(i));
    if (matchData) {
      showSnackbar(t('sns:message.recommendTag.addTagFailed').toString(), 'error');
      setIsMatchedData(true);
    } else {
      setIsMatchedData(false);
      setIsItemChanged(JSON.stringify(originalData) !== JSON.stringify(newValues));
    }
  };

  const itemNotRecommendChangeHandler = (value: SnsTag[] | undefined) => {
    const newValues = value || [];
    setCurrentNotRecommendValues(newValues);

    const listTagsDisplay = currentValues?.map((item) => item.tagName);
    const currentNewValues = newValues?.map((item) => item.tagName);

    const matchData = listTagsDisplay.some((i) => currentNewValues.includes(i));
    if (matchData) {
      showSnackbar(t('sns:message.recommendTag.tagNotDisplay').toString(), 'warning');
      setIsMatchedData(true);
    } else {
      setIsMatchedData(false);
      setIsNotRecommendItemChanged(JSON.stringify(originalNotRecommendData) !== JSON.stringify(newValues));
    }
  };

  useEffect(() => {
    let isActive = true;
    (async () => {
      if (!isInitialized) {
        const currentSelectedValues = await snsService.getCurrentRecommendedTagList();
        if (currentSelectedValues.data && isActive) {
          setCurrentValues(currentSelectedValues.data);
          setOriginalData(currentSelectedValues.data);
          setIsInitialized(true);
        }
      }
      if (!isNotRecommendInitialized) {
        const currentSelectedValues = await snsService.getCurrentNotRecommendedTagList();
        if (currentSelectedValues.data && isActive) {
          setCurrentNotRecommendValues(currentSelectedValues.data);
          setOriginalNotRecommendData(currentSelectedValues.data);
          setIsNotRecommendInitialized(true);
        }
      }
    })();

    return () => {
      isActive = false;
    };
  }, [isInitialized, isNotRecommendInitialized]);

  const saveItem = useCallback(async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    setAppLoading(true);
    const result = await snsService.saveRecommendTagData(originalData, currentValues);
    setAppLoading(false);
    setIsLoading(false);

    if (result) {
      showSnackbar(t('sns:message.recommendTag.saveSuccess').toString(), 'success');
      setOriginalData([...currentValues]);
      setIsItemChanged(false);
    } else {
      showSnackbar(t('sns:message.recommendTag.saveFailed').toString(), 'error');
    }
  }, [isLoading, setAppLoading, originalData, currentValues, showSnackbar, t]);

  const saveNotRecommendItem = useCallback(async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    setAppLoading(true);
    const result = await snsService.saveNotRecommendTagData(originalNotRecommendData, currentNotRecommendValues);
    setAppLoading(false);
    setIsLoading(false);

    if (result) {
      showSnackbar(t('sns:message.recommendTag.saveTagNotDisplaySuccess').toString(), 'success');
      setOriginalNotRecommendData([...currentNotRecommendValues]);
      setIsNotRecommendItemChanged(false);
    } else {
      showSnackbar(t('sns:message.recommendTag.saveFailed').toString(), 'error');
    }
  }, [currentNotRecommendValues, isLoading, originalNotRecommendData, setAppLoading, showSnackbar, t]);

  return (
    <Container className={containerClasses.smallContainer}>
      <Box my={3}>
        <Paper>
          <Box p={3}>
            {!isInitialized && <CircularProgress color="inherit" />}
            {isInitialized && (
              <Box>
                <Box mb={1}>
                  <Typography variant="h6" className={typoClasses.textWeightBold}>
                    {t('sns:title.recommendedTagsDisplay')}
                  </Typography>
                </Box>
                <AppAutoCompleteMultiple<SnsTag>
                  getData={getTagList}
                  onValueChange={itemChangeHandler}
                  initialValue={currentValues}
                  autoCompleteProps={{
                    ChipProps: {
                      variant: 'outlined',
                    },
                    disableCloseOnSelect: true,
                    popupIcon: null,
                    getOptionLabel: (option) => (typeof option === 'string' ? option : option.tagName),
                    filterOptions: (option) =>
                      option.filter((opt: SnsTag) => !currentValues.find((item: SnsTag) => item.id === opt.id)),
                    renderOption: ({ tagName }: SnsTag) => (
                      <div>
                        <Typography variant="body1">{tagName}</Typography>
                      </div>
                    ),
                    loadingText: t('common:message.searching').toString(),
                    noOptionsText: t('common:message.dataNotFound').toString(),
                  }}
                  textfieldProps={{
                    margin: 'dense',
                  }}
                />
              </Box>
            )}

            {isItemChanged && !isMatchedData && currentValues.length <= 10 && (
              <Box mt={2} display="flex" justifyContent="flex-end">
                <AppButton color="primary" disabled={isLoading} onClick={() => saveItem()}>
                  {t('common:button.save')}
                </AppButton>
              </Box>
            )}
          </Box>
        </Paper>
      </Box>

      <Box my={3}>
        <Paper>
          <Box p={3}>
            {!isNotRecommendInitialized && <CircularProgress color="inherit" />}
            {isNotRecommendInitialized && (
              <Box>
                <Box mb={1}>
                  <Typography variant="h6" className={typoClasses.textWeightBold}>
                    {t('sns:title.recommendedTagsNoDisplay')}
                  </Typography>
                  <Typography variant="subtitle2">{t('sns:title.recommendedTagsNoDisplayDescription')}</Typography>
                </Box>
                <AppAutoCompleteMultiple<SnsTag>
                  getData={getTagList}
                  onValueChange={itemNotRecommendChangeHandler}
                  initialValue={currentNotRecommendValues}
                  autoCompleteProps={{
                    disableCloseOnSelect: true,
                    popupIcon: null,
                    getOptionLabel: (option) => (typeof option === 'string' ? option : option.tagName),
                    filterOptions: (option) =>
                      option.filter(
                        (opt: SnsTag) => !currentNotRecommendValues.find((item: SnsTag) => item.id === opt.id),
                      ),
                    renderOption: ({ tagName }: SnsTag) => (
                      <div>
                        <Typography variant="body1">{tagName}</Typography>
                      </div>
                    ),
                    loadingText: t('common:message.searching').toString(),
                    noOptionsText: t('common:message.dataNotFound').toString(),
                  }}
                  textfieldProps={{
                    margin: 'dense',
                  }}
                />
              </Box>
            )}
            {isNotRecommendItemChanged && !isMatchedData && (
              <Box mt={2} display="flex" justifyContent="flex-end">
                <AppButton color="primary" disabled={isLoading} onClick={() => saveNotRecommendItem()}>
                  {t('common:button.save')}
                </AppButton>
              </Box>
            )}
          </Box>
        </Paper>
      </Box>

      <DashboardSnsTag />
    </Container>
  );
};

export default RecommendedTagList;
