import React, { useEffect, useState, ReactNode, ReactElement } from 'react';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { UnpackNestedValue, UseFormHandleSubmit } from 'react-hook-form';

import CommonWebConfigFields from './CommonWebConfigFields';
import usePrivilege from '../../../../hooks/usePrivilege';
import { TableColumn, WebConfig, WebConfigEditFormProps } from '../../../../models';
import ListItemEditor from '../../common/ListItemEditor';

dayjs.extend(customParseFormat);

export type WebConfigListItemProps<T, K> = WebConfigEditFormProps & {
  listColumns: TableColumn[];
  listTableRowKeyPrefix: string;
  listTableCellsContent: (item: T) => ReactNode;
  dialogFormContent: (item: T | undefined) => ReactNode;
  setEditFormValues: (item: T | undefined) => void;
  handleSubmit: UseFormHandleSubmit<K>;
  dialogSaveItemTransformer?: (item: UnpackNestedValue<K>) => T;
};

const WebConfigListItemEditor = <T, K>(props: WebConfigListItemProps<T, K>): ReactElement => {
  const {
    configItem,
    onSave,
    listColumns,
    listTableRowKeyPrefix,
    listTableCellsContent,
    dialogSaveItemTransformer,
    setEditFormValues,
    dialogFormContent,
    handleSubmit,
  } = props;
  const [config, setConfig] = useState(configItem);
  const [listItems, setListItems] = useState<T[]>([]);
  const [isListLoading, setIsListLoading] = useState(true);

  const isDataModified =
    configItem.configType !== config.configType || configItem.description !== JSON.stringify(listItems);
  const { canPerform } = usePrivilege();
  const canUpdateWebConfig = canPerform('webConfig', 'update');

  useEffect(() => {
    setConfig(configItem);
    try {
      setListItems(JSON.parse(configItem.description) as T[]);
    } catch (e) {
      setListItems([]);
    } finally {
      setIsListLoading(false);
    }
  }, [configItem]);

  const onCommonFieldsChanged = (data: Partial<WebConfig>) => {
    setConfig({
      ...config,
      ...data,
    });
  };

  const saveConfigData = () => {
    if (typeof onSave === 'function') {
      onSave({
        ...config,
        description: JSON.stringify(listItems),
      });
    }
  };

  const onSaveWebConfig = (index: number, item: T) => {
    if (canUpdateWebConfig) {
      const newItemList = [...listItems];
      if (index > -1) {
        newItemList[index] = item;
      } else {
        newItemList.push(item);
      }
      setListItems(newItemList);
    }
  };

  const onDeleteWebConfig = (index: number) => {
    const newItemList = [...listItems];
    newItemList.splice(index, 1);
    setListItems(newItemList);
  };

  return (
    <>
      {!!config && (
        <>
          <CommonWebConfigFields
            config={config}
            onDataChange={onCommonFieldsChanged}
            onSaveClick={saveConfigData}
            showModifiedDataWarning={isDataModified}
          />
          <ListItemEditor<T, K>
            adminActionField="webConfig"
            listData={listItems}
            listColumns={listColumns}
            listTableRowKeyPrefix={listTableRowKeyPrefix}
            listTableCellsContent={listTableCellsContent}
            dialogSaveItemTransformer={dialogSaveItemTransformer}
            setEditFormValues={setEditFormValues}
            dialogFormContent={dialogFormContent}
            handleSubmit={handleSubmit}
            onSave={onSaveWebConfig}
            onDelete={onDeleteWebConfig}
            deleteConfirmMessage="settings:message.config.itemDeleteWarning"
            appTableListProps={{
              'aria-label': 'settings-list',
              totalDataCount: listItems.length,
              hasPagination: false,
              isLoading: isListLoading,
            }}
          />
        </>
      )}
    </>
  );
};

export default WebConfigListItemEditor;
