import React, { FC, HTMLAttributes } from 'react';

import { TabItem } from '../../../models';

export type AppTabPanelProps = HTMLAttributes<HTMLDivElement> & {
  index: number;
  value: number;
  tabIdPrefix: string;
  tabPanelPrefix: string;
  tabItem: TabItem;
};

const AppTabPanel: FC<AppTabPanelProps> = (props) => {
  const { tabItem, index, value, children, tabIdPrefix, tabPanelPrefix, ...rest } = props;

  return (
    <div
      {...rest}
      role="tabpanel"
      hidden={value !== index}
      id={`${tabPanelPrefix}${tabItem.id}`}
      aria-labelledby={`${tabIdPrefix}${tabItem.id}`}>
      {value === index && children}
    </div>
  );
};

export default AppTabPanel;
