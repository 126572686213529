import React, { FC } from 'react';

import { Box, makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

import PaymentCreditCard from './PaymentCreditCard';
import { UserDataTC } from '../../../../../models';
import DataBlock from '../../../common/DataBlock';

export interface BillingInfoProps {
  currentUser: UserDataTC;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    '& .form-container, & .credit-card-container': {
      flexBasis: '100%',
    },
    '& .form-container': {
      paddingTop: '16px',
    },
    [theme.breakpoints.up('md')]: {
      flexFlow: 'row nowrap',
      '& .form-container': {
        flex: '1 0 auto',
        paddingLeft: '16px',
        paddingTop: '16px',
      },
      '& .credit-card-container': {
        flex: '1 0 auto',
        paddingRight: '16px',
        maxWidth: '400px',
        borderRight: 'solid 1px',
        borderRightColor: grey[400],
      },
    },
  },
}));

const BillingInfo: FC<BillingInfoProps> = (props) => {
  const { currentUser } = props;

  const classes = useStyles();

  return (
    <Box className={classes.container} py="16px">
      <Box className="credit-card-container">
        <PaymentCreditCard currentUser={currentUser} />
      </Box>
      <Box className="form-container">
        <DataBlock label="user:common.fields.billingCompanyName" data={currentUser.billingCompanyName} />
        <DataBlock label="user:common.fields.billingAddress" data={currentUser.billingAddress} />
        <DataBlock label="user:common.fields.billingTel" data={currentUser.billingTel} />
        <DataBlock label="user:common.fields.billingTaxId" data={currentUser.billingTaxId} />
      </Box>
    </Box>
  );
};

export default BillingInfo;
