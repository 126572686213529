import React, { FC, useContext, useEffect, useState } from 'react';

import { Box, Container, Divider, Paper, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

import StickyActionBar from '../../../components/partials/common/StickyActionBar';
import MasterDataCommonFields from '../../../components/partials/master-data/MasterDataCommonFields';
import AppBreadcrumb from '../../../components/ui/AppBreadcrumb';
import AppButton from '../../../components/ui/AppButton';
import { AppGlobalUiContext } from '../../../context/AppGlobalUiContext';
import { MainModuleContext } from '../../../context/MainModuleContext';
import { applyKTErrorToForm } from '../../../helpers/utils';
import { ApiResponse, BreadcrumbItem, MasterIndex, MasterIndexFormData } from '../../../models';
import * as masterIndexService from '../../../services/master-index';
import useAppContainerStyles from '../../../theme/container.style';
import useControlStyles from '../../../theme/controls.style';
import useTypographyStyles from '../../../theme/typography.style';

const MasterIndexEdit: FC = () => {
  const { t } = useTranslation();
  const { uid } = useParams<{ uid: string }>();
  const history = useHistory();
  const { path } = useRouteMatch();
  const { showSnackbar, setAppLoading, setAppBarTitle } = useContext(AppGlobalUiContext);
  const { currentRoute } = useContext(MainModuleContext);
  const [currentData, setCurrentData] = useState<MasterIndex | null>(null);
  const containerClass = useAppContainerStyles();
  const controlsClasses = useControlStyles();
  const typoClasses = useTypographyStyles();
  const defaultFormValues: MasterIndexFormData = {
    uid: 0,
    name: '',
    thaiName: '',
    slug: '',
    imageUrl: '',
    description: '',
    placeholder: '',
    attributeDataType: null,
  };
  const {
    control,
    handleSubmit,
    clearErrors,
    reset,
    setValue,
    getValues,
    setError,
    formState: { errors },
  } = useForm<MasterIndexFormData>({
    mode: 'onChange',
    defaultValues: defaultFormValues,
  });

  const breadcrumbItems: BreadcrumbItem[] = [
    { label: 'common:sidebar.menu.masterData', url: '/master-data' },
    { label: 'master-data:menu.masterIndex', url: '/master-data/master-index' },
    {
      label: `master-data:menu.${uid === 'create' ? 'newMasterIndex' : 'masterIndexUid'}`,
      labelParam: {
        masterIndexUid: uid,
      },
    },
  ];

  const setFormValues = (data: MasterIndex) => {
    reset();
    clearErrors();

    if (data) {
      const { uid: modelUid, name, thaiName, slug, imageUrl, description, placeholder, attributeDataType } = data;
      setValue('uid', modelUid || 0, { shouldValidate: true });
      setValue('name', name || '', { shouldValidate: true });
      setValue('thaiName', thaiName || '', { shouldValidate: true });
      setValue('slug', slug || '', { shouldValidate: true });
      setValue('imageUrl', imageUrl || '', { shouldValidate: true });
      setValue('description', description || '', { shouldValidate: true });
      setValue('placeholder', placeholder || '', { shouldValidate: true });
      setValue('attributeDataType', attributeDataType || null, { shouldValidate: true });
    }
  };

  useEffect(() => {
    let isActive = true;
    setAppBarTitle(`master-data:title.masterIndex.${uid === 'create' ? 'create' : 'edit'}`);

    (async () => {
      if (uid && uid !== 'create' && isActive) {
        const result = await masterIndexService.getMasterIndexByUid(uid);
        if (result.data && !result.error) {
          setFormValues(result.data);
          setCurrentData(result.data);
        }
        if (result.error) {
          showSnackbar(t(result.error), 'error');
        }
      }
    })();

    return () => {
      isActive = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSaveDataHandler = (data: MasterIndexFormData) => {
    (async () => {
      setAppLoading(true);
      let saveResult: ApiResponse<MasterIndex>;
      if (uid !== 'create' && currentData) {
        saveResult = await masterIndexService.updateMasterIndex(currentData.id, data);
      } else {
        saveResult = await masterIndexService.createMasterIndex(data);
      }

      if (saveResult) {
        if (saveResult.data) {
          clearErrors();
          showSnackbar(
            t(`master-data:message.masterIndex.${uid === 'create' ? 'create' : 'update'}Success`).toString(),
            'success',
          );

          // If uid has changed, refresh page to get fresh data
          // Don't have to clear app loading since it's refreshing
          if (uid === 'create' || (saveResult.data?.uid && saveResult.data.uid !== Number(uid))) {
            setTimeout(() => {
              window.location.href = `/master-data/master-index/${saveResult.data?.uid}`;
            }, 2000);
          } else {
            setAppLoading(false);
            setCurrentData(saveResult.data);
          }
        } else {
          setAppLoading(false);
          showSnackbar(t(saveResult.error).toString(), 'error');

          if (saveResult.validationErrors) {
            applyKTErrorToForm<MasterIndexFormData>(t, setError, getValues, saveResult.validationErrors);
          }
        }
      }
    })();
  };

  const onSaveClick = () => {
    handleSubmit(onSaveDataHandler)();
  };

  const openCategoryPage = () => {
    history.push(`/master-data/master-index/${uid}/categories`);
  };

  return (
    <>
      <Box px={6} py={3}>
        <AppBreadcrumb items={breadcrumbItems} />
      </Box>
      <Container className={containerClass.container} style={{ marginBottom: 60 }}>
        <Box py={3}>
          <Box py={2} display="flex" justifyContent="flex-end" className={controlsClasses.buttonsGroup}>
            <StickyActionBar>
              <AppButton color="default" variant="outlined" onClick={() => history.push(currentRoute?.backUrl || path)}>
                {t('common:button.cancel')}
              </AppButton>
              <AppButton color="primary" onClick={() => onSaveClick()}>
                {t('common:button.save')}
              </AppButton>
            </StickyActionBar>
          </Box>
          <Paper>
            <Box p={3}>
              <MasterDataCommonFields control={control} errors={errors} hasMetaInfo={false} />
              {!!currentData && (
                <>
                  <Box mt={3} mb={2}>
                    <Divider />
                  </Box>
                  <Box mb={3}>
                    <Typography className={typoClasses.textWeightBold} variant="h6">
                      {t('master-data:form.masterIndex.categoryInfoTitle')}
                    </Typography>
                  </Box>
                  <AppButton variant="outlined" color="primary" onClick={() => openCategoryPage()}>
                    {t('master-data:form.masterIndex.categoryInfoTitle')}
                  </AppButton>
                </>
              )}
            </Box>
          </Paper>
        </Box>
      </Container>
    </>
  );
};

export default MasterIndexEdit;
