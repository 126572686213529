/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/prefer-default-export */

import { apiTCRequest } from '../helpers/request';
import { BankMaster } from '../models';

export const getBankMasterList = async (): Promise<BankMaster[]> => {
  try {
    const result = await apiTCRequest<{ content: BankMaster[] }>('/api/bank-master/');

    return result.data.content;
  } catch (e) {
    return [];
  }
};
