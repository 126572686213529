/* eslint-disable @typescript-eslint/no-explicit-any */
import { KTCategory, KTCategoryPathItem } from './category.model';
import { AppTableConditions } from './common';

interface ListingDataImageItem {
  hashId: string;
  url: string;
  fileName: string;
}

interface ListingItemUserBankAccData {
  accountNumber: string;
  createdAt: string;
  updatedAt: string;
  hashId: string;
  bankBookFileHashId: string;
  bankEnglishName: string;
  bankThaiName: string;
  bankLogo: string;
}

export interface ListingItemUserData {
  displayName: string;
  username: string;
  email: string;
  homePhone: string;
  mobile: string;
  facebookUri: string;
  lineId: string;
  isEmailVerified: boolean;
  isEmailPublic: boolean;
  isMobileVerified: boolean;
  isMobilePublic: boolean;
  isHomeVerified: boolean;
  firstName: string;
  lastName: string;
  verifiedFullName: string;
  verifiedAddress: string;
  profileImageUrl: string;
  description: string;
  nationalId: string;
  passportNo: string;
  companyTaxId: string;
  companyName: string;
  province: string;
  district: string;
  address: string;
  zipcode: string;
  fax: string;
  personalWebHomepage: string;
  role: string;
  balance: number;
  sellerIdentityType: string;
  sellerType: string;
  createdAt: string;
  updatedAt: string;
  status: string;
  membershipStartAt: string;
  membershipEndAt: string;
  membershipProductId: number;
  shopLocationImageUrl: string;
  shopLocationLatitude: string;
  shopLocationLongitude: string;
  bankAcc: ListingItemUserBankAccData;
  hashId: string;
  roles: string[];
  membership: string;
  fullName: string;
  contactNumber: string;
  contactEmail: string;
  hasPassword: boolean;
  isIdentityVerified: boolean;
  isBankAccountVerified: boolean;
  isVerified: boolean;
  membershipProductHashId: string;
}

interface ListingItemCategoryData {
  thaiName: string;
  englishName: string;
  searchUrl: string;
  itemCount: number;
  hashId: string;
}

interface ListingItemBootsItemData {
  createdAt: string;
  finishBoostTime: string;
  hashId: string;
  hashItemId: string;
  nextBoostTime: string;
  remainCount: number;
  remainDays: number;
  startBoostTime: string;
  status: string;
  totalCount: number;
  totalDays: number;
  type: string;
  updatedAt: string;
}

export type ListingSearchParams = ListingSearchFormData & AppTableConditions;

export type ListingSearchParamsV1 = ListingSearchFormDataV1 & AppTableConditions;

export type ListingSearchFilters = Partial<ListingSearchParams>;

export type ListingSearchFiltersV1 = Partial<ListingSearchParamsV1>;

export interface ListingSearchFormData {
  searchField: string;
  searchKeyword: string;
  rootCategory?: string;
  breadcrumbPath2?: string;
  categoryQuery: string;
  brandQuery: string;
  modelQuery: string;
  filteringStatuses: string[];
  filteringPossessions: string[];
  filteringCrStatuses?: string[];
  createdDateFrom?: string;
  createdDateTo?: string;
  lowerFirstRegistrationYear?: string;
  upperFirstRegistrationYear?: string;
  lowerBoundPrice?: string;
  upperBoundPrice?: string;
}

export interface ListingSearchFormDataV1 {
  searchField: string;
  searchKeyword: string;
  rootCategory: string;
  subCategory: string;
  speCategory: string;
  filteringStatuses: string[];
  filteringPossessions: string[];
  filteringCrStatuses: string[];
}

export enum ListingStatus {
  PENDING = 'pending',
  SUBMITTED = 'submitted',
  PUBLISHED = 'published',
  REJECTED = 'rejected',
  DRAFT = 'draft',
  SOLD = 'sold',
  UNLISTED = 'unlisted',
  DELETE = 'delete',
  BOOKED = 'booked',
}

export enum ListingPossession {
  JkkeReset = 'jkke_reset',
  Jkke = 'jkke',
  JkkeWaiting = 'jkke_waiting',
  JkkeRejected = 'jkke_rejected',
  T2hOfficial = 't2h_official',
  T2hOfficialWaiting = 't2h_official_waiting',
  T2hOfficialRejected = 't2h_official_rejected',
  T2hOfficialConsignment = 't2h_official_consignment',
}

export enum ListingCRStatus {
  NotApproved = 'notApproved',
  Approved = 'approved',
  Pending = 'pending',
}

export enum ListingCleansingStatus {
  Checked = 'checked',
  Trash = 'trash',
  Pending = 'pending',
}

export interface ListingItemData {
  hashId: string;
  title: string;
  sellingPrice: number;
  createdAt: string;
  updatedAt: string;
  status: ListingStatus;
  possession: ListingPossession;
  crStatus?: ListingCRStatus;
  cleansingStatus?: ListingCleansingStatus;
  imageUrl: string;
  images: ListingDataImageItem[];
  imageCount: number;
  firstRegistrationYear: number;
  brand: { name: string; hashId: string };
  province: { englishName: string; englishRegion: string; hashId: string; thaiName: string; thaiRegion: string };
  rootCategory: { thaiName: string; englishName: string; hashId: string };
  subCategory: { thaiName: string; englishName: string; hashId: string };
  spacialCategory: { thaiName: string; englishName: string; hashId: string };
  user: { hashId: string; username: string };
  video: {
    fileName: string;
    hashId: string;
    size: number;
    url: string;
  };
  vehicleRegistrationBookImages: ListingDataImageItem[];
  categoryPath: CategoryPathItem[];
  provinceThai: string;
  userDisplayName: string;
  userHashId: string;
  markdownPrice: number;
}

export type ListingItemDataV1 = Omit<ListingItemData, 'categoryPath' | 'provinceThai'>;

export interface CategoryPathItem {
  slug: string;
  value?: string;
  valueNumber?: number;
}

export interface ListingData {
  firstRegistrationYear: number;
  transmission: string;
  operatingHours: number;
  mileage: number;
  purchasePrice: number;
  purchaseYear: number;
  vehicleRegNo: string;
  licencePlate: string;
  vin: string;
  title: string;
  detail: string;
  pickupLocation: string;
  imageUrl: string;
  sellingPrice: number;
  markdownPrice: number;
  viewCount: number;
  quality: number;
  comment: string;
  acceptedPaymentTypes: string;
  status: ListingStatus;
  possession: ListingPossession;
  possessionComment?: string;
  documentNote?: string;
  crStatus?: ListingCRStatus;
  cleansingStatus?: ListingCleansingStatus;
  publishedAt: string;
  soldAt: string;
  soldReason: string;
  createdAt: string;
  updatedAt: string;
  user: ListingItemUserData;
  category: {
    thaiNameGroup: string;
    englishNameGroup: string;
    thaiName: string;
    englishName: string;
    hashId: string;
    parentHashIds: string[];
  };
  brand: {
    name: string;
    hashId: string;
  };
  model: {
    hashId: string;
    name: string;
  };
  province: {
    thaiName: string;
    englishName: string;
    thaiRegion: string;
    englishRegion: string;
    hashId: string;
  };
  boosts: ListingItemBootsItemData[];
  itemVehicleRegistrationBookImages: ListingDataImageItem[];
  rootCategory: ListingItemCategoryData;
  subCategory: ListingItemCategoryData;
  spacialCategory: ListingItemCategoryData;
  favoriteCount: number;
  hashId: string;
  rootCategoryHashId: string;
  rootCategoryThai: string;
  subCategoryHashId: string;
  subCategoryThai: string;
  spacialCategoryHashId: string;
  spacialCategoryThai: string;
  userHashId: string;
  isPublic: boolean;
  images: ListingDataImageItem[];
  vehicleRegistrationBookImages: ListingDataImageItem[];
  video: {
    fileName: string;
    hashId: string;
    size: number;
    url: string;
  };
  imageCount: number;
  userMembership: string;
  isVerified: boolean;
  userDisplayName: string;
  provinceThai: string;
  provinceEnglish: string;
  categoryPath: CategoryPathItem[];
  isNotRequiredFinancial: boolean;
  brandName: string;
}

export interface ListingDataV2 {
  items: ListingData[];
  esCategories: KTCategory;
}

export interface BrandItemData {
  name: string;
  hashId: string;
}

export interface ModelItemData {
  name: string;
  hashId: string;
  categoryHashId: string;
  brandHashId: string;
}

export interface ListingFormData {
  hashId: string;
  categoryHashId: string | null;
  rootCategoryHashId: string | null;
  subCategoryHashId: string | null;
  spaCategoryHashId: string | null;
  brandHashId: string | null;
  modelHashId: string | null;
  modelName: string | null;
  provinceHashId: string | null;
  firstRegistrationYear: number | null | string;
  operatingHours: number | null | string;
  mileage: number | null | string;
  mileageNA: boolean | null;
  purchaseYear: number | null | string;
  licencePlate: string | null;
  vin: string | null;
  vehicleRegistrationBookFileHashIds: string[];
  title: string | null;
  detail: string | null;
  imageHashIds: string[];
  videoHashId: string | null;
  sellingPrice: number | null | string;
  markdownPrice: number | null | string;
  status: ListingStatus;
  possession: ListingPossession;
  possessionComment?: string;
  rejectReason?: string;
  isNotRequiredFinancial: boolean;
  documentNote?: string;
  crStatus?: ListingCRStatus;
  cleansingStatus?: ListingCleansingStatus;
  category?: KTCategory;
  categoryPath?: KTCategoryPathItem[];
  [key: string]: any;
}

export interface ListingFormDataV2 {
  title?: string;
  detail?: string;
  categoryPath?: CategoryPathItem[];
  purchasePrice?: number;
  purchaseYear?: number;
  vehicleRegNo?: string;
  licencePlate?: string;
  vin?: string;
  pickupLocation?: string;
  imageHashIds?: string[];
  vehicleRegistrationBookFileHashIds?: string[];
  videoHashId?: string;
  sellingPrice: number;
  markdownPrice?: number;
  possession?: ListingPossession;
  acceptedPaymentTypes?: string[];
  isNotRequiredFinancial?: boolean;
  documentNote?: string;
  crStatus?: ListingCRStatus;
  cleansingStatus?: ListingCleansingStatus;
}

export interface GetBrandsResponse {
  data: BrandItemData[];
}

export interface GetModelsResponse {
  data: ModelItemData[];
}
export interface GetListingResponse {
  data: ListingData;
}

export interface GetListingListResponse {
  data: ListingData[];
  totalCount: number;
}

export interface GetListingListResponseV2 {
  data: ListingDataV2;
  totalCount: number;
}

export interface PublishListingResponse {
  data: ListingData;
  status: number;
  statusText: string;
}

export interface RejectListingResponse {
  data: ListingData;
  status: number;
  statusText: string;
}

export interface DraftListingResponse {
  data: ListingData;
  status: number;
  statusText: string;
}

export interface UpdateListingResponse {
  data: ListingData;
  status: number;
  statusText: string;
}

export interface DeleteListingResponse {
  data: ListingData;
  status: number;
  statusText: string;
}

export interface PossessionListingResponse {
  data: ListingData;
  status: number;
  statusText: string;
}

export interface RejectPossessionListingResponse {
  data: ListingData;
  status: number;
  statusText: string;
}

export interface SoldInfo {
  soldReason: string;
  soldPrice: number;
}

export interface PossessionInfo {
  possessionComment: string;
}

export interface LatestBoostResponse {
  hashId: string;
  boostedCount: number;
  latestBoostedAt: string;
}

export interface UpdateStatusMultiListingFormDataRequest {
  hashIds: string[];
  itemStatus?: string;
  soldPrice?: number;
  soldReason?: string;
  rejectReason?: string;
  notifyUserUnlistedItem?: boolean;
}

export type SchemaVersion = 'v1' | 'v2';

export type listingItemResponse = {
  documentNote?: string;
  firstRegistrationYear: number;
  transmission: string;
  operatingHours: number;
  mileage: number;
  purchasePrice: number;
  purchaseYear: number;
  vehicleRegNo: string;
  licencePlate: string;
  vin: string;
  title: string;
  detail: string;
  pickupLocation: string;
  imageUrl: string;
  soldReason: string;
  sellingPrice: number;
  markdownPrice: number;
  viewCount: number;
  quality: number;
  comment: string;
  acceptedPaymentTypes: string;
  status: ListingStatus;
  cleansingStatus?: ListingCleansingStatus;
  possession: ListingPossession;
  possessionComment?: string;
  isNotRequiredFinancial: boolean;
  crStatus?: ListingCRStatus;
  publishedAt: string;
  soldAt: string;
  createdAt: string;
  updatedAt: string;
  tags?: string[];
  slugs?: string[];
  hashUids?: string[];
  categoryUids?: number[];
  categoryPath: CategoryPathItem[];
  rootCategoryUid?: number;
  subCategoryUids?: number[];
  spacialCategoryUids?: number[];
  brandUid?: number;
  modelUid?: number;
  brandName?: string;
  modelName?: string;
  hashId: string;
  rootCategoryHashId?: string;
  subCategoryHashId?: string;
  spacialCategoryHashId?: string;
  userHashId: string;
  isPublic: boolean;
  schema: SchemaVersion;
  images: ListingDataImageItem[];
  vehicleRegistrationBookImages: ListingDataImageItem[];
  video: {
    fileName: string;
    hashId: string;
    size: number;
    url: string;
  };
  userMembership?: string;
  isVerified?: boolean;
  boostedCount?: number;
  boostedUntil?: string;
  latestBoostedAt?: string | null;
  maxBoostLevel?: number;
  boostLevel?: number;
};

export interface GetViewCountResponse {
  itemHashId: string;
  viewCount: number;
}
