import React, { FC } from 'react';

import ImageIcon from '@material-ui/icons/Image';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import TheatersIcon from '@material-ui/icons/Theaters';

import { getFileType } from '../../../helpers/utils';

export type AppFileTypeIconProps = { filePath: string; iconClassName?: string };

const AppFileTypeIcon: FC<AppFileTypeIconProps> = (props) => {
  const { filePath, iconClassName } = props;

  if (!filePath) {
    return null;
  }

  const fileType = getFileType(filePath);

  if (!fileType) {
    return null;
  }

  return (
    <>
      {fileType === 'image' && <ImageIcon className={iconClassName || ''} />}
      {fileType === 'media' && <TheatersIcon className={iconClassName || ''} />}
      {fileType === 'misc' && <InsertDriveFileIcon className={iconClassName || ''} />}
    </>
  );
};

export default AppFileTypeIcon;
