import React, { ChangeEvent, FC, useContext, useRef, useState } from 'react';

import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  makeStyles,
  OutlinedInput,
  OutlinedInputProps,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import { useTranslation } from 'react-i18next';

import { AppGlobalUiContext } from '../../../context/AppGlobalUiContext';
import { ApiResponse, UserImage, UserTCImage } from '../../../models';
import * as fileService from '../../../services/file';
import ImageThumbnail from '../../partials/common/ImageThumbnail';
import AppButton, { AppButtonProps } from '../AppButton';

export type AppTCFileSelectorProps = {
  defaultFile?: UserImage;
  fileMode?: 'image';
  multiple?: boolean;
  buttonProps?: AppButtonProps;
  inputProps?: OutlinedInputProps;
  clearable?: boolean;
  disabled?: boolean;
  onImageClick?: (imageUrl: string) => void;
  onFilesSelected?: (files: UserImage) => void;
  onValueCleared?: () => void;
  imageType?: UserTCImage;
  userHashId?: string;
};

const useStyles = makeStyles(() => ({
  readOnlyInput: {
    '& > input': {
      cursor: 'default',
    },
  },
  hiddenFileInput: {
    position: 'absolute',
    opacity: 0,
    width: 0,
    top: -9999,
    left: -9999,
    visibility: 'hidden',
  },
}));

const AppTCFileSelector: FC<AppTCFileSelectorProps> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    fileMode = 'image',
    multiple,
    inputProps,
    onFilesSelected,
    onValueCleared,
    defaultFile,
    onImageClick,
    clearable = true,
    disabled = false,
    imageType = UserTCImage.selfie,
    userHashId,
  } = props;
  const { showSnackbar } = useContext(AppGlobalUiContext);
  const [selectedFiles, setSelectedFiles] = useState<UserImage | undefined>(defaultFile);
  const hiddenFileInputRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getInputText = () => {
    if (!selectedFiles) {
      return defaultFile?.fullUrl || '';
    }

    if (!multiple || selectedFiles) {
      return selectedFiles.fullUrl;
    }

    return t('common:mediaLibrary.selectedFiles', {
      fileCount: selectedFiles,
    }).toString();
  };

  const uploadFile = (
    fileUpload: File[],
    imageDimensionUpload: { width: number; height: number; x: number; y: number },
  ) => {
    if (!isLoading) {
      setIsLoading(true);
      (async () => {
        const uploadImageTcUser =
          imageType === UserTCImage.shopHeroImage || imageType === UserTCImage.shopLocationImage;
        const requestList = fileUpload.map((file: File) =>
          uploadImageTcUser && userHashId
            ? fileService.uploadImageTcUser(file, imageType, userHashId)
            : fileService.uploadTCImage(file, imageType),
        );
        const result = await Promise.all(requestList);

        const fileUploadResult = result.map((res: ApiResponse<UserImage | null>) => (res.data ? 'success' : 'error'));
        // array 0 since input default to support single file select
        const resultFile = result[0]?.data;

        if (resultFile) {
          setSelectedFiles(resultFile);

          if (typeof onFilesSelected === 'function') {
            onFilesSelected({ ...resultFile, style: JSON.stringify(imageDimensionUpload) });
          }
        }
        fileUploadResult?.forEach((res) => {
          showSnackbar(res === 'success' ? 'upload ไฟล์สำเร็จ' : 'upload ไฟล์ล้มเหลว', res);
        });
        setIsLoading(false);
      })();
    }
  };

  const onClearClicked = () => {
    setIsLoading(true);
    setSelectedFiles(undefined);
    if (hiddenFileInputRef?.current) {
      hiddenFileInputRef.current.files = null;
      hiddenFileInputRef.current.value = '';
    }
    if (typeof onValueCleared === 'function') {
      onValueCleared();
    }
    setIsLoading(false);
  };

  const openFileUploadClickHandler = () => {
    if (hiddenFileInputRef.current) {
      hiddenFileInputRef.current.click();
    }
  };

  const onInputFileChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    const fileList = e.target.files;

    if (fileList && file) {
      const files: File[] = [];

      for (let i = 0; i < fileList.length; i += 1) {
        const fileItem = fileList.item(i);
        if (fileItem) {
          files.push(fileItem);
        }
      }

      let dimension: { width: number; height: number; x: number; y: number } | null = null;
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          dimension = { width: img.width, height: img.height, x: 0, y: 0 };

          if (files.length > 0 && dimension != null) {
            uploadFile(files, dimension);
          }
        };
        img.src = event.target?.result as string;
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      {fileMode === 'image' && (
        <FormControl variant="outlined">
          {inputProps?.label && <InputLabel>{inputProps?.label}</InputLabel>}
          <input
            type="file"
            accept="image/*"
            className={classes.hiddenFileInput}
            ref={hiddenFileInputRef}
            onChange={onInputFileChanged}
          />
          {selectedFiles && (
            <ImageThumbnail
              imageUrl={selectedFiles.fullUrl}
              altText="Listing Image"
              imageClick={onImageClick}
              /* eslint-disable react/jsx-boolean-value */
              noLabel={true}
            />
          )}
          <OutlinedInput
            readOnly
            value={getInputText()}
            fullWidth
            {...inputProps}
            className={classes.readOnlyInput}
            disabled={disabled}
            endAdornment={
              <InputAdornment position="end">
                {clearable && !disabled && getInputText() && (
                  <IconButton aria-label="clear" onClick={() => onClearClicked()} edge="end">
                    <ClearIcon />
                  </IconButton>
                )}
                {!getInputText() && (
                  <AppButton
                    onClick={() => openFileUploadClickHandler()}
                    variant="outlined"
                    startIcon={<AddIcon fontSize="small" />}
                    disabled={disabled || isLoading}>
                    {t('common:button.upload')}
                  </AppButton>
                )}
              </InputAdornment>
            }
          />
        </FormControl>
      )}
    </>
  );
};

export default AppTCFileSelector;
