/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useState } from 'react';

import { Box, Button, CircularProgress, Radio, TextField, Typography } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import { Controller, UseFormReturn } from 'react-hook-form';

import { AppGlobalUiContext } from '../../../../context/AppGlobalUiContext';
import { YEAR_LIST } from '../../../../helpers/utils';
import { KTCategoryPathItem, KTMasterIndexAttributeDataType } from '../../../../models';
import { getEsCategoryChildren } from '../../../../services';
import AppCheckbox from '../../../ui/AppCheckbox';
import AppFormControl from '../../../ui/AppFormControl';
import ImagePreview from '../../common/ImagePreview';
import ModalSelectColor from '../ModalSelectColor';
import ModalSelectValue, { ModalSelectValueProps } from '../ModalSelectValue';

const MIN_NUMBER_VALUE = 1;
const MAX_NUMBER_VALUE = 99999999;
const MAX_NUMBER_ENGINE_HOUSE_POWER_VALUE = 9999;
const MAX_NUMBER_ENGINE_SIZE_VALUE = 99999;
const YEAR_SELECT_LIST = YEAR_LIST.map((i: any) => ({
  name: i.namePrefix,
  value: i.value.toString(),
}));

interface FieldByAttributeDataTypeProps {
  value?: string | number;
  itemPath: KTCategoryPathItem;
  path: KTCategoryPathItem[];
  defaultValue: any;
  formHolder: UseFormReturn<any>;
  isEditingForm: boolean;
  onSetValue?: (data: { name?: string; value?: any; uid?: number } & any) => void;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const FieldByAttributeDataType = (props: FieldByAttributeDataTypeProps) => {
  const { value = '', itemPath, path, defaultValue, formHolder, onSetValue, isEditingForm } = props;

  const { showSnackbar } = useContext(AppGlobalUiContext);

  const masterIndexData = itemPath.masterIndex;

  const [dataList, setDataList] = useState<ModalSelectValueProps['dataList']>([]);

  const [currentValuePreview, setCurrentValuePreview] = useState<string | number | null>();

  const [isModalSelectDataOpen, setIsModalSelectDataOpen] = useState(false);

  const [modalExampleImage, setModalExampleImage] = useState<string | undefined>(undefined);

  const [isLoadingDataList, setIsLoadingDataList] = useState(false);
  const [isLoadingDataListError, setIsLoadingDataListError] = useState(false);
  const [dependOnNotice, setDependOnNotice] = useState<string>('');
  const [yearDisplay, setYearDisplay] = useState<string>('');

  const {
    control,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = formHolder;

  const isRequired = Boolean(itemPath?.optional) !== true;
  const labelInput = `${masterIndexData.thaiName} ${isRequired ? '*' : ''}`;

  const handleSetCurrentValuePreview = (
    currentValue: string | number | undefined | null,
    currentDataList: ModalSelectValueProps['dataList'],
  ) => {
    if (currentValue) {
      if (currentDataList?.length > 0) {
        const currentData = currentDataList?.find((item: any) => item.value === currentValue);
        setCurrentValuePreview(currentData ? currentData?.name : currentValue);
      } else {
        setCurrentValuePreview(currentValue);
      }
    } else {
      setCurrentValuePreview('');
    }
  };

  React.useEffect(() => {
    path.forEach((pathItem) => {
      if (pathItem.dependOnCategories) {
        pathItem.dependOnCategories.forEach((parentItem) => {
          if (
            !!parentItem.masterIndex &&
            getValues(parentItem.masterIndex.slug) &&
            !getValues(pathItem.masterIndex.slug) &&
            pathItem.masterIndex.slug === itemPath.masterIndex.slug
          ) {
            setCurrentValuePreview('');
          } else if (dependOnNotice === '' && pathItem.masterIndex.slug === itemPath.masterIndex.slug) {
            let dependOnCategories = '';
            if (pathItem.dependOnCategories) {
              pathItem.dependOnCategories.forEach((dependOnCat) => {
                dependOnCategories = `${dependOnCategories}${dependOnCat.thaiName.toString()}, `;
              });
              setDependOnNotice(`(ต้องเลือก ${parentItem.masterIndex.thaiName} = [${dependOnCategories}])`);
            }
          }
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch()]);

  const handleSetValue = (data: any) => {
    path.forEach((pathItem) => {
      if (pathItem.dependOnCategories) {
        pathItem.dependOnCategories.forEach((dependItem) => {
          if (!!dependItem.masterIndex && data.name === dependItem.masterIndex.slug) {
            if (onSetValue) {
              onSetValue({ name: pathItem.masterIndex.slug, value: null, uid: null });
            }
            setValue(pathItem.masterIndex.slug, null);
          }
        });
      }
    });
    if (onSetValue) {
      onSetValue(data);
    }
    setValue(data?.name, data?.value);
    handleSetCurrentValuePreview(data?.value, dataList);
  };

  const handleSetKTCategoryChildrenToDataList = async (uid: any) => {
    if (dataList.length === 0) {
      setIsLoadingDataList(true);
      try {
        const result = await getEsCategoryChildren(uid, {
          sort: 'uid%7CASC',
        });

        const resultMapData = result.data?.esCategories.map((item: any) => ({
          ...item,
          name: item.thaiName,
          value: item.slug,
        }));
        if (resultMapData) {
          setDataList(resultMapData);
        }
        setIsLoadingDataList(false);
        setIsLoadingDataListError(false);
      } catch (error: any) {
        showSnackbar('เกิดข้อผิดพลาดขึ้น', 'error');
        setDataList([]);
        setIsLoadingDataList(false);
        setIsLoadingDataListError(true);
      }
    }
  };

  React.useEffect(() => {
    if (currentValuePreview && YEAR_SELECT_LIST.length > 0 && masterIndexData.slug === 'first-registration-year') {
      const yearData = YEAR_SELECT_LIST.find((item) => item.value === JSON.stringify(currentValuePreview));
      setYearDisplay(yearData?.name);
    }
  }, [currentValuePreview, masterIndexData.slug]);

  const handleSetDataListByAttributeDataType = (attributeDataType: KTMasterIndexAttributeDataType) => {
    switch (attributeDataType) {
      case KTMasterIndexAttributeDataType.CATEGORY:
        return handleSetKTCategoryChildrenToDataList(masterIndexData.uid);

      case KTMasterIndexAttributeDataType.ATTRIBUTE_CHOICE:
        return handleSetKTCategoryChildrenToDataList(masterIndexData.uid);

      case KTMasterIndexAttributeDataType.ATTRIBUTE_CHOICE_AS_COLOR:
        return handleSetKTCategoryChildrenToDataList(masterIndexData.uid);

      case KTMasterIndexAttributeDataType.ATTRIBUTE_CHOICE_AS_YEARS:
        return setDataList(YEAR_SELECT_LIST);

      default:
        return setDataList([]);
    }
  };

  const getFieldComponent = (currentItemPath: KTCategoryPathItem) => {
    switch (currentItemPath.masterIndex.attributeDataType) {
      case KTMasterIndexAttributeDataType.ATTRIBUTE_NUMBER:
      case KTMasterIndexAttributeDataType.ATTRIBUTE_TEXT:
        return (
          <Controller
            name={masterIndexData.slug}
            control={control}
            defaultValue={defaultValue}
            render={({ field }) => (
              <AppFormControl>
                <TextField
                  variant="outlined"
                  margin="dense"
                  id="masterIndexDataThaiName"
                  label={`ระบุ${labelInput}`}
                  inputProps={
                    currentItemPath.masterIndex.attributeDataType === KTMasterIndexAttributeDataType.ATTRIBUTE_NUMBER
                      ? {
                          inputMode: 'numeric',
                          pattern: '[0-9]*',
                        }
                      : {
                          inputMode: 'text',
                          pattern: '*',
                        }
                  }
                  error={errors[masterIndexData.slug]?.message}
                  disabled={!isEditingForm}
                  {...field}
                />
              </AppFormControl>
            )}
            rules={{
              required: isRequired ? `โปรดระบุ${masterIndexData.thaiName}` : false,

              validate:
                currentItemPath.masterIndex.attributeDataType === KTMasterIndexAttributeDataType.ATTRIBUTE_NUMBER
                  ? (v) => {
                      if (currentItemPath.masterIndex.slug === 'engine-horse-power') {
                        if (v?.length > 0 && Number(v.replace(/\D/g, '')) < MIN_NUMBER_VALUE) {
                          return `ต้องอยู่ระหว่าง ${MIN_NUMBER_VALUE} ถึง ${MAX_NUMBER_ENGINE_HOUSE_POWER_VALUE.toLocaleString()}`;
                        }

                        if (v?.length > 0 && Number(v.replace(/\D/g, '')) > MAX_NUMBER_ENGINE_HOUSE_POWER_VALUE) {
                          return `ต้องอยู่ระหว่าง ${MIN_NUMBER_VALUE} ถึง ${MAX_NUMBER_ENGINE_HOUSE_POWER_VALUE.toLocaleString()}`;
                        }
                      }
                      if (currentItemPath.masterIndex.slug === 'engine-size') {
                        if (v?.length > 0 && Number(v.replace(/\D/g, '')) < MIN_NUMBER_VALUE) {
                          return `ต้องอยู่ระหว่าง ${MIN_NUMBER_VALUE} ถึง ${MAX_NUMBER_ENGINE_SIZE_VALUE.toLocaleString()}`;
                        }

                        if (v?.length > 0 && Number(v.replace(/\D/g, '')) > MAX_NUMBER_ENGINE_SIZE_VALUE) {
                          return `ต้องอยู่ระหว่าง ${MIN_NUMBER_VALUE} ถึง ${MAX_NUMBER_ENGINE_SIZE_VALUE.toLocaleString()}`;
                        }
                      }

                      if (v?.length > 0 && Number(v.replace(/\D/g, '')) < MIN_NUMBER_VALUE) {
                        return `ต้องอยู่ระหว่าง ${MIN_NUMBER_VALUE} ถึง ${MAX_NUMBER_VALUE.toLocaleString()}`;
                      }

                      if (v?.length > 0 && Number(v.replace(/\D/g, '')) > MAX_NUMBER_VALUE) {
                        return `ต้องอยู่ระหว่าง ${MIN_NUMBER_VALUE} ถึง ${MAX_NUMBER_VALUE.toLocaleString()}`;
                      }
                      return undefined;
                    }
                  : undefined,
            }}
          />
        );

      case KTMasterIndexAttributeDataType.ATTRIBUTE_BOOLEAN:
        return (
          <AppFormControl>
            <AppCheckbox
              title={labelInput}
              checked={value?.toString() === 'true'}
              color="default"
              onChange={(e: any) => {
                if (e.target.checked) {
                  handleSetValue({ name: masterIndexData.slug, value: true });
                } else {
                  handleSetValue({ name: masterIndexData.slug, value: false });
                }
              }}
            />
            <Controller
              name={masterIndexData.slug}
              control={control}
              defaultValue={defaultValue}
              render={() => <TextField style={{ display: 'none' }} />}
              rules={{
                required: isRequired ? `โปรดระบุ${masterIndexData.thaiName}` : false,
              }}
            />
          </AppFormControl>
        );

      case KTMasterIndexAttributeDataType.ATTRIBUTE_CHOICE:
        if (dataList.length < 3) {
          return (
            <Box display="flex" flexDirection="row">
              {dataList.map((item: any, index: any) => (
                <Box alignContent="center" width="30%" textAlign="center">
                  {/* eslint-disable-next-line react/no-array-index-key */}
                  <React.Fragment key={index}>
                    <Radio
                      name={item.name}
                      checked={value === item.value}
                      disabled={!isEditingForm}
                      onChange={(e: any) => {
                        if (e.target.checked) {
                          handleSetValue({ name: masterIndexData.slug, value: item.value });
                        }
                      }}
                    />
                    <Typography>{item.name}</Typography>
                    <Controller
                      name={masterIndexData.slug}
                      control={control}
                      defaultValue={defaultValue}
                      render={() => <TextField style={{ display: 'none' }} />}
                      rules={{
                        required: isRequired ? `โปรดระบุ${masterIndexData.thaiName}` : false,
                      }}
                    />
                  </React.Fragment>
                </Box>
              ))}
            </Box>
          );
        }
        return (
          <>
            <Button
              style={{ width: '80%' }}
              onClick={() => {
                if (isEditingForm) {
                  setIsModalSelectDataOpen(true);
                }
              }}>
              <TextField
                fullWidth
                variant="outlined"
                margin="dense"
                name={`preview-${masterIndexData.slug}`}
                placeholder={currentItemPath.placeholder || `เลือก${labelInput}`}
                value={currentValuePreview}
                error={errors[masterIndexData.slug]?.message}
                style={{ pointerEvents: 'none' }}
                disabled={!isEditingForm}
              />
              <ArrowDropDownIcon color="primary" />
              <Controller
                name={masterIndexData.slug}
                control={control}
                defaultValue={defaultValue}
                render={() => <TextField style={{ display: 'none' }} />}
                rules={{
                  required: isRequired ? `โปรดเลือก${labelInput}` : false,
                }}
              />
            </Button>
            {value && isEditingForm && (
              <Button
                variant="outlined"
                onClick={() => handleSetValue({ name: masterIndexData.slug, value: null, uid: null })}>
                <CloseIcon color="primary" />
              </Button>
            )}
            <ModalSelectValue
              isOpen={isModalSelectDataOpen}
              selectedValue={value}
              title={labelInput}
              dataList={dataList}
              onClickItem={(data: any) => {
                handleSetValue({ name: masterIndexData.slug, value: data.value });
              }}
              handleClose={() => {
                setIsModalSelectDataOpen(false);
              }}
            />
          </>
        );

      case KTMasterIndexAttributeDataType.ATTRIBUTE_CHOICE_AS_COLOR:
        return (
          <>
            <Button
              style={{ width: '80%' }}
              onClick={() => {
                if (isEditingForm) {
                  setIsModalSelectDataOpen(true);
                }
              }}>
              <TextField
                fullWidth
                variant="outlined"
                margin="dense"
                name={`preview-${masterIndexData.slug}`}
                placeholder={currentItemPath.placeholder || `เลือก${labelInput}`}
                value={currentValuePreview}
                error={errors[masterIndexData.slug]?.message}
                style={{ pointerEvents: 'none' }}
                disabled={!isEditingForm}
              />
              <ArrowDropDownIcon color="primary" />
              <Controller
                name={masterIndexData.slug}
                control={control}
                defaultValue={defaultValue}
                render={() => <TextField style={{ display: 'none' }} />}
                rules={{
                  required: isRequired ? `โปรดเลือก${labelInput}` : false,
                }}
              />
            </Button>
            {value && isEditingForm && (
              <Button
                variant="outlined"
                onClick={() => handleSetValue({ name: masterIndexData.slug, value: null, uid: null })}>
                <CloseIcon color="primary" />
              </Button>
            )}
            <ModalSelectColor
              isOpen={isModalSelectDataOpen}
              selectedValue={value}
              title={labelInput}
              dataList={dataList}
              onClickItem={(data: any) => {
                handleSetValue({ name: masterIndexData.slug, value: data.value });
              }}
              handleClose={() => {
                setIsModalSelectDataOpen(false);
              }}
            />
          </>
        );

      default:
        if (
          [KTMasterIndexAttributeDataType.CATEGORY, KTMasterIndexAttributeDataType.ATTRIBUTE_CHOICE_AS_YEARS].includes(
            masterIndexData.attributeDataType,
          )
        ) {
          return (
            <>
              <Button
                style={{ width: '80%' }}
                onClick={() => {
                  if (isEditingForm) {
                    setIsModalSelectDataOpen(true);
                  }
                }}>
                <TextField
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  name={`preview-${masterIndexData.slug}`}
                  placeholder={currentItemPath.placeholder || `เลือก${labelInput}`}
                  value={
                    masterIndexData.slug === 'first-registration-year'
                      ? yearDisplay || currentValuePreview
                      : currentValuePreview
                  }
                  error={errors[masterIndexData.slug]?.message}
                  style={{ pointerEvents: 'none' }}
                  disabled={!isEditingForm}
                />
                <ArrowDropDownIcon color="primary" />
                <Controller
                  name={masterIndexData.slug}
                  control={control}
                  defaultValue={defaultValue}
                  render={() => <TextField style={{ display: 'none' }} />}
                  rules={{
                    required: isRequired ? `โปรดเลือก${labelInput}` : false,
                  }}
                />
              </Button>
              {value && isEditingForm && (
                <Button
                  variant="outlined"
                  onClick={() => handleSetValue({ name: masterIndexData.slug, value: null, uid: null })}>
                  <CloseIcon color="primary" />
                </Button>
              )}
              <ModalSelectValue
                isOpen={isModalSelectDataOpen}
                selectedValue={value}
                title={labelInput}
                dataList={dataList}
                onClickItem={(data: any) => {
                  handleSetValue({ name: masterIndexData.slug, value: data.value, uid: data.uid });
                }}
                handleClose={() => {
                  setIsModalSelectDataOpen(false);
                }}
              />
            </>
          );
        }
        return <>{masterIndexData.attributeDataType}</>;
    }
  };

  React.useEffect(() => {
    handleSetDataListByAttributeDataType(masterIndexData.attributeDataType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterIndexData.id]);

  React.useEffect(() => {
    handleSetCurrentValuePreview(value, dataList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataList]);

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography id={masterIndexData.slug}>
          {labelInput} {dependOnNotice}
        </Typography>
        {itemPath?.exampleUrl && (
          <Button onClick={() => setModalExampleImage(itemPath?.exampleUrl)}>
            <InfoIcon style={{ marginRight: 2 }} color="secondary" fontSize="default" />
            ดูตัวอย่าง
          </Button>
        )}
      </Box>
      {/* eslint-disable-next-line no-nested-ternary */}
      {isLoadingDataListError ? (
        <Button
          onClick={() => handleSetDataListByAttributeDataType(masterIndexData.attributeDataType)}
          disabled={isLoadingDataList}>
          {isLoadingDataList ? (
            <CircularProgress size={16} color="secondary" />
          ) : (
            <InfoIcon style={{ marginRight: 2 }} color="error" fontSize="default" />
          )}
          <Typography variant="subtitle1">โหลดอีกครั้ง</Typography>
        </Button>
      ) : isLoadingDataList ? (
        <Box mt={2}>
          <CircularProgress size={20} />
        </Box>
      ) : (
        <>
          {getFieldComponent(itemPath)}
          {itemPath?.exampleUrl && (
            <ImagePreview imageUrl={modalExampleImage} onCloseClick={() => setModalExampleImage(undefined)} asModal />
          )}
        </>
      )}

      {errors[masterIndexData.slug]?.message && (
        <Typography style={{ marginTop: 2 }} color="error" variant="subtitle1">
          {errors[masterIndexData.slug]?.message}
        </Typography>
      )}
    </>
  );
};

export default FieldByAttributeDataType;
