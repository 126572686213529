/* eslint-disable import/prefer-default-export */
import { apiKTRequest } from '../helpers/request';
import { apiResponseTransformer } from '../helpers/response';
import { ApiResponse } from '../models';

type GetAllBlogSlugParams = {
  page?: number;
  // eslint-disable-next-line camelcase
  per_page?: number;
  sort?: string;
};

export type ItemBlogSlugResponse = {
  slug: string;
  categorySlug: string;
  brandSlug: string;
  modelSlug: string;
  createdAt: string;
  updatedAt: string;
  id: number;
};

type GetAllBlogSlugResponse = {
  brandModelBlogSlugs: ItemBlogSlugResponse[];
  page: {
    size: number;
    totalElements: number;
    totalPages: number;
    number: number;
  };
};

export type UpdateBlogSlugRequestData = {
  categorySlug: string | null;
  brandSlug: string | null;
  modelSlug: string | null;
  slug: string | null;
};

export const getAllBlogSlug = async (params?: GetAllBlogSlugParams): Promise<ApiResponse<GetAllBlogSlugResponse>> => {
  const result = await apiKTRequest<GetAllBlogSlugResponse>('/admin_manager/brand-model-blog-slug-all', {
    method: 'GET',
    params,
  });
  return apiResponseTransformer(result, 'Error getAllBlogSlug');
};

export const addBlogSlugs = async (
  data: UpdateBlogSlugRequestData[],
): Promise<ApiResponse<GetAllBlogSlugResponse['brandModelBlogSlugs']>> => {
  const prepareData: UpdateBlogSlugRequestData[] = data.map((item) => ({
    categorySlug: (item.categorySlug !== '' ? item.categorySlug : null)?.trim() ?? null,
    brandSlug: (item.brandSlug !== '' ? item.brandSlug : null)?.trim() ?? null,
    modelSlug: (item.modelSlug !== '' ? item.modelSlug : null)?.trim() ?? null,
    slug: (item.slug !== '' ? item.slug : null)?.trim() ?? null,
  }));

  const result = await apiKTRequest<GetAllBlogSlugResponse>('/admin_manager/brand-model-blog-slug', {
    method: 'POST',
    data: prepareData,
  });
  return apiResponseTransformer(result, 'Error addBlogSlugs');
};

export const editBlogSlugById = async (
  id: number,
  data: UpdateBlogSlugRequestData,
): Promise<ApiResponse<ItemBlogSlugResponse>> => {
  const prepareData = {
    categorySlug: (data.categorySlug !== '' ? data.categorySlug : null)?.trim() ?? null,
    brandSlug: (data.brandSlug !== '' ? data.brandSlug : null)?.trim() ?? null,
    modelSlug: (data.modelSlug !== '' ? data.modelSlug : null)?.trim() ?? null,
    slug: (data.slug !== '' ? data.slug : null)?.trim() ?? null,
  };

  const result = await apiKTRequest<ItemBlogSlugResponse>(`/admin_manager/brand-model-blog-slug/${id}`, {
    method: 'POST',
    data: prepareData,
  });
  return apiResponseTransformer(result, 'Error editBlogSlugById');
};

export const deleteBlogSlugById = async (uid: number): Promise<ApiResponse<boolean>> => {
  try {
    await apiKTRequest<boolean>(`/admin_manager/brand-model-blog-slug/${uid.toString()}`, {
      method: 'DELETE',
    });

    return {
      data: true,
    };
  } catch (error) {
    return {
      data: null,
      error: 'Error deleteBlogSlugById',
    };
  }
};
