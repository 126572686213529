import React, { FC } from 'react';

import reportListingRoutes from './route.config';
import ModuleMainPage from '../../components/templates/ModuleMainPage';

const ReportListingMain: FC = () => (
  <ModuleMainPage pageTitle="common:title.reportListing" routeFunctions={reportListingRoutes} />
);

export default ReportListingMain;
