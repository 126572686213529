import React, { FC, ReactNode, useCallback, useContext, useEffect, useState } from 'react';

import {
  Avatar,
  Box,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import BlockIcon from '@material-ui/icons/Block';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import RemoveIcon from '@material-ui/icons/Remove';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { Controller, FieldError, UseControllerProps, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { Link, useHistory } from 'react-router-dom';

import { AppGlobalUiContext } from '../../../../context/AppGlobalUiContext';
import { AuthContext } from '../../../../context/AuthContext';
import { formatDate } from '../../../../helpers/date';
import { getFacebookLink, getLineLink, getT2HSiteUrl } from '../../../../helpers/utils';
import usePrivilege from '../../../../hooks/usePrivilege';
import {
  EmailSubscription,
  SelectItem,
  SellerApplication,
  User,
  UserEmailSubscription,
  UserRole,
  PaymentOption,
  remark,
  PaymentHistoryRequest,
  SocialLink,
  ReverifyFormData,
  FileResponse,
} from '../../../../models';
import * as paymentService from '../../../../services/payment';
import * as sellerAppService from '../../../../services/seller-applications';
import * as userService from '../../../../services/user';
import useControlStyles from '../../../../theme/controls.style';
import useTypographyStyles from '../../../../theme/typography.style';
import AppButton from '../../../ui/AppButton';
import AppCheckbox from '../../../ui/AppCheckbox';
import AppDatePicker from '../../../ui/AppDatePicker';
import AppDatetimeText from '../../../ui/AppDatetimeText';
import AppDialog from '../../../ui/AppDialog';
import AppFileSelector from '../../../ui/AppFileSelector';
import AppFormControl from '../../../ui/AppFormControl';
import AppFormErrorMessage from '../../../ui/AppFormErrorMessage';
import AppCircularProgress from '../../AppCircularProgress';
import ImagePreview from '../../common/ImagePreview';
import ImageThumbnail from '../../common/ImageThumbnail';
import MasterDataDropdown from '../../common/MasterDataDropdown';
import UserNotificationDialog from '../../common/UserNotificationDialog';
import UserPaymentHistoryDialog from '../../common/UserPaymentHistoryDialog';
import YoutubeThumbnail, { getYoutubeIdFromUrl } from '../../common/YoutubeThumbnail';
import UserAccountSuspendDialog from '../UserAccountSuspendDialog';

export type UserDetailProps = {
  user: User;
  latestSellerApplication?: SellerApplication;
  rules?: { [x: string]: UseControllerProps['rules'] };
};

type UserInfoBlockProps = {
  label: string;
  data: ReactNode;
  onlySeller?: boolean;
  endAdornment?: ReactNode;
  noWrapText?: boolean;
};

type UserEditFormProps = {
  userRole: string;
  userBalance: number;
  interviewLog?: string;
  coinAmount: string;
  sellerType: string;
  sellingCategory: string;
  paymentOption: string;
  remark: string;
  remarkEtc: string;
  productPrice: number;
  statusRemark: string;
  newsletterSubscription: boolean;
  membershipProductHashId: string | null;
  membershipStartAt: dayjs.Dayjs | null;
  membershipEndAt: dayjs.Dayjs | null;
  socialMedias?: SocialLink[];
} & ReverifyFormData;

type EmailSubscriptionRequestResult = [EmailSubscription[] | undefined, UserEmailSubscription[] | undefined];

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  flexBreak: {
    flexBasis: '100%',
  },
  displayName: {
    fontWeight: 700,
    wordBreak: 'break-all',
  },
  rightColumn: {
    borderTop: `solid 1px ${theme.palette.divider}`,
    borderLeft: 'none',
    [theme.breakpoints.up('md')]: {
      flexBasis: '340px',
      borderLeft: `solid 1px ${theme.palette.divider}`,
      borderTop: 'none',
      paddingLeft: theme.spacing(4),
      paddingTop: theme.spacing(5),
      maxWidth: '340px',
    },
  },
  profileImageCol: {
    [theme.breakpoints.up('md')]: {
      flexBasis: '180px',
    },
  },
  profileImage: {
    width: '112px',
    height: '112px',
  },
  verifiedText: {
    fontWeight: 700,
  },
  titleBlock: {
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(1),
      paddingLeft: 0,
    },
  },
  dataBlock: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(1),
    },
  },
  dataText: {
    marginRight: theme.spacing(1),
    maxWidth: 'calc(100% - 8px)',
  },
  dataTextLink: {
    maxWidth: '100%',
    wordWrap: 'break-word',
  },
  coinAmountBox: {
    backgroundColor: theme.palette.grey['200'],
    height: '46px',
  },
  paymentOptionBox: {
    backgroundColor: theme.palette.grey['200'],
    height: '46px',
  },
  remarkBox: {
    backgroundColor: theme.palette.grey['200'],
    height: '46px',
  },
  progressionOverlay: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    zIndex: 10,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: theme.customPalette.global.lightOverlay.backgroundColor,
  },
  extLinkIcon: {
    fontSize: 18,
    verticalAlign: 'middle',
    marginLeft: 3,
  },
}));

const userRoleList: SelectItem<UserRole>[] = [
  { label: 'user:common.role.buyer', value: UserRole.Buyer },
  { label: 'user:common.role.seller', value: UserRole.Seller },
  { label: 'user:common.role.seller_bronze', value: UserRole.SellerBronze },
  { label: 'user:common.role.seller_silver', value: UserRole.SellerSilver },
  { label: 'user:common.role.seller_gold', value: UserRole.SellerGold },
];

const paymentOptionList: SelectItem<PaymentOption>[] = [
  { label: 'user:common.paymentOption.bank_transfer', value: PaymentOption.BankTransfer },
  { label: 'user:common.paymentOption.promptpay', value: PaymentOption.Promptpay },
  { label: 'user:common.paymentOption.cash', value: PaymentOption.Cash },
  { label: 'user:common.paymentOption.credit_card', value: PaymentOption.CreditCard },
];

const remarkList: SelectItem<remark>[] = [
  { label: 'user:common.paymentRemark.direct_contact_to_cr', value: remark.DirectContactToCR },
  { label: 'user:common.paymentRemark.sale_visit_customer', value: remark.SaleVisitCustomer },
  { label: 'user:common.paymentRemark.can_not_use_omise', value: remark.CantUseOmise },
  { label: 'user:common.paymentRemark.other', value: remark.Other },
];

const gridSpacing = 0;

const UserDetail: FC<UserDetailProps> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const typoClasses = useTypographyStyles();
  const controlClasses = useControlStyles();
  const { t } = useTranslation();
  const { user: propsUser, latestSellerApplication, rules } = props;
  const getRules = (fieldName: string): UseControllerProps['rules'] | undefined =>
    rules ? rules[fieldName] : undefined;
  const [currentUser, setCurrentUser] = useState<User>(propsUser);
  const { canPerform } = usePrivilege();
  const { showSnackbar, closeSnackbar, setAppLoading } = useContext(AppGlobalUiContext);
  const [isSavingData, setIsSavingData] = useState<boolean>(false);
  const [isEditingForm, setIsEditingForm] = useState<boolean>(false);
  const [userEmailSubscriptions, setUserEmailSubscriptions] = useState<UserEmailSubscription[]>([]);
  const [emailSubscriptionsList, setEmailSubscriptionsList] = useState<EmailSubscription[]>([]);
  const [isUpdatingSubscription, setIsUpdatingSubscription] = useState<boolean>(false);
  const [isAccDeactivationOpen, setIsAccDeactivationOpen] = useState(false);
  const [isAccActivationOpen, setIsAccActivationOpen] = useState(false);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [isVerifyEmailConfirmOpen, setIsVerifyEmailConfirmOpen] = useState(false);
  const [isAddYoutubeLinkDialogOpen, setIsAddYoutubeLinkDialogOpen] = useState(false);
  const [isUserPaymentHistoryDialogOpen, setIsUserPaymentHistoryDialogOpen] = useState<boolean>(false);
  const [isUserNotificationDialogOpen, setIsUserNotificationDialogOpen] = useState<boolean>(false);
  const [latestSellerApp, setLatestSellerApp] = useState<SellerApplication | undefined>(latestSellerApplication);
  const [selectedPreviewImage, setSelectedPreviewImage] = useState<string | undefined>(undefined);
  const { authUser } = useContext(AuthContext);
  const [currentFormData, setCurrentFormData] = useState<UserEditFormProps>({
    userRole: currentUser.role,
    userBalance: currentUser.balance || 0,
    interviewLog: currentUser.interviewLog || '',
    coinAmount: '',
    paymentOption: '',
    remark: '',
    remarkEtc: '',
    productPrice: 0,
    sellerType: '', // TODO: Implement when it's ready
    sellingCategory: '', // TODO: Implement when it's ready
    statusRemark: 'normal', // TODO: Implement when it's ready
    newsletterSubscription: false,
    membershipProductHashId: currentUser.membershipProductHashId || null,
    membershipStartAt: currentUser.membershipStartAt ? dayjs(currentUser.membershipStartAt) : null,
    membershipEndAt: currentUser.membershipEndAt ? dayjs(currentUser.membershipEndAt) : null,
    socialMedias: currentUser.socialMedias,
    reVerifyIdentityFile1:
      latestSellerApp?.adminImage && latestSellerApp?.adminImage[0] ? latestSellerApp?.adminImage[0] : undefined,
    reVerifyIdentityFile2:
      latestSellerApp?.adminImage && latestSellerApp?.adminImage[1] ? latestSellerApp?.adminImage[1] : undefined,
    reVerifyIdentityFile3:
      latestSellerApp?.adminImage && latestSellerApp?.adminImage[2] ? latestSellerApp?.adminImage[2] : undefined,
    reVerifyIdentityFile4:
      latestSellerApp?.adminImage && latestSellerApp?.adminImage[3] ? latestSellerApp?.adminImage[3] : undefined,
    reVerifyIdentityFile5:
      latestSellerApp?.adminImage && latestSellerApp?.adminImage[4] ? latestSellerApp?.adminImage[4] : undefined,
  });
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm<UserEditFormProps>({
    mode: 'onSubmit',
    defaultValues: currentFormData,
  });
  const currentDisplayBalance = watch('userBalance', currentUser.balance);
  const canUpdateUserBalance = canPerform('userBalance', 'update');
  const canUpdateUserMembership = canPerform('userMembership', 'update');
  const canUpdateUserSocialMedias = canPerform('userSocialUrls', 'update');
  const canUpdateEmailSubscription = canPerform('userEmailSubscription', 'update');
  const canActivateAccount = canPerform('userAccount', 'activate');
  const canDeactivateAccount = canPerform('userAccount', 'deactivate');
  const canDeleteAccount = canPerform('userAccount', 'delete');
  const canUpdateEmailVerification = canPerform('userEmailVerification', 'update');
  const canUpdateUserRole = canPerform('userRole', 'update');
  const canUpdateUserInterviewLog = canPerform('userInterviewLog', 'update');
  const canEditUserDetail =
    canUpdateUserBalance || canUpdateUserMembership || canUpdateUserRole || canUpdateUserInterviewLog;
  const [isInterviewLogChanged, setIsInterviewLogChanged] = useState(false);
  const [remarkEtc, setRemarkEtc] = useState(false);

  const {
    control: dialogYoutubeFormControl,
    handleSubmit: dialogYoutubeFormHandleSubmit,
    getValues: dialogYoutubeFormGetValues,
    reset: dialogYoutubeFormResetValue,
    formState: { errors: dialogYoutubeFormErrors },
  } = useForm<SocialLink>({
    mode: 'onSubmit',
    defaultValues: {
      title: '',
      url: '',
    },
  });

  const youtubeLinkLimit = 1000;
  const isAbleAddingYoutubeLink =
    isEditingForm && (!currentFormData?.socialMedias || currentFormData?.socialMedias?.length < youtubeLinkLimit);

  useEffect(() => {
    if (getValues('remark') === remark.Other) {
      setRemarkEtc(true);
    } else {
      setRemarkEtc(false);
      setValue('remarkEtc', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('remark')]);

  useEffect(() => {
    setIsInterviewLogChanged(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('interviewLog')]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useEffect((): any => {
    let isSubscribed = true;

    Promise.all([userService.getEmailSubscriptions(), userService.getUserEmailSubscriptions(currentUser.hashId)])
      .then((results: EmailSubscriptionRequestResult): void => {
        if (!isSubscribed) {
          return;
        }

        setEmailSubscriptionsList(results[0] || []);
        setUserEmailSubscriptions(results[1] || []);
      })
      .catch(() =>
        isSubscribed ? showSnackbar(t('user:error.edit.getEmailSubscriptionFailed').toString(), 'error') : null,
      );

    return () => (isSubscribed = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLatestSellerApp(latestSellerApplication);
  }, [latestSellerApplication]);

  useEffect(() => {
    if (latestSellerApplication?.adminImage) {
      setValue('reVerifyIdentityFile1', latestSellerApplication?.adminImage[0] || undefined);
      setValue('reVerifyIdentityFile2', latestSellerApplication?.adminImage[1] || undefined);
      setValue('reVerifyIdentityFile3', latestSellerApplication?.adminImage[2] || undefined);
      setValue('reVerifyIdentityFile4', latestSellerApplication?.adminImage[3] || undefined);
      setValue('reVerifyIdentityFile5', latestSellerApplication?.adminImage[4] || undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latestSellerApplication?.adminImage]);

  const UserInfoBlock: FC<UserInfoBlockProps> = (blockProps) => {
    const { label, data, onlySeller = false, endAdornment = undefined, noWrapText = true } = blockProps;
    if (!onlySeller || (onlySeller && currentUser.role !== UserRole.Buyer)) {
      return (
        <>
          <div className={classes.flexBreak} />
          <Grid item xs={12} classes={{ root: classes.profileImageCol }}>
            <Box className={classes.titleBlock}>{`${t(label)} :`}</Box>
          </Grid>
          <Grid item xs={12} md zeroMinWidth>
            <Box display="flex" flexWrap="wrap" flexDirection="row" alignItems="center" className={classes.dataBlock}>
              <Typography component="span" noWrap={noWrapText} className={classes.dataText}>
                {data || '-'}
              </Typography>
              {endAdornment}
            </Box>
          </Grid>
        </>
      );
    }

    return null;
  };

  const updateApplicationFileSave = async (updateData: UserEditFormProps) => {
    updateData.adminImageHashIds = [];
    if (updateData.reVerifyIdentityFile1) {
      updateData.adminImageHashIds.push(updateData.reVerifyIdentityFile1.hashId);
    }
    if (updateData.reVerifyIdentityFile2) {
      updateData.adminImageHashIds.push(updateData.reVerifyIdentityFile2.hashId);
    }
    if (updateData.reVerifyIdentityFile3) {
      updateData.adminImageHashIds.push(updateData.reVerifyIdentityFile3.hashId);
    }
    if (updateData.reVerifyIdentityFile4) {
      updateData.adminImageHashIds.push(updateData.reVerifyIdentityFile4.hashId);
    }
    if (updateData.reVerifyIdentityFile5) {
      updateData.adminImageHashIds.push(updateData.reVerifyIdentityFile5.hashId);
    }

    if (latestSellerApp) {
      const result = await sellerAppService.updateSellerApplication(latestSellerApp.hashId, updateData);

      if (result) {
        if (result.data) {
          showSnackbar(t(`seller-applications:common.message.reverifyingUpdateSuccess`).toString(), 'success');
          setLatestSellerApp(result.data);
        } else {
          showSnackbar(t(result.error).toString(), 'error');
        }
      }
    }
  };

  const editFormSubmitHandler = async (data: UserEditFormProps) => {
    // If form data is being saved, no duplicate submission is allowed.
    if (isSavingData) {
      return;
    }
    const updateRequestList = [];
    if (canUpdateUserBalance) {
      updateRequestList.push(userService.updateUserBalance(currentUser.hashId, data.userBalance));
    }
    if (canUpdateUserMembership) {
      updateRequestList.push(
        userService.updateUserMembership(currentUser.hashId, {
          membershipProductHashId: data.membershipProductHashId,
          membershipStartAt: data.membershipStartAt?.format('YYYY-MM-DD') || null,
          membershipEndAt: data.membershipEndAt?.format('YYYY-MM-DD') || null,
        }),
      );
    }
    if (canUpdateUserRole) {
      updateRequestList.push(userService.updateUserRole(currentUser.hashId, data.userRole));
    }
    if (canUpdateUserInterviewLog && isInterviewLogChanged) {
      updateRequestList.push(userService.updateUserInterviewLog(currentUser.hashId, data.interviewLog));
    }
    if (canUpdateUserSocialMedias && currentFormData.socialMedias) {
      updateRequestList.push(
        userService.updateUserSocialUrls(currentUser.hashId, {
          socialMedias: currentFormData.socialMedias,
        }),
      );
    }
    await updateApplicationFileSave(data);

    try {
      setIsSavingData(true);
      setAppLoading(true);

      // Close it if user immediately click submit after the last error
      closeSnackbar();
      const results = await Promise.all(updateRequestList);
      if (canUpdateUserBalance || canUpdateUserMembership || canUpdateUserRole || canUpdateUserSocialMedias) {
        const topUpAmount = data.userBalance - currentUser.balance;
        const coinTopUpName = topUpAmount === 0 ? `` : `coin ${topUpAmount} `;
        const userRoleUpdateName = currentUser.role === data.userRole ? `` : `สมาชิกระดับ ${data.userRole} `;
        const membershipUpdateName = ``;
        const productThaiName = `${coinTopUpName}${userRoleUpdateName}${membershipUpdateName}`;
        const paymentHistoryReq: PaymentHistoryRequest = {
          userHashId: currentUser.hashId,
          remark: data.remarkEtc ? data.remarkEtc : data.remark,
          paymentOption: data.paymentOption,
          productThaiName,
          productPrice: data.productPrice * 100,
        };
        await paymentService.createPaymentHistory(paymentHistoryReq);
      }
      // Clear form after saving
      setValue('coinAmount', '', { shouldValidate: true });
      setIsEditingForm(false);
      setIsSavingData(false);

      showSnackbar(t('user:common.message.updateSuccess').toString(), 'success');

      const resultUser = results.filter((result) => !!result) as User[];

      // We'll take the last result of request as a User object and set it back to state by reload page
      if (resultUser.length) {
        const delaySecond = 3;
        setTimeout(() => {
          window.location.reload();
        }, 1000 * delaySecond);
        return;
      }
    } catch (e) {
      showSnackbar(t('user:error.edit.updateFailed').toString(), 'error');
    } finally {
      setAppLoading(false);
    }
  };

  const addYoutubeLinkFormSubmitHandler = (rawData: SocialLink) => {
    const socialMedias = currentFormData?.socialMedias || [];
    const defaultCreatedDate = formatDate(new Date(), 'YYYY-MM-dd');

    const data: SocialLink = {
      ...rawData,
      createdDate: rawData.createdDate || defaultCreatedDate,
    };

    setCurrentFormData({
      ...currentFormData,
      socialMedias: [...socialMedias, data],
    });

    dialogYoutubeFormResetValue();
  };

  const editForm = () => {
    setCurrentFormData({ ...getValues() });
    setIsEditingForm(true);
  };

  const cancelEditForm = () => {
    Object.keys(currentFormData).forEach((formField: string) => {
      const fieldName = formField as keyof UserEditFormProps;
      // 'coinAmount' field will always be reset
      setValue(fieldName, fieldName === 'coinAmount' ? '' : currentFormData[fieldName], { shouldValidate: true });
    });
    setIsEditingForm(false);
  };

  const adjustBalance = (type: 'increase' | 'decrease'): void => {
    const updateBalance = (type === 'increase' ? 1 : -1) * parseInt(getValues('coinAmount') || '0', 10);
    const currentBalance = getValues('userBalance');
    let newUserBalance = currentBalance + updateBalance;
    if (newUserBalance < 0) {
      newUserBalance = 0;
    }
    setValue('userBalance', newUserBalance);
    setValue('coinAmount', '');
  };

  const isEmailSubscribed = useCallback(
    (subscriptionHashId: string): boolean => {
      if (userEmailSubscriptions.length === 0) {
        return true;
      }

      return !!userEmailSubscriptions.find(
        (item) => item.emailSubscription.hashId === subscriptionHashId && item.isSubscribed,
      );
    },
    [userEmailSubscriptions],
  );

  const updateEmailSubscription = async (
    e: React.ChangeEvent<HTMLInputElement>,
    subscriptionItem: EmailSubscription,
  ) => {
    if (canUpdateEmailSubscription) {
      try {
        setIsUpdatingSubscription(true);

        const isSubscribed = e.target.checked;

        await userService.updateUserEmailSubscription(currentUser.hashId, {
          email_id: subscriptionItem.hashId,
          subscribe: isSubscribed,
        });

        // The user email subscription list is needed to be updated
        const newUserSubscriptionList = isSubscribed
          ? [
              ...userEmailSubscriptions,
              {
                userHashId: currentUser.hashId,
                isSubscribed,
                emailSubscription: subscriptionItem,
              },
            ]
          : [
              {
                userHashId: currentUser.hashId,
                isSubscribed,
                emailSubscription: subscriptionItem,
              },
            ];

        setUserEmailSubscriptions(newUserSubscriptionList);
      } catch (ex) {
        showSnackbar(t('user:error.edit.updateFailed').toString(), 'error');
      } finally {
        setIsUpdatingSubscription(false);
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const NumberFormatCustom = (numberInputProps: any) => {
    const { inputRef, onChange, ...other } = numberInputProps;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              // eslint-disable-next-line react/destructuring-assignment
              name: numberInputProps.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
      />
    );
  };

  const membershipProductValidator = (membershipProductHashId: string | null) => {
    const membershipStartAt = getValues('membershipStartAt');
    const membershipEndAt = getValues('membershipEndAt');

    if (!membershipProductHashId && !!membershipStartAt && !!membershipEndAt) {
      return t('validation:invalidMembershipProduct').toString();
    }

    return true;
  };

  const membershipDateValidator = () => {
    const membershipProductHashId = getValues('membershipProductHashId');
    const membershipStartAt = getValues('membershipStartAt');
    const membershipEndAt = getValues('membershipEndAt');

    if (membershipProductHashId && (!membershipStartAt || !membershipEndAt)) {
      return t('validation:invalidMembershipDate').toString();
    }

    if (!!membershipStartAt && !!membershipEndAt && membershipStartAt.isAfter(membershipEndAt)) {
      return t('validation:invalidMembershipDateRange').toString();
    }

    return true;
  };

  const onAccountDeactivationConfirm = async (reason: string) => {
    try {
      setAppLoading(true);
      const user = await userService.deactivateUser(currentUser.hashId, reason);
      if (user) {
        setCurrentUser(new User(user));
        showSnackbar(t('user:common.message.updateAccountSuccess').toString(), 'success');
      }
    } catch (e) {
      showSnackbar(t('user:error.edit.deactivateAccountFailed').toString(), 'error');
    } finally {
      setAppLoading(false);
      setIsAccDeactivationOpen(false);
      // INFO: location.reload() -- Ref: https://developer.mozilla.org/en-US/docs/Web/API/History/go
      // https://dev.to/raaynaldo/react-router-usehistory-uselocation-and-useparams-10cd
      history.go(0);
    }
  };

  const onAccountDeactivationCancel = () => {
    setIsAccDeactivationOpen(false);
  };

  const onAccountActivationConfirm = async () => {
    try {
      setAppLoading(true);
      const user = await userService.activateUser(currentUser.hashId);
      if (user) {
        setCurrentUser(new User(user));
        showSnackbar(t('user:common.message.updateAccountSuccess').toString(), 'success');
      }
    } catch (e) {
      showSnackbar(t('user:error.edit.activateAccountFailed').toString(), 'error');
    } finally {
      setAppLoading(false);
      setIsAccActivationOpen(false);
      // INFO: location.reload() -- Ref: https://developer.mozilla.org/en-US/docs/Web/API/History/go
      // https://dev.to/raaynaldo/react-router-usehistory-uselocation-and-useparams-10cd
      history.go(0);
    }
  };

  const onAccountActivationCancel = () => {
    setIsAccActivationOpen(false);
  };

  const onAccountDeleteConfirm = async () => {
    try {
      setAppLoading(true);
      const result = await userService.deleteUser(currentUser.hashId);
      setAppLoading(false);
      setIsDeleteConfirmOpen(false);

      if (result) {
        showSnackbar(t('user:common.message.userDeleteSuccess').toString(), 'success');
        history.goBack();
      }
    } catch (e) {
      setAppLoading(false);
      setIsDeleteConfirmOpen(false);
      showSnackbar(t('user:error.edit.activateAccountFailed').toString(), 'error');
      // INFO: location.reload() -- Ref: https://developer.mozilla.org/en-US/docs/Web/API/History/go
      // https://dev.to/raaynaldo/react-router-usehistory-uselocation-and-useparams-10cd
      history.go(0);
    }
  };

  const onAccountDeleteCancel = () => {
    setIsDeleteConfirmOpen(false);
  };

  const openVerifyEmailConfirm = () => {
    setIsVerifyEmailConfirmOpen(true);
  };

  const onVerifyEmailCancel = () => {
    setIsVerifyEmailConfirmOpen(false);
  };

  const openAddYoutubeLinkDialog = () => {
    setIsAddYoutubeLinkDialogOpen(true);
  };

  const closeAddYoutubeLinkDialog = () => {
    setIsAddYoutubeLinkDialogOpen(false);
  };

  const validateYoutubeLink = (url: string, message = 'validation:youtubeLink.url'): string | true => {
    const uid = getYoutubeIdFromUrl(url);

    if (uid) {
      return true;
    }

    return message;
  };

  const onClickOkAddYoutubeLinkDialog = () => {
    if (dialogYoutubeFormGetValues('title') && dialogYoutubeFormGetValues('url')) {
      dialogYoutubeFormHandleSubmit(addYoutubeLinkFormSubmitHandler)();
    }

    if (dialogYoutubeFormGetValues('title') && validateYoutubeLink(dialogYoutubeFormGetValues('url')) === true) {
      setIsAddYoutubeLinkDialogOpen(false);
    }
  };

  const onDeleteYoutubeLinkButtonClick = (index: number) => () => {
    const socialMedias = currentFormData.socialMedias || [];

    socialMedias.splice(index, 1);

    setCurrentFormData({
      ...currentFormData,
      socialMedias,
    });
  };

  const onVerifyEmailConfirm = () => {
    setAppLoading(true);

    userService
      .verifyUserEmail(currentUser.hashId)
      .then((result) => {
        if (result) {
          showSnackbar(t('user:common.message.emailVerificationSuccess').toString(), 'success');
          setCurrentUser(new User(result));
          setIsVerifyEmailConfirmOpen(false);
        }
      })
      .catch(() => {
        showSnackbar(t('user:error.edit.emailVerificationFailed').toString(), 'error');
      })
      .finally(() => {
        setAppLoading(false);
      });
  };

  const VerifiedText = () => (
    <Typography component="span" className={clsx(classes.verifiedText, typoClasses.successText)}>
      {`(${t('common:message.verified')})`}
    </Typography>
  );

  const UnverifiedText = () => (
    <>
      <Typography component="span" className={clsx(classes.verifiedText, typoClasses.errorText)}>
        {`(${t('user:details.label.notVerified')})`}
      </Typography>
      {canUpdateEmailVerification && (
        <Box ml={1}>
          <AppButton variant="outlined" size="small" color="primary" onClick={() => openVerifyEmailConfirm()}>
            {t('user:common.buttons.verifyEmailNow')}
          </AppButton>
        </Box>
      )}
    </>
  );

  const onSubmitClickHandler = () => {
    handleSubmit(editFormSubmitHandler)();
  };

  const onThumbnailClicked = (imageUrl: string) => {
    if (imageUrl) {
      setSelectedPreviewImage(imageUrl);
    }
  };

  return (
    <>
      <Paper elevation={1}>
        <Box className={classes.container}>
          <Grid container>
            <Grid item xs={12} md>
              <Box py={5}>
                <Grid container>
                  <Grid item xs={12} classes={{ root: classes.profileImageCol }}>
                    <Box mb={3}>
                      <Avatar
                        className={classes.profileImage}
                        alt={currentUser.username}
                        src={currentUser.profileImageUrl}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} zeroMinWidth>
                    <Box pt={1} mb={3}>
                      <Typography variant="body1" component="h4" className={classes.displayName}>
                        {`${t('user:common.fields.displayName')}: ${currentUser.displayName}`}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Grid container spacing={2}>
                      <Grid item xs="auto" md={12}>
                        <Box display="flex" justifyContent="flex-end">
                          <AppButton
                            variant="outlined"
                            color="primary"
                            onClick={() => setIsUserNotificationDialogOpen(true)}>
                            {t('user:common.buttons.userNotification')}
                          </AppButton>
                        </Box>
                      </Grid>
                      <Grid item xs="auto" md={12}>
                        <Box display="flex" justifyContent="flex-end">
                          <AppButton
                            variant="outlined"
                            color="primary"
                            onClick={() => setIsUserPaymentHistoryDialogOpen(true)}>
                            {t('user:common.buttons.paymentHistory')}
                          </AppButton>
                        </Box>
                      </Grid>
                      {authUser?.role === UserRole.GM && (
                        <Grid item xs="auto" md={12}>
                          <Box display="flex" justifyContent="flex-end">
                            <Link to={`/users/${currentUser.hashId}/chat`} className={typoClasses.noDecorationText}>
                              <AppButton variant="outlined" color="primary">
                                {t('user:common.buttons.chat')}
                              </AppButton>
                            </Link>
                          </Box>
                        </Grid>
                      )}
                      {latestSellerApplication?.hashId && (
                        <Grid item xs="auto" md={12}>
                          <Box display="flex" justifyContent="flex-end">
                            <Link
                              to={`/seller-applications/${latestSellerApplication?.hashId}`}
                              target="_blank"
                              className={typoClasses.noDecorationText}>
                              <AppButton variant="outlined" color="primary">
                                {t('user:common.buttons.linkToSellerApplication')}
                              </AppButton>
                            </Link>
                          </Box>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container spacing={gridSpacing}>
                    <UserInfoBlock
                      label="user:common.fields.role"
                      data={t(`user:common.role.${currentUser.role}`).toString()}
                    />
                    <UserInfoBlock label="user:common.fields.username" data={currentUser.username} />
                    <UserInfoBlock
                      label="user:common.fields.hashId"
                      data={
                        <a href={getT2HSiteUrl(`/u/${currentUser.hashId}`)} target="_blank" rel="noreferrer">
                          {currentUser.hashId}
                          <OpenInNewIcon className={classes.extLinkIcon} />
                        </a>
                      }
                    />
                  </Grid>
                </Grid>
                <Box py={3} pr={3}>
                  <Divider />
                </Box>
                <Grid item xs={12} container spacing={gridSpacing}>
                  <UserInfoBlock label="user:common.fields.fullName" data={currentUser.fullName} onlySeller />
                  <UserInfoBlock label="user:common.fields.nationalId" data={currentUser.nationalId} onlySeller />
                  {latestSellerApp && latestSellerApp.type === 'corporation' && (
                    <>
                      <UserInfoBlock
                        label="seller-applications:details.label.componyName"
                        data={latestSellerApp.companyName}
                      />
                      <UserInfoBlock
                        label="seller-applications:details.label.companyTaxId"
                        data={latestSellerApp.companyTaxId}
                      />
                    </>
                  )}
                  <UserInfoBlock
                    label="user:common.fields.mobile"
                    data={currentUser.mobile || '-'}
                    endAdornment={!!currentUser.isMobileVerified && <VerifiedText />}
                  />
                  <UserInfoBlock
                    label="user:common.fields.email"
                    data={currentUser.email}
                    endAdornment={currentUser.isEmailVerified ? <VerifiedText /> : <UnverifiedText />}
                  />
                  <UserInfoBlock
                    label={`user:common.fields.${currentUser.role === UserRole.Buyer ? 'address' : 'shopAddress'}`}
                    data={currentUser.address}
                    onlySeller
                    noWrapText={false}
                  />
                  <UserInfoBlock
                    label="user:common.fields.shopLocation"
                    data={
                      <a
                        href={currentUser.shopLocationImageUrl}
                        rel="noreferrer"
                        target="_blank"
                        className={classes.dataTextLink}>
                        {currentUser.shopLocationImageUrl}
                        <OpenInNewIcon className={classes.extLinkIcon} />
                      </a>
                    }
                    onlySeller
                    noWrapText={false}
                  />
                  <UserInfoBlock
                    label="user:common.fields.facebookUrl"
                    data={
                      currentUser.facebookUri ? (
                        <a
                          href={getFacebookLink(currentUser.facebookUri)}
                          target="_blank"
                          rel="noreferrer"
                          className={classes.dataTextLink}>
                          {getFacebookLink(currentUser.facebookUri, true)}
                        </a>
                      ) : null
                    }
                    onlySeller
                  />
                  <UserInfoBlock
                    label="user:common.fields.lineUrl"
                    data={
                      currentUser.lineId ? (
                        <a href={getLineLink(currentUser.lineId)} target="_blank" rel="noreferrer">
                          {currentUser.lineId}
                        </a>
                      ) : null
                    }
                    onlySeller
                  />
                </Grid>
                <Box py={3} pr={3}>
                  <Divider />
                </Box>
                <Grid item xs={12} container spacing={gridSpacing}>
                  <UserInfoBlock
                    label="user:details.label.accountStatus"
                    data={
                      <Typography
                        className={clsx(typoClasses.textWeightBold, {
                          [typoClasses.successText]: currentUser.status === 'active',
                          [typoClasses.warningText]: currentUser.status === 'inactive',
                        })}>
                        {t(`user:common.accountStatus.${currentUser.status}`)}
                      </Typography>
                    }
                  />
                  <UserInfoBlock
                    label="user:common.fields.createdAt"
                    data={<AppDatetimeText value={dayjs(currentUser.createdAt)} />}
                  />
                  {/* TODO: Will implement later when API is ready */}
                  {/* <UserInfoBlock label="user:details.label.postCount" data={currentUser.shopLocationImageUrl} onlySeller />
                  <UserInfoBlock label="user:details.label.lastUsed" data={currentUser.shopLocationImageUrl} onlySeller />
                  <UserInfoBlock label="user:details.label.lastPosted" data={currentUser.shopLocationImageUrl} onlySeller /> */}

                  <Grid item xs={12}>
                    <Box pt={2} className={controlClasses.buttonsGroup}>
                      {canActivateAccount && currentUser.isDeactivated && (
                        <AppButton
                          variant="outlined"
                          color="primary"
                          startIcon={<BlockIcon />}
                          onClick={() => setIsAccActivationOpen(true)}>
                          {t('user:common.buttons.activateAccount')}
                        </AppButton>
                      )}

                      {canDeactivateAccount && !currentUser.isDeactivated && (
                        <AppButton
                          variant="outlined"
                          color="primary"
                          startIcon={<BlockIcon />}
                          onClick={() => setIsAccDeactivationOpen(true)}>
                          {t('user:common.buttons.deactivateAccount')}
                        </AppButton>
                      )}

                      {canDeleteAccount && (
                        <AppButton
                          variant="outlined"
                          color="error"
                          startIcon={<DeleteOutlineIcon />}
                          onClick={() => setIsDeleteConfirmOpen(true)}>
                          {t('user:common.buttons.deleteAccount')}
                        </AppButton>
                      )}
                    </Box>
                  </Grid>
                </Grid>
                {canUpdateUserSocialMedias && (
                  <>
                    <Box py={3} pr={3}>
                      <Divider />
                    </Box>
                    <Box py={3} pr={3}>
                      <AppButton
                        variant="contained"
                        color="primary"
                        endIcon={<AddIcon />}
                        disabled={!isAbleAddingYoutubeLink}
                        onClick={openAddYoutubeLinkDialog}>
                        เพิ่มวิดีโอร้านค้า
                      </AppButton>
                      <Menu id="lock-menu" open={false}>
                        <MenuItem>Test</MenuItem>
                      </Menu>
                    </Box>
                    <Box py={3} pr={3}>
                      <Grid container spacing={2}>
                        {((currentFormData && currentFormData.socialMedias) || []).map((socialMedia, index) => (
                          <Grid
                            // eslint-disable-next-line react/no-array-index-key
                            key={`${socialMedia.title}_${socialMedia.url}_${index}`}
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            xl={3}>
                            <YoutubeThumbnail
                              createdAt={socialMedia.createdDate}
                              youtubeUrl={socialMedia.url}
                              thumbnailLabel={socialMedia.title}
                              onDeleteButtonClick={isEditingForm ? onDeleteYoutubeLinkButtonClick(index) : undefined}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                    <Box py={3} pr={3}>
                      <Divider />
                    </Box>
                  </>
                )}
              </Box>
              {latestSellerApp && (
                <Box mt={2} pr={3} pb={3}>
                  <Grid container spacing={2}>
                    {!!latestSellerApp.dbdFile && (
                      <Grid item xs={12} sm={6} md={4} xl={3}>
                        <ImageThumbnail
                          listingHashId={latestSellerApp.hashId}
                          imageUrl={latestSellerApp.dbdFile.url}
                          altText="DBD document file"
                          thumbnailLabel={t('seller-applications:details.label.dbdFile')}
                          imageClick={onThumbnailClicked}
                        />
                      </Grid>
                    )}
                    {!!latestSellerApp.nationalIdCardFile && (
                      <Grid item xs={12} sm={6} md={4} xl={3}>
                        <ImageThumbnail
                          listingHashId={latestSellerApp.hashId}
                          imageUrl={latestSellerApp.nationalIdCardFile.url}
                          altText={latestSellerApp.nationalId ? 'national id' : 'passport'}
                          thumbnailLabel={t(
                            `seller-applications:details.label.${
                              latestSellerApp.nationalId ? 'nationalIdCard' : 'passport'
                            }`,
                          )}
                          imageClick={onThumbnailClicked}
                        />
                      </Grid>
                    )}
                    {!!latestSellerApp.selfieFile && (
                      <Grid item xs={12} sm={6} md={4} xl={3}>
                        <ImageThumbnail
                          listingHashId={latestSellerApp.hashId}
                          imageUrl={latestSellerApp.selfieFile.url}
                          altText="Selfie file"
                          thumbnailLabel={t('seller-applications:details.label.idSelfiePic')}
                          imageClick={onThumbnailClicked}
                        />
                      </Grid>
                    )}
                    {!!latestSellerApp.bankBookFile && (
                      <Grid item xs={12} sm={6} md={4} xl={3}>
                        <ImageThumbnail
                          listingHashId={latestSellerApp.hashId}
                          imageUrl={latestSellerApp.bankBookFile.url}
                          altText="Bank account file"
                          thumbnailLabel={t('seller-applications:details.label.bookBankPic')}
                          imageClick={onThumbnailClicked}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Box>
              )}
            </Grid>

            <Grid item xs={12} md={3} className={classes.rightColumn}>
              {!isEditingForm && canEditUserDetail && (
                <Box display="flex" justifyContent="flex-end" mb={3}>
                  <AppButton variant="outlined" color="primary" onClick={editForm}>
                    {t('common:button.edit')}
                  </AppButton>
                </Box>
              )}

              {currentUser.isSeller && (
                <>
                  <Grid container>
                    <Grid item xs={12}>
                      <Box display="flex" justifyContent="space-between">
                        <Typography className={typoClasses.textWeightBold}>
                          {t('user:details.label.totalCoins')}
                        </Typography>
                      </Box>

                      <Box mb={4}>
                        <Grid container spacing={2} alignItems="flex-start">
                          <Grid item xs={12}>
                            <Box
                              className={classes.coinAmountBox}
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              mt={1}>
                              <Typography
                                component="h6"
                                variant="h6"
                                align="center"
                                noWrap
                                className={typoClasses.textWeightBold}>
                                {currentDisplayBalance?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || '0'}
                              </Typography>
                            </Box>
                          </Grid>
                          <div className={classes.flexBreak} />
                          {isEditingForm && canUpdateUserBalance && (
                            <>
                              <Grid item xs={6}>
                                <Controller
                                  control={control}
                                  name="coinAmount"
                                  render={({ field }) => (
                                    <TextField
                                      name={field.name}
                                      value={field.value}
                                      variant="outlined"
                                      placeholder={t('user:details.label.coinAmount').toString()}
                                      label={t('user:details.label.coinAmount').toString()}
                                      disabled={!isEditingForm}
                                      onChange={(e) => field.onChange(e.target.value)}
                                      InputProps={{ inputComponent: NumberFormatCustom }}
                                      fullWidth
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <Grid container spacing={1}>
                                  <Grid item xs={6}>
                                    <AppButton
                                      color="primary"
                                      disabled={!isEditingForm}
                                      fullWidth
                                      onClick={() => adjustBalance('increase')}>
                                      <AddIcon />
                                    </AppButton>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <AppButton
                                      color="primary"
                                      disabled={!isEditingForm}
                                      fullWidth
                                      onClick={() => adjustBalance('decrease')}>
                                      <RemoveIcon />
                                    </AppButton>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </>
                          )}
                        </Grid>
                      </Box>

                      <Box mb={4}>
                        <Typography className={typoClasses.textWeightBold}>
                          {t('user:details.label.membershipLabel')}
                        </Typography>
                        <Box mt={isEditingForm ? 2 : 0}>
                          <Typography variant="subtitle2">
                            <Controller
                              name="membershipProductHashId"
                              control={control}
                              defaultValue={currentFormData.membershipProductHashId}
                              rules={{
                                validate: (value) => membershipProductValidator(value),
                              }}
                              render={({ field }) => (
                                <AppFormControl error={!!errors.membershipProductHashId}>
                                  <MasterDataDropdown
                                    dataKey="membershipProducts"
                                    itemValuePropName="hashId"
                                    itemLabelPropName="thaiName"
                                    inputLabel="user:details.label.membershipLabel"
                                    value={field.value}
                                    onChange={field.onChange}
                                    disabled={!isEditingForm || !canUpdateUserMembership}
                                    renderAsText={!isEditingForm || !canUpdateUserMembership}
                                    noDataText={t('user:details.label.noMembership').toString()}
                                    allowEmpty
                                    error={!!errors.membershipProductHashId}
                                  />
                                  {!!errors.membershipProductHashId && (
                                    <FormHelperText>{errors.membershipProductHashId.message}</FormHelperText>
                                  )}
                                </AppFormControl>
                              )}
                            />
                          </Typography>
                        </Box>
                        {!isEditingForm && currentUser.membershipProductHashId && (
                          <>
                            <Box mt={3}>
                              <Typography className={typoClasses.textWeightBold}>
                                {t('user:details.label.membershipStartDate')}
                              </Typography>
                              <AppDatetimeText value={currentFormData.membershipStartAt} />
                            </Box>
                            <Box mt={3}>
                              <Typography className={typoClasses.textWeightBold}>
                                {t('user:details.label.membershipEndDate')}
                              </Typography>
                              <AppDatetimeText value={currentFormData.membershipEndAt} />
                            </Box>
                          </>
                        )}
                        {isEditingForm && canUpdateUserMembership && (
                          <>
                            <Box display="flex" mt={2}>
                              <Box flexBasis="50%" pr={1}>
                                <Controller
                                  name="membershipStartAt"
                                  control={control}
                                  defaultValue={currentFormData.membershipStartAt}
                                  rules={{
                                    validate: () => membershipDateValidator(),
                                  }}
                                  render={({ field }) => (
                                    <AppDatePicker
                                      value={field.value?.format('YYYY-MM-DD')}
                                      onChange={field.onChange}
                                      disabled={!isEditingForm}
                                      placeholder={t('common:label.startDate')}
                                      error={!!errors.membershipEndAt}
                                    />
                                  )}
                                />
                              </Box>
                              <Box flexBasis="50%" pl={1}>
                                <Controller
                                  name="membershipEndAt"
                                  control={control}
                                  defaultValue={currentFormData.membershipEndAt}
                                  rules={{
                                    validate: () => membershipDateValidator(),
                                  }}
                                  render={({ field }) => (
                                    <AppDatePicker
                                      value={field.value?.format('YYYY-MM-DD')}
                                      onChange={field.onChange}
                                      disabled={!isEditingForm}
                                      placeholder={t('common:label.endDate')}
                                      error={!!errors.membershipEndAt}
                                    />
                                  )}
                                />
                              </Box>
                            </Box>
                            {(!!errors.membershipStartAt || !!errors.membershipEndAt) && (
                              <FormHelperText error>
                                {((errors.membershipStartAt || errors.membershipEndAt) as FieldError).message}
                              </FormHelperText>
                            )}
                          </>
                        )}
                      </Box>

                      {isEditingForm && canUpdateUserBalance && (
                        <>
                          <Box mb={4}>
                            <Typography className={typoClasses.textWeightBold}>
                              {t('user:details.label.paymentOption')}
                            </Typography>
                            <Controller
                              name="paymentOption"
                              control={control}
                              defaultValue={currentFormData.userRole}
                              render={({ field }) => (
                                <AppFormControl boxProps={{ mt: 1 }}>
                                  <Select
                                    {...field}
                                    labelId="role-label"
                                    id="paymentOption"
                                    variant="outlined"
                                    fullWidth
                                    disabled={!isEditingForm}>
                                    {paymentOptionList.map((item) => (
                                      <MenuItem value={item.value} key={item.value}>
                                        {t(item.label)}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </AppFormControl>
                              )}
                            />
                          </Box>
                          <Box mb={4}>
                            <Typography className={typoClasses.textWeightBold}>
                              {t('user:details.label.paymentRemark')}
                            </Typography>
                            <Controller
                              name="remark"
                              control={control}
                              defaultValue={currentFormData.userRole}
                              render={({ field }) => (
                                <AppFormControl boxProps={{ mt: 1 }}>
                                  <Select
                                    {...field}
                                    labelId="role-label"
                                    id="remark"
                                    variant="outlined"
                                    fullWidth
                                    disabled={!isEditingForm}>
                                    {remarkList.map((item) => (
                                      <MenuItem value={item.value} key={item.value}>
                                        {t(item.label)}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </AppFormControl>
                              )}
                            />
                          </Box>

                          {remarkEtc && (
                            <Box mb={4}>
                              <Grid container spacing={2} alignItems="flex-start">
                                <div className={classes.flexBreak} />
                                <Grid item xs={12}>
                                  <Controller
                                    control={control}
                                    name="remarkEtc"
                                    render={({ field }) => (
                                      <TextField
                                        name={field.name}
                                        value={field.value}
                                        variant="outlined"
                                        placeholder={t('user:details.label.remarkEtc').toString()}
                                        label={t('user:details.label.remarkEtc').toString()}
                                        disabled={!isEditingForm}
                                        onChange={(e) => field.onChange(e.target.value)}
                                        fullWidth
                                      />
                                    )}
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                          )}

                          <Box display="flex" justifyContent="space-between">
                            <Typography className={typoClasses.textWeightBold}>
                              {t('user:details.label.productPrice')}
                            </Typography>
                          </Box>
                          <Box mb={4}>
                            <Grid container spacing={2} alignItems="flex-start">
                              <div className={classes.flexBreak} />
                              <Grid item xs={12}>
                                <Controller
                                  control={control}
                                  name="productPrice"
                                  render={({ field }) => (
                                    <TextField
                                      name={field.name}
                                      value={field.value}
                                      variant="outlined"
                                      placeholder={t('user:details.label.productPrice').toString()}
                                      label={t('user:details.label.productPrice').toString()}
                                      disabled={!isEditingForm}
                                      onChange={(e) => field.onChange(e.target.value)}
                                      fullWidth
                                      InputProps={{ inputComponent: NumberFormatCustom }}
                                    />
                                  )}
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        </>
                      )}
                    </Grid>
                  </Grid>
                  <Box mb={2}>
                    <Divider />
                  </Box>
                </>
              )}

              {canUpdateUserRole && (
                <Box mb={4}>
                  <Typography className={typoClasses.textWeightBold}>{t('user:details.label.roleLabel')}</Typography>
                  <Controller
                    name="userRole"
                    control={control}
                    defaultValue={currentFormData.userRole}
                    render={({ field }) => (
                      <AppFormControl boxProps={{ mt: 1 }}>
                        <Select
                          {...field}
                          labelId="role-label"
                          id="userRole"
                          variant="outlined"
                          fullWidth
                          disabled={!isEditingForm}>
                          {userRoleList.map((item) => (
                            <MenuItem value={item.value} key={item.value}>
                              {t(item.label)}
                            </MenuItem>
                          ))}
                        </Select>
                      </AppFormControl>
                    )}
                  />
                </Box>
              )}

              <Box mb={4}>
                <Typography className={typoClasses.textWeightBold}>{t('user:details.label.interviewLog')}</Typography>
                <Controller
                  name="interviewLog"
                  control={control}
                  defaultValue={currentFormData.interviewLog}
                  render={({ field }) => (
                    <AppFormControl boxProps={{ mt: 1 }}>
                      <TextField
                        {...field}
                        id="interviewLog"
                        value={field.value || ''}
                        multiline
                        rows={6}
                        label={t('user:details.label.interviewLog')}
                        variant="outlined"
                        disabled={!isEditingForm}
                      />
                    </AppFormControl>
                  )}
                />
              </Box>

              {latestSellerApp?.status === 'approved' && (
                <Box mb={4}>
                  <Grid container spacing={1}>
                    <Box mb={1}>
                      <Typography component="h6" variant="body1" className={typoClasses.textWeightBold}>
                        {t('seller-applications:details.label.reverificationByAdmin')}
                      </Typography>
                    </Box>

                    <Grid item xs={12}>
                      <Controller
                        name="reVerifyIdentityFile1"
                        control={control}
                        rules={getRules('reVerifyIdentityFile1') || {}}
                        render={({ field }) => (
                          <AppFormControl error={!!errors.reVerifyIdentityFile1}>
                            <AppFileSelector
                              disabled={!isEditingForm}
                              defaultFile={field.value}
                              fileMode="image"
                              inputProps={{
                                label: t('seller-applications:details.label.reVerifyIdentityFile').toString(),
                                error: !!errors.reVerifyIdentityFile1,
                              }}
                              onImageClick={onThumbnailClicked}
                              onFilesSelected={(file: FileResponse) => {
                                if (file) {
                                  field.onChange(file);
                                }
                              }}
                              onValueCleared={() => field.onChange('')}
                            />
                            {!!errors.reVerifyIdentityFile1 && (
                              <AppFormErrorMessage errors={errors} name="reVerifyIdentityFile1" />
                            )}
                          </AppFormControl>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="reVerifyIdentityFile2"
                        control={control}
                        rules={getRules('reVerifyIdentityFile2') || {}}
                        render={({ field }) => (
                          <AppFormControl error={!!errors.reVerifyIdentityFile2}>
                            <AppFileSelector
                              disabled={!isEditingForm}
                              defaultFile={field.value}
                              fileMode="image"
                              inputProps={{
                                label: t('seller-applications:details.label.reVerifyIdentityFile').toString(),
                                error: !!errors.reVerifyIdentityFile2,
                              }}
                              onImageClick={onThumbnailClicked}
                              onFilesSelected={(file: FileResponse) => {
                                if (file) {
                                  field.onChange(file);
                                }
                              }}
                              onValueCleared={() => field.onChange('')}
                            />
                            {!!errors.reVerifyIdentityFile2 && (
                              <AppFormErrorMessage errors={errors} name="reVerifyIdentityFile2" />
                            )}
                          </AppFormControl>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="reVerifyIdentityFile3"
                        control={control}
                        rules={getRules('reVerifyIdentityFile3') || {}}
                        render={({ field }) => (
                          <AppFormControl error={!!errors.reVerifyIdentityFile3}>
                            <AppFileSelector
                              disabled={!isEditingForm}
                              defaultFile={field.value}
                              fileMode="image"
                              inputProps={{
                                label: t('seller-applications:details.label.reVerifyIdentityFile').toString(),
                                error: !!errors.reVerifyIdentityFile3,
                              }}
                              onImageClick={onThumbnailClicked}
                              onFilesSelected={(file: FileResponse) => {
                                if (file) {
                                  field.onChange(file);
                                }
                              }}
                              onValueCleared={() => field.onChange('')}
                            />
                            {!!errors.reVerifyIdentityFile3 && (
                              <AppFormErrorMessage errors={errors} name="reVerifyIdentityFile3" />
                            )}
                          </AppFormControl>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="reVerifyIdentityFile4"
                        control={control}
                        rules={getRules('reVerifyIdentityFile4') || {}}
                        render={({ field }) => (
                          <AppFormControl error={!!errors.reVerifyIdentityFile4}>
                            <AppFileSelector
                              disabled={!isEditingForm}
                              defaultFile={field.value}
                              fileMode="image"
                              inputProps={{
                                label: t('seller-applications:details.label.reVerifyIdentityFile').toString(),
                                error: !!errors.reVerifyIdentityFile4,
                              }}
                              onImageClick={onThumbnailClicked}
                              onFilesSelected={(file: FileResponse) => {
                                if (file) {
                                  field.onChange(file);
                                }
                              }}
                              onValueCleared={() => field.onChange('')}
                            />
                            {!!errors.reVerifyIdentityFile4 && (
                              <AppFormErrorMessage errors={errors} name="reVerifyIdentityFile4" />
                            )}
                          </AppFormControl>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="reVerifyIdentityFile5"
                        control={control}
                        rules={getRules('reVerifyIdentityFile5') || {}}
                        render={({ field }) => (
                          <AppFormControl error={!!errors.reVerifyIdentityFile5}>
                            <AppFileSelector
                              disabled={!isEditingForm}
                              defaultFile={field.value}
                              fileMode="image"
                              inputProps={{
                                label: t('seller-applications:details.label.reVerifyIdentityFile').toString(),
                                error: !!errors.reVerifyIdentityFile5,
                              }}
                              onImageClick={onThumbnailClicked}
                              onFilesSelected={(file: FileResponse) => {
                                if (file) {
                                  field.onChange(file);
                                }
                              }}
                              onValueCleared={() => field.onChange('')}
                            />
                            {!!errors.reVerifyIdentityFile5 && (
                              <AppFormErrorMessage errors={errors} name="reVerifyIdentityFile5" />
                            )}
                          </AppFormControl>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}

              {isEditingForm && (
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="flex-end" mb={3} mt={3} className={controlClasses.buttonsGroup}>
                    <AppButton color="default" variant="outlined" onClick={cancelEditForm} disabled={isSavingData}>
                      {t('common:button.cancel')}
                    </AppButton>
                    <AppButton
                      type="submit"
                      color="primary"
                      disabled={isSavingData}
                      onClick={() => onSubmitClickHandler()}>
                      {t('common:button.save')}
                    </AppButton>
                  </Box>
                </Grid>
              )}

              <Grid item xs={12}>
                <Box pb={3}>
                  <Divider />
                </Box>
                {emailSubscriptionsList.length > 0 && (
                  <>
                    <Typography className={typoClasses.textWeightBold}>
                      {t('user:details.label.newsletterSubscription')}
                    </Typography>

                    <Box position="relative" mt={1} mb={4}>
                      {emailSubscriptionsList.map((item) => (
                        <Box key={item.hashId}>
                          <FormControlLabel
                            control={
                              <AppCheckbox
                                name={item.englishName}
                                checked={isEmailSubscribed(item.hashId)}
                                onChange={(e) => updateEmailSubscription(e, item)}
                                color="default"
                                disabled={!canUpdateEmailSubscription}
                              />
                            }
                            label={item.thaiName}
                          />
                        </Box>
                      ))}
                      {isUpdatingSubscription && (
                        <Box className={classes.progressionOverlay}>
                          <AppCircularProgress />
                        </Box>
                      )}
                    </Box>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>

      {isAccDeactivationOpen && (
        <UserAccountSuspendDialog onOkClicked={onAccountDeactivationConfirm} onClose={onAccountDeactivationCancel} />
      )}

      <ImagePreview imageUrl={selectedPreviewImage} onCloseClick={() => setSelectedPreviewImage(undefined)} asModal />

      <AppDialog
        open={isAccActivationOpen}
        title={t('user:details.label.accActivationDialogTitle')}
        okButtonText={t('common:button.save')}
        cancelButtonText={t('common:button.cancel')}
        onClose={onAccountActivationCancel}
        onOkClick={onAccountActivationConfirm}>
        {t('user:details.label.accActivationDialogDesc')}
      </AppDialog>

      <AppDialog
        open={isDeleteConfirmOpen}
        title={t('user:details.label.accDeleteDialogTitle')}
        okButtonText={t('user:common.buttons.deleteAccount')}
        okButtonColor="error"
        cancelButtonText={t('common:button.cancel')}
        onClose={onAccountDeleteCancel}
        onOkClick={onAccountDeleteConfirm}>
        <Box width="100%" maxWidth="320px">
          <Typography>{t('user:details.label.accDeleteDialogDesc1')}</Typography>
          <Box my={1} className={clsx(typoClasses.errorText, typoClasses.textWeightBold)}>
            {currentUser.username}
          </Box>
          <Typography>{t('user:details.label.accDeleteDialogDesc2')}</Typography>
        </Box>
      </AppDialog>

      <AppDialog
        open={isVerifyEmailConfirmOpen}
        title={t('common:dialog.title.confirm')}
        okButtonText={t('common:button.confirm')}
        okButtonColor="primary"
        cancelButtonText={t('common:button.cancel')}
        onClose={onVerifyEmailCancel}
        onOkClick={onVerifyEmailConfirm}>
        <Box width="100%" maxWidth="320px">
          <Typography>{t('user:details.label.verifyEmailDescription')}</Typography>
        </Box>
      </AppDialog>

      <AppDialog
        open={isAddYoutubeLinkDialogOpen}
        title="เพิ่มวิดีโอร้านค้า"
        okButtonText={t('common:button.confirm')}
        okButtonColor="primary"
        cancelButtonText={t('common:button.cancel')}
        onClose={closeAddYoutubeLinkDialog}
        onOkClick={onClickOkAddYoutubeLinkDialog}
        dialogProps={{
          fullWidth: true,
          maxWidth: 'sm',
        }}>
        <Box width="100%" maxWidth="600px">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                control={dialogYoutubeFormControl}
                name="title"
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <AppFormControl error={!!dialogYoutubeFormErrors.title}>
                    <TextField
                      name={field.name}
                      value={field.value}
                      variant="outlined"
                      placeholder="Title Video"
                      label="Title Video"
                      onChange={(e) => field.onChange(e.target.value)}
                      fullWidth
                    />
                    {!!dialogYoutubeFormErrors.title && (
                      <FormHelperText error>
                        {t('validation:requiredFieldAlt', {
                          fieldName: 'Title Video',
                        })}
                      </FormHelperText>
                    )}
                  </AppFormControl>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={dialogYoutubeFormControl}
                name="url"
                rules={{
                  required: true,
                  validate: validateYoutubeLink,
                }}
                render={({ field }) => (
                  <AppFormControl error={!!dialogYoutubeFormErrors.url}>
                    <TextField
                      name={field.name}
                      value={field.value}
                      variant="outlined"
                      placeholder="Link Youtube"
                      label="Link Youtube"
                      onChange={(e) => field.onChange(e.target.value)}
                      fullWidth
                    />
                    {!!dialogYoutubeFormErrors.url && (
                      <FormHelperText error>{t('validation:youtubeLink.url')}</FormHelperText>
                    )}
                  </AppFormControl>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={dialogYoutubeFormControl}
                name="createdDate"
                render={({ field }) => (
                  <Box width="100%">
                    <AppDatePicker
                      value={field.value ? formatDate(new Date(field.value), 'YYYY-MM-dd') : null}
                      onChange={(data) => field.onChange(data?.format('YYYY-MM-DD'))}
                      placeholder={t('common:message.createdAt')}
                      error={!!dialogYoutubeFormErrors.createdDate}
                    />
                  </Box>
                )}
              />
            </Grid>
          </Grid>
        </Box>
      </AppDialog>
      <UserPaymentHistoryDialog
        title={t('user:details.label.paymentHistory')}
        open={isUserPaymentHistoryDialogOpen}
        userHashId={currentUser.hashId}
        isLoadingData={isSavingData}
        onClose={() => setIsUserPaymentHistoryDialogOpen(false)}
      />
      <UserNotificationDialog
        title={t('user:details.label.userNotification')}
        open={isUserNotificationDialogOpen}
        userHashId={currentUser.hashId}
        isLoadingData={isSavingData}
        onClose={() => setIsUserNotificationDialogOpen(false)}
      />
    </>
  );
};

export default UserDetail;
