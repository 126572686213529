/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useCallback, useEffect, useState } from 'react';

import { Box, Grid, MenuItem, Select, Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import PROVINCE_LIST from '../../../../data/province';
import { removeDuplicatesByKey } from '../../../../helpers/remove-duplicates-by-key';
import { getProvinceName } from '../../../../helpers/utils';
import { SelectItem } from '../../../../models';
import getThaiAddressData from '../../../../services/v3/thai-address';

export type SelectAddressProps = {
  currentValue: {
    province?: string;
    district?: string;
    zipcode?: string;
  };
  errorMessage: {
    province?: string;
    district?: string;
    zipcode?: string;
  };
  onChange: {
    province: (value: string) => void;
    district: (value: string) => void;
    zipcode: (value: string) => void;
  };
  isEdit?: boolean;
};

type DropdownListType = {
  district: any[];
  zipcode: any[];
};

type DropdownModeType = 'province' | 'district' | 'zipcode';

const useStyles = makeStyles((theme) => ({
  labelTitle: {
    color: theme.palette.grey[500],
    position: 'absolute',
    top: '40px',
    padding: '0 14px',
    marginTop: ' -24px !important',
  },
  labelValue: {
    position: 'absolute',
    fontSize: '12px',
    top: '-8px',
    padding: '0 4px',
    display: 'inline',
    backgroundColor: '#fff',
    zIndex: 2,
    left: '12px',
  },
  inputError: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.error.main,
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: '12px',
    padding: '4px 14px 0px 14px',
  },
}));

const SelectAddress: FC<SelectAddressProps> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { currentValue, errorMessage, onChange, isEdit = false } = props;

  const [thaiAddressList, setThaiAddressList] = useState<any[]>([]);

  const districtDropdownList: DropdownListType['district'] = removeDuplicatesByKey(
    thaiAddressList.filter((itemAddress) =>
      itemAddress.province.includes(getProvinceName(currentValue.province || '')),
    ),
    'district',
  );
  const zipcodeDropdownList: DropdownListType['zipcode'] = removeDuplicatesByKey(
    thaiAddressList.filter(
      (itemAddress) =>
        itemAddress.province.includes(getProvinceName(currentValue.province || '')) &&
        itemAddress.district.includes(currentValue.district),
    ),
    'zipcode',
  );

  const provinceSelectList: SelectItem<string>[] = PROVINCE_LIST.map((item) => ({
    value: item.slug,
    label: item.thaiName,
  }));

  const onClickItem = useCallback(
    (mode: DropdownModeType, value: any) => {
      if (mode === 'province') {
        onChange.province(value);
      }

      if (mode === 'district') {
        onChange.district(value);
      }

      if (mode === 'zipcode') {
        onChange.zipcode(value);
      }
    },
    [onChange],
  );

  useEffect(() => {
    const thaiAddressData = getThaiAddressData();
    if (thaiAddressData) {
      setThaiAddressList(thaiAddressData);
    }
  }, []);

  useEffect(() => {
    if (
      zipcodeDropdownList.length === 1 &&
      (currentValue.zipcode === undefined || currentValue.zipcode === '') &&
      currentValue.zipcode !== zipcodeDropdownList[0].zipcode
    ) {
      onChange.zipcode(zipcodeDropdownList[0].zipcode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zipcodeDropdownList]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <Box style={{ position: 'relative' }}>
          <Typography
            className={
              getProvinceName(currentValue.province || '') || currentValue.province
                ? classes.labelValue
                : classes.labelTitle
            }>
            {t('user:common.fields.province')}
          </Typography>
          <Select
            id="user-app-province"
            value={currentValue.province}
            variant="outlined"
            renderValue={() => getProvinceName(currentValue.province || '') || currentValue.province}
            fullWidth
            disabled={isEdit}
            className={errorMessage.province && classes.inputError}>
            {provinceSelectList.map((item) => (
              <MenuItem
                value={item.value}
                key={item.value}
                onClick={() => {
                  onClickItem('province', item.value);
                }}>
                {t(item.label)}
              </MenuItem>
            ))}
          </Select>
          {errorMessage.province && <Typography className={classes.errorMessage}>{errorMessage.province}</Typography>}
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box style={{ position: 'relative' }}>
          <Typography
            className={
              currentValue.district || districtDropdownList.length === 1 ? classes.labelValue : classes.labelTitle
            }>
            {t('user:common.fields.district')}
          </Typography>
          <Select
            id="user-app-district"
            value={districtDropdownList.length === 1 ? districtDropdownList[0].district : currentValue?.district}
            variant="outlined"
            fullWidth
            disabled={isEdit}
            className={errorMessage.district && classes.inputError}
            renderValue={() =>
              districtDropdownList.length === 1 ? districtDropdownList[0].district : currentValue.district
            }>
            {districtDropdownList.map((item) => (
              <MenuItem
                value={item.district}
                key={item.district}
                onClick={() => {
                  onClickItem('district', item.district);
                }}>
                {t(item.district)}
              </MenuItem>
            ))}
          </Select>
          {errorMessage.district && <Typography className={classes.errorMessage}>{errorMessage.district}</Typography>}
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box style={{ position: 'relative' }}>
          <Typography
            className={
              currentValue.zipcode || zipcodeDropdownList.length === 1 ? classes.labelValue : classes.labelTitle
            }>
            {t('user:common.fields.zipcode')}
          </Typography>
          <Select
            id="user-app-zipcode"
            value={zipcodeDropdownList.length === 1 ? zipcodeDropdownList[0].zipcode : currentValue.zipcode}
            variant="outlined"
            fullWidth
            disabled={isEdit}
            renderValue={() =>
              zipcodeDropdownList.length === 1 ? zipcodeDropdownList[0].zipcode : currentValue.zipcode
            }
            className={errorMessage.zipcode && classes.inputError}>
            {zipcodeDropdownList.map((item) => (
              <MenuItem
                value={item.zipcode}
                key={item.zipcode}
                onClick={() => {
                  onClickItem('zipcode', item.zipcode);
                }}>
                {t(item.zipcode)}
              </MenuItem>
            ))}
          </Select>
          {errorMessage.zipcode && <Typography className={classes.errorMessage}>{errorMessage.zipcode}</Typography>}
        </Box>
      </Grid>
    </Grid>
  );
};

export default SelectAddress;
