import React, { FC, useCallback, useContext, useEffect, useState } from 'react';

import {
  Box,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Controller, UseControllerProps, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AuthContext } from '../../../../../../../context/AuthContext';
import { formatDate } from '../../../../../../../helpers/date';
import {
  ShopType,
  UserDataTC,
  UserEditApplicationAdditionalInfoRequest,
  UserVideo,
} from '../../../../../../../models/user.model';
import useControlStyles from '../../../../../../../theme/controls.style';
import AppButton from '../../../../../../ui/AppButton';
import AppCheckbox from '../../../../../../ui/AppCheckbox';
import AppDialog from '../../../../../../ui/AppDialog';
import AppFormControl from '../../../../../../ui/AppFormControl';
import YoutubeThumbnail, { getYoutubeIdFromUrl } from '../../../../../common/YoutubeThumbnail';

type AdditionalInfoFormModalProps = {
  open: boolean;
  currentUser: UserDataTC;
  onSubmit: (data?: UserEditApplicationAdditionalInfoRequest) => void;
  onClose: () => void;
  rules?: { [x: string]: UseControllerProps['rules'] };
};

const sellerTypeList = [
  {
    label: 'Broker Individual',
    value: ShopType.BrokerIndividual,
  },
  {
    label: 'Tent Company',
    value: ShopType.TentCompany,
  },
  {
    label: 'Tent Individual',
    value: ShopType.TentIndividual,
  },
  {
    label: 'User Individual',
    value: ShopType.UserIndividual,
  },
  {
    label: 'Isuzu OEM DLR',
    value: ShopType.isuzuOemDlr,
  },
  {
    label: 'Other',
    value: ShopType.other,
  },
];

const sellerProductTypeList = [
  {
    label: 'TRUCK',
    value: 'TRUCK',
  },
  {
    label: 'P-CAR/LCV',
    value: 'P-CAR/LCV',
  },
  {
    label: 'HVMC',
    value: 'HVMC',
  },
  {
    label: 'Agriculture',
    value: 'Agriculture',
  },
  {
    label: 'Motorbike',
    value: 'Motorbike',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

const AdditionalInfoFormModal: FC<AdditionalInfoFormModalProps> = (props: AdditionalInfoFormModalProps) => {
  const { currentUser, onSubmit, open, onClose } = props;
  const { t } = useTranslation();
  const controlClasses = useControlStyles();
  const { authUser } = useContext(AuthContext);
  const [isAddYoutubeLinkDialogOpen, setIsAddYoutubeLinkDialogOpen] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
    reset,
  } = useForm<UserEditApplicationAdditionalInfoRequest>({
    mode: 'onChange',
    defaultValues: {
      userHashId: currentUser.userHashId,
      sellerProductType: currentUser.sellerProductType || [],
      sellerType: currentUser.sellerType || 'BROKER_INDIVIDUAL',
      sellerYoutubeVdos: currentUser.sellerYoutubeVdos || [],
      sellerRatingScore: currentUser.sellerRatingScore || 0,
      badges: currentUser.badges || [],
    },
  });

  const youtubeList = watch('sellerYoutubeVdos');

  const {
    control: dialogYoutubeFormControl,
    handleSubmit: dialogYoutubeFormHandleSubmit,
    getValues: dialogYoutubeFormGetValues,
    reset: dialogYoutubeFormResetValue,
    formState: { errors: dialogYoutubeFormErrors },
  } = useForm<UserVideo>({
    mode: 'onSubmit',
    defaultValues: {
      title: '',
      url: '',
    },
  });

  const handleSaveData = useCallback((data: UserEditApplicationAdditionalInfoRequest) => {
    if (data?.userHashId) {
      onSubmit(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateYoutubeLink = (url: string, message = 'validation:youtubeLink.url'): string | true => {
    const uid = getYoutubeIdFromUrl(url);
    if (uid) {
      return true;
    }
    return message;
  };

  const onClickSubmitAddYoutubeLinkDialog = (data: UserVideo) => {
    const currentSellerYoutubeVdos = getValues('sellerYoutubeVdos') || [];
    setValue('sellerYoutubeVdos', [...currentSellerYoutubeVdos, data]);

    if (dialogYoutubeFormGetValues('title') && validateYoutubeLink(dialogYoutubeFormGetValues('url')) === true) {
      setIsAddYoutubeLinkDialogOpen(false);
      dialogYoutubeFormResetValue();
    }
  };

  const onDeleteYoutubeLinkButtonClick = (index: number) => {
    const currentSellerYoutubeVdos = getValues('sellerYoutubeVdos') || [];
    currentSellerYoutubeVdos.splice(index, 1);

    setValue('sellerYoutubeVdos', [...currentSellerYoutubeVdos]);
  };

  useEffect(() => {
    reset({
      userHashId: currentUser.userHashId,
      sellerProductType: currentUser.sellerProductType || [],
      sellerType: currentUser.sellerType || 'BROKER_INDIVIDUAL',
      sellerYoutubeVdos: currentUser.sellerYoutubeVdos || [],
      sellerRatingScore: currentUser.sellerRatingScore || 0,
      badges: currentUser.badges || [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  return (
    <>
      <AppDialog
        open={open}
        title={t('user:v3.label.additionalInfoEdit')}
        noActionBar
        dialogProps={{
          fullWidth: true,
          maxWidth: 'md',
          TransitionProps: {
            onExited: () => {
              reset();
            },
          },
        }}>
        <form onSubmit={handleSubmit(handleSaveData)}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Box mb={3}>
                <Typography>{t('user:common.fields.shopProductType')}</Typography>
                <Controller
                  name="sellerProductType"
                  control={control}
                  render={({ field }) => (
                    <AppFormControl error={!!errors.sellerProductType} style={{ display: 'inline' }}>
                      {sellerProductTypeList.map((item) => (
                        <FormControlLabel
                          key={item.value}
                          control={
                            <AppCheckbox
                              color="default"
                              onClick={() => {
                                if (field.value) {
                                  let newValue = [];
                                  if (!field.value.includes(item.value)) {
                                    newValue = [...field?.value, item.value];
                                  } else {
                                    newValue = [...field?.value].filter((val) => val !== item.value);
                                  }
                                  field.onChange(newValue);
                                }
                              }}
                              checked={(field.value || ([] as string[])).includes(item.value)}
                            />
                          }
                          label={item.label}
                        />
                      ))}
                    </AppFormControl>
                  )}
                />
              </Box>
              <Box mb={3}>
                <Controller
                  name="sellerType"
                  control={control}
                  defaultValue={currentUser.shopType}
                  render={({ field }) => (
                    <AppFormControl>
                      <InputLabel htmlFor="user-app-shop-type">{t('user:common.fields.shopType')}</InputLabel>
                      <Select id="user-app-shop-type" {...field} labelId="role-label" variant="outlined" fullWidth>
                        {sellerTypeList.map((item) => (
                          <MenuItem value={item.value} key={item.value}>
                            {t(item.label)}
                          </MenuItem>
                        ))}
                      </Select>
                    </AppFormControl>
                  )}
                />
              </Box>
              <Box mb={3}>
                <Controller
                  name="sellerRatingScore"
                  control={control}
                  rules={{
                    required: false,
                    maxLength: {
                      value: 100,
                      message: t('error:validation.invalidFormat', {
                        fieldName: t('user:common.fields.ratingScore'),
                      }),
                    },
                  }}
                  render={({ field }) => (
                    <AppFormControl error={!!errors.sellerRatingScore}>
                      <InputLabel htmlFor="user-app-rating-score">{t('user:common.fields.ratingScore')}</InputLabel>
                      <OutlinedInput
                        {...field}
                        id="user-app-shop-rating-score"
                        fullWidth
                        error={!!errors.sellerRatingScore}
                      />
                    </AppFormControl>
                  )}
                />
              </Box>
              <Box mb={3}>
                <Controller
                  control={control}
                  name="badges"
                  render={({ field }) => {
                    const fields = field.value || [];

                    return (
                      <>
                        {fields.length > 0 && (
                          <Box>
                            {fields.map((item, index: number) => (
                              <Box key={item.name} mb={3}>
                                <Box style={{ display: 'flex', justifyContent: 'space-between' }} mb={2}>
                                  <Typography>{`${t('user:common.fields.badges')} ${index + 1}`}</Typography>
                                  <DeleteForeverIcon
                                    color="error"
                                    onClick={() => {
                                      field.onChange(fields.filter((i, idx) => idx !== index));
                                    }}
                                    type="button"
                                  />
                                </Box>

                                <Grid container spacing={2}>
                                  <Grid item xs={12} md={12}>
                                    <Controller
                                      name={`badges.${index}.name`}
                                      control={control}
                                      rules={{
                                        required: true,
                                      }}
                                      render={(data) => (
                                        <AppFormControl>
                                          <InputLabel>{t('user:common.fields.badgeName')}</InputLabel>
                                          <OutlinedInput {...data.field} fullWidth />
                                        </AppFormControl>
                                      )}
                                    />
                                  </Grid>

                                  <Grid item xs={12} sm={6} md={6}>
                                    <Controller
                                      name={`badges.${index}.crName`}
                                      control={control}
                                      rules={{
                                        required: true,
                                      }}
                                      render={() => (
                                        <AppFormControl>
                                          <OutlinedInput defaultValue={authUser?.displayName} fullWidth disabled />
                                        </AppFormControl>
                                      )}
                                    />
                                  </Grid>

                                  <Grid item xs={12} sm={6} md={6}>
                                    <Controller
                                      name={`badges.${index}.createdAt`}
                                      control={control}
                                      rules={{
                                        required: true,
                                      }}
                                      render={(data) => (
                                        <AppFormControl>
                                          <InputLabel>{t('user:common.fields.createdAtBadge')}</InputLabel>
                                          <OutlinedInput
                                            type="date"
                                            defaultValue={
                                              data.field.value
                                                ? formatDate(new Date(data.field.value), 'YYYY-MM-dd')
                                                : null
                                            }
                                            fullWidth
                                            onChange={data.field.onChange}
                                          />
                                        </AppFormControl>
                                      )}
                                    />
                                  </Grid>
                                </Grid>
                              </Box>
                            ))}
                          </Box>
                        )}
                        <Box mt={2}>
                          <AppButton
                            variant="outlined"
                            color="primary"
                            startIcon={<AddIcon />}
                            onClick={() =>
                              field.onChange([
                                ...fields,
                                {
                                  name: '' as string,
                                  crName: authUser?.displayName as string,
                                  createdAt: 0,
                                },
                              ])
                            }>
                            {t(`user:common.fields.badges`)}
                          </AppButton>
                        </Box>
                      </>
                    );
                  }}
                />
              </Box>
              <Box my={2}>
                <Divider />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box mb={3}>
                <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
                  <Typography style={{ fontWeight: 'bold' }}>{t('user:common.fields.shopYoutubeVdos')}</Typography>
                  <AppButton
                    variant="contained"
                    color="primary"
                    endIcon={<AddIcon />}
                    onClick={() => setIsAddYoutubeLinkDialogOpen(true)}>
                    {t('user:common.fields.addSellerYoutubeVdo')}
                  </AppButton>
                </Box>
              </Box>
              <Box mb={3}>
                <Grid container spacing={2}>
                  {(youtubeList || []).map((socialMedia, index: number) => {
                    const rowKey = `social-media-${index}`;
                    return (
                      <Grid key={rowKey} item xs={12} sm={6} md={6}>
                        <YoutubeThumbnail
                          createdAt={socialMedia.createdAt}
                          youtubeUrl={socialMedia.url}
                          thumbnailLabel={socialMedia.title}
                          onDeleteButtonClick={() => onDeleteYoutubeLinkButtonClick(index)}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="flex-end" className={controlClasses.buttonsGroup} mb="16px">
            <AppButton color="default" variant="outlined" onClick={() => onClose()}>
              {t('common:button.cancel')}
            </AppButton>
            <AppButton type="submit" color="primary">
              {t('common:button.save')}
            </AppButton>
          </Box>
        </form>
      </AppDialog>
      <AppDialog
        open={isAddYoutubeLinkDialogOpen}
        title="user:common.fields.addSellerYoutubeVdo"
        noActionBar
        dialogProps={{
          fullWidth: true,
          maxWidth: 'sm',
        }}>
        <form onSubmit={dialogYoutubeFormHandleSubmit(onClickSubmitAddYoutubeLinkDialog)}>
          <Box width="100%" maxWidth="600px">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  control={dialogYoutubeFormControl}
                  name="title"
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <AppFormControl error={!!dialogYoutubeFormErrors.title}>
                      <TextField
                        name={field.name}
                        value={field.value}
                        variant="outlined"
                        placeholder="Title Video"
                        label="Title Video"
                        onChange={(e) => field.onChange(e.target.value)}
                        fullWidth
                      />
                      {!!dialogYoutubeFormErrors.title && (
                        <FormHelperText error>
                          {t('validation:requiredFieldAlt', {
                            fieldName: 'Title Video',
                          })}
                        </FormHelperText>
                      )}
                    </AppFormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={dialogYoutubeFormControl}
                  name="url"
                  rules={{
                    required: true,
                    validate: validateYoutubeLink,
                  }}
                  render={({ field }) => (
                    <AppFormControl error={!!dialogYoutubeFormErrors.url}>
                      <TextField
                        name={field.name}
                        value={field.value}
                        variant="outlined"
                        placeholder="Link Youtube"
                        label="Link Youtube"
                        onChange={(e) => field.onChange(e.target.value)}
                        fullWidth
                      />
                      {!!dialogYoutubeFormErrors.url && (
                        <FormHelperText error>{t('validation:youtubeLink.url')}</FormHelperText>
                      )}
                    </AppFormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={dialogYoutubeFormControl}
                  name="createdAt"
                  render={({ field }) => (
                    <AppFormControl boxProps={{ mb: 2 }} error={!!dialogYoutubeFormErrors.createdAt}>
                      <Box>
                        <OutlinedInput
                          fullWidth
                          type="date"
                          defaultValue={field.value ? formatDate(new Date(field.value), 'YYYY-MM-dd') : null}
                          onChange={field.onChange}
                          error={!!dialogYoutubeFormErrors.createdAt}
                          placeholder={t('common:message.createdAt')}
                        />
                      </Box>
                    </AppFormControl>
                  )}
                />
              </Grid>
            </Grid>
          </Box>
          <Box mt={2} display="flex" justifyContent="flex-end" className={controlClasses.buttonsGroup}>
            <AppButton color="default" variant="outlined" onClick={() => setIsAddYoutubeLinkDialogOpen(false)}>
              {t('common:button.cancel')}
            </AppButton>
            <AppButton type="submit" color="primary">
              {t('common:button.save')}
            </AppButton>
          </Box>
        </form>
      </AppDialog>
    </>
  );
};

export default AdditionalInfoFormModal;
