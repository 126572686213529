import React, { FC } from 'react';

import { Box, makeStyles, Typography } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';

export type SnsSharedItemThumbnailProps = {
  data: {
    title?: string;
    description?: string;
    url?: string;
    image?: string;
  };
};

const useStyles = makeStyles((theme) => ({
  thumbnailContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    borderRadius: 6,
    overflow: 'hidden',
    textDecoration: 'none',
    height: '300px',
    width: '100%',
    border: `solid 1px ${theme.palette.grey[400]}`,
    [theme.breakpoints.up('md')]: {
      width: '250px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '320px',
    },
  },
  thumbnailTitleText: {
    fontWeight: 700,
    fontSize: '14px',
    color: theme.palette.common.black,
  },
  thumbnailDescriptionText: {
    fontSize: '14px',
    color: theme.palette.grey[700],
  },
  previewImageContainer: {
    borderRadius: 6,
    overflow: 'hidden',
  },
  previewImage: {
    maxWidth: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  icon: {
    color: theme.palette.grey[700],
  },
}));

const SnsSharedItemThumbnail: FC<SnsSharedItemThumbnailProps> = (props) => {
  const { data } = props;
  const classes = useStyles();

  return (
    <a href={data.url} target="_blank" rel="noreferrer" className={classes.thumbnailContainer}>
      <img src={data.image} alt="Shared item" className={classes.previewImage} />

      <Box p={1}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <LinkIcon fontSize="small" className={classes.icon} />
          <Box flexGrow={1} p={1} maxWidth="calc(100% - 20px)">
            <Typography component="div" className={classes.thumbnailTitleText} noWrap>
              {data.title}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Typography component="div" className={classes.thumbnailDescriptionText} noWrap>
            {data.description}
          </Typography>
        </Box>
      </Box>
    </a>
  );
};

export default SnsSharedItemThumbnail;
