import React, { FC } from 'react';

import { Box } from '@material-ui/core';

const Loading: FC = () => (
  <Box position="fixed" width="100vw" height="100vh" display="flex" justifyContent="center" alignItems="center">
    Loading...
  </Box>
);

export default Loading;
