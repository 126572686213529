import React, { FC } from 'react';

import { TableCell, TableCellProps } from '@material-ui/core';
import clsx from 'clsx';

import useAppTableStyles from '../../../theme/table.style';

const AppTableCell: FC<TableCellProps> = (props) => {
  const tableClasses = useAppTableStyles();
  const { children, className, ...rest } = props;
  return (
    <TableCell className={clsx(tableClasses.bodyCell, className)} {...rest}>
      {children}
    </TableCell>
  );
};

export default AppTableCell;
