import React, { FC, useState } from 'react';

import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  makeStyles,
  OutlinedInput,
  OutlinedInputProps,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import FolderIcon from '@material-ui/icons/Folder';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import { useTranslation } from 'react-i18next';

import { getT2HAssetUrl } from '../../../helpers/utils';
import { FileItem } from '../../../models';
import AppButton, { AppButtonProps } from '../AppButton';
import AppMediaLibrary from '../AppMediaLibrary';

export type AppMediaSelectorProps = {
  defaultValue?: string;
  mode?: 'button' | 'input' | 'thumbnail';
  multiple?: boolean;
  buttonProps?: AppButtonProps;
  inputProps?: OutlinedInputProps;
  clearable?: boolean;
  onFilesSelected?: (files: FileItem[]) => void;
  onValueCleared?: () => void;
};

const useStyles = makeStyles(() => ({
  readOnlyInput: {
    '& > input': {
      cursor: 'default',
    },
  },
}));

const AppMediaSelector: FC<AppMediaSelectorProps> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    mode = 'button',
    multiple,
    buttonProps,
    inputProps,
    onFilesSelected,
    onValueCleared,
    defaultValue,
    clearable = true,
  } = props;
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = useState<FileItem[]>([]);
  const getInputText = () => {
    if (selectedFiles.length === 0) {
      return defaultValue || '';
    }

    if (!multiple || selectedFiles.length === 1) {
      return getT2HAssetUrl(selectedFiles[0].fullUrl);
    }

    return t('common:mediaLibrary.selectedFiles', {
      fileCount: selectedFiles.length,
    }).toString();
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const openDialog = () => {
    setDialogOpen(true);
  };

  const onDialogOkClicked = (files: FileItem[]) => {
    const selectedFileList = [...files].map((file: FileItem) => {
      // if any url is not in full asset format, modify it
      if (!/^(http).+/gi.test(file.fullUrl)) {
        file.fullUrl = getT2HAssetUrl(file.fullUrl);
      }
      return file;
    });
    setSelectedFiles(selectedFileList.length ? selectedFileList : []);
    if (typeof onFilesSelected === 'function') {
      onFilesSelected(selectedFileList);
    }
    closeDialog();
  };

  const onClearClicked = () => {
    setSelectedFiles([]);
    if (typeof onValueCleared === 'function') {
      onValueCleared();
    }
  };

  return (
    <>
      {mode === 'button' && (
        <AppButton
          {...buttonProps}
          variant="outlined"
          onClick={() => openDialog()}
          startIcon={<FolderOpenIcon fontSize="small" />}>
          {t('common:mediaLibrary.button.selectFile')}
        </AppButton>
      )}
      {mode === 'input' && (
        <FormControl variant="outlined">
          {inputProps?.label && <InputLabel>{inputProps?.label}</InputLabel>}
          <OutlinedInput
            readOnly
            value={getInputText()}
            fullWidth
            {...inputProps}
            className={classes.readOnlyInput}
            endAdornment={
              <InputAdornment position="end">
                {clearable && getInputText() && (
                  <IconButton aria-label="clear" onClick={() => onClearClicked()} edge="end">
                    <ClearIcon />
                  </IconButton>
                )}
                <IconButton aria-label="select file" onClick={() => openDialog()} edge="end">
                  <FolderIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      )}
      <AppMediaLibrary open={dialogOpen} onOkClick={onDialogOkClicked} onClose={closeDialog} />
    </>
  );
};

export default AppMediaSelector;
