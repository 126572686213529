import React, { FC, useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import OrderApproveListSearchForm from '../../../components/partials/v3/orderApprove/OrderApproveListSearchForm';
import OrderOnceListTable from '../../../components/partials/v3/orderOnce/OrderOnceListTable';
import { AppGlobalUiContext } from '../../../context/AppGlobalUiContext';
import { SearchFilterContext } from '../../../context/SearchFilterContext';
import { defaultRowsPerPage } from '../../../helpers/constants';
import { AppTableConditions } from '../../../models';
import {
  OrderListSearchFormDataTC,
  OrderListSearchParamsTC,
  OrderOnceData,
  OrderStateWorkflow,
  PaymentBy,
} from '../../../models/order-order.model';
import { getOrderApprove } from '../../../services/v3/order-approve';

type OrderApproveListPageProps = unknown;

const searchFilterContextKey = 'order-approve';

const OrderApproveList: FC<OrderApproveListPageProps> = () => {
  const { t } = useTranslation();
  const { showSnackbar } = useContext(AppGlobalUiContext);
  const [orderList, setOrderList] = useState<OrderOnceData[]>([]);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [totalItems, setTotalItems] = useState<number>(0);

  const { currentFilters: ctxFilters, setFilters } = useContext(SearchFilterContext);
  const currentFilters: OrderListSearchParamsTC = ctxFilters ? ctxFilters[searchFilterContextKey] : undefined;

  const [searchFilters, setSearchFilters] = useState<OrderListSearchFormDataTC>({
    searchField: currentFilters?.searchField || '',
    searchKeyword: currentFilters?.searchKeyword || '',
    filteringStatus: currentFilters?.filteringStatus || [OrderStateWorkflow.open],
    filteringPayment: currentFilters?.filteringPayment || [PaymentBy.cr],
  });

  const [tableConditions, setTableConditions] = useState<AppTableConditions>({
    page: currentFilters?.page || 0,
    rowsPerPage: currentFilters?.rowsPerPage || defaultRowsPerPage,
    sortColumn: currentFilters?.sortColumn || 'createdAt',
    sortDirection: currentFilters?.sortDirection || 'desc',
  });

  const getCurrentSearchParam = (): OrderListSearchParamsTC => ({
    page: tableConditions.page || 0,
    rowsPerPage: tableConditions.rowsPerPage || defaultRowsPerPage,
    sortColumn: tableConditions.sortColumn || 'createdAt',
    sortDirection: tableConditions.sortDirection || 'desc',
    searchField: searchFilters.searchField,
    searchKeyword: searchFilters.searchKeyword,
    filteringStatus: searchFilters.filteringStatus || [OrderStateWorkflow.open],
    filteringPayment: searchFilters.filteringPayment || [PaymentBy.cr],
  });

  const requestOrderList = async (isPageSubscribed = true): Promise<boolean> => {
    if (isPageSubscribed) {
      setIsLoadingData(true);

      const searchConditions = getCurrentSearchParam();
      setFilters(searchFilterContextKey, searchConditions);
      const result = await getOrderApprove(searchConditions);
      setIsLoadingData(false);

      if (result.data) {
        setOrderList(result.data.contents);
        setTotalItems(result.data?.page?.totalElements || 0);
        return Promise.resolve(true);
      }

      if (result.error) {
        setOrderList([]);
        setTotalItems(0);
        showSnackbar(t('order:error.list.requestFailed').toString(), 'error');
        return Promise.reject(result.error);
      }

      showSnackbar(t(result.error as string).toString(), 'error');
      return Promise.reject(result.error);
    }

    return false;
  };

  useEffect(() => {
    let isSubscribed = true;
    requestOrderList(isSubscribed);

    return () => {
      isSubscribed = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableConditions, searchFilters]);

  const tableConditionsChangedHandler = (values: AppTableConditions) => {
    setTableConditions(values);
  };

  const onSearchFormSubmit = (filters: OrderListSearchFormDataTC) => {
    if (isLoadingData) {
      showSnackbar(t('common.message.pleaseWaitForDataToFinish').toString(), 'warning');
      return;
    }
    if (tableConditions.page !== 0) {
      tableConditions.page = 0;
    }
    setSearchFilters(filters);
  };

  return (
    <>
      <OrderApproveListSearchForm
        isLoadingData={isLoadingData}
        currentFilters={searchFilters}
        onSearchFormSubmit={onSearchFormSubmit}
      />

      <OrderOnceListTable
        currentConditions={tableConditions}
        orderOnceList={orderList}
        totalOrderOnce={totalItems}
        isLoadingData={isLoadingData}
        onTableConditionsChanged={tableConditionsChangedHandler}
        onFetch={() => requestOrderList()}
      />
    </>
  );
};

export default OrderApproveList;
