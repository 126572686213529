import React, { FC } from 'react';

import { Chip, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { ListingPossession } from '../../../../models/listing.model';

export type ListingPossessionBadgeProps = {
  possession: ListingPossession;
};

const useStyles = makeStyles((theme) => ({
  possessionChip: {
    height: '26px',
    lineHeight: '26px',
  },
  possessionJkke: {
    backgroundColor: theme.customPalette.appPossession.jkke.main,
    color: theme.customPalette.appPossession.jkke.contrastText,
  },
  possessionJkkeRejected: {
    backgroundColor: theme.customPalette.appPossession.rejected.main,
    color: theme.customPalette.appPossession.rejected.contrastText,
  },
  possessionT2hOfficial: {
    backgroundColor: theme.customPalette.appPossession.t2hOfficial.main,
    color: theme.customPalette.appPossession.t2hOfficial.contrastText,
  },
  possessionT2hOfficialConsignment: {
    backgroundColor: theme.customPalette.appPossession.t2hOfficialConsignment.main,
    color: theme.customPalette.appPossession.t2hOfficialConsignment.contrastText,
  },
}));

const ListingPossessionBadge: FC<ListingPossessionBadgeProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { possession } = props;

  if (!possession) {
    return null;
  }

  return (
    <Chip
      classes={{ root: classes.possessionChip }}
      className={clsx({
        [classes.possessionJkke]: possession === ListingPossession.Jkke,
        [classes.possessionJkkeRejected]: possession === ListingPossession.JkkeRejected,
        [classes.possessionT2hOfficial]: possession === ListingPossession.T2hOfficial,
        [classes.possessionT2hOfficialConsignment]: possession === ListingPossession.T2hOfficialConsignment,
      })}
      label={t(`listing:common.possession.${possession}`).toString()}
    />
  );
};

export default ListingPossessionBadge;
