import InterviewLogList from './List';
import { RouteConfig } from '../../../models/route.model';

const interviewLogRoutes = (path: string): RouteConfig[] => [
  {
    key: 'INTERVIEW_LOG_LIST',
    path,
    component: InterviewLogList,
    exact: true,
    title: 'common:title.interviewLog',
  },
];

export default interviewLogRoutes;
