import { apiTCRequest, apiTCRequestV2 } from '../../helpers/request';
import { queryParamGenerator } from '../../helpers/utils';
import {
  AccessLogPrivateInfoDataRequest,
  ApiResponse,
  GetInterviewLogTCResponse,
  GetNoneUserInterviewLogTCResponse,
  InterviewLogDataRequest,
  InterviewLogSearchFiltersTC,
  NoneUserInterviewLogData,
} from '../../models';

const getInterviewLogListQueryParam = (param?: InterviewLogSearchFiltersTC): string => {
  const reqParam: { [x: string]: string | number } = {
    page: param?.page || 0, // plus 1 for index shift between FE component and backend query
    per_page: param?.rowsPerPage || 10,
    sort: param?.sortColumn ? `id%7C${(param?.sortDirection || 'desc').toUpperCase()}` : '',
  };

  if (param?.searchKeyword && param?.searchField) {
    reqParam[param.searchField] = param.searchKeyword.trim();
  }

  if (param?.filteringStatus) {
    reqParam.status = param.filteringStatus;
  }

  if (param?.filteringContactBy) {
    reqParam.contactBy = param.filteringContactBy;
  }

  if (param?.filteringContactType) {
    reqParam.contactType = param.filteringContactType;
  }

  if (param?.filteringStartCreatedDate) {
    reqParam.startCreatedDate = param.filteringStartCreatedDate;
  }
  if (param?.filteringEndCreatedDate) {
    reqParam.endCreatedDate = param.filteringEndCreatedDate;
  }

  if (param?.filteringStartUpdatedDate) {
    reqParam.startUpdatedDate = param.filteringStartUpdatedDate;
  }
  if (param?.filteringEndUpdatedDate) {
    reqParam.endUpdatedDate = param.filteringEndUpdatedDate;
  }

  if (param?.q) {
    reqParam.q = param.q;
  }

  return queryParamGenerator(reqParam);
};

export const getInterviewLog = async (
  param?: InterviewLogSearchFiltersTC,
): Promise<ApiResponse<GetInterviewLogTCResponse>> => {
  try {
    const queryParamString = getInterviewLogListQueryParam(param);
    const result = await apiTCRequest<GetInterviewLogTCResponse>(`/admin/interview-log/?${queryParamString}`);

    return {
      data: result.data ?? null,
    };
  } catch (e) {
    return {
      data: null,
      error: 'interview-log:error.list.requestFailed',
    };
  }
};

export const getNoneUserInterviewLog = async (
  param?: InterviewLogSearchFiltersTC,
): Promise<ApiResponse<GetNoneUserInterviewLogTCResponse>> => {
  try {
    const queryParamString = getInterviewLogListQueryParam(param);
    const result = await apiTCRequest<GetNoneUserInterviewLogTCResponse>(
      `/admin/anonymous-user-interview-log/?${queryParamString}`,
    );

    return {
      data: result.data ?? null,
    };
  } catch (e) {
    return {
      data: null,
      error: 'interview-log:error.list.requestFailed',
    };
  }
};

export const addNewNoneUserInterviewLogType = async (
  formData: InterviewLogDataRequest,
): Promise<ApiResponse<NoneUserInterviewLogData>> => {
  const result = await apiTCRequestV2<NoneUserInterviewLogData>(`/admin/anonymous-user-interview-log/`, {
    method: 'POST',
    data: { ...formData },
  });

  if (result.data) {
    return {
      ...result,
      data: result.data,
    };
  }

  return {
    ...result,
    data: null,
  };
};

export const updateNoneUserInterviewLog = async (
  hashId: string,
  formData: InterviewLogDataRequest,
  role: string,
): Promise<ApiResponse<NoneUserInterviewLogData | null>> => {
  try {
    const userRole = role === 'ADMIN_GM' ? 'admin_gm' : 'admin';
    const result = await apiTCRequestV2<NoneUserInterviewLogData>(
      `/${userRole}/anonymous-user-interview-log/${hashId}`,
      {
        method: 'PATCH',
        data: { ...formData },
      },
    );

    return {
      ...result,
      data: result.data,
    };
  } catch (e) {
    return Promise.reject(e);
  }
};

export const addAccessLogPrivateInfo = async (
  logData: AccessLogPrivateInfoDataRequest,
): Promise<ApiResponse<boolean>> => {
  const result = await apiTCRequestV2(`/admin/log/`, {
    method: 'POST',
    data: { ...logData },
  });

  if (result.status) {
    return {
      data: [200, 201].includes(result.status),
    };
  }

  return {
    data: false,
  };
};
