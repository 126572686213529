import React, { FC, useCallback, useContext, useState } from 'react';

import { Box, Container, Divider, TableRow, Typography, makeStyles } from '@material-ui/core';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import clsx from 'clsx';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useTranslation } from 'react-i18next';

import { AppGlobalUiContext } from '../../../../../context/AppGlobalUiContext';
import usePrivilege from '../../../../../hooks/usePrivilege';
import i18n from '../../../../../i18n';
import { AppTableConditions, TableColumn } from '../../../../../models';
import { PromoCodeData, PromoCodeFormDataRequest, PromoCodeStateWorkflow } from '../../../../../models/promo-code';
import { addNewPromoCode, deletePromoCodeByHashId } from '../../../../../services/promo-code';
import useAppContainerStyles from '../../../../../theme/container.style';
import useAppTableStyles from '../../../../../theme/table.style';
import AppButton from '../../../../ui/AppButton';
import AppDialog from '../../../../ui/AppDialog';
import AppTableCell from '../../../../ui/AppTableCell';
import AppTableList from '../../../../ui/AppTableList';
import DataBlock from '../../../common/DataBlock';
import AddPromoCodeItemDialog from '../../../common/v3/AddPromoCodeDialog';
import DataDisplayBlock from '../../users/UserDetail/DataDisplayBlock';
import PromoCodeStatusBadge from '../PromoCodeStatusBadge';

dayjs.extend(customParseFormat);

export type PromoCodeListTableProps = {
  currentConditions: AppTableConditions;
  totalPromoCode: number;
  isLoadingData: boolean;
  promoCodeList: PromoCodeData[];

  onFetch: () => void;
  onTableConditionsChanged: (values: AppTableConditions) => void;
};

const promoCodeTableColumns: TableColumn[] = [
  { name: 'isActive', label: 'promo-code:list.columns.isActive', width: 120, unsortable: true },
  { name: 'hashId', label: 'promo-code:list.columns.hashId', width: 120, unsortable: true },
  { name: 'promoCode', label: 'promo-code:list.columns.promoCode', width: 400, unsortable: true },
  { name: 'createdAt', label: 'promo-code:list.columns.createdAt', width: 100, unsortable: true },
  { name: 'availableFrom', label: 'promo-code:list.columns.availableFrom', width: 100, unsortable: true },
  { name: 'availableTo', label: 'promo-code:list.columns.availableTo', width: 100, unsortable: true },
  { name: 'deleted', label: 'promo-code:list.columns.deleted', width: 140, unsortable: true },
];
const useStyles = makeStyles((theme) => ({
  selectedRow: {
    backgroundColor: theme.palette.primary.main,
    '&:nth-of-type(2n+1)': {
      backgroundColor: theme.palette.primary.main,
    },
    '& td, & a': {
      color: theme.palette.primary.contrastText,
    },
    '&:not(.readonly):hover': {
      backgroundColor: theme.palette.primary.main,
      '& td, & a': {
        color: theme.palette.primary.contrastText,
      },
    },
  },
}));

const PromoCodeListTable: FC<PromoCodeListTableProps> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { totalPromoCode, isLoadingData, currentConditions, promoCodeList, onFetch, onTableConditionsChanged } = props;
  const containerClasses = useAppContainerStyles();
  const tableClasses = useAppTableStyles();
  const [tableConditions, setTableConditions] = useState<AppTableConditions>(currentConditions);
  const { showSnackbar, setAppLoading } = useContext(AppGlobalUiContext);

  const [selectedPromoCodeItem, setSelectedPromoCodeItem] = useState<PromoCodeData | undefined>(undefined);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

  const [isAddPromoCodeDialogOpen, setIsAddPromoCodeDialogOpen] = useState<boolean>(false);
  const dateDisplayFormat = i18n.language === 'th' ? 'D MMM BBBB' : 'D MMM YYYY';

  const { canPerform } = usePrivilege();
  const allowCreate = canPerform('promoCode', 'create');

  const isAvailable = selectedPromoCodeItem?.productPromoCodeStateWorkflow === PromoCodeStateWorkflow.Available;

  const onTableConditionChangedHandler = useCallback(
    (values: AppTableConditions) => {
      if (JSON.stringify(values) !== JSON.stringify(tableConditions)) {
        setTableConditions(values);
        onTableConditionsChanged(values);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableConditions],
  );

  const onDeleteConfirm = useCallback(async () => {
    if (selectedPromoCodeItem?.hashId) {
      setAppLoading(true);
      const result = await deletePromoCodeByHashId(selectedPromoCodeItem?.hashId);
      setAppLoading(false);

      if (result.data) {
        showSnackbar(t('promo-code:message.deleteSuccess').toString(), 'success');
        setIsDeleteConfirmOpen(false);
        onFetch();
      } else {
        setIsDeleteConfirmOpen(false);
        showSnackbar(t('promo-code:error.detail.deleteFailed').toString(), 'error');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPromoCodeItem?.hashId, setAppLoading, showSnackbar, t]);

  const handleAddPromoCode = useCallback(async () => {
    setIsAddPromoCodeDialogOpen(true);
  }, []);

  const onSubmitAddNewPromoCode = useCallback(
    async (formData?: PromoCodeFormDataRequest) => {
      if (formData) {
        setAppLoading(true);

        const availableFrom = dayjs(formData.availableFrom).valueOf();
        const availableTo = dayjs(formData.availableTo).valueOf();
        const promoCode = `T2H_${formData.promoCode.toUpperCase()}`;
        delete formData.promoType;
        const result = await addNewPromoCode({ ...formData, promoCode, availableFrom, availableTo });

        if (result.data) {
          setSelectedPromoCodeItem(result.data);
          showSnackbar(t('promo-code:message.addNewPromoCodeSuccess').toString(), 'success');
          setIsAddPromoCodeDialogOpen(false);

          setTimeout(() => {
            setIsDeleteConfirmOpen(true);
          }, 1000);

          onFetch();
        } else if (result.error.response.data.message === 'tcw-product-promocode-already-existed') {
          showSnackbar(t('promo-code:message.promoCodeAlreadyExisted').toString(), 'error');
        } else {
          showSnackbar(t('promo-code:message.addNewPromoCodeFailed').toString(), 'error');
        }
        setAppLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setAppLoading, showSnackbar, t],
  );

  return (
    <>
      <Container className={containerClasses.container}>
        <Box className={tableClasses.tableContainer}>
          <Box display="flex" justifyContent="space-between" alignItems="end" mb={3}>
            <Box display="flex" flexDirection="row">
              <AppButton
                variant="contained"
                color="primary"
                startIcon={<ConfirmationNumberIcon />}
                onClick={handleAddPromoCode}
                disabled={!allowCreate}>
                {t('promo-code:list.button.addPromoCode')}
              </AppButton>
            </Box>

            {!isLoadingData && (
              <Typography
                variant="subtitle2"
                color="textSecondary"
                style={{ marginBottom: '8px', marginRight: '8px', textAlign: 'right' }}>
                {t('common:list.resultTotal', { fieldName: t(totalPromoCode.toLocaleString() || '0') })}
              </Typography>
            )}
          </Box>

          <AppTableList
            {...tableConditions}
            aria-label="promo-code-list"
            columns={promoCodeTableColumns}
            totalDataCount={totalPromoCode}
            hasPagination
            onTableConditionChanged={onTableConditionChangedHandler}
            isLoading={isLoadingData}
            tableBodyContent={
              promoCodeList.length > 0 && (
                <>
                  {promoCodeList.map((item: PromoCodeData) => {
                    const isAvailableItem = item?.productPromoCodeStateWorkflow === PromoCodeStateWorkflow.Available;
                    return (
                      <TableRow
                        hover
                        key={item.hashId}
                        classes={{
                          root: clsx({
                            [tableClasses.tableRow]: true,
                            [tableClasses.clickableTableRow]: true,
                            [classes.selectedRow]: selectedPromoCodeItem === item,
                          }),
                        }}
                        onClick={() => {
                          setIsDeleteConfirmOpen(true);
                          setSelectedPromoCodeItem(item);
                        }}>
                        <AppTableCell>
                          <PromoCodeStatusBadge status={item.productPromoCodeStateWorkflow} />
                        </AppTableCell>
                        <AppTableCell>{item.hashId}</AppTableCell>

                        <AppTableCell>
                          {isAvailableItem ? (
                            <DataDisplayBlock
                              allowCopy
                              copySuccessText="คัดลอกโค้ดแล้ว"
                              style={{
                                marginBottom: '0px',
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}>
                              {item.promoCode}
                            </DataDisplayBlock>
                          ) : (
                            <Typography>{item.promoCode}</Typography>
                          )}
                        </AppTableCell>

                        <AppTableCell>
                          {item.createdAt ? (
                            <Typography component="div" variant="subtitle2">
                              {dayjs(item.createdAt).format(`${dateDisplayFormat}`)}
                              <br />
                              {dayjs(item.createdAt).format(`HH:mm:ss`)}
                            </Typography>
                          ) : (
                            '-'
                          )}
                        </AppTableCell>

                        <AppTableCell>
                          {item.availableFrom ? (
                            <Typography variant="subtitle2">
                              {dayjs(item.availableFrom).format(`${dateDisplayFormat}`)}
                            </Typography>
                          ) : (
                            <Typography>-</Typography>
                          )}
                        </AppTableCell>
                        <AppTableCell>
                          {item.availableTo ? (
                            <Typography variant="subtitle2">
                              {dayjs(item.availableTo).format(`${dateDisplayFormat}`)}
                            </Typography>
                          ) : (
                            <Typography>-</Typography>
                          )}
                        </AppTableCell>

                        <AppTableCell>
                          <AppButton
                            disabled={!isAvailableItem}
                            variant="outlined"
                            color="error"
                            onClick={() => {
                              setIsDeleteConfirmOpen(true);
                              setSelectedPromoCodeItem(item);
                            }}>
                            {t('promo-code:list.button.inActive')}
                          </AppButton>
                        </AppTableCell>
                      </TableRow>
                    );
                  })}
                </>
              )
            }
          />
        </Box>
      </Container>

      <AppDialog
        showCloseButton
        noActionBar={!isAvailable}
        open={isDeleteConfirmOpen}
        title={t('promo-code:dialog.delete.title')}
        okButtonText={t('promo-code:list.button.confirmCancelPromoCode')}
        okButtonColor="error"
        okButtonVariant="outlined"
        cancelButtonText={t('common:button.close')}
        onClose={() => {
          setIsDeleteConfirmOpen(false);
          setSelectedPromoCodeItem(undefined);
        }}
        onOkClick={onDeleteConfirm}
        dialogProps={{
          fullWidth: true,
          maxWidth: 'sm',
          disableBackdropClick: true,
        }}>
        <Box mb={isAvailable ? 'unset' : 3}>
          {selectedPromoCodeItem?.productPromoCodeStateWorkflow && (
            <Box style={{ position: 'absolute', left: '200px', top: '16px' }}>
              <PromoCodeStatusBadge status={selectedPromoCodeItem?.productPromoCodeStateWorkflow} />
            </Box>
          )}
          <DataBlock
            label="promo-code:list.columns.createdAt"
            data={dayjs(selectedPromoCodeItem?.createdAt).format('DD MMM YY HH:mm')}
          />
          <DataBlock label="promo-code:list.columns.hashId" data={selectedPromoCodeItem?.hashId} />
          <DataBlock label="promo-code:list.columns.name" data={selectedPromoCodeItem?.name} noWrapText={false} />
          {isAvailable ? (
            <DataBlock
              label="promo-code:list.columns.promoCode"
              data={
                <DataDisplayBlock
                  allowCopy
                  copySuccessText="คัดลอกโค้ดแล้ว"
                  style={{ marginBottom: '0px', wordBreak: 'break-all' }}>
                  {selectedPromoCodeItem?.promoCode}
                </DataDisplayBlock>
              }
              noWrapText={false}
            />
          ) : (
            <DataBlock
              label="promo-code:list.columns.promoCode"
              data={selectedPromoCodeItem?.promoCode}
              noWrapText={false}
            />
          )}

          {selectedPromoCodeItem?.description && (
            <DataBlock
              label="promo-code:list.columns.description"
              data={selectedPromoCodeItem?.description}
              noWrapText={false}
            />
          )}

          {selectedPromoCodeItem?.maxDiscountPriceType === 'COIN' ? (
            <DataBlock
              label="promo-code:details.label.promoCoinProductAmount"
              data={
                <>
                  {t(
                    selectedPromoCodeItem?.promoCoinProductUnit === 'UNIT'
                      ? 'promo-code:details.label.coin'
                      : 'promo-code:details.label.percent',
                    {
                      fieldName: t(selectedPromoCodeItem?.promoCoinProductAmount.toLocaleString()),
                    },
                  )}
                </>
              }
            />
          ) : (
            <DataBlock
              label="promo-code:list.columns.promoPriceAmount"
              data={
                <>
                  {selectedPromoCodeItem?.promoPriceAmount && (
                    <>
                      {t(
                        selectedPromoCodeItem?.promoPriceUnit === 'PERCENT'
                          ? 'promo-code:details.label.percent'
                          : 'promo-code:details.label.bath',
                        {
                          fieldName: t(selectedPromoCodeItem?.promoPriceAmount.toLocaleString()),
                        },
                      )}
                    </>
                  )}
                </>
              }
            />
          )}
          {selectedPromoCodeItem?.maxDiscountPrice && (
            <DataBlock
              label="promo-code:details.label.maxDiscountPrice"
              data={
                <>
                  {t(
                    selectedPromoCodeItem?.maxDiscountPriceType === 'THB'
                      ? 'promo-code:details.label.bath'
                      : 'promo-code:details.label.coin',
                    {
                      fieldName: t(selectedPromoCodeItem?.maxDiscountPrice.toLocaleString()),
                    },
                  )}
                </>
              }
            />
          )}
          {selectedPromoCodeItem?.applyToProductHashId && (
            <DataBlock
              label="promo-code:details.label.applyToProductHashId"
              data={selectedPromoCodeItem?.applyToProductHashId}
            />
          )}
          {selectedPromoCodeItem?.userOwnerHashId && (
            <DataBlock label="promo-code:details.label.userOwnerHashId" data={selectedPromoCodeItem?.userOwnerHashId} />
          )}
          {selectedPromoCodeItem?.availableFrom && (
            <DataBlock
              label="promo-code:details.label.availableFrom"
              data={dayjs(selectedPromoCodeItem?.availableFrom).format(`${dateDisplayFormat}`)}
            />
          )}
          {selectedPromoCodeItem?.availableTo && (
            <DataBlock
              label="promo-code:details.label.availableTo"
              data={dayjs(selectedPromoCodeItem?.availableTo).format(`${dateDisplayFormat}`)}
            />
          )}

          {isAvailable && (
            <Box>
              <Box my={2}>
                <Divider />
              </Box>
              <Typography>{t('promo-code:dialog.delete.promoCodeDeleteDialogDesc1')}</Typography>
              <Typography>{t('promo-code:dialog.delete.promoCodeDeleteDialogDesc2')}</Typography>
            </Box>
          )}
        </Box>
      </AppDialog>

      {isAddPromoCodeDialogOpen && (
        <AddPromoCodeItemDialog
          title={t('promo-code:list.addNewPromoCode')}
          open={isAddPromoCodeDialogOpen}
          onClose={() => {
            setIsAddPromoCodeDialogOpen(false);
          }}
          onCloseClick={() => {
            setIsAddPromoCodeDialogOpen(false);
          }}
          onFormSubmit={onSubmitAddNewPromoCode}
        />
      )}
    </>
  );
};

export default PromoCodeListTable;
