import React, { FC } from 'react';

import { Chip, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { UserDataTC, UserGeneralWorkflow, UserRoleTC, UserStateWorkflow } from '../../../../../models';

export type UserStatusBadgeProps = {
  status: UserDataTC['userGeneralWorkflow'];
};

const useStyles = makeStyles((theme) => ({
  statusChip: {
    height: '26px',
    lineHeight: '26px',
  },
  statusFirstInit: {
    backgroundColor: theme.customPalette.appWorkflow.statusFirstInit.main,
    color: theme.customPalette.appWorkflow.statusFirstInit.contrastText,
  },
  statusActive: {
    backgroundColor: theme.customPalette.appWorkflow.statusActive.main,
    color: theme.customPalette.appWorkflow.statusActive.contrastText,
  },
  statusBanned: {
    backgroundColor: theme.customPalette.appWorkflow.statusBanned.main,
    color: theme.customPalette.appWorkflow.statusBanned.contrastText,
  },
  statusBlackListed: {
    backgroundColor: theme.customPalette.appWorkflow.statusBlackListed.main,
    color: theme.customPalette.appWorkflow.statusBlackListed.contrastText,
  },
  statusInActive: {
    backgroundColor: theme.customPalette.appWorkflow.statusInActive.main,
    color: theme.customPalette.appWorkflow.statusInActive.contrastText,
  },

  stateBuyer: {
    backgroundColor: theme.customPalette.appWorkflow.stateBuyer.main,
    color: theme.customPalette.appWorkflow.stateBuyer.contrastText,
  },
  stateSeller: {
    backgroundColor: theme.customPalette.appWorkflow.stateSeller.main,
    color: theme.customPalette.appWorkflow.stateSeller.contrastText,
  },
  stateUnverifiedSeller: {
    backgroundColor: theme.customPalette.appWorkflow.unverifiedSeller.main,
    color: theme.customPalette.appStatus.approved.contrastText,
  },

  isBuyer: {
    backgroundColor: theme.customPalette.appWorkflow.buyer.main,
    color: theme.customPalette.appWorkflow.buyer.contrastText,
  },
  isSeller: {
    backgroundColor: theme.customPalette.appWorkflow.seller.main,
    color: theme.customPalette.appStatus.approved.contrastText,
  },
  isSellerGold: {
    backgroundColor: theme.customPalette.appWorkflow.sellerGold.main,
    color: theme.customPalette.appWorkflow.sellerGold.contrastText,
  },
  isSellerSilver: {
    backgroundColor: theme.customPalette.appWorkflow.sellerSilver.main,
    color: theme.customPalette.appWorkflow.sellerSilver.contrastText,
  },
  isSellerBronze: {
    backgroundColor: theme.customPalette.appWorkflow.sellerBronze.main,
    color: theme.customPalette.appWorkflow.sellerBronze.contrastText,
  },
}));

const UserStatusBadge: FC<UserStatusBadgeProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { status } = props;

  return (
    <Chip
      classes={{ root: classes.statusChip }}
      className={clsx({
        [classes.statusFirstInit]: status === UserGeneralWorkflow.FirstInit,
        [classes.statusActive]: status === UserGeneralWorkflow.Active,
        [classes.statusBanned]: status === UserGeneralWorkflow.Banned,
        [classes.statusBlackListed]: status === UserGeneralWorkflow.BlackListed,
        [classes.statusInActive]: status === UserGeneralWorkflow.InActive,

        [classes.stateBuyer]: status === UserStateWorkflow.Buyer,
        [classes.stateSeller]: status === UserStateWorkflow.Seller,
        [classes.stateUnverifiedSeller]: status === UserStateWorkflow.UnverifiedSeller,

        [classes.isBuyer]: status === UserRoleTC.Buyer,
        [classes.isSeller]: status === UserRoleTC.Seller,
        [classes.isSellerGold]: status === UserRoleTC.SellerGold,
        [classes.isSellerSilver]: status === UserRoleTC.SellerSilver,
        [classes.isSellerBronze]: status === UserRoleTC.SellerBronze,
      })}
      label={t(`user:v3.list.search.workflow.${status}`).toString()}
    />
  );
};

export default UserStatusBadge;
