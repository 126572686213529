import React, { FC, useCallback, useContext, useEffect, useState } from 'react';

import { Box, Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { AppGlobalUiContext } from '../../../../../context/AppGlobalUiContext';
import { SearchFilterContext } from '../../../../../context/SearchFilterContext';
import { defaultRowsPerPage } from '../../../../../helpers/constants';
import { AppTableConditions, UserDataTC } from '../../../../../models';
import {
  OrderOnceData,
  OrderOnceListSearchParamsTC,
  OrderSubscriptionOpenData,
} from '../../../../../models/order-order.model';
import { getOrderOnceByHashId, getOrderSubscriptionOpen } from '../../../../../services/v3/order-once';
import AppButton from '../../../../ui/AppButton';
import OrderOnceListTable from '../../orderOnce/OrderOnceListTable';
import OrderSubscriptionOpen from '../../orderSubscription/OrderSubscriptionOpen';

const searchFilterContextKey = 'users';

export interface OrderOnceListProps {
  currentUser: UserDataTC;
}

const useStyles = makeStyles((theme) => ({
  menuTab: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    width: '100%',
    backgroundColor: 'white',
  },
  buttonTab: {
    padding: '10px 20px',
    borderRadius: 'unset',
  },
  linkTab: {
    color: 'black',
    textDecoration: 'unset',
    padding: '20px 0',
  },
}));

const OrderOnceList: FC<OrderOnceListProps> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { currentUser } = props;
  const { showSnackbar } = useContext(AppGlobalUiContext);
  const [orderOnceList, setOrderOnceList] = useState<OrderOnceData[]>([]);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [totalOrderOnce, setTotalOrderOnce] = useState<number>(0);

  const [activeTab, setActiveTab] = useState<string>('once');

  const { currentFilters: ctxFilters, setFilters } = useContext(SearchFilterContext);
  const currentFilters: OrderOnceListSearchParamsTC = ctxFilters ? ctxFilters[searchFilterContextKey] : undefined;

  const [tableConditions, setTableConditions] = useState<AppTableConditions>({
    page: currentFilters?.page || 0,
    rowsPerPage: currentFilters?.rowsPerPage || defaultRowsPerPage,
    sortColumn: currentFilters?.sortColumn || 'createdAt',
    sortDirection: currentFilters?.sortDirection || 'desc',
  });

  const [orderSubscription, setOrderSubscription] = useState<OrderSubscriptionOpenData>();

  const handleTabClick = (orderType: string) => {
    setActiveTab(orderType);
  };

  const getCurrentSearchParam = (): OrderOnceListSearchParamsTC => ({
    page: tableConditions.page || 0,
    rowsPerPage: tableConditions.rowsPerPage || defaultRowsPerPage,
    sortColumn: tableConditions.sortColumn || 'createdAt',
    sortDirection: tableConditions.sortDirection || 'desc',
  });

  const getOrderOnceList = async (isPageSubscribed = true): Promise<boolean> => {
    if (isPageSubscribed) {
      setIsLoadingData(true);

      const searchConditions = getCurrentSearchParam();
      setFilters(searchFilterContextKey, searchConditions);

      const result = await getOrderOnceByHashId(currentUser.userHashId, searchConditions);

      setIsLoadingData(false);

      if (result.data) {
        setOrderOnceList(result.data.contents);
        setTotalOrderOnce(result.data?.page?.totalElements || 0);
        return Promise.resolve(true);
      }

      showSnackbar(t(result.error as string).toString(), 'error');
      return Promise.reject(result.error);
    }

    return false;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useDeepCompareEffect((): any => {
    let isSubscribed = true;

    if (activeTab === 'once') {
      getOrderOnceList(isSubscribed);
    }

    return () => (isSubscribed = false);
  }, [tableConditions]);

  const tableConditionsChangedHandler = (values: AppTableConditions) => {
    setTableConditions(values);
  };

  const getOrderSubscription = useCallback(async () => {
    setIsLoadingData(true);
    const result = await getOrderSubscriptionOpen(currentUser.userHashId);
    if (result.data) {
      setOrderSubscription(result.data);
    }
  }, [currentUser.userHashId]);

  useEffect(() => {
    if (activeTab !== 'once') {
      getOrderSubscription();
    } else {
      getOrderOnceList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  return (
    <>
      <Box>
        <Box display="flex" flexDirection="row" className={classes.menuTab}>
          <AppButton
            className={classes.buttonTab}
            variant="text"
            style={{
              backgroundColor: activeTab === 'once' ? '#F5F5F5' : 'transparent',
            }}
            onClick={() => handleTabClick('once')}>
            <Typography style={{ fontWeight: 'bold' }}>{t('user:details.tabs.orderOnce')}</Typography>
          </AppButton>
          <AppButton
            className={classes.buttonTab}
            variant="text"
            style={{
              backgroundColor: activeTab !== 'once' ? '#F5F5F5' : 'transparent',
            }}
            onClick={() => handleTabClick('subscription')}>
            <Typography style={{ fontWeight: 'bold' }}>{t('user:details.tabs.orderSubscriptionOpen')}</Typography>
          </AppButton>
        </Box>

        <Box className="tab-content">
          {activeTab === 'once' ? (
            <OrderOnceListTable
              currentConditions={tableConditions}
              orderOnceList={orderOnceList}
              totalOrderOnce={totalOrderOnce}
              isLoadingData={isLoadingData}
              onTableConditionsChanged={tableConditionsChangedHandler}
              onFetch={() => getOrderOnceList()}
            />
          ) : (
            <OrderSubscriptionOpen
              orderSubscription={orderSubscription}
              isLoadingData={isLoadingData}
              onFetch={() => getOrderSubscription()}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

export default OrderOnceList;
