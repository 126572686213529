import React, { FC, useContext, useState } from 'react';

import { useTranslation } from 'react-i18next';
import useDeepCompareEffect from 'use-deep-compare-effect';

import UserApplicationListTable from '../../../components/partials/v3/user-applications/UserApplicationListTable';
import UserApplicationsListSearchForm from '../../../components/partials/v3/user-applications/UserApplicationsListSearchForm';
import { AppGlobalUiContext } from '../../../context/AppGlobalUiContext';
import { SearchFilterContext } from '../../../context/SearchFilterContext';
import { defaultRowsPerPage } from '../../../helpers/constants';
import { AppTableConditions } from '../../../models';
import {
  UserAppListSearchFormData,
  UserAppListSearchParams,
  UserApplication,
} from '../../../models/user-application.model';
import * as userApplicationService from '../../../services/v3/user-applications';

const searchFilterContextKey = 'user-applications';

const UserApplicationList: FC = () => {
  const { t } = useTranslation();
  const { showSnackbar } = useContext(AppGlobalUiContext);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [userApplicationList, setUserApplicationList] = useState<UserApplication[]>([]);
  const [totalUserApplications, setTotalUserApplications] = useState<number>(0);
  // start filling default search filters (either from the one we saved in context or fresh conditions)
  const { currentFilters: ctxFilters, setFilters } = useContext(SearchFilterContext);
  const currentFilters: UserAppListSearchParams = ctxFilters ? ctxFilters[searchFilterContextKey] : undefined;
  const [searchFilters, setSearchFilters] = useState<UserAppListSearchFormData>({
    searchField: currentFilters?.searchField,
    searchKeyword: currentFilters?.searchKeyword || '',
    filteringStatuses: currentFilters?.filteringStatuses || [],
  });
  const [tableConditions, setTableConditions] = useState<AppTableConditions>({
    page: currentFilters?.page || 0,
    rowsPerPage: currentFilters?.rowsPerPage || defaultRowsPerPage,
    sortColumn: currentFilters?.sortColumn || 'createdAt',
    sortDirection: currentFilters?.sortDirection || 'desc',
  });

  const getCurrentSearchParam = (): UserAppListSearchParams => ({
    page: tableConditions.page || 0,
    rowsPerPage: tableConditions.rowsPerPage || defaultRowsPerPage,
    sortColumn: tableConditions.sortColumn || 'createdAt',
    sortDirection: tableConditions.sortDirection || 'desc',
    searchField: searchFilters.searchField,
    searchKeyword: searchFilters.searchKeyword,
    filteringStatuses: searchFilters.filteringStatuses,
  });

  const requestUserApplicationList = async (isPageSubscribed = true): Promise<boolean> => {
    if (isPageSubscribed) {
      setIsLoadingData(true);

      const searchConditions = getCurrentSearchParam();
      setFilters(searchFilterContextKey, searchConditions);
      const result = await userApplicationService.getUserApplicationList(searchConditions);

      setIsLoadingData(false);

      if (result.data) {
        setUserApplicationList(result.data.userApplications);
        setTotalUserApplications(result.data.totalCount);
        return Promise.resolve(true);
      }

      showSnackbar(t(result.error as string).toString(), 'error');
      return Promise.reject(result.error);
    }

    return false;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useDeepCompareEffect((): any => {
    let isSubscribed = true;

    requestUserApplicationList(isSubscribed);

    return () => (isSubscribed = false);
  }, [tableConditions, searchFilters]);

  const tableConditionsChangedHandler = (values: AppTableConditions) => {
    setTableConditions(values);
  };

  const onSearchFormSubmit = (filters: UserAppListSearchFormData) => {
    if (isLoadingData) {
      showSnackbar(t('common.message.pleaseWaitForDataToFinish').toString(), 'warning');
      return;
    }

    if (tableConditions.page !== 0) {
      tableConditions.page = 0;
    }

    setSearchFilters(filters);
  };

  return (
    <>
      <UserApplicationsListSearchForm
        isLoadingData={isLoadingData}
        currentFilters={searchFilters}
        onSearchFormSubmit={onSearchFormSubmit}
      />
      <UserApplicationListTable
        currentConditions={tableConditions}
        totalApplications={totalUserApplications}
        isLoadingData={isLoadingData}
        userApplicationList={userApplicationList}
        onTableConditionsChanged={tableConditionsChangedHandler}
      />
    </>
  );
};

export default UserApplicationList;
