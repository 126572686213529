import { apiTCRequestV2 } from '../../helpers/request';
import { queryParamGenerator } from '../../helpers/utils';
import { ApiResponse } from '../../models';
import {
  CoinUsageTransactionSearchFiltersTC,
  GetCoinUsageTransactionTCResponse,
  GetMembershipTransactionTCResponse,
  MembershipTransactionSearchFiltersTC,
} from '../../models/user.model';

export const getCoinBalanceByHashId = async (userHashId: string): Promise<number | null> => {
  const result = await apiTCRequestV2<{ balance: number }>(`/admin/user-wallet/${userHashId}/balance`);

  return result.data?.balance || null;
};

export const updateCoinTopUp = async (userHashId: string, coinAmount: number): Promise<string> => {
  try {
    const result = await apiTCRequestV2<string>(`/admin_gm/user-wallet/manage-coin-balance`, {
      method: 'PATCH',
      data: {
        userHashId,
        coinAmount,
      },
    });

    return result.data || '';
  } catch (e) {
    return Promise.reject(e);
  }
};

const getCoinUsageQueryParamTc = (param?: CoinUsageTransactionSearchFiltersTC): string => {
  const reqParam: { [x: string]: string | number } = {
    page: param?.page || 0, // plus 1 for index shift between FE component and backend query
    per_page: param?.rowsPerPage || 10,
    sort: param?.sortColumn ? `id%7C${(param?.sortDirection || 'desc').toUpperCase()}` : '',
  };

  if (param?.filteringUsageType?.length) {
    reqParam.usageType = param.filteringUsageType.join(',');
  }

  if (param?.q) {
    reqParam.q = param.q;
  }

  return queryParamGenerator(reqParam);
};

export const getCoinUsageTransaction = async (
  hashId: string,
  param?: CoinUsageTransactionSearchFiltersTC,
): Promise<ApiResponse<GetCoinUsageTransactionTCResponse>> => {
  try {
    const queryParamString = getCoinUsageQueryParamTc(param);
    const result = await apiTCRequestV2<GetCoinUsageTransactionTCResponse>(
      `admin/user-coin-usage-transaction/?${queryParamString}&userHashId=${hashId}`,
    );

    return {
      data: result.data ?? null,
    };
  } catch (e) {
    return {
      data: null,
      error: 'Error GET Coin Usage Transaction log By hashId',
    };
  }
};

export const getMembershipTransaction = async (
  hashId: string,
  param?: MembershipTransactionSearchFiltersTC,
): Promise<ApiResponse<GetMembershipTransactionTCResponse>> => {
  try {
    const queryParamString = getCoinUsageQueryParamTc(param);
    const result = await apiTCRequestV2<GetMembershipTransactionTCResponse>(
      `admin/user-membership-transaction/?${queryParamString}&userHashId=${hashId}`,
    );

    return {
      data: result.data ?? null,
    };
  } catch (e) {
    return {
      data: null,
      error: 'Error GET Membership Transaction log By hashId',
    };
  }
};
