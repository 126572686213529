/* eslint-disable import/prefer-default-export */
import { doc, FirestoreDataConverter, QueryDocumentSnapshot, setDoc, SnapshotOptions } from 'firebase/firestore';

import { firestoreDb, FS_DB_COLLECTION } from '../../../helpers/firebase';
import { MessageUser } from '../../../models/message-user';
import { UserData } from '../../../models/user.model';

export const messageUserConverter: FirestoreDataConverter<MessageUser> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toFirestore: (form: any) => ({
    ...form,
  }),
  fromFirestore: (snapshot: QueryDocumentSnapshot, options: SnapshotOptions) => {
    const data = snapshot.data(options);
    const {
      displayName,
      firstName,
      lastName,
      role,
      profileImageUrl,
      hashId,
      contactNumber,
      isVerified,
      blockedUsers = [],
    } = data;

    return { displayName, firstName, lastName, role, profileImageUrl, hashId, contactNumber, isVerified, blockedUsers };
  },
};

export const createFireStoreUser = async (
  userInfo: Pick<
    UserData,
    'displayName' | 'firstName' | 'lastName' | 'hashId' | 'profileImageUrl' | 'role' | 'isVerified'
  >,
): Promise<string | null> => {
  try {
    const ref = doc(firestoreDb, FS_DB_COLLECTION.users, userInfo.hashId);
    const { displayName, firstName, hashId, lastName, profileImageUrl, role, isVerified } = userInfo;
    const newUserData = { displayName, firstName, hashId, lastName, profileImageUrl, role, isVerified };
    await setDoc(ref, newUserData);

    return userInfo.hashId;
  } catch (ex) {
    return null;
  }
};
