/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { Box, Button, Grid, TextField, Typography } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Controller, UseFormReturn } from 'react-hook-form';

import { PROVINCE_OPTION } from '../../../../helpers/province-option';
import { KTCategoryPathItem } from '../../../../models';
import AppFormControl from '../../../ui/AppFormControl';
import ModalSelectValue, { ModalSelectValueProps } from '../ModalSelectValue';

interface FieldProvinceProps {
  itemPathProvince: KTCategoryPathItem;
  defaultProvinceUid: string;
  formHolder: UseFormReturn<any>;
  isEditingForm: boolean;
  onSetValue?: (data: { name?: string; value?: any; uid?: number } & any) => void;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const FieldProvince = (props: FieldProvinceProps) => {
  const { itemPathProvince, defaultProvinceUid, formHolder, onSetValue, isEditingForm } = props;
  const value = defaultProvinceUid;
  const dataList = PROVINCE_OPTION.map((i) => ({
    name: i.thaiName,
    value: i.slug,
    uid: i.uid,
  }));
  const [currentValuePreview, setCurrentValuePreview] = React.useState<string | number | null>();
  const [isModalSelectDataOpen, setIsModalSelectDataOpen] = React.useState(false);

  const {
    control,
    setValue,
    formState: { errors },
  } = formHolder;

  const handleSetCurrentValuePreview = (
    currentValue: string | number,
    modalDataList: ModalSelectValueProps['dataList'],
  ) => {
    if (currentValue) {
      const provinceValue = PROVINCE_OPTION.find(
        // eslint-disable-next-line max-len
        (provinceItem) =>
          provinceItem?.hashId === currentValue ||
          provinceItem?.slug === currentValue ||
          provinceItem?.uid === currentValue,
      )?.slug;

      if (modalDataList?.length > 0) {
        const currentData = modalDataList?.find((item) => item.value === provinceValue);
        setCurrentValuePreview(currentData ? currentData?.name : provinceValue);
      } else {
        setCurrentValuePreview(provinceValue);
      }
    } else {
      setCurrentValuePreview('');
    }
  };

  const handleSetValue = (data: any) => {
    if (onSetValue) {
      onSetValue(data);
    }
    setValue(data?.name, data?.value);
    handleSetCurrentValuePreview(data?.value, dataList);
  };

  React.useEffect(() => {
    handleSetCurrentValuePreview(value, dataList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid item xs={12}>
      <Box marginY={2} display="flex" flexDirection="row">
        <Typography style={{ paddingTop: 8 }}>{itemPathProvince.masterIndex.thaiName}</Typography>
        <Button
          type="button"
          onClick={() => {
            if (isEditingForm) {
              setIsModalSelectDataOpen(true);
            }
          }}>
          <Box marginX={2} display="block" width="100%">
            <Controller
              name={itemPathProvince.masterIndex.slug}
              control={control}
              render={() => (
                <AppFormControl>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    name={`preview-${itemPathProvince.masterIndex.slug}`}
                    placeholder={
                      itemPathProvince.masterIndex.placeholder || `เลือก${itemPathProvince.masterIndex.thaiName}`
                    }
                    value={currentValuePreview}
                    error={errors[itemPathProvince.masterIndex.slug]?.message}
                    disabled={!isEditingForm}
                  />
                </AppFormControl>
              )}
            />
          </Box>
          <ArrowDropDownIcon style={{ margin: 'auto', fontSize: '24px' }} color="primary" />
        </Button>
        <ModalSelectValue
          isOpen={isModalSelectDataOpen}
          selectedValue={value}
          title={itemPathProvince.masterIndex.thaiName}
          dataList={dataList}
          onClickItem={(data) => {
            handleSetValue({ name: itemPathProvince.masterIndex.slug, value: data.value, uid: data.uid });
          }}
          handleClose={() => {
            setIsModalSelectDataOpen(false);
          }}
        />
      </Box>
    </Grid>
  );
};

export default FieldProvince;
