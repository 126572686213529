import { makeStyles } from '@material-ui/core';

const useControlStyles = makeStyles((theme) => ({
  buttonsGroup: {
    '& > .MuiButton-root': {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  buttonIcon: {
    marginRight: '4px',
  },
}));

export default useControlStyles;
