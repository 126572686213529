import React, { FC, useContext, useState, useEffect } from 'react';

import { Box, Container } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useDeepCompareEffect from 'use-deep-compare-effect';

import ModelListTable from '../../../components/partials/master-data/model/ModelListTable';
import AppBreadcrumb from '../../../components/ui/AppBreadcrumb';
import AppButton from '../../../components/ui/AppButton';
import { AppGlobalUiContext } from '../../../context/AppGlobalUiContext';
import { SearchFilterContext } from '../../../context/SearchFilterContext';
import { defaultRowsPerPage } from '../../../helpers/constants';
import {
  AppTableConditions,
  BreadcrumbItem,
  Model,
  ModelListSearchFormData,
  ModelListSearchParams,
} from '../../../models';
import * as modelService from '../../../services/model';
import useAppContainerStyles from '../../../theme/container.style';
import useAppTableStyles from '../../../theme/table.style';

const searchFilterContextKey = 'master-data_models';

const ModelList: FC = () => {
  const { uid: brandUid } = useParams<{ uid: string }>();
  const { t } = useTranslation();
  const containerClasses = useAppContainerStyles();
  const tableClasses = useAppTableStyles();
  const { showSnackbar, setBreadcrumb } = useContext(AppGlobalUiContext);

  const [isLoading, setIsLoading] = useState(true);
  const [modelData, setModelData] = useState<Model[]>([]);
  const [totalModels, setTotalModels] = useState<number>(0);

  const { currentFilters: ctxFilters, setFilters } = useContext(SearchFilterContext);
  const currentFilters: ModelListSearchParams = ctxFilters ? ctxFilters[searchFilterContextKey] : undefined;

  // TODO: remove _ in front of setSearchFilters when API is ready
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchFilters, _setSearchFilters] = useState<ModelListSearchFormData>({
    searchField: currentFilters?.searchField,
    searchKeyword: currentFilters?.searchKeyword || '',
  });
  const [tableConditions, setTableConditions] = useState<AppTableConditions>({
    page: currentFilters?.page || 0,
    rowsPerPage: currentFilters?.rowsPerPage || defaultRowsPerPage,
    sortColumn: currentFilters?.sortColumn || 'name',
    sortDirection: currentFilters?.sortDirection || 'asc',
  });
  const breadcrumbItems: BreadcrumbItem[] = [
    { label: 'common:sidebar.menu.masterData', url: '/master-data' },
    { label: 'master-data:menu.brand', url: '/master-data/brands' },
    {
      label: 'master-data:menu.brandUid',
      url: `/master-data/brands/${brandUid}`,
      labelParam: {
        brandUid,
      },
    },
    { label: 'master-data:menu.model' },
  ];

  const getCurrentSearchParam = (): ModelListSearchParams => ({
    page: tableConditions.page || 0,
    rowsPerPage: tableConditions.rowsPerPage || defaultRowsPerPage,
    sortColumn: tableConditions.sortColumn || 'name',
    sortDirection: tableConditions.sortDirection || 'asc',
    searchField: searchFilters.searchField,
    searchKeyword: searchFilters.searchKeyword,
  });

  const requestModelList = async (isPageActive = true): Promise<boolean> => {
    if (isPageActive) {
      setIsLoading(true);
      const searchConditions = getCurrentSearchParam();
      setFilters(searchFilterContextKey, searchConditions);
      const result = await modelService.getModelListByBrandUid(brandUid, searchConditions);

      setIsLoading(false);

      if (result.data) {
        setModelData(result.data.list);
        setTotalModels(result.data.totalCount);
        return Promise.resolve(true);
      }

      showSnackbar(t(result.error as string).toString(), 'error');
      return Promise.reject(result.error);
    }

    return false;
  };

  useEffect(() => {
    setBreadcrumb(breadcrumbItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDeepCompareEffect(() => {
    let isActive = true;

    requestModelList(isActive);

    return () => {
      isActive = false;
    };
  }, [tableConditions, searchFilters]);

  const tableConditionsChangedHandler = (values: AppTableConditions) => {
    setTableConditions(values);
  };

  // TODO: will enable after search is available
  // const onSearchFormSubmit = (filters: ModelListSearchFormData) => {
  //   if (tableConditions.page !== 0) {
  //     tableConditions.page = 0;
  //   }

  //   setSearchFilters(filters);
  // };

  return (
    <>
      <Box px={6} py={3}>
        <AppBreadcrumb items={breadcrumbItems} />
      </Box>
      {/* TODO: will enable after search is available */}
      {/* <ModelListSearchForm currentFilters={searchFilters} onSearchFormSubmit={onSearchFormSubmit} /> */}
      <Container className={containerClasses.container}>
        <Box className={tableClasses.tableContainer}>
          <Box mb={2}>
            <AppButton
              color="primary"
              variant="outlined"
              startIcon={<Add />}
              url={`/master-data/brands/${brandUid}/models/create`}>
              {t('common:button.addField', {
                field: t('master-data:common.field.model').toString(),
              })}
            </AppButton>
          </Box>
          <ModelListTable
            currentConditions={tableConditions}
            totalModels={totalModels}
            isLoadingData={isLoading}
            modelList={modelData}
            onTableConditionsChanged={tableConditionsChangedHandler}
          />
        </Box>
      </Container>
    </>
  );
};

export default ModelList;
