import React, { FC, useCallback, useEffect, useState } from 'react';

import { Box, Grid, InputLabel, MenuItem, OutlinedInput, Select, Typography, makeStyles } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  FREE_COIN_3000,
  GOLD_COMPANY,
  GOLD_COMPANY_ADMIN,
  INDIVIDUAL1,
  INDIVIDUAL2,
  FREE_COIN_100,
} from '../../../../../helpers/constants/promo-code';
import { formatDate } from '../../../../../helpers/date';
import usePrivilege from '../../../../../hooks/usePrivilege';
import { PromoCodeFormDataRequest } from '../../../../../models/promo-code';
import AppButton from '../../../../ui/AppButton';
import AppDialog from '../../../../ui/AppDialog';
import AppFormControl from '../../../../ui/AppFormControl';

export type AddPromoCodeItemDialogProps = {
  open: boolean;
  title: string;
  onClose: () => void;
  onCloseClick?: () => void;
  onFormSubmit: (data: PromoCodeFormDataRequest) => void;
};

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  numberInput: {
    MozAppearance: 'none',
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
  confirmDialogBox: {
    width: '100%',
    maxWidth: '1000px',
    [theme.breakpoints.up('md')]: {
      minWidth: '400px',
    },
  },
  buttonGroups: {
    '& > *': {
      margin: `0 ${theme.spacing(1)}px`,
    },
  },
}));

const AddPromoCodeItemDialog: FC<AddPromoCodeItemDialogProps> = (props) => {
  const classes = useStyles();
  const { open, title, onClose, onCloseClick, onFormSubmit } = props;
  const { t } = useTranslation();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);

  const { canPerform } = usePrivilege();
  const allowCreate = canPerform('promoCode', 'create');
  const [promoCodeTypeData, setPromoCodeTypeData] = useState<string>('');

  const {
    handleSubmit,
    getValues,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm<PromoCodeFormDataRequest>({
    mode: 'onChange',
    defaultValues: {
      promoType: 'UNSPECIFIED' || null,
      name: '',
      description: '' || null,
      promoCode: '',
      availableFrom: 0 || null,
      availableTo: 0 || null,
      applyToProductHashId: '' || null,
      userOwnerHashId: '' || null,
      promoPriceAmount: 0 || null,
      promoPriceUnit: 'UNIT' || null,
      promoCoinProductAmount: 0 || null,
      promoCoinProductUnit: 'UNIT' || null,
      maxDiscountPrice: 0 || null,
      maxDiscountPriceType: 'THB' || null,
    },
  });

  const isDisabled = watch('promoPriceUnit') === 'UNIT' && watch('promoCoinProductUnit') === 'UNIT';
  const isPromoCodePrice = watch('promoPriceAmount');
  const isPromoCodeCoin = watch('promoCoinProductAmount');

  const now = new Date();
  const currentTime = now.getTime();

  useEffect(() => {
    if (watch('promoType')) {
      setPromoCodeTypeData(getValues('promoType') || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('promoType')]);

  useEffect(() => {
    switch (promoCodeTypeData) {
      case GOLD_COMPANY:
        setValue('promoCode', `${'T2H_'}${GOLD_COMPANY}_${currentTime}`);
        setValue('name', 'ส่วนลด เหรียญทองราย 1 เดือน ซื้อผ่านระบบโดยบัตรเครดิต');
        setValue('description', 'หัก ณ ที่จ่าย (3%) 148.57 บาท จากยอด 5,299 ที่ต้องชำระ 5,150.43 บาท');
        setValue('promoPriceAmount', 148.57);
        setValue('promoCoinProductAmount', 0);
        setValue('maxDiscountPriceType', 'THB');
        break;
      case GOLD_COMPANY_ADMIN:
        setValue('promoCode', `${'T2H_'}${GOLD_COMPANY_ADMIN}_${currentTime}`);
        setValue('name', 'ส่วนลด เหรียญทองราย 1 เดือน ซื้อผ่านแอดมิน ในนามนิติบุคคล');
        setValue('description', 'หัก ณ ที่จ่าย 440.16 บาท จากยอด 5,299 ที่ต้องชำระ 4,858.84 บาท');
        setValue('promoPriceAmount', 440.16);
        setValue('promoCoinProductAmount', 0);
        setValue('maxDiscountPriceType', 'THB');
        break;
      case INDIVIDUAL1:
        setValue('promoCode', `${'T2H_'}${INDIVIDUAL1}_${currentTime}`);
        setValue('name', 'ส่วนลด เหรียญทองราย 1 เดือน แบบบุคคลเคยซื้อในราคา 4,999');
        setValue('description', 'ส่วนลด 300 บาท จากยอด 5,299 ที่ต้องชำระ 4,999 บาท');
        setValue('promoPriceAmount', 300);
        setValue('promoCoinProductAmount', 0);
        setValue('maxDiscountPriceType', 'THB');
        break;
      case INDIVIDUAL2:
        setValue('promoCode', `${'T2H_'}${INDIVIDUAL2}_${currentTime}`);
        setValue('name', 'ส่วนลด เหรียญทองราย 1 เดือน แบบบุคคลเคยซื้อในราคา 4,999');
        setValue('description', 'ส่วนลด 299 บาท จากยอด 5,299 ที่ต้องชำระ 5,000 บาท');
        setValue('promoPriceAmount', 299);
        setValue('promoCoinProductAmount', 0);
        setValue('maxDiscountPriceType', 'THB');
        break;
      case FREE_COIN_3000:
        setValue('promoCode', `${'T2H_'}${FREE_COIN_3000}_${currentTime}`);
        setValue('name', 'ส่วนลด Coin 3,000 บาท');
        setValue('description', 'ส่วนลดสำหรับซื้อ Coin 3,000 บาท/ต่อเดือน ให้กับ Sale เหรียญเงิน');
        setValue('promoPriceAmount', 3000);
        setValue('promoCoinProductAmount', 0);
        setValue('maxDiscountPriceType', 'THB');
        break;
      case FREE_COIN_100:
        setValue('promoCode', `${'T2H_'}${FREE_COIN_100}_${currentTime}`);
        setValue('name', 'ส่วนลด Coin 100 บาท');
        setValue('description', 'ส่วนลดซื้อ Coin 100 บาท');
        setValue('promoPriceAmount', 100);
        setValue('promoCoinProductAmount', 0);
        setValue('maxDiscountPriceType', 'THB');
        break;
      default:
        setValue('name', '');
        setValue('description', '');
        setValue('promoCode', '');
        setValue('promoPriceAmount', 0);
        setValue('promoCoinProductAmount', 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promoCodeTypeData, setValue]);

  const onSaveClick = () => {
    setIsConfirmDialogOpen(true);
  };

  const onConfirmationSaveClick = () => {
    setIsConfirmDialogOpen(false);
    if (typeof onFormSubmit === 'function') {
      onFormSubmit(getValues());
    }
  };

  const onConfirmationCancelClick = () => {
    setIsConfirmDialogOpen(false);
  };

  const codeFieldInput = useCallback(() => {
    if (promoCodeTypeData === 'UNSPECIFIED') {
      if (isPromoCodePrice && isPromoCodePrice > 0) {
        const codeValue = `${'t2h_price'.toLocaleUpperCase()}${isPromoCodePrice}_${currentTime}`;
        setValue('promoCode', codeValue);
      } else if (isPromoCodeCoin && isPromoCodeCoin > 0) {
        const codeValue = `${'t2h_coin'.toLocaleUpperCase()}${isPromoCodeCoin}_${currentTime}`;
        setValue('promoCode', codeValue);
      } else {
        setValue('promoCode', '');
      }
    }
  }, [currentTime, isPromoCodeCoin, isPromoCodePrice, promoCodeTypeData, setValue]);

  return (
    <>
      <AppDialog
        open={open}
        onClose={onClose}
        title={title}
        okButtonText="common:button.close"
        okButtonColor="default"
        okButtonVariant="outlined"
        closeOnOkClicked
        noActionBar
        dialogProps={{
          fullWidth: true,
          maxWidth: 'md',
          disableBackdropClick: true,
        }}>
        <form onSubmit={handleSubmit(onSaveClick)}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Box mb={3}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Controller
                      name="promoType"
                      control={control}
                      render={({ field }) => (
                        <AppFormControl error={!!errors.promoType}>
                          <InputLabel htmlFor="promo-code-promoType">
                            {t('promo-code:details.label.promoType')}
                          </InputLabel>
                          <Select
                            id="promo-code-promoType"
                            value={field.value}
                            onChange={field.onChange}
                            error={!!errors.promoType}
                            fullWidth>
                            <MenuItem value="UNSPECIFIED">ไม่ระบุ</MenuItem>
                            <MenuItem value={GOLD_COMPANY}>แบบนิติบุคคลผ่านระบบ</MenuItem>
                            <MenuItem value={GOLD_COMPANY_ADMIN}>แบบนิติบุคคลผ่านแอดมิน</MenuItem>
                            <MenuItem value={INDIVIDUAL1}>แบบบุคคล 4,999</MenuItem>
                            <MenuItem value={INDIVIDUAL2}>แบบบุคคล 5,000</MenuItem>
                            <MenuItem value={FREE_COIN_3000}>ส่วนลดเหรียญ 3,000 บาท</MenuItem>
                            <MenuItem value={FREE_COIN_100}>ส่วนลดเหรียญ 100 บาท</MenuItem>
                          </Select>
                        </AppFormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="name"
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field }) => (
                        <AppFormControl>
                          <InputLabel htmlFor="promo-code-name">{t('promo-code:details.label.name')} *</InputLabel>
                          <OutlinedInput
                            {...field}
                            id="promo-code-name"
                            fullWidth
                            disabled={!allowCreate}
                            error={!!errors.name}
                          />
                        </AppFormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="description"
                      control={control}
                      rules={{
                        required: false,
                      }}
                      render={({ field }) => (
                        <AppFormControl>
                          <InputLabel htmlFor="promo-code-description">
                            {t('promo-code:details.label.description')}
                          </InputLabel>
                          <OutlinedInput
                            {...field}
                            id="promo-code-description"
                            fullWidth
                            disabled={!allowCreate}
                            error={!!errors.description}
                          />
                        </AppFormControl>
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box mb={1}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={3}>
                    <Controller
                      name="promoPriceAmount"
                      control={control}
                      render={(data) => (
                        <AppFormControl error={!!errors.promoPriceAmount}>
                          <InputLabel htmlFor="promo-code-promoPriceAmount">
                            {t('promo-code:details.label.promoPriceAmount')}
                          </InputLabel>
                          <Box style={{ position: 'relative' }}>
                            <OutlinedInput
                              type="number"
                              {...data.field}
                              id="promo-code-promoPriceAmount"
                              fullWidth
                              error={!!errors.promoPriceAmount}
                              onChange={(event) => {
                                data.field.onChange(+event.target.value);
                                setValue('maxDiscountPriceType', 'THB');
                                setValue('promoCoinProductUnit', 'UNIT');
                              }}
                              inputProps={{ min: '0', step: 'any' }}
                              disabled={!!isPromoCodeCoin}
                              onClick={(e) => {
                                const target = e.target as HTMLInputElement;
                                target.select();
                              }}
                            />
                          </Box>
                        </AppFormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Controller
                      name="promoPriceUnit"
                      control={control}
                      render={({ field }) => (
                        <AppFormControl error={!!errors.promoPriceUnit}>
                          <InputLabel htmlFor="promo-code-promoPriceUnit">
                            {t('promo-code:details.label.promoPriceUnit')}
                          </InputLabel>
                          <Select
                            id="promo-code-promoPriceUnit"
                            value={field.value}
                            onChange={field.onChange}
                            error={!!errors.promoPriceUnit}
                            disabled={!!isPromoCodeCoin}
                            fullWidth>
                            <MenuItem value="UNIT">บาท</MenuItem>
                            <MenuItem value="PERCENT">เปอร์เซ็นต์</MenuItem>
                          </Select>
                        </AppFormControl>
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <Controller
                      name="promoCoinProductAmount"
                      control={control}
                      render={(data) => (
                        <AppFormControl error={!!errors.promoCoinProductAmount}>
                          <InputLabel htmlFor="promo-code-promoCoinProductAmount">
                            {t('promo-code:details.label.promoCoinProductAmount')}
                          </InputLabel>
                          <Box style={{ position: 'relative' }}>
                            <OutlinedInput
                              type="number"
                              {...data.field}
                              id="promo-code-promoCoinProductAmount"
                              fullWidth
                              error={!!errors.promoCoinProductAmount}
                              onChange={(event) => {
                                data.field.onChange(+event.target.value);
                                setValue('maxDiscountPriceType', 'COIN');
                                setValue('promoPriceUnit', 'UNIT');
                              }}
                              inputProps={{ min: '0', step: 'any' }}
                              disabled={!!isPromoCodePrice}
                              onClick={(e) => {
                                const target = e.target as HTMLInputElement;
                                target.select();
                              }}
                            />
                          </Box>
                        </AppFormControl>
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <Controller
                      name="promoCoinProductUnit"
                      control={control}
                      render={({ field }) => (
                        <AppFormControl error={!!errors.promoCoinProductUnit}>
                          <InputLabel htmlFor="promo-code-promoCoinProductUnit">
                            {t('promo-code:details.label.promoCoinProductUnit')}
                          </InputLabel>
                          <Select
                            id="promo-code-promoCoinProductUnit"
                            value={field.value}
                            onChange={field.onChange}
                            error={!!errors.promoCoinProductUnit}
                            fullWidth
                            disabled={!!isPromoCodePrice}>
                            <MenuItem value="UNIT">เหรียญ</MenuItem>
                            <MenuItem value="PERCENT">เปอร์เซ็นต์</MenuItem>
                          </Select>
                        </AppFormControl>
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box mb={3}>
                <Grid container spacing={2} alignItems="flex-end">
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="maxDiscountPrice"
                      control={control}
                      rules={{
                        required: false,
                      }}
                      render={({ field }) => (
                        <AppFormControl error={!!errors.maxDiscountPrice}>
                          <InputLabel htmlFor="promo-code-maxDiscountPrice">
                            {t('promo-code:details.label.maxDiscountPriceForPercent')}
                          </InputLabel>
                          <Box style={{ position: 'relative' }}>
                            <OutlinedInput
                              type="number"
                              {...field}
                              id="promo-code-maxDiscountPrice"
                              fullWidth
                              error={!!errors.maxDiscountPrice}
                              inputProps={{ min: '0', step: 'any' }}
                              onChange={(event) => field.onChange(+event.target.value)}
                              disabled={isDisabled}
                            />
                          </Box>
                        </AppFormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Controller
                      name="maxDiscountPriceType"
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field }) => (
                        <AppFormControl error={!!errors.maxDiscountPriceType}>
                          <InputLabel htmlFor="promo-code-maxDiscountPriceType">
                            {t('promo-code:details.label.maxDiscountPriceType')}
                          </InputLabel>
                          <Select
                            id="promo-code-maxDiscountPriceType"
                            value={field.value}
                            onChange={field.onChange}
                            error={!!errors.maxDiscountPriceType}
                            fullWidth>
                            <MenuItem value="THB">บาท</MenuItem>
                            <MenuItem value="COIN">เหรียญ</MenuItem>
                          </Select>
                        </AppFormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Controller
                      name="availableFrom"
                      control={control}
                      render={({ field }) => (
                        <AppFormControl error={!!errors.availableFrom}>
                          <Box>
                            <Typography style={{ marginBottom: 6 }} variant="subtitle2">
                              {t('promo-code:details.label.availableFrom')}
                            </Typography>
                            <OutlinedInput
                              id="promo-code-availableFrom"
                              fullWidth
                              type="date"
                              defaultValue={field.value ? formatDate(new Date(field.value), 'YYYY-MM-dd') : null}
                              onChange={field.onChange}
                              error={!!errors.availableFrom}
                              disabled={!allowCreate}
                            />
                          </Box>
                        </AppFormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Controller
                      name="availableTo"
                      control={control}
                      render={({ field }) => (
                        <AppFormControl error={!!errors.availableTo}>
                          <Box>
                            <Typography style={{ marginBottom: 6 }} variant="subtitle2">
                              {t('promo-code:details.label.availableTo')}
                            </Typography>
                            <OutlinedInput
                              id="promo-code-availableTo"
                              fullWidth
                              type="date"
                              defaultValue={field.value ? formatDate(new Date(field.value), 'YYYY-MM-dd') : null}
                              onChange={field.onChange}
                              error={!!errors.availableTo}
                              disabled={!allowCreate}
                            />
                          </Box>
                        </AppFormControl>
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box mb={3}>
                <Grid container spacing={2} alignItems="flex-end">
                  <Grid item xs={12} sm={3}>
                    <Controller
                      name="applyToProductHashId"
                      control={control}
                      render={(data) => (
                        <AppFormControl error={!!errors.applyToProductHashId}>
                          <InputLabel htmlFor="promo-code-applyToProductHashId">
                            {t('promo-code:details.label.applyToProductHashId')}
                          </InputLabel>
                          <Box style={{ position: 'relative' }}>
                            <OutlinedInput
                              {...data.field}
                              id="promo-code-applyToProductHashId"
                              fullWidth
                              error={!!errors.applyToProductHashId}
                              disabled={!allowCreate}
                            />
                          </Box>
                        </AppFormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Controller
                      name="userOwnerHashId"
                      control={control}
                      render={(data) => (
                        <AppFormControl error={!!errors.userOwnerHashId}>
                          <InputLabel htmlFor="promo-code-userOwnerHashId">
                            {t('promo-code:details.label.userOwnerHashId')}
                          </InputLabel>
                          <Box style={{ position: 'relative' }}>
                            <OutlinedInput
                              {...data.field}
                              id="promo-code-userOwnerHashId"
                              fullWidth
                              error={!!errors.userOwnerHashId}
                              disabled={!allowCreate}
                            />
                          </Box>
                        </AppFormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="promoCode"
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={(data) => (
                        <AppFormControl error={!!errors.promoCode}>
                          <InputLabel htmlFor="promo-code-promoCode">
                            {t('promo-code:details.label.promoCode')} *
                          </InputLabel>

                          <Box style={{ position: 'relative' }}>
                            <OutlinedInput
                              {...data.field}
                              id="promo-code-promoCode"
                              fullWidth
                              error={!!errors.promoCode}
                              disabled={!allowCreate}
                              onFocus={codeFieldInput}
                            />
                          </Box>
                        </AppFormControl>
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box mb={3} display="flex" justifyContent="flex-end" className={classes.buttonGroups}>
                <AppButton type="submit" color="primary" disabled={!allowCreate}>
                  {t('common:button.ok')}
                </AppButton>
                <AppButton
                  variant="outlined"
                  color="default"
                  onClick={() => {
                    if (typeof onCloseClick === 'function') {
                      onCloseClick();
                    }
                  }}>
                  {t('common:button.close')}
                </AppButton>
              </Box>
            </Grid>
          </Grid>
        </form>

        <AppDialog
          title={t('common:dialog.title.confirm').toString()}
          open={isConfirmDialogOpen}
          onOkClick={onConfirmationSaveClick}
          onCancelClick={onConfirmationCancelClick}
          cancelButtonText={t('common:button.cancel').toString()}
          okButtonText={t('common:button.ok').toString()}
          okButtonColor="primary">
          <Box className={classes.confirmDialogBox}>
            <Box mb={3}>
              <Typography>{t('promo-code:dialogAddPromoCode.title')}</Typography>
            </Box>
          </Box>
        </AppDialog>
      </AppDialog>
    </>
  );
};

export default AddPromoCodeItemDialog;
