import React, { FC } from 'react';

import { Avatar, Box, Tooltip, Typography, makeStyles } from '@material-ui/core';
import { green, grey, indigo, red } from '@material-ui/core/colors';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import getFallbackShopType from '../../../../../helpers/get-fallback-shop-type';
import { getT2HSiteUrl } from '../../../../../helpers/utils';
import i18n from '../../../../../i18n';
import { UserDataTC } from '../../../../../models';
import UserStatusBadge from '../UserStatusBadge';
import DataDisplayBlock from './DataDisplayBlock';
import useTypographyStyles from '../../../../../theme/typography.style';

export interface BasicInfoProps {
  currentUser: UserDataTC;
  currentCoinBalance: number;
  isSeller?: boolean;
  isMembership?: boolean;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'unset',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '180px 1fr',
      gridTemplateRows: 'unset',
      placeContent: 'center',
    },
  },
  avatarContainer: {
    [theme.breakpoints.up('md')]: {
      paddingTop: '16px',
    },
  },
  avatar: {
    width: '120px',
    height: '120px',
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
    border: 'solid 2px',
    borderColor: grey[300],
    [theme.breakpoints.up('md')]: {
      width: '160px',
      height: '160px',
    },
  },
  commonInfoContainer: {
    marginBottom: '20px',
    [theme.breakpoints.up('md')]: {
      paddingLeft: '24px',
    },
  },
  personalInfoContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    [theme.breakpoints.up('lg')]: {
      flexFlow: 'row nowrap',
      '& > div:first-of-type': {
        flex: '1 0 auto',
      },
    },
  },
  socialLinkContainer: {
    marginTop: '20px',
    display: 'flex',
    flexFlow: 'column wrap',
    [theme.breakpoints.up('md')]: {
      flexFlow: 'row wrap',
      '& > *': {
        flex: '1 0 50%',
      },
    },
    [theme.breakpoints.up('lg')]: {
      '& > *': {
        flex: '1 0 50%',
      },
    },
  },
  displayName: {
    fontWeight: 700,
    fontSize: '24px',
    marginBottom: '6px',
    marginTop: '20px',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      marginTop: 'unset',
      textAlign: 'unset',
    },
    '&:before': {
      content: 'attr(data-title)',
      display: 'block',
      fontSize: '16px',
      color: grey[700],
      fontWeight: 400,
    },
  },
  statusBadgeContainer: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '10px',
    '& > *': {
      marginRight: '6px',
      marginBottom: '6px',
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'unset',
    },
  },
  basicInfoContainer: {
    display: 'inline-flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'unset',
    },
  },
  shortInfoContainer: {
    display: 'inline-flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: '16px',
    '&.clickable:hover': {
      cursor: 'pointer',
      color: indigo[500],
      '&:before': {
        color: grey[700],
      },
    },
    '&.full-block': {
      display: 'flex',
    },
  },
  commonInfo: {
    fontWeight: 600,
    '&:before': {
      content: 'attr(data-title)',
      fontWeight: 400,
      fontSize: '14px',
      color: grey[700],
      display: 'block',
    },
    '&.clickable:hover': {
      cursor: 'pointer',
      color: indigo[500],
      '&:before': {
        color: grey[700],
      },
    },
    '& ~ .open-link': {
      display: 'flex',
      marginLeft: '6px',
      alignSelf: 'flex-end',
    },
    '&.text-lg': {
      fontSize: '18px',
    },
    '& .leading-icon': {
      display: 'inline-flex',
      marginRight: '8px',
    },
  },
  usernameInfo: {
    fontWeight: 600,
    '&:before': {
      content: 'attr(data-title)',
      fontWeight: 400,
    },
    '&:hover': {
      cursor: 'pointer',
      color: indigo[500],
      '&:before': {
        color: 'initial',
      },
    },
    '& ~ .open-link': {
      display: 'flex',
      marginLeft: '4px',
    },
  },
  subTabContainer: {
    borderBottom: 'solid 1px',
    borderBottomColor: grey[300],
    '& .Mui-selected': {
      fontWeight: 700,
    },
  },
  coinBalanceBox: {
    position: 'relative',
    display: 'inline-flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    width: 'min(100%, 240px)',
    borderRadius: '8px',
    backgroundColor: grey[100],
    padding: '10px',
    fontSize: '20px',
    fontWeight: 600,
    marginTop: '20px',
    '& > .icon': {
      display: 'block',
      marginRight: '8px',
    },
    '&:before': {
      content: 'attr(data-title)',
      position: 'absolute',
      top: 0,
      left: 0,
      transform: 'translateY(-100%)',
      fontSize: '14px',
      fontWeight: 600,
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '10px',
    },
  },
  userMenu: {
    backgroundColor: grey[200],
  },
  userMenuItem: {
    backgroundColor: '#ffffff',
    '&.danger': {
      color: red[500],
    },
    '&.link': {
      color: indigo[500],
    },
  },
  userMenuContainer: {
    position: 'relative',
    top: 0,
    right: 0,
    '& .button-text': {
      display: 'none',
    },
    '& .MuiButton-startIcon': {
      marginRight: 0,
    },
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      transform: 'translate3d(16px, -16px, 0)',
    },
    [theme.breakpoints.up('lg')]: {
      '& .button-text': {
        display: 'inline-block',
      },
      '& .MuiButton-startIcon': {
        marginRight: '8px',
      },
    },
  },
  commonUserDataContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    '& > .menu-container': {
      order: 1,
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'flex-end',
      marginBottom: '24px',
    },
    '& > .common-data': {
      order: 2,
    },
    [theme.breakpoints.up('md')]: {
      '& > .menu-container': {
        order: 2,
        paddingTop: 0,
        paddingBottom: 0,
        marginBottom: 0,
      },
      '& > .common-data': {
        order: 1,
        marginTop: '20px',
      },
    },
  },
}));

const iconCoin = '../../static/images/icons/icon-coin.png';

const BasicInfo: FC<BasicInfoProps> = ({ currentUser, currentCoinBalance, isSeller, isMembership }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const typoClasses = useTypographyStyles();
  const dateDisplayFormat = i18n.language === 'th' ? 'D MMM BBBB' : 'D/MM/YY';

  return (
    <>
      <Box className={classes.avatarContainer}>
        <Avatar alt={currentUser.username} src={currentUser.profileImage?.fullUrl} className={classes.avatar} />
      </Box>
      <Box className={classes.commonInfoContainer}>
        <Typography
          variant="body1"
          component="h4"
          className={classes.displayName}
          data-title={t('user:common.fields.displayName')}>
          {currentUser.displayName}
        </Typography>

        {/* User role, membership, workflow badge */}
        <Box className={classes.statusBadgeContainer}>
          <UserStatusBadge status={currentUser.userRoleStateWorkflow ?? ''} />
          <UserStatusBadge status={currentUser.buyerSellerVerificationWorkflow} />
          <UserStatusBadge status={currentUser.userGeneralWorkflow} />
        </Box>

        <div>
          {/* Phone and E-mail */}
          <Box className={classes.basicInfoContainer}>
            <DataDisplayBlock
              marginRight="60px"
              minWidth="116px"
              label={t('user:common.fields.mobile')}
              allowCopy={!!currentUser.mobilePhone}
              copySuccessText="คัดลอกหมายเลขโทรศัพท์แล้ว"
              trailingContent={
                currentUser.isMobileVerified ? (
                  <Tooltip title="ยืนยันแล้ว" aria-label="ยืนยันแล้ว" placement="top" arrow>
                    <CheckCircleRoundedIcon
                      fontSize="small"
                      style={{ color: green[500], marginLeft: '4px', transform: 'translateY(12px)' }}
                    />
                  </Tooltip>
                ) : null
              }>
              {currentUser.mobilePhone ?? '-'}
            </DataDisplayBlock>

            <DataDisplayBlock
              marginRight="60px"
              label={t('user:common.fields.email')}
              allowCopy={!!currentUser.email}
              copySuccessText="คัดลอกอีเมลแล้ว"
              trailingContent={
                currentUser.isEmailVerified ? (
                  <Tooltip title="ยืนยันแล้ว" aria-label="ยืนยันแล้ว" placement="top" arrow>
                    <CheckCircleRoundedIcon
                      fontSize="small"
                      style={{ color: green[500], marginLeft: '4px', transform: 'translateY(12px)' }}
                    />
                  </Tooltip>
                ) : null
              }>
              {currentUser.email ?? '-'}
            </DataDisplayBlock>

            <DataDisplayBlock
              marginRight="60px"
              label={t('user:common.fields.hashId')}
              allowCopy
              copySuccessText="คัดลอก Hash ID ผู้ใช้แล้ว"
              newTabLink={getT2HSiteUrl(`/u/${currentUser.userHashId}`)}
              newTabTooltipText="เปิดหน้าผู้ใช้บนเว็บไซต์ในแท็บใหม่">
              {currentUser.userHashId}
            </DataDisplayBlock>

            <Box className={classes.shortInfoContainer} marginRight="60px">
              <Typography
                className={clsx(classes.commonInfo, 'text-lg')}
                component="div"
                data-title={t('user:coin.coinBalance')}>
                <img
                  src={iconCoin}
                  width={24}
                  height={24}
                  alt="coin"
                  className="leading-icon"
                  style={{ transform: 'translateY(4px)' }}
                />
                <Box
                  component="span"
                  height="24px"
                  padding="0 10px"
                  borderRadius="12px"
                  bgcolor={grey[200]}
                  minWidth="100px"
                  display="inline-flex"
                  justifyContent="flex-end"
                  alignItems="center">
                  {currentCoinBalance.toLocaleString()}
                </Box>
              </Typography>
            </Box>
          </Box>

          {/* Misc Info e.g. join date, membership date */}
          <Box display="flex" flexDirection="row" flexWrap="wrap">
            <DataDisplayBlock marginRight="60px" minWidth="116px" label={t('user:details.label.createdAt')}>
              {currentUser.createdAt ? dayjs(currentUser.createdAt).format(`${dateDisplayFormat}`) : null}
            </DataDisplayBlock>

            {isSeller && (
              <>
                <DataDisplayBlock marginRight="60px" label={t('user:details.label.sellerVerifiedAt')}>
                  {currentUser.sellerVerifiedAt
                    ? dayjs(currentUser.sellerVerifiedAt).format(`${dateDisplayFormat}`)
                    : '-'}
                </DataDisplayBlock>

                {isMembership && (
                  <>
                    <DataDisplayBlock marginRight="60px" label={t('user:details.label.membershipStartDate')}>
                      {currentUser.membershipStartedAt
                        ? dayjs(currentUser.membershipStartedAt).format(`${dateDisplayFormat}`)
                        : '-'}
                    </DataDisplayBlock>
                    <DataDisplayBlock marginRight="60px" label={t('user:details.label.membershipEndDate')}>
                      {currentUser.membershipEndAt
                        ? dayjs(currentUser.membershipEndAt).format(`${dateDisplayFormat}`)
                        : '-'}
                    </DataDisplayBlock>
                  </>
                )}
              </>
            )}
          </Box>

          {/* hash id, username, balance coin, user email subscription */}
          <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="start">
            {!!currentUser.username && (
              <DataDisplayBlock
                marginRight="60px"
                minWidth="116px"
                label={t('user:common.fields.username')}
                allowCopy
                copySuccessText="คัดลอกชื่อผู้ใช้แล้ว">
                {currentUser.username}
              </DataDisplayBlock>
            )}

            <DataDisplayBlock marginRight="40px" minWidth="100px" label={t('user:details.label.shopType')}>
              {t(`user:details.shopType.${currentUser.shopType || getFallbackShopType(currentUser)}`)}
            </DataDisplayBlock>

            <DataDisplayBlock minWidth="100px" label={t('user:details.label.newsletterSubscription')}>
              {currentUser.isNotificationEmailSubScripted || currentUser.isNotificationEmailTpg ? (
                <Box mt={1}>
                  {currentUser.isNotificationEmailSubScripted && (
                    <Typography className={typoClasses.textWeightBold}>
                      - {t('user:common.fields.featuredProductsOfWeek')}
                    </Typography>
                  )}
                  {currentUser.isNotificationEmailTpg && (
                    <Typography className={typoClasses.textWeightBold}>
                      - {t('user:common.fields.emailTpgDesc')}
                    </Typography>
                  )}
                </Box>
              ) : (
                '-'
              )}
            </DataDisplayBlock>
          </Box>
        </div>
      </Box>
    </>
  );
};

export default BasicInfo;
