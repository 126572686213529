/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, ReactNode, useContext, useEffect, useState } from 'react';

import { CircularProgress, InputLabel, MenuItem, Select, SelectProps, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { MasterDataContext } from '../../../../context/MasterDataContext';
import { AppMasterDataContextProps } from '../../../../models';
import useTypographyStyles from '../../../../theme/typography.style';
import AppFormControl, { AppFormControlProps } from '../../../ui/AppFormControl';

export type MasterDataDropdownProps = SelectProps & {
  dataKey: keyof AppMasterDataContextProps;
  itemLabelPropName: string;
  itemValuePropName: string;
  renderAsText?: boolean;
  noDataText?: string;
  inputLabel?: string;
  formControlProps?: AppFormControlProps;
  allowEmpty?: boolean;
  emptyItemLabel?: string;
  menuItemComponent?: (item: any) => ReactNode;
};

const MasterDataDropdown: FC<MasterDataDropdownProps> = (props) => {
  const {
    itemLabelPropName,
    itemValuePropName,
    dataKey,
    inputLabel,
    renderAsText = false,
    noDataText = '-',
    value = '',
    formControlProps,
    id = 'ddl-master-data',
    allowEmpty = false,
    emptyItemLabel = 'common:message.unspecified',
    menuItemComponent,
    ...rest
  } = props;
  const { getData } = useContext(MasterDataContext);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [listItem, setListItem] = useState<any[]>([]);
  const typoClasses = useTypographyStyles();

  useEffect((): any => {
    let isSubscribed = true;

    getData<any[]>(dataKey)
      .then((data) => (isSubscribed ? setListItem(data || []) : null))
      .finally(() => (isSubscribed ? setIsLoading(false) : null));

    return () => (isSubscribed = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const DropdownControl = () => {
    const matchedItem = listItem.find((item) => item[itemValuePropName] === value);

    return renderAsText ? (
      <Typography>{matchedItem ? matchedItem[itemLabelPropName] : noDataText}</Typography>
    ) : (
      <AppFormControl {...formControlProps}>
        {!!inputLabel && <InputLabel id={id}>{t(inputLabel)}</InputLabel>}
        <Select value={value || ''} id={id} fullWidth {...rest}>
          {allowEmpty && (
            <MenuItem value="">
              <Typography component="em" className={typoClasses.greyText}>
                {t(emptyItemLabel)}
              </Typography>
            </MenuItem>
          )}
          {listItem.map((item) => (
            <MenuItem key={item[itemValuePropName]} value={item[itemValuePropName]}>
              {typeof menuItemComponent === 'function' ? menuItemComponent(item) : item[itemLabelPropName]}
            </MenuItem>
          ))}
        </Select>
      </AppFormControl>
    );
  };

  const DropdownRenderer = () => (listItem.length === 0 ? null : <DropdownControl />);

  return <>{!isLoading ? <DropdownRenderer /> : <CircularProgress size={22} />}</>;
};

export default MasterDataDropdown;
