/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable import/prefer-default-export */
import { Message } from '../models/message';

export const sortMessage = (first: Message, second: Message) => {
  if (first.createdAt.isAfter(second.createdAt)) {
    return 1;
  }
  if (second.createdAt.isAfter(first.createdAt)) {
    return -1;
  }

  return 0;
};
