import React, { FC } from 'react';

import { Chip, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { SellerApplicationWorkflow } from '../../../../../models';

export type SellerApplicationStatusBadgeProps = {
  status: SellerApplicationWorkflow;
  isReverifyingSeller?: boolean;
};

const useStyles = makeStyles((theme) => ({
  statusChip: {
    height: '26px',
    lineHeight: '26px',
  },
  stateStartSellerApp: {
    backgroundColor: theme.customPalette.appWorkflow.stateStartSellerApp.main,
    color: theme.customPalette.appWorkflow.stateStartSellerApp.contrastText,
  },
  stateStartFirstCreate: {
    backgroundColor: theme.customPalette.appWorkflow.stateStartFirstCreate.main,
    color: theme.customPalette.appWorkflow.stateStartFirstCreate.contrastText,
  },
  stateWaitingForSellerApproval: {
    backgroundColor: theme.customPalette.appWorkflow.stateWaitingForSellerApproval.main,
    color: theme.customPalette.appWorkflow.stateWaitingForSellerApproval.contrastText,
  },
  stateSellerApproval: {
    backgroundColor: theme.customPalette.appWorkflow.stateSellerApproval.main,
    color: theme.customPalette.appWorkflow.stateSellerApproval.contrastText,
  },
  stateWaitingForEditingApplication: {
    backgroundColor: theme.customPalette.appWorkflow.stateWaitingForEditingApplication.main,
    color: theme.customPalette.appWorkflow.stateWaitingForEditingApplication.contrastText,
  },
  stateRejectedApplication: {
    backgroundColor: theme.customPalette.appWorkflow.stateRejectedApplication.main,
    color: theme.customPalette.appWorkflow.stateRejectedApplication.contrastText,
  },
  stateBlacklistApplication: {
    backgroundColor: theme.customPalette.appWorkflow.stateBlacklistApplication.main,
    color: theme.customPalette.appWorkflow.stateBlacklistApplication.contrastText,
  },
  stateDeletedApplication: {
    backgroundColor: theme.customPalette.appWorkflow.stateDeletedApplication.main,
    color: theme.customPalette.appWorkflow.stateDeletedApplication.contrastText,
  },
}));

const SellerApplicationStatusBadge: FC<SellerApplicationStatusBadgeProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { status, isReverifyingSeller } = props;

  return (
    <Chip
      classes={{ root: classes.statusChip }}
      className={clsx({
        [classes.stateStartSellerApp]: status === SellerApplicationWorkflow.StartSellerApp,
        [classes.stateStartFirstCreate]: status === SellerApplicationWorkflow.FirstCreate,
        [classes.stateWaitingForSellerApproval]: status === SellerApplicationWorkflow.WaitingForSellerApproval,
        [classes.stateSellerApproval]: status === SellerApplicationWorkflow.SellerApproval,
        [classes.stateWaitingForEditingApplication]: status === SellerApplicationWorkflow.WaitingForEditingApplication,
        [classes.stateRejectedApplication]: status === SellerApplicationWorkflow.RejectedApplication,
        [classes.stateBlacklistApplication]: status === SellerApplicationWorkflow.BlacklistApplication,
        [classes.stateDeletedApplication]: status === SellerApplicationWorkflow.DeletedApplication,
      })}
      label={
        isReverifyingSeller
          ? t('seller-applications:v3.details.sellerReverifying')
          : t(`seller-applications:v3.list.search.workflow.${status}`).toString()
      }
    />
  );
};

export default SellerApplicationStatusBadge;
