import React, { FC } from 'react';

import orderApproveRoutes from './route.config';
import ModuleMainPage from '../../../components/templates/ModuleMainPage';

const OrderApproveMain: FC = () => (
  <ModuleMainPage pageTitle="common:title.orderApprove" routeFunctions={orderApproveRoutes} />
);

export default OrderApproveMain;
