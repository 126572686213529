import React, { createContext, FC, useEffect } from 'react';

import useMainModule from '../hooks/useMainModule';
import { MainModuleContextProps, RouteConfig } from '../models';

const defaultValue: MainModuleContextProps = {
  routeConfig: [],
  currentRoute: undefined,
  setActiveRoute: () => null,
  sharedData: null,
  setSharedData: () => null,
};

const MainModuleContext = createContext<MainModuleContextProps>(defaultValue);

export type MainModuleProviderProps = {
  routeConfig: RouteConfig[];
  onRouteChange?: (route: RouteConfig | undefined) => void;
};

const MainModuleProvider: FC<MainModuleProviderProps> = (props) => {
  const { children, routeConfig, onRouteChange } = props;
  const { Provider } = MainModuleContext;
  const { currentRoute, setActiveRoute, sharedData, setSharedData } = useMainModule(routeConfig);

  useEffect(() => {
    if (typeof onRouteChange === 'function') {
      onRouteChange(currentRoute);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoute]);

  return (
    <Provider value={{ routeConfig, currentRoute, setActiveRoute, sharedData, setSharedData }}>{children}</Provider>
  );
};

export { MainModuleContext, MainModuleProvider };
