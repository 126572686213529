import React, { FC, useCallback, useContext, useState } from 'react';

import { useTranslation } from 'react-i18next';
import useDeepCompareEffect from 'use-deep-compare-effect';

import UserListSearchForm from '../../../components/partials/v3/users/UserListSearchForm';
import UserListTable from '../../../components/partials/v3/users/UserListTable';
import { AppGlobalUiContext } from '../../../context/AppGlobalUiContext';
import { SearchFilterContext } from '../../../context/SearchFilterContext';
import { defaultRowsPerPage } from '../../../helpers/constants';
import { getAppUrl } from '../../../helpers/utils';
import { AppTableConditions, UserDataTC, UserListSearchFormDataTC, UserListSearchParamsTC } from '../../../models';
import { getUserList } from '../../../services/v3/user';

type UserListPageProps = unknown;

const searchFilterContextKey = 'usersTc';

const UserList: FC<UserListPageProps> = () => {
  const { t } = useTranslation();
  const { showSnackbar } = useContext(AppGlobalUiContext);
  const [userList, setUserList] = useState<UserDataTC[]>([]);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [totalUsers, setTotalUsers] = useState<number>(0);

  const urlParams = new URLSearchParams(window.location.search);
  const initialSearchFilter: Partial<UserListSearchFormDataTC> = {};
  if (urlParams.get('roles')) {
    initialSearchFilter.filteringRoleStateWorkflow = (urlParams.get('roles') as string)
      .split(',')
      .map((value) => decodeURIComponent(value));
  }
  if (urlParams.get('searchField')) {
    initialSearchFilter.searchField = decodeURIComponent(urlParams.get('searchField') as string);
  }
  if (urlParams.get('searchKeyword')) {
    initialSearchFilter.searchKeyword = decodeURIComponent(urlParams.get('searchKeyword') as string);
  }

  const [searchFilters, setSearchFilters] = useState<UserListSearchFormDataTC>({
    searchField: initialSearchFilter?.searchField || 'displayName',
    searchKeyword: initialSearchFilter?.searchKeyword || '',
    filteringStatus: initialSearchFilter?.filteringStatus || [],
    filteringGeneralWorkflow: initialSearchFilter?.filteringGeneralWorkflow || [],
    filteringRoleStateWorkflow: initialSearchFilter?.filteringRoleStateWorkflow || [],
    filteringNationality: initialSearchFilter.filteringNationality || [],
  });

  // start filling default search filters (either from the one we saved in context or fresh conditions)
  const { currentFilters: ctxFilters, setFilters } = useContext(SearchFilterContext);
  const currentFilters: UserListSearchParamsTC = ctxFilters ? ctxFilters[searchFilterContextKey] : undefined;
  const [tableConditions, setTableConditions] = useState<AppTableConditions>({
    page: currentFilters?.page || 0,
    rowsPerPage: currentFilters?.rowsPerPage || defaultRowsPerPage,
    sortColumn: currentFilters?.sortColumn || 'createdAt',
    sortDirection: currentFilters?.sortDirection || 'desc',
  });

  const getCurrentSearchParam = (): UserListSearchParamsTC => ({
    page: tableConditions.page || 0,
    rowsPerPage: tableConditions.rowsPerPage || defaultRowsPerPage,
    sortColumn: tableConditions.sortColumn || 'createdAt',
    sortDirection: tableConditions.sortDirection || 'desc',
    searchField: searchFilters.searchField,
    searchKeyword: searchFilters.searchKeyword,
    filteringStatus: searchFilters.filteringStatus,
    filteringGeneralWorkflow: searchFilters.filteringGeneralWorkflow,
    filteringRoleStateWorkflow: searchFilters.filteringRoleStateWorkflow,
    filteringNationality: searchFilters.filteringNationality.length <= 1 ? searchFilters.filteringNationality : ['ALL'],
  });

  const requestUserList = async (isPageSubscribed = true): Promise<boolean> => {
    if (isPageSubscribed) {
      setIsLoadingData(true);

      const searchConditions = getCurrentSearchParam();

      setFilters(searchFilterContextKey, searchConditions);

      const result = await getUserList(searchConditions);

      setIsLoadingData(false);

      if (result.data) {
        setUserList(result.data.contents);
        setTotalUsers(result.data?.page?.totalElements || 0);
        return Promise.resolve(true);
      }

      showSnackbar(t(result.error as string).toString(), 'error');
      return Promise.reject(result.error);
    }

    return false;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useDeepCompareEffect((): any => {
    let isSubscribed = true;

    requestUserList(isSubscribed);

    return () => (isSubscribed = false);
  }, [tableConditions, searchFilters]);

  const tableConditionsChangedHandler = (values: AppTableConditions) => {
    setTableConditions(values);
  };

  const onSearchFormSubmit = useCallback(
    (filters: UserListSearchFormDataTC) => {
      if (isLoadingData) {
        showSnackbar(t('common.message.pleaseWaitForDataToFinish').toString(), 'warning');
        return;
      }

      if (tableConditions.page !== 0) {
        tableConditions.page = 0;
      }

      const params: Record<string, string> = {};
      if (filters.searchField.trim() && filters.searchKeyword.trim()) {
        params.searchField = encodeURIComponent(filters.searchField.trim());
        params.searchKeyword = encodeURIComponent(filters.searchKeyword.trim());
      }
      if (filters.filteringRoleStateWorkflow.length > 0) {
        params.roles = filters.filteringRoleStateWorkflow.map((value) => encodeURIComponent(value)).join(',');
      }

      let queryParamString = '';
      if (Object.keys(params).length > 0) {
        const queryParamList = Object.keys(params).reduce((acc: string[], key) => {
          acc.push(`${key}=${params[key]}`);
          return acc;
        }, []);

        queryParamString = `?${queryParamList.join('&')}`;
      }
      const currentAppUrl = getAppUrl(`/v3/users${queryParamString}`);
      window.history.pushState({ path: currentAppUrl }, '', currentAppUrl);

      setSearchFilters(filters);
    },
    [isLoadingData, showSnackbar, t, tableConditions],
  );

  return (
    <>
      <UserListSearchForm
        isLoadingData={isLoadingData}
        currentFilters={searchFilters}
        onSearchFormSubmit={onSearchFormSubmit}
      />
      <UserListTable
        currentConditions={tableConditions}
        userList={userList}
        totalUsers={totalUsers}
        isLoadingData={isLoadingData}
        onTableConditionsChanged={tableConditionsChangedHandler}
      />
    </>
  );
};

export default UserList;
