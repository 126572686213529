import React, { FC, useCallback, useState } from 'react';

import { Avatar, Box, Container, makeStyles, TableRow, Typography } from '@material-ui/core';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import { getAppUrl } from '../../../../../helpers/utils';
import i18n from '../../../../../i18n';
import { AppTableConditions, TableColumn, SellerApplicationTC, SellerApplicationWorkflow } from '../../../../../models';
import useAppContainerStyles from '../../../../../theme/container.style';
import useAppTableStyles from '../../../../../theme/table.style';
import AppTableCell from '../../../../ui/AppTableCell';
import AppTableList from '../../../../ui/AppTableList';
import SellerApplicationStatusBadge from '../SellerApplicationStatusBadge';

export type SellerListTableProps = {
  currentConditions: AppTableConditions;
  totalSeller: number;
  isLoadingData: boolean;
  sellerAppList: SellerApplicationTC[];
  onTableConditionsChanged: (values: AppTableConditions) => void;
};

const useStyles = makeStyles((theme) => ({
  dateDisplayText: {
    lineHeight: 1.3,
  },
  userAvatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    border: `solid 1px ${theme.palette.grey[300]}`,
    boxSizing: 'border-box',
  },
  userNameText: {
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
  },
  selectedRow: {
    backgroundColor: theme.palette.primary.main,
    '&:nth-of-type(2n+1)': {
      backgroundColor: theme.palette.primary.main,
    },
    '& td': {
      color: theme.palette.primary.contrastText,
    },
    '&:not(.readonly):hover': {
      backgroundColor: theme.palette.primary.main,
      '& td': {
        color: theme.palette.primary.contrastText,
      },
    },
  },
}));

const SellerApplicationListTable: FC<SellerListTableProps> = (props) => {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const { totalSeller, isLoadingData, currentConditions, sellerAppList, onTableConditionsChanged } = props;
  const containerClasses = useAppContainerStyles();
  const tableClasses = useAppTableStyles();
  const classes = useStyles();
  const [tableConditions, setTableConditions] = useState<AppTableConditions>(currentConditions);
  const dateDisplayFormat = i18n.language === 'th' ? 'D MMM BBBB' : 'D MMM YYYY';
  const [selectedAppId, setSelectedAppId] = useState<string | null>(null);

  const sellerAppTableColumns: TableColumn[] = [
    { name: 'status', label: 'seller-applications:v3.columns.status', width: 180, unsortable: true },
    { name: 'appHashId', label: 'seller-applications:v3.columns.appHashId', width: 120, unsortable: true },
    { name: 'userHashId', label: 'seller-applications:v3.columns.userHashId', width: 120, unsortable: true },
    { name: 'displayName', label: 'seller-applications:v3.columns.displayName', width: 240, unsortable: true },
    { name: 'email', label: 'seller-applications:v3.columns.email', width: 220, unsortable: true },
    { name: 'mobile', label: 'seller-applications:v3.columns.mobile', width: 160, unsortable: true },
    { name: 'shopType', label: 'seller-applications:v3.columns.shopType', width: 140, unsortable: true },
    { name: 'createdSellerAt', label: 'seller-applications:v3.columns.createdSellerAt', width: 160, unsortable: true },
    { name: 'updatedAt', label: 'seller-applications:v3.columns.updatedAt', width: 140, unsortable: true },
    { name: 'sellerVerifiedAt', label: 'seller-applications:v3.columns.approvedAt', width: 160, unsortable: true },
    { name: 'createAt', label: 'seller-applications:v3.columns.firstCreatedAt', width: 190, unsortable: true },
  ];

  const onTableConditionChangedHandler = useCallback(
    (values: AppTableConditions) => {
      if (JSON.stringify(values) !== JSON.stringify(tableConditions)) {
        setTableConditions(values);
        onTableConditionsChanged(values);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableConditions],
  );

  const openSellerAppDetailPage = useCallback(
    (sellerApplication: SellerApplicationTC) => {
      setSelectedAppId(sellerApplication.sellerApplication.hashId);
      window.open(getAppUrl(`${url}/${sellerApplication.sellerApplication.hashId}`), '_blank');
    },
    [url],
  );

  return (
    <>
      <Container className={containerClasses.container}>
        <Box className={tableClasses.tableContainer}>
          {!isLoadingData && (
            <Typography
              variant="subtitle2"
              color="textSecondary"
              style={{ marginBottom: '8px', marginRight: '8px', textAlign: 'right' }}>
              {t('common:list.resultTotal', { fieldName: t(totalSeller.toString() || '0') })}
            </Typography>
          )}

          <AppTableList
            {...tableConditions}
            aria-label="seller-application-list"
            columns={sellerAppTableColumns}
            totalDataCount={totalSeller}
            hasPagination
            onTableConditionChanged={onTableConditionChangedHandler}
            isLoading={isLoadingData}
            tableBodyContent={
              sellerAppList.length > 0 && (
                <>
                  {sellerAppList.map((app: SellerApplicationTC) => (
                    <TableRow
                      hover
                      key={app.sellerApplication.hashId}
                      classes={{
                        root: clsx({
                          [tableClasses.tableRow]: true,
                          [tableClasses.clickableTableRow]: true,
                          [classes.selectedRow]: selectedAppId === app.sellerApplication.hashId,
                        }),
                      }}
                      onClick={() => {
                        openSellerAppDetailPage(app);
                      }}>
                      <AppTableCell>
                        <SellerApplicationStatusBadge status={app.sellerApplication.sellerApplicationWorkflow} />
                      </AppTableCell>
                      <AppTableCell>
                        {app.sellerApplication.hashId ? `${app.sellerApplication.hashId}` : '-'}
                      </AppTableCell>
                      <AppTableCell>{app.user.userHashId ? `${app.user.userHashId}` : '-'}</AppTableCell>
                      <AppTableCell>
                        <Box display="flex" flexDirection="row" flexWrap="nowrap" alignItems="center">
                          <Avatar alt="sss" src={app.user.profileImage?.fullUrl} className={classes.userAvatar} />
                          <Box component="span" pl={1} flexGrow={1} minWidth={0} className={classes.userNameText}>
                            <Typography
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                marginRight: '4px',
                              }}>
                              {app.user.displayName ? `${app.user.displayName}` : '-'}
                            </Typography>
                          </Box>
                        </Box>
                      </AppTableCell>

                      <AppTableCell>{app.user.email ? `${app.user.email}` : '-'}</AppTableCell>
                      <AppTableCell>{app.user.mobilePhone ? `${app.user.mobilePhone}` : '-'}</AppTableCell>
                      <AppTableCell>
                        {app.sellerApplication.shopType
                          ? t(`user:details.shopType.${app.sellerApplication.shopType}`)
                          : '-'}
                      </AppTableCell>

                      <AppTableCell>
                        {dayjs(app.sellerApplication.createdAt).format(`${dateDisplayFormat}`)}
                        <br />
                        {dayjs(app.sellerApplication.createdAt).format(`HH:mm:ss`)}
                      </AppTableCell>

                      <AppTableCell>
                        {dayjs(app.sellerApplication.updatedAt).format(`${dateDisplayFormat}`)}
                        <br />
                        {dayjs(app.sellerApplication.updatedAt).format(`HH:mm:ss`)}
                      </AppTableCell>

                      <AppTableCell>
                        {app.sellerApplication.sellerApplicationWorkflow ===
                        SellerApplicationWorkflow.SellerApproval ? (
                          <Box>
                            {dayjs(app.sellerApplication.updatedAt).format(`${dateDisplayFormat}`)}
                            <br />
                            {dayjs(app.sellerApplication.updatedAt).format(`HH:mm:ss`)}
                          </Box>
                        ) : (
                          '-'
                        )}
                      </AppTableCell>

                      <AppTableCell>
                        {dayjs(app.user.createdAt).format(`${dateDisplayFormat}`)}
                        <br />
                        {dayjs(app.user.createdAt).format(`HH:mm:ss`)}
                      </AppTableCell>
                    </TableRow>
                  ))}
                </>
              )
            }
          />
        </Box>
      </Container>
    </>
  );
};

export default SellerApplicationListTable;
