import React, { FC, ReactNode } from 'react';

import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

type DataInfoBlockProps = {
  label: string;
  data: ReactNode;
  endAdornment?: ReactNode;
  noWrapText?: boolean;
  noNewLine?: boolean;
};

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  profileImageCol: {
    [theme.breakpoints.up('md')]: {
      flexBasis: '160px',
    },
  },
  profileImage: {
    width: '112px',
    height: '112px',
  },
  flexBreak: {
    flexBasis: '100%',
  },
  titleLable: {
    [theme.breakpoints.up('md')]: {
      flexBasis: '190px',
    },
  },
  titleBlock: {
    width: '190px',
    fontWeight: 'bold',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(1),
      paddingLeft: 0,
    },
  },
  dataBlock: {
    wordBreak: 'break-word',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(1),
    },
  },
  dataText: {
    marginRight: theme.spacing(1),
    maxWidth: 'calc(100% - 8px)',
    whiteSpace: 'unset',
  },
  dataTextLink: {
    maxWidth: '100%',
    wordWrap: 'break-word',
  },
  numberInput: {
    MozAppearance: 'none',
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
  previewImage: {
    maxWidth: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
}));

const DataBlock: FC<DataInfoBlockProps> = (props) => {
  const { t } = useTranslation();
  const { label, data, endAdornment = undefined, noWrapText = true, noNewLine = false } = props;
  const classes = useStyles();

  return (
    <Grid container>
      <div className={classes.flexBreak} />
      <Grid item xs={noNewLine ? 5 : 12} classes={{ root: classes.titleLable }}>
        <Box className={classes.titleBlock}>{`${t(label)} :`}</Box>
      </Grid>
      <Grid item xs={noNewLine ? 7 : 12} md zeroMinWidth>
        <Box display="inline-block" alignItems="center" className={classes.dataBlock}>
          <Typography component="span" noWrap={noWrapText}>
            {data || '-'}
          </Typography>
          {endAdornment}
        </Box>
      </Grid>
    </Grid>
  );
};

export default DataBlock;
