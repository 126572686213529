import React, { FC, useContext, useEffect } from 'react';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { AppGlobalUiContext } from '../../context/AppGlobalUiContext';

export type LayoutProps = {
  pageTitle?: string;
  metaTitle?: string;
};

const Layout: FC<LayoutProps> = (props) => {
  const { pageTitle, metaTitle, children } = props;
  const { t } = useTranslation();
  const { appBarTitle, setAppBarTitle } = useContext(AppGlobalUiContext);
  const layoutPageTitle = pageTitle || appBarTitle || metaTitle || 'common:title.default';

  useEffect(() => {
    setAppBarTitle(pageTitle || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageTitle]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${process.env.REACT_APP_APP_NAME} - ${t(layoutPageTitle)}`}</title>
      </Helmet>
      {children}
    </>
  );
};

export default Layout;
