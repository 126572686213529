import React, { ChangeEvent, FC, useContext, useEffect, useRef, useState } from 'react';

import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  makeStyles,
  OutlinedInput,
  OutlinedInputProps,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import { useTranslation } from 'react-i18next';

import { AppGlobalUiContext } from '../../../context/AppGlobalUiContext';
import { ApiResponse, FileCategory, FilePermission, FileResponse } from '../../../models';
import * as fileService from '../../../services/file';
import ImageThumbnail from '../../partials/common/ImageThumbnail';
import AppButton, { AppButtonProps } from '../AppButton';

export type AppFileSelectorProps = {
  defaultFile?: FileResponse;
  fileMode?: 'image';
  multiple?: boolean;
  buttonProps?: AppButtonProps;
  inputProps?: OutlinedInputProps;
  clearable?: boolean;
  disabled?: boolean;
  onImageClick?: (imageUrl: string) => void;
  onFilesSelected?: (files: FileResponse) => void;
  onValueCleared?: () => void;
};

const useStyles = makeStyles(() => ({
  readOnlyInput: {
    '& > input': {
      cursor: 'default',
    },
  },
  hiddenFileInput: {
    position: 'absolute',
    opacity: 0,
    width: 0,
    top: -9999,
    left: -9999,
    visibility: 'hidden',
  },
}));

const AppFileSelector: FC<AppFileSelectorProps> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    fileMode = 'image',
    multiple,
    inputProps,
    onFilesSelected,
    onValueCleared,
    defaultFile,
    onImageClick,
    clearable = true,
    disabled = false,
  } = props;
  const { showSnackbar } = useContext(AppGlobalUiContext);
  const [selectedFiles, setSelectedFiles] = useState<FileResponse | undefined>(defaultFile);
  const hiddenFileInputRef = useRef<HTMLInputElement>(null);
  const [uploadedFile, setUploadedFile] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const getInputText = () => {
    if (!selectedFiles) {
      return defaultFile?.url || '';
    }

    if (!multiple || selectedFiles) {
      return selectedFiles.url;
    }

    return t('common:mediaLibrary.selectedFiles', {
      fileCount: selectedFiles,
    }).toString();
  };

  const uploadFile = () => {
    if (!isLoading) {
      setIsLoading(true);

      (async () => {
        const privateQuery = {
          category: FileCategory.SellerApp,
          permission: FilePermission.Private,
        };
        const requestList = uploadedFile.map((file: File) => fileService.uploadPrivateFile(file, privateQuery));
        const result = await Promise.all(requestList);
        const fileUploadResult = result.map((res: ApiResponse<FileResponse>) => (res.data ? 'success' : 'error'));
        // array 0 since input default to support single file select
        const resultFile = result[0]?.data;
        if (resultFile) {
          setSelectedFiles(resultFile);
          if (typeof onFilesSelected === 'function') {
            onFilesSelected(resultFile);
          }
        }
        fileUploadResult?.forEach((res) => {
          showSnackbar(res === 'success' ? 'upload ไฟล์สำเร็จ' : 'upload ไฟล์ล้มเหลว', res);
        });
        setIsLoading(false);
      })();
    }
  };

  const onClearClicked = () => {
    setIsLoading(true);
    setSelectedFiles(undefined);
    if (hiddenFileInputRef?.current) {
      hiddenFileInputRef.current.files = null;
      hiddenFileInputRef.current.value = '';
    }
    if (typeof onValueCleared === 'function') {
      onValueCleared();
    }
    setIsLoading(false);
  };

  const openFileUploadClickHandler = () => {
    if (hiddenFileInputRef.current) {
      hiddenFileInputRef.current.click();
    }
  };

  const uploadFileSelectionHandler = (fileList: FileList) => {
    const files = [];

    for (let i = 0; i < fileList.length; i += 1) {
      const file = fileList.item(i);
      if (file) {
        files.push(file);
      }
    }
    setUploadedFile(files);
  };

  useEffect(() => {
    if (uploadedFile.length > 0) {
      uploadFile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedFile]);

  const onInputFileChanged = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      uploadFileSelectionHandler(e.target.files);
    }
  };

  return (
    <>
      {fileMode === 'image' && (
        <FormControl variant="outlined">
          {inputProps?.label && <InputLabel>{inputProps?.label}</InputLabel>}
          <input
            type="file"
            accept="image/*"
            className={classes.hiddenFileInput}
            ref={hiddenFileInputRef}
            onChange={onInputFileChanged}
          />
          {selectedFiles && (
            <ImageThumbnail
              imageUrl={selectedFiles.url}
              altText="Listing Image"
              imageClick={onImageClick}
              /* eslint-disable react/jsx-boolean-value */
              noLabel={true}
            />
          )}
          <OutlinedInput
            readOnly
            value={getInputText()}
            fullWidth
            {...inputProps}
            className={classes.readOnlyInput}
            disabled={disabled}
            endAdornment={
              <InputAdornment position="end">
                {clearable && !disabled && getInputText() && (
                  <IconButton aria-label="clear" onClick={() => onClearClicked()} edge="end">
                    <ClearIcon />
                  </IconButton>
                )}
                {!getInputText() && (
                  <AppButton
                    onClick={() => openFileUploadClickHandler()}
                    variant="outlined"
                    startIcon={<AddIcon fontSize="small" />}
                    disabled={disabled || isLoading}>
                    {t('common:button.upload')}
                  </AppButton>
                )}
              </InputAdornment>
            }
          />
        </FormControl>
      )}
    </>
  );
};

export default AppFileSelector;
