import List from './List';
import { RouteConfig } from '../../models/route.model';
import ListingEdit from '../listing/Edit';

const listingRoutesV1 = (path: string): RouteConfig[] => [
  {
    key: 'LISTINGV1',
    path,
    component: List,
    exact: true,
    title: 'common:title.listingv1',
  },
  {
    key: 'LISTING_DETAILV1',
    path: `${path}/:hashId`,
    component: ListingEdit,
    exact: true,
  },
];

export default listingRoutesV1;
