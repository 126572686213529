import { apiTCRequestV2 } from '../../helpers/request';
import { queryParamGenerator } from '../../helpers/utils';
import { ApiResponse } from '../../models';
import {
  GetLoginBonusListTCResponse,
  LoginBonus,
  LoginBonusFormDataRequest,
  LoginBonusSearchFiltersTC,
} from '../../models/login-bonus.model';

const getLoginBonusListQueryParam = (param?: LoginBonusSearchFiltersTC): string => {
  const reqParam: { [x: string]: string | number } = {
    page: param?.page || 0,
    per_page: param?.rowsPerPage || 10,
    sort: param?.sortColumn ? `id%7C${(param?.sortDirection || 'asc').toUpperCase()}` : '',
  };

  if (param?.searchKeyword && param?.searchField) {
    reqParam[param.searchField] = param.searchKeyword.trim();
  }

  if (param?.filteringStartDate) {
    reqParam.startDate = param.filteringStartDate;
  }
  if (param?.filteringEndDate) {
    reqParam.endDate = param.filteringEndDate;
  }

  if (param?.q) {
    reqParam.q = param.q;
  }

  return queryParamGenerator(reqParam);
};

export const getLoginBonusList = async (
  param?: LoginBonusSearchFiltersTC,
): Promise<ApiResponse<GetLoginBonusListTCResponse>> => {
  try {
    const queryParamString = getLoginBonusListQueryParam(param);
    const result = await apiTCRequestV2<GetLoginBonusListTCResponse>(`/admin/challenge-campaign/?${queryParamString}`);

    return {
      data: result.data ?? null,
    };
  } catch (e) {
    return {
      data: null,
      error: 'daily-challenge:loginBonus.error.list.requestFailed',
    };
  }
};

export const getLoginBonusListByHashId = async (
  hashId?: string,
  param?: LoginBonusSearchFiltersTC,
): Promise<ApiResponse<GetLoginBonusListTCResponse>> => {
  try {
    const queryParamString = getLoginBonusListQueryParam(param);
    const result = await apiTCRequestV2<GetLoginBonusListTCResponse>(
      `/admin/challenge-campaign/?${queryParamString}&userHashId=${hashId}`,
    );

    return {
      data: result.data ?? null,
    };
  } catch (e) {
    return {
      data: null,
      error: 'daily-challenge:loginBonus.error.list.requestFailed',
    };
  }
};

export const addNewLoginBonus = async (formData: LoginBonusFormDataRequest): Promise<ApiResponse<LoginBonus>> => {
  const result = await apiTCRequestV2<LoginBonus>(`/admin_gm/challenge-campaign/`, {
    method: 'POST',
    data: { ...formData },
  });

  if (result.data) {
    return {
      ...result,
      data: result.data,
    };
  }

  return {
    ...result,
    data: null,
  };
};

export const updateLoginBonus = async (
  challengeCampaignHashId: string,
  formData: LoginBonusFormDataRequest,
): Promise<ApiResponse<LoginBonus | null>> => {
  try {
    const result = await apiTCRequestV2<LoginBonus>(`/admin_gm/challenge-campaign/${challengeCampaignHashId}`, {
      method: 'PATCH',
      data: { ...formData },
    });

    return {
      ...result,
      data: result.data,
    };
  } catch (e) {
    return Promise.reject(e);
  }
};

export const deleteCampaignByHashId = async (
  challengeCampaignHashId: string,
): Promise<ApiResponse<LoginBonus | null>> => {
  try {
    const result = await apiTCRequestV2<LoginBonus>(`/admin_gm/challenge-campaign/${challengeCampaignHashId}`, {
      method: 'DELETE',
      data: challengeCampaignHashId,
    });

    return {
      ...result,
      data: result.data,
    };
  } catch (e) {
    return Promise.reject(e);
  }
};
