import React, { FC } from 'react';

import { Box, Grid, MenuItem, OutlinedInput, Select, Typography, makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import dayjs from 'dayjs';
import { Controller, UseControllerProps, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  ReportListingData,
  ReportListingFormDataRequest,
  ReportListingStatus,
} from '../../../../models/report-listing.model';
import AppButton from '../../../ui/AppButton';
import AppDatetimeText from '../../../ui/AppDatetimeText';
import AppDialog from '../../../ui/AppDialog';
import AppFormControl from '../../../ui/AppFormControl';
import DataDisplayBlock from '../../v3/users/UserDetail/DataDisplayBlock';
import ReportedStatusBadge from '../ReportedStatusBadge';

export type ReportItemDialogProps = {
  reportItem: ReportListingData;
  title: string;
  open: boolean;
  onCloseClick?: () => void;
  onFormSubmit: (data: ReportListingFormDataRequest) => void;
  rules?: { [x: string]: UseControllerProps['rules'] };
};

const reportStatusList = [
  {
    label: 'report-listing:common.reportStatus.waiting',
    value: ReportListingStatus.Waiting,
  },
  {
    label: 'report-listing:common.reportStatus.done',
    value: ReportListingStatus.Done,
  },
  {
    label: 'report-listing:common.reportStatus.duplicate_report',
    value: ReportListingStatus.DuplicateReport,
  },
  {
    label: 'report-listing:common.reportStatus.ignore',
    value: ReportListingStatus.Ignore,
  },
];

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  numberInput: {
    MozAppearance: 'none',
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
  confirmDialogBox: {
    width: '100%',
    maxWidth: '1000px',
    [theme.breakpoints.up('md')]: {
      minWidth: '400px',
    },
  },
  buttonGroups: {
    '& > *': {
      margin: `0 ${theme.spacing(1)}px`,
    },
  },
  previewInfoHeadingText: {
    color: grey[700],
    fontSize: '14px',
    minWidth: '90px',
  },
}));

const ReportItemDialog: FC<ReportItemDialogProps> = (props) => {
  const classes = useStyles();
  const { reportItem, open, title, onCloseClick, onFormSubmit } = props;
  const { t } = useTranslation();

  const {
    handleSubmit,
    getValues,
    control,
    formState: { errors },
  } = useForm<ReportListingFormDataRequest>({
    mode: 'onChange',
    defaultValues: {
      hashIds: reportItem.item.hashId,
      adminStatus: reportItem.adminStatus || '',
      crDetail: reportItem.crDetail || '',
    },
  });

  const onSaveClick = () => {
    if (typeof onFormSubmit === 'function') {
      onFormSubmit(getValues());
    }
  };

  return (
    <>
      <AppDialog
        open={open}
        title={title}
        okButtonText="common:button.close"
        okButtonColor="default"
        okButtonVariant="outlined"
        closeOnOkClicked
        noActionBar
        dialogProps={{
          fullWidth: true,
          maxWidth: 'sm',
          disableBackdropClick: true,
        }}>
        <form onSubmit={handleSubmit(onSaveClick)}>
          <Box>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4}>
                <ReportedStatusBadge status={reportItem.adminStatus} />
              </Grid>
              <Grid item xs={12} sm={8}>
                <DataDisplayBlock
                  label={t('report-listing:common.fields.listingId')}
                  allowCopy
                  copySuccessText="คัดลอกรหัสประกาศแล้ว"
                  newTabLink={`/listing/${reportItem.item.hashId}`}
                  newTabTooltipText="เปิดหน้าประกาศในแท็บใหม่">
                  {reportItem.item.hashId}
                </DataDisplayBlock>
              </Grid>
              <Grid item xs={12} sm={4}>
                <DataDisplayBlock
                  label={t('report-listing:common.fields.sellerName')}
                  allowCopy
                  copySuccessText="คัดลอก Hash ID ผู้ใช้แล้ว"
                  newTabLink={`/v3/users/${reportItem.item.userHashId}`}
                  newTabTooltipText="เปิดหน้าผู้ใช้ในแท็บใหม่">
                  {reportItem.item.userHashId}
                </DataDisplayBlock>
              </Grid>
              <Grid item xs={12} sm={8}>
                <DataDisplayBlock label={t('report-listing:common.fields.reportType')}>
                  {t(`report-listing:common.reportType.${reportItem.type}`)}
                </DataDisplayBlock>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.previewInfoHeadingText}>
                  {t('report-listing:common.fields.reportedComment')}
                </Typography>
                <Box mt={1} style={{ wordBreak: 'break-all' }}>
                  {reportItem.detail || '-'}
                </Box>
              </Grid>
              <Grid item xs={12} md={7}>
                <DataDisplayBlock
                  label={t('report-listing:common.fields.reportedBy')}
                  allowCopy
                  copySuccessText="คัดลอก Hash ID ผู้ใช้แล้ว"
                  newTabLink={`/v3/users/${reportItem.reporter.hashId}`}
                  newTabTooltipText="เปิดหน้าผู้ใช้ในแท็บใหม่">
                  {reportItem.reporter.hashId}
                </DataDisplayBlock>
              </Grid>
              <Grid item xs={12} md={5}>
                <DataDisplayBlock label={t('report-listing:common.fields.reportedAt')}>
                  <AppDatetimeText value={dayjs(reportItem?.createdAt)} withTime style={{ fontWeight: 'bold' }} />
                </DataDisplayBlock>
              </Grid>
              <Grid item xs={12} md={7} container>
                <Grid item xs={12} md="auto">
                  <Typography
                    className={classes.previewInfoHeadingText}
                    style={{ marginBottom: '6px', paddingTop: '6px' }}>
                    {t('report-listing:common.fields.adminStatus')}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="adminStatus"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    defaultValue={reportItem.adminStatus}
                    render={({ field }) => (
                      <AppFormControl error={!!errors.adminStatus}>
                        <Select {...field} variant="outlined" fullWidth margin="dense">
                          {reportStatusList.map((item) => (
                            <MenuItem value={item.value} key={item.value}>
                              {t(item.label)}
                            </MenuItem>
                          ))}
                        </Select>
                      </AppFormControl>
                    )}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={5}>
                <DataDisplayBlock label={t('report-listing:common.fields.updatedAt')}>
                  <AppDatetimeText value={dayjs(reportItem?.updatedAt)} withTime style={{ fontWeight: 'bold' }} />
                </DataDisplayBlock>
              </Grid>
            </Grid>

            <Grid item xs={12} container>
              <Grid item xs={12} md={2}>
                <Typography
                  className={classes.previewInfoHeadingText}
                  style={{ marginBottom: '6px', paddingTop: '6px' }}>
                  {t('report-listing:common.fields.reason')}
                </Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                <Controller
                  name="crDetail"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <AppFormControl error={!!errors.crDetail}>
                      <OutlinedInput
                        {...field}
                        multiline
                        rows={2}
                        id="report-crDetail"
                        fullWidth
                        error={!!errors.crDetail}
                      />
                    </AppFormControl>
                  )}
                />
              </Grid>
            </Grid>
          </Box>

          <Box display="flex" my={2} justifyContent="flex-end" className={classes.buttonGroups}>
            <AppButton
              variant="outlined"
              color="default"
              onClick={() => {
                if (typeof onCloseClick === 'function') {
                  onCloseClick();
                }
              }}>
              {t('common:button.cancel')}
            </AppButton>
            <AppButton type="submit" color="primary">
              {t('common:button.save')}
            </AppButton>
          </Box>
        </form>
      </AppDialog>
    </>
  );
};

export default ReportItemDialog;
