import React, { FC, useEffect, useContext, useState, useCallback } from 'react';

import { Box, Container, Divider, FormControlLabel, Grid, Paper, Typography } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

import StickyActionBar from '../../../components/partials/common/StickyActionBar';
import MasterDataCommonFields from '../../../components/partials/master-data/MasterDataCommonFields';
import AppBreadcrumb from '../../../components/ui/AppBreadcrumb';
import AppButton from '../../../components/ui/AppButton';
import AppCheckbox from '../../../components/ui/AppCheckbox';
import AppFormControl from '../../../components/ui/AppFormControl';
import { AppGlobalUiContext } from '../../../context/AppGlobalUiContext';
import { MainModuleContext } from '../../../context/MainModuleContext';
import { defaultRowsPerPage } from '../../../helpers/constants';
import { applyKTErrorToForm } from '../../../helpers/utils';
import { ApiResponse, Brand, BrandFormData, BreadcrumbItem, Category, CategoryListSearchParams } from '../../../models';
import * as brandService from '../../../services/brand';
import * as categoryService from '../../../services/category';
import useAppContainerStyles from '../../../theme/container.style';
import useControlStyles from '../../../theme/controls.style';
import useTypographyStyles from '../../../theme/typography.style';

const BrandEdit: FC = () => {
  const { t } = useTranslation();
  const { uid } = useParams<{ uid: string }>();
  const history = useHistory();
  const { path } = useRouteMatch();
  const { showSnackbar, setAppLoading, setAppBarTitle } = useContext(AppGlobalUiContext);
  const { currentRoute } = useContext(MainModuleContext);
  const containerClass = useAppContainerStyles();
  const controlsClasses = useControlStyles();
  const typoClasses = useTypographyStyles();
  const [currentBrand, setCurrentBrand] = useState<Brand | undefined>(undefined);
  const [categoryData, setCategoryData] = useState<Category[]>([]);

  const {
    control,
    setValue,
    handleSubmit,
    setError,
    getValues,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm<BrandFormData>({
    mode: 'onChange',
    defaultValues: {
      uid: 0,
      name: '',
      thaiName: '',
      slug: '',
      searchSynonym: '',
      rootCategorySlugs: [],
    },
  });

  const breadcrumbItems: BreadcrumbItem[] = [
    { label: 'common:sidebar.menu.masterData', url: '/master-data' },
    { label: 'master-data:menu.brand', url: '/master-data/brands' },
    {
      label: `master-data:menu.${uid === 'create' ? 'newBrand' : 'brandUid'}`,
      labelParam: {
        brandUid: uid,
      },
    },
  ];

  const setFormValues = (data: Brand) => {
    reset();
    clearErrors();

    setValue('uid', data.uid || 0);
    setValue('name', data.name || '');
    setValue('thaiName', data.thaiName || '');
    setValue('slug', data.slug || '');
    setValue('imageUrl', data.imageUrl || '', { shouldValidate: true });
    setValue('description', data.description || '', { shouldValidate: true });
    setValue('placeholder', data.placeholder || '', { shouldValidate: true });
    setValue('titleMeta', data.titleMeta || '', { shouldValidate: true });
    setValue('imageUrlMeta', data.imageUrlMeta || '', { shouldValidate: true });
    setValue('descriptionMeta', data.descriptionMeta || '', { shouldValidate: true });
    setValue('searchSynonym', data.searchSynonym || '');
    setValue('rootCategorySlugs', data.rootCategorySlugs || []);
  };

  useEffect(() => {
    let isActive = true;
    setAppBarTitle(`master-data:title.brand.${uid === 'create' ? 'create' : 'edit'}`);

    (async () => {
      if (uid && uid !== 'create' && isActive) {
        const category = await brandService.getBrandByUid(uid);
        if (isActive) {
          if (category.data) {
            setCurrentBrand(category.data);
            setFormValues(category.data);
          } else {
            showSnackbar(t('master-data:error.category.requestCategoryFailed').toString(), 'error');
          }
        }
      }
    })();

    return () => {
      isActive = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openModelPage = () => {
    history.push(`/master-data/brands/${uid}/models`);
  };

  const onSaveDataHandler = (data: BrandFormData) => {
    (async () => {
      setAppLoading(true);

      let saveResult: ApiResponse<Brand>;
      if (uid !== 'create' && currentBrand) {
        saveResult = await brandService.updateBrand(currentBrand.id, data);
      } else {
        saveResult = await brandService.createBrand(data);
      }

      if (saveResult && saveResult.data && saveResult.data.id) {
        await brandService.syncElasticsearchBrandData(saveResult.data.id);
      }

      if (saveResult) {
        if (saveResult.data) {
          showSnackbar(
            t(`master-data:message.brand.${uid === 'create' ? 'create' : 'update'}Success`).toString(),
            'success',
          );

          // If uid has changed, refresh page to get fresh data
          // Don't have to clear app loading since it's refreshing
          if (uid === 'create' || (saveResult.data?.uid && saveResult.data.uid !== Number(uid))) {
            setTimeout(() => {
              window.location.href = `/master-data/brands/${saveResult.data?.uid}`;
            }, 2000);
          } else {
            setAppLoading(false);
            setCurrentBrand(saveResult.data);
          }
        } else {
          setAppLoading(false);
          showSnackbar(t(saveResult.error).toString(), 'error');

          if (saveResult.validationErrors) {
            applyKTErrorToForm<BrandFormData>(t, setError, getValues, saveResult.validationErrors);
          }
        }
      }

      if (currentBrand) {
        const syncRootCategoryResult = await brandService.syncBrandWithRootCategory(currentBrand.slug, {
          rootCategorySlugs: data.rootCategorySlugs,
        });
        if (syncRootCategoryResult.data) {
          showSnackbar(t(`master-data:message.brand.updateSuccess`).toString(), 'success');
        } else {
          showSnackbar(t(syncRootCategoryResult.error).toString(), 'error');
        }
      }
    })();
  };

  const onSaveClick = () => {
    handleSubmit(onSaveDataHandler)();
  };

  const getCategorySearchParam = (): CategoryListSearchParams => ({
    page: 0,
    rowsPerPage: 100 || defaultRowsPerPage,
    sortColumn: 'name',
    sortDirection: 'desc',
    searchField: '',
    searchKeyword: '',
    masterIndexUid: '1',
  });

  const categoryList = useCallback(async () => {
    const searchConditions = getCategorySearchParam();
    const result = await categoryService.getCategoryList(searchConditions);
    if (result.data?.list) {
      setCategoryData(result.data.list);
    }
  }, []);

  useEffect(() => {
    categoryList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box px={6} py={3}>
        <AppBreadcrumb items={breadcrumbItems} />
      </Box>
      <Container className={containerClass.container} style={{ marginBottom: 60 }}>
        <Box py={3}>
          <Box py={2} display="flex" justifyContent="flex-end" className={controlsClasses.buttonsGroup}>
            <StickyActionBar>
              <AppButton color="default" variant="outlined" onClick={() => history.push(currentRoute?.backUrl || path)}>
                {t('common:button.cancel')}
              </AppButton>
              <AppButton color="primary" onClick={() => onSaveClick()}>
                {t('common:button.save')}
              </AppButton>
            </StickyActionBar>
          </Box>
          <Paper>
            <Box p={3}>
              <MasterDataCommonFields control={control} errors={errors} hasSearchSynonym />
              {!!currentBrand && (
                <>
                  <Box mt={3} mb={2}>
                    <Divider />
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={2}>
                      <Box mb={3}>
                        <Typography className={typoClasses.textWeightBold} variant="h6">
                          {t('master-data:form.model.modelInfoTitle')}
                        </Typography>
                      </Box>
                      <AppButton variant="outlined" color="primary" onClick={() => openModelPage()}>
                        {t('master-data:form.brand.manageModel')}
                      </AppButton>
                    </Grid>
                    <Grid item xs={12} md={12} lg={10}>
                      <Box mb={2}>
                        <Typography className={typoClasses.textWeightBold} variant="h6">
                          {t('common:label.rootCategoryType')}
                        </Typography>
                      </Box>
                      <Box mt={3} mb={2}>
                        <Controller
                          name="rootCategorySlugs"
                          control={control}
                          render={({ field }) => (
                            <AppFormControl style={{ display: 'inline' }}>
                              {categoryData.map((item) => (
                                <FormControlLabel
                                  key={item.uid}
                                  control={
                                    <AppCheckbox
                                      color="default"
                                      onClick={() => {
                                        if (field.value) {
                                          let newValue = [];
                                          if (!field.value.includes(item.slug)) {
                                            newValue = [...field?.value, item.slug];
                                          } else {
                                            newValue = [...field?.value].filter((val) => val !== item.slug);
                                          }

                                          field.onChange(newValue);
                                        }
                                      }}
                                      checked={field.value.includes(item.slug)}
                                    />
                                  }
                                  label={item.thaiName}
                                />
                              ))}
                            </AppFormControl>
                          )}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </>
              )}
            </Box>
          </Paper>
        </Box>
      </Container>
    </>
  );
};

export default BrandEdit;
