// eslint-disable-next-line import/prefer-default-export
export const PROVINCE_OPTION = [
  {
    thaiName: 'กระบี่',
    englishName: 'Krabi',
    thaiRegion: 'ภาคใต้',
    englishRegion: 'The Southern',
    slug: 'krabi',
    uid: 64,
    hashId: 'W83YoreZRx',
  },
  {
    thaiName: 'กรุงเทพ',
    englishName: 'Bangkok',
    thaiRegion: 'ภาคกลาง',
    englishRegion: 'The Central',
    slug: 'bangkok',
    uid: 30,
    hashId: '8QoZDjqeNp',
  },
  {
    thaiName: 'กาญจนบุรี',
    englishName: 'Kanchanaburi',
    thaiRegion: 'ภาคตะวันตก',
    englishRegion: 'The Western',
    slug: 'kanchanaburi',
    uid: 59,
    hashId: '37Kq1W2ZRb',
  },
  {
    thaiName: 'กาฬสินธุ์',
    englishName: 'Kalasin',
    thaiRegion: 'ภาคตะวันออกเฉียงเหนือ (อีสาน)',
    englishRegion: 'The Northeastern (Isarn)',
    slug: 'kalasin',
    uid: 10,
    hashId: '9r8Ym7n3BV',
  },
  {
    thaiName: 'กำแพงเพชร',
    englishName: 'Kamphaeng Phet',
    thaiRegion: 'ภาคกลาง',
    englishRegion: 'The Central',
    slug: 'kamphaeng-phet',
    uid: 31,
    hashId: '5wWY4kZ47X',
  },
  {
    thaiName: 'ขอนแก่น',
    englishName: 'Khon Kaen',
    thaiRegion: 'ภาคตะวันออกเฉียงเหนือ (อีสาน)',
    englishRegion: 'The Northeastern (Isarn)',
    slug: 'khon-kaen',
    uid: 11,
    hashId: 'G6MnR6nw5J',
  },
  {
    thaiName: 'จันทบุรี',
    englishName: 'Chantaburi',
    thaiRegion: 'ภาคตะวันออก',
    englishRegion: 'The Eastern',
    slug: 'chantaburi',
    uid: 52,
    hashId: '53Gn95bnOB',
  },
  {
    thaiName: 'ฉะเชิงเทรา',
    englishName: 'Chachoengsao',
    thaiRegion: 'ภาคตะวันออก',
    englishRegion: 'The Eastern',
    slug: 'chachoengsao',
    uid: 53,
    hashId: 'EWNn0o8q9b',
  },
  {
    thaiName: 'ชลบุรี',
    englishName: 'Chonburi',
    thaiRegion: 'ภาคตะวันออก',
    englishRegion: 'The Eastern',
    slug: 'chonburi',
    uid: 54,
    hashId: '9r8YmX7Z3B',
  },
  {
    thaiName: 'ชัยนาท',
    englishName: 'Chai Nat',
    thaiRegion: 'ภาคกลาง',
    englishRegion: 'The Central',
    slug: 'chai-nat',
    uid: 32,
    hashId: '43mqdVZyXE',
  },
  {
    thaiName: 'ชัยภูมิ',
    englishName: 'Chaiyaphum',
    thaiRegion: 'ภาคตะวันออกเฉียงเหนือ (อีสาน)',
    englishRegion: 'The Northeastern (Isarn)',
    slug: 'chaiyaphum',
    uid: 12,
    hashId: 'x1eqlJZB9y',
  },
  {
    thaiName: 'ชุมพร',
    englishName: 'Chumphon',
    thaiRegion: 'ภาคใต้',
    englishRegion: 'The Southern',
    slug: 'chumphon',
    uid: 65,
    hashId: 'PWxqw2PYKL',
  },
  {
    thaiName: 'ตรัง',
    englishName: 'Trang',
    thaiRegion: 'ภาคใต้',
    englishRegion: 'The Southern',
    slug: 'trang',
    uid: 66,
    hashId: 'oLmnGbBqWE',
  },
  {
    thaiName: 'ตราด',
    englishName: 'Trat',
    thaiRegion: 'ภาคตะวันออก',
    englishRegion: 'The Eastern',
    slug: 'trat',
    uid: 55,
    hashId: 'G6MnRM6qw5',
  },
  {
    thaiName: 'ตาก',
    englishName: 'Tak',
    thaiRegion: 'ภาคตะวันตก',
    englishRegion: 'The Western',
    slug: 'tak',
    uid: 60,
    hashId: 'v4KY3POqaE',
  },
  {
    thaiName: 'นครนายก',
    englishName: 'Nakhon Nayok',
    thaiRegion: 'ภาคกลาง',
    englishRegion: 'The Central',
    slug: 'nakhon-nayok',
    uid: 33,
    hashId: 'M1PZQlnEBX',
  },
  {
    thaiName: 'นครปฐม',
    englishName: 'Nakhon Pathom',
    thaiRegion: 'ภาคกลาง',
    englishRegion: 'The Central',
    slug: 'nakhon-pathom',
    uid: 34,
    hashId: 'x3bn7MYewp',
  },
  {
    thaiName: 'นครพนม',
    englishName: 'Nakhon Phanom',
    thaiRegion: 'ภาคตะวันออกเฉียงเหนือ (อีสาน)',
    englishRegion: 'The Northeastern (Isarn)',
    slug: 'nakhon-phanom',
    uid: 13,
    hashId: 'DPvn81ZN70',
  },
  {
    thaiName: 'นครราชสีมา',
    englishName: 'Nakhon Ratchasima',
    thaiRegion: 'ภาคตะวันออกเฉียงเหนือ (อีสาน)',
    englishRegion: 'The Northeastern (Isarn)',
    slug: 'nakhon-ratchasima',
    uid: 14,
    hashId: 'gEBYyaYkN9',
  },
  {
    thaiName: 'นครศรีธรรมราช',
    englishName: 'Nakhon Si Thammarat',
    thaiRegion: 'ภาคใต้',
    englishRegion: 'The Southern',
    slug: 'nakhon-si-thammarat',
    uid: 67,
    hashId: 'redZOdVY72',
  },
  {
    thaiName: 'นครสวรรค์',
    englishName: 'Nakhon Sawan',
    thaiRegion: 'ภาคกลาง',
    englishRegion: 'The Central',
    slug: 'nakhon-sawan',
    uid: 35,
    hashId: 'LO7ZKBn4b1',
  },
  {
    thaiName: 'นนทบุรี',
    englishName: 'Nonthaburi',
    thaiRegion: 'ภาคกลาง',
    englishRegion: 'The Central',
    slug: 'nonthaburi',
    uid: 36,
    hashId: 'W7pqLzZ5DQ',
  },
  {
    thaiName: 'นราธิวาส',
    englishName: 'Narathiwat',
    thaiRegion: 'ภาคใต้',
    englishRegion: 'The Southern',
    slug: 'narathiwat',
    uid: 68,
    hashId: 'Pg7Z6MonRv',
  },
  {
    thaiName: 'น่าน',
    englishName: 'Nan',
    thaiRegion: 'ภาคเหนือ',
    englishRegion: 'The Northern',
    slug: 'nan',
    uid: 3,
    hashId: 'WQ9nN3ZLlv',
  },
  {
    thaiName: 'บึงกาฬ',
    englishName: 'Bueng Kan',
    thaiRegion: 'ภาคตะวันออกเฉียงเหนือ (อีสาน)',
    englishRegion: 'The Northeastern (Isarn)',
    slug: 'bueng-kan',
    uid: 29,
    hashId: 'LPxnp3q2Xw',
  },
  {
    thaiName: 'บุรีรัมย์',
    englishName: 'Buriram',
    thaiRegion: 'ภาคตะวันออกเฉียงเหนือ (อีสาน)',
    englishRegion: 'The Northeastern (Isarn)',
    slug: 'buriram',
    uid: 15,
    hashId: '37Kq12nRbB',
  },
  {
    thaiName: 'ปทุมธานี',
    englishName: 'Pathum Thani',
    thaiRegion: 'ภาคกลาง',
    englishRegion: 'The Central',
    slug: 'pathum-thani',
    uid: 37,
    hashId: '9E6ZEAZ1Qk',
  },
  {
    thaiName: 'ประจวบคีรีขันธ์',
    englishName: 'Prachuap Khiri Khan',
    thaiRegion: 'ภาคตะวันตก',
    englishRegion: 'The Western',
    slug: 'prachuap-khiri-khan',
    uid: 61,
    hashId: 'lbgZe3Oqda',
  },
  {
    thaiName: 'ปราจีนบุรี',
    englishName: 'Prachinburi',
    thaiRegion: 'ภาคตะวันออก',
    englishRegion: 'The Eastern',
    slug: 'prachinburi',
    uid: 56,
    hashId: 'x1eqloJYB9',
  },
  {
    thaiName: 'ปัตตานี',
    englishName: 'Pattani',
    thaiRegion: 'ภาคใต้',
    englishRegion: 'The Southern',
    slug: 'pattani',
    uid: 69,
    hashId: 'B2aYJ9bZJp',
  },
  {
    thaiName: 'พระนครศรีอยุธยา',
    englishName: 'Ayutthaya',
    thaiRegion: 'ภาคกลาง',
    englishRegion: 'The Central',
    slug: 'ayutthaya',
    uid: 38,
    hashId: 'bJMq2WnlB3',
  },
  {
    thaiName: 'พะเยา',
    englishName: 'Phayao',
    thaiRegion: 'ภาคเหนือ',
    englishRegion: 'The Northern',
    slug: 'phayao',
    uid: 4,
    hashId: 'J7KYWpqGbW',
  },
  {
    thaiName: 'พังงา',
    englishName: 'Phang Nga',
    thaiRegion: 'ภาคใต้',
    englishRegion: 'The Southern',
    slug: 'phang-nga',
    uid: 70,
    hashId: 'Q0rqjX6ZWe',
  },
  {
    thaiName: 'พัทลุง',
    englishName: 'Phattalung',
    thaiRegion: 'ภาคใต้',
    englishRegion: 'The Southern',
    slug: 'phattalung',
    uid: 71,
    hashId: 'reRnb9Aqa1',
  },
  {
    thaiName: 'พิจิตร',
    englishName: 'Phichit',
    thaiRegion: 'ภาคกลาง',
    englishRegion: 'The Central',
    slug: 'phichit',
    uid: 39,
    hashId: 'e1JYX0YkAa',
  },
  {
    thaiName: 'พิษณุโลก',
    englishName: 'Phitsanulok',
    thaiRegion: 'ภาคกลาง',
    englishRegion: 'The Central',
    slug: 'phitsanulok',
    uid: 40,
    hashId: 'GdeqxzZ8mK',
  },
  {
    thaiName: 'ภูเก็ต',
    englishName: 'Phuket',
    thaiRegion: 'ภาคใต้',
    englishRegion: 'The Southern',
    slug: 'phuket',
    uid: 72,
    hashId: 'pMaYkzgZyg',
  },
  {
    thaiName: 'มหาสารคาม',
    englishName: 'Maha Sarakham',
    thaiRegion: 'ภาคตะวันออกเฉียงเหนือ (อีสาน)',
    englishRegion: 'The Northeastern (Isarn)',
    slug: 'maha-sarakham',
    uid: 16,
    hashId: 'v4KY3OYaEV',
  },
  {
    thaiName: 'มุกดาหาร',
    englishName: 'Mukdahan',
    thaiRegion: 'ภาคตะวันออกเฉียงเหนือ (อีสาน)',
    englishRegion: 'The Northeastern (Isarn)',
    slug: 'mukdahan',
    uid: 17,
    hashId: 'lbgZeOqdao',
  },
  {
    thaiName: 'ยะลา',
    englishName: 'Yala',
    thaiRegion: 'ภาคใต้',
    englishRegion: 'The Southern',
    slug: 'yala',
    uid: 77,
    hashId: 'M1PZQzlYEB',
  },
  {
    thaiName: 'ยโสธร',
    englishName: 'Yasothon',
    thaiRegion: 'ภาคตะวันออกเฉียงเหนือ (อีสาน)',
    englishRegion: 'The Northeastern (Isarn)',
    slug: 'yasothon',
    uid: 18,
    hashId: 'BWrngGnlJR',
  },
  {
    thaiName: 'ระนอง',
    englishName: 'Ranong',
    thaiRegion: 'ภาคใต้',
    englishRegion: 'The Southern',
    slug: 'ranong',
    uid: 73,
    hashId: 'LPxnpM3Z2X',
  },
  {
    thaiName: 'ระยอง',
    englishName: 'Rayong',
    thaiRegion: 'ภาคตะวันออก',
    englishRegion: 'The Eastern',
    slug: 'rayong',
    uid: 57,
    hashId: 'DPvn8W1qN7',
  },
  {
    thaiName: 'ราชบุรี',
    englishName: 'Ratchaburi',
    thaiRegion: 'ภาคตะวันตก',
    englishRegion: 'The Western',
    slug: 'ratchaburi',
    uid: 63,
    hashId: '6mLnzk1ZrX',
  },
  {
    thaiName: 'ร้อยเอ็ด',
    englishName: 'Roi Et',
    thaiRegion: 'ภาคตะวันออกเฉียงเหนือ (อีสาน)',
    englishRegion: 'The Northeastern (Isarn)',
    slug: 'roi-et',
    uid: 19,
    hashId: '6mLnz1qrXJ',
  },
  {
    thaiName: 'ลพบุรี',
    englishName: 'Lop Buri',
    thaiRegion: 'ภาคกลาง',
    englishRegion: 'The Central',
    slug: 'lop-buri',
    uid: 42,
    hashId: 'rjOqVEYpaN',
  },
  {
    thaiName: 'ลำปาง',
    englishName: 'Lampang',
    thaiRegion: 'ภาคเหนือ',
    englishRegion: 'The Northern',
    slug: 'lampang',
    uid: 7,
    hashId: 'Nb7nBXYDX5',
  },
  {
    thaiName: 'ลำพูน',
    englishName: 'Lamphun',
    thaiRegion: 'ภาคเหนือ',
    englishRegion: 'The Northern',
    slug: 'lamphun',
    uid: 8,
    hashId: '53Gn9bZOB0',
  },
  {
    thaiName: 'ศรีสะเกษ',
    englishName: 'Si Sa Ket',
    thaiRegion: 'ภาคตะวันออกเฉียงเหนือ (อีสาน)',
    englishRegion: 'The Northeastern (Isarn)',
    slug: 'si-sa-ket',
    uid: 23,
    hashId: 'redZOVn724',
  },
  {
    thaiName: 'สกลนคร',
    englishName: 'Sakon Nakhon',
    thaiRegion: 'ภาคตะวันออกเฉียงเหนือ (อีสาน)',
    englishRegion: 'The Northeastern (Isarn)',
    slug: 'sakon-nakhon',
    uid: 21,
    hashId: 'PWxqwPnKLb',
  },
  {
    thaiName: 'สงขลา',
    englishName: 'Songkhla',
    thaiRegion: 'ภาคใต้',
    englishRegion: 'The Southern',
    slug: 'songkhla',
    uid: 75,
    hashId: '5wWY4Ekq47',
  },
  {
    thaiName: 'สตูล',
    englishName: 'Satun',
    thaiRegion: 'ภาคใต้',
    englishRegion: 'The Southern',
    slug: 'satun',
    uid: 74,
    hashId: '8QoZD7jneN',
  },
  {
    thaiName: 'สมุทรปราการ',
    englishName: 'Samut Prakan',
    thaiRegion: 'ภาคกลาง',
    englishRegion: 'The Central',
    slug: 'samut-prakan',
    uid: 43,
    hashId: 'amgqAaqdKr',
  },
  {
    thaiName: 'สมุทรสงคราม',
    englishName: 'Samut Songkhram',
    thaiRegion: 'ภาคกลาง',
    englishRegion: 'The Central',
    slug: 'samut-songkhram',
    uid: 44,
    hashId: '95XZvMLnK4',
  },
  {
    thaiName: 'สมุทรสาคร',
    englishName: 'Samut Sakhon',
    thaiRegion: 'ภาคกลาง',
    englishRegion: 'The Central',
    slug: 'samut-sakhon',
    uid: 45,
    hashId: 'BKkZaXXnRl',
  },
  {
    thaiName: 'สระบุรี',
    englishName: 'Saraburi',
    thaiRegion: 'ภาคกลาง',
    englishRegion: 'The Central',
    slug: 'saraburi',
    uid: 49,
    hashId: 'QOPZ59QZey',
  },
  {
    thaiName: 'สระแก้ว',
    englishName: 'Sa Kaeo',
    thaiRegion: 'ภาคตะวันออก',
    englishRegion: 'The Eastern',
    slug: 'sa-kaeo',
    uid: 58,
    hashId: 'gEBYyLaZkN',
  },
  {
    thaiName: 'สิงห์บุรี',
    englishName: 'Sing Buri',
    thaiRegion: 'ภาคกลาง',
    englishRegion: 'The Central',
    slug: 'sing-buri',
    uid: 46,
    hashId: 'PxzYMp1ZGy',
  },
  {
    thaiName: 'สุพรรณบุรี',
    englishName: 'Suphan Buri',
    thaiRegion: 'ภาคกลาง',
    englishRegion: 'The Central',
    slug: 'suphan-buri',
    uid: 48,
    hashId: 'J7KYWmpnGb',
  },
  {
    thaiName: 'สุราษฎร์ธานี',
    englishName: 'Surat Thani',
    thaiRegion: 'ภาคใต้',
    englishRegion: 'The Southern',
    slug: 'surat-thani',
    uid: 76,
    hashId: '43mqdDVqyX',
  },
  {
    thaiName: 'สุรินทร์',
    englishName: 'Surin',
    thaiRegion: 'ภาคตะวันออกเฉียงเหนือ (อีสาน)',
    englishRegion: 'The Northeastern (Isarn)',
    slug: 'surin',
    uid: 22,
    hashId: 'oLmnGBqWED',
  },
  {
    thaiName: 'สุโขทัย',
    englishName: 'Sukhothai',
    thaiRegion: 'ภาคกลาง',
    englishRegion: 'The Central',
    slug: 'sukhothai',
    uid: 47,
    hashId: 'WQ9nN13qLl',
  },
  {
    thaiName: 'หนองคาย',
    englishName: 'Nong Khai',
    thaiRegion: 'ภาคตะวันออกเฉียงเหนือ (อีสาน)',
    englishRegion: 'The Northeastern (Isarn)',
    slug: 'nong-khai',
    uid: 24,
    hashId: 'Pg7Z6oYRvO',
  },
  {
    thaiName: 'หนองบัวลำภู',
    englishName: 'Nong Bua Lamphu',
    thaiRegion: 'ภาคตะวันออกเฉียงเหนือ (อีสาน)',
    englishRegion: 'The Northeastern (Isarn)',
    slug: 'nong-bua-lamphu',
    uid: 25,
    hashId: 'B2aYJbYJpA',
  },
  {
    thaiName: 'อำนาจเจริญ',
    englishName: 'Amnat Charoen',
    thaiRegion: 'ภาคตะวันออกเฉียงเหนือ (อีสาน)',
    englishRegion: 'The Northeastern (Isarn)',
    slug: 'amnat-charoen',
    uid: 28,
    hashId: 'pMaYkgYyg6',
  },
  {
    thaiName: 'อุดรธานี',
    englishName: 'Udon Thani',
    thaiRegion: 'ภาคตะวันออกเฉียงเหนือ (อีสาน)',
    englishRegion: 'The Northeastern (Isarn)',
    slug: 'udon-thani',
    uid: 26,
    hashId: 'Q0rqj6qWeb',
  },
  {
    thaiName: 'อุตรดิตถ์',
    englishName: 'Uttaradit',
    thaiRegion: 'ภาคเหนือ',
    englishRegion: 'The Northern',
    slug: 'uttaradit',
    uid: 9,
    hashId: 'EWNn08n9bo',
  },
  {
    thaiName: 'อุทัยธานี',
    englishName: 'Uthai Thani',
    thaiRegion: 'ภาคกลาง',
    englishRegion: 'The Central',
    slug: 'uthai-thani',
    uid: 51,
    hashId: 'Nb7nB1XqDX',
  },
  {
    thaiName: 'อุบลราชธานี',
    englishName: 'Ubon Ratchathani',
    thaiRegion: 'ภาคตะวันออกเฉียงเหนือ (อีสาน)',
    englishRegion: 'The Northeastern (Isarn)',
    slug: 'ubon-ratchathani',
    uid: 27,
    hashId: 'reRnbAqa1w',
  },
  {
    thaiName: 'อ่างทอง',
    englishName: 'Ang Thong',
    thaiRegion: 'ภาคกลาง',
    englishRegion: 'The Central',
    slug: 'ang-thong',
    uid: 50,
    hashId: 'o3bZPjmqO1',
  },
  {
    thaiName: 'เชียงราย',
    englishName: 'Chiang Rai',
    thaiRegion: 'ภาคเหนือ',
    englishRegion: 'The Northern',
    slug: 'chiang-rai',
    uid: 1,
    hashId: 'BKkZaXnRld',
  },
  {
    thaiName: 'เชียงใหม่',
    englishName: 'Chiang Mai',
    thaiRegion: 'ภาคเหนือ',
    englishRegion: 'The Northern',
    slug: 'chiang-mai',
    uid: 2,
    hashId: 'PxzYM1YGyv',
  },
  {
    thaiName: 'เพชรบุรี',
    englishName: 'Phetchaburi',
    thaiRegion: 'ภาคตะวันตก',
    englishRegion: 'The Western',
    slug: 'phetchaburi',
    uid: 62,
    hashId: 'BWrnggGnlJ',
  },
  {
    thaiName: 'เพชรบูรณ์',
    englishName: 'Phetchabun',
    thaiRegion: 'ภาคกลาง',
    englishRegion: 'The Central',
    slug: 'phetchabun',
    uid: 41,
    hashId: 'EwrYryZvX1',
  },
  {
    thaiName: 'เลย',
    englishName: 'Loei',
    thaiRegion: 'ภาคตะวันออกเฉียงเหนือ (อีสาน)',
    englishRegion: 'The Northeastern (Isarn)',
    slug: 'loei',
    uid: 20,
    hashId: 'W83YoenRx2',
  },
  {
    thaiName: 'แพร่',
    englishName: 'Phrae',
    thaiRegion: 'ภาคเหนือ',
    englishRegion: 'The Northern',
    slug: 'phrae',
    uid: 5,
    hashId: 'QOPZ5QZeyX',
  },
  {
    thaiName: 'แม่ฮ่องสอน',
    englishName: 'Mae Hong Son',
    thaiRegion: 'ภาคเหนือ',
    englishRegion: 'The Northern',
    slug: 'mae-hong-son',
    uid: 6,
    hashId: 'o3bZPmYO15',
  },
];
