import MenuEdit from './Edit';
import MenuList from './List';
import { RouteConfig } from '../../models/route.model';

const menuRoutes = (path: string): RouteConfig[] => [
  {
    key: 'MENU_LIST',
    path,
    component: MenuList,
    exact: true,
    title: 'common:title.menu',
  },
  {
    key: 'MENU_DETAIL',
    path: `${path}/:uid`,
    component: MenuEdit,
    exact: true,
    backUrl: path,
  },
];

export default menuRoutes;
