import React, { FC, MouseEvent, useCallback, useState } from 'react';

import { Avatar, Box, Container, makeStyles, TableRow, Typography } from '@material-ui/core';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import getFallbackShopType from '../../../../../helpers/get-fallback-shop-type';
import { getAppUrl, getT2HSiteUrl } from '../../../../../helpers/utils';
import useAuthContext from '../../../../../hooks/useAuthContext';
import { AppTableConditions, TableColumn, UserDataTC, UserRole } from '../../../../../models';
import useAppContainerStyles from '../../../../../theme/container.style';
import useAppTableStyles from '../../../../../theme/table.style';
import AppButton from '../../../../ui/AppButton';
import AppTableCell from '../../../../ui/AppTableCell';
import AppTableList from '../../../../ui/AppTableList';
import UserStatusBadge from '../UserStatusBadge';

export type UserListTableProps = {
  currentConditions: AppTableConditions;
  totalUsers: number;
  isLoadingData: boolean;
  userList: UserDataTC[];
  onTableConditionsChanged: (values: AppTableConditions) => void;
};

const iconCoin = '../static/images/icons/icon-coin.png';

const useStyles = makeStyles((theme) => ({
  dateDisplayText: {
    lineHeight: 1.3,
  },
  userAvatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    border: `solid 1px ${theme.palette.grey[300]}`,
    boxSizing: 'border-box',
  },
  userNameText: {
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
  },
  verifiedIcon: {
    fontSize: '16px',
    color: theme.palette.success.main,
    display: 'inline-flex',
    transform: 'translateY(-1px)',
    verticalAlign: 'middle',
    marginLeft: '3px',
  },
  selectedRow: {
    backgroundColor: theme.palette.primary.main,
    '&:nth-of-type(2n+1)': {
      backgroundColor: theme.palette.primary.main,
    },
    '& td, & a': {
      color: theme.palette.primary.contrastText,
    },
    '&:not(.readonly):hover': {
      backgroundColor: theme.palette.primary.main,
      '& td, & a': {
        color: theme.palette.primary.contrastText,
      },
    },
  },
}));

const UserListTable: FC<UserListTableProps> = (props) => {
  const { t, i18n } = useTranslation();
  const { url } = useRouteMatch();
  const { totalUsers, isLoadingData, currentConditions, userList, onTableConditionsChanged } = props;
  const containerClasses = useAppContainerStyles();
  const tableClasses = useAppTableStyles();
  const classes = useStyles();
  const { authUser } = useAuthContext();
  const [tableConditions, setTableConditions] = useState<AppTableConditions>(currentConditions);
  const [selectedUserHashId, setSelectedUserHashId] = useState<string | null>(null);
  const dateDisplayFormat = i18n.language === 'th' ? 'D MMM BBBB' : 'D MMM YYYY';
  const isAdminStaff = authUser && authUser.role === UserRole.Staff;
  const userTableColumns: TableColumn[] = [
    {
      name: 'status',
      label: 'user:v3.list.search.status.title',
      width: isAdminStaff ? 140 : 240,
      unsortable: true,
    },
    { name: 'displayName', label: 'user:list.columns.displayName', width: 220, unsortable: true },
    { name: 'mobilePhone', label: 'user:list.columns.mobile', width: 120, unsortable: true },
    { name: 'email', label: 'user:list.columns.email', width: 220, unsortable: true },
    { name: 'hashId', label: 'user:list.columns.hashId', width: 120, unsortable: true },
    { name: 'shopType', label: 'user:list.columns.shopType', width: 140, unsortable: true },
    { name: 'registerAt', label: 'user:list.columns.registerAt', width: 120 },
    { name: 'balance', label: 'user:list.columns.coinBalance', width: 120, unsortable: true },
    { name: 'nationality', label: 'user:common.fields.nationality', width: 120, unsortable: true },
    { name: 'remark', label: '', width: 160, unsortable: true },
  ];

  const onTableConditionChangedHandler = useCallback(
    (values: AppTableConditions) => {
      if (JSON.stringify(values) !== JSON.stringify(tableConditions)) {
        setTableConditions(values);
        onTableConditionsChanged(values);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableConditions],
  );

  const openUserDetailPage = useCallback(
    (user: UserDataTC) => {
      setSelectedUserHashId(user.userHashId);
      window.open(getAppUrl(`${url}/${user.userHashId}`), '_blank');
    },
    [url],
  );

  // To prevent event bubbling for row click
  const openUserPageClickHandler = useCallback((e: MouseEvent): void => {
    e.stopPropagation();
  }, []);

  return (
    <>
      <Container className={containerClasses.container}>
        <Box className={tableClasses.tableContainer}>
          {!isLoadingData && (
            <Typography
              variant="subtitle2"
              color="textSecondary"
              style={{ marginBottom: '8px', marginRight: '8px', textAlign: 'right' }}>
              {t('common:list.resultTotal', { fieldName: t(totalUsers.toLocaleString() || '0') })}
            </Typography>
          )}

          <AppTableList
            {...tableConditions}
            aria-label="user-list"
            columns={userTableColumns}
            totalDataCount={totalUsers}
            hasPagination
            onTableConditionChanged={onTableConditionChangedHandler}
            isLoading={isLoadingData}
            tableBodyContent={
              userList.length > 0 && (
                <>
                  {userList.map((user: UserDataTC) => (
                    <TableRow
                      hover
                      key={user.userHashId}
                      classes={{
                        root: clsx({
                          [tableClasses.tableRow]: true,
                          [tableClasses.clickableTableRow]: true,
                          [classes.selectedRow]: selectedUserHashId === user.userHashId,
                        }),
                      }}
                      onClick={() => openUserDetailPage(user)}>
                      <AppTableCell>
                        <Box
                          mr={1}
                          style={{
                            display: 'inline-block',
                          }}>
                          <UserStatusBadge
                            status={user.userRoleStateWorkflow !== null ? user.userRoleStateWorkflow : ''}
                          />
                        </Box>
                        {!isAdminStaff && (
                          <>
                            <UserStatusBadge status={user.userGeneralWorkflow} />
                            {user.buyerSellerVerificationWorkflow && (
                              <Box style={{ marginTop: '6px' }}>
                                <UserStatusBadge status={user.buyerSellerVerificationWorkflow} />
                              </Box>
                            )}
                          </>
                        )}
                      </AppTableCell>
                      <AppTableCell>
                        <Box display="flex" flexDirection="row" flexWrap="nowrap" alignItems="center">
                          <Avatar
                            alt={user.displayName}
                            src={user.profileImage?.fullUrl}
                            className={classes.userAvatar}
                          />
                          <Box component="span" pl={1} flexGrow={1} minWidth={0} className={classes.userNameText}>
                            <Typography
                              variant="subtitle2"
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                marginRight: '4px',
                              }}>
                              {user.displayName ? `${user.displayName}` : '-'}
                            </Typography>
                          </Box>
                        </Box>
                      </AppTableCell>
                      <AppTableCell>{user.mobilePhone ? user.mobilePhone : '-'}</AppTableCell>
                      <AppTableCell>{user.email}</AppTableCell>
                      <AppTableCell>
                        <Box>
                          {!!user.userHashId && (
                            <a
                              href={getT2HSiteUrl(`/u/${user.userHashId}`)}
                              target="_blank"
                              rel="noreferrer"
                              className={tableClasses.tableExternalLink}
                              onClick={openUserPageClickHandler}>
                              {user.userHashId !== null ? user.userHashId : '-'}
                            </a>
                          )}
                        </Box>
                      </AppTableCell>
                      <AppTableCell>
                        {t(`user:details.shopType.${user.shopType || getFallbackShopType(user)}`)}
                      </AppTableCell>
                      <AppTableCell>
                        <Typography variant="body2" className={classes.dateDisplayText}>
                          {dayjs(user.createdAt).format(`${dateDisplayFormat}`)}
                          <br />
                          {dayjs(user.createdAt).format(`HH:mm:ss`)}
                        </Typography>
                      </AppTableCell>
                      <AppTableCell>{user.balance ? user.balance.toLocaleString() : '0'}</AppTableCell>
                      <AppTableCell>
                        {user.nationality ? (
                          <Typography variant="body2">
                            {t(
                              `user:common.fields.${
                                user.nationality === 'Thai' ? 'nationalityTh' : 'nationalityForeign'
                              }`,
                            )}
                          </Typography>
                        ) : (
                          '-'
                        )}
                      </AppTableCell>
                      <AppTableCell>
                        <a
                          href={`/v3/users/${user?.userHashId}?tab=shopping`}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}>
                          <AppButton variant="contained" style={{ color: '#b07f05', marginTop: '6px' }}>
                            <img src={iconCoin} width={24} height={24} alt="coin" />
                            <Typography variant="subtitle2" style={{ marginLeft: 4, fontWeight: 'bold' }}>
                              {t('user:list.columns.coin')}
                            </Typography>
                          </AppButton>
                        </a>
                      </AppTableCell>
                    </TableRow>
                  ))}
                </>
              )
            }
          />
        </Box>
      </Container>
    </>
  );
};

export default UserListTable;
