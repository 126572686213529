import React, { FC } from 'react';

import { Box, Container, Grid, makeStyles, MenuItem, OutlinedInput, Select, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import YoutubeSearchedForIcon from '@material-ui/icons/YoutubeSearchedFor';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SelectItem, UserRole } from '../../../../../models';
import { FakhaListSearchFormDataTC, FakhaType } from '../../../../../models/fakha.model';
import useAppContainerStyles from '../../../../../theme/container.style';
import AppButton from '../../../../ui/AppButton';
import AppChipList from '../../../../ui/AppChipList';
import AppFormControl from '../../../../ui/AppFormControl';
import AppFormControlGroup from '../../../../ui/AppFormControlGroup';

export type FakhaListSearchFormProps = {
  isLoadingData?: boolean;
  currentFilters: FakhaListSearchFormDataTC | undefined;
  onSearchFormSubmit: (values: FakhaListSearchFormDataTC) => void;
};

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
  },
  searchBoxContainer: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 500,
    },
  },
  sellerStatusBoxContainer: {
    '& div': {
      width: 200,
    },
  },
  coinBoxContainer: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 300,
    },
  },
  coinInput: {
    maxWidth: '100px',
  },
  filterHeader: {
    fontWeight: 700,
  },
  selectedChipIcon: {
    marginRight: '4px',
    marginBottom: '4px',
    '& .MuiChip-icon': {
      order: 3,
      marginRight: '8px',
      marginLeft: '-6px',
    },
  },

  accordionBar: {
    boxShadow: 'none',
    borderTop: `solid 1px ${theme.palette.grey[300]}`,
    '& svg': {
      color: theme.palette.primary.main,
      fontSize: '30px',
    },
    margin: '0 !important',
    background: 'none',
    '&::before': {
      background: 'none',
    },
  },
  accordionSummary: {
    display: 'inline-flex',
    margin: '0 !important',
    minHeight: '60px',
    color: theme.palette.primary.main,
    '>div': {
      marginTop: '0',
    },
  },
  accordionDetail: {
    display: 'block',
    padding: '0 16px',
    margin: '0px 0px 16px 0px',
  },
}));

const searchFieldList: SelectItem<string>[] = [
  {
    label: 'fakha:list.search.displayName',
    value: 'displayName',
  },
  {
    label: 'fakha:list.search.userHashId',
    value: 'userHashId',
  },
  {
    label: 'fakha:list.search.email',
    value: 'email',
  },
  {
    label: 'fakha:list.search.mobile',
    value: 'mobilePhone',
  },
];

const searchType = [
  {
    label: 'fakha:list.filter.type.truck',
    value: FakhaType.truck,
  },
  {
    label: 'fakha:list.filter.type.heavyMachinery',
    value: FakhaType.heavyMachinery,
  },
  {
    label: 'fakha:list.filter.type.agriculturalMachinery',
    value: FakhaType.agriculturalMachinery,
  },
  {
    label: 'fakha:list.filter.type.other',
    value: FakhaType.other,
  },
];

const searchRolesStateWorkflow = [
  {
    label: 'user:v3.list.search.workflow.W3_BUYER_STATE',
    value: UserRole.Buyer,
  },
  {
    label: 'user:v3.list.search.workflow.W3_SELLER_STATE',
    value: UserRole.Seller,
  },
  {
    label: 'user:v3.list.search.workflow.W3_SELLER_GOLD_STATE',
    value: UserRole.SellerGold,
  },
  {
    label: 'user:v3.list.search.workflow.W3_SELLER_SILVER_STATE',
    value: UserRole.SellerSilver,
  },
  {
    label: 'user:v3.list.search.workflow.W3_SELLER_BRONZE_STATE',
    value: UserRole.SellerBronze,
  },
];

const FakhaListSearchForm: FC<FakhaListSearchFormProps> = (props) => {
  const { onSearchFormSubmit, currentFilters, isLoadingData = false } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const containerClasses = useAppContainerStyles();

  const { handleSubmit, control, getValues, setValue } = useForm<FakhaListSearchFormDataTC>({
    mode: 'onSubmit',
    defaultValues: {
      searchField: currentFilters?.searchField || searchFieldList[0].value,
      searchKeyword: currentFilters?.searchKeyword || '',
      filteringType: currentFilters?.filteringType || [],
      filteringRoleStateWorkflow: currentFilters?.filteringRoleStateWorkflow || [],
    },
  });

  const submitSearchForm = () => {
    if (!isLoadingData) {
      setValue('searchKeyword', getValues('searchKeyword').trim());
      handleSubmit(onSearchFormSubmit)();
    }
  };

  const clearSearchForm = () => {
    if (!isLoadingData) {
      setValue('searchField', searchFieldList[0].value);
      setValue('searchKeyword', '');
      setValue('filteringType', []);
      setValue('filteringRoleStateWorkflow', []);
      handleSubmit(onSearchFormSubmit)();
    }
  };

  return (
    <Box className={classes.filterContainer}>
      <Container className={containerClasses.container}>
        <form onSubmit={handleSubmit(onSearchFormSubmit)}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} md container spacing={3}>
              <Grid item xs={12} md="auto">
                <Box display="flex" flexDirection="row" flexWrap="nowrap" className={classes.searchBoxContainer}>
                  <AppFormControlGroup flexGrow={1} minWidth={0} pr={1}>
                    <AppFormControl margin="dense">
                      <Controller
                        name="searchField"
                        control={control}
                        defaultValue={searchFieldList[0].value}
                        render={({ field }) => (
                          <Select
                            labelId="user-list-search-field-label"
                            id="user-list-search-field"
                            value={field.value}
                            variant="outlined"
                            onChange={field.onChange}
                            disabled={isLoadingData}
                            fullWidth>
                            {searchFieldList.map((item) => (
                              <MenuItem value={item.value} key={item.value}>
                                {t(item.label)}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </AppFormControl>
                    <AppFormControl margin="dense" boxProps={{ flexGrow: 1 }}>
                      <Controller
                        name="searchKeyword"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <OutlinedInput
                            type="search"
                            endAdornment={<SearchIcon />}
                            id="user-list-search-keyword"
                            value={field.value}
                            onChange={field.onChange}
                            fullWidth
                            disabled={isLoadingData}
                          />
                        )}
                      />
                    </AppFormControl>
                  </AppFormControlGroup>
                  <AppButton type="submit" color="primary" style={{ alignSelf: 'flex-start' }} disabled={isLoadingData}>
                    {t('common:message.search')}
                  </AppButton>
                </Box>
              </Grid>

              <Grid container direction="row" spacing={2} item xs={12}>
                <Grid item sm={12} md={6}>
                  <Typography variant="body2" className={classes.filterHeader}>
                    {t('fakha:list.filter.listingType')}
                  </Typography>
                  <Box display="flex" flexDirection="row" flexWrap="wrap" mt={1}>
                    <Controller
                      control={control}
                      name="filteringType"
                      defaultValue={currentFilters?.filteringType || []}
                      render={({ field }) => (
                        <>
                          <AppChipList
                            items={searchType}
                            selectedValues={field.value}
                            onChange={(values: string[]) => {
                              field.onChange(values);
                              submitSearchForm();
                            }}
                            isDisabled={isLoadingData}
                          />
                        </>
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item sm={12} md={6}>
                  <Box pr={3}>
                    <Typography variant="body2" className={classes.filterHeader}>
                      {t('fakha:list.filter.roleStateWorkflow')}
                    </Typography>
                    <Box display="flex" flexDirection="row" flexWrap="wrap" mt={1}>
                      <Controller
                        control={control}
                        name="filteringRoleStateWorkflow"
                        defaultValue={currentFilters?.filteringRoleStateWorkflow || []}
                        render={({ field }) => (
                          <>
                            <AppChipList
                              items={searchRolesStateWorkflow}
                              selectedValues={field.value}
                              onChange={(values: string[]) => {
                                field.onChange(values);
                                submitSearchForm();
                              }}
                              isDisabled={isLoadingData}
                            />
                          </>
                        )}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md="auto">
              <AppButton variant="outlined" color="primary" onClick={clearSearchForm} disabled={isLoadingData}>
                <YoutubeSearchedForIcon />
              </AppButton>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Box>
  );
};

export default FakhaListSearchForm;
