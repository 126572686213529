import React, { FC } from 'react';

import interviewLogRoutes from './route.config';
import ModuleMainPage from '../../../components/templates/ModuleMainPage';

const InterviewLogMain: FC = () => (
  <ModuleMainPage pageTitle="common:title.interviewLog" routeFunctions={interviewLogRoutes} />
);

export default InterviewLogMain;
