import React, { FC } from 'react';

import { Box, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';

import { MessageGroup } from '../../../../models/message-group';

export type MessageGroupItemListProps = {
  data: MessageGroup;
  isSelected?: boolean;
};

const useStyles = makeStyles((theme) => ({
  groupChat: {
    borderBottom: `solid 1px ${theme.palette.divider}`,
    padding: '16px',
    position: 'relative',
    cursor: 'pointer',
    '&:hover': {
      background: '#F2F6FD',
    },
  },
  selected: {
    background: '#F2F6FD',
  },
  iconAvatar: {
    border: `solid 1px ${theme.palette.divider}`,
  },
  dataTextWrap: {
    paddingRight: theme.spacing(12),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 700,
  },

  extraSmallText: {
    fontSize: '12px',
    color: theme.palette.grey[500],
  },
  timeText: {
    position: 'absolute',
    right: 0,
    top: '8px',
    padding: '12px',
  },
  chatText: {
    color: theme.palette.grey[500],
    fontWeight: 400,
  },
}));
const MessageGroupItemList: FC<MessageGroupItemListProps> = (props) => {
  const { hashId } = useParams<{ hashId: string }>();
  const classes = useStyles();
  const { isSelected = false, data } = props;
  const { members = [] } = data;

  const membersWithoutCurrentUser = members.filter((item) => item.hashId !== hashId);
  const interlocutorUserData = membersWithoutCurrentUser[0];

  return (
    <>
      <Box className={clsx(isSelected ? classes.selected : '', classes.groupChat)} display="flex" flexDirection="row">
        <Box flexGrow={1} minWidth={0}>
          <Typography className={classes.dataTextWrap}>{interlocutorUserData?.displayName || 'No name'}</Typography>
          <Typography component="span" className={clsx(classes.extraSmallText, classes.timeText)}>
            {dayjs(data.recentMessage?.readInfo[data.recentMessage?.readInfo.length - 1].readAt).format('DD MMM YY')}
          </Typography>
          <Box>
            <Typography className={clsx(classes.chatText, classes.dataTextWrap)}>
              {data.recentMessage?.content?.content}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default MessageGroupItemList;
