import React, { FC } from 'react';

import { Box, Typography, makeStyles } from '@material-ui/core';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';

import useTypographyStyles from '../../../../theme/typography.style';

export type DashboardItemProps = {
  title: string;
  totalCount: string;
  growth: string;
  isGrowing?: string;
};

const useStyles = makeStyles(() => ({
  boxIcon: {
    background: '#64D6D6',
    minWidth: '40px',
    height: '40px',
    paddingTop: '8px',
    borderRadius: '50%',
    textAlign: 'center',
  },
}));

const DashboardItem: FC<DashboardItemProps> = (props) => {
  const typoClasses = useTypographyStyles();
  const classes = useStyles();
  const { title, totalCount, growth, isGrowing } = props;

  const newUserRegister = 'static/images/icons/dashboard/new-user-register.svg';
  const newSellerRegister = 'static/images/icons/dashboard/new-seller-register.svg';
  const sellerPost = 'static/images/icons/dashboard/seller-post.svg';
  const coinConsumed = 'static/images/icons/dashboard/coin-consumed.svg';
  const coinPurchase = 'static/images/icons/dashboard/coin-purchased.svg';
  const newChatRoom = 'static/images/icons/dashboard/new-chat-room.svg';
  const createListingPost = 'static/images/icons/dashboard/create-listing-post.svg';
  const soldPost = 'static/images/icons/dashboard/sold-post.svg';
  const conversionRateAfterPurchase =
    'static/images/icons/dashboard/conversion-rate-after-enter-coin-purchase-page.svg';
  const conversionMembership = 'static/images/icons/dashboard/membership.svg';
  const chatAmount = 'static/images/icons/dashboard/chat-amount.svg';
  const completedCreateListing = 'static/images/icons/dashboard/completed-create-listing-post.svg';
  const login = 'static/images/icons/dashboard/login.svg';
  const sns = 'static/images/icons/dashboard/sns.svg';

  const getIconDashboardByType = (typeDashboard: string | '') => {
    switch (typeDashboard) {
      case 'New user register':
        return (
          <Box className={classes.boxIcon} style={{ paddingTop: '10px' }}>
            <img src={newUserRegister} alt={`${typeDashboard}`} />
          </Box>
        );
      case 'New seller register':
        return (
          <Box className={classes.boxIcon}>
            <img src={newSellerRegister} alt={`${typeDashboard}`} />
          </Box>
        );
      case 'New seller dont post':
        return (
          <Box className={classes.boxIcon} style={{ background: '#FF8F6B' }}>
            <img src={sellerPost} alt={`${typeDashboard}`} />
          </Box>
        );
      case 'Total user create listing':
        return (
          <Box className={classes.boxIcon} style={{ background: '#FF8F6B' }}>
            <CreateOutlinedIcon className={typoClasses.whiteText} />
          </Box>
        );
      case 'Coin consumed':
        return (
          <Box className={classes.boxIcon} style={{ background: '#FFBF14', paddingTop: '10px' }}>
            <img src={coinConsumed} alt={`${typeDashboard}`} />
          </Box>
        );
      case 'Coin purchased':
        return (
          <Box className={classes.boxIcon} style={{ background: '#FFBF14' }}>
            <img src={coinPurchase} alt={`${typeDashboard}`} />
          </Box>
        );
      case 'New chat room':
        return (
          <Box className={classes.boxIcon} style={{ background: '#186DFA' }}>
            <img src={newChatRoom} alt={`${typeDashboard}`} />
          </Box>
        );
      case 'Create listing post':
        return (
          <Box className={classes.boxIcon} style={{ background: '#FF8F6B' }}>
            <img src={createListingPost} alt={`${typeDashboard}`} />
          </Box>
        );
      case 'Sold post':
        return (
          <Box className={classes.boxIcon} style={{ background: '#FF8F6B' }}>
            <img src={soldPost} alt={`${typeDashboard}`} />
          </Box>
        );
      case 'Conversion rate after enter coin purchase page':
        return (
          <Box className={classes.boxIcon} style={{ background: '#FFBF14', paddingTop: '11px' }}>
            <img src={conversionRateAfterPurchase} alt={`${typeDashboard}`} />
          </Box>
        );
      case 'Conversion rate after enter membership page':
        return (
          <Box className={classes.boxIcon} style={{ background: '#FFBF14', paddingTop: '10px' }}>
            <img src={conversionMembership} alt={`${typeDashboard}`} />
          </Box>
        );
      case 'Chat amount':
        return (
          <Box className={classes.boxIcon} style={{ background: '#186DFA', paddingTop: '10px' }}>
            <img src={chatAmount} alt={`${typeDashboard}`} />
          </Box>
        );
      case 'Completed create listing post':
        return (
          <Box className={classes.boxIcon} style={{ background: '#FF8F6B', padding: '10px 0 0 4px' }}>
            <img src={completedCreateListing} alt={`${typeDashboard}`} />
          </Box>
        );
      case 'Login amount':
        return (
          <Box className={classes.boxIcon} style={{ background: '#C0DB19', paddingTop: '10px' }}>
            <img src={login} alt={`${typeDashboard}`} />
          </Box>
        );
      case 'Page view of SNS':
        return (
          <Box className={classes.boxIcon} style={{ background: '#E26DE4' }}>
            <img src={sns} alt={`${typeDashboard}`} />
          </Box>
        );
      case 'Page view':
        return (
          <Box className={classes.boxIcon} style={{ background: '#E26DE4' }}>
            <VisibilityOutlinedIcon className={typoClasses.whiteText} />
          </Box>
        );
      default:
        return (
          <Box className={classes.boxIcon}>
            <PersonOutlineIcon className={typoClasses.whiteText} />
          </Box>
        );
    }
  };

  return (
    <>
      <Box display="flex" flexDirection="row" alignItems="center">
        {title && getIconDashboardByType(title)}
        <Box ml={1}>
          <Typography variant="subtitle2">{title}</Typography>
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" mt={1}>
        <Typography variant="h5" style={{ fontWeight: 'bold' }}>
          {parseInt(totalCount, 10).toLocaleString()}
        </Typography>
        <Box display="flex" flexDirection="row" textAlign="right">
          {totalCount !== '0' ? (
            <Box display="flex" flexDirection="row">
              {isGrowing === 'true' ? (
                <TrendingUpIcon className={typoClasses.successText} style={{ marginRight: '4px' }} />
              ) : (
                <TrendingDownIcon className={typoClasses.errorText} style={{ marginRight: '4px' }} />
              )}

              <Typography
                style={{ fontWeight: 'bold' }}
                className={isGrowing === 'true' ? typoClasses.successText : typoClasses.errorText}>
                {growth}
              </Typography>
            </Box>
          ) : (
            <Typography style={{ fontWeight: 'bold' }} className={typoClasses.greyText}>
              0 %
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
};

export default DashboardItem;
