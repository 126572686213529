/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import { Controller, UseFormReturn } from 'react-hook-form';

import { AppGlobalUiContext } from '../../../../context/AppGlobalUiContext';
import { EMOJI_PATTERN, SPECIAL_CHAR } from '../../../../helpers/constants';
import { KTCategoryPathItem } from '../../../../models';
import { getESModelsByBrandSlugAndModelName, getKTModelGroupNameListByBrandSlug } from '../../../../services/model';
import AppFormControl from '../../../ui/AppFormControl';
import ModalSelectValue, { ModalSelectValueProps } from '../ModalSelectValue';

const MAX_TEXT_LENGTH = 300;

interface FieldModelProps {
  brandSlug: string;
  rootCategoryId: number;
  itemPathModel?: KTCategoryPathItem;
  itemPathModelText?: KTCategoryPathItem;
  itemPathModelName?: KTCategoryPathItem;
  currentValueModelGroup: string;
  currentValueModel: string;
  formHolder: UseFormReturn<any>;
  isEditingForm: boolean;
  isModelTypeText: boolean;
  onSetValue?: (data: { name?: string; value?: any; uid?: number } & any) => void;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const FieldModel = (props: FieldModelProps) => {
  const {
    brandSlug,
    rootCategoryId,
    itemPathModel,
    itemPathModelText,
    itemPathModelName,
    onSetValue,
    currentValueModelGroup,
    currentValueModel,
    formHolder,
    isEditingForm,
    isModelTypeText,
  } = props;

  const [modelGroupList, setModelGroupList] = React.useState<ModalSelectValueProps['dataList']>([]);
  const [modelList, setModelList] = React.useState<ModalSelectValueProps['dataList']>([]);
  const [currentValueModelGroupPreview, setCurrentValueModelGroupPreview] = React.useState<string | number | null>();
  const [currentValueModelPreview, setCurrentValueModelPreview] = React.useState<string | number | null>();
  const [isLoadingModelGroupList, setIsLoadingModelGroupList] = React.useState(false);
  const [isLoadingModelGroupListError, setIsLoadingModelGroupListError] = React.useState(false);
  const [isLoadingModelList, setIsLoadingModelList] = React.useState(false);
  const [isLoadingModelListError, setIsLoadingModelListError] = React.useState(false);
  const [isModalSelectModelGroupOpen, setIsModalSelectModelGroupOpen] = React.useState(false);
  const [isModalSelectModelOpen, setIsModalSelectModelOpen] = React.useState(false);

  const { showSnackbar } = useContext(AppGlobalUiContext);

  const [modelType, setModelType] = React.useState<'typeSelect' | 'typeText'>(
    isModelTypeText ? 'typeText' : 'typeSelect',
  );

  const {
    control,
    setValue,
    formState: { errors },
  } = formHolder;

  const isRequired = Boolean(itemPathModel?.optional) !== true || Boolean(itemPathModelText?.optional) !== true;

  const handleSetKTModelGroupListToModelGroupList = async (slug: any) => {
    setModelGroupList([]);
    setModelList([]);
    setCurrentValueModelGroupPreview('');
    setCurrentValueModelPreview('');

    if (slug) {
      setIsLoadingModelGroupList(true);
      try {
        const result = await getKTModelGroupNameListByBrandSlug(slug, { rootCategoryId });

        if (result.data) {
          const resultMapData =
            result?.data?.list?.length > 0
              ? result.data?.list.map((item) => ({
                  ...item,
                  name: item.modelName,
                  value: item.modelName,
                }))
              : [];

          setModelGroupList(resultMapData);
        }
        setIsLoadingModelGroupList(false);
        setIsLoadingModelGroupListError(false);
      } catch (error: any) {
        showSnackbar(`error: ${error.message}`, 'error');
        setModelGroupList([]);
        setIsLoadingModelGroupList(false);
        setIsLoadingModelGroupListError(true);
      }
    }
  };

  const handleSetESModelsToModelList = async (slug: string, modelName: string) => {
    if (slug && modelName) {
      setCurrentValueModelPreview('');
      setIsLoadingModelList(true);
      try {
        const result = await getESModelsByBrandSlugAndModelName(slug, modelName);

        if (result.data) {
          const resultMapData =
            result?.data.list?.length > 0
              ? result.data.list.map((item) => ({
                  ...item,
                  name: item.name,
                  value: item.slug,
                }))
              : [];

          setModelList([...resultMapData, { name: 'ไม่ระบุ', value: '' }]);
        }
        setIsLoadingModelList(false);
        setIsLoadingModelListError(false);
      } catch (error: any) {
        showSnackbar(`error: ${error.message}`, 'error');
        setModelList([]);
        setIsLoadingModelList(false);
        setIsLoadingModelListError(true);
      }
    }
  };

  const handleSetCurrentValuePreview = (value: string | number, dataList: ModalSelectValueProps['dataList']) => {
    if (value) {
      if (dataList?.length > 0) {
        const currentData = dataList?.find((item) => item.value === value);

        return currentData ? currentData?.name : value;
      }
      return value;
    }
    return '';
  };

  const handleSetValue = (data: any) => {
    if (onSetValue) {
      onSetValue(data);
    }
    setValue(data.name, data.value);
  };

  React.useEffect(() => {
    handleSetKTModelGroupListToModelGroupList(brandSlug);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandSlug]);

  React.useEffect(() => {
    handleSetESModelsToModelList(brandSlug, currentValueModelGroup);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValueModelGroup]);

  React.useEffect(() => {
    setCurrentValueModelGroupPreview(handleSetCurrentValuePreview(currentValueModelGroup, modelGroupList));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelGroupList]);

  React.useEffect(() => {
    setCurrentValueModelPreview(handleSetCurrentValuePreview(currentValueModel, modelList));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelList]);

  React.useEffect(() => {
    if (modelType === 'typeSelect' && itemPathModelText?.masterIndex?.slug) {
      setValue(itemPathModelText?.masterIndex?.slug, '');
    } else if (modelType === 'typeText') {
      handleSetValue({ name: itemPathModel?.masterIndex.slug, value: null, uid: null });
      setCurrentValueModelGroupPreview('');
      if (itemPathModelName) {
        handleSetValue({ name: itemPathModelName.masterIndex.slug, value: null, uid: null });
        setCurrentValueModelPreview('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemPathModelName, modelType]);

  if (itemPathModel === undefined && itemPathModelText === undefined) {
    return null;
  }

  return (
    <>
      {itemPathModel && itemPathModelText && (
        <RadioGroup row defaultValue="typeSelect" value={modelType}>
          <FormControlLabel
            value="typeSelect"
            control={<Radio onChange={() => setModelType('typeSelect')} color="primary" />}
            label={`เลือก${itemPathModel?.masterIndex.thaiName}`}
            labelPlacement="end"
          />
          <FormControlLabel
            value="typeText"
            control={<Radio onChange={() => setModelType('typeText')} color="primary" />}
            label={itemPathModelText?.masterIndex.thaiName}
            labelPlacement="end"
          />
        </RadioGroup>
      )}

      {modelType === 'typeSelect' && itemPathModel && modelGroupList?.length > 0 && (
        <Box>
          <Typography>
            {`เลือก${itemPathModel?.masterIndex.thaiName} ${isRequired && modelGroupList.length > 0 ? '*' : ''}`}
          </Typography>
          <>
            {/* eslint-disable-next-line no-nested-ternary */}
            {isLoadingModelGroupListError ? (
              <Button
                style={{ width: '80%' }}
                variant="outlined"
                size="small"
                color="secondary"
                onClick={async () => {
                  if (isEditingForm) {
                    handleSetKTModelGroupListToModelGroupList(brandSlug);
                  }
                }}
                disabled={isLoadingModelGroupList}>
                <Box display="flex" alignItems="center" justifyContent="center">
                  {isLoadingModelGroupList ? (
                    <CircularProgress size={16} color="secondary" />
                  ) : (
                    <InfoIcon color="secondary" fontSize="default" />
                  )}
                  <Typography style={{ paddingLeft: 2 }} variant="subtitle1">
                    {isLoadingModelGroupList ? 'กำลังโหลด...' : 'โหลดอีกครั้ง'}
                  </Typography>
                </Box>
              </Button>
            ) : isLoadingModelGroupList ? (
              <Box mt={6}>
                <CircularProgress size={20} />
              </Box>
            ) : (
              modelGroupList?.length > 0 && (
                <Button
                  style={{ width: '80%' }}
                  onClick={() => {
                    if (isEditingForm) {
                      setIsModalSelectModelGroupOpen(true);
                    }
                  }}>
                  <Controller
                    name={itemPathModel.masterIndex.slug}
                    control={control}
                    rules={{
                      required: isRequired ? `โปรดเลือก${itemPathModel.masterIndex.thaiName}` : false,
                    }}
                    render={() => (
                      <TextField
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        name={`preview-${itemPathModel.masterIndex.slug}`}
                        placeholder={
                          itemPathModel.masterIndex.placeholder || `เลือก${itemPathModel.masterIndex.thaiName}`
                        }
                        value={currentValueModelGroupPreview}
                        error={!!errors[itemPathModel.masterIndex.slug]?.message}
                        style={{ pointerEvents: 'none' }}
                        disabled={!isEditingForm}
                      />
                    )}
                  />
                  <ArrowDropDownIcon style={{ margin: 'auto', fontSize: '24px' }} color="primary" />
                </Button>
              )
            )}

            {currentValueModelGroup && isEditingForm && (
              <Button
                variant="outlined"
                onClick={() => {
                  handleSetValue({ name: itemPathModel.masterIndex.slug, value: null, uid: null });
                  setCurrentValueModelGroupPreview('');
                  if (itemPathModelName) {
                    handleSetValue({ name: itemPathModelName.masterIndex.slug, value: null, uid: null });
                    setCurrentValueModelPreview('');
                  }
                }}>
                <CloseIcon color="primary" />
              </Button>
            )}
            {errors[itemPathModel.masterIndex.slug]?.message && (
              <Typography style={{ marginTop: 2 }} color="error" variant="subtitle1">
                {errors[itemPathModel.masterIndex.slug]?.message}
              </Typography>
            )}

            {modelGroupList?.length > 0 && (
              <ModalSelectValue
                isOpen={isModalSelectModelGroupOpen}
                selectedValue={currentValueModelGroup}
                title={itemPathModel.masterIndex.thaiName}
                dataList={modelGroupList}
                onClickItem={(data: any) => {
                  handleSetValue({ name: itemPathModel.masterIndex.slug, value: data.value, uid: data.uid });
                  setCurrentValueModelGroupPreview(handleSetCurrentValuePreview(data.value, modelGroupList));
                  setCurrentValueModelPreview('');
                  handleSetValue({ name: itemPathModelName?.masterIndex.slug, value: null, uid: null });
                }}
                handleClose={() => {
                  setIsModalSelectModelGroupOpen(false);
                }}
              />
            )}
            {itemPathModelName !== undefined && modelGroupList?.length > 0 && !isLoadingModelGroupListError && (
              <>
                <Box mt={1}>
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {isLoadingModelListError ? (
                    <Button
                      style={{ width: '80%' }}
                      variant="outlined"
                      size="small"
                      color="secondary"
                      onClick={async () => {
                        if (isEditingForm) {
                          handleSetESModelsToModelList(brandSlug, currentValueModelGroup);
                        }
                      }}
                      disabled={isLoadingModelList}>
                      <Box display="flex" alignItems="center" justifyContent="center">
                        {isLoadingModelList ? (
                          <CircularProgress size={16} color="secondary" />
                        ) : (
                          <InfoIcon color="secondary" fontSize="default" />
                        )}
                        <Typography style={{ paddingLeft: 2 }} variant="subtitle1">
                          {isLoadingModelList ? 'กำลังโหลด...' : 'โหลดอีกครั้ง'}
                        </Typography>
                      </Box>
                    </Button>
                  ) : isLoadingModelList ? (
                    <Box mt={6}>
                      <CircularProgress size={20} />
                    </Box>
                  ) : (
                    modelList?.length > 0 && (
                      <>
                        <Typography>{itemPathModelName.masterIndex.thaiName}</Typography>
                        <Button
                          style={{ width: '80%' }}
                          onClick={() => {
                            if (isEditingForm) {
                              setIsModalSelectModelOpen(true);
                            }
                          }}>
                          <Controller
                            name={itemPathModelName.masterIndex.slug}
                            control={control}
                            rules={{
                              required: !itemPathModelName.optional
                                ? `โปรดเลือก${itemPathModelName.masterIndex.thaiName}`
                                : false,
                            }}
                            render={() => (
                              <TextField
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                name={`preview-${itemPathModelName.masterIndex.slug}`}
                                placeholder={
                                  itemPathModelName.masterIndex.placeholder ||
                                  `เลือก${itemPathModelName.masterIndex.thaiName}`
                                }
                                value={currentValueModelPreview}
                                error={errors[itemPathModelName.masterIndex.slug]?.message}
                                style={{ pointerEvents: 'none' }}
                                disabled={!isEditingForm}
                              />
                            )}
                          />
                          <ArrowDropDownIcon style={{ margin: 'auto', fontSize: '24px' }} color="primary" />
                        </Button>
                      </>
                    )
                  )}
                  {currentValueModel && isEditingForm && (
                    <Button
                      variant="outlined"
                      onClick={() => {
                        handleSetValue({ name: itemPathModelName.masterIndex.slug, value: null, uid: null });
                        setCurrentValueModelPreview('');
                      }}>
                      <CloseIcon color="primary" />
                    </Button>
                  )}
                </Box>
                {errors[itemPathModelName.masterIndex.slug]?.message && (
                  <Typography style={{ marginTop: 2 }} color="error" variant="subtitle1">
                    {errors[itemPathModelName.masterIndex.slug]?.message}
                  </Typography>
                )}
                {modelList?.length > 0 && (
                  <ModalSelectValue
                    isOpen={isModalSelectModelOpen}
                    selectedValue={currentValueModel}
                    title={itemPathModelName.masterIndex.thaiName}
                    dataList={modelList}
                    onClickItem={(data) => {
                      handleSetValue({ name: itemPathModelName.masterIndex.slug, value: data.value, uid: data.uid });
                      setCurrentValueModelPreview(handleSetCurrentValuePreview(data.value, modelList));
                    }}
                    handleClose={() => {
                      setIsModalSelectModelOpen(false);
                    }}
                  />
                )}
              </>
            )}
          </>
        </Box>
      )}

      {modelType === 'typeText' && itemPathModelText && (
        <Box>
          {itemPathModelText && (
            <>
              <Box my={1}>
                <Typography>{`รุ่นอื่นๆ (โปรดระบุ) ${!itemPathModelText.optional ? '*' : ''}`}</Typography>
              </Box>
              <Controller
                name={itemPathModelText?.masterIndex?.slug}
                control={control}
                render={({ field }) => (
                  <AppFormControl>
                    <TextField
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      placeholder={`ระบุ${itemPathModelText?.masterIndex?.thaiName}`}
                      error={errors[itemPathModelText?.masterIndex?.slug]?.message}
                      {...field}
                      disabled={!isEditingForm}
                    />
                  </AppFormControl>
                )}
                rules={{
                  required: !itemPathModelText.optional ? `โปรดเลือก${itemPathModelText.masterIndex.thaiName}` : false,
                  maxLength: {
                    value: MAX_TEXT_LENGTH,
                    message: `ต้องไม่เกิน ${MAX_TEXT_LENGTH} ตัวอักษร`,
                  },
                  // eslint-disable-next-line consistent-return
                  validate: async (value: any) => {
                    if (SPECIAL_CHAR.test(value) || EMOJI_PATTERN.test(value)) {
                      return `ไม่สามารถใช้ อักขระพิเศษ ${SPECIAL_CHAR.source} และ Emoji`;
                    }
                  },
                }}
              />
              {errors[itemPathModelText.masterIndex.slug]?.message && (
                <Typography style={{ marginTop: 2 }} color="error" variant="subtitle1">
                  {errors[itemPathModelText.masterIndex.slug]?.message}
                </Typography>
              )}
            </>
          )}
        </Box>
      )}
    </>
  );
};

export default FieldModel;
