import React, { FC, useCallback, useContext, useState } from 'react';

import { Box, Container, TableRow, Typography } from '@material-ui/core';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { AppGlobalUiContext } from '../../../../../context/AppGlobalUiContext';
import { AppTableConditions, InterviewLogData, InterviewLogDataRequest, TableColumn } from '../../../../../models';
import { updateInterviewLog } from '../../../../../services/v3/user';
import useAppContainerStyles from '../../../../../theme/container.style';
import useAppTableStyles from '../../../../../theme/table.style';
import AppTableCell from '../../../../ui/AppTableCell';
import AppTableList from '../../../../ui/AppTableList';
import InterviewLogItemDialog from '../../users/UserDetail/InterviewLog/InterviewLogItemDialog';

export type InterviewLogListTableProps = {
  currentConditions: AppTableConditions;
  totalLogs: number;
  isLoadingData: boolean;
  interviewLogList: InterviewLogData[];
  onTableConditionsChanged: (values: AppTableConditions) => void;
  onFetch: () => void;
};

const InterviewLogListTable: FC<InterviewLogListTableProps> = (props) => {
  const { t, i18n } = useTranslation();
  const { totalLogs, isLoadingData, currentConditions, interviewLogList, onTableConditionsChanged, onFetch } = props;
  const containerClasses = useAppContainerStyles();
  const tableClasses = useAppTableStyles();
  const { showSnackbar, setAppLoading } = useContext(AppGlobalUiContext);
  const [tableConditions, setTableConditions] = useState<AppTableConditions>(currentConditions);
  const dateDisplayFormat = i18n.language === 'th' ? 'D MMM BBBB' : 'D MMM YYYY';
  const [selectedLogItem, setSelectedLogItem] = useState<InterviewLogData | undefined>(undefined);
  const [isInterviewLogDialogOpen, setIsInterviewLogDialogOpen] = useState<boolean>(false);

  const interviewLogTableColumns: TableColumn[] = [
    { name: 'userHashId', label: 'interview-log:list.columns.userHashId', width: 120, unsortable: true },
    {
      name: 'email',
      label: 'interview-log:list.columns.email',
      width: 300,
      unsortable: true,
    },
    { name: 'mobile', label: 'interview-log:list.columns.mobile', width: 140, unsortable: true },
    { name: 'contactBy', label: 'interview-log:list.columns.contactBy', width: 100, unsortable: true },
    {
      name: 'contactType',
      label: 'interview-log:list.columns.contactType',
      width: 220,
      unsortable: true,
    },
    { name: 'comment', label: 'interview-log:list.columns.comment', width: 400, unsortable: true },
    { name: 'fileImage', label: 'interview-log:list.columns.fileImage', width: 120, unsortable: true },
    { name: 'status', label: 'interview-log:list.columns.status', width: 200, unsortable: true },
    { name: 'createdAt', label: 'interview-log:list.columns.createdAt', width: 120 },
    { name: 'updatedAt', label: 'interview-log:list.columns.updatedAt', width: 120, unsortable: true },
    { name: 'createdBy', label: 'interview-log:list.columns.createdBy', width: 160, unsortable: true },
  ];

  const onTableConditionChangedHandler = useCallback(
    (values: AppTableConditions) => {
      if (JSON.stringify(values) !== JSON.stringify(tableConditions)) {
        setTableConditions(values);
        onTableConditionsChanged(values);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableConditions],
  );

  const openInterviewDetailPage = useCallback(async (item: InterviewLogData) => {
    setSelectedLogItem(item);
    setIsInterviewLogDialogOpen(true);
  }, []);

  const onEditFormSubmit = useCallback(
    async (data?: InterviewLogDataRequest) => {
      if (selectedLogItem && data) {
        setAppLoading(true);

        const result = await updateInterviewLog(selectedLogItem?.hashId, data);

        if (result.data) {
          setAppLoading(false);
          setSelectedLogItem(result.data);
          showSnackbar(t('interview-log:message.updateInterviewLogSuccess').toString(), 'success');
          setIsInterviewLogDialogOpen(false);
          onFetch();
        } else {
          setAppLoading(false);
          showSnackbar(t('interview-log:message.saveFailed').toString(), 'error');
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedLogItem, setAppLoading, showSnackbar, t],
  );

  return (
    <>
      <Container className={containerClasses.container}>
        <Box className={tableClasses.tableContainer}>
          {!isLoadingData && (
            <Typography
              variant="subtitle2"
              color="textSecondary"
              style={{ marginBottom: '8px', marginRight: '8px', textAlign: 'right' }}>
              {t('common:list.resultTotal', { fieldName: t(totalLogs.toLocaleString() || '0') })}
            </Typography>
          )}

          <AppTableList
            {...tableConditions}
            aria-label="interview-log-list"
            columns={interviewLogTableColumns}
            totalDataCount={totalLogs}
            hasPagination
            onTableConditionChanged={onTableConditionChangedHandler}
            isLoading={isLoadingData}
            tableBodyContent={
              interviewLogList.length > 0 && (
                <>
                  {interviewLogList.map((item: InterviewLogData) => (
                    <TableRow
                      key={item.hashId}
                      classes={{ root: clsx(tableClasses.tableRow, tableClasses.clickableTableRow) }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        openInterviewDetailPage(item);
                      }}>
                      <AppTableCell>
                        <a
                          href={`users/${item.userHashId}?tab=interview&hashId=${item.hashId}`}
                          target="_blank"
                          rel="noreferrer"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}>
                          {item.userHashId}
                        </a>
                      </AppTableCell>
                      <AppTableCell>{item.userEmail ? item.userEmail : '-'}</AppTableCell>
                      <AppTableCell>
                        <Typography variant="subtitle2">{item.userMobilePhone ? item.userMobilePhone : '-'}</Typography>
                      </AppTableCell>
                      <AppTableCell>
                        <Typography variant="subtitle2">{item.contactBy}</Typography>
                      </AppTableCell>
                      <AppTableCell>
                        <Typography variant="subtitle2">
                          {item.contactType ? t(`interview-log:contactType.${item.contactType}`) : '-'}
                        </Typography>
                      </AppTableCell>

                      <AppTableCell style={{ whiteSpace: 'normal' }}>
                        <Typography variant="subtitle2">{item.comment}</Typography>
                      </AppTableCell>
                      <AppTableCell>
                        {item.otherFiles?.length && item.otherFiles?.length > 0 ? (
                          <Typography variant="subtitle2" color="textSecondary">
                            {t('interview-log:list.columns.totalImages', {
                              fieldName: t(item.otherFiles?.length.toLocaleString()),
                            })}
                          </Typography>
                        ) : (
                          '-'
                        )}
                      </AppTableCell>
                      <AppTableCell>
                        <Typography variant="subtitle2">
                          {item.status ? t(`interview-log:status.${item.status}`) : '-'}
                        </Typography>
                      </AppTableCell>
                      <AppTableCell>
                        {item.createdAt ? (
                          <Typography variant="subtitle2">
                            {dayjs(item.createdAt).format(`${dateDisplayFormat}`)}
                            <br />
                            {dayjs(item.createdAt).format(`HH:mm:ss`)}
                          </Typography>
                        ) : (
                          <Typography>-</Typography>
                        )}
                      </AppTableCell>
                      <AppTableCell>
                        {item.updatedAt ? (
                          <Typography variant="subtitle2">
                            {dayjs(item.updatedAt).format(`${dateDisplayFormat}`)}
                            <br />
                            {dayjs(item.updatedAt).format(`HH:mm:ss`)}
                          </Typography>
                        ) : (
                          <Typography>-</Typography>
                        )}
                      </AppTableCell>
                      <AppTableCell style={{ whiteSpace: 'normal' }}>{item.crDisplayName}</AppTableCell>
                    </TableRow>
                  ))}
                </>
              )
            }
          />

          {selectedLogItem && (
            <InterviewLogItemDialog
              key={selectedLogItem.hashId}
              title={t('interview-log:list.columns.comment')}
              open={isInterviewLogDialogOpen}
              onClose={() => {
                setIsInterviewLogDialogOpen(false);
              }}
              onCloseClick={() => {
                setIsInterviewLogDialogOpen(false);
                setSelectedLogItem(undefined);
              }}
              interviewLogItem={selectedLogItem}
              onFormSubmit={onEditFormSubmit}
            />
          )}
        </Box>
      </Container>
    </>
  );
};

export default InterviewLogListTable;
