import { apiTCRequest } from '../../helpers/request';
import { queryParamGenerator } from '../../helpers/utils';
import {
  ApiResponse,
  GetSellerApplicationTCResponse,
  SellerApplicationFormDataTC,
  SellerApplicationTC,
  SellerSearchFiltersTC,
} from '../../models';
import { UserApplication } from '../../models/user-application.model';

const getSellerApplicationListQueryParam = (param?: SellerSearchFiltersTC): string => {
  const reqParam: { [x: string]: string | number } = {
    page: param?.page || 0, // plus 1 for index shift between FE component and backend query
    per_page: param?.rowsPerPage || 10,
    sort: param?.sortColumn ? `${param?.sortColumn || 'id'}%7C${(param?.sortDirection || 'desc').toUpperCase()}` : '',
  };

  if (param?.searchKeyword && param?.searchField) {
    reqParam[param.searchField] = param.searchKeyword.trim();
  }

  if (param?.filteringStatuses?.length) {
    reqParam.sellerApplicationState = param.filteringStatuses.join(',');
  }

  if (param?.q) {
    reqParam.q = param.q;
  }

  return queryParamGenerator(reqParam);
};

export const getSellerApplicationList = async (
  param?: SellerSearchFiltersTC,
): Promise<ApiResponse<GetSellerApplicationTCResponse>> => {
  try {
    const queryParamString = getSellerApplicationListQueryParam(param);
    const result = await apiTCRequest<GetSellerApplicationTCResponse>(`/admin/seller-application/?${queryParamString}`);

    return {
      data: result.data ?? null,
    };
  } catch (e) {
    return {
      data: null,
      error: 'seller-applications:error.list.requestFailed',
    };
  }
};

export const getSellerApplicationByHashId = async (hashId: string): Promise<ApiResponse<SellerApplicationTC>> => {
  try {
    const result = await apiTCRequest<SellerApplicationTC>(`/admin/seller-application/${hashId}`);

    return {
      data: result.data,
    };
  } catch (e) {
    return {
      data: null,
      error: 'seller-applications:error.detail.requestFailed',
    };
  }
};

export const approveSellerApplicationByHashId = async (hashId: string): Promise<ApiResponse<UserApplication>> => {
  try {
    const result = await apiTCRequest<UserApplication>(`/admin/seller-application/approve/${hashId}`, {
      method: 'POST',
    });
    return {
      data: result.data,
    };
  } catch (e) {
    return {
      data: null,
      error: 'Error approve Seller Application By hashId',
    };
  }
};

export const approveApplication = async (
  hashId: string,
  saveData: SellerApplicationFormDataTC,
): Promise<ApiResponse<SellerApplicationTC>> => {
  try {
    const isAcceptEmailEng = saveData.nationality === 'Foreign';
    const result = await apiTCRequest<SellerApplicationTC>(`/admin/seller-application/approve/${hashId}`, {
      method: 'PATCH',
      headers: {
        'Accept-Language': isAcceptEmailEng ? 'EN' : 'TH',
      },
      data: {
        verifiedFullName: saveData.verifiedFullName,
        verifiedCompanyName: saveData.verifiedCompanyName,
        passportNumber: saveData.passportNumber,
        companyTaxId: saveData.companyTaxId,
        nationalId: saveData.nationalId,
        province: saveData.province,
        district: saveData.district,
        address: saveData.address,
        zipcode: saveData.zipcode,
        birthday: saveData.birthday,
        nationality: saveData.nationality,
        gender: saveData.gender,
        bookBankNumber: saveData.bookBankNumber,
        bookBankCompanyHashId: saveData.bookBankCompanyHashId,
        isNewSellerCampaign: saveData.isNewSellerCampaign,
        comment: saveData.comment,
        interviewLogComment: saveData.interviewLogComment,
      },
    });

    return {
      data: result.data,
    };
  } catch (e) {
    return {
      data: null,
      error: 'seller-applications:error.detail.approveFailed',
    };
  }
};

export const editingSellerApplication = async (
  hashId: string,
  reason: string,
): Promise<ApiResponse<SellerApplicationTC>> => {
  try {
    const result = await apiTCRequest<SellerApplicationTC>(`/admin/seller-application/waiting-for-editing/${hashId}`, {
      method: 'PATCH',
      data: {
        comment: reason,
      },
    });

    return {
      data: result.data,
    };
  } catch (e) {
    return {
      data: null,
      error: 'Error Editing Seller Application By hashId',
    };
  }
};

export const rejectSellerApplication = async (
  hashId: string,
  reason: string,
): Promise<ApiResponse<SellerApplicationTC>> => {
  try {
    const result = await apiTCRequest<SellerApplicationTC>(`/admin/seller-application/reject/${hashId}`, {
      method: 'PATCH',
      data: {
        comment: reason,
      },
    });

    return {
      data: result.data,
    };
  } catch (e) {
    return {
      data: null,
      error: 'Error Reject Seller Application By hashId',
    };
  }
};

export const deletedSellerApplication = async (
  hashId: string,
  reason: string,
): Promise<ApiResponse<SellerApplicationTC>> => {
  try {
    const result = await apiTCRequest<SellerApplicationTC>(`/admin/seller-application/deleted/${hashId}`, {
      method: 'PATCH',
      data: {
        comment: reason,
      },
    });

    return {
      data: result.data,
    };
  } catch (e) {
    return {
      data: null,
      error: 'Error Deleted Seller Application By hashId',
    };
  }
};

export const blacklistSellerApplication = async (
  hashId: string,
  reason: string,
): Promise<ApiResponse<SellerApplicationTC>> => {
  try {
    const result = await apiTCRequest<SellerApplicationTC>(`/admin/seller-application/black-list-reject/${hashId}`, {
      method: 'PATCH',
      data: {
        comment: reason,
      },
    });

    return {
      data: result.data,
    };
  } catch (e) {
    return {
      data: null,
      error: 'Error Blacklist Seller Application By hashId',
    };
  }
};
