import { AppTableConditions } from './common';
import { UserDataTC, UserImage } from './user.model';

export enum OrderStateWorkflow {
  open = 'W11_OPEN_STATE',
  paid = 'W11_PAID_STATE',
  canceled = 'W11_CANCELED_STATE',
  expired = 'W11_EXPIRED_STATE',
  failed = 'W11_FAILED_STATE',
  refunded = 'W11_REFUNDED_STATE',
}
export enum PaymentBy {
  cr = 'CR',
  promptPay = 'PROMPT_PAY',
  creditCard = 'CREDIT_CARD',
}

export type OmisePromptPayDetail = {
  currency: string;
  amount: number;
  qrCodeUri: string;
  qrCodeCreatedAt: number;
};

export type OmiseCreditCardDetail = {
  brand: string;
  lastDigits: string;
  bank: string;
  name: string;
  expirationYear: number;
  expirationMonth: number;
  authorizeUri: string;
};

export type OrderOnceProductData = {
  productHashId: string;
  productType: string;
  productName: string;
  amount: number;
  productUnit: string;
  purchaseType: string;
  membershipType: string;
  coinFree: number;
  updatedAt: number;
  productAmount: number;
  perPrice: number;
  totalPrice: number;
};

export interface OrderOnceData {
  orderOnceHashId: string;
  shoppingCart: {
    products: OrderOnceProductData[];
    promoCode: string;
    freeCoinFromPromoCode: number;
    totalPrice: number;
    subTotal: number;
    totalDiscount: number;
  };
  orderStateWorkflow: OrderStateWorkflow;
  paymentEvidentFile?: UserImage | null;
  omiseChargeId: string;
  userOrderSubscriptionHashId: string;
  omiseCreditCardDetail: OmiseCreditCardDetail | null;
  omisePromptPayDetail: OmisePromptPayDetail | null;
  createdAt: number;
  cr: UserDataTC;
  confirmedByCr: UserDataTC;
  updatedAt: number;
  user: UserDataTC;
}

export interface GetOrderOnceListTCResponse {
  contents: OrderOnceData[];
  page?: {
    size?: number;
    totalElements?: number;
    totalPages?: number;
    number?: number;
  };
}

export type OrderOnceListSearchParamsTC = AppTableConditions;

export type OrderOnceFiltersTC = Partial<OrderOnceListSearchParamsTC> & {
  q?: string;
};

export interface OrderSubscriptionOpenData {
  orderSubscriptionHashId: string;
  userOrderOnce: OrderOnceData[];
  shoppingCart: {
    products: OrderOnceProductData[];
    promoCode: string;
    freeCoinFromPromoCode: number;
    totalPrice: number;
    subTotal: number;
    totalDiscount: number;
  };
  omiseCreditCardDetail: OmiseCreditCardDetail | null;
  totalPrice: number;
  nextOccurrencesOn: string[];
  createdAt: number;
}

export type OrderListSearchFormDataTC = {
  searchField: string;
  searchKeyword: string;
  filteringStatus: string[];
  filteringPayment: string[];
};

export type OrderListSearchParamsTC = OrderListSearchFormDataTC & AppTableConditions;

export type OrderFiltersTC = Partial<OrderListSearchParamsTC> & {
  q?: string;
};
