import React, { FC } from 'react';

import { Box, Container, Grid, makeStyles, OutlinedInput } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import YoutubeSearchedForIcon from '@material-ui/icons/YoutubeSearchedFor';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { MenuListSearchFormData } from '../../../../models';
import useAppContainerStyles from '../../../../theme/container.style';
import AppButton from '../../../ui/AppButton';
import AppFormControl from '../../../ui/AppFormControl';

export type MenuListSearchFormProps = {
  currentFilters: MenuListSearchFormData | undefined;
  onSearchFormSubmit: (values: MenuListSearchFormData) => void;
};

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
  },
  searchBoxContainer: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 500,
    },
  },
}));

const MenuListSearchForm: FC<MenuListSearchFormProps> = (props) => {
  const { onSearchFormSubmit, currentFilters } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const containerClasses = useAppContainerStyles();

  const { handleSubmit, control, setValue } = useForm<MenuListSearchFormData>({
    mode: 'onSubmit',
    defaultValues: {
      searchKeyword: currentFilters?.searchKeyword || '',
    },
  });

  const clearSearchForm = () => {
    setValue('searchKeyword', '');
    handleSubmit(onSearchFormSubmit)();
  };

  return (
    <Box className={classes.filterContainer}>
      <Container className={containerClasses.container}>
        <form onSubmit={handleSubmit(onSearchFormSubmit)}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} md container spacing={3}>
              <Grid item xs={12} md="auto">
                <Box display="flex" flexDirection="row" flexWrap="nowrap" className={classes.searchBoxContainer}>
                  <AppFormControl margin="dense" boxProps={{ flexGrow: 1, pr: 1 }}>
                    <Controller
                      name="searchKeyword"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <OutlinedInput
                          {...field}
                          type="search"
                          endAdornment={<SearchIcon />}
                          id="brand-list-search-keyword"
                          fullWidth
                        />
                      )}
                    />
                  </AppFormControl>
                  <AppButton type="submit" color="primary" style={{ alignSelf: 'flex-start' }}>
                    {t('common:message.search')}
                  </AppButton>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12} md="auto">
              <AppButton variant="outlined" color="primary" onClick={clearSearchForm}>
                <YoutubeSearchedForIcon />
              </AppButton>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Box>
  );
};

export default MenuListSearchForm;
