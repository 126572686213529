/* eslint-disable max-len */
import React, { FC, ReactNode, useCallback, useContext, useEffect, useState } from 'react';

import { Box, makeStyles, Typography, Grid, Paper } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import ImagePreview from '../../components/partials/common/ImagePreview';
import ImageThumbnail from '../../components/partials/common/ImageThumbnail';
import SellerApplicationDetailForm from '../../components/partials/seller-applications/SellerApplicationDetailForm';
import SellerApplicationStatusBadge from '../../components/partials/seller-applications/SellerApplicationStatusBadge';
import AppButton from '../../components/ui/AppButton';
import { AppGlobalUiContext } from '../../context/AppGlobalUiContext';
import { getT2HSiteUrl } from '../../helpers/utils';
import { ApiResponse, SellerApplication, SellerApplicationFormData } from '../../models';
import * as sellerAppService from '../../services/seller-applications';
import useTypographyStyles from '../../theme/typography.style';

type InfoBlockProps = {
  label: string;
  data: ReactNode;
  endAdornment?: ReactNode;
  noWrap?: boolean;
};

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'stretch',
    height: 'auto',
    minHeight: `calc(100vh - 48px)`,
    padding: '12px',
  },
  flexBreak: {
    flexBasis: '100%',
  },
  leftColumn: {
    position: 'relative',
  },
  rightColumn: {
    [theme.breakpoints.up('md')]: {
      borderLeft: `solid 1px ${theme.palette.divider}`,
    },
  },
  infoTitleBlock: {
    [theme.breakpoints.up('md')]: {
      flexBasis: '160px',
    },
  },
  titleBlock: {
    [theme.breakpoints.up('md')]: {
      padding: '5px',
      paddingLeft: 0,
    },
  },
  dataBlock: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.up('md')]: {
      padding: '5px',
    },
  },
  dataText: {
    marginRight: theme.spacing(1),
  },
  dataTextWrap: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  imageThumbnailContainer: {
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
    cursor: 'pointer',
  },
  imageDownloadButton: {
    cursor: 'pointer',
    opacity: 0.6,
    '&:hover': {
      opacity: 1,
    },
  },
  imageThumbnailBlock: {
    height: '175px',
    overflow: 'hidden',
    backgroundColor: theme.palette.grey[200],
  },
  thumbnailImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  imagePreviewContainer: {
    position: 'fixed',
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    zIndex: 10,
    top: '48px',
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: theme.customPalette.global.darkOverlay.backgroundColor,
    paddingTop: '50px',
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      top: 0,
    },
  },
  imagePreviewClose: {
    position: 'absolute',
    top: '6px',
    right: '12px',
    width: '44px',
    height: '44px',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    color: theme.palette.common.white,
    borderRadius: '50%',
  },
  imagePreviewBlock: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: '60px',
    right: 0,
    bottom: '100px',
    left: 0,
    overflow: 'auto',
    '-webkit-overflow-scrolling': 'touch',
  },
  imagePreviewRotate: {
    position: 'absolute',
    bottom: '70px',
    right: '12px',
    color: theme.palette.common.white,
    cursor: 'pointer',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    userSelect: 'none',
  },
  imagePreviewZoom: {
    position: 'absolute',
    bottom: '70px',
    left: '12px',
    color: theme.palette.common.white,
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    userSelect: 'none',
  },
  imagePreviewReset: {
    position: 'absolute',
    bottom: '70px',
    left: '50%',
    transform: 'translateX(-50%)',
    color: theme.palette.common.white,
    userSelect: 'none',
  },
  zoomButton: {
    cursor: 'pointer',
  },
  zoomButtonDisabled: {
    cursor: 'default',
    opacity: 0.5,
  },
  previewImage: {
    width: 'auto',
    height: 'auto',
  },
  previewImageZoomFit: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  previewImageZoom1: {
    width: '150%',
    height: 'auto',
    maxWidth: 'initial',
    maxHeight: 'initial',
  },
  previewImageZoom2: {
    width: '200%',
    height: 'auto',
    maxWidth: 'initial',
    maxHeight: 'initial',
  },
  previewImageZoom3: {
    width: '300%',
    height: 'auto',
    maxWidth: 'initial',
    maxHeight: 'initial',
  },
  previewImageZoom4: {
    width: '400%',
    height: 'auto',
    maxWidth: 'initial',
    maxHeight: 'initial',
  },
  imageRotationNone: {
    transform: 'rotateZ(0)',
  },
  imageRotation90: {
    transform: 'rotateZ(90deg)',
  },
  imageRotation180: {
    transform: 'rotateZ(180deg)',
  },
  imageRotation270: {
    transform: 'rotateZ(270deg)',
  },
  extLinkIcon: {
    fontSize: 18,
    verticalAlign: 'middle',
    marginLeft: 3,
  },
}));

const SellerApplicationEdit: FC = () => {
  const { hashId } = useParams<{ hashId: string }>();
  const { t, i18n } = useTranslation();
  const typoClasses = useTypographyStyles();
  const classes = useStyles();
  const { showSnackbar, setAppLoading, setAppBarBackButtonUrl } = useContext(AppGlobalUiContext);
  const [sellerApplication, setSellerApplication] = useState<SellerApplication | null>(null);
  const dateDisplayFormat = i18n.language === 'th' ? 'D MMM BBBB' : 'D MMM YYYY';
  const [selectedPreviewImage, setSelectedPreviewImage] = useState<string | undefined>(undefined);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useEffect((): any => {
    let isSubscribed = true;

    if (isSubscribed) {
      setAppLoading(true);
      setAppBarBackButtonUrl('/seller-applications');

      if (hashId) {
        sellerAppService
          .getSellerApplicationByHashId(hashId)
          .then((result) => {
            if (result.data) {
              setSellerApplication(result.data);
            } else {
              showSnackbar(t(result.error as string).toString(), 'error');
            }
          })
          .finally(() => {
            setAppLoading(false);
          });
      }
    }

    return () => (isSubscribed = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const InfoBlock: FC<InfoBlockProps> = (blockProps) => {
    const { label, data, endAdornment = undefined, noWrap = false } = blockProps;

    return (
      <>
        <div className={classes.flexBreak} />
        <Grid item xs={12} classes={{ root: classes.infoTitleBlock }}>
          <Box className={classes.titleBlock}>{`${t(label)} :`}</Box>
        </Grid>
        <Grid item xs={12} md zeroMinWidth>
          <Box display="flex" flexWrap="wrap" flexDirection="row" className={classes.dataBlock}>
            <Typography
              component="span"
              className={clsx(classes.dataText, {
                [classes.dataTextWrap]: !noWrap,
              })}>
              {data || '-'}
            </Typography>
            {endAdornment}
          </Box>
        </Grid>
      </>
    );
  };

  const VerifiedText = () => (
    <Typography component="span" className={clsx(typoClasses.successText, typoClasses.textWeightBold)}>
      {`(${t('common:message.verified')})`}
    </Typography>
  );

  const onEditFormSubmit = useCallback(
    async (formData: SellerApplicationFormData) => {
      console.log('notworks');
      if (sellerApplication && !isSubmitting) {
        console.log('works');
        setIsSubmitting(true);
        let result: ApiResponse<SellerApplication> | undefined;

        if (formData.status === 'approved') {
          result = await sellerAppService.approveApplication(sellerApplication.hashId, formData);
        } else if (formData.status === 'rejected') {
          result = await sellerAppService.rejectApplication(sellerApplication.hashId, formData.rejectReason);
        } else if (formData.status === 'pending') {
          result = await sellerAppService.setApplicationPending(sellerApplication.hashId);
        }

        if (result) {
          if (result.data) {
            showSnackbar(t(`seller-applications:common.message.${formData.status}Success`).toString(), 'success');
            setSellerApplication(result.data);
          } else {
            showSnackbar(t(result.error).toString(), 'error');
          }
        }
        setIsSubmitting(false);
      }
    },
    [isSubmitting, sellerApplication, showSnackbar, t],
  );

  const onThumbnailClicked = (imageUrl: string) => {
    if (imageUrl) {
      setSelectedPreviewImage(imageUrl);
    }
  };

  return (
    <>
      <Paper classes={{ root: classes.paper }}>
        {!!sellerApplication && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} className={classes.leftColumn}>
              <Box px={3}>
                <Box display="flex" my={2} flexWrap="wrap">
                  <Box mr={1} mb={1}>
                    <Typography component="h4" variant="body1" className={typoClasses.textWeightBold}>
                      {sellerApplication.user.username}
                    </Typography>
                  </Box>
                  <Box>
                    <SellerApplicationStatusBadge status={sellerApplication.status} />
                  </Box>
                </Box>
                <Grid container>
                  <InfoBlock
                    label="seller-applications:details.label.applyDate"
                    data={
                      <>
                        {`${dayjs(sellerApplication.createdAt).format(`${dateDisplayFormat}`)} / ${dayjs(
                          sellerApplication.createdAt,
                        ).format(`HH:mm:ss`)}`}
                      </>
                    }
                  />
                  <InfoBlock
                    label="seller-applications:details.label.applyType"
                    data={t(`seller-applications:common.applicationType.${sellerApplication.type}`)}
                  />
                  {sellerApplication.type === 'corporation' && (
                    <>
                      <InfoBlock
                        label="seller-applications:details.label.componyName"
                        data={sellerApplication.companyName}
                      />
                      <InfoBlock
                        label="seller-applications:details.label.companyTaxId"
                        data={sellerApplication.companyTaxId}
                      />
                    </>
                  )}
                  <InfoBlock
                    label="user:common.fields.mobile"
                    data={sellerApplication.user.mobile}
                    endAdornment={!!sellerApplication.user.isMobileVerified && <VerifiedText />}
                  />
                  <InfoBlock
                    label="user:common.fields.email"
                    data={sellerApplication.user.email}
                    endAdornment={!!sellerApplication.user.isEmailVerified && <VerifiedText />}
                  />
                  <InfoBlock
                    label="seller-applications:details.label.hashId"
                    data={
                      <a href={getT2HSiteUrl(`/u/${sellerApplication.user.hashId}`)} target="_blank" rel="noreferrer">
                        {sellerApplication.user.hashId}
                        <OpenInNewIcon className={classes.extLinkIcon} />
                      </a>
                    }
                  />
                  <Link
                    to={`/users/${sellerApplication.user.hashId}`}
                    target="_blank"
                    className={typoClasses.noDecorationText}>
                    <AppButton variant="outlined" color="primary">
                      {t('seller-applications:details.label.linkToUser')}
                    </AppButton>
                  </Link>
                  {sellerApplication.comment && (
                    <InfoBlock
                      label="seller-applications:details.label.rejectReason"
                      data={sellerApplication.comment}
                      noWrap
                    />
                  )}
                </Grid>
                <Box mt={2}>
                  <Grid container spacing={1}>
                    {!!sellerApplication.dbdFile && sellerApplication.type === 'corporation' && (
                      <Grid item xs={12} sm={6}>
                        <ImageThumbnail
                          listingHashId=""
                          imageUrl={sellerApplication.dbdFile.url}
                          altText="DBD document file"
                          thumbnailLabel={t('seller-applications:details.label.dbdFile')}
                          imageClick={onThumbnailClicked}
                        />
                      </Grid>
                    )}
                    {!!sellerApplication.nationalIdCardFile && (
                      <Grid item xs={12} sm={6}>
                        <ImageThumbnail
                          listingHashId=""
                          imageUrl={sellerApplication.nationalIdCardFile.url}
                          altText={`${t('seller-applications:details.label.nationalIdCard')} / ${t(
                            'seller-applications:details.label.passport',
                          )}`}
                          thumbnailLabel={`${t('seller-applications:details.label.nationalIdCard')} / ${t(
                            'seller-applications:details.label.passport',
                          )}`}
                          imageClick={onThumbnailClicked}
                        />
                      </Grid>
                    )}

                    {!!sellerApplication.selfieFile && (
                      <Grid item xs={12} sm={6}>
                        <ImageThumbnail
                          listingHashId=""
                          imageUrl={sellerApplication.selfieFile.url}
                          altText="Selfie file"
                          thumbnailLabel={t('seller-applications:details.label.idSelfiePic')}
                          imageClick={onThumbnailClicked}
                        />
                      </Grid>
                    )}
                    {!!sellerApplication.bankBookFile && (
                      <Grid item xs={12} sm={6}>
                        <ImageThumbnail
                          listingHashId=""
                          imageUrl={sellerApplication.bankBookFile.url}
                          altText="Bank account file"
                          thumbnailLabel={t('seller-applications:details.label.bookBankPic')}
                          imageClick={onThumbnailClicked}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </Box>
              {!!selectedPreviewImage && (
                <ImagePreview imageUrl={selectedPreviewImage} onCloseClick={() => setSelectedPreviewImage(undefined)} />
              )}
            </Grid>
            <Grid item xs={12} md={6} className={classes.rightColumn}>
              {!!sellerApplication && (
                <SellerApplicationDetailForm sellerApplication={sellerApplication} onFormSubmit={onEditFormSubmit} />
              )}
            </Grid>
          </Grid>
        )}
      </Paper>
    </>
  );
};

export default SellerApplicationEdit;
