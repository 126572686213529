import React, { FC } from 'react';

import { Box, Container, Grid } from '@material-ui/core';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import { Link, useRouteMatch } from 'react-router-dom';

import IconCard from '../../components/partials/common/IconCard';
import useAppContainerStyles from '../../theme/container.style';
import useTypographyStyles from '../../theme/typography.style';

const SettingsList: FC = () => {
  const { path } = useRouteMatch();
  const containerClasses = useAppContainerStyles();
  const typoClasses = useTypographyStyles();

  return (
    <>
      <Container className={containerClasses.container}>
        <Box py={3}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <Link to={`${path}/web-config`} className={typoClasses.noDecorationText}>
                <IconCard title="settings:title.t2hSettings" icon={<SettingsApplicationsIcon />} />
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default SettingsList;
