import React, { FC, useState } from 'react';

import { Box, Container, TableRow } from '@material-ui/core';
import clsx from 'clsx';

import { tableRowPerPageList } from '../../../../helpers/constants';
import { AppTableConditions } from '../../../../models';
import { ListingCRStatus, ListingItemData } from '../../../../models/listing.model';
import useAppContainerStyles from '../../../../theme/container.style';
import useAppTableStyles from '../../../../theme/table.style';
import AppTableCell from '../../../ui/AppTableCell';
import AppTableList from '../../../ui/AppTableList';
import ListingItem from '../ListingItemV1';

export type ListingTableProps = {
  currentConditions: AppTableConditions;
  totalItems: number;
  isLoadingData: boolean;
  items: ListingItemData[];
  onTableConditionsChanged: (values: AppTableConditions) => void;
  defaultRowsPerPage?: number;
  rowsPerPageOptions?: number[];
};

const ListingTable: FC<ListingTableProps> = (props) => {
  const {
    totalItems,
    isLoadingData,
    currentConditions,
    items,
    onTableConditionsChanged,
    defaultRowsPerPage = tableRowPerPageList[0],
    rowsPerPageOptions = [tableRowPerPageList[0], tableRowPerPageList[1]],
  } = props;
  const containerClasses = useAppContainerStyles();
  const tableClasses = useAppTableStyles();
  const [tableConditions, setTableConditions] = useState<AppTableConditions>(currentConditions);

  const onTableConditionChangedHandler = (values: AppTableConditions) => {
    if (JSON.stringify(values) !== JSON.stringify(tableConditions)) {
      setTableConditions(values);
      onTableConditionsChanged(values);
    }
  };

  return (
    <>
      <Container className={containerClasses.container}>
        <Box className={tableClasses.tableContainer}>
          <AppTableList
            {...tableConditions}
            aria-label="listing"
            totalDataCount={totalItems}
            hasPagination
            onTableConditionChanged={onTableConditionChangedHandler}
            isLoading={isLoadingData}
            style={{ borderCollapse: 'collapse' }}
            disableRowsLabel
            rowsPerPage={defaultRowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
            isFixedHeightAuto
            tableBodyContent={
              items.length > 0 && (
                <>
                  {items.map((item) => (
                    <TableRow
                      key={item.hashId}
                      classes={{
                        root: clsx(
                          tableClasses.dividerRow,
                          [ListingCRStatus.Approved].includes(item.crStatus as ListingCRStatus) && tableClasses.dimRow,
                        ),
                      }}>
                      <AppTableCell style={{ whiteSpace: 'unset' }}>
                        <ListingItem data={item} />
                      </AppTableCell>
                    </TableRow>
                  ))}
                </>
              )
            }
          />
        </Box>
      </Container>
    </>
  );
};

export default ListingTable;
