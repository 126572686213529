import React, { FC, useState, useContext } from 'react';

import { Box, Container } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import useDeepCompareEffect from 'use-deep-compare-effect';

import BrandListSearchForm from '../../../components/partials/master-data/brand/BrandListSearchForm';
import BrandListTable from '../../../components/partials/master-data/brand/BrandListTable';
import AppBreadcrumb from '../../../components/ui/AppBreadcrumb';
import AppButton from '../../../components/ui/AppButton';
import { AppGlobalUiContext } from '../../../context/AppGlobalUiContext';
import { SearchFilterContext } from '../../../context/SearchFilterContext';
import { defaultRowsPerPage } from '../../../helpers/constants';
import { Brand, AppTableConditions, BrandListSearchParams, BreadcrumbItem } from '../../../models';
import { BrandListSearchFormData } from '../../../models/brand.model';
import * as brandService from '../../../services/brand';
import useAppContainerStyles from '../../../theme/container.style';
import useAppTableStyles from '../../../theme/table.style';

const searchFilterContextKey = 'master-data_brands';

const BrandList: FC = () => {
  const { path } = useRouteMatch();
  const { t } = useTranslation();
  const containerClasses = useAppContainerStyles();
  const tableClasses = useAppTableStyles();
  const { showSnackbar } = useContext(AppGlobalUiContext);

  const breadcrumbItems: BreadcrumbItem[] = [
    { label: 'common:sidebar.menu.masterData', url: '/master-data' },
    { label: 'master-data:menu.brand' },
  ];

  const [isLoading, setIsLoading] = useState(true);
  const [brandData, setBrandData] = useState<Brand[]>([]);
  const [totalBrands, setTotalBrands] = useState<number>(0);
  const { currentFilters: ctxFilters, setFilters } = useContext(SearchFilterContext);
  const currentFilters: BrandListSearchParams = ctxFilters ? ctxFilters[searchFilterContextKey] : undefined;
  const [searchFilters, setSearchFilters] = useState<BrandListSearchFormData>({
    searchKeyword: currentFilters?.searchKeyword || '',
  });
  const [tableConditions, setTableConditions] = useState<AppTableConditions>({
    page: 0,
    rowsPerPage: defaultRowsPerPage,
    sortColumn: 'name',
    sortDirection: 'asc',
  });

  const getCurrentSearchParam = (): BrandListSearchParams => ({
    page: tableConditions.page || 0,
    rowsPerPage: tableConditions.rowsPerPage || defaultRowsPerPage,
    sortColumn: tableConditions.sortColumn || 'name',
    sortDirection: tableConditions.sortDirection || 'asc',
    searchKeyword: searchFilters.searchKeyword || '',
  });

  const requestBrandList = async (isPageActive = true): Promise<boolean> => {
    if (isPageActive) {
      setIsLoading(true);

      const searchConditions = getCurrentSearchParam();
      setFilters(searchFilterContextKey, searchConditions);
      const result = await brandService.getBrandMasterList(searchConditions);

      setIsLoading(false);

      if (result.data) {
        setBrandData(result.data.list);
        setTotalBrands(result.data.totalCount);
        return Promise.resolve(true);
      }

      showSnackbar(t(result.error as string).toString(), 'error');
      return Promise.reject(result.error);
    }

    return false;
  };

  useDeepCompareEffect(() => {
    let isActive = true;

    requestBrandList(isActive);

    return () => {
      isActive = false;
    };
  }, [tableConditions, searchFilters]);

  const tableConditionsChangedHandler = (values: AppTableConditions) => {
    setTableConditions(values);
  };

  const onSearchFormSubmit = (filters: BrandListSearchFormData) => {
    if (tableConditions.page !== 0) {
      tableConditions.page = 0;
    }

    setSearchFilters(filters);
  };

  return (
    <>
      <Box px={6} py={3}>
        <AppBreadcrumb items={breadcrumbItems} />
      </Box>
      <BrandListSearchForm currentFilters={searchFilters} onSearchFormSubmit={onSearchFormSubmit} />
      <Container className={containerClasses.container}>
        <Box className={tableClasses.tableContainer}>
          <Box mb={2}>
            <AppButton color="primary" variant="outlined" startIcon={<Add />} url={`${path}/create`}>
              {t('common:button.addField', {
                field: t('master-data:common.field.brand').toString(),
              })}
            </AppButton>
          </Box>
          <BrandListTable
            currentConditions={tableConditions}
            totalBrands={totalBrands}
            isLoadingData={isLoading}
            brandList={brandData}
            onTableConditionsChanged={tableConditionsChangedHandler}
          />
        </Box>
      </Container>
    </>
  );
};

export default BrandList;
