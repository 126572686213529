import React, { FC, useState } from 'react';

import { Box, FormHelperText, makeStyles, MenuItem, OutlinedInput, Select, Typography } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SelectItem } from '../../../../models';
import AppDialog from '../../../ui/AppDialog';
import AppEmptyMenuItemLabel from '../../../ui/AppEmptyMenuItemLabel';
import AppFormControl from '../../../ui/AppFormControl';

export type UserAccountSuspendDialogProps = {
  onOkClicked: (reason: string) => void;
  onClose: () => void;
};

const commonReasonList: SelectItem<string>[] = [
  { label: 'common:list.suspendReasons.suspicious', value: 'suspicious' },
  { label: 'common:list.suspendReasons.fraud', value: 'fraud' },
  { label: 'common:list.suspendReasons.noRecentActivity', value: 'noRecentActivity' },
];

const useStyles = makeStyles(() => ({
  formContainer: {
    width: 300,
  },
}));

const UserAccountSuspendDialog: FC<UserAccountSuspendDialogProps> = (props) => {
  const classes = useStyles();
  const { onOkClicked, onClose } = props;
  const { t } = useTranslation();
  const [templateDropdownValue, setTemplateDropdownValue] = useState<string>('');
  const {
    getValues,
    control,
    setValue,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<{ reason: string }>({
    mode: 'onChange',
    defaultValues: {
      reason: '',
    },
  });

  const onFormSubmit = () => {
    onOkClicked(getValues('reason'));
  };

  const okButtonClickHandler = () => {
    if (isValid) {
      handleSubmit(onFormSubmit)();
    }
  };

  const cancelButtonClickHandler = () => {
    onClose();
  };

  const templateListChangeHandler = (e: React.ChangeEvent<{ value: unknown }>) => {
    const selectedItem = commonReasonList.find((item) => item.value === e.target.value);
    setTemplateDropdownValue(e.target.value as string);
    setValue('reason', selectedItem ? t(selectedItem.label).toString() : '', { shouldValidate: true });
  };

  return (
    <AppDialog
      open
      title={t('user:details.label.accDeactivationDialogTitle')}
      okButtonText={t('common:button.save')}
      okButtonColor="primary"
      cancelButtonText={t('common:button.cancel')}
      onOkClick={okButtonClickHandler}
      onCancelClick={cancelButtonClickHandler}
      okButtonProps={{
        disabled: !isValid,
      }}>
      <Typography variant="body1">{t('user:details.label.accDeactivationDialogDesc')}</Typography>
      <Box mt={1} className={classes.formContainer}>
        <Box mb={2}>
          <AppFormControl>
            <Select displayEmpty value={templateDropdownValue} onChange={templateListChangeHandler} fullWidth>
              <MenuItem value="">
                <AppEmptyMenuItemLabel itemLabel="user:details.label.chooseFromTemplate" />
              </MenuItem>

              {commonReasonList.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {t(item.label)}
                </MenuItem>
              ))}
            </Select>
          </AppFormControl>
        </Box>
        <Controller
          name="reason"
          control={control}
          rules={{
            required: true,
            minLength: 10,
          }}
          defaultValue=""
          render={({ field }) => (
            <AppFormControl error={!isValid}>
              <OutlinedInput
                name={field.name}
                value={field.value}
                fullWidth
                autoFocus
                error={!!errors.reason}
                onChange={(e) => field.onChange(e.target.value)}
                placeholder={t('user:details.label.accDeactivationPlaceholder').toString()}
              />
              {!!errors.reason && <FormHelperText error>{t('user:error.edit.deactivationReasonError')}</FormHelperText>}
            </AppFormControl>
          )}
        />
      </Box>
    </AppDialog>
  );
};

export default UserAccountSuspendDialog;
