import React, { useState, FC, useEffect } from 'react';

import {
  Box,
  ButtonGroup,
  Card,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import clsx from 'clsx';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import { getRgbaFromHex, moveDragDropItems, reorderDragDropItems } from '../../../../helpers/utils';
import { T2HMenuItemFormData, T2HMenuItem } from '../../../../models/menu.model';
import useTypographyStyles from '../../../../theme/typography.style';
import AppButton from '../../../ui/AppButton';
import AppDialog from '../../../ui/AppDialog';
import MenuEditorDialog from '../MenuEditorDialog';

export type T2HMenuEditorProps = {
  defaultValue: T2HMenuItem[];
  isDataFromClone: boolean;
  disabled?: boolean;
  onChange?: (menus: T2HMenuItem[]) => void;
};

export type T2HSubMenuItemsContainerProps = {
  droppableId: string;
  menus: T2HMenuItem[] | undefined;
  disabled?: boolean;
  onItemAddClick?: () => void;
  onItemEditClick?: (subItemIndex: number) => void;
  onItemDeleteClick?: (subItemIndex: number) => void;
};

const droppableGroupKeyPrefix = 'droppable-group-';
const droppableSubItemKeyPrefix = 'droppable-sub-item-';
const useStyles = makeStyles((theme) => ({
  droppableArea: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexFlow: 'row nowrap',
    borderRadius: 4,
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(2, 2),
    overflow: 'hidden',
    overflowX: 'auto',
  },
  draggableItemContainer: {
    flexBasis: 240,
    minWidth: 240,
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up('lg')]: {
      flexBasis: 300,
      minWidth: 300,
    },
    '&:focus-visible': {
      outline: 'none',
    },
  },
  draggingPlaceholder: {
    borderRadius: 5,
    borderWidth: 2,
    borderStyle: 'dashed',
    borderColor: theme.palette.primary.main,
  },
  subItemListDroppableArea: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    flexFlow: 'column nowrap',
    overflow: 'hidden',
    overflowX: 'auto',
    minHeight: 400,
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  subItemDraggableContainer: {
    borderRadius: 4,
    backgroundColor: theme.palette.grey[200],
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1, 2),
    '&:focus-visible': {
      outline: 'none',
    },
  },
  subItemDraggingPlaceholder: {
    borderRadius: 5,
    borderWidth: 2,
    borderStyle: 'dashed',
    borderColor: getRgbaFromHex(theme.palette.primary.main, 50),
    backgroundColor: getRgbaFromHex(theme.palette.primary.main, 30),
  },
  subMenuItemListItem: {
    padding: theme.spacing(1, 0),
    '& + .MuiListItem-root': {
      borderTop: `solid 1px ${theme.palette.grey[300]}`,
    },
  },
}));

const ActionButtons = (props: { onEditClick: () => void; onDeleteClick: () => void }) => {
  const { onEditClick, onDeleteClick } = props;
  const typoClasses = useTypographyStyles();
  return (
    <>
      <IconButton aria-label="edit" disableRipple disableFocusRipple size="small" onClick={() => onEditClick()}>
        <EditIcon fontSize="small" />
      </IconButton>
      <IconButton aria-label="edit" disableRipple disableFocusRipple size="small" onClick={() => onDeleteClick()}>
        <DeleteIcon fontSize="small" className={typoClasses.errorText} />
      </IconButton>
    </>
  );
};

const SubMenuItemContainer: FC<T2HSubMenuItemsContainerProps> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { droppableId, menus, disabled = false, onItemAddClick, onItemEditClick, onItemDeleteClick } = props;
  const [currentItems, setCurrentItems] = useState<T2HMenuItem[]>(menus || []);

  useEffect(() => {
    setCurrentItems(menus || []);
  }, [menus]);

  const onAddClickHandler = () => {
    if (typeof onItemAddClick === 'function') {
      onItemAddClick();
    }
  };

  const onEditClickHandler = (index: number) => {
    if (typeof onItemEditClick === 'function') {
      onItemEditClick(index);
    }
  };

  const onDeleteClickHandler = (index: number) => {
    if (typeof onItemDeleteClick === 'function') {
      onItemDeleteClick(index);
    }
  };

  return (
    <>
      <Droppable droppableId={droppableId} isDropDisabled={disabled}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={clsx(classes.subItemListDroppableArea, {
              [classes.subItemDraggingPlaceholder]: snapshot.isDraggingOver,
            })}>
            {currentItems.length > 0 &&
              currentItems.map((item: T2HMenuItem, index: number) => {
                const itemKey = `item-${index}`;
                return (
                  <Draggable key={itemKey} draggableId={`${droppableId}_${index.toString()}`} index={index}>
                    {(providedItem) => (
                      <div
                        ref={providedItem.innerRef}
                        {...providedItem.draggableProps}
                        {...providedItem.dragHandleProps}
                        className={classes.subItemDraggableContainer}>
                        <Box display="flex" flexDirection="row">
                          <Box flexGrow={1} pr={1}>
                            <Typography>{item.label}</Typography>
                          </Box>
                          {!disabled && (
                            <ActionButtons
                              onEditClick={() => onEditClickHandler(index)}
                              onDeleteClick={() => onDeleteClickHandler(index)}
                            />
                          )}
                        </Box>
                      </div>
                    )}
                  </Draggable>
                );
              })}

            {!snapshot.isDraggingOver && (
              <Box>
                {!disabled && (
                  <AppButton
                    color="default"
                    variant="text"
                    onClick={() => onAddClickHandler()}
                    startIcon={<AddIcon fontSize="small" />}>
                    {t('menu:editor.label.addNewMenuItem')}
                  </AppButton>
                )}
              </Box>
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </>
  );
};

const T2HMenuEditor: FC<T2HMenuEditorProps> = (props) => {
  const { isDataFromClone, defaultValue, disabled = false, onChange } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const typoClasses = useTypographyStyles();
  const [currentMenus, setCurrentMenus] = useState<T2HMenuItem[]>(defaultValue);
  const [editingGroupIndex, setEditingGroupIndex] = useState<number | undefined>(undefined);
  const [editingSubItemIndex, setEditingSubItemIndex] = useState<number | undefined>(undefined);
  const [editingItemIndex, setEditingItemIndex] = useState<number>(-1);
  const [isMenuGroupEditing, setIsMenuGroupEditing] = useState<boolean>(true);
  const [isMenuItemEditDialogOpen, setIsMenuItemEditDialogOpen] = useState<boolean>(false);
  const [isMenuItemDeleteDialogOpen, setIsMenuItemDeleteDialogOpen] = useState<boolean>(false);
  const [editingData, setEditingData] = useState<T2HMenuItemFormData | undefined>(undefined);
  const currentMenuPathString = JSON.stringify(currentMenus);

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange(currentMenus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMenuPathString]);

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorderDragDropItems<T2HMenuItem>(currentMenus, result.source.index, result.destination.index);

    setCurrentMenus(items);
  };

  const onSubItemsDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newMenus = [...currentMenus];
    const { source, destination } = result;
    let items: T2HMenuItem[];
    const sourceIndex = Number(source.droppableId.replace(droppableSubItemKeyPrefix, ''));
    const destinationIndex = Number(destination.droppableId.replace(droppableSubItemKeyPrefix, ''));
    const sourceData = newMenus[sourceIndex].children || [];
    const destData = newMenus[destinationIndex].children || [];

    if (source.droppableId === destination.droppableId) {
      items = reorderDragDropItems<T2HMenuItem>(sourceData, source.index, destination.index);

      newMenus[sourceIndex].children = [...items];
      setCurrentMenus(newMenus);
    } else {
      const moveResult = moveDragDropItems<T2HMenuItem>(sourceData, destData, source, destination);
      if (moveResult) {
        newMenus[sourceIndex].children = moveResult[source.droppableId];
        newMenus[destinationIndex].children = moveResult[destination.droppableId];
        setCurrentMenus(newMenus);
      }
    }
  };

  const openMenuItemEditDialog = (groupIndex: number, subItemIndex?: number) => {
    let editingItemData;

    if (groupIndex > -1) {
      editingItemData = currentMenus[groupIndex];

      if (typeof subItemIndex === 'number' && editingItemData.children) {
        editingItemData = editingItemData.children[subItemIndex] || undefined;
      }
    }

    setEditingData({
      imageUrl: editingItemData?.imageUrl || '',
      label: editingItemData?.label || '',
      url: editingItemData?.url || '',
      clientSideRedirect: editingItemData?.clientSideRedirect || false,
      trackingClass: editingItemData?.trackingClass || '',
      newWindow: editingItemData?.newWindow || false,
      attributes: editingItemData?.attributes || '{}',
    });

    setEditingGroupIndex(groupIndex);
    setEditingSubItemIndex(subItemIndex);
    setIsMenuItemEditDialogOpen(true);

    // Editing item index is only used to determine 'add' or 'edit' mode to display button text properly.
    // Whether it's group or sub item, if it's -1, then it's an 'add' mode in dialog. Otherwise, it's an 'edit' mode.
    setEditingItemIndex(typeof subItemIndex === 'number' ? subItemIndex : groupIndex);
  };

  const openMenuItemDeleteDialog = (groupIndex: number, subItemIndex?: number) => {
    setEditingGroupIndex(groupIndex);
    setEditingSubItemIndex(subItemIndex);
    setIsMenuItemDeleteDialogOpen(true);
  };

  const deleteMenuItem = () => {
    const newMenus = [...currentMenus];
    if (typeof editingGroupIndex === 'number') {
      if (typeof editingSubItemIndex === 'number') {
        newMenus[editingGroupIndex].children?.splice(editingSubItemIndex, 1);
      } else {
        newMenus.splice(editingGroupIndex, 1);
      }

      setCurrentMenus(newMenus);
    }

    setIsMenuItemDeleteDialogOpen(false);
  };

  const menuItemEditHandler = (formData: T2HMenuItemFormData) => {
    if (formData && typeof editingGroupIndex === 'number') {
      const newMenus = [...currentMenus];
      const targetMenuGroup = newMenus[editingGroupIndex];

      // If typeof editingSubItemIndex was number, that means we're editing sub menu item level
      // So we process with group children
      if (typeof editingSubItemIndex === 'number') {
        if (!targetMenuGroup.children) {
          targetMenuGroup.children = [];
        }

        if (editingSubItemIndex === -1) {
          targetMenuGroup.children.push(formData);
        } else {
          targetMenuGroup.children[editingSubItemIndex] = formData;
        }
      } else if (editingGroupIndex === -1) {
        newMenus.push({ ...formData, children: [], attributes: '{}' });
      } else {
        newMenus[editingGroupIndex] = { ...newMenus[editingGroupIndex], ...formData };
      }

      setCurrentMenus(newMenus);
    }

    setIsMenuItemEditDialogOpen(false);
  };

  const toggleGroupEditing = (flag: boolean) => {
    if (flag !== isMenuGroupEditing) {
      setIsMenuGroupEditing(!isMenuGroupEditing);
    }
  };

  return (
    <>
      <Box p={3}>
        <Typography variant="h6" className={typoClasses.textWeightBold}>
          {t('menu:title.menuEditor')}
        </Typography>
        <Box mt={2}>
          <ButtonGroup>
            <AppButton
              color="primary"
              variant={isMenuGroupEditing ? 'contained' : 'outlined'}
              disableElevation
              onClick={() => toggleGroupEditing(true)}>
              {t('menu:editor.label.editGroupButton')}
            </AppButton>
            <AppButton
              color="primary"
              variant={!isMenuGroupEditing ? 'contained' : 'outlined'}
              disableElevation
              onClick={() => toggleGroupEditing(false)}>
              {t('menu:editor.label.editItemButton')}
            </AppButton>
          </ButtonGroup>
        </Box>
        <Box mt={2}>
          {isMenuGroupEditing && (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable" direction="horizontal" isDropDisabled={disabled}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className={clsx(classes.droppableArea, {
                      [classes.draggingPlaceholder]: snapshot.isDraggingOver,
                    })}>
                    {currentMenus.length > 0 &&
                      currentMenus.map((item: T2HMenuItem, index: number) => {
                        const itemKey = `${droppableGroupKeyPrefix}${index}`;
                        return (
                          <Draggable key={itemKey} draggableId={`${itemKey}_${index.toString()}`} index={index}>
                            {(providedItem) => (
                              <div
                                ref={providedItem.innerRef}
                                {...providedItem.draggableProps}
                                {...providedItem.dragHandleProps}
                                className={classes.draggableItemContainer}>
                                <Box flexGrow={1}>
                                  <Card>
                                    <Box p={2}>
                                      <Typography variant="subtitle2" className={typoClasses.greyText}>
                                        {t('menu:editor.label.groupNameLabel')}
                                      </Typography>
                                      <Box display="flex" flexDirection="row">
                                        <Box flexGrow={1} pr={1}>
                                          <Typography className={typoClasses.textWeightBold}>{item.label}</Typography>
                                        </Box>
                                        {!disabled && (
                                          <ActionButtons
                                            onEditClick={() => openMenuItemEditDialog(index)}
                                            onDeleteClick={() => openMenuItemDeleteDialog(index)}
                                          />
                                        )}
                                      </Box>
                                      {item.children && item.children.length > 0 && (
                                        <Box mt={1}>
                                          <Divider />
                                          <List dense>
                                            {item.children.map((menuItem, menuIndex) => {
                                              const menuItemKey = `menu-item-${menuIndex}`;

                                              return (
                                                <ListItem key={menuItemKey} className={classes.subMenuItemListItem}>
                                                  <ListItemText primary={menuItem.label} />
                                                </ListItem>
                                              );
                                            })}
                                          </List>
                                        </Box>
                                      )}
                                    </Box>
                                  </Card>
                                </Box>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}

                    {!snapshot.isDraggingOver && (
                      <div className={clsx(classes.draggableItemContainer)}>
                        {editingGroupIndex === undefined && (
                          <AppButton
                            color="default"
                            variant="text"
                            onClick={() => openMenuItemEditDialog(-1)}
                            startIcon={<AddIcon fontSize="small" />}>
                            {t('menu:editor.label.addNewGroup')}
                          </AppButton>
                        )}
                      </div>
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
          {!isMenuGroupEditing && currentMenus.length > 0 && (
            <Box className={classes.droppableArea}>
              <DragDropContext onDragEnd={onSubItemsDragEnd}>
                {currentMenus.map((item: T2HMenuItem, index: number) => {
                  const itemKey = `${droppableSubItemKeyPrefix}${index}`;

                  return (
                    <Box className={classes.draggableItemContainer} key={itemKey}>
                      <Box flexGrow={1}>
                        <Card>
                          <Box p={2}>
                            <Typography variant="subtitle2" className={typoClasses.greyText}>
                              {t('menu:editor.label.groupNameLabel')}
                            </Typography>
                            <Typography className={typoClasses.textWeightBold}>{item.label}</Typography>
                            <Box mt={1}>
                              <Divider />
                            </Box>
                            <SubMenuItemContainer
                              droppableId={itemKey}
                              menus={item.children}
                              onItemAddClick={() => openMenuItemEditDialog(index, -1)}
                              onItemEditClick={(subItemIndex) => openMenuItemEditDialog(index, subItemIndex)}
                              onItemDeleteClick={(subItemIndex) => openMenuItemDeleteDialog(index, subItemIndex)}
                              disabled={disabled}
                            />
                          </Box>
                        </Card>
                      </Box>
                    </Box>
                  );
                })}
              </DragDropContext>
            </Box>
          )}
        </Box>
      </Box>

      <MenuEditorDialog
        open={isMenuItemEditDialogOpen}
        editingData={editingData}
        editingIndex={editingItemIndex}
        isDataFromClone={isDataFromClone}
        onClose={() => {
          setIsMenuItemEditDialogOpen(false);
        }}
        onDialogOkClick={(formData: T2HMenuItemFormData) => {
          menuItemEditHandler(formData);
        }}
        onDialogExited={() => {
          setEditingItemIndex(-1);
          setEditingData(undefined);
          setEditingGroupIndex(undefined);
          setEditingSubItemIndex(undefined);
        }}
      />

      <AppDialog
        type="warning"
        open={isMenuItemDeleteDialogOpen}
        title="common:dialog.title.warning"
        okButtonColor="error"
        okButtonText="common:button.delete"
        cancelButtonText="common:button.cancel"
        onOkClick={() => deleteMenuItem()}
        onClose={() => {
          setIsMenuItemDeleteDialogOpen(false);
        }}
        dialogProps={{
          TransitionProps: {
            onExited: () => {
              setEditingGroupIndex(undefined);
              setEditingSubItemIndex(undefined);
            },
          },
        }}>
        <Typography className={typoClasses.errorText}>
          {t(
            `menu:editor.message.${
              typeof editingSubItemIndex === 'number' ? 'subItemDeleteWarning' : 'groupItemDeleteWarning'
            }`,
          )}
        </Typography>
      </AppDialog>
    </>
  );
};

export default T2HMenuEditor;
