import React, { FC, useState } from 'react';

import { TableRow } from '@material-ui/core';
import clsx from 'clsx';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { AppTableConditions, Model, TableColumn } from '../../../../../models';
import useAppTableStyles from '../../../../../theme/table.style';
import AppTableCell from '../../../../ui/AppTableCell';
import AppTableList from '../../../../ui/AppTableList';

const modelTableColumns: TableColumn[] = [
  { name: 'name', label: 'master-data:common.field.name' },
  { name: 'thaiName', label: 'master-data:common.field.thaiName' },
  { name: 'slug', label: 'master-data:common.field.slug' },
  { name: 'modelName', label: 'master-data:list.model.columns.modelName' },
];

export type ModelListTableProps = {
  currentConditions: AppTableConditions;
  totalModels: number;
  isLoadingData: boolean;
  modelList: Model[];
  onTableConditionsChanged: (values: AppTableConditions) => void;
};

const ModelListTable: FC<ModelListTableProps> = (props) => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { totalModels, isLoadingData, currentConditions, modelList, onTableConditionsChanged } = props;
  const tableClasses = useAppTableStyles();
  const [tableConditions, setTableConditions] = useState<AppTableConditions>(currentConditions);

  const onTableConditionChangedHandler = (values: AppTableConditions) => {
    if (JSON.stringify(values) !== JSON.stringify(tableConditions)) {
      setTableConditions(values);
      onTableConditionsChanged(values);
    }
  };

  const openModelDetailPage = (model: Model) => {
    history.push(`${url}/${model.uid}`);
  };

  return (
    <AppTableList
      {...tableConditions}
      aria-label="brand-list"
      columns={modelTableColumns}
      totalDataCount={totalModels}
      hasPagination
      onTableConditionChanged={onTableConditionChangedHandler}
      isLoading={isLoadingData}
      tableBodyContent={
        modelList.length > 0 && (
          <>
            {modelList.map((model: Model) => (
              <TableRow
                hover
                key={model.id}
                classes={{ root: clsx(tableClasses.tableRow, tableClasses.clickableTableRow) }}
                onClick={() => openModelDetailPage(model)}>
                <AppTableCell>{model.name}</AppTableCell>
                <AppTableCell>{model.thaiName}</AppTableCell>
                <AppTableCell>{model.slug}</AppTableCell>
                <AppTableCell>{model.modelName}</AppTableCell>
              </TableRow>
            ))}
          </>
        )
      }
    />
  );
};

export default ModelListTable;
