import { apiRequest } from '../helpers/request';
import { queryParamGenerator } from '../helpers/utils';
import {
  ApiResponse,
  GetSellerAppListResponse,
  ReverifyFormData,
  SellerApplication,
  SellerApplicationFormData,
  SellerAppListSearchFilters,
} from '../models';

const getSellerAppListQueryParam = (param?: SellerAppListSearchFilters): string => {
  const reqParam: { [x: string]: string | number } = {
    page: (param?.page || 0) + 1, // plus 1 for index shift between FE component and backend query
    per_page: param?.rowsPerPage || 10,
    sort: param?.sortColumn ? `${param.sortColumn}|${(param?.sortDirection || 'desc').toUpperCase()}` : '',
  };

  // Only search with keyword when it comes with the selected column to be searched
  if (param?.searchKeyword && param?.searchField) {
    reqParam[param.searchField] = param.searchKeyword.trim();
  }
  if (param?.filteringStatuses?.length) {
    reqParam.status = param.filteringStatuses.join(',');
  }
  if (param?.filteringTypes?.length) {
    reqParam.types = param.filteringTypes.join(',');
  }

  return queryParamGenerator(reqParam);
};

const getSellerApplicationList = async (
  param?: SellerAppListSearchFilters,
): Promise<ApiResponse<GetSellerAppListResponse>> => {
  try {
    const queryParamString = getSellerAppListQueryParam(param);
    const result = await apiRequest<SellerApplication[]>(`/admin/seller-applications?${queryParamString}`);

    return {
      data: {
        sellerApplications: result.data,
        totalCount: Number(result.headers['x-total-count'] || '0') || result.data.length,
      },
    };
  } catch (e) {
    return {
      data: null,
      error: 'seller-applications:error.list.requestFailed',
    };
  }
};

const getSellerApplicationByHashId = async (hashId: string): Promise<ApiResponse<SellerApplication>> => {
  try {
    const result = await apiRequest<SellerApplication>(`/admin/seller-applications/${hashId}`);

    return {
      data: result.data,
    };
  } catch (e) {
    return {
      data: null,
      error: 'seller-applications:error.detail.requestFailed',
    };
  }
};

const updateSellerApplication = async (
  hashId: string,
  saveData: ReverifyFormData,
): Promise<ApiResponse<SellerApplication>> => {
  try {
    const result = await apiRequest<SellerApplication>(`/admin/seller-applications/${hashId}`, {
      method: 'PATCH',
      data: { adminImageHashIds: saveData.adminImageHashIds },
    });

    return {
      data: result.data,
    };
  } catch (e) {
    return {
      data: null,
      error: 'seller-applications:error.detail.patchFailed',
    };
  }
};

const approveApplication = async (
  hashId: string,
  saveData: SellerApplicationFormData,
): Promise<ApiResponse<SellerApplication>> => {
  try {
    const result = await apiRequest<SellerApplication>(`/admin/seller-applications/${hashId}/approve`, {
      method: 'POST',
      data: {
        nationalId: saveData.nationalId,
        passportNo: saveData.passportNo,
        firstName: saveData.verifiedFirstName,
        lastName: saveData.verifiedLastName,
        userAddress: saveData.verifiedAddress,
        bankHashId: saveData.bankHashId,
        bankAccNo: saveData.bankAccNo,
      },
    });

    return {
      data: result.data,
    };
  } catch (e) {
    return {
      data: null,
      error: 'seller-applications:error.detail.approveFailed',
    };
  }
};

const rejectApplication = async (hashId: string, reason: string): Promise<ApiResponse<SellerApplication>> => {
  try {
    const result = await apiRequest<SellerApplication>(`/admin/seller-applications/${hashId}/reject`, {
      method: 'POST',
      data: {
        rejectReason: reason,
      },
    });

    return {
      data: result.data,
    };
  } catch (e) {
    return {
      data: null,
      error: 'seller-applications:error.detail.rejectFailed',
    };
  }
};

const setApplicationPending = async (hashId: string): Promise<ApiResponse<SellerApplication>> => {
  try {
    const result = await apiRequest<SellerApplication>(`/admin/seller-applications/${hashId}/pending`, {
      method: 'POST',
    });

    return {
      data: result.data,
    };
  } catch (e) {
    return {
      data: null,
      error: 'seller-applications:error.detail.pendingFailed',
    };
  }
};

// eslint-disable-next-line import/prefer-default-export
export {
  getSellerApplicationList,
  getSellerApplicationByHashId,
  updateSellerApplication,
  approveApplication,
  rejectApplication,
  setApplicationPending,
};
