import React, { FC } from 'react';

import loginBonusRoutes from './route.config';
import ModuleMainPage from '../../../../components/templates/ModuleMainPage';

const LoginBonusMain: FC = () => (
  <ModuleMainPage pageTitle="common:title.dailyLoginBonus" routeFunctions={loginBonusRoutes} />
);

export default LoginBonusMain;
