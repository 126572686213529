import React, { FC } from 'react';

import { Box, Container, Grid, makeStyles, MenuItem, OutlinedInput, Select, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import YoutubeSearchedForIcon from '@material-ui/icons/YoutubeSearchedFor';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SelectItem, SellerAppListSearchFormData } from '../../../../models';
import useAppContainerStyles from '../../../../theme/container.style';
import useTypographyStyles from '../../../../theme/typography.style';
import AppButton from '../../../ui/AppButton';
import AppChipList from '../../../ui/AppChipList';
import AppFormControl from '../../../ui/AppFormControl';
import AppFormControlGroup from '../../../ui/AppFormControlGroup';

export type SellerAppListSearchFormProps = {
  isLoadingData?: boolean;
  currentFilters: SellerAppListSearchFormData | undefined;
  onSearchFormSubmit: (values: SellerAppListSearchFormData) => void;
};

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
  },
  searchBoxContainer: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 600,
    },
  },
}));

const searchFieldList: SelectItem<string>[] = [
  {
    label: 'user:common.fields.username',
    value: 'username',
  },
  {
    label: 'user:common.fields.email',
    value: 'email',
  },
  {
    label: 'user:common.fields.displayName',
    value: 'displayName',
  },
  {
    label: 'user:common.fields.mobile',
    value: 'mobile',
  },
  {
    label: 'user:common.fields.hashId',
    value: 'userHashId',
  },
];

const searchStatuses = [
  {
    label: 'seller-applications:common.statuses.submitted',
    value: 'submitted',
  },
  {
    label: 'seller-applications:common.statuses.approved',
    value: 'approved',
  },
  {
    label: 'seller-applications:common.statuses.rejected',
    value: 'rejected',
  },
  {
    label: 'seller-applications:common.statuses.pending',
    value: 'pending',
  },
];

const searchTypes = [
  {
    label: 'seller-applications:common.applicationType.individual',
    value: 'individual',
  },
  {
    label: 'seller-applications:common.applicationType.corporation',
    value: 'corporation',
  },
];

const SellerApplicationsListSearchForm: FC<SellerAppListSearchFormProps> = (props) => {
  const classes = useStyles();
  const containerClasses = useAppContainerStyles();
  const typoClasses = useTypographyStyles();
  const { t } = useTranslation();
  const { currentFilters, onSearchFormSubmit, isLoadingData = false } = props;
  const { handleSubmit, setValue, control, getValues } = useForm<SellerAppListSearchFormData>({
    mode: 'onSubmit',
    defaultValues: {
      searchField: currentFilters?.searchField || searchFieldList[0].value,
      searchKeyword: currentFilters?.searchKeyword || '',
      filteringStatuses: currentFilters?.filteringStatuses || [],
      filteringTypes: currentFilters?.filteringTypes || [],
    },
  });

  const submitSearchForm = () => {
    if (!isLoadingData) {
      setValue('searchKeyword', getValues('searchKeyword').trim());
      handleSubmit(onSearchFormSubmit)();
    }
  };

  const clearSearchForm = () => {
    if (!isLoadingData) {
      setValue('searchField', searchFieldList[0].value);
      setValue('searchKeyword', '');
      setValue('filteringStatuses', []);
      setValue('filteringTypes', []);
      handleSubmit(onSearchFormSubmit)();
    }
  };

  return (
    <Box className={classes.filterContainer}>
      <Container className={containerClasses.container}>
        <form onSubmit={handleSubmit(onSearchFormSubmit)}>
          <Grid container direction="row" spacing={3}>
            <Grid item xs={12} md>
              <Box display="flex" flexDirection="row" flexWrap="nowrap" className={classes.searchBoxContainer}>
                <AppFormControlGroup flexGrow={1} minWidth={0} pr={1}>
                  <AppFormControl margin="dense">
                    <Controller
                      name="searchField"
                      control={control}
                      defaultValue={searchFieldList[0].value}
                      render={({ field }) => (
                        <Select
                          labelId="seller-app-list-search-field-label"
                          id="seller-app-list-search-field"
                          value={field.value}
                          variant="outlined"
                          onChange={field.onChange}
                          fullWidth
                          disabled={isLoadingData}>
                          {searchFieldList.map((item) => (
                            <MenuItem value={item.value} key={item.value}>
                              {t(item.label)}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </AppFormControl>
                  <AppFormControl margin="dense" boxProps={{ flexGrow: 1 }}>
                    <Controller
                      name="searchKeyword"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <OutlinedInput
                          type="search"
                          endAdornment={<SearchIcon />}
                          id="user-list-search-keyword"
                          value={field.value}
                          onChange={field.onChange}
                          fullWidth
                          disabled={isLoadingData}
                        />
                      )}
                    />
                  </AppFormControl>
                </AppFormControlGroup>
                <AppButton type="submit" color="primary" style={{ alignSelf: 'flex-start' }} disabled={isLoadingData}>
                  {t('common:message.search')}
                </AppButton>
              </Box>

              <Grid container direction="row" spacing={0}>
                <Grid item xs={12} md="auto">
                  <Box minWidth="300px" maxWidth="600px" mt={2}>
                    <Typography variant="body2" className={typoClasses.textWeightBold}>
                      {t('seller-applications:list.search.statusTitle')}
                    </Typography>
                    <Box display="flex" flexDirection="row" flexWrap="wrap" mt={1}>
                      <Controller
                        control={control}
                        name="filteringStatuses"
                        defaultValue={currentFilters?.filteringStatuses || []}
                        render={({ field }) => (
                          <>
                            <AppChipList
                              items={searchStatuses}
                              selectedValues={field.value}
                              onChange={(values: string[]) => {
                                field.onChange(values);
                                submitSearchForm();
                              }}
                            />
                          </>
                        )}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md="auto">
                  <Box minWidth="200px" maxWidth="300px" mt={2}>
                    <Typography variant="body2" className={typoClasses.textWeightBold}>
                      {t('seller-applications:list.search.applicationTypeTitle')}
                    </Typography>
                    <Box display="flex" flexDirection="row" flexWrap="wrap" mt={1}>
                      <Controller
                        control={control}
                        name="filteringTypes"
                        defaultValue={currentFilters?.filteringStatuses || []}
                        render={({ field }) => (
                          <>
                            <AppChipList
                              items={searchTypes}
                              selectedValues={field.value}
                              onChange={(values: string[]) => {
                                field.onChange(values);
                                submitSearchForm();
                              }}
                            />
                          </>
                        )}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md="auto">
              <AppButton variant="outlined" color="primary" onClick={clearSearchForm} disabled={isLoadingData}>
                <YoutubeSearchedForIcon />
              </AppButton>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Box>
  );
};

export default SellerApplicationsListSearchForm;
