import React, { FC, useCallback, useContext, useEffect, useState } from 'react';

import { Box, Container, TableRow, Typography } from '@material-ui/core';
import clsx from 'clsx';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { AppGlobalUiContext } from '../../../../../context/AppGlobalUiContext';
import useQuery from '../../../../../hooks/useQuery';
import i18n from '../../../../../i18n';
import { AppTableConditions, TableColumn } from '../../../../../models';
import { OrderOnceData, OrderStateWorkflow } from '../../../../../models/order-order.model';
import { cancelOrderOnceByHashId, approveOrderOnceByHashId } from '../../../../../services/v3/order-once';
import useAppContainerStyles from '../../../../../theme/container.style';
import useAppTableStyles from '../../../../../theme/table.style';
import AppTableCell from '../../../../ui/AppTableCell';
import AppTableList from '../../../../ui/AppTableList';
import OrderOnceProductItemDialog from '../../../common/v3/OrderOnceProductItemDialog';
import OrderStatusBadge from '../OrderStatusBadge';

dayjs.extend(customParseFormat);

export type OrderOnceTableProps = {
  currentConditions: AppTableConditions;
  orderOnceList: OrderOnceData[];
  totalOrderOnce: number;
  isLoadingData: boolean;
  onTableConditionsChanged: (values: AppTableConditions) => void;
  onFetch: () => void;
};

const orderOnceTableColumns: TableColumn[] = [
  {
    name: 'orderStateWorkflow',
    label: 'order:orderList.columns.orderStateWorkflow',
    width: 140,
    unsortable: true,
  },
  { name: 'userHashId', label: 'order:orderList.columns.userHashId', width: 120, unsortable: true },
  {
    name: 'orderOnceHashId',
    label: 'order:orderList.columns.orderOnceHashId',
    width: 120,
    unsortable: true,
  },
  { name: 'createAt', label: 'order:orderList.columns.createAt', width: 120 },
  { name: 'omisePromptPayDetail', label: 'order:orderList.columns.paymentBy', width: 140, unsortable: true },
  { name: 'totalPrice', label: 'order:orderList.columns.totalPrice', width: 140, unsortable: true },
  { name: 'manage', label: '', width: 100, unsortable: true },
];

const OrderOnceListTable: FC<OrderOnceTableProps> = (props) => {
  const { t } = useTranslation();
  const query = useQuery();

  const history = useHistory();
  const { path } = useRouteMatch();
  const { totalOrderOnce, isLoadingData, currentConditions, orderOnceList, onFetch, onTableConditionsChanged } = props;
  const containerClasses = useAppContainerStyles();
  const tableClasses = useAppTableStyles();
  const [tableConditions, setTableConditions] = useState<AppTableConditions>(currentConditions);
  const { showSnackbar, setAppLoading } = useContext(AppGlobalUiContext);

  const dateDisplayFormat = i18n.language === 'th' ? 'D MMM BBBB' : 'D MMM YYYY';

  const [selectedProductItem, setSelectedProductItem] = useState<OrderOnceData | undefined>(undefined);
  const [isProductDialogOpen, setIsProductDialogOpen] = useState<boolean>(false);

  const onTableConditionChangedHandler = useCallback(
    (values: AppTableConditions) => {
      if (JSON.stringify(values) !== JSON.stringify(tableConditions)) {
        setTableConditions(values);
        onTableConditionsChanged(values);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableConditions],
  );

  const openProductDetailPage = useCallback(async (item: OrderOnceData) => {
    setSelectedProductItem(item);
    setIsProductDialogOpen(true);
  }, []);

  const onCancelOrderSubmit = useCallback(async () => {
    if (selectedProductItem?.orderOnceHashId) {
      setAppLoading(true);
      const result = await cancelOrderOnceByHashId(selectedProductItem?.orderOnceHashId);
      setAppLoading(false);

      if (result.data) {
        showSnackbar(t('order:orderList.message.cancelOrderSuccess').toString(), 'success');
        setIsProductDialogOpen(false);
        onFetch();
        history.push(`${history.location.pathname}`);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        setIsProductDialogOpen(false);
        showSnackbar(t('order:orderList.message.cancelOrderFailed').toString(), 'error');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProductItem?.orderOnceHashId, setAppLoading, showSnackbar, t]);

  const onApproveOrderSubmit = useCallback(async () => {
    if (selectedProductItem?.orderOnceHashId) {
      setAppLoading(true);
      const result = await approveOrderOnceByHashId(selectedProductItem?.orderOnceHashId);
      setAppLoading(false);
      if (result.data) {
        showSnackbar(t('order:orderList.message.approveOrderSuccess').toString(), 'success');
        const queryOrderId = query.get('id');
        if (queryOrderId) {
          history.push('/v3/order-approve');
        }
        onFetch();
        setIsProductDialogOpen(false);
      } else {
        setIsProductDialogOpen(false);
        showSnackbar(t('order:orderList.message.approveOrderFailed').toString(), 'error');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProductItem?.orderOnceHashId, setAppLoading, showSnackbar, t]);

  useEffect(() => {
    const selectedId = query.get('id');
    if (selectedId) {
      setIsProductDialogOpen(true);
      const selectData = orderOnceList.filter((item) => item.orderOnceHashId === selectedId);
      openProductDetailPage(selectData[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderOnceList, query]);

  return (
    <>
      <Container className={containerClasses.container}>
        <Box className={tableClasses.tableContainer}>
          <Box textAlign="right" mb={2}>
            {!isLoadingData && (
              <Typography
                variant="subtitle2"
                color="textSecondary"
                style={{ marginBottom: '8px', marginRight: '8px', textAlign: 'right' }}>
                {t('common:list.resultTotal', { fieldName: t(totalOrderOnce.toLocaleString() || '0') })}
              </Typography>
            )}
          </Box>

          <AppTableList
            {...tableConditions}
            aria-label="product-list"
            columns={orderOnceTableColumns}
            totalDataCount={totalOrderOnce}
            hasPagination
            onTableConditionChanged={onTableConditionChangedHandler}
            isLoading={isLoadingData}
            tableBodyContent={
              orderOnceList.length > 0 && (
                <>
                  {orderOnceList.map((item: OrderOnceData) => (
                    <TableRow
                      hover
                      key={item.orderOnceHashId}
                      classes={{ root: clsx(tableClasses.tableRow, tableClasses.clickableTableRow) }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        openProductDetailPage(item);
                      }}>
                      <AppTableCell>
                        <OrderStatusBadge
                          status={item.orderStateWorkflow}
                          isOrderByCr={item.cr && item.orderStateWorkflow === OrderStateWorkflow.open}
                        />
                      </AppTableCell>
                      <AppTableCell>
                        <a
                          href={`${path.includes('users') ? '' : 'users/'}${item.user.userHashId}`}
                          target="_blank"
                          rel="noreferrer"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}>
                          {item.user.userHashId}
                        </a>
                      </AppTableCell>
                      <AppTableCell>{item.orderOnceHashId}</AppTableCell>
                      <AppTableCell>
                        {dayjs(item.createdAt).format(`${dateDisplayFormat}`)} <br />
                        {dayjs(item.createdAt).format(`HH:mm:ss`)}
                      </AppTableCell>
                      <AppTableCell>
                        {item.omisePromptPayDetail?.qrCodeUri && (
                          <Typography>{t('order:orderList.list.promptPay')}</Typography>
                        )}
                        {item.omiseCreditCardDetail?.brand && (
                          <Typography>{t('order:orderList.list.creditCard')}</Typography>
                        )}
                        {!item.omiseCreditCardDetail?.brand && !item.omisePromptPayDetail?.qrCodeUri && (
                          <Typography>Admin CR</Typography>
                        )}
                        {item.userOrderSubscriptionHashId && (
                          <Typography variant="subtitle2">{t('order:orderList.list.orderSubscriptionOpen')}</Typography>
                        )}
                      </AppTableCell>
                      <AppTableCell>
                        <Typography>
                          {t('order:orderList.list.totalPrice', {
                            fieldName: t(item.shoppingCart.totalPrice.toLocaleString() || '0'),
                          })}
                        </Typography>
                      </AppTableCell>
                      <AppTableCell>
                        <Typography variant="subtitle2">{t('order:orderList.columns.orderDetail')}</Typography>
                      </AppTableCell>
                    </TableRow>
                  ))}
                </>
              )
            }
          />
        </Box>
      </Container>

      {selectedProductItem && (
        <OrderOnceProductItemDialog
          title={t('order:orderList.details.title')}
          open={isProductDialogOpen}
          onClose={() => {
            setIsProductDialogOpen(false);
            if (path.includes('order-approve')) {
              history.push('/v3/order-approve');
            }
          }}
          onCloseClick={() => {
            setIsProductDialogOpen(false);
            if (path.includes('order-approve')) {
              history.push('/v3/order-approve');
            }
          }}
          productItem={selectedProductItem}
          onCancelOrder={onCancelOrderSubmit}
          onApproveOrder={onApproveOrderSubmit}
        />
      )}
    </>
  );
};

export default OrderOnceListTable;
