import React, { FC, ReactNode } from 'react';

import { FormHelperText, TextField } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';

import WebConfigListItemEditor from './WebConfigListItemEditor';
import usePrivilege from '../../../../hooks/usePrivilege';
import { FileItem, RecentCategoryItem, TableColumn, WebConfigEditFormProps } from '../../../../models';
import AppFormControl from '../../../ui/AppFormControl';
import AppMediaSelector from '../../../ui/AppMediaSelector';
import AppTableCell from '../../../ui/AppTableCell';

type RecentCategoryItemFormData = RecentCategoryItem;

const tableColumns: TableColumn[] = [
  { name: 'title', label: 'settings:list.recentCategory.title', width: 150, unsortable: true },
  { name: 'iconUrl', label: 'settings:list.recentCategory.iconUrl', unsortable: true },
  { name: 'apiUrl', label: 'settings:list.recentCategory.apiUrl', unsortable: true },
  { name: 'viewMoreUrl', label: 'settings:list.recentCategory.viewMoreUrl', unsortable: true },
  { name: 'numberOfItem', label: 'settings:list.recentCategory.numberOfItem', width: 80, unsortable: true },
];

const WebConfigRecentCategories: FC<WebConfigEditFormProps> = (props) => {
  const { configItem, onSave } = props;
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    setValue,
    trigger,
    clearErrors,
    formState: { errors },
  } = useForm<RecentCategoryItemFormData>({
    mode: 'onSubmit',
    defaultValues: {
      title: '',
      iconUrl: '',
      apiUrl: '',
      viewMoreUrl: '',
      numberOfItem: 0,
    },
  });
  const { canPerform } = usePrivilege();
  const canUpdateWebConfig = canPerform('webConfig', 'update');

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const NumberFormatCustom = (numberInputProps: any) => {
    const { inputRef, onChange, ...other } = numberInputProps;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              // eslint-disable-next-line react/destructuring-assignment
              name: numberInputProps.name,
              value: values.value,
            },
          });
        }}
        isNumericString
        allowNegative={false}
      />
    );
  };

  const tableCellRenderer = (item: RecentCategoryItem): ReactNode => (
    <>
      <AppTableCell>{item.title}</AppTableCell>
      <AppTableCell>{item.iconUrl}</AppTableCell>
      <AppTableCell>{item.apiUrl}</AppTableCell>
      <AppTableCell>{item.viewMoreUrl}</AppTableCell>
      <AppTableCell>{item.numberOfItem}</AppTableCell>
    </>
  );

  const editFormContent = (item: RecentCategoryItem | undefined) => (
    <form>
      <Controller
        defaultValue={item?.title}
        control={control}
        name="title"
        rules={{ required: true }}
        render={({ field }) => (
          <AppFormControl boxProps={{ mb: 3 }} error={!!errors.title}>
            <TextField
              {...field}
              variant="outlined"
              label={`${t('settings:list.recentCategory.title').toString()} (title)`}
              error={!!errors.title}
              disabled={!canUpdateWebConfig}
            />
            {!!errors.title && (
              <FormHelperText error>
                {t('validation:requiredFieldAlt', {
                  fieldName: t('settings:list.recentCategory.title').toString(),
                })}
              </FormHelperText>
            )}
          </AppFormControl>
        )}
      />
      <Controller
        defaultValue={item?.iconUrl}
        control={control}
        name="iconUrl"
        rules={{ required: true }}
        render={({ field }) => (
          <AppFormControl boxProps={{ mb: 3 }} error={!!errors.iconUrl}>
            <AppMediaSelector
              defaultValue={field.value}
              mode="input"
              inputProps={{
                label: t('settings:list.recentCategory.iconUrl').toString(),
              }}
              onFilesSelected={(files: FileItem[]) => {
                if (files.length) {
                  field.onChange(files[0].fullUrl);
                }
              }}
              onValueCleared={() => field.onChange('')}
            />
            {!!errors.iconUrl && (
              <FormHelperText error>
                {t('validation:requiredFieldAlt', {
                  fieldName: t('settings:list.recentCategory.iconUrl').toString(),
                })}
              </FormHelperText>
            )}
          </AppFormControl>
        )}
      />
      <Controller
        defaultValue={item?.apiUrl}
        control={control}
        name="apiUrl"
        rules={{ required: true }}
        render={({ field }) => (
          <AppFormControl boxProps={{ mb: 3 }} error={!!errors.apiUrl}>
            <TextField
              {...field}
              variant="outlined"
              label={`${t('settings:list.recentCategory.apiUrl').toString()} (apiUrl)`}
              error={!!errors.apiUrl}
              disabled={!canUpdateWebConfig}
            />
            {!!errors.apiUrl && (
              <FormHelperText error>
                {t('validation:requiredFieldAlt', {
                  fieldName: t('settings:list.recentCategory.apiUrl').toString(),
                })}
              </FormHelperText>
            )}
          </AppFormControl>
        )}
      />
      <Controller
        control={control}
        name="viewMoreUrl"
        rules={{ required: true }}
        render={({ field }) => (
          <>
            <AppFormControl boxProps={{ mb: 3 }} error={!!errors.viewMoreUrl}>
              <TextField
                {...field}
                variant="outlined"
                label={`${t('settings:list.recentCategory.viewMoreUrl').toString()} (viewMoreUrl)`}
                error={!!errors.viewMoreUrl}
                disabled={!canUpdateWebConfig}
              />
              {!!errors.viewMoreUrl && (
                <FormHelperText error>
                  {t('validation:requiredFieldAlt', {
                    fieldName: t('settings:list.recentCategory.viewMoreUrl').toString(),
                  })}
                </FormHelperText>
              )}
            </AppFormControl>
          </>
        )}
      />
      <Controller
        control={control}
        name="numberOfItem"
        rules={{ required: true }}
        render={({ field }) => (
          <>
            <AppFormControl boxProps={{ mb: 3 }} error={!!errors.numberOfItem}>
              <TextField
                {...field}
                variant="outlined"
                label={`${t('settings:list.recentCategory.numberOfItem').toString()} (numberOfItem)`}
                error={!!errors.numberOfItem}
                InputProps={{ inputComponent: NumberFormatCustom }}
                disabled={!canUpdateWebConfig}
              />
              {!!errors.numberOfItem && (
                <FormHelperText error>
                  {t('validation:requiredFieldAlt', {
                    fieldName: t('settings:list.recentCategory.numberOfItem').toString(),
                  })}
                </FormHelperText>
              )}
            </AppFormControl>
          </>
        )}
      />
    </form>
  );

  const setFormValues = (item: RecentCategoryItem | undefined) => {
    clearErrors();
    setValue('title', item?.title || '');
    setValue('iconUrl', item?.iconUrl || '');
    setValue('apiUrl', item?.apiUrl || '');
    setValue('viewMoreUrl', item?.viewMoreUrl || '');
    setValue('numberOfItem', item?.numberOfItem || 0);
    if (item) {
      trigger();
    }
  };

  return (
    <WebConfigListItemEditor<RecentCategoryItem, RecentCategoryItemFormData>
      configItem={configItem}
      onSave={onSave}
      listTableRowKeyPrefix="recent-cat-"
      listColumns={tableColumns}
      listTableCellsContent={tableCellRenderer}
      dialogFormContent={editFormContent}
      handleSubmit={handleSubmit}
      setEditFormValues={setFormValues}
    />
  );
};

export default WebConfigRecentCategories;
