import React, { FC } from 'react';

import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import useTypographyStyles from '../../../theme/typography.style';

export type AppEmptyMenuItemLabelProps = {
  itemLabel?: string;
};

const AppEmptyMenuItemLabel: FC<AppEmptyMenuItemLabelProps> = (props) => {
  const { itemLabel } = props;
  const typoClasses = useTypographyStyles();
  const { t } = useTranslation();

  return (
    <Typography component="em" className={typoClasses.greyText}>
      {t(itemLabel || 'common:message.unspecified')}
    </Typography>
  );
};

export default AppEmptyMenuItemLabel;
