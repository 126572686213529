/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  browserLocalPersistence,
  GoogleAuthProvider,
  setPersistence,
  signInWithCustomToken,
  signInWithPopup,
  signOut,
} from 'firebase/auth';
import jwtDecode from 'jwt-decode';

import { getMe } from './user';
import { removeAdminTokenCookie, setAdminTokenCookie } from '../helpers/cookies';
import { firebaseAuth } from '../helpers/firebase';
import { apiTCRequest } from '../helpers/request';
import { pickNotEmptyV2 } from '../helpers/utils';
import { ApiResponse, AuthResponse, AuthToken, AuthTokenResponse } from '../models';
import { AdminUserProfile, UserData, UserRole } from '../models/user.model';

const userEmailDomain = (process.env.REACT_APP_USER_EMAIL_DOMAIN || '').split(',');
const accessTokenStorageName = '_auth-token';

export type AuthCheckResponse = {
  user: AdminUserProfile | null;
  error: any;
};

/* eslint-disable camelcase */
export type BackendToken = {
  access_token: string;
  refresh_token: string;
  token_type: string;
  expires_in: string;
  firebase_custom_token?: string;
};
/* eslint-enable camelcase */

/**
 * Check if email is an allowed domain
 *
 * @param email - Email to be checked
 * @returns boolean value to indicate email is allowed or not
 */
const checkUserEmailDomain = (email: string): boolean => {
  if (!email) {
    return false;
  }

  const emailArray = email.split('@');
  return userEmailDomain.includes(emailArray[1]);
};

/**
 * Get saved authentication token
 *
 * @returns Authentication token object or null
 */
export const getAuthToken = (): AuthToken | null => {
  if (!localStorage.getItem(accessTokenStorageName)) {
    return null;
  }

  try {
    return JSON.parse(localStorage.getItem(accessTokenStorageName) as string);
  } catch (e) {
    return null;
  }
};

/**
 * Set or unset authentication token
 *
 * @param accessToken Access token string
 * @param authScheme Authentication scheme
 */
export const setAuthToken = (
  value: {
    adminAccessToken?: string;
    authScheme?: string;
    firebaseIdToken?: string;
    firebaseCustomToken?: string;
  } | null,
): void => {
  if (!value) {
    localStorage.removeItem(accessTokenStorageName);
    removeAdminTokenCookie();
  } else {
    const { adminAccessToken, authScheme = 'Bearer', firebaseIdToken, firebaseCustomToken } = value;
    const existingConfig = getAuthToken();

    if (!existingConfig) {
      localStorage.setItem(
        accessTokenStorageName,
        JSON.stringify({
          scheme: authScheme,
          adminAccessToken,
          firebaseIdToken,
          firebaseCustomToken,
        }),
      );
    } else {
      const newValues: Partial<AuthToken> = pickNotEmptyV2({
        scheme: authScheme,
        adminAccessToken,
        firebaseIdToken,
        firebaseCustomToken,
      });
      localStorage.setItem(
        accessTokenStorageName,
        JSON.stringify({
          ...existingConfig,
          ...newValues,
        }),
      );
    }
    if (firebaseIdToken) {
      setAdminTokenCookie(firebaseIdToken);
    }
  }
};

/**
 * Clear saved access token
 */
export const clearAuthToken = (): void => {
  setAuthToken(null);
  removeAdminTokenCookie();
};

export const firebaseSignInWithCustomToken = async (customToken: string): Promise<string | null> => {
  try {
    // Set authentication session persistence mode to browser local (indexedDB)
    await setPersistence(firebaseAuth, browserLocalPersistence);

    const credential = await signInWithCustomToken(firebaseAuth, customToken);
    const { user: firebaseUser } = credential;

    // If user is valid, we are good to go!
    if (firebaseUser) {
      const finalIdToken = await firebaseUser.getIdToken();
      return finalIdToken;
    }

    return null;
  } catch (ex) {
    return null;
  }
};

export const appSignOut = async (): Promise<boolean> => {
  try {
    await signOut(firebaseAuth);

    return true;
  } catch (error) {
    return false;
  }
};

const checkUserAvailability = (user: UserData) => {
  let isUserAllowed = user?.email ? checkUserEmailDomain(user.email) : false;

  if (user?.role && ![UserRole.GM, UserRole.Manager, UserRole.Staff].includes(user.role as UserRole)) {
    isUserAllowed = false;
  }

  return isUserAllowed;
};

export const appSignIn = async (): Promise<
  ApiResponse<
    | (Omit<AuthResponse, 'customToken'> & {
        idToken: string | null;
      })
    | null
  >
> => {
  let customToken;
  let signInResult;

  // Sign-in with social provider
  const authProvider = new GoogleAuthProvider();
  authProvider.setCustomParameters({
    prompt: 'select_account',
  });
  authProvider.addScope('email');
  authProvider.addScope('profile');

  try {
    const result = await signInWithPopup(firebaseAuth, authProvider);
    const { user } = result;

    if (user) {
      const idToken = await user.getIdToken();

      signInResult = await apiTCRequest<AuthTokenResponse>('/api/social/login', {
        method: 'POST',
        data: {
          provider: 'Firebase',
          accessToken: idToken,
          idToken,
        },
      });

      const { data } = signInResult;
      if (!data) {
        return {
          data: null,
          error: 'User has been signed out due to an unknown error during sign-in',
        };
      }

      customToken = process.env.REACT_APP_V3_ENABLED === 'true' ? data.firebaseCustomToken : data.firebase_custom_token;
    }

    // If 'customToken' is correctly obtained, that means we are ready to use it to sign-in to Firebase
    if (customToken) {
      const finalIdToken = await firebaseSignInWithCustomToken(customToken);

      if (finalIdToken) {
        const decodedToken = jwtDecode(finalIdToken);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const { firebase, aud } = decodedToken as any;
        const { sign_in_provider: signInProvider } = firebase || {};
        const isValidUser =
          signInProvider === 'custom' &&
          /^\d+$/g.test(user.providerData[0].uid) &&
          aud === process.env.REACT_APP_FIREBASE_PROJECT_ID;
        if (!isValidUser) {
          await appSignOut();

          return {
            data: null,
            error: 'error:auth.domainNotAllowed',
          };
        }
      }
      setAuthToken({
        authScheme: 'Bearer',
        firebaseIdToken: finalIdToken || undefined,
      });

      // If finalIdToken is not null, get me
      const userInfo = await getMe();

      if (!userInfo || !checkUserAvailability(userInfo)) {
        await appSignOut();

        return {
          data: null,
          error: 'error:auth.domainNotAllowed',
        };
      }

      return {
        data: {
          idToken: finalIdToken,
        },
        error: !finalIdToken ? 'An error occurred while signing in to Firebase.' : null,
      };
    }

    // If sign-in but got no custom token, sign user out of the app
    await appSignOut();
    return {
      data: null,
      error: 'User has been signed out due to an unknown error during sign-in',
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.message?.toString().indexOf('auth/account-exists-with-different-credential') > -1) {
      return {
        data: null,
        error: 'auth/account-exists-with-different-credential',
      };
    }

    return {
      data: null,
      error: e.message,
    };
  }
};
