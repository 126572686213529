import Cookies from 'js-cookie';

const adminTokenCookieName = 'admin_token';
const cookieDomain = process.env.REACT_APP_COOKIE_DOMAIN || 'localhost';

export const removeAdminTokenCookie = (): void => {
  Cookies.set(adminTokenCookieName, '');
  Cookies.remove(adminTokenCookieName);
};

export const setAdminTokenCookie = (firebaseIdToken: string): void => {
  removeAdminTokenCookie();

  if (cookieDomain && !['localhost'].includes(cookieDomain)) {
    Cookies.set(adminTokenCookieName, firebaseIdToken, {
      expires: 7,
      sameSite: 'lax',
      domain: cookieDomain,
      secure: true,
    });
  } else {
    Cookies.set(adminTokenCookieName, firebaseIdToken, {
      expires: 7,
      sameSite: 'lax',
      domain: cookieDomain || 'localhost',
    });
  }
};
