import React, { FC, useCallback, useEffect, useState } from 'react';

import {
  Box,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Controller, UseControllerProps, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DISPLAY_NAME_REGEX, TEL_REGEX } from '../../../../../../../helpers/constants/regex-pattern';
import {
  ShopType,
  UserDataTC,
  UserEditApplicationShopInfoRequest,
  UserImage,
  UserTCImage,
} from '../../../../../../../models/user.model';
import useControlStyles from '../../../../../../../theme/controls.style';
import AppButton from '../../../../../../ui/AppButton';
import AppCheckbox from '../../../../../../ui/AppCheckbox';
import AppDialog from '../../../../../../ui/AppDialog';
import AppFormControl from '../../../../../../ui/AppFormControl';
import AppFormErrorMessage from '../../../../../../ui/AppFormErrorMessage';
import AppTCFileSelector from '../../../../../../ui/AppTCFileSelector';
import ImagePreview from '../../../../../common/ImagePreview';
import SelectAddress from '../../../../../common/SelectAddress';

type ShopFormModalProps = {
  open: boolean;
  currentUser: UserDataTC;
  onSubmit: (data?: UserEditApplicationShopInfoRequest) => void;
  onClose: () => void;
  rules?: { [x: string]: UseControllerProps['rules'] };
};

const shopTypeList = [
  {
    label: 'Broker Individual',
    value: ShopType.BrokerIndividual,
  },
  {
    label: 'Tent Company',
    value: ShopType.TentCompany,
  },
  {
    label: 'Tent Individual',
    value: ShopType.TentIndividual,
  },
  {
    label: 'User Individual',
    value: ShopType.UserIndividual,
  },
];

const shopProductTypeList = [
  {
    label: 'TRUCK',
    value: 'TRUCK',
  },
  {
    label: 'P-CAR/LCV',
    value: 'P-CAR/LCV',
  },
  {
    label: 'HVMC',
    value: 'HVMC',
  },
  {
    label: 'Agriculture',
    value: 'Agriculture',
  },
  {
    label: 'Motorbike',
    value: 'Motorbike',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

const ShopFormModal: FC<ShopFormModalProps> = (props) => {
  const { open, currentUser, onSubmit, onClose, rules } = props;
  const { t } = useTranslation();
  const controlClasses = useControlStyles();
  const [selectedPreviewImage, setSelectedPreviewImage] = useState<string | undefined>(undefined);
  const getRules = (fieldName: string): UseControllerProps['rules'] | undefined =>
    rules ? rules[fieldName] : undefined;

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    trigger,
    register,
    formState: { errors },
  } = useForm<UserEditApplicationShopInfoRequest>({
    mode: 'onChange',
    defaultValues: {
      userHashId: currentUser.userHashId,
      shopLocationLatitude: currentUser.shopLocationLatitude || undefined,
      shopLocationLongitude: currentUser.shopLocationLongitude || undefined,
      shopName: currentUser.shopName || '',
      shopOtherTelephoneNumbers: currentUser?.shopOtherTelephoneNumbers || [],
      shopProductType: currentUser.shopProductType || [],
      shopDescription: currentUser.shopDescription || '',
      shopProvince: currentUser.shopProvince || '',
      shopDistrict: currentUser.shopDistrict || '',
      shopAddress: currentUser.shopAddress || '',
      shopZipcode: currentUser.shopZipcode || '',
      shopType: currentUser.shopType || 'BROKER_INDIVIDUAL',
      shopLocationImage: currentUser.shopLocationImage || undefined,
      shopHeroImage: currentUser.shopHeroImage || undefined,
    },
  });

  register('shopProvince', {
    required: `${t('user:error.detail.validateProvince')}`,
  });
  register('shopDistrict', {
    required: `${t('user:error.detail.validateDistrict')}`,
  });
  register('shopZipcode', {
    required: `${t('user:error.detail.validateZipCode')}`,
  });

  const handleSaveData = useCallback((data: UserEditApplicationShopInfoRequest) => {
    if (data?.userHashId) {
      onSubmit(data);
      setSelectedPreviewImage(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const imageSlideData = [currentUser?.shopLocationImage?.fullUrl, currentUser?.shopHeroImage?.fullUrl];

  useEffect(() => {
    reset({
      userHashId: currentUser.userHashId,
      shopLocationLatitude: currentUser.shopLocationLatitude || undefined,
      shopLocationLongitude: currentUser.shopLocationLongitude || undefined,
      shopName: currentUser.shopName || '',
      shopOtherTelephoneNumbers: currentUser?.shopOtherTelephoneNumbers || [],
      shopProductType: currentUser.shopProductType || [],
      shopDescription: currentUser.shopDescription || '',
      shopProvince: currentUser.shopProvince || '',
      shopDistrict: currentUser.shopDistrict || '',
      shopAddress: currentUser.shopAddress || '',
      shopZipcode: currentUser.shopZipcode || '',
      shopType: currentUser.shopType || 'BROKER_INDIVIDUAL',
      shopLocationImage: currentUser.shopLocationImage || undefined,
      shopHeroImage: currentUser.shopHeroImage || undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  return (
    <AppDialog
      open={open}
      title={t('user:v3.label.shopEdit')}
      noActionBar
      dialogProps={{
        fullWidth: true,
        maxWidth: 'lg',
        TransitionProps: {
          onExited: () => {
            reset();
          },
        },
      }}>
      <form onSubmit={handleSubmit(handleSaveData)}>
        {!!selectedPreviewImage && (
          <ImagePreview
            imageUrl={selectedPreviewImage}
            onCloseClick={() => setSelectedPreviewImage(undefined)}
            isPreviewSlide
            isFixWidthModal
            imageSlideData={imageSlideData}
          />
        )}
        <Grid container spacing={3}>
          <Grid item xs={12} md={5}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} md={6}>
                <Controller
                  name="shopLocationImage"
                  control={control}
                  rules={getRules('shopLocationImage') || {}}
                  render={({ field }) => (
                    <AppFormControl error={!!errors.shopLocationImage}>
                      <AppTCFileSelector
                        defaultFile={field.value as UserImage}
                        fileMode="image"
                        inputProps={{
                          label: t('user:common.fields.shopLocationImageUrl').toString(),
                          error: !!errors.shopLocationImage,
                        }}
                        onFilesSelected={(file: UserImage) => {
                          if (file) {
                            field.onChange(file.fullUrl);
                            setValue('shopLocationImage', file);
                          }
                        }}
                        onValueCleared={() => field.onChange('')}
                        imageType={UserTCImage.shopLocationImage}
                        userHashId={currentUser.userHashId}
                        onImageClick={setSelectedPreviewImage}
                      />
                      {!!errors.shopLocationImage && <AppFormErrorMessage errors={errors} name="shopLocationImage" />}
                    </AppFormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={6}>
                <Controller
                  name="shopHeroImage"
                  control={control}
                  rules={getRules('shopHeroImage') || {}}
                  render={({ field }) => (
                    <AppFormControl error={!!errors.shopHeroImage}>
                      <AppTCFileSelector
                        defaultFile={field.value as UserImage}
                        fileMode="image"
                        inputProps={{
                          label: t('user:common.fields.shopHeroImageUrl').toString(),
                          error: !!errors.shopHeroImage,
                        }}
                        onFilesSelected={(file: UserImage) => {
                          if (file) {
                            setValue('shopHeroImage', { ...file, style: file.style });
                          }
                        }}
                        onValueCleared={() => field.onChange(null)}
                        imageType={UserTCImage.shopHeroImage}
                        userHashId={currentUser.userHashId}
                        onImageClick={setSelectedPreviewImage}
                      />
                      {!!errors.shopHeroImage && <AppFormErrorMessage errors={errors} name="shopHeroImage" />}
                    </AppFormControl>
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={7}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Box mb={3}>
                  <Controller
                    name="shopLocationLatitude"
                    control={control}
                    rules={{
                      required: false,
                      maxLength: {
                        value: 100,
                        message: t('error:validation.invalidFormat', {
                          fieldName: t('user:common.fields.shopLocationLatitude'),
                        }),
                      },
                    }}
                    render={({ field }) => (
                      <AppFormControl error={!!errors.shopLocationLatitude}>
                        <InputLabel htmlFor="user-app-shop-location-latitude">
                          {t('user:common.fields.shopLocationLatitude')}
                        </InputLabel>

                        <OutlinedInput
                          {...field}
                          id="user-app-shop-location-latitude"
                          fullWidth
                          error={!!errors.shopLocationLatitude}
                        />
                      </AppFormControl>
                    )}
                  />
                </Box>
                <Box mb={3}>
                  <Controller
                    name="shopLocationLongitude"
                    control={control}
                    rules={{
                      required: false,
                      maxLength: {
                        value: 100,
                        message: t('error:validation.invalidFormat', {
                          fieldName: t('user:common.fields.shopLocationLongitude'),
                        }),
                      },
                    }}
                    render={({ field }) => (
                      <AppFormControl error={!!errors.shopLocationLongitude}>
                        <InputLabel htmlFor="user-app-shop-location-longitude">
                          {t('user:common.fields.shopLocationLongitude')}
                        </InputLabel>

                        <OutlinedInput
                          {...field}
                          id="user-app-shop-location-longitude"
                          fullWidth
                          error={!!errors.shopLocationLongitude}
                        />
                      </AppFormControl>
                    )}
                  />
                </Box>
                <Box mb={3}>
                  <Controller
                    name="shopName"
                    control={control}
                    rules={{
                      required: false,
                      maxLength: {
                        value: 100,
                        message: t('error:validation.invalidFormat', {
                          fieldName: t('user:common.fields.shopName'),
                        }),
                      },
                    }}
                    render={({ field }) => (
                      <AppFormControl error={!!errors.shopName}>
                        <InputLabel htmlFor="user-app-shop-name">{t('user:common.fields.shopName')}</InputLabel>

                        <OutlinedInput {...field} id="user-app-shop-name" fullWidth error={!!errors.shopName} />
                      </AppFormControl>
                    )}
                  />
                </Box>
                <Box mb={3}>
                  <Controller
                    name="shopType"
                    control={control}
                    defaultValue={currentUser.shopType}
                    render={({ field }) => (
                      <AppFormControl>
                        <InputLabel htmlFor="user-app-shop-type">{t('user:common.fields.shopType')}</InputLabel>
                        <Select id="user-app-shop-type" {...field} labelId="role-label" variant="outlined" fullWidth>
                          {shopTypeList.map((item) => (
                            <MenuItem value={item.value} key={item.value}>
                              {t(item.label)}
                            </MenuItem>
                          ))}
                        </Select>
                      </AppFormControl>
                    )}
                  />
                </Box>

                <Box mb={3}>
                  <Typography>{t('user:common.fields.shopProductType')}</Typography>
                  <Controller
                    name="shopProductType"
                    control={control}
                    render={({ field }) => (
                      <AppFormControl error={!!errors.shopProductType} style={{ display: 'inline' }}>
                        {shopProductTypeList.map((item) => (
                          <FormControlLabel
                            key={item.value}
                            control={
                              <AppCheckbox
                                color="default"
                                onClick={() => {
                                  if (field.value) {
                                    let newValue = [];
                                    if (!field.value.includes(item.value)) {
                                      newValue = [...field?.value, item.value];
                                    } else {
                                      newValue = [...field?.value].filter((val) => val !== item.value);
                                    }
                                    field.onChange(newValue);
                                  }
                                }}
                                checked={(field.value || ([] as string[])).includes(item.value)}
                              />
                            }
                            label={item.label}
                          />
                        ))}
                      </AppFormControl>
                    )}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box mb={3}>
                  <Controller
                    name="shopDescription"
                    control={control}
                    rules={{
                      required: false,
                      maxLength: {
                        value: 500,
                        message: t('error:validation.invalidFormat', {
                          fieldName: t('user:common.fields.shopDescription'),
                        }),
                      },
                    }}
                    render={({ field }) => (
                      <AppFormControl error={!!errors.shopDescription}>
                        <InputLabel htmlFor="user-app-shop-description">
                          {t('user:common.fields.shopDescription')}
                        </InputLabel>
                        <OutlinedInput
                          {...field}
                          id="user-app-shop-description"
                          fullWidth
                          error={!!errors.shopDescription}
                        />
                      </AppFormControl>
                    )}
                  />
                </Box>
                <Box mb={3}>
                  <Controller
                    name="shopAddress"
                    control={control}
                    rules={{
                      required: false,
                      maxLength: {
                        value: 100,
                        message: t('error:validation.invalidFormat', {
                          fieldName: t('user:common.fields.shopAddress'),
                        }),
                      },
                    }}
                    render={({ field }) => (
                      <AppFormControl error={!!errors.shopAddress}>
                        <InputLabel htmlFor="user-app-shop-address">{t('user:common.fields.shopAddress')}</InputLabel>
                        <OutlinedInput {...field} id="user-app-shop-address" fullWidth error={!!errors.shopAddress} />
                      </AppFormControl>
                    )}
                  />
                </Box>
                <Box mb={3}>
                  <SelectAddress
                    currentValue={{
                      province: watch('shopProvince'),
                      district: watch('shopDistrict'),
                      zipcode: watch('shopZipcode'),
                    }}
                    errorMessage={{
                      province: errors.shopProvince?.message,
                      district: errors.shopDistrict?.message,
                      zipcode: errors.shopZipcode?.message,
                    }}
                    onChange={{
                      province: (value) => {
                        setValue('shopProvince', value);
                        setValue('shopDistrict', '');
                        setValue('shopZipcode', '');
                        if (errors.shopProvince?.message != null) {
                          trigger('shopProvince');
                        }
                      },
                      district: (value) => {
                        setValue('shopDistrict', value);
                        setValue('shopZipcode', '');
                        if (errors.shopDistrict?.message != null) {
                          trigger('shopDistrict');
                        }
                      },
                      zipcode: (value) => {
                        setValue('shopZipcode', value);
                        if (errors.shopZipcode?.message != null) {
                          trigger('shopZipcode');
                        }
                      },
                    }}
                  />
                </Box>
                <Box mb={3}>
                  <Controller
                    control={control}
                    name="shopOtherTelephoneNumbers"
                    render={({ field }) => {
                      const fields = field.value || [];

                      return (
                        <>
                          {fields.length > 0 && (
                            <Box>
                              {fields.map((item, index: number) => (
                                <Box key={item.tel} mb={3}>
                                  <Box style={{ display: 'flex', justifyContent: 'space-between' }} mb={2}>
                                    <Typography>{`${t('user:common.fields.shopOtherTelephoneNumbers')} ${
                                      index + 1
                                    }`}</Typography>
                                    <DeleteForeverIcon
                                      color="error"
                                      onClick={() => {
                                        field.onChange(fields.filter((i, idx) => idx !== index));
                                      }}
                                      type="button"
                                    />
                                  </Box>

                                  <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                      <Controller
                                        name={`shopOtherTelephoneNumbers.${index}.name`}
                                        control={control}
                                        rules={{
                                          required: true,
                                          pattern: {
                                            value: DISPLAY_NAME_REGEX,
                                            message: t('error:validation.invalidFormat', {
                                              fieldMin: 3,
                                              fieldMax: 100,
                                            }),
                                          },
                                        }}
                                        render={(data) => (
                                          <AppFormControl>
                                            <InputLabel>{t('user:common.fields.nameOther')}</InputLabel>
                                            <OutlinedInput {...data.field} fullWidth />
                                          </AppFormControl>
                                        )}
                                      />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                      <Controller
                                        name={`shopOtherTelephoneNumbers.${index}.tel`}
                                        control={control}
                                        rules={{
                                          required: true,
                                          pattern: {
                                            value: TEL_REGEX,
                                            message: t('error:validation.invalidFormat', {
                                              fieldName: t('common:label.telephoneNumber'),
                                            }),
                                          },
                                        }}
                                        render={(data) => (
                                          <AppFormControl>
                                            <InputLabel>{t('user:common.fields.mobile')}</InputLabel>
                                            <OutlinedInput type="number" {...data.field} fullWidth />
                                          </AppFormControl>
                                        )}
                                      />
                                    </Grid>
                                  </Grid>
                                </Box>
                              ))}
                            </Box>
                          )}
                          <Box mt={2}>
                            <AppButton
                              variant="outlined"
                              color="primary"
                              startIcon={<AddIcon />}
                              onClick={() =>
                                field.onChange([
                                  ...fields,
                                  {
                                    name: '' as string,
                                    tel: '' as string,
                                  },
                                ])
                              }>
                              {t(`user:common.fields.shopOtherTelephoneNumbers`)}
                            </AppButton>
                          </Box>
                        </>
                      );
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Box display="flex" justifyContent="flex-end" className={controlClasses.buttonsGroup} mb="16px">
          <AppButton color="default" variant="outlined" onClick={() => onClose()}>
            {t('common:button.cancel')}
          </AppButton>
          <AppButton type="submit" color="primary">
            {t('common:button.save')}
          </AppButton>
        </Box>
      </form>
    </AppDialog>
  );
};

export default ShopFormModal;
