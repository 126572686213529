import React, { FC, useCallback, useEffect } from 'react';

import { Box, FormHelperText, Grid, InputLabel, OutlinedInput } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { UserDataTC, UserEditApplicationBillingInfoRequest } from '../../../../../../../models/user.model';
import useControlStyles from '../../../../../../../theme/controls.style';
import AppButton from '../../../../../../ui/AppButton';
import AppDialog from '../../../../../../ui/AppDialog';
import AppFormControl from '../../../../../../ui/AppFormControl';

type BillingInfoFormModalProps = {
  open: boolean;
  currentUser: UserDataTC;
  onSubmit: (data?: UserEditApplicationBillingInfoRequest) => void;
  onClose: () => void;
};

const BillingInfoFormModal: FC<BillingInfoFormModalProps> = (props: BillingInfoFormModalProps) => {
  const { currentUser, open, onSubmit, onClose } = props;
  const { t } = useTranslation();
  const controlClasses = useControlStyles();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<UserEditApplicationBillingInfoRequest>({
    mode: 'onChange',
    defaultValues: {
      userHashId: currentUser.userHashId,
      billingCompanyName: currentUser.billingCompanyName || '',
      billingAddress: currentUser.billingAddress || '',
      billingTel: currentUser.billingTel || '',
      billingTaxId: currentUser.billingTaxId || '',
    },
  });

  const handleSaveData = useCallback((data: UserEditApplicationBillingInfoRequest) => {
    if (data?.userHashId) {
      onSubmit(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reset({
      userHashId: currentUser.userHashId,
      billingCompanyName: currentUser.billingCompanyName || '',
      billingAddress: currentUser.billingAddress || '',
      billingTel: currentUser.billingTel || '',
      billingTaxId: currentUser.billingTaxId || '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  return (
    <AppDialog
      open={open}
      title={t('user:v3.label.billingEdit')}
      noActionBar
      dialogProps={{
        fullWidth: true,
        maxWidth: 'md',
        TransitionProps: {
          onExited: () => {
            reset();
          },
        },
      }}>
      <form onSubmit={handleSubmit(handleSaveData)}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box mb={2}>
              <Controller
                name="billingCompanyName"
                control={control}
                rules={{
                  required: false,
                  maxLength: {
                    value: 100,
                    message: t('error:validation.invalidFormat', {
                      fieldName: t('user:common.fields.billingCompanyName'),
                    }),
                  },
                }}
                render={({ field }) => (
                  <AppFormControl error={!!errors.billingCompanyName}>
                    <InputLabel htmlFor="user-app-billing-company-name">
                      {t('user:common.fields.billingCompanyName')}
                    </InputLabel>

                    <OutlinedInput
                      {...field}
                      id="user-app-billing-company-name"
                      fullWidth
                      error={!!errors.billingCompanyName}
                    />
                  </AppFormControl>
                )}
              />
            </Box>
            <Box mb={2}>
              <Controller
                name="billingTaxId"
                control={control}
                rules={{
                  required: false,
                  maxLength: {
                    value: 13,
                    message: t('error:validation.invalidFormat', {
                      fieldName: t('user:common.fields.billingTaxId'),
                    }),
                  },
                }}
                render={({ field }) => (
                  <AppFormControl error={!!errors.billingTaxId}>
                    <InputLabel htmlFor="user-app-billing-tax-id">{t('user:common.fields.billingTaxId')}</InputLabel>
                    <OutlinedInput
                      {...field}
                      id="user-app-billing-tax-id"
                      type="number"
                      fullWidth
                      error={!!errors.billingTaxId}
                    />
                    {!!errors.billingTaxId && (
                      <FormHelperText error>
                        {errors.billingTaxId.type === 'required' &&
                          t('validation:requiredField', {
                            fieldName: t('user:common.fields.billingTaxId').toString(),
                          })}
                        {(errors.billingTaxId.type === 'validate' || errors.billingTaxId.type === 'maxLength') &&
                          t('validation:invalidFieldFormat', {
                            fieldName: t('user:common.fields.companyTaxId').toString(),
                            suggestion: t('user:error.detail.nationalIdFormat').toString(),
                          })}
                      </FormHelperText>
                    )}
                  </AppFormControl>
                )}
              />
            </Box>
            <Box mb={2}>
              <Controller
                name="billingTel"
                control={control}
                rules={{
                  required: false,
                  maxLength: {
                    value: 10,
                    message: t('error:validation.invalidFormat', {
                      fieldName: t('user:common.fields.billingTel'),
                    }),
                  },
                }}
                render={({ field }) => (
                  <AppFormControl error={!!errors.billingTel}>
                    <InputLabel htmlFor="user-app-billing-tel">{t('user:common.fields.billingTel')}</InputLabel>

                    <OutlinedInput
                      {...field}
                      id="user-app-billing-tel"
                      type="number"
                      fullWidth
                      error={!!errors.billingTel}
                    />
                  </AppFormControl>
                )}
              />
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Box mb={2}>
              <Controller
                name="billingAddress"
                control={control}
                rules={{
                  required: false,
                  maxLength: {
                    value: 100,
                    message: t('error:validation.invalidFormat', {
                      fieldName: t('user:common.fields.billingAddress'),
                    }),
                  },
                }}
                render={({ field }) => (
                  <AppFormControl error={!!errors.billingCompanyName}>
                    <InputLabel htmlFor="user-app-billing-address">{t('user:common.fields.billingAddress')}</InputLabel>

                    <OutlinedInput
                      {...field}
                      multiline
                      rows={5}
                      id="user-app-billing-address"
                      fullWidth
                      error={!!errors.billingAddress}
                    />
                  </AppFormControl>
                )}
              />
            </Box>
          </Grid>
        </Grid>

        <Box display="flex" justifyContent="flex-end" className={controlClasses.buttonsGroup} mb="16px">
          <AppButton color="default" variant="outlined" onClick={() => onClose()}>
            {t('common:button.cancel')}
          </AppButton>
          <AppButton type="submit" color="primary">
            {t('common:button.save')}
          </AppButton>
        </Box>
      </form>
    </AppDialog>
  );
};

export default BillingInfoFormModal;
