import React, { ChangeEvent, ReactElement, useEffect, useMemo, useState } from 'react';

import { CircularProgress, makeStyles, TextField, TextFieldProps } from '@material-ui/core';
import Autocomplete, { AutocompleteProps, AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete';
import { debounce } from 'lodash';

export type AppAutoCompleteMultipleProps<T> = {
  getData: (value: string) => Promise<T[]>;
  changeDebounceDuration?: number;
  autoCompleteProps?: Omit<AutocompleteProps<T, true, false, true>, 'options' | 'renderInput'>;
  initialValue?: T[] | undefined;
  onValueChange?: (option: T[] | undefined) => void;
  onClose?: () => void;
  textfieldProps?: TextFieldProps;
};

const useStyles = makeStyles((theme) => ({
  autoCompleteInputRoot: {
    '&[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"]': {
      paddingTop: 4,
      paddingBottom: 4,
      height: 'auto',
      minHeight: theme.controls.control.small.height,
    },
  },
  loadingText: {
    fontSize: theme.typography.subtitle2.fontSize,
  },
  noOptionsText: {
    fontSize: theme.typography.subtitle2.fontSize,
  },
  input: {
    height: 'auto',
    minHeight: theme.controls.control.small.height,
  },
}));

const AppAutoCompleteMultiple = <T,>(props: AppAutoCompleteMultipleProps<T>): ReactElement => {
  const classes = useStyles();
  const {
    getData,
    changeDebounceDuration = 500,
    autoCompleteProps,
    initialValue,
    onValueChange: onSelectedValueChange,
    onClose,
    textfieldProps,
  } = props;
  const [currentOptions, setCurrentOptions] = useState<T[]>([]);
  const [selectedValue, setSelectedValue] = useState<T[] | undefined>(initialValue);
  const [inputValue, setInputValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = useMemo(
    () =>
      debounce(({ input }: { input: string }, callback: (results: T[]) => void) => {
        (async () => {
          setIsLoading(true);
          const result = await getData(input);

          setIsLoading(false);
          callback(result);
        })();
      }, changeDebounceDuration),
    [changeDebounceDuration, getData],
  );

  const setValue = (value: T[] | null | undefined) => {
    setCurrentOptions(value || currentOptions);
    setSelectedValue(value as T[] | undefined);
  };

  useEffect(() => {
    let isActive = true;

    if (inputValue === '' || inputValue.length < 3) {
      setCurrentOptions(selectedValue || []);
      return undefined;
    }

    fetchData({ input: inputValue }, (results: T[]) => {
      if (isActive) {
        let newOptions = [] as T[];

        if (selectedValue) {
          newOptions = selectedValue;
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setCurrentOptions(newOptions);
      }
    });

    return () => {
      isActive = false;
    };
  }, [inputValue, selectedValue, fetchData]);

  useEffect(() => {
    setValue(initialValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onAutocompleteInputChange = (e: ChangeEvent<any>, value: string) => {
    setInputValue(value);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onValueChange = (value: (T | string)[]) => {
    setValue(value as T[]);
    if (typeof onSelectedValueChange === 'function') {
      onSelectedValueChange(value as T[] | undefined);
    }
  };

  const onAutocompleteBlur = () => {
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  return (
    <Autocomplete
      {...autoCompleteProps}
      multiple
      classes={{
        ...autoCompleteProps?.classes,
        inputRoot: classes.autoCompleteInputRoot,
        noOptions: classes.noOptionsText,
        loading: classes.loadingText,
      }}
      options={currentOptions}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={selectedValue}
      onChange={(e, value) => {
        onValueChange(value as (T | string)[]);
      }}
      onInputChange={onAutocompleteInputChange}
      onBlur={onAutocompleteBlur}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          variant="outlined"
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: <>{isLoading ? <CircularProgress color="inherit" size={20} /> : null}</>,
          }}
          {...textfieldProps}
        />
      )}
    />
  );
};

export default AppAutoCompleteMultiple;
