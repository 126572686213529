/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { Backdrop, Box, Button, makeStyles, Modal, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  ItemStyled: {
    padding: '6px 12px',
    display: 'block',
    textAlign: 'center',
    width: '100%',
    '&:hover': {
      cursor: 'all 150ms linear',
    },
  },
  IsSelected: {
    backgroundColor: '#F0F4F7',
  },
}));

interface ItemSelectDataProps {
  name: string;
  imageUrl?: string;
  value: string | number;
}

export interface ModalSelectColorProps {
  isOpen: boolean;
  title: string;
  dataList: ItemSelectDataProps[];
  selectedValue?: ItemSelectDataProps['value'];
  onClickItem?: (data: ItemSelectDataProps) => void;
  handleClose: () => void;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ModalSelectColor = (props: ModalSelectColorProps) => {
  const { isOpen, title, dataList, selectedValue, onClickItem, handleClose } = props;
  const classes = useStyles();

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        margin="auto"
        maxWidth={{ _: `calc(100% - 16px * 2)`, md: '450px' }}
        maxHeight={{ _: `calc(100% - 16px * 2)` }}
        borderRadius="sm"
        overflow="hidden">
        <Box bgcolor="white" height="56px" display="flex" alignItems="center" justifyContent="space-between">
          <Typography style={{ paddingLeft: '18px', fontWeight: 'bold' }} color="textPrimary">
            {title}
          </Typography>
          <Button style={{ marginRight: 4, width: '40px', height: '40px' }} onClick={handleClose}>
            <CloseIcon color="error" />
          </Button>
        </Box>
        <Box p={6} bgcolor="white">
          <Box height="max-content" maxHeight="calc(100vh - 200px)" overflow="auto">
            {dataList?.length > 0 ? (
              <Box display="grid" gridGap={4} gridTemplateColumns={{ _: 'repeat(3,1fr)', sm: 'repeat(4,1fr)' }}>
                {dataList.map((item, index) => {
                  const itemName = item.name;

                  return (
                    <Button
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      className={clsx(classes.ItemStyled, {
                        [classes.IsSelected]: selectedValue,
                      })}
                      type="button"
                      onClick={() => {
                        // eslint-disable-next-line no-unused-expressions
                        onClickItem && onClickItem(item);
                        handleClose();
                      }}>
                      <Box mb={2} height="48px" display="flex">
                        {item.imageUrl && (
                          <Box width={48} height={48} margin="auto">
                            <img src={item.imageUrl} width={48} height={48} alt="color" />
                          </Box>
                        )}
                      </Box>
                      <Typography variant="subtitle2">{itemName}</Typography>
                    </Button>
                  );
                })}
              </Box>
            ) : (
              <Typography style={{ marginTop: 4, marginBottom: 4, textAlign: 'center' }}>โปรดรอสักครู่...</Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalSelectColor;
