import React, { FC, useCallback, useContext, useEffect, useState } from 'react';

import {
  Avatar,
  Box,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Controller, UseControllerProps, ValidateResult, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AppGlobalUiContext } from '../../../../../../../context/AppGlobalUiContext';
import { formatDate } from '../../../../../../../helpers/date';
import {
  BankMaster,
  UserDataTC,
  UserEditApplicationPrivateInfoRequest,
  UserImage,
  UserTCImage,
} from '../../../../../../../models';
import { checkBookBankNumberAvailable, checkNationalIdAvailable } from '../../../../../../../services/user';
import useControlStyles from '../../../../../../../theme/controls.style';
import AppButton from '../../../../../../ui/AppButton';
import AppDialog from '../../../../../../ui/AppDialog';
import AppFormControl from '../../../../../../ui/AppFormControl';
import AppFormErrorMessage from '../../../../../../ui/AppFormErrorMessage';
import AppTCFileSelector from '../../../../../../ui/AppTCFileSelector';
import ImagePreview from '../../../../../common/ImagePreview';
import MasterDataDropdown from '../../../../../common/MasterDataDropdown';
import SelectAddress from '../../../../../common/SelectAddress';

export type PrivateInfoFormProps = {
  open: boolean;
  currentUser: UserDataTC;
  onSubmit: (data?: UserEditApplicationPrivateInfoRequest) => void;
  onClose: () => void;
  rules?: { [x: string]: UseControllerProps['rules'] };
};

const useStyles = makeStyles(() => ({
  numberInput: {
    MozAppearance: 'none',
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
  previewImage: {
    maxWidth: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  bankLogo: {
    width: '28px',
    height: '28px',
    marginRight: '8px',
  },
  bankSelectorRoot: {
    display: 'flex',
    alignItems: 'center',
  },
  bankItemName: {
    flexGrow: 1,
    maxWidth: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

const PrivateInfoFormModal: FC<PrivateInfoFormProps> = (props) => {
  const { open, currentUser, onSubmit, rules, onClose } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const controlClasses = useControlStyles();
  const { showSnackbar } = useContext(AppGlobalUiContext);

  const [selectedPreviewImage, setSelectedPreviewImage] = useState<string | undefined>(undefined);

  const [handleNationalId, setHandleNationalId] = useState<string | undefined>(undefined);
  const [isCheckNationalIdDialogOpen, setIsCheckNationalIdDialogOpen] = useState<boolean>(false);
  const [nationalIdAvailable, setNationalIdAvailable] = useState<boolean>(false);

  const [handleBookBankNumber, setHandleBookBankNumber] = useState<string | undefined>(undefined);
  const [isCheckBookBankNumberDialogOpen, setIsCheckBookBankNumberDialogOpen] = useState<boolean>(false);
  const [bookBankNumberAvailable, setBookBankNumberAvailable] = useState<boolean>(false);

  const {
    control,
    watch,
    handleSubmit,
    register,
    reset,
    trigger,
    formState: { errors },
    setValue,
  } = useForm<UserEditApplicationPrivateInfoRequest>({
    mode: 'onChange',
    defaultValues: {
      userHashId: currentUser.userHashId,
      verifiedFullName: currentUser.verifiedFullName || '',
      verifiedCompanyName: currentUser.verifiedCompanyName || '',
      passportNumber: currentUser.passportNumber || '',
      companyTaxId: currentUser.companyTaxId || '',
      nationalId: currentUser.nationalId || '',
      province: currentUser.province || '',
      district: currentUser.district || '',
      address: currentUser.address || '',
      zipcode: currentUser.zipcode || '',
      birthday: currentUser.birthday || '',
      nationality: currentUser.nationality || '',
      gender: currentUser.gender || '',
      selfieImage: currentUser.selfieImage || undefined,
      nidImage: currentUser.nidImage || undefined,
      bookBankNumber: currentUser.bookBankNumber || '',
      bookBankImage: currentUser.bookBankImage || undefined,
      bookBankCompanyHashId: currentUser.bookBankCompany?.hashId,
    },
  });
  register('province', {
    required: `${t('user:error.detail.validateProvince')}`,
  });
  register('district', {
    required: `${t('user:error.detail.validateDistrict')}`,
  });
  register('zipcode', {
    required: `${t('user:error.detail.validateZipCode')}`,
  });

  const isRequireData = watch('companyTaxId') || watch('nationalId') || watch('passportNumber') !== '';

  const getRules = (fieldName: string): UseControllerProps['rules'] | undefined =>
    rules ? rules[fieldName] : undefined;

  const checkNationalIdNotUsed = async (nationalId: string) => {
    const result = await checkNationalIdAvailable(nationalId);
    setNationalIdAvailable(!result);
    setHandleNationalId(nationalId);
    setIsCheckNationalIdDialogOpen(true);
  };

  const checkBookBankNumberDuplicate = async (bookBankNumber: string) => {
    const result = await checkBookBankNumberAvailable(bookBankNumber);
    setBookBankNumberAvailable(!result);
    setHandleBookBankNumber(bookBankNumber);
    setIsCheckBookBankNumberDialogOpen(true);
  };

  const handleSaveData = useCallback((data: UserEditApplicationPrivateInfoRequest) => {
    if (!(data.companyTaxId || data.nationalId || data.passportNumber)) {
      showSnackbar(
        'กรุณากรอกข้อมูล เลขบัตรประชาชน หรือ เลขพาสปอร์ต หรือ เลขประจำตัวผู้เสียภาษี อย่างใดอย่างนึง',
        'warning',
      );
    } else if (data?.userHashId) {
      onSubmit(data);
      setSelectedPreviewImage(undefined);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const idNumberValidator = (value?: string): ValidateResult => {
    if (value && !/\d{13}/g.test(value)) {
      return 'validation:invalidFieldFormat';
    }
    return true;
  };

  const idNumberAlphabetValidator = (value?: string): ValidateResult => {
    if (value && !/^[a-zA-Z0-9]*$/g.test(value)) {
      return 'validation:invalidFieldFormat';
    }
    return true;
  };

  const bankNumberValidator = (value: string): ValidateResult => {
    if (!/\d{10}/g.test(value)) {
      return 'validation:invalidFieldFormat';
    }
    return true;
  };

  const getBankLogoUrl = (url: string): string =>
    `${(process.env.REACT_APP_T2H_FRONTEND_URL || process.env.REACT_APP_DOMAIN_URL) as string}/${url.replace(
      /\//,
      '',
    )}`;

  const imageSlideData = [
    currentUser?.bookBankImage?.fullUrl,
    currentUser?.nidImage?.fullUrl,
    currentUser?.selfieImage?.fullUrl,
  ];

  useEffect(() => {
    reset({
      userHashId: currentUser.userHashId,
      verifiedFullName: currentUser.verifiedFullName || '',
      verifiedCompanyName: currentUser.verifiedCompanyName || '',
      passportNumber: currentUser.passportNumber || '',
      companyTaxId: currentUser.companyTaxId || '',
      nationalId: currentUser.nationalId || '',
      province: currentUser.province || '',
      district: currentUser.district || '',
      address: currentUser.address || '',
      zipcode: currentUser.zipcode || '',
      birthday: currentUser.birthday || '',
      nationality: currentUser.nationality || '',
      gender: currentUser.gender || '',
      selfieImage: currentUser.selfieImage || undefined,
      nidImage: currentUser.nidImage || undefined,
      bookBankNumber: currentUser.bookBankNumber || '',
      bookBankImage: currentUser.bookBankImage || undefined,
      bookBankCompanyHashId: currentUser.bookBankCompany?.hashId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  return (
    <>
      <AppDialog
        open={open}
        title={t('user:v3.label.privateInfoEdit')}
        noActionBar
        dialogProps={{
          fullWidth: true,
          maxWidth: 'lg',
          TransitionProps: {
            onExited: () => {
              reset();
            },
          },
        }}>
        <form onSubmit={handleSubmit(handleSaveData)}>
          {!!selectedPreviewImage && (
            <ImagePreview
              imageUrl={selectedPreviewImage}
              onCloseClick={() => setSelectedPreviewImage(undefined)}
              isPreviewSlide
              isFixWidthModal
              imageSlideData={imageSlideData}
            />
          )}
          <Grid container spacing={3}>
            <Grid item xs={12} md={5}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={6}>
                  <Controller
                    name="nidImage"
                    control={control}
                    rules={getRules('nidImage') || {}}
                    render={({ field }) => (
                      <AppFormControl error={!!errors.nidImage}>
                        <AppTCFileSelector
                          defaultFile={field.value as UserImage}
                          fileMode="image"
                          inputProps={{
                            label: t('user:common.fields.nidImageUrl').toString(),
                            error: !!errors.nidImage,
                          }}
                          onFilesSelected={(file: UserImage) => {
                            if (file) {
                              field.onChange(file.fullUrl);
                              setValue('nidImage', file);
                            }
                          }}
                          onValueCleared={() => field.onChange('')}
                          imageType={UserTCImage.nid}
                          onImageClick={setSelectedPreviewImage}
                        />
                        {!!errors.nidImage && <AppFormErrorMessage errors={errors} name="nidImage" />}
                      </AppFormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={6}>
                  <Controller
                    name="selfieImage"
                    control={control}
                    rules={getRules('selfieImage') || {}}
                    render={({ field }) => (
                      <AppFormControl error={!!errors.selfieImage}>
                        <AppTCFileSelector
                          defaultFile={field.value as UserImage}
                          fileMode="image"
                          inputProps={{
                            label: t('user:common.fields.selfieImageUrl').toString(),
                            error: !!errors.selfieImage,
                          }}
                          onFilesSelected={(file: UserImage) => {
                            if (file) {
                              field.onChange(file.fullUrl);
                              setValue('selfieImage', file);
                            }
                          }}
                          onValueCleared={() => field.onChange('')}
                          imageType={UserTCImage.selfie}
                          onImageClick={setSelectedPreviewImage}
                        />
                        {!!errors.selfieImage && <AppFormErrorMessage errors={errors} name="selfieImage" />}
                      </AppFormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={6}>
                  <Controller
                    name="bookBankImage"
                    control={control}
                    rules={getRules('bookBankImage') || {}}
                    render={({ field }) => (
                      <AppFormControl error={!!errors.bookBankImage}>
                        <AppTCFileSelector
                          defaultFile={field.value as UserImage}
                          fileMode="image"
                          inputProps={{
                            label: t('user:common.fields.bookBankImageUrl').toString(),
                            error: !!errors.bookBankImage,
                          }}
                          onFilesSelected={(file: UserImage) => {
                            if (file) {
                              field.onChange(file.fullUrl);
                              setValue('bookBankImage', file);
                            }
                          }}
                          onValueCleared={() => field.onChange('')}
                          imageType={UserTCImage.bookBank}
                          onImageClick={setSelectedPreviewImage}
                        />
                        {!!errors.bookBankImage && <AppFormErrorMessage errors={errors} name="bookBankImage" />}
                      </AppFormControl>
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={7}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="verifiedFullName"
                        control={control}
                        rules={{
                          required: false,
                          maxLength: {
                            value: 100,
                            message: t('error:validation.invalidFormat', {
                              fieldName: t('user:common.fields.verifiedFullName'),
                            }),
                          },
                        }}
                        render={(data) => (
                          <AppFormControl error={!!errors.verifiedFullName}>
                            <InputLabel htmlFor="user-app-verified-fullName">
                              {t('user:common.fields.verifiedFullName')}
                            </InputLabel>
                            <Box style={{ position: 'relative' }}>
                              <OutlinedInput
                                {...data.field}
                                id="user-app-verified-fullName"
                                fullWidth
                                error={!!errors.verifiedFullName}
                              />
                            </Box>
                          </AppFormControl>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="verifiedCompanyName"
                        control={control}
                        rules={{
                          required: false,
                          maxLength: {
                            value: 100,
                            message: t('error:validation.invalidFormat', {
                              fieldName: t('user:common.fields.verifiedCompanyName'),
                            }),
                          },
                        }}
                        render={(data) => (
                          <AppFormControl error={!!errors.verifiedCompanyName}>
                            <InputLabel htmlFor="user-app-verified-company-name">
                              {t('user:common.fields.verifiedCompanyName')}
                            </InputLabel>
                            <Box style={{ position: 'relative' }}>
                              <OutlinedInput
                                {...data.field}
                                id="user-app-verified-company-name"
                                fullWidth
                                error={!!errors.verifiedCompanyName}
                              />
                            </Box>
                          </AppFormControl>
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <Controller
                        name="passportNumber"
                        control={control}
                        rules={{
                          required: !isRequireData,
                          validate: idNumberAlphabetValidator,
                        }}
                        render={({ field }) => (
                          <AppFormControl>
                            <InputLabel htmlFor="user-app-passport-number">
                              {t('user:common.fields.passportNumber')}
                            </InputLabel>
                            <OutlinedInput
                              {...field}
                              id="user-app-passport-number"
                              fullWidth
                              error={!!errors.passportNumber && !isRequireData}
                            />
                            {!!errors.passportNumber && !isRequireData && (
                              <FormHelperText error>
                                {errors.passportNumber.type === 'required' &&
                                  t('user:error.detail.fieldsPrivateInfoRequire')}
                                {(errors.passportNumber.type === 'validate' ||
                                  errors.passportNumber.type === 'maxLength') &&
                                  t('validation:invalidFieldFormat', {
                                    fieldName: t('user:common.fields.passportNumber').toString(),
                                  })}
                              </FormHelperText>
                            )}
                          </AppFormControl>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Controller
                        name="companyTaxId"
                        control={control}
                        rules={{
                          required: !isRequireData,
                          maxLength: 13,
                          validate: idNumberValidator,
                        }}
                        render={({ field }) => (
                          <AppFormControl>
                            <InputLabel htmlFor="user-app-company-tax-id">
                              {t('user:common.fields.companyTaxId')}
                            </InputLabel>
                            <OutlinedInput
                              {...field}
                              id="user-app-company-tax-id"
                              type="number"
                              fullWidth
                              error={!!errors.companyTaxId && !isRequireData}
                            />
                            {!!errors.companyTaxId && !isRequireData && (
                              <FormHelperText error>
                                {errors.companyTaxId.type === 'required' &&
                                  t('user:error.detail.fieldsPrivateInfoRequire')}
                                {(errors.companyTaxId.type === 'validate' ||
                                  errors.companyTaxId.type === 'maxLength') &&
                                  t('validation:invalidFieldFormat', {
                                    fieldName: t('user:common.fields.companyTaxId').toString(),
                                    suggestion: t('user:error.detail.nationalIdFormat').toString(),
                                  })}
                              </FormHelperText>
                            )}
                          </AppFormControl>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Controller
                        name="nationalId"
                        control={control}
                        rules={{
                          required: !isRequireData,
                          maxLength: 13,
                          validate: idNumberValidator,
                        }}
                        render={({ field }) => (
                          <AppFormControl>
                            <InputLabel htmlFor="user-app-national-id">{t('user:common.fields.nationalId')}</InputLabel>
                            <OutlinedInput
                              {...field}
                              id="user-app-national-id"
                              type="number"
                              fullWidth
                              error={!!errors.nationalId && !isRequireData}
                              inputProps={{
                                className: classes.numberInput,
                              }}
                              endAdornment={
                                <InputAdornment position="end">
                                  <AppButton
                                    size="small"
                                    variant="outlined"
                                    onClick={() => checkNationalIdNotUsed(field.value ? field.value : '')}>
                                    {t('common:button.check')}
                                  </AppButton>
                                </InputAdornment>
                              }
                            />
                            {!!errors.nationalId && !isRequireData && (
                              <FormHelperText error>
                                {errors.nationalId.type === 'required' &&
                                  t('user:error.detail.fieldsPrivateInfoRequire')}
                                {(errors.nationalId.type === 'validate' || errors.nationalId.type === 'maxLength') &&
                                  t('validation:invalidFieldFormat', {
                                    fieldName: t('user:common.fields.nationalId').toString(),
                                    suggestion: t('user:error.detail.nationalIdFormat').toString(),
                                  })}
                              </FormHelperText>
                            )}
                          </AppFormControl>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <Controller
                        name="birthday"
                        control={control}
                        defaultValue={currentUser.birthday}
                        rules={{
                          required: false,
                        }}
                        render={({ field }) => (
                          <AppFormControl error={!!errors.birthday}>
                            <InputLabel htmlFor="user-app-birthday">{t('user:common.fields.birthday')}</InputLabel>
                            <Box>
                              <OutlinedInput
                                id="user-app-birthday"
                                fullWidth
                                type="date"
                                defaultValue={field.value ? formatDate(new Date(field.value), 'YYYY-MM-dd') : null}
                                onChange={field.onChange}
                                error={!!errors.birthday}
                              />
                            </Box>
                          </AppFormControl>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Controller
                        name="nationality"
                        control={control}
                        render={({ field }) => (
                          <AppFormControl error={!!errors.nationality}>
                            <InputLabel htmlFor="user-app-nationality">
                              {t('user:common.fields.nationality')}
                            </InputLabel>
                            <Select
                              id="user-app-nationality"
                              value={field.value}
                              onChange={field.onChange}
                              error={!!errors.nationality}
                              fullWidth>
                              <MenuItem value="Thai">{t('user:common.fields.nationalityTh')}</MenuItem>
                            </Select>
                          </AppFormControl>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Controller
                        name="gender"
                        control={control}
                        render={({ field }) => (
                          <AppFormControl error={!!errors.gender}>
                            <InputLabel htmlFor="user-app-gender">{t('user:common.fields.gender')}</InputLabel>
                            <Select
                              id="user-app-gender"
                              value={field.value}
                              onChange={field.onChange}
                              error={!!errors.gender}
                              fullWidth>
                              <MenuItem value="M">{t('user:common.fields.male')}</MenuItem>
                              <MenuItem value="F">{t('user:common.fields.female')}</MenuItem>
                              <MenuItem value="other">{t('user:common.fields.other')}</MenuItem>
                            </Select>
                            {!!errors.gender && (
                              <FormHelperText error>
                                {t('validation:requiredFieldAlt', {
                                  fieldName: t('board:card.common.fields.boardType').toString(),
                                })}
                              </FormHelperText>
                            )}
                          </AppFormControl>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="bookBankNumber"
                        control={control}
                        rules={{
                          required: true,
                          minLength: 10,
                          maxLength: 20,
                          validate: bankNumberValidator,
                        }}
                        render={({ field }) => (
                          <AppFormControl>
                            <InputLabel htmlFor="user-app-book-bank-number">
                              {t('user:common.fields.bookBankNumber')}
                            </InputLabel>
                            <OutlinedInput
                              {...field}
                              id="user-app-book-bank-number"
                              type="number"
                              fullWidth
                              error={!!errors.bookBankNumber}
                              inputProps={{
                                className: classes.numberInput,
                                onKeyDown: (e) => {
                                  const isNumberKey = /[0-9]/g.test(e.key);
                                  if (field.value.length < 20) {
                                    return true;
                                  }

                                  if (!isNumberKey) {
                                    return true;
                                  }

                                  e.preventDefault();
                                  return false;
                                },
                              }}
                              endAdornment={
                                <InputAdornment position="end">
                                  <AppButton
                                    onClick={() => checkBookBankNumberDuplicate(field.value)}
                                    size="small"
                                    variant="outlined">
                                    {t('common:button.check')}
                                  </AppButton>
                                </InputAdornment>
                              }
                            />
                            {!!errors.bookBankNumber && (
                              <FormHelperText error>
                                {errors.bookBankNumber.type === 'required' &&
                                  t('validation:requiredField', {
                                    fieldName: t('user:common.fields.bookBankNumber').toString(),
                                  })}
                                {(errors.bookBankNumber.type === 'validate' ||
                                  errors.bookBankNumber.type === 'maxLength' ||
                                  errors.bookBankNumber.type === 'minLength') &&
                                  t('validation:invalidFieldFormat', {
                                    fieldName: t('user:common.fields.bookBankNumber').toString(),
                                    suggestion: t('user:error.detail.bookBankNumberIdFormat').toString(),
                                  })}
                              </FormHelperText>
                            )}
                          </AppFormControl>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="bookBankCompanyHashId"
                        control={control}
                        rules={{
                          required: true,
                          maxLength: {
                            value: 100,
                            message: t('error:validation.invalidFormat', {
                              fieldName: t('user:common.fields.bookBankCompanyHashId'),
                            }),
                          },
                        }}
                        render={({ field }) => (
                          <AppFormControl error={!!errors.bookBankCompanyHashId}>
                            <MasterDataDropdown
                              dataKey="bankMaster"
                              itemValuePropName="hashId"
                              itemLabelPropName="thaiName"
                              inputLabel={t('user:common.fields.bookBankCompanyHashId').toString()}
                              value={field.value}
                              onChange={field.onChange}
                              classes={{ root: classes.bankSelectorRoot }}
                              error={!!errors.bookBankCompanyHashId}
                              menuItemComponent={(item: BankMaster) => (
                                <>
                                  <Avatar
                                    alt={item.thaiName}
                                    src={getBankLogoUrl(item.logo)}
                                    className={classes.bankLogo}
                                  />
                                  <span className={classes.bankItemName}>{item.thaiName}</span>
                                </>
                              )}
                            />

                            {!!errors.bookBankCompanyHashId && (
                              <FormHelperText error>
                                {t('validation:requiredSelectField', {
                                  fieldName: t('user:common.fields.bookBankCompanyHashId').toString(),
                                })}
                              </FormHelperText>
                            )}
                          </AppFormControl>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="address"
                        control={control}
                        rules={{
                          required: true,
                          maxLength: {
                            value: 100,
                            message: t('error:validation.invalidFormat', {
                              fieldName: t('user:common.fields.address'),
                            }),
                          },
                        }}
                        render={({ field }) => (
                          <AppFormControl>
                            <InputLabel htmlFor="user-app-address">{t('user:common.fields.address')}</InputLabel>
                            <OutlinedInput {...field} id="user-app-address" fullWidth error={!!errors.address} />
                            {!!errors.address && (
                              <FormHelperText error>
                                {errors.address.type === 'required' &&
                                  t('validation:requiredField', {
                                    fieldName: t('user:common.fields.address').toString(),
                                  })}
                                {(errors.address.type === 'validate' || errors.address.type === 'maxLength') &&
                                  t('validation:invalidFieldFormat', {
                                    fieldName: t('user:common.fields.address').toString(),
                                  })}
                              </FormHelperText>
                            )}
                          </AppFormControl>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <SelectAddress
                        currentValue={{
                          province: watch('province'),
                          district: watch('district'),
                          zipcode: watch('zipcode'),
                        }}
                        errorMessage={{
                          province: errors.province?.message,
                          district: errors.district?.message,
                          zipcode: errors.zipcode?.message,
                        }}
                        onChange={{
                          province: (value) => {
                            setValue('province', value);
                            setValue('district', '');
                            setValue('zipcode', '');
                            if (errors.province?.message != null) {
                              trigger('province');
                            }
                          },
                          district: (value) => {
                            setValue('district', value);
                            setValue('zipcode', '');
                            if (errors.district?.message != null) {
                              trigger('district');
                            }
                          },
                          zipcode: (value) => {
                            setValue('zipcode', value);
                            if (errors.zipcode?.message != null) {
                              trigger('zipcode');
                            }
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="flex-end" className={controlClasses.buttonsGroup} my="16px">
            <AppButton
              color="default"
              variant="outlined"
              onClick={() => {
                onClose();
                setSelectedPreviewImage(undefined);
              }}>
              {t('common:button.cancel')}
            </AppButton>
            <AppButton type="submit" color="primary">
              {t('common:button.save')}
            </AppButton>
          </Box>
        </form>
      </AppDialog>

      <AppDialog
        open={isCheckNationalIdDialogOpen}
        title="common:dialog.title.info"
        okButtonText="common:button.close"
        okButtonColor="primary"
        okButtonVariant="outlined"
        onOkClick={() => setIsCheckNationalIdDialogOpen(false)}>
        <Typography>
          {t(
            `seller-applications:common.message.${
              nationalIdAvailable ? 'nationalIdCardUsed' : 'nationalIdCardNotUsed'
            }`,
            {
              fieldName: handleNationalId,
            },
          )}
        </Typography>
      </AppDialog>

      <AppDialog
        open={isCheckBookBankNumberDialogOpen}
        title="common:dialog.title.info"
        okButtonText="common:button.close"
        okButtonColor="primary"
        okButtonVariant="outlined"
        onOkClick={() => setIsCheckBookBankNumberDialogOpen(false)}>
        <Typography>
          {t(`seller-applications:common.message.${bookBankNumberAvailable ? 'bankAccNoUsed' : 'bankAccNoNotUsed'}`, {
            fieldName: handleBookBankNumber,
          })}
        </Typography>
      </AppDialog>
    </>
  );
};

export default PrivateInfoFormModal;
