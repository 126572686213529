/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import dayjs from 'dayjs';
import {
  collection,
  DocumentData,
  getDocs,
  query,
  QueryConstraint,
  QueryDocumentSnapshot,
  SnapshotOptions,
  Timestamp,
  where,
} from 'firebase/firestore';

import getMessageGroupMemberData from './group-member-data';
import { firestoreDb, FS_DB_COLLECTION } from '../../../helpers/firebase';
import { ApiResponse } from '../../../models';
import { MessageGroup } from '../../../models/message-group';
import { MessageUser } from '../../../models/message-user';

const convertDataToMessageGroup = async (id: string, data: DocumentData) => {
  const { createdAt, isPrivate, blockingUsers = [], recentMessage, allMembers = '' } = data;
  const createdDate = createdAt ? dayjs((createdAt as Timestamp).toDate()) : null;

  let recentMessageCreateAtDate: dayjs.Dayjs | undefined;
  let recentMessageData =
    recentMessage && typeof recentMessage.id === 'string'
      ? {
          ...recentMessage,
        }
      : null;
  if (recentMessage) {
    if (typeof recentMessage.createdAt === 'string') {
      recentMessageCreateAtDate = dayjs(recentMessage.createdAt, 'YYYY-MM-DD HH:mm:ss');
    } else if (typeof recentMessage.createdAt?.seconds === 'number') {
      recentMessageCreateAtDate = dayjs.unix(recentMessage.createdAt.seconds);
    }
    recentMessageData = {
      ...recentMessage,
      createdAt: recentMessageCreateAtDate || undefined,
    };
  }

  return new MessageGroup({
    id,
    createdAt: createdDate,
    createdBy: null,
    members: [],
    allMembers,
    isPrivate,
    recentMessage: recentMessageData,
    blockingUsers,
  });
};

const memberFilter = async (hashId: string, data: MessageGroup[]) => {
  const hashIds = data.map((item) => item.allMemberHashIdList.filter((id) => id !== hashId)[0]);

  let membersData: MessageUser[] = [];
  if (hashIds.length) {
    membersData = await getMessageGroupMemberData([...hashIds, hashId]);
  }

  const newMessageGroup = data.map((item) => {
    let createdUser: MessageUser | null = null;

    if (item.createdBy && membersData.length > 0) {
      createdUser = membersData.find((u) => u.hashId === item.createdBy) || null;
    }

    return new MessageGroup({
      ...item,
      createdBy: createdUser,
      members: membersData.filter((mData) => item.allMemberHashIdList.includes(mData.hashId)),
    });
  });

  return newMessageGroup;
};

export const messageGroupListConverter = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toFirestore: (form: any) => ({
    ...form,
  }),
  fromFirestore: async (snapshot: QueryDocumentSnapshot, options: SnapshotOptions) => {
    const data = snapshot.data(options);
    const result = await convertDataToMessageGroup(snapshot.id, data);
    return result;
  },
};

const getGroupListByUserId = async (hashId: string): Promise<ApiResponse<MessageGroup[]>> => {
  try {
    const ref = collection(firestoreDb, FS_DB_COLLECTION.messageGroups);

    // NOTE Can't do complicate query form firestore
    const queryActiveMember: QueryConstraint[] = [where('members', 'array-contains', hashId)];
    const messageQueryActiveMember = await getDocs(
      query(ref, ...queryActiveMember).withConverter(messageGroupListConverter),
    );

    const queryInActiveMember: QueryConstraint[] = [where('inactiveMembers', 'array-contains', hashId)];
    const messageQueryInactiveMember = await getDocs(
      query(ref, ...queryInActiveMember).withConverter(messageGroupListConverter),
    );

    let memberActiveGroupList: MessageGroup[] = [];
    if (!messageQueryActiveMember.empty) {
      memberActiveGroupList = await Promise.all(
        messageQueryActiveMember.docs.map(async (groupDoc) => {
          const data = await groupDoc.data();
          return data;
        }),
      );
    }

    let memberInActiveGroupList: MessageGroup[] = [];
    if (!messageQueryInactiveMember.empty) {
      memberInActiveGroupList = await Promise.all(
        messageQueryInactiveMember.docs.map(async (groupDoc) => {
          const data = await groupDoc.data();
          return data;
        }),
      );
    }
    const messageGroupListAll: MessageGroup[] = await memberFilter(hashId, [
      ...memberActiveGroupList,
      ...memberInActiveGroupList,
    ]);

    return {
      data: messageGroupListAll,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export default getGroupListByUserId;
