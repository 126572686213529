import React, { FC, useContext, useState } from 'react';

import { Box, Container } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useDeepCompareEffect from 'use-deep-compare-effect';

import CategoryListSearchForm from '../../../components/partials/master-data/category/CategoryListSearchForm';
import CategoryListTable from '../../../components/partials/master-data/category/CategoryListTable';
import AppBreadcrumb from '../../../components/ui/AppBreadcrumb';
import AppButton from '../../../components/ui/AppButton';
import { AppGlobalUiContext } from '../../../context/AppGlobalUiContext';
import { SearchFilterContext } from '../../../context/SearchFilterContext';
import { defaultRowsPerPage } from '../../../helpers/constants';
import {
  AppTableConditions,
  BreadcrumbItem,
  Category,
  CategoryListSearchParams,
  CategorySearchFormData,
} from '../../../models';
import * as categoryService from '../../../services/category';
import useAppContainerStyles from '../../../theme/container.style';
import useAppTableStyles from '../../../theme/table.style';

const searchFilterContextKey = 'master_category';

const CategoryList: FC = () => {
  const { uid: masterIndexUid } = useParams<{ uid: string }>();
  const { t } = useTranslation();
  const containerClasses = useAppContainerStyles();
  const tableClasses = useAppTableStyles();
  const { showSnackbar } = useContext(AppGlobalUiContext);

  const [isLoading, setIsLoading] = useState(true);
  const [categoryData, setCategoryData] = useState<Category[]>([]);
  const [totalCategory, setTotalCategory] = useState<number>(0);
  // start filling default search filters (either from the one we saved in context or fresh conditions)
  const { currentFilters: ctxFilters, setFilters } = useContext(SearchFilterContext);
  const currentFilters: CategoryListSearchParams = ctxFilters ? ctxFilters[searchFilterContextKey] : undefined;
  const [searchFilters, setSearchFilters] = useState<CategorySearchFormData>({
    searchField: currentFilters?.searchField,
    searchKeyword: currentFilters?.searchKeyword || '',
    masterIndexUid,
  });
  const [tableConditions, setTableConditions] = useState<AppTableConditions>({
    page: currentFilters?.page || 0,
    rowsPerPage: currentFilters?.rowsPerPage || defaultRowsPerPage,
    sortColumn: currentFilters?.sortColumn || 'name',
    sortDirection: currentFilters?.sortDirection || 'desc',
  });
  const breadcrumbItems: BreadcrumbItem[] = [
    { label: 'common:sidebar.menu.masterData', url: '/master-data' },
    { label: 'master-data:menu.masterIndex', url: '/master-data/master-index' },
    {
      label: 'master-data:menu.masterIndexUid',
      url: `/master-data/master-index/${masterIndexUid}`,
      labelParam: {
        masterIndexUid,
      },
    },
    { label: 'master-data:menu.category' },
  ];

  const getCurrentSearchParam = (): CategoryListSearchParams => ({
    page: tableConditions.page || 0,
    rowsPerPage: tableConditions.rowsPerPage || defaultRowsPerPage,
    sortColumn: tableConditions.sortColumn || 'name',
    sortDirection: tableConditions.sortDirection || 'asc',
    searchField: searchFilters.searchField,
    searchKeyword: searchFilters.searchKeyword,
    masterIndexUid,
  });

  const requestCategoryList = async (isPageSubscribed = true): Promise<boolean> => {
    if (isPageSubscribed) {
      setIsLoading(true);

      const searchConditions = getCurrentSearchParam();

      // Save search filter to search filter context
      setFilters(searchFilterContextKey, searchConditions);

      const result = await categoryService.getCategoryList(searchConditions);

      setIsLoading(false);

      if (result.data) {
        setCategoryData(result.data.list);
        setTotalCategory(result.data.totalCount);
        return Promise.resolve(true);
      }

      showSnackbar(t(result.error as string).toString(), 'error');
      return Promise.reject(result.error);
    }

    return false;
  };

  useDeepCompareEffect(() => {
    let isActive = true;

    requestCategoryList(isActive);

    return () => {
      isActive = false;
    };
  }, [tableConditions, searchFilters]);

  const tableConditionsChangedHandler = (values: AppTableConditions) => {
    setTableConditions(values);
  };

  const onSearchFormSubmit = (filters: CategorySearchFormData) => {
    if (tableConditions.page !== 0) {
      tableConditions.page = 0;
    }

    setSearchFilters(filters);
  };

  return (
    <>
      <Box px={6} py={3}>
        <AppBreadcrumb items={breadcrumbItems} />
      </Box>
      <CategoryListSearchForm currentFilters={searchFilters} onSearchFormSubmit={onSearchFormSubmit} />
      <Container className={containerClasses.container}>
        <Box className={tableClasses.tableContainer}>
          <Box mb={2}>
            <AppButton
              color="primary"
              variant="outlined"
              startIcon={<Add />}
              url={`/master-data/master-index/${masterIndexUid}/categories/create`}>
              {t('common:button.addField', {
                field: t('master-data:common.field.category').toString(),
              })}
            </AppButton>
          </Box>

          <CategoryListTable
            currentConditions={tableConditions}
            categoryList={categoryData}
            totalCategories={totalCategory}
            isLoadingData={isLoading}
            onTableConditionsChanged={tableConditionsChangedHandler}
          />
        </Box>
      </Container>
    </>
  );
};

export default CategoryList;
