import { apiTCRequest } from '../../helpers/request';
import { queryParamGenerator } from '../../helpers/utils';
import { ApiResponse } from '../../models';
import { GetOrderOnceListTCResponse, OrderFiltersTC } from '../../models/order-order.model';

const getOrderApproveListQueryParam = (param?: OrderFiltersTC): string => {
  const reqParam: { [x: string]: string | number } = {
    page: param?.page || 0, // plus 1 for index shift between FE component and backend query
    per_page: param?.rowsPerPage || 10,
    sort: param?.sortColumn ? `id%7C${(param?.sortDirection || 'desc').toUpperCase()}` : '',
  };

  if (param?.searchKeyword && param?.searchField) {
    reqParam[param.searchField] = param.searchKeyword.trim();
  }

  if (param?.filteringStatus?.length) {
    reqParam.orderStateWorkflow = param.filteringStatus.join(',');
  }

  if (param?.filteringPayment?.length) {
    reqParam.paymentMethod = param.filteringPayment.join(',');
  }

  if (param?.q) {
    reqParam.q = param.q;
  }

  return queryParamGenerator(reqParam);
};

// eslint-disable-next-line import/prefer-default-export
export const getOrderApprove = async (param?: OrderFiltersTC): Promise<ApiResponse<GetOrderOnceListTCResponse>> => {
  try {
    const queryParamString = getOrderApproveListQueryParam(param);
    const result = await apiTCRequest<GetOrderOnceListTCResponse>(`/admin/order/once?${queryParamString}`);

    return {
      data: result.data ?? null,
    };
  } catch (e) {
    return {
      data: null,
      error: 'order:error.list.requestFailed',
    };
  }
};
