import React, { FC } from 'react';

import { Chip, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { UserApplication } from '../../../../../models/user-application.model';

export type UserApplicationStatusBadgeProps = {
  status: UserApplication['verifiedAt'];
};

const useStyles = makeStyles((theme) => ({
  statusChip: {
    height: '26px',
    lineHeight: '26px',
  },
  statusApproved: {
    backgroundColor: theme.customPalette.appStatus.approved.main,
    color: theme.customPalette.appStatus.approved.contrastText,
  },
  statusPending: {
    backgroundColor: theme.customPalette.appStatus.pending.main,
    color: theme.customPalette.appStatus.pending.contrastText,
  },
}));

const SellerApplicationStatusBadge: FC<UserApplicationStatusBadgeProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { status } = props;

  return (
    <Chip
      classes={{ root: classes.statusChip }}
      className={clsx({
        [classes.statusApproved]: !!status,
        [classes.statusPending]: !status,
      })}
      label={
        status
          ? t(`user-applications:common.statuses.approved`).toString()
          : t(`user-applications:common.statuses.pending`).toString()
      }
    />
  );
};

export default SellerApplicationStatusBadge;
