import React, { FC, useEffect, useState, MouseEvent } from 'react';

import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Dialog,
  Divider,
  FormHelperText,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import BrokenImageIcon from '@material-ui/icons/BrokenImage';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import LaunchIcon from '@material-ui/icons/Launch';
import clsx from 'clsx';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import CommonWebConfigFields from './CommonWebConfigFields';
import { getT2HAssetUrl, getT2HSiteUrl } from '../../../../helpers/utils';
import usePrivilege from '../../../../hooks/usePrivilege';
import { FileItem, HeroBannerConfig, HeroBannerItem, WebConfig, WebConfigEditFormProps } from '../../../../models';
import useTypographyStyles from '../../../../theme/typography.style';
import AppButton from '../../../ui/AppButton';
import AppDialog from '../../../ui/AppDialog';
import AppFormControl from '../../../ui/AppFormControl';
import AppMediaSelector from '../../../ui/AppMediaSelector';

type HeroBannerFormProps = {
  imageUrl: string;
  link: string;
};

type PreviewImage = {
  url: string;
  size: {
    width: number;
    height: number;
  };
};

type HeroBannerType = 'desktop' | 'mobile';

const useStyles = makeStyles((theme) => ({
  imageBox: {
    height: '200px',
    overflow: 'hidden',
  },
  bannerThumbnailCard: {
    height: '100%',
  },
  bannerThumbnailImage: {
    height: '160px',
    width: '100%',
    cursor: 'pointer',
  },
  bannerThumbnailBrokenImage: {
    height: '160px',
    width: '100%',
    cursor: 'default',
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.grey[400],
  },
  bannerThumbnailCardContent: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  bannerLinkIcon: {
    display: 'inline-flex',
    verticalAlign: 'middle',
    marginLeft: '4px',
    transform: 'translateY(-2px)',
  },
  imagePreviewDialog: {
    backgroundColor: 'transparent',
    borderRadius: '0',
    boxShadow: 'none',
    width: 'auto',
    maxWidth: 'calc(100% - 64px)',
  },
  previewImage: {
    width: 'auto',
    maxWidth: '100%',
    alignSelf: 'center',
  },
  editDialogRoot: {
    width: '100%',
  },
  editDialogSm: {
    maxWidth: '600px',
  },
  editDialogMd: {
    maxWidth: '700px',
  },
  editDialogLg: {
    maxWidth: '900px',
  },
  imagePreviewText: {
    color: theme.palette.common.white,
  },
  emptyBannerLink: {
    height: '40px',
  },
}));

const WebConfigHeroBanner: FC<WebConfigEditFormProps> = (props) => {
  const typoClasses = useTypographyStyles();
  const classes = useStyles();
  const { t } = useTranslation();
  const { configItem, onSave } = props;
  const [config, setConfig] = useState(configItem);
  const [configObject, setConfigObject] = useState<HeroBannerConfig | undefined>(undefined);
  const [selectedImage, setSelectedImage] = useState<PreviewImage | undefined>(undefined);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
  const [selectedItemType, setSelectedItemType] = useState<HeroBannerType | undefined>(undefined);
  const [selectedItem, setSelectedItem] = useState<HeroBannerItem | undefined>(undefined);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<HeroBannerFormProps>({
    mode: 'onSubmit',
    defaultValues: {
      imageUrl: '',
      link: '',
    },
  });
  const isDataModified =
    configItem.configType !== config.configType || configItem.description !== JSON.stringify(configObject);
  const { canPerform } = usePrivilege();
  const canUpdateWebConfig = canPerform('webConfig', 'update');

  useEffect(() => {
    try {
      const configJsonObject = JSON.parse(configItem.description) as HeroBannerConfig;
      setConfigObject(configJsonObject);
    } catch (e) {
      setConfigObject(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCommonFieldsChanged = (data: Partial<WebConfig>) => {
    setConfig({
      ...config,
      ...data,
    });
  };

  const saveConfigData = () => {
    if (typeof onSave === 'function') {
      onSave({
        ...config,
        description: JSON.stringify(
          configObject || {
            desktop: [],
            mobile: [],
          },
        ),
      });
    }
  };

  const openPreview = (image: PreviewImage) => {
    setSelectedImage(image);
  };

  const closePreview = () => {
    setSelectedImage(undefined);
  };

  const closeDeleteConfirmDialog = () => {
    setSelectedItemIndex(-1);
    setSelectedItemType(undefined);
    setIsDeleteDialogOpen(false);
  };

  const onDeleteConfirm = () => {
    if (configObject && selectedItemType) {
      const newBannerList = [...configObject[selectedItemType]];
      newBannerList.splice(selectedItemIndex, 1);
      setConfigObject({
        ...configObject,
        [selectedItemType]: newBannerList,
      });
      closeDeleteConfirmDialog();
    }
  };

  const openDeleteConfirmDialog = (e: MouseEvent, index: number, type: HeroBannerType) => {
    e.stopPropagation();
    setSelectedItemIndex(index);
    setSelectedItemType(type);
    setIsDeleteDialogOpen(true);
  };

  const closeEditDialog = () => {
    setSelectedItemIndex(-1);
    setSelectedItemType(undefined);
    setSelectedItem(undefined);
    setIsEditDialogOpen(false);
  };

  const onEditSave = (formData: HeroBannerFormProps) => {
    if (configObject) {
      if (selectedItemIndex > -1) {
        configObject[selectedItemType as HeroBannerType][selectedItemIndex] = formData;
      } else {
        configObject[selectedItemType as HeroBannerType].push(formData);
      }

      closeEditDialog();
    }
  };

  const onEditSaveClick = () => {
    handleSubmit(onEditSave)();
  };

  const openEditDialog = (e: MouseEvent, index: number, type: HeroBannerType) => {
    if (configObject) {
      const selectedBannerItem = index === -1 ? undefined : configObject[type][index];
      setSelectedItemType(type);
      setSelectedItemIndex(index);
      setSelectedItem(selectedBannerItem);
      setIsEditDialogOpen(true);

      setValue('imageUrl', selectedBannerItem?.imageUrl || '');
      setValue('link', selectedBannerItem?.link || '');
    }
  };

  const BannerThumbnailCard = ({
    banner,
    index,
    type,
  }: {
    banner: HeroBannerItem;
    index: number;
    type: HeroBannerType;
  }) => {
    const imageUrl = /^(http)s*:\/\/.+$/gi.test(banner.imageUrl) ? banner.imageUrl : getT2HAssetUrl(banner.imageUrl);
    const bannerLink = /^(http)s*:\/\/.+$/gi.test(banner.link) ? banner.link : getT2HSiteUrl(banner.link);
    const [actualSize, setActualSize] = useState<PreviewImage['size']>({
      width: 0,
      height: 0,
    });
    const [isImageReady, setIsImageReady] = useState(false);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    useEffect((): any => {
      let isSubscribed = true;

      const image = new Image();
      image.onload = (e) => {
        const img = e.target as HTMLImageElement;
        if (isSubscribed) {
          setActualSize({
            width: img.width,
            height: img.height,
          });
          setIsImageReady(true);
        }
      };
      image.onerror = () => {
        if (isSubscribed) {
          setIsImageReady(true);
        }
      };

      image.src = imageUrl;

      return () => (isSubscribed = false);
    }, [imageUrl]);

    return (
      <Card classes={{ root: classes.bannerThumbnailCard }}>
        {isImageReady && actualSize.width > 0 && actualSize.height > 0 && (
          <CardMedia
            image={imageUrl}
            title={`${type} banner ${index}`}
            className={classes.bannerThumbnailImage}
            onClick={() =>
              openPreview({
                url: imageUrl,
                size: actualSize,
              })
            }
          />
        )}
        {(actualSize.width === 0 || actualSize.height === 0) && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            className={classes.bannerThumbnailBrokenImage}>
            <BrokenImageIcon fontSize="large" />
          </Box>
        )}
        <CardContent
          className={clsx(classes.bannerThumbnailCardContent, {
            [classes.emptyBannerLink]: !banner.link,
          })}>
          {!!banner.link && (
            <Typography component="a" variant="subtitle2" href={bannerLink} target="_blank">
              {t('settings:detail.config.bannerLink')}
              <LaunchIcon fontSize="small" className={classes.bannerLinkIcon} />
            </Typography>
          )}
        </CardContent>

        {canUpdateWebConfig && (
          <CardActions>
            <AppButton
              size="small"
              color="default"
              variant="text"
              startIcon={<EditIcon fontSize="small" />}
              onClick={(e) => openEditDialog(e, index, type)}>
              {t('common:button.edit')}
            </AppButton>
            <AppButton
              size="small"
              color="error"
              variant="text"
              startIcon={<DeleteIcon fontSize="small" className={typoClasses.errorText} />}
              onClick={(e) => openDeleteConfirmDialog(e, index, type)}>
              {t('common:button.delete')}
            </AppButton>
          </CardActions>
        )}
      </Card>
    );
  };

  const BannerThumbnailList = ({ type }: { type: HeroBannerType }) => {
    if (!configObject) {
      return null;
    }

    return (
      <Box my={2}>
        {canUpdateWebConfig && (
          <Box mb={2}>
            <AppButton
              color="primary"
              variant="outlined"
              startIcon={<AddIcon fontSize="small" />}
              onClick={(e) => openEditDialog(e, -1, type)}>
              {t('common:button.add')}
            </AppButton>
          </Box>
        )}
        <Grid container spacing={2}>
          {configObject[type].map((banner, idx) => {
            const itemKey = `${type}-banner-${idx}`;

            return (
              <Grid item xs={12} sm={6} md={4} lg={3} key={itemKey}>
                <BannerThumbnailCard banner={banner} index={idx} type={type} />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    );
  };

  return (
    <>
      {!!configObject && (
        <>
          <CommonWebConfigFields
            config={config}
            onDataChange={onCommonFieldsChanged}
            onSaveClick={saveConfigData}
            showModifiedDataWarning={isDataModified}
          />
          <Box mt={3}>
            <Typography component="h6" variant="h6" className={typoClasses.textWeightBold}>
              {t('settings:detail.config.desktopScreen')}
            </Typography>
            <BannerThumbnailList type="desktop" />
          </Box>
          <Divider />
          <Box mt={2}>
            <Typography component="h6" variant="h6" className={typoClasses.textWeightBold}>
              {t('settings:detail.config.mobileScreen')}
            </Typography>
            <BannerThumbnailList type="mobile" />
          </Box>
        </>
      )}
      <AppDialog
        open={isEditDialogOpen}
        title={t(`settings:dialog.config.heroBanner.${selectedItem ? 'edit' : 'addNew'}`, {
          screenSize: t(`settings:detail.config.${selectedItemType}Screen`).toString(),
        })}
        onClose={closeEditDialog}
        onOkClick={onEditSaveClick}
        okButtonText={t(`common:button.${selectedItem ? 'edit' : 'add'}`).toString()}
        okButtonColor={selectedItem ? 'success' : 'primary'}
        cancelButtonText="common:button.cancel"
        dialogProps={{
          disableBackdropClick: true,
          classes: {
            paper: classes.editDialogRoot,
            paperWidthSm: classes.editDialogSm,
            paperWidthMd: classes.editDialogMd,
            paperWidthLg: classes.editDialogLg,
          },
        }}>
        <Controller
          control={control}
          name="imageUrl"
          rules={{ required: true }}
          render={({ field }) => (
            <AppFormControl boxProps={{ mt: 3 }} error={!!errors.imageUrl}>
              <AppMediaSelector
                defaultValue={field.value}
                mode="input"
                inputProps={{
                  label: t('settings:detail.config.imageUrl').toString(),
                }}
                onFilesSelected={(files: FileItem[]) => {
                  if (files.length) {
                    field.onChange(files[0].fullUrl);
                  }
                }}
                onValueCleared={() => field.onChange('')}
              />
              <FormHelperText error={!!errors.imageUrl}>
                {!!errors.imageUrl &&
                  t('validation:requiredFieldAlt', { fieldName: t('settings:detail.config.imageUrl').toString() })}
                {!errors.imageUrl && t('settings:dialog.config.heroBanner.imageUrlHelpText')}
              </FormHelperText>
            </AppFormControl>
          )}
        />
        <Controller
          control={control}
          name="link"
          render={({ field }) => (
            <AppFormControl boxProps={{ mt: 3 }}>
              <TextField {...field} label={t('settings:detail.config.bannerLink').toString()} variant="outlined" />
              <FormHelperText>{t('settings:dialog.config.heroBanner.bannerLinkHelpText')}</FormHelperText>
            </AppFormControl>
          )}
        />
      </AppDialog>
      <AppDialog
        open={isDeleteDialogOpen}
        title="common:dialog.title.confirm"
        cancelButtonText="common:button.cancel"
        okButtonText="common:button.delete"
        okButtonColor="error"
        onClose={closeDeleteConfirmDialog}
        onOkClick={onDeleteConfirm}>
        {t('settings:message.config.bannerDeleteWarning')}
      </AppDialog>
      <Dialog open={!!selectedImage} onClose={closePreview} fullWidth classes={{ paper: classes.imagePreviewDialog }}>
        <img src={selectedImage?.url} alt="preview" className={classes.previewImage} />
        <Box mt={1} display="flex" justifyContent="flex-end">
          <Typography variant="subtitle2" component="label" align="center" className={classes.imagePreviewText}>
            {t('settings:detail.config.actualImageSize', {
              size: `${selectedImage?.size.width} x ${selectedImage?.size.height} px`,
            })}
          </Typography>
        </Box>
      </Dialog>
    </>
  );
};

export default WebConfigHeroBanner;
