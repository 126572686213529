/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useContext, useEffect, useState } from 'react';

import { Box, Card, CardContent, Grid, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { AppGlobalUiContext } from '../../../../context/AppGlobalUiContext';
import { getS3TdFile } from '../../../../services/s3-td';
import AppCircularProgress from '../../AppCircularProgress';
import DashboardItem from '../DashboardItem';

export type DashboardTopWebStatisticProps = {
  selectPeriod: string;
  isPageView?: boolean;
};

const popularList = {
  filter: '',
  growth: '',
  isgrowing: '',
  key: '',
  time: '',
  title: '',
  totalcount: '',
  totalcountprev: '',
};

const webStatisticList = { ...popularList };

const useStyles = makeStyles((theme) => ({
  colItem: {
    width: 'calc(100% / 5) !important',
  },
  card: {
    borderRadius: '16px',
    boxShadow: `0 0 15px 0px ${theme.palette.grey[300]}`,
    minHeight: '120px',
  },
  cardContent: {
    padding: theme.spacing(2, 1),
  },
}));

const DashboardTopWebStatistic: FC<DashboardTopWebStatisticProps> = (props) => {
  const { selectPeriod, isPageView = false } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const [webStatistic, setWebStatistic] = useState<any>(webStatisticList);
  const { setAppLoading, showSnackbar } = useContext(AppGlobalUiContext);

  const requestTopWebStatistic = async () => {
    const result = await getS3TdFile(encodeURIComponent('web_statistics.csv'));
    setAppLoading(true);

    if (result.data?.data) {
      const dataFilter = result.data?.data.filter((item) => item.filter === selectPeriod);

      if (isPageView) {
        const pageViewData = dataFilter.slice(-2);
        setWebStatistic(pageViewData);
      } else {
        const webStatisticData = dataFilter.slice(0, 15);
        setWebStatistic(webStatisticData);
      }
    } else {
      showSnackbar(
        t('dashboard:error.list.requestFailed', {
          fieldName: t('dashboard:v3.title.webStatistic').toString(),
        }),
        'error',
      );
    }
    setAppLoading(false);
  };

  useEffect(() => {
    requestTopWebStatistic();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectPeriod]);

  return (
    <>
      {webStatistic.length > 0 ? (
        <Grid container direction="row" spacing={2}>
          {webStatistic.map((item: any, idx: number) => {
            const itemKey = `${item}${idx}`;
            return (
              <Grid
                key={itemKey}
                item
                xs={12}
                sm={isPageView ? 12 : 4}
                lg={isPageView ? 12 : 'auto'}
                className={classes.colItem}>
                <Card variant="outlined" className={classes.card}>
                  <CardContent className={classes.cardContent}>
                    <DashboardItem
                      title={item.title}
                      totalCount={item.totalcount}
                      growth={item.growth}
                      isGrowing={item.isgrowing}
                    />
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <Box>
          <AppCircularProgress />
        </Box>
      )}
    </>
  );
};

export default DashboardTopWebStatistic;
