import React, { FC } from 'react';

import { Box, CircularProgress, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const AppCircularProgress: FC = () => {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height={200}>
      <Box component="div" mb={1}>
        <CircularProgress />
      </Box>
      <Typography variant="body2">{t('common:message.loading')}</Typography>
    </Box>
  );
};

export default AppCircularProgress;
