import React, { FC, useCallback, useContext, useState } from 'react';

import { Box, Container, IconButton, makeStyles, TableRow, Tooltip, Typography } from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import clsx from 'clsx';
import copy from 'copy-to-clipboard';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { AppGlobalUiContext } from '../../../../../context/AppGlobalUiContext';
import { AppTableConditions, TableColumn } from '../../../../../models';
import { UserApplication } from '../../../../../models/user-application.model';
import {
  approveUserApplicationByHashId,
  deleteUserApplicationByHashId,
} from '../../../../../services/v3/user-applications';
import useAppContainerStyles from '../../../../../theme/container.style';
import useAppTableStyles from '../../../../../theme/table.style';
import AppButton from '../../../../ui/AppButton';
import AppDialog from '../../../../ui/AppDialog';
import AppTableCell from '../../../../ui/AppTableCell';
import AppTableList from '../../../../ui/AppTableList';
import UserApplicationStatusBadge from '../UserApplicationStatusBadge';

export type UserListTableProps = {
  currentConditions: AppTableConditions;
  totalApplications: number;
  isLoadingData: boolean;
  userApplicationList: UserApplication[];
  onTableConditionsChanged: (values: AppTableConditions) => void;
};

const userTableColumns: TableColumn[] = [
  { name: 'status', label: 'user-applications:list.columns.status', width: 120, unsortable: true },
  { name: 'email', label: 'user-applications:list.columns.email', width: 220, unsortable: true },
  { name: 'verifiedAt', label: 'user-applications:list.columns.verifiedAt', width: 100 },
  { name: 'expiredAt', label: 'user-applications:list.columns.expiredAt', width: 100 },
  { name: 'manage', label: 'user-applications:list.columns.manage', width: 180, unsortable: true },
];

const useStyles = makeStyles((theme) => ({
  dateDisplayText: {
    lineHeight: 1.3,
  },
  userNameText: {
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
  },
  userAvatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    border: `solid 1px ${theme.palette.grey[300]}`,
    boxSizing: 'border-box',
  },
}));

const UserApplicationListTable: FC<UserListTableProps> = (props) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { totalApplications, isLoadingData, currentConditions, userApplicationList, onTableConditionsChanged } = props;
  const classes = useStyles();
  const containerClasses = useAppContainerStyles();
  const tableClasses = useAppTableStyles();
  const [tableConditions, setTableConditions] = useState<AppTableConditions>(currentConditions);
  const dateDisplayFormat = i18n.language === 'th' ? 'D MMM BBBB' : 'D MMM YYYY';

  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [isApproveConfirmOpen, setIsApproveConfirmOpen] = useState(false);
  const { showSnackbar, setAppLoading } = useContext(AppGlobalUiContext);
  const [selectedUserApplication, setSelectedUserApplication] = useState<UserApplication | undefined>(undefined);

  const onTableConditionChangedHandler = (values: AppTableConditions) => {
    if (JSON.stringify(values) !== JSON.stringify(tableConditions)) {
      setTableConditions(values);
      onTableConditionsChanged(values);
    }
  };

  const onApproveConfirm = useCallback(async () => {
    if (selectedUserApplication?.hashId) {
      setAppLoading(true);
      const result = await approveUserApplicationByHashId(selectedUserApplication?.hashId);
      setAppLoading(false);

      if (result.data) {
        showSnackbar(t('user-applications:common.message.approvedSuccess').toString(), 'success');
        setIsApproveConfirmOpen(false);
        window.location.href = '/v3/user-applications';
      } else {
        setIsApproveConfirmOpen(false);
        showSnackbar(t('user-applications:error.list.approveFailed').toString(), 'error');
      }
    }
  }, [selectedUserApplication?.hashId, setAppLoading, showSnackbar, t]);

  const onDeleteConfirm = useCallback(async () => {
    if (selectedUserApplication?.hashId) {
      setAppLoading(true);
      const result = await deleteUserApplicationByHashId(selectedUserApplication?.hashId);
      setAppLoading(false);

      if (result.data) {
        showSnackbar(t('user-applications:common.message.deleteSuccess').toString(), 'success');
        setIsDeleteConfirmOpen(false);
        window.location.href = '/v3/user-applications';
      } else {
        setIsDeleteConfirmOpen(false);
        showSnackbar(t('user-applications:error.list.deleteFailed').toString(), 'error');
      }
    }
  }, [selectedUserApplication?.hashId, setAppLoading, showSnackbar, t]);

  return (
    <>
      <Container className={containerClasses.container}>
        <Box className={tableClasses.tableContainer}>
          <AppTableList
            {...tableConditions}
            aria-label="user-list"
            columns={userTableColumns}
            totalDataCount={totalApplications}
            hasPagination
            onTableConditionChanged={onTableConditionChangedHandler}
            isLoading={isLoadingData}
            tableBodyContent={
              userApplicationList.length > 0 && (
                <>
                  {userApplicationList?.map((app: UserApplication) => {
                    const dataDiff = dayjs(app.expiredAt).diff(dayjs(new Date()), 'd', true);
                    const isCloseExpire = dataDiff <= 7 && !app.verifiedAt && !app.isExpired;

                    return (
                      <TableRow
                        key={app.hashId}
                        classes={{ root: clsx(tableClasses.tableRow, tableClasses.clickableTableRow) }}>
                        <AppTableCell>
                          <UserApplicationStatusBadge status={app.verifiedAt} />
                        </AppTableCell>
                        <AppTableCell>
                          <Box style={{ display: 'flex' }}>
                            <Typography
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                marginRight: '4px',
                              }}>
                              {app.email}
                            </Typography>
                            {isCloseExpire && (
                              <Tooltip
                                title={t('user-applications:list:label.copy').toString()}
                                placement="top"
                                aria-label="clone">
                                <IconButton
                                  aria-label="edit"
                                  disableRipple
                                  disableFocusRipple
                                  size="small"
                                  onClick={() => {
                                    copy(app.verificationLink ? app.verificationLink : 'null');
                                    showSnackbar(
                                      t('user-applications:common.message.copySuccess').toString(),
                                      'success',
                                    );
                                  }}>
                                  <FileCopyOutlinedIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            )}
                          </Box>
                        </AppTableCell>
                        <AppTableCell>
                          {app.verifiedAt ? (
                            <Typography component="div" variant="subtitle2" className={classes.dateDisplayText}>
                              {dayjs(app.verifiedAt).format(`${dateDisplayFormat}`)}
                              <br />
                              {dayjs(app.verifiedAt).format(`HH:mm:ss`)}
                            </Typography>
                          ) : (
                            '-'
                          )}
                        </AppTableCell>
                        <AppTableCell>
                          {isCloseExpire && (
                            <Tooltip
                              title={t('user-applications:list:label.closeExpire').toString()}
                              placement="top"
                              aria-label="clone">
                              <Typography
                                component="div"
                                variant="subtitle2"
                                className={classes.dateDisplayText}
                                color="error">
                                {dayjs(app.expiredAt).format(`${dateDisplayFormat}`)}
                                <br />
                                {dayjs(app.expiredAt).format(`HH:mm:ss`)}
                              </Typography>
                            </Tooltip>
                          )}
                          {app.isExpired && (
                            <Tooltip
                              title={t('user-applications:list:label.expired').toString()}
                              placement="top"
                              aria-label="clone">
                              <Typography
                                component="div"
                                variant="subtitle2"
                                className={classes.dateDisplayText}
                                style={{ color: 'red' }}>
                                {dayjs(app.expiredAt).format(`${dateDisplayFormat}`)}
                                <br />
                                {dayjs(app.expiredAt).format(`HH:mm:ss`)}
                              </Typography>
                            </Tooltip>
                          )}
                          {!isCloseExpire && !app.isExpired && (
                            <Typography component="div" variant="subtitle2" className={classes.dateDisplayText}>
                              {dayjs(app.expiredAt).format(`${dateDisplayFormat}`)}
                              <br />
                              {dayjs(app.expiredAt).format(`HH:mm:ss`)}
                            </Typography>
                          )}
                        </AppTableCell>
                        <AppTableCell>
                          <AppButton
                            color="primary"
                            disabled={!isCloseExpire}
                            style={{ marginRight: '12px' }}
                            onClick={() => {
                              setIsApproveConfirmOpen(true);
                              setSelectedUserApplication(app);
                            }}>
                            {t('user-applications:list.button.approve')}
                          </AppButton>
                          <AppButton
                            variant="outlined"
                            color="error"
                            onClick={() => {
                              setIsDeleteConfirmOpen(true);
                              setSelectedUserApplication(app);
                            }}>
                            {t('user-applications:list.button.delete')}
                          </AppButton>
                        </AppTableCell>
                      </TableRow>
                    );
                  })}
                </>
              )
            }
          />
        </Box>
        <AppDialog
          open={isApproveConfirmOpen}
          title={t('user-applications:dialog.approve.title')}
          okButtonText={t('user-applications:list.button.approve')}
          okButtonColor="primary"
          cancelButtonText={t('common:button.cancel')}
          onClose={() => {
            setIsApproveConfirmOpen(false);
            setSelectedUserApplication(undefined);
          }}
          onOkClick={onApproveConfirm}>
          <Box width="100%" maxWidth="320px">
            <Typography>{t('user-applications:dialog.approve.userAppApproveDialogDesc1')}</Typography>
            <Typography>{selectedUserApplication?.email}</Typography>
          </Box>
        </AppDialog>

        <AppDialog
          open={isDeleteConfirmOpen}
          title={t('user-applications:dialog.delete.title')}
          okButtonText={t('user-applications:list.button.delete')}
          okButtonColor="error"
          cancelButtonText={t('common:button.cancel')}
          onClose={() => {
            setIsDeleteConfirmOpen(false);
            setSelectedUserApplication(undefined);
          }}
          onOkClick={onDeleteConfirm}>
          <Box width="100%" maxWidth="320px">
            <Typography>{t('user-applications:dialog.delete.userAppDeleteDialogDesc1')}</Typography>
            <Typography>{selectedUserApplication?.email}</Typography>
          </Box>
        </AppDialog>
      </Container>
    </>
  );
};

export default UserApplicationListTable;
