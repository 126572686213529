import React, { FC } from 'react';

import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const AppNoTableData: FC = () => {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height={200}
      style={{ textAlign: 'center' }}>
      <Typography variant="body2">{t('common:message.dataNotFound')}</Typography>
    </Box>
  );
};

export default AppNoTableData;
