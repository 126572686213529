import React, { FC } from 'react';

import { Box, Chip, makeStyles } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import { useTranslation } from 'react-i18next';

import { SelectItem } from '../../../models';

export type AppChipListProps = {
  items: SelectItem<string>[];
  selectedValues: string[];
  onChange: (selectedValues: string[]) => void;
  isDisabled?: boolean;
};

const useStyles = makeStyles(() => ({
  selectedChipIcon: {
    marginRight: '4px',
    marginBottom: '4px',
    '& .MuiChip-icon': {
      order: 3,
      marginRight: '8px',
      marginLeft: '-6px',
    },
  },
}));

const AppChipList: FC<AppChipListProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isDisabled, items, selectedValues, onChange } = props;

  const chipClickHandler = (key: string) => {
    let newSelectedValues = [...selectedValues];
    if (newSelectedValues.includes(key)) {
      newSelectedValues = newSelectedValues.filter((val) => val !== key);
    } else {
      newSelectedValues.push(key);
    }

    onChange(newSelectedValues);
  };

  return (
    <Box>
      {items.map((item) => (
        <Chip
          key={item.value}
          label={t(item.label).toString()}
          variant="outlined"
          color={selectedValues.includes(item.value) ? 'primary' : 'default'}
          clickable
          icon={selectedValues.includes(item.value) ? <DoneIcon fontSize="small" /> : undefined}
          onClick={() => chipClickHandler(item.value)}
          className={classes.selectedChipIcon}
          disabled={isDisabled}
        />
      ))}
    </Box>
  );
};

export default AppChipList;
