import React, { FC, useCallback, useContext, useState } from 'react';

import { Box, Container, Grid, TableRow, Typography, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteForever from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import clsx from 'clsx';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useTranslation } from 'react-i18next';

import { AppGlobalUiContext } from '../../../../../context/AppGlobalUiContext';
import usePrivilege from '../../../../../hooks/usePrivilege';
import i18n from '../../../../../i18n';
import { AppTableConditions, TableColumn } from '../../../../../models';
import { LoginBonus, LoginBonusFormDataRequest } from '../../../../../models/login-bonus.model';
import { addNewLoginBonus, deleteCampaignByHashId, updateLoginBonus } from '../../../../../services/v3/login-bonus';
import useAppContainerStyles from '../../../../../theme/container.style';
import useAppTableStyles from '../../../../../theme/table.style';
import AppButton from '../../../../ui/AppButton';
import AppDialog from '../../../../ui/AppDialog';
import AppTableCell from '../../../../ui/AppTableCell';
import AppTableList from '../../../../ui/AppTableList';
import DataBlock from '../../../common/DataBlock';
import AddNewLoginBonusItemDialog from '../AddNewLoginBonusItemDialog';
import LoginBonusItemDialog from '../LoginBonusItemDialog';

dayjs.extend(customParseFormat);

export type LoginBonusListTableProps = {
  currentConditions: AppTableConditions;
  totalBonusList: number;
  isLoadingData: boolean;
  loginBonusList: LoginBonus[];

  onFetch: () => void;
  onTableConditionsChanged: (values: AppTableConditions) => void;
};

const useStyles = makeStyles((theme) => ({
  buttonGroups: {
    '& > *': {
      margin: `0 ${theme.spacing(1)}px`,
    },
  },
}));

const loginBonusTableColumns: TableColumn[] = [
  { name: 'hashId', label: 'daily-challenge:loginBonus.list.columns.hashId', width: 120, unsortable: true },
  { name: 'title', label: 'daily-challenge:loginBonus.list.columns.campaignName', width: 200, unsortable: true },
  {
    name: 'startDate',
    label: 'daily-challenge:loginBonus.list.columns.startDateAt',
    width: 120,
    unsortable: true,
  },
  { name: 'endtDate', label: 'daily-challenge:loginBonus.list.columns.endDateAt', width: 120, unsortable: true },
  { name: 'days', label: 'daily-challenge:loginBonus.list.columns.days', width: 100, unsortable: true },
  { name: 'createdAt', label: 'daily-challenge:loginBonus.list.columns.createdAt', width: 120, unsortable: true },
  { name: 'updatedAt', label: 'daily-challenge:loginBonus.list.columns.updatedAt', width: 120, unsortable: true },
  { name: 'manage', label: '', width: 180, unsortable: true },
];

const LoginBonusListTable: FC<LoginBonusListTableProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { totalBonusList, isLoadingData, currentConditions, loginBonusList, onFetch, onTableConditionsChanged } = props;
  const containerClasses = useAppContainerStyles();
  const tableClasses = useAppTableStyles();
  const [tableConditions, setTableConditions] = useState<AppTableConditions>(currentConditions);
  const { showSnackbar, setAppLoading } = useContext(AppGlobalUiContext);

  const [selectedLoginBonusItem, setSelectedLoginBonusItem] = useState<LoginBonus | undefined>(undefined);
  const [isLoginBonusDialogOpen, setIsLoginBonusDialogOpen] = useState<boolean>(false);
  const [isAddLoginBonusDialogOpen, setIsAddLoginBonusDialogOpen] = useState<boolean>(false);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

  const { canPerform } = usePrivilege();
  const allowCreate = canPerform('loginBonus', 'create');
  const allowEdit = canPerform('loginBonus', 'update');
  const dateDisplayFormat = i18n.language === 'th' ? 'D MMM BBBB' : 'D MMM YYYY';
  const now = new Date();
  const currentTime = now.getTime();

  const onTableConditionChangedHandler = useCallback(
    (values: AppTableConditions) => {
      if (JSON.stringify(values) !== JSON.stringify(tableConditions)) {
        setTableConditions(values);
        onTableConditionsChanged(values);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableConditions],
  );

  const openLoginBonusDetailPage = useCallback(async (item: LoginBonus) => {
    setSelectedLoginBonusItem(item);
    setIsLoginBonusDialogOpen(true);
  }, []);

  const onEditFormSubmit = useCallback(
    async (data?: LoginBonusFormDataRequest) => {
      if (selectedLoginBonusItem && data) {
        setAppLoading(true);
        const startDate = data.startDate ? dayjs(data.startDate).valueOf() : null;
        const endDate = data.endDate ? dayjs(data.endDate).valueOf() : null;

        const dayRewards = [
          Number(data.day1),
          Number(data.day2),
          Number(data.day3),
          Number(data.day4),
          Number(data.day5),
          Number(data.day6),
          Number(data.day7),
          Number(data.day8),
          Number(data.day9),
          Number(data.day10),
        ].filter((item) => item !== 0);

        delete data.day1;
        delete data.day2;
        delete data.day3;
        delete data.day4;
        delete data.day5;
        delete data.day6;
        delete data.day7;
        delete data.day8;
        delete data.day9;
        delete data.day10;
        delete data.period;

        const result = await updateLoginBonus(selectedLoginBonusItem?.hashId, {
          ...data,
          startDate,
          endDate,
          dayRewards,
        });

        if (result.data) {
          setAppLoading(false);
          setSelectedLoginBonusItem(result.data);
          showSnackbar(t('daily-challenge:loginBonus.message.updateSuccess').toString(), 'success');
          setIsLoginBonusDialogOpen(false);
          onFetch();
        } else if (
          result.error.response.data.details ===
          'challengeCampaign cannot edit due to time to update less than 60 to start campaign'
        ) {
          showSnackbar(t('daily-challenge:loginBonus.error.detail.timeOutEdit').toString(), 'error');
          setAppLoading(false);
          setTimeout(() => {
            setIsLoginBonusDialogOpen(false);
          }, 1000);
        } else if (result.error.response.data.details === 'Invalid startDate or endDate.') {
          showSnackbar(t('daily-challenge:loginBonus.error.detail.invalidStartDateAndEndDate').toString(), 'error');
          setAppLoading(false);
        } else if (result.error.response.data.message === 'tcchallenge-campaign-duplicated-bad-request') {
          showSnackbar(t('daily-challenge:loginBonus.error.detail.campaignDuplicated').toString(), 'error');
          setAppLoading(false);
          setTimeout(() => {
            setIsLoginBonusDialogOpen(false);
          }, 1000);
        } else {
          setAppLoading(false);
          showSnackbar(t('daily-challenge:loginBonus.message.updateFailed').toString(), 'error');
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedLoginBonusItem, setAppLoading, showSnackbar, t],
  );

  const handleAddLoginBonus = useCallback(async () => {
    setIsAddLoginBonusDialogOpen(true);
  }, []);

  const onSubmitAddNewLoginBonus = useCallback(
    async (formData?: LoginBonusFormDataRequest) => {
      if (formData) {
        setAppLoading(true);

        const startDate = formData.startDate ? dayjs(formData.startDate).valueOf() : null;
        const endDate = formData.endDate ? dayjs(formData.endDate).valueOf() : null;

        const dayRewards = [
          Number(formData.day1),
          Number(formData.day2),
          Number(formData.day3),
          Number(formData.day4),
          Number(formData.day5),
          Number(formData.day6),
          Number(formData.day7),
          Number(formData.day8),
          Number(formData.day9),
          Number(formData.day10),
        ].filter((item) => item !== 0);

        delete formData.day1;
        delete formData.day2;
        delete formData.day3;
        delete formData.day4;
        delete formData.day5;
        delete formData.day6;
        delete formData.day7;
        delete formData.day8;
        delete formData.day9;
        delete formData.day10;
        delete formData.period;

        const result = await addNewLoginBonus({ ...formData, startDate, endDate, dayRewards });

        if (result.data) {
          setSelectedLoginBonusItem(result.data);
          showSnackbar(t('daily-challenge:loginBonus.message.addNewLoginBonusSuccess').toString(), 'success');
          setIsAddLoginBonusDialogOpen(false);
          onFetch();
        } else if (result.error.response.data.message === 'tcchallenge-campaign-duplicated-bad-request') {
          showSnackbar(t('daily-challenge:loginBonus.error.detail.campaignDuplicated').toString(), 'error');
          setAppLoading(false);
          setTimeout(() => {
            setIsLoginBonusDialogOpen(false);
          }, 1000);
        } else if (result.error.response.data.message === 'tcchallenge-campaign-start-date-end-date-bad-request') {
          showSnackbar(t('daily-challenge:loginBonus.error.detail.invalidStartDateAndEndDate').toString(), 'error');
          setAppLoading(false);
        } else {
          showSnackbar(t('daily-challenge:loginBonus.message.addNewLoginBonusFailed').toString(), 'error');
        }
        setAppLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setAppLoading, showSnackbar, t],
  );

  const onConfirmationDeleteClick = useCallback(async () => {
    if (selectedLoginBonusItem?.hashId) {
      setAppLoading(true);
      const result = await deleteCampaignByHashId(selectedLoginBonusItem?.hashId);
      setAppLoading(false);

      if (result.data) {
        showSnackbar(t('daily-challenge:loginBonus.message.deleteSuccess').toString(), 'success');
        setIsDeleteConfirmOpen(false);
        onFetch();
      } else if (result.error.response.data.message === 'tcchallenge-campaign-delete-date-bad-request') {
        showSnackbar(t('daily-challenge:loginBonus.error.detail.timeOutEdit').toString(), 'error');
      } else {
        showSnackbar(t('daily-challenge:loginBonus.error.detail.deleteFailed').toString(), 'error');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLoginBonusItem?.hashId, setAppLoading, showSnackbar, t]);

  return (
    <>
      <Container className={containerClasses.container}>
        <Box className={tableClasses.tableContainer}>
          <Box mb={2}>
            <Grid container spacing={2}>
              <Grid item sm={12} md={8}>
                <AppButton
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={handleAddLoginBonus}
                  disabled={!allowCreate}>
                  {t('daily-challenge:loginBonus.button.addNewCampaign')}
                </AppButton>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                {!isLoadingData && (
                  <Box style={{ marginTop: '8px', marginRight: '8px', textAlign: 'right' }}>
                    <Typography variant="subtitle2" color="textSecondary">
                      {t('common:list.resultTotal', {
                        fieldName: t(totalBonusList.toLocaleString() || '0'),
                      })}
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>

          <AppTableList
            {...tableConditions}
            aria-label="login-bonus-list"
            columns={loginBonusTableColumns}
            totalDataCount={totalBonusList}
            hasPagination
            onTableConditionChanged={onTableConditionChangedHandler}
            isLoading={isLoadingData}
            tableBodyContent={
              loginBonusList.length > 0 && (
                <>
                  {loginBonusList.map((item: LoginBonus) => {
                    const isCampaignExpired = Number(item.endDate) < currentTime;
                    return (
                      <TableRow
                        hover
                        key={item.hashId}
                        classes={{ root: clsx(tableClasses.tableRow, tableClasses.clickableTableRow) }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          openLoginBonusDetailPage(item);
                        }}>
                        <AppTableCell>{item.hashId}</AppTableCell>
                        <AppTableCell>{item.title}</AppTableCell>
                        <AppTableCell>
                          {dayjs(item.startDate).format(`${dateDisplayFormat}`)}
                          <br />
                          {dayjs(item.startDate).format(`HH:mm:ss`)}
                        </AppTableCell>
                        <AppTableCell>
                          {dayjs(item.endDate).format(`${dateDisplayFormat}`)}
                          <br />
                          {dayjs(item.endDate).format(`HH:mm:ss`)}
                        </AppTableCell>
                        <AppTableCell>{item.dayRewards.length}</AppTableCell>
                        <AppTableCell>
                          {dayjs(item.createdAt).format(`${dateDisplayFormat}`)}
                          <br />
                          {dayjs(item.createdAt).format(`HH:mm:ss`)}
                        </AppTableCell>
                        <AppTableCell>
                          {dayjs(item.updatedAt).format(`${dateDisplayFormat}`)}
                          <br />
                          {dayjs(item.updatedAt).format(`HH:mm:ss`)}
                        </AppTableCell>
                        <AppTableCell>
                          {!item.deletedAt ? (
                            <Box>
                              {!isCampaignExpired ? (
                                <Box display="flex" className={classes.buttonGroups}>
                                  <AppButton
                                    color="primary"
                                    variant="outlined"
                                    startIcon={<EditIcon style={{ marginLeft: '10px' }} />}
                                    disabled={!allowEdit}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      openLoginBonusDetailPage(item);
                                    }}
                                  />

                                  <AppButton
                                    color="error"
                                    variant="outlined"
                                    startIcon={<DeleteForever style={{ marginLeft: '10px' }} />}
                                    disabled={!allowEdit}
                                    style={{ marginLeft: '2px' }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      setIsDeleteConfirmOpen(true);
                                      setSelectedLoginBonusItem(item);
                                    }}
                                  />
                                </Box>
                              ) : (
                                <Typography color="error" variant="subtitle2" style={{ textAlign: 'center' }}>
                                  หมดอายุแล้ว
                                </Typography>
                              )}
                            </Box>
                          ) : (
                            <Typography color="error" variant="subtitle2" style={{ textAlign: 'center' }}>
                              ปิดการใช้งานแล้ว
                            </Typography>
                          )}
                        </AppTableCell>
                      </TableRow>
                    );
                  })}
                </>
              )
            }
          />
        </Box>
      </Container>

      {isAddLoginBonusDialogOpen && (
        <AddNewLoginBonusItemDialog
          title={t('daily-challenge:loginBonus.dialogAddNewLoginBonus.title')}
          open={isAddLoginBonusDialogOpen}
          onClose={() => {
            setIsAddLoginBonusDialogOpen(false);
          }}
          onCloseClick={() => {
            setIsAddLoginBonusDialogOpen(false);
          }}
          onFormSubmit={onSubmitAddNewLoginBonus}
        />
      )}

      {selectedLoginBonusItem && (
        <LoginBonusItemDialog
          title={t('daily-challenge:loginBonus.title')}
          loginBonusItem={selectedLoginBonusItem}
          open={isLoginBonusDialogOpen}
          onFormSubmit={onEditFormSubmit}
          onClose={() => {
            setIsLoginBonusDialogOpen(false);
          }}
          onCloseClick={() => {
            setIsLoginBonusDialogOpen(false);
          }}
          onDialogExited={() => {
            setSelectedLoginBonusItem(undefined);
          }}
          isExpire={Number(selectedLoginBonusItem.endDate) < currentTime}
        />
      )}

      <AppDialog
        showCloseButton
        open={isDeleteConfirmOpen}
        title={t('daily-challenge:loginBonus.dialogDeleteLoginBonus.title')}
        okButtonText={t('common:button.confirm')}
        okButtonColor="error"
        okButtonVariant="contained"
        cancelButtonText={t('common:button.close')}
        onClose={() => {
          setIsDeleteConfirmOpen(false);
          setSelectedLoginBonusItem(undefined);
        }}
        onOkClick={onConfirmationDeleteClick}
        dialogProps={{
          fullWidth: true,
          maxWidth: 'sm',
          disableBackdropClick: true,
        }}>
        <Box mb={1}>
          <DataBlock label="daily-challenge:loginBonus.list.columns.hashId" data={selectedLoginBonusItem?.hashId} />
          <DataBlock
            label="daily-challenge:loginBonus.list.columns.campaignName"
            data={selectedLoginBonusItem?.title}
          />

          <DataBlock
            label="daily-challenge:loginBonus.list.columns.startDateAt"
            data={dayjs(selectedLoginBonusItem?.startDate).format(`D MMM BBBB HH:mm:ss`)}
          />
          <DataBlock
            label="daily-challenge:loginBonus.list.columns.endDateAt"
            data={dayjs(selectedLoginBonusItem?.endDate).format(`D MMM BBBB HH:mm:ss`)}
          />
        </Box>
        <Typography>{t('daily-challenge:loginBonus.dialogDeleteLoginBonus.description')}</Typography>
      </AppDialog>
    </>
  );
};

export default LoginBonusListTable;
