import React, { FC, useContext, useState } from 'react';

import { useTranslation } from 'react-i18next';
import useDeepCompareEffect from 'use-deep-compare-effect';

import PromoCodeListSearchForm from '../../components/partials/v3/promo-code/PromoCodeListSearchForm';
import PromoCodeListTable from '../../components/partials/v3/promo-code/PromoCodeListTable';
import { AppGlobalUiContext } from '../../context/AppGlobalUiContext';
import { SearchFilterContext } from '../../context/SearchFilterContext';
import { defaultRowsPerPage } from '../../helpers/constants';
import { AppTableConditions } from '../../models';
import { PromoCodeData, PromoCodeListSearchFormData, PromoCodeListSearchParamsTC } from '../../models/promo-code';
import { getPromoCodeData } from '../../services/promo-code';

const searchFilterContextKey = 'users';

const PromoCodeList: FC = () => {
  const { t } = useTranslation();
  const { showSnackbar } = useContext(AppGlobalUiContext);
  const [promoCodeList, setPromoCodeList] = useState<PromoCodeData[]>([]);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [totalPromoCode, setTotalPromoCode] = useState<number>(0);

  const { currentFilters: ctxFilters, setFilters } = useContext(SearchFilterContext);
  const currentFilters: PromoCodeListSearchParamsTC = ctxFilters ? ctxFilters[searchFilterContextKey] : undefined;
  const [searchFilters, setSearchFilters] = useState<PromoCodeListSearchFormData>({
    searchField: currentFilters?.searchField,
    searchKeyword: currentFilters?.searchKeyword || '',
    filterStateWorkflow: currentFilters?.filterStateWorkflow || [],
  });

  const [tableConditions, setTableConditions] = useState<AppTableConditions>({
    page: currentFilters?.page || 0,
    rowsPerPage: currentFilters?.rowsPerPage || defaultRowsPerPage,
    sortColumn: currentFilters?.sortColumn || 'createdAt',
    sortDirection: currentFilters?.sortDirection || 'desc',
  });

  const getCurrentSearchParam = (): PromoCodeListSearchParamsTC => ({
    page: tableConditions.page || 0,
    rowsPerPage: tableConditions.rowsPerPage || defaultRowsPerPage,
    sortColumn: tableConditions.sortColumn || 'createdAt',
    sortDirection: tableConditions.sortDirection || 'desc',
    searchField: searchFilters.searchField,
    searchKeyword: searchFilters.searchKeyword,
    filterStateWorkflow: searchFilters.filterStateWorkflow,
  });

  const getPromoCodeList = async (isPageSubscribed = true): Promise<boolean> => {
    if (isPageSubscribed) {
      setIsLoadingData(true);

      const searchConditions = getCurrentSearchParam();
      setFilters(searchFilterContextKey, searchConditions);

      const result = await getPromoCodeData(searchConditions);

      setIsLoadingData(false);

      if (result.data) {
        setPromoCodeList(result.data.contents);
        setTotalPromoCode(result.data?.page?.totalElements || 0);
        return Promise.resolve(true);
      }

      showSnackbar(t('promo-code:error.list.requestFailed').toString(), 'error');
      return Promise.reject(result.error);
    }

    return false;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useDeepCompareEffect((): any => {
    let isSubscribed = true;

    getPromoCodeList(isSubscribed);

    return () => (isSubscribed = false);
  }, [tableConditions, searchFilters]);

  const tableConditionsChangedHandler = (values: AppTableConditions) => {
    setTableConditions(values);
  };

  const onSearchFormSubmit = (filters: PromoCodeListSearchFormData) => {
    if (isLoadingData) {
      showSnackbar(t('common.message.pleaseWaitForDataToFinish').toString(), 'warning');
      return;
    }
    if (tableConditions.page !== 0) {
      tableConditions.page = 0;
    }
    setSearchFilters(filters);
  };

  return (
    <>
      <PromoCodeListSearchForm
        isLoadingData={isLoadingData}
        currentFilters={searchFilters}
        onSearchFormSubmit={onSearchFormSubmit}
      />
      <PromoCodeListTable
        currentConditions={tableConditions}
        promoCodeList={promoCodeList}
        totalPromoCode={totalPromoCode}
        isLoadingData={isLoadingData}
        onTableConditionsChanged={tableConditionsChangedHandler}
        onFetch={() => getPromoCodeList()}
      />
    </>
  );
};

export default PromoCodeList;
