import React, { createContext, FC } from 'react';

import useMasterData from '../hooks/useMasterData';
import { AppMasterDataContextHookProps } from '../models';

const MasterDataContext = createContext<AppMasterDataContextHookProps>({
  getData: () => Promise.resolve(undefined),
});

const MasterDataProvider: FC = (props) => {
  const { children } = props;
  const { Provider } = MasterDataContext;
  const { getData } = useMasterData();

  return <Provider value={{ getData }}>{children}</Provider>;
};

export { MasterDataContext, MasterDataProvider };
