import React, { FC, useEffect, useState } from 'react';

import { Box, TableRow, Typography } from '@material-ui/core';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { defaultRowsPerPage } from '../../../../../helpers/constants';
import { getT2HSiteUrl } from '../../../../../helpers/utils';
import i18n from '../../../../../i18n';
import { AppTableConditions, NotificationType, TableColumn } from '../../../../../models';
import { NotificationDataTC } from '../../../../../models/notification.model';
import { getNotificationsByUserHashIdTC } from '../../../../../services/notification';
import AppDialog from '../../../../ui/AppDialog';
import AppTableCell from '../../../../ui/AppTableCell';
import AppTableList from '../../../../ui/AppTableList';

export type UserNotificationDialogProps = {
  title: string;
  open: boolean;
  userHashId: string;
  isLoadingData: boolean;
  onClose: () => void;
  onDialogExited?: () => void;
  type?: NotificationType;
};

const UserNotificationDialog: FC<UserNotificationDialogProps> = (props) => {
  const { open, title, userHashId, type = 'T2H_TEXT', onClose, onDialogExited } = props;
  const [data, setData] = useState<NotificationDataTC[]>([]);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { t } = useTranslation();

  const dateDisplayFormat = i18n.language === 'th' ? 'D MMM BBBB' : 'D MMM YYYY';

  const notificationTableColumns: TableColumn[] = [
    { name: 'hashId', label: 'user:list.columns.hashId', width: 120 },
    { name: 'timestamp', label: 'user:list.columns.createdAt', width: 150 },
    { name: 'content', label: 'user:list.columns.content', width: 450 },
    { name: 'link', label: 'user:list.columns.link', width: 200 },
    { name: 'type', label: 'user:list.columns.type', width: 200 },
    { name: 'deleteAt', label: 'user:list.columns.deleteAt', width: 150 },
  ];

  const [tableConditions, setTableConditions] = useState<Partial<AppTableConditions>>({
    page: 0,
    rowsPerPage: defaultRowsPerPage,
    sortDirection: 'desc',
    notificationType: type,
  });

  const requestNotificationList = async () => {
    setIsLoading(true);
    if (userHashId) {
      const res = await getNotificationsByUserHashIdTC(userHashId, tableConditions);

      if (res.data) {
        setData(res.data.contents);
        setTotalItems(res.data?.page?.totalElements || 0);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    requestNotificationList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userHashId]);

  const onTableConditionChangedHandler = (values: AppTableConditions) => {
    if (JSON.stringify(values) !== JSON.stringify(tableConditions)) {
      setTableConditions({ ...values });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useDeepCompareEffect((): any => {
    requestNotificationList();
  }, [tableConditions]);

  return (
    <AppDialog
      open={open}
      title={title}
      onClose={onClose}
      okButtonText="common:button.close"
      okButtonColor="default"
      okButtonVariant="outlined"
      closeOnOkClicked
      dialogProps={{
        fullWidth: true,
        maxWidth: 'lg',
        TransitionProps: {
          onExited: () => {
            if (typeof onDialogExited === 'function') {
              onDialogExited();
            }
          },
        },
      }}>
      <Box px={3}>
        {!isLoading && !!data && (
          <AppTableList
            {...tableConditions}
            aria-label="payment-history-list"
            columns={notificationTableColumns}
            totalDataCount={totalItems}
            hasPagination
            onTableConditionChanged={onTableConditionChangedHandler}
            tableBodyContent={
              data.length > 0 && (
                <>
                  {data.map((item: NotificationDataTC) => (
                    <TableRow key={item.hashId}>
                      <AppTableCell style={{ whiteSpace: 'unset' }}>
                        <Typography variant="subtitle2">{item.hashId}</Typography>
                      </AppTableCell>
                      <AppTableCell>
                        {item.createdAt ? (
                          <Typography component="div" variant="subtitle2">
                            {dayjs(item.createdAt).format(`${dateDisplayFormat}`)}
                            <br />
                            {dayjs(item.createdAt).format(`HH:mm:ss`)}
                          </Typography>
                        ) : (
                          '-'
                        )}
                      </AppTableCell>
                      <AppTableCell style={{ whiteSpace: 'unset' }}>
                        <Typography variant="subtitle2">{item.messageTh}</Typography>
                      </AppTableCell>
                      <AppTableCell>
                        {item.link ? (
                          <Typography>
                            <a href={getT2HSiteUrl(`${item.link}`)} target="_blank" rel="noreferrer">
                              {item.link}
                            </a>
                          </Typography>
                        ) : (
                          '-'
                        )}
                      </AppTableCell>
                      <AppTableCell style={{ textAlign: 'center' }}>
                        {t(`user:details.label.${item.notificationType}`)}
                      </AppTableCell>
                      <AppTableCell>
                        {item.deletedAt ? (
                          <Typography component="div" variant="subtitle2">
                            {dayjs(item.deletedAt).format(`${dateDisplayFormat}`)}
                            <br />
                            {dayjs(item.deletedAt).format(`HH:mm:ss`)}
                          </Typography>
                        ) : (
                          '-'
                        )}
                      </AppTableCell>
                    </TableRow>
                  ))}
                </>
              )
            }
          />
        )}
      </Box>
    </AppDialog>
  );
};

export default UserNotificationDialog;
