import React, { ReactElement, useContext } from 'react';

import { Redirect, Route, RouteProps, Switch } from 'react-router-dom';

import ErrorPageTemplate from '../components/templates/ErrorPage';
import AppRouteRenderer from '../components/ui/AppRouteRenderer';
import { AuthContext } from '../context/AuthContext';
import { RouteConfig } from '../models';
import { AuthUser } from '../models/auth-user.model';

type RenderRoutesProps = {
  routes: RouteConfig[];
};

interface RouteWithSubRoutesProps extends RouteProps {
  exact: boolean;
  route: RouteConfig;
  isAuthenticated?: boolean;
  authUser?: AuthUser | null;
}

export const RouteWithSubRoutes = (routeProps: RouteWithSubRoutesProps): ReactElement => {
  const { route, isAuthenticated, exact } = routeProps;

  if (['/', '/login'].includes(route.path) && isAuthenticated) {
    return <Redirect to={{ pathname: '/dashboard' }} />;
  }

  return (
    <Route
      path={route.path}
      exact={exact}
      render={(props) => (
        <AppRouteRenderer translations={route.translations}>
          <route.component {...props} routes={route.routes} />
        </AppRouteRenderer>
      )}
    />
  );
};

export const ProtectedRoute = (routeProps: RouteWithSubRoutesProps): ReactElement => {
  const { route, isAuthenticated, authUser, exact } = routeProps;
  const hasUserRole = authUser && route.forRoles && route.forRoles.length > 0;
  const isRoleAllowed = hasUserRole && !!authUser?.role ? route.forRoles?.includes(authUser.role) : true;
  let redirectPath = '';

  if (!isAuthenticated) {
    redirectPath = route.path !== '/login' ? '/login' : '';
  } else if (route.path === '/login' || route.path === '/') {
    redirectPath = '/dashboard';
  } else {
    redirectPath = !isRoleAllowed ? '/unauthorized' : '';
  }

  if (redirectPath) {
    return (
      <Route
        path={route.path}
        exact={exact}
        render={({ location }) => (
          <Redirect
            to={{
              pathname: redirectPath,
              state: { from: location },
            }}
          />
        )}
      />
    );
  }

  return (
    <Route
      path={route.path}
      exact={exact}
      render={(props) => (
        <AppRouteRenderer translations={route.translations}>
          <route.component {...props} routes={route.routes} />
        </AppRouteRenderer>
      )}
    />
  );
};

export function RenderRoutes(props: RenderRoutesProps): ReactElement {
  const { routes } = props;
  const { authUser, isAuthenticated } = useContext(AuthContext);

  return (
    <>
      <Switch>
        {routes.map((route: RouteConfig) => {
          const isRouteProtected = route.protected || (route.forRoles && route.forRoles.length > 0);
          const routeExact = typeof route.exact === 'undefined' ? true : route.exact;

          if (isRouteProtected) {
            return (
              <ProtectedRoute
                exact={routeExact}
                isAuthenticated={isAuthenticated}
                authUser={authUser}
                key={route.key}
                path={route.path}
                route={route}
              />
            );
          }

          return (
            <RouteWithSubRoutes
              exact={routeExact}
              isAuthenticated={isAuthenticated}
              authUser={authUser}
              key={route.key}
              path={route.path}
              route={route}
            />
          );
        })}
        <Route component={() => <ErrorPageTemplate type="not-found" />} />
      </Switch>
    </>
  );
}
