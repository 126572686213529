import React, { FC, useState } from 'react';

import {
  Box,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { UpdateStatusMultiListingFormDataRequest } from '../../../../models/listing.model';
import useTypographyStyles from '../../../../theme/typography.style';
import AppButton from '../../../ui/AppButton';
import AppCheckbox from '../../../ui/AppCheckbox';
import AppDialog from '../../../ui/AppDialog';
import AppFormControl from '../../../ui/AppFormControl';

export type EditListingStatusDialogProps = {
  open: boolean;
  title: string;
  onClose: () => void;
  onCloseClick?: () => void;
  onFormSubmit: (data: UpdateStatusMultiListingFormDataRequest) => void;
  selectedItems: string[];
};

const useStyles = makeStyles((theme) => ({
  confirmDialogBox: {
    width: '100%',
    maxWidth: '1000px',
    [theme.breakpoints.up('md')]: {
      minWidth: '400px',
    },
  },
  buttonGroups: {
    '& > *': {
      margin: `0 ${theme.spacing(1)}px`,
    },
  },
}));

const EditListingStatusDialog: FC<EditListingStatusDialogProps> = (props) => {
  const classes = useStyles();
  const typoClasses = useTypographyStyles();
  const { open, title, onClose, onCloseClick, onFormSubmit, selectedItems } = props;
  const { t } = useTranslation();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);

  const {
    handleSubmit,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm<UpdateStatusMultiListingFormDataRequest>({
    mode: 'onChange',
    defaultValues: {
      hashIds: selectedItems || '',
      itemStatus: 'unlisted',
      rejectReason: '',
      soldReason: '',
      soldPrice: 0,
      notifyUserUnlistedItem: true,
    },
  });

  const isCurrentStatusSold = getValues('itemStatus') === 'sold';

  const onSaveClick = () => {
    setIsConfirmDialogOpen(true);
  };

  const onConfirmationSaveClick = () => {
    setIsConfirmDialogOpen(false);

    if (typeof onFormSubmit === 'function') {
      onFormSubmit(getValues());
    }
  };

  const onConfirmationCancelClick = () => {
    setIsConfirmDialogOpen(false);
  };

  return (
    <>
      <AppDialog
        open={open}
        onClose={onClose}
        title={title}
        okButtonText="common:button.close"
        okButtonColor="default"
        okButtonVariant="outlined"
        closeOnOkClicked
        noActionBar
        dialogProps={{
          disableBackdropClick: true,
          fullWidth: true,
          maxWidth: 'md',
        }}>
        <form onSubmit={handleSubmit(onSaveClick)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="flex" flexDirection="row" mb={1}>
                <Typography>ทั้งหมดจำนวน</Typography>
                <Typography color="primary" style={{ fontWeight: 'bold', margin: '0 6px' }}>
                  {t('listing:dialog.updateStatusListing.totalListing', {
                    item: selectedItems.length.toLocaleString(),
                  })}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="itemStatus"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <AppFormControl error={!!errors.itemStatus}>
                    <InputLabel htmlFor="listing-item-status">
                      {t('listing:dialog.updateStatusListing.selectStatuses')}
                    </InputLabel>
                    <Select
                      id="listing-item-status"
                      value={field.value}
                      onChange={field.onChange}
                      error={!!errors.itemStatus}
                      fullWidth>
                      <MenuItem value="unlisted">{t('listing:common.status.unlisted')}</MenuItem>
                      <MenuItem value="sold">{t('listing:common.status.sold')}</MenuItem>
                    </Select>
                  </AppFormControl>
                )}
              />
            </Grid>

            {isCurrentStatusSold && (
              <Grid item xs={12} sm={4}>
                <Controller
                  name="soldPrice"
                  control={control}
                  render={({ field }) => (
                    <AppFormControl error={!!errors.itemStatus}>
                      <InputLabel htmlFor="soldPrice">{t('listing:common.fields.sellingPrice')}</InputLabel>
                      <OutlinedInput
                        {...field}
                        id="soldPrice"
                        type="number"
                        inputProps={{ min: '0', step: 'any' }}
                        fullWidth
                        onClick={(e) => {
                          const target = e.target as HTMLInputElement;
                          target.select();
                        }}
                      />
                    </AppFormControl>
                  )}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              {isCurrentStatusSold ? (
                <Controller
                  name="soldReason"
                  control={control}
                  render={(data) => (
                    <AppFormControl error={!!errors.soldReason}>
                      <InputLabel htmlFor="soldReason-reject-listing">
                        {t('listing:dialog.updateStatusListing.reason')}
                      </InputLabel>
                      <Box style={{ position: 'relative' }}>
                        <OutlinedInput
                          {...data.field}
                          id="soldReason-reject-listing"
                          fullWidth
                          error={!!errors.soldReason}
                          rows={2}
                          multiline
                        />
                      </Box>
                    </AppFormControl>
                  )}
                />
              ) : (
                <Controller
                  name="rejectReason"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={(data) => (
                    <AppFormControl error={!!errors.rejectReason}>
                      <InputLabel htmlFor="rejectReason-reject-listing">
                        {t('listing:dialog.updateStatusListing.reason')}
                      </InputLabel>
                      <Box style={{ position: 'relative' }}>
                        <OutlinedInput
                          {...data.field}
                          id="rejectReason-reject-listing"
                          fullWidth
                          error={!!errors.rejectReason}
                          rows={2}
                          multiline
                        />
                      </Box>
                    </AppFormControl>
                  )}
                />
              )}
            </Grid>

            <Grid item xs={12} md={6}>
              {!isCurrentStatusSold && (
                <Controller
                  name="notifyUserUnlistedItem"
                  control={control}
                  rules={{
                    required: false,
                  }}
                  render={({ field }) => (
                    <AppFormControl>
                      <FormControlLabel
                        control={
                          <AppCheckbox
                            name={t('listing:dialog.updateStatusListing.notifyUserUnlisted')}
                            checked={getValues('notifyUserUnlistedItem')}
                            onChange={(e) => {
                              field.onChange(e.currentTarget.checked);
                            }}
                            color="default"
                          />
                        }
                        label={t('listing:dialog.updateStatusListing.notifyUserUnlisted')}
                      />
                    </AppFormControl>
                  )}
                />
              )}
            </Grid>

            <Grid item xs={12} md={6}>
              <Box display="flex" mb={2} justifyContent="flex-end" className={classes.buttonGroups}>
                <AppButton
                  variant="outlined"
                  color="default"
                  onClick={() => {
                    if (typeof onCloseClick === 'function') {
                      onCloseClick();
                    }
                  }}>
                  {t('common:button.cancel').toString()}
                </AppButton>
                <AppButton type="submit" color="primary">
                  {t('common:button.save').toString()}
                </AppButton>
              </Box>
            </Grid>
          </Grid>
        </form>

        <AppDialog
          title={t('common:dialog.title.confirm').toString()}
          open={isConfirmDialogOpen}
          onOkClick={onConfirmationSaveClick}
          onCancelClick={onConfirmationCancelClick}
          cancelButtonText={t('common:button.cancel').toString()}
          okButtonText={t('common:button.confirm').toString()}
          okButtonColor="error"
          dialogProps={{
            maxWidth: 'md',
          }}>
          <Box className={classes.confirmDialogBox}>
            <Box display="flex">
              <Typography>{t('listing:dialog.updateStatusListing.subTitle')}</Typography>
              <Typography color="primary" style={{ fontWeight: 'bold', margin: '0 4px' }}>
                {t('listing:dialog.updateStatusListing.totalListing', {
                  item: selectedItems.length.toLocaleString(),
                })}
              </Typography>
            </Box>
            <Box display="flex">
              <Typography>{t('listing:dialog.updateStatusListing.updateStatusTo')}</Typography>
              <Typography color="primary" style={{ fontWeight: 'bold', marginLeft: '4px' }}>
                {t(`listing:common.status.${getValues('itemStatus')?.toLocaleLowerCase()}`)}
              </Typography>
            </Box>
            {isCurrentStatusSold && (
              <Box mt={2} display="flex">
                <Typography className={typoClasses.textWeightBold}>
                  {t('listing:common.fields.sellingPrice')} :
                </Typography>
                <Typography style={{ marginLeft: '6px' }}>
                  {Number(getValues('soldPrice'))?.toLocaleString() || 0}
                </Typography>
              </Box>
            )}

            <Box mt={2}>
              <Typography className={typoClasses.textWeightBold}>เหตุผล :</Typography>
              <Typography>{watch(isCurrentStatusSold ? 'soldReason' : 'rejectReason')}</Typography>
            </Box>
          </Box>
        </AppDialog>
      </AppDialog>
    </>
  );
};

export default EditListingStatusDialog;
