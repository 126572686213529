import React from 'react';

import DayjsUtils from '@date-io/dayjs';
import { ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import axios from 'axios';
import 'dayjs/locale/th';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';
import { AppGlobalUiProvider } from './context/AppGlobalUiContext';
import { AuthProvider } from './context/AuthContext';
import { MasterDataProvider } from './context/MasterDataContext';
import { SearchFilterProvider } from './context/SearchFilterContext';
import { firebaseAuth } from './helpers/firebase';
import ScrollToTop from './helpers/ScrollToTop';
import { appDefaultLocale } from './i18n';
import reportWebVitals from './reportWebVitals';
import { getAuthToken, setAuthToken } from './services';
import appTheme from './theme';

// Axios request interceptor
axios.interceptors.request.use(
  async (request) => {
    let authAccessToken = getAuthToken();

    let idToken = authAccessToken?.firebaseIdToken;
    if (authAccessToken) {
      if (firebaseAuth.currentUser) {
        idToken = await firebaseAuth.currentUser.getIdToken();
        authAccessToken = {
          ...authAccessToken,
          firebaseIdToken: idToken,
        };

        setAuthToken({
          firebaseIdToken: idToken,
        });
      }

      if (idToken) {
        request.headers.Authorization = `${authAccessToken.scheme || 'Bearer'} firebase:${idToken}`;
      }
    }

    return request;
  },
  async (error) => Promise.reject(error),
);

dayjs.locale(appDefaultLocale);
if (appDefaultLocale === 'th') {
  dayjs.extend(buddhistEra);
}

ReactDOM.render(
  <AuthProvider>
    <Router>
      <ScrollToTop />
      <ThemeProvider theme={appTheme}>
        <MasterDataProvider>
          <SearchFilterProvider>
            <MuiPickersUtilsProvider utils={DayjsUtils}>
              <AppGlobalUiProvider>
                <App />
              </AppGlobalUiProvider>
            </MuiPickersUtilsProvider>
          </SearchFilterProvider>
        </MasterDataProvider>
      </ThemeProvider>
    </Router>
  </AuthProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
