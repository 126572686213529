/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ChangeEvent, FC, useContext, useEffect, useState } from 'react';

import { makeStyles, Paper, Tab, Tabs } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams, useLocation } from 'react-router-dom';

import ListingDetail, { ListingDetailProps } from '../../components/partials/listing/ListingDetail';
import AppTabPanel from '../../components/ui/AppTabPanel';
import { AppGlobalUiContext } from '../../context/AppGlobalUiContext';
import { AuthContext } from '../../context/AuthContext';
import { PROVINCE_OPTION } from '../../helpers/province-option';
import { pickNotEmptyV2, removeNullProperties } from '../../helpers/utils';
import { ApiResponse, KTCategory, KTCategoryPathItem, KTMasterIndexAttributeDataType, TabItem } from '../../models';
import {
  CategoryPathItem,
  ListingData,
  ListingFormData,
  ListingFormDataV2,
  ListingPossession,
  ListingStatus,
  PossessionInfo,
  SoldInfo,
} from '../../models/listing.model';
import { getKTCategoryBySlug, getUserByHashId } from '../../services';
import {
  draftListingByHashId,
  getListingByHashId,
  markAsSoldListingByHashId,
  markAsBookedListingByHashId,
  markAsUnlistedByHashId,
  publishListingByHashId,
  rejectListingByHashId,
  updateListing,
  approveJkkeListingByHashId,
  rejectJkkeListingByHashId,
  approveT2hOfficialListingByHashId,
  rejectT2hOfficialListingByHashId,
  waitJkkeListingByHashId,
  waitT2hOfficialListingByHashId,
  consignT2hOfficialListingByHashId,
  updateListingV2,
  resetJkkeListingByHashId,
} from '../../services/listing';

type ListingEditPageProps = unknown;

const tabIdPrefix = 'listing-tabs-';
const tabPanelPrefix = 'listing-tabpanel-';
const tabList: TabItem[] = [
  {
    id: 'info',
    label: 'listing:details.tabs.info',
    value: 'info',
  },
];

const useStyles = makeStyles(() => ({
  tabBar: {
    position: 'relative',
    zIndex: 2,
  },
  tabPanel: {
    position: 'relative',
    zIndex: 1,
  },
}));

const ListingEdit: FC<ListingEditPageProps> = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { showSnackbar, setAppLoading, setAppBarBackButtonUrl, setAppBarTitle } = useContext(AppGlobalUiContext);
  const classes = useStyles();
  const { authUser } = useContext(AuthContext);
  const [data, setData] = useState<ListingData | null>(null);
  const [reservedCategoryPathItems, setReservedCategoryPathItems] = useState<CategoryPathItem[]>([]);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [isUserDeleted, setIsUserDeleted] = useState<boolean>(false);
  const { hashId } = useParams<{ hashId: string }>();
  const [rootCategory, setRootCategory] = useState<KTCategory | null>(null);
  const location = useLocation();

  const findListingVersionFromCategoryPathItem = (item: CategoryPathItem) => item.slug === 'schema';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useEffect((): any => {
    let isSubscribed = true;

    if (isSubscribed) {
      setAppLoading(true);

      const pathNameArr = location.pathname.split(/\//gi).filter((fragment) => fragment);
      setAppBarBackButtonUrl(`/${pathNameArr[0] || 'listing'}`);

      if (hashId) {
        getListingByHashId(hashId)
          .then(async (result) => {
            if (!isSubscribed) {
              return null;
            }

            if (result.data) {
              if (result.data.userHashId) {
                const userResult = await getUserByHashId(result.data.userHashId);
                if (!userResult) {
                  setIsUserDeleted(true);
                }
              }
              setData(result.data);
              setAppBarTitle(result.data.title);
            }

            return true;
          })
          .finally(() => {
            setAppLoading(false);
          });
      }
    }

    return () => (isSubscribed = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const tabChangeHandler = (e: ChangeEvent<any>, value: any) => {
    setSelectedTab(value);
  };

  const genItemRequestData = (categoryPath: KTCategoryPathItem[], formData: ListingFormData) => {
    const categoryPathSlugList = categoryPath.map((item) => item.masterIndex.slug);
    const categoryPathValueNumberSlugList = categoryPath
      .filter((item) =>
        [
          KTMasterIndexAttributeDataType.ATTRIBUTE_NUMBER,
          KTMasterIndexAttributeDataType.ATTRIBUTE_CHOICE_AS_YEARS,
        ].includes(item.masterIndex.attributeDataType),
      )
      .map((item) => item.masterIndex.slug);
    const categoryPathValueWithValueNumberSlugList = categoryPath
      .filter(
        (item) => item.masterIndex.attributeDataType === KTMasterIndexAttributeDataType.ATTRIBUTE_CHOICE_AS_PROVINCE,
      )
      .map((item) => item.masterIndex.slug);

    const filterFormDataCategoryPath = Object.keys(formData).filter((key) => categoryPathSlugList.includes(key));

    // eslint-disable-next-line arrow-body-style
    const formDataCategoryPath = filterFormDataCategoryPath.reduce((obj, key) => {
      return {
        ...obj,
        [key]: formData[key],
      };
    }, {});

    const formDataCategoryPathArray = Object.entries(pickNotEmptyV2(formDataCategoryPath)).map(
      (item) => ({ slug: item[0], value: item[1] } as { slug: string; value: string | number }),
    );

    const formDataCategoryPathArraySetValue = formDataCategoryPathArray.map((item) => {
      if (categoryPathValueNumberSlugList.includes(item.slug)) {
        return { slug: item.slug, valueNumber: item.value ? Number(item.value.toString().replaceAll(',', '')) : 0 };
      }

      if (categoryPathValueWithValueNumberSlugList.includes(item.slug)) {
        const foundProvince =
          PROVINCE_OPTION.find((provinceItem) => item.value === provinceItem.slug) ||
          PROVINCE_OPTION.find((provinceItem) => item.value === provinceItem.uid);

        if (foundProvince) {
          return {
            slug: item.slug,
            value: foundProvince.slug,
            valueNumber: foundProvince.uid,
          };
        }
      }

      return item;
    });

    return {
      categoryPath: formDataCategoryPathArraySetValue,
    };
  };

  const onEditFormSubmit = async (
    formData: ListingFormData,
    isUpdateStatus = false,
    isUpdatePossession = false,
    rejectReason: string,
    dirtySoldInfo: SoldInfo,
    possessionInfo: PossessionInfo,
    isSendNotifyUserUnlisted: boolean,
  ) => {
    setAppLoading(true);

    const prepareUpdateFormData: ListingFormData = {
      ...removeNullProperties({
        categoryHashId: formData.categoryHashId || null,
        rootCategoryHashId: formData.rootCategoryHashId || null,
        subCategoryHashId: formData.subCategoryHashId || null,
        spaCategoryHashId: formData.spaCategoryHashId || null,
        brandHashId: formData.brandHashId || null,
        modelHashId: formData.modelHashId || null,
        provinceHashId: formData.provinceHashId || null,
        firstRegistrationYear: formData.firstRegistrationYear || null,
        operatingHours: formData.operatingHours || null,
        purchaseYear: formData.purchaseYear || null,
        licencePlate: formData.licencePlate || null,
        vin: formData.vin || null,
        vehicleRegistrationBookFileHashIds: formData.vehicleRegistrationBookFileHashIds,
        title: formData.title || null,
        imageHashIds: formData.imageHashIds,
        videoHashId: formData.videoHashId || null,
        sellingPrice: formData.sellingPrice || null,
        markdownPrice: formData.markdownPrice !== 0 && !!formData.markdownPrice ? formData.markdownPrice : null,
        documentNote: formData.documentNote || null,
        crStatus: formData.crStatus || null,
        cleansingStatus: formData.cleansingStatus || null,
        isNotRequiredFinancial: formData.isNotRequiredFinancial || null,
      }),
      /**
       * ! In order to be able to set null to mileage property we have take it out from removeNullProperties.
       */
      detail: formData.detail || null,
      mileage: formData.mileage || null,
    };

    let result;

    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let updateItemPromises: Promise<ApiResponse<any>>[] = [];
      let deleteItem = false;

      if (isUpdateStatus) {
        if (formData.status === ListingStatus.PUBLISHED) {
          updateItemPromises = [...updateItemPromises, publishListingByHashId(formData.hashId)];
        } else if (formData.status === ListingStatus.REJECTED) {
          updateItemPromises = [...updateItemPromises, rejectListingByHashId(formData.hashId, rejectReason)];
        } else if (formData.status === ListingStatus.SOLD) {
          const soldReasonUpdatedByAdmin = `sold-by-admin-${authUser?.hashId}`;
          const soldInfo: SoldInfo = {
            ...dirtySoldInfo,
            soldReason: soldReasonUpdatedByAdmin,
          };
          updateItemPromises = [...updateItemPromises, markAsSoldListingByHashId(formData.hashId, soldInfo)];
        } else if (formData.status === ListingStatus.DRAFT) {
          updateItemPromises = [...updateItemPromises, draftListingByHashId(formData.hashId)];
        } else if (formData.status === ListingStatus.UNLISTED) {
          updateItemPromises = [
            ...updateItemPromises,
            markAsUnlistedByHashId(formData.hashId, isSendNotifyUserUnlisted),
          ];
          deleteItem = true;
        } else if (formData.status === ListingStatus.BOOKED) {
          updateItemPromises = [...updateItemPromises, markAsBookedListingByHashId(formData.hashId)];
        }
      }

      if (isUpdatePossession) {
        if (formData.possession === ListingPossession.JkkeReset) {
          updateItemPromises = [...updateItemPromises, resetJkkeListingByHashId(formData.hashId)];
        } else if (formData.possession === ListingPossession.Jkke) {
          updateItemPromises = [...updateItemPromises, approveJkkeListingByHashId(formData.hashId)];
        } else if (formData.possession === ListingPossession.JkkeWaiting) {
          updateItemPromises = [...updateItemPromises, waitJkkeListingByHashId(formData.hashId)];
        } else if (formData.possession === ListingPossession.JkkeRejected) {
          updateItemPromises = [...updateItemPromises, rejectJkkeListingByHashId(formData.hashId, possessionInfo)];
        } else if (formData.possession === ListingPossession.T2hOfficial) {
          updateItemPromises = [...updateItemPromises, approveT2hOfficialListingByHashId(formData.hashId)];
        } else if (formData.possession === ListingPossession.T2hOfficialWaiting) {
          updateItemPromises = [...updateItemPromises, waitT2hOfficialListingByHashId(formData.hashId)];
        } else if (formData.possession === ListingPossession.T2hOfficialConsignment) {
          updateItemPromises = [...updateItemPromises, consignT2hOfficialListingByHashId(formData.hashId)];
        } else if (formData.possession === ListingPossession.T2hOfficialRejected) {
          updateItemPromises = [
            ...updateItemPromises,
            rejectT2hOfficialListingByHashId(formData.hashId, possessionInfo),
          ];
        }
      }

      const isUpdatePossessionSuccess = (await updateItemPromises[1]).data;

      if (isUpdatePossessionSuccess) {
        const [updatedStatusResult, updatedPossessionResult] = await Promise.all(updateItemPromises);

        // merge both item.status & item.possession to prevent race conditions
        result = {
          ...(updatedStatusResult || {}),
          ...(updatedPossessionResult || {}),
          ...(updatedStatusResult && updatedStatusResult.data && updatedPossessionResult && updatedPossessionResult.data
            ? {
                status: updatedStatusResult.data.status,
                possession: updatedStatusResult.data.possession,
                possessionComment: updatedStatusResult.data?.possessionComment,
              }
            : {}),
        };

        // section for v2 update
        if (formData.categoryPath && formData.category) {
          const {
            categoryHashId: unusedCategoryHashId,
            rootCategoryHashId: unusedRootCategoryHashId,
            subCategoryHashId: unusedSubCategoryHashId,
            spaCategoryHashId: unusedSpaCategoryHashId,
            brandHashId: unusedBrandHashId,
            modelHashId: unusedModelHashId,
            modelName: unusedModelName,
            provinceHashId: unusedProvinceHashId,
            firstRegistrationYear: unusedFirstRegistrationYear,
            operatingHours: unusedOperatingHours,
            mileage: unusedMileage,
            mileageNA: unusedMileageNA,
            status: unusedStatus,
            possessionComment: unusedPossessionComment,
            rejectReason: unusedRejectReason,
            category: unusedCategory,
            vehicleRegistrationBookFileHashIds: unusedVehicleRegistrationBookFileHashIds,
            videoHashId: unusedVideoHashId,
            ...restPrepareUpdateFormDataV1
          } = prepareUpdateFormData;
          /* eslint-enable */

          const rawPrepareUpdateFormDataV2 = genItemRequestData(formData.categoryPath, formData);
          const prepareUpdateFormDataV2: ListingFormDataV2 = {
            ...(restPrepareUpdateFormDataV1 as ListingFormDataV2),
            ...((rawPrepareUpdateFormDataV2 || {}) as ListingFormDataV2),
            categoryPath: [
              ...(reservedCategoryPathItems || []),
              ...(rawPrepareUpdateFormDataV2?.categoryPath as CategoryPathItem[]),
            ],
          };

          result = await updateListingV2(formData.hashId, prepareUpdateFormDataV2);
        } else {
          // update v1
          result = await updateListing(formData.hashId, prepareUpdateFormData);
        }

        if (result?.error) {
          throw new Error(result?.error);
        }

        if (result?.data?.data) {
          setData(result?.data.data);
          showSnackbar(t(`listing:success.edit`), 'success');
        }

        // Delete listing item must do the last order.
        // To prevent the error from update can't find listing item.
        if (deleteItem) {
          history.replace('/listing');
        }
        const delaySecond = 2;

        setTimeout(() => {
          setAppLoading(false);
          history.go(0);
        }, 1000 * delaySecond);
      } else {
        showSnackbar(
          `${t(`listing:error.editPossessionStatus`, {
            status: t(`listing:common.possession.${formData.possession}`).toString(),
          })}`,
          'error',
        );
        setAppLoading(false);
        setTimeout(() => {
          history.go(0);
        }, 3000);
      }
    } catch (error) {
      setAppLoading(false);
      showSnackbar(`${t(`listing:error.edit`)}`, 'error');
    }
  };

  const onRootCategoryChange: ListingDetailProps['onRootCategoryChange'] = (rootCat) => {
    const rootCategorySlug = rootCat?.value;

    if (!rootCategorySlug) {
      return;
    }

    (async () => {
      const rootCategoryResult = ((await getKTCategoryBySlug(rootCategorySlug)) || {}).data;

      if (rootCategoryResult) {
        setRootCategory(rootCategoryResult as KTCategory);
      }
    })();
  };

  React.useEffect(() => {
    const schemaVersionCategoryPathItem: CategoryPathItem | undefined = data?.categoryPath?.find(
      findListingVersionFromCategoryPathItem,
    );

    setReservedCategoryPathItems([schemaVersionCategoryPathItem as CategoryPathItem].filter(Boolean));
  }, [data?.categoryPath]);

  React.useEffect(() => {
    if (data?.categoryPath) {
      (async () => {
        const rootCategorySlug =
          data.categoryPath?.find((item: CategoryPathItem) => item.slug === 'root-category')?.value || null;

        if (!rootCategorySlug) {
          return;
        }

        const rootCategoryResult = ((await getKTCategoryBySlug(rootCategorySlug)) || {}).data;

        setRootCategory(rootCategoryResult as KTCategory);
      })();
    }
  }, [data]);

  return (
    <>
      {!!data && (
        <>
          <Paper square className={classes.tabBar}>
            <Tabs
              value={selectedTab}
              onChange={tabChangeHandler}
              aria-label="Listing detail tabs"
              textColor="primary"
              indicatorColor="primary">
              {tabList.map((tab: TabItem) => (
                <Tab
                  key={tab.id}
                  label={t(tab.label).toString()}
                  id={`${tabIdPrefix}${tab.id}`}
                  aria-controls={`${tabPanelPrefix}${tab.id}`}
                  disabled={tab.disabled || false}
                />
              ))}
            </Tabs>
          </Paper>
          <AppTabPanel
            value={selectedTab}
            index={0}
            tabItem={tabList[0]}
            tabIdPrefix={tabIdPrefix}
            tabPanelPrefix={tabPanelPrefix}
            className={classes.tabPanel}>
            {data && (
              <ListingDetail
                data={data}
                rootCategory={rootCategory}
                isUserDeleted={isUserDeleted}
                onFormSubmit={onEditFormSubmit}
                onRootCategoryChange={onRootCategoryChange}
              />
            )}
          </AppTabPanel>
        </>
      )}
    </>
  );
};

export default ListingEdit;
