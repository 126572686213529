/* eslint-disable @typescript-eslint/no-explicit-any */
import { apiKTRequest, apiRequest } from '../helpers/request';
import { apiResponseTransformer } from '../helpers/response';
import { getPagingParameter, queryParamGenerator } from '../helpers/utils';
import {
  ApiResponse,
  Category,
  CategoryFormData,
  CategoryMasterRoot,
  CategorySearchFilters,
  ESCategory,
  GetEsCategoryChildrenParams,
  KTCategory,
  KTPageResult,
  KTRootCategory,
  ListResponse,
} from '../models';

type CategoryListAxiosResponse = {
  categories: Category[];
  page: KTPageResult;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const parsePathFromStringToJson = (itemCat: any) =>
  !itemCat
    ? itemCat
    : {
        ...itemCat,
        breadcrumbPath: itemCat.breadcrumbPath ? JSON.parse(itemCat.breadcrumbPath) : null,
        path: itemCat.path ? JSON.parse(itemCat.path) : null,
      };

export const getAllCategoryMasterRoot = async (): Promise<CategoryMasterRoot[]> => {
  try {
    const result = await apiRequest<CategoryMasterRoot[]>('/root-category-master/all');

    return result.data || [];
  } catch (e) {
    return [];
  }
};

/**
 * Get search category query parameter from given filters
 *
 * @param param Category search filter
 * @returns URL query parameter string
 */
const getCategoryListQueryParam = (param?: CategorySearchFilters): string => {
  const reqParam = getPagingParameter(param, true);

  // Only search with keyword when it comes with the selected column to be searched
  if (param?.searchKeyword && param?.searchField) {
    reqParam[param.searchField] = param.searchKeyword;
  }

  if (param?.masterIndexUid) {
    reqParam.masterIndexUid = param.masterIndexUid;
  }

  return queryParamGenerator(reqParam, true);
};

/**
 * Get category list from search filter
 *
 * @param param Category search filter
 * @returns Search request result as promise function
 */
export const getCategoryList = async (param?: CategorySearchFilters): Promise<ApiResponse<ListResponse<Category>>> => {
  const qString = getCategoryListQueryParam(param);
  const result = await apiKTRequest<CategoryListAxiosResponse>(`/api/category?${qString}`);

  return apiResponseTransformer<CategoryListAxiosResponse, ListResponse<Category>>(
    result,
    'master-data:error.category.requestCategoryFailed',
    (data: CategoryListAxiosResponse) => ({
      list: data.categories,
      totalCount: data.page.totalElements,
    }),
  );
};

/**
 * Get category by its name
 *
 * @param keyword Category search keyword
 * @returns Search request result as promise function
 */
export const getCategoryByName = async (keyword: string): Promise<ApiResponse<ListResponse<Category>>> => {
  const result = await apiKTRequest<CategoryListAxiosResponse>(`/api/category?q=${keyword}`);

  return apiResponseTransformer<CategoryListAxiosResponse, ListResponse<Category>>(
    result,
    'master-data:error.category.requestCategoryFailed',
    (data: CategoryListAxiosResponse) => ({
      list: data.categories,
      totalCount: data.page.totalElements,
    }),
  );
};

/**
 * Get category by its name
 *
 * @param masterIndexUid Category masterIndexUid
 * @returns Search request result as promise function
 */
export const getCategoryByMasterIndexUid = async (
  masterIndexUid: string,
): Promise<ApiResponse<ListResponse<Category>>> => {
  const result = await apiKTRequest<CategoryListAxiosResponse>(
    `/api/category?page=0&per_page=100&masterIndexUid=${masterIndexUid}`,
  );

  return apiResponseTransformer<CategoryListAxiosResponse, ListResponse<Category>>(
    result,
    'master-data:error.category.requestCategoryFailed',
    (data: CategoryListAxiosResponse) => ({
      list: data.categories,
      totalCount: data.page.totalElements,
    }),
  );
};

/**
 * Get category by its name
 *
 * @param masterIndexUid Category masterIndexUid
 * @returns Search request result as promise function
 */
export const getModelByCategoryUid = async (categoryUid: string): Promise<ApiResponse<ListResponse<Category>>> => {
  const result = await apiKTRequest<CategoryListAxiosResponse>(
    `/api/category/${categoryUid}/models?page=0&per_page=100`,
  );

  return apiResponseTransformer<CategoryListAxiosResponse, ListResponse<Category>>(
    result,
    'master-data:error.category.requestCategoryFailed',
    (data: CategoryListAxiosResponse) => ({
      list: data.categories,
      totalCount: data.page.totalElements,
    }),
  );
};

/**
 * Get category from given UID
 *
 * @param uid Category UID
 * @returns Search request result as promise function
 */
export const getCategoryByUid = async (uid: number | string): Promise<ApiResponse<Category>> => {
  const result = await apiKTRequest<Category>(`/api/category/${uid}`);

  return apiResponseTransformer(result, 'master-data:error.category.requestCategoryFailed');
};

/**
 * Create new category
 *
 * @param data Category form data to be created
 * @returns Create result as promise function
 */
export const createCategory = async (data: CategoryFormData): Promise<ApiResponse<Category>> => {
  const result = await apiKTRequest<Category>(`/admin_manager/category`, {
    method: 'POST',
    data: {
      ...data,
    },
  });

  return apiResponseTransformer(result, 'master-data:error.category.createFailed');
};

/**
 * Delete category
 *
 * @param data Category form data to be deleted
 * @returns Delete result as promise function
 */
export const deleteCategory = async (id: string): Promise<boolean> => {
  try {
    const result = await apiKTRequest<Category>(`/admin_manager/category/${id}`, {
      method: 'DELETE',
    });
    return result.status === 200;
  } catch (e) {
    return Promise.reject(e);
  }
};

/**
 * Update category
 *
 * @param id Category ID
 * @param data Category form data to be updated
 * @returns Update result as promise function
 */
export const updateCategory = async (id: number, data: CategoryFormData): Promise<ApiResponse<Category>> => {
  const result = await apiKTRequest<Category>(`/admin_manager/category`, {
    method: 'PATCH',
    data: {
      ...data,
      id,
    },
  });

  return apiResponseTransformer(result, 'master-data:error.category.updateFailed');
};

/**
 * Sync elasticsearch categories
 *
 * @returns Update result as promise function
 */
export const syncElasticsearchCategoriesData = async (): Promise<ApiResponse<boolean>> => {
  const result = await apiKTRequest<boolean>(`/admin_manager/es/category/sync`, {
    method: 'GET',
  });

  return apiResponseTransformer(result, 'master-data:error.operations.dataSyncFailed');
};

/**
 * Sync elasticsearch category
 *
 * @param id Category ID (NOT UID)
 * @returns Update result as promise function
 */
export const syncElasticsearchCategoryData = async (id: number): Promise<ApiResponse<ESCategory>> => {
  const result = await apiKTRequest<ESCategory>(`/admin_manager/es/category/sync/${id}`, {
    method: 'GET',
  });

  return apiResponseTransformer(result, 'master-data:error.category.updateFailed');
};

/**
 * Get ESModels
 *
 * @param masterIndexUid masterIndex Uid
 * @param GetEsCategoryChildrenParams string
 * @returns ESCategories with pagination
 */
export const getEsCategoryChildren = async (
  masterIndexUid: number,
  params?: GetEsCategoryChildrenParams,
): Promise<
  ApiResponse<{
    esCategories: ESCategory[];
    page: KTPageResult;
  }>
> => {
  const qString = queryParamGenerator({
    page: 0,
    per_page: 30,
    ...params,
  });

  const result = await apiKTRequest<ESCategory>(`/api/es/category/${masterIndexUid}/children?${qString}`);

  return apiResponseTransformer(result, 'master-index:error.list.requestEsCategoryFailed');
};

/**
 * Get ESModel
 *
 * @param slug string
 * @returns ESCategory
 */
export const getEsCategoryBySlug = async (slug: string): Promise<ESCategory> => {
  const result = await apiKTRequest<ESCategory>(`/api/es/category/slug/${slug}`);
  const esCategory = parsePathFromStringToJson(result.data);

  return esCategory;
};

export const getEsCategoryBySlugs = async (slug: string[]): Promise<ESCategory[]> => {
  const slugs = new URLSearchParams();
  slug.map((item) => slugs.append('slugs', item));

  const result = await apiKTRequest<{ esCategories: ESCategory[] }>(`/api/es/category/slugs`, {
    params: slugs,
  });
  const esCategory = parsePathFromStringToJson(result.data);
  return esCategory.esCategories;
};

/**
 * Get KTCategory
 *
 * @param slug string
 * @returns KTCategory
 */
export const getKTCategoryBySlug = async (slug: string): Promise<ApiResponse<KTCategory>> => {
  const result = await apiKTRequest<KTCategory>(`/api/es/category/slug/${slug}`);

  return apiResponseTransformer(result, 'master-index:error.list.requestKTCategoryFailed');
};

/**
 * Get ESModels
 * @returns ESCategories with masterindex = 1
 */
export const getKTMasterIndexRootCategory = async (): Promise<ESCategory[]> => {
  const result = await apiKTRequest<KTRootCategory>(`/api/es/category/1/children?sort=id%7CASC`);

  return result.data.esCategories;
};
