import React, { FC, useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import ReportedItemTable from '../../components/partials/listing/ReportedItemTable';
import ReportedSearchForm from '../../components/partials/listing/ReportedSearchForm';
import { AppGlobalUiContext } from '../../context/AppGlobalUiContext';
import { SearchFilterContext } from '../../context/SearchFilterContext';
import { defaultRowsPerPage } from '../../helpers/constants';
import { AppTableConditions } from '../../models';
import {
  ReportListingData,
  ReportListingListSearchFormData,
  ReportListingListSearchParams,
} from '../../models/report-listing.model';
import { getReportListingList } from '../../services/report-listing';

const searchFilterContextKey = 'report-listing';

const ReportListingList: FC = () => {
  const { t } = useTranslation();
  const { showSnackbar } = useContext(AppGlobalUiContext);
  const [reportList, setReportList] = useState<ReportListingData[]>([]);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [totalList, setTotalList] = useState<number>(0);

  const { currentFilters: ctxFilters, setFilters } = useContext(SearchFilterContext);
  const currentFilters: ReportListingListSearchParams = ctxFilters ? ctxFilters[searchFilterContextKey] : undefined;
  const [searchFilters, setSearchFilters] = useState<ReportListingListSearchFormData>({
    filteringTypes: currentFilters?.filteringTypes || [],
    filteringStatuses: currentFilters?.filteringStatuses || [],
  });

  const [tableConditions, setTableConditions] = useState<AppTableConditions>({
    page: currentFilters?.page || 0,
    rowsPerPage: currentFilters?.rowsPerPage || defaultRowsPerPage,
    sortColumn: currentFilters?.sortColumn || 'createdAt',
    sortDirection: currentFilters?.sortDirection || 'desc',
  });

  const getCurrentSearchParam = (): ReportListingListSearchParams => ({
    page: tableConditions.page || 0,
    rowsPerPage: tableConditions.rowsPerPage || defaultRowsPerPage,
    sortColumn: tableConditions.sortColumn || 'createdAt',
    sortDirection: tableConditions.sortDirection || 'desc',
    filteringTypes: searchFilters.filteringTypes,
    filteringStatuses: searchFilters.filteringStatuses,
  });

  const requestReportList = async (isPageSubscribed = true): Promise<boolean> => {
    if (isPageSubscribed) {
      setIsLoadingData(true);

      const searchConditions = getCurrentSearchParam();
      setFilters(searchFilterContextKey, searchConditions);
      const result = await getReportListingList({ ...searchConditions });
      setIsLoadingData(false);

      if (result.data) {
        setReportList(result.data.contents);
        setTotalList(result.data.totalCount);
        return Promise.resolve(true);
      }

      if (result.error) {
        setReportList([]);
        setTotalList(0);
        showSnackbar(t('report-listing:message.error.list.requestFailed').toString(), 'error');
        return Promise.reject(result.error);
      }
      showSnackbar(t(result.error as string).toString(), 'error');
      return Promise.reject(result.error);
    }

    return false;
  };

  useEffect(() => {
    let isSubscribed = true;
    requestReportList(isSubscribed);

    return () => {
      isSubscribed = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableConditions, searchFilters]);

  const tableConditionsChangedHandler = (values: AppTableConditions) => {
    setTableConditions(values);
  };

  const onSearchFormSubmit = (filters: ReportListingListSearchFormData) => {
    if (isLoadingData) {
      showSnackbar(t('common.message.pleaseWaitForDataToFinish').toString(), 'warning');
      return;
    }
    if (tableConditions.page !== 0) {
      tableConditions.page = 0;
    }
    setSearchFilters(filters);
  };

  return (
    <>
      <ReportedSearchForm
        isLoadingData={isLoadingData}
        currentFilters={searchFilters}
        onSearchFormSubmit={onSearchFormSubmit}
      />
      <ReportedItemTable
        currentConditions={tableConditions}
        reportList={reportList}
        totalReportedItems={totalList}
        isLoadingData={isLoadingData}
        onTableConditionsChanged={tableConditionsChangedHandler}
        onFetch={() => requestReportList()}
      />
    </>
  );
};

export default ReportListingList;
