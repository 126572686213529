/* eslint-disable import/prefer-default-export */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { getKTResponseErrorMessage } from './response';
import { getBackendUrl, getKTBackendUrl, getTCBackendUrl } from './utils';
import { AxiosApiResponse } from '../models/common';

export const apiRequest = async <T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
  const reqUrl = getBackendUrl(url);
  if (!reqUrl) {
    return Promise.reject(new Error('error:request.undefinedEnv'));
  }

  const reqConfig: AxiosRequestConfig = config || {};
  reqConfig.url = reqUrl;

  const result = await axios.request<T>(reqConfig);

  return result;
};

export const apiTCRequest = async <T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
  const reqUrl = getTCBackendUrl(url);
  if (!reqUrl) {
    return Promise.reject(new Error('error:request.undefinedEnv'));
  }

  const reqConfig: AxiosRequestConfig = config || {};
  reqConfig.url = reqUrl;

  const result = await axios.request<T>(reqConfig);

  return result;
};

export const apiTCRequestV2 = async <T>(
  url: string,
  config?: AxiosRequestConfig,
): Promise<AxiosApiResponse<T | null>> => {
  const reqUrl = getTCBackendUrl(url);
  if (!reqUrl) {
    return Promise.reject(new Error('error:request.undefinedEnv'));
  }

  const reqConfig: AxiosRequestConfig = config || {};
  reqConfig.url = reqUrl;

  const result = await axios.request<T>(reqConfig).catch((e) => ({ data: null, error: e }));

  return result as AxiosApiResponse<T>;
};

export const apiKTRequest = async <T>(url: string, config?: AxiosRequestConfig): Promise<AxiosApiResponse<T>> => {
  const reqUrl = getKTBackendUrl(url);
  if (!reqUrl) {
    return Promise.reject(new Error('error:request.undefinedEnv'));
  }

  const reqConfig: AxiosRequestConfig = config || {};
  reqConfig.url = reqUrl;

  const result = await axios.request<T>(reqConfig).catch((e) => {
    if (Array.isArray(e.response?.data.errors)) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const errorList = e.response.data.errors.map((error: any) => {
        const { code, field, defaultMessage } = error;
        return getKTResponseErrorMessage(code, field, defaultMessage);
      });

      return { data: null, validationErrors: errorList };
    }
    if (e.response?.data) {
      const errorItem = getKTResponseErrorMessage('', '', e.response.data.message);
      return { data: null, validationErrors: errorItem ? [errorItem] : null };
    }

    return { data: null };
  });

  return result as AxiosApiResponse<T>;
};
