import React, { FC } from 'react';

import { Chip, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { ReportStatus } from '../../../../models';
import { colors } from '../../../../theme/colors';

export type ReportedStatusBadgeProps = {
  status: string;
};

const useStyles = makeStyles(() => ({
  submittedChip: {
    backgroundColor: colors.appSnsReportStatus.submitted.main,
    color: colors.appSnsReportStatus.submitted.contrastText,
  },
  approvedChip: {
    backgroundColor: colors.appSnsReportStatus.approved.main,
    color: colors.appSnsReportStatus.approved.contrastText,
  },
  rejectedChip: {
    backgroundColor: colors.appSnsReportStatus.rejected.main,
    color: colors.appSnsReportStatus.rejected.contrastText,
  },
  markAsDuplicatedChip: {
    backgroundColor: colors.appSnsReportStatus.markAsDuplicated.main,
    color: colors.appSnsReportStatus.markAsDuplicated.contrastText,
  },
}));

const ReportedStatusBadge: FC<ReportedStatusBadgeProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { status } = props;

  return (
    <Chip
      label={t(`sns:common.reportStatus.${status}`).toString()}
      className={clsx({
        [classes.submittedChip]: status === ReportStatus.Submitted,
        [classes.approvedChip]: status === ReportStatus.Approved,
        [classes.rejectedChip]: status === ReportStatus.Rejected,
        [classes.markAsDuplicatedChip]: status === ReportStatus.MarkAsDuplicated,
      })}
    />
  );
};

export default ReportedStatusBadge;
