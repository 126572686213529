import { apiKTRequest } from '../helpers/request';
import { apiResponseTransformer } from '../helpers/response';
import { ApiResponse } from '../models';

/**
 * Sync elasticsearch dictionary
 *
 * @returns Update result as promise function
 */
// eslint-disable-next-line import/prefer-default-export
export const syncElasticsearchDictsData = async (): Promise<ApiResponse<boolean>> => {
  const result = await apiKTRequest<boolean>(`/es/dictionary/sync`, {
    method: 'GET',
  });

  return apiResponseTransformer(result, 'master-data:error.operations.dataSyncFailed');
};
